import React from "react";
import styled from "styled-components";
import { rose } from "./../colors";

const StyledErrorMessage = styled.div`
  color: ${rose};
  margin-top: 5px;
  font-family: arial;
  font-size: 12px;
`;

const ErrorMessage = ({ children }) => (
  <StyledErrorMessage>{children}</StyledErrorMessage>
);

export default ErrorMessage;
