import React, { Component } from "react";
import { EXAM_TYPES } from "../../consts";

/** Internal */
import { REQUEST_EXAM_STATE_ID_BY_NAME } from "../../assets/js/Consts.js";
import DefaultExamTableReportFormView from "./../DefaultExamTableReportFormView";
import connectWithEndpoint from "./requests.js";
import validation from "./validation.js";
import {
  examTableHeaderFactory,
  endocrinologyFactory,
} from "../../assets/js/reportFunctions.js";
import { EXAM_TYPE_ID_BY_NAME } from "../../assets/js/Consts.js";
import {
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";

/** External */
import { FunctionUtil } from "./../../useful";
import update from "immutability-helper";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";

const API_URL = process.env.REACT_APP_PROXY;

const View = ({ data, methods, $field, $validation, $submit }) => {
  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;

    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    $field: $field,
    ...methods,
  };

  return <DefaultExamTableReportFormView data={newData} methods={newMethods} />;
};

const ValidatedView = validation(View);

class DefaultExamTableReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        options: [],
        vetSignerId: 0,
        chemilObs: "",
      },
      shouldSendEmail: false,
    };
    this.state.fields.chemilObs = "";
    this.isUpdate = this.isUpdate.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);
    this.resultChange = this.resultChange.bind(this);
    this.stateChange = this.stateChange.bind(this);
    this.multiFieldResultChange = this.multiFieldResultChange.bind(this);
    this.examTableHeaderData = this.examTableHeaderData.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const examRequestId = this.props.match.params.examRequestId;
        const examTypeId = this.props.examTypeId;

        let reportsUrl = "";
        let refsUrl = "";

        switch (examTypeId) {
          case EXAM_TYPE_ID_BY_NAME["ENDOCRINOLOGY_CHEMILUMINESCENCE"]:
            reportsUrl = `${API_URL}/endocrinology-chemiluminescence/reports/${examRequestId}`;
            refsUrl = `${API_URL}/reference-values/endocrinology-chemiluminescence/request-exams/${examRequestId}`;
            // refsUrl = `${API_URL}/reference-values/?requestExamId=${requestExamId}&examTypeId=${EXAM_TYPES.ENDOCRINOLOGY_CHEMILUMINESCENCE}`;
            break;
          case EXAM_TYPE_ID_BY_NAME["ENDOCRINOLOGY_RADIOIMMUNOASSAY"]:
            reportsUrl = `${API_URL}/endocrinology-radioimmunoassay/reports/${examRequestId}`;
            refsUrl = `${API_URL}/reference-values/endocrinology-radioimmunoassay/request-exams/${examRequestId}`;
            // refsUrl = `${API_URL}/reference-values/?requestExamId=${requestExamId}&examTypeId=${EXAM_TYPES.ENDOCRINOLOGY_RADIOIMMUNOASSAY}`;
            break;
        }
        const urls = [reportsUrl, refsUrl];

        const allRequests = urls.map((url) =>
          fetch(url).then((response) => response.json())
        );

        Promise.all(allRequests).then(([reports, refs]) => {
          this.fillFields(reports, refs);
        });
      }
    };

    this.props.getRequestExamsWithExamRequestId(then);
  }

  addReportData(options, reports) {
    return options.map((option) => {
      const found = reports.find((report) => report.examId === option.examId);

      if (found) {
        option.id = found.id;
        option.result = found.result;
        option.obs = found.obs;
        option.requestExamState = found.requestExamState;
      }

      return option;
    });
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fillFields(reports, refs) {
    const vetSignerId = this.getVetSignerId(reports);

    const requestExams = [...this.props.requestExamsWithExamRequestId.value];

    let options = requestExams.map((requestExam) => {
      return endocrinologyFactory(requestExam, refs);
    });

    if (reports.length > 0) {
      options = this.addReportData(options, reports);
    }

    const newOptions = options.map((requestExam) => {
      if (
        requestExam.requestExamState ===
        REQUEST_EXAM_STATE_ID_BY_NAME["AGUARDANDO_RECEBIMENTO"]
      ) {
        requestExam.requestExamState =
          REQUEST_EXAM_STATE_ID_BY_NAME["RECEBIDO_EM_ANDAMENTO"];
      }

      return requestExam;
    });

    this.setState((state) =>
      update(state, {
        fields: {
          options: { $set: newOptions },
          vetSignerId: { $set: vetSignerId },
          chemilObs: { $set: options[0].obs },
        },
        shouldSendEmail: {
          $set: false,
        },
      })
    );
  }

  changeField(idx, subfield, value) {
    this.setState((state) =>
      update(state, {
        fields: {
          ["options"]: {
            [idx]: {
              [subfield]: { $set: value },
            },
          },
        },
      })
    );
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes sample code state
   *
   * @param {integer} idx The index of the request sample
   * in the request sample state array
   * @param {subfield} subfield
   * @param {mixed} value The new value
   *
   */
  multiFieldResultChange(field, value) {
    const infoArray = field.split("-");
    const idx = infoArray[1];
    const subfield = infoArray[2];
    this.changeField(idx, subfield, value);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes sample code state
   *
   */
  resultChange(field, value) {
    const idx = field.replace("result-", "");
    const subfield = "result";
    this.changeField(idx, subfield, value);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes request status id
   *
   */
  stateChange(field, value) {
    const idx = field.replace("result-", "");
    const subfield = "requestExamState";
    this.changeField(idx, subfield, value);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Activates state that redirects to list
   *
   */
  redirectToList() {
    this.setState((state) => update(state, { redirectToList: { $set: true } }));
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChange(field, value) {
    this.setState((state) =>
      update(state, { fields: { [field]: { $set: value } } })
    );
  }

  getVetSignerId(reports) {
    return reports.length > 0 ? reports[0].vetSignerId : 0;
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  /**
   *
   * @author Alessandro Bastos Grandini
   *
   * Submits hemogram report form data
   *
   * @param {Object} event The submit event
   *
   * @return {boolean}
   *
   */
  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const { options, chemilObs } = this.state.fields;
    const completeOptions = options.map((option) => {
      option.vetSignerId = this.state.fields.vetSignerId;
      option.obs = chemilObs;
      return option;
    });

    const isUpdate = this.isUpdate();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    for (let i in completeOptions) {
      if (!this.state.completeValidation)
        completeOptions[i].result =
          completeOptions[i].result === "" || completeOptions[i].result == 0
            ? null
            : completeOptions[i].result;
      if (requestExamState > 0)
        completeOptions[i].requestExamState = requestExamState;
    }
    completeOptions.parcialSave = !this.state.completeValidation;
    if (cancel) {
      this.props.cancelReport(completeOptions, then);
    } else if (stop) {
      this.props.stopReport(completeOptions, then);
    } else {
      this.props.postReport(completeOptions, release, then);
    }
  };

  isUpdate() {
    const options = this.state.fields.options;

    for (const option of options) {
      if (option.id > 0) {
        return true;
      }
    }

    return false;
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Sorts report header promise into
   * a data object
   *
   * @return {Object}
   *
   */
  examTableHeaderData() {
    const { examTableHeaderData } = this.props;

    return examTableHeaderData.fulfilled ? examTableHeaderData.value : null;
  }

  requestExamStatesData(requestExamStates) {
    return requestExamStates && requestExamStates.fulfilled
      ? requestExamStates.value
      : [];
  }

  shouldDisplayLoader() {
    return false;
  }

  render() {
    const { examRequestId, requestExamId } = this.props.match.params;
    const backLink = "/solicitacoes-exame/" + examRequestId;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    const { title, submitPromise, requestExamStates } = this.props;

    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      successNotificatio: this.successNotification,
      invalidNotification: this.invalidNotification,
      resultChange: this.resultChange,
      stateChange: this.stateChange,
      handleSendReportEmail: this.handleSendReportEmail,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      cancelReport: this.cancelReport,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      stopReport: this.stopReport,
    };

    const examTableHeaderData = this.examTableHeaderData();

    const data = {
      title: title,
      text: this.state.text,
      backLink: backLink,
      fields: this.state.fields,
      submitPromise: submitPromise,
      examRequestId: this.props.match.params.examRequestId,
      disableAll: submitPromise !== undefined && submitPromise.pending,
      isUpdate: this.isUpdate(),
      examTableHeaderData: examTableHeaderFactory(examTableHeaderData),
      requestExamStates: this.requestExamStatesData(requestExamStates),
      shouldSendEmail: this.state.shouldSendEmail,
      completeValidation: this.state.completeValidation,
      requestExamId: requestExamId,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(connectWithEndpoint)(
  DefaultExamTableReportForm
);
