import React, { useEffect, useState } from "react";
import { SELECT_TEXT } from "../../../../../assets/js/Consts.js";
import * as LifeVet from "../../../../../components";
import { RequestExam } from "../../../../../types/index";

export const ExamSelect = ({
  data,
  setExam,
  disable,
  fetchedExams,
}: {
  data: any;
  setExam: any;
  disable: boolean;
  fetchedExams: any;
}) => {
  const defaultRef: any = null;
  const [ref, setRef] = useState(defaultRef);
  let options: any = fetchedExams.fulfilled ? fetchedExams.data : [];

  useEffect(() => {
    if (fetchedExams.fulfilled) ref && ref.focus();
  }, [fetchedExams.fulfilled]);

  return (
    <LifeVet.FormGroup>
      <LifeVet.InputLabel>Exame</LifeVet.InputLabel>

      <LifeVet.Select
        options={options}
        value={data}
        searchable={true}
        clearable={true}
        closeOnSelect={false}
        multi={true}
        disabled={fetchedExams.pending || disable}
        placeholder={
          fetchedExams.pending ? SELECT_TEXT.LOADING : SELECT_TEXT.ACTIVE
        }
        onChange={(option:any) => {
          setExam(option);
        }}
        setRef={(ref) => setRef(ref)}
        tabSelectsValue={false}
        onKeyDown
      />
    </LifeVet.FormGroup>
  );
};
