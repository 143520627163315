import {
  getBiochemistryTableObjs,
  calculateBilirubinIndirect,
  calculateIonicCalcium,
} from "../../../assets/js/reportFunctions";
import { EXAM_TYPES } from "../../../consts";
import {
  EXAM_ID_BY_NAME,
  EXAM_NAME_BY_ID,
  isSimpleResultBiochemistry,
  profileStructure,
} from "../../../consts/exams";
import { calculateGlobulin } from "../../../hooks/hormone/examFunctions/biochemistryMethods";

export const biochemistyDefaultState = (examId) => {
  const biochemistrysIds = profileStructure[examId].biochemistrysIds;
  const fields = biochemistrysIds.reduce((acc, id) => {
    const fieldName = EXAM_NAME_BY_ID[id];
    return { acc, [fieldName]: "" };
  }, {});
  return { fields, biochemObs: "" };
};

export const biochemistryStateFactoryAPI = (examId, obj) => {
  const biochemistrysIds = profileStructure[examId].biochemistrysIds;
  const state = biochemistrysIds.reduce((acc, id) => {
    const fieldName = EXAM_NAME_BY_ID[id];
    return { ...acc, [fieldName]: obj[fieldName] };
  }, {});
  return {
    ...state,
    biochemObs: obj.biochemistryObs,
    totalProteinAndFractionsAlbumin: obj.totalProteinAndFractionsAlbumin,
    totalProteinAndFractionsTotalProtein:
      obj.totalProteinAndFractionsTotalProtein,
    totalProteinAndFractionsGlobulin: obj.totalProteinAndFractionsGlobulin,
    bilirubinAndFractionsTotal: obj.bilirubinAndFractionsTotal,
    bilirubinAndFractionsDirect: obj.bilirubinAndFractionsDirect,
    bilirubinAndFractionsIndirect: obj.bilirubinAndFractionsIndirect,
    isIonicCalciumEditable: obj.isIonicCalciumEditable,
    ionicCalciumIonicCalcium: obj.isIonicCalciumEditable
          ? obj.ionicCalciumIonicCalcium
          : calculateIonicCalcium(
              obj.ionicCalciumCalcium,
              obj.ionicCalciumAlbumin,
              obj.ionicCalciumTotalProtein
            ),
    ionicCalciumCalcium: obj.ionicCalciumCalcium,
    ionicCalciumAlbumin: obj.ionicCalciumAlbumin,
    ionicCalciumTotalProtein: obj.ionicCalciumTotalProtein,
    totalProtein: obj.totalProtein,
    relationAlbuminGlobulinResult: obj.relationAlbuminGlobulinResult,
    relationAlbuminGlobulinAlbumin: obj.relationAlbuminGlobulinAlbumin,
    relationAlbuminGlobulinGlobulin: obj.relationAlbuminGlobulinGlobulin
  };
};

export const biochemistryFieldsToApiObjParser = (examId, fields) => {
  const biochemistrysIds = profileStructure[examId].biochemistrysIds;
  const biochemistryFields = biochemistrysIds.reduce((acc, id) => {
    const fieldName = EXAM_NAME_BY_ID[id];
    return { ...acc, [fieldName]: fields[fieldName] };
  }, {});
  return {
    ...biochemistryFields,
    biochemistryObs: fields.biochemObs,
    totalProteinAndFractionsAlbumin: fields.totalProteinAndFractionsAlbumin,
    totalProteinAndFractionsTotalProtein:
      fields.totalProteinAndFractionsTotalProtein,
    totalProteinAndFractionsGlobulin: fields.totalProteinAndFractionsGlobulin,
    bilirubinAndFractionsTotal: fields.bilirubinAndFractionsTotal,
    bilirubinAndFractionsDirect: fields.bilirubinAndFractionsDirect,
    bilirubinAndFractionsIndirect: fields.bilirubinAndFractionsIndirect,
    ionicCalciumIonicCalcium: fields.isIonicCalciumEditable
          ? fields.ionicCalciumIonicCalcium
          : calculateIonicCalcium(
              fields.ionicCalciumCalcium,
              fields.ionicCalciumAlbumin,
              fields.ionicCalciumTotalProtein
            ),
    isIonicCalciumEditable: fields.isIonicCalciumEditable,
    ionicCalcium: fields.ionicCalcium,
    ionicCalciumCalcium: fields.ionicCalciumCalcium,
    ionicCalciumAlbumin: fields.ionicCalciumAlbumin,
    ionicCalciumTotalProtein: fields.ionicCalciumTotalProtein,
    totalProtein: fields.totalProtein,
    relationAlbuminGlobulinResult: fields.relationAlbuminGlobulinResult,
    relationAlbuminGlobulinAlbumin: fields.relationAlbuminGlobulinAlbumin,
    relationAlbuminGlobulinGlobulin: fields.relationAlbuminGlobulinGlobulin
  };
};

export const biochemistryTableRowsState = (examId, fields, refs) => {
  const biochemistrysIds = profileStructure[examId].biochemistrysIds;
  const biochemistryValues = biochemistrysIds.reduce(
    (acc, id) => [...acc, biochemistryStateRowByExamId(id, fields)],
    []
  );
  return getBiochemistryTableObjs(biochemistryValues, refs);
};

export const biochemistryFieldsNames = (examId) => {
  const biochemistrysIds = profileStructure[examId].biochemistrysIds;
  return biochemistrysIds.map((id) => EXAM_NAME_BY_ID[id]);
};

const biochemistryStateRowByExamId = (id, fields) => {
  switch (id) {
    case EXAM_ID_BY_NAME["RELATION_ALBUMIN_GLOBULIN"]:
      return {
        id: EXAM_ID_BY_NAME["RELATION_ALBUMIN_GLOBULIN"],
        relationAlbuminGlobulinResult: (fields.relationAlbuminGlobulinAlbumin / fields.relationAlbuminGlobulinGlobulin) || 0,
        relationAlbuminGlobulinAlbumin: fields.relationAlbuminGlobulinAlbumin ||0 ,
        relationAlbuminGlobulinGlobulin: fields.relationAlbuminGlobulinGlobulin|| 0 
      };
    case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]:

   // * Fixing bug with bilirubin and fractions (not sending indirect value to server)
      fields.bilirubinAndFractionsIndirect = calculateBilirubinIndirect(
        fields.bilirubinAndFractionsTotal,
        fields.bilirubinAndFractionsDirect
      );
   // *
      return {
        id: EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"],
        bilirubinAndFractionsTotal: fields.bilirubinAndFractionsTotal,
        bilirubinAndFractionsDirect: fields.bilirubinAndFractionsDirect,
        bilirubinAndFractionsIndirect: calculateBilirubinIndirect(
          fields.bilirubinAndFractionsTotal,
          fields.bilirubinAndFractionsDirect
        ),
      };

    case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]:
      return {
        id: EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"],
        totalProteinAndFractionsTotalProtein:
          fields.totalProteinAndFractionsTotalProtein,
        totalProteinAndFractionsAlbumin: fields.totalProteinAndFractionsAlbumin,
        totalProteinAndFractionsGlobulin: calculateGlobulin(
          fields.totalProteinAndFractionsTotalProtein,
          fields.totalProteinAndFractionsAlbumin
        ),
      };
    case EXAM_ID_BY_NAME["IONIC_CALCIUM"]:
      return {
        id: EXAM_ID_BY_NAME["IONIC_CALCIUM"],
        ionicCalciumCalcium: fields.ionicCalciumCalcium,
        ionicCalciumAlbumin: fields.ionicCalciumAlbumin,
        ionicCalciumTotalProtein: fields.ionicCalciumTotalProtein,
        isIonicCalciumEditable: fields.isIonicCalciumEditable,
        ionicCalciumIonicCalcium: fields.isIonicCalciumEditable
          ? fields.ionicCalciumIonicCalcium
          : calculateIonicCalcium(
              fields.ionicCalciumCalcium,
              fields.ionicCalciumAlbumin,
              fields.ionicCalciumTotalProtein
            ),
        mustNotDisplayResultField: true,
      };
    default:
      const fieldName = EXAM_NAME_BY_ID[id];

      return {
        id,
        result: fields[fieldName],
      };
  }
};

export const biochemistryRequests = (requestExamId) => {
  return {
    biochemRefsPromise: `/reference-values/?requestExamId=${requestExamId}&examTypeId=${EXAM_TYPES.BIOCHEMISTRY}`,
  };
};
