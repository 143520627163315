const secondsToDismiss = 5;

export const DEFAULT_INVALID_MESSAGE = {
  title: "Atenção, dados incompletos!",
  message: "Verifique os destaques em vermelho.",
  level: "error",
  position: "tr",
  autoDismiss: secondsToDismiss
};

export const DEFAULT_ERROR_MESSAGE = {
  title: "Erro durante o processamento.",
  message: "Tente novamente e caso o erro persista contate o suporte.",
  level: "error",
  position: "tr",
  autoDismiss: secondsToDismiss
};

export const DEFAULT_SAVE_MESSAGE = {
  title: "Sucesso",
  message: "Dados salvos com successo!",
  level: "success",
  position: "tr",
  autoDismiss: secondsToDismiss
};

export const DEFAULT_ALREADY_INCLUDED_MESSAGE = {
  title: "Erro",
  message: "O valor informado já esta incluído",
  level: "error",
  position: "tr",
  autoDismiss: secondsToDismiss
};

export const DEFAULT_DELETE_SUCCESS_MESSAGE = {
  title: "Sucesso",
  message: "Registro(s) removido com sucesso!",
  level: "success",
  position: "tr",
  autoDismiss: secondsToDismiss
};
