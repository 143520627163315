export const initialData = {
  titles: {
    radioimmunoassayCortisol: "Radioimunoensaio Cortisol",
    radioimmunoassayCortisol4HAfterActhStimuli:
      "Radioimunoensaio Cortisol após estímulo ACTH",
    radioimmunoassayCortisol4HAfterDexamethasone:
      "Radioimunoensaio Cortisol 4h Após Dexametasona",
    radioimmunoassayCortisol8HAfterDexamethasone:
      "Radioimunoensaio 8h Após Dexametasona",
    radioimmunoassayErythropoetin: "Radioimunoensaio Eritropoetina",
    radioimmunoassayEstradiol: "Radioimunoensaio Estradiol",
    radioimmunoassayFreeT4: "Radioimunoensaio T4 Livre",
    radioimmunoassayHfs: "Radioimunoensaio HFS",
    radioimmunoassayHypersensitiveActh: "Radioimunoensaio ACTH Hipersensível",
    radioimmunoassayInsulin: "Radioimunoensaio Insulina",
    radioimmunoassayLevothyroxinAfterTotalT4:
      "Radioimunoensaio T4 Total Pós Levotiroxina",
    radioimmunoassayLh: "Radioimunoensaio LH",
    radioimmunoassayPhenobarbital: "Radioimunoensaio Fenobarbital",
    radioimmunoassayProgesterone: "Radioimunoensaio Progesterona",
    radioimmunoassayPth: "Radioimunoensaio PTH",
    radioimmunoassayTestosterone: "Radioimunoensaio Testosterona",
    radioimmunoassayTotalT3: "Radioimunoensaio T3 Total",
    radioimmunoassayTotalT4: "Radioimunoensaio T4 Total",
    radioimmunoassayTsh: "Radioimunoensaio TSH",
    radioimmunoassayFreeT4AfterDialysis: "T4 Total pós Diálise",
  },
  fields: {
    radioimmunoassayCortisolMin: "",
    radioimmunoassayCortisolMax: "",
    radioimmunoassayCortisolUnit: "",
    radioimmunoassayCortisol4HAfterActhStimuliMin: "",
    radioimmunoassayCortisol4HAfterActhStimuliMax: "",
    radioimmunoassayCortisol4HAfterActhStimuliUnit: "",
    radioimmunoassayCortisol4HAfterDexamethasoneMin: "",
    radioimmunoassayCortisol4HAfterDexamethasoneMax: "",
    radioimmunoassayCortisol4HAfterDexamethasoneUnit: "",
    radioimmunoassayCortisol8HAfterDexamethasoneMin: "",
    radioimmunoassayCortisol8HAfterDexamethasoneMax: "",
    radioimmunoassayCortisol8HAfterDexamethasoneUnit: "",
    radioimmunoassayErythropoetinMin: "",
    radioimmunoassayErythropoetinMax: "",
    radioimmunoassayErythropoetinUnit: "",
    radioimmunoassayEstradiolMin: "",
    radioimmunoassayEstradiolMax: "",
    radioimmunoassayEstradiolUnit: "",
    radioimmunoassayFreeT4Min: "",
    radioimmunoassayFreeT4Max: "",
    radioimmunoassayFreeT4Unit: "",
    radioimmunoassayHfsMin: "",
    radioimmunoassayHfsMax: "",
    radioimmunoassayHfsUnit: "",
    radioimmunoassayHypersensitiveActhMin: "",
    radioimmunoassayHypersensitiveActhMax: "",
    radioimmunoassayHypersensitiveActhUnit: "",
    radioimmunoassayInsulinMin: "",
    radioimmunoassayInsulinMax: "",
    radioimmunoassayInsulinUnit: "",
    radioimmunoassayLevothyroxinAfterTotalT4Min: "",
    radioimmunoassayLevothyroxinAfterTotalT4Max: "",
    radioimmunoassayLevothyroxinAfterTotalT4Unit: "",
    radioimmunoassayLhMin: "",
    radioimmunoassayLhMax: "",
    radioimmunoassayLhUnit: "",
    radioimmunoassayPhenobarbitalMin: "",
    radioimmunoassayPhenobarbitalMax: "",
    radioimmunoassayPhenobarbitalUnit: "",
    radioimmunoassayProgesteroneMin: "",
    radioimmunoassayProgesteroneMax: "",
    radioimmunoassayProgesteroneUnit: "",
    radioimmunoassayPthMin: "",
    radioimmunoassayPthMax: "",
    radioimmunoassayPthUnit: "",
    radioimmunoassayTestosteroneMin: "",
    radioimmunoassayTestosteroneMax: "",
    radioimmunoassayTestosteroneUnit: "",
    radioimmunoassayTotalT3Min: "",
    radioimmunoassayTotalT3Max: "",
    radioimmunoassayTotalT3Unit: "",
    radioimmunoassayTotalT4Min: "",
    radioimmunoassayTotalT4Max: "",
    radioimmunoassayTotalT4Unit: "",
    radioimmunoassayTshMin: "",
    radioimmunoassayTshMax: "",
    radioimmunoassayTshUnit: "",
    radioimmunoassayFreeT4AfterDialysisMin: "",
    radioimmunoassayFreeT4AfterDialysisMax: "",
    radioimmunoassayFreeT4AfterDialysisUnit: "",
  },
};
