import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import { AGE } from "../../assets/js/Consts";
import TripleRadioMaterial from "../TripleRadioMaterial";
import NewTripleRadioMaterial from "../NewTripleRadioMaterial";

const defaultProps = {};
const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for dual radio with material design styling
 */
const AnimalAgeOptionRadioMaterial = ({
  disabled,
  fieldName,
  selectedValue,
  updateField,
  $field,
  required,
}) => {
  useEffect(() => {
    $field(fieldName, (selectedValue) => {
      updateField(fieldName, selectedValue);
    });
  }, []);

  return (
    <NewTripleRadioMaterial
      required={required}
      title={"Opções"}
      name={fieldName}
      disabled={disabled}
      selectedValue={selectedValue}
      firstLabel={"Não informado"}
      firstValue={AGE.N}
      secondLabel={"Adulto"}
      secondValue={AGE.A}
      thirdLabel={"Inativo"}
      thirdValue={AGE.I}
      {...$field(fieldName, (value) => {
        updateField(fieldName, value);
      })}
    />
  );
};

AnimalAgeOptionRadioMaterial.defaultProps = defaultProps;
AnimalAgeOptionRadioMaterial.propTypes = propTypes;

export default React.memo(AnimalAgeOptionRadioMaterial);
