import React, { useEffect, useRef, useState } from "react";
import * as LifeVet from "./../../../../../components";
import { ExamSelect, ExamTypeSelect } from "./../index";
import { useFetch, ROUTES } from "../../../../../hooks/use-fetch";

export const ExamsSelection = ({
  examType,
  exam,
  setExamType,
  setExam,
  addExams,
  disableExamSelect,
  disableAll,
  customerId,
}: {
  examType: any;
  exam: any;
  setExamType: any;
  setExam: any;
  addExams: any;
  disableExamSelect: boolean;
  disableAll: boolean;
  customerId: number;
}) => {
  const fetchedExamTypes = useFetch();
  const fetchedExams = useFetch();

  useEffect(() => {
    fetchedExamTypes.fetch(ROUTES.getExamTypes());
  }, []);

  useEffect(() => {
    examType.id &&
      fetchedExams.fetch(
        ROUTES.getLabExamsByCustomerAndExamType(customerId, examType.id)
      );
  }, [examType]);

  return (
    <LifeVet.Row>
      <LifeVet.Col sm={3}>
        <ExamTypeSelect
          data={examType}
          setExamType={setExamType}
          disable={disableAll}
          fetchedExamTypes={fetchedExamTypes}
        />
      </LifeVet.Col>
      <LifeVet.Col sm={6}>
        <ExamSelect
          data={exam}
          setExam={setExam}
          disable={disableExamSelect || disableAll}
          fetchedExams={fetchedExams}
        />
      </LifeVet.Col>
      <LifeVet.Col sm={3}>
        <LifeVet.Button
          disabled={disableAll}
          className={"mt-15"}
          onClick={() => addExams(exam)}
        >
          Adicionar Exames
        </LifeVet.Button>
      </LifeVet.Col>
    </LifeVet.Row>
  );
};
