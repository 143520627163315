import React from "react";

/**
 * Internal
 */
import { unityValidation } from "./../UnityForm/validation";
import RegionsFields from "./RegionsFields";
import UnityFields from "./UnityFields";
import UsersFields from "./UsersFields";
import EmailsFields from "./EmailsFields";
import PhonesFields from "./PhonesFields";
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import PanelMaterialBottom from "./../PanelMaterialBottom";
import ButtonBackMaterial from "./../ButtonBackMaterial";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";

/**
 * External
 */
import { validated } from "react-custom-validation";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import ResponsibleFields from "./ResponsibleFields";

const UnityFormView = ({
  methods,
  $field,
  fields,
  data,
  $submit,
  $validation,
}) => {
  const onClickSave = function (handleSubmit, invalidNotification) {
    return (e) => {
      e.preventDefault();
      $submit(handleSubmit, invalidNotification);
    };
  };

  const saveBtn = (
    <ButtonSaveMaterial
      type="highlight"
      title={data.submit.btnTitle}
      right={true}
      onClick={onClickSave(methods.handleSubmit, methods.invalidNotification)}
      disabled={data.allDisabled}
      requesting={data.submit.pending}
      requestingTitle={data.submit.placeholder}
    />
  );

  const backBtnTop = (
    <ButtonBackMaterial
      type="default"
      title="Voltar"
      position="top"
      disabled={data.allDisabled}
    />
  );

  const backBtnBottom = (
    <ButtonBackMaterial
      type="default"
      title="Voltar"
      position="bottom"
      disabled={data.allDisabled}
    />
  );

  return (
    <React.Fragment>
      <PanelMaterial>
        <PanelMaterialTop title={data.submit.panelTitle}>
          <Link to="/unidades">{backBtnTop}</Link>
        </PanelMaterialTop>
        <PanelMaterialBody>
          <UnityFields
            methods={methods}
            fields={fields}
            data={data}
            $field={$field}
            $validation={$validation}
          />
          <Row>
            <hr className="mt-20 mb-20" />
          </Row>
          <Row>
            <EmailsFields methods={methods} fields={fields} data={data} />
            <PhonesFields methods={methods} fields={fields} data={data} />
          </Row>
          <Row>
            <hr className="mt-20 mb-20" />
          </Row>
          <Row>
            <UsersFields methods={methods} fields={fields} data={data} />
            <RegionsFields methods={methods} fields={fields} data={data} />
          </Row>
          <Row>
            <hr className="mt-20 mb-20" />
          </Row>
          <Row>
            <ResponsibleFields methods={methods} fields={fields} data={data} />
          </Row>
        </PanelMaterialBody>
        <PanelMaterialBottom>
          <Link to="/unidades">{backBtnBottom}</Link>
          {saveBtn}
        </PanelMaterialBottom>
      </PanelMaterial>
    </React.Fragment>
  );
};

const withValidation = validated(unityValidation);

export default withValidation(UnityFormView);
