import React from 'react';

/** Internal */
import FlatPanelMaterial from './../FlatPanelMaterial';
import ReportHeader from './../ReportHeader';
import DualRadioMaterial from './../DualRadioMaterial';
import InputMaterial from './../InputMaterial';
import SelectMaterial from './../SelectMaterial';
import { IMMUNOLOGY_EXAM_MATERIALS } from './../../assets/js/Consts';
import ReportPanelMaterial from '../ReportPanelMaterial';
import { Col, Row } from './../../components/grid';

const CanineBrucellosisFormView = ( { data, methods } ) =>
  <ReportPanelMaterial
    title='Brucelose Canina'
    backLink={ data.backLink }
    shouldDisable={ data.disableAll }
    isUpdate={ data.isUpdate }
    isRequesting={ data.submitData.pending }
    handleSubmit={ methods.onSubmit }
    handleSendReportEmail={ methods.handleSendReportEmail }
    shouldSendEmail={ data.shouldSendEmail } >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation} />
      </Col>
    </Row>
    <FlatPanelMaterial>
      <Row>
        <Col md={12}><h5><b>Método</b>: Nome do método</h5></Col>
        <Col md={12}><h5><b>Descritivo</b>: Descrição do exame</h5></Col>
      </Row>
    </FlatPanelMaterial>
    <FlatPanelMaterial title='Resultado'>
      <Row>
        <Col md={4}></Col>
        <SelectMaterial
          id="materialId"
          title="Material Enviado"
          md={4}
          hasValidationRecoil
          name="materialId"
          options={IMMUNOLOGY_EXAM_MATERIALS}
          value={data.fields.materialId}
          searchable={true}
          disabled={data.disableAll}
          {...methods.$field('materialId', (event) => {
            methods.fieldChange('materialId', event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.materialId.show &&
            !data.$validation.materialId.isValid
          }
          validationReason={
            data.$validation &&
            data.$validation.materialId.error.reason
          }
          isValid={
            data.$validation &&
            data.$validation.materialId.isValid
          } />
      </Row>
      <Row className="mb-10">
        <Col md={4}></Col>
        <DualRadioMaterial
          md={4}
          title='Resultado'
          name='result'
          selectedValue={data.fields.result}
          disabled={data.disableAll}
          firstLabel='Negativo'
          firstValue={0}
          secondLabel='Postivo'
          secondValue={1}
          {...methods.$field('result', (event) => {
            methods.fieldChange('result', event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.result.show &&
            !data.$validation.result.isValid
          }
          validationReason={
            data.$validation &&
            data.$validation.result.error.reason
          }
          isValid={
            data.$validation &&
            data.$validation.result.isValid
          } />
      </Row>
    </FlatPanelMaterial>
    <FlatPanelMaterial title='Diagnóstico'>
      <Row>
        <Col xs={1} className='text-right'>
          <p className='mr-10 cleanItem'>OBS</p>
        </Col>
        <Col xs={11}>
          <InputMaterial
            name='obs'
            md={12}
            disabled={data.disableAll}
            value={data.fields.obs}
            fit={true}
            onChange={event => methods.fieldChange('obs', event.target.value)} />
        </Col>
      </Row>
    </FlatPanelMaterial>
  </ReportPanelMaterial>

export default CanineBrucellosisFormView;