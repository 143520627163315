import connect from "../../api-connector";

const connectWithEndpoint = connect(props => ({
  outsourcedExams: `/outsourced-exams?is_outsourced=1`,
  outsourcedExamsSearch: (filters, page, sorted, then) => ({
    outsourcedExamseeds: {
      url: `/outsourced-exams?id=${filters.id}&name=${filters.name}&examType=${filters.examType}&page=${page}&sort=${sorted[0].id}&desc=${sorted[0].desc}&is_outsourced=1`,
      method: "GET",
      then: then,
      force: true
    }
  })
}));

export default connectWithEndpoint;
