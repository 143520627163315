import React from 'react';

/** Internal */
import SelectMaterial from './../SelectMaterial';
import LVInputTextUpper from './../../old_components/LVInputTextUpper';
import {
  CNPJ_MASK,
  CEP_MASK,
  STATUS
} from '../../assets/js/Consts';

/** External */
import { Row } from 'react-bootstrap';

const UnityFields = ({ 
  fields, 
  methods,
  data, 
  $field, 
  $validation 
}) => {
  
  const nameInput = <LVInputTextUpper
    id="name"
    name="name"
    required
    autofocus={ true }
    title="Nome"
    md={ 6 }
    value={fields.name || ''}
    disabled={data.allDisabled || data.shouldDisableAll}

    { ...$field('name', (event) => {
      methods.fieldChange('name', event.target.value);
    })}
    hasValidation={
      $validation && 
      $validation.name.show && 
      !$validation.name.isValid
    }
    validationReason={
      $validation &&
      $validation.name.error.reason
    }
  />;

  const cnpjInput = <LVInputTextUpper
    id="cnpj"
    name="cnpj"
    title="CNPJ"
    required
    
    md={3}
    value={fields.cnpj}
    disabled={data.allDisabled || data.shouldDisableAll}
    mask
    maskValue={CNPJ_MASK}

    {...$field('cnpj', (event) => {
      methods.fieldChange('cnpj', event.target.value);
    })}
    hasValidation={
      $validation &&
      $validation.cnpj.show &&
      !$validation.cnpj.isValid
    }
    validationReason={
      $validation &&
      $validation.cnpj.error.reason
    }
  />;

  const statusSelect = <SelectMaterial
    title="Status"
    md={3}
    name="status"
    options={ STATUS }
    value={ fields.status }
    searchable={ true }
    disabled={data.allDisabled || data.shouldDisableAll}
    { ...$field('status', ( event ) => {
      methods.fieldChange( 'status', event );
    } ) }
  />;

  const stateSelect = <SelectMaterial
    title="Estado"
    required
    md={4}
    name="state"
    hasValidationRecoil={ true }
    options={ data.states.value ? data.states.value : [] }
    value={fields.stateId}
    searchable={true}
    disabled={data.allDisabled || data.shouldDisableAll}
    placeholder={data.states.pending ? 'Carregando..' : 'Selecione'}
    {...$field('stateId', (event) => {
      methods.loadCities(event);
      methods.fieldChange('stateId', event);
    })}
    hasValidation={
      $validation &&
      $validation.stateId.show &&
      !$validation.stateId.isValid
    }
    validationReason={
      $validation &&
      $validation.stateId.error.reason
    }
    isValid={
      $validation &&
      $validation.stateId.isValid
    }
  />;

  const citySelect = <SelectMaterial
    title="Cidade"
    required
    md={4}
    name="city"
    hasValidationRecoil={ true }
    options={data.cities && data.cities.value ? data.cities.value : []}
    value={fields.cityId}
    searchable={true}
    disabled={data.allDisabled || (fields.stateId === 0) || data.shouldDisableAll }
    {...$field('cityId', (event) => {
      methods.fieldChange('cityId', event);
    })}
    hasValidation={
      $validation &&
      $validation.cityId.show &&
      !$validation.cityId.isValid
    }
    validationReason={
      $validation &&
      $validation.cityId.error.reason
    }
    isValid={
      $validation &&
      $validation.cityId.isValid
    }
  />;

  const neighborhoodInput = <LVInputTextUpper
    id="neighborhood"
    title="Bairro"
    required
    name="neighborhood"
    md={4}
    value={fields.neighborhood}
    disabled={data.allDisabled || data.shouldDisableAll}
    {...$field('neighborhood', (event) => {
      methods.fieldChange('neighborhood', event.target.value);
    })}
    hasValidation={
      $validation &&
      $validation.neighborhood.show &&
      !$validation.neighborhood.isValid
    }
    validationReason={
      $validation &&
      $validation.neighborhood.error.reason
    }
  />;

  const cepInput = <LVInputTextUpper
    id="cep"
    title="CEP"
    name="cep"
    required
    md={3}
    value={fields.cep}
    disabled={data.allDisabled || data.shouldDisableAll}
    mask
    maskValue={ CEP_MASK }
    {...$field('cep', (event) => {
      methods.fieldChange('cep', event.target.value);
    })}
    hasValidation={
      $validation &&
      $validation.cep.show &&
      !$validation.cep.isValid
    }
    validationReason={
      $validation &&
      $validation.cep.error.reason
    }
  />;

  const publicPlaceInput = <LVInputTextUpper
    id="publicPlace"
    title="Logradouro"
    required
    name="publicPlace"
    md={3}
    value={fields.publicPlace}
    disabled={data.allDisabled || data.shouldDisableAll}
    {...$field('publicPlace', (event) => {
      methods.fieldChange('publicPlace', event.target.value);
    })}
  />;

  const numberInput = <LVInputTextUpper
    id="number"
    title="Número"
    required
    name="number"
    md={2}
    value={fields.number}
    disabled={data.allDisabled || data.shouldDisableAll}
    {...$field('number', (event) => {
      methods.fieldChange('number', event.target.value);
    })}
    hasValidation={
      $validation &&
      $validation.number.show &&
      !$validation.number.isValid
    }
    validationReason={
      $validation &&
      $validation.number.error.reason
    }
  />;

  const complementInput = <LVInputTextUpper
    id="complement"
    title="Complemento"
    name="complement"
    md={4}
    value={fields.complement}
    disabled={data.allDisabled || data.shouldDisableAll}
    {...$field('complement', (event) => {
      methods.fieldChange('complement', event.target.value);
    })}
    hasValidation={
      $validation &&
      $validation.complement.show &&
      !$validation.complement.isValid
    }
    validationReason={
      $validation &&
      $validation.complement.error.reason
    }
  />;

  const profile = <React.Fragment>
    { nameInput }
    { cnpjInput }
    { statusSelect }
  </React.Fragment>;

  const address = <React.Fragment>
    { stateSelect }
    { citySelect }
    { neighborhoodInput }
    { cepInput }
    { publicPlaceInput }
    { numberInput }
    { complementInput }
  </React.Fragment>;

  return (
    <React.Fragment>
      <Row> { profile } </Row>
      <Row> <hr/> </Row>
      <Row> { address } </Row>
    </React.Fragment>
  );
}

export default UnityFields;