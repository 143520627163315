import React, { useEffect } from "react";

/**
 * External
 */
import { Row, Col } from "./../../components/grid";

/**
 * Internal
 */
import InputMaterial from "./../InputMaterial";

/**
 * @author Alessandro Bastos Grandini
 *
 *  Erythrogram diagnosis fields
 *
 */
const ErythrogramDiagnosisContent = ({
  obs,
  diagnosisFn,
  shouldDisable,
  $validation,
  fieldChange,
  $field,
  diagnosis,
  diagnosisInput,
}) => {
  useEffect(() => {
    fieldChange("erythrogramDiagnosis", diagnosisFn());
    // console.debug("*** DiagnosisFN", diagnosisFn());
  }, [diagnosisFn()]);

  useEffect(() => {
    fieldChange("erythrogramDiagnosis", diagnosis);
    // console.debug("*** DiagnosisFN", diagnosis);
  }, []);
  return (
    <React.Fragment>
      <Row>
        <Col xs={1} className="text-right">
          <p className="mr-10 cleanItem">S.V</p>
        </Col>
        <Col xs={11}>
          <InputMaterial
            name="erythrogramDiagnosis"
            md={12}
            fit={true}
            value={diagnosisInput || diagnosis || diagnosisFn()}
            {...$field("erythrogramDiagnosis", (event) => {
              fieldChange("erythrogramDiagnosis", event.target.value);
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={1} className="text-right">
          <p className="mr-10 cleanItem">OBS</p>
        </Col>
        <Col xs={11}>
          <InputMaterial
            name="erythrogramObs"
            md={12}
            fit={true}
            value={obs}
            disabled={shouldDisable}
            {...$field("erythrogramObs", (event) => {
              fieldChange("erythrogramObs", event.target.value);
            })}
            hasValidation={$validation.erythrogramObs.show}
            validationReason={$validation.erythrogramObs.error.reason}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};
ErythrogramDiagnosisContent.propTypes = {};

export default ErythrogramDiagnosisContent;
