import connect from "../../api-connector";

const connectWithEndpoint = connect(props => ({
  getExamsRequest: (query, then) => ({
    examsRequest: {
      url: `/exam-requests/filter/lab/` + props.userLabId + `${query}`,
      method: "GET",
      then: then,
      force: true
    }
  }),
  exams: "/exams",
  requestExamStates: "/exam-request-states",
  sexs: "/sexs",
  species: "/species",
  customers: "/customers/labs/" + props.userLabId
}));

export default connectWithEndpoint;
