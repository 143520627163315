import React, { useEffect, useState } from "react";
import __update from "immutability-helper";
import { Animal, Request, Customer, Validable } from "./../../types/index";
import { ObjectSchema, Shape, ValidationError } from "yup";
import { cloneDeep } from "lodash";

export const useValidatedForm = <T extends {}>(
  data: T,
  resetState: any,
  schema: ObjectSchema<Shape<object, any>>
) => {
  const defaultValue: any = {};
  const [errors, setErrors] = useState(defaultValue);
  const [touched, _setTouched] = useState(defaultValue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setError = (path: string, value: string) =>
    setErrors(setProp(cloneDeep(errors), path, value));

  const unsetError = (path: string) =>
    setErrors(unsetProp(cloneDeep(errors), path));

  const setTouched = (path: string) => {
    _setTouched(setProp(cloneDeep(touched), path, true));
  };

  const unsetTouched = (path: string) => {
    _setTouched(unsetProp(cloneDeep(touched), path));
  };

  const handleBlur = (path: string) => {
    setTouched(path);
    schema
      .validateAt(path, data)
      .then(() => {
        unsetError(path);
      })
      .catch((error: ValidationError) => {
        setError(error.path, error.message);
      });
  };

  const validate = (onSuccess: any, onError: any) => {
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        setErrors({});
        _setTouched({});
        onSuccess();
      })
      .catch(onError);
  };

  const validateField = (path: string) => {
    schema
      .validateAt(path, data)
      .then(() => {
        unsetError(path);
      })
      .catch((error: ValidationError) => {
        setError(error.path, error.message);
      });
  };

  const onValidationError = (catchedErrors: any) => {
    setIsSubmitting(false);
    let newErrors = cloneDeep(errors);
    let newTouched = cloneDeep(touched);
    // console.log("catchedErrros", catchedErrors);
    for (let error of catchedErrors.inner) {
      newErrors = setProp(newErrors, error.path, error.message);
      newTouched = setProp(newTouched, error.path, true);
    }
    setErrors(newErrors);
    _setTouched(newTouched);
  };

  const setProp = (target: any, fullPath: string, value: string | boolean) => {
    const paths = fullPath.split(".");
    if (paths.length === 1) {
      target[paths[0]] = value;
    } else {
      let last = target;
      for (let i = 0; i < paths.length; i++) {
        let current;
        const path = paths[i];
        const moreItemsToCome = i + 1 < paths.length;
        current = moreItemsToCome ? {} : value;
        const groups = path.match(/^(\w+?)\[(\d+?)\]$/);
        if (groups) {
          const name = groups[1];
          const index = groups[2];
          const array: any[] = [];
          last[name] = array;
          last[name][index] = current;
          last = current;
        } else {
          last[path] = current;
          last = current;
        }
      }
    }
    return target;
  };

  const unsetProp = (target: any, fullPath: string) => {
    const paths = fullPath.split(".");
    if (paths.length === 1) {
      delete target[paths[0]];
    } else {
      let current = target;
      for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        const moreItemsToCome = i + 1 < paths.length;
        const groups = path.match(/^(\w+?)\[(\d+?)\]$/);
        if (moreItemsToCome) {
          if (groups) {
            const name = groups[1];
            const index = groups[2];
            current = current[name][index];
          } else {
            current = current[path];
          }
        } else {
          if (groups) {
            const name = groups[1];
            const index = groups[2];
            delete current[name][index];
          } else {
            delete current[path];
          }
        }
      }
    }
    return target;
  };

  const resetValidation = () => {
    setErrors({});
    _setTouched({});
  };

  const reset = () => {
    setErrors({});
    _setTouched({});
    resetState();
  };

  return {
    errors: errors,
    touched: touched,
    isSubmitting,
    setError,
    unsetError,
    setTouched,
    unsetTouched,
    handleBlur,
    reset,
    resetValidation,
    validate,
    validateField,
    onValidationError,
    setIsSubmitting,
  };
};
