import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import NumberInputMaterial2 from "./../../NumberInputMaterial2";
import SelectMaterial from "./../../SelectMaterial";
import { YES_OR_NO } from "../../../assets/js/Consts.js";
import { initialData } from "./initial-data";
import * as Lifevet from "./../../../components/index";

export default function HematologyReference({
  data,
  setField,
  extract,
  loading,
  loadInitialData,
}) {
  useEffect(() => {
    loadInitialData(initialData);
  }, []);

  function renderMinAndMaxFields() {
    const { titles } = initialData;

    return Object.keys(titles).map((key, index) => {
      let title = titles[key];

      return (
        <Row className="mt-20" key={index}>
          <Col md={3}>
            <p>{title}</p>
          </Col>

          <Col md={2}>
            <NumberInputMaterial2
              name={`${key}Min`}
              title={"Min"}
              inputGroupAfter=""
              inputGroup={true}
              hasValidationRecoil={true}
              decimalScale={1}
              fit={true}
              block={true}
              allowNegative={false}
              value={extract(data, `${key}Min`, "")}
              disabled={loading}
              shouldDisableThousandSeparator={false}
              handleChange={(field, value) => {
                setField(`${key}Min`, value);
              }}
            />
          </Col>

          <Col md={2}>
            <NumberInputMaterial2
              name={`${key}Max`}
              title={"Max"}
              inputGroupAfter=""
              inputGroup={true}
              hasValidationRecoil={true}
              decimalScale={1}
              fit={true}
              block={true}
              allowNegative={false}
              value={extract(data, `${key}Max`, "")}
              disabled={loading}
              shouldDisableThousandSeparator={false}
              handleChange={(field, value) => setField(`${key}Max`, value)}
            />
          </Col>

          {index === 25 && (
            <Col md={2}>
              <div className={"input-group d-block fit"}>
                <Lifevet.InputLabel style={{ fontWeight: "normal", lineHeight: 0 }}>
                  Unidade
                </Lifevet.InputLabel>

                <Lifevet.Input
                  className="form-control"
                  name={`${key}Unit`}
                  onChange={(a) => {
                    a.persist();

                    setField(`${key}Unit`, a.target.value);
                  }}
                  value={extract(data, `${key}Unit`, "")}
                  disabled={loading}
                />
              </div>
            </Col>
          )}
        </Row>
      );
    });
  }

  return (
    <div>
      {renderMinAndMaxFields()}
      <Row className="mt-20">
        <Col md={3}>
          <p>Porcentagem de Reticulócitos Normal</p>
        </Col>

        <Col md={2}>
          <NumberInputMaterial2
            name={"reticResultNormalVal"}
            title={""}
            inputGroupAfter=""
            inputGroup={true}
            hasValidationRecoil={true}
            decimalScale={1}
            fit={true}
            block={true}
            allowNegative={false}
            value={extract(data, "reticResultNormalVal", "")}
            disabled={loading}
            shouldDisableThousandSeparator={false}
            handleChange={(field, value) =>
              setField("reticResultNormalVal", value)
            }
          />
        </Col>
      </Row>

      <Row className="mt-20">
        <Col md={3}>
          <p>Valor de Reticulócitos Absoluto</p>
        </Col>

        <Col md={2}>
          <NumberInputMaterial2
            name={"reticAbsoluteNormalVal"}
            title={""}
            inputGroupAfter=""
            inputGroup={true}
            hasValidationRecoil={true}
            decimalScale={1}
            fit={true}
            block={true}
            allowNegative={false}
            value={extract(data, "reticAbsoluteNormalVal", "")}
            disabled={loading}
            shouldDisableThousandSeparator={false}
            handleChange={(field, value) =>
              setField("reticAbsoluteNormalVal", value)
            }
          />
        </Col>
      </Row>

      <Row className="mt-20">
        <Col md={3}>
          <p>Fator de Correção de Contagem de Reticulócitos</p>
        </Col>

        <Col md={2}>
          <NumberInputMaterial2
            name={"reticCountCorrectionFactor"}
            title={""}
            inputGroupAfter=""
            inputGroup={true}
            hasValidationRecoil={true}
            decimalScale={1}
            fit={true}
            block={true}
            allowNegative={false}
            value={extract(data, "reticCountCorrectionFactor", "")}
            disabled={loading}
            shouldDisableThousandSeparator={false}
            handleChange={(field, value) =>
              setField("reticCountCorrectionFactor", value)
            }
          />
        </Col>
      </Row>
      <Row className="mt-10">
        <Col md={3}>
          <p>Tem Heterófilos?</p>
        </Col>

        <Col md={2}>
          <SelectMaterial
            title=""
            md={12}
            name="hasHeterophils"
            options={YES_OR_NO}
            value={extract(data, "hasHeterophils", 0) ? 1 : 0}
            searchable={true}
            disabled={loading}
            onChange={(value) => {
              // console.log("value", value);
              setField("hasHeterophils", value);
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
