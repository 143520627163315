import React from "react";

import { SELECT_TEXT } from "../../../../../assets/js/Consts.js";
import * as LifeVet from "../../../../../components";

const VetSelect = ({
  isReadOnly,
  fields,
  disableAll,
  vetsPromise,
  updateField,
  $field,
  $validation,
  setRef,
}) => (
  <LifeVet.FormGroup
    errorMessage={$validation.vetId.show && $validation.vetId.error.reason}
  >
    <LifeVet.InputLabel>Médico Vet *</LifeVet.InputLabel>
    <LifeVet.Select
      id="vet_select"
      name="vetId"
      options={vetsPromise && vetsPromise.fulfilled ? vetsPromise.value : []}
      value={fields.vetId}
      searchable={true}
      clearable={true}
      disabled={
        fields.customerId === 0 ||
        (vetsPromise && vetsPromise.pending) ||
        disableAll ||
        isReadOnly
      }
      theValueIsAnId={true}
      placeholder={
        !vetsPromise
          ? ""
          : vetsPromise.pending
          ? SELECT_TEXT.LOADING
          : SELECT_TEXT.ACTIVE
      }
      {...$field("vetId", (value) => {
        updateField("vetId", value && value.id);
      })}
      setRef={setRef}
    />
  </LifeVet.FormGroup>
);

VetSelect.propTypes = {};

export default VetSelect;
