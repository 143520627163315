import React from 'react';

/**
 * Internal
 */
import TextAreaPanel2 from './../TextAreaPanel2';
import {MultiCheckbox} from "../../components/result-group";

/**
 * External
 */
import { Row, Col } from './../../components/grid';

const HemocytozoaResearchContent = ( {
  resultFieldName,
  result,
  resultHasValidation,
  resultValidationReason,
  obsFieldName,
  obs,
  obsHasValidation,
  obsValidationReason,
  handleChange,
  handleBlur,
  shouldDisable,
  handleResultChange,
  checkboxes,
  multiCheckboxChange,
  checkdState
} ) =>
  <React.Fragment>
    <Row>
      <Col md={ 12 }>
        <TextAreaPanel2
          title={ 'Resultado' }
          name={ resultFieldName }
          value={ result }
          disabled={ shouldDisable }
          handleChange={(name, value) => handleResultChange(value)  }
          handleBlur={ handleBlur }
          hasValidation={ resultHasValidation }
          validationReason={ resultValidationReason } />
          {checkboxes && 
            <MultiCheckbox
            itens={checkboxes}
            handleChange={multiCheckboxChange}
            state={checkdState}
            />
          }  
      </Col>
    </Row>
    { ( obs !== undefined ) && <Row>
      <Col md={ 12 }>
        <TextAreaPanel2
          title="Obs"
          name={ obsFieldName }
          value={ obs }
          disabled={ shouldDisable }
          handleChange={ handleChange }
          handleBlur={ handleBlur }
          hasValidation={ obsHasValidation }
          validationReason={ obsValidationReason } />
      </Col>
    </Row> }
  </React.Fragment>

export default HemocytozoaResearchContent;