import React, { Component } from "react";
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import ButtonSaveMaterial from "../ButtonSaveMaterial";
import PanelMaterialBottom from "./../PanelMaterialBottom";
import SelectMaterial from "./../SelectMaterial";
import connectWithEndpoint from "./requests.js";
import { FunctionUtil } from "./../../useful";
import {
  BTN_SAVE_TITLE,
  BTN_UPDATE_TITLE,
  BTN_SAVING_TITLE,
  BTN_UPDATING_TITLE,
  EXAM_REFERENCE_TYPES,
  EXAM_REFERENCE_TYPES_LIST,
} from "../../assets/js/Consts.js";
import { PromiseUtil } from "../../useful";
import ImmunologyReferenceForm from "./immunologyReferenceForm";
import BiochemistryReferenceForm from "./biochemistryReferenceForm";
import EndocrinologyChemiluminescenceForm from "./endocrinologyChemiluminescenceForm";
import EndocrinologyRadioimmunoassayForm from "./endocrinologyRadioimmunoassayForm";
import UrinalysisForm from "./urinalysisReferenceForm";
import HematologyForm from "./hematologyReferenceForm";
import ManifoldReferenceForm from "./manifoldReferenceForm";

/** External */
import update from "immutability-helper";
import { Row, Col } from "react-bootstrap";
import { useSpeciesAndReference } from "./useSpeciesAndReference";
import { months } from "moment";
import { BounceLoader } from "react-spinners";

const extractOrDefaultValue = (obj, key, dflt) => {
  return obj && obj[key] ? obj[key] : dflt;
};

const loading = () => {
  return <BounceLoader color="#00B4AD" />;
};

const selectExamTypeToRender = (examTypeId, references, referenceChange) => {
  const examProps = {
    loadInitialData: () => {},
    data: references,
    setField: referenceChange,
    extract: extractOrDefaultValue,
    loading: false,
  };

  switch (examTypeId) {
    case EXAM_REFERENCE_TYPES.HEMATOLOGY.id: {
      return <HematologyForm {...examProps} />;
    }
    case EXAM_REFERENCE_TYPES.IMMUNOLOGY.id: {
      return <ImmunologyReferenceForm {...examProps} />;
    }
    case EXAM_REFERENCE_TYPES.BIOCHEMISTRY.id: {
      return <BiochemistryReferenceForm {...examProps} />;
    }
    case EXAM_REFERENCE_TYPES.CHEMILUMINESCENCE.id: {
      return <EndocrinologyChemiluminescenceForm {...examProps} />;
    }
    case EXAM_REFERENCE_TYPES.RADIOIMMUNOASSAY.id: {
      return <EndocrinologyRadioimmunoassayForm {...examProps} />;
    }
    case EXAM_REFERENCE_TYPES.URINALYSIS.id: {
      return <UrinalysisForm {...examProps} />;
    }
    case EXAM_REFERENCE_TYPES.MANIFOLD.id: {
      return <ManifoldReferenceForm {...examProps} />;
    }
  }
};

const SpeciesAndReferenceView = (props) => {
  const {
    specieId,
    setSpecieId,
    references,
    examTypeId,
    setExamTypeId,
    setPeriodId,
    periods,
    periodId,
    referenceChange,
    handleSubmit,
    specieHasPeriod,
  } = useSpeciesAndReference(props);

  const SPECIES = props.species.pending ? [] : props.species.value;
  const submitRequesting = props.submitResponse && props.submitResponse.pending;
  const referenceRequesting = props.referenceValues && props.referenceValues.pending;
  const requesting = submitRequesting || referenceRequesting;

  return (
    <PanelMaterial>
      <PanelMaterialTop title={"Espécie e Valores Normais"} />
      <PanelMaterialBody>
        <Row>
          <SelectMaterial
            title="Espécie"
            required
            md={4}
            name="species"
            options={SPECIES}
            value={specieId}
            searchable={true}
            onChange={setSpecieId}
          />
          <SelectMaterial
            title="Tipo de Exame"
            required
            md={4}
            name="examType"
            options={EXAM_REFERENCE_TYPES_LIST}
            value={examTypeId}
            disabled={specieId === 0}
            searchable={true}
            onChange={setExamTypeId}
          />
          {specieHasPeriod(specieId, periods) && (
            <SelectMaterial
              title="Período"
              md={4}
              name="examType"
              options={periods}
              value={periodId}
              searchable={false}
              onChange={setPeriodId}
            />
          )}
        </Row>
        {requesting
          ? loading()
          : selectExamTypeToRender(examTypeId, references, referenceChange)}
      </PanelMaterialBody>
      <PanelMaterialBottom>
        <ButtonSaveMaterial
          type="highlight"
          title={BTN_SAVE_TITLE}
          right={true}
          onClick={handleSubmit}
          requesting={submitRequesting}
          disabled={requesting}
          requestingTitle={BTN_SAVING_TITLE}
        />
      </PanelMaterialBottom>
    </PanelMaterial>
  );
};

export default SpeciesAndReferenceView;
