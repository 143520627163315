import { EXAM_ID_BY_NAME, profileStructure } from "../../../consts/exams";
import {
  biochemistyDefaultState,
  biochemistryStateFactoryAPI,
  biochemistryFieldsToApiObjParser,
  biochemistryTableRowsState,
  biochemistryFieldsNames,
  biochemistryRequests,
} from "./biochemistryMethods";
import {
  easDefaultState,
  easFiedsNames,
  easFieldsToApiObjParser,
  easStateApiFactory,
  easValidationRules,
  easRequests,
  extractEasOptions,
} from "./EasMethods";
import {
  hemogramDefaultState,
  hemogramStateFactoryAPI,
  hemogramFieldsToApiObjParser,
} from "./hemogramMethods";

import {
  hemocytozoaDefaultState,
  hemocytozoaFieldsToApiObjParser,
  hemocytozoaValidationRules,
  hemocytozoaStateApiFactory,
  hemocytozoaFieldNames,
} from "./hemocytozoaMethods";

import {
  microfilariaeDefaultState,
  microfilariaeFieldNames,
  microfilariaeFieldsToApiObjParser,
  microfilariaeValidationRules,
  microfilariaeStateApiFactory,
} from "./microfilariaeMethods";

import {
  chemiluminescenceDefaultState,
  chemiluminescenceFieldsNames,
  chemiluminescenceFieldsToApiParser,
  chemiluminescenceRequests,
  chemiluminescenceTableRowsState,
  chemiluminescenceStateApiFactory,
} from "./chemiluminescenceMethods";
import {
  ehrlichiaDefaultState,
  ehrlichiaFieldNames,
  ehrlichiaFieldsToApiObjParser,
  ehrlichiaStateApiFactory,
  ehrlichiaValidationRules,
} from "./molecularBiology/ehrlichiaMethods";
import {
  babesiaDefaultState,
  babesiaFieldNames,
  babesiaFieldsToApiObjParser,
  babesiaStateApiFactory,
  babesiaValidationRules,
} from "./molecularBiology/babesiaMethods";
import {
  qualitativeAnaplasmDefaultState,
  qualitativeAnaplasmFieldNames,
  qualitativeAnaplasmFieldsToApiObjParser,
  qualitativeAnaplasmStateApiFactory,
  qualitativeAnaplasmValidationRules,
} from "./molecularBiology/qualitativeAnaplasmMethods.js";
import {
  leishmaniasisDefaultState,
  leishmaniasisFieldNames,
  leishmaniasisFieldsToApiObjParser,
  leishmaniasisStateApiFactory,
  leishmaniasisValidationRules,
} from "./molecularBiology/leishmaniasisMethods";
import {
  outsourcedDefaultState,
  outsourcedFieldsNames,
  outsourcedFieldsToApiParser,
  outsourcedStateApiFactory,
} from "./Outsourced/outsourcedMethods";
import {
  rpcuDefaultState,
  rpcuFieldNames,
  rpcuStateApiFactory,
  rpcuFieldsToApiObjParser,
  rpcuValidationRules,
  rpcuRequests,
} from "./RPCUMethods";
import {
  reticulocyteDefaultState,
  reticulocyteFieldNames,
  reticulocyteFieldsToApiObjParser,
  reticulocyteRequests,
  reticulocyteStateApiFactory,
  reticulocyteValidationRules,
} from "./reticulocyteMethods";
import {
  bloodProteinDefaultState,
  bloodProteinFieldNames,
  bloodProteinFieldsToApiObjParser,
  bloodProteinRequests,
  bloodProteinStateApiFactory,
  bloodProteinValidationRules,
} from "./bloodProteinMethods";

export const createProfileDefaultState = (profileId) => {
  const structure = profileStructure[profileId];
  const exams = structure.exams;
  const biochemistryState = structure.hasBiochemistry
    ? biochemistyDefaultState(profileId)
    : {};
  const chemiluminescenceState = structure.hasChemiluminescence
    ? biochemistyDefaultState(profileId)
    : {};
  const outsourcedState = structure.hasOutsourced
    ? outsourcedDefaultState(profileId)
    : {};
  const hemogramState = exams[EXAM_ID_BY_NAME.HEMOGRAM]
    ? hemogramDefaultState()
    : {};
  const easState = exams[EXAM_ID_BY_NAME.EAS] ? easDefaultState() : {};
  const hemocytozoaState = exams[EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]
    ? hemocytozoaDefaultState()
    : {};
  const microfilariaeState = exams[EXAM_ID_BY_NAME.MICROFILARIAE_RESEARCH]
    ? microfilariaeDefaultState()
    : {};

  const babesiaState = exams[EXAM_ID_BY_NAME.BABESIA]
    ? babesiaDefaultState()
    : [];
  const qualitativeAnaplasmState = exams[
    EXAM_ID_BY_NAME.QUALITATIVE_ANAPLASM_PCR_REAL_TIME
  ]
    ? qualitativeAnaplasmDefaultState()
    : [];
  // const fivState = exams[EXAM_ID_BY_NAME.QUALITATIVE_FIV]
  //   ? qualitativeFivDefaultState()
  //   : [];
  // const felvState = exams[EXAM_ID_BY_NAME.QUALITATIVE_FELV]
  //   ? qualitativeFelvDefaultState()
  //   : [];
  // const mycoplasmState = exams[EXAM_ID_BY_NAME.QUALITATIVE_MYCOPLASM]
  //   ? qualitativeMycoplasmDefaultState()
  //   : [];
  const leishmaniasisState = exams[EXAM_ID_BY_NAME.LEISHMANIOSE]
    ? leishmaniasisDefaultState()
    : [];
  const ehrlichiaState = exams[EXAM_ID_BY_NAME.EHRLICHIA]
    ? ehrlichiaDefaultState()
    : [];
  const rpcuState = exams[EXAM_ID_BY_NAME.PROTEIN_CREATININE_RATIO]
    ? rpcuDefaultState()
    : [];
  const reticulocyteState = exams[EXAM_ID_BY_NAME.RETICULOCYTE_COUNT]
    ? reticulocyteDefaultState()
    : [];
  const bloodProteinState = exams[EXAM_ID_BY_NAME.BLOOD_PROTEIN]
    ? bloodProteinDefaultState()
    : [];
  const state = {
    ...chemiluminescenceState,
    ...biochemistryState,
    ...hemogramState,
    ...easState,
    ...hemocytozoaState,
    ...microfilariaeState,
    ...ehrlichiaState,
    ...babesiaState,
    ...qualitativeAnaplasmState,
    ...rpcuState,
    ...reticulocyteState,
    ...bloodProteinState,
    ...leishmaniasisState,
  };
  return state;
};

export const tableRowsState = (examId, fields, refs) => {
  const data = biochemistryTableRowsState(examId, fields, refs);
  return data
};

export const chemiluminescenceRowsState = (examId, fields, refs) => {
  return chemiluminescenceTableRowsState(examId, fields, refs);
};

export const apiToFieldsStateParser = (examId, obj) => {
  const structure = profileStructure[examId];
  const exams = structure.exams;
  const biochemistryState = structure.hasBiochemistry
    ? biochemistryStateFactoryAPI(examId, obj)
    : {};
  const outsourcedState = structure.hasOutsourced
    ? outsourcedStateApiFactory(examId, obj)
    : {};
  const chemiluminescenceState = structure.hasChemiluminescence
    ? chemiluminescenceStateApiFactory(examId, obj)
    : {};
  const hemogramState = exams[EXAM_ID_BY_NAME.HEMOGRAM]
    ? hemogramStateFactoryAPI(obj)
    : {};
  const easState = exams[EXAM_ID_BY_NAME.EAS] ? easStateApiFactory(obj) : {};

  const hemocytozoaState = exams[EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]
    ? hemocytozoaStateApiFactory(obj)
    : {};
  const microfilariaeState = exams[EXAM_ID_BY_NAME.MICROFILARIAE_RESEARCH]
    ? microfilariaeStateApiFactory(obj)
    : {};

  const ehrlichiaState = exams[EXAM_ID_BY_NAME.EHRLICHIA]
    ? ehrlichiaStateApiFactory(obj)
    : {};
  const babesiaState = exams[EXAM_ID_BY_NAME.BABESIA]
    ? babesiaStateApiFactory(obj)
    : {};
  const qualitativeAnaplasmState = exams[
    EXAM_ID_BY_NAME.QUALITATIVE_ANAPLASM_PCR_REAL_TIME
  ]
    ? qualitativeAnaplasmStateApiFactory(obj)
    : {};
  const leishmaniasisState = exams[EXAM_ID_BY_NAME.LEISHMANIOSE]
    ? leishmaniasisStateApiFactory(obj)
    : {};
  const rpcuState = exams[EXAM_ID_BY_NAME.PROTEIN_CREATININE_RATIO]
    ? rpcuStateApiFactory(obj)
    : {};
  const reticulocyteState = exams[EXAM_ID_BY_NAME.RETICULOCYTE_COUNT]
    ? reticulocyteStateApiFactory(obj)
    : {};
  const bloodProteinState = exams[EXAM_ID_BY_NAME.BLOOD_PROTEIN]
    ? bloodProteinStateApiFactory(obj)
    : {};
  return {
    ...biochemistryState,
    ...hemogramState,
    ...easState,
    ...hemocytozoaState,
    ...microfilariaeState,
    ...chemiluminescenceState,
    ...ehrlichiaState,
    ...babesiaState,
    ...rpcuState,
    ...reticulocyteState,
    ...bloodProteinState,
    ...leishmaniasisState,
    ...qualitativeAnaplasmState,
  };
};

export const fieldsStateToApiParser = (examId, fields, refs) => {
  const structure = profileStructure[examId];
  const exams = structure.exams;
  const biochemistryObj = structure.hasBiochemistry
    ? biochemistryFieldsToApiObjParser(examId, fields)
    : {};
  const outsourcedObj = structure.hasOutsourced
    ? outsourcedFieldsToApiParser(examId, fields)
    : {};
  const chemiluminescenceObj = structure.hasChemiluminescence
    ? chemiluminescenceFieldsToApiParser(examId, fields)
    : {};
  const hemogramObj = exams[EXAM_ID_BY_NAME.HEMOGRAM]
    ? hemogramFieldsToApiObjParser(fields, refs)
    : {};
  const easObj = exams[EXAM_ID_BY_NAME.EAS]
    ? easFieldsToApiObjParser(fields)
    : {};
  const hemocytozoaObj = exams[EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]
    ? hemocytozoaFieldsToApiObjParser(fields)
    : {};
  const microfilariaeObj = exams[EXAM_ID_BY_NAME.MICROFILARIAE_RESEARCH]
    ? microfilariaeFieldsToApiObjParser(fields)
    : {};
  const ehrlichiaObj = exams[EXAM_ID_BY_NAME.EHRLICHIA]
    ? ehrlichiaFieldsToApiObjParser(fields)
    : {};
  const babesiaObj = exams[EXAM_ID_BY_NAME.BABESIA]
    ? babesiaFieldsToApiObjParser(fields)
    : {};
  const qualitativeAnaplasmObj = exams[
    EXAM_ID_BY_NAME.QUALITATIVE_ANAPLASM_PCR_REAL_TIME
  ]
    ? qualitativeAnaplasmFieldsToApiObjParser(fields)
    : {};
  const leishmaniasisObj = exams[EXAM_ID_BY_NAME.LEISHMANIOSE]
    ? leishmaniasisFieldsToApiObjParser(fields)
    : {};
  const rpcuObj = exams[EXAM_ID_BY_NAME.PROTEIN_CREATININE_RATIO]
    ? rpcuFieldsToApiObjParser(fields)
    : {};
  const reticulocyteObj = exams[EXAM_ID_BY_NAME.RETICULOCYTE_COUNT]
    ? reticulocyteFieldsToApiObjParser(fields)
    : {};
  const bloodProteinObj = exams[EXAM_ID_BY_NAME.BLOOD_PROTEIN]
    ? bloodProteinFieldsToApiObjParser(fields)
    : {};
  const obj = {
    ...biochemistryObj,
    ...hemogramObj,
    ...easObj,
    ...hemocytozoaObj,
    ...microfilariaeObj,
    ...chemiluminescenceObj,
    ...ehrlichiaObj,
    ...babesiaObj,
    ...rpcuObj,
    ...reticulocyteObj,
    ...bloodProteinObj,
    ...leishmaniasisObj,
    ...qualitativeAnaplasmObj,
  };
  return obj;
};

export const profileFieldsNames = (examId) => {
  const structure = profileStructure[examId];
  const exams = structure.exams;
  const biochemistry = structure.hasBiochemistry
    ? biochemistryFieldsNames(examId)
    : [];
  const outsourced = structure.hasOutsourced
    ? outsourcedFieldsNames(examId)
    : [];
  const chemiluminescence = structure.hasChemiluminescence
    ? chemiluminescenceFieldsNames(examId)
    : [];
  const eas = exams[EXAM_ID_BY_NAME.EAS] ? easFiedsNames() : [];
  const hemocytozoa = exams[EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]
    ? hemocytozoaFieldNames()
    : [];
  const microfilariae = exams[EXAM_ID_BY_NAME.MICROFILARIAE_RESEARCH]
    ? microfilariaeFieldNames()
    : [];
  const ehrlichia = exams[EXAM_ID_BY_NAME.EHRLICHIA]
    ? ehrlichiaFieldNames()
    : [];
  const babesia = exams[EXAM_ID_BY_NAME.BABESIA] ? babesiaFieldNames() : [];
  const qualitativeAnaplasm = exams[
    EXAM_ID_BY_NAME.QUALITATIVE_ANAPLASM_PCR_REAL_TIME
  ]
    ? qualitativeAnaplasmFieldNames()
    : [];
  const leishmaniasis = exams[EXAM_ID_BY_NAME.LEISHMANIOSE]
    ? leishmaniasisFieldNames()
    : [];
  const rpcu = exams[EXAM_ID_BY_NAME.PROTEIN_CREATININE_RATIO]
    ? rpcuFieldNames()
    : [];
  const reticulocyte = exams[EXAM_ID_BY_NAME.RETICULOCYTE_COUNT]
    ? reticulocyteFieldNames()
    : [];
  const bloodProtein = exams[EXAM_ID_BY_NAME.BLOOD_PROTEIN]
    ? bloodProteinFieldNames()
    : [];
  return [
    ...biochemistry,
    ...eas,
    ...hemocytozoa,
    ...microfilariae,
    ...chemiluminescence,
    ...ehrlichia,
    ...babesia,
    ...qualitativeAnaplasm,
    ...rpcu,
    ...reticulocyte,
    ...bloodProtein,
    ...leishmaniasis,
  ];
};

export const profileValidationRules = (
  examId,
  fields,
  isCompleteValidation
) => {
  const structure = profileStructure[examId];
  const exams = structure.exams;
  const easRules = exams[EXAM_ID_BY_NAME.EAS]
    ? easValidationRules(fields, isCompleteValidation)
    : {};
  const hemocytozoaRules = exams[EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]
    ? hemocytozoaValidationRules(fields, isCompleteValidation)
    : {};
  const microfilariaeRules = exams[EXAM_ID_BY_NAME.MICROFILARIAE_RESEARCH]
    ? microfilariaeValidationRules(fields, isCompleteValidation)
    : {};

  const ehrlichiaRules = exams[EXAM_ID_BY_NAME.EHRLICHIA]
    ? ehrlichiaValidationRules(fields, isCompleteValidation)
    : {};
  const babesiaRules = exams[EXAM_ID_BY_NAME.BABESIA]
    ? babesiaValidationRules(fields, isCompleteValidation)
    : {};
  const qualitativeANaplasmRules = exams[
    EXAM_ID_BY_NAME.QUALITATIVE_ANAPLASM_PCR_REAL_TIME
  ]
    ? qualitativeAnaplasmValidationRules(fields, isCompleteValidation)
    : {};
  const leishmaniasisRules = exams[EXAM_ID_BY_NAME.LEISHMANIOSE]
    ? leishmaniasisValidationRules(fields, isCompleteValidation)
    : {};
  const rpcuRules = exams[EXAM_ID_BY_NAME.PROTEIN_CREATININE_RATIO]
    ? rpcuValidationRules(fields, isCompleteValidation)
    : {};
  const reticulocyteRules = exams[EXAM_ID_BY_NAME.RETICULOCYTE_COUNT]
    ? reticulocyteValidationRules(fields, isCompleteValidation)
    : {};
  const bloodProteinRules = exams[EXAM_ID_BY_NAME.BLOOD_PROTEIN]
    ? bloodProteinValidationRules(fields, isCompleteValidation)
    : {};
  return {
    ...easRules,
    ...hemocytozoaRules,
    ...microfilariaeRules,
    ...ehrlichiaRules,
    ...babesiaRules,
    ...rpcuRules,
    ...reticulocyteRules,
    ...bloodProteinRules,
    ...leishmaniasisRules,
    ...qualitativeANaplasmRules,
  };
};

export const examTitleById = (examId) => {
  switch (examId) {
    case EXAM_ID_BY_NAME.PROFILE_OBESITY:
      return "Perfil Obeso";
    case EXAM_ID_BY_NAME.PROFILE_MORE_COMPLETE_HEPATIC:
      return "Perfil Hepático Mais Completo";
    case EXAM_ID_BY_NAME.PROFILE_COMPLETE_PARASITOLOGICAL_MONITORING:
      return "Perfil Acompanhamento Parasitológico Completo";
    case EXAM_ID_BY_NAME.PROFILE_CHECKUP2:
      return "Perfil Checkup 2";
    default:
      return "Perfil";
  }
};

export const profileRequests = (examId, requestExamId) => {
  const structure = profileStructure[examId];
  const exams = structure.exams;
  const eas = exams[EXAM_ID_BY_NAME.EAS] ? easRequests(requestExamId) : {};
  const biochemistry = structure.hasBiochemistry
    ? biochemistryRequests(requestExamId)
    : {};
  const chemiluminescence = structure.hasChemiluminescence
    ? chemiluminescenceRequests(requestExamId)
    : {};
  const rpcu = exams[EXAM_ID_BY_NAME.PROTEIN_CREATININE_RATIO]
    ? rpcuRequests(requestExamId)
    : {};
  const reticulocyte = exams[EXAM_ID_BY_NAME.RETICULOCYTE_COUNT]
    ? reticulocyteRequests(requestExamId)
    : {};
  const bloodProtein = exams[EXAM_ID_BY_NAME.BLOOD_PROTEIN]
    ? bloodProteinRequests(requestExamId)
    : {};
  return {
    ...eas,
    ...biochemistry,
    ...chemiluminescence,
    ...rpcu,
    ...reticulocyte,
    ...bloodProtein,
  };
};

export const profilePropsData = (examId, props) => {
  const structure = profileStructure[examId];
  const exams = structure.exams;
  const easPropsData = exams[EXAM_ID_BY_NAME.EAS]
    ? extractEasOptions(props)
    : {};
  return {
    ...easPropsData,
  };
};
