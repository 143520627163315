import React from "react";
import * as LifeVet from "./../../../../../components/index";
import { Modal } from "react-bootstrap";

export const ReportModal = ({
  isOpen,
  closeModal,
  isMobile,
  url,
}: {
  isOpen: boolean;
  closeModal: any;
  isMobile: boolean;
  url: string;
}) => {
  return (
    <Modal bsSize="large" show={isOpen && !isMobile} onHide={closeModal}>
      <Modal.Body>
        <embed src={url} width="100%" height="600" />
      </Modal.Body>
      <Modal.Footer>
        <LifeVet.Button
          title={"Fechar"}
          type={"default"}
          onClick={closeModal}
        />
      </Modal.Footer>
    </Modal>
  );
};
