/**
 * Holds definition for all requests
 */

/**
 * @author Victor Heringer
 *
 * Sends an email with a exam pdf attached
 *
 * @param {Number} id The request exam id
 * @param {Function} then Callback function
 */
export const sendEmailReport = (id, then) => ({
  sendEmailReportPromisse: {
    url: `/mail/print-report/${id}`,
    method: "POST",
    then: then
  }
});

/**
 * @author Victor Heringer
 *
 * Gets that for a report reader
 *
 * @param {Number} id The report id
 */
export const getReportHeader = id => ({
  reportHeaderPromise: {
    url: `/report/request-exams/${id}/report-header`,
    method: "GET"
  }
});

/**
 * @author Victor Heringer
 *
 * Saves a report to api
 *
 * @param {Object} report Report data
 * @param {Function} then Callback function
 */
export const postReport = (report, then) => ({
  reportSubmitPromise: {
    url: `/reports`,
    method: "POST",
    force: true,
    body: JSON.stringify(report),
    then: then
  }
});

/**
 * @author Victor Heringer
 *
 * Updates a report to api
 *
 * @param {Object} report Report data
 * @param {Function} then Callback function
 */
export const updateReport = (report, then) => ({
  reportSubmitPromise: {
    url: `/reports/${report.id}`,
    method: "PUT",
    force: true,
    body: JSON.stringify(report),
    then: then
  }
});

/**
 * @author Victor Heringer
 *
 * Gets a report from api
 *
 * @param {Number} id Report id
 * @param {Function} then Callback function
 */
export const getReportById = (id, then) => ({
  reportPromise: {
    url: `/reports/${id}`,
    then: then
  }
});
