import React, { KeyboardEvent, useState, useRef, useEffect } from "react";
import { useValidatedAnimalForm } from "./../../../../../hooks/animal/index";
import { Animal, Customer } from "./../../../../../types/index";
import { useFetch, ROUTES } from "./../../../../../hooks/use-fetch/index";
import * as LifeVet from "./../../../../../components/index";
import { AnimalFields } from "./../animal-fields/index";
import { AnimalsTable } from "./../animals-table/index";
import { MESSAGES } from "./../../../../../consts/index";
import { Modal } from "react-bootstrap";

export const AnimalModal = ({
  customer,
  methods,
  handleChange,
  labId,
  customerId,
  loggedUserIsFromClinic,
  isOpen,
  closeAnimalModal,
  successNotification,
  errorNotification,
}: {
  customer: Customer;
  methods: any;
  handleChange: any;
  labId: number;
  customerId: number;
  loggedUserIsFromClinic: boolean;
  isOpen: boolean;
  closeAnimalModal: () => void;
  successNotification: any;
  errorNotification: any;
}) => {
  const SUBMIT_MODAL_KEY = "Enter";

  const selectAnimal = (animal: Animal) => {
    handleChange("animal", animal);
    closeAnimalModal();
  };

  const nameInputRef = useRef(document.createElement("div"));
  const nameSearchInputRef = useRef(document.createElement("div"));

  const [showForm, setShowForm] = useState(false);

  const defaultAnimal: any = false;
  const [selectedAnimal, setSelectedAnimal] = useState(defaultAnimal);

  const [animalNameFilter, setAnimalNameFilter] = useState("");

  useEffect(() => {
    showForm
      ? nameInputRef.current && nameInputRef.current.focus()
      : setTimeout(
          () =>
            nameSearchInputRef.current && nameSearchInputRef.current.focus(),
          500
        );
        animalNameFilter && animalForm.methods.updateField("name", animalNameFilter)
        setAnimalNameFilter("");
  }, [showForm, isOpen]);

  const animalForm = useValidatedAnimalForm();

  const fetchedSpecies = useFetch();
  const fetchedBreeds = useFetch();

  useEffect(() => {
    fetchedSpecies.fetch(ROUTES.getSpecies());
    fetchedBreeds.fetch(ROUTES.getBreeds());
  }, []);

  const disableAll =
    animalForm.isSubmitting || fetchedSpecies.pending || fetchedBreeds.pending;

  const onSuccess = (animal: any) => {
    successNotification(MESSAGES.ANIMAL_SAVED_SUCCESSFULLY);
    setShowForm(false);
    animalForm.setIsSubmitting(false);
    animalForm.reset();
    const animalWithId = {
      ...animalForm.data,
      id: animal.id,
    };
    selectAnimal(animalWithId);
  };

  const onError = () => {
    errorNotification(MESSAGES.ANIMAL_SAVING_ERROR);
    animalForm.setIsSubmitting(false);
  };

  return (
    <Modal
      show={isOpen}
      bsSize="lg"
      onHide={closeAnimalModal}
      onExited={() => {}}
    >
      <Modal.Header closeButton>
        <Modal.Title>{showForm ? "Novo Animal" : "Animais"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LifeVet.Row className="mt-20">
          <LifeVet.Col md={12}>
            {showForm ? (
              <div
                onKeyDown={(e: KeyboardEvent<HTMLElement>) =>
                  e.key === "Enter" && animalForm.submit(onSuccess, onError)
                }
              >
                <AnimalFields
                  data={animalForm.data}
                  customer={customer}
                  methods={animalForm.methods}
                  errors={animalForm.errors}
                  touched={animalForm.touched}
                  setError={animalForm.setError}
                  setTouched={animalForm.setTouched}
                  handleChange={animalForm.handleChange}
                  handleBlur={animalForm.handleBlur}
                  disableAll={animalForm.isSubmitting}
                  nameRef={nameInputRef}
                  loggedUserIsFromClinic={loggedUserIsFromClinic}
                  labId={labId}
                />
              </div>
            ) : (
              <AnimalsTable
                labId={labId}
                customerId={customerId}
                loggedUserIsFromClinic={loggedUserIsFromClinic}
                nameSearchInputRef={nameSearchInputRef}
                selectAnimal={selectAnimal}
                setSelectedAnimal={setSelectedAnimal}
                setAnimalNameFilter={setAnimalNameFilter}
              />
            )}
          </LifeVet.Col>
        </LifeVet.Row>
      </Modal.Body>
      <Modal.Footer>
        {!showForm && (
          <>
            <LifeVet.Button
              variant={"primary"}
              depth={2}
              disabled={disableAll || !selectedAnimal}
              onClick={() => selectAnimal(selectedAnimal)}
            >
              Selecionar
            </LifeVet.Button>
            <LifeVet.Button
              variant={"primary"}
              depth={2}
              disabled={disableAll}
              onClick={() => setShowForm(true)}
            >
              Novo
            </LifeVet.Button>
          </>
        )}
        {showForm && (
          <LifeVet.Button
            variant={"primary"}
            depth={2}
            disabled={disableAll}
            onClick={() => {
              setShowForm(false);
              animalForm.reset();
            }}
          >
            Voltar à Listagem
          </LifeVet.Button>
        )}
        {showForm && (
          <LifeVet.Button
            variant={"primary"}
            disabled={disableAll}
            onClick={() => animalForm.submit(onSuccess, onError)}
          >
            Salvar e Selecionar
          </LifeVet.Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
