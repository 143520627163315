import { styled } from "../../components/stitches"

export const StyledLabel = styled('label', {
  fontFamily: '$mainFont',
  fontSize: '11px',
  lineHeight: '16px',
  textTransform: 'uppercase',
  color: '$blackberry',

  variants: {
    error: {
      true: {
        color: '$rose'
      }
    }
  }
})