import { molecularBiologyRules } from "../../../../assets/js/validationRules";

export const ehrlichiaFieldNames = () => {
  return [
    "ehrlichiaMolecularBiologyMaterials",
    "ehrlichiaTechnique",
    "ehrlichiaQualitativeResult",
    "ehrlichiaCopyValues",
    "ehrlichiaPrecision",
    "ehrlichiaResult",
    "ehrlichiaObs",
  ];
};

export const ehrlichiaDefaultState = () => {
  return {
    ehrlichiaMolecularBiologyMaterials: [],
    ehrlichiaTechnique: -1,
    ehrlichiaQualitativeResult: -1,
    ehrlichiaCopyValues: "",
    ehrlichiaPrecision: "",
    ehrlichiaResult: "",
    ehrlichiaObs: "",
  };
};

export const ehrlichiaStateApiFactory = (obj) => {
  const {
    ehrlichiaMaterials,
    ehrlichiaTechnique,
    ehrlichiaQualitativeResult,
    ehrlichiaCopyValues,
    ehrlichiaPrecision,
    ehrlichiaResult,
    ehrlichiaObs,
  } = obj;
  return {
    ehrlichiaMolecularBiologyMaterials: ehrlichiaMaterials
      ? ehrlichiaMaterials
      : [],
    ehrlichiaTechnique: ehrlichiaTechnique ? ehrlichiaTechnique : -1,
    ehrlichiaQualitativeResult: !(ehrlichiaQualitativeResult === null)
      ? ehrlichiaQualitativeResult
      : -1,
    ehrlichiaCopyValues: ehrlichiaCopyValues ? ehrlichiaCopyValues : "",
    ehrlichiaPrecision: ehrlichiaPrecision ? ehrlichiaPrecision : "",
    ehrlichiaResult: ehrlichiaResult ? ehrlichiaResult : "",
    ehrlichiaObs: ehrlichiaObs ? ehrlichiaObs : "",
  };
};

export const ehrlichiaFieldsToApiObjParser = (fields) => {
  const {
    ehrlichiaMolecularBiologyMaterials,
    ehrlichiaTechnique,
    ehrlichiaQualitativeResult,
    ehrlichiaCopyValues,
    ehrlichiaPrecision,
    ehrlichiaResult,
    ehrlichiaObs,
  } = fields;

  return {
    ehrlichiaMolecularBiologyMaterials: ehrlichiaMolecularBiologyMaterials,
    ehrlichiaTechnique: ehrlichiaTechnique,
    ehrlichiaQualitativeResult: ehrlichiaQualitativeResult,
    ehrlichiaCopyValues: ehrlichiaCopyValues,
    ehrlichiaPrecision: ehrlichiaPrecision,
    ehrlichiaResult: ehrlichiaResult,
    ehrlichiaObs: ehrlichiaObs,
  };
};

export const ehrlichiaValidationRules = (fields, isCompleteValidation) => {
  const {
    ehrlichiaMolecularBiologyMaterials,
    ehrlichiaTechnique,
    ehrlichiaQualitativeResult,
    ehrlichiaCopyValues,
    ehrlichiaPrecision,
  } = fields;
  return molecularBiologyRules(
    "ehrlichiaTechnique",
    ehrlichiaTechnique,
    "ehrlichiaQualitativeResult",
    ehrlichiaQualitativeResult,
    "ehrlichiaCopyValues",
    ehrlichiaCopyValues,
    "ehrlichiaPrecision",
    ehrlichiaPrecision,
    "ehrlichiaMolecularBiologyMaterials",
    ehrlichiaMolecularBiologyMaterials,
    isCompleteValidation
  );
};
