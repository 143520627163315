import React from "react";

/**
 * Internal
 */
import PlateletsPanel from "./../PlateletsPanel";
import ErythrogramPanel from "./../ErythrogramPanel";
import LeukogramPanel from "./../LeukogramPanel";
import HemogramDiagnosisPanel from "./../HemogramDiagnosisPanel";
import GlobalLeukogramPanel from "./../GlobalLeukogramPanel";
import HemogramCounter from "./../HemogramCounter";

/**
 * External
 */
import { Row, Col } from "./../../components/grid";
import PlateletsPanelWithCounter from "../PlateletsPanelWithCounter";

const HemogramContent = ({
  fields,
  refs,
  disableAll,
  $validation,
  total,
  $field,
  fieldChange,
  calcMCV,
  calcMCHC,
  calcAbsolute,
  resetLeukogram,
  onClickCounter,
  toggleIntenseLeucopeny,
  finalGlobalLeukogram,
  erythrogramDiagnosis,
  leukogramDiagnosis,
  plateletsDiagnosis,
  handleKeyPress,
  closeCounter,
  setRef,
  reportData,
}) => (
  <React.Fragment>
    <Row>
      <Col md={5}>
        <ErythrogramPanel
          title={"Erythrograma"}
          erythrocytes={fields.erythrocytes}
          corpuscularVolume={fields.corpuscularVolume}
          hemoglobiometry={fields.hemoglobiometry}
          erythrocytesMin={refs.erythrocytesMin}
          erythrocytesMax={refs.erythrocytesMax}
          corpuscularVolumeMin={refs.corpuscularVolumeMin}
          corpuscularVolumeMax={refs.corpuscularVolumeMax}
          hemoglobiometryMin={refs.hemoglobiometryMin}
          hemoglobiometryMax={refs.hemoglobiometryMax}
          mcvMin={refs.mcvMin}
          mcvMax={refs.mcvMax}
          mchcMin={refs.mchcMin}
          mchcMax={refs.mchcMax}
          disableAll={disableAll}
          $validation={$validation}
          fieldChange={fieldChange}
          $field={$field}
          calcMCV={calcMCV}
          calcMCHC={calcMCHC}
          flat={true}
          setRef={setRef}
        />
        <GlobalLeukogramPanel
          globalLeukometry={fields.globalLeukometry}
          metarrubrycites={fields.metarrubrycites}
          isIntenseLeucopeny={fields.isIntenseLeucopeny}
          disableAll={disableAll}
          $validation={$validation}
          refs={refs}
          fieldChange={fieldChange}
          $field={$field}
          toggleIntenseLeucopeny={toggleIntenseLeucopeny}
          finalGlobalLeukogram={finalGlobalLeukogram}
          setRef={setRef}
        />
      </Col>
      <Col md={7}>
        <LeukogramPanel
          title={"Leucometria Específica"}
          isIntenseLeucopeny={fields.isIntenseLeucopeny}
          relativeBasophils={fields.relativeBasophils}
          relativeEosinophils={fields.relativeEosinophils}
          relativeMyelocytes={fields.relativeMyelocytes}
          relativeMetamyelocytes={fields.relativeMetamyelocytes}
          relativeBands={fields.relativeBands}
          relativeSegs={fields.relativeSegs}
          relativeLymphocytes={fields.relativeLymphocytes}
          relativeMonocytes={fields.relativeMonocytes}
          relativeHeterophils={fields.relativeHeterophils}
          disableAll={disableAll}
          $validation={$validation}
          refs={refs}
          total={total}
          fieldChange={fieldChange}
          $field={$field}
          calcAbsolute={calcAbsolute}
          resetLeukogram={resetLeukogram}
          onClickCounter={onClickCounter}
        />
        <PlateletsPanelWithCounter
          panelTitle={"Plaquetas"}
          fieldTitle={"Resultado"}
          fieldName={"platelets"}
          value={fields.platelets}
          shouldDisable={disableAll}
          hasValidation={$validation.platelets.show}
          validationReason={$validation.platelets.error.reason}
          fieldChange={fieldChange}
          $field={$field}
          valueMin={refs.plateletsMin}
          valueMax={refs.plateletsMax}
          setRef={setRef}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <HemogramDiagnosisPanel
          hemogramObs={fields.hemogramObs}
          platelets={fields.platelets}
          erythrogramObs={fields.erythrogramObs}
          leukogramObs={fields.leukogramObs}
          plateletsObs={fields.plateletsObs}
          disableAll={disableAll}
          $validation={$validation}
          fieldChange={fieldChange}
          $field={$field}
          erythrogramDiagnosis={erythrogramDiagnosis}
          leukogramDiagnosis={leukogramDiagnosis}
          plateletsDiagnosis={plateletsDiagnosis}
          reportData={reportData}
          diagnosisInput={fields.erythrogramDiagnosis}
        />
      </Col>
    </Row>
    <HemogramCounter
      open={fields.isCounterOpen}
      total={total}
      fields={fields}
      handleKeyPress={handleKeyPress}
      handleClose={closeCounter}
      hasHeterophils={refs.hasHeterophils}
    />
  </React.Fragment>
);

export default HemogramContent;
