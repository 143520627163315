import React, { Component } from 'react';

/**
 * External
 */
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

/**
 * Internal
 */
import PricingTableList from './../PricingTableList';
import NotFound from './../NotFound';

class PricingTableSection extends Component {

  constructor(props) {
    super(props);
    this.renderList = this.renderList.bind(this);
  }

  /**
   * @author Victor Heringer
   *
   * Renders the Exam request sample list
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderList(routeProps) {

    return (
      <PricingTableList
        {...routeProps}
        notificationSystem={this.props.notificationSystem}
      />
    );
  }

  render() {

    return (
      <Switch>
        <Route
          exact
          path={'/tabela-preco'}
          render={this.renderList}
          notificationSystem={this.props.notificationSystem}
        />
      </Switch>
    );

  }

}

export default PricingTableSection;