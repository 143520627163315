import React, { useEffect, useCallback, useState, KeyboardEvent } from "react";
import { useFetch, ROUTES } from "./../../../../../hooks/use-fetch/index";
import * as LifeVet from "./../../../../../components/index";
import { debounce } from "lodash";
import {
  SpecieFilters,
  BreedFilters,
  Customer,
} from "./../../../../../types/index";
import LVInputTextUpper from "./../../../../../old_components/LVInputTextUpper";
import DatePickerMaterial from "./../../../../DatePickerMaterial";
import { SEX } from "../../../../../assets/js/Consts.js";
import { PHONE_MASK, CPF_MASK } from "../../../../../assets/js/Consts";
import moment from "../../../../../assets/js/moment.js";

export const AnimalFields = ({
  data,
  customer,
  methods,
  errors,
  touched,
  setError,
  setTouched,
  handleChange,
  handleBlur,
  disableAll,
  nameRef,
  loggedUserIsFromClinic,
  labId,
}: {
  data: any;
  customer: Customer;
  methods: any;
  errors: any;
  touched: any;
  setError: any;
  setTouched: any;
  handleChange: any;
  handleBlur: any;
  disableAll: boolean;
  nameRef: any;
  loggedUserIsFromClinic: boolean;
  labId: number;
}) => {
  const isCommandKey = (key: number) =>
    new Set([37, 38, 39, 40, 13, 19, 8, 9]).has(key);

  const isBackspaceKey = (key: number) =>
    new Set([37, 38, 39, 40, 13, 19, 8, 9]).has(key);

  const today = moment().endOf("day");
  const birth = data.birthDate ? moment(data.birthDate) : null;

  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const [mock, setMock] = useState("");

  // const fetchedCustomers = useFetch();
  const fetchedSpecies = useFetch();
  const fetchedBreeds = useFetch();

  useEffect(() => {
    handleChange("customer", customer);
    fetchedSpecies.fetch(ROUTES.getSpecies());
    fetchedBreeds.fetch(ROUTES.getBreeds({ specieId: data.specie.id }));
  }, []);

  useEffect(() => {
    fetchedBreeds.fetch(ROUTES.getBreeds({ specieId: data.specie.id }));
    fetchedBreeds.reset();
  }, [data.specie.name]);

  useEffect(() => {
    const date = moment().add(-months, "M").add(-years, "y");
    methods.updateField("birthDate", date);
  }, [years, months]);

  const fetchSpecies = useCallback(
    debounce((filters: SpecieFilters) => {
      fetchedSpecies.fetch(ROUTES.getSpecies(filters));
    }, 500),
    []
  );

  const fetchBreeds = useCallback(
    debounce((filters: BreedFilters) => {
      fetchedBreeds.fetch(ROUTES.getBreeds(filters));
    }, 500),
    []
  );

  return (
    <>
      <LifeVet.Row>
        {!loggedUserIsFromClinic && (
          <LifeVet.Col sm={3}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Cliente</LifeVet.InputLabel>
              <LifeVet.Select
                name="customer"
                options={[customer]}
                isLoading={false}
                theValueIsAnId
                value={customer.id}
                disabled={true}
                onChange={(option: any) => {}}
                onBlur={() => {}}
                onKeyDown={(e: any) => {}}
                searchable
              />
            </LifeVet.FormGroup>
          </LifeVet.Col>
        )}
        <LifeVet.Col sm={3}>
          <LifeVet.FormGroup noError>
            <LifeVet.InputLabel hasError={touched.name && errors.name}>
              Nome do Animal
            </LifeVet.InputLabel>
            <LifeVet.Input
              type="text"
              value={data.name}
              innerRef={nameRef}
              name={"name"}
              isViewOnly={false}
              disabled={disableAll}
              onChange={(e: React.ChangeEvent<any>) =>
                handleChange("name", e.target.value)
              }
              onBlur={() => handleBlur("name")}
              errorMessage={touched.name && errors.name}
              uppercase
            />
          </LifeVet.FormGroup>
        </LifeVet.Col>
        <LifeVet.Col sm={3}>
          <div onKeyDown={(e: any) => e.stopPropagation()}>
            <LifeVet.FormGroup noError>
              <LifeVet.InputLabel
                hasError={
                  touched.specie &&
                  errors.specie &&
                  (errors.specie.id || errors.specie.name)
                }
              >
                Espécie
              </LifeVet.InputLabel>
              <LifeVet.Select
                name="specie"
                options={
                  fetchedSpecies.fulfilled ? fetchedSpecies.data.data : []
                }
                isLoading={fetchedSpecies.pending}
                theValueIsAnId
                value={data.specie.id}
                disabled={disableAll}
                onChange={(option: any) => handleChange("specie", option)}
                onBlur={() => handleBlur("specie")}
                onKeyDown={(e: any) => {
                  if (isCommandKey(e.keyCode)) return;
                  let value = isBackspaceKey(e.keyCode)
                    ? e.target.value
                    : e.target.value + e.key;

                  fetchSpecies({ name: value });
                }}
                searchable
                errorMessage={
                  touched.specie &&
                  errors.specie &&
                  (errors.specie.id || errors.specie.name)
                }
              ></LifeVet.Select>
            </LifeVet.FormGroup>
          </div>
        </LifeVet.Col>
        <LifeVet.Col sm={3}>
          <div onKeyDown={(e: any) => e.stopPropagation()}>
            <LifeVet.FormGroup noError>
              <LifeVet.InputLabel
                hasError={
                  touched.breed &&
                  errors.breed &&
                  (errors.breed.id || errors.breed.name)
                }
              >
                Raça
              </LifeVet.InputLabel>
              <LifeVet.Select
                name="breed"
                options={fetchedBreeds.fulfilled ? fetchedBreeds.data.data : []}
                theValueIsAnId
                value={data.breed.id}
                disabled={
                  disableAll || fetchedSpecies.pending || data.specie.id === 0
                }
                isLoading={fetchedBreeds.pending}
                onChange={(option: any) => handleChange("breed", option)}
                onBlur={() => handleBlur("breed")}
                searchable
                errorMessage={
                  touched.breed &&
                  errors.breed &&
                  (errors.breed.id || errors.breed.name)
                }
                onKeyDown={(e: any) => {
                  if (isCommandKey(e.keyCode)) return;
                  let value = isBackspaceKey(e.keyCode)
                    ? e.target.value
                    : e.target.value + e.key;

                  fetchBreeds({
                    name: value,
                    specieId: data.specie.id,
                  });
                }}
              ></LifeVet.Select>
            </LifeVet.FormGroup>
          </div>
        </LifeVet.Col>
      </LifeVet.Row>
      <LifeVet.Row>
        <LifeVet.Col md={3}>
          <LifeVet.FormGroup noError>
            <LifeVet.InputLabel hasError={touched.sexId && errors.sexId}>
              Sexo
            </LifeVet.InputLabel>
            <br />
            <LifeVet.Checkbox
              name={"sexId"}
              checked={SEX.M === data.sexId}
              onChange={(e: React.ChangeEvent<any>) =>
                handleChange("sexId", SEX.M)
              }
              disabled={disableAll}
            />
            <LifeVet.InputLabel className={"ml-5 mr-5"}>M</LifeVet.InputLabel>
            <LifeVet.Checkbox
              name={"sexId"}
              checked={SEX.F === data.sexId}
              onChange={(e: React.ChangeEvent<any>) =>
                handleChange("sexId", SEX.F)
              }
              disabled={disableAll}
            />
            <LifeVet.InputLabel className={"ml-5 mr-5"}>F</LifeVet.InputLabel>
            <LifeVet.Checkbox
              name={"sexId"}
              checked={SEX.U === data.sexId}
              onChange={(e: React.ChangeEvent<any>) =>
                handleChange("sexId", SEX.U)
              }
              disabled={disableAll}
            />
            <LifeVet.InputLabel className={"ml-5 mr-5"}>U</LifeVet.InputLabel>
          </LifeVet.FormGroup>
        </LifeVet.Col>
        <LifeVet.Col sm={3}>
          <LifeVet.FormGroup noError>
            <LifeVet.InputLabel hasError={touched.owner && errors.owner}>
              Proprietário
            </LifeVet.InputLabel>
            <LifeVet.Input
              type="text"
              value={data.owner}
              name={"owner"}
              isViewOnly={false}
              onChange={(e: React.ChangeEvent<any>) =>
                handleChange("owner", e.target.value)
              }
              onBlur={() => handleBlur("owner")}
              disabled={disableAll}
              errorMessage={touched.owner && errors.owner}
              innerRef={null}
              uppercase
            />
          </LifeVet.FormGroup>
        </LifeVet.Col>
        <LifeVet.Col sm={2}>
          <LifeVet.FormGroup noError>
            <LifeVet.InputLabel hasError={touched.years && errors.years}>
              Anos
            </LifeVet.InputLabel>
            <LifeVet.Input
              type="number"
              value={years}
              max={150}
              name={""}
              isViewOnly={false}
              onChange={(e: React.ChangeEvent<any>) => {
                const max = +e.target.max;
                let value = +e.target.value;
                value = value > max ? max : value;
                setYears(value);
              }}
              onBlur={() => {}}
              errorMessage={touched.years && errors.years}
              disabled={disableAll}
              innerRef={null}
            />
          </LifeVet.FormGroup>
        </LifeVet.Col>
        <LifeVet.Col sm={2}>
          <LifeVet.FormGroup noError>
            <LifeVet.InputLabel hasError={touched.months && errors.months}>
              Meses
            </LifeVet.InputLabel>
            <LifeVet.Input
              type="number"
              max={11}
              value={months}
              name={""}
              isViewOnly={false}
              onChange={(e: React.ChangeEvent<any>) => {
                const max = +e.target.max;
                let value = +e.target.value;
                value = value > max ? max : value;
                setMonths(value);
              }}
              onBlur={() => {}}
              disabled={disableAll}
              errorMessage={touched.months && errors.months}
              innerRef={null}
            />
          </LifeVet.FormGroup>
        </LifeVet.Col>

        <LifeVet.Col md={2}>
          <LifeVet.FormGroup noError>
            <DatePickerMaterial
              id={"birthDate"}
              name={"birthDate"}
              title={"Nascimento"}
              maxDate={today}
              startDate={birth}
              disabled={disableAll}
              readOnly={false}
              onChange={(date: any) => {
                let now = moment();
                setYears(now.diff(date, "years"));
                setMonths(now.diff(date, "months") % 12);
                handleChange("birthDate", date);
              }}
              onBlur={() => handleBlur("birthDate")}
              hasValidation={true}
              validationType={"danger"}
              validationReason={touched.birthDate && errors.birthDate}
              hasValidationRecoil={true}
            />
          </LifeVet.FormGroup>
        </LifeVet.Col>
      </LifeVet.Row>
      <LifeVet.Row>
        <LifeVet.Col sm={3}>
          <LifeVet.FormGroup>
            <LifeVet.InputLabel hasError={touched.phone && errors.phone}>
              Telefone
            </LifeVet.InputLabel>

            <LVInputTextUpper
              mask
              maskValue={PHONE_MASK}
              id="phone"
              name="phone"
              value={data.phone}
              componentUnder
              autofocus={false}
              required={false}
              onChange={(e: React.ChangeEvent<any>) =>
                handleChange("phone", e.target.value)
              }
              onBlur={() => handleBlur("phone")}
              disabled={disableAll}
            />
          </LifeVet.FormGroup>
        </LifeVet.Col>
        <LifeVet.Col sm={3}>
          <LifeVet.FormGroup>
            <LifeVet.InputLabel hasError={touched.cpf && errors.cpf}>
              CPF
            </LifeVet.InputLabel>
            <LVInputTextUpper
              id="cpf"
              name="cpf"
              value={data.cpf}
              mask
              maskValue={CPF_MASK}
              componentUnder
              autofocus={false}
              required={false}
              onChange={(e: React.ChangeEvent<any>) =>
                handleChange("cpf", e.target.value)
              }
              onBlur={() => handleBlur("cpf")}
              disabled={disableAll}
            />
            <></>
          </LifeVet.FormGroup>
        </LifeVet.Col>
        <LifeVet.Col sm={2}>
          <LifeVet.FormGroup noError>
            <LifeVet.InputLabel
              hasError={touched.externalCode && errors.externalCode}
            >
              Cód. Externo
            </LifeVet.InputLabel>
            <LifeVet.Input
              type="text"
              value={data.externalCode}
              name={"externalCode"}
              isViewOnly={false}
              onChange={(e: React.ChangeEvent<any>) =>
                handleChange("externalCode", e.target.value)
              }
              onBlur={() => handleBlur("externalCode")}
              errorMessage={touched.externalCode && errors.externalCode}
              innerRef={null}
              disabled={disableAll}
              uppercase
            />
          </LifeVet.FormGroup>
        </LifeVet.Col>
        <LifeVet.Col sm={2}>
          <LifeVet.FormGroup noError>
            <LifeVet.InputLabel>Cód. Microchip</LifeVet.InputLabel>
            <LifeVet.Input
              type="text"
              value={data.microchipCode}
              name={"microchipCode"}
              isViewOnly={false}
              onChange={(e: React.ChangeEvent<any>) =>
                handleChange("microchipCode", e.target.value)
              }
              onBlur={() => handleBlur("microchipCode")}
              errorMessage={touched.microchipCode && errors.microchipCode}
              innerRef={null}
              disabled={disableAll}
              uppercase
            />
          </LifeVet.FormGroup>
        </LifeVet.Col>
      </LifeVet.Row>
      {console.clear()}
      {/* {console.log(errors)} */}
      <br />
      <br />
      {/* {console.log(touched)} */}
    </>
  );
};
