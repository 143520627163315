import * as Yup from "yup";
import { YupLocale } from "./index";

Yup.setLocale(YupLocale);

export const AnimalValidationSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required(),
  birthDate: Yup.string().required(),
  owner: Yup.string().required(),
  phone: Yup.string(),
  cpf: Yup.string(),
  externalCode: Yup.string(),
  microchipCode: Yup.string(),
  sexId: Yup.number().required().min(1).max(3),
  sexName: Yup.string(),
  specie: Yup.object()
    .shape({
      id: Yup.number().min(1, "Este campo é obrigatório").required(),
      name: Yup.string().required(),
      status: Yup.number(),
    })
    .required(),
  breed: Yup.object().shape({
    id: Yup.number().min(1, "Este campo é obrigatório").required(),
    name: Yup.string().required(),
    status: Yup.number(),
  }),
  customer: Yup.object().shape({
    id: Yup.number().min(1).required(),
    name: Yup.string().required(),
    type: Yup.string(),
    isActive: Yup.boolean(),
    receivesPrintedReport: Yup.boolean(),
    phones: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        value: Yup.string(),
      })
    ),
    emails: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        value: Yup.string(),
        sendReport: Yup.boolean(),
        sendInvoice: Yup.boolean(),
      })
    ),
    addresses: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        publicPlace: Yup.string(),
        cep: Yup.string(),
        number: Yup.string(),
        complement: Yup.string(),
        neighborhood: Yup.string(),
        cityId: Yup.number(),
        stateId: Yup.number(),
      })
    ),
  }),
});
