import React, { useEffect } from "react";
import { RequestSample } from "../../../../../types/index";
import { RequestSamplesTable } from "./../index";
import * as LifeVet from "./../../../../../components/index";

export const SamplesSection = ({
  data,
  methods,
  errors,
  touched,
  setTouched,
  handleChange,
  handleBlur,
  loggedUserIsFromClinic,
  disableAll,
}: {
  data: Array<RequestSample>;
  methods: any;
  errors: any;
  touched: any;
  setTouched: any;
  handleChange: any;
  handleBlur: any;
  loggedUserIsFromClinic: boolean;
  disableAll: boolean;
}) => {
  return (
    <>
      <RequestSamplesTable
        data={data}
        methods={methods}
        touched={touched}
        errors={errors}
        setTouched={setTouched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        loggedUserIsFromClinic={loggedUserIsFromClinic}
        disableAll={disableAll}
      />
    </>
  );
};
