import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import NumberInputMaterial2 from "../../NumberInputMaterial2";
import InputMaterial from "../../InputMaterial";
import { initialData } from "./initial-data";

export default function ManifoldReference({
  data,
  setField,
  extract,
  loading,
  loadInitialData,
}) {
  useEffect(() => {
    loadInitialData(initialData);
  }, []);

  function renderMinAndMaxField(key) {
    const { titles } = initialData;

    let title = titles[key];
    return (
      <Row className="mt-20">
        <Col md={2}>
          <p>{title}</p>
        </Col>
        <Col md={2}>
          <NumberInputMaterial2
            name={`${key}Min`}
            title={"Min"}
            inputGroupAfter=""
            inputGroup={true}
            hasValidationRecoil={true}
            decimalScale={1}
            fit={true}
            block={true}
            inputGroup={true}
            allowNegative={false}
            value={extract(data, `${key}Min`, "")}
            disabled={loading}
            shouldDisableThousandSeparator={false}
            handleChange={(field, value) =>
              setField(`${key}Min`, value)
            }
          />
        </Col>
        <Col md={2}>
          <NumberInputMaterial2
            name={`${key}Max`}
            title={"Max"}
            inputGroupAfter=""
            inputGroup={true}
            hasValidationRecoil={true}
            decimalScale={1}
            fit={true}
            block={true}
            inputGroup={true}
            allowNegative={false}
            value={extract(data, `${key}Max`, "")}
            disabled={loading}
            shouldDisableThousandSeparator={false}
            handleChange={(field, value) =>
              setField(`${key}Max`, value)
            }
          />
        </Col>
      </Row>
    );
  }
  
  return (
    <div>
      <h4>Líquido Torácico</h4>
      {renderMinAndMaxField("thoracicDensity")}
      <h4>Líquido Ascítico</h4>
      {renderMinAndMaxField("asciticDensity")}
      <h4>Análise de Líquor</h4>
      {renderMinAndMaxField("liquorDensity")}
      {renderMinAndMaxField("liquorPh")}
      {renderMinAndMaxField("liquorProteins")}
      {renderMinAndMaxField("liquorGlucose")}
      {renderMinAndMaxField("liquorLeukocytes")}

      <Row className="mt-20">
        <Col md={2}>
          <p>Coagulaçao</p>
        </Col>
        <Col md={2}>
          <InputMaterial
            disabled={loading}
            title=""
            name="liquorCoagulation"
            md={12}
            value={extract(data, "liquorCoagulation", "")}
            onChange={(event) =>
              setField("liquorCoagulation", event.target.value)
            }
          />
        </Col>
      </Row>
    </div>
  );
}
