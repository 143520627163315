import React from "react";

/** Internal */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import PanelMaterialBottom from "./../PanelMaterialBottom";
import ButtonBackMaterial from "./../ButtonBackMaterial";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import SelectMaterial from "./../SelectMaterial";
import InputMaterial from "./../InputMaterial";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";
import {
  required,
  minLength,
  maxLength,
  greaterThan,
  lazyIsUniqueOutsourcedExam
} from "../../assets/js/validationFunctions";

/** External */
import { validated } from "react-custom-validation";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";

const OutsourcedExamsFormView = ({
  isUpdate,
  examsTypes,
  fields,
  $field,
  $submit,
  fieldChange,
  $validation,
  disableAll,
  handleSubmit,
  invalidNotification
}) => {
  const saveBtnTitle = "Salvar";

  const onClickSave = function(handleSubmit, invalidNotification) {
    return e => {
      e.preventDefault();
      $submit(handleSubmit, invalidNotification);
    };
  };

  const statusOptions = [
    { id: 0, name: "Inativo" },
    { id: 1, name: "Ativo" }
  ];

  return (
    <React.Fragment>
      <PanelMaterial>
        <PanelMaterialTop title="Novo Exame">
          <Link to="/exame-terceirizado">
            <ButtonBackMaterial
              type="default"
              title="Voltar"
              position="top"
              disabled={false}
            />
          </Link>
        </PanelMaterialTop>
        <PanelMaterialBody>
          <Row>
            
            <InputMaterial
              id="name"
              title="Nome"
              required
              name="name"
              md={4}
              value={fields.name || ""}
              disabled={disableAll}
              {...$field("name", event => {
                fieldChange("name", event.target.value);
              })}
              hasValidation={$validation.name.show && !$validation.name.isValid}
              validationReason={$validation.name.error.reason}
            />
            <SelectMaterial
              title="Tipo do Exame"
              required
              md={4}
              name="examTypeId"
              options={
                examsTypes && examsTypes.fulfilled ? examsTypes.value : []
              }
              value={fields.examTypeId}
              searchable={true}
              disabled={disableAll}
              placeholder={examsTypes.pending ? "Carregando.." : "Selecione"}
              {...$field("examTypeId", value => {
                fieldChange("examTypeId", value);
              })}
              isValid={$validation.examTypeId.isValid}
              hasValidation={
                $validation.examTypeId.show && !$validation.examTypeId.isValid
              }
              validationReason={$validation.examTypeId.error.reason}
            />

            <SelectMaterial
              title="Status"
              md={4}
              name="status"
              options={statusOptions}
              value={fields.status}
              searchable={true}
              disabled={disableAll}
              {...$field("status", value => {
                fieldChange("status", value);
              })}
            />
          </Row>
        </PanelMaterialBody>
        <PanelMaterialBottom>
          <Link to="/exame-terceirizado">
            <ButtonBackMaterial
              type="default"
              title="Voltar"
              position="bottom"
              disabled={false}
            />
          </Link>
          <ButtonSaveMaterial
            type="highlight"
            title={saveBtnTitle}
            right={true}
            onClick={onClickSave(handleSubmit, invalidNotification)}
            disabled={disableAll}
            requesting={false}
            requestingTitle={isUpdate ? "Atualizando.." : "Salvando.."}
          />
        </PanelMaterialBottom>
      </PanelMaterial>
    </React.Fragment>
  );
};

const withValidation = validated(props => {
  const { name, examTypeId } = props.fields;
  const fields = ["name", "examTypeId"];
  const nameRules = props.isUpdate
    ? [
        [required, name, "Informe o nome"],
        [minLength, name, 2, "Mínimo de 2 caracteres"]
      ]
    : [
        [required, name, "Informe o nome"],
        [minLength, name, 2, "Mínimo de 2 caracteres"],
        [lazyIsUniqueOutsourcedExam, name, examTypeId, "Exame já cadastrado"]
      ];

  const validations = {
    name: {
      rules: nameRules
    },
    examTypeId: {
      rules: [[greaterThan, examTypeId, 0, "Selecione a especie"]]
    }
  };

  return { fields, validations };
});

export default withValidation(OutsourcedExamsFormView);
