import React from "react";

/** Internal */
import { Row, Col } from "./../../components/grid";
import AnimalSexRadioMaterial from "./../AnimalSexRadioMaterial";
import NumberInputMaterial from "./../NumberInputMaterial";
import DatePickerMaterial from "./../DatePickerMaterial";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";

/**
 * External
 */
import moment from "../../assets/js/moment.js";
import DualRadioMaterial from "../DualRadioMaterial";
import TripleRadioMaterial from "../TripleRadioMaterial";
import AnimalAgeOptionRadioMaterial from "../AnimalAgeOptionRadioMaterial";

const AnimalFieldsSecondRow = ({
  $field,
  $validation,
  updateField,
  onChangeBirthDate,
  onChangeYearsOrMonths,
  sexId,
  ageOption,
  birthDate,
  years,
  months,
  owner,
  disableAll,
}) => {
  const today = moment().endOf("day");
  const birth = birthDate ? moment(birthDate) : null;

  return (
    <Row>
      <Col md={2}>
        <AnimalSexRadioMaterial
          disabled={disableAll}
          fieldName="sexId"
          selectedValue={sexId}
          updateField={updateField}
          $field={$field}
          $validation={$validation}
          required
        />
      </Col>
      <Col md={3}>
        <AnimalAgeOptionRadioMaterial
          disabled={disableAll}
          fieldName="ageOption"
          selectedValue={ageOption}
          updateField={updateField}
          $field={$field}
          $validation={$validation}
        />
      </Col>

      <NumberInputMaterial
        disabled={!!ageOption}
        id="years"
        required={ageOption ? false : true}
        title="Anos"
        name="years"
        md={1}
        value={years}
        allowNegative={false}
        isNumericString={true}
        format="###"
        fieldChange={onChangeYearsOrMonths}
        {...$field("years")}
        hasValidation={$validation.years && $validation.years.show}
        validationReason={$validation.years && $validation.years.error.reason}
      />
      <NumberInputMaterial
        disabled={!!ageOption}
        id="months"
        title="Meses"
        required={ageOption ? false : true}
        name="months"
        md={1}
        value={months}
        allowNegative={false}
        isNumericString={true}
        format="##"
        fieldChange={onChangeYearsOrMonths}
        {...$field("months")}
        hasValidation={$validation.months && $validation.months.show}
        validationReason={$validation.months && $validation.months.error.reason}
      />
      <Col md={1}>
        <DatePickerMaterial
          disabled={!!ageOption || disableAll}
          id={"birthDate"}
          name={"birthDate"}
          title="Nascimento"
          maxDate={today}
          startDate={birth}
          {...$field("birth", (value) => {
            onChangeBirthDate(value);
          })}
          hasValidation={$validation.birthDate && $validation.birthDate.show}
          validationReason={
            $validation.birthDate && $validation.birthDate.error.reason
          }
        />
      </Col>
      <LVInputTextUpper
        id="owner"
        title="Proprietário"
        required
        name="owner"
        md={3}
        value={owner}
        disabled={disableAll}
        {...$field("owner", (event) => {
          updateField("owner", event.target.value);
        })}
        hasValidation={$validation.owner.show}
        validationReason={$validation.owner.error.reason}
      />
    </Row>
  );
};

export default AnimalFieldsSecondRow;
