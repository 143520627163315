import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal
 */
import { NavLink } from 'react-router-dom';
import AccessManager from './../AccessManager';

/**
 * Assets
 */
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleUp from '@fortawesome/fontawesome-free-solid/faAngleUp';

/**
 * @author Victor Heringer
 *
 * Links that is actually an anchor for sublinks
 */
class LVSidebarSubmenuLink extends Component {

  constructor( props ) {
    super( props );
    this.toggle = this.toggle.bind( this );
  }

  toggle( ) {
    this.props.toggleList( this.props.refKey );
  }

  render() {

    const { open } = this.props;
    const iconClass = open ? "sub-menu-caret-up" : "sub-menu-caret-down";

    return (
      <AccessManager allowed={this.props.allowed} name={this.props.title}>
        <li>
          <a onClick={ this.toggle }> { this.props.title }
            <span className="sub-menu-caret">
              <FontAwesomeIcon
                size="lg"
                icon={ faAngleUp }
                className={ iconClass }
              />
            </span>
          </a>
          <ul
            ref={ "subMenu" }
            className={
              open
              ? "nav nav-pills nav-stacked sub-nav-pills sub-menu-show"
              : "nav nav-pills nav-stacked sub-nav-pills sub-menu-hide"
            }
          >
            {
              this.props.submenus.map( ( subItem ) =>
                <AccessManager 
                  allowed={subItem.allowed} 
                  name={this.props.title}
                  key={subItem.key}
                >
                <li >
                  <NavLink
                  activeStyle={{                    
                    pointerEvents: 'none'
                  }}
                    id={ subItem.id }
                    exact
                    to={ subItem.to }
                  >{ subItem.title }</NavLink>
                </li>
                </AccessManager>
              )
            }
          </ul>
        </li>
      </AccessManager>
    );
  }
}

LVSidebarSubmenuLink.propTypes = {
  title: PropTypes.string.isRequired,
  submenus: PropTypes.array.isRequired
}

export default LVSidebarSubmenuLink;
