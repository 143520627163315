import { validated } from "react-custom-validation";
import { animalRules } from "../../assets/js/validationRules";

const validation = validated((props) => {
  const {
    name,
    years,
    months,
    owner,
    sexId,
    birthDate,
    cpf,
    breedId,
    speciesId,
    customerId,
    ageOption,
  } = props.data.fields;

  const fields = [
    "name",
    "years",
    "owner",
    "cpf",
    "sexId",
    "breedId",
    "speciesId",
    "customerId",
    "ageOption",
    "birthDate",
  ];

  const fieldNames = {
    nameName: "name",
    yearsName: "years",
    monthsName: "months",
    ownerName: "owner",
    sexIdName: "sexId",
    cpfName: "cpf",
    speciesIdName: "speciesId",
    breedIdName: "breedId",
    customerIdName: "customerId",
    ageOptionName: "ageOption",
    birthDateName: "birthDate",
  };

  const validations = animalRules({
    name: name,
    years: years,
    months: months,
    owner: owner,
    sexId: sexId,
    birthDate: birthDate,
    cpf: cpf,
    breedId: breedId,
    speciesId: speciesId,
    ageOption: ageOption,
    customerId: customerId,
    fieldNames: fieldNames,
  });
  return { fields, validations };
});

export default validation;
