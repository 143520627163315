import React, { useEffect, useState } from "react";
import { ExamsSelection, RequestExamsTable } from "./../index";
import * as LifeVet from "../../../../../components/index";
import { MESSAGES, REQUEST_EXAM_STATES } from "./../../../../../consts/index";
import { useFetch, ROUTES } from "../../../../../hooks/use-fetch";
import { RequestSample, RequestExam } from "./../../../../../types/index";
import __update from "immutability-helper";

export const ExamsSection = ({
  exams,
  samples,
  methods,
  errors,
  touched,
  handleChange,
  handleBlur,
  setError,
  loggedUserIsFromClinic,
  customerId,
  printExam,
  redirectToExam,
  disableAll,
  warningNotification,
  errorNotification,
  infoNotification,
}: {
  exams: Array<RequestExam>;
  samples: Array<RequestSample>;
  methods: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  setError: any;
  loggedUserIsFromClinic: boolean;
  customerId: number;
  printExam: any;
  redirectToExam: (exam: RequestExam) => void;
  disableAll: boolean;
  warningNotification: any;
  errorNotification: any;
  infoNotification: any;
}) => {
  const [examType, setExamType] = useState({ id: 0, name: "" });
  const [exam, setExam] = useState([]);

  const disableExamSelect = examType.id === 0;

  const fetchedExams = useFetch();

  const requestExamCanceling = useFetch();
  const cancelExam = (exam: RequestExam) => {
    const onSuccess = () => {
      MESSAGES.REQUEST_EXAM_CANCELLED_SUCCESSFULLY.message = `Exame ${exam.name} cancelado com sucesso`;
      warningNotification(MESSAGES.REQUEST_EXAM_CANCELLED_SUCCESSFULLY);
      exam.status = REQUEST_EXAM_STATES.CANCELLED;
      methods.exams.update(exam);
    };
    const onError = () => {
      MESSAGES.REQUEST_EXAM_CANCELLING_ERROR.message = `Não foi possível cancelar o exame ${exam.name}`;
      errorNotification(MESSAGES.REQUEST_EXAM_CANCELLING_ERROR);
    };
    requestExamCanceling.fetch(
      ROUTES.cancelRequestExam(exam.id),
      {
        method: "PUT",
      },
      onSuccess,
      onError
    );
    infoNotification(MESSAGES.PROCESSING_MESSAGE);
  };

  const createRequestExam = (labExam: any): RequestExam => {
    return {
      id: 0,
      name: labExam.name,
      price: labExam.price,
      deadlineInDays: labExam.deadline_in_days,
      obs: "",
      paymentMethod: undefined,
      isPaid: false,
      status: loggedUserIsFromClinic
        ? REQUEST_EXAM_STATES.PENDING
        : REQUEST_EXAM_STATES.IN_PROGRESS,
      startDate: "",
      attachmentId: 0,
      exam: {
        id: labExam.id,
        name: labExam.name,
        isOutsourced: labExam.is_outsourced,
        examType: {
          id: labExam.exam.exam_type.id,
          name: labExam.exam.exam_type.name,
        },
      },
      report: null,
      createdAt: "",
      updatedAt: "",
    };
  };

  const createRequestSample = (sample: any): RequestSample => {
    return { id: 0, code: "", name: sample.name, sampleId: sample.id };
  };

  const addExams = (selectedExams: any) => {
    const newSamples: Array<RequestSample> = [];

    const sampleAlreadyIncluded = (sampleId: number) => {
      const sampleAlreadyIncluded = methods.samples.getWithSampleId(sampleId);
      const sampleBeingIncluded = newSamples
        .filter((current) => current.sampleId === sampleId)
        .pop();

      if (sampleAlreadyIncluded || sampleBeingIncluded) return true;

      return false;
    };

    const examAlreadyIncluded = (examId: number) => {
      return exams.find((current: RequestExam) => current.exam.id === examId);
    };

    selectedExams.forEach((selectedExam: any) => {
      if (examAlreadyIncluded(selectedExam.id)) {
        MESSAGES.EXAM_ALREADY_INCLUDED_WARNING.message = `O exame ${selectedExam.name} já foi incluído.`;
        warningNotification(MESSAGES.EXAM_ALREADY_INCLUDED_WARNING);
        return;
      }
      const newRequestExam = createRequestExam(selectedExam);
      methods.exams.add([newRequestExam]);

      for (const sample of selectedExam.samples) {
        const newRequestSample = createRequestSample(sample);
        if (!sampleAlreadyIncluded(newRequestSample.sampleId))
          newSamples.push(newRequestSample);
      }
    });
    methods.samples.add(newSamples);
    setExam([]);
    setError("exams", "");
  };

  const removeRemainingSamples = () => {
    let remainingLabExamSamples: any = [];

    for (let exam of exams) {
      const labExam = fetchedExams.data
        .filter((fetchedExam: any) => {
          return fetchedExam.id === exam.exam.id;
        })
        .pop();
      if (labExam)
        remainingLabExamSamples = [
          ...remainingLabExamSamples,
          ...labExam.samples,
        ];
    }

    const samplesToBeRemoved = samples.filter((sample: RequestSample) => {
      let found = false;
      for (let labExamSample of remainingLabExamSamples) {
        if (sample.sampleId === labExamSample.id) {
          found = true;
          break;
        }
      }
      return !found;
    });

    samplesToBeRemoved.forEach((sample: RequestSample) =>
      methods.samples.remove(sample)
    );
  };

  useEffect(() => {
    fetchedExams.fulfilled && removeRemainingSamples();
  }, [exams, fetchedExams.fulfilled]);

  useEffect(() => {
    fetchedExams.fetch(ROUTES.getExams());
  }, []);

  return (
    <>
      <ExamsSelection
        examType={examType}
        exam={exam}
        setExamType={setExamType}
        setExam={setExam}
        addExams={addExams}
        disableExamSelect={disableExamSelect}
        disableAll={disableAll}
        customerId={customerId}
      />
      <RequestExamsTable
        data={exams}
        methods={methods.exams}
        handleChange={handleChange}
        handleBlur={handleBlur}
        loggedUserIsFromClinic={loggedUserIsFromClinic}
        printExam={printExam}
        redirectToExam={redirectToExam}
        cancelExam={cancelExam}
        disableAll={disableAll || fetchedExams.pending}
      />
      {touched && !Array.isArray(errors) && (
        <LifeVet.ErrorMessage>{errors}</LifeVet.ErrorMessage>
      )}
    </>
  );
};
