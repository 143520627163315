import React, { Component } from "react";

/**
 * External
 */
import { Switch, Route, Redirect } from "react-router-dom";
import Fader from "react-fader";
import { connect } from "react-redux";

/**
 * Internal
 */
import update from "immutability-helper";

import { ACCESS_PROFILES } from "./../../assets/js/Consts";
import AccessManager from "./../../old_components/AccessManager";
import Header from "./../../old_components/LVHeader";
import ExamRequestSection from "./../ExamRequestSection";
import LVSideBar from "./../../old_components/LVSideBar";
import AnimalSection from "./../AnimalSection";
import BreedSection from "./../BreedSection";
import Species from "./../species";
import UnitySection from "./../UnitySection";
import CustomerSection from "./../CustomerSection";
import OutsourcedExamsSection from "./../OutsourcedExamsSection";
import ExamSampleSection from "./../ExamSampleSection";
import SpeciesAndReferencesForm from "./../SpeciesAndReferencesForm";
import PricingTableSection from "./../PricingTableSection";
import NotFound from "./../NotFound";
import PaymentMethodSection from "../PaymentMethodSection";
import Invoices from "../../modules/invoices";
import DashboardSection from "../DashboardSection";
import TicketsSection from "./../../modules/tickets";
import ExamRecords from "../ExamRecords";
import GetUserTokenBasedOnEnv from "./../../services/get-user-token-based-on-env";
import EmailRecords from "../EmailRecords";
import LabRecords from "../LabRecords";
import { AntibioticSection } from "../AntibioticSection";
import { DailyOutsourcedExamsSection } from "../DailyOutsourcedExams";

class MainSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldDisplaySidebar: true,
      logUserOut: false,
    };
    this.resetEventsBlocked = false;
    this.resetTimeoutId = null;
  }

  setUpAuthenticationOptions = () => {
    if (this.props.user.logout_by_inactivity) {
      this.setUpInactivityTimer(this.props.user.inactivity_duration);
    }
  };

  setUpInactivityTimer = (inactivityDuration) => {
    this.setTimerEvents(inactivityDuration);
    this.resetTimer(inactivityDuration);
  };

  setTimerEvents = (inactivityDuration) => {
    const forwardEvent = () => {
      const unlockResetEvents = () =>
        setTimeout(() => (this.resetEventsBlocked = false), 700);

      if (!this.resetEventsBlocked) {
        this.resetEventsBlocked = true;
        unlockResetEvents();
        this.resetTimer(inactivityDuration);
      }
    };

    window.onmousemove = forwardEvent;
    window.onkeypress = forwardEvent;
    window.ontouchmove = forwardEvent;
  };

  setTimer = (inactivityDuration) =>
    setTimeout(() => this.logout(), inactivityDuration * 1000 * 60);

  resetTimer = (inactivityDuration) => {
    clearTimeout(this.resetTimeoutId);
    this.resetTimeoutId = this.setTimer(inactivityDuration);
  };

  logout = () => {
    if (this.checkToken()) {
      window.auth = null;
      window.localStorage.clear();
      this.setState({ logUserOut: true });
    }
  };

  checkToken = () => {
    let token = GetUserTokenBasedOnEnv();
    return token ? true : false;
  };

  /**
   * @author Victor Heringer
   *
   * Change the visibily state of sidebar
   */
  toggleSidebar = () => {
    this.setState((state) =>
      update(state, {
        shouldDisplaySidebar: {
          $set: !state.shouldDisplaySidebar,
        },
      })
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * Render the home route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderExamRequestSection = (routeProps) => {
    return (
      <ExamRequestSection
        {...routeProps}
        notificationSystem={this.props.notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * Render the animal route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderAnimalSection = (routeProps) => {
    return (
      <AnimalSection
        {...routeProps}
        notificationSystem={this.props.notificationSystem}
      />
    );
  };

  /**
   * @author Gabriel Cardoso
   *
   * Render the antibiotic route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */

  renderAntibioticSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[
          ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
        ]}
        notAllowedComponent={<NotFound />}
      >
        <AntibioticSection
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    )
  }

  /**
   * @author Victor Heringer
   *
   * Render the outsourced exams route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderOutsourcedExamsSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[
          ACCESS_PROFILES.UNIT_ADMINISTRATOR,
          ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
          ACCESS_PROFILES.UNIT_USER,
          ACCESS_PROFILES.CLINIC_USER,
          ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
        ]}
        notAllowedComponent={<NotFound />}
      >
        <OutsourcedExamsSection
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    );
  };

  /**
   * @author Victor Heringer
   *
   * Render the species normal values exams route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderSpeciesAndReferencesForm = (routeProps) => {
    return (
      <SpeciesAndReferencesForm
        {...routeProps}
        notificationSystem={this.props.notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * Render the exam request sample route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderExamSampleSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[
          ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
        ]}
        notAllowedComponent={<NotFound />}
      >
        <ExamSampleSection
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    );
  };

  /**
   * @author Victor Heringer
   *
   * Render the breed route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderBreedSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[
          ACCESS_PROFILES.UNIT_ADMINISTRATOR,
          ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
          ACCESS_PROFILES.UNIT_USER,
          ACCESS_PROFILES.CLINIC_USER,
          ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
        ]}
        notAllowedComponent={<NotFound />}
      >
        <BreedSection
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    );
  };

  /**
   * @author Sergio Pallet
   *
   * Render the Dashboard route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderDashboardSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[ACCESS_PROFILES.GENERAL_ADMINISTRATOR]}
        notAllowedComponent={<NotFound />}
      >
        <DashboardSection
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    );
  };

  renderExamRecordSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[ACCESS_PROFILES.UNIT_ADMINISTRATOR]}
        notAllowedComponent={<NotFound />}
      >
        <ExamRecords
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    );
  };

  renderLabRecordSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[ACCESS_PROFILES.UNIT_ADMINISTRATOR]}
        notAllowedComponent={<NotFound />}
      >
        <LabRecords
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    );
  };

  renderEmailRecordSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[ACCESS_PROFILES.UNIT_ADMINISTRATOR]}
        notAllowedComponent={<NotFound />}
      >
        <EmailRecords
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    );
  };

  renderOutsourcedExamsReportSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[ACCESS_PROFILES.UNIT_ADMINISTRATOR]}
      >
        <DailyOutsourcedExamsSection 
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
        </AccessManager>
      )
  }

  /**
   *
   * @author Anderson Souza <andersonsouza@protonmail.com>
   *
   * Render the Specie route
   *
   * @param {Object} routeProps Props for the route
   */

  renderSpecieSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[
          ACCESS_PROFILES.UNIT_ADMINISTRATOR,
          ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
          ACCESS_PROFILES.UNIT_USER,
          ACCESS_PROFILES.CLINIC_USER,
          ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
        ]}
        notAllowedComponent={<NotFound />}
      >
        <Species {...routeProps} />
      </AccessManager>
    );
  };

  /**
   * @author Victor Heringer
   *
   * Render the payment method route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderPaymentMethodSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[
          ACCESS_PROFILES.UNIT_ADMINISTRATOR,
          ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
        ]}
        notAllowedComponent={<NotFound />}
      >
        <PaymentMethodSection
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    );
  };

  /**
   * @author Victor Heringer
   *
   * Render the unity route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderUnitySection = (routeProps) => {
    return (
      <AccessManager
        allowed={[
          ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
          ACCESS_PROFILES.UNIT_ADMINISTRATOR,
          ACCESS_PROFILES.UNIT_USER,
        ]}
        notAllowedComponent={<NotFound />}
      >
        <UnitySection
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    );
  };

  renderInvoices = (routeProps) => {
    return (
      <AccessManager
        allowed={[
          ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
          ACCESS_PROFILES.UNIT_USER,
          ACCESS_PROFILES.UNIT_ADMINISTRATOR,
          ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
          ACCESS_PROFILES.CLINIC_USER,
        ]}
        notAllowedComponent={<NotFound />}
      >
        <Invoices
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    );
  };

  /**
   * @author Victor Heringer
   *
   * Render the customer route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderCustomerSection = (routeProps) => {
    return (
      <AccessManager
        allowed={[
          ACCESS_PROFILES.UNIT_USER,
          ACCESS_PROFILES.UNIT_ADMINISTRATOR,
          ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
          ACCESS_PROFILES.CLINIC_USER,
        ]}
        notAllowedComponent={<NotFound />}
      >
        <CustomerSection
          {...routeProps}
          notificationSystem={this.props.notificationSystem}
        />
      </AccessManager>
    );
  };

  renderTicketsSection = (routeProps) => {
    const lab = this.props.user.lab_id;
    return <TicketsSection lab={lab} {...routeProps} />;
  };

  /**
   * @author Victor Heringer
   *
   * Render pricing table
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderPricingTableSection = (routeProps) => {
    return (
      <PricingTableSection
        {...routeProps}
        notificationSystem={this.props.notificationSystem}
      />
    );
  };

  renderOldSystem = (routeProps) => {
    window.open("http://lifevet.jaamtech.com.br", "_blank");
    this.props.history.push("/");
  };

  render() {
    if (this.state.logUserOut) return <Redirect to="/login" />;

    /**
     * Get the data from state
     */
    const { shouldDisplaySidebar } = this.state;

    const hasSidebarClass = "col-lg-10 col-lg-offset-2 col-md-12 t-all";
    const hasntSidebarClass = "col-lg-12 t-all";

    return (
      <div
      // onClick={(event) => {
      //   event.stopPropagation();
      // }}
      // onSubmit={(event) => {
      //   event.stopPropagation();
      // }}
      >
        <Header
          barsAction={this.toggleSidebar}
          logout={this.props.logout}
          username={this.props.user.username}
          userProfile={ACCESS_PROFILES.toString(this.props.user.access_profile)}
          sidebarVisible={true}
          notificationSystem={this.props.notificationSystem}
        />
        <div className="container-fluid">
          <div className="row">
            <LVSideBar show={true} logout={this.props.logout} />
            <div className={hasSidebarClass}>
              <Switch compoment={Fader}>
                <Route
                  exact
                  path={"/"}
                  render={this.renderExamRequestSection}
                />
                <Route
                  path={"/solicitacoes-exame"}
                  render={this.renderExamRequestSection}
                />
                <Route
                  path={'/solicitacoes-listagem'}
                  render={this.renderExamRequestSection}
                />
                <Route path={"/animais"} render={this.renderAnimalSection} />
                <Route path={"/racas"} render={this.renderBreedSection} />
                <Route path={"/especies"} render={this.renderSpecieSection} />
                <Route path={"/antibioticos"} render={this.renderAntibioticSection} />
                <Route
                  path={"/forma-pagamento"}
                  render={this.renderPaymentMethodSection}
                />
                <Route
                  path={"/especies-valores"}
                  render={this.renderSpeciesAndReferencesForm}
                />
                <Route path={"/unidades"} render={this.renderUnitySection} />
                <Route path={"/clientes"} render={this.renderCustomerSection} />
                <Route
                  path={"/exame-terceirizado"}
                  render={this.renderOutsourcedExamsSection}
                />
                <Route path={"/financeiro"} render={this.renderInvoices} />
                <Route
                  path={"/exame-amostra"}
                  render={this.renderExamSampleSection}
                />
                <Route
                  path={"/tabela-preco"}
                  render={this.renderPricingTableSection}
                />
                <Route path={"/refresh"} render={this.renderRefresh} />
                <Route
                  path={"/dashboard"}
                  render={this.renderDashboardSection}
                />
                <Route
                  path={"/relatorios-exames-finalizados"}
                  render={this.renderExamRecordSection}
                />
                <Route
                  path={"/relatorios-unidades"}
                  render={this.renderLabRecordSection}
                />
                <Route
                  path={"/relatorios-emails-enviados"}
                  render={this.renderEmailRecordSection}
                />
                <Route
                  path={"/relatorios-exames-terceirizados"}
                  render={this.renderOutsourcedExamsReportSection}
                />
                <Route path={"/old-system"} render={this.renderOldSystem} />
                <Route path={"/tickets"} render={this.renderTicketsSection} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    is_authenticated: state.userReducer.is_authenticated,
  };
};

export default connect(mapStateToProps)(MainSection);
