import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as LifeVet from "../index";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faSync from "@fortawesome/fontawesome-free-solid/faSync";

const StyledIcon = styled.div`
  animation-name: rotate360;
  animation-iteration-count: infinite;
  animation-duration: ${(props) => (props.completed ? "2s" : 0)};
  animation-timing-function: linear;
  margin-left: 5px;
  float: right;
`;

const StyledButton = styled.button`
  min-width: 100px;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin: 5px;
  padding: 8px;
  outline: none;
  border: none;
  background-color: #0aafaa;
  border-radius: 6px;

  box-shadow: 0px 0px 2px black;
  &:hover {
    opacity: 0.85;
  }
`;

export const ButtonTimer = ({ goal, loop, callback, title, finishText }) => {
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const UPDATE_SIGNAL_DURATION = 3;

  function startTimer(timeout = 1000) {
    const id = setInterval(() => {
      setProgress((progress) => progress + 1);
    }, timeout);
    setIntervalId(id);
  }

  const resetTimer = () => clearInterval(intervalId);

  const restartTimer = () => {
    setProgress(0);
    resetTimer();
    startTimer();
  };

  const fireCallback = () => {
    callback();
    setProgress(goal);
    resetTimer();
    startTimer();
  };

  useEffect(() => {
    startTimer();
  }, []);

  useEffect(() => {
    if (progress >= goal + UPDATE_SIGNAL_DURATION) {
      callback();
      resetTimer();

      loop && restartTimer();
    }
  }, [progress]);

  const percentage = (progress / goal) * 100;
  const completed = percentage >= 100;
  return (
    <StyledButton title={title} onClick={() => fireCallback()}>
      {completed ? `${goal} / ${goal}` : `${progress} / ${goal}`}{" "}
      <StyledIcon completed={completed}>
        <FontAwesomeIcon size="lg" icon={faSync} styled />
      </StyledIcon>
    </StyledButton>
  );
};
