import {
  calcMCV,
  calcMCHC,
  erythrogramDiagnosis,
  plateletsDiagnosis,
} from "../../../hooks/hemogram/examFunctions";
import {
  calcAbsolute,
  leukogramDiagnosis,
} from "../../../hooks/hemogram/examFunctions/leukogramMethods";
import { hemogramState } from "../../../modules/factories/default-states";

export const hemogramDefaultState = () => {
  return hemogramState();
};

export function hemogramStateFactoryAPI(obj) {
  const isIntenseLeucopeny = obj.isIntenseLeucopeny ? true : false;
  const shouldBlockCounterSound = !isIntenseLeucopeny;

  const format = (value) => {
    const isZero = 0 === value;

    if (isZero) {
      return isIntenseLeucopeny ? "" : "0";
    }

    switch (typeof value) {
      case "string":
        return value.toString();
      case "number":
        return value;

      default:
        return "";
    }
  };

  const erythrocytes = format(obj.erythrocytes);

  const corpuscularVolume = format(obj.corpuscularVolume);

  const hemoglobiometry = format(obj.hemoglobiometry);

  const globalLeukometry = format(obj.globalLeukometry);

  const metarrubrycites = format(obj.metarrubrycites);

  const relativeBasophils = format(obj.relativeBasophils);

  const relativeEosinophils = format(obj.relativeEosinophils);

  const relativeMyelocytes = format(obj.relativeMyelocytes);

  const relativeMetamyelocytes = format(obj.relativeMetamyelocytes);

  const relativeBands = format(obj.relativeBands);

  const relativeSegs = format(obj.relativeSegs);

  const relativeLymphocytes = format(obj.relativeLymphocytes);

  const relativeMonocytes = format(obj.relativeMonocytes);

  const relativeHeterophils = format(obj.relativeHeterophils);

  const platelets = format(obj.platelets);

  return {
    erythrocytes: erythrocytes,
    corpuscularVolume: corpuscularVolume,
    hemoglobiometry: hemoglobiometry,
    erythrogramObs: obj.erythrogramObs,
    globalLeukometry: globalLeukometry,
    isIntenseLeucopeny: isIntenseLeucopeny,
    metarrubrycites: metarrubrycites,
    relativeBasophils: relativeBasophils,
    relativeEosinophils: relativeEosinophils,
    relativeMyelocytes: relativeMyelocytes,
    relativeMetamyelocytes: relativeMetamyelocytes,
    relativeBands: relativeBands,
    relativeSegs: relativeSegs,
    relativeLymphocytes: relativeLymphocytes,
    relativeMonocytes: relativeMonocytes,
    relativeHeterophils: relativeHeterophils,
    platelets: platelets,
    leukogramObs: obj.leukogramObs,
    plateletsObs: obj.plateletsObs,
    vetSignerId: obj.vetSignerId,
    requestExamState: obj.requestExamState,
    hemogramObs: obj.hemogramObs,
    shouldBlockCounterSound: shouldBlockCounterSound,
  };
}

export const hemogramFieldsToApiObjParser = (fields, refs) => {
  const { hemogramObs } = fields;

  return {
    ...plateletsFieldsToApiObjParser(fields, refs),
    ...herythogramFieldsToApiObjParser(fields, refs),
    ...leukogramFieldsToApiObjParser(fields, refs),
    hemogramObs: hemogramObs,
  };

  return {};
};

function plateletsFieldsToApiObjParser(fields, refs) {
  const { platelets, plateletsObs } = fields;
  return {
    plateletsObs: plateletsObs,
    platelets: platelets,
    plateletsDiagnosis: plateletsDiagnosis(platelets, refs),
  };
}

function leukogramFieldsToApiObjParser(fields, refs) {
  const {
    globalLeukometry,
    isIntenseLeucopeny,
    metarrubrycites,
    relativeBasophils,
    relativeEosinophils,
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    relativeSegs,
    relativeLymphocytes,
    relativeMonocytes,
    relativeHeterophils,
    leukogramObs,
  } = fields;

  const relBasophils = isIntenseLeucopeny ? 0 : relativeBasophils;
  const relEosinophils = isIntenseLeucopeny ? 0 : relativeEosinophils;
  const relLymphocytes = isIntenseLeucopeny ? 0 : relativeLymphocytes;
  const relMonocytes = isIntenseLeucopeny ? 0 : relativeMonocytes;
  const relHeterophils = isIntenseLeucopeny ? 0 : relativeHeterophils;
  const metarrub = isIntenseLeucopeny ? 0 : metarrubrycites;
  const relMyelocytes = isIntenseLeucopeny ? 0 : relativeMyelocytes;
  const relMetamyelocytes = isIntenseLeucopeny ? 0 : relativeMetamyelocytes;
  const relBands = isIntenseLeucopeny ? 0 : relativeBands;
  const relSegs = isIntenseLeucopeny ? 0 : relativeSegs;
  return {
    globalLeukometry: globalLeukometry,
    isIntenseLeucopeny: isIntenseLeucopeny ? true : false,
    metarrubrycites: metarrub,
    relativeBasophils: relBasophils,
    relativeEosinophils: relEosinophils,
    relativeMyelocytes: relMyelocytes,
    relativeMetamyelocytes: relMetamyelocytes,
    relativeBands: relBands,
    relativeSegs: relSegs,
    relativeLymphocytes: relLymphocytes,
    relativeMonocytes: relMonocytes,
    relativeHeterophils: relHeterophils,
    leukogramObs: leukogramObs,
    absoluteBasophils: calcAbsolute(relBasophils, {
      globalLeukometry,
      metarrubrycites:metarrub,
    }),
    absoluteEosinophils: calcAbsolute(relEosinophils, {
      globalLeukometry,
      metarrubrycites:metarrub,
    }),
    absoluteMyelocytes: calcAbsolute(relMyelocytes, {
      globalLeukometry,
      metarrubrycites:metarrub,
    }),
    absoluteMetamyelocytes: calcAbsolute(relMetamyelocytes, {
      globalLeukometry,
      metarrubrycites:metarrub,
    }),
    absoluteBands: calcAbsolute(relBands, { globalLeukometry, metarrubrycites:metarrub }),
    absoluteSegs: calcAbsolute(relSegs, { globalLeukometry, metarrubrycites:metarrub }),
    absoluteLymphocytes: calcAbsolute(relLymphocytes, {
      globalLeukometry,
      metarrubrycites:metarrub,
    }),
    absoluteMonocytes: calcAbsolute(relMonocytes, {
      globalLeukometry,
      metarrubrycites:metarrub,
    }),
    absoluteHeterophils: calcAbsolute(relHeterophils, {
      globalLeukometry,
      metarrubrycites:metarrub,
    }),
    leukogramDiagnosis: leukogramDiagnosis(fields, refs),
  };
}

function herythogramFieldsToApiObjParser(fields, refs) {
  const {
    erythrocytes,
    corpuscularVolume,
    hemoglobiometry,
    erythrogramObs,
    erythrogramDiagnosis,
  } = fields;

  const mcv = calcMCV({ corpuscularVolume, erythrocytes });
  const mchc = calcMCHC({ corpuscularVolume, hemoglobiometry });

  return {
    erythrocytes: erythrocytes,
    corpuscularVolume: corpuscularVolume,
    hemoglobiometry: hemoglobiometry,
    mcv: mcv,
    mchc: mchc,
    erythrogramDiagnosis: erythrogramDiagnosis,
    erythrogramObs: erythrogramObs,
  };
}
