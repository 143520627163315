import React, { useState, useEffect } from "react";

import { SELECT_TEXT } from "../../../../../assets/js/Consts.js";
import * as LifeVet from "../../../../../components";
import { Customer, Vet } from "./../../../../../types/index";
import { VetConversor } from "./../../../../../services/request-conversor/old-to-new";

export const VetSelect = ({
  data,
  errors,
  touched,
  handleChange,
  handleBlur,
  disable,
  isReadOnly,
  vetsFetching,
}: {
  data: Vet;
  methods: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  disable: boolean;
  isReadOnly: boolean;
  vetsFetching: any;
}) => {
  const defaultRef: any = null;
  const [ref, setRef] = useState(defaultRef);

  let options: any = vetsFetching.fulfilled ? vetsFetching.data : [];

  useEffect(() => {
    if (vetsFetching.fulfilled) ref && ref.focus();
  }, [vetsFetching.fulfilled]);

  errors = errors && (errors.id || errors.name);

  return (
    <LifeVet.FormGroup noError>
      <LifeVet.InputLabel hasError={touched && errors}>
        Médico Vet *
      </LifeVet.InputLabel>
      <LifeVet.Select
        options={options}
        value={data.id}
        searchable={true}
        disabled={disable || isReadOnly}
        theValueIsAnId={true}
        placeholder={
          vetsFetching.pending ? SELECT_TEXT.LOADING : SELECT_TEXT.ACTIVE
        }
        onChange={(option: any) => handleChange("vet", VetConversor(option))}
        onBlur={() => handleBlur("vet")}
        setRef={(ref) => setRef(ref)}
        onKeyDown
        errorMessage={touched && errors}
      />
    </LifeVet.FormGroup>
  );
};
