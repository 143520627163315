import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import MicrofilariaeResearchContent from "./../MicrofilariaeResearchContent";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col } from "./../../components/grid";

const MicrofilariaeResearchReportFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Pesquisa de Microfilária"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Col md={12}>
      <ReportHeader
        data={data.reportHeaderData}
        fields={data.fields}
        fieldChange={methods.fieldChange}
        $field={methods.$field}
        $validation={data.$validation}
      />
    </Col>
    <Col md={12}>
      <MicrofilariaeResearchContent
        title="Pesquisa de Microfilária"
        resultFieldName={"result"}
        result={data.result}
        resultHasValidation={data.$validation.result.show}
        resultValidationReason={data.$validation.result.error.reason}
        obsFieldName={"obs"}
        obs={data.fields.obs}
        obsHasValidation={data.$validation.obs.show}
        obsValidationReason={data.$validation.obs.error.reason}
        handleChange={methods.handleChange}
        handleBlur={methods.handleBlur}
        shouldDisable={data.disableAll}
        checkboxes={data.checkboxes}
        multiCheckboxChange={methods.multiBoxChange}
        checkdState={data.checkState}
        handleResultChange={methods.handleCheckedResultChange}
      />
    </Col>
  </ReportPanelMaterial>
);

export default MicrofilariaeResearchReportFormView;
