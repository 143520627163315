/*


  THIS FILE MUST BE ELIMINATED!!!
  ALL CONSTS SHALL BE PLACED IN /CONSTS.

  @ANDERSON

*/

/**
 * External
 */
import { MASKS } from "./../../consts";

/**
 * @var {Number}
 */
export const FIVE_SECONDS = 5;

/**
 * @var {Number}
 */
export const SUBMIT_MODAL_KEY = 13;

/**
 * @var {Object}
 */
export const SEX = { M: 1, F: 2, U: 3 };

export const AGE = { N: 1, A: 2, I: 0 };

/**
 * @var {Array}
 */
export const STATUS = [
  { id: 0, name: "INATIVO" },
  { id: 1, name: "ATIVO" },
];

/**
 * @var {Array}
 */
export const PERSON_TYPES = [
  { id: 0, name: "PESSOA JURÍDICA" },
  { id: 1, name: "PESSOA FÍSICA" },
];

/**
 * @var {Array}
 */
export const YES_OR_NO = [
  { id: 0, name: "NÃO" },
  { id: 1, name: "SIM" },
];

/**
 * @var {Array}
 */
export const USER_TYPE = [
  { id: 0, name: "RESPONSÁVEL" },
  { id: 1, name: "VETERINÁRIO" },
  { id: 2, name: "RECEPCIONISTA" },
  { id: 3, name: "ADMINISTRATIVO" },
  { id: 4, name: "FINANCEIRO" },
  { id: 5, name: "AUXILIAR" },
  { id: 6, name: "PLANTONISTA" },
  { id: 7, name: "ESPECIALISTA" },
];

/*


  THIS FILE MUST BE ELIMINATED!!!
  ALL CONSTS SHALL BE PLACED IN /CONSTS.

  @ANDERSON

*/

/**
 * @var {Array}
 */
export const PAYMENT_FREQUENCE = [
  { id: 0, name: "SEMANAL" },
  { id: 1, name: "QUINZENAL" },
  { id: 2, name: "MENSAL" },
];

/**
 * @var {Object}
 */
export const EXAM_URL_BY_ID = function (id) {
  switch (id) {
    case EXAM_ID_BY_NAME["BABESIA"]:
      return "babesia";
    case EXAM_ID_BY_NAME["QUALITATIVE_ANAPLASM_PCR_REAL_TIME"]:
      return "anaplasma-qualitativo-pcr-real-time";
    case EXAM_ID_BY_NAME["QUALITATIVE_FIV_PCR_REAL_TIME"]:
      return "fiv-qualitativo-pcr-real-time";
    case EXAM_ID_BY_NAME["QUALITATIVE_FELV_PCR_REAL_TIME"]:
      return "felv-qualitativo-pcr-real-time";
    case EXAM_ID_BY_NAME["QUALITATIVE_MYCOPLASM_PCR_REAL_TIME"]:
      return "mycoplasma-qualitativo-pcr-real-time";
    case EXAM_ID_BY_NAME["DISTEMPER"]:
      return "cinomose";
    case EXAM_ID_BY_NAME["EHRLICHIA"]:
      return "ehrlichia";
    case EXAM_ID_BY_NAME["LEISHMANIOSE"]:
      return "leishmaniose";
    case EXAM_ID_BY_NAME["LEPTOSPIROSE"]:
      return "leptospirose";
    case EXAM_ID_BY_NAME["FIBRINOGEN"]:
      return "fibrinogenio";
    case EXAM_ID_BY_NAME["ASCITIC_FLUID"]:
      return "analise-liquido-ascitico";
    case EXAM_ID_BY_NAME["THORACIC_FLUID"]:
      return "analise-liquido-toracico";
    case EXAM_ID_BY_NAME["PHENOBARBITAL"]:
      return "fenobarbital";
    case EXAM_ID_BY_NAME["PLATELETS"]:
      return "contagem-plaquetas";
    case EXAM_ID_BY_NAME["RETICULOCYTE_COUNT"]:
      return "contagem-reticulocitos";
    case EXAM_ID_BY_NAME["ERYTHROGRAM"]:
      return "eritrograma";
    case EXAM_ID_BY_NAME["CORPUSCULAR_VOLUME"]:
      return "hematocrito";
    case EXAM_ID_BY_NAME["HEMOGRAM"]:
      return "hemograma";
    case EXAM_ID_BY_NAME["HEMOGRAM_WITH_HEMOPARASITOLOGICAL_RESEARCH"]:
      return "hemograma-pesquisa-hemoparasitologica";
    case EXAM_ID_BY_NAME["HEMOGRAM_WITH_HEMOCYTOZOA_RESEARCH"]:
      return "hemograma-pesquisa-hemocitozoarios";
    case EXAM_ID_BY_NAME["HEMOGRAM_WITH_BUFFY_COAT_RESEARCH"]:
      return "hemograma-pesquisa-em-capa-leucocitaria";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_COMPLETE_2"]:
      return "perfil-preoperatorio-completo-2";
    case EXAM_ID_BY_NAME["PROFILE_ENDOCRINOLOGICAL"]:
      return "perfil-endocrinologico";
    case EXAM_ID_BY_NAME["PROFILE_ROUTINE_3"]:
      return "perfil-rotina-3";
    case EXAM_ID_BY_NAME["PROFILE_ONCOLOGICAL"]:
      return "perfil-oncologico";
    case EXAM_ID_BY_NAME["LEUKOGRAM"]:
      return "leukograma";
    case EXAM_ID_BY_NAME["LENTZ_CORPUSCLE_RESEARCH"]:
      return "pesquisa-corpusculo-lentz";
    case EXAM_ID_BY_NAME["HEMOCYTOZOA_RESEARCH"]:
      return "pesquisa-hemocitozoarios";
    case EXAM_ID_BY_NAME["MICROFILARIAE_RESEARCH"]:
      return "pesquisa-microfilaria";
    case EXAM_ID_BY_NAME["HEMOCYTOZOA_SERIAL_RESEARCH"]:
      return "pesquisa-seriada-hemocitozoarios";
    case EXAM_ID_BY_NAME["COAGULATION_TEST"]:
      return "teste-coagulacao";
    case EXAM_ID_BY_NAME["CROSS_MATCH"]:
      return "teste-compatibilidade-sanguinea";
    case EXAM_ID_BY_NAME["FOUR_DX"]:
      return "4dx";
    case EXAM_ID_BY_NAME["DISTEMPER_PARVOVIRUS_IGG"]:
      return "cinomose-parvovirose-igg";
    case EXAM_ID_BY_NAME["DISTEMPER_PARVOVIRUS_IGM"]:
      return "cinomose-parvovirose-igm";
    case EXAM_ID_BY_NAME["HEARTWORM_DISEASE"]:
      return "dirofilariose";
    case EXAM_ID_BY_NAME["EHRLICHIA_CANIS"]:
      return "ehrlichia-canis";
    case EXAM_ID_BY_NAME["FIV_FELV"]:
      return "fiv-felv";
    case EXAM_ID_BY_NAME["GIARDIA"]:
      return "giardia";
    case EXAM_ID_BY_NAME["IGA"]:
      return "imunoglobulina-a";
    case EXAM_ID_BY_NAME["PIF"]:
      return "pif";
    case EXAM_ID_BY_NAME["COPROCULTURE"]:
      return "cultura-de-fezes";
    case EXAM_ID_BY_NAME["FUNGAL_CULTURE"]:
      return "cultura-fungos";
    case EXAM_ID_BY_NAME["CULTURE_ANTIBIOGRAM_AEROBIC"]:
      return "cultura-antibiograma-aerobios";
    case EXAM_ID_BY_NAME["CULTURE_ANTIBIOGRAM_ANAEROBIC"]:
      return "cultura-antibiograma-anaerobios";
    case EXAM_ID_BY_NAME["HEMOCULTURE"]:
      return "hemocultura";
    case EXAM_ID_BY_NAME["IMMUNE_MEDIATED_HEMOLYTIC_ANEMIA"]:
      return "anemia-hemolitica-imunomediada";
    case EXAM_ID_BY_NAME["DIRECT_MYCOLOGICAL_RESEARCH"]:
      return "micologico-direto";
    case EXAM_ID_BY_NAME["MALASSEZIA_RESEARCH"]:
      return "pesquisa-malassezia-sp";
    case EXAM_ID_BY_NAME["SPOROTHRIX_SCHENCKII"]:
      return "pesquisa-sporothrix-schenckii";
    case EXAM_ID_BY_NAME["UROCULTURE"]:
      return "urocultura";
    case EXAM_ID_BY_NAME["PARASITOLOGICAL_FECES_MIF"]:
      return "parasitologico-fezes-mif";
    case EXAM_ID_BY_NAME["PARASITOLOGICAL_FRESH_FECES"]:
      return "parasitologico-fezes-frescas";
    case EXAM_ID_BY_NAME["PARASITOLOGICAL_FECES_OPG"]:
      return "parasitologico-fezes-opg";
    case EXAM_ID_BY_NAME["ECTOPARASITES_RESEARCH"]:
      return "pesquisa-ectoparasitas";
    case EXAM_ID_BY_NAME["URINARY_BILIARY_CALCULATION"]:
      return "analise-calculo-urinario-biliar";
    case EXAM_ID_BY_NAME["EAS"]:
      return "eas";
    case EXAM_ID_BY_NAME["MICROALBUMINURIA"]:
      return "microalbuminuria";
    case EXAM_ID_BY_NAME["CORTISOL_CREATININE_RATIO"]:
      return "relacao-cortisol-creatinina";
    case EXAM_ID_BY_NAME["PROTEIN_CREATININE_RATIO"]:
      return "relacao-proteina-creatinina";
    case EXAM_ID_BY_NAME["HEMOGRAM_WITH_MICROFILARIAE_RESEARCH"]:
      return "hemograma-pesquisa-microfilaria";
    case EXAM_ID_BY_NAME["BLOOD_PROTEIN"]:
      return "proteinas-plasmaticas";
    case EXAM_ID_BY_NAME["PARVO_CORONA"]:
      return "parvovirus-coronavirus";
    case EXAM_ID_BY_NAME["URINARY_GGT"]:
      return "ggt-urinaria";
    case EXAM_ID_BY_NAME["CYTOLOGY"]:
      return "citologia";
    case EXAM_ID_BY_NAME["LIQUOR_ANALYSIS"]:
      return "analise-liquor";
    case EXAM_ID_BY_NAME["PROFILE_SMALL_ANIMALS"]:
      return "perfil-veterinario-animais-pequenos";
    case EXAM_ID_BY_NAME["PROFILE_LARGE_ANIMALS"]:
      return "perfil-veterinario-animais-grandes";
    case EXAM_ID_BY_NAME["PROFILE_SIMPLE"]:
      return "perfil-simples";
    case EXAM_ID_BY_NAME["PROFILE_PARASITOLOGICAL_MONITORING"]:
      return "perfil-acompanhamento-parasitologico";
    case EXAM_ID_BY_NAME["PROFILE_DOG_OBESITY_CHECK"]:
      return "perfil-cao-obeso";
    case EXAM_ID_BY_NAME["PROFILE_ELECTROLYTIC"]:
      return "perfil-eletrolitico";
    case EXAM_ID_BY_NAME["PROFILE_GERIATRIC"]:
      return "perfil-geriatrico";
    case EXAM_ID_BY_NAME["PROFILE_HEPATIC_SIMPLE"]:
      return "perfil-hepatico-simples";
    case EXAM_ID_BY_NAME["PROFILE_HEPATIC_COMPLETE"]:
      return "perfil-hepatico-completo";
    case EXAM_ID_BY_NAME["PROFILE_BONE_CHECK"]:
      return "perfil-osseo";
    case EXAM_ID_BY_NAME["PROFILE_PANCREATIC"]:
      return "perfil-pancreatico";
    case EXAM_ID_BY_NAME["PROFILE_ROUTINE"]:
      return "perfil-rotina";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_SIMPLE"]:
      return "perfil-pre-operatorio-simples";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_COMPLETE"]:
      return "perfil-pre-operatorio-completo";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_CANINE"]:
      return "perfil-pre-operatorio-canino";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_CANINE_2"]:
      return "perfil-pre-operatorio-canino-2";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_FELINE"]:
      return "perfil-pre-operatorio-felino";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_FELINE_2"]:
      return "perfil-pre-operatorio-felino-2";
    case EXAM_ID_BY_NAME["PROFILE_RENAL"]:
      return "perfil-renal";
    case EXAM_ID_BY_NAME["PROFILE_RENAL_2"]:
      return "perfil-renal-2";
    case EXAM_ID_BY_NAME["PROFILE_RENAL_3"]:
      return "perfil-renal-3";
    case EXAM_ID_BY_NAME["PROFILE_THYROID"]:
      return "perfil-tireoidiano";
    case EXAM_ID_BY_NAME["PROFILE_CARDIAC"]:
      return "perfil-cardiaco";
    case EXAM_ID_BY_NAME["PROFILE_SURGICAL"]:
      return "perfil-cirurgico";
    case EXAM_ID_BY_NAME["PROFILE_GASTROENTERITIS_BRONZE"]:
      return "perfil-gastroenterite-bronze";
    case EXAM_ID_BY_NAME["PROFILE_GASTROENTERITIS_SILVER"]:
      return "perfil-gastroenterite-prata";
    case EXAM_ID_BY_NAME["PROFILE_GASTROENTERITIS_GOLD"]:
      return "perfil-gastroenterite-ouro";
    case EXAM_ID_BY_NAME["PROFILE_HEMOPARASITES_BRONZE"]:
      return "perfil-hemoparasita-bronze";
    case EXAM_ID_BY_NAME["PROFILE_HEMOPARASITES_SILVER"]:
      return "perfil-hemoparasita-prata";
    case EXAM_ID_BY_NAME["PROFILE_HEMOPARASITES_GOLD"]:
      return "perfil-hemoparasita-ouro";
    case EXAM_ID_BY_NAME["PROFILE_CHECKUP"]:
      return "perfil-checkup";
    case EXAM_ID_BY_NAME["PROFILE_OBESITY"]:
      return "perfil-obeso";
    case EXAM_ID_BY_NAME["PROFILE_MORE_COMPLETE_HEPATIC"]:
      return "perfil-hepatico-mais-completo";
    case EXAM_ID_BY_NAME["PROFILE_COMPLETE_PARASITOLOGICAL_MONITORING"]:
      return "perfil-acompanhamento-parasitologico-completo";
    default:
      return "terceirizado";
  }
};

/*


  THIS FILE MUST BE ELIMINATED!!!
  ALL CONSTS SHALL BE PLACED IN /CONSTS.

  @ANDERSON

*/

/**
 * @var {Object}
 */
export const EXAM_ID_BY_NAME = {
  BABESIA: 1,
  QUALITATIVE_ANAPLASM_PCR_REAL_TIME: 500,
  QUALITATIVE_FIV_PCR_REAL_TIME: 501,
  QUALITATIVE_FELV_PCR_REAL_TIME: 502,
  QUALITATIVE_MYCOPLASM_PCR_REAL_TIME: 503,
  DISTEMPER: 2,
  EHRLICHIA: 3,
  LEISHMANIOSE: 4,
  LEPTOSPIROSE: 5,
  URIC_ACID: 6,
  ALBUMIN: 7,
  ALT_TGP: 8,
  AMYLASE: 9,
  AST_TGO: 10,
  BILIRUBIN_AND_FRACTIONS: 11,
  CALCIUM: 12,
  IONIC_CALCIUM: 13,
  CHLORIDE: 14,
  TOTAL_CHOLESTEROL: 15,
  TOTAL_CHOLESTEROL_AND_FRACTIONS: 16,
  CREATININE: 17,
  CPK: 18,
  UREA: 19,
  IRON: 20,
  ALKALINE_PHOSPHATASE: 21,
  PHOSPHORUS: 22,
  FRUCTOSAMINE: 23,
  GGT: 24,
  GLUCOSE: 25,
  GLYCATED_HEMOGLOBIN: 26,
  LACTATE: 27,
  LIPASE: 28,
  IMMUNOREACTIVE_LIPASE: 29,
  MAGNESIUM: 30,
  PH: 31,
  POTASSIUM: 32,
  TOTAL_PROTEIN: 33,
  TOTAL_PROTEIN_AND_FRACTIONS: 34,
  SODIUM: 35,
  TRIGLYCERIDES: 36,
  FIBRINOGEN: 37,
  ASCITIC_FLUID: 38,
  THORACIC_FLUID: 39,
  CHEMILUMINESCENCE_HYPERSENSITIVE_ACTH: 40,
  CHEMILUMINESCENCE_CORTISOL: 41,
  CHEMILUMINESCENCE_CORTISOL_4H_AFTER_DEXAMETHASONE: 42,
  CHEMILUMINESCENCE_CORTISOL_8H_AFTER_DEXAMETHASONE: 43,
  CHEMILUMINESCENCE_CORTISOL_4H_AFTER_ACTH_STIMULI: 44,
  CHEMILUMINESCENCE_ERYTHROPOETIN: 45,
  CHEMILUMINESCENCE_ESTRADIOL: 46,
  CHEMILUMINESCENCE_PHENOBARBITAL: 47,
  CHEMILUMINESCENCE_HFS: 48,
  CHEMILUMINESCENCE_INSULIN: 49,
  CHEMILUMINESCENCE_LH: 50,
  CHEMILUMINESCENCE_PROGESTERONE: 51,
  CHEMILUMINESCENCE_PTH: 52,
  CHEMILUMINESCENCE_TOTAL_T3: 53,
  CHEMILUMINESCENCE_FREE_T4: 54,
  CHEMILUMINESCENCE_TOTAL_T4: 55,
  CHEMILUMINESCENCE_LEVOTHYROXIN_AFTER_TOTAL_T4: 56,
  CHEMILUMINESCENCE_TESTOSTERONE: 57,
  CHEMILUMINESCENCE_TSH: 58,
  RADIOIMMUNOASSAY_CORTISOL: 59,
  RADIOIMMUNOASSAY_CORTISOL_4H_AFTER_DEXAMETHASONE: 60,
  RADIOIMMUNOASSAY_CORTISOL_8H_AFTER_DEXAMETHASONE: 61,
  RADIOIMMUNOASSAY_CORTISOL_4H_AFTER_ACTH_STIMULI: 62,
  RADIOIMMUNOASSAY_ESTRADIOL: 63,
  RADIOIMMUNOASSAY_TOTAL_T3: 64,
  RADIOIMMUNOASSAY_FREE_T4: 65,
  RADIOIMMUNOASSAY_TOTAL_T4: 66,
  RADIOIMMUNOASSAY_LEVOTHYROXIN_AFTER_TOTAL_T4: 67,
  RADIOIMMUNOASSAY_FREE_T4_AFTER_DIALYSIS: 68,
  PLATELETS: 69,
  RETICULOCYTE_COUNT: 70,
  ERYTHROGRAM: 71,
  CORPUSCULAR_VOLUME: 72,
  HEMOGRAM: 73,
  HEMOGRAM_WITH_HEMOPARASITOLOGICAL_RESEARCH: 74,
  HEMOGRAM_WITH_HEMOCYTOZOA_RESEARCH: 75,
  HEMOGRAM_WITH_BUFFY_COAT_RESEARCH: 621,
  PROFILE_PREOPERATIVE_COMPLETE_2: 622,
  PROFILE_ENDOCRINOLOGICAL: 623,
  PROFILE_ROUTINE_3: 628,
  PROFILE_ONCOLOGICAL: 629,
  LEUKOGRAM: 76,
  LENTZ_CORPUSCLE_RESEARCH: 77,
  HEMOCYTOZOA_RESEARCH: 78,
  MICROFILARIAE_RESEARCH: 79,
  HEMOCYTOZOA_SERIAL_RESEARCH: 80,
  COAGULATION_TEST: 81,
  CROSS_MATCH: 82,
  IMMUNE_MEDIATED_HEMOLYTIC_ANEMIA: 83,
  FOUR_DX: 84,
  DISTEMPER_PARVOVIRUS_IGG: 85,
  DISTEMPER_PARVOVIRUS_IGM: 86,
  HEARTWORM_DISEASE: 87,
  EHRLICHIA_CANIS: 88,
  GIARDIA: 89,
  FIV_FELV: 90,
  IGA: 91,
  PIF: 92,
  COPROCULTURE: 93,
  FUNGAL_CULTURE: 94,
  CULTURE_ANTIBIOGRAM_AEROBIC: 95,
  CULTURE_ANTIBIOGRAM_ANAEROBIC: 96,
  HEMOCULTURE: 97,
  DIRECT_MYCOLOGICAL_RESEARCH: 98,
  MALASSEZIA_RESEARCH: 99,
  SPOROTHRIX_SCHENCKII: 100,
  UROCULTURE: 101,
  PARASITOLOGICAL_FECES_MIF: 102,
  PARASITOLOGICAL_FRESH_FECES: 103,
  PARASITOLOGICAL_FECES_OPG: 104,
  ECTOPARASITES_RESEARCH: 105,
  URINARY_BILIARY_CALCULATION: 106,
  EAS: 107,
  MICROALBUMINURIA: 108,
  CORTISOL_CREATININE_RATIO: 109,
  PROTEIN_CREATININE_RATIO: 110,
  LDH: 111,
  TLI: 112,
  HEMOGRAM_WITH_MICROFILARIAE_RESEARCH: 113,
  BLOOD_PROTEIN: 114,
  PARVO_CORONA: 115,
  URINARY_GGT: 116,
  CYTOLOGY: 117,
  LIQUOR_ANALYSIS: 118,
  PROFILE_SIMPLE: 119,
  PROFILE_PARASITOLOGICAL_MONITORING: 120,
  PROFILE_DOG_OBESITY_CHECK: 121,
  PROFILE_ELECTROLYTIC: 122,
  PROFILE_GERIATRIC: 123,
  PROFILE_HEPATIC_SIMPLE: 124,
  PROFILE_HEPATIC_COMPLETE: 125,
  PROFILE_BONE_CHECK: 126,
  PROFILE_PANCREATIC: 127,
  PROFILE_CARDIAC: 128,
  PROFILE_SURGICAL: 129,
  PROFILE_GASTROENTERITIS_BRONZE: 130,
  PROFILE_GASTROENTERITIS_SILVER: 131,
  PROFILE_GASTROENTERITIS_GOLD: 132,
  PROFILE_ROUTINE: 133,
  PROFILE_HEMOPARASITES_BRONZE: 134,
  PROFILE_HEMOPARASITES_SILVER: 135,
  PROFILE_HEMOPARASITES_GOLD: 136,
  PROFILE_SMALL_ANIMALS: 137,
  PROFILE_LARGE_ANIMALS: 138,
  PROFILE_CHECKUP: 139,
  PROFILE_PREOPERATIVE_SIMPLE: 140,
  PROFILE_PREOPERATIVE_COMPLETE: 141,
  PROFILE_PREOPERATIVE_CANINE: 142,
  PROFILE_PREOPERATIVE_CANINE_2: 143,
  PROFILE_PREOPERATIVE_FELINE: 144,
  PROFILE_PREOPERATIVE_FELINE_2: 145,
  PROFILE_RENAL: 146,
  PROFILE_RENAL_2: 147,
  PROFILE_RENAL_3: 148,
  PROFILE_THYROID: 149,
  BIOCHEMISTRY_OBS: 150,
  ELECTROLYTES: 284,
  PROFILE_OBESITY: 485,
  PROFILE_MORE_COMPLETE_HEPATIC: 486,
  PROFILE_COMPLETE_PARASITOLOGICAL_MONITORING: 487,
  RELATION_ALBUMIN_GLOBULIN: 600,
  PROFILE_CHECKUP2: 601,
};

/*


  THIS FILE MUST BE ELIMINATED!!!
  ALL CONSTS SHALL BE PLACED IN /CONSTS.

  @ANDERSON

*/

/**
 * @var {Object}
 */
export const REQUEST_EXAM_STATE_ID_BY_NAME = {
  AGUARDANDO_RECEBIMENTO: 2,
  RECEBIDO_EM_ANDAMENTO: 3,
  ATENDIDO_E_NAO_LIBERADO: 4,
  PARADO_POR_PROBLEMAS: 5,
  CANCELADO: 6,
  ATENDIDO_E_LIBERADO: 7,
  FATURADO: 8,
};

export const EXAMS_TYPES = [
  { id: 0, name: "Hematologia" },
  { id: 1, name: "Bioquímica" },
];

/**
 * @var {Object}
 */
export const EXAM_TYPE_ID_BY_NAME = {
  BIOCHEMISTRY: 2,
  ENDOCRINOLOGY_CHEMILUMINESCENCE: 5,
  ENDOCRINOLOGY_RADIOIMMUNOASSAY: 6,
};

/**
 * @var {Object}
 */
export const EXAM_TYPE_BY_ID = {
  2: "bioquimica",
  5: "endocrinologia-quimioluminescencia",
  6: "endocrinologia-radioimunoensaio",
};

/**
 * @var {Array}
 */
export const PHONE_MASK = MASKS.PHONE;

/**
 * @var {Array}
 */
export const CEP_MASK = MASKS.CEP;

/**
 * @var {Array}
 */
export const CNPJ_MASK = MASKS.CNPJ;

/**
 * @var {Array}
 */
export const CPF_MASK = MASKS.CPF;

/**
 * @var {Function}
 */
export const EMAIL_MASK = MASKS.EMAIL;

/**
 * @var {string}
 */
export const LOADING_TEXT = "Carregando..";

/**
 * @var {string}
 */
export const DONE_TEXT = "Selecione";

/**
 * @var {string}
 */
export const BTN_SAVE_TITLE = "Salvar";

/*


  THIS FILE MUST BE ELIMINATED!!!
  ALL CONSTS SHALL BE PLACED IN /CONSTS.

  @ANDERSON

*/

/**
 * @var {string}
 */
export const BTN_UPDATE_TITLE = "Atualizar";

/**
 * @var {string}
 */
export const BTN_SAVING_TITLE = "Salvando..";

/**
 * @var {string}
 */
export const BTN_UPDATING_TITLE = "Atualizando..";

/**
 * @var {Number}
 */
export const ZERO = 0;

/**
 * @var {Number}
 */
export const TEN = 10;

/**
 * @var {Number}
 */
export const A_HUNDRED = 100;

/**
 * @var {Number}
 */
export const A_HUNDRED_AND_EIGHTY = 180;

/**
 * @var {function}
 */
export const isIntegerBiochemExam = (examId) => {
  switch (examId) {
    case EXAM_ID_BY_NAME["ALT_TGP"]: // ALT (TGP)
    case EXAM_ID_BY_NAME["AMYLASE"]: // Amilase
    case EXAM_ID_BY_NAME["AST_TGO"]: // AST TGO
    case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL"]: // Colesterol Total
    case EXAM_ID_BY_NAME["CPK"]: // CPK
    case EXAM_ID_BY_NAME["ALKALINE_PHOSPHATASE"]: // Fosfatase Alcalina
    case EXAM_ID_BY_NAME["FRUCTOSAMINE"]: // Frutosamina
    case EXAM_ID_BY_NAME["GLUCOSE"]: // Glicose
    case EXAM_ID_BY_NAME["LIPASE"]: // Lipase
    case EXAM_ID_BY_NAME["TRIGLYCERIDES"]: // Triglicerídeos
    case EXAM_ID_BY_NAME["SODIUM"]: // Sódio
    case EXAM_ID_BY_NAME["IMMUNOREACTIVE_LIPASE"]: // Lipase imuno reativa
    case EXAM_ID_BY_NAME["CHLORIDE"]: // Cloreto
    case EXAM_ID_BY_NAME["IRON"]: // Ferro
    case EXAM_ID_BY_NAME["LACTATE"]: // Lactato
    case EXAM_ID_BY_NAME["LACTATE"]: // HDL
      return true;
    default:
      return false;
  }
};
/**
 * @author Sergio Pallet
 *
 * quantity of decimal plates in the input of the exam
 *
 * @var {examId}
 */
export const qttDecimalPlates = (examId) => {
  const examsOneDecimalPlate = [
    EXAM_ID_BY_NAME["UREA"],
    EXAM_ID_BY_NAME["CREATININE"],
    EXAM_ID_BY_NAME["GGT"],
    EXAM_ID_BY_NAME["TOTAL_PROTEIN"],
    EXAM_ID_BY_NAME["ALBUMIN"],
    EXAM_ID_BY_NAME["POTASSIUM"],
    EXAM_ID_BY_NAME["PHOSPHORUS"],
    EXAM_ID_BY_NAME["CALCIUM"],
    EXAM_ID_BY_NAME["MAGNESIUM"],
    EXAM_ID_BY_NAME["IONIC_CALCIUM"],
    EXAM_ID_BY_NAME["GLYCATED_HEMOGLOBIN"],
    EXAM_ID_BY_NAME["TLI"],
  ];

  return examsOneDecimalPlate.includes(examId) ? 1 : 2;
};

/*


  THIS FILE MUST BE ELIMINATED!!!
  ALL CONSTS SHALL BE PLACED IN /CONSTS.

  @ANDERSON

*/

/**
 * @var {Object}
 */
export const KEY_TO_FIELD = {
  a: "relativeMetamyelocytes",
  A: "relativeMetamyelocytes",
  s: "relativeMyelocytes",
  S: "relativeMyelocytes",
  d: "relativeEosinophils",
  D: "relativeEosinophils",
  f: "relativeBands",
  F: "relativeBands",
  g: "relativeSegs",
  G: "relativeSegs",
  h: "relativeLymphocytes",
  H: "relativeLymphocytes",
  j: "relativeMonocytes",
  J: "relativeMonocytes",
  k: "relativeHeterophils",
  K: "relativeHeterophils",
  l: "relativeBasophils",
  L: "relativeBasophils",
  m: "metarrubrycites",
  M: "metarrubrycites",
  Enter: "totalCounter",
};

export const IMMUNOLOGY_GIARDIA_EXAM_METHODS = [
  { id: '.', name: " " },
  { id: 1, name: "ELISA" },
  { id: 2, name: "IMUNOCROMATOGRAFIA" }
]

export const IMMUNOLOGY_EXAM_MATERIALS = [
  { id: 1, name: "Soro" },
  { id: 2, name: "Sangue total" },
  { id: 3, name: "Plasma" },
  { id: 4, name: "Fezes" },
  { id: 5, name: "Líquido Cavitário" },
  { id: 6, name: "SWAB" },
  { id: 7, name: "SWAB com Fezes" },
];

export const IMMUNOLOGY_SCORE_EXAM_VALUES = [
  { id: 0, name: "0" },
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
];

/**
 * @var {number}
 */
export const COUNTER_LIMIT = 100;

/**
 * @var {Object}
 */
export const SELECT_TEXT = {
  ACTIVE: "Selecione",
  LOADING: "Carregando..",
};

/**
 * @var {Object}
 */
export const SUBMIT_BTN_TEXT = {
  SAVE: "Salvar",
  SAVING: "Salvando..",
  UPDATE: "Atualizar",
  UPDATING: "Atualizando..",
};

/**
 * @var {String}
 */
export const SUCCESS_EMAIL_MESSAGE =
  "Em até 3 minutos o cliente deve ter recebido o email!";
export const WARNING_EMAIL_MESSAGE =
  "Apenas exames com status de Atendido e Liberado podem ser enviados";

/**
 * @var {Object}
 */
export const ACCESS_PROFILES = {
  GENERAL_ADMINISTRATOR: 1,
  UNIT_ADMINISTRATOR: 2,
  UNIT_USER: 3,
  CLINIC_ADMINISTRATOR: 4,
  CLINIC_USER: 5,
  toString: function (status) {
    switch (status) {
      case this.GENERAL_ADMINISTRATOR:
        return " Administrador geral";
      case this.UNIT_ADMINISTRATOR:
        return " Administrador da unidade";
      case this.UNIT_USER:
        return " Usuário da unidade";
      case this.CLINIC_ADMINISTRATOR:
        return " Administrador da Clínica";
      case this.CLINIC_USER:
        return " Usuário da clínica";
      default:
        return "Perfil de usuário desconhecido";
    }
  },
};

/*


  THIS FILE MUST BE ELIMINATED!!!
  ALL CONSTS SHALL BE PLACED IN /CONSTS.

  @ANDERSON

*/

/**
 * @var {Array}
 */
export const ACCESS_PROFILES_LIST = [
  {
    id: ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
    name: "Administrador Geral",
  },
  {
    id: ACCESS_PROFILES.UNIT_ADMINISTRATOR,
    name: "Administrador da Unidade",
  },
  {
    id: ACCESS_PROFILES.UNIT_USER,
    name: "Usuário da Unidade",
  },
  {
    id: ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
    name: "Administrador da Clínica",
  },
  {
    id: ACCESS_PROFILES.CLINIC_USER,
    name: "Usuário da Clínica",
  },
];

/**
 * @var {string}
 */
export const UROCULTURE_NEGATIVE_MESSAGE =
  "Ausência de crescimento bacteriano em Agar Sangue, Agar Cled e Agar MacConkey, em 48 horas, a 37 ºC.";

/**
 * @var {string}
 */
export const COPROCULTURE_NEGATIVE_MESSAGE =
  "Ausência de crescimento bacteriano em Agar Sangue, Agar SS e Agar MacConkey, em 48 horas, a 37 ºC.";

/**
 * @var {string}
 */
export const HEMOCULTURE_NEGATIVE_MESSAGE =
  "Ausência de crescimento bacteriano em Caldo TSB, Agar Sangue e Agar MacConkey, em 5 dias, a 37 ºC.";

/**
 * @var {string}
 */
export const CULTURE_ANTIBIOGRAM_ANAEROBIC_NEGATIVE_MESSAGE =
  "Ausência de crescimento bacteriano em Agar Sangue e Agar MacConkey, em 48 horas, a 37 ºC, em atmosfera de anaerobiose.";

/**
 * @var {string}
 */
export const CULTURE_ANTIBIOGRAM_AEROBIC_NEGATIVE_MESSAGE =
  "Ausência de crescimento bacteriano em Agar Sangue e Agar MacConkey, em 48 horas, a 37 ºC.";

export const EXAM_REFERENCE_TYPES_LIST = [
  { id: 2, name: "Bioquímica" },
  { id: 4, name: "Diversos" },
  { id: 5, name: "Quimioluminiscencia" },
  { id: 6, name: "Radioimunoensaio" },
  { id: 7, name: "Hematologia" },
  { id: 9, name: "Imunologia" },
  { id: 12, name: "Urinálise" },
];

export const EXAM_REFERENCE_TYPES = {
  BIOCHEMISTRY: { id: 2, name: "Bioquímica" },
  MANIFOLD: { id: 4, name: "Diversos" },
  CHEMILUMINESCENCE: { id: 5, name: "Quimioluminiscencia" },
  RADIOIMMUNOASSAY: { id: 6, name: "Radioimunoensaio" },
  HEMATOLOGY: { id: 7, name: "Hematologia" },
  IMMUNOLOGY: { id: 9, name: "Imunologia" },
  URINALYSIS: { id: 12, name: "Urinálise" },
};

/*


  THIS FILE MUST BE ELIMINATED!!!
  ALL CONSTS SHALL BE PLACED IN /CONSTS.

  @ANDERSON

*/

export const MOLECULAR_BIOLOGY_MATERIALS = [
  { id: 1, name: "Sangue com EDTA" },
  { id: 2, name: "Soro" },
  { id: 3, name: "Urina" },
  { id: 4, name: "Aspirado de Linfonodo" },
  { id: 5, name: "Aspirado de Medula" },
  { id: 6, name: "Líquor" },
  { id: 7, name: "SWAB de Secreção Ocular" },
  { id: 8, name: "Aspirado de Lesão" }
];

export const REQUIRED_VALIDATION_MESSAGE = "Obrigatório";
export const OBS_VALIDATION_MAX_LENGTH = 1000;
export const MAX_LENGTH_VALIDATION_MESSAGE = (maxLength) =>
  `Excedeu valor máximo de ${maxLength}`;

/**
 * @var {Object}
 */
export const IMMUNOLOGY_INFO = {
  FOUR_DX: {
    METHOD: "ELISA - SNAP",
    DESCRIPTION:
      "Detecção de anticorpos IgG contra Ehrlichia canis ou Ehrlichia ewingii, Borrelia burgdorferi (Doença de Lyme), e Anaplasma phagocytophilum ou Anaplasma platys, e detecção de antígeno de Dirofilaria immitis.",
  },
  DISTEMPER_PARVOVIRUS_IGG: {
    METHOD: "Dot-ELISA",
    DESCRIPTION:
      "Determinação no soro, os níveis do anticorpo IgG contra o Vírus da Parvovirose canina e Vírus da Cinomose canina",
  },
  DISTEMPER_PARVOVIRUS_IGM: {
    METHOD: "Dot-ELISA",
    DESCRIPTION:
      "Determinação no soro, os níveis do anticorpo IgM contra o Vírus da Parvovirose canina e Vírus da Cinomose canina",
  },
  HEARTWORM_DISEASE: {
    METHOD: "ELISA - SNAP",
    DESCRIPTION: "Detecção de anticorpos contra Dirofilariose",
  },
  EHRLICHIA_CANIS: {
    METHOD: "Dot-ELISA",
    DESCRIPTION:
      "Detecção de anticorpos contra Ehrlichia canis ou Ehrlichia ewingii",
  },
  FIV_FELV: {
    METHOD: "Dot-ELISA",
    DESCRIPTION:
      "Detecção do vírus da imunodeficiência felina e o vírus da leucemia felina",
  },
  GIARDIA: {
    METHOD: "Imunocromatográfico",
    DESCRIPTION: "Detecção qualitativa de antígenos de Giardia lamblia",
  },
  IGA: {
    METHOD: "Imunoturbidimetria",
    DESCRIPTION: "Determinação da Imunoglobulina A - IgA",
  },
  PIF: {
    METHOD: "Dot-ELISA",
    DESCRIPTION: "Peritonite Infecciosa Felina",
  },
  PARVO_CORONA: {
    METHOD: "Imunocromatográfico",
    DESCRIPTION:
      "Pesquisa de antígeno nas fezes do vírus da Parvovirose Canina e Coronavirose Canina",
  },
};

/*


  THIS FILE MUST BE ELIMINATED!!!
  ALL CONSTS SHALL BE PLACED IN /CONSTS.

  @ANDERSON

*/
