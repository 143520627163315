import __update from "immutability-helper";
import { UseRequestState, Action } from "./../../types/index";

export const ACTIONS = {
  UPDATE: "UPDATE",
  UPDATE_FIELD: "UPDATE_FIELD",
};

const update = (
  value: UseRequestState,
  target: UseRequestState
): UseRequestState => {
  const commands = {
    id: { $set: value.id },
    status: { $set: value.status },
    createdAt: { $set: value.createdAt },
    updatedAt: { $set: value.updatedAt },
    clinicalReport: { $set: value.clinicalReport },
  };

  return __update(target, commands);
};

const updateField = (
  field: string,
  value: any,
  target: UseRequestState
): UseRequestState => {
  const commands = {
    [field]: { $set: value },
  };
  return __update(target, commands);
};

export const reducer = (state: UseRequestState, action: Action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return update(action.payload, state);
    case ACTIONS.UPDATE_FIELD:
      return updateField(action.payload.field, action.payload.value, state);
    default:
      return state;
  }
};
