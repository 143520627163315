import { validated } from 'react-custom-validation';

import {
  commonReportRules,
  hemogramRules,
  biochemistryRules,
  easRules
  } from '../../assets/js/validationRules';

const validation = validated( props => {

  const {
    erythrocytes,
    corpuscularVolume,
    hemoglobiometry,
    globalLeukometry,
    relativeBasophils,
    relativeEosinophils,
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    relativeSegs,
    relativeLymphocytes,
    relativeMonocytes,
    relativeHeterophils,
    platelets,
    erythrogramObs,
    leukogramObs,
    plateletsObs,
    hemogramObs,
    easVolume,
    easColorOptionId,
    easSmellOptionId,
    easAspectOptionId,
    easDensity,
    easProteinsOptionId,
    easGlucoseOptionId,
    easAcetoneOptionId,
    easPh,
    easBilirubinOptionId,
    easUrobilinogenOptionId,
    easHemoglobinOptionId,
    easLeukocyteOptionId,
    easNitriteOptionId,
    easEpithelialCellsOptionTags,
    easRidgesOptionTags,
    easCastsOptionTags,
    easErythrocytesMin,
    easErythrocytesMax,
    easIsErythrocytesAbsent,
    easIsErythrocytesUncountable,
    easPusCellsMin,
    easPusCellsMax,
    easIsPusCellsAbsent,
    easIsPusCellsUncountable,
    easBacterialFloraOptionId,
    easSpermatozoaOptionId,
    easObs,
    isIntenseLeucopeny,
    vetSignerId,
    requestExamState
  } = props.fields;

  const { refs, isUpdate, rows, completeValidation } = props;

  let fields = [
    'erythrocytes',
    'corpuscularVolume',
    'hemoglobiometry',
    'globalLeukometry',
    'relativeBasophils',
    'relativeEosinophils',
    'relativeMyelocytes',
    'relativeMetamyelocytes',
    'relativeBands',
    'relativeSegs',
    'relativeLymphocytes',
    'relativeMonocytes',
    'relativeHeterophils',
    'platelets',
    'erythrogramObs',
    'leukogramObs',
    'plateletsObs',
    'hemogramObs',
    'total',
    'mchc',
    'easVolume',
    'easColorOptionId',
    'easSmellOptionId',
    'easAspectOptionId',
    'easDensity',
    'easProteinsOptionId',
    'easGlucoseOptionId',
    'easAcetoneOptionId',
    'easPh',
    'easBilirubinOptionId',
    'easUrobilinogenOptionId',
    'easHemoglobinOptionId',
    'easLeukocyteOptionId',
    'easNitriteOptionId',
    'easEpithelialCellsOptionTags',
    'easRidgesOptionTags',
    'easCastsOptionTags',
    'easErythrocytesMin',
    'easErythrocytesMax',
    'easIsErythrocytesAbsent',
    'easIsErythrocytesUncountable',
    'easPusCellsMin',
    'easPusCellsMax',
    'easIsPusCellsAbsent',
    'easIsPusCellsUncountable',
    'easBacterialFloraOptionId',
    'easSpermatozoaOptionId',
    'easObs',
    'urea',
    'creatinine',
    'sodium',
    'potassium',
    'phosphorus',
    'requestExamState',
    'vetSignerId'
  ];

  const fieldNames = {
    erythrocytesName:           'erythrocytes',
    corpuscularVolumeName:      'corpuscularVolume',
    hemoglobiometryName:        'hemoglobiometry',
    relativeBasophilsName:      'relativeBasophils',
    relativeEosinophilsName:    'relativeEosinophils',
    relativeMyelocytesName:     'relativeMyelocytes',
    relativeMetamyelocytesName: 'relativeMetamyelocytes',
    relativeBandsName:          'relativeBands',
    relativeSegsName:           'relativeSegs',
    relativeLymphocytesName:    'relativeLymphocytes',
    relativeMonocytesName:      'relativeMonocytes',
    relativeHeterophilsName:    'relativeHeterophils',
    plateletsName:              'platelets',
    plateletsObsName:           'plateletsObs'
  };

  const commonValidation = commonReportRules( vetSignerId, requestExamState );

  const hemogramValidation = hemogramRules( {
    globalLeukometry: globalLeukometry,
    plateletsName: 'platelets',
    platelets: platelets,
    plateletsObsName: 'plateletsObs',
    plateletsObs: plateletsObs,
    erythrocytes: erythrocytes,
    corpuscularVolume: corpuscularVolume,
    hemoglobiometry: hemoglobiometry,
    erythrogramObs: erythrogramObs,
    mchc: props.mchc,
    mchcMax: refs.mchcMax,
    isIntenseLeucopeny: isIntenseLeucopeny,
    relativeBasophils: relativeBasophils,
    relativeEosinophils: relativeEosinophils,
    relativeLymphocytes: relativeLymphocytes,
    relativeMonocytes: relativeMonocytes,
    relativeMyelocytes: relativeMyelocytes,
    relativeMetamyelocytes: relativeMetamyelocytes,
    relativeBands: relativeBands,
    relativeSegs: relativeSegs,
    relativeHeterophils: relativeHeterophils,
    leukogramObs: leukogramObs,
    hasHeterophils: refs.hasHeterophils,
    isUpdate: isUpdate,
    total: props.total,
    fieldNames: fieldNames,
    hemogramObs,
    completeValidation
  } );

  const easValidation = easRules(
    'easVolume',
    easVolume,
    'easColorOptionId',
    easColorOptionId,
    'easSmellOptionId',
    easSmellOptionId,
    'easAspectOptionId',
    easAspectOptionId,
    'easDensity',
    easDensity,
    'easProteinsOptionId',
    easProteinsOptionId,
    'easGlucoseOptionId',
    easGlucoseOptionId,
    'easAcetoneOptionId',
    easAcetoneOptionId,
    'easPh',
    easPh,
    'easBilirubinOptionId',
    easBilirubinOptionId,
    'easUrobilinogenOptionId',
    easUrobilinogenOptionId,
    'easHemoglobinOptionId',
    easHemoglobinOptionId,
    'easLeukocyteOptionId',
    easLeukocyteOptionId,
    'easNitriteOptionId',
    easNitriteOptionId,
    'easEpithelialCellsOptionTags',
    easEpithelialCellsOptionTags,
    'easRidgesOptionTags',
    easRidgesOptionTags,
    'easCastsOptionTags',
    easCastsOptionTags,
    'easBacterialFloraOptionId',
    easBacterialFloraOptionId,
    'easSpermatozoaOptionId',
    easSpermatozoaOptionId,
    'easObs',
    easObs,
    easIsErythrocytesAbsent,
    easIsErythrocytesUncountable,
    easErythrocytesMin,
    easErythrocytesMax,
    easIsPusCellsAbsent,
    easIsPusCellsUncountable,
    easPusCellsMin,
    easPusCellsMax,
    completeValidation
  );

  const biochemistryValidation = biochemistryRules( rows, completeValidation );

  const validations = {
    ...commonValidation,
    ...hemogramValidation,
    ...biochemistryValidation,
    ...easValidation
  };

  return { fields, validations };
} );

export default validation;