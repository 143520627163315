import React from "react";

/** Internal */
import FlatPanelMaterial from "./../FlatPanelMaterial";
import ReportHeader from "./../ReportHeader";
import InputMaterial from "./../InputMaterial";
import SelectMaterial from "./../SelectMaterial";
import {
  IMMUNOLOGY_EXAM_MATERIALS,
  IMMUNOLOGY_INFO
} from "./../../assets/js/Consts";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";
import MethodPanel from "./../MethodPanel";
import FourthRadioMaterial from "../FourthRadioMaterial";
import DualRadioMaterial from "../DualRadioMaterial";
import { ManufacturerInformationFields } from "../ManufacturerInformationFields";

const FourDXReportFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="4DX - IgG elisa"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <MethodPanel
      method={IMMUNOLOGY_INFO.FOUR_DX.METHOD}
      description={IMMUNOLOGY_INFO.FOUR_DX.DESCRIPTION}
    />
    <FlatPanelMaterial title="Resultado">
      <Row className="mb-10">
        <Col md={4}></Col>
        <SelectMaterial
          id="materialId"
          title="Material Enviado"
          md={4}
          hasValidationRecoil
          name="materialId"
          options={IMMUNOLOGY_EXAM_MATERIALS}
          value={data.fields.materialId}
          searchable={true}
          disabled={data.disableAll}
          {...methods.$field("materialId", event => {
            methods.fieldChange("materialId", event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.materialId.show &&
            !data.$validation.materialId.isValid
          }
          validationReason={
            data.$validation && data.$validation.materialId.error.reason
          }
          isValid={data.$validation && data.$validation.materialId.isValid}
        />
      </Row>
      <Row className="mb-20">
        <Col md={4}></Col>
        <FourthRadioMaterial
          md={4}
          disabled={data.disableAll}
          title="Ehrlichia cannis / ewingii"
          name="isEhrlichiaCannis"
          selectedValue={data.fields.isEhrlichiaCannis}
          firstLabel="Negativo"
          firstValue={0}
          secondLabel="Não Reagente"
          secondValue={2}
          thirdLabel="Positivo"
          thirdValue={1}
          fourthLabel="Reagente"
          fourthValue={3}
          {...methods.$field("isEhrlichiaCannis", event => {
            methods.fieldChange("isEhrlichiaCannis", event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.isEhrlichiaCannis.show &&
            !data.$validation.isEhrlichiaCannis.isValid
          }
          validationReason={
            data.$validation && data.$validation.isEhrlichiaCannis.error.reason
          }
          isValid={
            data.$validation && data.$validation.isEhrlichiaCannis.isValid
          }
        />
      </Row>
      <Row>
        <Col md={4}></Col>
        <FourthRadioMaterial
          md={4}
          disabled={data.disableAll}
          title="Borrelia Burgdorferi"
          name="isBorreliaBurgdorferi"
          selectedValue={data.fields.isBorreliaBurgdorferi}
          firstLabel="Negativo"
          firstValue={0}
          secondLabel="Não Reagente"
          secondValue={2}
          thirdLabel="Positivo"
          thirdValue={1}
          fourthLabel="Reagente"
          fourthValue={3}
          {...methods.$field("isBorreliaBurgdorferi", event => {
            methods.fieldChange("isBorreliaBurgdorferi", event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.isBorreliaBurgdorferi.show &&
            !data.$validation.isBorreliaBurgdorferi.isValid
          }
          validationReason={
            data.$validation &&
            data.$validation.isBorreliaBurgdorferi.error.reason
          }
          isValid={
            data.$validation && data.$validation.isBorreliaBurgdorferi.isValid
          }
        />
      </Row>
      <Row className="mt-20 pb-10">
        <Col md={4}></Col>
        <DualRadioMaterial
          md={4}
          disabled={data.disableAll}
          title="Dirofilaria immitis"
          name="isDirofilariaImmitis"
          selectedValue={data.fields.isDirofilariaImmitis}
          disabled={data.disableAll}
          firstLabel="Negativo"
          firstValue={0}
          secondLabel="Positivo"
          secondValue={1}
          {...methods.$field("isDirofilariaImmitis", event => {
            methods.fieldChange("isDirofilariaImmitis", event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.isDirofilariaImmitis.show &&
            !data.$validation.isDirofilariaImmitis.isValid
          }
          validationReason={
            data.$validation &&
            data.$validation.isDirofilariaImmitis.error.reason
          }
          isValid={
            data.$validation && data.$validation.isDirofilariaImmitis.isValid
          }
        />
      </Row>
      <Row>
        <Col md={4}></Col>
        <FourthRadioMaterial
          md={4}
          disabled={data.disableAll}
          title="Anasplasma phagocytophilum / platys"
          name="isAnasplasmaPhagocytophilum"
          selectedValue={data.fields.isAnasplasmaPhagocytophilum}
          disabled={data.disableAll}
          firstLabel="Negativo"
          firstValue={0}
          secondLabel="Não Reagente"
          secondValue={2}
          thirdLabel="Positivo"
          thirdValue={1}
          fourthLabel="Reagente"
          fourthValue={3}
          {...methods.$field("isAnasplasmaPhagocytophilum", event => {
            methods.fieldChange("isAnasplasmaPhagocytophilum", event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.isAnasplasmaPhagocytophilum.show &&
            !data.$validation.isAnasplasmaPhagocytophilum.isValid
          }
          validationReason={
            data.$validation &&
            data.$validation.isAnasplasmaPhagocytophilum.error.reason
          }
          isValid={
            data.$validation &&
            data.$validation.isAnasplasmaPhagocytophilum.isValid
          }
        />
      </Row>
    </FlatPanelMaterial>
    <FlatPanelMaterial title="Diagnóstico">
      <Row>
        <Col xs={1} className="text-right">
          <p className="mr-10 cleanItem">OBS</p>
        </Col>
        <Col xs={11}>
          <InputMaterial
            name="obs"
            md={12}
            disabled={data.disableAll}
            value={data.fields.obs}
            fit={true}
            onChange={event => methods.fieldChange("obs", event.target.value)}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
    
    <ManufacturerInformationFields
      manufacturer={data.fields.manufacturer}
      batch={data.fields.batch}
      validity={data.fields.validity}
      obs={data.fields.manufacturerInformationObs}
      obsFieldName="manufacturerInformationObs"
      onChange={(name, value) => methods.fieldChange(name, value)}
    />
  </ReportPanelMaterial>
);

export default FourDXReportFormView;
