import React, { Component } from "react";
import connect from "../../api-connector";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <h1> Dashboard</h1>;
  }
}

export default Dashboard;
