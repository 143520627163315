import React from "react";
export const Radio = ({ name, checked, disabled, value }) => (
  <input
    type="radio"
    name={name}
    checked={checked}
    disabled={disabled}
    value={value}
  />
);
