import React from "react";
import styled from "styled-components";
import { rose, blackberry, granadilla } from "./../colors";

/**
 * @author Victor Heringer
 *
 * Transforms the event value to uppercase
 *
 * @param {Object} event
 * @param {Function} callback
 */
const upperCase = (event, callback) => {
  event.target.value = event.target.value.toUpperCase();
  callback && callback(event);
};

const StyledInput = styled.input`
  border-radius: 3px;
  border: ${(props) => hasErrorBorder(props)};
  padding: 5px;
  font-size: 12px;
  width: 100%;
  color: ${blackberry};
  box-sizing: border-box;
  box-shadow: ${(props) => hasViewModeBoxShadow(props)};
  font-family: Arial;
  &::-webkit-input-placeholder {
    color: ${(props) => hasErrorColor(props)};
  }
  ${(props) =>
    props.isViewOnly &&
    `&:disabled {
    background-color: #ffffff;
  };`}
`;

const StyledErrorMessage = styled.div`
  color: ${rose};
  margin-top: 5px;
  font-family: arial;
  font-size: 12px;
`;

const hasErrorBorder = ({ errorMessage }) => {
  return errorMessage ? ` 1px solid ${rose};` : "0";
};

const hasErrorColor = ({ errorMessage }) => {
  return errorMessage ? rose : granadilla;
};

const hasViewModeBoxShadow = ({ isViewOnly }) => {
  return isViewOnly
    ? "0px 0px 1px rgba(0, 0, 0, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.24)"
    : "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)";
};

const Input = ({
  name = "",
  type = "text",
  disabled = false,
  isViewOnly = false,
  value,
  onChange,
  onBlur,
  onKeyDown = (e) => {},
  innerRef,
  placeholder = "",
  errorMessage = "",
  uppercase = false,
  onClick = (e) => {},
  className = "",
  maxlength = 100,
  max = 1000,
  autoFocus = false,
}) => {
  disabled = isViewOnly ? isViewOnly : disabled;
  let onChangeFunc = onChange;
  let onBlurFunc = onBlur;
  if (uppercase) {
    onChangeFunc = (event) => upperCase(event, onChange);
    onBlurFunc = (event) => upperCase(event, onBlur);
  }

  return (
    <>
      <StyledInput
        name={name}
        value={value}
        type={type}
        disabled={disabled}
        isViewOnly={isViewOnly}
        onChange={onChangeFunc}
        onBlur={onBlurFunc}
        onKeyDown={onKeyDown}
        errorMessage={errorMessage}
        ref={innerRef}
        placeholder={placeholder}
        onClick={onClick}
        className={className}
        maxlength={maxlength}
        max={max}
        autoFocus={autoFocus}
      />
      {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </>
  );
};

Input.displayName = "input";

export default Input;
