import React from 'react';
import PropTypes from 'prop-types';

/**
 * External
 */
import { Row, Col } from './../../components/grid';

/**
* Internal
*/
import NumberInputMaterial2 from './../NumberInputMaterial2';
import FlatPanelMaterial from './../FlatPanelMaterial';
import TextAreaMaterial from './../TextAreaMaterial';

const propTypes = {};
const defaultProps = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const NumberInputWithResultPanel = ( {
  panelTitle,
  inputTitle,
  inputName,
  inputValue,
  handleChange,
  handleBlur,
  hasInputValidation,
  inputValidationReason,
  resultFieldTitle,
  resultFunction,
  shouldDisable
} ) =>
  <FlatPanelMaterial title={ panelTitle }>
    <Row className="mb-10">
      <div className='col-xs-4 text-right'>
        <p className='mr-20 cleanItem'>{ inputTitle }</p>
      </div>
      <Col md={ 2 }>
        <NumberInputMaterial2
          name={ inputName }
          md={ 3 }
          inputGroupAfter=""
          inputGroup={ true }
          hasValidationRecoil={ true }
          decimalScale={ 0 }
          fit={ true }
          block={ true }
          inputGroup={ true }
          allowNegative={ false }
          value={ inputValue }
          disabled={ shouldDisable }
          shouldDisableThousandSeparator={ false }
          handleChange={ handleChange }
          handleBlur={ handleBlur }
          hasValidation={ hasInputValidation }
          validationReason={ inputValidationReason } />
      </Col>
    </Row>
    <Row>
      <Col md={ 12 }>
        <TextAreaMaterial
          name="result"
          title={ resultFieldTitle }
          value={ resultFunction() }
          disabled={ true } />
      </Col>
    </Row>
  </FlatPanelMaterial>

NumberInputWithResultPanel.propTypes = propTypes;
NumberInputWithResultPanel.defaultProps = defaultProps;

export default NumberInputWithResultPanel;