import React from "react";

/**
 * External
 */
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  decimalScale: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

const defaultProps = {
  decimalScale: 0,
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Displays number
 */
const NumberDisplay = ({ value, decimalScale, prefix, suffix }) => {
  
  return (
    <NumberFormat
      displayType={"text"}
      value={value}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={decimalScale}
      fixedDecimalScale={true}
      isNumericString={true}
      prefix={prefix}
      suffix={suffix}
    />
  );
};

NumberDisplay.propTypes = propTypes;
NumberDisplay.defaultProps = defaultProps;

export default  React.memo(NumberDisplay);