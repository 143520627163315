import React from 'react';

/**
 * External
 */
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

/**
 * Internal
 */
import PanelMaterial from './../PanelMaterial';
import PanelMaterialBody from './../PanelMaterialBody';
import PanelMaterialTop from './../PanelMaterialTop';
import SelectMaterial from './../SelectMaterial';

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const DoubleSelectPanel = ( {
  panelTitle,
  firstTitle,
  secondTitle,
  firstName,
  secondName,
  firstOptions,
  secondOptions,
  firstValue,
  secondValue,
  disableAll,
  firstValidationShow,
  secondValidationShow,
  firstValidationReason,
  secondValidationReason,
  fieldChange,
  $field
} ) =>
  <React.Fragment>
    <PanelMaterial className='pb-20' flat={ true }>
      <PanelMaterialTop title={ panelTitle } />
      <PanelMaterialBody>
        <Col md={ 6 }>
          <SelectMaterial
            title={ firstTitle }
            md={ 9 }
            name={ firstName }
            options={ firstOptions }
            value={ firstValue }
            searchable={ true }
            disabled={ disableAll }
            placeholder={ 'Selecione' }
            hasValidationRecoil={ true }

            { ...$field( firstName, ( value ) => {
              fieldChange( firstName, value );
            } ) }

            hasValidation={ firstValidationShow }
            validationReason={ firstValidationReason } />
        </Col>
        <Col md={ 6 }>
          <SelectMaterial
            title={ secondTitle }
            md={ 9 }
            name={ secondName }
            options={ secondOptions }
            value={ secondValue }
            searchable={ true }
            disabled={ disableAll }
            placeholder={ 'Selecione' }
            hasValidationRecoil={ true }

            { ...$field( secondName, ( value ) => {
              fieldChange( secondName, value );
            } ) }

            hasValidation={ secondValidationShow }
            validationReason={ secondValidationReason } />
        </Col>
      </PanelMaterialBody>
    </PanelMaterial>
  </React.Fragment>

DoubleSelectPanel.propTypes = propTypes;

export default DoubleSelectPanel;