import { validated } from 'react-custom-validation';

import { commonReportRules, heartwormDiseaseRules } from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    vetSignerId,
    requestExamState,
    result,
    materialId,
  } = props.data.fields;

  const {completeValidation} = props.data;
  const fields = [
    'requestExamState',
    'vetSignerId',
    'materialId',
    'result'
  ];

  const heartwormDiseaseValidation = heartwormDiseaseRules(
    'materialId',
    materialId,
    'result',
    result,
    completeValidation
  );

  const commonValidation = commonReportRules( vetSignerId, requestExamState );

  const validations = {
    ...commonValidation,
    ...heartwormDiseaseValidation
  };

  return { fields, validations };
} );

export default validation;