import React from "react";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import PanelMaterialBottom from "./../PanelMaterialBottom";
import ButtonBackMaterial from "./../ButtonBackMaterial";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import AnimalFields from "./../AnimalFields";

/**
 * External
 */
import { Link } from "react-router-dom";

/**
 * Assets
 */
import "react-datepicker/dist/react-datepicker.css";

const AnimalFormView = ({
  data: {
    fields,
    disableAll,
    submit,
    $validation,
    customersPromise,
    speciesPromise,
    breedsPromise,
    shouldDisableCustomer,
    title,
  },
  methods: {
    $field,
    fieldChange,
    birthDateChange,
    yearsMonthsChange,
    onChangeSpecies,
    onSubmit,
  },
}) => (
  <React.Fragment>
    <PanelMaterial>
      <PanelMaterialTop title={title ? `Animal :${title}` : "Novo Animal"}>
        <Link to="/animais">
          <ButtonBackMaterial
            type="default"
            title="Voltar"
            position="top"
            disabled={disableAll}
          />
        </Link>
      </PanelMaterialTop>
      <PanelMaterialBody>
        <AnimalFields
          $field={$field}
          $validation={$validation}
          updateField={fieldChange}
          onChangeSpecies={onChangeSpecies}
          onChangeBirthDate={birthDateChange}
          onChangeYearsOrMonths={yearsMonthsChange}
          shouldRenderCustomer={true}
          fields={fields}
          customersPromise={customersPromise}
          speciesPromise={speciesPromise}
          breedsPromise={breedsPromise}
          disableAll={disableAll}
          shouldDisableCustomer={shouldDisableCustomer}
        />
      </PanelMaterialBody>
      <PanelMaterialBottom>
        <Link to="/animais">
          <ButtonBackMaterial
            type="default"
            title="Voltar"
            position="bottom"
            disabled={disableAll}
          />
        </Link>
        <ButtonSaveMaterial
          type="highlight"
          title={submit.btnTitle}
          right={true}
          onClick={onSubmit}
          requesting={submit.pending}
          disabled={/* disableAll */ false}
          requestingTitle={submit.placeholder}
        />
      </PanelMaterialBottom>
    </PanelMaterial>
  </React.Fragment>
);

export default AnimalFormView;
