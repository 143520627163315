import React, { Component } from "react";

/** External */
import update from "immutability-helper";
import { MenuItem, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

/** Internal */
import LVSidebarSimpleLink from "./../LVSideBar/LVSidebarSimpleLink";
import AccessManager from "./../AccessManager";
import { logout } from "./../../redux/actions/";
import { ACCESS_PROFILES } from "./../../assets/js/Consts";

/** Assets */
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Cog from "../../assets/img/cog.png";
import LogoHorizontalWhite from "../../assets/img/logo-horizontal-white.png";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faBars from "@fortawesome/fontawesome-free-solid/faBars";
import faCloseMenu from "@fortawesome/fontawesome-free-solid/faOutdent";
import ChangePassword from "./../../containers/ChangePassword";
import { withRouter } from "react-router-dom";

/**
 * @author Victor Heringer
 *
 * Represents the header component
 */
class LVHeader extends Component {
  /**  Constructor */
  constructor(props) {
    super(props);
    this.state = { expandedNavbar: false, changePasswordModal: false };
    this.logout = this.logout.bind(this);
    this.toggleChangePassword = this.toggleChangePassword.bind(this);
  }

  /**
   * @author Victor Heringer
   *
   * Logs out a user from system
   *
   * @return Void
   */
  logout() {
    this.props.dispatch(logout());
  }

  /**
   * @author Victor Heringer
   *
   * Changes the logged user password
   *
   * @todo
   *
   * @return Void
   */
  toggleChangePassword() {
    this.setState((state) =>
      update(state, {
        changePasswordModal: { $set: !this.state.changePasswordModal },
      })
    );
  }

  onToggle = () => {
    this.setState((state) =>
      update(state, {
        expandedNavbar: { $set: !this.state.expandedNavbar },
      })
    );
  };

  render() {
    const links = {
      left: [
        {
          url: "/",
          id: "principal",
          title: "Principal",
          isDropdown: false,
          childrens: [],
          allowed: [
            ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_USER,
            ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
            ACCESS_PROFILES.CLINIC_USER,
          ],
        },
        {
          url: "",
          id: "cadastro_basico",
          title: "Cadastros Básicos",
          isDropdown: true,
          allowed: [
            ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_USER,
            ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
            ACCESS_PROFILES.CLINIC_USER,
          ],
          childrens: [
            {
              title: "Animal",
              url: "/animais",
              allowed: [
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_USER,
                ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
                ACCESS_PROFILES.CLINIC_USER,
              ],
            },
            {
              title: "Espécie",
              url: "/especies",
              allowed: [
                ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_USER,
                ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
                ACCESS_PROFILES.CLINIC_USER,
              ],
            },
            {
              title: "Raça",
              url: "/racas",
              allowed: [
                ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_USER,
                ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
                ACCESS_PROFILES.CLINIC_USER,
              ],
            },
            {
              title: "Antibiótico",
              url: "/antibioticos",
              allowed: [
                ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
              ],
            },
            {
              title: "Cliente",
              url: "/clientes",
              allowed: [
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_USER,
                ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
                ACCESS_PROFILES.CLINIC_USER,
              ],
            },
            {
              title: "Espécie e Valores Normais",
              url: "/especies-valores",
              allowed: [
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_USER,
              ],
            },
            {
              title: "Unidade",
              url: "/unidades",
              allowed: [
                ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_USER,
              ],
            },
            {
              title: "Exame Terceirizado",
              url: "/exame-terceirizado",
              allowed: [
                ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_USER,
              ],
            },
            {
              title: "Exame x Material de Amostra",
              url: "/exame-amostra",
              allowed: [
                ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
              ],
            },
          ],
        },
        {
          url: "",
          id: "financeiro",
          title: "Financeiro",
          isDropdown: true,
          allowed: [
            ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_USER,
            ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
            ACCESS_PROFILES.CLINIC_USER,
          ],
          childrens: [
            {
              title: "Faturamento",
              url: "/financeiro/faturamento",
              allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
            },
            {
              title: "Consulta de Fatura",
              url: "/financeiro/consulta-fatura",
              allowed: [
                ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_USER,
                ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
                ACCESS_PROFILES.CLINIC_USER,
              ],
            },
            {
              url: "/tabela-preco",
              title: "Tabela de Preço",
              allowed: [
                ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_USER,
                ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
                ACCESS_PROFILES.CLINIC_USER,
              ],
            },
            {
              url: "/forma-pagamento",
              title: "Forma de Pagamento",
              allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
            },
          ],
        },
        {
          url: "",
          id: "relatorios",
          title: "Relatórios",
          isDropdown: true,
          allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
          childrens: [
            {
              title: "Exames Finalizados (Consolidados)",
              url: "/relatorios-exames-finalizados",
              allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
            },
            {
              title: "Materiais de Amostra Utilizados",
              url: "/relatorio-amostras-utilizadas",
              allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
            },
            {
              title: "Relatório de Emails Enviados",
              url: "/relatorios-emails-enviados",
              allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
            },
          ],
        },
      ],
    };

    const navLeftComponent = (
      <Nav className="hidden-if-sidebar">
        {links.left.map((link) => {
          if (link.isDropdown) {
            return (
              <AccessManager key={link.id} allowed={link.allowed}>
                <NavDropdown
                  key={link.title}
                  title={link.title}
                  id={link.title}
                >
                  {link.childrens.map((children) => {
                    return (
                      <LVSidebarSimpleLink
                        key={children.title}
                        to={children.url}
                        title={children.title}
                        allowed={children.allowed}
                        onClick={this.onToggle}
                        className="simple-link"
                      />
                    );
                  })}
                </NavDropdown>
              </AccessManager>
            );
          } else {
            return (
              <LVSidebarSimpleLink
                key={link.title}
                to={link.url}
                title={link.title}
                onClick={this.onToggle}
                className="simple-link"
                allowed={link.allowed}
              />
            );
          }
        })}
      </Nav>
    );

    /*
    <br />
              <span id="wellcome" className="welcome">
                {this.props.username}{" "}
                <span class="badge badge-primary">
                  {this.props.userProfile}
                </span>
              </span>
    */
    const NavRightComponent = (
      <Nav pullRight>
        <NavDropdown
          noCaret
          eventKey={3}
          id="dd-configs"
          title={
            <div>
              {this.props.username}
              <span className="separator"> | </span>
              <img className="config-icon" alt="cog" src={Cog} />
            </div>
          }
        >
          <MenuItem onClick={this.toggleChangePassword}>
            {" "}
            <span style={{ fontSize: "11px" }}>Alterar Senha</span>
          </MenuItem>
          <MenuItem onClick={() => this.props.history.push("/tickets")}>
            <span style={{ fontSize: "11px" }}>Abrir Chamado</span>
          </MenuItem>
          <MenuItem onClick={this.logout}>
            <span style={{ fontSize: "11px" }}>Sair</span>
          </MenuItem>
        </NavDropdown>
      </Nav>
    );

    return (
      <React.Fragment>
        <Navbar
          fluid
          fixedTop
          className="nav-material"
          collapseOnSelect
          onToggle={this.onToggle}
          expanded={this.state.expandedNavbar}
        >
          <Navbar.Header>
            <Navbar.Brand className="brand-material">
              <FontAwesomeIcon
                icon={this.props.sidebarVisible ? faCloseMenu : faCloseMenu}
                onClick={this.props.barsAction}
              />
              <Link to={"/"}>
                <img src={LogoHorizontalWhite} alt="life.vet" id="brand" className="brand" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            {navLeftComponent}
            {NavRightComponent}
          </Navbar.Collapse>
        </Navbar>
        <ChangePassword
          show={this.state.changePasswordModal}
          handleClose={this.toggleChangePassword}
          notificationSystem={this.props.notificationSystem}
        />
      </React.Fragment>
    );
  }
}

export default connect()(withRouter(LVHeader));
