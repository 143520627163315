import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import FlatPanelMaterial from "./../FlatPanelMaterial";
import InputMaterial from "./../InputMaterial";
import SelectMaterial from "./../SelectMaterial";
import {
  IMMUNOLOGY_EXAM_MATERIALS,
  IMMUNOLOGY_SCORE_EXAM_VALUES,
  IMMUNOLOGY_INFO
} from "./../../assets/js/Consts";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";
import MethodPanel from "./../MethodPanel";

const EhrlichiaCanisReportFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Ehrlichia Canis - Elisa Dot Blot"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <MethodPanel
      method={IMMUNOLOGY_INFO.EHRLICHIA_CANIS.METHOD}
      description={IMMUNOLOGY_INFO.EHRLICHIA_CANIS.DESCRIPTION}
    />
    <FlatPanelMaterial title="Resultado">
      <Row>
        <Col md={4}></Col>
        <SelectMaterial
          id="materialId"
          title="Material Enviado"
          md={4}
          name="materialId"
          options={IMMUNOLOGY_EXAM_MATERIALS}
          value={data.fields.materialId}
          searchable={true}
          disabled={data.disableAll}
          hasValidationRecoil
          {...methods.$field("materialId", event => {
            methods.fieldChange("materialId", event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.materialId.show &&
            !data.$validation.materialId.isValid
          }
          validationReason={
            data.$validation && data.$validation.materialId.error.reason
          }
          isValid={data.$validation && data.$validation.materialId.isValid}
        />
      </Row>
      <Row>
        <Col xs={4}></Col>
        <SelectMaterial
          id="result"
          title="Resultado"
          md={4}
          name="result"
          options={IMMUNOLOGY_SCORE_EXAM_VALUES}
          value={data.fields.result}
          searchable={true}
          disabled={data.disableAll}
          hasValidationRecoil
          {...methods.$field("result", event => {
            methods.fieldChange("result", event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.result.show &&
            !data.$validation.result.isValid
          }
          validationReason={
            data.$validation && data.$validation.result.error.reason
          }
          isValid={data.$validation && data.$validation.result.isValid}
        />
      </Row>
    </FlatPanelMaterial>
    <FlatPanelMaterial title="Diagnóstico">
      <Row>
        <Col xs={1} className="text-right">
          <p className="mr-10 cleanItem">OBS</p>
        </Col>
        <Col xs={11}>
          <InputMaterial
            name="obs"
            md={12}
            disabled={data.disableAll}
            value={data.fields.obs}
            fit={true}
            onChange={event => methods.fieldChange("obs", event.target.value)}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
  </ReportPanelMaterial>
);

export default EhrlichiaCanisReportFormView;
