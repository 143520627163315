import { required } from "../../assets/js/validationFunctions.js";

/**
 * @author Victor Heringer
 *
 * Validation function for email
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const sampleModalValidation = props => {
  const { modalSampleName } = props.data.fields;

  const fields = ["modalSampleName"];

  const validations = {
    modalSampleName: {
      rules: [[required, modalSampleName, ""]]
    }
  };

  return { fields, validations };
};
