import React from "react";

/** Internal */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import ReceiveAndAssistModal from "./ReceiveAndAssistModal";
import { StringUtil } from "./../../useful";
import LVTable from "./../../old_components/LVTable";
import { PromiseUtil } from "../../useful";
import { ACCESS_PROFILES } from "./../../assets/js/Consts";
import AccessManager from "./../../old_components/AccessManager";
import "./index.css";
import * as LifeVet from "./../../components";

/** External */
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faPlusSquare from "@fortawesome/fontawesome-free-solid/faPlusSquare";
import faCheckSquare from "@fortawesome/fontawesome-free-solid/faCheckSquare";
import faSearch from "@fortawesome/fontawesome-free-solid/faSearch";
import { Link } from "react-router-dom";
import { Row, Col } from "./../../components/grid";
import { ButtonTimer } from "./../../components/index";

/**
 * @author Alessandro Bastos Grandini
 *
 * Renders animal column in request exams table
 *
 * @param {Object} data Represents an exam request in the table
 *
 * @return {Object}
 */
const renderAnimalInfo = (data) => {
  const { name, specie, owner } = data.original.animal;
  const animalInfo = [name, "-", specie.name, "-", owner].join(" ");
  return <div className="cut-text">{animalInfo}</div>;
};

/**
 * @author Victor Heringer
 *
 * Renders date column in request exams table
 *
 * @param {Object} data Represents an exam request in the table
 *
 * @return {Object}
 */
const renderDateInfo = (data) => {
  return (
    <div className="cut-text">
      {StringUtil.formatDateUi(data.original.created_at)}
    </div>
  );
};

/**
 * @author Victor Heringer
 *
 * Renders customer column in request exams table
 *
 * @param {Object} data Represents an exam request in the table
 *
 * @return {Object}
 */
const renderCustomerInfo = (data) => {
  return <div className="cut-text">{data.original.customer.name}</div>;
};

/**
 * @author Victor Heringer
 *
 * Renders the exame request list view
 */
const ExamRequestListView = ({ methods, data }) => {
  const defaultConfig = {
    pageSize: 5,
    onDoubleClickRow: methods.onTableDoubleClick,
    columns: [
      {
        id: "id",
        accessor: "id",
        Header: "Cód",
        sortable: true,
        minWidth: 25,
      },
      {
        id: "date",
        Header: "Data",
        accessor: "created_at",
        sortable: true,
        minWidth: 45,
        sortMethod: (first, second) => {
          first = first.split(" ")[0];
          first = first.split("-");
          first = new Date(first[2], first[1], first[0]);

          second = second.split(" ")[0];
          second = second.split("-");
          second = new Date(second[2], second[1], second[0]);

          return first > second ? 1 : -1;
        },
        Cell: renderDateInfo,
      },
      {
        id: "animal",
        Header: "Animal",
        accessor: "animal.name",
        sortable: true,
        sortMethod: (first, second) => {
          return first.localeCompare(second);
        },
        Cell: renderAnimalInfo,
      },
      {
        id: "customer",
        Header: "Cliente",
        accessor: "customer.name",
        minWidth: 50,
        sortable: true,
        sortMethod: (first, second) => {
          return first.localeCompare(second);
        },
        Cell: renderCustomerInfo,
      },
    ],
  };

  const {
    lateExams,
    pendingExams,
    stuckExams,
    awaitingExams,
    inProgressExams,
    attendedPartiallyExams,
    cancelledExams,
    attendedExams,
    billedExams,
  } = data;

  const configLate = {
    ...defaultConfig,
    loading: PromiseUtil.isPending(lateExams),
    data: PromiseUtil.extractValue(lateExams, []),
  };

  const configPending = {
    ...defaultConfig,
    loading: PromiseUtil.isPending(pendingExams),
    data: PromiseUtil.extractValue(pendingExams, []),
  };

  const configStuck = {
    ...defaultConfig,
    loading: PromiseUtil.isPending(stuckExams),
    data: PromiseUtil.extractValue(stuckExams, []),
  };

  const configAwaiting = {
    ...defaultConfig,
    loading: PromiseUtil.isPending(awaitingExams),
    data: PromiseUtil.extractValue(awaitingExams, []),
  };

  const configInProgress = {
    ...defaultConfig,
    loading: PromiseUtil.isPending(inProgressExams),
    data: PromiseUtil.extractValue(inProgressExams, []),
  };

  const configAttendedPartially = {
    ...defaultConfig,
    loading: PromiseUtil.isPending(attendedPartiallyExams),
    data: PromiseUtil.extractValue(attendedPartiallyExams, []),
  };

  const configCancelled = {
    ...defaultConfig,
    loading: PromiseUtil.isPending(cancelledExams),
    data: PromiseUtil.extractValue(cancelledExams, []),
  };

  const configAttended = {
    ...defaultConfig,
    loading: PromiseUtil.isPending(attendedExams),
    data: PromiseUtil.extractValue(attendedExams, []),
  };

  const configBilled = {
    ...defaultConfig,
    loading: PromiseUtil.isPending(billedExams),
    data: PromiseUtil.extractValue(billedExams, []),
  };

  return (
    <React.Fragment>
      <ReceiveAndAssistModal data={data} methods={methods} />
      <LifeVet.Panel>
        <LifeVet.PanelHeader title="Solicitações de Exame">
          <Row className="text-center">
            <Col className="mt-5" xsHidden>
              {/* <ButtonTimer
                goal={120}
                loop
                callback={methods.refreshExamRequestTables}
                title={"Clique para atualizar a página"}
              /> */}
              <AccessManager
                allowed={[
                  ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                  ACCESS_PROFILES.UNIT_USER,
                  ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
                  ACCESS_PROFILES.CLINIC_USER,
                ]}
              >
                {/* <Link to="/solicitacoes-exame/2.0/novo">
                  <LifeVet.Button depth="2" variant="danger">
                    Solicitação (2.0)
                  </LifeVet.Button>
                </Link> */}
                <Link to="/solicitacoes-exame/novo">
                  <LifeVet.Button depth="2" variant="primary">
                    Nova Solicitação
                  </LifeVet.Button>
                </Link>
              </AccessManager>
              <Link to="/solicitacoes-exame/buscar">
                <LifeVet.Button depth="2" variant="secondary">
                  Buscar Solicitação
                </LifeVet.Button>
              </Link>
              <AccessManager
                allowed={[
                  ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                  ACCESS_PROFILES.UNIT_USER,
                ]}
              >
                <LifeVet.Button
                  onClick={methods.handleShowReceiveAndAssistModal}
                  depth="2"
                  variant="secondary"
                >
                  Receber e Laudar
                </LifeVet.Button>
              </AccessManager>
            </Col>

            <Col className="pl-0 pr-0" smHidden mdHidden lgHidden>
              <Link to="/solicitacoes-exame/novo">
                <LifeVet.Button depth="2" variant="primary">
                  <FontAwesomeIcon size="lg" icon={faPlusSquare} />
                </LifeVet.Button>
              </Link>

              <Link to="/solicitacoes-exame/buscar">
                <LifeVet.Button depth="2" variant="secondary">
                  <FontAwesomeIcon size="lg" icon={faSearch} />
                </LifeVet.Button>
              </Link>
              <AccessManager
                allowed={[
                  ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                  ACCESS_PROFILES.UNIT_USER,
                ]}
              >
                <LifeVet.Button
                  onClick={methods.handleShowReceiveAndAssistModal}
                  depth="2"
                  variant="secondary"
                >
                  <FontAwesomeIcon size="lg" icon={faCheckSquare} />
                </LifeVet.Button>
              </AccessManager>
            </Col>
          </Row>
        </LifeVet.PanelHeader>
        <LifeVet.PanelBody>
          {data.isClinicUserType ? (
            <React.Fragment>
              <Row>
                <Col sm={6}>
                  <Col sm={12}>
                    <PanelMaterial className="pendingPanel" flat>
                      <PanelMaterialTop title={"Salvo e Não enviado"} />
                      <PanelMaterialBody>
                        <LVTable
                          data={configPending.data}
                          loading={configPending.loading}
                          columns={configPending.columns}
                          sortable={configPending.sortable}
                          pageSize={configPending.pageSize}
                          getTrProps={configPending.getTrProps}
                          onDoubleClickRow={configPending.onDoubleClickRow}
                        />
                      </PanelMaterialBody>
                    </PanelMaterial>
                  </Col>

                  <Col sm={12}>
                    <PanelMaterial className="awatingPanel" flat>
                      <PanelMaterialTop title={"Ag. Recebimento"} />
                      <PanelMaterialBody>
                        <LVTable
                          data={configAwaiting.data}
                          loading={configAwaiting.loading}
                          columns={configAwaiting.columns}
                          sortable={configAwaiting.sortable}
                          pageSize={configAwaiting.pageSize}
                          getTrProps={configAwaiting.getTrProps}
                          onDoubleClickRow={configAwaiting.onDoubleClickRow}
                        />
                      </PanelMaterialBody>
                    </PanelMaterial>
                  </Col>
                </Col>
                <Col sm={6}>
                  <Col sm={12}>
                    <PanelMaterial className="inProgressPanel" flat>
                      <PanelMaterialTop title={"Recebido e Em Andamento"} />
                      <PanelMaterialBody>
                        <LVTable
                          data={configInProgress.data}
                          loading={configInProgress.loading}
                          columns={configInProgress.columns}
                          sortable={configInProgress.sortable}
                          pageSize={configInProgress.pageSize}
                          getTrProps={configInProgress.getTrProps}
                          onDoubleClickRow={configInProgress.onDoubleClickRow}
                          pageSize={14}
                          defaultPageSize={14}
                        />
                      </PanelMaterialBody>
                    </PanelMaterial>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <PanelMaterial className="attendedPanel" flat>
                    <PanelMaterialTop title={"Finalizados"} />
                    <PanelMaterialBody>
                      <LVTable
                        data={configAttended.data}
                        loading={configAttended.loading}
                        columns={configAttended.columns}
                        sortable={configAttended.sortable}
                        pageSize={configAttended.pageSize}
                        getTrProps={configAttended.getTrProps}
                        onDoubleClickRow={configAttended.onDoubleClickRow}
                      />
                    </PanelMaterialBody>
                  </PanelMaterial>
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Row>
                <Col sm={6}>
                  <Col sm={12}>
                    <PanelMaterial className="pendingPanel" flat>
                      <PanelMaterialTop title={"Pendentes"} />
                      <PanelMaterialBody>
                        <LVTable
                          data={configPending.data}
                          loading={configPending.loading}
                          columns={configPending.columns}
                          sortable={configPending.sortable}
                          pageSize={configPending.pageSize}
                          getTrProps={configPending.getTrProps}
                          onDoubleClickRow={configPending.onDoubleClickRow}
                        />
                      </PanelMaterialBody>
                    </PanelMaterial>
                  </Col>

                  <Col sm={12}>
                    <PanelMaterial className="awatingPanel" flat>
                      <PanelMaterialTop title={"Ag. Recebimento"} />
                      <PanelMaterialBody>
                        <LVTable
                          data={configAwaiting.data}
                          loading={configAwaiting.loading}
                          columns={configAwaiting.columns}
                          sortable={configAwaiting.sortable}
                          pageSize={configAwaiting.pageSize}
                          getTrProps={configAwaiting.getTrProps}
                          onDoubleClickRow={configAwaiting.onDoubleClickRow}
                        />
                      </PanelMaterialBody>
                    </PanelMaterial>
                  </Col>
                </Col>
                <Col sm={6}>
                  <Col sm={12}>
                    <PanelMaterial className="inProgressPanel" flat>
                      <PanelMaterialTop title={"Em Andamento"} />
                      <PanelMaterialBody>
                        <LVTable
                          data={configInProgress.data}
                          loading={configInProgress.loading}
                          columns={configInProgress.columns}
                          sortable={configInProgress.sortable}
                          pageSize={configInProgress.pageSize}
                          getTrProps={configInProgress.getTrProps}
                          onDoubleClickRow={configInProgress.onDoubleClickRow}
                          pageSize={14}
                          defaultPageSize={14}
                        />
                      </PanelMaterialBody>
                    </PanelMaterial>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <PanelMaterial className="latePanel" flat>
                    <PanelMaterialTop title={"Atrasados"} />
                    <PanelMaterialBody>
                      <LVTable
                        data={configLate.data}
                        loading={configLate.loading}
                        columns={configLate.columns}
                        sortable={configLate.sortable}
                        pageSize={configLate.pageSize}
                        getTrProps={configLate.getTrProps}
                        onDoubleClickRow={configLate.onDoubleClickRow}
                      />
                    </PanelMaterialBody>
                  </PanelMaterial>
                </Col>
                <Col sm={6}>
                  <PanelMaterial className="problemPanel" flat>
                    <PanelMaterialTop title={"P. por Problema"} />
                    <PanelMaterialBody>
                      <LVTable
                        data={configStuck.data}
                        loading={configStuck.loading}
                        columns={configStuck.columns}
                        sortable={configStuck.sortable}
                        pageSize={configStuck.pageSize}
                        getTrProps={configStuck.getTrProps}
                        onDoubleClickRow={configStuck.onDoubleClickRow}
                      />
                    </PanelMaterialBody>
                  </PanelMaterial>
                </Col>

                <Col sm={12}>
                  <PanelMaterial className="partiallyAttendedPanel" flat>
                    <PanelMaterialTop title={"P. Atendido"} />
                    <PanelMaterialBody>
                      <LVTable
                        data={configAttendedPartially.data}
                        loading={configAttendedPartially.loading}
                        columns={configAttendedPartially.columns}
                        sortable={configAttendedPartially.sortable}
                        pageSize={configAttendedPartially.pageSize}
                        getTrProps={configAttendedPartially.getTrProps}
                        onDoubleClickRow={
                          configAttendedPartially.onDoubleClickRow
                        }
                      />
                    </PanelMaterialBody>
                  </PanelMaterial>
                </Col>

                <Col sm={6}>
                  <PanelMaterial className="cancelledPanel" flat>
                    <PanelMaterialTop title={"Cancelados"} />
                    <PanelMaterialBody>
                      <LVTable
                        data={configCancelled.data}
                        loading={configCancelled.loading}
                        columns={configCancelled.columns}
                        sortable={configCancelled.sortable}
                        pageSize={configCancelled.pageSize}
                        getTrProps={configCancelled.getTrProps}
                        onDoubleClickRow={configCancelled.onDoubleClickRow}
                      />
                    </PanelMaterialBody>
                  </PanelMaterial>
                </Col>

                <Col sm={6}>
                  <PanelMaterial className="attendedPanel" flat>
                    <PanelMaterialTop title={"Atendidos"} />
                    <PanelMaterialBody>
                      <LVTable
                        data={configAttended.data}
                        loading={configAttended.loading}
                        columns={configAttended.columns}
                        sortable={configAttended.sortable}
                        pageSize={configAttended.pageSize}
                        getTrProps={configAttended.getTrProps}
                        onDoubleClickRow={configAttended.onDoubleClickRow}
                      />
                    </PanelMaterialBody>
                  </PanelMaterial>
                </Col>

                <Col sm={6}>
                  <PanelMaterial className="billedPanel" flat>
                    <PanelMaterialTop title={"Faturados"} />
                    <PanelMaterialBody>
                      <LVTable
                        data={configBilled.data}
                        loading={configBilled.loading}
                        columns={configBilled.columns}
                        sortable={configBilled.sortable}
                        pageSize={configBilled.pageSize}
                        getTrProps={configBilled.getTrProps}
                        onDoubleClickRow={configBilled.onDoubleClickRow}
                      />
                    </PanelMaterialBody>
                  </PanelMaterial>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </LifeVet.PanelBody>
      </LifeVet.Panel>
    </React.Fragment>
  );
};

export default ExamRequestListView;
