import React from "react";
import LVTable from "../../../old_components/LVTable";
import * as DateUtil from "./../../../useful/date-util";

const InvoicesPaymentsTable = ({
  data,
  onTableDoubleClick,
  handleDeletePayment
}) => {
  const columns = [
    {
      id: "value",
      accessor: "value",
      Header: "Valor",
      sortable: true,
      minWidth: 30,
      Cell: data => `R$ ${data.original.value}`
    },
    {
      id: "payment_method",
      Header: "Forma de Pagamento",
      sortable: true,
      minWidth: 40,
      Cell: data => data.original.payment_method.name
    },
    {
      id: "payment_date",
      accessor: "payment_date",
      Header: "Data",
      sortable: true,
      minWidth: 40,
      Cell: data => DateUtil.formatDate(data.original.payment_date)
    },

    {
      id: "obs",
      accessor: "obs",
      Header: "Observações",
      sortable: true,
      minWidth: 100,
      Cell: data => data.original.obs
    },
    {
      id: "delete",
      Header: "Excluir",
      sortable: true,
      minWidth: 30,
      Cell: data => (
        <span
          onClick={() => {
            handleDeletePayment(data.original.invoice_id, data.original.id);
          }}
        >
          <i className="fa fa-trash" style={{ fontSize: "16px" }} />
        </span>
      )
    }
  ];

  return (
    <LVTable
      data={data}
      loading={false}
      columns={columns}
      sortable={true}
      pageSize={5}
      onDoubleClickRow={onTableDoubleClick}
    />
  );
};

export default InvoicesPaymentsTable;
