import connect from "../../api-connector";

const connectWithEndpoint = connect(props => ({
  species: "/species/all",
  periods: "/periods",

  filter: ({specieId,periodId, examTypeId}, then) => ({
    referenceValues: {
      url: `/reference-values/?specie=${specieId}&period=${periodId}&examTypeId=${examTypeId}`,
      then: then,
      method: "GET",
      force: true
    }
  }),


  getImmunologyReferenceValues: (speciesId, then) => ({
    referenceValues: {
      url: `/reference-values/immunology/species/${speciesId}`,
      then: then,
      method: "GET",
      force: true
    }
  }),
  getUrinalysisReferenceValues: (speciesId, then) => ({
    referenceValues: {
      url: `/reference-values/urinalysis/species/${speciesId}`,
      then: then,
      method: "GET",
      force: true
    }
  }),
  getHematologyReferenceValues: (speciesId, then) => ({
    referenceValues: {
      url: `/reference-values/hematology/species/${speciesId}`,
      then: then,
      method: "GET",
      force: true
    }
  }),
  getBiochemistryReferenceValues: (speciesId, then) => ({
    referenceValues: {
      url: `/reference-values/biochemistry/species/${speciesId}`,
      then: then,
      method: "GET",
      force: true
    }
  }),
  getChemiluminescenceReferenceValues: (speciesId, then) => ({
    referenceValues: {
      url: `/reference-values/chemiluminescence/species/${speciesId}`,
      then: then,
      method: "GET",
      force: true
    }
  }),
  getRadioimmunoassayReferenceValues: (speciesId, then) => ({
    referenceValues: {
      url: `/reference-values/radioimmunoassay/species/${speciesId}`,
      then: then,
      method: "GET",
      force: true
    }
  }),
  getManifoldReferenceValues: (speciesId, then) => ({
    referenceValues: {
      url: `/reference-values/manifold/species/${speciesId}`,
      then: then,
      method: "GET",
      force: true
    }
  }),
  submit: (data, then) => ({
    submitResponse: {
      url: `/reference-values/examType`,
      then: then,
      method: "POST",
      force: true,
      body: JSON.stringify(data)
    }
  })
}));

export default connectWithEndpoint;
