import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import { isTrue } from '../../assets/js/validationFunctions';

import { REQUIRED_VALIDATION_MESSAGE } from '../../assets/js/Consts.js';

import { commonReportRules } from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    files,
    editorState,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  let fields = [
    'files',
    'editorState',
    'requestExamState',
    'vetSignerId'
  ];

  const commonValidation = commonReportRules( vetSignerId, requestExamState );

  const validations = {
    ...commonValidation
  };

  const isFileEmpty = ( files.length === 0 );

  const isEditorStateValid = editorState.getCurrentContent().hasText();

  if( isFileEmpty ) {
    validations.editorState = {
      rules: [
        [ isTrue, isEditorStateValid, "Preencha o texto ou selecione um arquivo" ]
      ]
    };
  }

  return { fields, validations };
} );

export default validation;