import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import NumberInputMaterial2 from "../../NumberInputMaterial2";
import InputMaterial from "../../InputMaterial";
import { initialData } from "./initial-data";
import * as Lifevet from "./../../../components/index";

export default function BiochemistryReference({
  data,
  setField,
  extract,
  loading,
  loadInitialData,
}) {
  const [search, setSearch] = useState("");
  useEffect(() => {
    loadInitialData(initialData);
  }, []);

  function renderFields(search) {
    const { titles } = initialData;
    let names;
    if (search === "") {
      names = Object.keys(titles).sort();
    } else {
      const regex = new RegExp(`${search}`, "i");
      names = Object.keys(titles).filter(
        (title) => search && title.search(regex) !== -1
      );
    }

    return names.map((name) => {
      const key = titles[name];

      return (
        <Col
          md={4}
          style={{
            padding: "20px",
            borderRight: "solid lightgrey 1px",
            height: "140px",
          }}
          key={key}
        >
          <Row>
            <Col md={6}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  textAlign: "center",
                }}
              >
                {name}
              </p>
            </Col>

            <Col md={6}>
              <Lifevet.InputLabel style={{ fontWeight: "normal" }}>
                Unidade
                <Lifevet.Input
                  name={`${key}Unit`}
                  onChange={(a, b, c) => {
                    a.persist();

                    setField(`${key}Unit`, a.target.value);
                  }}
                  value={extract(data, `${key}Unit`, "")}
                  disabled={loading}
                />
              </Lifevet.InputLabel>
            </Col>
          </Row>
          <Row>
            <Col md={6} style={{ marginTop: "15px" }}>
              <NumberInputMaterial2
                name={`${key}Min`}
                title={"MIN"}
                inputGroupAfter=""
                inputGroup={true}
                hasValidationRecoil={true}
                decimalScale={2}
                fit={true}
                block={true}
                inputGroup={true}
                allowNegative={false}
                value={extract(data, `${key}Min`, "")}
                disabled={loading}
                shouldDisableThousandSeparator={false}
                handleChange={(field, value) => {
                  setField(`${key}Min`, value);
                }}
              />
            </Col>
            <Col md={6} style={{ marginTop: "15px" }}>
              <NumberInputMaterial2
                name={`${key}Max`}
                title={"MAX"}
                inputGroupAfter=""
                inputGroup={true}
                hasValidationRecoil={true}
                decimalScale={2}
                fit={true}
                block={true}
                inputGroup={true}
                allowNegative={false}
                value={extract(data, `${key}Max`, "")}
                disabled={loading}
                shouldDisableThousandSeparator={false}
                handleChange={(field, value) => setField(`${key}Max`, value)}
              />
            </Col>
          </Row>
        </Col>
      );
    });
  }

  return (
    <div style={{ padding: "10px" }}>
      <Row>
        <Col md={4}>
          <Lifevet.Input
            placeholder={"Pesquisar campo..."}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>
      <br />
      <br />
      <Row>{renderFields(search)}</Row>
    </div>
  );
}
