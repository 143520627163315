import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import ErythrogramPanel from "./../ErythrogramPanel";
import ErythrogramDiagnosisPanel from "./../ErythrogramDiagnosisPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";

const ErythrogramReportFormReportFormView = ({
  data: {
    refs,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    requestExamId,
    shouldSendEmail,
    reportData,
  },
  methods: {
    $field,
    fieldChange,
    onSubmit,
    handleSendReportEmail,
    calcMCHC,
    calcMCV,
    erythrogramDiagnosis,
    cancelReport,
    stopReport,
  },
}) => {
  return (
    <ReportPanelMaterial
      title="Eritrograma"
      backLink={backLink}
      shouldDisable={disableAll}
      isUpdate={isUpdate}
      isRequesting={submitPromise && submitPromise.pending}
      handleSubmit={onSubmit}
      handleSendReportEmail={handleSendReportEmail}
      shouldSendEmail={shouldSendEmail}
      cancelReport={cancelReport}
      stopReport={stopReport}
    >
      <Row>
        <Col md={12}>
          <ReportHeader
            data={reportHeaderData}
            fields={fields}
            fieldChange={fieldChange}
            $field={$field}
            $validation={$validation}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ErythrogramPanel
            title={"Resultado"}
            erythrocytes={fields.erythrocytes}
            corpuscularVolume={fields.corpuscularVolume}
            hemoglobiometry={fields.hemoglobiometry}
            erythrocytesMin={refs.erythrocytesMin}
            erythrocytesMax={refs.erythrocytesMax}
            corpuscularVolumeMin={refs.corpuscularVolumeMin}
            corpuscularVolumeMax={refs.corpuscularVolumeMax}
            hemoglobiometryMin={refs.hemoglobiometryMin}
            hemoglobiometryMax={refs.hemoglobiometryMax}
            mcvMin={refs.mcvMin}
            mcvMax={refs.mcvMax}
            mchcMin={refs.mchcMin}
            mchcMax={refs.mchcMax}
            disableAll={disableAll}
            $validation={$validation}
            fieldChange={fieldChange}
            $field={$field}
            calcMCV={calcMCV}
            calcMCHC={calcMCHC}
            flat={true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ErythrogramDiagnosisPanel
            obs={fields.erythrogramObs}
            diagnosisFn={erythrogramDiagnosis}
            shouldDisable={disableAll}
            $validation={$validation}
            fieldChange={fieldChange}
            $field={$field}
            diagnosis={
              reportData.erythrogramDiagnosis
                ? reportData.erythrogramDiagnosis
                : null
            }
            diagnosisInput={fields.erythrogramDiagnosis}
          />
        </Col>
      </Row>
    </ReportPanelMaterial>
  );
};

export default ErythrogramReportFormReportFormView;
