import React, { useReducer } from "react";
import { useEmails } from "./../emails/index";
import { usePhones } from "./../phones/index";
import { useAddresses } from "./../addresses/index";
import { reducer, ACTIONS } from "./reducer";
import { UseCustomerState, Customer, CustomerType } from "./../../types/index";

const defaultCustomer: UseCustomerState = {
  id: 0,
  type: CustomerType.COMPANY,
  name: "",
  isActive: true,
  receivesPrintedReport: true,
};

export const useCustomer = () => {
  const [customer, dispatch] = useReducer(reducer, defaultCustomer);
  const emails = useEmails();
  const phones = usePhones();
  const addresses = useAddresses();

  const data: Customer = {
    id: customer.id,
    type: customer.type,
    name: customer.name,
    isActive: customer.isActive,
    receivesPrintedReport: customer.receivesPrintedReport,
    emails: emails.data,
    phones: phones.data,
    addresses: addresses.data,
  };

  const methods = {
    update: (customer: Customer) => {
      dispatch({ type: ACTIONS.UPDATE, payload: customer });
      emails.methods.reset();
      emails.methods.add(customer.emails);

      phones.methods.reset();
      phones.methods.add(customer.phones);

      addresses.methods.reset();
      addresses.methods.add(customer.addresses);
    },

    updateField: (field: string, value: string | number | boolean) =>
      dispatch({ type: ACTIONS.UPDATE_FIELD, payload: { field, value } }),

    reset: () => dispatch({ type: ACTIONS.UPDATE, payload: defaultCustomer }),

    emails: emails.methods,
    phones: phones.methods,
    addresses: addresses.methods,
  };

  return {
    data: data,
    defaultData: defaultCustomer,
    methods: methods,
  };
};
