import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal
 */
import InputValidationMsg from './../InputValidationMsg';
import styles from './index.module.css';

const defaultProps = {
  md: 12,
  type: 'text',
  title: '',
  disabled: false,
  validationReason: '',
  hasValidation: false,
  validationType: 'danger',
  hasValidationRecoil: true
};

const propTypes = {

  /**
   * Required
   */
  name: PropTypes.string.isRequired,

  /**
   * Default
   */
  title: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number
  ] ),
  validationType: PropTypes.oneOf( [
    'danger',
    'warning',
    'info'
  ] ),
  hasValidationRecoil: PropTypes.bool,

  /**
   * Custom
   */
  md: PropTypes.number,
  labelInline: PropTypes.bool,
  inputGroup: PropTypes.bool,
  inputGroupAfter: PropTypes.string,
  titleAfter: PropTypes.string,
  hasValidation: PropTypes.bool,
  validationReason: PropTypes.string,
  block: PropTypes.bool,
  fit: PropTypes.bool,

  /**
   * Classes
   */
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for input text with material design styling
 */
const TextAreaMaterial = ( {
  id,
  name,
  title,
  value,
  onBlur,
  onChange,
  disabled,
  titleAfter,
  hasValidation,
  validationType,
  hasValidationRecoil,
  validationReason
} ) => {

  /**
   * Validation Component
   * @type {Object}
   */
  const validation = <InputValidationMsg
    id={ name + '-validation-msg' }
    type={ validationType }
    visible={ hasValidation }
    message={ validationReason }
    top={ hasValidationRecoil ? '-18px' : '' }
  />;

  return (
    <div className={ 'textAreaWrapper' }>
      <div className="form-group">
        { title && <label>{ title }</label> }
        <textarea
          id={ id }
          name={ name }
          value={ value }
          onBlur={ onBlur }
          disabled={ disabled }
          onChange={ onChange }
          className={ 'form-control textAreaMaterial' }
        />
        { titleAfter &&
          <label className={ styles.labelAfeterMaterial }>
            { titleAfter }
          </label>
        }
      </div>
      { validation }
    </div>
  );

}

TextAreaMaterial.defaultProps = defaultProps;
TextAreaMaterial.propTypes = propTypes;

export default TextAreaMaterial;