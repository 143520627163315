import { hemocytozoaResearchRules } from "../../../assets/js/validationRules";

export const hemocytozoaFieldNames = () => {
  return ["hemocytozoaResearchResult", "hemocytozoaResearchObs"];
};

export const hemocytozoaDefaultState = () => {
  return {
    hemocytozoaResearchResult: "",
    hemocytozoaResearchObs: "",
  };
};

export const hemocytozoaStateApiFactory = (obj) => {
  return {
    hemocytozoaResearchResult: obj.hemocytozoaResearchResult,
    hemocytozoaResearchObs: obj.hemocytozoaResearchObs,
  };
};

export const hemocytozoaFieldsToApiObjParser = (fields) => {
  const { hemocytozoaResearchResult, hemocytozoaResearchObs } = fields;

  return {
    hemocytozoaResearchResult: hemocytozoaResearchResult,
    hemocytozoaResearchObs: hemocytozoaResearchObs,
  };
};

export const hemocytozoaValidationRules = (fields, isCompleteValidation) => {
  const { hemocytozoaResearchResult, hemocytozoaResearchObs } = fields;

  return hemocytozoaResearchRules(
    "hemocytozoaResearchResult",
    hemocytozoaResearchResult,
    "hemocytozoaResearchObs",
    hemocytozoaResearchObs,
    isCompleteValidation
  );
};
