import connect from "../../api-connector";

const connectWithEndpoint = connect(() => ({
  receiveAndAssistRequestExam: (barcode, then, error = () => { }) => ({
    receiveAndAssistPromise: {
      url: `/exam-request/request-sample/code/${barcode}`,
      method: "PUT",
      then: then,
      catch: error,
      force: true,
    },
  }),
}));

export default connectWithEndpoint;
