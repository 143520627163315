import React, { useEffect, useState } from "react";
import * as LifeVet from "./../../../../../components/index";
import { Modal } from "react-bootstrap";

export const ReceiveAndAssistModal = ({
  isOpen,
  closeModal,
  receiveAndAssist,
  submitting,
}: {
  isOpen: boolean;
  closeModal: () => void;
  receiveAndAssist: (sampleCode: string) => void;
  submitting: boolean;
}) => {
  const [sampleCode, setSampleCode] = useState("");

  useEffect(() => {
    if (sampleCode.length === 10) receiveAndAssist(sampleCode);
  }, [sampleCode]);

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Receber e Laudar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LifeVet.Row>
          <LifeVet.Col md={8}>
            <LifeVet.Input
              className="mt-10"
              value={sampleCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSampleCode(e.target.value);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                e.key === "Enter" && receiveAndAssist(sampleCode)
              }
              type="text"
              disabled={submitting}
              innerRef={null}
              onBlur={() => {}}
              autoFocus={true}
            />
          </LifeVet.Col>
          <LifeVet.Col md={4}>
            <LifeVet.Button
              disabled={sampleCode.length === 0 || submitting}
              onClick={() => receiveAndAssist(sampleCode)}
            >
              Receber e Laudar
            </LifeVet.Button>
          </LifeVet.Col>
        </LifeVet.Row>
      </Modal.Body>
    </Modal>
  );
};
