import React from "react";

/**
 * External
 */
import { Row, Col } from "./../../components/grid";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import InputMaterial from "./../InputMaterial";
import PanelMaterialBody from "../PanelMaterialBody";
import PlateletsDiagnosisContent from "../PlateletsDiagnosisContent";
import ErythrogramDiagnosisContent from "../ErythrogramDiagnosisContent";
import LeukogramDiagnosisContent from "../LeukogramDiagnosisContent";

/**
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Hemogram diagnosis
 */
const HemogramDiagnosisPanel = ({
  hemogramObs,
  erythrogramObs,
  leukogramObs,
  plateletsObs,
  disableAll,
  $validation,
  fieldChange,
  $field,
  erythrogramDiagnosis,
  leukogramDiagnosis,
  plateletsDiagnosis,
  reportData,
  diagnosisInput,
}) => (
  <PanelMaterial className="pt-5" title="Diagnóstico" flat={true}>
    <PanelMaterialTop title="Diagnóstico" />
    <PanelMaterialBody>
      <ErythrogramDiagnosisContent
        obs={erythrogramObs}
        diagnosisFn={erythrogramDiagnosis}
        shouldDisable={disableAll}
        $validation={$validation}
        fieldChange={fieldChange}
        $field={$field}
        diagnosis={
          reportData.erythrogramDiagnosis
            ? reportData.erythrogramDiagnosis
            : null
        }
        diagnosisInput={diagnosisInput}
      />
      <Row>
        <Col xs={12} className="no-padding">
          <hr />
        </Col>
      </Row>
      <LeukogramDiagnosisContent
        obs={leukogramObs}
        diagnosisFn={leukogramDiagnosis}
        shouldDisable={disableAll}
        $validation={$validation}
        fieldChange={fieldChange}
        $field={$field}
      />
      <Row>
        <Col xs={12} className="no-padding">
          <hr />
        </Col>
      </Row>
      <PlateletsDiagnosisContent
        plateletsObs={plateletsObs}
        $validation={$validation}
        fieldChange={fieldChange}
        $field={$field}
        diagnosisFn={plateletsDiagnosis}
        shouldDisable={disableAll}
      />
      <Row>
        <Col xs={12} className="no-padding">
          <hr />
        </Col>
      </Row>
      <Row>
        <Col xs={1} className="text-right">
          <p className="mr-10 cleanItem">OBS</p>
        </Col>
        <Col xs={11}>
          <InputMaterial
            name="hemogramObs"
            md={12}
            fit={true}
            value={hemogramObs}
            disabled={disableAll}
            {...$field("hemogramObs", (event) => {
              fieldChange("hemogramObs", event.target.value);
            })}
            hasValidation={$validation.hemogramObs.show}
            validationReason={$validation.hemogramObs.error.reason}
          />
        </Col>
      </Row>
    </PanelMaterialBody>
  </PanelMaterial>
);

HemogramDiagnosisPanel.propTypes = {};

export default HemogramDiagnosisPanel;
