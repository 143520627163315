import React from "react";
import FadeIn from "react-fade-in";
import { Modal, Row, Col } from "react-bootstrap";
import Notifications from "react-notification-system-redux";
import * as LifeVet from "../../components";
import {Link} from "react-router-dom";

const ExamRecordsView = ({
  values,
  touched,
  errors,
  isSubmitting,
  setFieldValue,
  inputChange,
  fieldChange,
  handleBlur,
  handleSubmit,
  regions,
  customers,
  disableAll,
  shouldEnableLabs,
}) => {
  return (
    <FadeIn>
      <LifeVet.Panel>
        <LifeVet.PanelHeader title="Relatório de Exames">
          <div>.</div>
        </LifeVet.PanelHeader>
        <LifeVet.PanelBody>
          <Row>
            <Col sm={2}>
              <LifeVet.FormGroup
              // errorMessage={touched.start_date && errors.start_date}
              >
                <LifeVet.InputLabel>Data Inicial</LifeVet.InputLabel>
                <LifeVet.Input
                  onChange={inputChange}
                  onBlur={handleBlur}
                  //   value={values.start_date}
                  disabled={isSubmitting || disableAll}
                  name="startDate"
                  type="date"
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={2}>
              <LifeVet.FormGroup
              // errorMessage={touched.end_date && errors.end_date}
              >
                <LifeVet.InputLabel>Data Final</LifeVet.InputLabel>
                <LifeVet.Input
                  onChange={inputChange}
                  onBlur={handleBlur}
                  // //   value={values.end_date}
                  disabled={isSubmitting || disableAll}
                  name="endDate"
                  type="date"
                />
              </LifeVet.FormGroup>
            </Col>
          
            {/* <Col sm={2}>
              <LifeVet.FormGroup
                
              >
                <LifeVet.InputLabel>Status</LifeVet.InputLabel>
                <LifeVet.Input
                  onChange={inputChange}
                  onBlur={handleBlur}
                   value={values.due_date}
                  disabled={isSubmitting || disableAll}
                  name="status"
                  type="text"
                />
              </LifeVet.FormGroup>
            </Col> */}
          </Row>
          <Row>
            <Col sm={4}>
              <LifeVet.FormGroup>
                <LifeVet.InputLabel>Regiões</LifeVet.InputLabel>

                <LifeVet.Select
                  id="exam_select"
                  name="regions"
                  value={values.regions}
                  options={regions}
                  searchable={true}
                  disabled={isSubmitting || disableAll}
                  multi={false}
                  closeOnSelect={false}
                  onChange={(values, field) => {
                    fieldChange(values.id, field.name);
                  }}
                  theValueIsAnId
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={3}>
              <LifeVet.FormGroup>
                <LifeVet.InputLabel>Clientes</LifeVet.InputLabel>
                <LifeVet.Select
                  id="exam_select"
                  name="customer"
                  value={values.customer}
                  options={customers}
                  searchable={true}
                  disabled={isSubmitting || disableAll}
                  multi={false}
                  closeOnSelect={true}
                  onChange={(values, field) => {
                    fieldChange(values.id, field.name);
                  }}
                  theValueIsAnId
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={2} className="mt-15">
              <LifeVet.Button
                float="right"
                type="submit"
                depth="2"
                variant="primary"
                onClick={handleSubmit}
                disabled={disableAll}
              >
                Exportar CSV
              </LifeVet.Button>
            </Col>
          </Row>
        </LifeVet.PanelBody>
        <LifeVet.PanelFooter>
          {shouldEnableLabs && (
            <Link to="/relatorios-unidades">
              <LifeVet.Button
                float="right"
                type="submit"
                depth="2"
                variant="primary"
                disabled={disableAll}
                onClick={() => {}}
              >
                Unidade
              </LifeVet.Button>
            </Link>
          )}
        </LifeVet.PanelFooter>
      </LifeVet.Panel>
    </FadeIn>
  );
};

export default ExamRecordsView;
