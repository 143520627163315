import { validated } from "react-custom-validation";

import {
  required,
  greaterThan,
  lessThanOrEqualTo,
} from "../../assets/js/validationFunctions";

import { EXAM_ID_BY_NAME, ZERO } from "../../assets/js/Consts.js";
import { REQUEST_EXAM_STATES } from "./../../consts";

const examIsCancelled = (requestExamStatus) => {
  return requestExamStatus == REQUEST_EXAM_STATES.CANCELLED;
};

const validation = validated((props) => {
  const { options, vetSignerId } = props.data.fields;
  let submitActionIsRelease = props.data.submitActionIsRelease;
  let fields = [];
  let validations = {};
  const completeValidation = props.data.completeValidation;

  const getRule = (rule, params, message) => {
    return completeValidation
      ? [rule, ...params, message]
      : [required, "defaultValue", message];
  };

  validations["vetSignerId"] = {
    rules: [[greaterThan, vetSignerId, ZERO, "Obrigatório"]],
  };

  fields.push("vetSignerId");

  options.forEach((option, index) => {
    const examId = option.examId;

    const fieldName = `result-${index}`;

    switch (examId) {
      case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]: {
        const totalName = `result-${index}-total`;
        const directName = `result-${index}-direct`;

        fields.push(totalName);
        fields.push(directName);

        const { total, direct } = option;

        const totalValue = total === "" ? 0 : total;
        const directValue = direct === "" ? 0 : direct;

        if (
          examIsCancelled(option.requestExamState) &&
          !submitActionIsRelease
        ) {
          validations[totalName] = {
            rules: [],
          };

          validations[directName] = {
            rules: [],
          };
        } else {
          validations[totalName] = {
            rules: [getRule(required, [totalValue], "Obrigatório")],
          };

          validations[directName] = {
            rules: [
              getRule(required, [direct], "Obrigatório"),
              getRule(
                lessThanOrEqualTo,
                [directValue, totalValue],
                "Deve ser menor que o total"
              ),
            ],
          };
        }

        break;
      }
      case EXAM_ID_BY_NAME["IONIC_CALCIUM"]: {
        const isCalculatedResult = !option.isResultEditable;

        if (isCalculatedResult) {
          const { calcium, albumin, totalProtein } = option;

          const calciumName = `result-${index}-calcium`;
          const albuminName = `result-${index}-albumin`;
          const totalProteinName = `result-${index}-totalProtein`;

          fields.push(calciumName);
          fields.push(albuminName);
          fields.push(totalProteinName);

          if (
            examIsCancelled(option.requestExamState) &&
            !submitActionIsRelease
          ) {
            validations[calciumName] = {
              rules: [],
            };

            validations[albuminName] = {
              rules: [],
            };

            validations[totalProteinName] = {
              rules: [],
            };
          } else {
            validations[calciumName] = {
              rules: [getRule(required, [calcium], "Obrigatório")],
            };

            validations[albuminName] = {
              rules: [getRule(required, [albumin], "Obrigatório")],
            };

            validations[totalProteinName] = {
              rules: [getRule(required, [totalProtein], "Obrigatório")],
            };
          }
        } else {
          const { result } = option;

          fields.push(fieldName);

          if (
            examIsCancelled(option.requestExamState) &&
            !submitActionIsRelease
          ) {
            validations[fieldName] = {
              rules: [],
            };
          } else {
            validations[fieldName] = {
              rules: [getRule(required, [result], "Obrigatório")],
            };
          }
        }

        break;
      }
      case EXAM_ID_BY_NAME["ELECTROLYTES"]: {
        const isCalculatedResult = !option.isResultEditable;

        if (isCalculatedResult) {
          const {
            calcium,
            albumin,
            totalProtein,
            sodium,
            potassium,
            phosphorus,
            chloride,
            ionicCalcium,
          } = option;

          const calciumName = `result-${index}-calcium`;
          const albuminName = `result-${index}-albumin`;
          const totalProteinName = `result-${index}-totalProtein`;
          const sodiumName = `result-${index}-sodium`;
          const phosphorusName = `result-${index}-phosphorus`;
          const potassiumName = `result-${index}-potassium`;
          const chlorideName = `result-${index}-chloride`;
          const ionicCalciumName = `result-${index}-ionicCalcium`;

          fields.push(calciumName);
          fields.push(albuminName);
          fields.push(totalProteinName);
          fields.push(sodiumName);
          fields.push(phosphorusName);
          fields.push(potassiumName);
          fields.push(chlorideName);
          fields.push(ionicCalciumName);
          if (
            examIsCancelled(option.requestExamState) &&
            !submitActionIsRelease
          ) {
            validations[calciumName] = {
              rules: [],
            };

            validations[albuminName] = {
              rules: [],
            };

            validations[totalProteinName] = {
              rules: [],
            };
          } else {
            validations[calciumName] = {
              rules: [getRule(required, [calcium], "Obrigatório 1")],
            };

            validations[albuminName] = {
              rules: [getRule(required, [albumin], "Obrigatório 2")],
            };

            validations[totalProteinName] = {
              rules: [getRule(required, [totalProtein], "Obrigatório 3")],
            };

            validations[sodiumName] = {
              rules: [getRule(required, [sodium], "Obrigatório 4")],
            };

            validations[phosphorusName] = {
              rules: [getRule(required, [phosphorus], "Obrigatório 5")],
            };
            validations[potassiumName] = {
              rules: [getRule(required, [potassium], "Obrigatório 6")],
            };
            validations[chlorideName] = {
              rules: [getRule(required, [chloride], "Obrigatório 7")],
            };
            // validations[ionicCalciumName] = {
            //   rules: [getRule(required, [ionicCalcium], "Obrigatório")]
            // };
          }
        } else {
          const { result } = option;

          fields.push(fieldName);

          if (
            examIsCancelled(option.requestExamState) &&
            !submitActionIsRelease
          ) {
            validations[fieldName] = {
              rules: [],
            };
          } else {
            validations[fieldName] = {
              rules: [],
            };
          }
        }

        break;
      }
      case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]: {
        const { totalProtein, albumin } = option;

        const totalProteinName = `result-${index}-totalProtein`;
        const albuminName = `result-${index}-albumin`;

        fields.push(totalProteinName);
        fields.push(albuminName);

        if (
          examIsCancelled(option.requestExamState) &&
          !submitActionIsRelease
        ) {
          validations[totalProteinName] = {
            rules: [],
          };

          validations[albuminName] = {
            rules: [],
          };
        } else {
          validations[totalProteinName] = {
            rules: [
              getRule(required, [totalProtein], "Obrigatório"),
              getRule(
                greaterThan,
                [totalProtein, 0],
                "Deve ser maior que zero"
              ),
            ],
          };

          validations[albuminName] = {
            rules: [
              getRule(required, [albumin], "Obrigatório"),
              getRule(
                lessThanOrEqualTo,
                [albumin, totalProtein],
                "Deve ser menor que proteínas totais"
              ),
            ],
          };
        }

        break;
      }
      case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]: {
        const { totalCholesterol, hdl, triglycerides } = option;

        const totalCholesterolName = `result-${index}-totalCholesterol`;
        const hdlName = `result-${index}-hdl`;
        const triglyceridesName = `result-${index}-triglycerides`;

        fields.push(totalCholesterolName);
        fields.push(hdlName);
        fields.push(triglyceridesName);

        if (
          examIsCancelled(option.requestExamState) &&
          !submitActionIsRelease
        ) {
          validations[totalCholesterolName] = {
            rules: [],
          };

          validations[hdlName] = {
            rules: [],
          };

          validations[triglyceridesName] = {
            rules: [],
          };
        } else {
          validations[totalCholesterolName] = {
            rules: [
              getRule(required, [totalCholesterol], "Obrigatório"),
              getRule(greaterThan, [totalCholesterol, ZERO], "Obrigatório"),
            ],
          };

          validations[hdlName] = {
            rules: [getRule(required, [hdl], "Obrigatório")],
          };

          validations[triglyceridesName] = {
            rules: [getRule(required, [triglycerides], "Obrigatório")],
          };
        }

        break;
      }
      default: {
        const { result } = option;

        fields.push(fieldName);

        if (
          examIsCancelled(option.requestExamState) &&
          !submitActionIsRelease
        ) {
          validations[fieldName] = {
            rules: [],
          };
        } else {
          validations[fieldName] = {
            rules: [getRule(required, [result], "Obrigatório")],
          };
        }
      }
    }
  });

  return { fields, validations };
});

export default validation;
