import React, { Component } from "react";

/** Internal */
import LeukogramReportFormView from "./../LeukogramReportFormView";
import connectWithEndpoint from "./requests.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import validation from "./validation.js";

import {
  BTN_SAVE_TITLE,
  BTN_UPDATE_TITLE,
  BTN_SAVING_TITLE,
  BTN_UPDATING_TITLE,
  REQUEST_EXAM_STATE_ID_BY_NAME,
} from "../../assets/js/Consts.js";

import {
  containerState,
  reportState,
} from "../../modules/factories/default-states.js";
import { PromiseUtil } from "../../useful";
import { REPORT_DEFAULT_VALUES } from "./../../consts";

import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";

import { leukogramState } from "../../modules/factories/default-states.js";

import {
  toggleIntenseLeucopeny,
  openCounter,
  closeCounter,
  handleKeyPress,
  enableCounterSound,
  handleFinishedPlaying,
  calcTotal,
  calcAbsolute,
  leukogramDiagnosis,
  zeroLeukogram,
  incrementField,
  globalLeukDiagnosis,
  resetLeukogram,
  finalGlobalLeukogram,
  isNeutropLeukocytosis,
  diagnosis,
  displayResults,
  DNNE,
  zeroIfIntenseLeucopeny,
  createLeukogramRefObj,
  createLeukogramObj,
  createReportObj,
  createLeukogramUpdateState,
} from "../../assets/js/reportFunctions.js";

/** External */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

const View = ({ data, methods, $field, $validation, $submit }) => {
  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;

    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };

  const onClickCounter = () => {
    methods.openCounter();
    methods.zeroLeukogram();
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    $field: $field,
    onClickCounter: onClickCounter,
    ...methods,
  };

  return <LeukogramReportFormView data={newData} methods={newMethods} />;
};

const ValidatedView = validation(View);

class LeukogramReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        ...reportState(),
        ...leukogramState(),
      },
      shouldSendEmail: false,
      ...containerState(),
    };

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.createObj = this.createObj.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.shouldDisplayLoader = this.shouldDisplayLoader.bind(this);
    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);
    this.toggleIntenseLeucopeny = toggleIntenseLeucopeny.bind(this);
    this.openCounter = openCounter.bind(this);
    this.closeCounter = closeCounter.bind(this);
    this.handleKeyPress = handleKeyPress.bind(this);
    this.enableCounterSound = enableCounterSound.bind(this);
    this.handleFinishedPlaying = handleFinishedPlaying.bind(this);
    this.calcTotal = calcTotal.bind(this);
    this.calcAbsolute = calcAbsolute.bind(this);
    this.leukogramDiagnosis = leukogramDiagnosis.bind(this);
    this.zeroLeukogram = zeroLeukogram.bind(this);
    this.incrementField = incrementField.bind(this);
    this.globalLeukDiagnosis = globalLeukDiagnosis.bind(this);
    this.resetLeukogram = resetLeukogram.bind(this);
    this.finalGlobalLeukogram = finalGlobalLeukogram.bind(this);
    this.isNeutropLeukocytosis = isNeutropLeukocytosis.bind(this);
    this.createReportObj = createReportObj.bind(this);
    this.createLeukogramObj = createLeukogramObj.bind(this);
    this.diagnosis = diagnosis.bind(this);
    this.displayResults = displayResults.bind(this);
    this.DNNE = DNNE.bind(this);
    this.zeroIfIntenseLeucopeny = zeroIfIntenseLeucopeny.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        xhr.response.status === 200 && this.fillFields(data);
      };

      this.props.getReportById(id, then);
    }

    this.props.getReportHeader(this.props.match.params.requestExamId);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    const editState = createLeukogramUpdateState(obj);
    editState.shouldSendEmail = {
      $set:
        obj.requestExamState ==
        REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_LIBERADO"],
    };
    const fillObject = (state) => update(state, editState);
    this.setState(fillObject);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Creates hemogram object
   *
   * @return {Object}
   *
   */
  createObj() {
    return {
      id: this.getId(),
      ...this.createReportObj(),
      ...this.createLeukogramObj(),
    };
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Decides wether is should
   * display the screen loader
   *
   */
  shouldDisplayLoader() {
    const { reportPromise } = this.props;
    const isUpdate = this.isUpdate();

    let editFetches = null;

    const shouldCheckEditFetches = isUpdate && reportPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([reportPromise]);
    }

    return shouldCheckEditFetches && editFetches && editFetches.pending;
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   *
   */
  submitData() {
    const { reportSubmitPromise } = this.props;

    const isUpdate = this.isUpdate();

    const btnTitle = isUpdate ? BTN_UPDATE_TITLE : BTN_SAVE_TITLE;
    const isSet = reportSubmitPromise !== undefined;
    const isPending = isSet && reportSubmitPromise.pending;
    const isFulfilled = isSet && reportSubmitPromise.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: isUpdate ? BTN_UPDATING_TITLE : BTN_SAVING_TITLE,
      btnTitle: btnTitle,
    };
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (!this.state.completeValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" ? null : obj[i];
      }
    }
    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  render() {
    const { examRequestId, requestExamId } = this.props.match.params;
    const backLink = "/solicitacoes-exame/" + examRequestId;

    const { reportSubmitPromise, refsPromise, reportHeaderPromise } =
      this.props;

    const { fields } = this.state;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    // Loader
    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );

    const refs = PromiseUtil.extractValue(
      refsPromise,
      REPORT_DEFAULT_VALUES.LEUKOGRAM_REF_DEFAULT,
      createLeukogramRefObj
    );

    const total = this.calcTotal();

    const data = {
      isUpdate: this.isUpdate(),
      completeValidation: this.state.completeValidation,
      fields: this.state.fields,
      backLink: backLink,
      submitPromise: reportSubmitPromise,
      examRequestId: examRequestId,
      disableAll: reportSubmitPromise && reportSubmitPromise.pending,
      reportHeaderData: reportHeaderFactory(reportHeaderData),
      refs: refs,
      total: fields.isIntenseLeucopeny ? "" : total,
      requestExamId: requestExamId,
      shouldSendEmail: this.state.shouldSendEmail,
    };

    // Methods
    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      invalidNotification: this.invalidNotification,
      toggleIntenseLeucopeny: this.toggleIntenseLeucopeny,
      openCounter: this.openCounter,
      closeCounter: this.closeCounter,
      handleKeyPress: this.handleKeyPress,
      enableCounterSound: this.enableCounterSound,
      handleFinishedPlaying: this.handleFinishedPlaying,
      calcTotal: this.calcTotal,
      calcAbsolute: this.calcAbsolute,
      leukogramDiagnosis: this.leukogramDiagnosis,
      zeroLeukogram: this.zeroLeukogram,
      incrementField: this.incrementField,
      globalLeukDiagnosis: this.globalLeukDiagnosis,
      resetLeukogram: this.resetLeukogram,
      finalGlobalLeukogram: this.finalGlobalLeukogram,
      isNeutropLeukocytosis: this.isNeutropLeukocytosis,
      diagnosis: this.diagnosis,
      displayResults: this.displayResults,
      DNNE: this.DNNE,
      zeroIfIntenseLeucopeny: this.zeroIfIntenseLeucopeny,
      handleSendReportEmail: this.handleSendReportEmail,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      cancelReport: this.cancelReport,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      stopReport: this.stopReport,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(connectWithEndpoint)(LeukogramReportForm);
