import React from "react";

/** Internal */
import { responsibleModalValidation } from "./../UnityForm/validation";
import DefaultTable from "./../DefaultTable";
import InputMaterial from "./../InputMaterial";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import SelectMaterial from "./../SelectMaterial";
import CheckboxMaterial from "./../CheckboxInputMaterial";
import StatusCircle from "./../StatusCircle";
import TableButtonRemoveMaterial from "./../TableButtonRemoveMaterial";
import TableButtonEditMaterial from "./../TableButtonEditMaterial";
import TableButtonAddMaterial from "./../TableButtonAddMaterial";
import {
  STATUS,
  YES_OR_NO,
  CPF_MASK,
  PHONE_MASK,
  ACCESS_PROFILES_LIST,
  SUBMIT_MODAL_KEY,
} from "../../assets/js/Consts.js";
import LVFileInputMaterial from "./../../old_components/LVFileInputMaterial";

/** Assets */
import styles from "./index.module.css";

/** External */
import { validated } from "react-custom-validation";
import { Row, Col, Modal } from "react-bootstrap";
import HiddenMaterial from "../HiddenMaterial";

/**
 * @author Victor Heringer
 *
 * Render the checkbox thats allow to delete user
 *
 * @param {Object} data
 * @param {Function} toggleSelectResponsible
 * @param {Array} selectedResponsible
 */
const renderCheckbox = (data, toggleSelectResponsible, selectedResponsible) => {
  if (!data.original.id) {
    return (
      <CheckboxMaterial
        checked={selectedResponsible.indexOf(data.original.name) > -1}
        onChange={() => toggleSelectResponsible(data.original.name)}
      />
    );
  }
  return <span />;
};

/**
 * @author Victor Heringer
 *
 * Render a circle to display boolean status
 *
 * @param {Bool} status
 *
 * @return {Object}
 */
const renderStatusCircle = (status) => {
  return <StatusCircle status={status} />;
};

/**
 * @author Victor Heringer
 *
 * Render the edit button
 *
 * @param {Object} data Table data, including id to fetch user
 * @param {Function} handleLoadResponsibleToModal Calls modal and sets the user
 *
 * @return {Object}
 */
const renderEditButton = (data, handleLoadResponsibleToModal) => {
  const responsible = {
    id: data.original.id,
    login: data.original.login,
    active: data.original.active,
    password: "",
    username: data.original.name,
    email: data.original.email,
    phone: data.original.phone,
    cpf: data.original.cpf,
    isVet: data.original.isVet,
    crmv: data.original.crmv,
    signature: data.original.signature,
    accessProfile: data.original.accessProfile,
  };

  const onClick = () => handleLoadResponsibleToModal(responsible);

  return (
    <TableButtonEditMaterial onClick={onClick} disabled={!responsible.id} />
  );
};

/**
 * @author Victor Heringer
 *
 * Returns react table configs
 *
 * @return {Array}
 */
const getTableConfigs = (
  responsible,
  toggleSelectResponsible,
  selectedResponsible,
  handleLoadResponsibleToModal
) => {
  let config = {
    defaultPageSize: 5,
    defaultSorted: [{ id: "id", desc: true }],
    pageSize: 25,
    resizable: false,
    showPagination: true,
    noDataText: "Nenhum registro".toUpperCase(),
    loadingText: "Carregando",
    className: "-striped -highlight",
    previousText: "Anterior",
    nextText: "Próximo",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    columns: [],
    showPageSizeOptions: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
  };
  config.data = responsible;
  config.pageSize = 5;
  config.className = "no-page-info";
  config.columns = [
    {
      Header: "",
      sortable: false,
      filterable: false,
      width: 60,
      Cell: (data) =>
        renderCheckbox(data, toggleSelectResponsible, selectedResponsible),
    },
    {
      Header: "",
      sortable: false,
      width: 60,
      className: styles.centeredText,
      Cell: (data) => renderEditButton(data, handleLoadResponsibleToModal),
    },
    {
      Header: "Nome",
      accessor: "name",
      sortable: true,
      className: styles.centeredText,
    },
    {
      Header: "Veterinário",
      accessor: "isVet",
      sortable: true,
      Cell: (data) => renderStatusCircle(data.original.isVet),
      className: styles.centeredText,
    },
    {
      Header: "Responsável",
      accessor: "isResponsible",
      sortable: true,
      Cell: (data) => renderStatusCircle(true),
      className: styles.centeredText,
    },
  ];

  return config;
};

function randomString(len) {
  const charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var randomString = "";
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
}
/**
 * @author Victor Heringer
 *
 * Regions form fields
 */
const ResponsibleFields = ({
  fields,
  $field,
  $fieldEvent,
  $validation,
  $submit,
  methods: {
    fieldChange,
    fieldChangeIsVet,
    addResponsibleToTable,
    handleRemoveResponsible,
    handleLoadResponsibleToModal,
    toggleSelectResponsible,
    handleResponsibleSignatureUpload,
  },
  data: {
    selectedResponsible,
    displayResponsibleModal,
    defaultResponsible,
    shouldDisableAll,
  },
}) => {
  const nameInput = (
    <LVInputTextUpper
      id="modalResponsibleName"
      name="modalResponsibleName"
      title="Nome"
      required
      autofocus={true}
      md={6}
      {...$field("modalResponsibleName", (event) => {
        fieldChange("modalResponsibleName", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalResponsibleName.show &&
        !$validation.modalResponsibleName.isValid
      }
      validationReason={
        $validation && $validation.modalResponsibleName.error.reason
      }
      isValid={$validation && $validation.modalResponsibleName.isValid}
    />
  );

  const cpfInput = (
    <HiddenMaterial>
      <LVInputTextUpper
        id="modalResponsibleCPF"
        name="modalResponsibleCPF"
        title="CPF"
        md={6}
        mask
        maskValue={CPF_MASK}
        value="55555555555"
        {...$field("modalResponsibleCPF", (event) => {
          fieldChange("modalResponsibleCPF", event.target.value);
        })}
        hasValidation={
          $validation &&
          $validation.modalResponsibleCPF.show &&
          !$validation.modalResponsibleCPF.isValid
        }
        validationReason={
          $validation && $validation.modalResponsibleCPF.error.reason
        }
        isValid={$validation && $validation.modalResponsibleCPF.isValid}
      />
    </HiddenMaterial>
  );

  const emailInput = (
    <HiddenMaterial>
      <LVInputTextUpper
        id="modalResponsibleEmail"
        name="modalResponsibleEmail"
        title="Email"
        required
        md={6}
        value={
          fields.modalResponsibleEmail ||
          "responsavel" + randomString(5).toString() + "@lifevet.com"
        }
        {...$field("modalResponsibleEmail", (event) => {
          fieldChange("modalResponsibleEmail", event.target.value);
        })}
        hasValidation={
          $validation &&
          $validation.modalResponsibleEmail.show &&
          !$validation.modalResponsibleEmail.isValid
        }
        validationReason={
          $validation && $validation.modalResponsibleEmail.error.reason
        }
        isValid={$validation && $validation.modalResponsibleEmail.isValid}
      />
    </HiddenMaterial>
  );

  const phoneInput = (
    <HiddenMaterial>
      <LVInputTextUpper
        id="modalResponsiblePhone"
        name="modalResponsiblePhone"
        title="Telefone"
        md={6}
        mask
        maskValue={PHONE_MASK}
        value="55555555555"
        {...$field("modalResponsiblePhone", (event) => {
          fieldChange("modalResponsiblePhone", event.target.value);
        })}
        hasValidation={
          $validation &&
          $validation.modalResponsiblePhone.show &&
          !$validation.modalResponsiblePhone.isValid
        }
        validationReason={
          $validation && $validation.modalResponsiblePhone.error.reason
        }
        isValid={$validation && $validation.modalResponsiblePhone.isValid}
      />
    </HiddenMaterial>
  );

  const loginInput = (
    <HiddenMaterial>
      <InputMaterial
        id="modalResponsibleLogin"
        name="modalResponsibleLogin"
        title="Login"
        required
        md={6}
        value={
          fields.modalResponsibleLogin ||
          "responsavel" + randomString(5).toString()
        }
        {...$field("modalResponsibleLogin", (event) => {
          fieldChangeIsVet("modalResponsibleLogin", event.target.value);
        })}
        hasValidation={
          $validation &&
          $validation.modalResponsibleLogin.show &&
          !$validation.modalResponsibleLogin.isValid
        }
        validationReason={
          $validation && $validation.modalResponsibleLogin.error.reason
        }
        isValid={$validation && $validation.modalResponsibleLogin.isValid}
      />
    </HiddenMaterial>
  );

  const passwordInput = (
    <HiddenMaterial>
      <InputMaterial
        id="modalResponsiblePassword"
        name="modalResponsiblePassword"
        title="Senha"
        required
        type="password"
        md={6}
        value={fields.modalResponsiblePassword || randomString(8).toString()}
        {...$field("modalResponsiblePassword", (event) => {
          fieldChange("modalResponsiblePassword", event.target.value);
        })}
        hasValidation={
          $validation &&
          $validation.modalResponsiblePassword.show &&
          !$validation.modalResponsiblePassword.isValid
        }
        validationReason={
          $validation && $validation.modalResponsiblePassword.error.reason
        }
        isValid={$validation && $validation.modalResponsiblePassword.isValid}
      />
    </HiddenMaterial>
  );

  const accessProfile = (
    <HiddenMaterial>
      <SelectMaterial
        title="Perfil de Acesso"
        md={6}
        name="modalResponsibleAccessProfile"
        options={ACCESS_PROFILES_LIST}
        value={1}
        disabled
        {...$field("modalResponsibleAccessProfile", (event) => {
          fieldChange("modalResponsibleAccessProfile", event);
        })}
      />
    </HiddenMaterial>
  );

  const active = (
    <SelectMaterial
      title="Ativo"
      md={6}
      name="modalResponsibleActive"
      options={STATUS}
      value="1"
      {...$field("modalResponsibleActive", (event) => {
        fieldChange("modalResponsibleActive", event);
      })}
    />
  );

  const shouldDisableVet =
    fields.modalResponsibleId > 0 && fields.modalResponsibleInitIsVet
      ? true
      : false;

  const isVetSelect = (
    <SelectMaterial
      title="Veterinário"
      md={6}
      name="modalResponsibleIsVet"
      options={YES_OR_NO}
      value={fields.modalResponsibleIsVet || "1"}
      {...$field("modalResponsibleIsVet", (event) => {
        fieldChangeIsVet("modalResponsibleIsVet", event);
      })}
    />
  );

  const crmv = (
    <LVInputTextUpper
      id="modalResponsibleCRMV"
      name="modalResponsibleCRMV"
      title="CRMV"
      md={6}
      value={fields.modalResponsibleCRMV || "RJ-"}
      {...$field("modalResponsibleCRMV", (event) => {
        fieldChange("modalResponsibleCRMV", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalResponsibleCRMV.show &&
        !$validation.modalResponsibleCRMV.isValid
      }
      validationReason={
        $validation && $validation.modalResponsibleCRMV.error.reason
      }
      isValid={$validation && $validation.modalResponsibleCRMV.isValid}
    />
  );

  const submitResponsible = () =>
    $submit(
      () =>
        addResponsibleToTable(
          {
            id: fields.modalResponsibleId,
            login: fields.modalResponsibleLogin,
            password: fields.modalResponsiblePassword,
            name: fields.modalResponsibleName,
            email: fields.modalResponsibleEmail,
            phone: fields.modalResponsiblePhone,
            cpf: fields.modalResponsibleCPF,
            phone: fields.modalResponsiblePhone,
            isVet: fields.modalResponsibleIsVet,
            crmv: fields.modalResponsibleCRMV,
            active: fields.modalResponsibleActive,
            signature: fields.modalResponsibleSignature,
            accessProfile: fields.modalResponsibleAccessProfile,
          },
          $fieldEvent
        ),
      () => ({})
    );

  const modal = (
    <Modal
      show={displayResponsibleModal}
      onHide={() => handleLoadResponsibleToModal(defaultResponsible)}
    >
      <Modal.Header closeButton>
        <Modal.Title> Adicionar Usuário Responsável </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {nameInput}
          {cpfInput}
          {emailInput}
          {phoneInput}
          {loginInput}
          {passwordInput}
          {accessProfile}
          {active}
          {isVetSelect}
          {crmv}
        </Row>
        <Row className="p-15">
          <Col xs={12}>
            <LVFileInputMaterial
              id={"responsibleSignatureUpload"}
              name={"responsibleSignatureUpload"}
              allowedExtensions={[".png", ".jpg", ".jpeg"]}
              setContainerFiles={handleResponsibleSignatureUpload}
              asBase64={true}
              maxFileSize={500000}
              allowMultiples={false}
              hasImgPreview={true}
              clearBtnTitle={"Remover Assinatura"}
              title={"Selecionar Asssinatura"}
              description={
                "Tamanho máximo aceito para o arquivo: 500kb - Utilize a resolução 200x85 px"
              }
              hasFilesNames={true}
              placeholders={fields.modalResponsibleSignature}
              disabled={shouldDisableAll}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefaultMaterial
          keyCode={SUBMIT_MODAL_KEY}
          onKeyPress={submitResponsible}
          type="default"
          title="Adicionar"
          disabled={shouldDisableAll}
          onClick={submitResponsible}
        />
      </Modal.Footer>
    </Modal>
  );

  const openModalBtn = (
    <TableButtonAddMaterial
      title={"Novo Usuário"}
      className={"pull-right"}
      disabled={shouldDisableAll}
      onClick={() => handleLoadResponsibleToModal(defaultResponsible)}
    />
  );

  const table = (
    <DefaultTable
      configurations={getTableConfigs(
        fields.responsible,
        toggleSelectResponsible,
        selectedResponsible,
        handleLoadResponsibleToModal
      )}
    />
  );

  const removeResponsibleBtn = (
    <TableButtonRemoveMaterial
      text={{
        confirm: "Remover",
        message:
          "Os registros selecionados serão removidos permanentemente. Deseja continuar?",
        title: "Remover Usuários",
      }}
      className="pull-left"
      disabled={selectedResponsible.length === 0}
      onClick={handleRemoveResponsible}
    />
  );

  return (
    <React.Fragment>
      {modal}
      <Col md={12}>
        <Row>
          <Col xs={12} className="mb-15 mt-15">
            {openModalBtn}
            {removeResponsibleBtn}
            <h5 className="table-title text-center"> Responsável </h5>
          </Col>
          <Col xs={12}>{table}</Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

const withValidation = validated(responsibleModalValidation);

export default withValidation(ResponsibleFields);
