export const leaf = "#0AAFAA";
export const orchid = "#00A0C2";
export const blueberry = "#008CCD";
export const grape = "#5F72C2";
export const violet = "#92519E";
export const rose = "#BB006B";
export const petunia = "#FFFFFF";
export const blackberry = "#212121";
export const granadilla = "#A6ABBD";
export const pumpkin = "#FFA17A";
export const red = "#FF0000";

export const leaf100 = "#CBFBE8";
export const leaf200 = "#99F7DB";
export const leaf300 = "#64E7CC";
export const leaf400 = "#3CCFBD";
export const leaf500 = "#0AAFAA";
export const leaf600 = "#078E96";
export const leaf700 = "#056C7D";
export const leaf800 = "#034E65";
export const leaf900 = "#013953";

export const lime100 = "#EFFAD1";
export const lime200 = "#DDF6A6";
export const lime300 = "#BCE374";
export const lime400 = "#97C94E";
export const lime500 = "#69A51F";
export const lime600 = "#538D16";
export const lime700 = "#3F760F";
export const lime800 = "#2D5F09";
export const lime900 = "#214F05";

export const blueberry100 = "#D4F5F9";
export const blueberry200 = "#ABE7F3";
export const blueberry300 = "#7BC6DC";
export const blueberry400 = "#539CBA";
export const blueberry500 = "#25688D";
export const blueberry600 = "#1B5179";
export const blueberry700 = "#123D65";
export const blueberry800 = "#0B2B51";
export const blueberry900 = "#071E43";

export const pumpkin100 = "#FFF3E4";
export const pumpkin200 = "#FFE4C9";
export const pumpkin300 = "#FFD1AF";
export const pumpkin400 = "#FFBF9B";
export const pumpkin500 = "#FFA17A";
export const pumpkin600 = "#DB7659";
export const pumpkin700 = "#B7503D";
export const pumpkin800 = "#933026";
export const pumpkin900 = "#7A1917";

export const lychee100 = "#FBC9D2";
export const lychee200 = "#F894B0";
export const lychee300 = "#EA5D92";
export const lychee400 = "#D63581";
export const lychee500 = "#BB006B";
export const lychee600 = "#A0006C";
export const lychee700 = "#860067";
export const lychee800 = "#6C005E";
export const lychee900 = "#590057";

export const granadilla100 = "#F5F7FB";
export const granadilla200 = "#ECEFF8";
export const granadilla300 = "#DADEEB";
export const granadilla400 = "#C3C8D7";
export const granadilla500 = "#A6ABBD";
export const granadilla600 = "#7981A2";
export const granadilla700 = "#535C88";
export const granadilla800 = "#343D6D";
export const granadilla900 = "#1F275A";

export const text000 = "#FFFFFF";
export const text100 = "#FAFAFA";
export const text200 = "#F5F5F5";
export const text300 = "#EEEEEE";
export const text400 = "#E0E0E0";
export const text500 = "#BDBDBD";
export const text600 = "#9E9E9E";
export const text700 = "#757575";
export const text800 = "#616161";
export const text900 = "#424242";
export const text1000 = "#424242";

export const Colors = {
  text: {
    text000: text000,
    text100: text100,
    text200: text200,
    text300: text300,
    text400: text400,
    text500: text500,
    text600: text600,
    text700: text700,
    text800: text800,
    text900: text900,
    text1000: text1000
  },
  leaf: {
    leaf100: leaf100,
    leaf200: leaf200,
    leaf300: leaf300,
    leaf400: leaf400,
    leaf500: leaf500,
    leaf600: leaf600,
    leaf700: leaf700,
    leaf800: leaf800,
    leaf900: leaf900
  },
  lime: {
    lime100: lime100,
    lime200: lime200,
    lime300: lime300,
    lime400: lime400,
    lime500: lime500,
    lime600: lime600,
    lime700: lime700,
    lime800: lime800,
    lime900: lime900
  },
  blueberry: {
    blueberry100: blueberry100,
    blueberry200: blueberry200,
    blueberry300: blueberry300,
    blueberry400: blueberry400,
    blueberry500: blueberry500,
    blueberry600: blueberry600,
    blueberry700: blueberry700,
    blueberry800: blueberry800,
    blueberry900: blueberry900
  },
  pumpkin: {
    pumpkin100: pumpkin100,
    pumpkin200: pumpkin200,
    pumpkin300: pumpkin300,
    pumpkin400: pumpkin400,
    pumpkin500: pumpkin500,
    pumpkin600: pumpkin600,
    pumpkin700: pumpkin700,
    pumpkin800: pumpkin800,
    pumpkin900: pumpkin900
  },
  lychee: {
    lychee100: lychee100,
    lychee200: lychee200,
    lychee300: lychee300,
    lychee400: lychee400,
    lychee500: lychee500,
    lychee600: lychee600,
    lychee700: lychee700,
    lychee800: lychee800,
    lychee900: lychee900
  },
  granadilla: {
    granadilla100: granadilla100,
    granadilla200: granadilla200,
    granadilla300: granadilla300,
    granadilla400: granadilla400,
    granadilla500: granadilla500,
    granadilla600: granadilla600,
    granadilla700: granadilla700,
    granadilla800: granadilla800,
    granadilla900: granadilla900
  }
};
