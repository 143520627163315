import React from "react";
import styled from "styled-components";
import { text800 } from "./../colors";
import { secondaryFont } from "./../fonts";

const StyledListText = styled.p`
  ${secondaryFont}
  margin: 0;
  color: ${text800};
  font-size: 16px;
`;

const ListText = props => (
  <StyledListText {...props}>{props.children}</StyledListText>
);

export default ListText;
