import { validated } from 'react-custom-validation';

import {
  commonReportRules, parasitologicalFreshFecesRules
  } from '../../assets/js/validationRules';

const validation = validated( props => {

  const {
    fecesColorOptionId,
    fecesSmellOptionId,
    fecesAspectOptionId,
    fecesConsistencyOptionId,
    macroscopicParasites,
    vetSignerId,
    requestExamState
  } = props.data.fields;

 const {completeValidation} = props.data;
 const result = props.data.result;

  let fields = [
    'fecesColorOptionId',
    'fecesSmellOptionId',
    'fecesAspectOptionId',
    'fecesConsistencyOptionId',
    'macroscopicParasites',
    'result',
    'vetSignerId',
    'requestExamState'
  ];

  const commonValidation = commonReportRules(
    vetSignerId, requestExamState );

  const freshFecesValidation = parasitologicalFreshFecesRules(
    'fecesColorOptionId',
    fecesColorOptionId,
    'fecesSmellOptionId',
    fecesSmellOptionId,
    'fecesAspectOptionId',
    fecesAspectOptionId,
    'fecesConsistencyOptionId',
    fecesConsistencyOptionId,
    'macroscopicParasites',
    macroscopicParasites,
    'result',
    result,
    completeValidation
  );

  const validations = {
    ...commonValidation,
    ...freshFecesValidation
  };

  return { fields, validations };

} );

export default validation;