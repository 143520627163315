import update from "immutability-helper";
import Decimal from "./Decimal.js";

/**
 * @author Alessandro Bastos Grandini
 * @return {number}
 */
export function createAntibioticObjects(tags, originalAntibioticOptions) {
  const objs = tags.map((tag) => {
    tag = tag.slice(0, tag.indexOf("- "));
    tag = tag === "" ? " -" : tag;
    const found = originalAntibioticOptions.find((antibiotic) =>
        tag.trim() === antibiotic.name.trim()
    );
    return found;
  });
  return objs;
}

/**
 * @author Alessandro Bastos Grandini
 * @return {number}
 */
export function getColoniesCount() {
  const { coloniesCount, isNegative } = this.state.fields;
  return isNegative ? "" : coloniesCount;
}

export function prepareToggleIsNegative(
  isNegativeFieldName,
  resultName = null,
  negativeResultMessage
) {
  return function toggleIsNegative() {
    
    const then = () => {
      if(resultName === null)
        return
      this.setState((state) =>
        update(state, {
          fields: {
            [resultName]: {
              $set: this.state.fields[isNegativeFieldName]
              ? negativeResultMessage
              : this.state.fields[resultName]
            },
          },
        })
      );
    };

    this.setState((state) =>
      update(state, {
        fields: {
          [isNegativeFieldName]: {
            $set: !this.state.fields[isNegativeFieldName],
          }
        },
      }), then
    );
  };
}

export function createAntibioticTagName(antibiotic) {
  return antibiotic.name + " - " + antibiotic.initials;
}

export function prepareAntibioticTags(options) {
  return options.map((antibiotic) => {
    return {
      id: antibiotic.id,
      name: createAntibioticTagName(antibiotic),
    };
  });
}

export function createAntibioticOptions(antibioticOptionsPromise) {
  if (antibioticOptionsPromise.pending) {
    return [];
  }

  const antibiotics = antibioticOptionsPromise.value;

  return prepareAntibioticTags(antibiotics);
}

export function calculateQuantitativeResult(coloniesCount) {
  if (
    coloniesCount === null ||
    coloniesCount === "" ||
    coloniesCount === undefined
  ) {
    return "";
  }
  const coloniesCountDec = new Decimal(coloniesCount);
  return coloniesCountDec.times(100).toPrecision();
}

export function getQuantitativeResult(coloniesCount, isNegative) {
  if (isNegative) {
    return "";
  }

  if (coloniesCount === "") {
    return "";
  }

  return calculateQuantitativeResult(coloniesCount);
}
