import React, { useMemo } from "react";
import { useTable, useRowSelect } from "react-table-v7";
import { Table } from "react-bootstrap";
import { RequestExam } from "../../../../../types/index";
import { REQUEST_EXAM_STATES } from "./../../../../../consts/index";
import {
  renderPrintButton,
  renderDeleteButton,
  renderCheckbox,
  renderStatus,
  renderDeadLine,
  renderPrice,
  renderObservation,
  renderPaymentMethod,
} from "./fields";

const TOTAL_PER_PAGE = 7;

export const RequestExamsTable = ({
  data,
  methods,
  handleChange,
  handleBlur,
  loggedUserIsFromClinic,
  printExam,
  cancelExam,
  redirectToExam,
  disableAll,
}: {
  data: Array<RequestExam>;
  methods: any;
  handleChange: any;
  handleBlur: any;
  loggedUserIsFromClinic: boolean;
  printExam: any;
  cancelExam: any;
  redirectToExam: (exam: RequestExam) => void;
  disableAll: boolean;
}) => {
  const singleClick = (exam: RequestExam) => {
    if (
      loggedUserIsFromClinic &&
      exam.status >= REQUEST_EXAM_STATES.ATTENDED_AND_RELEASED
    ) {
      printExam(exam);
    }
  };

  const doubleClick = (exam: RequestExam) => redirectToExam(exam);

  const columns = useMemo(
    () => [
      {
        Header: "Impressão",
        accessor: "id",
        minWidth: "50px",
        id: "print",
        Cell: ({ row: { original } }: { row: { original: RequestExam } }) =>
          renderPrintButton(original, printExam, loggedUserIsFromClinic),
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "id",
        minWidth: "50px",
        id: "delete",
        Cell: ({ row: { original } }: { row: { original: RequestExam } }) =>
          renderDeleteButton(original, cancelExam, methods.remove),
        disableSortBy: true,
      },
      {
        Header: "Nome do Exame",
        accessor: "name",
        minWidth: "120px",
        id: "name",
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "status",
        minWidth: "120px",
        id: "status",
        Cell: ({ row }: { row: any }) => renderStatus(row.original),
        disableSortBy: true,
      },
      {
        Header: "Prazo (dias)",
        accessor: "deadlineInDays",
        minWidth: "75px",
        id: "deadlineInDays",
        Cell: ({ row }: { row: { original: RequestExam } }) => {
          const data = {
            exam: row.original,
            loggedUserIsFromClinic: loggedUserIsFromClinic,
            disable: disableAll,
          };
          return renderDeadLine(data, methods.update);
        },
        disableSortBy: true,
      },
      {
        Header: "Preço (R$)",
        accessor: "price",
        minWidth: "100px",
        id: "price",
        Cell: ({ row }: { row: { original: RequestExam } }) => {
          const data = {
            exam: row.original,
            loggedUserIsFromClinic: loggedUserIsFromClinic,
            disable: disableAll,
          };
          return renderPrice(data, methods.update);
        },
        disableSortBy: true,
      },
      {
        Header: "Pago",
        accessor: "isPaid",
        minWidth: "60px",
        id: "isPaid",
        Cell: ({ row }: { row: { original: RequestExam } }) => {
          const data = {
            exam: row.original,
            loggedUserIsFromClinic: loggedUserIsFromClinic,
            disable: disableAll,
          };
          return renderCheckbox(data, methods.update);
        },
        disableSortBy: true,
      },
      {
        Header: "Observação",
        accessor: "obs",
        minWidth: "140px",
        id: "obs",
        Cell: ({ row }: { row: { original: RequestExam } }) => {
          const data = {
            exam: row.original,
            loggedUserIsFromClinic: loggedUserIsFromClinic,
            disable: disableAll,
          };
          return renderObservation(data, methods.update);
        },
        disableSortBy: true,
      },
      {
        Header: "Forma de Pagamento",
        accessor: "paymentMethod",
        minWidth: "120px",
        id: "paymentMethod",
        Cell: ({ row }: { row: { original: RequestExam } }) => {
          const data = {
            exam: row.original,
            loggedUserIsFromClinic: loggedUserIsFromClinic,
            disable: disableAll,
          };
          return renderPaymentMethod(data, methods.update);
        },
        disableSortBy: true,
      },
      {
        Header: "Recebimento",
        accessor: "startDate",
        minWidth: "90px",
        id: "startDate",
        Cell: ({ row }: { row: { original: RequestExam } }) => {
          let date = "";
          let time = "";
          if (row.original.startDate) {
            const obj = new Date(row.original.startDate);
            date = obj.toLocaleDateString();
            time = obj.toLocaleTimeString();
          }
          return <span>{`${date} ${time}`}</span>;
        },
        disableSortBy: true,
      },
    ],
    [disableAll]
  );

  const exams = useMemo(() => data, [data]);

  const table = useTable(
    {
      initialState: {
        pageSize: TOTAL_PER_PAGE,
        pageIndex: 0,
      },
      columns: columns,
      data: exams,
      autoResetFilters: false, // don't reset filters when passed new data to the table
      autoResetSortBy: false, // don't reset sorting when passed new data to the table
      autoResetPage: false, // don't reset pagination when passed new data to the table
      disableMultiSort: true,
      pageCount: 1,
    },
    useRowSelect
  );
  const {
    state: { sortBy },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleRowSelected,
  } = table;

  return (
    <div style={{ overflowX: "scroll", overflowY: "visible" }}>
      <Table
        {...getTableProps()}
        condensed
        bordered
        hover
        striped
        className={"text-center"}
      >
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps()}
                  style={{ textAlign: "center" }}
                >
                  {column.render("Header")}{" "}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps(row.getToggleRowSelectedProps())}
                onClick={() => {
                  let selectedRow = selectedFlatRows.slice(-1).pop();
                  if (selectedRow && row.index === selectedRow.index) {
                    doubleClick(row.original);
                    return;
                  }

                  if (selectedRow) toggleRowSelected(selectedRow.index, false);
                  row.toggleRowSelected();
                  singleClick(row.original);
                }}
                style={
                  row.isSelected
                    ? { cursor: "pointer", backgroundColor: "#B7DBFF" }
                    : { cursor: "pointer" }
                }
              >
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      style={{ minWidth: cell.column.minWidth }}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {rows.length === 0 && (
        <div
          style={{ marginTop: "-15px", fontWeight: "bold" }}
          className="text-center"
        >
          Nenhum registro.
        </div>
      )}
    </div>
  );
};
