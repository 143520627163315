import React from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";

/**
 * Assets
 */
import "./index.css";

/**
 * @author Victor Heringer
 *
 * Goes to de previous route
 */
const ON_CLICK = () => {
  // console.log(window.location);
};

/**
 * @author Victor Heringer
 *
 * Pure links for sidebar
 */
const ButtonPrintMaterial = ({ title, type, right, disabled }) => (
  <ButtonDefaultMaterial
    onClick={ON_CLICK}
    type={type}
    title={title}
    disabled={disabled}
    className={"btn-print" + (right ? " btn-right" : "")}
  />
);

ButtonPrintMaterial.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["default", "highlight"]),
  right: PropTypes.bool,
};

export default ButtonPrintMaterial;
