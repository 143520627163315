import React from "react";
import styled from "styled-components";
import PanelFooter from "./panel-footer";
import PanelBody from "./panel-body";
import PanelHeader from "./panel-header";

const StyledPanel = styled.div``;

const Panel = props => <StyledPanel {...props}>{props.children}</StyledPanel>;

export { Panel, PanelHeader, PanelBody, PanelFooter };
