import { required } from "../../assets/js/validationFunctions.js";

/**
 * @author Victor Heringer
 *
 * Validation function for pricing table
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const pricingTableModalValidation = props => {
  const { modalPricingTableName } = props.data.fields;

  const fields = ["modalPricingTableName"];

  const validations = {
    modalPricingTableName: {
      rules: [[required, modalPricingTableName, "Informe um nome"]]
    }
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for exam place
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const examPlaceModalValidation = props => {
  const { modalExamPlaceName } = props.data.fields;

  const fields = ["modalExamPlaceName"];

  const validations = {
    modalExamPlaceName: {
      rules: [[required, modalExamPlaceName, "Informe um nome"]]
    }
  };

  return { fields, validations };
};
