import { validated } from "react-custom-validation";
import {
  required,
  minLength,
  greaterThan,
  validateCNPJ,
  validateCPF,
  validEmail,
  requiredIfExistAnother,
  handleApiValidate
} from "../../assets/js/validationFunctions.js";

import { commonReportRules } from "../../assets/js/validationRules";

const validation = validated(props => {
  const {
    vetSignerId,
    requestExamState,
    sample,
    microscopy,
    diagnosis,
    note,
    signature
  } = props.data.fields;

  const { completeValidation } = props.data;
  const commonValidation = commonReportRules(vetSignerId, requestExamState);
  const validations = {
    ...commonValidation,
    sample: {
      rules: completeValidation ? [[required, sample, "Informe a amostra"]] : []
    },
    microscopy: {
      rules: completeValidation
        ? [[required, microscopy, "Informe a microscopia"]]
        : []
    },
    diagnosis: {
      rules: completeValidation
        ? [[required, diagnosis, "Informe o diagnóstico"]]
        : []
    },
    note: {
      rules: completeValidation
        ? [[required, note, "Informe a nota do patologista"]]
        : []
    },
    signature: {
      rules: completeValidation
        ? [[required, signature, "Informe a assinatura do patologista"]]
        : []
    }
  };

  let fields = [
    "requestExamState",
    "vetSignerId",
    "sample",
    "microscopy",
    "diagnosis",
    "note",
    "signature"
  ];

  return { fields, validations };
});

export default validation;
