import React, { useEffect, useState } from "react";
import * as Lifevet from "./../../../components/index";
import { Request, RequestExam } from "../../../types/index";
import { useFetch, ROUTES } from "../../../hooks/use-fetch";
import {
  Header,
  Body,
  Footer,
  ReportModal,
  ReceiveAndAssistModal,
} from "./components/index";
import {
  MESSAGES,
  EXAMS,
  EXAM_REQUEST_STATES,
  ENV,
} from "./../../../consts/index";

export const View = ({
  data,
  methods,
  errors,
  touched,
  setError,
  setTouched,
  handleChange,
  handleBlur,
  submit,
  requestIsSubmitting,
  fetching,
  history,
  isMobile,
  loggedUserIsFromClinic,
  loggedUserIsAClinicUser,
  labId,
  customerId,
  formIsInUpdateMode,
  setUpRedirectionTo,
  successNotification,
  errorNotification,
  warningNotification,
  infoNotification,
}: {
  data: Request;
  methods: any;
  errors: any;
  touched: any;
  setError: any;
  setTouched: any;
  handleChange: any;
  handleBlur: any;
  submit: any;
  requestIsSubmitting: boolean;
  fetching: boolean;
  history: any;
  isMobile: boolean;
  loggedUserIsFromClinic: boolean;
  loggedUserIsAClinicUser: boolean;
  labId: number;
  customerId: number;
  formIsInUpdateMode: boolean;
  setUpRedirectionTo: any;
  successNotification: any;
  errorNotification: any;
  warningNotification: any;
  infoNotification: any;
}) => {
  const [receiveModalIsOpen, setReceiveModalIsOpen] = useState(false);
  const [animalModalIsOpen, setAnimalModalIsOpen] = useState(false);
  const [reportModalIsOpen, setReportModalIsOpen] = useState(false);

  const openReceiveModal = () => setReceiveModalIsOpen(true);
  const closeReceiveModal = () => setReceiveModalIsOpen(false);

  const openAnimalModal = () => {
    methods.animal.customer.update(data.customer);
    setAnimalModalIsOpen(true);
  };
  const closeAnimalModal = () => setAnimalModalIsOpen(false);

  const openReportModal = () => setReportModalIsOpen(true);
  const closeReportModal = () => setReportModalIsOpen(false);

  const customersFetching = useFetch();
  const vetsFetching = useFetch();

  useEffect(() => {
    if (fetching) return;
    customersFetching.fetch(ROUTES.getCustomersByLab(labId));
  }, []);

  useEffect(() => {
    if (data.customer.id === 0) return;

    vetsFetching.fetch(ROUTES.getVetsByCustomer(data.customer.id));
  }, [data.customer.id]);

  const emailSending = useFetch();

  const sendRequestMail = (requestId: number) => {
    const onSuccess = () => {};

    const onError = () => errorNotification(MESSAGES.EMAIL_SENDING_ERROR);
    emailSending.fetch(
      ROUTES.mailRequest(requestId),
      { method: "POST" },
      onSuccess,
      onError
    );

    successNotification(MESSAGES.EMAIL_SENT_SUCCESSFULLY);
  };

  const reportFetching = useFetch();

  const printRequest = (requestId: number) => {
    const onSuccess = () => openReportModal();
    const onError = () => errorNotification(MESSAGES.ERROR_MESSAGE);

    reportFetching.fetch(
      ROUTES.getRequestPrintedReport(requestId),
      {
        method: "GET",
      },
      onSuccess,
      onError
    );
    infoNotification(MESSAGES.WAITING_REPORT_MESSAGE);
  };

  const printExam = (exam: RequestExam) => {
    const onSuccess = () => openReportModal();
    const onError = () => errorNotification(MESSAGES.ERROR_MESSAGE);
    reportFetching.fetch(
      ROUTES.getExamPrintedReport(exam.report ? exam.report.id : 0),
      { method: "GET" },
      onSuccess,
      onError
    );
    infoNotification(MESSAGES.WAITING_REPORT_MESSAGE);
  };

  const sampleReceiving = useFetch();
  const receiveAndAssist = (sampleCode: string) => {
    const onSuccess = (obj: any) => {
      if (obj === null || Object.keys(obj).length === 0) {
        errorNotification(MESSAGES.REQUEST_SAMPLE_CODE_NOT_FOUND);
      } else {
        closeReceiveModal();
        history.push(`/solicitacoes-exame/2.0/${obj.id}`);
      }
    };
    const onError = () => errorNotification(MESSAGES.ERROR_MESSAGE);

    sampleReceiving.fetch(
      ROUTES.receiveAndAssistSample(sampleCode),
      { method: "PUT", cache: "no-cache" },
      onSuccess,
      onError
    );
  };

  const redirectToRequestExam = (requestExam: RequestExam) => {
    const requestId = data.id;
    let url;

    const getBiochemistryPattern = (examTypeId: number) => {
      const pattern = {
        isBiochemistryPattern: false,
        name: "",
      };

      if (examTypeId === EXAMS.EXAM_TYPE_ID_BY_NAME.BIOCHEMISTRY) {
        pattern.isBiochemistryPattern = true;
        pattern.name = EXAMS.EXAM_TYPE_BY_ID[2];
      } else if (
        examTypeId ===
        EXAMS.EXAM_TYPE_ID_BY_NAME.ENDOCRINOLOGY_CHEMILUMINESCENCE
      ) {
        pattern.isBiochemistryPattern = true;
        pattern.name = EXAMS.EXAM_TYPE_BY_ID[5];
      } else if (
        examTypeId === EXAMS.EXAM_TYPE_ID_BY_NAME.ENDOCRINOLOGY_RADIOIMMUNOASSAY
      ) {
        pattern.isBiochemistryPattern = true;
        pattern.name = EXAMS.EXAM_TYPE_BY_ID[6];
      }

      return pattern;
    };

    const biochemistryPattern = getBiochemistryPattern(
      requestExam.exam.examType.id
    );

    if (
      !requestExam.exam.isOutsourced &&
      biochemistryPattern.isBiochemistryPattern
    ) {
      url = `/solicitacoes-exame/${requestId}/${biochemistryPattern.name}`;
    } else {
      const examUrlName = EXAMS.EXAM_URL_BY_ID(requestExam.exam.id);
      const examUrlId = requestExam.report ? requestExam.report.id : "novo";
      url = `/solicitacoes-exame/${requestId}/exame/${requestExam.id}/${examUrlName}/${examUrlId}`;
    }

    setUpRedirectionTo(url);
  };

  const ROOT = ENV.REACT_APP_S3_URL
    ? ENV.REACT_APP_S3_URL + "/"
    : ENV.REACT_APP_PROXY + "/storage/printed-reports/";

  const isReadOnly =
    data.status > EXAM_REQUEST_STATES.PENDING && loggedUserIsFromClinic;
  const disableAll =
    requestIsSubmitting || customersFetching.pending || vetsFetching.pending;

  const reportUrl = reportFetching.fulfilled
    ? ROOT + reportFetching.data.fileName
    : "";

  return (
    <Lifevet.Panel>
      {receiveModalIsOpen && (
        <ReceiveAndAssistModal
          isOpen={receiveModalIsOpen}
          closeModal={closeReceiveModal}
          receiveAndAssist={receiveAndAssist}
          submitting={sampleReceiving.pending}
        />
      )}
      {reportModalIsOpen && (
        <ReportModal
          isOpen={reportModalIsOpen}
          isMobile={isMobile}
          url={reportUrl}
          closeModal={closeReportModal}
        />
      )}
      <Header
        id={data.id}
        formIsInUpdateMode={formIsInUpdateMode}
        disableAll={disableAll}
        history={history}
        openReceiveAndAssistModal={openReceiveModal}
      />
      <Body
        data={data}
        methods={methods}
        history={history}
        errors={errors}
        touched={touched}
        setError={setError}
        setTouched={setTouched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        customersFetching={customersFetching}
        vetsFetching={vetsFetching}
        disableAll={disableAll}
        isMobile={isMobile}
        loggedUserIsFromClinic={loggedUserIsFromClinic}
        loggedUserIsAClinicUser={loggedUserIsAClinicUser}
        formIsInUpdateMode={formIsInUpdateMode}
        isReadOnly={isReadOnly}
        labId={labId}
        customerId={customerId}
        animalModalIsOpen={animalModalIsOpen}
        openAnimalModal={openAnimalModal}
        closeAnimalModal={closeAnimalModal}
        printExam={printExam}
        redirectToExam={redirectToRequestExam}
        successNotification={successNotification}
        errorNotification={errorNotification}
        warningNotification={warningNotification}
        infoNotification={infoNotification}
      />
      <Footer
        requestId={data.id}
        disableAll={disableAll}
        isReadOnly={isReadOnly}
        formIsInUpdateMode={formIsInUpdateMode}
        loggedUserIsFromClinic={loggedUserIsFromClinic}
        goBack={history.goBack}
        requestIsSubmitting={requestIsSubmitting}
        submit={submit}
        submitWithPartialSave={() =>
          submit({ isPartialSave: true, redirect: true, restart: true })
        }
        submitAndRestart={() =>
          submit({ isPartialSave: false, redirect: false, restart: true })
        }
        printRequest={printRequest}
        sendEmail={sendRequestMail}
      />
    </Lifevet.Panel>
  );
};
