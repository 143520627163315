import {
  Request,
  Customer,
  RequestExam,
  RequestSample,
  Email,
  Phone,
  Address,
  Animal,
  Specie,
  Breed,
  Vet,
  CustomerType,
  getSexNameFromId,
  Sex,
} from "./../../../types/index";
import { toString } from "./../../../consts/payment-methods";

export const RequestConversor = (oldRequest: any): Request => {
  const customer = CustomerConversor(oldRequest.customer);
  const vet = VetConversor(oldRequest.vet);
  const animal = AnimalConversor(oldRequest.animal);
  const requestExams = oldRequest.request_exams.map((oldRequestExam: object) =>
    RequestExamConversor(oldRequestExam)
  );
  const requestSamples = oldRequest.request_samples.map(
    (oldRequestSample: object) => RequestSampleConversor(oldRequestSample)
  );
  const newExamRequest: Request = {
    id: oldRequest.id,
    clinicalReport: oldRequest.clinical_report,
    status: oldRequest.exam_request_state,
    createdAt: oldRequest.created_at,
    updatedAt: oldRequest.update_at,
    customer: customer,
    vet: vet,
    animal: animal,
    exams: requestExams,
    samples: requestSamples,
  };
  return newExamRequest;
};

export const CustomerConversor = (oldCustomer: any): Customer => {
  let addresses = [];
  let emails = [];
  let phones = [];

  if (oldCustomer.addresses) {
    addresses = oldCustomer.addresses.map((address: object) =>
      AddressConversor(address)
    );
  }

  if (oldCustomer.emails) {
    emails = oldCustomer.emails.map((email: object) => EmailConversor(email));
  }

  if (oldCustomer.emails) {
    phones = oldCustomer.phones.map((phone: object) => PhoneConversor(phone));
  }

  return {
    id: oldCustomer.id,
    type: oldCustomer.type,
    name: oldCustomer.name,
    isActive: oldCustomer.is_active,
    receivesPrintedReport: oldCustomer.shouldSendPrintedReport,
    phones: phones,
    emails: emails,
    addresses: addresses,
  };
};

export const AnimalConversorForList = (oldAnimal: any): Animal => {
  const specie = {
    id: oldAnimal.speciesId,
    name: oldAnimal.species,
    status: 1,
  };

  const breed = {
    id: oldAnimal.breedId,
    name: oldAnimal.breed,
    status: 1,
  };

  const customer = {
    id: oldAnimal.customerId,
    type: CustomerType.COMPANY,
    name: oldAnimal.customer,
    isActive: true,
    receivesPrintedReport: false,
    phones: [],
    emails: [],
    addresses: [],
  };

  const sexId: Sex = oldAnimal.sexId;

  return {
    id: oldAnimal.id,
    name: oldAnimal.name,
    birthDate: oldAnimal.birthDate,
    owner: oldAnimal.owner,
    phone: oldAnimal.phone,
    cpf: oldAnimal.cpf,
    externalCode: oldAnimal.externalCode,
    microchipCode: oldAnimal.microchipCode,
    sexId: sexId,
    sexName: getSexNameFromId[sexId],
    specie,
    breed,
    customer,
  };
};

export const AnimalConversor = (oldAnimal: any): Animal => {
  const sexId: Sex = oldAnimal.sexId;

  const specie = {
    id: oldAnimal.specie.id,
    name: oldAnimal.specie.name,
    status: oldAnimal.specie.status,
  };

  const breed = {
    id: oldAnimal.breed.id,
    name: oldAnimal.breed.name,
    status: oldAnimal.breed.status,
  };

  const customer = CustomerConversor(oldAnimal.customer);

  return {
    id: oldAnimal.id,
    name: oldAnimal.name,
    birthDate: oldAnimal.age.birth_date,
    owner: oldAnimal.owner,
    phone: oldAnimal.phone,
    cpf: oldAnimal.cpf,
    externalCode: oldAnimal.external_code,
    microchipCode: oldAnimal.microchip_code,
    sexId: oldAnimal.sex_id,
    sexName: "",
    specie,
    breed,
    customer,
  };
};

export const VetConversor = (oldVet: any): Vet => {
  return {
    id: oldVet.id,
    name: oldVet.name,
    crmv: oldVet.crmv,
    signatureData: oldVet.signature_data,
    signatureFile: oldVet.signature_file,
  };
};

export const EmailConversor = (oldEmail: any): Email => {
  return {
    id: oldEmail.id,
    value: oldEmail.value,
    sendReport: oldEmail.send_report,
    sendInvoice: oldEmail.send_invoice,
  };
};

export const PhoneConversor = (oldPhone: any): Phone => {
  return {
    id: oldPhone.id,
    value: oldPhone.value,
  };
};

export const AddressConversor = (oldAddress: any): Address => {
  return {
    id: oldAddress.id,
    publicPlace: oldAddress.public_place,
    cep: oldAddress.cep,
    number: oldAddress.number,
    complement: oldAddress.complement,
    neighborhood: oldAddress.neighborhood,
    cityId: oldAddress.city_id,
    stateId: oldAddress.state_id,
  };
};

export const RequestExamConversor = (oldRequestExam: any): RequestExam => {
  return {
    id: oldRequestExam.id,
    name: oldRequestExam.name,
    price: oldRequestExam.final_price,
    deadlineInDays: oldRequestExam.final_dead_line,
    obs: oldRequestExam.obs,
    paymentMethod: oldRequestExam.payment_method ? {
      id: oldRequestExam.payment_method,
      name: toString(oldRequestExam.payment_method),
    } : undefined,
    isPaid: oldRequestExam.is_paid,
    status: oldRequestExam.request_exam_state,
    startDate: oldRequestExam.start_date_time,
    attachmentId: oldRequestExam.attachment_id,
    exam: {
      id: oldRequestExam.lab_exam.exam.id,
      name: oldRequestExam.lab_exam.exam.name,
      isOutsourced: oldRequestExam.lab_exam.exam.is_outsourced,
      examType: {
        id: oldRequestExam.lab_exam.exam.exam_type.id,
        name: oldRequestExam.lab_exam.exam.exam_type.name,
      },
    },
    report: oldRequestExam.report
      ? {
          id: oldRequestExam.report.id,
          vetSignerId: oldRequestExam.report.vet_signer_id,
        }
      : null,
    createdAt: oldRequestExam.created_at,
    updatedAt: oldRequestExam.update_at,
  };
};

export const RequestSampleConversor = (
  oldRequestSample: any
): RequestSample => {
  return {
    id: oldRequestSample.id,
    code: oldRequestSample.code,
    name: oldRequestSample.name,
    sampleId: oldRequestSample.sample.id,
  };
};
