/**
 * @author Alessandro Bastos Grandini
 *
 * Converts deadline in number format to string with suffix
 *
 * @return {string}
 *
 */
export default function deadlineToString(value) {
  if (value === 0) {
    return "";
  }

  if (value === 1) {
    return "1 dia";
  }

  return value + " dias";
}
