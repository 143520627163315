import __update from "immutability-helper";
import { Vet, Action } from "./../../types/index";

export const ACTIONS = {
  UPDATE: "UPDATE",
  UPDATE_FIELD: "UPDATE_FIELD",
};

const update = (value: Vet, target: Vet): Vet => {
  const commands = {
    id: { $set: value.id },
    name: { $set: value.name },
    crmv: { $set: value.crmv },
    signatureData: { $set: value.signatureData },
    signatureFile: { $set: value.signatureFile },
  };
  return __update(target, commands);
};

const updateField = (field: string, value: any, target: Vet) => {
  const commands = {
    [field]: { $set: value },
  };
  return __update(target, commands);
};

export const reducer = (state: Vet, action: Action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return update(action.payload, state);
    case ACTIONS.UPDATE_FIELD:
      return updateField(action.payload.field, action.payload.value, state);
    default:
      return state;
  }
};
