import React, { Component } from "react";
import update from "immutability-helper";
import validation from "./validation.js";
import connectWithEndpoint from "./requests.js";
import View from "./View";

import { FIVE_SECONDS } from "./../../consts/time-lengths";

const INVALID_CURRENT_PASSWORD = {
  title: "Erro",
  message: "Senha atual informada é inválida.",
  level: "error",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

const VALID_CHANGE_PASSWORD = {
  title: "Sucesso",
  message: "Senha alterada com sucesso!",
  level: "success",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

/**
 * @author Victor Heringer
 *
 * Adds validation to the view
 *
 * @prop {Object} data
 * @prop {Object} methods
 * @prop {Object} $field
 * @prop {Object} $validation
 * @prop {Function} $submit
 */
const ViewWithValidation = ({
  data,
  methods,
  $field,
  $validation,
  $submit,
}) => {
  const dataWithValidation = { $validation: $validation, ...data };

  const methodsWithValidation = {
    onSubmit: (e) => {
      e.preventDefault();
      $submit(methods.handleSubmit, methods.invalidNotification);
    },
    $field: $field,
    ...methods,
  };

  return <View data={dataWithValidation} methods={methodsWithValidation} />;
};

const ValidatedView = validation(ViewWithValidation);

/**
 * @author Victor Heringer
 *
 * Container to change password
 */
class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        currentPassword: "",
        newPassword: "",
        reNewPassword: "",
      },
    };

    this.fieldChange = this.fieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * @author Victor Heringer
   *
   * Changes the given field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChange(field, value) {
    this.setState((state) =>
      update(state, { fields: { [field]: { $set: value } } })
    );
  }

  handleSubmit() {
    const onSuccess = () => {
      this.props.notificationSystem.add(VALID_CHANGE_PASSWORD);
      this.props.handleClose();
    };

    const onError = (err, req) => {
      if (req.response.status === 401) {
        this.props.notificationSystem.add(INVALID_CURRENT_PASSWORD);
      }
    };

    this.props.updatePassword(this.state.fields, onSuccess, onError);
  }

  render() {
    const data = {
      fields: this.state.fields,
      show: this.props.show,
    };

    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      handleClose: this.props.handleClose,
    };

    return <ValidatedView data={data} methods={methods} />;
  }
}

export default connectWithEndpoint(ChangePassword);
