import React, { Component } from "react";

/**
 * Internal
 */
import BreedListView from "./../BreedListView";

/**
 * External
 */
import refetchConnect from "./../../api-connector";
import { connect as reduxConnect } from "react-redux";
import { FunctionUtil } from "./../../useful";
import FadeIn from "react-fade-in";
import debounce from "lodash/debounce";
import { Redirect } from "react-router";
import { ACCESS_PROFILES } from "../../assets/js/Consts";

class BreedList extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.filter = this.filter.bind(this);
    this.onTableDoubleClick = this.onTableDoubleClick.bind(this);
    this.shouldDisableAll = this.shouldDisableAll.bind(this);
    this.state = {
      pages: 1,
      sorted: null,
      shouldRedirect: false,
      redirectToId: 0
    };
  }

  onTableDoubleClick(currentId, state, rowInfo, column) {
    this.setState({ shouldRedirect: true, redirectToId: rowInfo.original.id });
  }

  /**
   * @author Victor Heringer
   *
   * Filter breeds table
   *
   * @param {Object} state
   * @param {Object} instance
   */
  filter(state, instance) {
    const filters = { id: "", name: "", species: "" };

    for (let data in state.filtered) {
      filters[state.filtered[data].id] = state.filtered[data].value;
    }

    const then = (data, xhr) => {
      this.setState({
        pages: data.last_page
      });
    };
    this.props.search(filters, state.page + 1, state.sorted, then);
  }

  /**
   * @author Sergio Pallet
   *
   * Check if should disable all fields
   *
   * @return {Boolean}
   */
  shouldDisableAll() {
    const haveNoAccess =
      this.props.access_profile == ACCESS_PROFILES.CLINIC_ADMINISTRATOR ||
      this.props.access_profile == ACCESS_PROFILES.CLINIC_USER ||
      this.props.access_profile == ACCESS_PROFILES.UNIT_USER ||
      this.props.access_profile == ACCESS_PROFILES.UNIT_ADMINISTRATOR;
    return haveNoAccess;
  }

  render() {
    const { user, objects, access_profile, history } = this.props;

    if (this.state.shouldRedirect) {
      const id = this.state.redirectToId;
      return <Redirect to={`/racas/${id}`} />;
    }

    return (
      <FadeIn>
        <BreedListView
          user={user}
          objects={objects}
          filter={debounce(this.filter.bind(this), 500)}
          accessProfile={access_profile}
          pages={this.state.pages}
          onTableDoubleClick={this.onTableDoubleClick}
          disableAll={this.shouldDisableAll()}
          history={history}
        />
      </FadeIn>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user
  };
};

const connectWithEndpoint = refetchConnect(props => ({
  objects: `/breeds`,
  search: (filters, page, sorted, then) => ({
    objects: {
      url: `/breeds?id=${filters.id}&name=${filters.name}&specie=${filters.species}&page=${page}&sort=${sorted[0].id}&desc=${sorted[0].desc}`,
      method: "GET",
      then: then,
      force: true
    }
  })
}));

const connectWithRedux = reduxConnect(mapStateToProps);

export default FunctionUtil.compose(
  connectWithRedux,
  connectWithEndpoint
)(BreedList);
