import { validated } from 'react-custom-validation';

import {
  commonReportRules,
  hemogramRules,
  molecularBiologyRules,
  heartwormDiseaseRules,
  biochemistryRules2
  } from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    erythrocytes,
    corpuscularVolume,
    hemoglobiometry,
    globalLeukometry,
    relativeBasophils,
    relativeEosinophils,
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    relativeSegs,
    relativeLymphocytes,
    relativeMonocytes,
    relativeHeterophils,
    platelets,
    erythrogramObs,
    leukogramObs,
    plateletsObs,
    hemogramObs,
    isIntenseLeucopeny,
    ehrlichiaQualitativeResult,
    ehrlichiaTechnique,
    ehrlichiaCopyValues,
    ehrlichiaPrecision,
    ehrlichiaMolecularBiologyMaterials,
    babesiaQualitativeResult,
    babesiaTechnique,
    babesiaCopyValues,
    babesiaPrecision,
    babesiaMolecularBiologyMaterials,
    heartwormDiseaseIsPositive,
    heartwormDiseaseMaterialId,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const { refs, isUpdate, rows, completeValidation } = props.data;
  

  let fields = [
    'erythrocytes',
    'corpuscularVolume',
    'hemoglobiometry',
    'globalLeukometry',
    'relativeBasophils',
    'relativeEosinophils',
    'relativeMyelocytes',
    'relativeMetamyelocytes',
    'relativeBands',
    'relativeSegs',
    'relativeLymphocytes',
    'relativeMonocytes',
    'relativeHeterophils',
    'platelets',
    'erythrogramObs',
    'leukogramObs',
    'plateletsObs',
    'hemogramObs',
    'isIntenseLeucopeny',
    'ehrlichiaQualitativeResult',
    'ehrlichiaTechnique',
    'ehrlichiaCopyValues',
    'ehrlichiaPrecision',
    'ehrlichiaMolecularBiologyMaterials',
    'babesiaQualitativeResult',
    'babesiaTechnique',
    'babesiaCopyValues',
    'babesiaPrecision',
    'babesiaMolecularBiologyMaterials',
    'heartwormDiseaseIsPositive',
    'heartwormDiseaseMaterialId',
    'urea',
    'creatinine',
    'altTgp',
    'astTgo',
    'totalProteinAndFractionsTotalProtein',
    'totalProteinAndFractionsAlbumin',
    'bilirubinAndFractionsTotal',
    'bilirubinAndFractionsDirect',
    'requestExamState',
    'vetSignerId'
  ];

  const fieldNames = {
    erythrocytesName:           'erythrocytes',
    corpuscularVolumeName:      'corpuscularVolume',
    hemoglobiometryName:        'hemoglobiometry',
    relativeBasophilsName:      'relativeBasophils',
    relativeEosinophilsName:    'relativeEosinophils',
    relativeMyelocytesName:     'relativeMyelocytes',
    relativeMetamyelocytesName: 'relativeMetamyelocytes',
    relativeBandsName:          'relativeBands',
    relativeSegsName:           'relativeSegs',
    relativeLymphocytesName:    'relativeLymphocytes',
    relativeMonocytesName:      'relativeMonocytes',
    relativeHeterophilsName:    'relativeHeterophils',
    plateletsName:              'platelets',
    plateletsObsName:           'plateletsObs'
  };

  const commonValidation = commonReportRules( vetSignerId, requestExamState );

  const hemogramValidation = hemogramRules( {
    globalLeukometry: globalLeukometry,
    plateletsName: 'platelets',
    platelets: platelets,
    plateletsObsName: 'plateletsObs',
    plateletsObs: plateletsObs,
    erythrocytes: erythrocytes,
    corpuscularVolume: corpuscularVolume,
    hemoglobiometry: hemoglobiometry,
    erythrogramObs: erythrogramObs,
    mchc: props.data.mchc,
    mchcMax: refs.mchcMax,
    isIntenseLeucopeny: isIntenseLeucopeny,
    relativeBasophils: relativeBasophils,
    relativeEosinophils: relativeEosinophils,
    relativeLymphocytes: relativeLymphocytes,
    relativeMonocytes: relativeMonocytes,
    relativeMyelocytes: relativeMyelocytes,
    relativeMetamyelocytes: relativeMetamyelocytes,
    relativeBands: relativeBands,
    relativeSegs: relativeSegs,
    relativeHeterophils: relativeHeterophils,
    leukogramObs: leukogramObs,
    hasHeterophils: refs.hasHeterophils,
    isUpdate: isUpdate,
    total: props.data.total,
    fieldNames: fieldNames,
    hemogramObs,
    completeValidation
  } );

  const ehrlichiaValidation = molecularBiologyRules(
    'ehrlichiaTechnique',
    ehrlichiaTechnique,
    'ehrlichiaQualitativeResult',
    ehrlichiaQualitativeResult,
    'ehrlichiaCopyValues',
    ehrlichiaCopyValues,
    'ehrlichiaPrecision',
    ehrlichiaPrecision,
    'ehrlichiaMolecularBiologyMaterials',
    ehrlichiaMolecularBiologyMaterials,
    completeValidation
  );

  const babesiaValidation = molecularBiologyRules(
    'babesiaTechnique',
    babesiaTechnique,
    'babesiaQualitativeResult',
    babesiaQualitativeResult,
    'babesiaCopyValues',
    babesiaCopyValues,
    'babesiaPrecision',
    babesiaPrecision,
    'babesiaMolecularBiologyMaterials',
    babesiaMolecularBiologyMaterials,
    completeValidation
  );

  const heartwormDiseaseValidation = heartwormDiseaseRules(
    'heartwormDiseaseMaterialId',
    heartwormDiseaseMaterialId,
    'heartwormDiseaseIsPositive',
    heartwormDiseaseIsPositive,
    completeValidation
  );

  const biochemistryValidation = biochemistryRules2( rows, completeValidation );

  const validations = {
    ...commonValidation,
    ...hemogramValidation,
    ...ehrlichiaValidation,
    ...babesiaValidation,
    ...heartwormDiseaseValidation,
    ...biochemistryValidation
  };

  return { fields, validations };
} );

export default validation;