import React, { Component } from "react";

/** Internal */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import StatusCircle from "./../StatusCircle";
import LVTable from "./../../old_components/LVTable";
import PaymentMethodModal from "./PaymentMethodModal";

/** External */
import { Link } from "react-router-dom";

/**
 * @author Victor Heringer
 *
 * Render a circle to display boolean status
 *
 * @param {Bool} status
 *
 * @return {Object}
 */
const renderStatusCircle = status => {
  return <StatusCircle status={status} />;
};

class PaymentMethodListView extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = { pages: 1 };
  }

  /**
   * @author Victor Heringer
   *
   * Renders breed edit link column
   *
   * @param {Object} data Represents an breed in the table
   *
   * @return {Object}
   */
  renderEditLink(data) {
    const url = "/forma-pagamento/" + data.original.id;

    return (
      <Link to={url}>
        <button className="btn-ex-selecionados btn-table-action">
          <i className="fa fa-pencil" />
        </button>
      </Link>
    );
  }

  render() {
    const { objects, filter, onTableDoubleClick, data, methods } = this.props;
    let config = {
      defaultPageSize: 5,
      defaultSorted: [{ id: "id", desc: true }],
      pageSize: 15,
      resizable: false,
      showPagination: true,
      noDataText: "Nenhum registro".toUpperCase(),
      loadingText: "Carregando",
      className: "-striped -highlight",
      previousText: "Anterior",
      nextText: "Próximo",
      pageText: "Página",
      ofText: "de",
      rowsText: "linhas",
      columns: [],
      showPageSizeOptions: false,
      pageSizeOptions: [5, 10, 15, 20, 25, 50, 100]
    };

    config.data = objects.value && objects.value.data ? objects.value.data : [];
    config.loading = objects.pending;
    config.onDoubleClickRow = onTableDoubleClick;
    config.columns = [
      {
        id: "id",
        Header: "Código",
        sortable: true,
        accessor: "id",
        className: "centeredText",
        width: 80
      },
      {
        id: "name",
        Header: "Nome",
        sortable: true,
        accessor: "name",
        className: "centeredText"
      }
    ];

    return (
      <React.Fragment>
        <PanelMaterial>
          <PanelMaterialTop title={"Forma de Pagamento"}>
            <ButtonSaveMaterial
              title="Nova Forma de Pagamento"
              type="highlight"
              position="top"
              right={true}
              onClick={event =>
                methods.handleClosePaymentMethodModal(event, true)
              }
            />
          </PanelMaterialTop>
          <PanelMaterialBody>
            <PaymentMethodModal data={data} methods={methods} />
            <LVTable
              filterable
              manual
              onFetchData={filter}
              pages={this.props.pages}
              data={config.data}
              ofText={config.ofText}
              columns={config.columns}
              loading={config.loading}
              pageSize={config.pageSize}
              sortable={config.sortable}
              nextText={config.nextText}
              resizable={config.resizable}
              className={config.className}
              defaultSorted={config.defaultSorted}
              showPagination={config.showPagination}
              defaultPageSize={config.defaultPageSize}
              showPageSizeOptions={config.showPageSizeOptions}
              onDoubleClickRow={config.onDoubleClickRow}
            />
          </PanelMaterialBody>
        </PanelMaterial>
      </React.Fragment>
    );
  }
}

export default PaymentMethodListView;
