import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import HemogramPanel from "./../HemogramPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import ProfileBiochemistryTable from "./../../old_components/ProfileBiochemistryTable";
import FlatPanelMaterial from "./../FlatPanelMaterial";
import HemocytozoaResearchPanel from "./../HemocytozoaResearchPanel";
import MicrofilariaeResearchPanel from "./../MicrofilariaeResearchPanel";
import { MultiCheckbox } from "../../components/result-group";

/**
 * External
 */
import { Row, Col } from "./../../components/grid";

const HemogramWithBiochemistryAndHemoparasitologicalView = ({
  title,
  data: {
    refs,
    total,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    requestExamStates,
    biochemistryTableData,
    hemocitozoaResearchCheckboxes,
    hemocytozoaResearchResult,
    checkdState,
    checkdStateMicrofilariaeResearch,
    microfilariaeResearchCheckboxes,
    microfilariaeResearchResult,
    reportData,
  },
  methods: {
    $field,
    fieldChange,
    onSubmit,
    calcMCHC,
    calcMCV,
    erythrogramDiagnosis,
    plateletsDiagnosis,
    calcAbsolute,
    resetLeukogram,
    onClickCounter,
    leukogramDiagnosis,
    toggleIntenseLeucopeny,
    finalGlobalLeukogram,
    handleKeyPress,
    handleFinishedPlaying,
    closeCounter,
    handleChange,
    handleBlur,
    toggleIonicCalciumEditable,
    cancelReport,
    stopReport,
    handleSendReportEmail,
    handleFocus,
    setRef,
    multiBoxChange,
    handleCheckedResultChange,
    microfilariaeResearchMultiBoxChange,
    microfilariaeResearchMandleCheckedResultChange,
  },
}) => (
  <ReportPanelMaterial
    title={title}
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    cancelReport={cancelReport}
    stopReport={stopReport}
    handleSendReportEmail={handleSendReportEmail}
    handleFocus={handleFocus}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <HemogramPanel
      fields={fields}
      refs={refs}
      disableAll={disableAll}
      $validation={$validation}
      total={total}
      $field={$field}
      fieldChange={fieldChange}
      calcMCV={calcMCV}
      calcMCHC={calcMCHC}
      calcAbsolute={calcAbsolute}
      resetLeukogram={resetLeukogram}
      onClickCounter={onClickCounter}
      toggleIntenseLeucopeny={toggleIntenseLeucopeny}
      finalGlobalLeukogram={finalGlobalLeukogram}
      erythrogramDiagnosis={erythrogramDiagnosis}
      leukogramDiagnosis={leukogramDiagnosis}
      plateletsDiagnosis={plateletsDiagnosis}
      handleKeyPress={handleKeyPress}
      handleFinishedPlaying={handleFinishedPlaying}
      closeCounter={closeCounter}
      setRef={setRef}
      reportData={reportData}
    />
    <Row>
      <Col md={12}>
        <HemocytozoaResearchPanel
          resultFieldName={"hemocytozoaResearchResult"}
          result={hemocytozoaResearchResult}
          resultHasValidation={$validation.hemocytozoaResearchResult.show}
          resultValidationReason={
            $validation.hemocytozoaResearchResult.error.reason
          }
          obsFieldName={"hemocytozoaResearchObs"}
          obs={fields.hemocytozoaResearchObs}
          obsHasValidation={$validation.hemocytozoaResearchObs.show}
          obsValidationReason={$validation.hemocytozoaResearchObs.error.reason}
          handleChange={handleChange}
          handleBlur={handleBlur}
          shouldDisable={disableAll}
          checkboxes={hemocitozoaResearchCheckboxes}
          multiCheckboxChange={multiBoxChange}
          checkdState={checkdState}
          handleResultChange={handleCheckedResultChange}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <MicrofilariaeResearchPanel
          resultFieldName={"microfilariaeResearchResult"}
          result={microfilariaeResearchResult}
          resultHasValidation={$validation.microfilariaeResearchResult.show}
          resultValidationReason={
            $validation.microfilariaeResearchResult.error.reason
          }
          obsFieldName={"microfilariaeResearchObs"}
          obs={fields.microfilariaeResearchObs}
          obsHasValidation={$validation.microfilariaeResearchObs.show}
          obsValidationReason={
            $validation.microfilariaeResearchObs.error.reason
          }
          handleChange={handleChange}
          handleBlur={handleBlur}
          shouldDisable={disableAll}
          handleResultChange={microfilariaeResearchMandleCheckedResultChange}
          checkboxes={microfilariaeResearchCheckboxes}
          multiCheckboxChange={microfilariaeResearchMultiBoxChange}
          checkdState={checkdStateMicrofilariaeResearch}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FlatPanelMaterial title="Bioquímica">
          <ProfileBiochemistryTable
            data={biochemistryTableData}
            isUpdate={isUpdate}
            handleChange={handleChange}
            handleBlur={handleBlur}
            toggleIonicCalciumEditable={toggleIonicCalciumEditable}
            shouldDisable={disableAll}
            requestExamStates={requestExamStates}
            biochemObs={fields.biochemObs}
          />
        </FlatPanelMaterial>
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default HemogramWithBiochemistryAndHemoparasitologicalView;
