import React from "react";
import __update from "immutability-helper";
import { OnSuccess, OnError } from "./../../types/index";
import { AnimalValidationSchema } from "./../../schemas/index";
import { useAnimal } from "./index";
import { useValidatedForm } from "../validated-form/index";
import { AnimalConversor } from "../../services/request-conversor/new-to-old";
import { useFetch, ROUTES } from "../use-fetch";

export const useValidatedAnimalForm = () => {
  const saveAnimal = useFetch();
  const animal = useAnimal();
  const {
    errors,
    touched,
    isSubmitting,
    setError,
    unsetError,
    setTouched,
    unsetTouched,
    handleBlur,
    reset,
    resetValidation,
    validate,
    validateField,
    onValidationError,
    setIsSubmitting,
  } = useValidatedForm(
    animal.data,
    animal.methods.reset,
    AnimalValidationSchema
  );

  const onSubmit = (onSuccess: OnSuccess, onError: OnError) => {
    const onValid = () => {
      let oldAnimal = AnimalConversor(animal.data);
      setIsSubmitting(true);
      saveAnimal.fetch(
        ROUTES.saveAnimal(oldAnimal.id),
        {
          method: oldAnimal.id === 0 ? "POST" : "PUT",
          body: JSON.stringify(oldAnimal),
        },
        onSuccess,
        onError
      );
    };
    validate(onValid, onValidationError);
  };

  const handleChange = (name: string, value: any) => {
    switch (name) {
      case "customer":
        animal.methods.customer.update(value);
        break;
      case "breed":
        animal.methods.breed.update(value);
        break;
      case "specie":
        animal.methods.specie.update(value);
        break;
      default:
        animal.methods.updateField(name, value);
    }
    unsetTouched(name);
  };

  return {
    data: animal.data,
    methods: animal.methods,
    errors,
    touched,
    isSubmitting,
    reset,
    resetValidation,
    setError,
    unsetError,
    setTouched,
    handleChange,
    handleBlur,
    validate,
    validateField,
    setIsSubmitting,
    submit: onSubmit,
  };
};
