import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import NumberInputWithReferencePanel from "./../NumberInputWithReferencePanel";
import TextAreaPanel from "./../TextAreaPanel";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Row, Col } from "./../../components/grid";

const UrinaryGgtReportFormView = ({
  data: {
    refs,
    isUpdate,
    fields,
    backLink,
    disableAll,
    $validation,
    submitData,
    reportHeaderData,
    shouldSendEmail
  },
  methods: {
    fieldChange,
    handleChange,
    handleBlur,
    onSubmit,
    $field,
    handleSendReportEmail,
    cancelReport,
    stopReport
  }
}) => (
  <ReportPanelMaterial
    title="GGT Urinária"
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitData.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <NumberInputWithReferencePanel
          title={"Resultado"}
          fieldName={"result"}
          value={fields.result}
          disableAll={disableAll}
          hasValidation={$validation.result.show}
          validationReason={$validation.result.error.reason}
          fieldChange={fieldChange}
          $field={$field}
          valueMin={refs.min}
          valueMax={refs.max}
          decimalScale={1}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <TextAreaPanel
          title={"Obs"}
          fieldTitle={""}
          name={"obs"}
          value={fields.obs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default UrinaryGgtReportFormView;
