import React from "react";
export const Loader = ({ children, loading }) => {
  const zIndexValue = loading ? 1 : 3;
  const displayValue = loading ? "block" : "none";
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 2,
          backgroundColor: "white",
          opacity: 0.85,
          display: displayValue,
        }}
      >
        <div
          style={{
            position: "relative",
            left: "45%",
            top: "50%",
            opacity: 1,
            fontSize: "14px",
          }}
        >
          CARREGANDO
        </div>
      </div>
      <div style={{ position: "relative", zIndex: zIndexValue }}>
        {children}
      </div>
    </div>
  );
};
