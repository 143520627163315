import React from 'react';
import PropTypes from 'prop-types';

/**
 * Assets
 */
import './index.css';

/**
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Panel for most usecases
 */
const PanelMaterial = ( { children, className, flat } ) => {

	return (
		<React.Fragment>
			<div className={ 
        "panel-material clearfix " 
        + className
        + (flat ? ' flat' : ' deepth' )
        }
      >
        { children }
			</div>
		</React.Fragment>
	);
}

const childrenPropTypes = ( props, propName, componentName ) => {

	/**
	 * @type {String}
	 */
	const acceptMessage = 'accepts only these three types of children: PanelMaterialTop, PanelMaterialBody, and PanelMaterialBottom.';

	/**
	 * @type {Number}
	 */
	const TWO_COMPONENTS = 2;

	/**
	 * @type {Number}
	 */
	const THREE_COMPONENTS = 3;

	let children = props[ propName ];
	children = children.length ? children : [ children ];

	let topIndex;
	let bodyIndex;
	let bottomIndex;

	const check = ( obj, component ) => {
		return obj.type && obj.type.name && obj.type.name === component;
	}

	const PanelMaterialTop = children.find( ( obj, idx ) => {
		if( check( obj, 'PanelMaterialTop' ) ) {
			topIndex = idx;
			return true;
		}
		return false
	} );

	const PanelMaterialBody = children.find( ( obj, idx ) => {
		if( check( obj, 'PanelMaterialBody' ) ) {
			bodyIndex = idx;
			return true;
		}
		return false
	} );

	const PanelMaterialBottom = children.find( ( obj, idx ) => {
		if( check( obj, 'PanelMaterialBottom' ) ) {
			bottomIndex = idx;
			return true;
		}
		return false
	} );

	// Checks wether PanelMaterialBody is one of the children
	if( undefined === PanelMaterialBody ) {
		return new Error(
			'`' + componentName + '` ' +
			'expects at least the PanelMaterialBody component'
		);
	}

	// Checks wether more than three children are being passed
	if( React.Children.count( children ) > THREE_COMPONENTS ) {
		return new Error(
			'`' + componentName + '` ' +
			'should not have more than 3 child components'
		);
	}

	// Checks wether exactly two children are being passed
	// and if the types are among the expected
	if( React.Children.count( children ) === TWO_COMPONENTS ) {
		if( PanelMaterialTop === undefined && PanelMaterialBottom === undefined ) {
			return new Error( '`' + componentName + '` ' + acceptMessage );
		}
	}

	// Checks wether three children are being passed
	// and if the types are among the expected
	if( React.Children.count( children ) === THREE_COMPONENTS ) {
		if( PanelMaterialTop === undefined || PanelMaterialBottom === undefined ) {
			return new Error( '`' + componentName + '` ' + acceptMessage );
		}
	}

	const bodyIsntFirstOrMiddle =
		PanelMaterialBody &&
		( bodyIndex !== 0 ) &&
		( bodyIndex !== 1 )
	;

	if( bodyIsntFirstOrMiddle ) {
		return new Error(
			'`' + componentName + '` ' +
			'expects PanelMaterialBody to be its first or middle child'
		);
	}

	const topIsntFirst = PanelMaterialTop && ( topIndex !== 0 );

	if( topIsntFirst ) {
		return new Error(
			'`' + componentName + '` ' +
			'expects PanelMaterialTop to be its first child'
		);
	}

	const bottomIsntLast = 
		PanelMaterialBottom &&
		( bottomIndex !== 1 ) &&
		( bottomIndex !== 2 )

	if( bottomIsntLast ) {
		return new Error(
			'`' + componentName + '` ' +
			'expects PanelMaterialBottom to be its last child'
		);
	}
}

PanelMaterial.propTypes = {
	title: PropTypes.string,
	children: childrenPropTypes,
	className: PropTypes.string
}

export default PanelMaterial;
