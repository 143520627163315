import React from "react";

import { SELECT_TEXT } from "../../../../../assets/js/Consts.js";
import * as LifeVet from "../../../../../components";

const CustomerSelect = ({
  fields,
  disableAll,
  disableCustomerSelect,
  customersPromise,
  onSelectCustomer,
  $field,
  $validation,
  setRef,
}) => (
  <LifeVet.FormGroup
    errorMessage={
      $validation.customerId.show && $validation.customerId.error.reason
    }
  >
    <LifeVet.InputLabel>Cliente *</LifeVet.InputLabel>
    <LifeVet.Select
      id="customer_select"
      name="customerId"
      options={
        customersPromise && customersPromise.fulfilled
          ? customersPromise.value
          : []
      }
      value={fields.customerId}
      searchable={true}
      clearable={true}
      disabled={
        (customersPromise && customersPromise.pending) ||
        disableAll ||
        disableCustomerSelect
      }
      theValueIsAnId={true}
      placeholder={
        customersPromise && customersPromise.pending
          ? SELECT_TEXT.LOADING
          : SELECT_TEXT.ACTIVE
      }
      {...$field("customerId", (value) => {
        onSelectCustomer(value && value.id);
      })}
      setRef={setRef}
    />
  </LifeVet.FormGroup>
);

export default CustomerSelect;
