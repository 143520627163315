import React, {
  FormEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import * as LifeVet from "../../../../../components";
import { Request } from "../../../../../types/index";

export const ClinicalReportField = ({
  data,
  errors,
  touched,
  handleChange,
  handleBlur,
  disable,
  isReadOnly,
  vet,
}: {
  data: string;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  disable: boolean;
  isReadOnly: boolean;
  vet: any;
}) => {
  const ref: any = useRef(null);

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [vet.id]);

  return (
    <LifeVet.FormGroup noError>
      <LifeVet.InputLabel hasError={touched && errors}>
        Relato Clínico
      </LifeVet.InputLabel>
      <LifeVet.Input
        disabled={disable || isReadOnly}
        type="text"
        name={""}
        isViewOnly={false}
        value={data}
        onChange={(e: React.ChangeEvent<any>) =>
          handleChange("clinicalReport", e.target.value)
        }
        onBlur={() => handleBlur("clinicalReport")}
        errorMessage={touched && errors}
        innerRef={ref}
      />
    </LifeVet.FormGroup>
  );
};
