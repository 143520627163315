import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import LVTable from "./../../old_components/LVTable";
import NumberInputMaterial2 from "./../../containers/NumberInputMaterial2";
import TextAreaPanel2 from "./../../containers/TextAreaPanel2";

/**
 * External
 */
import NumberDisplay from "./../../containers/NumberDisplay";
import { getDecimalPrecisionByExamId } from "./../../consts";
import { Col } from "./../../components/grid";

/**
 * Assets
 */
import styles from "./index.module.css";
import InputValidationMsg from "../../containers/InputValidationMsg";
import InputExam from "../../components/input-exam/index.js";

/**
 * @author Alessandro Bastos Grandini
 *
 * Renders the report result field
 *
 * @param {Object} data Number input material component
 *
 * @return {Object}
 *
 */
const renderRefs = props => {
  const data = props.original;
  const { refMin, refMax, refUnit } = data;
  
  return (
    <Col md={12} className="mt-10">
      <p className="text-center">
        <NumberDisplay value={refMin} decimalScale={1} suffix={" - "} />
        <NumberDisplay value={refMax} decimalScale={1} suffix={` ${refUnit}`} />
      </p>
    </Col>
  );
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for react table
 */
class ProfileEndocrinologyTable extends Component {
  constructor(props) {
    super(props);

    this.renderResultField = this.renderResultField.bind(this);
  }

  renderResultField(props) {
    const row = props.original;
    const fieldName = row.fieldName;
    const value = row.result;
    const hasValidation = row.hasValidation;
    const validationReason = row.validationReason;

    return (
      <div className="text-left">
        {/* <NumberInputMaterial2
          name={fieldName}
          hasValidationRecoil={true}
          decimalScale={getDecimalPrecisionByExamId(props.original.examId)}
          block={true}
          inputGroup={true}
          allowNegative={false}
          value={value}
          disabled={this.props.shouldDisable}
          handleChange={this.props.handleChange}
          handleBlur={this.props.handleBlur}
          hasValidation={hasValidation}
          validationReason={validationReason}
        /> */}

{/* <Col md={md}> */}
        {/* {title && <label>{title}</label>} */}
        <InputExam
          // title={title}
          name={fieldName}
          // md={md}
          block={true}
          decimalScale={2}
          handleChange={this.props.handleChange}
          value={value}
          disabled={this.props.shouldDisable}
          handleBlur={this.props.handleBlur}
          //{...validationHandlers}
        />
        <InputValidationMsg
          visible={hasValidation}
          message={validationReason}
        />
      {/* </Col> */}


      </div>
    );
  }

  render() {
    /**
     * @type {array}
     */
    const columns = [
      {
        Header: "Nome do Exame",
        accessor: "name",
        className: styles.leftText,
        maxWidth: 480
      },
      {
        Header: props => <span className="float-left">Resultado</span>,
        className: styles.centeredText,
        sortable: false,
        width: 120,
        Cell: this.renderResultField
      },
      {
        Header: "Valores Normais",
        sortable: false,
        className: styles.centeredText,
        Cell: renderRefs,
        width: 320
      }
    ];

    return (
      <React.Fragment>
        <div className="text-center">
          <h5 className="table-title">{this.props.title.toUpperCase()}</h5>
        </div>
        <div className="fit-table">
          <LVTable
            data={this.props.data}
            columns={columns}
            defaultSorted={[{ id: "name", desc: false }]}
            pageSize={this.props.data.length}
            showPagination={false}
            shouldPaintRowOnClick={false}
          />
          {this.props.chemilObs !== undefined ? (
            <TextAreaPanel2
              title="Obs"
              name={"chemilObs"}
              value={this.props.chemilObs}
              disabled={this.props.shouldDisable}
              handleChange={this.props.handleChange}
              handleBlur={this.props.handleBlur}
            />
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

ProfileEndocrinologyTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array
};

ProfileEndocrinologyTable.defaultProps = {
  title: "",
  data: []
};

export default ProfileEndocrinologyTable;
