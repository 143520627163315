import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal
 */
import NumberDisplay from './../NumberDisplay';
import { Col } from './../../components/grid';

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const ReferenceValueWithTitle = ( {
  title,
  valueMin,
  valueMax,
  decimalScale,
  unit
} ) =>
  <React.Fragment>
    <Col md={6} className='text-right'>
      <p className='cleanItem'>{ title }</p>
    </Col>
    <Col md={ 6 } className="">
      <p className="centeredLeft">
        <NumberDisplay
          value={ valueMin }
          decimalScale={ decimalScale }
          suffix={ ' - ' } />
        <NumberDisplay
          value={ valueMax }
          decimalScale={ decimalScale }
          suffix={ ` ${ unit }` } />
      </p>
    </Col>
  </React.Fragment>

ReferenceValueWithTitle.propTypes = propTypes;

export default ReferenceValueWithTitle;