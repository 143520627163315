import React, { useReducer } from "react";

import { Address } from "./../../types/index";
import { reducer, ACTIONS } from "./reducer";

const defaultAddresses: Array<Address> = [];

export const useAddresses = () => {
  const [addresses, dispatch] = useReducer(reducer, defaultAddresses);

  const data = addresses;
  const methods = {
    add: (address: Array<Address>) =>
      dispatch({ type: ACTIONS.ADD, payload: address }),
    update: (address: Address) =>
      dispatch({ type: ACTIONS.UPDATE, payload: address }),
    remove: (id: number) => dispatch({ type: ACTIONS.REMOVE, payload: id }),
    get: (id: number) => addresses.filter((curr) => curr.id === id).pop(),
    reset: () => dispatch({ type: ACTIONS.RESET }),
  };
  
  return {
    data,
    methods,
  };
};
