import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * External
 */
import { Panel, Row } from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleUp from '@fortawesome/fontawesome-free-solid/faAngleUp';
import update from 'immutability-helper';

/**
* Internal
*/
import PanelMaterial from './../PanelMaterial';
import PanelMaterialBody from '../PanelMaterialBody';
import PanelMaterialBottom from '../PanelMaterialBottom';
import ButtonSaveMaterial from '../ButtonSaveMaterial';
import InputMaterial from './../InputMaterial';
import SelectMaterial from './../SelectMaterial';
import TagsInputMaterial from './../TagsInputMaterial';

/**
 * Assets
 */
import './index.css';

const propTypes = {
  data: PropTypes.shape( {
    animal: PropTypes.string.isRequired,
    vets: PropTypes.shape( {
      options: PropTypes.array.isRequired,
      placeholder: PropTypes.string.isRequired
    } ),
    requestExamStates: PropTypes.shape( {
      options: PropTypes.array.isRequired,
      placeholder: PropTypes.string.isRequired
    } ),
    owner: PropTypes.string.isRequired,
    species: PropTypes.string.isRequired,
    breed: PropTypes.string.isRequired,
    age: PropTypes.string.isRequired,
    sex: PropTypes.string.isRequired,
    requestSampleNames: PropTypes.array.isRequired,
  } ).isRequired
};

/**
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a TableExamReportHeaderPanel form
 * 
 */
class TableExamReportHeaderPanel extends Component {

  constructor( props ) {

    super( props );

    this.state = {
      isOpen: false
    }

    this.toggleOpen = this.toggleOpen.bind( this );

  }

 

  /**
   * @author Alessandro Bastos Grandini
   *
   * Toggles header open/closed state
   *
   */
  toggleOpen() {
    this.setState( ( state ) => update(
      state, { isOpen: { $set: !this.state.isOpen } }
    ) );
  }

  onToggle() {

  }

  render() {

    const iconClass = this.state.isOpen
      ? "sub-menu-caret-up"
      : "sub-menu-caret-down"
      ;

    const {
      animal,
      vets,
      owner,
      species,
      breed,
      age,
      sex,
      requestSampleNames
    } = this.props.data;

    const {
      fieldChange,
      $field,
      fields,
      $validation,
      shouldDisable
    } = this.props;

    return (
      <div className="reportHeader">
        <PanelMaterial className='pb-10 mt-0 mb-15' flat={ true }>
          <PanelMaterialBody>
            <Row>
              <InputMaterial
                title="Animal"
                name="animal"
                id="animal"
                md={ 4 }
                value={ animal }
                disabled />
              <InputMaterial
                title="Status"
                name="status"
                id="status"
                md={ 4 }
                value={ "VIDE TABELA" }
                disabled />
              <SelectMaterial
                title="Assinatura"
                md="4"
                id="vetSignerId"
                name="vetSignerId"
                placeholder={ 'Selecione' }
                value={ fields.vetSignerId }
                disabled={ shouldDisable }
                searchable={ true }
                options={ vets.options }

                { ...$field( 'vetSignerId', ( value ) => {
                  fieldChange( 'vetSignerId', value );
                } ) }

                hasValidation={ $validation.vetSignerId.show }
                validationReason={ $validation.vetSignerId.error.reason }
                hasValidationRecoil={ true } />
              </Row>
              <Row className="mb-20">
                <TagsInputMaterial
                  title={ 'Amostras' }
                  tags={ requestSampleNames }
                  md={ 12 }
                  disabled={ true } />
              </Row>
            <Panel
              id="report-header-panel"
              expanded={ this.state.isOpen } 
              onToggle={ this.onToggle } >
              <Panel.Collapse>
                <Panel.Body>
                  <Row>
                    <InputMaterial
                      title="Proprietário"
                      name="owner"
                      id="owner"
                      md={ 3 }
                      value={ owner }
                      disabled />
                    <InputMaterial
                      title="Espécie"
                      name="specie"
                      id="specie"
                      md={ 3 }
                      value={ species }
                      disabled />
                    <InputMaterial
                      title="Raça"
                      name="breed"
                      id="breed"
                      md={ 3 }
                      value={ breed }
                      disabled />
                    <InputMaterial
                      title="Idade"
                      name="age"
                      id="age"
                      md={ 2 }
                      value={ age }
                      disabled />
                    <InputMaterial
                      title="Sexo"
                      name="sex"
                      id="sex"
                      md={ 1 }
                      value={ sex }
                      disabled />
                  </Row>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </PanelMaterialBody>
          <PanelMaterialBottom>
            <ButtonSaveMaterial
              onClick={ this.toggleOpen }
              title={
                <FontAwesomeIcon
                  size="lg"
                  icon={ faAngleUp }
                  className={ iconClass } />
              }
              type="highlight"
              position="top"
              right={ true }
              id="btn_go_exam_request"
              className='circular-btn btn-center' />
          </PanelMaterialBottom>
        </PanelMaterial>
      </div>
    );

  };

}

TableExamReportHeaderPanel.propTypes = propTypes;

export default TableExamReportHeaderPanel;