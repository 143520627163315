import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * External
 */
import { Col } from 'react-bootstrap';
import { RadioGroup, Radio } from 'react-radio-group';

/**
 * Internal
 */
import InputValidationMsg from './../InputValidationMsg';

const defaultProps = {};
const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for dual radio with material design styling
 */
const TripleRadioMaterial = ( {
  title,
  name,
  selectedValue,
  onChange,
  disabled,
  firstLabel,
  firstValue,
  secondLabel,
  secondValue,
  thirdLabel,
  thirdValue,
  hasValidation,
  validationType,
  hasValidationRecoil,
  validationReason,
  required
} ) => {

  /**
   * Validation Component
   * @type {Object}
   */
  const validation = <InputValidationMsg
    type={ validationType }
    visible={ hasValidation }
    message={ validationReason }
    top={ hasValidationRecoil ? '-18px' : '' }
  />;

  return (
    <div>
      <div className="form-group mb-0">
        <label className="mb-0">{ title }</label>
        { required && <label> * </label> }
        <RadioGroup
          name={ name }
          onChange={ onChange }
          selectedValue={ selectedValue } >
          <Col xs={ 4 } className="pd-smaller-left">
            <Radio disabled={ disabled } value={ firstValue } /> 
            <span className="radio-label">{ firstLabel }</span>
          </Col>
          <Col xs={ 4 } className="pd-smaller-left">
            <Radio disabled={ disabled } value={ secondValue } /> 
            <span className="radio-label">{ secondLabel }</span>
          </Col>
          <Col xs={ 4 } className="pd-smaller-left">
            <Radio disabled={ disabled } value={ thirdValue } /> 
            <span className="radio-label">{ thirdLabel }</span>
          </Col>
        </RadioGroup>
      </div>
      { validation }
    </div>
    );

}

TripleRadioMaterial.defaultProps = defaultProps;
TripleRadioMaterial.propTypes = propTypes;

export default TripleRadioMaterial;