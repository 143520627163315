import React from "react";

import { rose } from "./../colors";

/**
 * External
 */
import ReactSelect from "react-select";
import styled from "styled-components";

/**
 * @author Anderson Souza
 */

const StyledErrorMessage = styled.div`
  color: ${rose};
  margin-top: 5px;
  font-family: arial;
  font-size: 12px;
`;

const getOptionWithId = (id, options) => {
  if (!options || options.length == 0) return null;
  let value = options
    .filter((current) => {
      return current.id == id;
    })
    .pop();
  return value ? value : null;
};
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "30px",
    height: state.isMulti && state.hasValue ? "auto" : "30px",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    minHeight: "28px",
    height: state.isMulti && state.hasValue ? "auto" : "28px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    minHeight: "28px",
    height: state.isMulti && state.hasValue ? "auto" : "28px",
  }),
};

const Select = ({
  id = 0,
  name = "",
  value,
  options = [],
  searchable = false,
  disabled = false,
  placeholder = "",
  multi = false,
  closeOnSelect = true,
  clearable = false,
  theValueIsAnId = false,
  onChange,
  onBlur = () => {},
  onKeyDown,
  onClick = (e) => {},
  tabIndex = 0,
  errorMessage = "",
  isLoading = false,
  tabSelectsValue = true,
  loadingMessage = () => "Carregando...",
  setRef = (a) => {},
}) => {
  return (
    <>
      <ReactSelect
        instanceId={id}
        name={name}
        value={theValueIsAnId ? getOptionWithId(value, options) : value}
        options={options}
        isSearchable={true === searchable}
        isDisabled={true === disabled}
        placeholder={placeholder}
        isMulti={multi === true}
        closeMenuOnSelect={true === closeOnSelect}
        isClearable={true === clearable}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onClick={onClick}
        tabIndex={tabIndex}
        ref={(ref) => setRef(ref, name)}
        noOptionsMessage={() => "Nenhum resultado."}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        hideSelectedOptions={true}
        isLoading={isLoading}
        loadingMessage={loadingMessage}
        tabSelectsValue={tabSelectsValue}
        styles={customStyles}
      />
      {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </>
  );
};

export default Select;
