export const PENDING = 1;
export const AWAITING = 2;
export const IN_PROGRESS = 3;
export const ATTENDED_PARTIALLY = 4;
export const STUCK_BY_PROBLEMS = 5;
export const CANCELLED = 6;
export const ATTENDED = 7;
export const BILLED = 8;

export const values = [
  { id: 1, name: "Pendente de Envio" },
  { id: 2, name: "Aguardando Recebimento" },
  { id: 3, name: "Em Andamento" },
  { id: 4, name: "Atendida Parcialmente" },
  { id: 5, name: "Parada Por Problemas" },
  { id: 3, name: "Cancelada" },
  { id: 4, name: "Atendida" },
  { id: 5, name: "Faturada" }
];

export const toString = id => {
  switch (parseInt(id)) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
      return values[id - 1].name;
      break;
    default:
      return "Status de solicitação inválido.";
  }
};
