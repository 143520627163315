import connect from "../../api-connector";

const connectWithEndpoint = connect(props => ({
  states: "/states",
  regions: "/customers/lab/regions",
  pricingTables: "/pricing-tables",
  companyWithCNPJ: (id, cnpj) => ({
    companyCNPJ: {
      url: `/company/validate?id=${id}&cnpj=${cnpj}`,
      method: "GET",
      force: true
    }
  }),
  citiesForState: (stateId, then) => ({
    cities: {
      url: `/state/${stateId}/cities`,
      method: "GET",
      then: then,
      force: true
    }
  }),
  add: (obj, then, error) => ({
    customerResponse: {
      url: `/customers`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  }),
  update: (id, obj, then, error) => ({
    customerResponse: {
      url: `/customers/${id}`,
      method: "PUT",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  }),
  deleteUser: (id, then, error) => ({
    deleteUser: {
      url: `/person/${id}`,
      method: "DELETE",
      then: then,
      catch: error,
      force: true
    }
  }),
  deleteEmail: (id, then, error) => ({
    deleteEmail: {
      url: `/person/email/${id}`,
      method: "DELETE",
      then: then,
      catch: error,
      force: true
    }
  }),
  deletePhone: (id, then, error) => ({
    deletePhone: {
      url: `/person/phone/${id}`,
      method: "DELETE",
      then: then,
      catch: error,
      force: true
    }
  }),
  withId: (id, then) => ({
    obj: {
      url: `/customers/${id}`,
      then: then
    }
  })
}));

export default connectWithEndpoint;
