import React, { Component } from "react";

/** External */
import classNames from "classnames";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

/** Internal */
import LVSidebarSimpleLink from "./LVSidebarSimpleLink";
import LVSidebarSubmenuLink from "./LVSidebarSubmenuLink";
import { logout } from "./../../redux/actions/";
import update from "immutability-helper";
import { ACCESS_PROFILES } from "./../../assets/js/Consts";

/** Assets */
import "./index.css";

/**
 * Define the sidebar and submenus links
 * @type {Array}
 */
const sidebarLinks = [
  {
    key: 1,
    open: false,
    name: "Principal",
    to: "/",
    subItens: [],
    allowed: [
      ACCESS_PROFILES.UNIT_ADMINISTRATOR,
      ACCESS_PROFILES.UNIT_USER,
      ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
      ACCESS_PROFILES.CLINIC_USER,
    ],
  },
  {
    key: 2,
    open: false,
    name: "Cadastro Básico",
    stateName: "cadastroBasico",
    allowed: [
      ACCESS_PROFILES.CLINIC_USER,
      ACCESS_PROFILES.UNIT_USER,
      ACCESS_PROFILES.UNIT_ADMINISTRATOR,
      ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
      ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
    ],
    subItens: {
      data: [
        {
          key: 1,
          to: "/animais",
          title: "Animal",
          allowed: [
            ACCESS_PROFILES.CLINIC_USER,
            ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_USER,
          ],
        },
        {
          key: 2,
          to: "/especies",
          title: "Espécie",
          allowed: [
            ACCESS_PROFILES.CLINIC_USER,
            ACCESS_PROFILES.UNIT_USER,
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
            ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
          ],
        },
        {
          key: 3,
          to: "/racas",
          title: "Raça",
          allowed: [
            ACCESS_PROFILES.CLINIC_USER,
            ACCESS_PROFILES.UNIT_USER,
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
            ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
          ],
        },
        {
          key: 4,
          to: "/antibioticos",
          title: "Antibiótico",
          allowed: [
            ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
          ],
        },
        {
          key: 5,
          to: "/clientes",
          title: "Cliente",
          allowed: [
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.CLINIC_USER,
            ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_USER,
          ],
        },
        {
          key: 6,
          to: "/especies-valores",
          title: "Espécie e Valores Normais",
          allowed: [
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
          ],
        },
        {
          key: 7,
          to: "/unidades",
          title: "Unidade",
          allowed: [
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_USER,
            ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
          ],
        },
        {
          key: 8,
          to: "/exame-terceirizado",
          title: "Exame Terceirizado",
          allowed: [
            ACCESS_PROFILES.UNIT_USER,
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
          ],
        },
        {
          key: 9,
          to: "/exame-amostra",
          title: "Exame x Material de Amostra",
          allowed: [
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
          ],
        },
      ],
    },
  },
  {
    key: 5,
    open: false,
    name: "Financeiro",
    allowed: [
      ACCESS_PROFILES.UNIT_ADMINISTRATOR,
      ACCESS_PROFILES.CLINIC_USER,
      ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
      ACCESS_PROFILES.UNIT_USER
    ],
    subItens: {
      data: [
        {
          key: 1,
          to: "/financeiro/faturamento",
          title: "Faturamento",
          allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
        },
        {
          key: 2,
          to: "/financeiro/consulta-fatura",
          title: "Consulta de Fatura",
          allowed: [
            ACCESS_PROFILES.GENERAL_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_USER,
            ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
            ACCESS_PROFILES.CLINIC_USER,
          ],
        },
        {
          key: 3,
          to: "/tabela-preco",
          title: "Tabela de Preço",
          allowed: [
            ACCESS_PROFILES.UNIT_ADMINISTRATOR,
            ACCESS_PROFILES.CLINIC_USER,
            ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
            ACCESS_PROFILES.UNIT_USER
          ]
        },
        {
          key: 4,
          to: "/forma-pagamento",
          title: "Forma de Pagamento",
          allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
        },
      ],
    },
  },
  {
    key: 7,
    open: false,
    name: "Relatórios",
    allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
    subItens: {
      data: [
        {
          key: 1,
          to: "/relatorios-exames-finalizados",
          title: "Exames Finalizados (Consolidados)",
          allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
        },
        {
          key: 2,
          to: "/relatorio-amostras-utilizadas",
          title: "Materiais de Amostra Utilizados",
          allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
        },
        {
          key: 3,
          title: "Relatório de Emails Enviados",
          to: "/relatorios-emails-enviados",
          allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
        },
        {
          key: 4,
          title: "Exames Terceirizados (Diário)",
          to: "/relatorios-exames-terceirizados",
          allowed: [ACCESS_PROFILES.UNIT_ADMINISTRATOR],
        }
      ],
    },
  },
  {
    key: 10,
    open: false,
    name: "Consulta de Laudos Antigos",
    to: "/old-system",
    subItens: [],
    allowed: [
      ACCESS_PROFILES.UNIT_ADMINISTRATOR,
      ACCESS_PROFILES.CLINIC_USER,
      ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
      ACCESS_PROFILES.UNIT_USER,
    ],
  },
  {
    key: 9,
    open: false,
    name: "DashBoard",
    to: "/dashboard",
    subItens: [],
    allowed: [ACCESS_PROFILES.GENERAL_ADMINISTRATOR],
  },
];

class SideBar extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.searchMenu = this.searchMenu.bind(this);
    this.links = sidebarLinks;
    this.state = { sidebarLinks: sidebarLinks };
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  logout() {
    this.props.dispatch(logout());
  }

  /**
   * @author Victor Heringer
   * @author Alessandro Bastos Grandini
   *
   * Opens or closes submenu
   *
   * @param  {Object} event
   */
  toggleOpen(key) {
    for (var i = 0; i < this.state.sidebarLinks.length; i++) {
      if (this.state.sidebarLinks[i].key === key) {
        let toUpdate = {};
        toUpdate[i] = { open: { $set: !this.state.sidebarLinks[i].open } };
        this.setState((state) => update(state, { sidebarLinks: toUpdate }));
      }
    }
  }

  searchMenu(event) {
    let filterFunction = function filterFunction(searchValue) {
      searchValue = searchValue.trim();

      const allLinks = cloneDeep(sidebarLinks);
      const displayLinks = [];

      for (let i = 0; i < allLinks.length; i++) {
        let displaySubLinks = [];
        let subList = allLinks[i].subItens.data;
        let subListAsJson = JSON.stringify(allLinks[i].subItens).trim();

        let listTitle = allLinks[i].name.toLowerCase();
        let subListTitle = subListAsJson.toLowerCase();

        let hasInName = listTitle.indexOf(searchValue) >= 0;
        let hasInSubList = subListTitle.indexOf(searchValue) >= 0;

        if (hasInSubList) {
          for (let i = 0; i < subList.length; i++) {
            let subListTitle = subList[i].title.toLowerCase();

            if (subListTitle.indexOf(searchValue) >= 0) {
              displaySubLinks.push(subList[i]);
            }
          }
        }

        if (displaySubLinks.length > 0) {
          allLinks[i].subItens.data = displaySubLinks;
          if (!allLinks[i].open) {
            allLinks[i].open = true;
          }
        }

        if (hasInName || hasInSubList) {
          displayLinks.push(allLinks[i]);
        }
      }

      return displayLinks;
    }.bind(this);

    const searchValue = event.target.value;
    let data = null;

    if (!searchValue.trim()) {
      data = sidebarLinks;
    } else {
      data = filterFunction(event.target.value);
    }

    this.setState((state) => update(state, { sidebarLinks: { $set: data } }));
  }

  render() {
    const dynamicListItems = this.state.sidebarLinks.map((link) =>
      link.subItens.data && link.subItens.data.length > 0 ? (
        <LVSidebarSubmenuLink
          id={link.id}
          refKey={link.key}
          key={link.key}
          open={link.open}
          title={link.name}
          submenus={link.subItens.data}
          toggleList={this.toggleOpen}
          allowed={link.allowed}
        />
      ) : (
        <LVSidebarSimpleLink
          id={link.id}
          key={link.key}
          title={link.name}
          strict={link.strict}
          to={link.to}
          allowed={link.allowed}
        />
      )
    );

    const { show } = this.props;

    const sidebarClass = classNames(
      "col-lg-2",
      "side",
      { showSidebar: show },
      { hideSidebar: !show }
    );

    return (
      <div ref="sidebar" className={sidebarClass}>
        <div className="sidebarWrapper">
          <div className="sidebarMenu">
            <div className="form-group">
              <input
                type="text"
                className="form-control form-control-material"
                placeholder="BUSCAR NO MENU"
                onChange={this.searchMenu}
              />
            </div>
            <ul className="nav nav-pills nav-stacked nav-pills-material">
              {dynamicListItems}
              <li>
                <a onClick={this.logout}> Sair </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(SideBar);
