export const erythogramState = {
    erythrocytes: "",
    corpuscularVolume: "",
    hemoglobiometry: "",
    vmc: "",
    chmc: "",
    erythrogramDiagnosis: "" ,
    erythrogramObs: "" ,
  };
  
  
  
  export const globalLeukogramState = {
    // globalLeukometry: "",
    // metarrubrycites: "",
    // isIntenseLeucopeny: false,
    // finalGlobalLeukogram: "",  
  };
  
  export const leukogramState = {
    relativeBasophils: "",
    relativeEosinophils: "",
    relativeMyelocytes: "",
    relativeMetamyelocytes: "",
    relativeBands: "",
    relativeSegs: "",
    relativeLymphocytes: "",
    relativeMonocytes: "",
    relativeHeterophils: "",
    leukogramTotal: "",
    globalLeukometry: "",
    metarrubrycites: "",
    isIntenseLeucopeny: false,
    finalGlobalLeukogram: "",  
    leukogramDiagnosis : "",
    leukogramObs: "" ,
  };
  
  export const plateletsState = {
    platelets: "",
    plateletsObs: "" ,
    plateletsDiagnosis: ""
  };
  
