import React from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialBody from "../PanelMaterialBody";
import PanelMaterialTop from "../PanelMaterialTop";
import PanelMaterialBottom from "./../PanelMaterialBottom";
import ButtonBackMaterial from "./../ButtonBackMaterial";
import ButtonPrintMaterial from "./../ButtonPrintMaterial";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import SubmitButtonMaterial from "../SubmitButtonMaterial";
import { FocusManagerTarget } from "./../../services";

/**
 * External
 */
import { Link } from "react-router-dom";

const propTypes = {
  title: PropTypes.string.isRequired,
  backLink: PropTypes.string.isRequired,
  shouldDisable: PropTypes.bool,
  isUpdate: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  shouldDisable: false,
  isRequesting: false,
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a ReportPanelMaterial
 *
 */
const ReportPanelMaterial = ({
  title,
  backLink,
  shouldDisable,
  isUpdate,
  isRequesting,
  handleSubmit,
  children,
  handleSendReportEmail,
  cancelReport,
  stopReport,
  handleFocus,
}) => (
  <FocusManagerTarget handler={handleFocus}>
    <PanelMaterial>
      <PanelMaterialTop title={title}>
        <Link to={backLink}>
          <SubmitButtonMaterial
            isUpdate={isUpdate}
            type="default"
            title="Voltar"
            position="bottom"
            disabled={shouldDisable}
          />
        </Link>
        <SubmitButtonMaterial
          // isRequesting={isRequesting}
          // shouldDisable={shouldDisable}
          handleSubmit={() => handleSubmit(false, true)}
          // disabled={shouldDisable}
          title="Salvar parcial"
        />
        {isUpdate && (
          <SubmitButtonMaterial
            isRequesting={isRequesting}
            shouldDisable={shouldDisable}
            isUpdate={isUpdate}
            handleSubmit={stopReport}
            disabled={shouldDisable}
            title="Parar por problema"
            type="default"
          />
        )}

        {isUpdate && (
          <SubmitButtonMaterial
            isRequesting={isRequesting}
            shouldDisable={shouldDisable}
            isUpdate={isUpdate}
            handleSubmit={cancelReport}
            disabled={shouldDisable}
            title="Cancelar"
            type="danger"
          />
        )}
        <SubmitButtonMaterial
          isRequesting={isRequesting}
          shouldDisable={shouldDisable}
          isUpdate={isUpdate}
          handleSubmit={() => handleSubmit(false, false, true)}
          disabled={shouldDisable}
          title="Atender e não liberar"
        />
      </PanelMaterialTop>
      <PanelMaterialBody>{children}</PanelMaterialBody>
      <PanelMaterialBottom>
        <Link to={backLink}>
          <ButtonBackMaterial
            type="default"
            title="Voltar"
            position="bottom"
            disabled={shouldDisable}
          />
        </Link>

        <SubmitButtonMaterial
          isRequesting={isRequesting}
          shouldDisable={shouldDisable}
          isUpdate={isUpdate}
          handleSubmit={() => handleSubmit(true)}
          disabled={shouldDisable}
          title="Atender e Liberar"
        />

        <SubmitButtonMaterial
          isRequesting={isRequesting}
          shouldDisable={shouldDisable}
          isUpdate={isUpdate}
          handleSubmit={() => handleSubmit(false, true)}
          disabled={shouldDisable}
          title="Salvar parcial"
        />

        <SubmitButtonMaterial
          isRequesting={isRequesting}
          shouldDisable={shouldDisable}
          isUpdate={isUpdate}
          handleSubmit={() => handleSubmit(false)}
          disabled={shouldDisable}
          title="Salvar"
        />

        {isUpdate && (
          <ButtonDefaultMaterial
            type="default"
            title="Email"
            className="pull-right ml-20"
            right={true}
            onClick={handleSendReportEmail}
            disabled={shouldDisable}
          />
        )}
        {isUpdate && (
          <ButtonPrintMaterial
            type="default"
            title="Imprimir Laudo"
            right={true}
            disabled={shouldDisable}
          />
        )}
      </PanelMaterialBottom>
    </PanelMaterial>
  </FocusManagerTarget>
);

ReportPanelMaterial.propTypes = propTypes;
ReportPanelMaterial.defaultProps = defaultProps;

export default ReportPanelMaterial;
