import React from 'react';
import PropTypes from 'prop-types';

/**
* Internal
*/
import PanelMaterial from './../PanelMaterial';
import PanelMaterialTop from './../PanelMaterialTop';
import PanelMaterialBody from './../PanelMaterialBody';
import HemocytozoaResearchContent from './../HemocytozoaResearchContent';
import ButtonDefaultMaterial from './../ButtonDefaultMaterial';

/**
 * Assets
 */

 const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a HemocytozoaSerialResearchPanel form
 * 
 */
const HemocytozoaSerialResearchPanel = ( {
  title,
  resultFieldName,
  result,
  resultHasValidation,
  resultValidationReason,
  obsFieldName,
  obs,
  obsHasValidation,
  obsValidationReason,
  handleChange,
  handleBlur,
  handleRemove,
  shouldDisable,
  isThereMoreThanOne
} ) =>
  <PanelMaterial flat={ true }>
    <PanelMaterialTop title={ title ? title : "Pesquisa de Hemocitozoários" }>
      { isThereMoreThanOne && <ButtonDefaultMaterial
        type="danger"
        title="Remover"
        position="top"
        style={ { backgroundColor: '#FF3951', color: '#FFF' } } 
        onClick={ handleRemove }
        disabled={ shouldDisable } /> }
    </PanelMaterialTop>
    <PanelMaterialBody>
      <HemocytozoaResearchContent
        resultFieldName={ resultFieldName }
        result={ result }
        resultHasValidation={ resultHasValidation }
        resultValidationReason={ resultValidationReason }
        obsFieldName={ obsFieldName }
        obs={ obs }
        obsHasValidation={ obsHasValidation }
        obsValidationReason={ obsValidationReason }
        handleChange={ handleChange }
        handleBlur={ handleBlur }
        shouldDisable={ shouldDisable } />
    </PanelMaterialBody>
  </PanelMaterial>

HemocytozoaSerialResearchPanel.propTypes = propTypes;

export default HemocytozoaSerialResearchPanel;