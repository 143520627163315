/**
 * Internal
 */

import { FIVE_SECONDS } from "./../../consts/time-lengths";

/*
  THE Consts.js FILE MUST BE ELIMINATED!
  ALL CONSTS SHALL BE PLACED IN consts/ DIRECTORY.
  */
import {
  SUCCESS_EMAIL_MESSAGE,
  WARNING_EMAIL_MESSAGE,
  REQUEST_EXAM_STATE_ID_BY_NAME,
} from "../../assets/js/Consts.js";

/**
 * External
 */
import update from "immutability-helper";

/**
 * @author Alessandro Bastos Grandini
 *
 * Extracts id from url
 *
 * @return {number}
 *
 */
export function getId() {
  return this.props.match.params.id ? this.props.match.params.id : 0;
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Checks wether it's in edit mode
 *
 * @return {boolean}
 *
 */
export function isUpdate() {
  return this.getId() > 0;
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Changes field state
 *
 * @param {string} field The name of the state that represents the field
 * @param {mixed} value The new value
 */
export function fieldChange(field, value, callback) {
  this.setState(
    (state) => update(state, { fields: { [field]: { $set: value } } }),
    callback
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Activates state that redirects to list
 *
 */
export function redirectToList() {
  this.setState({ redirectToList: true });
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Displays an invalid form notification
 *
 */
export function invalidNotification(title, message) {
  this.props.notificationSystem.add({
    title: title ? title : "Atenção, dados incompletos!",
    message: message ? message : "Verifique os destaques em vermelho.",
    level: "error",
    position: "tr",
    autoDismiss: FIVE_SECONDS,
  });
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Displays an warning form notification
 *
 */
export function warningNotification(message) {
  this.props.notificationSystem.add({
    title: "Atenção!",
    message: message ? message : "Sucesso!",
    level: "warning",
    position: "tr",
    autoDismiss: 5,
  });
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Displays a form submit success notification
 *
 * @param {String} messsage Message to be displayed
 */
export function successNotification(message) {
  this.props.notificationSystem.add({
    title: "Sucesso!",
    message: message ? message : "Sucesso!",
    level: "success",
    position: "tr",
    autoDismiss: 5,
  });
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Submits report form data
 *
 * @param {Object} event The submit event
 *
 * @return {boolean}
 *
 */
export function handleSubmit() {
  const isUpdate = this.isUpdate();
  const obj = this.createObj();

  const then = (data, xhr) => {
    if (xhr.response.status === 200) {
      const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
      this.successNotification(message);
    }

    this.redirectToList();
  };

  if (isUpdate) {
    // Hotfix, should be removed later, and keep only 'else'
    if (this.props.update) {
      this.props.update(obj, then);
    } else {
      this.props.updateReport(obj, then);
    }
  } else {
    // Hotfix, should be removed later, and keep only 'else'
    if (this.props.post) {
      this.props.post(obj, then);
    } else {
      this.props.postReport(obj, then);
    }
  }
}

export function createHandleClickTable(
  state,
  rowInfo,
  column,
  selectedRowId,
  onClick,
  onDoubleClick
) {
  const currentId = rowInfo ? rowInfo.original.id : null;
  const shouldPaint = currentId === selectedRowId;

  return {
    onClick: (e, handleOriginal) => {
      onClick(currentId);
      handleOriginal && handleOriginal();
    },
    onDoubleClick: (e, handleOriginal) => {
      onDoubleClick(currentId);
      handleOriginal && handleOriginal();
    },
    style: {
      background: shouldPaint ? "#4dc3bf6b" : "",
    },
  };
}

/**
 * @author Victor Heringer
 *
 * Sends report through email
 *
 * @param {Number} id
 */
export function handleSendReportEmail() {
  let requestExamId = this.props.match.params.requestExamId;
  const requestExamsState = this.state.fields.requestExamState;
  let requestExamsStates = [requestExamsState];

  if (requestExamId === undefined) {
    requestExamId = this.props.requestExamsWithExamRequestId.value[0].id;
    requestExamsStates = this.props.requestExamsWithExamRequestId.value.map(
      (exam) => exam.requestExamState
    );
  }

  if (!shoudSendEmail(requestExamsStates)) {
    this.props.notificationSystem.add({
      title: "Não enviado!",
      message: WARNING_EMAIL_MESSAGE,
      level: "warning",
      position: "tr",
      autoDismiss: 5,
    });
    return;
  }
  this.props.sendEmailReport(requestExamId);
  this.successNotification(SUCCESS_EMAIL_MESSAGE);
}

function shoudSendEmail(examStates) {
  const notAllowed = examStates.filter(
    (examState) =>
      examState != REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_LIBERADO"]
  );
  return notAllowed.length === 0;
}
