import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import { required } from '../../assets/js/validationFunctions';

import {
  commonReportRules
  } from '../../assets/js/validationRules';

const validation = validated( props => {

  const {
    result,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const {completeValidation} = props.data;

  let fields = [
    'result',
    'vetSignerId',
    'requestExamState'
  ];

  const commonValidation = commonReportRules(
    vetSignerId, requestExamState );

  const validations = {
    ...commonValidation,
    result: {
      rules: [
        [ required, result, 'Obrigatório' ]
      ]
    }
  };

  return { fields, validations };

} );

export default validation;