import { required } from "../../../assets/js/validationFunctions";
import { corpuscularVolumeRules } from "../../../assets/js/validationRules";
import { EXAM_TYPES } from "../../../consts";

export const reticulocyteFieldNames = () => {
  return [
    "reticulocyteByField",
    "reticulocyteErythrocyteCount",
    "reticulocyteCorpuscularVolume",
    "reticulocytePercent",
    "reticulocyteAbsoluteErythrocyteCount",
    "reticulocyteTotalReticulocyte",
    "reticulocyteCorrection",
    "reticulocyteObs",
  ];
};

export const reticulocyteDefaultState = () => {
  return {
    reticulocyteByField: "",
    reticulocyteErythrocyteCount: "",
    reticulocyteCorpuscularVolume: "",
    reticulocytePercent: "",
    reticulocyteAbsoluteErythrocyteCount: "",
    reticulocyteTotalReticulocyte: "",
    reticulocyteCorrection: "",
    reticulocyteObs: "",
  };
};

export const reticulocyteStateApiFactory = (obj) => {
  return {
    reticulocyteByField: obj.reticulocyteByField,
    reticulocyteErythrocyteCount: obj.reticulocyteErythrocyteCount,
    reticulocyteCorpuscularVolume: obj.reticulocyteCorpuscularVolume,
    reticulocytePercent: obj.reticulocytePercent,
    reticulocyteAbsoluteErythrocyteCount:
      obj.reticulocyteAbsoluteErythrocyteCount,
    reticulocyteTotalReticulocyte: obj.reticulocyteTotalReticulocyte,
    reticulocyteCorrection: obj.reticulocyteCorrection,
    reticulocyteObs: obj.reticulocyteObs,
  };
};

export const reticulocyteFieldsToApiObjParser = (fields) => {
  const {
    reticulocyteByField,
    reticulocyteErythrocyteCount,
    reticulocyteCorpuscularVolume,
    reticulocytePercent,
    reticulocyteAbsoluteErythrocyteCount,
    reticulocyteTotalReticulocyte,
    reticulocyteCorrection,
    reticulocyteObs,
  } = fields;

  return {
    reticulocyteByField: reticulocyteByField,
    reticulocyteErythrocyteCount: reticulocyteErythrocyteCount,
    reticulocyteCorpuscularVolume: reticulocyteCorpuscularVolume,
    reticulocytePercent: reticulocytePercent,
    reticulocyteAbsoluteErythrocyteCount: reticulocyteAbsoluteErythrocyteCount,
    reticulocyteTotalReticulocyte: reticulocyteTotalReticulocyte,
    reticulocyteCorrection: reticulocyteCorrection,
    reticulocyteObs: reticulocyteObs,
  };
};

export const reticulocyteValidationRules = (fields, isCompleteValidation) => {
  const {
    reticulocyteByField,
    reticulocyteErythrocyteCount,
    reticulocyteCorpuscularVolume,
  } = fields;

  const corpuscularVolumeValidation = corpuscularVolumeRules(
    "reticulocyteCorpuscularVolume",
    reticulocyteCorpuscularVolume
  );
  return {
    ...corpuscularVolumeValidation,
    reticulocyteByField: {
      rules: isCompleteValidation
        ? [[required, reticulocyteByField, "Obrigatório"]]
        : [],
    },
    reticulocyteErythrocyteCount: {
      rules: isCompleteValidation
        ? [[required, reticulocyteErythrocyteCount, "Obrigatório"]]
        : [],
    },
    reticulocyteCorpuscularVolume: {
      rules: isCompleteValidation
        ? [[required, reticulocyteCorpuscularVolume, "Obrigatório"]]
        : [],
    },
  };
};

export const reticulocyteRequests = (requestExamId) => {
  return {
    reticulocyteRefsPromise: `/reference-values/?requestExamId=${requestExamId}&examTypeId=${EXAM_TYPES.HEMATOLOGY}`,
  };
};
