import connect from "../../api-connector";
import { EXAM_TYPE_ID_BY_NAME } from "../../assets/js/Consts.js";

const urlName = examTypeId => {
  switch (examTypeId) {
    case EXAM_TYPE_ID_BY_NAME.ENDOCRINOLOGY_CHEMILUMINESCENCE:
      return "endocrinology-chemiluminescence";
    case EXAM_TYPE_ID_BY_NAME.ENDOCRINOLOGY_RADIOIMMUNOASSAY:
      return "endocrinology-radioimmunoassay";
    default:
      return "";
  }
};

const connectWithEndpoint = connect(props => {
  const examRequestId = props.match.params.examRequestId;
  const name = urlName(props.examTypeId);
  const examTypeId = props.examTypeId;

  return {
    requestExamStates: `/request-exam-states`,
    examTableHeaderData: `/report/request-exams/${examRequestId}/exam-table-report-header/${examTypeId}`,
    getRequestExamsWithExamRequestId: then => ({
      requestExamsWithExamRequestId: {
        url: `/${name}/request-exams/${examRequestId}`,
        then: then
      }
    }),
    getReportsWithExamRequestId: then => ({
      reportsWithExamRequestId: {
        url: `/biochemistry/reports/${examRequestId}`,
        then: then
      }
    }),
    getExams: then => ({
      exams: {
        url: `/${name}/exam-requests/${examRequestId}`,
        then: then
      }
    }),
    withExamRequestId: (id, then) => ({
      obj: {
        url: `/reports/exam-requests/${id}`,
        then: then
      }
    }),
    sendEmailReport: (id, then) => ({
      sendEmailReportPromisse: {
        url: `/mail/print-report/${id}`,
        method: "POST",
        then: then
      }
    }),
    postReport: (report, release, then) => ({
      submitPromise: {
        url: `/reports/endocrinology-chemiluminescence/${examRequestId}?release=${release}`,
        method: "POST",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    cancelReport: (report, then) => ({
      submitPromise: {
        url: `/reports/endocrinology-chemiluminescence/${examRequestId}/cancel`,
        method: "PUT",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    stopReport: (report, then) => ({
      submitPromise: {
        url: `/reports/endocrinology-chemiluminescence/${examRequestId}/stop`,
        method: "PUT",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    })
  };
});

export default connectWithEndpoint;
