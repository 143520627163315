import React from "react";

/** Internal */
import HemocytozoaSerialResearchPanel from "./../HemocytozoaSerialResearchPanel";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import ReportPanelMaterial from "../ReportPanelMaterial";
import TextAreaPanel2 from "../TextAreaPanel2";
import ReportHeader from "./../ReportHeader";
import { Row, Col } from "./../../components/grid";

const HemocytozoaSerialResearchReportFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Pesquisa Seriada de Hemocitozoários"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={3} mdOffset={9}>
        <ButtonDefaultMaterial
          id="include_research_btn"
          onClick={methods.addResearch}
          type="highlight"
          title={"Incluir Pesquisa Nº " + (data.fields.researchs.length + 1)}
          className="btn-form mt-10"
          block={true}
          disabled={data.disableAll}
        />
      </Col>
    </Row>
    {data.fields.researchs.map((research, index) => (
      <Row key={index}>
        <Col md={12}>
          <HemocytozoaSerialResearchPanel
            title={"Pesquisa Nº " + (index + 1)}
            resultFieldName={"result-" + index}
            result={research.result}
            resultHasValidation={data.$validation["result-" + index].show}
            resultValidationReason={
              data.$validation["result-" + index].error.reason
            }
            handleChange={methods.handleResearchChange}
            handleBlur={methods.handleBlur}
            handleRemove={methods.prepareResearchRemoval(index)}
            shouldDisable={data.disableAll}
            isThereMoreThanOne={data.fields.researchs.length > 1}
          />
        </Col>
      </Row>
    ))}
    <Row>
      <Col md={12}>
        <TextAreaPanel2
          title="Obs"
          name="obs"
          value={data.fields.obs}
          disabled={data.disableAll}
          handleChange={methods.handleChange}
          handleBlur={methods.handleBlur}
          hasValidation={data.$validation.obs.show}
          validationReason={data.$validation.obs.error.reason}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default HemocytozoaSerialResearchReportFormView;
