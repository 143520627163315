import { validated } from "react-custom-validation";
import {
  required,
  minLength,
  greaterThan,
  validateCNPJ,
  validateCPF,
  validEmail,
  requiredIfExistAnother,
  handleApiValidate
} from "../../assets/js/validationFunctions.js";

import { commonReportRules } from "../../assets/js/validationRules";

const validation = validated(props => {
  const {
    vetSignerId,
    requestExamState,
    density,
    volume,
    colorId,
    smellId,
    aspectId,
    sedimentId,
    supernatantId,
    proteins,
    glucose,
    hiddenBloodId,
    ph,
    coagulation,
    hemocyte,
    hemocyteResult,
    leukocytes,
    result
  } = props.data.fields;

  const { completeValidation } = props.data;

  const commonValidation = commonReportRules(vetSignerId, requestExamState);
  const validations = {
    ...commonValidation,
    density: {
      rules: completeValidation ? [[required, density, "Informe o campo"]] : []
    },
    volume: {
      rules: completeValidation ? [[required, volume, "Informe o campo"]] : []
    },
    colorId: {
      rules: completeValidation
        ? [[greaterThan, colorId, 0, "Informe o campo"]]
        : []
    },
    smellId: {
      rules: completeValidation
        ? [[greaterThan, smellId, 0, "Informe o campo"]]
        : []
    },
    aspectId: {
      rules: completeValidation
        ? [[greaterThan, aspectId, 0, "Informe o campo"]]
        : []
    },
    sedimentId: {
      rules: completeValidation
        ? [[greaterThan, sedimentId, 0, "Informe o campo"]]
        : []
    },
    supernatantId: {
      rules: completeValidation
        ? [[greaterThan, supernatantId, 0, "Informe o campo"]]
        : []
    },
    proteins: {
      rules: completeValidation ? [[required, proteins, "Informe o campo"]] : []
    },
    glucose: {
      rules: completeValidation ? [[required, glucose, "Informe o campo"]] : []
    },
    hiddenBloodId: {
      rules: completeValidation
        ? [[greaterThan, hiddenBloodId, 0, "Informe o campo"]]
        : []
    },
    ph: {
      rules: completeValidation ? [[required, ph, "Informe o campo"]] : []
    },
    coagulation: {
      rules: completeValidation
        ? [[required, coagulation, "Informe o campo"]]
        : []
    },
    hemocyte: {
      rules: completeValidation ? [[required, hemocyte, "Informe o campo"]] : []
    },
    hemocyteResult: {
      rules: completeValidation ? [] : []
    },
    leukocytes: {
      rules: completeValidation
        ? [[required, leukocytes, "Informe o campo"]]
        : []
    },
    result: {
      rules: completeValidation ? [[required, result, "Informe o campo"]] : []
    }
  };

  let fields = [
    "requestExamState",
    "vetSignerId",
    "density",
    "volume",
    "colorId",
    "smellId",
    "aspectId",
    "sedimentId",
    "supernatantId",
    "proteins",
    "glucose",
    "hiddenBloodId",
    "ph",
    "coagulation",
    "hemocyte",
    "hemocyteResult",
    "leukocytes",
    "result"
  ];

  return { fields, validations };
});

export default validation;
