import __update from "immutability-helper";
import { Action, UseCustomerState } from "./../../types/index";

export const ACTIONS = {
  UPDATE: "UPDATE",
  UPDATE_FIELD: "UPDATE_FIELD",
};

export const update = (value: UseCustomerState, target: UseCustomerState): UseCustomerState => {
  const commands = {
    id: { $set: value.id },
    type: { $set: value.type },
    name: { $set: value.name },
    isActive: { $set: value.isActive },
    receivesPrintedReport: { $set: value.receivesPrintedReport }
  };
  return __update(target, commands);
};

export const updateField = (
  field: string,
  value: any,
  target: UseCustomerState
): UseCustomerState => {
  const commands = {
    [field]: { $set: value },
  };
  return __update(target, commands);
};

export const reducer = (state: UseCustomerState, action: Action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return update(action.payload, state);
    case ACTIONS.UPDATE_FIELD:
      return updateField(action.payload.field, action.payload.value, state);
    default:
      return state;
  }
};
