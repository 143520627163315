import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import HeartwormDiseaseContent from "./../HeartwormDiseaseContent";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";
import { ManufacturerInformationFields } from "../ManufacturerInformationFields";

const HeartwormDiseaseReportFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Dirofilariose"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    
    <Row>
      <Col md={12}>
        <HeartwormDiseaseContent
          materialId={data.fields.materialId}
          isPositive={data.fields.result}
          materialIdFieldName="materialId"
          isPositiveFieldName="result"
          materialIdHasValidation={data.$validation.materialId.show}
          isPositiveHasValidation={data.$validation.result.show}
          materialIdValidationReason={data.$validation.materialId.error.reason}
          isPositiveValidationReason={data.$validation.result.error.reason}
          obsFieldName="obs"
          obs={data.fields.obs}
          shouldDisable={data.disableAll}
          handleChange={methods.handleChange}
          handleBlur={methods.handleBlur}
        />
      </Col>
    </Row>
    
    {/* <ManufacturerInformationFields
      manufacturer={data.fields.manufacturer}
      batch={data.fields.batch}
      validity={data.fields.validity}
      obs={data.fields.manufacturerInformationObs}
      obsFieldName="manufacturerInformationObs"
      onChange={(name, value) => methods.fieldChange(name, value)}
    /> */}
  </ReportPanelMaterial>
);

export default HeartwormDiseaseReportFormView;
