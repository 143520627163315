import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import DualRadioMaterial from "./../DualRadioMaterial";
import InputMaterial from "./../InputMaterial";
import CheckboxMaterial from "./../CheckboxMaterial";
import TextAreaMaterial from "./../TextAreaMaterial";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";
import FlatPanelMaterial from "./../FlatPanelMaterial";

const SporothrixSchenckiiFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Pesquisa de Sporothrix Schenckii"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <FlatPanelMaterial title="Resultado">
      <Row>
        <InputMaterial
          id="material"
          name="material"
          title="Material Enviado"
          type="text"
          disabled={data.disableAll}
          md={4}
          value={data.fields.material}
          {...methods.$field("material", event => {
            methods.fieldChange("material", event.target.value);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.material.show &&
            !data.$validation.material.isValid
          }
          validationReason={
            data.$validation && data.$validation.material.error.reason
          }
          isValid={data.$validation && data.$validation.material.isValid}
        />
        <DualRadioMaterial
          md={4}
          title="Resultado"
          name="result"
          selectedValue={data.fields.result}
          disabled={data.disableAll}
          firstLabel="Negativo"
          firstValue={0}
          secondLabel="Postivo"
          secondValue={1}
          {...methods.$field("result", event => {
            methods.changeResult("result", event, methods.changeText);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.result.show &&
            !data.$validation.result.isValid
          }
          validationReason={
            data.$validation && data.$validation.result.error.reason
          }
          isValid={data.$validation && data.$validation.result.isValid}
        />
        <Col md={4} className="pt-20 mt-5">
          <CheckboxMaterial
            id="isNegativeToCulture"
            name="isNegativeToCulture"
            titleAfter="Resultado negativo a cultura"
            checked={data.fields.isNegativeToCulture}
            disabled={data.disableAll}
            onChange={() =>
              methods.toggleCheckbox(
                "isNegativeToCulture",
                methods.changeObsText
              )
            }
          />
        </Col>
        <Col md={12} className="mt-10">
          <TextAreaMaterial
            id="text"
            disabled={data.disableAll}
            title="Informações"
            name="text"
            value={data.fields.text}
            {...methods.$field("text", event => {
              methods.fieldChange("text", event.target.value);
            })}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
    <FlatPanelMaterial title="Diagnóstico">
      <Row>
        <Col xs={1} className="text-right">
          <p className="mr-10 cleanItem">OBS</p>
        </Col>
        <Col xs={11}>
          <InputMaterial
            name="obs"
            md={12}
            disabled={data.disableAll}
            value={data.fields.obs}
            fit={true}
            onChange={event => methods.fieldChange("obs", event.target.value)}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
  </ReportPanelMaterial>
);

export default SporothrixSchenckiiFormView;
