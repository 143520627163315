import React, { Component } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import InputMaterial from "../InputMaterial";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import { ReceiveAndAssistModalValidation } from "./../ExamRequestList/validation";
import { validated } from "react-custom-validation";

/**
 * @author Victor Heringer
 *
 * Modal to receive and assist an exam request
 */
class ReceiveAndAssistModal extends Component {
  componentDidMount() {
    window.addEventListener("keydown", this.handleKey);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKey);
  }

  handleKey = (e) => {
    const submit = () =>
      this.props.methods.receiveAndAssistSampleCode(
        this.props.data.fields.modalSampleCode
      );
    if (e.keyCode == 13) {
      this.props.$submit(submit);
    }
  };

  render() {
    const {
      $field,
      $validation,
      $submit,
      $fieldEvent,
      methods,
      data,
    } = this.props;

    return (
      <div>
        <Modal
          show={data.showReceiveAndAssistModal}
          onHide={methods.handleCloseReceiveAndAssistModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Receber e Laudar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <InputMaterial
                md={7}
                id="modalExamPlaceName"
                name="modalExamPlaceName"
                title="Identificação / Código de Barra"
                autofocus={true}
                value={data.fields.modalSampleCode}
                {...$field("modalSampleCode", (event) => {
                  methods.updateField("modalSampleCode", event.target.value);
                })}
                hasValidation={
                  $validation &&
                  $validation.modalSampleCode.show &&
                  !$validation.modalSampleCode.isValid
                }
                validationReason={
                  $validation && $validation.modalSampleCode.error.reason
                }
                isValid={$validation && $validation.modalSampleCode.isValid}
              />
              <Col md={5}>
                <ButtonSaveMaterial
                  className="mt-15"
                  title="Receber"
                  type="highlight"
                  position="top"
                  right={true}
                  id="btn_receive_and_assist"
                  block
                  onClick={(event) =>
                    $submit(() =>
                      methods.receiveAndAssistSampleCode(
                        data.fields.modalSampleCode
                      )
                    )
                  }
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const withValidation = validated(ReceiveAndAssistModalValidation);

export default withValidation(ReceiveAndAssistModal);
