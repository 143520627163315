import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * External
 */
import classNames from 'classnames';

/**
 * Internal
 */
import './index.css';

/**
 * @author Victor Heringer
 *
 * Wrapper for validation messages
 */
class InputValidationMsg extends Component {

  render() {

    /**
     * Props
     */
    const { message, type, top, visible, className } = this.props;

    /**
     * Style for message container
     * @type {Object}
     */
    const msgStyle = { marginTop: top };

    /**
     * Check if the element shoud hana any distance
     * 
     * @param  {Number}  side Distance from the given side
     * 
     * @return {Boolean}      True if has distance, otherwise false.
     */
    const hasDistanceFrom = function hasDistanceFrom( side ) {
      return side ? true : false;
    }

    /**
     * Classnames for easy class change accroding to given state
     * @type {Object}
     */
    const msgClass = classNames(
      'msg',
      className,
      { 'msg-visible': visible },
      { 'msg-hide': !visible },
      { 'msg-info': type === 'info' },
      { 'msg-danger': type === 'danger' },
      { 'msg-warning': type === 'warning' },
      { 'msg-top-recoil': hasDistanceFrom( top ) }
    );

    return (
      <React.Fragment>
        <span 
          className={ msgClass } 
          style={ msgStyle } >
          { message }
        </span>
      </React.Fragment>
    );
  }
}

InputValidationMsg.defaultProps = {
  top: '',
  type: 'danger',
  message: '',
  visible: false
};

InputValidationMsg.propTypes = {
  top: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf( [ 'danger', 'warning', 'info' ] ),
  visible: PropTypes.bool
}

export default InputValidationMsg;