import React, { Component, useState } from "react";

import { Tab, Tabs, Col, Row } from "react-bootstrap";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
import { ArrayUtil, FunctionUtil } from "./../../useful";
import refetchConnect from "./../../api-connector";
import connectWithEndpoint from "./requests";
import { connect as reduxConnect } from "react-redux";
import LabRecordsView from "./view";
import { useForm } from "../../hooks/useForm";
import promiseUtil from "./../../useful/promise-util";



const header = [
  "Laboratorio",
  "Exame",
  "Quantidade"
];

function enableLabs(userLabId) {
  return true;
}

const LabRecords = (props) => {
  const [values, loading, inputChange, fieldChange, handleSubmit] = useForm();
  const regions = promiseUtil.extractValue(props.regions, []);
  const customers = promiseUtil.extractValue(props.customers, []);


  const submitActions = () => {
    const success = (data) => {      
      ArrayUtil.exportCSVFile(header, data.data, 'relatório');
    };
    const error = () => {
      alert("Erro interno, tente novamente ou contacte o suporte");
    };
    props.examRecords(values, success, error);
  };

  const shoundEnableLabs = enableLabs(props.userLabId);

  return (
    <>
      <LabRecordsView
        values={values}
        handleSubmit={handleSubmit(submitActions)}
        inputChange={inputChange}
        fieldChange={fieldChange}
        loading={loading}
        regions={regions}
        customers={customers}
        shoundEnableLabs={shoundEnableLabs}
      />
    </>
  );
};


const connectWithEndpoint2 = refetchConnect(connectWithEndpoint);
const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);

export default FunctionUtil.compose(
  connectWithEndpoint2,
  connectWithRedux,
)(LabRecords);


