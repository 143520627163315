import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * External
 */
import { Col } from "react-bootstrap";
import { RadioGroup, Radio } from "react-radio-group";

/**
 * Internal
 */
import InputValidationMsg from "./../InputValidationMsg";

const defaultProps = {};
const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for dual radio with material design styling
 */
const DualRadioMaterial = ({
  md,
  title,
  name,
  selectedValue,
  onChange,
  disabled,
  firstLabel,
  firstValue,
  secondLabel,
  secondValue,
  hasValidation,
  validationType,
  hasValidationRecoil,
  validationReason
}) => {
  /**
   * Validation Component
   * @type {Object}
   */
  const validation = (
    <InputValidationMsg
      type={validationType}
      visible={hasValidation}
      message={validationReason}
      top={hasValidationRecoil ? "-18px" : ""}
    />
  );

  let handleChange = undefined;

  if (onChange) {
    handleChange = value => {
      onChange(name, value);
    };
  }

  return (
    <Col md={md}>
      <div className="form-group mb-0">
        <label className="mb-0">{title}</label>
        <RadioGroup
          name={name}
          onChange={handleChange}
          selectedValue={selectedValue}
        >
          <Col xs={6} className="pd-smaller-left">
            <Radio disabled={disabled} value={firstValue} />
            <span className="radio-label">{firstLabel}</span>
          </Col>
          <Col xs={6} className="pd-smaller-left">
            <Radio disabled={disabled} value={secondValue} />
            <span className="radio-label">{secondLabel}</span>
          </Col>
        </RadioGroup>
      </div>
      {validation}
    </Col>
  );
};

DualRadioMaterial.defaultProps = defaultProps;
DualRadioMaterial.propTypes = propTypes;

export default DualRadioMaterial;
