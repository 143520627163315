import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * External
 */
import { Col } from "react-bootstrap";
import classNames from "classnames";
import NumberFormat from "react-number-format";

/**
 * Internal
 */
import InputValidationMsg from "./../InputValidationMsg";
import styles from "./index.module.css";

const convertIfNullUndefNaN = value => {
  if (value === undefined || value === null || value === NaN) {
    return "";
  }
  return value;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for input text with material design styling
 */
class NumberInputMaterial2 extends Component {
  constructor(props) {
    super(props);
    this.renderInput = this.renderInput.bind(this);
  }

  renderInput(inputClass) {
    const {
      decimalScale,
      fixedDecimalScale,
      format,
      placeholder,
      mask,
      isAllowed,
      isNumericString,
      name,
      disabled,
      type,
      allowNegative,
      handleBlur,
      handleChange,
      autofocus,
      suffix,
      shouldDisableThousandSeparator
    } = this.props;

    let { value } = this.props;

    value = convertIfNullUndefNaN(value);

    const onValueChange = (values, e) => {
      const field = e.target.name;
      const value = values.value;
      if (handleChange) {
        handleChange(field, value);
      }
    };

    const thousandSeparator = shouldDisableThousandSeparator ? undefined : ".";

    return (
      <NumberFormat
        type={type}
        name={name}
        mask={mask}
        value={value}
        format={format}
        onBlur={handleBlur}
        autoFocus={autofocus}
        disabled={disabled}
        className={inputClass}
        isAllowed={isAllowed}
        placeholder={placeholder}
        displayType="input"
        decimalScale={decimalScale}
        allowNegative={allowNegative}
        onValueChange={onValueChange}
        isNumericString={isNumericString}
        decimalSeparator={","}
        thousandSeparator={thousandSeparator}
        fixedDecimalScale={fixedDecimalScale}
        suffix={suffix}
      />
    );
  }

  render() {
    const {
      title,
      block,
      titleAfter,
      inputGroup,
      inputGroupAfter,
      className,
      inputGroupClassName,
      fit
    } = this.props;

    /**
     * Validation props
     */
    const {
      hasValidation,
      validationReason,
      validationType,
      hasValidationRecoil
    } = this.props;

    /**
     * Input group class
     * @type {Object}
     */
    const inputGroupClass = classNames(
      { inputGroupClassName },
      { "input-group": inputGroup },
      { "form-group": !inputGroup },
      { "d-block": block },
      { fit: fit }
    );

    /**
     * Input class
     * @type {Object}
     */
    const inputClass = classNames(
      "form-control",
      styles.inputMaterial,
      className
    );

    return (
      <Col>
        <div className={inputGroupClass}>
          {title && <label>{title}</label>}
          {this.renderInput(inputClass)}
          {titleAfter && (
            <label className={styles.labelAfterMaterial}>{titleAfter}</label>
          )}
          {inputGroup && inputGroupAfter && (
            <span className="input-group-addon">{inputGroupAfter}</span>
          )}
        </div>
        <InputValidationMsg
          type={validationType}
          visible={hasValidation}
          message={validationReason}
          top={hasValidationRecoil ? "-18px" : ""}
        />
      </Col>
    );
  }
}

NumberInputMaterial2.defaultProps = {
  type: "text",
  disabled: false,
  validationReason: "",
  hasValidation: false,
  validationType: "danger",
  hasValidationRecoil: true,
  isNumericString: true
};

NumberInputMaterial2.propTypes = {
  /**
   * Required
   */
  name: PropTypes.string.isRequired,

  /**
   * Default
   */
  title: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validationType: PropTypes.oneOf(["danger", "warning", "info"]),
  hasValidationRecoil: PropTypes.bool,

  /**
   * Custom
   */
  labelInline: PropTypes.bool,
  inputGroup: PropTypes.bool,
  inputGroupAfter: PropTypes.string,
  titleAfter: PropTypes.string,
  hasValidation: PropTypes.bool,
  validationReason: PropTypes.string,
  block: PropTypes.bool,
  fit: PropTypes.bool,
  shouldDisableThousandSeparator: PropTypes.bool,

  /**
   * Classes
   */
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string
};

export default NumberInputMaterial2;
