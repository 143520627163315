/**
 * External
 */
import axios from "axios";
import jwt_decode from "jwt-decode";
import { ENV_TYPES, LOCAL_STORAGE_KEYS } from "./../../consts/";
import GetUserTokenBasedOnEnv from "./../../services/get-user-token-based-on-env";

const URL = process.env.REACT_APP_PROXY;

export const FETCH_LOGIN_BEGIN = "FETCH_USER";
export const FETCH_LOGIN_FAIL = "LOGIN_FAIL";
export const FETCH_LOGIN_SUCCESS = "LOGIN_USER";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_NOT_LOGGED_IN = "USER_NOT_LOGGED_IN";

/**
 * Login fetch starts
 */
export const fetchLoginBegin = () => ({
  type: FETCH_LOGIN_BEGIN,
});

/**
 * Login fetch succes
 */
export const fetchLoginSuccess = (user) => ({
  type: FETCH_LOGIN_SUCCESS,
  payload: { user },
});

/**
 * Login Fetch error
 */
export const fetchLoginFail = (error) => ({
  type: FETCH_LOGIN_FAIL,
  payload: { error },
});

/**
 * Define a user as logged in
 */
export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  payload: { user },
});

/**
 * Define a user as not logged in
 */
export const userNotLoggedIn = () => ({
  type: USER_NOT_LOGGED_IN,
});

/**
 * Logouts user from system
 */
export const userLogout = () => ({
  type: USER_LOGOUT,
});

/**
 * @author Victor Heringer
 *
 * Use when you need to request a login to api
 *
 * @param  {string} username
 * @param  {string} password
 */
export function loginUser(username, password, onSuccess = () => {}) {
  const env = process.env.REACT_APP_ENV;
  return (dispatch) => {
    dispatch(fetchLoginBegin());

    let config = {
      headers: {
        Accept: "application/json",
      },
    };

    axios
      .post(`${URL}/login`, { username: username, password: password }, config)
      .then((response) => {
        let user = jwt_decode(response.data.token);
        if (env === ENV_TYPES.LOCAL) {
          window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.AUTH_TOKEN,
            response.data.token
          );
        } else {
          window.auth = { token: response.data.token };
        }

        user.token = response.data.token;
        dispatch(fetchLoginSuccess(user));
        return user;
      })
      .then(onSuccess)
      .catch((error) => {
        dispatch(fetchLoginFail(error));
      });
  };
}

/**
 * @author Victor Heringer
 *
 * Calls the acction to verify if user has a token stored.
 */
export function verifyToken() {
  return (dispatch) => {
    let token = GetUserTokenBasedOnEnv();

    let user = token ? jwt_decode(token) : null;

    return user ? dispatch(userLoggedIn(user)) : dispatch(userNotLoggedIn());
  };
}

/**
 * @author Victor Heringer
 *
 * Logout the user from system
 */
export function logout() {
  return (dispatch) => {
    window.auth = null;
    window.localStorage.clear();
    dispatch(userLogout());
  };
}
