import React from "react";
import styled from "styled-components";
import { rose, blackberry, granadilla } from "./../colors";

const StyledTextArea = styled.textarea`
  border-radius: 3px;
  border: ${props => hasErrorBorder(props)};
  padding: 10px;
  font-size: 12px;
  width: 100%;
  color: ${blackberry};
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: Arial;

  &::-webkit-textarea-placeholder {
    color: ${props => hasErrorColor(props)};
  }
`;

const StyledErrorMessage = styled.div`
  color: ${rose};
  margin-top: 5px;
  font-family: arial;
  font-size: 12px;
`;

const hasErrorBorder = ({ errorMessage }) => {
  return errorMessage ? ` 1px solid ${rose};` : "0";
};

const hasErrorColor = ({ errorMessage }) => {
  return errorMessage ? rose : granadilla;
};

const TextArea = props => (
  <>
    <StyledTextArea {...props} />
    {props.errorMessage && (
      <StyledErrorMessage>{props.errorMessage}</StyledErrorMessage>
    )}
  </>
);

export default TextArea;
