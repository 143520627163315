import React from 'react';

/**
 * @author Victor Heringer
 * 
 * Creates element for pdf preview
 * 
 * @param {String} value
 * @param {String} name
 * @param {String} width
 * @param {String} height
 */
const preview = ( value, name, width, height) => {
  return <embed
    key={name}
    src={value}
    width={width}
    height={height}
    type='application/pdf'
    key={name}
  /> 
}

/**
 * @author Victor Heringer
 * 
 * Previews a pdf at browser
 * 
 * @prop {Array} pdfs
 * @prop {Bool} display
 * @prop {Array} placeholders
 * @prop {String} width
 * @prop {String} height 
 */
const PdfPreview = ({ pdfs, display, placeholders, width, height }) => {
  return display ? pdfs.length > 0
    ? pdfs.map((pdf) => preview(pdf.value, pdf.name, width, height))
    : placeholders.map((pdf) => preview(pdf.value, pdf.name, width, height))
  : null;
}

export default PdfPreview;