import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
* Internal
*/
import ThreeTagsInputPanel from './../ThreeTagsInputPanel';

/**
 * Assets
 */

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 * 
 * Component that represents a AntibiogramPanel form
 * 
 */

 /**
  * @version 0.0.1
  *
  * @author Gabriel Cardoso
  *
  * @description allow to select 'Nenhum antibiótico testado' option in all inputs
  *
 */

const AntibiogramPanel = ({
  antibioticOptions,
  suggestionsFilter,
  sensitiveTagsFieldName,
  sensitiveTags,
  sensitiveHasValidation,
  sensitiveValidationReason,
  intermediateTagsFieldName,
  intermediateTags,
  intermediateHasValidation,
  intermediateValidationReason,
  resistantTagsFieldName,
  resistantTags,
  resistantHasValidation,
  resistantValidationReason,
  shouldDisable,
  handleChange,
  handleBlur
}) => {
  function removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
  }

  function onChange(fieldname, value) {
    handleChange(fieldname, removeDuplicates(value));
  }

  const filteredFirstSuggestions = antibioticOptions.filter(item => {
    if (item.name.includes('Nenhum dos antibióticos testados')) return true

    return !resistantTags.includes(item.name) && !intermediateTags.includes(item.name);
  })

  const filteredSecondSuggestions = antibioticOptions.filter(item => {
    if (item.name.includes('Nenhum dos antibióticos testados')) return true

    return !sensitiveTags.includes(item.name) && !resistantTags.includes(item.name);
  })

  const filteredThirdSuggestions = antibioticOptions.filter(item => {
    if (item.name.includes('Nenhum dos antibióticos testados')) return true

    return !sensitiveTags.includes(item.name) && !intermediateTags.includes(item.name);
  })

  return (
    <ThreeTagsInputPanel
      panelTitle="Antibiograma"
      firstTitle="Sensível"
      firstTags={sensitiveTags}
      firstName={sensitiveTagsFieldName}
      firstSuggestions={filteredFirstSuggestions}
      firstSuggestionsFilter={suggestionsFilter}
      firstHasValidation={sensitiveHasValidation}
      firstValidationReason={sensitiveValidationReason}
      secondTitle="Intermediário"
      secondTags={intermediateTags}
      secondName={intermediateTagsFieldName}
      secondSuggestions={filteredSecondSuggestions}
      secondSuggestionsFilter={suggestionsFilter}
      secondHasValidation={intermediateHasValidation}
      secondValidationReason={intermediateValidationReason}
      thirdTitle="Resistente"
      thirdTags={resistantTags}
      thirdName={resistantTagsFieldName}
      thirdSuggestions={filteredThirdSuggestions}
      thirdSuggestionsFilter={suggestionsFilter}
      thirdHasValidation={resistantHasValidation}
      thirdValidationReason={resistantValidationReason}
      shouldDisable={shouldDisable}
      handleChange={onChange}
      handleBlur={handleBlur}
    />
  )
}

AntibiogramPanel.propTypes = propTypes;

export default AntibiogramPanel;