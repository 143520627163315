import React, { Component } from "react";
import ExamRequestSearchListView from "./../ExamRequestSearchListView";
import connectWithEndpoint from "./request";
import update from "immutability-helper";
import { StringUtil } from "./../../useful";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { FunctionUtil } from "./../../useful";
import { yearsMonthsChange } from "../../assets/js/animalFunctions";
import moment from "moment";
import { values } from "../../consts/payment-frequency";
import { ACCESS_PROFILES } from "../../assets/js/Consts";

class ExamRequestSearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        code: "",
        animalName: "",
        specieId: 0,
        ownerName: "",
        examId: 0,
        sexId: 0,
        years: "",
        months: "",
        birthDate: null,
        requestExamState: 0,
        customerId: props.userPersonCustomerId ? props.userPersonCustomerId : 0,
        initialDate: null,
        endDate: null,
      },
      shouldDisableCustomer: props.userPersonCustomerId > 0,
      shouldRedirect: false,
      redirectToId: 0,
      page: 0,
    };
    this.yearsMonthsChange = yearsMonthsChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKey);
    const query = this.props.history.location.search;
    this.fillQueryFields(query);
    if (query != "") {
      this.props.getExamsRequest(query);
    }
  }

  fillQueryFields(query) {
    const params = StringUtil.queryParamsAsObject(query);
    const queryParams = this.mapQueryParamsInStateParams(params);
    this.setState((state, props) => {
      return { ...state, fields: { ...state.fields, ...queryParams } };
    }, this.setLastPageVisited);
  }

  mapQueryParamsInStateParams(params) {
    // console.log("params:", params);
    const keys = [
      "animalName",
      "code",
      "customerId",
      "ownerName",
      "specieId",
      "customerId",
      "requestExamState",
      "examId",
      "sexId",
      "months",
      "years",
      "initialDate",
      "endDate",
      "birthDate",
    ];
    const dateKeys = ["initialDate", "endDate", "birthDate"];

    return Object.keys(params).reduce((acc, key) => {
      if (keys.includes(key)) {
        if (params[key] !== "null" && params[key] !== undefined) {
          params[key] = dateKeys.includes(key)
            ? moment(params[key])
            : params[key];
          return { ...acc, [key]: params[key] };
        }
        return acc;
      } else return acc;
    }, {});
  }

  onPageChange = (page) => {
    this.setState({ page: page });
    this.saveLastPageVisited(page);
  };
  saveLastPageVisited = (page) => {
    localStorage.setItem("lastPage", page);
  };
  setLastPageVisited = () => {
    const lastPage = localStorage.getItem("lastPage");
    //  console.log("lastPage:", lastPage);
    lastPage && this.setState({ page: Number.parseInt(lastPage, 10) });
    localStorage.setItem("lastPage", 0);
  };

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKey);
  }

  handleKey = (e) => {
    if (e.keyCode == 13) {
      this.handleSubmitSearch();
    }
  };

  /**
   * @author Victor Heringer
   *
   * Changes the given field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChange = (field, value) => {
    this.setState((state) =>
      update(state, {
        fields: {
          [field]: { $set: value },
        },
      })
    );
  };

  /**
   * @author Victor Heringer
   *
   * Searchs data
   */
  handleSubmitSearch = () => {
    let fields = { ...this.state.fields };
    this.setState({ page: 0 });

    if (fields.initialDate) {
      fields.initialDate =
        fields.initialDate.format("YYYY-MM-DD") + " 00:00:00";
    }
    if (fields.endDate) {
      fields.endDate = fields.endDate.format("YYYY-MM-DD") + " 23:59:59";
    }

    const query = StringUtil.objectToQueryString(fields);
    this.props.history.push("/solicitacoes-exame/buscar" + query);
    this.props.getExamsRequest(query);
  };

  isCLinicUser = () => {
    const allowed = [
      ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
      ACCESS_PROFILES.CLINIC_USER,
    ];
    return allowed.includes(this.props.userProfile);
  };

  handleCleanFilters = () => {
    let defaultStateList = {
      fields: {
        code: { $set: "" },
        animalName: { $set: "" },
        specieId: { $set: 0 },
        ownerName: { $set: "" },
        examId: { $set: 0 },
        sexId: { $set: 0 },
        years: { $set: "" },
        months: { $set: "" },
        birthDate: { $set: null },
        requestExamState: { $set: 0 },
        initialDate: { $set: null },
        endDate: { $set: null },
      },
    };

    defaultStateList = this.isCLinicUser()
      ? defaultStateList
      : { fields: { ...defaultStateList.fields, customerId: { $set: 0 } } };

    this.setState((state) => update(state, defaultStateList));
  };

  onTableDoubleClick = (currentId, state, rowInfo, column) => {
    rowInfo &&
      this.setState({
        shouldRedirect: true,
        redirectToId: rowInfo.original.code,
      });
  };

  render() {
    if (this.state.shouldRedirect) {
      const id = this.state.redirectToId;
      this.props.history.push(`/solicitacoes-exame/${id}`);
    }

    const data = {
      examsRequest: this.props.examsRequest,
      fields: this.state.fields,
      requestExamStates: this.props.requestExamStates,
      exams: this.props.exams,
      sexs: this.props.sexs,
      species: this.props.species,
      customers: this.props.customers,
      shouldDisableCustomer: this.state.shouldDisableCustomer,
      page: this.state.page,
    };

    const methods = {
      fieldChange: this.fieldChange,
      handleSubmitSearch: this.handleSubmitSearch,
      handleCleanFilters: this.handleCleanFilters,
      onTableDoubleClick: this.onTableDoubleClick,
      yearsMonthsChange: this.yearsMonthsChange,
      onPageChange: this.onPageChange,
    };

    return <ExamRequestSearchListView data={data} methods={methods} />;
  }
}

const mapStateToProps = function (state) {
  return {
    accessProfile: state.userReducer.access_profile,
    userPersonCustomerId: state.userReducer.user.customer_person_id,
    userLabId: state.userReducer.lab_id,
    customerId: state.userReducer.user.customer_id,
    userProfile: state.userReducer.access_profile,
  };
};

export default FunctionUtil.compose(
  connect(mapStateToProps),
  connectWithEndpoint
)(ExamRequestSearchList);
