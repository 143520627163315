import React, { Component } from 'react';

/**
 * External
 */
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

/**
 * Internal
 */
import AnimalList from './../AnimalList';
import AnimalForm from './../AnimalForm';
import NotFound from './../NotFound';

class AnimalSection extends Component {

  constructor( props ) {
    super( props );
    this.renderList = this.renderList.bind( this );
    this.renderForm = this.renderForm.bind( this );
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Renders the animal list
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderList( routeProps ) {

    return (
      <AnimalList
        { ...routeProps }
      />
    );

  }

  /**
     * @author Alessandro Bastos Grandini
     *
     * Renders theanimal form
     *
     * @param  {Object} routeProps Props for the route
     *
     * @return {Object}
     */
  renderForm( routeProps ) {

    return (
      <AnimalForm
        { ...routeProps }
        notificationSystem={ this.props.notificationSystem }
      />
    );

  }

  render() {

    return (
      <Switch>
        <Route
          exact
          path={ '/animais' }
          render={ this.renderList } />
        <Route
          path={ '/animais/novo' }
          render={ this.renderForm } />
        <Route
          path={ '/animais/:id' }
          render={ this.renderForm } />
        <Route component={ NotFound } />
      </Switch>
    );

  }

}

const mapStateToProps = function ( state ) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user,
  }
}

export default connect( mapStateToProps )( AnimalSection );