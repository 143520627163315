import { getChemiluminescenceTableObjs } from "../../../../assets/js/reportFunctions";
import { EXAM_TYPES } from "../../../../consts";
import {
  EXAM_ID_BY_NAME,
  EXAM_NAME_BY_ID,
  profileStructure,
} from "../../../../consts/exams";

const camelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const outsourcedDefaultState = (examId) => {
  const outsourcedNames = profileStructure[examId].outsourcedNames;
  const fields = outsourcedNames.reduce((acc, name) => {
    const fieldNames = [
      camelCase(name.name + "Editor"),
      camelCase(name.name + "Files"),
    ];
    return {
      ...acc,
      [fieldNames[0]]: [fieldNames[0]],
      [fieldNames[1]]: [fieldNames[1]],
    };
  }, {});
  return { fields };
};

export const outsourcedStateApiFactory = (examId, obj) => {
  const outsourcedNames = profileStructure[examId].outsourcedNames;
  const state = outsourcedNames.reduce((acc, name) => {
    const fieldNames = [
      camelCase(name.name + "Editor"),
      camelCase(name.name + "Files"),
    ];
    return {
      ...acc,
      [fieldNames[0]]: obj[fieldNames[0]],
      [fieldNames[1]]: obj[fieldNames[1]],
    };
  }, {});
  return {
    ...state,
    outsourced_cortisol: obj.outsourcedCortisol,
  };
};

export const outsourcedFieldsToApiParser = (examId, fields) => {
  const outsourcedNames = profileStructure[examId].outsourcedNames;
  const outsourcedFields = outsourcedNames.reduce((acc, name) => {
    const fieldNames = [
      camelCase(name.name + "Editor"),
      camelCase(name.name + "Files"),
    ];
    return {
      ...acc,
      [fieldNames[0]]: [fieldNames[0]],
      [fieldNames[1]]: [fieldNames[1]],
    };
  }, {});
  return {
    ...outsourcedFields,
  };
};

export const outsourcedFieldsNames = (examId) => {
  const outsourcedNames = profileStructure[examId].outsourcedNames;
  return outsourcedNames.map((name) => {
    return [camelCase(name.name + "Result"), camelCase(name.name + "Files")];
  })[0];
};
