import React, { Component } from 'react';

/** External */
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

/** Internal */
import PaymentMethodList from './../PaymentMethodList';
import NotFound from './../NotFound';

class PaymentMethodSection extends Component {

  constructor( props ) {
    super( props );
    this.renderList = this.renderList.bind( this );
  }

  /**
   * @author Victor Heringer
   *
   * Renders the breed list
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderList( routeProps ) {
    return (
      <PaymentMethodList 
        { ...routeProps } 
      />
    );
  }

  render() {

    return (
      <Switch>
        <Route
          exact
          path={ '/forma-pagamento' }
          render={ this.renderList } />
        <Route component={ NotFound } />
      </Switch>
    );

  }
}

const mapStateToProps = function ( state ) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user,
  }
}

export default connect( mapStateToProps )( PaymentMethodSection );