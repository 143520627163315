import connect from "./../../api-connector.js";

const connectWithEndpoint = connect((props) => ({
  // speciesAllPromise: "/species/all",
  speciesPromise: "/species/all",
  examTypesPromise: "/exam-types",

  getCustomers: (then, error = () => {}) => ({
    customersPromise: {
      url: "/customers/labs/" + props.userLabId,
      method: "GET",
      then: then,
      catch: error,
    },
  }),

  getAnimalWithId: (id, then, error = () => {}) => ({
    fetchedAnimalPromise: {
      url: `/animals/${id}`,
      method: "GET",
      then: then,
      catch: error,
    },
  }),

  getBreedsWithSpeciesId: (id) => ({
    breedsPromise: `/breeds/species/${id}`,
  }),

  getVetsWithCustomerId: (id, then, error = () => {}) => ({
    vetsPromise: {
      url: `/vets/customers/${id}`,
      method: "GET",
      then: then,
      catch: error,
    },
  }),

  getExamRequestWithId: (id, then, error = () => {}) => ({
    fetchedExamRequestPromise: {
      //objPromise
      url: `/exam-requests/${id}`,
      method: "GET",
      then: then,
      catch: error,
    },
  }),

  getLabExamsWithCustomerIdAndExamTypeId: (
    customerId,
    examTypeId,
    then,
    error = () => {}
  ) => ({
    labExamsPromise: {
      url: `/lab-exams/customer/${customerId}/exam-type/${examTypeId}`,
      method: "GET",
      then: then,
      catch: error,
    },
  }),

  getExamRequestPrintedReport: (id, then, error = () => {}) => ({
    printedExamRequestPromise: {
      url: `/exam-requests/${id}/print`,
      method: "GET",
      then: then,
      catch: error,
      force: true,
    },
  }),

  // getExamsReferenceValuesByAnimalId: (animalId) => ({
  //   ExamsReferenceValuesResponse: {
  //     url: `/reference-values/${animalId}/exams`,
  //   },
  // }),

  emailExamRequest: (examRequestId, then, error = () => {}) => ({
    emailExamRequestPromise: {
      url: `/mail/print-reports/${examRequestId}`,
      method: "POST",
      then: then,
      catch: error,
    },
  }),

  addExamRequest: (obj, then, isPartialSave = false, error = () => {}) => ({
    submittedExamRequestPromise: {
      url: `/exam-requests?isPartialSave=${isPartialSave}`,
      method: "POST",
      body: JSON.stringify(obj),
      force: true,
      then: then,
      catch: error,
    },
  }),

  updateExamRequest: (obj, then, isPartialSave = false, error = () => {}) => ({
    submittedExamRequestPromise: {
      url: `/exam-requests/${obj.id}?isPartialSave=${isPartialSave}`,
      method: "PUT",
      body: JSON.stringify(obj),
      force: true,
      then: then,
      catch: error,
    },
  }),

  addAnimal: (obj, then, error = () => {}) => ({
    createdAnimalPromise: {
      url: `/animals`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true,
    },
  }),

  updateAnimal: (obj, then, error = () => {}) => ({
    updatedAnimalPromise: {
      url: `/animals/` + obj.id,
      method: "PUT",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true,
    },
  }),

  receiveAndAssistRequestExam: (barcode, then, error = () => {}) => ({
    receiveAndAssistPromise: {
      url: `/exam-request/request-sample/code/${barcode}`,
      method: "PUT",
      then: then,
      catch: error,
      force: true,
    },
  }),

  isUniqSample: (code, examRequestId, then, error = () => {}) => ({
    isUniqSampleObj: {
      url: `/is-unique-sample/${examRequestId}/${code}`,
      method: "GET",
      then: then,
      catch: error,
      force: true,
    },
  }),

  printedSingleReport: (id, then, error = () => {}) => ({
    printReportPromise: {
      url: `/print/report/${id}`,
      then: then,
      catch: error,
      force: true,
    },
  }),

  cancelExamRequest: (reportId, then, error = () => {}) => ({
    cancelledExamRequestPromise: {
      url: `/reports/${reportId}/cancel`,
      method: "PUT",
      force: true,
      then: then,
      catch: error,
    },
  }),

  searchAnimals: (filters, page, pageSize, sorted, then, error = () => {}) => ({
    searchedAnimalsPromise: {
      url:
        "/animals?" +
        `id=${filters.id}` +
        `&name=${filters.name}` +
        `&customer=${filters.customer}` +
        `&customerId=${filters.customerId}` +
        `&owner=${filters.owner}` +
        `&species=${filters.species}` +
        `&breed=${filters.breed}` +
        `&breedStatus=${1}` +
        `&specieStatus=${1}` +
        `&externalCode=${filters.externalCode}` +
        `&microchipCode=${filters.microchipCode}` +
        `&cpf=${filters.cpf}` +
        `&phone=${filters.phone}` +
        `&sexId=${filters.sexId}` +
        `&page=${page}` +
        `&totalPerPage=${pageSize}` +
        `&sort=${sorted[0].id}` +
        `&desc=${sorted[0].desc}`,
      method: "GET",
      refreshing: true,
      then: then,
      catch: error,
    },
  }),
}));

export default connectWithEndpoint;
