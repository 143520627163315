import React from "react";

/**
 * Internal
 */
import ReportPanelMaterial from "./../ReportPanelMaterial";
import ReportHeader from "./../ReportHeader";
import NumberInputWithReferencePanel2 from "./../NumberInputWithReferencePanel2";
import ObsPanel from "./../ObsPanel";
import { Col, Row } from "./../../components/grid";

const CorpuscularVolumeReportFormView = ({
  data: {
    refs,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    shouldSendEmail
  },
  methods: {
    $field,
    fieldChange,
    handleChange,
    handleBlur,
    onSubmit,
    handleSendReportEmail,
    cancelReport,
    stopReport
  }
}) => (
  <ReportPanelMaterial
    title="Hematócrito ( Volume Globular )"
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <NumberInputWithReferencePanel2
          title="Resultado"
          value={fields.result}
          fieldName="result"
          shouldDisable={disableAll}
          hasValidation={$validation.result.show}
          validationReason={$validation.result.error.reason}
          handleChange={handleChange}
          handleBlur={handleBlur}
          valueMin={refs.corpuscularVolumeMin}
          valueMax={refs.corpuscularVolumeMax}
          decimalScale={1}
          unit="%"
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ObsPanel
          name="obs"
          value={fields.obs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default CorpuscularVolumeReportFormView;
