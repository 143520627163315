export const mainFont = `
  font-family: arial, sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 16px;
`;

export const secondaryFont = `
  font-family: times;
  font-size: 11px;
  line-height: 20px;
`;

export const printableFont = "times";
