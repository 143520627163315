import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal
 */
import FlatPanelMaterial from './../FlatPanelMaterial';
import FecesMacroscopicFields from './../FecesMacroscopicFields';
import TextAreaMaterial2 from './../TextAreaMaterial2';
import { Col, Row } from './../../components/grid';
import { MultiCheckbox } from "../../components/result-group";

/**
 * External
 */
const propTypes = {
  shouldDisable: PropTypes.bool
};

const defaultProps = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Parasitological fresh feces content
 * 
 */
const ParasitologicalFreshFecesContent = ( {
  title,
  fecesColorOptionId,
  fecesColorOptionIdFieldName,
  fecesColorOptions,
  isFecesColorOptionsLoading,
  fecesColorOptionIdHasValidation,
  fecesColorOptionIdValidationReason,
  fecesSmellOptionId,
  fecesSmellOptionIdFieldName,
  fecesSmellOptions,
  isFecesSmellOptionsLoading,
  fecesSmellOptionIdHasValidation,
  fecesSmellOptionIdValidationReason,
  fecesAspectOptionId,
  fecesAspectOptionIdFieldName,
  fecesAspectOptions,
  isFecesAspectOptionsLoading,
  fecesAspectOptionIdHasValidation,
  fecesAspectOptionIdValidationReason,
  fecesConsistencyOptionId,
  fecesConsistencyOptionIdFieldName,
  fecesConsistencyOptions,
  isFecesConsistencyOptionsLoading,
  fecesConsistencyOptionIdHasValidation,
  fecesConsistencyOptionIdValidationReason,
  macroscopicParasitesFieldName,
  macroscopicParasites,
  macroscopicParasitesHasValidation,
  macroscopicParasitesValidationReason,
  resultFieldName,
  result,
  resultHasValidation,
  resultValidationReason,
  obsFieldName,
  obs,
  shouldDisable,
  handleChange,
  handleBlur,
  parasitologicalFreshFecesResult,
  checkdState,
  checkboxes,
  multiBoxChange,
  handleCheckedResultChange
} ) =>
  <React.Fragment>  
    <FlatPanelMaterial title={ title }>
      <FecesMacroscopicFields
        fecesColorOptionId={ fecesColorOptionId }
        fecesColorOptionIdFieldName={ fecesColorOptionIdFieldName }
        fecesColorOptions={ fecesColorOptions }
        isFecesColorOptionsLoading={ isFecesColorOptionsLoading }
        fecesColorOptionIdHasValidation={ fecesColorOptionIdHasValidation }
        fecesColorOptionIdValidationReason={ fecesColorOptionIdValidationReason }
        fecesSmellOptionId={ fecesSmellOptionId }
        fecesSmellOptionIdFieldName={ fecesSmellOptionIdFieldName }
        fecesSmellOptions={ fecesSmellOptions }
        isFecesSmellOptionsLoading={ isFecesSmellOptionsLoading }
        fecesSmellOptionIdHasValidation={ fecesSmellOptionIdHasValidation }
        fecesSmellOptionIdValidationReason={ fecesSmellOptionIdValidationReason }
        fecesAspectOptionId={ fecesAspectOptionId }
        fecesAspectOptionIdFieldName={ fecesAspectOptionIdFieldName }
        fecesAspectOptions={ fecesAspectOptions }
        isFecesAspectOptionsLoading={ isFecesAspectOptionsLoading }
        fecesAspectOptionIdHasValidation={ fecesAspectOptionIdHasValidation }
        fecesAspectOptionIdValidationReason={ fecesAspectOptionIdValidationReason }
        fecesConsistencyOptionId={ fecesConsistencyOptionId }
        fecesConsistencyOptionIdFieldName={ fecesConsistencyOptionIdFieldName }
        fecesConsistencyOptions={ fecesConsistencyOptions }
        isFecesConsistencyOptionsLoading={ isFecesConsistencyOptionsLoading }
        fecesConsistencyOptionIdHasValidation={ fecesConsistencyOptionIdHasValidation }
        fecesConsistencyOptionIdValidationReason={ fecesConsistencyOptionIdValidationReason }
        macroscopicParasitesFieldName={ macroscopicParasitesFieldName }
        macroscopicParasites={ macroscopicParasites }
        macroscopicParasitesHasValidation={ macroscopicParasitesHasValidation }
        macroscopicParasitesValidationReason={ macroscopicParasitesValidationReason }
        shouldDisable={ shouldDisable }
        handleChange={ handleChange }
        handleBlur={ handleBlur } />
    </FlatPanelMaterial>    
    <FlatPanelMaterial title="Resultado">
      <Row className="mb-10">
        <Col md={ 12 }>

          <TextAreaMaterial2
            title=""
            name={ resultFieldName }
            value={ parasitologicalFreshFecesResult }
            shouldDisable={ shouldDisable }
            handleChange={ (name, value) => handleCheckedResultChange(value)}
            handleBlur={ handleBlur }
            hasValidation={ resultHasValidation }
            validationReason={ resultValidationReason } 
            />
            <MultiCheckbox
            itens={checkboxes}
            handleChange={multiBoxChange}
            state={checkdState}
            />
        </Col>
      </Row>
    </FlatPanelMaterial>
    <FlatPanelMaterial title="Obs">
      <Row className="mb-10">
        <Col md={ 12 }>
          <TextAreaMaterial2
            title=""
            name={ obsFieldName }
            value={ obs }
            shouldDisable={ shouldDisable }
            handleChange={ handleChange }
            handleBlur={ handleBlur } />
        </Col>
      </Row>
    </FlatPanelMaterial>
  </React.Fragment>

ParasitologicalFreshFecesContent.defaultProps = defaultProps;
ParasitologicalFreshFecesContent.propTypes = propTypes;

export default ParasitologicalFreshFecesContent;