import React from 'react';
import { Row } from './../../components/grid';
import InputMaterial from './../InputMaterial';
import ButtonDefaultMaterial from './../ButtonDefaultMaterial';
import { Modal } from 'react-bootstrap';

const View = ( { 
  data,
  methods
} ) => {
  return ( 
    <Modal show={data.show} onHide={methods.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Alterar Senha</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <InputMaterial
            id="currentPassword"
            name="currentPassword"
            type='password'
            title="Senha Atual"
            md={12}
            {...methods.$field('currentPassword', (event) => {
              methods.fieldChange('currentPassword', event.target.value);
            })}
            hasValidation={
              data.$validation &&
              data.$validation.currentPassword.show &&
              !data.$validation.currentPassword.isValid
            }
            validationReason={
              data.$validation &&
              data.$validation.currentPassword.error.reason
            }
            isValid={
              data.$validation &&
              data.$validation.currentPassword.isValid
            }
          />
          <InputMaterial
            id="newPassword"
            name="newPassword"
            type='password'
            title="Nova Senha"
            md={12}
            {...methods.$field('newPassword', (event) => {
              methods.fieldChange('newPassword', event.target.value);
            })}
            hasValidation={
              data.$validation &&
              data.$validation.newPassword.show &&
              !data.$validation.newPassword.isValid
            }
            validationReason={
              data.$validation &&
              data.$validation.newPassword.error.reason
            }
            isValid={
              data.$validation &&
              data.$validation.newPassword.isValid
            }
          />
          <InputMaterial
            id="reNewPassword"
            name="reNewPassword"
            title="Repitir Nova Senha"
            type='password'
            md={12}
            {...methods.$field('reNewPassword', (event) => {
              methods.fieldChange('reNewPassword', event.target.value);
            })}
            hasValidation={
              data.$validation &&
              data.$validation.reNewPassword.show &&
              !data.$validation.reNewPassword.isValid
            }
            validationReason={
              data.$validation &&
              data.$validation.reNewPassword.error.reason
            }
            isValid={
              data.$validation &&
              data.$validation.reNewPassword.isValid
            }
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefaultMaterial
          type="default"
          title="Alterar Senha"
          onClick={methods.onSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default View;