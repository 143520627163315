import React from 'react';
import { Grid as BsGrid } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * @author Victor Heringer
 *
 * Container for grid
 * 
 * @prop {String} componentClass
 * @prop {String} mdClass
 * @prop {String} className
 * @prop {Bool} fluid
 * @prop {Element} children
 */
const Container = ({ 
  componentClass, 
  mdClass, 
  fluid, 
  className, 
  children 
}) => {
  return (<React.Fragment>
    <BsGrid
      componentClass={componentClass}
      bsClass={mdClass}
      className={className}
      fluid={fluid}
    >
      {children}
    </BsGrid>
  </React.Fragment>);
}

Container.defaultProps = {
  mdClass: 'container',
  componentClass: 'div',
  fluid: false
};

Container.propTypes = {
  componentClass: PropTypes.string,
  mdClass: PropTypes.string,
  className: PropTypes.string,
  fluid: PropTypes.bool
}

export default Container;