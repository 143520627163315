import React from 'react';

/** External */
import { Alert } from 'react-bootstrap';

/** Internal */
import PanelMaterial from './../PanelMaterial';
import PanelMaterialBody from '../PanelMaterialBody';

/** Assets */
import styles from './index.module.css';

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents an In Construction screen
 */
const NotFound = () =>
  <PanelMaterial className={ 'pb-15' } title="Em Construção">
    <PanelMaterialBody>
      <Alert
        className={ styles.notFound }
        bsStyle="danger">
        { 'Página não encontrada' }
      </Alert>
    </PanelMaterialBody>
  </PanelMaterial>;

export default NotFound;