import React, { Component } from "react";

/** Internal */
import PaymentMethodListView from "./../PaymentMethodListView";

/** External */

import refetchConnect from "./../../api-connector";
import { connect as reduxConnect } from "react-redux";
import { FunctionUtil } from "./../../useful";
import FadeIn from "react-fade-in";
import debounce from "lodash/debounce";
import { Redirect } from "react-router";
import update from "immutability-helper";

class PaymentMethodList extends Component {
  /** Constructor */
  constructor(props) {
    super(props);
    this.filter = this.filter.bind(this);
    this.onTableDoubleClick = this.onTableDoubleClick.bind(this);
    this.state = {
      pages: 1,
      sorted: null,
      shouldRedirect: false,
      redirectToId: 0,
      showPaymentMethodModal: false,
      fields: {
        name: "",
        id: 0
      }
    };
  }

  onTableDoubleClick(currentId, state, rowInfo, column) {
    this.setState(
      {
        fields: {
          id: rowInfo.original.id,
          name: rowInfo.original.name
        }
      },
      this.handleClosePaymentMethodModal
    );
  }

  /**
   * @author Victor Heringer
   *
   * Filter PaymentMethods table
   *
   * @param {Object} state
   * @param {Object} instance
   */
  filter(state, instance) {
    const filters = { id: "", name: "" };

    for (let data in state.filtered) {
      filters[state.filtered[data].id] = state.filtered[data].value;
    }

    const then = (data, xhr) => {
      this.setState({
        pages: data.last_page
      });
    };

    this.props.search(filters, state.page, state.sorted, then);
  }

  handleClosePaymentMethodModal = (event, refresh) => {
    if (refresh) {
      this.setState({
        showPaymentMethodModal: !this.state.showPaymentMethodModal,
        fields: {
          name: "",
          id: 0
        }
      });
    } else {
      this.setState({
        showPaymentMethodModal: !this.state.showPaymentMethodModal
      });
    }
  };

  submitData = data => {
    const then = () => {
      this.props.reload();
      this.handleClosePaymentMethodModal();
    };

    if (data.id == 0) {
      this.props.addPaymentMethod(data, then);
    } else {
      this.props.updatePaymentMethod(data, then);
    }
  };

  /**
   * @author Victor Heringer
   *
   * Changes the given field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChange = (field, value) => {
    this.setState(state =>
      update(state, {
        fields: {
          [field]: { $set: value }
        }
      })
    );
  };

  render() {
    const { user, objects, access_profile } = this.props;

    if (this.state.shouldRedirect) {
      const id = this.state.redirectToId;
      return <Redirect to={`//${id}`} />;
    }

    const data = {
      showPaymentMethodModal: this.state.showPaymentMethodModal,
      fields: this.state.fields,
      isFetching: objects.pending
    };

    const methods = {
      handleClosePaymentMethodModal: this.handleClosePaymentMethodModal,
      submitData: this.submitData,
      fieldChange: this.fieldChange
    };

    return (
      <FadeIn>
        <PaymentMethodListView
          data={data}
          methods={methods}
          user={user}
          objects={objects}
          filter={debounce(this.filter.bind(this), 500)}
          accessProfile={access_profile}
          pages={this.state.pages}
          onTableDoubleClick={this.onTableDoubleClick}
        />
      </FadeIn>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user
  };
};

const connectWithEndpoint = refetchConnect(props => ({
  objects: `/payment-methods`,
  reload: () => ({
    objects: {
      url: `/payment-methods`,
      method: "GET",
      force: true
    }
  }),
  search: (filters, page, sorted, then) => ({
    objects: {
      url: `/payment-methods?id=${filters.id}&name=${filters.name}&page=${page}&sort=${sorted[0].id}&desc=${sorted[0].desc}`,
      method: "GET",
      then: then,
      force: true
    }
  }),
  addPaymentMethod: (obj, then, error) => ({
    examPlaceResponse: {
      url: `/payment-method`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  }),
  updatePaymentMethod: (obj, then, error) => ({
    examPlaceResponse: {
      url: `/payment-methods/${obj.id}`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  })
}));

const connectWithRedux = reduxConnect(mapStateToProps);

export default FunctionUtil.compose(
  connectWithRedux,
  connectWithEndpoint
)(PaymentMethodList);
