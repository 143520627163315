import React, { Component } from "react";

/** Internal */
import CustomerListView from "./../CustomerListView";

/** External */
import connect from "../../api-connector";
import { connect as reduxConnect } from "react-redux";
import { FunctionUtil } from "./../../useful";
import FadeIn from "react-fade-in";
import debounce from "lodash/debounce";
import { Redirect } from "react-router";
import { ACCESS_PROFILES } from "../../assets/js/Consts";

class CustomerList extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.filter = this.filter.bind(this);
    this.onTableDoubleClick = this.onTableDoubleClick.bind(this);
    this.state = {
      pages: 1,
      sorted: null,
      shouldRedirect: false,
      redirectToId: 0
    };
  }

  onTableDoubleClick(currentId, state, rowInfo, column) {
    this.setState({ shouldRedirect: true, redirectToId: rowInfo.original.id });
  }

  /**
   * @author Victor Heringer
   *
   * Check if should disable all fields
   *
   * @return {Boolean}
   */
  shouldDisableAll() {
    const haveNoAccess =
      this.props.access_profile == ACCESS_PROFILES.CLINIC_USER ||
      this.props.access_profile == ACCESS_PROFILES.UNIT_USER;
    const pending =
      this.props.customerResponse && this.props.customerResponse.pending;
    return haveNoAccess || pending;
  }

  /**
   * @author Victor Heringer
   *
   * Filter unity table
   *
   * @param {Object} state
   * @param {Object} instance
   */
  filter(state, instance) {
    const filters = { id: "", name: "", type: "" };

    for (let data in state.filtered) {
      filters[state.filtered[data].id] = state.filtered[data].value;
    }

    const then = (data, xhr) => {
      this.setState({ pages: data.last_page });
    };

    this.props.customersSearch(filters, state.page, state.sorted, then);
  }

  render() {
    const { user, customers, access_profile } = this.props;

    if (this.state.shouldRedirect) {
      const id = this.state.redirectToId;
      return <Redirect to={`/clientes/${id}`} />;
    }

    return (
      <FadeIn>
        <CustomerListView
          user={user}
          objects={customers}
          filter={debounce(this.filter.bind(this), 500)}
          accessProfile={access_profile}
          pages={this.state.pages}
          onTableDoubleClick={this.onTableDoubleClick}
          shouldDisableAll={this.shouldDisableAll()}
        />
      </FadeIn>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user
  };
};

const connectWithEndpoint = connect(props => ({
  customers: `/customers`,
  customersSearch: (filters, page, sorted, then) => ({
    customers: {
      url: `/customers?id=${filters.id}&name=${filters.name}&type=${filters.type}&page=${page}&sort=${sorted[0].id}&desc=${sorted[0].desc}`,
      method: "GET",
      then: then,
      force: true
    }
  })
}));

const connectWithRedux = reduxConnect(mapStateToProps);

export default FunctionUtil.compose(
  connectWithRedux,
  connectWithEndpoint
)(CustomerList);
