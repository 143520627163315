const reportHeaderFactory = function reportHeaderFactory(obj) {
  let vets = {
    options: [],
    placeholder: "Carregando..",
  };

  let requestExamStates = {
    options: [],
    placeholder: "Carregando..",
  };

  let requestSampleNames = [];

  if (obj) {
    vets.options = obj.vets ? obj.vets : [];
    vets.placeholder = "Selecione";

    requestExamStates.options = obj.requestExamStates
      ? obj.requestExamStates
      : [];

    requestSampleNames = obj.requestSamples.map(
      (requestSample) => requestSample.name
    );

    requestExamStates.placeholder = "Selecione";

    return {
      animal: obj.animal || "",
      statuses: obj.statuses || [],
      vets: vets,
      owner: obj.owner || "",
      species: obj.species || "",
      breed: obj.breed || "",
      age: obj.age || "",
      sex: obj.sex || "",
      requestSampleNames: requestSampleNames || "",
      deadline: obj.deadline || "",
      endDate: obj.endDate || "",
      obs: obj.obs || "",
      requestExamStates: requestExamStates,
      animalAgeOption: obj.animalAgeOption || "",
    };
  }

  return {
    animal: "",
    statuses: [],
    vets: vets,
    owner: "",
    species: "",
    breed: "",
    age: "",
    sex: "",
    requestSampleNames: requestSampleNames,
    deadline: "",
    endDate: "",
    obs: "",
    requestExamStates: requestExamStates,
    animalAgeOption: "",
  };
};

export default reportHeaderFactory;
