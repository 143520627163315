import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import LeukogramPanel from "./../LeukogramPanel";
import LeukogramDiagnosisPanel from "./../LeukogramDiagnosisPanel";
import GlobalLeukogramPanel from "./../GlobalLeukogramPanel";
import HemogramCounter from "./../HemogramCounter";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";

const LeukogramReportFormReportFormView = ({
  data: {
    refs,
    total,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    shouldSendEmail
  },
  methods: {
    $field,
    fieldChange,
    onSubmit,
    calcAbsolute,
    resetLeukogram,
    onClickCounter,
    leukogramDiagnosis,
    toggleIntenseLeucopeny,
    finalGlobalLeukogram,
    handleKeyPress,
    handleFinishedPlaying,
    closeCounter,
    handleSendReportEmail,
    cancelReport,
    stopReport
  }
}) => {
  return (
    <ReportPanelMaterial
      title="Leucograma"
      backLink={backLink}
      shouldDisable={disableAll}
      isUpdate={isUpdate}
      isRequesting={submitPromise && submitPromise.pending}
      handleSubmit={onSubmit}
      handleSendReportEmail={handleSendReportEmail}
      shouldSendEmail={shouldSendEmail}
      cancelReport={cancelReport}
      stopReport={stopReport}
    >
      <Row>
        <Col md={12}>
          <ReportHeader
            data={reportHeaderData}
            fields={fields}
            fieldChange={fieldChange}
            $field={$field}
            $validation={$validation}
          />
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <GlobalLeukogramPanel
            globalLeukometry={fields.globalLeukometry}
            metarrubrycites={fields.metarrubrycites}
            isIntenseLeucopeny={fields.isIntenseLeucopeny}
            disableAll={disableAll}
            $validation={$validation}
            refs={refs}
            fieldChange={fieldChange}
            $field={$field}
            toggleIntenseLeucopeny={toggleIntenseLeucopeny}
            finalGlobalLeukogram={finalGlobalLeukogram}
          />
        </Col>
        <Col md={7}>
          <LeukogramPanel
            title={"Leucometria Específica"}
            isIntenseLeucopeny={fields.isIntenseLeucopeny}
            relativeBasophils={fields.relativeBasophils}
            relativeEosinophils={fields.relativeEosinophils}
            relativeMyelocytes={fields.relativeMyelocytes}
            relativeMetamyelocytes={fields.relativeMetamyelocytes}
            relativeBands={fields.relativeBands}
            relativeSegs={fields.relativeSegs}
            relativeLymphocytes={fields.relativeLymphocytes}
            relativeMonocytes={fields.relativeMonocytes}
            relativeHeterophils={fields.relativeHeterophils}
            disableAll={disableAll}
            $validation={$validation}
            refs={refs}
            total={total}
            fieldChange={fieldChange}
            $field={$field}
            calcAbsolute={calcAbsolute}
            resetLeukogram={resetLeukogram}
            onClickCounter={onClickCounter}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <LeukogramDiagnosisPanel
            obs={fields.leukogramObs}
            diagnosisFn={leukogramDiagnosis}
            shouldDisable={disableAll}
            $validation={$validation}
            fieldChange={fieldChange}
            $field={$field}
          />
        </Col>
      </Row>
      <HemogramCounter
        open={fields.isCounterOpen}
        total={total}
        fields={fields}
        handleKeyPress={handleKeyPress}
        blockSound={fields.shouldBlockCounterSound}
        handleFinishedPlaying={handleFinishedPlaying}
        handleClose={closeCounter}
        hasHeterophils={refs.hasHeterophils}
      />
    </ReportPanelMaterial>
  );
};

export default LeukogramReportFormReportFormView;
