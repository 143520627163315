import React from "react";

/** Internal */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import PanelMaterialBottom from "./../PanelMaterialBottom";
import ButtonBackMaterial from "./../ButtonBackMaterial";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import SelectMaterial from "./../SelectMaterial";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";
import {
  required,
  minLength,
  greaterThan,
  lazyIsUniqueBreed
} from "../../assets/js/validationFunctions";
import { STATUS } from "./../../assets/js/Consts";

/** External */
import { validated } from "react-custom-validation";
import { Link } from "react-router-dom";
import { Row } from "./../../components/grid";

const BreedFormView = ({
  isUpdate,
  species,
  fields,
  $field,
  $submit,
  fieldChange,
  $validation,
  disableAll,
  handleSubmit,
  invalidNotification
}) => {
  const saveBtnTitle = "Salvar";

  const onClickSave = function(handleSubmit, invalidNotification) {
    return e => {
      e.preventDefault();
      $submit(handleSubmit, invalidNotification);
    };
  };

  return (
    <React.Fragment>
      <PanelMaterial>
        <PanelMaterialTop title="Nova Raça">
          <Link to="/racas">
            <ButtonBackMaterial
              type="default"
              title="Voltar"
              position="top"
              disabled={false}
            />
          </Link>
        </PanelMaterialTop>
        <PanelMaterialBody>
          <Row>
            <LVInputTextUpper
              id="name"
              title="Nome"
              required
              name="name"
              md={4}
              value={fields.name || ""}
              disabled={disableAll}
              {...$field("name", event => {
                fieldChange("name", event.target.value);
              })}
              hasValidation={$validation.name.show && !$validation.name.isValid}
              validationReason={$validation.name.error.reason}
            />
            <SelectMaterial
              title="Espécie"
              required
              md={4}
              name="speciesId"
              options={species && species.fulfilled ? species.value.data : []}
              value={fields.speciesId}
              searchable={true}
              disabled={disableAll}
              placeholder={species.pending ? "Carregando.." : "Selecione"}
              {...$field("speciesId", value => {
                fieldChange("speciesId", value);
              })}
              isValid={$validation.speciesId.isValid}
              hasValidation={
                $validation.speciesId.show && !$validation.speciesId.isValid
              }
              validationReason={$validation.speciesId.error.reason}
            />

            <SelectMaterial
              title="Estado"
              md={4}
              name="status"
              options={STATUS}
              value={fields.status}
              searchable={true}
              disabled={disableAll}
              {...$field("status", value => {
                fieldChange("status", value);
              })}
            />
          </Row>
        </PanelMaterialBody>
        <PanelMaterialBottom>
          <Link to="/racas">
            <ButtonBackMaterial
              type="default"
              title="Voltar"
              position="bottom"
              disabled={false}
            />
          </Link>
          <ButtonSaveMaterial
            type="highlight"
            title={saveBtnTitle}
            right={true}
            onClick={onClickSave(handleSubmit, invalidNotification)}
            disabled={disableAll}
            requesting={false}
            requestingTitle={isUpdate ? "Atualizando.." : "Salvando.."}
          />
        </PanelMaterialBottom>
      </PanelMaterial>
    </React.Fragment>
  );
};

const withValidation = validated(props => {
  const { name, speciesId } = props.fields;
  const fields = ["name", "speciesId"];
  const nameRules = props.isUpdate
    ? [
        [required, name, "Informe o nome"],
        [minLength, name, 2, "Mínimo de 2 caracteres"]
      ]
    : [
        [required, name, "Informe o nome"],
        [minLength, name, 2, "Mínimo de 2 caracteres"],
        [lazyIsUniqueBreed, name, speciesId, "espécie já cadastrada"]
      ];

  const validations = {
    name: {
      rules:  nameRules
    },
    speciesId: {
      rules: [[greaterThan, speciesId, 0, "Selecione a especie"]]
    }
  };

  return { fields, validations };
});

export default withValidation(BreedFormView);
