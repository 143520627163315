import React, { useEffect, useMemo, useCallback } from "react";
import * as LifeVet from "./../../../../../components/index";
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
} from "react-table-v7";
import { debounce } from "lodash";
import { Table, Pager } from "react-bootstrap";
import { AnimalConversorForList } from "./../../../../../services/request-conversor/old-to-new/index";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faMinus,
} from "@fortawesome/fontawesome-free-solid/";
import {
  Animal,
  AnimalFilters,
  sexOptions,
} from "./../../../../../types/index";
import { useFetch, ROUTES } from "../../../../../hooks/use-fetch";

const renderOrderingArrow = (isSorted: boolean, desc: boolean) => {
  if (isSorted) {
    return desc ? (
      <FontAwesomeIcon
        className="select-custom-arrow"
        size="lg"
        icon={faAngleDown}
      />
    ) : (
      <FontAwesomeIcon
        className="select-custom-arrow"
        size="lg"
        icon={faAngleUp}
      />
    );
  }
  return (
    <FontAwesomeIcon className="select-custom-arrow" size="lg" icon={faMinus} />
  );
};

const TOTAL_PER_PAGE = 7;

export const AnimalsTable = ({
  nameSearchInputRef,
  loggedUserIsFromClinic,
  labId,
  customerId,
  selectAnimal,
  setSelectedAnimal,
  setAnimalNameFilter,
}: {
  nameSearchInputRef: any;
  loggedUserIsFromClinic: boolean;
  labId: number;
  customerId: number;
  setAnimalNameFilter: Function;
  selectAnimal: (animal: Animal) => void;
  setSelectedAnimal: (animalIsSelected: boolean) => void;
}) => {
  const defaultTextFilter =
    (ref: any) =>
    ({ column }: { column: any }) => {
      return (
        <LifeVet.Input
          value={column.filterValue}
          onChange={(e: React.ChangeEvent<any>) =>
            column.setFilter(e.target.value || undefined)
          }
          onBlur={null}
          innerRef={ref}
          name={""}
          isViewOnly={false}
          errorMessage={""}
          uppercase
        />
      );
    };

  const sexFilter = ({ column }: { column: any }) => {
    return (
      <LifeVet.Select
        value={column.filterValue}
        options={sexOptions}
        theValueIsAnId={true}
        onChange={(option: any) => {
          column.setFilter((option && option.id) || undefined);
        }}
        searchable={false}
        clearable={true}
        onKeyDown
      ></LifeVet.Select>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: "id",
        id: "id",
        Filter: defaultTextFilter(null),
      },
      {
        Header: "Nome",
        accessor: "name",
        id: "name",
        Filter: defaultTextFilter(nameSearchInputRef),
        disableSortBy: true,
      },
      // {
      //   Header: "Cliente",
      //   accessor: "customer.name",
      //   id: "customer",
      //   Filter: defaultTextFilter(null),
      //   disableSortBy: true,
      // },
      {
        Header: "Proprietário",
        accessor: "owner",
        id: "owner",
        Filter: defaultTextFilter(null),
        disableSortBy: true,
      },
      {
        Header: "Espécie",
        accessor: "specie.name",
        id: "species",
        Filter: defaultTextFilter(null),
        disableSortBy: true,
      },
      {
        Header: "Raça",
        accessor: "breed.name",
        id: "breed",
        Filter: defaultTextFilter(null),
        disableSortBy: true,
      },
      {
        Header: "Sexo",
        accessor: "sexName",
        id: "sexId",
        Filter: sexFilter,
        disableSortBy: true,
      },
    ],
    []
  );

  const fetchedAnimals = useFetch();

  const debounceFetchData = useCallback(
    debounce(
      (
        filters: AnimalFilters,
        sortings: any,
        pageIndex: number,
        pageSize: number
      ) => {
        fetchedAnimals.fetch(
          ROUTES.getAnimals(filters, sortings, {
            totalPerPage: pageSize,
            page: pageIndex,
          })
        );
      },
      400
    ),
    []
  );

  const data = useMemo(() => {
    if (fetchedAnimals.fulfilled || fetchedAnimals.data) {
      return fetchedAnimals.data.map((animal: any) =>
        AnimalConversorForList(animal)
      );
    }
    return [];
  }, [fetchedAnimals.fulfilled]);

  const lastPage = fetchedAnimals.data && fetchedAnimals.data.last_page;

  const table = useTable(
    {
      initialState: {
        pageSize: TOTAL_PER_PAGE,
        pageIndex: 0,
      },
      columns: columns,
      data: data,
      autoResetFilters: false, // don't reset filters when passed new data to the table
      autoResetSortBy: false, // don't reset sorting when passed new data to the table
      autoResetPage: false, // don't reset pagination when passed new data to the table
      disableMultiSort: true,
      manualSortBy: true, // allow server-side sorting
      manualFilters: true, // allow server-side filtering
      manualPagination: true, // allow server-side pagination

      pageCount: -1, // -1 means we don't know how many pages there are in total
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const {
    state: { filters, sortBy, pageIndex, pageSize },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    previousPage,
    nextPage,
    gotoPage,
    canNextPage,
    selectedFlatRows,
    toggleRowSelected,
  } = table;

  const findAnimalFilter = (filters: any) => {
    const nameFilter = filters.find((filter: any) => filter.id === "name");
    return nameFilter ? nameFilter.value : null;
  };
  useEffect(() => {
    const selectedFilters: AnimalFilters = filters.reduce(
      (acc: any, option: any) => {
        acc[option.id] = option.value;
        return acc;
      },
      {}
    );
    selectedFilters["customerId"] = customerId;

    const sortings = sortBy.reduce((acc: any, option: any) => {
      acc["asc"] = !option.desc;
      acc["sort"] = option.id;
      return acc;
    }, {});
    const animalNameFilter = findAnimalFilter(filters);
    animalNameFilter && setAnimalNameFilter(animalNameFilter);
    debounceFetchData(selectedFilters, sortings, pageIndex, pageSize);
  }, [filters, sortBy, pageIndex]);

  useEffect(() => gotoPage(0), [filters]);

  return (
    <>
      <LifeVet.Loader loading={fetchedAnimals.pending}>
        <Table
          {...getTableProps()}
          condensed
          bordered
          hover
          striped
          className={"text-center"}
        >
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}{" "}
                    {renderOrderingArrow(column.isSorted, column.isSortedDesc)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <tr>
              {headerGroups.map((headerGroup: any) =>
                headerGroup.headers.map((column: any) => (
                  <td>{column.render("Filter")}</td>
                ))
              )}
            </tr>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps(row.getToggleRowSelectedProps())}
                  onClick={() => {
                    const selectedRow = selectedFlatRows.pop();
                    const rowWasSelected = !!selectedRow;
                    const selectedRowWasClicked =
                      rowWasSelected && row.index === selectedRow.index;
                    const animal = row.original;
                    if (selectedRowWasClicked) {
                      selectAnimal(animal);
                    } else if (rowWasSelected) {
                      toggleRowSelected(selectedRow.index, false);
                      row.toggleRowSelected();
                      setSelectedAnimal(animal);
                    } else {
                      row.toggleRowSelected();
                      setSelectedAnimal(animal);
                    }
                  }}
                  style={
                    row.isSelected
                      ? { cursor: "pointer", backgroundColor: "#B7DBFF" }
                      : { cursor: "pointer" }
                  }
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        style={{ minWidth: "120px" }}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </LifeVet.Loader>
      <Pager>
        <Pager.Item
          onClick={fetchedAnimals.fulfilled ? previousPage : undefined}
          href="#"
        >
          &larr; Anterior
        </Pager.Item>{" "}
        {data.length ? `${pageIndex + 1} de ${lastPage}` : `0 de 0`}
        <Pager.Item
          onClick={fetchedAnimals.fulfilled ? nextPage : undefined}
          href="#"
        >
          Próxima &rarr;
        </Pager.Item>
      </Pager>
    </>
  );
};
