import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
* Internal
*/
import TextAreaMaterial from './../TextAreaMaterial';
import FlatPanelMaterial from './../FlatPanelMaterial';

/**
* External
*/
import { Col } from 'react-bootstrap';

/**
 * Assets
 */

const propTypes = {
  title: PropTypes.string,
  fieldTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  hasValidation: PropTypes.bool,
  validationReason: PropTypes.string,
  shouldDisable: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func
  
};
const defaultProps = {
  title: '',
  fieldTitle: '',
  hasValidation: false,
  shouldDisable: false
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a TextAreaPanel form
 * 
 */
const TextAreaPanel = ( {
  title,
  fieldTitle,
  name,
  value,
  hasValidation,
  validationReason,
  shouldDisable,
  handleChange,
  handleBlur
  } ) =>
  <FlatPanelMaterial title={ title } >
    <Col md={ 12 }>
      <TextAreaMaterial
        title={ fieldTitle }
        fieldTitle={ fieldTitle }
        name={ name }
        value={ value }
        disabled={ shouldDisable }
        onChange={ handleChange }
        onBlur={ handleBlur }
        hasValidation={ hasValidation }
        validationReason={ validationReason }
        />
    </Col>
  </FlatPanelMaterial>

TextAreaPanel.propTypes = propTypes;
TextAreaPanel.defaultProps = defaultProps;

export default TextAreaPanel;