import React, { useState } from "react";
import { connect as reduxConnect } from "react-redux";

import apiConnect from "./../../../api-connector";
import promiseUtil from "./../../../useful/promise-util";
import * as DateUtil from "./../../../useful/date-util";
import { mapStateToProps, mapDispatchToProps } from "./../../../redux/maps";
import requests from "./../requests";
import FormState from "./form-state";
import InvoiceProcessingView from "./view.js";
import {
  FETCH_MESSAGE,
  PROCESS_INVOICES_MESSAGE,
  ERROR_MESSAGE,
} from "./../../../consts/messages";

const InvoiceProcessing = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  successNotification,
  infoNotification,
  errorNotification,
  notifications,
  processInvoices,
  previewInvoice,
  getInvoice,
  printInvoice,
  mailInvoice,
  searched,
  processed,
  previewed,
  fetched,
  regions,
  customers,
}) => {
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);
  const [invoicesWereProcessed, setInvoicesWereProcessed] = useState(false);
  const [invoicesWereSearched, setInvoicesWereSearched] = useState(false);
  const [selectedCustomersIds, setSelectedCustomersIds] = useState([]);
  const [printableInvoiceUrl, setPrintableInvoiceUrl] = useState(false);

  let potentialInvoices = promiseUtil.extractValue(searched, []);
  potentialInvoices = invoicesWereSearched ? potentialInvoices : [];

  let processedInvoices = promiseUtil.extractValue(processed, []);
  processedInvoices = invoicesWereProcessed ? processedInvoices : [];

  const previewedInvoice = promiseUtil.extractValue(previewed, null);

  const fetchedInvoice = promiseUtil.extractValue(fetched, null);

  regions = promiseUtil.extractValue(regions, []);

  customers = promiseUtil.extractValue(customers, null);

  let disableAll =
    (searched && searched.pending) ||
    (processed && processed.pending) ||
    (regions && regions.pending) ||
    (previewed && previewed.pending) ||
    (fetched && fetched.pending) ||
    (customers && customers.pending) ||
    processedInvoices.length > 0;

  let disableSelectionOfAllCustomers =
    potentialInvoices.length == 0 || disableAll;

  const handleClosePrintableInvoiceModal = () => {
    setPrintableInvoiceUrl(false);
  };

  const toggleDetailsModal = (e) => {
    e.preventDefault();
    setDetailsModalIsOpen(!detailsModalIsOpen);
  };

  const togglePreviewModal = (e) => {
    e && e.preventDefault();
    setPreviewModalIsOpen(!previewModalIsOpen);
  };

  const selectAllCustomers = () => {
    let allCustomers = potentialInvoices.map((invoice) => {
      return invoice.customer_id;
    });
    setSelectedCustomersIds(allCustomers);
  };

  const toggleCustomerSelection = (
    customerId,
    selectedCustomersIds,
    setSelectedCustomersIds,
    isCustomerSelectedFunc
  ) => {
    let selected = isCustomerSelectedFunc(customerId, selectedCustomersIds);
    selected
      ? removeCustomerFromSelection(
          customerId,
          selectedCustomersIds,
          setSelectedCustomersIds
        )
      : addCustomerToSelection(
          customerId,
          selectedCustomersIds,
          setSelectedCustomersIds
        );
  };

  const removeCustomerFromSelection = (
    customerId,
    selectedCustomersIds,
    setSelectedCustomersIds
  ) => {
    let newSelectedCustomersIds = selectedCustomersIds.filter(
      (selectedCustomer) => {
        return selectedCustomer != customerId;
      }
    );

    setSelectedCustomersIds(newSelectedCustomersIds);
  };

  const addCustomerToSelection = (
    customerId,
    selectedCustomersIds,
    setSelectedCustomersIds
  ) => {
    let newSelectedCustomersIds = [...selectedCustomersIds];
    newSelectedCustomersIds.push(customerId);
    setSelectedCustomersIds(newSelectedCustomersIds);
  };

  const isCustomerSelected = (customer, selectedCustomersIds) => {
    let selected = false;
    selectedCustomersIds.forEach((customer_id) => {
      customer == customer_id && (selected = true);
    });
    return selected;
  };

  const handleInvoiceGeneration = (
    selectedCustomersIds,
    potentialInvoices,
    processInvoices,
    formValues,
    setInvoicesWereProcessed
  ) => {
    let invoicesToProcess = potentialInvoices.filter((invoice) => {
      return selectedCustomersIds.includes(invoice.customer_id);
    });

    invoicesToProcess = invoicesToProcess.map((invoice) => {
      invoice.start_date = formValues.start_date;
      invoice.end_date = formValues.end_date;
      return invoice;
    });

    const onSuccess = () => {
      setInvoicesWereProcessed(true);
      successNotification(PROCESS_INVOICES_MESSAGE);
    };

    const onError = () => {
      errorNotification(ERROR_MESSAGE);
    };

    processInvoices(invoicesToProcess, onSuccess, onError);
  };

  const handleInvoicePreview = (
    customerId,
    potentialInvoices,
    previewInvoice,
    formValues,
    setPreviewModalIsOpen
  ) => {
    let invoice = null;

    potentialInvoices.forEach((potentialInvoice) => {
      if (potentialInvoice.customer_id == customerId) {
        invoice = potentialInvoice;
        invoice.start_date = formValues.start_date;
        invoice.end_date = formValues.end_date;
      }
    });
    const onSuccess = () => {
      setPreviewModalIsOpen(true);
      successNotification(FETCH_MESSAGE);
    };

    const onError = () => {
      errorNotification(ERROR_MESSAGE);
    };

    previewInvoice(invoice, onSuccess, onError);
  };

  const handleInvoicePrinting = (invoiceId) => {
    const onSuccess = (values) => {
      const API_URL = process.env.REACT_APP_PROXY;
      const S3_URL = process.env.REACT_APP_S3_URL;

      let root = S3_URL
        ? S3_URL + "/"
        : API_URL + "/storage/printed-invoices/";

      setPrintableInvoiceUrl(root + values.file);
      handleSubmit();
    };

    const onError = () => {
      errorNotification(ERROR_MESSAGE);
    };

    infoNotification({
      title: "Solicitação em processo.",
      message: "Estamos gerando sua fatura, aguarde um momento!",
      level: "info",
      position: "tr",
      autoDismiss: 5,
    });

    printInvoice(invoiceId, onSuccess, onError);
  };

  const handleInvoiceMailing = (invoiceId) => {
    const onSuccess = (values) => {};

    const onError = () => {
      errorNotification(ERROR_MESSAGE);
    };

    infoNotification({
      title: "Solicitação em andamento.",
      message: "Em até 3 minutos o cliente receberá a fatura.",
      level: "info",
      position: "tr",
      autoDismiss: 5,
    });

    mailInvoice(invoiceId, onSuccess, onError);
  };

  const onPreviewTableDoubleClick = (
    id,
    potentialInvoices,
    previewInvoice,
    formValues,
    setPreviewModalIsOpen
  ) => {
    handleInvoicePreview(
      id,
      potentialInvoices,
      previewInvoice,
      formValues,
      setPreviewModalIsOpen
    );
  };

  const onDetailsTableDoubleClick = (id, setDetailsModalIsOpen) => {
    const onSuccess = () => {
      setDetailsModalIsOpen(true);
      successNotification(FETCH_MESSAGE);
    };

    const onError = () => {
      errorNotification(ERROR_MESSAGE);
    };

    getInvoice(id, onSuccess, onError);
  };

  const handleMonthAndYearChange = (event, setFieldValue, handleChange) => {
    const year = event.target.value.substr(0, 4);
    const month = event.target.value.substr(5, 2);
    setFieldValue(
      "start_date",
      DateUtil.getFirstDayOfTheMonthAsString(month, year)
    );
    setFieldValue(
      "end_date",
      DateUtil.getLastDayOfTheMonthAsString(month, year)
    );
    handleChange(event);
  };

  const handleReturnToSearch = () => {
    setInvoicesWereProcessed(false);
    setInvoicesWereSearched(false);
    setSelectedCustomersIds([]);
  };

  return (
    <InvoiceProcessingView
      previewModalIsOpen={previewModalIsOpen}
      detailsModalIsOpen={detailsModalIsOpen}
      invoicesWereProcessed={invoicesWereProcessed}
      togglePreviewModal={togglePreviewModal}
      toggleDetailsModal={toggleDetailsModal}
      setDetailsModalIsOpen={setDetailsModalIsOpen}
      setPreviewModalIsOpen={setPreviewModalIsOpen}
      setInvoicesWereProcessed={setInvoicesWereProcessed}
      setInvoicesWereSearched={setInvoicesWereSearched}
      previewedInvoice={previewedInvoice}
      fetchedInvoice={fetchedInvoice}
      processedInvoices={processedInvoices}
      potentialInvoices={potentialInvoices}
      previewInvoice={previewInvoice}
      processInvoices={processInvoices}
      values={values}
      touched={touched}
      errors={errors}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleMonthAndYearChange={handleMonthAndYearChange}
      handleInvoiceGeneration={handleInvoiceGeneration}
      handleInvoicePrinting={handleInvoicePrinting}
      printableInvoiceUrl={printableInvoiceUrl}
      handleClosePrintableInvoiceModal={handleClosePrintableInvoiceModal}
      handleInvoiceMailing={handleInvoiceMailing}
      handleReturnToSearch={handleReturnToSearch}
      isSubmitting={isSubmitting}
      disableAll={disableAll}
      disableSelectionOfAllCustomers={disableSelectionOfAllCustomers}
      customers={customers}
      regions={regions}
      selectedCustomersIds={selectedCustomersIds}
      setSelectedCustomersIds={setSelectedCustomersIds}
      selectAllCustomers={selectAllCustomers}
      toggleCustomerSelection={toggleCustomerSelection}
      removeCustomerFromSelection={removeCustomerFromSelection}
      addCustomerToSelection={addCustomerToSelection}
      isCustomerSelected={isCustomerSelected}
      onPreviewTableDoubleClick={onPreviewTableDoubleClick}
      onDetailsTableDoubleClick={onDetailsTableDoubleClick}
      setFieldValue={setFieldValue}
      notifications={notifications}
    />
  );
};

const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);
const connectWithApi = apiConnect(requests);

export default connectWithRedux(connectWithApi(FormState(InvoiceProcessing)));
