import React from "react";

import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import PanelMaterialBottom from "./../PanelMaterialBottom";
import ButtonBackMaterial from "./../ButtonBackMaterial";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import SelectMaterial from "./../SelectMaterial";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";

import { STATUS } from "./../../assets/js/Consts";
import { Link } from "react-router-dom";
import { Row } from "./../../components/grid";
import { FormValidation } from "./validations";

const SpecieFormView = ({
  isUpdate,
  fields,
  $field,
  $submit,
  fieldChange,
  $validation,
  disableAll,
  handleSubmit,
  invalidNotification
}) => {
  const onClickSave = (handleSubmit, invalidNotification) => {
    return e => {
      e.preventDefault();
      $submit(handleSubmit, invalidNotification);
    };
  };

  return (
    <React.Fragment>
      <PanelMaterial>
        <PanelMaterialTop title="Nova Espécie" />
        <PanelMaterialBody>
          <Row>
            <LVInputTextUpper
              id="name"
              title="Nome"
              required
              name="name"
              md={8}
              value={fields.name || ""}
              disabled={disableAll}
              {...$field("name", event => {
                fieldChange("name", event.target.value);
              })}
              hasValidation={$validation.name.show && !$validation.name.isValid}
              validationReason={$validation.name.error.reason}
            />

            <SelectMaterial
              title="Estado"
              md={4}
              name="status"
              options={STATUS}
              value={fields.status}
              searchable={true}
              disabled={disableAll}
              {...$field("status", value => {
                fieldChange("status", value);
              })}
            />
          </Row>
        </PanelMaterialBody>
        <PanelMaterialBottom>
          <Link to="/especies">
            <ButtonBackMaterial
              type="default"
              title="Voltar"
              position="bottom"
              disabled={false}
            />
          </Link>
          <ButtonSaveMaterial
            type="highlight"
            title={"Salvar"}
            right={true}
            onClick={onClickSave(handleSubmit, invalidNotification)}
            disabled={disableAll}
            requesting={false}
            requestingTitle={isUpdate ? "Atualizando..." : "Salvando..."}
          />
        </PanelMaterialBottom>
      </PanelMaterial>
    </React.Fragment>
  );
};

export default FormValidation(SpecieFormView);
