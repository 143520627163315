import React from 'react'
import { ChangeEvent } from "react";
import { Col, Row } from "../../components";
import FlatPanelMaterial from "../FlatPanelMaterial";
import InputMaterial from "../InputMaterial";

interface ManufacturerInformationFieldsProps {
  manufacturer: string,
  batch: string,
  validity: string,
  obs?: string
  obsFieldName?: string
  disableAll?: boolean,
  onChange: (name:string,value:string)=>void
}

/**
 * @version 0.0.1
 *
 * @author Kayo Oliveira <contato@kayooliveira.com>
 *
 * @description Responsible to render the Manufacturer Information Fields Section.
 *
 * @param {ManufacturerInformationFieldsProps} props
 * 
 * @return return
 * 
 * @example
 * <ManufacturerInformationFields
 *    manufacturer="Manufacturer"
 *    batch="L-00000"
 *    validity="01/01/2001"
 *    obs="Observation"
 *    obsFieldName="manufacturerObs"
 *    disableAll={false} // If false, you don't need to send as prop.
 *    onChange={(name,value) => console.log({name ,value})}
 *  />
 *    
*/

export function ManufacturerInformationFields({
  manufacturer,
  batch,
  validity,
  obs = "Licenciado pelo Ministério da Agricultura, Pecuária e Abastecimento sob registro nº MG00034-5",
  obsFieldName = 'obs',
  disableAll,
  onChange
}: ManufacturerInformationFieldsProps) {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const name = event.target.name;
    
    return onChange(name,value)
  }
  return (
    <FlatPanelMaterial title="Informações do Fabricante">
      <Row>
        <Row>
            <Col md={4}>
                <p className="ml-15 cleanItem">Fabricante</p>
                <InputMaterial
                  name="manufacturer"
                  md={12}
                  value={manufacturer}
                  disabled={disableAll}
                  fit={true}
                  onChange={handleChange}
                />
            </Col>
          <Col md={4}>
              <p className="ml-15 cleanItem">Lote</p>
              <InputMaterial
                name="batch"
                md={12}
                value={batch}
                disabled={disableAll}
                fit={true}
                onChange={handleChange}
              />
          </Col>
          <Col md={4}>
              <p className="ml-15 cleanItem">Validade</p>
              <InputMaterial
                name="validity"
                md={12}
                value={validity}
                disabled={disableAll}
                fit={true}
                onChange={handleChange}
              />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <p className="ml-15 cleanItem">Observação</p>
            <InputMaterial
              name={obsFieldName}
              md={12}
              value={obs }
              disabled={disableAll}
              fit={true}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Row>
    </FlatPanelMaterial>
   );
}