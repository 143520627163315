import React from "react";

/**
 * Internal
 */
import ReportPanelMaterial from "./../ReportPanelMaterial";
import ReportHeader from "./../ReportHeader";
import EasContent from "./../EasContent";

/**
 * External
 */
import { Row, Col } from "./../../components/grid";

const EasReportFormView = ({
  data: {
    refs,
    isUpdate,
    fields,
    disableAll,
    shouldSendEmail,
    options,
    $validation,
    reportHeaderData,
    submitPromise,
    backLink
  },
  methods: {
    fieldChange,
    suggestionsFilter,
    onSubmit,
    handleChange,
    handleBlur,
    handleToggle,
    handleSendReportEmail,
    $field,
    cancelReport,
    stopReport
  }
}) => {
  return (
    <ReportPanelMaterial
      title="EAS"
      backLink={backLink}
      shouldDisable={disableAll}
      isUpdate={isUpdate}
      isRequesting={submitPromise && submitPromise.pending}
      handleSubmit={onSubmit}
      handleSendReportEmail={handleSendReportEmail}
      shouldSendEmail={shouldSendEmail}
      cancelReport={cancelReport}
      stopReport={stopReport}
    >
      <Row>
        <Col md={12}>
          <ReportHeader
            data={reportHeaderData}
            fields={fields}
            fieldChange={fieldChange}
            $field={$field}
            $validation={$validation}
          />
        </Col>
      </Row>
      <EasContent
        volumeFieldName="volume"
        volume={fields.volume}
        volumeHasValidation={$validation.volume.show}
        volumeValidationReason={$validation.volume.error.reason}
        densityFieldName="density"
        density={fields.density}
        densityHasValidation={$validation.density.show}
        densityValidationReason={$validation.density.error.reason}
        densityRefs={refs.density}
        colorOptionIdFieldName="colorOptionId"
        colorOptionId={fields.colorOptionId}
        colorOptionIdHasValidation={$validation.colorOptionId.show}
        colorOptionIdValidationReason={$validation.colorOptionId.error.reason}
        colorOptions={options.colorOptions}
        smellOptionIdFieldName="smellOptionId"
        smellOptionId={fields.smellOptionId}
        smellOptionIdHasValidation={$validation.smellOptionId.show}
        smellOptionIdValidationReason={$validation.smellOptionId.error.reason}
        smellOptions={options.smellsOptions}
        aspectOptionIdFieldName="aspectOptionId"
        aspectOptionId={fields.aspectOptionId}
        aspectOptionIdHasValidation={$validation.aspectOptionId.show}
        aspectOptionIdValidationReason={$validation.aspectOptionId.error.reason}
        aspectOptions={options.aspectsOptions}
        proteinsOptionIdFieldName={"proteinsOptionId"}
        proteinsOptionId={fields.proteinsOptionId}
        proteinsOptionIdHasValidation={$validation.proteinsOptionId.show}
        proteinsOptionIdValidationReason={
          $validation.proteinsOptionId.error.reason
        }
        proteinsOptions={options.proteinsOptions}
        proteinsRefs={refs.proteins}
        glucoseOptionIdFieldName={"glucoseOptionId"}
        glucoseOptionId={fields.glucoseOptionId}
        glucoseOptionIdHasValidation={$validation.glucoseOptionId.show}
        glucoseOptionIdValidationReason={
          $validation.glucoseOptionId.error.reason
        }
        glucoseOptions={options.glucoseOptions}
        glucoseRefs={refs.glucose}
        acetoneOptionIdFieldName={"acetoneOptionId"}
        acetoneOptionId={fields.acetoneOptionId}
        acetoneOptionIdHasValidation={$validation.acetoneOptionId.show}
        acetoneOptionIdValidationReason={
          $validation.acetoneOptionId.error.reason
        }
        acetoneOptions={options.acetoneOptions}
        acetoneRefs={refs.acetone}
        phFieldName={"ph"}
        ph={fields.ph}
        phHasValidation={$validation.ph.show}
        phValidationReason={$validation.ph.error.reason}
        phRefs={refs.ph}
        bilirubinOptionIdFieldName={"bilirubinOptionId"}
        bilirubinOptionId={fields.bilirubinOptionId}
        bilirubinOptionIdHasValidation={$validation.bilirubinOptionId.show}
        bilirubinOptionIdValidationReason={
          $validation.bilirubinOptionId.error.reason
        }
        bilirubinOptions={options.bilirubinOptions}
        bilirubinRefs={refs.bilirubin}
        urobilinogenOptionIdFieldName={"urobilinogenOptionId"}
        urobilinogenOptionId={fields.urobilinogenOptionId}
        urobilinogenOptionIdHasValidation={
          $validation.urobilinogenOptionId.show
        }
        urobilinogenOptionIdValidationReason={
          $validation.urobilinogenOptionId.error.reason
        }
        urobilinogenOptions={options.urobilinogenOptions}
        urobilinogenRefs={refs.urobilinogen}
        hemoglobinOptionIdFieldName={"hemoglobinOptionId"}
        hemoglobinOptionId={fields.hemoglobinOptionId}
        hemoglobinOptionIdHasValidation={$validation.hemoglobinOptionId.show}
        hemoglobinOptionIdValidationReason={
          $validation.hemoglobinOptionId.error.reason
        }
        hemoglobinOptions={options.hemoglobinOptions}
        hemoglobinRefs={refs.hemoglobin}
        leukocyteOptionIdFieldName={"leukocyteOptionId"}
        leukocyteOptionId={fields.leukocyteOptionId}
        leukocyteOptionIdHasValidation={$validation.leukocyteOptionId.show}
        leukocyteOptionIdValidationReason={
          $validation.leukocyteOptionId.error.reason
        }
        leukocyteOptions={options.leukocyteOptions}
        leukocyteRefs={refs.leukocyte}
        nitriteOptionIdFieldName={"nitriteOptionId"}
        nitriteOptionId={fields.nitriteOptionId}
        nitriteOptionIdHasValidation={$validation.nitriteOptionId.show}
        nitriteOptionIdValidationReason={
          $validation.nitriteOptionId.error.reason
        }
        nitriteOptions={options.nitriteOptions}
        nitriteRefs={refs.nitrite}
        epithelialCellsOptionTagsFieldName={"epithelialCellsOptionTags"}
        epithelialCellsOptionTags={fields.epithelialCellsOptionTags}
        epithelialCellsOptionTagsSuggestions={options.epithelialCellsOptions}
        epithelialCellsOptionTagsHasValidation={
          $validation.epithelialCellsOptionTags.show
        }
        epithelialCellsOptionTagsValidationReason={
          $validation.epithelialCellsOptionTags.error.reason
        }
        epithelialCellsRefs={refs.epithelialCells}
        erythrocytesMinFieldName="erythrocytesMin"
        erythrocytesMin={fields.erythrocytesMin}
        erythrocytesMaxFieldName="erythrocytesMax"
        erythrocytesMax={fields.erythrocytesMax}
        erythrocytesHasValidation={$validation.isErythrocytesValid.show}
        erythrocytesValidationReason={
          $validation.isErythrocytesValid.error.reason
        }
        isErythrocytesAbsentFieldName="isErythrocytesAbsent"
        isErythrocytesAbsent={fields.isErythrocytesAbsent}
        isErythrocytesUncountableFieldName="isErythrocytesUncountable"
        isErythrocytesUncountable={fields.isErythrocytesUncountable}
        erythrocytesRefs={refs.erythrocytes}
        pusCellsMinFieldName="pusCellsMin"
        pusCellsMin={fields.pusCellsMin}
        pusCellsMaxFieldName="pusCellsMax"
        pusCellsMax={fields.pusCellsMax}
        pusCellsHasValidation={$validation.isPusCellsValid.show}
        pusCellsValidationReason={$validation.isPusCellsValid.error.reason}
        isPusCellsAbsentFieldName="isPusCellsAbsent"
        isPusCellsAbsent={fields.isPusCellsAbsent}
        isPusCellsUncountableFieldName="isPusCellsUncountable"
        isPusCellsUncountable={fields.isPusCellsUncountable}
        pusCellsRefs={refs.pusCells}
        ridgesOptionTagsFieldName={"ridgesOptionTags"}
        ridgesOptionTags={fields.ridgesOptionTags}
        ridgesOptionTagsSuggestions={options.ridgesOptions}
        ridgesOptionTagsHasValidation={$validation.ridgesOptionTags.show}
        ridgesOptionTagsValidationReason={
          $validation.ridgesOptionTags.error.reason
        }
        ridgesOptionRefs={refs.ridges}
        castsOptionTagsFieldName={"castsOptionTags"}
        castsOptionTags={fields.castsOptionTags}
        castsOptionTagsSuggestions={options.castsOptions}
        castsOptionTagsHasValidation={$validation.castsOptionTags.show}
        castsOptionTagsValidationReason={
          $validation.castsOptionTags.error.reason
        }
        castsOptionRefs={refs.casts}
        bacterialFloraOptionIdFieldName={"bacterialFloraOptionId"}
        bacterialFloraOptionId={fields.bacterialFloraOptionId}
        bacterialFloraOptionIdHasValidation={
          $validation.bacterialFloraOptionId.show
        }
        bacterialFloraOptionIdValidationReason={
          $validation.bacterialFloraOptionId.error.reason
        }
        bacterialFloraOptions={options.bacterialFloraOptions}
        bacterialFloraOptionRefs={refs.bacterialFlora}
        spermatozoaOptionIdFieldName={"spermatozoaOptionId"}
        spermatozoaOptionId={fields.spermatozoaOptionId}
        spermatozoaOptionIdHasValidation={$validation.spermatozoaOptionId.show}
        spermatozoaOptionIdValidationReason={
          $validation.spermatozoaOptionId.error.reason
        }
        spermatozoaOptions={options.spermatozoaOptions}
        spermatozoaOptionRefs={refs.spermatozoa}
        obsFieldName={"obs"}
        obs={fields.obs}
        suggestionsFilter={suggestionsFilter}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleToggle={handleToggle}
        shouldDisable={disableAll}
      />
    </ReportPanelMaterial>
  );
};

export default EasReportFormView;
