import React from "react";

/**
 * Internal
 */
import ReportPanelMaterial from "./../ReportPanelMaterial";
import TableExamReportHeaderPanel from "./../TableExamReportHeaderPanel";
import DefaultExamTable from "./../DefaultExamTable";
import TextAreaPanel2 from "../TextAreaPanel2";

/**
 * External
 */
import { Row, Col } from "./../../components/grid";

const DefaultExamTableReportFormView = ({
  data: {
    title,
    fields,
    backLink,
    isUpdate,
    disableAll,
    $validation,
    submitPromise,
    requestExamStates,
    examTableHeaderData,
    shouldSendEmail
  },
  methods: {
    stateChange,
    resultChange,
    fieldChange,
    $field,
    onSubmit,
    handleSendReportEmail,
    cancelReport,
    stopReport
  }
}) => (
  <ReportPanelMaterial
    title={title}
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Row>
      <Col md={12}>
        <TableExamReportHeaderPanel
          data={examTableHeaderData}
          shouldDisable={disableAll}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <hr className="mt-20" />
      </Col>
    </Row>
    <Row>
      <Col md={12} className="fit-table">
        <DefaultExamTable
          title={"Exames"}
          options={fields.options}
          requestExamStates={requestExamStates}
          handleStateChange={stateChange}
          resultChange={resultChange}
          shouldDisable={disableAll}
          $field={$field}
          $validation={$validation}
        />
      </Col>
      <TextAreaPanel2
        title="Obs"
        name={"chemilObs"}
        value={fields.chemilObs}
        disabled={disableAll}
        handleChange={fieldChange}
        // handleBlur={this.props.handleBlur}
      />
    </Row>
  </ReportPanelMaterial>
);

export default DefaultExamTableReportFormView;
