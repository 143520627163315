import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import ButtonDefaultMaterial from './../ButtonDefaultMaterial';

/** External */
import classNames from 'classnames';
import update from 'immutability-helper';
import { Modal } from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import trash from '@fortawesome/fontawesome-free-solid/faTrashAlt';

class TableButtonRemoveMaterial extends Component {

  constructor( props ) {
    super( props );
    this.state = { isDialogOpen: false };
    this.toggleDialog = this.toggleDialog.bind( this );
    this.handleConfirm = this.handleConfirm.bind( this );
  }

  toggleDialog( ) {
    this.setState( ( state ) => update( state, 
      { isDialogOpen: { $set: !this.state.isDialogOpen } }
    ) ) ;
  }

  handleConfirm( ) {
    this.props.onClick();
    this.toggleDialog();
  }

  render() {
    return( 
      <React.Fragment>
        <Modal
          show={ this.state.isDialogOpen }
          onHide={ () => null }
        >
          <Modal.Header closeButton>
            <Modal.Title> { this.props.text.title } </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>{ this.props.text.message }</h5>
          </Modal.Body>
          <Modal.Footer>
            <ButtonDefaultMaterial
              type="danger"
              title={ this.props.text.confirm }
              onClick={ this.handleConfirm }
            />
          </Modal.Footer>
        </Modal>
        <button
          className={ 
            classNames( styles.tableRemoveBtn, this.props.className ) 
          }
          onClick={ this.toggleDialog }
          disabled={ this.props.disabled }
        >
          <FontAwesomeIcon icon={ trash } />
        </button>
      </React.Fragment>
    )
  }
}

export default TableButtonRemoveMaterial;