const FocusManagerKeys = {
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  PLUS_SIGN: 107,
  MINUS_SIGN: 109
};

export default FocusManagerKeys;
