import { createStitches } from "@stitches/react";

export const { styled, css } = createStitches({
  theme: {
    colors: {
        blackberry: "#212121",
        leaf: "#0AAFAA",
        orchid: "#00A0C2",
        blueberry: "#008CCD",
        grape: "#5F72C2",
        violet: "#92519E",
        rose: "#BB006B",
        petunia: "#FFFFFF",
        granadilla: "#A6ABBD",
        pumpkin: "#FFA17A",
        red: "#FF0000",

        text000: "#FFFFFF",
        text100: "#FAFAFA",
        text200: "#F5F5F5",
        text300: "#EEEEEE",
        text400: "#E0E0E0",
        text500: "#BDBDBD",
        text600: "#9E9E9E",
        text700: "#757575",
        text800: "#616161",
        text900: "#424242",
        text1000: "#424242",

        leaf100: "#CBFBE8",
        leaf200: "#99F7DB",
        leaf300: "#64E7CC",
        leaf400: "#3CCFBD",
        leaf500: "#0AAFAA",
        leaf600: "#078E96",
        leaf700: "#056C7D",
        leaf800: "#034E65",
        leaf900: "#013953",

        lime100: "#EFFAD1",
        lime200: "#DDF6A6",
        lime300: "#BCE374",
        lime400: "#97C94E",
        lime500: "#69A51F",
        lime600: "#538D16",
        lime700: "#3F760F",
        lime800: "#2D5F09",
        lime900: "#214F05",

        blueberry100: "#D4F5F9",
        blueberry200: "#ABE7F3",
        blueberry300: "#7BC6DC",
        blueberry400: "#539CBA",
        blueberry500: "#25688D",
        blueberry600: "#1B5179",
        blueberry700: "#123D65",
        blueberry800: "#0B2B51",
        blueberry900: "#071E43",

        pumpkin100: "#FFF3E4",
        pumpkin200: "#FFE4C9",
        pumpkin300: "#FFD1AF",
        pumpkin400: "#FFBF9B",
        pumpkin500: "#FFA17A",
        pumpkin600: "#DB7659",
        pumpkin700: "#B7503D",
        pumpkin800: "#933026",
        pumpkin900: "#7A1917",

        lychee100: "#FBC9D2",
        lychee200: "#F894B0",
        lychee300: "#EA5D92",
        lychee400: "#D63581",
        lychee500: "#BB006B",
        lychee600: "#A0006C",
        lychee700: "#860067",
        lychee800: "#6C005E",
        lychee900: "#590057",

        granadilla100: "#F5F7FB",
        granadilla200: "#ECEFF8",
        granadilla300: "#DADEEB",
        granadilla400: "#C3C8D7",
        granadilla500: "#A6ABBD",
        granadilla600: "#7981A2",
        granadilla700: "#535C88",
        granadilla800: "#343D6D",
        granadilla900: "#1F275A"
    },

    fonts: {
      mainFont: 'arial, sans-serif;',
      secondaryFont: 'times',
      printableFont: 'times'
    },
  },

  media: {
    sm: '(min-width: 640px)',
    md: '(max-width: 768px)',
    lg: '(max-width: 1024px)',
  },
})