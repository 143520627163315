import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App";
import store from "./redux/store.js";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

/** External */
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

/** Assets */
import "./assets/css/index.css";
import "./assets/css/overriding.css";
import "./assets/css/useful.css";
import "font-awesome/css/font-awesome.css";

Bugsnag.start({
  apiKey: "8a133d6afefdf0b4b4320968a1a8a200",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);
