import React from "react";

/** Internal */
import PanelMaterialBody from "../PanelMaterialBody";
import PanelMaterial from "./../PanelMaterial";
import FlatPanelMaterial from "./../FlatPanelMaterial";
import ReportHeader from "./../ReportHeader";
import InputMaterial from "./../InputMaterial";
import SelectMaterial from "./../SelectMaterial";
import {
  IMMUNOLOGY_EXAM_MATERIALS,
  IMMUNOLOGY_SCORE_EXAM_VALUES,
  IMMUNOLOGY_INFO
} from "./../../assets/js/Consts";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";
import MethodPanel from "./../MethodPanel";
import { ManufacturerInformationFields } from "../ManufacturerInformationFields";

const DistemperAndParvovirusIgGReportFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Cinomose + Parvovirose - Elisa IgG"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <MethodPanel
      method={IMMUNOLOGY_INFO.DISTEMPER_PARVOVIRUS_IGG.METHOD}
      description={IMMUNOLOGY_INFO.DISTEMPER_PARVOVIRUS_IGG.DESCRIPTION}
    />
    <FlatPanelMaterial title="Resultado">
      <Row>
        <Col md={4}></Col>
        <SelectMaterial
          id="materialId"
          title="Material Enviado"
          md={4}
          name="materialId"
          options={IMMUNOLOGY_EXAM_MATERIALS}
          value={data.fields.materialId}
          searchable={true}
          disabled={data.disableAll}
          hasValidationRecoil
          {...methods.$field("materialId", event => {
            methods.fieldChange("materialId", event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.materialId.show &&
            !data.$validation.materialId.isValid
          }
          validationReason={
            data.$validation && data.$validation.materialId.error.reason
          }
          isValid={data.$validation && data.$validation.materialId.isValid}
        />
      </Row>
      <Row>
        <Col xs={4}></Col>
        <SelectMaterial
          id="distemper"
          title="Sorologia Para Cinomose"
          md={4}
          name="distemper"
          options={IMMUNOLOGY_SCORE_EXAM_VALUES}
          value={data.fields.distemper}
          searchable={true}
          disabled={data.disableAll}
          hasValidationRecoil
          {...methods.$field("distemper", event => {
            methods.fieldChange("distemper", event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.distemper.show &&
            !data.$validation.distemper.isValid
          }
          validationReason={
            data.$validation && data.$validation.distemper.error.reason
          }
          isValid={data.$validation && data.$validation.distemper.isValid}
        />
      </Row>
      <Row>
        <Col xs={4}></Col>
        <SelectMaterial
          id="parvovirus"
          title="Sorologia Para Parvovirose"
          md={4}
          name="parvovirus"
          options={IMMUNOLOGY_SCORE_EXAM_VALUES}
          value={data.fields.parvovirus}
          searchable={true}
          disabled={data.disableAll}
          hasValidationRecoil
          {...methods.$field("parvovirus", event => {
            methods.fieldChange("parvovirus", event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.parvovirus.show &&
            !data.$validation.parvovirus.isValid
          }
          validationReason={
            data.$validation && data.$validation.parvovirus.error.reason
          }
          isValid={data.$validation && data.$validation.parvovirus.isValid}
        />
      </Row>
    </FlatPanelMaterial>
    <ManufacturerInformationFields
      manufacturer={data.fields.manufacturer}
      batch={data.fields.batch}
      validity={data.fields.validity}
      obs={data.fields.manufacturerInformationObs}
      obsFieldName="manufacturerInformationObs"
      onChange={(name,value) => methods.fieldChange(name,value)}
    />
    <FlatPanelMaterial title="Diagnóstico">
      <Row>
        <Col xs={1} className="text-right">
          <p className="mr-10 cleanItem">OBS</p>
        </Col>
        <Col xs={11}>
          <InputMaterial
            name="obs"
            md={12}
            value={data.fields.obs}
            disabled={data.disableAll}
            fit={true}
            onChange={event => methods.fieldChange("obs", event.target.value)}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
  </ReportPanelMaterial>
);

export default DistemperAndParvovirusIgGReportFormView;
