import React, { Component } from "react";

/** Internal */
import ParasitologicalFreshFecesReportFormView from "./../ParasitologicalFreshFecesReportFormView";
import connectWithEndpoint from "./requests.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import validation from "./validation.js";
import { PromiseUtil } from "../../useful";
import { REQUEST_EXAM_STATE_ID_BY_NAME } from "../../assets/js/Consts.js";
import {
  containerState,
  reportState,
} from "../../modules/factories/default-states.js";
import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";

/** External */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";
import {
  parasitologialFreshFecesCheckboxes,
  withCheckbox,
} from "../../components/result-group";
import { EXAM_ID_BY_NAME } from "../../consts/exams";

const View = ({ data, methods, $field, $validation, $submit, $fieldEvent }) => {
  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;

    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };

  const handleChange = (field, value) => {
    $fieldEvent("change", field);
    methods.fieldChange(field, value);
  };

  const handleBlur = (event) => {
    $fieldEvent("blur", event.target.name);
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    handleChange: handleChange,
    handleBlur: handleBlur,
    $field: $field,
    ...methods,
  };

  return (
    <ParasitologicalFreshFecesReportFormView
      data={newData}
      methods={newMethods}
    />
  );
};

const ValidatedView = validation(View);

class ParasitologicalFreshFecesReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        ...reportState(),
        fecesColorOptionId: 0,
        fecesSmellOptionId: 0,
        fecesAspectOptionId: 0,
        fecesConsistencyOptionId: 0,
        macroscopicParasites: "",
        result: "",
        obs: "",
      },
      shouldSendEmail: false,
      ...containerState(),
    };

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);
    this.createObj = this.createObj.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.shouldDisplayLoader = this.shouldDisplayLoader.bind(this);
    this.quantitativeResult = this.quantitativeResult.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        xhr.response.status === 200 && this.fillFields(data);
      };

      this.props.getReportById(id, then);
    }
    this.props.getReportHeader(this.props.match.params.requestExamId);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    this.props.handleCheckedResultChange(obj.result);
    this.setState((state) =>
      update(state, {
        fields: {
          fecesColorOptionId: { $set: obj.fecesColorOptionId },
          fecesSmellOptionId: { $set: obj.fecesSmellOptionId },
          fecesAspectOptionId: { $set: obj.fecesAspectOptionId },
          fecesConsistencyOptionId: { $set: obj.fecesConsistencyOptionId },
          macroscopicParasites: { $set: obj.macroscopicParasites },
          result: { $set: obj.result },
          obs: { $set: obj.obs },
          vetSignerId: { $set: obj.vetSignerId },
          requestExamState: { $set: obj.requestExamState },
        },
        shouldSendEmail: {
          $set:
            obj.requestExamState ==
            REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_LIBERADO"],
        },
      })
    );
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Creates hemogram object
   *
   * @return {Object}
   *
   */
  createObj() {
    const {
      fecesColorOptionId,
      fecesSmellOptionId,
      fecesAspectOptionId,
      fecesConsistencyOptionId,
      macroscopicParasites,
      obs,
      reportDataId,
      vetSignerId,
      requestExamState,
    } = this.state.fields;

    const requestExamId = this.props.match.params.requestExamId;
    const result = this.props.checkedResult;

    return {
      id: this.getId(),
      requestExamId: requestExamId,
      fecesColorOptionId: fecesColorOptionId,
      fecesSmellOptionId: fecesSmellOptionId,
      fecesAspectOptionId: fecesAspectOptionId,
      fecesConsistencyOptionId: fecesConsistencyOptionId,
      macroscopicParasites: macroscopicParasites,
      result: result,
      obs: obs,
      reportDataId: reportDataId,
      vetSignerId: vetSignerId,
      requestExamState: requestExamState,
    };
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Decides wether it should
   * display the screen loader
   *
   */
  shouldDisplayLoader() {
    const { reportPromise } = this.props;
    const isUpdate = this.isUpdate();

    let editFetches = null;

    const shouldCheckEditFetches = isUpdate && reportPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([reportPromise]);
    }

    return isUpdate && editFetches && editFetches.pending;
  }

  quantitativeResult() {
    const { eggsQuantity } = this.state.fields;
    return "Qualitative";
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (!this.state.completeValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" || obj[i] == 0 ? null : obj[i];
      }
    }
    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  render() {
    const { examRequestId, requestExamId } = this.props.match.params;
    const {
      reportSubmitPromise,
      reportHeaderPromise,
      fecesColorOptionsPromise,
      fecesSmellOptionsPromise,
      fecesAspectOptionsPromise,
      fecesConsistencyOptionsPromise,
    } = this.props;

    const backLink = "/solicitacoes-exame/" + examRequestId;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    // Loader
    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );

    const data = {
      isUpdate: this.isUpdate(),
      fields: this.state.fields,
      completeValidation: this.state.completeValidation,
      fecesColorOptionsPromise: fecesColorOptionsPromise,
      fecesSmellOptionsPromise: fecesSmellOptionsPromise,
      fecesAspectOptionsPromise: fecesAspectOptionsPromise,
      fecesConsistencyOptionsPromise: fecesConsistencyOptionsPromise,
      submitPromise: reportSubmitPromise,
      backLink: backLink,
      requestExamId: requestExamId,
      shouldSendEmail: this.state.shouldSendEmail,
      examRequestId: examRequestId,
      disableAll: reportSubmitPromise && reportSubmitPromise.pending,
      reportHeaderData: reportHeaderFactory(reportHeaderData),
      checkState: this.props.multiCheckboxState,
      checkboxes: parasitologialFreshFecesCheckboxes,
      result: this.props.checkedResult,
    };

    // Methods
    const methods = {
      fieldChange: this.fieldChange,
      quantitativeResult: this.quantitativeResult,
      handleSubmit: this.handleSubmit,
      invalidNotification: this.invalidNotification,
      handleSendReportEmail: this.handleSendReportEmail,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      cancelReport: this.cancelReport,
      stopReport: this.stopReport,
      multiBoxChange: this.props.multiBoxChange,
      handleCheckedResultChange: this.props.handleCheckedResultChange,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(connectWithEndpoint, (component) =>
  withCheckbox(component, EXAM_ID_BY_NAME.PARASITOLOGICAL_FRESH_FECES)
)(ParasitologicalFreshFecesReportForm);
