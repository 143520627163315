import React from "react";

/** Internal */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import LVTable from "./../../old_components/LVTable/index";

/** External */
import { Link } from "react-router-dom";

const AnimalListView = ({
  objects,
  filter,
  pages,
  isTableLoading,
  onTableDoubleClick,
}) => {
  let config = {
    defaultPageSize: 5,
    defaultSorted: [{ id: "id", desc: true }],
    pageSize: 15,
    resizable: false,
    showPagination: true,
    noDataText: "Nenhum registro".toUpperCase(),
    loadingText: "Carregando",
    className: "-striped -highlight",
    previousText: "Anterior",
    nextText: "Próximo",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    columns: [],
    showPageSizeOptions: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
  };

  config.data = objects;
  config.loading = isTableLoading;
  config.filterable = true;
  config.onDoubleClickRow = onTableDoubleClick;
  config.manual = true;
  config.onFetchData = filter;
  config.pages = pages;

  config.columns = [
    {
      Header: "Código",
      sortable: true,
      minWidth: 1,
      accessor: "id",
    },
    {
      Header: "Nome",
      sortable: true,
      minWidth: 2,
      accessor: "name",
    },
    {
      Header: "Cliente",
      sortable: true,
      minWidth: 2,
      accessor: "customer",
    },
    {
      Header: "Proprietário",
      sortable: true,
      minWidth: 2,
      accessor: "owner",
    },
    {
      Header: "Espécie",
      sortable: true,
      minWidth: 2,
      accessor: "species",
    },
    {
      Header: "Raça",
      sortable: true,
      minWidth: 2,
      accessor: "breed",
    },
    {
      Header: "Sexo",
      sortable: false,
      minWidth: 2,
      accessor: "sexId",
      Cell: (data) => {
        const sexId = data.row.sexId;
        switch (sexId) {
          case 1:
            return "Macho";
            break;
          case 2:
            return "Fêmea";
            break;
          case 3:
            return "Indefinido";
            break;
          default:
            return "Todos";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Todos</option>
          <option value={1}>Macho</option>
          <option value={2}>Fêmea</option>
          <option value={3}>Indefinido</option>
        </select>
      ),
    },
  ];

  return (
    <React.Fragment>
      <PanelMaterial>
        <PanelMaterialTop title={"Animais"}>
          <Link to="/animais/novo">
            <ButtonSaveMaterial
              title="Novo Animal"
              type="highlight"
              position="top"
              right={true}
            />
          </Link>
        </PanelMaterialTop>
        <PanelMaterialBody>
          <LVTable
            filterable={config.filterable}
            manual={config.manual}
            onFetchData={config.onFetchData}
            pages={config.pages}
            data={config.data}
            columns={config.columns}
            loading={config.loading}
            pageSize={config.pageSize}
            sortable={config.sortable}
            defaultSorted={config.defaultSorted}
            showPagination={config.showPagination}
            defaultPageSize={config.defaultPageSize}
            showPageSizeOptions={config.showPageSizeOptions}
            onDoubleClickRow={config.onDoubleClickRow}
          />
        </PanelMaterialBody>
      </PanelMaterial>
    </React.Fragment>
  );
};

export default AnimalListView;
