import { validated } from "react-custom-validation";

/**
 * Internal
 */
import { required, greaterThan } from "../../assets/js/validationFunctions";

import { commonReportRules } from "../../assets/js/validationRules";

const validation = validated(props => {
  const {
    cortisol,
    creatinine,
    obs,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const { completeValidation } = props.data;

  let fields = [
    "cortisol",
    "creatinine",
    "obs",
    "requestExamState",
    "vetSignerId"
  ];

  const commonValidation = commonReportRules(vetSignerId, requestExamState);

  const validation = {
    cortisol: {
      rules: completeValidation ? [[required, cortisol, "Obrigatório"]] : []
    },
    creatinine: {
      rules: completeValidation ? [[required, creatinine, "Obrigatório"]] : []
    },
    obs: {
      rules: []
    }
  };

  const validations = {
    ...commonValidation,
    ...validation
  };

  return { fields, validations };
});

export default validation;
