import React from "react";
import LVTable from "./../../../old_components/LVTable";
import * as DateUtil from "./../../../useful/date-util";

const renderPrintIcon = () => {
  return <i className="fa fa-print" />;
};

const renderMailIcon = () => {
  return <i className="fa fa-envelope" />;
};

const ProcessedInvoicesTable = ({
  data,
  onTableDoubleClick,
  handleInvoicePrinting,
  handleInvoiceMailing
}) => {
  const columns = [
    {
      id: "print",
      Header: "Imprimir",
      sortable: true,
      minWidth: 35,
      Cell: data => (
        <span
          onClick={() => {
            handleInvoicePrinting(data.original.id);
          }}
        >
          {renderPrintIcon()}
        </span>
      )
    },
    {
      id: "mail",
      Header: "Enc. Email",
      sortable: true,
      minWidth: 40,
      Cell: data => (
        <span
          onClick={() => {
            handleInvoiceMailing(data.original.id);
          }}
        >
          {renderMailIcon()}
        </span>
      )
    },
    {
      id: "customer",
      Header: "Cliente",
      sortable: true,
      minWidth: 70,
      Cell: data => data.original.customer.name
    },
    {
      id: "id",
      accessor: "id",
      Header: "Número da Fatura",
      sortable: true,
      minWidth: 50,
      Cell: data => data.original.id
    },
    {
      id: "start_date",
      accessor: "start_date",
      Header: "Data Inicio",
      sortable: true,
      minWidth: 40,
      Cell: data => DateUtil.formatDate(data.original.start_date)
    },
    {
      id: "end_date",
      accessor: "end_date",
      Header: "Data Final",
      sortable: true,
      minWidth: 40,
      Cell: data => DateUtil.formatDate(data.original.end_date)
    },
    {
      id: "due_date_time",
      accessor: "due_date_time",
      Header: "Vencimento",
      sortable: true,
      minWidth: 40,
      Cell: data => DateUtil.formatDate(data.original.due_date_time)
    },
    {
      id: "gross_value",
      accessor: "gross_value",
      Header: "Valor Bruto",
      sortable: true,
      minWidth: 40,
      Cell: data => `R$ ${data.original.gross_value}`
    },
    {
      id: "net_value",
      accessor: "net_value",
      Header: "Valor Líquido",
      sortable: true,
      minWidth: 50,
      Cell: data => `R$ ${data.original.net_value}`
    }
  ];

  return (
    <LVTable
      data={data}
      loading={false}
      columns={columns}
      sortable={true}
      pageSize={15}
      onDoubleClickRow={onTableDoubleClick}
    />
  );
};

export default ProcessedInvoicesTable;
