import {
  OBS_VALIDATION_MAX_LENGTH,
  REQUIRED_VALIDATION_MESSAGE,
} from "../../../assets/js/Consts";
import { maxLength, required } from "../../../assets/js/validationFunctions";
import { EXAM_TYPES } from "../../../consts";

export const rpcuFieldNames = () => {
  return ["rpcuProtein", "rpcuCreatinine", "rpcuResult", "rpcuObs"];
};

export const rpcuDefaultState = () => {
  return {
    rpcuProtein: "",
    rpcuCreatinine: "",
    rpcuResult: "",
    rpcuObs: "",
  };
};

export const rpcuStateApiFactory = (obj) => {
  return {
    rpcuProtein: obj.rpcuProtein,
    rpcuCreatinine: obj.rpcuCreatinine,
    rpcuResult: obj.rpcuResult,
    rpcuObs: obj.rpcuObs,
  };
};

export const rpcuFieldsToApiObjParser = (fields) => {
  const { rpcuProtein, rpcuCreatinine, rpcuResult, rpcuObs } = fields;

  return {
    rpcuProtein: rpcuProtein,
    rpcuCreatinine: rpcuCreatinine,
    rpcuResult: rpcuResult,
    rpcuObs: rpcuObs,
  };
};

export const rpcuValidationRules = (fields, isCompleteValidation) => {
  const { rpcuProtein, rpcuCreatinine, rpcuObs } = fields;

  return {
    rpcuProtein: {
      rules: isCompleteValidation
        ? [[required, rpcuProtein, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    rpcuCreatinine: {
      rules: isCompleteValidation
        ? [[required, rpcuCreatinine, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    rpcuObs: {
      rules: isCompleteValidation
        ? [[maxLength, rpcuObs, OBS_VALIDATION_MAX_LENGTH]]
        : [],
    },
  };
};

export const rpcuRequests = (requestExamId) => {
  return {
    rpcuRefsPromise: `/reference-values/?requestExamId=${requestExamId}&examTypeId=${EXAM_TYPES.URINALYSIS}`,
  };
};
