import React from "react";

import * as LifeVet from "../../../../../components";

const ClinicalReportField = ({
  isReadOnly,
  fields,
  disableAll,
  updateField,
  $field,
  $validation,
  clinicalReportRef,
}) => (
  <LifeVet.FormGroup
    errorMessage={
      $validation.clinicalReport.show && $validation.clinicalReport.error.reason
    }
  >
    <LifeVet.InputLabel>Relato Clínico</LifeVet.InputLabel>
    <LifeVet.Input
      disabled={disableAll || isReadOnly}
      name="clinicalReport"
      type="text"
      value={fields.clinicalReport}
      {...$field("clinicalReport", (event) => {
        updateField("clinicalReport", event.target.value);
      })}
      innerRef={clinicalReportRef}
    />
  </LifeVet.FormGroup>
);

ClinicalReportField.propTypes = {};

export default ClinicalReportField;
