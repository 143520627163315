import React, { useCallback } from "react";
import styled from "styled-components";

import apiConnect from "./../../api-connector";
import { connect as reduxConnect } from "react-redux";
import { connectListWithEndpoint } from "./requests";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";

import FormState from "./form-state";
import FadeIn from "react-fade-in";
import { MdAnnouncement, MdInbox } from "react-icons/md";
import * as LifeVet from "./../../components";
import {
  InProgress,
  InAnalysis,
  InDevelopment,
  CompletedBadge,
} from "./badge.js";

const StyledNotificationIcon = styled(MdAnnouncement)`
  font-size: 30px;
  color: ${LifeVet.Colors.leaf.leaf500};
`;

const StyledEmptyIcon = styled(MdInbox)`
  font-size: 30px;
  color: ${LifeVet.Colors.granadilla.granadilla500};
`;

const renderBadge = (ticketStatus) => {
  switch (parseInt(ticketStatus)) {
    case 1:
      return <InProgress />;
    case 2:
      return <InAnalysis />;
    case 3:
      return <InDevelopment />;
    case 4:
      return <CompletedBadge />;
  }
};

const TicketsList = ({ access_profile, history, tickets }) => {
  const navigate = useCallback(() => {
    history.push("/tickets/novo");
  });

  const goToTicket = (ticketId) => {
    history.push(`/tickets/${ticketId}`);
  };

  return (
    <FadeIn>
      <LifeVet.Panel>
        <LifeVet.PanelHeader title="Tickets">
          <LifeVet.Button
            onClick={navigate}
            type="submit"
            depth="2"
            variant="primary"
          >
            Novo Ticket
          </LifeVet.Button>
        </LifeVet.PanelHeader>
        <LifeVet.PanelBody>
          <LifeVet.IconList>
            {tickets.value &&
              tickets.value.map((ticket) => (
                <LifeVet.IconListItem
                  key={ticket.id}
                  icon={<StyledNotificationIcon />}
                >
                  <LifeVet.ListTitle>
                    #{ticket.id} - {ticket.subject}
                  </LifeVet.ListTitle>
                  <LifeVet.ListText>{ticket.message}</LifeVet.ListText>
                  <LifeVet.BadgeWrapper>
                    {renderBadge(ticket.status)}
                  </LifeVet.BadgeWrapper>
                  {access_profile === 1 && (
                    <LifeVet.Button
                      onClick={() => goToTicket(ticket.id)}
                      depth="2"
                      variant="secondary"
                      float="right"
                    >
                      Editar
                    </LifeVet.Button>
                  )}
                </LifeVet.IconListItem>
              ))}
            {tickets.value && tickets.value.length == 0 && (
              <LifeVet.IconListItem icon={<StyledEmptyIcon />}>
                <LifeVet.ListTitle>Nenhum Registro</LifeVet.ListTitle>
                <LifeVet.ListText>
                  Você ainda não cadastrou nenhum ticket, eles aparecerão aqui
                  quando forem cadastrados.
                </LifeVet.ListText>
              </LifeVet.IconListItem>
            )}
          </LifeVet.IconList>
        </LifeVet.PanelBody>
        <LifeVet.PanelFooter></LifeVet.PanelFooter>
      </LifeVet.Panel>
    </FadeIn>
  );
};

const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);
const connectWithApi = apiConnect(connectListWithEndpoint);

export default connectWithRedux(connectWithApi(FormState(TicketsList)));
