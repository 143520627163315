import React from "react";

import { Modal } from "react-bootstrap";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faAngleLeft from "@fortawesome/fontawesome-free-solid/faAngleLeft";
import faCheckSquare from "@fortawesome/fontawesome-free-solid/faCheckSquare";

import ButtonDefaultMaterial from "../../ButtonDefaultMaterial";
import AnimalModal from "./components/animal-modal";
import CustomerSelect from "./components/customer-select";
import VetSelect from "./components/vet-select";
import ClinicalReportField from "./components/clinical-report-field";
import AnimalSection from "./components/animal-section/index.js";
import ExamsSection from "./components/exams-section/index.js";

import { ACCESS_PROFILES } from "../../../assets/js/Consts";
import AccessManager from "../../../old_components/AccessManager";
import ReceiveAndAssistModal from "../../ExamRequestListView/ReceiveAndAssistModal";
import * as LifeVet from "../../../components";

import "react-select-legacy/dist/react-select.css";
import "react-table-v6/react-table.css";
import styles from "./index.module.css";

const downloadReportIfTheDeviceIsMobile = (
  printedModalUrl,
  isMobile,
  infoNotification
) => {
  if (printedModalUrl && isMobile) {
    infoNotification({
      title: "Arquivo baixado com sucesso!",
      message: "Verifique os downloads de seu navegador.",
      position: "tr",
      autoDismiss: 10,
    });
    window.location.href = printedModalUrl;
  }
};

/**
 *
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Represents an ExamRequest
 */
const ExamRequestFormView = ({
  data: {
    id,
    panelTitle,
    fields,
    formIsInUpdateMode,
    disableAll,
    customersPromise,
    vetsPromise,
    animal,
    searchedAnimalsPromise,
    examTypesPromise,
    labExamsPromise,
    isExamTypesSelected,
    submittedExamRequestPromise,
    $validation,
    showAnimalModal,
    animalPages,
    shouldShowAnimalForm,
    speciesPromise,
    breedsPromise,
    shouldDisableAnimalFields,
    showReceiveAndAssistModal,
    disableCustomerSelect,
    isReadOnly,
    loggedUserIsFromClinic,
    showPrintReportModal,
    printedModalUrl,
    animalNameSearchInputRef,
    animalNameInputRef,
    clinicalReportRef,
    setRef,
    isMobile,
  },
  methods: {
    handlePrintReportModalClose, //OK,
    updateField,
    onChangeSampleCode,
    onSelectCustomer,
    onSelectExamType,
    printSingleReport,
    printExamRequest,
    addLabExams,
    $field,
    onSubmit,
    onSubmitAndRestart,
    emailExamRequestReports,
    isCustomerSelected,
    handleShowAnimalModal,
    filterAnimals,
    handleShowAnimalForm,
    handleCloseAnimalForm,
    handleAnimalTableClicks,
    handleSelectAnimal,
    handleAnimalSubmit,
    invalidNotification,
    onChangeSpecies,
    onChangeYearsOrMonths,
    onChangeBirthDate,
    onExitedAnimalModal,
    handleAnimalModalClose,
    handleKeepAnimalFilterState,
    handleShowReceiveAndAssistModal,
    receiveAndAssistSampleCode,
    handleCloseReceiveAndAssistModal,
    onTableDoubleClick,
    onTableSingleClick,
    removeReportFromTable,
    setRequestExamSampleFieldRef,
    updateRequestExamPrice,
    updateRequestExamDeadline,
    updateRequestExamField,
    shouldDisableExamsTableFields,
    onChangeRequestExamIsPaidField,
    cancelReport,
    infoNotification,
    history,
  },
}) => {
  const labUserTitle = formIsInUpdateMode ? "Atualizar" : "Salvar";
  const submitBtnTitle = loggedUserIsFromClinic ? "Enviar " : labUserTitle;
  const submitBtnPlaceholder = formIsInUpdateMode
    ? "Atualizando.."
    : "Salvando..";

  return (
    <React.Fragment>
      {downloadReportIfTheDeviceIsMobile(
        printedModalUrl,
        isMobile,
        infoNotification
      )}

      <Modal
        bsSize="large"
        show={showPrintReportModal && !isMobile}
        onHide={handlePrintReportModalClose}
      >
        <Modal.Body>
          <embed src={printedModalUrl} width="100%" height="600" />
        </Modal.Body>
        <Modal.Footer>
          <ButtonDefaultMaterial
            title={"Fechar"}
            type={"default"}
            onClick={handlePrintReportModalClose}
          />
        </Modal.Footer>
      </Modal>

      <ReceiveAndAssistModal
        data={{
          showReceiveAndAssistModal: showReceiveAndAssistModal,
          fields: fields,
        }}
        methods={{
          updateField: updateField,
          receiveAndAssistSampleCode: receiveAndAssistSampleCode,
          handleCloseReceiveAndAssistModal: handleCloseReceiveAndAssistModal,
        }}
      />
      <AnimalModal
        updateField={updateField}
        onChangeSpecies={onChangeSpecies}
        onChangeBirthDate={onChangeBirthDate}
        shouldRenderCustomer={true}
        fields={fields}
        customersPromise={null}
        speciesPromise={speciesPromise}
        breedsPromise={breedsPromise}
        pages={animalPages}
        filter={filterAnimals}
        searchedAnimalsPromise={searchedAnimalsPromise}
        showModal={showAnimalModal}
        shouldShowForm={
          shouldShowAnimalForm ||
          history.location.pathname.split("/")[2] != "novo"
        }
        handleShowForm={handleShowAnimalForm}
        handleCloseForm={handleCloseAnimalForm}
        handleClose={handleAnimalModalClose}
        handleTableClicks={handleAnimalTableClicks}
        handlePressSelectBtn={handleSelectAnimal}
        handleAnimalSubmit={handleAnimalSubmit}
        invalidNotification={invalidNotification}
        disableAll={isReadOnly || shouldDisableAnimalFields}
        handleExited={onExitedAnimalModal}
        handleKeepFilterState={handleKeepAnimalFilterState}
        onChangeYearsOrMonths={onChangeYearsOrMonths}
        animalNameInputRef={animalNameInputRef}
        animalNameSearchInputRef={animalNameSearchInputRef}
      />

      <LifeVet.Panel>
        <LifeVet.PanelHeader title={panelTitle}>
          <LifeVet.Row className="text-center">
            <LifeVet.Col>
              <LifeVet.Button
                variant="primary"
                depth="2"
                disabled={disableAll}
                icon={<FontAwesomeIcon size="lg" icon={faAngleLeft} />}
                responsive
                onClick={() => {
                  history.push("/solicitacoes-exame/novo");
                }}
              >
                Nova Solicitação
              </LifeVet.Button>
              <LifeVet.Button
                variant="secondary"
                depth="2"
                disabled={disableAll}
                icon={<FontAwesomeIcon size="lg" icon={faAngleLeft} />}
                responsive
                onClick={() => {
                  history.goBack();
                }}
              >
                Voltar
              </LifeVet.Button>

              <AccessManager
                allowed={[
                  ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                  ACCESS_PROFILES.UNIT_USER,
                ]}
              >
                <LifeVet.Button
                  depth="2"
                  onClick={handleShowReceiveAndAssistModal}
                  variant="secondary"
                  icon={<FontAwesomeIcon size="lg" icon={faCheckSquare} />}
                  responsive
                >
                  Receber e Laudar
                </LifeVet.Button>
              </AccessManager>
            </LifeVet.Col>
          </LifeVet.Row>
        </LifeVet.PanelHeader>
        <LifeVet.PanelBody>
          <LifeVet.Row>
            <LifeVet.Col sm={3}>
              <CustomerSelect
                fields={fields}
                disableAll={disableAll}
                disableCustomerSelect={disableCustomerSelect}
                customersPromise={customersPromise}
                onSelectCustomer={onSelectCustomer}
                $field={$field}
                $validation={$validation}
                setRef={setRef}
              />
            </LifeVet.Col>
            <LifeVet.Col sm={3}>
              <VetSelect
                isReadOnly={isReadOnly}
                fields={fields}
                disableAll={disableAll}
                vetsPromise={vetsPromise}
                updateField={updateField}
                $field={$field}
                $validation={$validation}
                setRef={setRef}
              />
            </LifeVet.Col>
            <LifeVet.Col sm={6}>
              <ClinicalReportField
                isReadOnly={isReadOnly}
                fields={fields}
                disableAll={disableAll}
                updateField={updateField}
                $field={$field}
                $validation={$validation}
                clinicalReportRef={clinicalReportRef}
              />
            </LifeVet.Col>
          </LifeVet.Row>
          <LifeVet.Row>
            <hr />
          </LifeVet.Row>
          <AnimalSection
            name={animal.name}
            species={animal.species}
            breed={animal.breed}
            sexId={animal.sexId}
            years={animal.years}
            months={animal.months}
            owner={animal.owner}
            ageOption={animal.ageOption}
            disabled={
              (loggedUserIsFromClinic && formIsInUpdateMode) ||
              isReadOnly ||
              false
            }
            hasValidation={
              !isCustomerSelected(fields.customerId) &&
              $validation.animalId.show
            }
            validationMsg={$validation.animalId.error.reason}
            handleShowAnimalModal={handleShowAnimalModal}
          />
          <ExamsSection
            isReadOnly={isReadOnly}
            id={id}
            formIsInUpdateMode={formIsInUpdateMode}
            fields={fields}
            disableAll={disableAll}
            updateField={updateField}
            $field={$field}
            $validation={$validation}
            addLabExams={addLabExams}
            printSingleReport={printSingleReport}
            onSelectExamType={onSelectExamType}
            onChangeSampleCode={onChangeSampleCode}
            examTypesPromise={examTypesPromise}
            labExamsPromise={labExamsPromise}
            isExamTypesSelected={isExamTypesSelected}
            onTableDoubleClick={onTableDoubleClick}
            onTableSingleClick={onTableSingleClick}
            removeReportFromTable={removeReportFromTable}
            isCustomerSelected={isCustomerSelected}
            setRequestExamSampleFieldRef={setRequestExamSampleFieldRef}
            updateRequestExamPrice={updateRequestExamPrice}
            updateRequestExamDeadline={updateRequestExamDeadline}
            updateRequestExamField={updateRequestExamField}
            shouldDisableExamsTableFields={shouldDisableExamsTableFields}
            loggedUserIsFromClinic={loggedUserIsFromClinic}
            onChangeRequestExamIsPaidField={onChangeRequestExamIsPaidField}
            cancelReport={cancelReport}
            setRef={setRef}
            isMobile={isMobile}
          />
        </LifeVet.PanelBody>

        <LifeVet.PanelFooter>
          <LifeVet.Button
            disabled={disableAll}
            onClick={() => {
              history.goBack();
            }}
            depth="2"
            variant="secondary"
            float="left"
          >
            Voltar
          </LifeVet.Button>
          <LifeVet.Button
            onClick={() => onSubmit(false)}
            disabled={disableAll || isReadOnly}
            depth="2"
            variant="primary"
          >
            {submittedExamRequestPromise && submittedExamRequestPromise.pending
              ? submitBtnPlaceholder
              : submitBtnTitle}
          </LifeVet.Button>
          {loggedUserIsFromClinic && (
            <LifeVet.Button
              onClick={() => onSubmit(true)}
              disabled={disableAll || isReadOnly}
              depth="2"
              variant="primary"
            >
              {submittedExamRequestPromise &&
              submittedExamRequestPromise.pending
                ? submitBtnPlaceholder
                : "Salvar "}
            </LifeVet.Button>
          )}
          {!formIsInUpdateMode && !loggedUserIsFromClinic && (
            <LifeVet.Button
              onClick={onSubmitAndRestart}
              disabled={disableAll || isReadOnly}
              depth="2"
              variant="primary"
            >
              {submittedExamRequestPromise &&
              submittedExamRequestPromise.pending
                ? submitBtnPlaceholder
                : "Salvar e Criar Nova"}
            </LifeVet.Button>
          )}
          {formIsInUpdateMode && !loggedUserIsFromClinic && (
            <LifeVet.Button
              className={styles.emailBtn}
              disabled={disableAll || isReadOnly}
              onClick={emailExamRequestReports}
              depth="2"
              variant="primary"
            >
              Email
            </LifeVet.Button>
          )}
          {formIsInUpdateMode && (
            <LifeVet.Button
              className={styles.emailBtn}
              disabled={disableAll || isReadOnly}
              onClick={() => {
                printExamRequest(id);
              }}
              depth="2"
              variant="primary"
            >
              Imprimir
            </LifeVet.Button>
          )}
        </LifeVet.PanelFooter>
      </LifeVet.Panel>
    </React.Fragment>
  );
};

export default ExamRequestFormView;
