import React from "react";

/** Internal */
import ButtonSaveMaterial from "../ButtonSaveMaterial";
import { examPlaceModalValidation } from "../PricingTableList/validation";
import { validated } from "react-custom-validation";
import update from "immutability-helper";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";

/** External */
import { Row, Col, Modal } from "react-bootstrap";
import ReactTable from "react-table-v6";
import debounce from "lodash/debounce";

const ExamPlaceModal = ({
  data,
  methods,
  $field,
  $validation,
  $submit,
  $fieldEvent
}) => {
  const { examPlaces, examPlaceModalShow, fields } = data;

  const config = update(
    {
      defaultPageSize: 5,
      defaultSorted: [{ id: "id", desc: true }],
      pageSize: 15,
      resizable: false,
      showPagination: true,
      noDataText: "Nenhum registro".toUpperCase(),
      loadingText: "Carregando",
      className: "-striped -highlight",
      previousText: "Anterior",
      nextText: "Próximo",
      pageText: "Página",
      ofText: "de",
      rowsText: "linhas",
      columns: [],
      showPageSizeOptions: false,
      pageSizeOptions: [5, 10, 15, 20, 25, 50, 100]
    },
    {
      data: {
        $set: examPlaces && examPlaces.value ? examPlaces.value.data : []
      },
      loading: {
        $set: examPlaces && examPlaces.pending
      },
      filterable: {
        $set: true
      },
      manual: {
        $set: true
      },
      onFetchData: {
        $set: debounce(methods.examPlaceModalFilters, 500)
      },
      pages: {
        $set: examPlaces.pages
      },
      pageSize: {
        $set: 5
      },
      defaultSorted: {
        $set: [{ id: "id", desc: false }]
      },
      columns: {
        $set: [
          {
            Header: "Código",
            maxWidth: 100,
            accessor: "id",
            className: "centeredText"
          },
          {
            Header: "Nome da tabela",
            accessor: "name",
            className: "centeredText"
          }
        ]
      }
    }
  );

  const submitData = methods.submitExamPlaceModalData();

  return (
    <Modal show={examPlaceModalShow} onHide={methods.handleCloseExamPlaceModal}>
      <Modal.Header closeButton>
        <Modal.Title>Loca de Exame</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <ReactTable {...config} />
          </Col>
        </Row>
        <Row className="mt-20">
          <LVInputTextUpper
            md={8}
            id="modalExamPlaceName"
            name="modalExamPlaceName"
            title="Novo Local de Exame"
            value={fields.modalExamPlaceName}
            {...$field("modalExamPlaceName", event => {
              methods.fieldChange("modalExamPlaceName", event.target.value);
            })}
            hasValidation={
              $validation &&
              $validation.modalExamPlaceName.show &&
              !$validation.modalExamPlaceName.isValid
            }
            validationReason={
              $validation && $validation.modalExamPlaceName.error.reason
            }
            isValid={$validation && $validation.modalExamPlaceName.isValid}
          />
          <Col md={4}>
            <ButtonSaveMaterial
              title={submitData.btnTitle}
              type="highlight"
              requestingTitle={submitData.placeholder}
              className="mt-15"
              disabled={submitData.pending}
              requesting={submitData.pending}
              onClick={() =>
                $submit(() => methods.handleSubmitExamPlace($fieldEvent))
              }
              block
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const withValidation = validated(examPlaceModalValidation);

export default withValidation(ExamPlaceModal);
