import React from "react";

import ReportPanelMaterial from "../ReportPanelMaterial";
import ReportHeader from "./../ReportHeader";
import LVFileInputMaterial from "./../../old_components/LVFileInputMaterial";
import WysiwygEditor from "./../WysiwygEditor";
import { Row, Col } from "./../../components/grid";

const OutsourcedReportFormReportFormView = ({ data, methods }) => {
  const isFileSelected = data.fields.files.length > 0;

  return (
    <ReportPanelMaterial
      title={data.title}
      backLink={data.backLink}
      shouldDisable={data.disableAll}
      isUpdate={data.isUpdate}
      isRequesting={data.submitData.pending}
      handleSubmit={methods.onSubmit}
      handleSendReportEmail={methods.handleSendReportEmail}
      shouldSendEmail={data.shouldSendEmail}
      cancelReport={methods.cancelReport}
      stopReport={methods.stopReport}
    >
      <Row>
        <Col md={12}>
          <ReportHeader
            data={data.reportHeaderData}
            fields={data.fields}
            fieldChange={methods.fieldChange}
            $field={methods.$field}
            $validation={data.$validation}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <LVFileInputMaterial
            inline
            id={"signaturesUpload"}
            name={"signaturesUpload"}
            allowedExtensions={[".pdf"]}
            setContainerFiles={value => {
              methods.handleChange("files", value);
            }}
            asBase64={true}
            maxFileSize={10000000}
            allowMultiples={true}
            hasImgPreview={false}
            hasPdfPreview={true}
            clearBtnTitle="Remover Laudo"
            title="Selecionar Laudo"
            description="Tamanho máximo aceito para o arquivo: 200kb"
            hasFilesNames={true}
            placeholders={data.files}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <WysiwygEditor
            name="editorState"
            editorState={data.editorState}
            onChange={methods.handleChange}
            onBlur={methods.handleBlur}
            shouldDisable={isFileSelected || data.disableAll}
            shouldDisplayValidation={
              data.$validation.editorState && data.$validation.editorState.show
            }
            validationMessage={
              data.$validation.editorState &&
              data.$validation.editorState.error.reason
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} />
      </Row>
    </ReportPanelMaterial>
  );
};

export default OutsourcedReportFormReportFormView;
