import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import ReportPanelMaterial from "../ReportPanelMaterial";
import DefaultMolecularBiologyContent from "../DefaultMolecularBiologyContent";
import { Col, Row } from "./../../components/grid";

const DefaultMolecularBiologyFormView = ({
  data: {
    panelTitle,
    fields,
    backLink,
    submitData,
    reportHeaderData,
    $validation,
    isUpdate,
    disableAll,
    shouldSendEmail,
    materialsSuggestions
  },
  methods: {
    onSubmit,
    $field,
    fieldChange,
    handleChange,
    handleBlur,
    calcMolecularBiologyResult,
    suggestionsFilter,
    handleSendReportEmail,
    cancelReport,
    stopReport
  }
}) => (
  <ReportPanelMaterial
    title={panelTitle}
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitData && submitData.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <DefaultMolecularBiologyContent
      materialsFieldName={"materials"}
      materials={fields.materials}
      materialsSuggestions={materialsSuggestions}
      materialsSuggestionsFilter={suggestionsFilter}
      materialsHasValidation={$validation.materials.show}
      materialsValidationReason={$validation.materials.error.reason}
      techniqueFieldName="technique"
      technique={fields.technique}
      techniqueHasValidation={$validation.technique.show}
      techniqueValidationReason={$validation.technique.error.reason}
      qualitativeResultFieldName="qualitativeResult"
      qualitativeResult={fields.qualitativeResult}
      qualitativeResultHasValidation={$validation.qualitativeResult.show}
      qualitativeResultValidationReason={
        $validation.qualitativeResult.error.reason
      }
      copyValuesFieldName="copyValues"
      copyValues={fields.copyValues}
      copyValuesHasValidation={$validation.copyValues.show}
      copyValuesValidationReason={$validation.copyValues.error.reason}
      precisionFieldName="precision"
      precision={fields.precision}
      precisionHasValidation={$validation.precision.show}
      precisionValidationReason={$validation.precision.error.reason}
      resultFieldName="result"
      result={calcMolecularBiologyResult(
        fields.copyValues,
        fields.precision,
        fields.technique
      )}
      obsFieldName="obs"
      obs={fields.obs}
      shouldDisable={disableAll}
      handleChange={handleChange}
      handleBlur={handleBlur}
    />
  </ReportPanelMaterial>
);

export default DefaultMolecularBiologyFormView;
