import { validated } from "react-custom-validation";

import { required, greaterThan } from "../../assets/js/validationFunctions";

import { ZERO } from "../../assets/js/Consts.js";

const validation = validated(props => {
  const { options, vetSignerId } = props.data.fields;
  const { completeValidation } = props.data;
  let fields = [];
  let validations = {};

  options.forEach((option, index) => {
    const fieldName = `result-${index}`;

    const { result } = option;

    validations[fieldName] = {
      rules: completeValidation ? [[required, result, "Obrigatório"]] : []
    };

    fields.push(fieldName);
  });

  validations["vetSignerId"] = {
    rules: [[greaterThan, vetSignerId, ZERO, "Obrigatório"]]
  };

  fields.push("vetSignerId");

  return { fields, validations };
});

export default validation;