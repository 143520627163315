
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Panel, PanelBody, PanelFooter, PanelHeader } from "../../components";
import GetUserTokenBasedOnEnv from '../../services/get-user-token-based-on-env';

export function DailyOutsourcedExamsSection(props: any) {
  const [from,setFrom] = useState('')
  const [to, setTo] = useState('')
  
  async function downloadReport() {
    const response = await fetch(process.env.REACT_APP_PROXY + '/request-exams/daily-report', {
      method: 'POST',
      headers: {
        'authorization': GetUserTokenBasedOnEnv(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        from,
        to
      })
    })

    if (response.ok) {
      
      const data = await response.json();
      const fileName = data.file;

      if (fileName) {
        const link = document.createElement('a');
        link.href = process.env.REACT_APP_PROXY + '/'+fileName;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        link.click();

        link.remove();
        
        props.notificationSystem.add({
          title:  "Sucesso",
          message: "O relatório será baixado em instantes.",
          level: "success",
          position: "tr",
          autoDismiss: 5,
        }); 
      } else {
        props.notificationSystem.add({
          title:  "Erro",
          message: "Não foi possível baixar o relatório. Tente novamente mais tarde.",
          level: "error",
          position: "tr",
          autoDismiss: 5,
        }); 
      }

    } else {
        props.notificationSystem.add({
          title:  "Erro",
          message: "Não foi possível baixar o relatório. Tente novamente mais tarde.",
          level: "error",
          position: "tr",
          autoDismiss: 5,
        });
    }
  }

  function handleChangeFrom(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    setFrom(value)
  }

  function handleChangeTo(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    setTo(value)
  }

   return (
     <Panel
      title="Teste"
     >
       <PanelHeader title="Relatório de Exames Terceirizados" />
       <PanelBody>
         <form>
           <div>
             <label htmlFor="from">
               Data Inicial
             </label>
           <input value={from} onChange={handleChangeFrom} type="date" name="from" id="from" />
           </div>
           <div>
             <label htmlFor='to'>
                Data Final
             </label>
             <input value={to} onChange={handleChangeTo} type="date" name="to" id="to" />

           </div>
         </form>
         <h1>Relatório dos exames feitos na unidade (terceirizados)</h1>
         <span>Deixe em branco para buscar o relatório diário</span>
       </PanelBody>
       
        <PanelFooter>
          <Button
            float="right"
            type="submit"
            depth="2"
            variant="primary"
           title="Baixar"
           onClick={downloadReport	}
          >
            Baixar Relatório
          </Button>
        </PanelFooter>
     </Panel>
   );
}