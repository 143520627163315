import React from 'react';

/**
 * Internal
 */
import FlatPanelMaterial from './../FlatPanelMaterial';
import HeartwormDiseaseContent from './../HeartwormDiseaseContent';

/**
 * External
 */
import { Col, Row } from './../../components/grid';

const HeartwormDiseasePanel = ( {
  title,
  materialId,
  isPositive,
  materialIdFieldName,
  isPositiveFieldName,
  materialIdHasValidation,
  isPositiveHasValidation,
  materialIdValidationReason,
  isPositiveValidationReason,
  obsFieldName,
  obs,
  shouldDisable,
  handleChange,
  handleBlur
} ) =>
  <FlatPanelMaterial title={ title } >
    <Row>
      <Col md={ 12 }>
        <HeartwormDiseaseContent
          materialId={ materialId }
          isPositive={ isPositive }
          materialIdFieldName={ materialIdFieldName }
          isPositiveFieldName={ isPositiveFieldName }
          materialIdHasValidation={ materialIdHasValidation }
          isPositiveHasValidation={ isPositiveHasValidation }
          materialIdValidationReason={ materialIdValidationReason }
          isPositiveValidationReason={ isPositiveValidationReason }
          obsFieldName={ obsFieldName }
          obs={ obs }
          shouldDisable={ shouldDisable }
          handleChange={ handleChange }
          handleBlur={ handleBlur } />
      </Col>
    </Row>
  </FlatPanelMaterial>

export default HeartwormDiseasePanel;