import React, { Component } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import InputMaterial from '../InputMaterial';
import ButtonSaveMaterial from './../ButtonSaveMaterial';
import { PaymentMethodModalValidation } from './../PaymentMethodList/validation';
import { validated } from 'react-custom-validation';

/**
 * @author Victor Heringer
 * 
 * Modal to receive and assist an exam request
 */
class PaymentMethodModal extends Component {

  componentDidMount() {
    window.addEventListener('keydown', this.handleKey);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKey);
  }

  handleKey = e => {
    if (e.keyCode == 13) {
      this.props.$submit(() => 
        this.props.methods.submitData(this.props.data.fields)
      ); 
    }
  }

  render() {

    const {
      $field,
      $validation,
      $submit,
      $fieldEvent,
      methods,
      data
    } = this.props;

    return(
      <div>
        <Modal show={data.showPaymentMethodModal} onHide={methods.handleClosePaymentMethodModal}>
          <Modal.Header closeButton>
            <Modal.Title>Forma de Pagamento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <InputMaterial
                md={7}
                id="name"
                name="name"
                title="Forma de Pagamento"
                autofocus={true}
                disabled={ data.isFetching }
                value={data.fields.name}
                {...$field('name', (event) => {
                  methods.fieldChange('name', event.target.value);
                })}
                hasValidation={
                  $validation &&
                  $validation.name.show &&
                  !$validation.name.isValid
                }
                validationReason={
                  $validation &&
                  $validation.name.error.reason
                }
                isValid={
                  $validation &&
                  $validation.name.isValid
                }
              />
              <Col md={5}>
                <ButtonSaveMaterial
                  className="mt-15"
                  title="Salvar"
                  type="highlight"
                  position="top"
                  disabled={ data.isFetching }
                  right={true}
                  id="btn_receive_and_assist"
                  block
                  onClick={
                    event => $submit(
                      () => methods.submitData(data.fields)
                    )
                  }
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const withValidation = validated( PaymentMethodModalValidation );

export default withValidation( PaymentMethodModal );