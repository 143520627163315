import React from "react";
import styled from "styled-components";
import { blueberry500, granadilla100 } from "./../colors";
import { mainFont } from "./../fonts";

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  margin-bottom: -25px;
  justify-content: flex-end;
`;

const StyledHeaderTitleWrapper = styled.div`
  max-width: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const StyledTitle = styled.div`
  ${mainFont}
  margin: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${blueberry500};
  color: ${granadilla100};
  padding: 10px 8px;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const StyledHeaderContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const PanelHeader = (props) => (
  <StyledHeader {...props}>
    <StyledHeaderTitleWrapper>
      <StyledTitle>{props.title}</StyledTitle>
    </StyledHeaderTitleWrapper>
    <StyledHeaderContent>{props.children}</StyledHeaderContent>
  </StyledHeader>
);

export default PanelHeader;
