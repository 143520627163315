import React from "react";
import { ENV_TYPES, LOCAL_STORAGE_KEYS } from "./../../consts/";

const GetUserTokenBasedOnEnv = () => {
  const env = process.env.REACT_APP_ENV;

  return env === ENV_TYPES.LOCAL
    ? window.localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)
    : window.auth && window.auth.token;
};

export default GetUserTokenBasedOnEnv;
