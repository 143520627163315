import React from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";

/**
 * Assets
 */
import "./index.css";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faSpinner from "@fortawesome/fontawesome-free-solid/faSpinner";

/**
 * @author Victor Heringer
 *
 * Material button with stylized for confirmation actions
 */

const ButtonSaveMaterial = ({
  id,
  title,
  requestingTitle,
  type,
  requesting,
  onClick,
  right,
  disabled,
  className,
  responsive,
  block,
  keyCode,
  onKeyPress
}) => (
    <ButtonDefaultMaterial
      block={block}
      id={id}
      onClick={onClick}
      type={type}
      responsive={responsive}
      title={requesting ? requestingTitle : title}
      className={className + " btn-save" + (right ? " btn-right" : "")}
      disabled={requesting || disabled}
      icon={
        requesting ? (
          <FontAwesomeIcon className="spin" size="lg" icon={faSpinner} />
        ) : (
          ""
        )
      }
    keyCode={keyCode} 
    onKeyPress={onKeyPress}

    >
    </ButtonDefaultMaterial>  
);

ButtonSaveMaterial.defaultProps = {
  block: false
};

ButtonSaveMaterial.propTypes = {
  requesting: PropTypes.bool,
  type: PropTypes.oneOf(["default", "highlight"]),
  onClick: PropTypes.func,
  right: PropTypes.bool
};

export default ButtonSaveMaterial;
