import React, { forwardRef, InputHTMLAttributes } from "react"
import { FieldError } from "react-hook-form"
import { StyledErrorMessage, StyledInput } from "./styles"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError
}

export const Input = forwardRef<HTMLInputElement, InputProps>(({ error, ...props }, ref) => {
   return (
     <div>
      <StyledInput ref={ref} error={!!error} {...props} />

      {error && <StyledErrorMessage>{error.message}</StyledErrorMessage>}
    </div>
   )
  }
)