import React from 'react';

/**
 * @author Victor Heringer
 * 
 * Title is acctuly the label for the input
 * 
 * @prop {String} text
 */
const Title = ({ text, styles, disabled }) => {
  const stylesDisabled = disabled ? { 'background': '#EEEEEE' } : {};
  return <label style={stylesDisabled} className={styles}>{text}</label>;
}

export default Title;
