import React, { Component } from "react";

/**
 * Internal
 */
import HemogramWithBiochemistryView from "./../HemogramWithBiochemistryView";
import connectWithEndpoint from "./requests.js";
import validation from "./validation.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import {
  containerState,
  reportState,
  hemogramState,
} from "../../modules/factories/default-states.js";
import { PromiseUtil, FunctionUtil } from "../../useful";
import {
  EXAM_ID_BY_NAME,
  REQUEST_EXAM_STATE_ID_BY_NAME,
} from "../../assets/js/Consts.js";
import { REPORT_DEFAULT_VALUES } from "./../../consts";
import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";
import {
  isAnemia,
  isPolicitemia,
  bloodCellsDiagnosis,
  toggleIntenseLeucopeny,
  openCounter,
  closeCounter,
  handleKeyPress,
  enableCounterSound,
  handleFinishedPlaying,
  calcMCV,
  calcMCHC,
  calcTotal,
  calcAbsolute,
  erythrogramDiagnosis,
  mcvDiagnosis,
  mchcDiagnosis,
  leukogramDiagnosis,
  zeroLeukogram,
  incrementField,
  globalLeukDiagnosis,
  resetLeukogram,
  finalGlobalLeukogram,
  isNeutropLeukocytosis,
  diagnosis,
  displayResults,
  DNNE,
  plateletsDiagnosis,
  zeroIfIntenseLeucopeny,
  createHemogramObj,
  createHemogramUpdateState,
  createHemogramRefObj,
  createBiochemistryRefObj,
  createReportObj,
  createPlateletsObj,
  createErythrogramObj,
  createLeukogramObj,
  getBiochemistryTableObjs,
  biochemWithValidationData,
} from "../../assets/js/reportFunctions.js";
import Notifications from "react-notification-system-redux";
import { connect as reduxConnect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
import { DEFAULT_INVALID_MESSAGE } from "../../consts/notifications";
import { FocusManagerConfig, hemogramConfigOption } from "./../../services";

/**
 * External
 */
import update from "immutability-helper";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

const View = ({ data, methods, $field, $validation, $submit, $fieldEvent }) => {
  const rows = data.rows;

  const biochemistryTableData = biochemWithValidationData(rows, $validation);

  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;

    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };

  const onClickCounter = () => {
    methods.openCounter();
    methods.zeroLeukogram();
  };

  const handleChange = (field, value) => {
    $fieldEvent("change", field);
    methods.fieldChange(field, value);
  };

  const handleBlur = (event) => {
    $fieldEvent("blur", event.target.name);
  };

  const newData = {
    biochemistryTableData: biochemistryTableData,
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    handleChange: handleChange,
    handleBlur: handleBlur,
    $field: $field,
    onClickCounter: onClickCounter,
    ...methods,
  };

  return (
    <>
      <HemogramWithBiochemistryView
        title="Perfil Geriátrico"
        data={newData}
        methods={newMethods}
      />
      <Notifications notifications={methods.notifications} />
    </>
  );
};

const ValidatedView = validation(View);

class ProfileGeriatricReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        ...reportState(),
        ...hemogramState(),
      },
      ...containerState(),
      shouldSendEmail: false,
    };

    this.state.fields.urea = "";
    this.state.fields.creatinine = "";
    this.state.fields.altTgp = "";
    this.state.fields.glucose = "";
    this.state.fields.biochemObs = "";

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.successNotification = successNotification.bind(this);
    this.openCounter = openCounter.bind(this);
    this.closeCounter = closeCounter.bind(this);
    this.handleKeyPress = handleKeyPress.bind(this);
    this.enableCounterSound = enableCounterSound.bind(this);
    this.handleFinishedPlaying = handleFinishedPlaying.bind(this);
    this.isAnemia = isAnemia.bind(this);
    this.isPolicitemia = isPolicitemia.bind(this);
    this.bloodCellsDiagnosis = bloodCellsDiagnosis.bind(this);
    this.calcMCV = calcMCV.bind(this);
    this.calcMCHC = calcMCHC.bind(this);
    this.calcTotal = calcTotal.bind(this);
    this.calcAbsolute = calcAbsolute.bind(this);
    this.erythrogramDiagnosis = erythrogramDiagnosis.bind(this);
    this.mcvDiagnosis = mcvDiagnosis.bind(this);
    this.mchcDiagnosis = mchcDiagnosis.bind(this);
    this.leukogramDiagnosis = leukogramDiagnosis.bind(this);
    this.zeroLeukogram = zeroLeukogram.bind(this);
    this.incrementField = incrementField.bind(this);
    this.globalLeukDiagnosis = globalLeukDiagnosis.bind(this);
    this.resetLeukogram = resetLeukogram.bind(this);
    this.finalGlobalLeukogram = finalGlobalLeukogram.bind(this);
    this.isNeutropLeukocytosis = isNeutropLeukocytosis.bind(this);
    this.plateletsDiagnosis = plateletsDiagnosis.bind(this);
    this.toggleIntenseLeucopeny = toggleIntenseLeucopeny.bind(this);
    this.zeroIfIntenseLeucopeny = zeroIfIntenseLeucopeny.bind(this);
    this.diagnosis = diagnosis.bind(this);
    this.displayResults = displayResults.bind(this);
    this.rows = this.rows.bind(this);
    this.biochemRefs = this.biochemRefs.bind(this);
    this.DNNE = DNNE.bind(this);
    this.createPlateletsObj = createPlateletsObj.bind(this);
    this.createErythrogramObj = createErythrogramObj.bind(this);
    this.createLeukogramObj = createLeukogramObj.bind(this);
    this.createHemogramObj = createHemogramObj.bind(this);
    this.createReportObj = createReportObj.bind(this);
    this.createObj = this.createObj.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        if (xhr.response.status === 200) {
          const obj = data;
          this.fillFields(obj);
        }
      };

      this.props.withId(id, then);
    }
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    let editState = createHemogramUpdateState(obj);

    editState.fields.urea = { $set: obj.urea };
    editState.fields.creatinine = { $set: obj.creatinine };
    editState.fields.altTgp = { $set: obj.altTgp };
    editState.fields.glucose = { $set: obj.glucose };
    editState.fields.biochemObs = {
      $set: obj.biochemistryObs,
    };

    const fillObject = (state) => update(state, editState);

    this.setState(fillObject);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Obtains the submit object
   *
   * @return {Object}
   *
   */
  createObj() {
    const { urea, creatinine, altTgp, glucose, biochemObs } = this.state.fields;

    const hemogramObj = {
      id: this.getId(),
      ...this.createReportObj(),
      ...this.createHemogramObj(),
    };

    return {
      ...hemogramObj,
      urea: urea,
      creatinine: creatinine,
      altTgp: altTgp,
      glucose: glucose,
      biochemObs: biochemObs,
    };
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (!this.state.completeValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" ? null : obj[i];
      }
    }

    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  biochemRefs() {
    const { biochemRefsPromise } = this.props;

    return PromiseUtil.extractValue(
      biochemRefsPromise,
      REPORT_DEFAULT_VALUES.BIOCHEMISTRY_REF_DEFAULT,
      createBiochemistryRefObj
    );
  }

  rows() {
    const biochemRefs = this.biochemRefs();

    const values = [
      { id: EXAM_ID_BY_NAME["UREA"], result: this.state.fields.urea },
      {
        id: EXAM_ID_BY_NAME["CREATININE"],
        result: this.state.fields.creatinine,
      },
      { id: EXAM_ID_BY_NAME["ALT_TGP"], result: this.state.fields.altTgp },
      { id: EXAM_ID_BY_NAME["GLUCOSE"], result: this.state.fields.glucose },
    ];

    return getBiochemistryTableObjs(values, biochemRefs);
  }

  render() {
    const { examRequestId } = this.props.match.params;
    const backLink = "/solicitacoes-exame/" + examRequestId;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    const {
      refsPromise,
      reportSubmitPromise,
      objPromise,
      examsPromise,
      requestExamStatesPromise,
      reportHeaderPromise,
    } = this.props;

    const {
      fields,
      isCounterOpen,
      isIntenseLeucopeny,
      shouldBlockCounterSound,
      showTotalValidation,
    } = this.state;

    const isUpdate = this.isUpdate();

    // Loader
    let editFetches = null;
    const shouldCheckEditFetches = isUpdate && objPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([refsPromise, objPromise]);
    }

    const shouldDisplayLoader = isUpdate
      ? editFetches && editFetches.pending
      : refsPromise.pending;
    if (shouldDisplayLoader) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );

    const requestExamStates = PromiseUtil.extractValue(
      requestExamStatesPromise,
      []
    );

    const biochemistryExams = PromiseUtil.extractValue(examsPromise, []);

    const refs = PromiseUtil.extractValue(
      refsPromise,
      REPORT_DEFAULT_VALUES.HEMOGRAM_REF_DEFAULT,
      createHemogramRefObj
    );

    const total = this.calcTotal();

    const reportPromiseData = PromiseUtil.extractValue(objPromise, {});

    const data = {
      isUpdate: isUpdate,
      fields: fields,
      backLink: backLink,
      rows: this.rows(),
      refs: refs,
      biochemistryExams: biochemistryExams,
      examRequestId: examRequestId,
      isCounterOpen: isCounterOpen,
      requestExamStates: requestExamStates,
      shouldBlockCounterSound: shouldBlockCounterSound,
      showTotalValidation: showTotalValidation,
      submitPromise: reportSubmitPromise,
      disableAll: reportSubmitPromise && reportSubmitPromise.pending,
      total: isIntenseLeucopeny ? "" : total,
      mchc: this.calcMCHC(),
      completeValidation: this.state.completeValidation,
      reportHeaderData: reportHeaderFactory(reportHeaderData),
      reportData: reportPromiseData,
    };

    // Methods
    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      openCounter: this.openCounter,
      resetLeukogram: this.resetLeukogram,
      handleKeyPress: this.handleKeyPress,
      calcAbsolute: this.calcAbsolute,
      toggleIntenseLeucopeny: this.toggleIntenseLeucopeny,
      invalidNotification: () => {
        this.props.errorNotification(DEFAULT_INVALID_MESSAGE);
      },
      notifications: this.props.notifications,
      finalGlobalLeukogram: this.finalGlobalLeukogram,
      zeroLeukogram: this.zeroLeukogram,
      showvalidationFunctions: this.showvalidationFunctions,
      erythrogramDiagnosis: this.erythrogramDiagnosis,
      leukogramDiagnosis: this.leukogramDiagnosis,
      calcMCV: this.calcMCV,
      calcMCHC: this.calcMCHC,
      calcTotal: this.calcTotal,
      plateletsDiagnosis: this.plateletsDiagnosis,
      handleFinishedPlaying: this.handleFinishedPlaying,
      closeCounter: this.closeCounter,
      resultChange: this.resultChange,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      cancelReport: this.cancelReport,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      handleSendReportEmail: this.handleSendReportEmail,
      stopReport: this.stopReport,
      handleFocus: this.props.handleFocus,
      setRef: this.props.setRef,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);

const focusManager = FocusManagerConfig(
  hemogramConfigOption.config,
  hemogramConfigOption.initialFocus
);

export default FunctionUtil.compose(
  focusManager,
  connectWithEndpoint,
  connectWithRedux
)(ProfileGeriatricReportForm);
