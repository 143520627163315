import React from "react";

/** Internal */
import FlatPanelMaterial from "./../FlatPanelMaterial";
import ReportHeader from "./../ReportHeader";
import InputMaterial from "./../InputMaterial";
import ObsPanel from "./../ObsPanel";
import ResultPanel from "./../ResultPanel";
import NumberInputMaterial from "./../NumberInputMaterial";
import NumberInputWithReference from "./../NumberInputWithReference";
import SelectMaterial from "./../SelectMaterial";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";
import SelectMaterial2 from "../SelectMaterial2";

const AsciticThoracicFluidReportFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title={data.panelTitle}
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FlatPanelMaterial title="Físico">
          <Row>
            <Col md={12}>
              <NumberInputWithReference
                fieldTitle={"Densidade"}
                fieldName={"density"}
                value={data.fields.density}
                shouldDisable={data.disableAll}
                hasValidation={data.$validation.density.show}
                validationReason={data.$validation.density.error.reason}
                fieldChange={methods.fieldChange}
                $field={methods.$field}
                valueMin={data.refs.densityMin}
                valueMax={data.refs.densityMax}
                decimalScale={1}
                unit="kg/m3"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <hr />
            </Col>
          </Row>
          <Row>
            <NumberInputMaterial
              name="volume"
              md={4}
              title="Volume"
              allowNegative={false}
              decimalScale={1}
              fieldChange={methods.fieldChange}
              value={data.fields.volume}
              disabled={data.disableAll}
              {...methods.$field("volume")}
              hasValidation={data.$validation.volume.show}
              validationReason={data.$validation.volume.error.reason}
              hasValidationRecoil
            />
            <SelectMaterial
              id="colorId"
              title="Cor"
              md={4}
              name="colorId"
              options={data.colorOptions}
              value={data.fields.colorId}
              searchable={true}
              disabled={data.disableAll}
              hasValidationRecoil
              {...methods.$field("colorId", event => {
                methods.fieldChange("colorId", event);
              })}
              hasValidation={
                data.$validation &&
                data.$validation.colorId.show &&
                !data.$validation.colorId.isValid
              }
              validationReason={
                data.$validation && data.$validation.colorId.error.reason
              }
              isValid={data.$validation && data.$validation.colorId.isValid}
            />
            <SelectMaterial
              id="smellId"
              title="Odor"
              md={4}
              name="smellId"
              options={data.smellOptions}
              value={data.fields.smellId}
              searchable={true}
              disabled={data.disableAll}
              hasValidationRecoil
              {...methods.$field("smellId", event => {
                methods.fieldChange("smellId", event);
              })}
              hasValidation={
                data.$validation &&
                data.$validation.smellId.show &&
                !data.$validation.smellId.isValid
              }
              validationReason={
                data.$validation && data.$validation.smellId.error.reason
              }
              isValid={data.$validation && data.$validation.smellId.isValid}
            />
            <SelectMaterial
              id="aspectId"
              title="Aspecto"
              md={4}
              name="aspectId"
              options={data.aspectOptions}
              value={data.fields.aspectId}
              searchable={true}
              disabled={data.disableAll}
              hasValidationRecoil
              {...methods.$field("aspectId", event => {
                methods.fieldChange("aspectId", event);
              })}
              hasValidation={
                data.$validation &&
                data.$validation.aspectId.show &&
                !data.$validation.aspectId.isValid
              }
              validationReason={
                data.$validation && data.$validation.aspectId.error.reason
              }
              isValid={data.$validation && data.$validation.aspectId.isValid}
            />
            <SelectMaterial
              id="sedimentId"
              title="Sedimento"
              md={4}
              name="sedimentId"
              options={data.sedimentOptions}
              value={data.fields.sedimentId}
              searchable={true}
              disabled={data.disableAll}
              hasValidationRecoil
              {...methods.$field("sedimentId", event => {
                methods.fieldChange("sedimentId", event);
              })}
              hasValidation={
                data.$validation &&
                data.$validation.sedimentId.show &&
                !data.$validation.sedimentId.isValid
              }
              validationReason={
                data.$validation && data.$validation.sedimentId.error.reason
              }
              isValid={data.$validation && data.$validation.sedimentId.isValid}
            />
            <SelectMaterial
              id="supernatantId"
              title="Cor de Sobrenadante"
              md={4}
              name="supernatantId"
              options={data.supernatantOptions}
              value={data.fields.supernatantId}
              searchable={true}
              disabled={data.disableAll}
              hasValidationRecoil
              {...methods.$field("supernatantId", event => {
                methods.fieldChange("supernatantId", event);
              })}
              hasValidation={
                data.$validation &&
                data.$validation.supernatantId.show &&
                !data.$validation.supernatantId.isValid
              }
              validationReason={
                data.$validation && data.$validation.supernatantId.error.reason
              }
              isValid={
                data.$validation && data.$validation.supernatantId.isValid
              }
            />
          </Row>
        </FlatPanelMaterial>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <FlatPanelMaterial title="Químico">
          <div className="col-xs-5">
            <SelectMaterial2
              title="Proteínas"
              name="proteins"
              options={data.proteinsOptions}
              value={data.fields.proteins}
              isSearchable={true}
              hasValidationRecoil={false}
              shouldDisable={data.disableAll}
              onChange={methods.fieldChange}
              hasValidation={data.$validation.proteins.show}
              validationReason={data.$validation.proteins.error.reason}
            />
          </div>
          <div className="col-xs-5">
            <SelectMaterial2
              title="Glicose"
              name="glucose"
              options={data.glucoseOptions}
              value={data.fields.glucose}
              isSearchable={true}
              hasValidationRecoil={false}
              shouldDisable={data.shouldDisable}
              onChange={methods.fieldChange}
              hasValidation={data.$validation.glucose.show}
              validationReason={data.$validation.glucose.error.reason}
            />
          </div>
          <SelectMaterial
            id="hiddenBloodId"
            title="Sangue Oculto"
            md={6}
            name="hiddenBloodId"
            options={data.hiddenBloodOptions}
            value={data.fields.hiddenBloodId}
            searchable={true}
            disabled={data.disableAll}
            hasValidationRecoil
            {...methods.$field("hiddenBloodId", event => {
              methods.fieldChange("hiddenBloodId", event);
            })}
            hasValidation={
              data.$validation &&
              data.$validation.hiddenBloodId.show &&
              !data.$validation.hiddenBloodId.isValid
            }
            validationReason={
              data.$validation && data.$validation.hiddenBloodId.error.reason
            }
            isValid={data.$validation && data.$validation.hiddenBloodId.isValid}
          />
          <NumberInputMaterial
            name="ph"
            md={6}
            title="Ph"
            allowNegative={false}
            decimalScale={1}
            fieldChange={methods.fieldChange}
            value={data.fields.ph}
            disabled={data.disableAll}
            {...methods.$field("ph")}
            hasValidation={data.$validation.ph.show}
            validationReason={data.$validation.ph.error.reason}
            hasValidationRecoil
          />
          <InputMaterial
            name="coagulation"
            md={6}
            title="Coagulação"
            fieldChange={methods.fieldChange}
            value={data.fields.coagulation}
            disabled={data.disableAll}
            {...methods.$field("coagulation", event => {
              methods.fieldChange("coagulation", event.target.value);
            })}
            hasValidation={data.$validation.coagulation.show}
            validationReason={data.$validation.coagulation.error.reason}
            hasValidationRecoil
          />
        </FlatPanelMaterial>
      </Col>
      <Col md={6}>
        <FlatPanelMaterial title="Contagem">
          <NumberInputMaterial
            name="hemocyte"
            md={6}
            title="Hemácias"
            allowNegative={false}
            decimalScale={5}
            fieldChange={methods.handleHemocyteChange}
            value={data.fields.hemocyte}
            disabled={data.disableAll}
            {...methods.$field("hemocyte")}
            hasValidation={data.$validation.hemocyte.show}
            validationReason={data.$validation.hemocyte.error.reason}
            hasValidationRecoil
            decimalScale={4}
          />
          <NumberInputMaterial
            name="hemocyteResult"
            md={6}
            title="Resultado"
            allowNegative={false}
            decimalScale={1}
            fieldChange={methods.fieldChange}
            value={data.fields.hemocyteResult}
            disabled
            {...methods.$field("hemocyteResult")}
            hasValidation={data.$validation.hemocyteResult.show}
            validationReason={data.$validation.hemocyteResult.error.reason}
            hasValidationRecoil
            decimalScale={0}
          />
          <NumberInputMaterial
            name="leukocytes"
            md={12}
            title="Leucócitos"
            allowNegative={false}
            decimalScale={1}
            fieldChange={methods.fieldChange}
            value={data.fields.leukocytes}
            disabled={data.disableAl}
            {...methods.$field("leukocytes")}
            hasValidation={data.$validation.leukocytes.show}
            validationReason={data.$validation.leukocytes.error.reason}
            hasValidationRecoil
            decimalScale={0}
          />
        </FlatPanelMaterial>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ResultPanel
          name="result"
          value={data.fields.result}
          shouldDisable={data.disableAll}
          handleChange={methods.handleChange}
          handleBlur={methods.handleBlur}
          hasValidation={data.$validation.result.show}
          validationReason={data.$validation.result.error.reason}
          decimalScale={0}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ObsPanel
          name="obs"
          value={data.fields.obs}
          shouldDisable={data.disableAll}
          handleChange={methods.handleChange}
          handleBlur={methods.handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default AsciticThoracicFluidReportFormView;
