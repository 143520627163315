import { validated } from 'react-custom-validation';

import { required } from '../../assets/js/validationFunctions';
import { commonReportRules, fibrinogenRules } from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    result,
    obs,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const {completeValidation } = props.data;

  let fields = [
    'result',
    'obs',
    'requestExamState',
    'vetSignerId'
  ];

  const commonValidation = commonReportRules( vetSignerId, requestExamState );

  const fibrinogenValidation = fibrinogenRules(
    'result',
    result,
    'obs',
    obs,
    completeValidation
  );

  const validations = {
    ...commonValidation,
    ...fibrinogenValidation
  };

  return { fields, validations };
} );

export default validation;