export const MOLECULARBIOLOGYPCR = 1;
export const BIOCHEMISTRY = 2;
export const CYTOPATHOLOGICAL = 3;
export const DIVERSE = 4;
export const ENDOCRINOLOGY_CHEMILUMINESCENCE = 5;
export const ENDOCRINOLOGY_RADIOIMMUNOASSAY = 6;
export const HEMATOLOGY = 7;
export const HISTOPATHOLOGY = 8;
export const IMMUNOLOGY = 9;
export const MICROBIOLOGY = 10;
export const PARASITOLOGY = 11;
export const URINALYSIS = 12;
export const PROFILE = 13;

