import { StringUtil } from "./../../useful";

const connectWithEndpoint = () => ({
  searchForPotentialInvoices: (options, success, error) => {
    let queryString = StringUtil.buildQueryString(options);
    return {
      searched: {
        url: `/invoices/search${queryString}`,
        method: "GET",
        then: success,
        catch: error
      }
    };
  },
  fetchInvoicesCsv : (options, sussess, error) => {
    const queryString = StringUtil.buildQueryString(options);
    return {
      invoicesCsv: {
        url: `/invoices/csv/${queryString}`,
        method: "GET",
        then: sussess,
        catch: error

      }
    };
  },
  searchInvoices: (options, success, error) => {
    let queryString = StringUtil.buildQueryString(options);
    return {
      searched: {
        url: `/invoices${queryString}`,
        method: "GET",
        then: success,
        catch: error
      }
    };
  },
  getInvoice: (invoiceId, success, error) => ({
    fetched: {
      url: `/invoices/${invoiceId}`,
      method: "GET",
      then: success,
      catch: error
    }
  }),
  processInvoices: (invoices, success, error) => ({
    processed: {
      url: `/invoices`,
      method: "POST",
      body: JSON.stringify(invoices),
      then: success,
      catch: error
    }
  }),
  previewInvoice: (invoice, success, error) => ({
    previewed: {
      url: `/invoices/preview`,
      method: "POST",
      body: JSON.stringify(invoice),
      then: success,
      catch: error
    }
  }),
  printInvoice: (invoiceId, success, error) => ({
    printedInvoice: {
      url: `/invoices/${invoiceId}/print`,
      method: "GET",
      then: success,
      catch: error
    }
  }),
  mailInvoice: (invoiceId, success, error) => ({
    mailedInvoice: {
      url: `/mail/invoice/${invoiceId}`,
      method: "POST",
      then: success,
      catch: error
    }
  }),
  deleteInvoice: (invoiceId, success, error) => ({
    deletedInvoice: {
      url: `/invoices/${invoiceId}`,
      method: "DELETE",
      then: success,
      catch: error
    }
  }),
  registerPaymentToInvoice: (invoiceId, invoicePayment, success, error) => ({
    registeredPayment: {
      url: `/invoices/${invoiceId}/invoice-payments`,
      method: "POST",
      body: JSON.stringify(invoicePayment),
      then: success,
      catch: error
    }
  }),
  deletePaymentFromInvoice: (invoicePaymentId, success, error) => ({
    deletedPayment: {
      url: `/invoice-payments/${invoicePaymentId}`,
      method: "DELETE",
      then: success,
      catch: error
    }
  }),
  regions: "/customers/lab/regions",
  customers: "/customers/all"
});

export default connectWithEndpoint;
