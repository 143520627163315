import React, { Component } from "react";

/**
 * Internal
 */
import AnimalFormView from "./../AnimalFormView";
import validation from "./validation.js";
import { connect } from "react-redux";
import connectWithEndpoint from "./requests.js";
import moment from "../../assets/js/moment.js";
import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
} from "../../assets/js/containerFunctions.js";

import {
  onChangeSpecies,
  birthDateChange,
  yearsMonthsChange,
} from "../../assets/js/animalFunctions.js";

/**
 * External
 */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import { Redirect } from "react-router-dom";
import FadeIn from "react-fade-in";
import { BounceLoader } from "react-spinners";

const View = ({ data, methods, $field, $validation, $submit }) => {
  const onSubmit = (e) => {
    e.preventDefault();
    $submit(methods.handleSubmit, methods.invalidNotification);
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    $field: $field,
    ...methods,
  };

  return <AnimalFormView data={newData} methods={newMethods} />;
};

const ValidatedView = validation(View);

class AnimalForm extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        id: props.match.params.id ? props.match.params.id : 0,
        customerId:
          props.userPersonCustomerId > 0 ? props.userPersonCustomerId : 0,
        speciesId: 0,
        breedId: 0,
        sexId: 0,
        name: "",
        birthDate: null,
        years: "",
        months: "",
        owner: "",
        phone: "",
        cpf: "",
        externalCode: "",
        microchipCode: "",
        ageOption: null,
      },
      shouldDisableCustomer:
        props.userPersonCustomerId > 0 || props.match.params.id ? true : false,
    };

    this.isUpdate = isUpdate.bind(this);
    this.getId = getId.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.birthDateChange = birthDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);
    this.onChangeSpecies = onChangeSpecies.bind(this);
    this.createObj = this.createObj.bind(this);
    this.yearsMonthsChange = yearsMonthsChange.bind(this);
  }

  componentDidMount() {
    const { withId, breedsWithSpeciesId } = this.props;
    const { fields } = this.state;

    if (fields.id > 0) {
      const then = (data, xhr) => {
        if (xhr.response.status === 200) {
          const obj = data;
          this.fillFields(obj);
          breedsWithSpeciesId(obj.speciesId);
        }
      };

      withId(fields.id, then);
    }
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fillFields(obj) {
    const birthDate = obj.birthDate ? moment(obj.birthDate) : null;
    this.setState((state) =>
      update(state, {
        fields: {
          name: { $set: obj.name },
          owner: { $set: obj.owner },
          phone: { $set: obj.phone },
          cpf: { $set: obj.cpf },
          externalCode: { $set: obj.externalCode },
          microchipCode: { $set: obj.microchipCode },
          birthDate: { $set: birthDate },
          sexId: { $set: obj.sexId },
          breedId: { $set: obj.breedId },
          speciesId: { $set: obj.speciesId },
          customerId: { $set: obj.customerId },
          ageOption: { $set: obj.ageOption },
        },
      })
    );
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Obtains the submit object
   *
   * @return {Object}
   *
   */
  createObj() {
    const {
      id,
      name,
      birthDate,
      years,
      owner,
      phone,
      cpf,
      externalCode,
      microchipCode,
      sexId,
      months,
      speciesId,
      breedId,
      customerId,
      ageOption,
    } = this.state.fields;

    return {
      id: id,
      name: name,
      birthDate: birthDate?.format("YYYY-MM-DD"),
      years: years,
      owner: owner,
      phone: phone,
      cpf: cpf,
      externalCode: externalCode,
      microchipCode: microchipCode,
      sexId: sexId,
      months: months,
      speciesId: speciesId,
      breedId: breedId,
      ageOption: ageOption,
      customerId: customerId,
    };
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Submits animal form data
   *
   * @param {Object} event The submit event
   *
   * @return {boolean}
   *
   */
  handleSubmit() {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Animal atualizado!" : "Animal salvo!";
        this.successNotification(message);
        this.redirectToList();
      }
    };

    if (obj.id > 0) {
      this.props.update(obj, then);
    } else {
      this.props.add(obj, then);
    }
  }

  render() {
    const { fields, redirectToList } = this.state;

    const {
      objPromise,
      breedsPromise,
      speciesPromise,
      customersPromise,
      submitPromise,
    } = this.props;

    // Redirect
    if (redirectToList) {
      return <Redirect to="/animais" />;
    }

    // Loader
    const shouldDisplayLoader = objPromise && objPromise.pending;
    if (shouldDisplayLoader) {
      return <BounceLoader color="#00B4AD" load />;
    }

    // Edit
    const isUpdate = this.isUpdate();

    // Submit
    const saveBtnTitle = isUpdate ? "Atualizar" : "Salvar";
    const submit = {
      isSet: submitPromise !== undefined,
      pending: submitPromise && submitPromise.pending,
      isFulfilled: submitPromise && submitPromise.fulfilled,
      placeholder: isUpdate ? "Atualizando.." : "Salvando..",
      btnTitle: saveBtnTitle,
    };

    const disableAll = submit.isSet;

    const data = {
      fields: fields,
      submit: submit,
      isUpdate: isUpdate,
      disableAll: disableAll,
      submit: submit,
      customersPromise: customersPromise,
      speciesPromise: speciesPromise,
      breedsPromise: breedsPromise,
      shouldDisableCustomer: this.state.shouldDisableCustomer,
      title: fields.name ? "Editar Animal: " + fields.name : "Novo Animal",
    };

    // Methods
    const methods = {
      fieldChange: this.fieldChange,
      birthDateChange: this.birthDateChange,
      yearsMonthsChange: this.yearsMonthsChange,
      handleSubmit: this.handleSubmit,
      invalidNotification: this.invalidNotification,
      onChangeSpecies: this.onChangeSpecies,
      onFieldChange: this.onFieldChange,
    };
    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    userLabId: state.userReducer.lab_id,
    userPersonCustomerId: state.userReducer.user.customer_person_id,
    customerId: state.userReducer.user.customer_id,
  };
};

export default FunctionUtil.compose(
  connect(mapStateToProps),
  connectWithEndpoint
)(AnimalForm);
