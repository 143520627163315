import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { EXAM_TYPES } from "../../consts";
import { ROUTES, useFetch } from "../use-fetch";
import { EXAM_ID_BY_NAME } from "../../consts/exams";
import {
  biochemistryExamsStateFactory,
  mockFactory,
  initialStateFactory,
  makeHormoneTableData,
  fillObjectDataWithReport,
  biochemistryFactory
} from "./factory";
import reportCommonConnectWithEndpoint from "./../../reportCommonRequests";
import connectWithEndpoint from "./requests.js";
import { FunctionUtil } from "./../../useful";
import { report } from "process";
import update from "immutability-helper";
import { calculateLdl,calculateBilirubinIndirect,calculateIonicCalcium, calculateGlobulin, calculateVldl } from "./examFunctions";
import { biochemistryDefaultValues } from "./biochemistryFactory";

const API_URL = process.env.REACT_APP_PROXY;

const initialState = {
    completeValidation: false,
    submitActionIsRelease: false,
    fields: {
      options: [],
      vetSignerId: 0,
      biochemObs :""
    },
    shouldSendEmail: false
  };


const useHormone2 = ({examsIds, reportData}) => {
    const examRequestId = 20;
    const requestExamId = 66;
    const reportId = 55;
 const [state, setState] = useState(initialState);
 const [refs, setRefs] = useState({});
 const [errors, setErrors] = useState({});

 const refsPromise = useFetch();
 const reportsPromise = useFetch();
 const [requestExamsWithExamRequestId, setRequestExamsWithExamRequestId] = useState([]);
const requestExamsWithExamRequestIdPromise = useFetch();

 function setDedaultFieldErrors(field) {
   setErrors({...errors, ...{[field]:true}})
 }



 useEffect(() => {
   
  // requestExamsWithExamRequestIdPromise.fetch(
  //   ROUTES.getBiochemistryExams(examRequestId),
  //   ROUTES.getReport(reportId),
  //   { method: "GET" },
  //   (data) => setRequestExamsWithExamRequestId(data)
  // );

  refsPromise.fetch(
    ROUTES.getRefs(requestExamId, EXAM_TYPES.BIOCHEMISTRY),
    { method: "GET" },
    (data) => setRefs(data)
  );



 }, []);

 

 useEffect(() => {
    if(Object.keys(refs).length != 0) {
        reportsPromise.fetch(
            //ROUTES.getBiochemistryReports(examRequestId),
            ROUTES.getReport(reportId),
            { method: "GET" },
            (data) => fillFieldsWithReport(data, refs)
          );
    }

 }, [refs]);


  function changeField(idx, subfield, value) {
    setState(state =>
      update(state, {
        fields: {
          ["options"]: {
            [idx]: {
              [subfield]: { $set: value }
            }
          }
        }
      })
    );
  };

function  multiFieldResultChange(field, value) {

    const infoArray = field.split("-");
    const idx = infoArray[1];
    const subfield = infoArray[2];
    changeField(idx, subfield, value);
  };

  function resultChange(field, value) {
    const idx = field.replace("result-", "");
    const subfield = "result";
    changeField(idx, subfield, value);
  }

  function stateChange(field, value) {
    const idx = field.replace("result-", "");
    const subfield = "requestExamState";
    changeField(idx, subfield, value);
  }

  function makeIonicCalciumToggable(idx) {
    return () => {
      toggleIonicCalciumEditable(idx);
    };
  }

  function toggleIonicCalciumEditable(idx) {
    const subfield = "isResultEditable";
    const option = state.fields.options[idx];
    const isResultEditable = option.isResultEditable;
    changeField(idx, subfield, !isResultEditable);
  }


  function fillFieldsWithReport(reports, refs) {
    if(!reports)
      return;

    let options = examsIds.map(examId => {
      return biochemistryFactory(examId, refs);
    });

    reports && (options = fillObjectDataWithReport(options, reports));
    
    setState(state =>
      update(state, {
        fields: {
          options: { $set: options },
          biochemObs: {$set: options[0].obs}
        },
        shouldSendEmail: {
          $set: false
        }
      })
    );
  }


  const methods = {
    resultChange,
    $field: () => {},
    calculateIonicCalcium,
    multiFieldResultChange, 
    makeIonicCalciumToggable,
    calculateBilirubinIndirect,
    calculateGlobulin,
    calculateVldl,
      calculateLdl,
    stateChange,
    setDedaultFieldErrors
  }

  const data = {
    disableAll: false,
    requestExamStates : "",
    fields : state.fields,
    errors
  }
  

  return { data, methods };
};

export default useHormone2;


