import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import {
  required,
  greaterThan
  } from '../../assets/js/validationFunctions';

import {
  commonReportRules
  } from '../../assets/js/validationRules';

const validation = validated( props => {

  const {
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const result = props.data.result;
  let fields = [
    'result',
    'requestExamState',
    'vetSignerId'
  ];

  const commonValidation = commonReportRules(
    vetSignerId, requestExamState );

  const validations = {
    ...commonValidation,
    result: {
      rules: [
        [ required, result, 'Obrigatório' ]
      ]
    } };

  return { fields, validations };

} );

export default validation;