import connect from "./api-connector";

import {
  sendEmailReport,
  getReportHeader,
  postReport,
  updateReport,
  getReportById
} from "./requests.js";

/**
 * @author Victor Heringer
 *
 * Common requests to use at reports
 */
const reportCommonConnectWithEndpoint = connect(props => {
  return {
    sendEmailReport: sendEmailReport,
    getReportHeader: getReportHeader,
    postReport: postReport,
    updateReport: updateReport,
    getReportById: getReportById
  };
});

export default reportCommonConnectWithEndpoint;
