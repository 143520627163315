import React, { Component } from "react";

/**
 * Internal
 */
import ProfileGastroenteritisSilverReportFormView from "./../ProfileGastroenteritisSilverReportFormView";
import connectWithEndpoint from "./requests.js";
import validation from "./validation.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import {
  MOLECULAR_BIOLOGY_MATERIALS,
  REQUEST_EXAM_STATE_ID_BY_NAME,
  EXAM_ID_BY_NAME,
} from "../../assets/js/Consts.js";

import {
  containerState,
  reportState,
  hemogramState,
} from "../../modules/factories/default-states.js";

import { PromiseUtil, FunctionUtil } from "../../useful";
import { REPORT_DEFAULT_VALUES } from "./../../consts";

import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";

import {
  isAnemia,
  isPolicitemia,
  bloodCellsDiagnosis,
  toggleIntenseLeucopeny,
  openCounter,
  closeCounter,
  handleKeyPress,
  enableCounterSound,
  handleFinishedPlaying,
  calcMCV,
  calcMCHC,
  calcTotal,
  calcAbsolute,
  erythrogramDiagnosis,
  mcvDiagnosis,
  mchcDiagnosis,
  leukogramDiagnosis,
  zeroLeukogram,
  incrementField,
  globalLeukDiagnosis,
  resetLeukogram,
  finalGlobalLeukogram,
  isNeutropLeukocytosis,
  diagnosis,
  displayResults,
  DNNE,
  plateletsDiagnosis,
  zeroIfIntenseLeucopeny,
  createHemogramObj,
  createHemogramUpdateState,
  createHemogramRefObj,
  createReportObj,
  createPlateletsObj,
  createErythrogramObj,
  createLeukogramObj,
  calcMolecularBiologyResult,
  suggestionsFilter,
  prepareAntibioticTagsSuggestionFilter,
} from "../../assets/js/reportFunctions.js";

import {
  withCheckbox,
  parasitologialFreshFecesCheckboxes,
} from "../../components/result-group/index.js";

import {
  getQuantitativeResult,
  prepareToggleIsNegative,
  createAntibioticOptions,
  createAntibioticObjects,
  createAntibioticTagName,
} from "../../assets/js/cultureFunctions.js";
import Notifications from "react-notification-system-redux";
import { connect as reduxConnect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
import { DEFAULT_INVALID_MESSAGE } from "../../consts/notifications";
import { FocusManagerConfig, hemogramConfigOption } from "./../../services";

/**
 * External
 */
import update from "immutability-helper";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

const View = ({ data, methods, $field, $validation, $submit, $fieldEvent }) => {
  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;

    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };

  const onClickCounter = () => {
    methods.openCounter();
    methods.zeroLeukogram();
  };

  const handleChange = (field, value) => {
    $fieldEvent("change", field);
    methods.fieldChange(field, value);
  };

  const handleBlur = (event) => {
    $fieldEvent("blur", event.target.name);
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    handleChange: handleChange,
    handleBlur: handleBlur,
    $field: $field,
    onClickCounter: onClickCounter,
    ...methods,
  };

  return (
    <>
      <ProfileGastroenteritisSilverReportFormView
        title="Perfil Gastroenterite Prata"
        data={newData}
        methods={newMethods}
      />
      <Notifications notifications={methods.notifications} />
    </>
  );
};

const ValidatedView = validation(View);

class ProfileGastroenteritisSilverReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        ...reportState(),
        ...hemogramState(),
      },
      ...containerState(),
      shouldSendEmail: false,
    };

    // Biochemistry
    this.state.fields.parvoCoronaMaterialId = 0;
    this.state.fields.parvoCoronaIsParvovirus = -1;
    this.state.fields.parvoCoronaIsCoronavirus = -1;
    this.state.fields.parvoCoronaObs = "";
    this.state.fields.parasitologicalFreshFecesFecesColorOptionId = 0;
    this.state.fields.parasitologicalFreshFecesFecesSmellOptionId = 0;
    this.state.fields.parasitologicalFreshFecesFecesAspectOptionId = 0;
    this.state.fields.parasitologicalFreshFecesFecesConsistencyOptionId = 0;
    this.state.fields.parasitologicalFreshFecesMacroscopicParasites = "";
    this.state.fields.parasitologicalFreshFecesResult = "";
    this.state.fields.parasitologicalFreshFecesObs = "";
    this.state.fields.urocultureMaterial = "";
    this.state.fields.urocultureResult = "";
    this.state.fields.urocultureObs = "";
    this.state.fields.urocultureSensitiveAntibioticTags = [];
    this.state.fields.urocultureIntermediateAntibioticTags = [];
    this.state.fields.urocultureResistantAntibioticTags = [];
    this.state.fields.urocultureColoniesCount = "";
    this.state.fields.urocultureIsNegative = false;
    this.state.fields.fecesCoprocytologicalResult = "";

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.successNotification = successNotification.bind(this);
    this.openCounter = openCounter.bind(this);
    this.closeCounter = closeCounter.bind(this);
    this.handleKeyPress = handleKeyPress.bind(this);
    this.enableCounterSound = enableCounterSound.bind(this);
    this.handleFinishedPlaying = handleFinishedPlaying.bind(this);
    this.isAnemia = isAnemia.bind(this);
    this.isPolicitemia = isPolicitemia.bind(this);
    this.bloodCellsDiagnosis = bloodCellsDiagnosis.bind(this);
    this.calcMCV = calcMCV.bind(this);
    this.calcMCHC = calcMCHC.bind(this);
    this.calcTotal = calcTotal.bind(this);
    this.calcAbsolute = calcAbsolute.bind(this);
    this.erythrogramDiagnosis = erythrogramDiagnosis.bind(this);
    this.mcvDiagnosis = mcvDiagnosis.bind(this);
    this.mchcDiagnosis = mchcDiagnosis.bind(this);
    this.leukogramDiagnosis = leukogramDiagnosis.bind(this);
    this.zeroLeukogram = zeroLeukogram.bind(this);
    this.incrementField = incrementField.bind(this);
    this.globalLeukDiagnosis = globalLeukDiagnosis.bind(this);
    this.resetLeukogram = resetLeukogram.bind(this);
    this.finalGlobalLeukogram = finalGlobalLeukogram.bind(this);
    this.isNeutropLeukocytosis = isNeutropLeukocytosis.bind(this);
    this.plateletsDiagnosis = plateletsDiagnosis.bind(this);
    this.toggleIntenseLeucopeny = toggleIntenseLeucopeny.bind(this);
    this.zeroIfIntenseLeucopeny = zeroIfIntenseLeucopeny.bind(this);
    this.diagnosis = diagnosis.bind(this);
    this.displayResults = displayResults.bind(this);
    this.DNNE = DNNE.bind(this);
    this.createPlateletsObj = createPlateletsObj.bind(this);
    this.createErythrogramObj = createErythrogramObj.bind(this);
    this.createLeukogramObj = createLeukogramObj.bind(this);
    this.createHemogramObj = createHemogramObj.bind(this);
    this.createReportObj = createReportObj.bind(this);
    this.createObj = this.createObj.bind(this);
    this.shouldDisplayLoader = this.shouldDisplayLoader.bind(this);
    this.toggleIsNegative = prepareToggleIsNegative(
      "urocultureIsNegative"
    ).bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        if (xhr.response.status === 200) {
          const obj = data;
          this.fillFields(obj);
        }
      };

      this.props.withId(id, then);
    }
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    let editState = createHemogramUpdateState(obj);
    this.props.handleCheckedResultChange(obj.parasitologicalFreshFecesResult);

    let sensitiveAntibioticTags = [];
    let intermediateAntibioticTags = [];
    let resistantAntibioticTags = [];

    const isPositive = !obj.urocultureIsNegative;

    if (isPositive) {
      sensitiveAntibioticTags = obj.urocultureSensitiveAntibioticOptions
        ? obj.urocultureSensitiveAntibioticOptions.map((obj) =>
            createAntibioticTagName(obj)
          )
        : [];
      intermediateAntibioticTags = obj.urocultureIntermediateAntibioticOptions
        ? obj.urocultureIntermediateAntibioticOptions.map((obj) =>
            createAntibioticTagName(obj)
          )
        : [];
      resistantAntibioticTags = obj.urocultureResistantAntibioticOptions
        ? obj.urocultureResistantAntibioticOptions.map((obj) =>
            createAntibioticTagName(obj)
          )
        : [];
    }

    editState.fields.parvoCoronaMaterialId = {
      $set: obj.parvoCoronaMaterialId,
    };
    editState.fields.parvoCoronaIsParvovirus = {
      $set: obj.parvoCoronaIsParvovirus,
    };
    editState.fields.parvoCoronaIsCoronavirus = {
      $set: obj.parvoCoronaIsCoronavirus,
    };
    editState.fields.parvoCoronaObs = { $set: obj.parvoCoronaObs };
    editState.fields.parasitologicalFreshFecesFecesColorOptionId = {
      $set: obj.parasitologicalFreshFecesFecesColorOptionId,
    };
    editState.fields.parasitologicalFreshFecesFecesSmellOptionId = {
      $set: obj.parasitologicalFreshFecesFecesSmellOptionId,
    };
    editState.fields.parasitologicalFreshFecesFecesAspectOptionId = {
      $set: obj.parasitologicalFreshFecesFecesAspectOptionId,
    };
    editState.fields.parasitologicalFreshFecesFecesConsistencyOptionId = {
      $set: obj.parasitologicalFreshFecesFecesConsistencyOptionId,
    };
    editState.fields.parasitologicalFreshFecesMacroscopicParasites = {
      $set: obj.parasitologicalFreshFecesMacroscopicParasites,
    };
    editState.fields.parasitologicalFreshFecesResult = {
      $set: obj.parasitologicalFreshFecesResult,
    };
    editState.fields.parasitologicalFreshFecesObs = {
      $set: obj.parasitologicalFreshFecesObs,
    };
    editState.fields.urocultureMaterial = { $set: obj.urocultureMaterial };
    editState.fields.urocultureResult = {
      $set: obj.urocultureIsNegative ? "" : obj.urocultureResult,
    };
    editState.fields.urocultureObs = {
      $set: obj.urocultureIsNegative ? "" : obj.urocultureObs,
    };
    editState.fields.urocultureSensitiveAntibioticTags = {
      $set: obj.urocultureIsNegative ? [] : sensitiveAntibioticTags,
    };
    editState.fields.urocultureIntermediateAntibioticTags = {
      $set: obj.urocultureIsNegative ? [] : intermediateAntibioticTags,
    };
    editState.fields.urocultureResistantAntibioticTags = {
      $set: obj.urocultureIsNegative ? [] : resistantAntibioticTags,
    };
    editState.fields.urocultureColoniesCount = {
      $set: obj.urocultureColoniesCount,
    };
    editState.fields.urocultureIsNegative = { $set: obj.urocultureIsNegative };
    editState.fields.fecesCoprocytologicalResult = {
      $set: obj.fecesCoprocytologicalResult,
    };

    const fillObject = (state) => update(state, editState);

    this.setState(fillObject);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Obtains the submit object
   *
   * @return {Object}
   *
   */
  createObj() {
    const {
      parvoCoronaMaterialId,
      parvoCoronaIsParvovirus,
      parvoCoronaIsCoronavirus,
      parvoCoronaObs,
      parasitologicalFreshFecesFecesColorOptionId,
      parasitologicalFreshFecesFecesSmellOptionId,
      parasitologicalFreshFecesFecesAspectOptionId,
      parasitologicalFreshFecesFecesConsistencyOptionId,
      parasitologicalFreshFecesMacroscopicParasites,
      parasitologicalFreshFecesObs,
      urocultureResult,
      urocultureObs,
      urocultureMaterial,
      urocultureColoniesCount,
      urocultureIsNegative,
      urocultureSensitiveAntibioticTags,
      urocultureIntermediateAntibioticTags,
      urocultureResistantAntibioticTags,
      fecesCoprocytologicalResult,
    } = this.state.fields;

    const hemogramObj = {
      id: this.getId(),
      ...this.createReportObj(),
      ...this.createHemogramObj(),
    };

    const originalAntibioticOptions = this.props.antibioticOptionsPromise.value;

    const urocultureNegativeMessage = this.props.urocultureNegativeMessage;

    return {
      ...hemogramObj,
      parvoCoronaMaterialId: parvoCoronaMaterialId,
      parvoCoronaIsParvovirus: parvoCoronaIsParvovirus,
      parvoCoronaIsCoronavirus: parvoCoronaIsCoronavirus,
      parvoCoronaObs: parvoCoronaObs,
      parasitologicalFreshFecesFecesColorOptionId:
        parasitologicalFreshFecesFecesColorOptionId,
      parasitologicalFreshFecesFecesSmellOptionId:
        parasitologicalFreshFecesFecesSmellOptionId,
      parasitologicalFreshFecesFecesAspectOptionId:
        parasitologicalFreshFecesFecesAspectOptionId,
      parasitologicalFreshFecesFecesConsistencyOptionId:
        parasitologicalFreshFecesFecesConsistencyOptionId,
      parasitologicalFreshFecesMacroscopicParasites:
        parasitologicalFreshFecesMacroscopicParasites,
      parasitologicalFreshFecesResult: this.props.checkedResult,
      parasitologicalFreshFecesObs: parasitologicalFreshFecesObs,
      urocultureMaterial: urocultureMaterial,
      urocultureColoniesCount: urocultureIsNegative
        ? ""
        : urocultureColoniesCount,
      urocultureQuantitativeResult: getQuantitativeResult(
        urocultureColoniesCount
      ),
      urocultureIsNegative: urocultureIsNegative,
      urocultureResult: urocultureIsNegative
        ? urocultureNegativeMessage
        : urocultureResult,
      urocultureSensitiveAntibioticOptions: createAntibioticObjects(
        urocultureSensitiveAntibioticTags,
        originalAntibioticOptions
      ),
      urocultureIntermediateAntibioticOptions: createAntibioticObjects(
        urocultureIntermediateAntibioticTags,
        originalAntibioticOptions
      ),
      urocultureResistantAntibioticOptions: createAntibioticObjects(
        urocultureResistantAntibioticTags,
        originalAntibioticOptions
      ),
      urocultureObs: urocultureIsNegative ? "" : urocultureObs,
      fecesCoprocytologicalResult: fecesCoprocytologicalResult,
    };
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (!this.state.completeValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" ? null : obj[i];
      }
    }

    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * Decides wether it should
   * display the screen loader
   *
   */
  shouldDisplayLoader() {
    const { reportPromise, refsPromise } = this.props;
    const isUpdate = this.isUpdate();

    let editFetches = null;

    const shouldCheckEditFetches =
      isUpdate && reportPromise !== undefined && refsPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([reportPromise, refsPromise]);

      return isUpdate && editFetches && editFetches.pending;
    }

    return refsPromise && refsPromise.pending;
  }

  render() {
    const { examRequestId } = this.props.match.params;
    const backLink = "/solicitacoes-exame/" + examRequestId;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    const {
      refsPromise,
      reportSubmitPromise,
      fecesColorOptionsPromise,
      fecesSmellOptionsPromise,
      fecesAspectOptionsPromise,
      fecesConsistencyOptionsPromise,
      requestExamStatesPromise,
      reportHeaderPromise,
      antibioticOptionsPromise,
      objPromise,
    } = this.props;

    const {
      fields,
      isCounterOpen,
      isIntenseLeucopeny,
      shouldBlockCounterSound,
      showTotalValidation,
    } = this.state;

    const isUpdate = this.isUpdate();

    // Loader
    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );

    const requestExamStates = PromiseUtil.extractValue(
      requestExamStatesPromise,
      []
    );

    const refs = PromiseUtil.extractValue(
      refsPromise,
      REPORT_DEFAULT_VALUES.HEMOGRAM_REF_DEFAULT,
      createHemogramRefObj
    );

    const total = this.calcTotal();

    const reportPromiseData = PromiseUtil.extractValue(objPromise, {});

    const data = {
      isUpdate: isUpdate,
      fields: fields,
      backLink: backLink,
      refs: refs,
      examRequestId: examRequestId,
      isCounterOpen: isCounterOpen,
      requestExamStates: requestExamStates,
      shouldBlockCounterSound: shouldBlockCounterSound,
      showTotalValidation: showTotalValidation,
      fecesColorOptionsPromise: fecesColorOptionsPromise,
      fecesSmellOptionsPromise: fecesSmellOptionsPromise,
      fecesAspectOptionsPromise: fecesAspectOptionsPromise,
      fecesConsistencyOptionsPromise: fecesConsistencyOptionsPromise,
      materialsSuggestions: MOLECULAR_BIOLOGY_MATERIALS,
      submitPromise: reportSubmitPromise,
      disableAll: reportSubmitPromise && reportSubmitPromise.pending,
      total: isIntenseLeucopeny ? "" : total,
      mchc: this.calcMCHC(),
      reportHeaderData: reportHeaderFactory(reportHeaderData),
      antibioticOptions: createAntibioticOptions(antibioticOptionsPromise),
      completeValidation: this.state.completeValidation,
      urocultureNegativeMessage: this.props.urocultureNegativeMessage,
      parasitologicalFreshFecesResult: this.props.checkedResult,
      checkdState: this.props.multiCheckboxState,
      checkboxes: parasitologialFreshFecesCheckboxes,
      reportData: reportPromiseData,
    };

    // Methods
    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      openCounter: this.openCounter,
      resetLeukogram: this.resetLeukogram,
      handleKeyPress: this.handleKeyPress,
      calcAbsolute: this.calcAbsolute,
      toggleIntenseLeucopeny: this.toggleIntenseLeucopeny,
      invalidNotification: () => {
        this.props.errorNotification(DEFAULT_INVALID_MESSAGE);
      },
      notifications: this.props.notifications,
      finalGlobalLeukogram: this.finalGlobalLeukogram,
      zeroLeukogram: this.zeroLeukogram,
      showvalidationFunctions: this.showvalidationFunctions,
      erythrogramDiagnosis: this.erythrogramDiagnosis,
      leukogramDiagnosis: this.leukogramDiagnosis,
      calcMCV: this.calcMCV,
      calcMCHC: this.calcMCHC,
      calcTotal: this.calcTotal,
      plateletsDiagnosis: this.plateletsDiagnosis,
      handleFinishedPlaying: this.handleFinishedPlaying,
      calcMolecularBiologyResult: calcMolecularBiologyResult,
      closeCounter: this.closeCounter,
      resultChange: this.resultChange,
      suggestionsFilter: suggestionsFilter,
      toggleIsNegative: this.toggleIsNegative,
      prepareAntibioticTagsSuggestionFilter:
        prepareAntibioticTagsSuggestionFilter,
      getQuantitativeResult: getQuantitativeResult,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      cancelReport: this.cancelReport,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      handleSendReportEmail: this.handleSendReportEmail,
      stopReport: this.stopReport,
      handleFocus: this.props.handleFocus,
      setRef: this.props.setRef,
      multiBoxChange: this.props.multiBoxChange,
      handleCheckedResultChange: this.props.handleCheckedResultChange,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);

const focusManager = FocusManagerConfig(
  hemogramConfigOption.config,
  hemogramConfigOption.initialFocus
);

export default FunctionUtil.compose(
  focusManager,
  connectWithEndpoint,
  connectWithRedux,
  (component) =>
    withCheckbox(component, EXAM_ID_BY_NAME.PARASITOLOGICAL_FRESH_FECES)
)(ProfileGastroenteritisSilverReportForm);
