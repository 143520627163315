import React, { Component } from "react";
import update from "immutability-helper";
import Notifications from "react-notification-system-redux";
import { connect as connectWithRedux } from "react-redux";
import FadeIn from "react-fade-in";

import ExamRequestListView from "./../ExamRequestListView";
import connectWithEndpoint from "./request";
import { MESSAGES, ACCESS_PROFILES } from "./../../consts";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
import { FunctionUtil } from "./../../useful";

/**
 * @author Victor Heringer
 *
 * Exam request container for functions
 */
class ExamRequestList extends Component {
  state = {
    fields: {
      modalSampleCode: "",
    },
    showReceiveAndAssistModal: false,
    shouldRedirect: false,
    redirectToId: 0,
  };

  componentDidMount() {
    // console.log('montando componente');
    this.isClinicUserType(this.props.access_profile)
      ? this.props.getCustomerExamRequestsByStatus()
      : this.props.getLabExamRequestsByStatus();
  }

  isClinicUserType = (profile) => {
    const clinicUserTypes = [
      ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
      ACCESS_PROFILES.CLINIC_USER,
    ];

    return clinicUserTypes.includes(profile);
  };

  /**
   * @author Victor Heringer
   *
   * Opens modal
   *
   * @return {Void}
   */
  handleShowReceiveAndAssistModal = () => {
    this.setState((state) =>
      update(state, {
        showReceiveAndAssistModal: {
          $set: !this.state.showReceiveAndAssistModal,
        },
      })
    );
    this.updateField("modalSampleCode", "");
  };

  /**
   * @author Victor Heringer
   *
   * Closes modal
   *
   * @return {Void}
   */
  handleCloseReceiveAndAssistModal = () => {
    this.setState((state) =>
      update(state, {
        showReceiveAndAssistModal: {
          $set: !this.state.showReceiveAndAssistModal,
        },
      })
    );
  };

  updateField = (field, value) => {
    if (field === "modalSampleCode" && value.length > 10) return;

    this.setState((state) =>
      update(state, {
        fields: { [field]: { $set: value } },
      })
    );

    if (field === "modalSampleCode" && value.length == 10)
      this.receiveAndAssistSampleCode(value);
  };

  onTableDoubleClick = (currentId, state, rowInfo, column) => {
    if (!rowInfo) {
      return;
    }
    this.setState({ shouldRedirect: true, redirectToId: rowInfo.original.id });
  };

  receiveAndAssistSampleCode = (code) => {
    const objIsEmpty = (obj) => {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    };

    const onSuccess = (data) => {
      if (objIsEmpty(data)) {
        this.props.errorNotification(MESSAGES.REQUEST_SAMPLE_CODE_NOT_FOUND);
      }
    };
    this.props.receiveAndAssistRequestExam(code, onSuccess);
  };

  render() {
    const { receiveAndAssistPromise } = this.props;

    if (
      (receiveAndAssistPromise &&
        receiveAndAssistPromise.value &&
        receiveAndAssistPromise.value.id) ||
      this.state.shouldRedirect
    ) {
      const id = this.state.redirectToId || receiveAndAssistPromise.value.id;
      this.props.history.push(`/solicitacoes-exame/${id}`);
    }

    const methods = {
      handleShowReceiveAndAssistModal: this.handleShowReceiveAndAssistModal,
      handleCloseReceiveAndAssistModal: this.handleCloseReceiveAndAssistModal,
      updateField: this.updateField,
      receiveAndAssistSampleCode: this.receiveAndAssistSampleCode,
      requestExam: this.props.requestExam,
      handleClickRouteTable: this.handleClickRouteTable,
      onTableDoubleClick: this.onTableDoubleClick,
      refreshExamRequestTables: this.isClinicUserType(this.props.access_profile)
        ? this.props.getCustomerExamRequestsByStatus
        : this.props.getLabExamRequestsByStatus,
    };

    const data = {
      fields: this.state.fields,
      showReceiveAndAssistModal: this.state.showReceiveAndAssistModal,
      isClinicUserType: this.isClinicUserType(this.props.access_profile),
      userId: this.props.user.id,
      receiveAndAssistPromise: receiveAndAssistPromise,
      pendingExams: this.props.pendingExams,
      stuckExams: this.props.stuckExams,
      awaitingExams: this.props.awaitingExams,
      inProgressExams: this.props.inProgressExams,
      lateExams: this.props.lateExams,
      attendedPartiallyExams: this.props.attendedPartiallyExams,
      cancelledExams: this.props.cancelledExams,
      attendedExams: this.props.attendedExams,
      billedExams: this.props.billedExams,
    };

    return (
      <FadeIn>
        <ExamRequestListView methods={methods} data={data} />
        <Notifications notifications={this.props.notifications} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(
  connectWithRedux(mapStateToProps, mapDispatchToProps),
  connectWithEndpoint
)(ExamRequestList);
