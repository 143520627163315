import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { View } from "./view/view";
import * as LifeVet from "./../../components/index";
import { useValidatedRequestForm } from "../../hooks/request/index";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
import Notifications from "react-notification-system-redux";
import {
  CustomerConversor,
  RequestConversor,
} from "./../../services/request-conversor/old-to-new/index";
import { MESSAGES } from "./../../consts/index";
import { useFetch, ROUTES } from "../../hooks/use-fetch";

const Index = ({
  isMobile,
  loggedUserIsFromClinic,
  loggedUserIsAClinicUser,
  userLabId,
  customerId,
  userPersonCustomerId,
  history,
  match,
  successNotification,
  errorNotification,
  warningNotification,
  infoNotification,
  notifications,
}: {
  isMobile: boolean;
  loggedUserIsFromClinic: boolean;
  loggedUserIsAClinicUser: boolean;
  userLabId: number;
  customerId: number;
  userPersonCustomerId: number;
  history: any;
  match: any;
  successNotification: any;
  errorNotification: any;
  warningNotification: any;
  infoNotification: any;
  notifications: any;
}) => {
  const requestId = match.params.id ? match.params.id : 0;
  const formIsInUpdateMode = requestId !== 0;

  const fetchedExamRequest = useFetch();
  const fetchedCustomer = useFetch();

  const RequestForm = useValidatedRequestForm();

  const submitRequest = (options: {
    isPartialSave: boolean;
    redirect: boolean;
    restart: boolean;
  }) => {
    const onSuccess = (obj: any) => {
      successNotification(
        formIsInUpdateMode
          ? MESSAGES.UPDATED_EXAM_REQUEST
          : MESSAGES.CREATED_EXAM_REQUEST
      );
      RequestForm.setIsSubmitting(false);

      if (options.restart) {
        RequestForm.reset();
      } else {
        RequestForm.data.id === 0
          ? history.push(`/solicitacoes-exame/2.0/${obj.id}`)
          : loadNewRequest(obj.id);
      }
      options.redirect && setUpRedirectionTo("/");
    };

    const onError = () => {
      errorNotification(MESSAGES.ERROR_MESSAGE);
      RequestForm.setIsSubmitting(false);
    };

    RequestForm.submit(options.isPartialSave, onSuccess, onError);
  };

  const onSuccess = (oldExamRequest: any) => {
    RequestForm.methods.update(RequestConversor(oldExamRequest));
  };

  const onError = (error: any) => {
    errorNotification(MESSAGES.ERROR_MESSAGE);
  };

  const loadNewRequest = (requestId: number) => {
    fetchedExamRequest.fetch(
      ROUTES.getRequest(requestId),
      { method: "GET" },
      onSuccess,
      onError
    );
  };

  const [redirection, setRedirection] = useState({
    shouldRedirect: false,
    to: "",
  });

  const setUpRedirectionTo = (to: string) => {
    setRedirection({ shouldRedirect: true, to: to });
  };

  useEffect(() => {
    if (loggedUserIsFromClinic) {
      fetchedCustomer.fetch(
        ROUTES.getCustomersByLab(userLabId),
        { method: "GET" },
        (oldCustomer) => {
          oldCustomer = oldCustomer.shift();
          const newCustomer = {
            ...RequestForm.data.customer,
            id: oldCustomer.id,
            name: oldCustomer.name,
          };
          RequestForm.methods.customer.update(newCustomer);
        }
      );
    }
  }, []);

  useEffect(() => {
    match.params.id > 0 && loadNewRequest(match.params.id);
  }, [match.params.id]);

  const fetching =
    fetchedCustomer.pending || (fetchedExamRequest.pending && requestId > 0);

  if (redirection.shouldRedirect) return <Redirect push to={redirection.to} />;

  return (
    <>
      <LifeVet.Loader loading={fetching}>
        <View
          data={RequestForm.data}
          methods={RequestForm.methods}
          errors={RequestForm.errors}
          touched={RequestForm.touched}
          setError={RequestForm.setError}
          setTouched={RequestForm.setTouched}
          handleChange={RequestForm.handleChange}
          handleBlur={RequestForm.handleBlur}
          submit={submitRequest}
          requestIsSubmitting={RequestForm.isSubmitting}
          fetching={fetching}
          history={history}
          isMobile={isMobile}
          loggedUserIsFromClinic={loggedUserIsFromClinic}
          loggedUserIsAClinicUser={loggedUserIsAClinicUser}
          labId={userLabId}
          customerId={userPersonCustomerId}
          formIsInUpdateMode={formIsInUpdateMode}
          setUpRedirectionTo={setUpRedirectionTo}
          successNotification={successNotification}
          errorNotification={errorNotification}
          warningNotification={warningNotification}
          infoNotification={infoNotification}
        />
      </LifeVet.Loader>
      <Notifications notifications={notifications} />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
