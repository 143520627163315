import React from "react";

/**
 * Internal
 */
import FlatPanelMaterial from "./../FlatPanelMaterial";
import TagsInputMaterial2 from "./../TagsInputMaterial2";
import DualRadioMaterial2 from "./../DualRadioMaterial2";
import TextAreaMaterial2 from "./../TextAreaMaterial2";
import NumberInputMaterial2 from "./../NumberInputMaterial2";

/**
 * External
 */
import { Col, Row } from "./../../components/grid";

const DefaultMolecularBiologyContent = ({
  materialsFieldName,
  materials,
  materialsSuggestions,
  materialsSuggestionsFilter,
  materialsHasValidation,
  materialsValidationReason,
  techniqueFieldName,
  technique,
  techniqueHasValidation,
  techniqueValidationReason,
  qualitativeResultFieldName,
  qualitativeResult,
  qualitativeResultHasValidation,
  qualitativeResultValidationReason,
  copyValuesFieldName,
  copyValues,
  copyValuesHasValidation,
  copyValuesValidationReason,
  precisionFieldName,
  precision,
  precisionHasValidation,
  precisionValidationReason,
  resultFieldName,
  result,
  obsFieldName,
  obs,
  shouldDisable,
  handleChange,
  handleBlur
}) => (
  <React.Fragment>
    <FlatPanelMaterial title="Resultado">
      <Row>
        <Col md={6}>
          <TagsInputMaterial2
            title="Tipo de Material"
            name={materialsFieldName}
            tags={materials}
            disabled={shouldDisable}
            onChange={handleChange}
            onBlur={handleBlur}
            suggestions={materialsSuggestions}
            suggestionsFilter={materialsSuggestionsFilter}
            hasValidation={materialsHasValidation}
            validationReason={materialsValidationReason}
            validationRecoil={false}
          />
        </Col>
        <Col md={3}>
          <DualRadioMaterial2
            title="Resultado Qualitativo"
            name={qualitativeResultFieldName}
            selectedValue={qualitativeResult}
            disabled={shouldDisable}
            firstLabel="Negativo"
            firstValue={0}
            secondLabel="Positivo"
            secondValue={1}
            onChange={handleChange}
            hasValidation={qualitativeResultHasValidation}
            validationReason={qualitativeResultValidationReason}
          />
        </Col>
        <Col md={3}>
          <DualRadioMaterial2
            title="Técnica"
            name={techniqueFieldName}
            selectedValue={technique}
            disabled={shouldDisable}
            firstLabel="200"
            firstValue={200}
            secondLabel="400"
            secondValue={400}
            onChange={handleChange}
            hasValidation={techniqueHasValidation}
            validationReason={techniqueValidationReason}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <NumberInputMaterial2
            name={copyValuesFieldName}
            title="Valor de cópias"
            decimalScale={4}
            allowNegative={false}
            value={copyValues}
            disabled={shouldDisable || !qualitativeResult}
            handleChange={handleChange}
            handleBlur={handleBlur}
            hasValidationRecoil={true}
            hasValidation={copyValuesHasValidation}
            validationReason={copyValuesValidationReason}
          />
        </Col>
        <Col md={4}>
          <NumberInputMaterial2
            name={precisionFieldName}
            title="Precisão"
            decimalScale={1}
            allowNegative={false}
            value={precision}
            disabled={shouldDisable || !qualitativeResult}
            handleChange={handleChange}
            handleBlur={handleBlur}
            hasValidationRecoil={true}
            hasValidation={precisionHasValidation}
            validationReason={precisionValidationReason}
          />
        </Col>
        <Col md={4}>
          <NumberInputMaterial2
            name={resultFieldName}
            title="Resultado"
            decimalScale={1}
            allowNegative={false}
            value={result}
            disabled
            handleChange={handleChange}
            handleBlur={handleBlur}
            hasValidationRecoil={true}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
    <FlatPanelMaterial title="Obs">
      <Row className="mb-10">
        <Col md={12}>
          <TextAreaMaterial2
            title=""
            name={obsFieldName}
            value={obs}
            shouldDisable={shouldDisable}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
  </React.Fragment>
);

export default DefaultMolecularBiologyContent;
