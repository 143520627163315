import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import DonorPanel from "./../DonorPanel";
import DoubleNumberInputWithReferencePanel from "./../DoubleNumberInputWithReferencePanel";
import DoubleSelectPanel from "./../DoubleSelectPanel";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Row, Col } from "./../../components/grid";
import TextAreaPanel2 from "../TextAreaPanel2";

const CrossMatchReportFormView = ({
  data: {
    refs,
    isUpdate,
    fields,
    backLink,
    disableAll,
    $validation,
    submitData,
    majorCrossMatchOptions,
    minorCrossMatchOptions,
    reportHeaderData,
    shouldSendEmail
  },
  methods: {
    fieldChange,
    birthDateChange,
    onSubmit,
    yearsMonthsChange,
    $field,
    handleSendReportEmail,
    cancelReport,
    stopReport
  }
}) => (
  <ReportPanelMaterial
    title="Teste de Compatibilidade Sanguínea"
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitData.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <DonorPanel
          panelTitle={"Informações do Animal Doador"}
          name={fields.donorName}
          nameField="donorName"
          breed={fields.donorBreed}
          breedField="donorBreed"
          birthDate={fields.birthDate}
          birthDateField="birthDate"
          years={fields.years}
          yearsField="years"
          months={fields.months}
          monthsField="months"
          sexId={fields.donorSexId}
          sexIdField="donorSexId"
          owner={fields.donorOwner}
          ownerField="donorOwner"
          disableAll={disableAll}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
          birthDateChange={birthDateChange}
          yearsMonthsChange={yearsMonthsChange}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <DoubleNumberInputWithReferencePanel
          panelTitle={"Animal Receptor"}
          firstTitle={"Hematócrito"}
          firstName={"receptorCorpuscularVolume"}
          firstValue={fields.receptorCorpuscularVolume}
          firstRefMin={refs.corpuscularVolumeMin}
          firstRefMax={refs.corpuscularVolumeMax}
          firstRefUnit={"%"}
          firstValidationShow={$validation.receptorCorpuscularVolume.show}
          firstValidationReason={
            $validation.receptorCorpuscularVolume.error.reason
          }
          secondTitle={"Proteína Plasmática"}
          secondValue={fields.receptorBloodProtein}
          secondRefMin={refs.bloodProteinMin}
          secondRefMax={refs.bloodProteinMax}
          secondRefUnit={"g/dL"}
          secondName={"receptorBloodProtein"}
          secondValidationShow={$validation.receptorBloodProtein.show}
          secondValidationReason={$validation.receptorBloodProtein.error.reason}
          disableAll={disableAll}
          $validation={$validation}
          fieldChange={fieldChange}
          $field={$field}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <DoubleNumberInputWithReferencePanel
          panelTitle={"Animal Doador"}
          firstTitle={"Hematócrito"}
          firstName={"donorCorpuscularVolume"}
          firstValue={fields.donorCorpuscularVolume}
          firstRefMin={refs.corpuscularVolumeMin}
          firstRefMax={refs.corpuscularVolumeMax}
          firstRefMax={refs.corpuscularVolumeMax}
          firstRefUnit={"%"}
          firstValidationShow={$validation.donorCorpuscularVolume.show}
          firstValidationReason={
            $validation.donorCorpuscularVolume.error.reason
          }
          secondTitle={"Proteína Plasmática"}
          secondValue={fields.donorBloodProtein}
          secondRefMin={refs.bloodProteinMin}
          secondRefMax={refs.bloodProteinMax}
          secondRefUnit={"g/dL"}
          secondName={"donorBloodProtein"}
          secondValidationShow={$validation.donorBloodProtein.show}
          secondValidationReason={$validation.donorBloodProtein.error.reason}
          disableAll={disableAll}
          $validation={$validation}
          fieldChange={fieldChange}
          $field={$field}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <DoubleSelectPanel
          panelTitle={"Resultado"}
          firstTitle={"Prova Maior"}
          secondTitle={"Prova Menor"}
          firstName={"majorCrossMatchOptionId"}
          secondName={"minorCrossMatchOptionId"}
          firstOptions={majorCrossMatchOptions}
          secondOptions={minorCrossMatchOptions}
          firstValue={fields.majorCrossMatchOptionId}
          secondValue={fields.minorCrossMatchOptionId}
          disableAll={disableAll}
          firstValidationShow={$validation.majorCrossMatchOptionId.show}
          secondValidationShow={$validation.minorCrossMatchOptionId.show}
          firstValidationReason={
            $validation.majorCrossMatchOptionId.error.reason
          }
          secondValidationReason={
            $validation.minorCrossMatchOptionId.error.reason
          }
          fieldChange={fieldChange}
          $field={$field}
        />
      </Col>
      <TextAreaPanel2
              title="Obs"
              name={"obs"}
              value={fields.obs}
              disabled={disableAll}
              handleChange={fieldChange}
      />
    </Row>    
  </ReportPanelMaterial>
);

export default CrossMatchReportFormView;
