/* eslint-disable no-unused-vars */
import React from "react";
import { MOLECULAR_BIOLOGY_MATERIALS } from "../../../assets/js/Consts";
import {
  calcAbsoluteErythrocyteCount,
  calcMolecularBiologyResult,
  calcReticulocyteCorrection,
  calcReticulocytePercent,
  calcTotalReticulocyte,
  suggestionsFilter,
} from "../../../assets/js/reportFunctions";
import { Col, Row } from "../../../components";
import { calculateIonicCalcium } from "../../../hooks/hormone/examFunctions";
import ProfileBiochemistryTable from "../../../old_components/ProfileBiochemistryTable";
import ProfileEndocrinologyTable from "../../../old_components/ProfileEndocrinologyTable";
import CheckboxMaterial from "../../CheckboxMaterial";
import DefaultMolecularBiologyPanel from "../../DefaultMolecularBiologyPanel";
import EasPanel from "../../EasPanel";
import FlatPanelMaterial from "../../FlatPanelMaterial";
import HemocytozoaResearchPanel from "../../HemocytozoaResearchPanel";
import HemogramPanel from "../../HemogramPanel";
import MicrofilariaeResearchPanel from "../../MicrofilariaeResearchPanel";
import NumberInputMaterial2 from "../../NumberInputMaterial2";
import NumberInputWithReference2 from "../../NumberInputWithReference2";
import ReticulocyteCountPanel from "../../ReticulocyteCountPanel";
import ReticulocyteNormalFractionPanel from "../../ReticulocyteNormalFractionPanel";
import TextAreaPanel2 from "../../TextAreaPanel2";
import BloodProteinPanel from "../../BloodProteinPanel";
import { fieldChange } from "../../../assets/js/containerFunctions";

export const renderHemogramPanel = (data, methods) => {
  const { fields, refs, disableAll, total, $validation, reportData } = data;
  const {
    finalGlobalLeukogram,
    erythrogramDiagnosis,
    leukogramDiagnosis,
    plateletsDiagnosis,
    $field,
    fieldChange,
    calcMCHC,
    calcMCV,
    resetLeukogram,
    onClickCounter,
    toggleIntenseLeucopeny,
    handleKeyPress,
    handleFinishedPlaying,
    closeCounter,
    setRef,
    calcAbsolute,
  } = methods;

  return (
    <HemogramPanel
      fields={fields}
      refs={refs}
      disableAll={disableAll}
      $validation={$validation}
      total={total}
      $field={$field}
      fieldChange={fieldChange}
      calcMCV={calcMCV}
      calcMCHC={calcMCHC}
      calcAbsolute={calcAbsolute}
      resetLeukogram={resetLeukogram}
      onClickCounter={onClickCounter}
      toggleIntenseLeucopeny={toggleIntenseLeucopeny}
      finalGlobalLeukogram={finalGlobalLeukogram}
      erythrogramDiagnosis={erythrogramDiagnosis}
      leukogramDiagnosis={leukogramDiagnosis}
      plateletsDiagnosis={plateletsDiagnosis}
      handleKeyPress={handleKeyPress}
      handleFinishedPlaying={handleFinishedPlaying}
      closeCounter={closeCounter}
      setRef={setRef}
      reportData={reportData}
    />
  );
};

export const renderBiochemistryPanel = (data, methods) => {
  const {
    biochemistryTableData,
    isUpdate,
    disableAll,
    requestExamStates,
    fields,
  } = data;
  const { handleBlur, handleChange, toggleIonicCalciumEditable } = methods;
  fields.ionicCalciumIonicCalcium
    ? (fields.ionicCalciumIonicCalcium = fields.ionicCalciumIonicCalcium)
    : (fields.ionicCalciumIonicCalcium = calculateIonicCalcium(
        fields.ionicCalciumCalcium,
        fields.ionicCalciumAlbumin,
        fields.ionicCalciumTotalProtein
      ));

  return (
    <Row>
      <Col md={12}>
        <FlatPanelMaterial title="Bioquímica">
          <ProfileBiochemistryTable
            data={biochemistryTableData}
            isUpdate={isUpdate}
            handleChange={handleChange}
            handleBlur={handleBlur}
            shouldDisable={disableAll}
            requestExamStates={requestExamStates}
            biochemObs={fields.biochemObs}
            toggleIonicCalciumEditable={toggleIonicCalciumEditable}
          />
        </FlatPanelMaterial>
      </Col>
    </Row>
  );
};

export const renderEasPanel = (data, methods) => {
  const { fields, $validation, refsEas, easOptions, disableAll } = data;
  const { suggestionsFilter, handleBlur, handleChange, handleToggle } = methods;

  return (
    <EasPanel
      volumeFieldName="easVolume"
      volume={fields.easVolume}
      volumeHasValidation={$validation.easVolume.show}
      volumeValidationReason={$validation.easVolume.error.reason}
      densityFieldName="easDensity"
      density={fields.easDensity}
      densityHasValidation={$validation.easDensity.show}
      densityValidationReason={$validation.easDensity.error.reason}
      densityRefs={refsEas.density}
      colorOptionIdFieldName="easColorOptionId"
      colorOptionId={fields.easColorOptionId}
      colorOptionIdHasValidation={$validation.easColorOptionId.show}
      colorOptionIdValidationReason={$validation.easColorOptionId.error.reason}
      colorOptions={easOptions.colorOptions}
      smellOptionIdFieldName="easSmellOptionId"
      smellOptionId={fields.easSmellOptionId}
      smellOptionIdHasValidation={$validation.easSmellOptionId.show}
      smellOptionIdValidationReason={$validation.easSmellOptionId.error.reason}
      smellOptions={easOptions.smellsOptions}
      aspectOptionIdFieldName="easAspectOptionId"
      aspectOptionId={fields.easAspectOptionId}
      aspectOptionIdHasValidation={$validation.easAspectOptionId.show}
      aspectOptionIdValidationReason={
        $validation.easAspectOptionId.error.reason
      }
      aspectOptions={easOptions.aspectsOptions}
      proteinsOptionIdFieldName={"easProteinsOptionId"}
      proteinsOptionId={fields.easProteinsOptionId}
      proteinsOptionIdHasValidation={$validation.easProteinsOptionId.show}
      proteinsOptionIdValidationReason={
        $validation.easProteinsOptionId.error.reason
      }
      proteinsOptions={easOptions.proteinsOptions}
      proteinsRefs={refsEas.proteins}
      glucoseOptionIdFieldName={"easGlucoseOptionId"}
      glucoseOptionId={fields.easGlucoseOptionId}
      glucoseOptionIdHasValidation={$validation.easGlucoseOptionId.show}
      glucoseOptionIdValidationReason={
        $validation.easGlucoseOptionId.error.reason
      }
      glucoseOptions={easOptions.glucoseOptions}
      glucoseRefs={refsEas.glucose}
      acetoneOptionIdFieldName={"easAcetoneOptionId"}
      acetoneOptionId={fields.easAcetoneOptionId}
      acetoneOptionIdHasValidation={$validation.easAcetoneOptionId.show}
      acetoneOptionIdValidationReason={
        $validation.easAcetoneOptionId.error.reason
      }
      acetoneOptions={easOptions.acetoneOptions}
      acetoneRefs={refsEas.acetone}
      phFieldName={"easPh"}
      ph={fields.easPh}
      phHasValidation={$validation.easPh.show}
      phValidationReason={$validation.easPh.error.reason}
      phRefs={refsEas.ph}
      bilirubinOptionIdFieldName={"easBilirubinOptionId"}
      bilirubinOptionId={fields.easBilirubinOptionId}
      bilirubinOptionIdHasValidation={$validation.easBilirubinOptionId.show}
      bilirubinOptionIdValidationReason={
        $validation.easBilirubinOptionId.error.reason
      }
      bilirubinOptions={easOptions.bilirubinOptions}
      bilirubinRefs={refsEas.bilirubin}
      urobilinogenOptionIdFieldName={"easUrobilinogenOptionId"}
      urobilinogenOptionId={fields.easUrobilinogenOptionId}
      urobilinogenOptionIdHasValidation={
        $validation.easUrobilinogenOptionId.show
      }
      urobilinogenOptionIdValidationReason={
        $validation.easUrobilinogenOptionId.error.reason
      }
      urobilinogenOptions={easOptions.urobilinogenOptions}
      urobilinogenRefs={refsEas.urobilinogen}
      hemoglobinOptionIdFieldName={"easHemoglobinOptionId"}
      hemoglobinOptionId={fields.easHemoglobinOptionId}
      hemoglobinOptionIdHasValidation={$validation.easHemoglobinOptionId.show}
      hemoglobinOptionIdValidationReason={
        $validation.easHemoglobinOptionId.error.reason
      }
      hemoglobinOptions={easOptions.hemoglobinOptions}
      hemoglobinRefs={refsEas.hemoglobin}
      leukocyteOptionIdFieldName={"easLeukocyteOptionId"}
      leukocyteOptionId={fields.easLeukocyteOptionId}
      leukocyteOptionIdHasValidation={$validation.easLeukocyteOptionId.show}
      leukocyteOptionIdValidationReason={
        $validation.easLeukocyteOptionId.error.reason
      }
      leukocyteOptions={easOptions.leukocyteOptions}
      leukocyteRefs={refsEas.leukocyte}
      nitriteOptionIdFieldName={"easNitriteOptionId"}
      nitriteOptionId={fields.easNitriteOptionId}
      nitriteOptionIdHasValidation={$validation.easNitriteOptionId.show}
      nitriteOptionIdValidationReason={
        $validation.easNitriteOptionId.error.reason
      }
      nitriteOptions={easOptions.nitriteOptions}
      nitriteRefs={refsEas.nitrite}
      epithelialCellsOptionTagsFieldName={"easEpithelialCellsOptionTags"}
      epithelialCellsOptionTags={fields.easEpithelialCellsOptionTags}
      epithelialCellsOptionTagsSuggestions={easOptions.epithelialCellsOptions}
      epithelialCellsOptionTagsHasValidation={
        $validation.easEpithelialCellsOptionTags.show
      }
      epithelialCellsOptionTagsValidationReason={
        $validation.easEpithelialCellsOptionTags.error.reason
      }
      epithelialCellsRefs={refsEas.epithelialCells}
      erythrocytesMinFieldName="easErythrocytesMin"
      erythrocytesMin={fields.easErythrocytesMin}
      erythrocytesMaxFieldName="easErythrocytesMax"
      erythrocytesMax={fields.easErythrocytesMax}
      erythrocytesHasValidation={$validation.isErythrocytesValid.show}
      erythrocytesValidationReason={
        $validation.isErythrocytesValid.error.reason
      }
      isErythrocytesAbsentFieldName="easIsErythrocytesAbsent"
      isErythrocytesAbsent={fields.easIsErythrocytesAbsent}
      isErythrocytesUncountableFieldName="easIsErythrocytesUncountable"
      isErythrocytesUncountable={fields.easIsErythrocytesUncountable}
      erythrocytesRefs={refsEas.erythrocytes}
      pusCellsMinFieldName="easPusCellsMin"
      pusCellsMin={fields.easPusCellsMin}
      pusCellsMaxFieldName="easPusCellsMax"
      pusCellsMax={fields.easPusCellsMax}
      pusCellsHasValidation={$validation.isPusCellsValid.show}
      pusCellsValidationReason={$validation.isPusCellsValid.error.reason}
      isPusCellsAbsentFieldName="easIsPusCellsAbsent"
      isPusCellsAbsent={fields.easIsPusCellsAbsent}
      isPusCellsUncountableFieldName="easIsPusCellsUncountable"
      isPusCellsUncountable={fields.easIsPusCellsUncountable}
      pusCellsRefs={refsEas.pusCells}
      ridgesOptionTagsFieldName={"easRidgesOptionTags"}
      ridgesOptionTags={fields.easRidgesOptionTags}
      ridgesOptionTagsSuggestions={easOptions.ridgesOptions}
      ridgesOptionTagsHasValidation={$validation.easRidgesOptionTags.show}
      ridgesOptionTagsValidationReason={
        $validation.easRidgesOptionTags.error.reason
      }
      ridgesOptionRefs={refsEas.ridges}
      castsOptionTagsFieldName={"easCastsOptionTags"}
      castsOptionTags={fields.easCastsOptionTags}
      castsOptionTagsSuggestions={easOptions.castsOptions}
      castsOptionTagsHasValidation={$validation.easCastsOptionTags.show}
      castsOptionTagsValidationReason={
        $validation.easCastsOptionTags.error.reason
      }
      castsOptionRefs={refsEas.casts}
      bacterialFloraOptionIdFieldName={"easBacterialFloraOptionId"}
      bacterialFloraOptionId={fields.easBacterialFloraOptionId}
      bacterialFloraOptionIdHasValidation={
        $validation.easBacterialFloraOptionId.show
      }
      bacterialFloraOptionIdValidationReason={
        $validation.easBacterialFloraOptionId.error.reason
      }
      bacterialFloraOptions={easOptions.bacterialFloraOptions}
      bacterialFloraOptionRefs={refsEas.bacterialFlora}
      spermatozoaOptionIdFieldName={"easSpermatozoaOptionId"}
      spermatozoaOptionId={fields.easSpermatozoaOptionId}
      spermatozoaOptionIdHasValidation={$validation.easSpermatozoaOptionId.show}
      spermatozoaOptionIdValidationReason={
        $validation.easSpermatozoaOptionId.error.reason
      }
      spermatozoaOptions={easOptions.spermatozoaOptions}
      spermatozoaOptionRefs={refsEas.spermatozoa}
      obsFieldName={"easObs"}
      obs={fields.easObs}
      suggestionsFilter={suggestionsFilter}
      handleChange={handleChange}
      handleBlur={handleBlur}
      handleToggle={handleToggle}
      shouldDisable={disableAll}
    />
  );
};

export const renderMicrofilariaePanel = (data, methods) => {
  const {
    fields,
    $validation,
    disableAll,
    result2,
    checkdState2,
    microfilariaeResearchCheckboxes,
    microfilariaeResearchObs,
  } = data;
  const { handleBlur, handleChange, handleResultChange2, multiBoxChange2 } =
    methods;

  fields.microfilariaeResearchResult = result2;

  return (
    <MicrofilariaeResearchPanel
      resultFieldName={"microfilariaeResearchResult"}
      result={result2}
      resultHasValidation={$validation.microfilariaeResearchResult.show}
      resultValidationReason={
        $validation.microfilariaeResearchResult.error.reason
      }
      obsFieldName="microfilariaeResearchObs"
      obs={fields.microfilariaeResearchObs}
      obsHasValidation={$validation.microfilariaeResearchObs.show}
      obsValidationReason={$validation.microfilariaeResearchObs.error.reason}
      handleBlur={handleBlur}
      handleChange={handleChange}
      shouldDisable={disableAll}
      handleResultChange={handleResultChange2}
      checkboxes={microfilariaeResearchCheckboxes}
      multiCheckboxChange={multiBoxChange2}
      checkdState={checkdState2}
    />
  );
};

export const renderHemocytozoaPanel = (data, methods) => {
  const {
    fields,
    $validation,
    disableAll,
    hemocitozoaResearchCheckboxes,
    checkdState,
    result,
  } = data;
  const { handleBlur, handleResultChange, handleChange, multiBoxChange } =
    methods;
  fields.hemocytozoaResearchResult = result;

  return (
    <HemocytozoaResearchPanel
      resultFieldName={"hemocytozoaResearchResult"}
      result={result}
      resultHasValidation={$validation.hemocytozoaResearchResult.show}
      resultValidationReason={
        $validation.hemocytozoaResearchResult.error.reason
      }
      obsFieldName={"hemocytozoaResearchObs"}
      obs={fields.hemocytozoaResearchObs}
      obsHasValidation={$validation.hemocytozoaResearchObs.show}
      obsValidationReason={$validation.hemocytozoaResearchObs.error.reason}
      handleChange={handleChange}
      handleBlur={handleBlur}
      shouldDisable={disableAll}
      handleResultChange={handleResultChange}
      checkboxes={hemocitozoaResearchCheckboxes}
      multiCheckboxChange={multiBoxChange}
      checkdState={checkdState}
    />
  );
};

export const renderChemiluminescencePanel = (data, methods) => {
  const {
    chemiluminescenceTableData,
    isUpdate,
    disableAll,
    requestExamStates,
    fields,
  } = data;
  const { handleBlur, handleChange } = methods;

  return (
    <>
      <FlatPanelMaterial title="Quimioluminescência">
        <CheckboxMaterial
          name={"deactivateChemilExam"}
          titleAfter="Resultado Anexo"
          disabled={disableAll}
          onChange={handleChange}
          checked={fields.deactivateChemilExam}
        />
        <ProfileEndocrinologyTable
          data={chemiluminescenceTableData}
          isUpdate={isUpdate}
          handleChange={handleChange}
          handleBlur={handleBlur}
          shouldDisable={disableAll || fields.deactivateChemilExam}
          requestExamStates={requestExamStates}
          chemilObs={fields.chemiluminescenceObs}
          handleInative={true}
        />
      </FlatPanelMaterial>
    </>
  );
};

export const renderEhrlichiaPanel = (data, methods) => {
  const { fields, disableAll, $validation } = data;
  const { handleChange, handleBlur } = methods;

  return (
    <>
      <DefaultMolecularBiologyPanel
        title={"PCR Ehrlichia"}
        materialsFieldName={"ehrlichiaMolecularBiologyMaterials"}
        materials={fields.ehrlichiaMolecularBiologyMaterials}
        materialsSuggestions={MOLECULAR_BIOLOGY_MATERIALS}
        materialsSuggestionsFilter={suggestionsFilter}
        materialsHasValidation={
          $validation.ehrlichiaMolecularBiologyMaterials.show
        }
        materialsValidationReason={
          $validation.ehrlichiaMolecularBiologyMaterials.error.reason
        }
        techniqueFieldName="ehrlichiaTechnique"
        technique={fields.ehrlichiaTechnique}
        techniqueHasValidation={$validation.ehrlichiaTechnique.show}
        techniqueValidationReason={$validation.ehrlichiaTechnique.error.reason}
        qualitativeResultFieldName="ehrlichiaQualitativeResult"
        qualitativeResult={fields.ehrlichiaQualitativeResult}
        qualitativeResultHasValidation={
          $validation.ehrlichiaQualitativeResult.show
        }
        qualitativeResultValidationReason={
          $validation.ehrlichiaQualitativeResult.error.reason
        }
        copyValuesFieldName="ehrlichiaCopyValues"
        copyValues={fields.ehrlichiaCopyValues}
        copyValuesHasValidation={$validation.ehrlichiaCopyValues.show}
        copyValuesValidationReason={
          $validation.ehrlichiaCopyValues.error.reason
        }
        precisionFieldName="ehrlichiaPrecision"
        precision={fields.ehrlichiaPrecision}
        precisionHasValidation={$validation.ehrlichiaPrecision.show}
        precisionValidationReason={$validation.ehrlichiaPrecision.error.reason}
        resultFieldName="ehrlichiaResult"
        result={calcMolecularBiologyResult(
          fields.ehrlichiaCopyValues,
          fields.ehrlichiaPrecision,
          fields.ehrlichiaTechnique
        )}
        obsFieldName="ehrlichiaObs"
        obs={fields.ehrlichiaObs}
        shouldDisable={disableAll}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </>
  );
};

export const renderBabesiaPanel = (data, methods) => {
  const { fields, disableAll, $validation } = data;
  const { handleChange, handleBlur } = methods;

  return (
    <>
      <DefaultMolecularBiologyPanel
        title={"PCR Babésia"}
        materialsFieldName={"babesiaMaterials"}
        materials={fields.babesiaMaterials}
        materialsSuggestions={MOLECULAR_BIOLOGY_MATERIALS}
        materialsSuggestionsFilter={suggestionsFilter}
        materialsHasValidation={$validation.babesiaMaterials.show}
        materialsValidationReason={$validation.babesiaMaterials.error.reason}
        techniqueFieldName="babesiaTechnique"
        technique={fields.babesiaTechnique}
        techniqueHasValidation={$validation.babesiaTechnique.show}
        techniqueValidationReason={$validation.babesiaTechnique.error.reason}
        qualitativeResultFieldName="babesiaQualitativeResult"
        qualitativeResult={fields.babesiaQualitativeResult}
        qualitativeResultHasValidation={
          $validation.babesiaQualitativeResult.show
        }
        qualitativeResultValidationReason={
          $validation.babesiaQualitativeResult.error.reason
        }
        copyValuesFieldName="babesiaCopyValues"
        copyValues={fields.babesiaCopyValues}
        copyValuesHasValidation={$validation.babesiaCopyValues.show}
        copyValuesValidationReason={$validation.babesiaCopyValues.error.reason}
        precisionFieldName="babesiaPrecision"
        precision={fields.babesiaPrecision}
        precisionHasValidation={$validation.babesiaPrecision.show}
        precisionValidationReason={$validation.babesiaPrecision.error.reason}
        resultFieldName="babesiaResult"
        result={calcMolecularBiologyResult(
          fields.babesiaCopyValues,
          fields.babesiaPrecision,
          fields.babesiaTechnique
        )}
        obsFieldName="babesiaObs"
        obs={fields.babesiaObs}
        shouldDisable={disableAll}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </>
  );
};

export const renderQualitativeAnaplasmPanel = (data, methods) => {
  const { fields, disableAll, $validation } = data;
  const { handleChange, handleBlur } = methods;

  return (
    <>
      <DefaultMolecularBiologyPanel
        title={"Anaplasma Qualitativo"}
        materialsFieldName={"qualitativeAnaplasmMaterials"}
        materials={fields.qualitativeAnaplasmMaterials}
        materialsSuggestions={MOLECULAR_BIOLOGY_MATERIALS}
        materialsSuggestionsFilter={suggestionsFilter}
        materialsHasValidation={$validation.qualitativeAnaplasmMaterials.show}
        materialsValidationReason={
          $validation.qualitativeAnaplasmMaterials.error.reason
        }
        techniqueFieldName="qualitativeAnaplasmTechnique"
        technique={fields.qualitativeAnaplasmTechnique}
        techniqueHasValidation={$validation.qualitativeAnaplasmTechnique.show}
        techniqueValidationReason={
          $validation.qualitativeAnaplasmTechnique.error.reason
        }
        qualitativeResultFieldName="qualitativeAnaplasmQualitativeResult"
        qualitativeResult={fields.qualitativeAnaplasmQualitativeResult}
        qualitativeResultHasValidation={
          $validation.qualitativeAnaplasmQualitativeResult.show
        }
        qualitativeResultValidationReason={
          $validation.qualitativeAnaplasmQualitativeResult.error.reason
        }
        copyValuesFieldName="qualitativeAnaplasmCopyValues"
        copyValues={fields.qualitativeAnaplasmCopyValues}
        copyValuesHasValidation={$validation.qualitativeAnaplasmCopyValues.show}
        copyValuesValidationReason={
          $validation.qualitativeAnaplasmCopyValues.error.reason
        }
        precisionFieldName="qualitativeAnaplasmPrecision"
        precision={fields.qualitativeAnaplasmPrecision}
        precisionHasValidation={$validation.qualitativeAnaplasmPrecision.show}
        precisionValidationReason={
          $validation.qualitativeAnaplasmPrecision.error.reason
        }
        resultFieldName="qualitativeAnaplasmResult"
        result={calcMolecularBiologyResult(
          fields.qualitativeAnaplasmCopyValues,
          fields.qualitativeAnaplasmPrecision,
          fields.qualitativeAnaplasmTechnique
        )}
        obsFieldName="qualitativeAnaplasmObs"
        obs={fields.qualitativeAnaplasmObs}
        shouldDisable={disableAll}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </>
  );
};

// export const renderOutsourcedPanel = (data, methods) => {
//   const { fields, outsourcedExams, files, $validation, disableAll } = data;
//   const { handleChange, handleBlur, camelCase } = methods;

//   fields.editorState = EditorState.createEmpty();
//   return (
//     <>
//       {outsourcedExams &&
//         outsourcedExams.map((exam) => {
//           const fileInputName = camelCase(exam.name + "Input");
//           const editorName = camelCase(exam.name + "Editor");
//           const fileFieldName = camelCase(exam.name + "Files");
//           const examFiles = files ? files[fileFieldName] : [];

//           const contentBlock = htmlToDraft(fields.outsourcedResult);
//           let editorState = null;

//           if (contentBlock) {
//             const contentState = ContentState.createFromBlockArray(
//               contentBlock.contentBlocks
//             );
//             editorState = EditorState.createWithContent(contentState);
//           } else {
//             editorState = EditorState.createEmpty();
//           }
//           return (
//             <OutsourcedProfilePanelView
//               title={exam.label}
//               fileInputName={fileInputName}
//               fileFieldName={fileFieldName}
//               files={examFiles}
//               editorName={editorName}
//               editorState={editorState}
//               handleChange={handleChange}
//               handleBlur={handleBlur}
//               disableAll={disableAll}
//               $validation={$validation}
//             />
//           );
//         })}
//       ;
//     </>
//   );
// };

export const renderRpcuPanel = (data, methods) => {
  data.fields.rpcuResult = methods.calculateRpcuRatio(
    data.fields.rpcuProtein,
    data.fields.rpcuCreatinine
  );
  return (
    <>
      <FlatPanelMaterial title="Relação Proteína/Creatinina Urinária">
        <Row>
          <Col md={12}>
            <FlatPanelMaterial title="Resultado">
              <Row>
                <Col md={3}>
                  <NumberInputMaterial2
                    title="Protein"
                    name="rpcuProtein"
                    hasValidationRecoil={true}
                    decimalScale={1}
                    value={data.fields.rpcuProtein}
                    disabled={data.disableAll}
                    inputGroupAfter="mg/dL"
                    inputGroup={true}
                    handleChange={methods.handleChange}
                    handleBlur={methods.handleBlur}
                    hasValidation={data.$validation.rpcuProtein.show}
                    validationReason={data.$validation.rpcuProtein.error.reason}
                  />
                </Col>
                <Col md={3}>
                  <NumberInputMaterial2
                    title="Creatinine"
                    name="rpcuCreatinine"
                    hasValidationRecoil={true}
                    decimalScale={1}
                    value={data.fields.rpcuCreatinine}
                    disabled={data.disableAll}
                    inputGroupAfter="mg/dL"
                    inputGroup={true}
                    handleChange={methods.handleChange}
                    handleBlur={methods.handleBlur}
                    hasValidation={data.$validation.rpcuCreatinine.show}
                    validationReason={
                      data.$validation.rpcuCreatinine.error.reason
                    }
                  />
                </Col>
                <Col md={6}>
                  <NumberInputWithReference2
                    fieldTitle="Resultado"
                    value={data.fields.rpcuResult}
                    fieldName="rpcuResult"
                    shouldDisable={true}
                    allowNegative={false}
                    handleChange={methods.handleChange}
                    handleBlur={methods.handleBlur}
                    valueMin={data.rpcuRefs.proteinCreatinineRatioMin}
                    valueMax={data.rpcuRefs.proteinCreatinineRatioMax}
                    decimalScale={2}
                    unit="mg/dL"
                  />
                </Col>
              </Row>
            </FlatPanelMaterial>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TextAreaPanel2
              title={"Obs"}
              fieldTitle={""}
              name={"rpcuObs"}
              value={data.fields.rpcuObs}
              shouldDisable={data.disableAll}
              handleChange={methods.handleChange}
              handleBlur={methods.handleBlur}
            />
          </Col>
        </Row>
      </FlatPanelMaterial>
    </>
  );
};

export const renderReticulocytePanel = (data, methods) => {
  const { reticulocyteRefs, fields, $validation, disableAll } = data;
  const { handleBlur, handleChange } = methods;

  fields.reticulocytePercent = calcReticulocytePercent(
    fields.reticulocyteByField
  );
  fields.reticulocyteAbsoluteErythrocyteCount = calcAbsoluteErythrocyteCount(
    fields.reticulocyteErythrocyteCount
  );
  fields.reticulocyteTotalReticulocyte = calcTotalReticulocyte(
    fields.reticulocyteErythrocyteCount,
    fields.reticulocyteByField
  );
  fields.reticulocyteCorrection = calcReticulocyteCorrection(
    fields.reticulocyteCorpuscularVolume,
    fields.reticulocyteByField
  );
  return (
    <>
      <FlatPanelMaterial title={"Contagem de Reticulócitos"}>
        <Row>
          <Col md={6}>
            <ReticulocyteCountPanel
              panelTitle={"Resultado"}
              reticulocyteByField={fields.reticulocyteByField}
              reticulocyteByFieldName={"reticulocyteByField"}
              reticulocyteByFieldTitle={"Ret. por Campo"}
              reticulocyteByFieldHasValidation={
                $validation.reticulocyteByField.show
              }
              reticulocyteByFieldValidationReason={
                $validation.reticulocyteByField.error.reason
              }
              erythrocyteCount={fields.reticulocyteErythrocyteCount}
              erythrocyteCountName={"reticulocyteErythrocyteCount"}
              erythrocyteCountTitle={"Nº de Hemácias"}
              erythrocyteCountHasValidation={
                $validation.reticulocyteErythrocyteCount.show
              }
              erythrocyteCountValidationReason={
                $validation.reticulocyteErythrocyteCount.error.reason
              }
              corpuscularVolume={fields.reticulocyteCorpuscularVolume}
              corpuscularVolumeName={"reticulocyteCorpuscularVolume"}
              corpuscularVolumeTitle={"Vol. Globular"}
              corpuscularVolumeHasValidation={
                $validation.reticulocyteCorpuscularVolume.show
              }
              corpuscularVolumeValidationReason={
                $validation.reticulocyteCorpuscularVolume.error.reason
              }
              reticulocytePercent={fields.reticulocytePercent}
              reticulocytePercentName={"reticulocytePercent"}
              reticulocytePercentTitle={"% de Ret."}
              absoluteErythrocyteCount={
                fields.reticulocyteAbsoluteErythrocyteCount
              }
              absoluteErythrocyteCountName={
                "reticulocyteAbsoluteErythrocyteCount"
              }
              absoluteErythrocyteCountTitle={"Nº de Hemácias Abs"}
              totalReticulocyteName={"reticulocyteTotalReticulocyte"}
              totalReticulocyteTitle={"Total de Retic."}
              totalReticulocyte={fields.reticulocyteTotalReticulocyte}
              reticulocyteCorrectionName={"reticulocyteCorrection"}
              reticulocyteCorrectionTitle={"Correção de Ret."}
              reticulocyteCorrection={fields.reticulocyteCorrection}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Col>

          <Col md={6}>
            <ReticulocyteNormalFractionPanel
              normalMin={reticulocyteRefs.reticCountFractionNormalMin}
              normalMax={reticulocyteRefs.reticCountFractionNormalMax}
              lowMin={reticulocyteRefs.reticCountFractionLowMin}
              lowMax={reticulocyteRefs.reticCountFractionLowMax}
              moderateMin={reticulocyteRefs.reticCountFractionModerateMin}
              moderateMax={reticulocyteRefs.reticCountFractionModerateMax}
              highMin={reticulocyteRefs.reticCountFractionHighMin}
              highMax={reticulocyteRefs.reticCountFractionHighMax}
              unit={"mg/dL"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TextAreaPanel2
              title={"Obs"}
              fieldTitle={""}
              name={"reticulocyteObs"}
              value={fields.reticulocyteObs}
              shouldDisable={disableAll}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Col>
        </Row>
      </FlatPanelMaterial>
    </>
  );
};

export const renderBloodProteinPanel = (data, methods) => {
  const { bloodProteinRefs, $field, fields, $validation, disableAll } = data;
  const { fieldChange, handleBlur, handleChange } = methods;

  return (
    <>
      <BloodProteinPanel
        panelTitle={"Proteínas Plasmáticas"}
        result={fields.bloodProteinResult}
        resultName={"bloodProteinResult"}
        refMin={bloodProteinRefs.bloodProteinMin}
        refMax={bloodProteinRefs.bloodProteinMax}
        disableAll={disableAll}
        fieldChange={fieldChange}
        $field={$field}
        validationReason={$validation.bloodProteinResult.error.reason}
        hasValidation={$validation.bloodProteinResult.show}
      />
    </>
  );
};

export const renderLeishmaniasisPanel = (data, methods) => {
  const { fields, $validation, disableAll } = data;
  const { handleBlur, handleChange } = methods;

  return (
    <>
      <DefaultMolecularBiologyPanel
        title={"Leishmaniose"}
        materialsFieldName={"leishmaniasisMaterials"}
        materials={fields.leishmaniasisMaterials}
        materialsSuggestions={MOLECULAR_BIOLOGY_MATERIALS}
        materialsSuggestionsFilter={suggestionsFilter}
        materialsHasValidation={$validation.leishmaniasisMaterials.show}
        materialsValidationReason={
          $validation.leishmaniasisMaterials.error.reason
        }
        techniqueFieldName="leishmaniasisTechnique"
        technique={fields.leishmaniasisTechnique}
        techniqueHasValidation={$validation.leishmaniasisTechnique.show}
        techniqueValidationReason={
          $validation.leishmaniasisTechnique.error.reason
        }
        qualitativeResultFieldName="leishmaniasisQualitativeResult"
        qualitativeResult={fields.leishmaniasisQualitativeResult}
        qualitativeResultHasValidation={
          $validation.leishmaniasisQualitativeResult.show
        }
        qualitativeResultValidationReason={
          $validation.leishmaniasisQualitativeResult.error.reason
        }
        copyValuesFieldName="leishmaniasisCopyValues"
        copyValues={fields.leishmaniasisCopyValues}
        copyValuesHasValidation={$validation.leishmaniasisCopyValues.show}
        copyValuesValidationReason={
          $validation.leishmaniasisCopyValues.error.reason
        }
        precisionFieldName="leishmaniasisPrecision"
        precision={fields.leishmaniasisPrecision}
        precisionHasValidation={$validation.leishmaniasisPrecision.show}
        precisionValidationReason={
          $validation.leishmaniasisPrecision.error.reason
        }
        resultFieldName="leishmaniasisResult"
        result={calcMolecularBiologyResult(
          fields.leishmaniasisCopyValues,
          fields.leishmaniasisPrecision,
          fields.leishmaniasisTechnique
        )}
        obsFieldName="leishmaniasisObs"
        obs={fields.leishmaniasisObs}
        shouldDisable={disableAll}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </>
  );
};
