import __update from "immutability-helper";
import { Phone, Action } from "./../../types/index";

export const ACTIONS = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  RESET: "RESET",
};

const add = (value: Array<Phone>, target: Array<Phone>): Array<Phone> => {
  const commands = {
    $push: value,
  };
  return __update(target, commands);
};

const update = (value: Phone, target: Array<Phone>): Array<Phone> => {
  const newValue: Array<Phone> = target.map((curr) =>
    curr.id === value.id ? value : curr
  );
  const commands = {
    $set: newValue,
  };
  return __update(target, commands);
};

const remove = (value: Phone, target: Array<Phone>): Array<Phone> => {
  const newValue: Array<Phone> = target.filter((curr) => curr.id !== value.id);
  const commands = {
    $set: newValue,
  };
  return __update(target, commands);
};

const reset = (target: Array<Phone>): Array<Phone> => {
  const commands = {
    $set: [],
  };
  return __update(target, commands);
};

export const reducer = (state: Array<Phone>, action: Action) => {
  switch (action.type) {
    case ACTIONS.ADD:
      return add(action.payload, state);
    case ACTIONS.UPDATE:
      return update(action.payload, state);
    case ACTIONS.REMOVE:
      return remove(action.payload, state);
    case ACTIONS.RESET:
      return reset(state);
    default:
      return state;
  }
};
