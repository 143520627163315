import React, { useRef } from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import InputValidationMsg from "./../InputValidationMsg";
import styles from "./index.module.css";

const defaultProps = {
  md: 12,
  type: "text",
  title: "",
  disabled: false,
  validationReason: "",
  hasValidation: false,
  validationType: "danger",
  hasValidationRecoil: true
};

const propTypes = {
  /**
   * Required
   */
  name: PropTypes.string.isRequired,

  /**
   * Default
   */
  title: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validationType: PropTypes.oneOf(["danger", "warning", "info"]),
  hasValidationRecoil: PropTypes.bool,

  /**
   * Custom
   */
  md: PropTypes.number,
  labelInline: PropTypes.bool,
  inputGroup: PropTypes.bool,
  inputGroupAfter: PropTypes.string,
  titleAfter: PropTypes.string,
  hasValidation: PropTypes.bool,
  validationReason: PropTypes.string,
  block: PropTypes.bool,
  fit: PropTypes.bool,

  /**
   * Classes
   */
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for input text with material design styling
 */
const TextAreaMaterial2 = ({
  name,
  title,
  value,
  handleBlur,
  handleChange,
  disabled,
  titleAfter,
  hasValidation,
  validationType,
  hasValidationRecoil,
  validationReason
}) => {
  let onChange = undefined;
  const texAreaRef = useRef(null);

  if (handleChange) {
    onChange = event => {
      if (handleChange) {
        handleChange(name, event.target.value);
      }
    };
  }

  const changeTexRows = (ref) => {
    if(ref && ref.current)
      ref.current.style.height = ref.current.scrollHeight + "px";
  };
  
  /**
   * Validation Component
   * @type {Object}
   */
  const validation = (
    <InputValidationMsg
      type={validationType}
      visible={hasValidation}
      message={validationReason}
      top={hasValidationRecoil ? "-18px" : ""}
    />
  );

  return (
    <div className={"textAreaWrapper"}>
      <div className="form-group">
        {title && <label>{title}</label>}
        <textarea
          ref={texAreaRef}
          name={name}
          value={value}
          onBlur={handleBlur}
          disabled={disabled}
          onChange={onChange}
          className={"textAreaMaterial"}
          style={{ 
          height: "40px",
        }}          
        />
        {changeTexRows(texAreaRef)}
        {titleAfter && (
          <label className={styles.labelAfeterMaterial}>{titleAfter}</label>
        )}
      </div>
      {validation}
    </div>
  );
};

TextAreaMaterial2.defaultProps = defaultProps;
TextAreaMaterial2.propTypes = propTypes;

export default TextAreaMaterial2;
