import WarningSound from "./../../assets/sounds/sonic-warning.mp3";

/**
 * @author Sergio Pallet
 *
 * Service for Sounds
 */
class SoundService {
  /**
   * @author Sergio Alex Pallet
   *
   * Play the warning sound
   *
   * @return {void}
   */
  static playWarningSound() {
    new Audio(WarningSound).play();
  }
}

export default SoundService;
