import { Decimal } from "decimal.js";
// import moment from "moment";
import moment from "./../assets/js/moment.js";
Decimal.set({ rounding: 4 });

/**
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Useful methods to string
 */
class StringUtil {
  /**
   * @author Victor Heringer
   *
   * Retrive all numbers from given string
   *
   * @param {String} value
   *
   * @return {String}
   */
  static onlyNumbers(value) {
    if (value == undefined || !value) return "";
    const array = value.match(/\d/g);
    return array && array.length > 0 ? value.match(/\d/g).join("") : "";
  }

  /**
   * @author Victor Heringer
   *
   * Format a number to string format cnpj
   *
   * @param {Number} number CNPJ number
   *
   * @return {String}
   */
  static formatCNPJ(number) {
    if (!number) {
      return "";
    }

    number = number.toString();

    const regex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
    return number.replace(regex, "$1.$2.$3/$4-$5");
  }

  /**
   * @author Victor Heringer
   *
   * Format a number to string format cpf
   *
   * @param {Number} number cpf number
   *
   * @return {String}
   */
  static formatCPF(number) {
    if (!number) {
      return "";
    }

    number = number.toString();

    const regex = /^(\d{3})(\d{3})(\d{3})(\d{2})/;
    return number.replace(regex, "$1.$2.$3-$4");
  }

  static formatCEP(number) {
    if (!number) {
      return "";
    }

    number = number.toString();

    const regex = /^(\d{5})(\d{3})/;
    return number.replace(regex, "$1-$2");
  }

  /**
   * @author Victor Heringer
   *
   * Format a number to string format phone
   *
   * @param {Number} number CNPJ number
   *
   * @return {String}
   */
  static formatPhone(phone) {
    phone = phone.toString();

    if (!phone) {
      throw "Format phone function expect the first param to be no null";
    }

    if (phone.length === 10) {
      return phone.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2-$3");
    }

    return phone.replace(/(\d{2})(\d{5})(\d{4})/, "$1 $2-$3");
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Capitalizes first letter of string
   *
   * @param {Strig} str
   *
   * @return {String}
   */
  static capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * @author Victor Heringer
   *
   * Converts date from db to ui
   *
   * @param {Strig} str
   *
   * @return {String}
   */
  static formatDateUi(date) {
    return date === "-000001-11-30T03:06:28.000000Z"
      ? ""
      : moment(date).format("DD/MM/YY HH:mm");
  }

  /**
   * @author Victor Heringer
   *
   * Converts a flat object to a query string
   *
   * @param {Strig} obj
   *
   * @return {String}
   */
  static objectToQueryString(obj) {
    return Object.keys(obj).reduce(
      (q, k) => (q != k ? `${q}&${k}=${obj[k]}` : `?${k}=${obj[k]}`),
      Object.keys(obj)[0]
    );
  }

  static isset(value, Decimal) {
    return (
      value !== null && value !== undefined && value !== "" && value !== false
    );
  }

  static isNumeric(value) {
    const re = /(0|[1-9][0-9]*)$/;
    return re.test(value);
  }

  static isNumericStr(value) {
    return /^\d+$/.test(value);
  }

  static isDecimalWithOneDigit(value) {
    var ex = /^[0-9]+\,?[0-9]*$/;

    if (ex.test(value) == false) {
      return false;
    }
    return true;
  }

  static thousandsFormatting(value) {
    return new Intl.NumberFormat("de-DE").format(value);
  }

  static formatNumber(value) {
    let decimal = new Decimal(value.replace(",", "."));
    decimal.d[0] = this.thousandsFormatting(decimal.d[0]);
    return decimal.toPrecision();
  }

  static isNumberWithOneComaAtMost(value) {
    var match = ("" + value).match(/^[0-9](.)+$/);
    if (!match) {
      return false;
    }
    return true;
  }

  static numberOfDecimalPlaces(num) {
    var match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
      return 0;
    }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0) -
        // Adjust for scientific notation.
        (match[2] ? +match[2] : 0)
    );
  }

  static strHasSubstr(str, substring) {
    return str.indexOf(substring) !== -1;
  }

  static strLastChar(str) {
    return str.slice(-1);
  }

  static removeTraillingZeroes(numStr) {
    var precedingZero = /^[0]+/;
    var traillingZero = /[0]+$/;
    var decimalSeparator = /[.]$/;
    var result = "";

    result = numStr;

    if (result.indexOf(".") > -1) {
      // Remove trailing 0's
      result = result.replace(traillingZero, "");
    }
    // Remove trailing decimal
    result = result.replace(decimalSeparator, "");

    return result;
  }

  static substrOcurrenceCount(str, substring) {
    var count = 0;
    var pos = str.indexOf(substring);
    while (pos > -1) {
      ++count;
      pos = str.indexOf(substring, ++pos);
    }

    return count;
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Check if a value is empty string
   *
   * @param  {Mix}  value The value that will be tested
   *
   * @return {Boolean}       True if is empty string
   */
  static isStrEmpty(value) {
    return value === "";
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Checks if a value is filled
   *
   * @param  {Mix}  value The value that will be tested
   *
   * @return {Boolean}       True if is empty filled
   */
  static isStrFilled(value) {
    return !this.isStrEmpty(value);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Checks if there is at least one string empty
   *
   * @param  {string}  value The params that will be tested
   *
   * @return {Boolean}       True if at least one param is empty string
   */
  static areAnyStrsEmpty() {
    for (var arg = 0; arg < arguments.length; ++arg) {
      var element = arguments[arg];

      if (this.isStrEmpty(element)) {
        return true;
      }
    }

    return false;
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Converts a empty string into 0
   *
   * @param  {Mix}  value The value that will be tested
   *
   * @return {Mix}        0 if is a empty string, otherwise return the value
   */
  static ifEmptyStrToZero(value) {
    return this.isStrEmpty(value) || !value ? 0 : value;
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param  {number}  number
   *
   * @return {string}
   */
  static toStrWithZeroToEmptyStr(number) {
    return number === 0 ? "" : number.toString();
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param  {number}  number
   *
   * @return {string}
   */
  static isFloat(number) {
    return number % 1 != 0;
  }

  /**
   * @author Sergio Alex Pallet
   *
   * Return Months Passed from the date
   *
   * @param  {date}  String in date time format
   *
   * @return {string} Months
   */
  static dateToMonths(date) {
    const now = moment();
    const months = now.diff(date, "months") % 12;
    if (months < 0) {
      return 0;
    }
    return months;
  }

  /**
   * @author Kayo Oliveira
   *
   * Return Months Passed from the date and "created_at"
   *
   * @param  {date}  String in date time format
   *
   * @return {string} Months
   */
  static newDateToMonths(date, createdAt) {
    const createdAtDate = moment(new Date(createdAt));
    const newDate = moment(new Date(date));
    const months = createdAtDate.diff(newDate, "months") % 12;
    if (months < 0) {
      return 0;
    }
    return months;
  }

  /**
   * @author Kayo Oliveira
   *
   * Return Years Passed from the date and "created_at"
   *
   * @param  {date}  String in date time format
   *
   * @return {string} Years
   */
  static newDateToYears(date, createdAt) {
    const createdAtDate = moment(new Date(createdAt));
    const newDate = moment(new Date(date));
    const years = createdAtDate.diff(newDate, "years");
    if (years < 0) {
      return 0;
    }
    return years;
  }

  /**
   * @author Sergio Alex Pallet
   *
   * Return Years Passed from the date
   *
   * @param  {date}  String in date time format
   *
   * @return {string} Years
   */
  static dateToYears(date) {
    const now = moment();
    const years = now.diff(date, "years");
    if (years < 0) {
      return 0;
    }
    return years;
  }

  static buildQueryString(params = {}) {
    let query = "?";
    for (let prop in params) {
      if (params[prop] !== null) query += `${prop}=${params[prop]}&`;
    }

    return query;
  }

  static queryParamsAsObject(query) {
    const params = new URLSearchParams(query);
    const queryParams = {};
    for (const [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    return queryParams;
  }
}

export default StringUtil;
