/**
 * @author Victor Heringer
 *
 * Handles data format manipulaion
 */
class LabFormFactory {
  /**
   * @author Victor Heringer
   *
   * Convert customer data format from api to client expected data
   *
   * @param {Object} data
   *
   * @return {Object}
   */
  static formatLabFromApi(data) {
    const address = data.company.person.addresses[0];

    let lab = {
      /** Company */
      name: data.company.person.name,
      status: data.company.person.is_active,
      cnpj: data.company.cnpj,
      companyId: data.company.id,

      /** Address */
      cep: address.cep,
      neighborhood: address.neighborhood,
      publicPlace: address.public_place,
      number: address.number,
      complement: address.complement,
      stateId: address.city.state_id,
      cityId: address.city.id,

      regions: data.regions,
      phones: LabFormFactory.formatPhonesFromApi(data.company.person.phones),
      emails: LabFormFactory.formatEmailsFromApi(data.company.person.emails),
      users: LabFormFactory.formatUsersFromApi(data.users),

      responsible: LabFormFactory.formatResponsibleFromApi(data.responsible),
    };
    return lab;
  }

  /**
   * @author Victor Heringer
   *
   * Convert email data format from api to client expected data
   *
   * @param {Array} emails
   *
   * @return {Object}
   */
  static formatEmailsFromApi(emails) {
    return emails.map((data) => {
      return { id: data.id, name: data.value };
    });
  }

  /**
   * @author Victor Heringer
   *
   * Convert phone data format from api to client expected data
   *
   * @param {Array} phones
   *
   * @return {Object}
   */
  static formatPhonesFromApi(phones) {
    return phones.map((data) => {
      return { id: data.id, value: data.value };
    });
  }

  /**
   * @author Victor Heringer
   *
   * Convert user data format from api to client expected data
   *
   * @param {Array} users
   *
   * @return {Object}
   */
  static formatUsersFromApi(users) {
    return users.map((data) => {
      const user = data.user;
      const isVet = data.user.individual.is_vet;

      let userData = {
        /** User */
        id: user.id,
        login: user.name,
        active: user.active,
        password: "",
        cpf: user.individual.cpf,
        name: user.individual.person.name,
        email: "",
        phone: "",
        accessProfile: user.access_profile,

        /** Vet */
        crmv: "",
        isVet: isVet,
        signature: [],
      };

      if (user.individual.person.phones.length > 0) {
        userData.phone = data.user.individual.person.phones[0].value;
      }

      if (user.individual.person.emails.length > 0) {
        userData.email = data.user.individual.person.emails[0].value;
      }

      if (isVet) {
        const signatureFile = data.user.individual.vet.signature_file;
        const signatureData = data.user.individual.vet.signature_data;
        userData.crmv = user.individual.vet.crmv;

        if (signatureFile) {
          userData.signature = [
            {
              name: signatureFile,
              value: signatureData,
              extension: signatureFile.split(".").pop(),
            },
          ];
        } else {
          userData.signature = [
            {
              name: "",
              value: "",
              extension: "",
            },
          ];
        }
      }

      return userData;
    });
  }

  /**
   * @author Kayo Oliveira
   *
   * Convert responsible data format from api to client expected data
   *
   * @param {Array} responsible
   *
   * @return {Object}
   */

  static formatResponsibleFromApi(responsibles) {
    // console.log(responsibles);
    return responsibles.map((data) => {
      const responsible = data.user;
      const isVet = data.user.individual.is_vet;

      let responsibleData = {
        /** Responsible */
        id: responsible.id,
        login: responsible.name,
        active: responsible.active,
        password: "",
        cpf: responsible.individual.cpf,
        name: responsible.individual.person.name,
        email: "",
        phone: "",
        accessProfile: responsible.access_profile,

        /** Vet */
        crmv: "",
        isVet: isVet,
        signature: [],
      };

      if (responsible.individual.person.phones.length > 0) {
        responsibleData.phone = data.user.individual.person.phones[0].value;
      }

      if (responsible.individual.person.emails.length > 0) {
        responsibleData.email = data.user.individual.person.emails[0].value;
      }

      if (isVet) {
        const signatureFile = data.user.individual.vet.signature_file;
        const signatureData = data.user.individual.vet.signature_data;
        responsibleData.crmv = responsible.individual.vet.crmv;

        if (signatureFile) {
          responsibleData.signature = [
            {
              name: signatureFile,
              value: signatureData,
              extension: signatureFile.split(".").pop(),
            },
          ];
        } else {
          responsibleData.signature = [
            {
              name: "",
              value: "",
              extension: "",
            },
          ];
        }
      }

      return responsibleData;
    });
  }

  /**
   * @author Victor Heringer
   *
   * Returns default data for user
   *
   * @return {Object}
   */
  static createDefaultUser() {
    return {
      id: 0,
      login: "",
      active: 1,
      password: "",
      username: "",
      email: "",
      accessProfile: 1,
      cpf: "",
      phone: "",
      isVet: 0,
      crmv: "",
      signature: [],
    };
  }

  static createDefaultResponsible() {
    return {
      id: 0,
      login: "",
      active: 1,
      password: "",
      username: "",
      email: "",
      accessProfile: 1,
      cpf: "",
      phone: "",
      isVet: 0,
      crmv: "",
      signature: [],
    };
  }

  /**
   * @author Victor Heringer
   *
   * Returns default data for email
   *
   * @return {Object}
   */
  static createDefaultEmail() {
    return { id: 0, value: "" };
  }

  /**
   * @author Victor Heringer
   *
   * Returns default data for phone
   *
   * @return {Object}
   */
  static createDefaultPhone() {
    return { id: 0, value: "" };
  }

  /**
   * @author Victor Heringer
   *
   * Returns default data for region
   *
   * @return {Object}
   */
  static createDefaultRegion() {
    return { id: 0, value: "" };
  }
}

export default LabFormFactory;
