import React, { Component } from "react";

/** Internal */
import OutsourcedReportFormView from "./../OutsourcedReportFormView";
import connectWithEndpoint from "./requests.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import validation from "./validation.js";

import {
  BTN_SAVE_TITLE,
  BTN_UPDATE_TITLE,
  BTN_SAVING_TITLE,
  BTN_UPDATING_TITLE,
  REQUEST_EXAM_STATE_ID_BY_NAME,
} from "../../assets/js/Consts.js";

import { reportState } from "../../modules/factories/default-states.js";

import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";

import { plateletsDiagnosis } from "../../assets/js/reportFunctions.js";

/** External */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

import { Editor } from "react-draft-wysiwyg";

import { EditorState, convertToRaw, ContentState } from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

/**
 * @author Victor Heringer
 *
 * Adds validation to the view
 *
 * @prop {Object} data
 * @prop {Object} methods
 * @prop {Object} $field
 * @prop {Object} $validation
 * @prop {Function} $submit
 */
const View = ({ data, methods, $field, $validation, $submit, $fieldEvent }) => {
  const dataWithValidation = { $validation: $validation, ...data };

  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;

    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };

  const handleChange = (field, value) => {
    $fieldEvent("change", field);
    methods.fieldChange(field, value);
  };

  const handleBlur = (event) => {
    $fieldEvent("blur", event.target.name);
  };

  const methodsWithValidation = {
    onSubmit: onSubmit,
    $field: $field,
    handleChange: handleChange,
    handleBlur: handleBlur,
    ...methods,
  };

  return (
    <OutsourcedReportFormView
      data={dataWithValidation}
      methods={methodsWithValidation}
    />
  );
};

const ValidatedView = validation(View);

/**
 * @author Victor Heringer
 *
 * Container for ousourced exams report form
 */
class OutsourcedReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        ...reportState(),
        editorState: EditorState.createEmpty(),
        files: [],
      },
    };

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);
    this.plateletsDiagnosis = plateletsDiagnosis.bind(this);
    this.createObj = this.createObj.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.submitData = this.submitData.bind(this);
    this.shouldDisplayLoader = this.shouldDisplayLoader.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        if (xhr.response.status === 200) {
          const obj = data;
          this.fillFields(obj);
        }
      };

      this.props.withId(id, then);
    }
  }

  /**
   * @author Victor Heringer
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    const API_URL = process.env.REACT_APP_PROXY;
    const S3_URL = process.env.REACT_APP_S3_URL;

    let fileUrl = S3_URL
      ? S3_URL + "/" + obj.fileName
      : API_URL + "/storage/printed-reports/" + obj.fileName;

    const contentBlock = htmlToDraft(obj.result);
    let editorState = null;

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }

    if (obj.fileName) {
      this.setState((state) =>
        update(state, {
          fields: {
            files: {
              $push: [
                {
                  name: obj.fileName,
                  value: fileUrl,
                },
              ],
            },
          },
        })
      );
    }

    this.setState((state) =>
      update(state, {
        fields: {
          vetSignerId: { $set: obj.vetSignerId },
          requestExamState: { $set: obj.requestExamState },
          editorState: { $set: editorState },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Creates hemogram object
   *
   * @return {Object}
   *
   */
  createObj() {
    const { vetSignerId, requestExamState } = this.state.fields;
    const editorState = this.state.fields.editorState;

    const textReport = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const { files } = this.state.fields;

    return {
      id: this.getId(),
      examId: this.state.examId,
      report: files,
      textReport: files.length > 0 ? "" : textReport,
      requestExamId: this.props.match.params.requestExamId,
      vetSignerId: vetSignerId,
      requestExamState: requestExamState,
    };
  }

  /**
   * @author Victor Heringer
   *
   * Decides wether is should display the screen loader
   */
  shouldDisplayLoader() {
    const { objPromise } = this.props;

    let editFetches = null;
    const isUpdate = this.isUpdate();
    const shouldCheckEditFetches = isUpdate && objPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([objPromise]);
    }

    return shouldCheckEditFetches && editFetches && editFetches.pending;
  }

  /**
   * @author Victor Heringer
   *
   * Sorts report header promise into a data object
   *
   * @return {Object}
   */
  reportHeaderData() {
    const { reportHeader } = this.props;
    return reportHeader.fulfilled ? reportHeader.value : null;
  }

  /**
   * @author Victor Heringer
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   */
  submitData() {
    const { reportSubmitPromise } = this.props;

    const isUpdate = this.isUpdate();

    const btnTitle = isUpdate ? BTN_UPDATE_TITLE : BTN_SAVE_TITLE;

    const isSet = reportSubmitPromise !== undefined;
    const isPending = isSet && reportSubmitPromise.pending;
    const isFulfilled = isSet && reportSubmitPromise.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: isUpdate ? BTN_UPDATING_TITLE : BTN_SAVING_TITLE,
      btnTitle: btnTitle,
    };
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  render() {
    const { examRequestId } = this.props.match.params;
    const backLink = "/solicitacoes-exame/" + examRequestId;
    const { requestExamPromise } = this.props;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    // Loader
    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const submitData = this.submitData();
    const reportHeaderData = this.reportHeaderData();

    const data = {
      title:
        requestExamPromise && requestExamPromise.fulfilled
          ? requestExamPromise.value.name
          : "",
      files: this.state.fields.files,
      isUpdate: this.isUpdate(),
      fields: this.state.fields,
      backLink: backLink,
      submitData: submitData,
      examRequestId: examRequestId,
      disableAll: submitData.pending,
      editorState: this.state.fields.editorState,
      reportHeaderData: reportHeaderFactory(reportHeaderData),
    };

    const methods = {
      onEditorStateChange: this.onEditorStateChange,
      fieldChange: this.fieldChange,
      plateletsDiagnosis: this.plateletsDiagnosis,
      handleSubmit: this.handleSubmit,
      invalidNotification: this.invalidNotification,
      onUploadFile: this.onUploadFile,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      cancelReport: this.cancelReport,
      handleSendReportEmail: this.handleSendReportEmail,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      stopReport: this.stopReport,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(connectWithEndpoint)(OutsourcedReportForm);
