import React, { Component } from "react";

/** Internal */
import PricingTableListView from "./../PricingTableListView";
import PricingTableModal from "../PricingTableListView/PricingTableModal";
import ExamPlaceModal from "../PricingTableListView/ExamPlaceModal";
import connectWithEndpoint from "./requests.js";
import {
  BTN_SAVE_TITLE,
  BTN_SAVING_TITLE,
  ACCESS_PROFILES
} from "../../assets/js/Consts.js";
import { NOTIFICATIONS } from "./../../consts";
import { connect } from "react-redux";
import { FunctionUtil } from "./../../useful";

/** External */
import update from "immutability-helper";

/**
 * @author Victor Heringer
 *
 * Container for pricing table modules' methods
 */
class PricingTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        modalPricingTableName: "",
        modalExamPlaceName: "",
        selectedPricingTable: props.customerId > 0 ? props.customerId : 0,
        examValues: []
      },
      pricingTableModalShow: false,
      pricingTableModal: { pages: 1, sorted: null },
      examPlaceModalShow: false,
      examPlaceModal: { pages: 1, sorted: null }
    };

    if (props.customerId > 0) {
      const then = data => this.fillFields(data);
      this.props.pricingTableWithCustomerId(props.customerId, then);      
    }
  }

  componentDidMount() {}

  /**
   * @author Victor Heringer
   *
   * Fill form fields with data from api
   *
   * @param {Object} data
   *
   * @return {Void}
   */
  fillFields = data => {
    let obj = {};
    let val = {};

    for (let i = 0; i < data.exams.length; i++) {
      val = {
        pivot_id: data.exams[i].pivot.id,
        pricing_table_id: data.exams[i].pivot.pricing_table_id,
        exam_id: data.exams[i].pivot.exam_id,
        deadline_in_days: data.exams[i].pivot.deadline_in_days,
        is_active: data.exams[i].pivot.is_active,
        price: data.exams[i].pivot.price,
        exam_place_id: data.exams[i].pivot.exam_place_id
          ? data.exams[i].pivot.exam_place_id
          : 0
      };

      obj[val.pivot_id] = val;
    }
    
    this.setState(state =>
      update(state, { fields: { examValues: { $set: obj } } })
    );
  };

  /**
   * @author Victor Heringer
   *
   * Closes the modal
   *
   * @return {Void}
   */
  handleClosePricingTableModal = () => {
    this.setState(
      update(this.state, { $set: { pricingTableModalShow: false } })
    );
  };

  /**
   * @author Victor Heringer
   *
   * Opens the modal
   *
   * @return {Void}
   */
  handleShowPricingTableModal = () => {
    this.setState(
      update(this.state, { $set: { pricingTableModalShow: true } })
    );
  };

  /**
   * @author Victor Heringer
   *
   * Closes the modal
   *
   * @return {Void}
   */
  handleCloseExamPlaceModal = () => {
    this.setState(update(this.state, { $set: { examPlaceModalShow: false } }));
  };

  /**
   * @author Victor Heringer
   *
   * Opens the modal
   *
   * @return {Void}
   */
  handleShowExamPlaceModal = () => {
    this.setState(update(this.state, { $set: { examPlaceModalShow: true } }));
  };

  /**
   * @author Victor Heringer
   *
   * Submit data to create a new Pricing Table
   *
   * @param {Function} successCallback
   *
   * @return {Void}
   */
  handleSubmitPricingTable = successCallback => {
    const pricingTable = { name: this.state.fields.modalPricingTableName };

    const onSuccess = () => {
      this.setState(
        update(this.state, {
          fields: { modalPricingTableName: { $set: "" } }
        })
      );
      successCallback("reset");
      this.props.notificationSystem.add(NOTIFICATIONS.DEFAULT_SAVE_MESSAGE);
    };

    this.props.addPricingTable(pricingTable, onSuccess);
  };

  /**
   * @author Victor Heringer
   *
   * Submit data to create a new exam place
   *
   * @param {Function} successCallback
   *
   * @return {Void}
   */
  handleSubmitExamPlace = successCallback => {
    const examPlace = { name: this.state.fields.modalExamPlaceName };

    const onSuccess = () => {
      this.setState(
        update(this.state, {
          fields: { modalExamPlaceName: { $set: "" } }
        })
      );
      successCallback("reset");
      this.props.notificationSystem.add(NOTIFICATIONS.DEFAULT_SAVE_MESSAGE);
    };

    this.props.addExamPlace(examPlace, onSuccess);
  };

  /**
   * @author Victor Heringer
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   */
  submitPricingTableModalData = () => {
    const { PricingTableResponse } = this.props;

    const isSet = PricingTableResponse !== undefined;
    const isPending = isSet && PricingTableResponse.pending;
    const isFulfilled = isSet && PricingTableResponse.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: BTN_SAVING_TITLE,
      btnTitle: BTN_SAVE_TITLE
    };
  };

  /**
   * @author Victor Heringer
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   */
  submitExamPlaceModalData = () => {
    const { examPlaceResponse } = this.props;

    const isSet = examPlaceResponse !== undefined;
    const isPending = isSet && examPlaceResponse.pending;
    const isFulfilled = isSet && examPlaceResponse.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: BTN_SAVING_TITLE,
      btnTitle: BTN_SAVE_TITLE
    };
  };

  /**
   * @author Victor Heringer
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   */
  submitData = () => {
    const { pricingTableExamsResponse } = this.props;

    const isSet = pricingTableExamsResponse !== undefined;
    const isPending = isSet && pricingTableExamsResponse.pending;
    const isFulfilled = isSet && pricingTableExamsResponse.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: BTN_SAVING_TITLE,
      btnTitle: BTN_SAVE_TITLE
    };
  };

  /**
   * @author Victor Heringer
   *
   * Filter pricing table
   *
   * @param {Object} state
   * @param {Object} instance
   */
  pricingTableModalFilters = (state, instance) => {
    const filters = { id: "", name: "" };

    for (let data in state.filtered) {
      filters[state.filtered[data].id] = state.filtered[data].value;
    }

    const then = data => {
      this.setState(
        update(this.state, {
          pricingTableModal: { pages: { $set: data.last_page } }
        })
      );
    };

    this.props.pricingTableSearch(filters, state.page, state.sorted, then);
  };

  /**
   * @author Victor Heringer
   *
   * Filter exam place
   *
   * @param {Object} state
   * @param {Object} instance
   */
  examPlaceModalFilters = (state, instance) => {
    const filters = { id: "", name: "" };

    for (let data in state.filtered) {
      filters[state.filtered[data].id] = state.filtered[data].value;
    }

    const then = data => {
      this.setState(
        update(this.state, {
          examPlaceModal: { pages: { $set: data.last_page } }
        })
      );
    };

    this.props.examPlaceSearch(filters, state.page, state.sorted, then);
  };

  /**
   * @author Victor Heringer
   *
   * Changes the given field state
   *
   * @param {String} field The name of the state that represents the field
   * @param {Mixe} value The new value
   *
   * @return {Void}
   */
  fieldChange = (field, value) => {
    this.setState(state =>
      update(state, {
        fields: { [field]: { $set: value } }
      })
    );
  };

  /**
   * @author Victor Heringer
   *
   * Changes the selected pricing table field
   *
   * @param {String} field The name of the state that represents the field
   * @param {Mixe} value The new value
   *
   * @return {Void}
   */
  fieldChangePricingTable = (field, value) => {
    this.setState(state =>
      update(state, {
        fields: { [field]: { $set: value } }
      })
    );

    const then = data => this.fillFields(data);
    this.props.pricingTableWithId(value, then);
  };

  /**
   * @author Victor Heringer
   *
   * Handle the field change for components like NumberInputMaterial
   *
   * @param {String} field The name of the state that represents the field
   * @param {Mixed} value The new value
   *
   * @return {Void}
   */
  fieldChangeTable = (field, value) => {
    const fields = field.split(".");
    this.setState(state =>
      update(state, {
        fields: {
          examValues: {
            [fields[0]]: { [fields[1]]: { $set: value } }
          }
        }
      })
    );
  };

  isCLinicUser = () => {
    const allowed = [
      ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
      ACCESS_PROFILES.CLINIC_USER
    ];
    return allowed.includes(this.props.userProfile);
  };

  /**
   * @author Victor Heringer
   *
   * Checks if should disable forms based on user access profile
   *
   * @return {Boolean}
   */
  shouldDisableTableForm = () => (this.isCLinicUser());

  

  /**
   * @author Victor Heringer
   *
   * Handle the submit to relate pricing table with exams
   *
   * @param {String} field The name of the state that represents the field
   * @param {Mixed} value The new value
   *
   * @return {Void}
   */
  handleSubmit = values => {
    const msg = NOTIFICATIONS.DEFAULT_SAVE_MESSAGE;
    const then = () => this.props.notificationSystem.add(msg);
    this.props.pricingTableExams(values, then);
  };

  /**
   * @author Victor Heringer
   *
   * Checks if should disable select table input
   *
   * @return {Boolean}
   */
  shouldDisableTableSelect = () => {
    return this.props.customerId > 0;
  };

  filterActiveExams = (pricingTablePromisse, isCLinicUser ) => {
    
    if(!(pricingTablePromisse && pricingTablePromisse.value))
      return pricingTablePromisse;

      pricingTablePromisse.value.exams = !isCLinicUser ? pricingTablePromisse.value.exams :
        pricingTablePromisse.value.exams.filter(exam => {return exam.pivot.is_active === 1});
        return pricingTablePromisse;
        
  }

  tableFilter = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
            true
    );
}

  render() {
    const data = {
      pricingTableModalShow: this.state.pricingTableModalShow,
      fields: this.state.fields,
      request: this.props.sampleResponse,
      pricingTableModal: this.state.pricingTableModal,
      pricingTables: this.props.pricingTables,
      submitData: this.submitData(),
      allPricingTables: this.props.allPricingTables,
      selectedPricingTable: this.state.selectedPricingTable,
      examPlaceModalShow: this.state.examPlaceModalShow,
      examPlaces: this.props.examPlaces,
      allExamPlaces: this.props.allExamPlaces,
      pricingTableResponseWithId: this.filterActiveExams(this.props.pricingTableResponseWithId, this.isCLinicUser()),
      examValues: this.state.fields.examValues,
      shouldDisableTableSelect: this.shouldDisableTableSelect(),
      shouldDisableTableForm: this.shouldDisableTableForm()
    };

    const methods = {
      handleClosePricingTableModal: this.handleClosePricingTableModal,
      handleShowPricingTableModal: this.handleShowPricingTableModal,
      fieldChange: this.fieldChange,
      handleSubmitPricingTable: this.handleSubmitPricingTable,
      pricingTableModalFilters: this.pricingTableModalFilters,
      submitPricingTableModalData: this.submitPricingTableModalData,
      handleSubmit: this.handleSubmit,
      fieldChangePricingTable: this.fieldChangePricingTable,
      handleCloseExamPlaceModal: this.handleCloseExamPlaceModal,
      handleShowExamPlaceModal: this.handleShowExamPlaceModal,
      handleSubmitExamPlace: this.handleSubmitExamPlace,
      examPlaceModalFilters: this.examPlaceModalFilters,
      fieldChangeTable: this.fieldChangeTable,
      submitExamPlaceModalData: this.submitExamPlaceModalData,
      tableFilter : this.tableFilter
    };

    return (
      <React.Fragment>
        <ExamPlaceModal data={data} methods={methods} />
        <PricingTableModal data={data} methods={methods} />
        <PricingTableListView data={data} methods={methods} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    userLabId: state.userReducer.lab_id,
    userPersonCustomerId: state.userReducer.user.customer_person_id,
    customerId: state.userReducer.user.customer_id,
    userProfile: state.userReducer.access_profile
  };
};

export default FunctionUtil.compose(
  connect(mapStateToProps),
  connectWithEndpoint
)(PricingTable);
