import React, { Component } from "react";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import StatusCircle from "./../StatusCircle";
import LVTable from "./../../old_components/LVTable";

/** External */
import { Link } from "react-router-dom";
import ReactTable from "react-table-v6";

class OutsourcedExamsListView extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      pages: 1
    };
  }

  /**
   * @author Victor Heringer
   *
   * Render a circle to display boolean status
   *
   * @param {Bool} status
   *
   * @return {Object}
   */
  renderStatusCircle(status) {
    return <StatusCircle status={status} />;
  }

  render() {
    const { objects, filter, shoudDisable, history } = this.props;
    
    let config = {
      defaultPageSize: 5,
      defaultSorted: [{ id: "id", desc: true }],
      pageSize: 15,
      resizable: false,
      showPagination: true,
      noDataText: "Nenhum registro".toUpperCase(),
      loadingText: "Carregando",
      className: "-striped -highlight",
      previousText: "Anterior",
      nextText: "Próximo",
      pageText: "Página",
      ofText: "de",
      rowsText: "linhas",
      columns: [],
      showPageSizeOptions: false,
      pageSizeOptions: [5, 10, 15, 20, 25, 50, 100]
    };

    config.data =
      objects && objects.value && objects.value.data ? objects.value.data : [];
    config.loading = objects && objects.pending;
    config.onDoubleClickRow = this.props.onTableDoubleClick;
    config.columns = [
      {
        id: "id",
        Header: "Código",
        sortable: true,
        accessor: "id",
        className: "centeredText",
        width: 80
      },
      {
        id: "name",
        Header: "Nome",
        sortable: true,
        accessor: "name",
        className: "centeredText"
      },
      {
        id: "examType",
        Header: "Tipo Exame",
        sortable: false,
        accessor: "exam_type_name",
        className: "centeredText"
      },
      {
        id: "status",
        Header: "Ativo",
        filterable: false,
        sortable: false,
        Cell: data => this.renderStatusCircle(data.original.active),
        className: "centeredText"
      }
    ];

    return (
      <React.Fragment>
        <PanelMaterial>
          <PanelMaterialTop title={"Exames Terceirizados"}>
              <ButtonSaveMaterial
                title="Novo Exame"
                type="highlight"
                position="top"
                right={true}
                onClick={()=> {history.push('/exame-terceirizado/nova')}}
                disabled={shoudDisable}
              />
          </PanelMaterialTop>
          <PanelMaterialBody>
            <LVTable
              filterable
              manual
              onFetchData={filter}
              pages={this.props.pages}
              data={config.data}
              ofText={config.ofText}
              columns={config.columns}
              loading={config.loading}
              pageSize={config.pageSize}
              rowsText={config.rowsText}
              pageText={config.pageText}
              sortable={config.sortable}
              nextText={config.nextText}
              resizable={config.resizable}
              className={config.className}
              noDataText={config.noDataText}
              loadingText={config.loadingText}
              previousText={config.previousText}
              defaultSorted={config.defaultSorted}
              showPagination={config.showPagination}
              defaultPageSize={config.defaultPageSize}
              showPageSizeOptions={config.showPageSizeOptions}
              onDoubleClickRow={config.onDoubleClickRow}
            />
          </PanelMaterialBody>
        </PanelMaterial>
      </React.Fragment>
    );
  }
}

export default OutsourcedExamsListView;
