import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';

/** External */
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import pencil from '@fortawesome/fontawesome-free-solid/faPencilAlt';

/**
 * @author Victor Heringer
 * 
 * @param {Function} onClick Handles the click at button
 * @param {Boolean} disabled Disables the button
 */
const TableButtonEditMaterial = ( { onClick, disabled, className } ) => {
  return (
    <button
      className={ styles.tableEditBtn + ' ' + className }
      onClick={ onClick }
      disabled={ disabled }
    >
      <FontAwesomeIcon icon={ pencil } />
    </button>
  );
}

TableButtonEditMaterial.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default TableButtonEditMaterial;