import React from "react";
import styled from "styled-components";

const Badge = styled.span`
  width: auto;
  height: 20px;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  background-color: ${props => getBackgroundColor(props)};
  color: ${props => getTextColor(props)};
  padding: 3px 6px;
  margin: 1px;
  text-align: center;
  border-radius: 5px;
`;

const getBackgroundColor = ({ backgroundColor }) => {
  return backgroundColor ? backgroundColor : "#6c757d";
};

const getTextColor = ({ textColor }) => {
  return textColor ? textColor : "#FFFFFF";
};

export default props => {
  return <Badge {...props}>{props.text}</Badge>;
};
