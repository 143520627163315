import React from "react";

/**
 * External
 */
import PropTypes from "prop-types";
import { Row, Col } from "./../../components/grid";

/**
 * Internal
 */
import FlatPanelMaterial from "./../FlatPanelMaterial";
import NumberInputMaterial2 from "./../NumberInputMaterial2";

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const ReticulocyteCountPanel = ({
  panelTitle,
  reticulocyteByField,
  reticulocyteByFieldName,
  reticulocyteByFieldTitle,
  reticulocyteByFieldHasValidation,
  reticulocyteByFieldValidationReason,
  erythrocyteCount,
  erythrocyteCountName,
  erythrocyteCountTitle,
  erythrocyteCountHasValidation,
  erythrocyteCountValidationReason,
  corpuscularVolume,
  corpuscularVolumeName,
  corpuscularVolumeTitle,
  corpuscularVolumeHasValidation,
  corpuscularVolumeValidationReason,
  reticulocytePercent,
  reticulocytePercentName,
  reticulocytePercentTitle,
  absoluteErythrocyteCount,
  absoluteErythrocyteCountName,
  absoluteErythrocyteCountTitle,
  totalReticulocyte,
  totalReticulocyteName,
  totalReticulocyteTitle,
  reticulocyteCorrection,
  reticulocyteCorrectionName,
  reticulocyteCorrectionTitle,
  shouldDisable,
  handleChange,
  handleBlur,
}) => (
  <FlatPanelMaterial title={panelTitle}>
    <Row>
      <Col md={4}>
        <NumberInputMaterial2
          name={reticulocyteByFieldName}
          title={reticulocyteByFieldTitle}
          decimalScale={1}
          allowNegative={false}
          value={reticulocyteByField}
          disabled={shouldDisable}
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasValidationRecoil={true}
          hasValidation={reticulocyteByFieldHasValidation}
          validationReason={reticulocyteByFieldValidationReason}
        />
      </Col>
      <Col md={4}>
        <NumberInputMaterial2
          name={erythrocyteCountName}
          title={erythrocyteCountTitle}
          decimalScale={2}
          allowNegative={false}
          value={erythrocyteCount}
          disabled={shouldDisable}
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasValidationRecoil={true}
          hasValidation={erythrocyteCountHasValidation}
          validationReason={erythrocyteCountValidationReason}
        />
      </Col>
      <Col md={4}>
        <NumberInputMaterial2
          name={corpuscularVolumeName}
          title={corpuscularVolumeTitle}
          decimalScale={2}
          allowNegative={false}
          value={corpuscularVolume}
          disabled={shouldDisable}
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasValidationRecoil={true}
          hasValidation={corpuscularVolumeHasValidation}
          validationReason={corpuscularVolumeValidationReason}
        />
      </Col>
    </Row>
    <Row>
      <Col md={3}>
        <NumberInputMaterial2
          title={reticulocytePercentTitle}
          name={reticulocytePercentName}
          decimalScale={1}
          allowNegative={false}
          value={reticulocytePercent}
          disabled={true}
        />
      </Col>
      <Col md={3}>
        <NumberInputMaterial2
          title={absoluteErythrocyteCountTitle}
          name={absoluteErythrocyteCountName}
          decimalScale={1}
          allowNegative={false}
          value={absoluteErythrocyteCount}
          disabled={true}
        />
      </Col>
      <Col md={3}>
        <NumberInputMaterial2
          title={totalReticulocyteTitle}
          name={totalReticulocyteName}
          decimalScale={1}
          allowNegative={false}
          value={totalReticulocyte}
          disabled={true}
        />
      </Col>
      <Col md={3}>
        <NumberInputMaterial2
          title={reticulocyteCorrectionTitle}
          name={reticulocyteCorrectionName}
          decimalScale={1}
          allowNegative={false}
          value={reticulocyteCorrection}
          disabled={true}
        />
      </Col>
    </Row>
  </FlatPanelMaterial>
);

ReticulocyteCountPanel.propTypes = propTypes;

export default ReticulocyteCountPanel;
