import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import { ZERO } from '../../assets/js/Consts.js';
import {
  required,
  greaterThan
} from '../../assets/js/validationFunctions';

import {
  commonReportRules
  } from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    fecesColorOptionId,
    fecesSmellOptionId,
    fecesAspectOptionId,
    fecesConsistencyOptionId,
    macroscopicParasites,
    eggsQuantity,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const {completeValidation} = props.data;
  const result = props.data.result;

  let fields = [
    'fecesColorOptionId',
    'fecesSmellOptionId',
    'fecesAspectOptionId',
    'fecesConsistencyOptionId',
    'macroscopicParasites',
    'result',
    'eggsQuantity',
    'vetSignerId',
    'requestExamState'
  ];

  const commonValidation = commonReportRules(
    vetSignerId, requestExamState );

  const validations = {
    ...commonValidation,
    fecesColorOptionId: {
      rules: completeValidation? [
        [ greaterThan, fecesColorOptionId, ZERO, 'Obrigatório' ]
      ] : []
    },
    fecesSmellOptionId: {
      rules: completeValidation? [
        [ greaterThan, fecesSmellOptionId, ZERO, 'Obrigatório' ]
      ] : []
    },
    fecesAspectOptionId: {
      rules: completeValidation? [
        [ greaterThan, fecesAspectOptionId, ZERO, 'Obrigatório' ]
      ] : []
    },
    fecesConsistencyOptionId: {
      rules: completeValidation? [
        [ greaterThan, fecesConsistencyOptionId, ZERO, 'Obrigatório' ]
      ] : []
    },
    macroscopicParasites: {
      rules: completeValidation? [
        [ required, macroscopicParasites, 'Obrigatório' ]
      ] : []
    },
    result: {
      rules: completeValidation? [
        [ required, result, 'Obrigatório' ]
      ] : []
    },
    eggsQuantity: {
      rules: []
    }
  };

  return { fields, validations };
} );

export default validation;