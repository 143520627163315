import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import HemogramPanel from "./../HemogramPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import DefaultMolecularBiologyPanel from "./../DefaultMolecularBiologyPanel";
import HeartwormDiseaseContent from "./../HeartwormDiseaseContent";
import FlatPanelMaterial from "./../FlatPanelMaterial";
import ProfileBiochemistryTable from "./../../old_components/ProfileBiochemistryTable";

/**
 * External
 */
import { Col, Row } from "./../../components/grid";

const ProfileHemoparasitesBronzeReportFormView = ({
  title,
  data: {
    refs,
    total,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    materialsSuggestions,
    requestExamStates,
    biochemistryTableData,
    reportData,
  },
  methods: {
    $field,
    fieldChange,
    onSubmit,
    calcMCHC,
    calcMCV,
    erythrogramDiagnosis,
    plateletsDiagnosis,
    calcAbsolute,
    resetLeukogram,
    onClickCounter,
    leukogramDiagnosis,
    toggleIntenseLeucopeny,
    finalGlobalLeukogram,
    handleKeyPress,
    handleFinishedPlaying,
    calcMolecularBiologyResult,
    closeCounter,
    handleChange,
    handleBlur,
    suggestionsFilter,
    toggleIonicCalciumEditable,
    cancelReport,
    stopReport,
    handleSendReportEmail,
    handleFocus,
    setRef,
  },
}) => (
  <ReportPanelMaterial
    title={title}
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    cancelReport={cancelReport}
    stopReport={stopReport}
    handleSendReportEmail={handleSendReportEmail}
    handleFocus={handleFocus}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <HemogramPanel
          fields={fields}
          refs={refs}
          disableAll={disableAll}
          $validation={$validation}
          total={total}
          $field={$field}
          fieldChange={fieldChange}
          calcMCV={calcMCV}
          calcMCHC={calcMCHC}
          calcAbsolute={calcAbsolute}
          resetLeukogram={resetLeukogram}
          onClickCounter={onClickCounter}
          toggleIntenseLeucopeny={toggleIntenseLeucopeny}
          finalGlobalLeukogram={finalGlobalLeukogram}
          erythrogramDiagnosis={erythrogramDiagnosis}
          leukogramDiagnosis={leukogramDiagnosis}
          plateletsDiagnosis={plateletsDiagnosis}
          handleKeyPress={handleKeyPress}
          handleFinishedPlaying={handleFinishedPlaying}
          closeCounter={closeCounter}
          setRef={setRef}
          reportData={reportData}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <DefaultMolecularBiologyPanel
          title={"PCR Ehrlichia"}
          materialsFieldName={"ehrlichiaMolecularBiologyMaterials"}
          materials={fields.ehrlichiaMolecularBiologyMaterials}
          materialsSuggestions={materialsSuggestions}
          materialsSuggestionsFilter={suggestionsFilter}
          materialsHasValidation={
            $validation.ehrlichiaMolecularBiologyMaterials.show
          }
          materialsValidationReason={
            $validation.ehrlichiaMolecularBiologyMaterials.error.reason
          }
          techniqueFieldName="ehrlichiaTechnique"
          technique={fields.ehrlichiaTechnique}
          techniqueHasValidation={$validation.ehrlichiaTechnique.show}
          techniqueValidationReason={
            $validation.ehrlichiaTechnique.error.reason
          }
          qualitativeResultFieldName="ehrlichiaQualitativeResult"
          qualitativeResult={fields.ehrlichiaQualitativeResult}
          qualitativeResultHasValidation={
            $validation.ehrlichiaQualitativeResult.show
          }
          qualitativeResultValidationReason={
            $validation.ehrlichiaQualitativeResult.error.reason
          }
          copyValuesFieldName="ehrlichiaCopyValues"
          copyValues={fields.ehrlichiaCopyValues}
          copyValuesHasValidation={$validation.ehrlichiaCopyValues.show}
          copyValuesValidationReason={
            $validation.ehrlichiaCopyValues.error.reason
          }
          precisionFieldName="ehrlichiaPrecision"
          precision={fields.ehrlichiaPrecision}
          precisionHasValidation={$validation.ehrlichiaPrecision.show}
          precisionValidationReason={
            $validation.ehrlichiaPrecision.error.reason
          }
          resultFieldName="result"
          result={calcMolecularBiologyResult(
            fields.ehrlichiaCopyValues,
            fields.ehrlichiaPrecision,
            fields.ehrlichiaTechnique
          )}
          obsFieldName="ehrlichiaObs"
          obs={fields.ehrlichiaObs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <DefaultMolecularBiologyPanel
          title={"PCR Babesia"}
          materialsFieldName={"babesiaMolecularBiologyMaterials"}
          materials={fields.babesiaMolecularBiologyMaterials}
          materialsSuggestions={materialsSuggestions}
          materialsSuggestionsFilter={suggestionsFilter}
          materialsHasValidation={
            $validation.babesiaMolecularBiologyMaterials.show
          }
          materialsValidationReason={
            $validation.babesiaMolecularBiologyMaterials.error.reason
          }
          techniqueFieldName="babesiaTechnique"
          technique={fields.babesiaTechnique}
          techniqueHasValidation={$validation.babesiaTechnique.show}
          techniqueValidationReason={$validation.babesiaTechnique.error.reason}
          qualitativeResultFieldName="babesiaQualitativeResult"
          qualitativeResult={fields.babesiaQualitativeResult}
          qualitativeResultHasValidation={
            $validation.babesiaQualitativeResult.show
          }
          qualitativeResultValidationReason={
            $validation.babesiaQualitativeResult.error.reason
          }
          copyValuesFieldName="babesiaCopyValues"
          copyValues={fields.babesiaCopyValues}
          copyValuesHasValidation={$validation.babesiaCopyValues.show}
          copyValuesValidationReason={
            $validation.babesiaCopyValues.error.reason
          }
          precisionFieldName="babesiaPrecision"
          precision={fields.babesiaPrecision}
          precisionHasValidation={$validation.babesiaPrecision.show}
          precisionValidationReason={$validation.babesiaPrecision.error.reason}
          resultFieldName="result"
          result={calcMolecularBiologyResult(
            fields.babesiaCopyValues,
            fields.babesiaPrecision,
            fields.babesiaTechnique
          )}
          obsFieldName="babesiaObs"
          obs={fields.babesiaObs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <HeartwormDiseaseContent
          title="Resultado"
          materialId={fields.heartwormDiseaseMaterialId}
          isPositive={fields.heartwormDiseaseIsPositive}
          materialIdFieldName="heartwormDiseaseMaterialId"
          isPositiveFieldName="heartwormDiseaseIsPositive"
          materialIdHasValidation={$validation.heartwormDiseaseMaterialId.show}
          isPositiveHasValidation={$validation.heartwormDiseaseIsPositive.show}
          materialIdValidationReason={
            $validation.heartwormDiseaseMaterialId.error.reason
          }
          isPositiveValidationReason={
            $validation.heartwormDiseaseIsPositive.error.reason
          }
          obsFieldName="heartwormDiseaseObs"
          obs={fields.heartwormDiseaseObs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FlatPanelMaterial title="Bioquímica">
          <ProfileBiochemistryTable
            data={biochemistryTableData}
            isUpdate={isUpdate}
            handleChange={handleChange}
            handleBlur={handleBlur}
            toggleIonicCalciumEditable={toggleIonicCalciumEditable}
            shouldDisable={disableAll}
            requestExamStates={requestExamStates}
            biochemObs={fields.biochemObs}
          />
        </FlatPanelMaterial>
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default ProfileHemoparasitesBronzeReportFormView;
