import React from "react";

/** Internal */
import FlatPanelMaterial from "../FlatPanelMaterial";
import ReportHeader from "./../ReportHeader";
import InputMaterial from "./../InputMaterial";
import TextAreaMaterial from "./../TextAreaMaterial";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";
import TextAreaMaterial2 from "../TextAreaMaterial2";

const CytologyReportFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Citologia"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData && data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <FlatPanelMaterial title="Resultado">
      <Row>
        <Col md={12} className="mt-10">
          <TextAreaMaterial2
            id="sample"
            title="Amostra"
            name="sample"
            disabled={data.disableAll}
            value={data.fields.sample}            
            handleChange={methods.fieldChange}
            hasValidation={
              data.$validation &&
              data.$validation.sample.show &&
              !data.$validation.sample.isValid
            }
            validationReason={
              data.$validation && data.$validation.sample.error.reason
            }
            isValid={data.$validation && data.$validation.sample.isValid}
          />
        </Col>
        <Col md={12} className="mt-10">
          <TextAreaMaterial2
            id="microscopy"
            title="Microscopia"
            name="microscopy"
            disabled={data.disableAll}
            value={data.fields.microscopy}
            handleChange={methods.fieldChange}
            hasValidation={
              data.$validation &&
              data.$validation.microscopy.show &&
              !data.$validation.microscopy.isValid
            }
            validationReason={
              data.$validation && data.$validation.microscopy.error.reason
            }
            isValid={data.$validation && data.$validation.microscopy.isValid}
          />
        </Col>
        <Col md={12} className="mt-10">
          <TextAreaMaterial2
            id="diagnosis"
            title="Diagnóstico"
            name="diagnosis"
            value={data.fields.diagnosis}
            handleChange={methods.fieldChange}
            disabled={data.disableAll}
            hasValidation={
              data.$validation &&
              data.$validation.diagnosis.show &&
              !data.$validation.diagnosis.isValid
            }
            validationReason={
              data.$validation && data.$validation.diagnosis.error.reason
            }
            isValid={data.$validation && data.$validation.diagnosis.isValid}
          />
        </Col>
        <Col md={12} className="mt-10">
          <TextAreaMaterial2
            id="note"
            title="Nota do patologista"
            name="note"
            value={data.fields.note}
            disabled={data.disableAll}
            handleChange={methods.fieldChange}
            hasValidation={
              data.$validation &&
              data.$validation.note.show &&
              !data.$validation.note.isValid
            }
            validationReason={
              data.$validation && data.$validation.note.error.reason
            }
            isValid={data.$validation && data.$validation.note.isValid}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
    <FlatPanelMaterial title="Diagnóstico">
      <Row>
        <Col xs={1} className="text-right">
          <p className="mr-10 cleanItem">OBS</p>
        </Col>
        <Col xs={11}>
          <InputMaterial
            name="obs"
            md={12}
            value={data.fields.obs}
            fit={true}
            disabled={data.disableAll}
            onChange={event => methods.fieldChange("obs", event.target.value)}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
    <FlatPanelMaterial title="Assinatura do patologista">
      <Row>
        <Col xs={11}>
          <InputMaterial
            id="signature"
            name="signature"
            value={data.fields.signature}
            disabled={data.disableAll}
            {...methods.$field("signature", event => {
              methods.fieldChange("signature", event.target.value);
            })}
            hasValidation={
              data.$validation &&
              data.$validation.signature.show &&
              !data.$validation.signature.isValid
            }
            validationReason={
              data.$validation && data.$validation.signature.error.reason
            }
            isValid={data.$validation && data.$validation.signature.isValid}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
  </ReportPanelMaterial>
);

export default CytologyReportFormView;
