import React from "react";
import LVTable from "./../../../old_components/LVTable";
import * as LifeVet from "../../../components";

const PotentialInvoicesTable = ({
  potentialInvoices,
  selectedCustomersIds,
  setSelectedCustomersIds,
  onTableDoubleClick,
  toggleCustomerSelection,
  isCustomerSelected
}) => {
  const renderCheckbox = (
    customerId,
    selectedCustomersIds,
    setSelectedCustomersIds,
    isCustomerSelected
  ) => {
    return (
      <LifeVet.Checkbox
        checked={isCustomerSelected(customerId, selectedCustomersIds)}
        onChange={() =>
          toggleCustomerSelection(
            customerId,
            selectedCustomersIds,
            setSelectedCustomersIds,
            isCustomerSelected
          )
        }
      />
    );
  };

  const loading = false;
  const pageSize = 15;
  const columns = [
    {
      id: "code",
      accessor: "code",
      Header: "Código",
      sortable: true,
      minWidth: 50,
      Cell: data => (
        <span style={{ lineHeight: "20px" }}>{data.original.code}</span>
      )
    },
    {
      id: "name",
      accessor: "name",
      Header: "Cliente",
      sortable: true,
      minWidth: 75,
      Cell: data => (
        <span style={{ lineHeight: "20px" }}>{data.original.name}</span>
      )
    },
    {
      id: "generate",
      Header: "Faturar?",
      sortable: false,
      minWidth: 50,
      Cell: data =>
        renderCheckbox(
          data.original.customer_id,
          selectedCustomersIds,
          setSelectedCustomersIds,
          isCustomerSelected
        )
    }
  ];

  return (
    <LVTable
      data={potentialInvoices}
      loading={loading}
      columns={columns}
      sortable={true}
      pageSize={pageSize}
      onDoubleClickRow={onTableDoubleClick}
    />
  );
};

export default PotentialInvoicesTable;
