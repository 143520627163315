import React from 'react'
import styles from './container.module.css'
import newLogo from '../../../../assets/img/logo-horizontal.png'
import * as LifeVet from '../../../../components'
import {useHistory} from 'react-router'
export function HomeContainer({}) {
  const history = useHistory()

  function handleAccessOldPage() {
    history.push('/solicitacoes-listagem')
  }
   return (
          <div className={styles.homeDiv}>
            <img src={newLogo} alt='' />
            <h1>Bem vindo a Life.Vet</h1>
            <h3>Buscando melhorar a sua experiência com o nosso sistema. <br/> Estamos passando por algumas atualizações. </h3>

            <span>clique no botão abaixo para visualizar a listagem de solicitações.</span>

            <LifeVet.Button onClick={handleAccessOldPage} depth="2" variant="primary">
              ACESSAR PÁGINA ANTIGA
            </LifeVet.Button>

            <span className={styles.slogan}>"Diagnosticar corretamente é o primeiro passo no sucesso do tratamento."</span>
          </div>
   );
}