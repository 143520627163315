import React from "react";
import * as LifeVet from "../../../../../components/index";
import { REQUEST_EXAM_STATES } from "../../../../../consts/index";
import NumberInputMaterial from "../../../../NumberInputMaterial";
import { values as PaymentMethods } from "./../../../../../consts/payment-methods";
import { RequestExam } from "../../../../../types/index";

export const renderPrintButton = (
  exam: RequestExam,
  printExam: any,
  loggedUserIsFromClinic: boolean
) => {
  const attendedStatus = [
    REQUEST_EXAM_STATES.ATTENDED_AND_RELEASED,
    REQUEST_EXAM_STATES.BILLED,
  ];
  const examIsAttended = attendedStatus.includes(exam.status);

  const mustBeDisabled =
    (loggedUserIsFromClinic && !examIsAttended) ||
    exam.status < REQUEST_EXAM_STATES.IN_PROGRESS;

  const isNotSaved = exam.report === null;

  return (
    <button
      disabled={isNotSaved || mustBeDisabled}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        printExam(exam);
        e.stopPropagation();
      }}
    >
      <i className="fa fa-print" />
    </button>
  );
};

export const renderDeleteButton = (
  exam: RequestExam,
  cancelExam: any,
  removeExam: any
) => {
  const isSaved = exam.createdAt !== "";
  if (isSaved) {
    const cancelled = exam.status === REQUEST_EXAM_STATES.CANCELLED;
    return (
      <button
        className={"btn-table-action btn-ex-selecionados"}
        disabled={cancelled}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          cancelExam(exam);
          e.stopPropagation();
        }}
      >
        <i
          className="fa fa-times-circle"
          style={{ color: cancelled ? "grey" : "red" }}
        />
      </button>
    );
  } else {
    return (
      <button
        className={"btn-table-action btn-ex-selecionados"}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          removeExam(exam);
          e.stopPropagation();
        }}
      >
        <i className="fa fa-trash" style={{ color: "red" }} />
      </button>
    );
  }
};

export const renderCheckbox = (data: any, update: any) => {
  return (
    <LifeVet.Checkbox
      checked={data.exam.isPaid}
      disabled={data.loggedUserIsFromClinic || data.disable}
      name="is_paid"
      onChange={(e: any) => {
        data.exam.isPaid = !data.exam.isPaid;
        update(data.exam);
      }}
      onClick={(e: React.FocusEvent<HTMLInputElement>) => e.stopPropagation()}
    />
  );
};

export const renderStatus = (exam: RequestExam) => {
  return (
    <span>{REQUEST_EXAM_STATES.getRequestExamStateNameById(exam.status)}</span>
  );
};

export const renderDeadLine = (
  data: {
    exam: RequestExam;
    loggedUserIsFromClinic: boolean;
    disable: boolean;
  },
  update: any
) => {
  return (
    <NumberInputMaterial
      key="deadlineInput"
      title={null}
      name={""}
      md={12}
      allowNegative={false}
      isNumericString={true}
      block={true}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={2}
      fieldChange={(name: string, value: any) => {
        const newExam = {
          ...data.exam,
          deadlineInDays: value,
        };
        update(newExam);
      }}
      value={data.exam.deadlineInDays}
      disabled={data.loggedUserIsFromClinic || data.disable}
      className="text-center"
      onClick={(e: React.FocusEvent<HTMLInputElement>) => e.stopPropagation()}
    />
  );
};

export const renderPrice = (
  data: {
    exam: RequestExam;
    loggedUserIsFromClinic: boolean;
    disable: boolean;
  },
  update: any
) => {
  return (
    <NumberInputMaterial
      title={null}
      name={""}
      md={12}
      allowNegative={false}
      isNumericString={true}
      block={true}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={2}
      fieldChange={(name: string, value: any) => {
        data.exam.price = value;
        update(data.exam);
      }}
      onClick={(e: React.FocusEvent<HTMLInputElement>) => e.stopPropagation()}
      value={data.exam.price}
      disabled={data.loggedUserIsFromClinic || data.disable}
      className="text-center"
    />
  );
};

export const renderObservation = (
  data: {
    exam: RequestExam;
    loggedUserIsFromClinic: boolean;
    disable: boolean;
  },
  update: any
) => {
  return (
    <LifeVet.Input
      value={data.exam.obs}
      onClick={(e: React.FocusEvent<HTMLInputElement>) => e.stopPropagation()}
      onChange={(e: any) => {
        data.exam.obs = e.target.value;
        update(data.exam);
      }}
      onBlur={() => {}}
      innerRef={null}
      disabled={data.loggedUserIsFromClinic || data.disable}
    />
  );
};

export const renderPaymentMethod = (
  data: {
    exam: RequestExam;
    loggedUserIsFromClinic: boolean;
    disable: boolean;
  },
  update: any
) => {
  return (
    <div
      onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
    >
      <LifeVet.Select
        name="paymentMethod"
        options={PaymentMethods}
        value={data.exam.paymentMethod}
        disabled={data.loggedUserIsFromClinic || data.disable}
        onChange={(option: any) => {
          data.exam.paymentMethod = option;
          update(data.exam);
        }}
        onKeyDown={() => {}}
        placeholder="Selecione..."
      ></LifeVet.Select>
    </div>
  );
};
