import React from "react";

/** Internal */
import SelectMaterial from "./../SelectMaterial";
import LVFileInputMaterial from "./../../old_components/LVFileInputMaterial";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";
import { CNPJ_MASK, CEP_MASK, CPF_MASK, STATUS } from "../../assets/js/Consts";
import { PERSON_TYPES } from "./../../assets/js/Consts";

/** External */
import { Row, Col } from "./../../components/grid";

const CustomerFields = ({ fields, methods, data, $field, $validation }) => {
  const logoFileInput = (
    <Col xs={12}>
      <LVFileInputMaterial
        id={"signaturesUpload"}
        name={"signaturesUpload"}
        allowedExtensions={[".png", ".jpg", ".jpeg"]}
        setContainerFiles={methods.handleLogoUpload}
        asBase64={true}
        inline={true}
        maxFileSize={500000}
        allowMultiples={false}
        hasImgPreview={true}
        clearBtnTitle={"Remover Logo"}
        title={"Selecionar Logo"}
        hasFilesNames={true}
        description={
          "Tamanho máximo para o arquivo: 200kb - Utilize a resolução 200x85 px"
        }
        placeholders={fields.logo}
        disabled={data.shouldDisableAll}
      />
    </Col>
  );

  const nameInput = (
    <LVInputTextUpper
      id="name"
      autofocus={true}
      name="name"
      title="Nome"
      required
      md={4}
      value={fields.name || ""}
      disabled={data.allDisabled || data.shouldDisableAll}
      {...$field("name", (event) => {
        methods.fieldChange("name", event.target.value);
      })}
      hasValidation={
        $validation && $validation.name.show && !$validation.name.isValid
      }
      validationReason={$validation && $validation.name.error.reason}
    />
  );

  const personTypeSelect = (
    <SelectMaterial
      title="Tipo"
      md={4}
      name="personType"
      options={PERSON_TYPES}
      value={fields.personType}
      searchable={true}
      disabled={data.allDisabled || data.shouldDisableAll}
      {...$field("personType", (event) => {
        methods.fieldChange("personType", event);
      })}
    />
  );

  const cnpjInput = (
    <LVInputTextUpper
      id="cnpj"
      name="cnpj"
      title="CNPJ"
      required
      md={4}
      value={fields.cnpj}
      disabled={data.allDisabled || data.shouldDisableAll}
      mask
      maskValue={CNPJ_MASK}
      {...$field("cnpj", (event) => {
        methods.fieldChange("cnpj", event.target.value);
      })}
      hasValidation={
        $validation && $validation.cnpj.show && !$validation.cnpj.isValid
      }
      validationReason={$validation && $validation.cnpj.error.reason}
    />
  );

  const cpfInput = (
    <LVInputTextUpper
      id="cpf"
      name="cpf"
      title="CPF"
      required
      md={4}
      value={fields.cpf}
      disabled={data.allDisabled || data.shouldDisableAll}
      mask
      maskValue={CPF_MASK}
      {...$field("cpf", (event) => {
        methods.fieldChange("cpf", event.target.value);
      })}
    />
  );

  const statusSelect = (
    <SelectMaterial
      title="Status"
      md={4}
      name="status"
      options={STATUS}
      value={fields.status}
      searchable={true}
      disabled={data.allDisabled || data.shouldDisableAll}
      {...$field("status", (event) => {
        methods.fieldChange("status", event);
      })}
    />
  );

  const regionsSelect = (
    <SelectMaterial
      title="Regiões"
      md={4}
      name="regions"
      required
      hasValidationRecoil={true}
      options={data.regions.value ? data.regions.value : []}
      multi={true}
      value={fields.regionIds}
      searchable={true}
      disabled={data.allDisabled || data.shouldDisableAll}
      placeholder={data.regions.pending ? "Carregando.." : "Selecione"}
      {...$field("regionIds", (event) => {
        methods.fieldChange(
          "regionIds",
          event.split(",")[0] === "" ? [] : event.split(",")
        );
      })}
      hasValidation={
        $validation &&
        $validation.regionIds.show &&
        !$validation.regionIds.isValid
      }
      validationReason={$validation && $validation.regionIds.error.reason}
      isValid={$validation && $validation.regionIds.isValid}
    />
  );

  const pricingTablesSelect = (
    <SelectMaterial
      title="Tabela de Preços"
      required
      md={4}
      name="pricingTables"
      hasValidationRecoil={true}
      options={data.pricingTables.value ? data.pricingTables.value : []}
      value={fields.pricingTableId}
      searchable={true}
      disabled={data.allDisabled || data.shouldDisableAll}
      placeholder={data.pricingTables.pending ? "Carregando.." : "Selecione"}
      {...$field("pricingTableId", (event) => {
        methods.fieldChange("pricingTableId", event);
      })}
      hasValidation={
        $validation &&
        $validation.pricingTableId.show &&
        !$validation.pricingTableId.isValid
      }
      validationReason={$validation && $validation.pricingTableId.error.reason}
      isValid={$validation && $validation.pricingTableId.isValid}
    />
  );

  const stateSelect = (
    <SelectMaterial
      title="Estado"
      required
      md={4}
      name="state"
      hasValidationRecoil={true}
      options={data.states.value ? data.states.value : []}
      value={fields.stateId}
      searchable={true}
      disabled={data.allDisabled || data.shouldDisableAll}
      placeholder={data.states.pending ? "Carregando.." : "Selecione"}
      {...$field("stateId", (event) => {
        methods.loadCities(event);
        methods.fieldChange("stateId", event);
      })}
      hasValidation={
        $validation && $validation.stateId.show && !$validation.stateId.isValid
      }
      validationReason={$validation && $validation.stateId.error.reason}
      isValid={$validation && $validation.stateId.isValid}
    />
  );

  const citySelect = (
    <SelectMaterial
      title="Cidade"
      required
      md={4}
      name="city"
      hasValidationRecoil={true}
      options={data.cities && data.cities.value ? data.cities.value : []}
      value={fields.cityId}
      searchable={true}
      disabled={
        data.allDisabled || fields.stateId === 0 || data.shouldDisableAll
      }
      {...$field("cityId", (event) => {
        methods.fieldChange("cityId", event);
      })}
      hasValidation={
        $validation && $validation.cityId.show && !$validation.cityId.isValid
      }
      validationReason={$validation && $validation.cityId.error.reason}
      isValid={$validation && $validation.cityId.isValid}
    />
  );

  const neighborhoodInput = (
    <LVInputTextUpper
      id="neighborhood"
      title="Bairro"
      required
      name="neighborhood"
      md={4}
      value={fields.neighborhood}
      disabled={data.allDisabled || data.shouldDisableAll}
      {...$field("neighborhood", (event) => {
        methods.fieldChange("neighborhood", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.neighborhood.show &&
        !$validation.neighborhood.isValid
      }
      validationReason={$validation && $validation.neighborhood.error.reason}
    />
  );

  const cepInput = (
    <LVInputTextUpper
      id="cep"
      title="CEP"
      name="cep"
      required
      md={3}
      value={fields.cep}
      disabled={data.allDisabled || data.shouldDisableAll}
      mask
      maskValue={CEP_MASK}
      {...$field("cep", (event) => {
        methods.fieldChange("cep", event.target.value);
      })}
      hasValidation={
        $validation && $validation.cep.show && !$validation.cep.isValid
      }
      validationReason={$validation && $validation.cep.error.reason}
    />
  );

  const publicPlaceInput = (
    <LVInputTextUpper
      id="publicPlace"
      title="Logradouro"
      name="publicPlace"
      md={3}
      value={fields.publicPlace}
      disabled={data.allDisabled || data.shouldDisableAll}
      {...$field("publicPlace", (event) => {
        methods.fieldChange("publicPlace", event.target.value);
      })}
    />
  );

  const numberInput = (
    <LVInputTextUpper
      id="number"
      title="Número"
      required
      name="number"
      md={2}
      value={fields.number}
      disabled={data.allDisabled || data.shouldDisableAll}
      {...$field("number", (event) => {
        methods.fieldChange("number", event.target.value);
      })}
      hasValidation={
        $validation && $validation.number.show && !$validation.number.isValid
      }
      validationReason={$validation && $validation.number.error.reason}
    />
  );

  const complementInput = (
    <LVInputTextUpper
      id="complement"
      title="Complemento"
      name="complement"
      md={4}
      value={fields.complement}
      disabled={data.allDisabled || data.shouldDisableAll}
      {...$field("complement", (event) => {
        methods.fieldChange("complement", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.complement.show &&
        !$validation.complement.isValid
      }
      validationReason={$validation && $validation.complement.error.reason}
    />
  );

  const profile = (
    <React.Fragment>
      {nameInput}
      {personTypeSelect}
      {fields.personType == 0 && cnpjInput}
      {fields.personType == 1 && cpfInput}
      {statusSelect}
      {regionsSelect}
      {pricingTablesSelect}
      <Col xs={12}>{logoFileInput}</Col>
    </React.Fragment>
  );

  const address = (
    <React.Fragment>
      {stateSelect}
      {citySelect}
      {neighborhoodInput}
      {cepInput}
      {publicPlaceInput}
      {numberInput}
      {complementInput}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Row> {profile} </Row>
      <Row>
        {" "}
        <hr />{" "}
      </Row>
      <Row> {address} </Row>
    </React.Fragment>
  );
};

export default CustomerFields;
