import React from "react";
import LVTable from "../../../old_components/LVTable";
import * as DateUtil from "./../../../useful/date-util";
import {ArrayUtil} from "./../../../useful"

const InvoiceExamsTable = ({ data }) => {
  const columns = [
    {
      id: "id",
      accessor: "id",
      Header: "Id",
      sortable: true,
      minWidth: 50,
      Cell: data => data.original.id
    },
    {
      id: "created_at",
      accessor: "created_at",
      Header: "Data Solic.",
      sortable: true,
      minWidth: 50,
      Cell: data => DateUtil.formatDate(data.original.created_at)
    },
    {
      id: "id",
      accessor: "id",
      Header: "Código Solic.",
      sortable: true,
      minWidth: 50,
      Cell: data => data.original.exam_request.id
    },
    {
      id: "animal",
      accessor: "animal.name",
      Header: "Animal",
      sortable: true,
      minWidth: 50,
      Cell: data =>
        data.original.exam_request && data.original.exam_request.animal.name
    },
    {
      id: "exam",
      accessor: "exam.name",
      Header: "Exame",
      sortable: true,
      minWidth: 50,
      Cell: data => data.original.lab_exam && data.original.lab_exam.exam.name
    },
    {
      id: "finalized_at",
      accessor: "finalized_at",
      Header: "Data Finaliz. Exame",
      sortable: true,
      minWidth: 50,
      Cell: data =>
        DateUtil.formatDate(data.original.end_date_time.substr(0, 10))
    },
    {
      id: "animal",
      accessor: "animal.owner",
      Header: "Proprietário",
      sortable: true,
      minWidth: 50,
      Cell: data =>
        data.original.exam_request && data.original.exam_request.animal.owner
    },
    {
      id: "price",
      accessor: "final_price",
      Header: "Preço",
      sortable: true,
      minWidth: 50,
      Cell: data => `R$ ${ArrayUtil.numberFormat(data.original.final_price)}`
    }
  ];

  return (
    <LVTable
      data={data ? data : []}
      loading={false}
      columns={columns}
      sortable={true}
      pageSize={5}
    />
  );
};

export default InvoiceExamsTable;
