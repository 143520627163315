import React from "react";

const actualEventKeyEqualsExpectedKey = (eventKey, expectedKey) => {
  return eventKey === expectedKey;
};

const fireCallbackIfDefined = (callback) => {
  callback && callback();
};

export const KeyEventListener = (props) => {
  let onKeyDownCallback = props.onKeyDown;
  let onKeyUpCallback = props.onKeyUp;
  let onKeyPressCallback = props.onKeyPress;
  return (
    <span
      onKeyDown={(event) =>
        actualEventKeyEqualsExpectedKey(event.key, props.keyCode) &&
        fireCallbackIfDefined(onKeyDownCallback)
      }
      onKeyUp={(event) =>
        actualEventKeyEqualsExpectedKey(event.key, props.keyCode) &&
        fireCallbackIfDefined(onKeyUpCallback)
      }
      onKeyPress={(event) =>
        actualEventKeyEqualsExpectedKey(event.key, props.keyCode) &&
        fireCallbackIfDefined(onKeyPressCallback)
      }
    >
      {props.children}
    </span>
  );
};
