import React from "react";

import { Row, Col } from "./../../components/grid";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import ReportHeader from "./../ReportHeader";
import FibrinogenContent from "./../FibrinogenContent";

const FibrinogenReportFormView = ({
  data: {
    refs,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    shouldSendEmail
  },
  methods: {
    $field,
    fieldChange,
    handleChange,
    handleBlur,
    onSubmit,
    cancelReport,
    stopReport,
    handleSendReportEmail
  }
}) => (
  <ReportPanelMaterial
    title="Fibrinogênio"
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    handleSubmit={onSubmit}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FibrinogenContent
          resultFieldName="result"
          result={fields.result}
          obsFieldName="obs"
          obs={fields.obs}
          resultHasValidation={$validation.result.show}
          resultValidationReason={$validation.result.error.reason}
          obsHasValidation={$validation.obs.show}
          obsValidationReason={$validation.obs.error.reason}
          refMin={refs.fibrinogenMin}
          refMax={refs.fibrinogenMax}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default FibrinogenReportFormView;
