import React, { Component, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import useHormone from "../../hooks/hormone";
import HormoneView from "./view";
import HormoneView2 from "./view2";


const HormoneContent = ({exams, handleChange, tableData,data,  methods,errors}) => {

    
    return (        
        <HormoneView2
        exams={exams}
        handleChange={handleChange}
        tableData={tableData}
        data={data}
        methods={methods}
        errors={errors}
        />
    );
}


export default React.memo(HormoneContent);