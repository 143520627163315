import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

/**
 * External
 */

const propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

/**
 * @author Anderson Souza
 *
 */
const Checkbox = ({
  name = "",
  checked,
  disabled = false,
  onChange = React.ChangeEvent,
  className = "",
  onClick = (e) => {},
}) => {
  return (
    <input
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={`${styles.checkbox} ${className}`}
      onClick={onClick}
    />
  );
};

Checkbox.propTypes = propTypes;

export default Checkbox;
