import React, { useReducer } from "react";
import { Phone } from "./../../types/index";
import { reducer, ACTIONS } from "./reducer";

const defaultPhones: Array<Phone> = [];

export const usePhones = () => {
  const [phones, dispatch] = useReducer(reducer, defaultPhones);
  const data = phones;
  const methods = {
    add: (phones: Array<Phone>) =>
      dispatch({ type: ACTIONS.ADD, payload: phones }),
    update: (phone: Phone) =>
      dispatch({ type: ACTIONS.UPDATE, payload: phone }),
    remove: (id: number) => dispatch({ type: ACTIONS.REMOVE, payload: id }),
    get: (id: number) => phones.filter((curr) => curr.id === id).pop(),
    reset: () => dispatch({ type: ACTIONS.RESET }),
  };
  return {
    data,
    methods,
  };
};
