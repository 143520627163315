import React from "react";
import ButtonDefaultMaterial from "./../../containers/ButtonDefaultMaterial";

/**
 * @author Victor Heringer
 *
 * Creates a button to clear all images or files
 *
 * @prop {String} title
 * @prop {Function} onClick
 * @prop {Boolean} display
 */
const ClearButton = ({ title, onClick, display, style, disabled }) => {
  const btn = (
    <ButtonDefaultMaterial
      type="danger"
      title={title}
      className={style.clearBtn}
      onClick={onClick}
      disabled={disabled}
    />
  );

  return display ? btn : null;
};

export default ClearButton;
