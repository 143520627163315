import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal
 */
import DatePickerMaterial from './../DatePickerMaterial';

/**
 * External
 */
import moment from '../../assets/js/moment.js';

const defaultProps = {};
const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for input text with material design styling
 */
const BirthDatePickerMaterial = ( {
  id,
  name,
  value,
  $field,
  disabled,
  onChange
} ) => {

  const today = moment().endOf( 'day' );

  return (
    <DatePickerMaterial
      id={ id }
      name={ name }
      title="Data de Nascimento"
      disabled={ disabled }
      startDate={ value }
      maxDate={ today }
      $field={ $field }

      { ...$field( name, ( value ) => {
        onChange( value );
      } ) } />
  );

}

BirthDatePickerMaterial.defaultProps = defaultProps;
BirthDatePickerMaterial.propTypes = propTypes;

export default BirthDatePickerMaterial;