import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import NumberInputMaterial2 from "../../NumberInputMaterial2";
import InputMaterial from "../../InputMaterial";
import { initialData } from "./initial-data";

export default function EndocrinologyReference({
  data,
  setField,
  extract,
  loading,
  loadInitialData,
}) {
  useEffect(() => {
    loadInitialData(initialData);
  }, []);

  function renderFields() {
    const { titles } = initialData;

    return Object.keys(titles).map((key, index) => {
      let title = titles[key];
      return (
        <Row>
          <Col md={3}>
            <p>{title}</p>
          </Col>
          <Col md={3}>
            <NumberInputMaterial2
              name={`${key}Min`}
              title={"Min"}
              inputGroupAfter=""
              inputGroup={true}
              hasValidationRecoil={true}
              decimalScale={1}
              fit={true}
              block={true}
              inputGroup={true}
              allowNegative={false}
              value={extract(data, `${key}Min`, "")}
              disabled={loading}
              shouldDisableThousandSeparator={false}
              handleChange={(field, value) => {
                setField(`${key}Min`, value);
              }}
            />
          </Col>
          <Col md={3}>
            <NumberInputMaterial2
              name={`${key}Max`}
              title={"Max"}
              inputGroupAfter=""
              inputGroup={true}
              hasValidationRecoil={true}
              decimalScale={1}
              fit={true}
              block={true}
              inputGroup={true}
              allowNegative={false}
              value={extract(data, `${key}Max`, "")}
              disabled={loading}
              shouldDisableThousandSeparator={false}
              handleChange={(field, value) => setField(`${key}Max`, value)}
            />
          </Col>
          <Col md={3}>
            <div style={{ marginTop: "-15px" }}>
              <InputMaterial
                disabled={loading}
                title="Unidade"
                name={`${key}Unit`}
                md={12}
                value={extract(data, `${key}Unit`, "")}
                handleChange={(field, value) => setField(`${key}Unit`, value)}
              />
            </div>
          </Col>
        </Row>
      );
    });
  }
  return <div>{renderFields()}</div>;
}

