import { styled } from "../../components/stitches"

export const StyledButtonDefault = styled('button', {
  display: 'inline-block',
  fontWeight: 'normal',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  touchAction: 'manipulation',
  cursor: 'pointer',
  letterSpacing: '2px',
  borderRadius: '4px',
  border: '1px solid transparent',
  lineHeight: '1.4',
  fontSize: '14px',
  minWidth: '100px',
  fontFamily: 'QuickSand',
  textTransform: 'uppercase',
  boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
  padding: "6px 12px",

  '&:hover': {
    color: '#333'
  },

  '&:[disabled], fieldset[disabled]': {
    cursor: 'not-allowed',
    filter: 'alpha(opacity=100)',
    '-webkit-box-shadow': 'none',
    boxShadow: 'none',
    opacity: 1,
    color: '#616161',
    backgroundColor: '#ccc',
    border: '#757575'
  },

  '@md': {
    display: 'block',
    width: '100%',
    marginBottom: '30px',
    padding: '10px',
  },

  variants: {
    variant: {
      default: {
        backgroundColor: '#00678f',
        transition:' 0.2s',
        color: "white",

        '&:hover': {
          backgroundColor: "#427d94"
        },

        '&:active, &:focus': {
          color: "white"
        }
      },

      highlight: {
        backgroundColor: '#4dc3bf',
        transition:' 0.2s',
        color: "white",

        '&:hover': {
          backgroundColor: "#0aafaa"
        }
      },

      warning: {
        backgroundColor: '#ff9f1c',
        transition:' 0.2s',
        color: "white",

        '&:hover': {
          backgroundColor: "#ffb822"
        }
      },

      danger: {
        backgroundColor: '#ff3951',
        transition:' 0.2s',
        color: "white",

        '&:hover': {
          backgroundColor: "#c43d4d"
        }
      }
    },

    position: {
      right: {
        float: 'right'
      },

      left: {
        float: 'left'
      },
    }
  }
})