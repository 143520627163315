import React, { Component } from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import HemogramPanel from "./../HemogramPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import ProfileBiochemistryTable from "./../../old_components/ProfileBiochemistryTable";
import FlatPanelMaterial from "./../FlatPanelMaterial";
import EasPanel from "./../EasPanel";
import connectWithEndpoint from "./requests.js";
import validation from "./validation.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import { Col, Row } from "./../../components/grid";

import {
  containerState,
  reportState,
  hemogramState,
} from "../../modules/factories/default-states.js";

import { PromiseUtil, FunctionUtil } from "../../useful";
import {
  EXAM_ID_BY_NAME,
  REQUEST_EXAM_STATE_ID_BY_NAME,
} from "../../assets/js/Consts.js";
import { REPORT_DEFAULT_VALUES } from "./../../consts";

import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";

import {
  isAnemia,
  isPolicitemia,
  bloodCellsDiagnosis,
  toggleIntenseLeucopeny,
  openCounter,
  closeCounter,
  handleKeyPress,
  enableCounterSound,
  handleFinishedPlaying,
  calcMCV,
  calcMCHC,
  calcTotal,
  calcAbsolute,
  erythrogramDiagnosis,
  mcvDiagnosis,
  mchcDiagnosis,
  leukogramDiagnosis,
  zeroLeukogram,
  incrementField,
  globalLeukDiagnosis,
  resetLeukogram,
  finalGlobalLeukogram,
  isNeutropLeukocytosis,
  diagnosis,
  displayResults,
  DNNE,
  plateletsDiagnosis,
  zeroIfIntenseLeucopeny,
  createHemogramObj,
  createHemogramUpdateState,
  createHemogramRefObj,
  createBiochemistryRefObj,
  createReportObj,
  createPlateletsObj,
  createErythrogramObj,
  createLeukogramObj,
  getBiochemistryTableObjs,
  biochemWithValidationData,
  suggestionsFilter,
  fieldToggle,
} from "../../assets/js/reportFunctions.js";
import Notifications from "react-notification-system-redux";
import { connect as reduxConnect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
import { DEFAULT_INVALID_MESSAGE } from "../../consts/notifications";
import { FocusManagerConfig, hemogramConfigOption } from "./../../services";

/**
 * External
 */
import update from "immutability-helper";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

const View = ({
  rows,
  refs,
  refsEas,
  total,
  options,
  fields,
  isUpdate,
  backLink,
  disableAll,
  reportSubmitPromise,
  reportHeaderData,
  requestExamStates,
  fieldChange,
  calcMCHC,
  calcMCV,
  erythrogramDiagnosis,
  plateletsDiagnosis,
  calcAbsolute,
  resetLeukogram,
  leukogramDiagnosis,
  toggleIntenseLeucopeny,
  finalGlobalLeukogram,
  handleKeyPress,
  handleFinishedPlaying,
  closeCounter,
  toggleIonicCalciumEditable,
  invalidNotification,
  notifications,
  fieldToggle,
  $field,
  $validation,
  $submit,
  $fieldEvent,
  handleSubmitWithRelease,
  handleSubmitWithoutRelease,
  cancelReport,
  stopReport,
  changeCompleteValidation,
  attendAndNotRelease,
  handleSendReportEmail,
  handleFocus,
  setRef,
  openCounter,
  zeroLeukogram,
  reportData,
}) => {
  const biochemistryTableData = biochemWithValidationData(rows, $validation);

  const onSubmit = (
    release = false,
    parcialSave = false,
    varAttendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? handleSubmitWithRelease
      : varAttendAndNotRelease
      ? attendAndNotRelease
      : handleSubmitWithoutRelease;

    const then = () => {
      $submit(submitMethod, invalidNotification);
    };
    parcialSave
      ? changeCompleteValidation(false, then)
      : changeCompleteValidation(true, then);
  };

  const onClickCounter = () => {
    openCounter();
    zeroLeukogram();
  };

  const handleToggle = (field) => {
    fieldToggle(field);
  };

  const handleChange = (field, value) => {
    $fieldEvent("change", field);
    fieldChange(field, value);
  };

  const handleBlur = (event) => {
    $fieldEvent("blur", event.target.name);
  };

  return (
    <>
      <ReportPanelMaterial
        title="Perfil Renal 3"
        backLink={backLink}
        shouldDisable={disableAll}
        isUpdate={isUpdate}
        isRequesting={reportSubmitPromise && reportSubmitPromise.pending}
        handleSubmit={onSubmit}
        cancelReport={cancelReport}
        stopReport={stopReport}
        handleSendReportEmail={handleSendReportEmail}
        handleFocus={handleFocus}
      >
        <Row>
          <Col md={12}>
            <ReportHeader
              data={reportHeaderData}
              fields={fields}
              fieldChange={fieldChange}
              $field={$field}
              $validation={$validation}
            />
          </Col>
        </Row>
        <HemogramPanel
          fields={fields}
          refs={refs}
          disableAll={disableAll}
          $validation={$validation}
          total={total}
          $field={$field}
          fieldChange={fieldChange}
          calcMCV={calcMCV}
          calcMCHC={calcMCHC}
          calcAbsolute={calcAbsolute}
          resetLeukogram={resetLeukogram}
          onClickCounter={onClickCounter}
          toggleIntenseLeucopeny={toggleIntenseLeucopeny}
          finalGlobalLeukogram={finalGlobalLeukogram}
          erythrogramDiagnosis={erythrogramDiagnosis}
          leukogramDiagnosis={leukogramDiagnosis}
          plateletsDiagnosis={plateletsDiagnosis}
          handleKeyPress={handleKeyPress}
          handleFinishedPlaying={handleFinishedPlaying}
          closeCounter={closeCounter}
          setRef={setRef}
          reportData={reportData}
        />
        <EasPanel
          volumeFieldName="easVolume"
          volume={fields.easVolume}
          volumeHasValidation={$validation.easVolume.show}
          volumeValidationReason={$validation.easVolume.error.reason}
          densityFieldName="easDensity"
          density={fields.easDensity}
          densityHasValidation={$validation.easDensity.show}
          densityValidationReason={$validation.easDensity.error.reason}
          densityRefs={refsEas.density}
          colorOptionIdFieldName="easColorOptionId"
          colorOptionId={fields.easColorOptionId}
          colorOptionIdHasValidation={$validation.easColorOptionId.show}
          colorOptionIdValidationReason={
            $validation.easColorOptionId.error.reason
          }
          colorOptions={options.colorOptions}
          smellOptionIdFieldName="easSmellOptionId"
          smellOptionId={fields.easSmellOptionId}
          smellOptionIdHasValidation={$validation.easSmellOptionId.show}
          smellOptionIdValidationReason={
            $validation.easSmellOptionId.error.reason
          }
          smellOptions={options.smellsOptions}
          aspectOptionIdFieldName="easAspectOptionId"
          aspectOptionId={fields.easAspectOptionId}
          aspectOptionIdHasValidation={$validation.easAspectOptionId.show}
          aspectOptionIdValidationReason={
            $validation.easAspectOptionId.error.reason
          }
          aspectOptions={options.aspectsOptions}
          proteinsOptionIdFieldName={"easProteinsOptionId"}
          proteinsOptionId={fields.easProteinsOptionId}
          proteinsOptionIdHasValidation={$validation.easProteinsOptionId.show}
          proteinsOptionIdValidationReason={
            $validation.easProteinsOptionId.error.reason
          }
          proteinsOptions={options.proteinsOptions}
          proteinsRefs={refsEas.proteins}
          glucoseOptionIdFieldName={"easGlucoseOptionId"}
          glucoseOptionId={fields.easGlucoseOptionId}
          glucoseOptionIdHasValidation={$validation.easGlucoseOptionId.show}
          glucoseOptionIdValidationReason={
            $validation.easGlucoseOptionId.error.reason
          }
          glucoseOptions={options.glucoseOptions}
          glucoseRefs={refsEas.glucose}
          acetoneOptionIdFieldName={"easAcetoneOptionId"}
          acetoneOptionId={fields.easAcetoneOptionId}
          acetoneOptionIdHasValidation={$validation.easAcetoneOptionId.show}
          acetoneOptionIdValidationReason={
            $validation.easAcetoneOptionId.error.reason
          }
          acetoneOptions={options.acetoneOptions}
          acetoneRefs={refsEas.acetone}
          phFieldName={"easPh"}
          ph={fields.easPh}
          phHasValidation={$validation.easPh.show}
          phValidationReason={$validation.easPh.error.reason}
          phRefs={refsEas.ph}
          bilirubinOptionIdFieldName={"easBilirubinOptionId"}
          bilirubinOptionId={fields.easBilirubinOptionId}
          bilirubinOptionIdHasValidation={$validation.easBilirubinOptionId.show}
          bilirubinOptionIdValidationReason={
            $validation.easBilirubinOptionId.error.reason
          }
          bilirubinOptions={options.bilirubinOptions}
          bilirubinRefs={refsEas.bilirubin}
          urobilinogenOptionIdFieldName={"easUrobilinogenOptionId"}
          urobilinogenOptionId={fields.easUrobilinogenOptionId}
          urobilinogenOptionIdHasValidation={
            $validation.easUrobilinogenOptionId.show
          }
          urobilinogenOptionIdValidationReason={
            $validation.easUrobilinogenOptionId.error.reason
          }
          urobilinogenOptions={options.urobilinogenOptions}
          urobilinogenRefs={refsEas.urobilinogen}
          hemoglobinOptionIdFieldName={"easHemoglobinOptionId"}
          hemoglobinOptionId={fields.easHemoglobinOptionId}
          hemoglobinOptionIdHasValidation={
            $validation.easHemoglobinOptionId.show
          }
          hemoglobinOptionIdValidationReason={
            $validation.easHemoglobinOptionId.error.reason
          }
          hemoglobinOptions={options.hemoglobinOptions}
          hemoglobinRefs={refsEas.hemoglobin}
          leukocyteOptionIdFieldName={"easLeukocyteOptionId"}
          leukocyteOptionId={fields.easLeukocyteOptionId}
          leukocyteOptionIdHasValidation={$validation.easLeukocyteOptionId.show}
          leukocyteOptionIdValidationReason={
            $validation.easLeukocyteOptionId.error.reason
          }
          leukocyteOptions={options.leukocyteOptions}
          leukocyteRefs={refsEas.leukocyte}
          nitriteOptionIdFieldName={"easNitriteOptionId"}
          nitriteOptionId={fields.easNitriteOptionId}
          nitriteOptionIdHasValidation={$validation.easNitriteOptionId.show}
          nitriteOptionIdValidationReason={
            $validation.easNitriteOptionId.error.reason
          }
          nitriteOptions={options.nitriteOptions}
          nitriteRefs={refsEas.nitrite}
          epithelialCellsOptionTagsFieldName={"easEpithelialCellsOptionTags"}
          epithelialCellsOptionTags={fields.easEpithelialCellsOptionTags}
          epithelialCellsOptionTagsSuggestions={options.epithelialCellsOptions}
          epithelialCellsOptionTagsHasValidation={
            $validation.easEpithelialCellsOptionTags.show
          }
          epithelialCellsOptionTagsValidationReason={
            $validation.easEpithelialCellsOptionTags.error.reason
          }
          epithelialCellsRefs={refsEas.epithelialCells}
          erythrocytesMinFieldName="easErythrocytesMin"
          erythrocytesMin={fields.easErythrocytesMin}
          erythrocytesMaxFieldName="easErythrocytesMax"
          erythrocytesMax={fields.easErythrocytesMax}
          erythrocytesHasValidation={$validation.isErythrocytesValid.show}
          erythrocytesValidationReason={
            $validation.isErythrocytesValid.error.reason
          }
          isErythrocytesAbsentFieldName="easIsErythrocytesAbsent"
          isErythrocytesAbsent={fields.easIsErythrocytesAbsent}
          isErythrocytesUncountableFieldName="easIsErythrocytesUncountable"
          isErythrocytesUncountable={fields.easIsErythrocytesUncountable}
          erythrocytesRefs={refsEas.erythrocytes}
          pusCellsMinFieldName="easPusCellsMin"
          pusCellsMin={fields.easPusCellsMin}
          pusCellsMaxFieldName="easPusCellsMax"
          pusCellsMax={fields.easPusCellsMax}
          pusCellsHasValidation={$validation.isPusCellsValid.show}
          pusCellsValidationReason={$validation.isPusCellsValid.error.reason}
          isPusCellsAbsentFieldName="easIsPusCellsAbsent"
          isPusCellsAbsent={fields.easIsPusCellsAbsent}
          isPusCellsUncountableFieldName="easIsPusCellsUncountable"
          isPusCellsUncountable={fields.easIsPusCellsUncountable}
          pusCellsRefs={refsEas.pusCells}
          ridgesOptionTagsFieldName={"easRidgesOptionTags"}
          ridgesOptionTags={fields.easRidgesOptionTags}
          ridgesOptionTagsSuggestions={options.ridgesOptions}
          ridgesOptionTagsHasValidation={$validation.easRidgesOptionTags.show}
          ridgesOptionTagsValidationReason={
            $validation.easRidgesOptionTags.error.reason
          }
          ridgesOptionRefs={refsEas.ridges}
          castsOptionTagsFieldName={"easCastsOptionTags"}
          castsOptionTags={fields.easCastsOptionTags}
          castsOptionTagsSuggestions={options.castsOptions}
          castsOptionTagsHasValidation={$validation.easCastsOptionTags.show}
          castsOptionTagsValidationReason={
            $validation.easCastsOptionTags.error.reason
          }
          castsOptionRefs={refsEas.casts}
          bacterialFloraOptionIdFieldName={"easBacterialFloraOptionId"}
          bacterialFloraOptionId={fields.easBacterialFloraOptionId}
          bacterialFloraOptionIdHasValidation={
            $validation.easBacterialFloraOptionId.show
          }
          bacterialFloraOptionIdValidationReason={
            $validation.easBacterialFloraOptionId.error.reason
          }
          bacterialFloraOptions={options.bacterialFloraOptions}
          bacterialFloraOptionRefs={refsEas.bacterialFlora}
          spermatozoaOptionIdFieldName={"easSpermatozoaOptionId"}
          spermatozoaOptionId={fields.easSpermatozoaOptionId}
          spermatozoaOptionIdHasValidation={
            $validation.easSpermatozoaOptionId.show
          }
          spermatozoaOptionIdValidationReason={
            $validation.easSpermatozoaOptionId.error.reason
          }
          spermatozoaOptions={options.spermatozoaOptions}
          spermatozoaOptionRefs={refsEas.spermatozoa}
          obsFieldName={"easObs"}
          obs={fields.easObs}
          suggestionsFilter={suggestionsFilter}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleToggle={handleToggle}
          shouldDisable={disableAll}
        />
        <Row>
          <Col md={12}>
            <FlatPanelMaterial title="Bioquímica">
              <ProfileBiochemistryTable
                data={biochemistryTableData}
                isUpdate={isUpdate}
                handleChange={handleChange}
                handleBlur={handleBlur}
                toggleIonicCalciumEditable={toggleIonicCalciumEditable}
                shouldDisable={disableAll}
                requestExamStates={requestExamStates}
                biochemObs={fields.biochemObs}
              />
            </FlatPanelMaterial>
          </Col>
        </Row>
      </ReportPanelMaterial>
      <Notifications notifications={notifications} />
    </>
  );
};

const ValidatedView = validation(View);

class ProfileRenal3ReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        ...reportState(),
        ...hemogramState(),
      },
      ...containerState(),
      shouldSendEmail: false,
    };

    this.state.fields.easVolume = "";
    this.state.fields.easColorOptionId = 0;
    this.state.fields.easSmellOptionId = 0;
    this.state.fields.easAspectOptionId = 0;
    this.state.fields.easDensity = "";
    this.state.fields.easProteinsOptionId = 0;
    this.state.fields.easGlucoseOptionId = 0;
    this.state.fields.easAcetoneOptionId = 0;
    this.state.fields.easPh = "";
    this.state.fields.easBilirubinOptionId = 0;
    this.state.fields.easUrobilinogenOptionId = 0;
    this.state.fields.easHemoglobinOptionId = 0;
    this.state.fields.easLeukocyteOptionId = 0;
    this.state.fields.easNitriteOptionId = 0;
    this.state.fields.easEpithelialCellsOptionTags = [];
    this.state.fields.easRidgesOptionTags = [];
    this.state.fields.easCastsOptionTags = [];
    this.state.fields.easErythrocytesMin = "";
    this.state.fields.easErythrocytesMax = "";
    this.state.fields.easIsErythrocytesAbsent = false;
    this.state.fields.easIsErythrocytesUncountable = false;
    this.state.fields.easPusCellsMin = "";
    this.state.fields.easPusCellsMax = "";
    this.state.fields.easIsPusCellsAbsent = false;
    this.state.fields.easIsPusCellsUncountable = false;
    this.state.fields.easBacterialFloraOptionId = 0;
    this.state.fields.easSpermatozoaOptionId = 0;
    this.state.fields.easObs = "";
    this.state.fields.urea = "";
    this.state.fields.creatinine = "";
    this.state.fields.sodium = "";
    this.state.fields.potassium = "";
    this.state.fields.phosphorus = "";
    this.state.fields.biochemObs = "";

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.successNotification = successNotification.bind(this);
    this.openCounter = openCounter.bind(this);
    this.closeCounter = closeCounter.bind(this);
    this.handleKeyPress = handleKeyPress.bind(this);
    this.enableCounterSound = enableCounterSound.bind(this);
    this.handleFinishedPlaying = handleFinishedPlaying.bind(this);
    this.isAnemia = isAnemia.bind(this);
    this.isPolicitemia = isPolicitemia.bind(this);
    this.bloodCellsDiagnosis = bloodCellsDiagnosis.bind(this);
    this.calcMCV = calcMCV.bind(this);
    this.calcMCHC = calcMCHC.bind(this);
    this.calcTotal = calcTotal.bind(this);
    this.calcAbsolute = calcAbsolute.bind(this);
    this.erythrogramDiagnosis = erythrogramDiagnosis.bind(this);
    this.mcvDiagnosis = mcvDiagnosis.bind(this);
    this.mchcDiagnosis = mchcDiagnosis.bind(this);
    this.leukogramDiagnosis = leukogramDiagnosis.bind(this);
    this.zeroLeukogram = zeroLeukogram.bind(this);
    this.incrementField = incrementField.bind(this);
    this.globalLeukDiagnosis = globalLeukDiagnosis.bind(this);
    this.resetLeukogram = resetLeukogram.bind(this);
    this.finalGlobalLeukogram = finalGlobalLeukogram.bind(this);
    this.isNeutropLeukocytosis = isNeutropLeukocytosis.bind(this);
    this.plateletsDiagnosis = plateletsDiagnosis.bind(this);
    this.toggleIntenseLeucopeny = toggleIntenseLeucopeny.bind(this);
    this.zeroIfIntenseLeucopeny = zeroIfIntenseLeucopeny.bind(this);
    this.diagnosis = diagnosis.bind(this);
    this.displayResults = displayResults.bind(this);
    this.rows = this.rows.bind(this);
    this.biochemRefs = this.biochemRefs.bind(this);
    this.DNNE = DNNE.bind(this);
    this.createPlateletsObj = createPlateletsObj.bind(this);
    this.createErythrogramObj = createErythrogramObj.bind(this);
    this.createLeukogramObj = createLeukogramObj.bind(this);
    this.createHemogramObj = createHemogramObj.bind(this);
    this.createReportObj = createReportObj.bind(this);
    this.createObj = this.createObj.bind(this);
    this.fieldToggle = fieldToggle.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        if (xhr.response.status === 200) {
          const obj = data;
          this.fillFields(obj);
        }
      };

      this.props.withId(id, then);
    }
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    let editState = createHemogramUpdateState(obj);

    editState.fields.easVolume = { $set: obj.easVolume };
    editState.fields.easColorOptionId = { $set: obj.easColorOptionId };
    editState.fields.easSmellOptionId = { $set: obj.easSmellOptionId };
    editState.fields.easAspectOptionId = { $set: obj.easAspectOptionId };
    editState.fields.easDensity = { $set: obj.easDensity };
    editState.fields.easProteinsOptionId = { $set: obj.easProteinsOptionId };
    editState.fields.easGlucoseOptionId = { $set: obj.easGlucoseOptionId };
    editState.fields.easAcetoneOptionId = { $set: obj.easAcetoneOptionId };
    editState.fields.easPh = { $set: obj.easPh };
    editState.fields.easBilirubinOptionId = { $set: obj.easBilirubinOptionId };
    editState.fields.easUrobilinogenOptionId = {
      $set: obj.easUrobilinogenOptionId,
    };
    editState.fields.easHemoglobinOptionId = {
      $set: obj.easHemoglobinOptionId,
    };
    editState.fields.easLeukocyteOptionId = { $set: obj.easLeukocyteOptionId };
    editState.fields.easNitriteOptionId = { $set: obj.easNitriteOptionId };
    editState.fields.easEpithelialCellsOptionTags = {
      $set: obj.easEpithelialCellsOptionTags,
    };
    editState.fields.easRidgesOptionTags = { $set: obj.easRidgesOptionTags };
    editState.fields.easCastsOptionTags = { $set: obj.easCastsOptionTags };
    editState.fields.easErythrocytesMin = { $set: obj.easErythrocytesMin };
    editState.fields.easErythrocytesMax = { $set: obj.easErythrocytesMax };
    editState.fields.easIsErythrocytesAbsent = {
      $set: obj.easIsErythrocytesAbsent,
    };
    editState.fields.easIsErythrocytesUncountable = {
      $set: obj.easIsErythrocytesUncountable,
    };
    editState.fields.easPusCellsMin = { $set: obj.easPusCellsMin };
    editState.fields.easPusCellsMax = { $set: obj.easPusCellsMax };
    editState.fields.easIsPusCellsAbsent = { $set: obj.easIsPusCellsAbsent };
    editState.fields.easIsPusCellsUncountable = {
      $set: obj.easIsPusCellsUncountable,
    };
    editState.fields.easBacterialFloraOptionId = {
      $set: obj.easBacterialFloraOptionId,
    };
    editState.fields.easSpermatozoaOptionId = {
      $set: obj.easSpermatozoaOptionId,
    };
    editState.fields.easObs = { $set: obj.easObs };
    editState.fields.urea = { $set: obj.urea };
    editState.fields.creatinine = { $set: obj.creatinine };
    editState.fields.sodium = { $set: obj.sodium };
    editState.fields.potassium = { $set: obj.potassium };
    editState.fields.phosphorus = { $set: obj.phosphorus };
    editState.fields.biochemObs = {
      $set: obj.biochemistryObs,
    };

    const fillObject = (state) => update(state, editState);

    this.setState(fillObject);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Obtains the submit object
   *
   * @return {Object}
   *
   */
  createObj() {
    const {
      easVolume,
      easColorOptionId,
      easSmellOptionId,
      easAspectOptionId,
      easDensity,
      easProteinsOptionId,
      easGlucoseOptionId,
      easAcetoneOptionId,
      easPh,
      easBilirubinOptionId,
      easUrobilinogenOptionId,
      easHemoglobinOptionId,
      easLeukocyteOptionId,
      easNitriteOptionId,
      easEpithelialCellsOptionTags,
      easRidgesOptionTags,
      easCastsOptionTags,
      easErythrocytesMin,
      easErythrocytesMax,
      easIsErythrocytesAbsent,
      easIsErythrocytesUncountable,
      easPusCellsMin,
      easPusCellsMax,
      easIsPusCellsAbsent,
      easIsPusCellsUncountable,
      easBacterialFloraOptionId,
      easSpermatozoaOptionId,
      easObs,
      urea,
      creatinine,
      sodium,
      potassium,
      phosphorus,
      biochemObs,
    } = this.state.fields;

    const hemogramObj = {
      id: this.getId(),
      ...this.createReportObj(),
      ...this.createHemogramObj(),
    };

    const easErythMin =
      easIsErythrocytesAbsent || easIsErythrocytesUncountable
        ? ""
        : easErythrocytesMin;
    const easErythMax =
      easIsErythrocytesAbsent || easIsErythrocytesUncountable
        ? ""
        : easErythrocytesMax;
    const easPCellsMin =
      easIsPusCellsAbsent || easIsPusCellsUncountable ? "" : easPusCellsMin;
    const easPCellsMax =
      easIsPusCellsAbsent || easIsPusCellsUncountable ? "" : easPusCellsMax;
    return {
      ...hemogramObj,
      easVolume,
      easColorOptionId: easColorOptionId,
      easSmellOptionId: easSmellOptionId,
      easAspectOptionId: easAspectOptionId,
      easDensity: easDensity,
      easProteinsOptionId: easProteinsOptionId,
      easGlucoseOptionId: easGlucoseOptionId,
      easAcetoneOptionId: easAcetoneOptionId,
      easPh: easPh,
      easBilirubinOptionId: easBilirubinOptionId,
      easUrobilinogenOptionId: easUrobilinogenOptionId,
      easHemoglobinOptionId: easHemoglobinOptionId,
      easLeukocyteOptionId: easLeukocyteOptionId,
      easNitriteOptionId: easNitriteOptionId,
      easEpithelialCellsOptionTags: easEpithelialCellsOptionTags,
      easRidgesOptionTags: easRidgesOptionTags,
      easCastsOptionTags: easCastsOptionTags,
      easErythrocytesMin: easErythMin,
      easErythrocytesMax: easErythMax,
      easIsErythrocytesAbsent: easIsErythrocytesAbsent,
      easIsErythrocytesUncountable: easIsErythrocytesUncountable,
      easPusCellsMin: easPCellsMin,
      easPusCellsMax: easPCellsMax,
      easIsPusCellsAbsent: easIsPusCellsAbsent,
      easIsPusCellsUncountable: easIsPusCellsUncountable,
      easBacterialFloraOptionId: easBacterialFloraOptionId,
      easSpermatozoaOptionId: easSpermatozoaOptionId,
      easObs: easObs,
      urea: urea,
      creatinine: creatinine,
      sodium: sodium,
      potassium: potassium,
      phosphorus: phosphorus,
      biochemObs: biochemObs,
    };
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (!this.state.completeValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" ? null : obj[i];
      }
    }

    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  biochemRefs() {
    const { biochemRefsPromise } = this.props;

    return PromiseUtil.extractValue(
      biochemRefsPromise,
      REPORT_DEFAULT_VALUES.BIOCHEMISTRY_REF_DEFAULT,
      createBiochemistryRefObj
    );
  }

  rows() {
    const biochemRefs = this.biochemRefs();

    const values = [
      { id: EXAM_ID_BY_NAME["UREA"], result: this.state.fields.urea },
      {
        id: EXAM_ID_BY_NAME["CREATININE"],
        result: this.state.fields.creatinine,
      },
      { id: EXAM_ID_BY_NAME["SODIUM"], result: this.state.fields.sodium },
      { id: EXAM_ID_BY_NAME["POTASSIUM"], result: this.state.fields.potassium },
      {
        id: EXAM_ID_BY_NAME["PHOSPHORUS"],
        result: this.state.fields.phosphorus,
      },
    ];

    return getBiochemistryTableObjs(values, biochemRefs);
  }

  render() {
    const { examRequestId } = this.props.match.params;
    const backLink = "/solicitacoes-exame/" + examRequestId;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    const {
      refsPromise,
      reportSubmitPromise,
      objPromise,
      examsPromise,
      requestExamStatesPromise,
      reportHeaderPromise,
      refsEas,
      colorOptions,
      smellOptions,
      aspectOptions,
      glucoseOptions,
      acetoneOptions,
      bilirubinOptions,
      urobilinogenOptions,
      hemoglobinOptions,
      leukocyteOptions,
      nitriteOptions,
      bacterialFloraOptions,
      spermatozoaOptions,
      proteinsOptions,
      epithelialCellsOptions,
      ridgesOptions,
      castsOptions,
    } = this.props;

    const {
      fields,
      isCounterOpen,
      isIntenseLeucopeny,
      shouldBlockCounterSound,
      showTotalValidation,
    } = this.state;

    const isUpdate = this.isUpdate();

    // Loader
    let editFetches = null;
    const shouldCheckEditFetches = isUpdate && objPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([refsPromise, objPromise]);
    }

    const shouldDisplayLoader = isUpdate
      ? editFetches && editFetches.pending
      : refsPromise.pending;
    if (shouldDisplayLoader) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );

    const requestExamStates = PromiseUtil.extractValue(
      requestExamStatesPromise,
      []
    );

    const biochemistryExams = PromiseUtil.extractValue(examsPromise, []);

    const refs = PromiseUtil.extractValue(
      refsPromise,
      REPORT_DEFAULT_VALUES.HEMOGRAM_REF_DEFAULT,
      createHemogramRefObj
    );

    const total = this.calcTotal();

    const reportPromiseData = PromiseUtil.extractValue(objPromise, {});

    const options = {
      colorOptions: colorOptions.fulfilled ? colorOptions.value : [],
      smellsOptions: smellOptions.fulfilled ? smellOptions.value : [],
      aspectsOptions: aspectOptions.fulfilled ? aspectOptions.value : [],
      glucoseOptions: glucoseOptions.fulfilled ? glucoseOptions.value : [],
      acetoneOptions: acetoneOptions.fulfilled ? acetoneOptions.value : [],
      bilirubinOptions: bilirubinOptions.fulfilled
        ? bilirubinOptions.value
        : [],
      urobilinogenOptions: urobilinogenOptions.fulfilled
        ? urobilinogenOptions.value
        : [],
      hemoglobinOptions: hemoglobinOptions.fulfilled
        ? hemoglobinOptions.value
        : [],
      leukocyteOptions: leukocyteOptions.fulfilled
        ? leukocyteOptions.value
        : [],
      nitriteOptions: nitriteOptions.fulfilled ? nitriteOptions.value : [],
      bacterialFloraOptions: bacterialFloraOptions.fulfilled
        ? bacterialFloraOptions.value
        : [],
      spermatozoaOptions: spermatozoaOptions.fulfilled
        ? spermatozoaOptions.value
        : [],
      proteinsOptions: proteinsOptions.fulfilled ? proteinsOptions.value : [],
      epithelialCellsOptions: epithelialCellsOptions.fulfilled
        ? epithelialCellsOptions.value
        : [],
      ridgesOptions: ridgesOptions.fulfilled ? ridgesOptions.value : [],
      castsOptions: castsOptions.fulfilled ? castsOptions.value : [],
    };

    return (
      <FadeIn>
        <ValidatedView
          isUpdate={isUpdate}
          fields={fields}
          backLink={backLink}
          rows={this.rows()}
          refs={refs}
          refsEas={refsEas.fulfilled ? refsEas.value : []}
          biochemistryExams={biochemistryExams}
          examRequestId={examRequestId}
          isCounterOpen={isCounterOpen}
          requestExamStates={requestExamStates}
          shouldBlockCounterSound={shouldBlockCounterSound}
          showTotalValidation={showTotalValidation}
          reportSubmitPromise={reportSubmitPromise}
          disableAll={reportSubmitPromise && reportSubmitPromise.pending}
          total={isIntenseLeucopeny ? "" : total}
          options={options}
          mchc={this.calcMCHC()}
          reportHeaderData={reportHeaderFactory(reportHeaderData)}
          fieldChange={this.fieldChange}
          handleSubmit={this.handleSubmit}
          openCounter={this.openCounter}
          resetLeukogram={this.resetLeukogram}
          handleKeyPress={this.handleKeyPress}
          calcAbsolute={this.calcAbsolute}
          toggleIntenseLeucopeny={this.toggleIntenseLeucopeny}
          invalidNotification={() => {
            this.props.errorNotification(DEFAULT_INVALID_MESSAGE);
          }}
          notifications={this.props.notifications}
          finalGlobalLeukogram={this.finalGlobalLeukogram}
          zeroLeukogram={this.zeroLeukogram}
          showvalidationFunctions={this.showvalidationFunctions}
          erythrogramDiagnosis={this.erythrogramDiagnosis}
          leukogramDiagnosis={this.leukogramDiagnosis}
          calcMCV={this.calcMCV}
          calcMCHC={this.calcMCHC}
          calcTotal={this.calcTotal}
          plateletsDiagnosis={this.plateletsDiagnosis}
          handleFinishedPlaying={this.handleFinishedPlaying}
          closeCounter={this.closeCounter}
          resultChange={this.resultChange}
          fieldToggle={this.fieldToggle}
          handleSubmitWithRelease={this.handleSubmitWithRelease}
          handleSubmitWithoutRelease={this.handleSubmitWithoutRelease}
          cancelReport={this.cancelReport}
          stopReport={this.stopReport}
          changeCompleteValidation={this.changeCompleteValidation}
          attendAndNotRelease={this.attendAndNotRelease}
          completeValidation={this.state.completeValidation}
          handleSendReportEmail={this.handleSendReportEmail}
          handleFocus={this.props.handleFocus}
          setRef={this.props.setRef}
          reportData={reportPromiseData}
        />
      </FadeIn>
    );
  }
}

const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);

const focusManager = FocusManagerConfig(
  hemogramConfigOption.config,
  hemogramConfigOption.initialFocus
);

export default FunctionUtil.compose(
  focusManager,
  connectWithEndpoint,
  connectWithRedux
)(ProfileRenal3ReportForm);
