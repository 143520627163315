import React from 'react';
import { Col as BsCol } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * @author Victor Heringer
 * 
 * Coluns for grid
 * 
 * @prop {String} componentClass
 * @prop {Number} xs
 * @prop {Number} sm
 * @prop {Number} md
 * @prop {Number} lg
 * @prop {Bool} xsHidden
 * @prop {Bool} smHidden
 * @prop {Bool} mdHidden
 * @prop {Bool} lgHidden
 * @prop {Number} xsOffset
 * @prop {Number} smOffset
 * @prop {Number} mdOffset
 * @prop {Number} lgOffset
 * @prop {Number} xsPush
 * @prop {Number} smPush
 * @prop {Number} mdPush
 * @prop {Number} lgPush
 * @prop {Number} xsPull
 * @prop {Number} smPull
 * @prop {Number} mdPull
 * @prop {Number} lgPull
 * @prop {String} mdClass
 * @prop {String} className
 * @prop {Element} children
 */
const Col = ({
  componentClass,
  xs,
  sm,
  md,
  lg,
  xsHidden,
  smHidden,
  mdHidden,
  lgHidden,
  xsOffset,
  smOffset,
  mdOffset,
  lgOffset,
  xsPush,
  smPush,
  mdPush,
  lgPush,
  xsPull,
  smPull,
  mdPull,
  lgPull,
  mdClass,
  className,
  children
}) => {
  return (<React.Fragment>
    <BsCol
      componentClass={componentClass}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xsHidden={xsHidden}
      smHidden={smHidden}
      mdHidden={mdHidden}
      lgHidden={lgHidden}
      xsOffset={xsOffset}
      smOffset={smOffset}
      mdOffset={mdOffset}
      lgOffset={lgOffset}
      xsPush={xsPush}
      smPush={smPush}
      mdPush={mdPush}
      lgPush={lgPush}
      xsPull={xsPull}
      smPull={smPull}
      mdPull={mdPull}
      lgPull={lgPull}
      bsClass={mdClass}
      className={className}
    >
      {children}
    </BsCol>
  </React.Fragment>);
}

Col.defaultProps = {
  mdClass: 'col',
  componentClass: 'div'
};

Col.propTypes = {
  componentClass: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xsHidden: PropTypes.bool,
  smHidden: PropTypes.bool,
  mdHidden: PropTypes.bool,
  lgHidden: PropTypes.bool,
  xsOffset: PropTypes.number,
  smOffset: PropTypes.number,
  mdOffset: PropTypes.number,
  lgOffset: PropTypes.number,
  xsPush: PropTypes.number,
  smPush: PropTypes.number,
  mdPush: PropTypes.number,
  lgPush: PropTypes.number,
  xsPull: PropTypes.number,
  smPull: PropTypes.number,
  mdPull: PropTypes.number,
  lgPull: PropTypes.number,
  mdClass: PropTypes.string,
  className: PropTypes.string
};

export default Col;