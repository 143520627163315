const defaultState = {
  requestId: 0,
};

export default function RequestReducer(state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_REQUEST_ID":
      return {
        ...state,
        requestId: action.payload,
      };

    default:
      return state;
  }
}
