import { required } from "../../../assets/js/validationFunctions";
import { EXAM_TYPES } from "../../../consts";

export const bloodProteinFieldNames = () => {
  return ["bloodProteinResult"];
};

export const bloodProteinDefaultState = () => {
  return {
    bloodProteinResult: "",
  };
};

export const bloodProteinStateApiFactory = (obj) => {
  return {
    bloodProteinResult: obj.bloodProteinResult,
  };
};

export const bloodProteinFieldsToApiObjParser = (fields) => {
  const { bloodProteinResult } = fields;

  return {
    bloodProteinResult: bloodProteinResult,
  };
};

export const bloodProteinValidationRules = (fields, isCompleteValidation) => {
  const { bloodProteinResult } = fields;

  return {
    bloodProteinResult: {
      rules: isCompleteValidation
        ? [[required, bloodProteinResult, "Obrigatório"]]
        : [],
    },
  };
};

export const bloodProteinRequests = (requestExamId) => {
  return {
    bloodProteinRefsPromise: `/reference-values/?requestExamId=${requestExamId}&examTypeId=${EXAM_TYPES.HEMATOLOGY}`,
  };
};
