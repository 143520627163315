import React from 'react';
import PropTypes from 'prop-types';

/**
 * External
 */
import { Col } from 'react-bootstrap';

/**
 * Internal
 */
import InputValidationMsg from './../InputValidationMsg';
import NumberInputMaterial2 from './../NumberInputMaterial2';
import CheckboxMaterial from './../CheckboxMaterial';

/**
 * Internal
 */
import styles from './index.module.css';

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for input text with material design styling
 */
const MinMaxInputMaterial = ( {
  md,
  title,
  firstCheckboxTitle,
  secondCheckboxTitle,
  minName,
  maxName,
  firstCheckboxName,
  secondCheckboxName,
  minValue,
  maxValue,
  isFirstCheckboxChecked,
  isSecondCheckboxChecked,
  refs,
  onBlur,
  onChange,
  onToggle,
  hasValidation,
  validationReason,
  validationType,
  hasValidationRecoil,
  shouldDisable
} ) => {

  /**
   * Validation Component
   * @type {Object}
   */
  const validation = <InputValidationMsg
    type={ validationType }
    visible={ hasValidation }
    message={ validationReason }
    top={ hasValidationRecoil ? '-18px' : '' } />;

  const minVal = ( isFirstCheckboxChecked || isSecondCheckboxChecked )
    ? ''
    : minValue
    ;

  const maxVal = ( isFirstCheckboxChecked || isSecondCheckboxChecked )
    ? ''
    : maxValue
    ;

  return (
    <Col md={ md }>
      <Col md={ 12 } >
        <Col md={ 3 } className="text-right" >
          <p className='mr-20 cleanItem'>{ title }</p>
        </Col>
        <Col md={ 1 } >
          <NumberInputMaterial2
            name={ minName }
            md={ 3 }
            inputGroupAfter=""
            inputGroup={ true }
            hasValidationRecoil={ true }
            decimalScale={ 1 }
            fit={ true }
            block={ true }
            inputGroup={ true }
            allowNegative={ false }
            value={ minVal }
            disabled={ isFirstCheckboxChecked || isSecondCheckboxChecked || shouldDisable }
            shouldDisableThousandSeparator={ false }
            handleChange={ onChange }
            handleBlur={ onBlur } />
        </Col>
        <Col xs={ 1 } ><p className='cleanItem text-center'>ATÉ</p></Col>
        <Col xs={ 1 } >
          <NumberInputMaterial2
            name={ maxName }
            md={ 3 }
            inputGroupAfter=""
            inputGroup={ true }
            hasValidationRecoil={ true }
            decimalScale={ 1 }
            fit={ true }
            block={ true }
            inputGroup={ true }
            allowNegative={ false }
            value={ maxVal }
            disabled={ isFirstCheckboxChecked || isSecondCheckboxChecked || shouldDisable }
            shouldDisableThousandSeparator={ false }
            handleChange={ onChange }
            handleBlur={ onBlur } />
        </Col>
        <Col xs={ 1 }></Col>
        <Col xs={ 5 } >
          <div className='text-center cleanItem'>
            <p>{ refs }</p>
          </div>
        </Col>
      </Col>
      <Col xs={ 12 } >
        <Col xs={ 3 } >
          <p className='mr-20 cleanItem'></p>
        </Col>
        <Col xs={ 2 } >
          <CheckboxMaterial
            name={ firstCheckboxName }
            titleAfter={ firstCheckboxTitle }
            checked={ isFirstCheckboxChecked }
            disabled={ isSecondCheckboxChecked || shouldDisable }
            onChange={ onToggle } />
        </Col>
        <Col xs={ 2 } >
          <CheckboxMaterial
            name={ secondCheckboxName }
            titleAfter={ secondCheckboxTitle }
            checked={ isSecondCheckboxChecked }
            disabled={ isFirstCheckboxChecked || shouldDisable }
            onChange={ onToggle } />
        </Col>
        { validation }
      </Col>
    </Col>
  );

}

MinMaxInputMaterial.defaultProps = {
  validationType: 'danger',
  hasValidationRecoil: true
};

MinMaxInputMaterial.propTypes = {};

export default MinMaxInputMaterial;