import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal
 */
import FlatPanelMaterial from './../FlatPanelMaterial';
import ParasitologicalFreshFecesContent from './../ParasitologicalFreshFecesContent';

/**
 * External
 */
const propTypes = {
  shouldDisable: PropTypes.bool
};

const defaultProps = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Erythrogram diagnosis panel
 * 
 */
const ParasitologicalFreshFecesPanel = ( {
  title,
  fecesColorOptionId,
  fecesColorOptionIdFieldName,
  fecesColorOptions,
  isFecesColorOptionsLoading,
  fecesColorOptionIdHasValidation,
  fecesColorOptionIdValidationReason,
  fecesSmellOptionId,
  fecesSmellOptionIdFieldName,
  fecesSmellOptions,
  isFecesSmellOptionsLoading,
  fecesSmellOptionIdHasValidation,
  fecesSmellOptionIdValidationReason,
  fecesAspectOptionId,
  fecesAspectOptionIdFieldName,
  fecesAspectOptions,
  isFecesAspectOptionsLoading,
  fecesAspectOptionIdHasValidation,
  fecesAspectOptionIdValidationReason,
  fecesConsistencyOptionId,
  fecesConsistencyOptionIdFieldName,
  fecesConsistencyOptions,
  isFecesConsistencyOptionsLoading,
  fecesConsistencyOptionIdHasValidation,
  fecesConsistencyOptionIdValidationReason,
  macroscopicParasitesFieldName,
  macroscopicParasites,
  macroscopicParasitesHasValidation,
  macroscopicParasitesValidationReason,
  resultFieldName,
  result,
  resultHasValidation,
  resultValidationReason,
  obsFieldName,
  obs,
  shouldDisable,
  handleChange,
  handleBlur,
  parasitologicalFreshFecesResult,
  checkdState,
  checkboxes,
  multiBoxChange,
  handleCheckedResultChange
} ) =>
  <FlatPanelMaterial title="Parasitológico de Fezes Frescas">
    <ParasitologicalFreshFecesContent
      title={ title }
      fecesColorOptionId={ fecesColorOptionId }
      fecesColorOptionIdFieldName={ fecesColorOptionIdFieldName }
      fecesColorOptions={ fecesColorOptions }
      isFecesColorOptionsLoading={ isFecesColorOptionsLoading }
      fecesColorOptionIdHasValidation={ fecesColorOptionIdHasValidation }
      fecesColorOptionIdValidationReason={ fecesColorOptionIdValidationReason }
      fecesSmellOptionId={ fecesSmellOptionId }
      fecesSmellOptionIdFieldName={ fecesSmellOptionIdFieldName }
      fecesSmellOptions={ fecesSmellOptions }
      isFecesSmellOptionsLoading={ isFecesSmellOptionsLoading }
      fecesSmellOptionIdHasValidation={ fecesSmellOptionIdHasValidation }
      fecesSmellOptionIdValidationReason={ fecesSmellOptionIdValidationReason }
      fecesAspectOptionId={ fecesAspectOptionId }
      fecesAspectOptionIdFieldName={ fecesAspectOptionIdFieldName }
      fecesAspectOptions={ fecesAspectOptions }
      isFecesAspectOptionsLoading={ isFecesAspectOptionsLoading }
      fecesAspectOptionIdHasValidation={ fecesAspectOptionIdHasValidation }
      fecesAspectOptionIdValidationReason={ fecesAspectOptionIdValidationReason }
      fecesConsistencyOptionId={ fecesConsistencyOptionId }
      fecesConsistencyOptionIdFieldName={ fecesConsistencyOptionIdFieldName }
      fecesConsistencyOptions={ fecesConsistencyOptions }
      isFecesConsistencyOptionsLoading={ isFecesConsistencyOptionsLoading }
      fecesConsistencyOptionIdHasValidation={ fecesConsistencyOptionIdHasValidation }
      fecesConsistencyOptionIdValidationReason={ fecesConsistencyOptionIdValidationReason }
      macroscopicParasitesFieldName={ macroscopicParasitesFieldName }
      macroscopicParasites={ macroscopicParasites }
      macroscopicParasitesHasValidation={ macroscopicParasitesHasValidation }
      macroscopicParasitesValidationReason={ macroscopicParasitesValidationReason }
      resultFieldName={ resultFieldName }
      result={ result }
      resultHasValidation={ resultHasValidation }
      resultValidationReason={ resultValidationReason }
      obsFieldName={ obsFieldName }
      obs={ obs }
      shouldDisable={ shouldDisable }
      handleChange={ handleChange }
      handleBlur={ handleBlur } 
      parasitologicalFreshFecesResult={parasitologicalFreshFecesResult}
      checkdState={checkdState}
      checkboxes={checkboxes}
      multiBoxChange={multiBoxChange}
      handleCheckedResultChange={handleCheckedResultChange}
      />
  </FlatPanelMaterial>

ParasitologicalFreshFecesPanel.defaultProps = defaultProps;
ParasitologicalFreshFecesPanel.propTypes = propTypes;

export default ParasitologicalFreshFecesPanel;