import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import {
  required,
  greaterThan
  } from '../../assets/js/validationFunctions';

import {
  commonReportRules,
  erythrogramRules
  } from '../../assets/js/validationRules';

const validation = validated( props => {

  const {
    erythrocytes,
    corpuscularVolume,
    hemoglobiometry,
    erythrogramObs,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const { mchcMax } = props.data.refs;
  const { calcMCHC } = props.methods
  const { isUpdate, completeValidation } = props.data;

  let fields = [
    'erythrocytes',
    'corpuscularVolume',
    'hemoglobiometry',
    'erythrogramObs',
    'requestExamState',
    'vetSignerId'
  ];

  const commonValidation = commonReportRules(
    vetSignerId, requestExamState );

  const mchc = calcMCHC();

  const fieldNames = {
    erythrocytesName:      'erythrocytes',
    corpuscularVolumeName: 'corpuscularVolume',
    hemoglobiometryName:   'hemoglobiometry'
  };

  const erythrogramValidation = erythrogramRules( {
    erythrocytes: erythrocytes,
    corpuscularVolume: corpuscularVolume,
    hemoglobiometry: hemoglobiometry,
    erythrogramObs: erythrogramObs,
    mchc: mchc,
    mchcMax: mchcMax,
    isUpdate: isUpdate,
    fieldNames: fieldNames,
    completeValidation: completeValidation
    } );

  const validations = {
    ...commonValidation,
    ...erythrogramValidation
    };

  return { fields, validations };

} );

export default validation;