import React, { Component } from "react";
import Dashboard from "../Dashboard";

class DashboardSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Dashboard />;
  }
}

export default DashboardSection;
