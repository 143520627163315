import React from "react";
import update from "immutability-helper";

const FocusManagerConfig = (config, initialFocusName) => {
  return WrappedComponent => {
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          config: config
        };
      }

      componentDidMount() {
        let element = this.getElementByName(initialFocusName);
        let code = setInterval(() => {
          element.ref && element.ref.focus();
        }, 200);

        setTimeout(() => {
          clearInterval(code);
        }, 2500);
      }

      handleFocus = (name, event) => {
        let target = this.getTargetByElementNameAndKeyCode(name, event.keyCode);

        if (!target) return;

        let element = this.getElementByName(target);

        if (!element) return;

        setTimeout(() => element.ref.focus(), 30);
      };

      setRef = element => {
        if (!element) return;

        let { config } = this.state;

        config = config.map(current => {
          if (current.name === element.name) current.ref = element;
          return current;
        });

        this.setState(state => update(state, { config: { $set: config } }));
      };

      getTargetByElementNameAndKeyCode = (name, keyCode) => {
        let element = this.state.config
          .filter(current => current.name === name)
          .pop();

        if (!element) return;

        element = element.targets
          .filter(current => current.keyCode === keyCode)
          .pop();

        return element ? element.target : null;
      };

      getElementByName = name => {
        return this.state.config.filter(current => current.name === name).pop();
      };

      render() {
        return (
          <WrappedComponent
            handleFocus={this.handleFocus}
            setRef={this.setRef}
            {...this.props}
          />
        );
      }
    };
  };
};
export default FocusManagerConfig;
