import React from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import refetchConnect from "./../../api-connector";
import { connect as reduxConnect } from "react-redux";
import { FunctionUtil } from "./../../useful";

import FadeIn from "react-fade-in";
import debounce from "lodash/debounce";

import LVTable from "./../../old_components/LVTable";
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import StatusCircle from "./../StatusCircle";
import { ACCESS_PROFILES } from "../../assets/js/Consts";
import { connectListWithEndpoint } from "./requests";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";

const tableColumns = [
  {
    id: "status",
    Header: "Ativo",
    filterable: false,
    Cell: (data) => <StatusCircle status={data.original.status} />,
    width: 80,
  },
  {
    id: "id",
    Header: "Código",
    sortable: true,
    accessor: "id",
    width: 80,
  },
  {
    id: "name",
    Header: "Nome",
    sortable: true,
    accessor: "name",
  },
];

const SpecieList = ({ user, objects, history, access_profile }) => {
  const filter = (state, instance) => {
    const search = {};
    state.filtered.map((q) => (search[q.id] = q.value));
    history.push({ search: `?${queryString.stringify(search)}` });
  };

  const onTableDoubleClick = (currentId, state, rowInfo, column) => {
    const id = rowInfo.original.id;
    history.push(`/especies/${id}`);
  };

  const shouldDisableAll = () => {
    const condtions = [
      access_profile == ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
      access_profile == ACCESS_PROFILES.CLINIC_USER,
      access_profile == ACCESS_PROFILES.UNIT_USER,
      access_profile == ACCESS_PROFILES.UNIT_ADMINISTRATOR,
    ];

    return condtions.reduce((acc, condition) => acc || condition);
  };

  return (
    <FadeIn>
      <PanelMaterial>
        <PanelMaterialTop title={"Espécies"}>
          <ButtonSaveMaterial
            right
            title="Nova Espécie"
            type="highlight"
            onClick={() => {
              history.push("/especies/nova");
            }}
            position="top"
            disabled={shouldDisableAll()}
          />
        </PanelMaterialTop>
        <PanelMaterialBody>
          <LVTable
            filterable
            manual
            onFetchData={debounce(filter, 500)}
            data={objects.value ? objects.value.data : []}
            columns={tableColumns}
            loading={objects.pending}
            onDoubleClickRow={onTableDoubleClick}
          />
        </PanelMaterialBody>
      </PanelMaterial>
    </FadeIn>
  );
};

const connectWithEndpoint = refetchConnect(connectListWithEndpoint);
const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);

export default FunctionUtil.compose(
  connectWithRedux,
  connectWithEndpoint
)(SpecieList);
