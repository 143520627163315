import connect from "../../api-connector";

const connectWithEndpoint = connect((props) => {
  return {
    search: (filters, page, pageSize, sorted, then) => ({
      objects: {
        url:
          "/animals?" +
          `id=${filters.id}` +
          `&name=${filters.name}` +
          `&customer=${filters.customer}` +
          `&customerId=${filters.customerId}` +
          `&owner=${filters.owner}` +
          `&species=${filters.species}` +
          `&breed=${filters.breed}` +
          `&externalCode=${filters.externalCode}` +
          `&microchipCode=${filters.microchipCode}` +
          `&cpf=${filters.cpf}` +
          `&phone=${filters.phone}` +
          `&sexId=${filters.sexId}` +
          `&page=${page}` +
          `&totalPerPage=${pageSize}` +
          `&sort=${sorted[0].id}` +
          `&desc=${sorted[0].desc}`,
        method: "GET",
        refreshing: true,
        then: then,
      },
    }),
  };
});

export default connectWithEndpoint;
