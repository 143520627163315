import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import {
  required
  } from '../../assets/js/validationFunctions';

import {
  commonReportRules,
  corpuscularVolumeRules
} from '../../assets/js/validationRules';

const validation = validated( props => {

  const {
    reticulocyteByField,
    erythrocyteCount,
    corpuscularVolume,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const {completeValidation} = props.data;

  let fields = [
    'reticulocyteByField',
    'erythrocyteCount',
    'corpuscularVolume',
    'text',
    'vetSignerId',
    'requestExamState'
  ];

  const corpuscularVolumeValidation = corpuscularVolumeRules( 'corpuscularVolume', corpuscularVolume );

  const commonValidation =
    commonReportRules( vetSignerId, requestExamState );

  const validations = {
    ...commonValidation,
    ...corpuscularVolumeValidation,
    reticulocyteByField: {
      rules: completeValidation ? [
        [ required, reticulocyteByField, 'Obrigatório' ]
      ] : []
    },
    erythrocyteCount: {
      rules: completeValidation ? [
        [ required, erythrocyteCount, 'Obrigatório' ]
      ] : []
    },
    corpuscularVolume: {
      rules: completeValidation ? [
        [ required, corpuscularVolume, 'Obrigatório' ]
      ] : []
    }
  };

  return { fields, validations };

} );

export default validation;