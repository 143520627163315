import React from "react";

/** Internal */

import { ACCESS_PROFILES } from "./../../assets/js/Consts";
import AccessManager from "./../../old_components/AccessManager";
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import PanelMaterialBottom from "./../PanelMaterialBottom";
import { Row, Col } from "./../../components/grid";
import SelectMaterial from "./../SelectMaterial";
import { YES_OR_NO } from "./../../assets/js/Consts.js";
import NumberInputMaterial from "./../NumberInputMaterial";
// import {ArrayUtil} from "./../../useful;
import { ArrayUtil } from "../../useful";

/** External */
import ReactTable from "react-table-v6";
import update from "immutability-helper";

const PricingTableListView = ({ data, methods }) => {
  const {
    pricingTableResponseWithId,
    submitData,
    allExamPlaces,
    examValues,
    shouldDisableTableForm
  } = data;

  let PLACES = allExamPlaces && allExamPlaces.value ? allExamPlaces.value : [];

  PLACES = [{ id: 0, name: "NENHUM" }].concat(PLACES);






  const pricingTableColumns = (isClinicUser)  => {

    const adminColumns = [
      {
        Header: "Prazo (Dias)",
        maxWidth: 120,
        className: "centeredText",
        filterable: true,
        Cell: data => {
          return (
            <NumberInputMaterial
              value={examValues[data.original.pivot.id].deadline_in_days}
              name={data.original.pivot.id + ".deadline_in_days"}
              md={12}
              fit
              disabled={shouldDisableTableForm}
              allowNegative={false}
              isNumericString={true}
              format="###"
              fieldChange={methods.fieldChangeTable}
            />
          );
        }
      },
      {
        Header: "Lugar do Exame",
        className: "centeredText",
        filterable: true,
        Cell: data => {
          return (
            <SelectMaterial
              options={PLACES}
              disabled={shouldDisableTableForm}
              value={examValues[data.original.pivot.id].exam_place_id}
              onChange={event =>
                methods.fieldChangeTable(
                  data.original.pivot.id + ".exam_place_id",
                  event
                )
              }
              fit
            />
          );
        }
      },
      {
        Header: "Ativo",
        className: "centeredText",
        filterable: true,
        maxWidth: 120,
        Cell: data => {
          return (
            <SelectMaterial
              options={YES_OR_NO}
              disabled={shouldDisableTableForm}
              name={data.original.pivot.id + ".is_active"}
              value={examValues[data.original.pivot.id].is_active}
              onChange={event =>
                methods.fieldChangeTable(
                  data.original.pivot.id + ".is_active",
                  event
                )
              }
              fit
            />
          );
        }
      }
    ];

    const defaultColumns = [
      
        {
          Header: "Código",
          accessor: "id",
          maxWidth: 60,
          className: "centeredText",
          filterable: false
        },
        {
          Header: "Tipo de Exame",
          maxWidth:  isClinicUser ? 300 : "null",
          className: "centeredText",
          accessor: "exam_type.name",
          filterable: true
        },
        
        {
          Header: "Nome do Exame",
          accessor: "name",
          maxWidth:  isClinicUser ? 300 : "null",
          className: "centeredText",
          filterable: true,
          Cell: data => <div className="cut-text">{data.original.name}</div>
        },
        {
          Header: "Valor (R$)",
          maxWidth:  isClinicUser ? 300 : 120,
          className: "centeredText",
          filterable: true,
          Cell: data => {
            return (
              <NumberInputMaterial
                value={examValues[data.original.pivot.id].price}
                name={data.original.pivot.id + ".price"}
                md={12}
                allowNegative={false}
                decimalScale={2}
                isNumericString={true}
                fit
                disabled={shouldDisableTableForm}
                fieldChange={methods.fieldChangeTable}
              />
            );
          } 
        },
      
    ];
    return isClinicUser ? {$set: defaultColumns} : {$set: [...defaultColumns, ...adminColumns]};

    
  }

  const TABLE_EXAMS =
    pricingTableResponseWithId && pricingTableResponseWithId.value
      ? pricingTableResponseWithId.value.exams
      : [];

  let config = update(
    {
      defaultPageSize: 5,
      defaultSorted: [{ id: "exam_type.name", desc: false }],
      pageSize: 15,
      resizable: false,
      showPagination: true,
      noDataText: "Nenhum registro".toUpperCase(),
      loadingText: "Carregando",
      className: "-striped -highlight",
      previousText: "Anterior",
      nextText: "Próximo",
      pageText: "Página",
      ofText: "de",
      rowsText: "linhas",
      columns: [],
      showPageSizeOptions: false,
      pageSizeOptions: [5, 10, 15, 20, 25, 50, 100]
    },
    {
      data: {
        $set: TABLE_EXAMS
      },
      loading: {
        $set:
          data.pricingTableResponseWithId &&
          data.pricingTableResponseWithId.pending
      },
      showPagination: {
        $set: false
      },
      pageSize: {
        $set: (TABLE_EXAMS.length ===0 ) ? 5 : TABLE_EXAMS.length
      },
      columns: pricingTableColumns(shouldDisableTableForm),
    }
  );

  

  const PRICING_TABLES =
    data.allPricingTables && data.allPricingTables.value
      ? data.allPricingTables.value
      : [];

  return (
    <React.Fragment>
      <PanelMaterial>
        <PanelMaterialTop title={"Tabela de Preço"}>
          <AccessManager allowed={[ACCESS_PROFILES.UNIT_ADMINISTRATOR]}>
            <ButtonDefaultMaterial
              title="Nova Tabela"
              type="highlight"
              position="top"
              right={true}
              onClick={methods.handleShowPricingTableModal}
            />
          </AccessManager>
          <AccessManager allowed={[ACCESS_PROFILES.UNIT_ADMINISTRATOR]}>
            <ButtonDefaultMaterial
              title="Novo Local de Exame"
              type="highlight"
              position="top"
              right={true}
              onClick={methods.handleShowExamPlaceModal}
            />
          </AccessManager>
        </PanelMaterialTop>
        <PanelMaterialBody>
          <Row>
            <SelectMaterial
              title="Selecione uma tabela"
              md={5}
              name="examType"
              options={PRICING_TABLES}
              value={data.shouldDisableTableSelect ? null : data.fields.selectedPricingTable}
              searchable={true}
              onChange={event =>
                methods.fieldChangePricingTable("selectedPricingTable", event)
              }
              disabled={data.shouldDisableTableSelect}
            />
          </Row>
          <Row className="pb-20">
            <Col md={12}>
              <ReactTable {...config}
              filterable
              // defaultFilterMethod={({ id, value }, row) =>
              // row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true}
              defaultFilterMethod={methods.tableFilter}
              />
              
            </Col>
          </Row>
        </PanelMaterialBody>
        <PanelMaterialBottom>
          <ButtonSaveMaterial
            type="highlight"
            title={submitData.btnTitle}
            right={true}
            onClick={() => methods.handleSubmit(data.fields)}
            requesting={submitData.pending}
            disabled={submitData.pending}
            requestingTitle={submitData.placeholder}
          />
        </PanelMaterialBottom>
      </PanelMaterial>
    </React.Fragment>
  );
};

export default PricingTableListView;
