import React from 'react';
import PropTypes from 'prop-types';

/**
 * Character that a string must replace
 */
const REPLACEABLE = '#'; 

/**
 * Variable character will be removed if not replaced
 */
const VARIABLE = '&';

/**
 * @author Victor Heringer
 *
 * Checks if there is enought room at pattern to fit the values
 *
 * @prop {String} pattern
 * @prop {String} value
 */
const checkSlots = (pattern, value) => {

  const totalReplaceable = (pattern.match(/#/g) || []).length;

  if((value.length - totalReplaceable) < 0) {
    throw 'Mask has few values than given string to replace';
  } 
}

/**
 * @author Victor Heringer
 * 
 * Masqierade a string
 * 
 * @prop {String} pattern
 * @prop {String} value
 */
const masquerade = (pattern, value) => {
  checkSlots(pattern, value);

  let masked  = '';
  let pointer = 0;

  const replacebles = (pattern.match(/\#/g) || []).length;

  if(replacebles == value.length) {
    pattern = pattern.replace(/\&/g, '');
  }

  for (var i = 0; i < pattern.length; i++) {
    if (pattern.charAt(i) == REPLACEABLE || pattern.charAt(i) == VARIABLE) {
      masked = masked + value[pointer];
      pointer++;
    }
    else {
      masked = masked + pattern[i];
    }
  }

  return masked.replace(/\&/g, '');
}

/**
 * @author Victor Heringer
 * 
 * Adds a mask to a string
 * 
 * @prop {String} pattern
 * @prop {String} value
 */
const Mask = ({ pattern, value }) => {
  return <React.Fragment>{ masquerade(pattern, value) }</React.Fragment>;
}

Mask.propTypes = {
  pattern: PropTypes.string,
  value: PropTypes.string
};

export default Mask;