import * as Yup from "yup";
import { YupLocale } from "./index";

const API_URL = process.env.REACT_APP_PROXY;

Yup.setLocale(YupLocale);

// Placeholder method.
Yup.addMethod<Yup.StringSchema>(Yup.string, "isSampleUnique", function () {
  return this.test(
    "is-unique",
    `Código já utilizado.`,
    // @ts-ignore
    function (value: string | undefined | null, context: object) {
      if (value?.length === 0) return Promise.resolve(false);
      return fetch(`${API_URL}/is-unique-sample/0/${value}`)
        .then((response) => response.json())
        .then((body) => (body.isUnique === true ? true : false));
    }
  );
});

export { Yup };
