import React from 'react';

/**
 * External
 */
import PropTypes from 'prop-types';

/**
* Internal
*/
import FlatPanelMaterial from './../PanelMaterial';
import NumberInputWithReference2 from './../NumberInputWithReference2';
import { Row, Col } from './../../components/grid';

const propTypes = {};

/**
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const NumberInputWithReferencePanel2 = ( {
  title,
  fieldTitle,
  value,
  fieldName,
  shouldDisable,
  hasValidation,
  validationReason,
  handleChange,
  handleBlur,
  valueMin,
  valueMax,
  decimalScale,
  unit
} ) =>
    <FlatPanelMaterial title={ title } >
      <Row>
        <Col md={ 12 } >
          <NumberInputWithReference2
            fieldTitle={ fieldTitle }
            value={ value }
            fieldName={ fieldName }
            shouldDisable={ shouldDisable }
            hasValidation={ hasValidation }
            validationReason={ validationReason }
            handleChange={ handleChange }
            handleBlur={ handleBlur }
            valueMin={ valueMin }
            valueMax={ valueMax }
            decimalScale={ decimalScale }
            unit={ unit } />
        </Col>
      </Row>
  </FlatPanelMaterial>

NumberInputWithReferencePanel2.propTypes = propTypes;

export default NumberInputWithReferencePanel2;