import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import InputValidationMsg from "./../InputValidationMsg";

/**
 * External
 */
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Autosuggest from "react-autosuggest";
import classNames from "classnames";
import { cloneDeep } from "lodash";

/**
 * Assets
 */
import "./index.css";

/**
 * @author Alessandro Bastos Grandini
 *
 * Decides wether suggestion should be rendered
 *
 * @param  {String}  name The suggestion name
 *
 * @return {boolean} True or false
 *
 */
const shouldRenderSuggestions = name => name && name.trim().length > 0;

/**
 * @author Alessandro Bastos Grandini
 *
 * Extracts suggestion value
 *
 * @param  {Object}  suggestion The suggestion object
 *
 * @return {String} The name of the suggestion
 *
 */
const getSuggestionValue = suggestion => {
  return suggestion.name;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Filters suggestions
 *
 * @param  {Array}    suggestions Array of suggestion objects
 * @param  {function} suggestionsFilter Filter for suggestions
 * @param  {Array}    tags Array of tags
 *
 * @return {Array} The resulting suggestions
 *
 */
const filteredSuggestions = (suggestions, suggestionsFilter, tags) => {
  if (suggestionsFilter && suggestions) {
    const filter = suggestions => {
      return suggestionsFilter(suggestions, tags);
    };

    return suggestions.filter(filter);
  }

  return suggestions;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Default props object
 *
 */
const defaultProps = {
  md: 12,
  type: "text",
  title: "",
  disabled: false,
  validationReason: "",
  hasValidation: false,
  validationType: "danger",
  hasValidationRecoil: true,
  suggestionsFilter: undefined,
  inputGroup: false
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Prop types object
 *
 */
const propTypes = {
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  validationReason: PropTypes.string,
  hasValidation: PropTypes.bool,
  validationType: PropTypes.string,
  hasValidationRecoil: PropTypes.bool,
  suggestionsFilter: PropTypes.func,
  inputGroup: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  updateTags: PropTypes.func
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for tags input
 *
 */
const TagsInputMaterial2 = ({
  title,
  fit,
  tags,
  name,
  block,
  onBlur,
  onChange,
  disabled,
  inputGroup,
  suggestions,
  hasValidation,
  validationType,
  hasValidationRecoil,
  validationReason,
  suggestionsFilter,
  containerClassName,
  inputGroupClassName,
  placeholder
}) => {
  /**
   * @author Alessandro Bastos Grandini
   *
   * Updates tag state
   *
   * @param  {Array}  newTags Array of tag names
   *
   */
  const handleChange = newTags => {
    if (onChange) {
      const filteredTags = newTags.filter(tag => {
        for (const suggestion of suggestions) {
          if (suggestion.name === tag) {
            return true;
          }
        }

        return false;
      });
      onChange(name, filteredTags);
    }
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * Handles blur
   *
   * @param  {Array}  newTags Array of tag names
   *
   */
  const handleBlur = () => {
    if (onBlur) {
      const fakeEvent = {
        target: {
          name: name
        }
      };

      onBlur(fakeEvent);
    }
  };

  /**
   * Input group class
   * @type {Object}
   */
  const inputGroupClass = classNames(
    { inputGroupClassName },
    { "input-group": inputGroup },
    { "form-group": !inputGroup },
    { "d-block": block },
    { fit: fit }
  );

  /**
   * Container input class
   * @type {Object}
   */
  const inputContainerClass = classNames(containerClassName);

  /**
   * @author Alessandro Bastos Grandini
   *
   * Renders autosuggest input
   *
   * @param  {Object}  obj Object with params
   *
   * @return {Obj} A configured autosuggest component
   *
   */
  const autosuggestRenderInput = ({ addTag, ...props }) => {
    const { disabled } = props;

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    let searchValue = (props.value && props.value.trim().toLowerCase()) || "";

    searchValue = searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    let suggestionsWithoutAccents = cloneDeep(suggestions);
    suggestionsWithoutAccents = suggestionsWithoutAccents.map(suggestion => {
      suggestion.name = suggestion.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return suggestion;
    });

    let finalSuggestions = [];

    if (!disabled && suggestionsWithoutAccents && suggestionsFilter && tags) {
      suggestionsWithoutAccents = filteredSuggestions(
        suggestionsWithoutAccents,
        suggestionsFilter,
        tags
      ).filter(suggestion => {
        const lowercaseName = suggestion.name.toLowerCase();
        return lowercaseName.includes(searchValue);
      });
    }

    let name = "";
    suggestionsWithoutAccents.forEach(filtered => {
      suggestions.forEach(suggestion => {
        name = suggestion.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        filtered.name == name && finalSuggestions.push(suggestion);
      });
    });

    const inputProps = {
      ...props,
      placeholder: placeholder ? placeholder : "",
      onChange: handleOnChange,
      onBlur: handleBlur
    };

    return (
      <Autosuggest
        ref={props.ref}
        suggestions={finalSuggestions}
        shouldRenderSuggestions={shouldRenderSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={suggestion => <span>{suggestion.name}</span>}
        inputProps={inputProps}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.name);
        }}
        onSuggestionsClearRequested={() => {}}
        onSuggestionsFetchRequested={() => {}}
      />
    );
  };

  /**
   * Validation Component
   * @type {Object}
   */
  const validation = (
    <InputValidationMsg
      type={validationType}
      visible={hasValidation}
      message={validationReason}
      top={hasValidationRecoil ? "-18px" : ""}
    />
  );

  const disabledClass = disabled ? " disabled-tag " : "";

  const style = { paddingBottom: hasValidationRecoil ? "20px" : "" };

  return (
    <React.Fragment>
      <div style={style} className={inputContainerClass + disabledClass}>
        {title && <label className="input-label">{title}</label>}
        <TagsInput
          value={tags}
          disabled={disabled}
          onChange={handleChange}
          renderInput={autosuggestRenderInput}
        />
      </div>
      {validation}
    </React.Fragment>
  );
};

TagsInputMaterial2.defaultProps = defaultProps;
TagsInputMaterial2.propTypes = propTypes;

export default TagsInputMaterial2;
