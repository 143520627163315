import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import HemocytozoaResearchPanel from "./../HemocytozoaResearchPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";

const HemocytozoaResearchReportFormView = ({
  data: {
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    shouldSendEmail,
    checkState,
    checkboxes,
    result
  },
  methods: {
    $field,
    fieldChange,
    onSubmit,
    handleSendReportEmail,
    handleChange,
    handleBlur,
    cancelReport,
    stopReport,
    multiCheckboxChange,
    handleResultChange,
  }
}) => (
  <ReportPanelMaterial
    title="Pesquisa de Hemocitozoários"
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <HemocytozoaResearchPanel
          resultFieldName={"result"}
          handleResultChange={handleResultChange}
          result={result}
          resultHasValidation={$validation.result.show}
          resultValidationReason={$validation.result.error.reason}
          obsFieldName={"obs"}
          obs={fields.obs}
          obsHasValidation={$validation.obs.show}
          obsValidationReason={$validation.obs.error.reason}
          handleChange={handleChange}
          handleBlur={handleBlur}
          shouldDisable={disableAll}
          checkboxes={checkboxes}
          multiCheckboxChange={multiCheckboxChange}
          checkdState={checkState}

        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default HemocytozoaResearchReportFormView;
