import React from 'react';

/**
 * Internal
 */
import FlatPanelMaterial from './../FlatPanelMaterial';
import ParvoCoronaContent from './../ParvoCoronaContent';


const ParvoCoronaPanel = ( {
    title,
    materialId,
    isParvovirus,
    isCoronavirus,
    materialIdFieldName,
    isParvovirusFieldName,
    isCoronavirusFieldName,
    materialIdHasValidation,
    isParvovirusHasValidation,
    isCoronavirusHasValidation,
    materialIdValidationReason,
    isParvovirusValidationReason,
    isCoronavirusValidationReason,
    obsFieldName,
    obs,
    shouldDisable,
    handleChange,
    handleBlur
} ) =>
  <FlatPanelMaterial title="Sorologia de Parvo + Coronavírus">
    <ParvoCoronaContent
      title={ title }
      materialId={ materialId }
      isParvovirus={ isParvovirus }
      isCoronavirus={ isCoronavirus }
      materialIdFieldName={ materialIdFieldName }
      isParvovirusFieldName={ isParvovirusFieldName }
      isCoronavirusFieldName={ isCoronavirusFieldName }
      materialIdHasValidation={ materialIdHasValidation }
      isParvovirusHasValidation={ isParvovirusHasValidation }
      isCoronavirusHasValidation={ isCoronavirusHasValidation }
      materialIdValidationReason={ materialIdValidationReason }
      isParvovirusValidationReason={ isParvovirusValidationReason }
      isCoronavirusValidationReason={ isCoronavirusValidationReason }
      obsFieldName={ obsFieldName }
      obs={ obs }
      shouldDisable={ shouldDisable }
      handleChange={ handleChange }
      handleBlur={ handleBlur } />
  </FlatPanelMaterial>

export default ParvoCoronaPanel;