import React, { Component } from "react";

/** External */
import { Switch, Route, Redirect } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import jwt_decode from "jwt-decode";

/** Internal */
import MainSection from "./../MainSection";
import Login from "./../Login";
import { verifyToken } from "./../../redux/actions/";
import { ACCESS_PROFILES } from "../../assets/js/Consts";
import GetUserTokenBasedOnEnv from "./../../services/get-user-token-based-on-env";

/**
 * @author Alessandro Bastos Grandini
 *
 * Main App component
 */
class App extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      notificationSystem: React.createRef(),
    };
    this.notificationSystem = null;
    this.renderLogin = this.renderLogin.bind(this);
    this.renderMainSection = this.renderMainSection.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.clearNotifications = this.clearNotifications.bind(this);
    this.props.dispatch(verifyToken());
  }

  componentDidMount() {
    this.setState((prevState) => {
      prevState.notificationSystem = this.notificationSystem;
      return prevState;
    });
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Creates a new notificaion
   *
   * @param {Object} options Params to create a notification
   */
  addNotification(options) {
    this.setState((prevState, props) => {
      prevState.notificationSystem.clearNotifications();
      prevState.notificationSystem.addNotification(options);
      return prevState;
    });
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Clear all notifications
   *
   * @return {Object}
   */
  clearNotifications() {
    this.setState((prevState, props) => {
      prevState.notificationSystem.clearNotifications();
      return prevState;
    });
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Render the login route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   *
   */
  renderLogin(routeProps) {
    let notificationSystem = {
      add: this.addNotification,
      clear: this.clearNotifications,
    };
    let location = "/";
    if (this.props.user && this.props.user.access_profile) {
      if (
        this.props.user.access_profile === ACCESS_PROFILES.GENERAL_ADMINISTRATOR
      ) {
        location = "/dashboard";
      }
    }
    if (this.checkToken()) {
      return <Redirect to={location} />;
    }
    return <Login {...routeProps} notificationSystem={notificationSystem} />;
  }

  checkToken = () => {
    let token = GetUserTokenBasedOnEnv();
    let user = token ? jwt_decode(token) : null;

    return user ? true : false;
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * Render the dashboard route if is logged in
   *
   * @param  {Object} routeProps Propos for the route
   *
   * @return {Object}
   */
  renderMainSection(routeProps) {
    let location = this.props.location;
    let notificationSystem = {
      add: this.addNotification,
      clear: this.clearNotifications,
    };

    if (this.checkToken()) {
      return (
        <MainSection
          {...routeProps}
          logout={this.props.logout}
          notificationSystem={notificationSystem}
        />
      );
    }

    let to = { pathname: "/login", state: { from: location } };
    return <Redirect to={to} />;
  }

  render() {
    const currentRoute = this.props.location.pathname;


    return (
      <div>
        <NotificationSystem
          ref={(notificationSystem) =>
            (this.notificationSystem = notificationSystem)
          }
        />
        {this.state.notificationSystem &&
        this.state.notificationSystem.addNotification ? (
          <Switch>
            <Route exact path="/login" render={this.renderLogin} />
            <Route path="/" render={this.renderMainSection} />
          </Switch>
        ) : (
          <h1> carregando </h1>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    is_authenticated: state.userReducer.is_authenticated,
  };
};

export default withRouter(connect(mapStateToProps)(App));
