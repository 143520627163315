import FocusManagerKeys from "./focus-manager-keys.js";

export const hemogramConfigOption = {
  initialFocus: "erythrocytes",
  config: [
    {
      name: "erythrocytes",
      ref: null,
      targets: [
        { keyCode: FocusManagerKeys.ENTER, target: "corpuscularVolume" }
      ]
    },
    {
      name: "corpuscularVolume",
      ref: null,
      targets: [{ keyCode: FocusManagerKeys.ENTER, target: "hemoglobiometry" }]
    },
    {
      name: "hemoglobiometry",
      ref: null,
      targets: [{ keyCode: FocusManagerKeys.ENTER, target: "globalLeukometry" }]
    },
    {
      name: "globalLeukometry",
      ref: null,
      targets: [{ keyCode: FocusManagerKeys.ENTER, target: "platelets" }]
    },
    {
      name: "platelets",
      ref: null,
      targets: [{ keyCode: FocusManagerKeys.SHIFT, target: "globalLeukometry" }]
    }
  ]
};
