export const EXAM_URL_BY_ID = function (id) {
  switch (id) {
    case EXAM_ID_BY_NAME["BABESIA"]:
      return "babesia";
    case EXAM_ID_BY_NAME["QUALITATIVE_ANAPLASM_PCR_REAL_TIME"]:
      return "anaplasma-qualitativo-pcr-real-time";
    case EXAM_ID_BY_NAME["QUALITATIVE_FIV_PCR_REAL_TIME"]:
      return "fiv-qualitativo-pcr-real-time";
    case EXAM_ID_BY_NAME["QUALITATIVE_FELV_PCR_REAL_TIME"]:
      return "felv-qualitativo-pcr-real-time";
    case EXAM_ID_BY_NAME["QUALITATIVE_MYCOPLASM_PCR_REAL_TIME"]:
      return "mycoplasma-qualitativo-pcr-real-time";
    case EXAM_ID_BY_NAME["DISTEMPER"]:
      return "cinomose";
    case EXAM_ID_BY_NAME["EHRLICHIA"]:
      return "ehrlichia";
    case EXAM_ID_BY_NAME["LEISHMANIOSE"]:
      return "leishmaniose";
    case EXAM_ID_BY_NAME["LEPTOSPIROSE"]:
      return "leptospirose";
    case EXAM_ID_BY_NAME["FIBRINOGEN"]:
      return "fibrinogenio";
    case EXAM_ID_BY_NAME["ASCITIC_FLUID"]:
      return "analise-liquido-ascitico";
    case EXAM_ID_BY_NAME["THORACIC_FLUID"]:
      return "analise-liquido-toracico";
    case EXAM_ID_BY_NAME["PHENOBARBITAL"]:
      return "fenobarbital";
    case EXAM_ID_BY_NAME["PLATELETS"]:
      return "contagem-plaquetas";
    case EXAM_ID_BY_NAME["RETICULOCYTE_COUNT"]:
      return "contagem-reticulocitos";
    case EXAM_ID_BY_NAME["ERYTHROGRAM"]:
      return "eritrograma";
    case EXAM_ID_BY_NAME["CORPUSCULAR_VOLUME"]:
      return "hematocrito";
    case EXAM_ID_BY_NAME["HEMOGRAM"]:
      return "hemograma";
    case EXAM_ID_BY_NAME["HEMOGRAM_WITH_HEMOPARASITOLOGICAL_RESEARCH"]:
      return "hemograma-pesquisa-hemoparasitologica";
    case EXAM_ID_BY_NAME["HEMOGRAM_WITH_HEMOCYTOZOA_RESEARCH"]:
      return "hemograma-pesquisa-hemocitozoarios";
    case EXAM_ID_BY_NAME["HEMOGRAM_WITH_BUFFY_COAT_RESEARCH"]:
      return "hemograma-pesquisa-em-capa-leucocitaria";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_COMPLETE_2"]:
      return "perfil-preoperatorio-completo-2";
    case EXAM_ID_BY_NAME["PROFILE_ENDOCRINOLOGICAL"]:
      return "perfil-endocrinologico";
    case EXAM_ID_BY_NAME["PROFILE_ROUTINE_3"]:
      return "perfil-rotina-3";
    case EXAM_ID_BY_NAME["PROFILE_ONCOLOGICAL"]:
      return "perfil-oncologico";
    case EXAM_ID_BY_NAME["LEUKOGRAM"]:
      return "leukograma";
    case EXAM_ID_BY_NAME["LENTZ_CORPUSCLE_RESEARCH"]:
      return "pesquisa-corpusculo-lentz";
    case EXAM_ID_BY_NAME["HEMOCYTOZOA_RESEARCH"]:
      return "pesquisa-hemocitozoarios";
    case EXAM_ID_BY_NAME["MICROFILARIAE_RESEARCH"]:
      return "pesquisa-microfilaria";
    case EXAM_ID_BY_NAME["HEMOCYTOZOA_SERIAL_RESEARCH"]:
      return "pesquisa-seriada-hemocitozoarios";
    case EXAM_ID_BY_NAME["COAGULATION_TEST"]:
      return "teste-coagulacao";
    case EXAM_ID_BY_NAME["CROSS_MATCH"]:
      return "teste-compatibilidade-sanguinea";
    case EXAM_ID_BY_NAME["FOUR_DX"]:
      return "4dx";
    case EXAM_ID_BY_NAME["DISTEMPER_PARVOVIRUS_IGG"]:
      return "cinomose-parvovirose-igg";
    case EXAM_ID_BY_NAME["DISTEMPER_PARVOVIRUS_IGM"]:
      return "cinomose-parvovirose-igm";
    case EXAM_ID_BY_NAME["HEARTWORM_DISEASE"]:
      return "dirofilariose";
    case EXAM_ID_BY_NAME["EHRLICHIA_CANIS"]:
      return "ehrlichia-canis";
    case EXAM_ID_BY_NAME["FIV_FELV"]:
      return "fiv-felv";
    case EXAM_ID_BY_NAME["GIARDIA"]:
      return "giardia";
    case EXAM_ID_BY_NAME["IGA"]:
      return "imunoglobulina-a";
    case EXAM_ID_BY_NAME["PIF"]:
      return "pif";
    case EXAM_ID_BY_NAME["COPROCULTURE"]:
      return "cultura-de-fezes";
    case EXAM_ID_BY_NAME["FUNGAL_CULTURE"]:
      return "cultura-fungos";
    case EXAM_ID_BY_NAME["CULTURE_ANTIBIOGRAM_AEROBIC"]:
      return "cultura-antibiograma-aerobios";
    case EXAM_ID_BY_NAME["CULTURE_ANTIBIOGRAM_ANAEROBIC"]:
      return "cultura-antibiograma-anaerobios";
    case EXAM_ID_BY_NAME["HEMOCULTURE"]:
      return "hemocultura";
    case EXAM_ID_BY_NAME["IMMUNE_MEDIATED_HEMOLYTIC_ANEMIA"]:
      return "anemia-hemolitica-imunomediada";
    case EXAM_ID_BY_NAME["DIRECT_MYCOLOGICAL_RESEARCH"]:
      return "micologico-direto";
    case EXAM_ID_BY_NAME["MALASSEZIA_RESEARCH"]:
      return "pesquisa-malassezia-sp";
    case EXAM_ID_BY_NAME["SPOROTHRIX_SCHENCKII"]:
      return "pesquisa-sporothrix-schenckii";
    case EXAM_ID_BY_NAME["UROCULTURE"]:
      return "urocultura";
    case EXAM_ID_BY_NAME["PARASITOLOGICAL_FECES_MIF"]:
      return "parasitologico-fezes-mif";
    case EXAM_ID_BY_NAME["PARASITOLOGICAL_FRESH_FECES"]:
      return "parasitologico-fezes-frescas";
    case EXAM_ID_BY_NAME["PARASITOLOGICAL_FECES_OPG"]:
      return "parasitologico-fezes-opg";
    case EXAM_ID_BY_NAME["ECTOPARASITES_RESEARCH"]:
      return "pesquisa-ectoparasitas";
    case EXAM_ID_BY_NAME["URINARY_BILIARY_CALCULATION"]:
      return "analise-calculo-urinario-biliar";
    case EXAM_ID_BY_NAME["EAS"]:
      return "eas";
    case EXAM_ID_BY_NAME["MICROALBUMINURIA"]:
      return "microalbuminuria";
    case EXAM_ID_BY_NAME["CORTISOL_CREATININE_RATIO"]:
      return "relacao-cortisol-creatinina";
    case EXAM_ID_BY_NAME["PROTEIN_CREATININE_RATIO"]:
      return "relacao-proteina-creatinina";
    case EXAM_ID_BY_NAME["HEMOGRAM_WITH_MICROFILARIAE_RESEARCH"]:
      return "hemograma-pesquisa-microfilaria";
    case EXAM_ID_BY_NAME["BLOOD_PROTEIN"]:
      return "proteinas-plasmaticas";
    case EXAM_ID_BY_NAME["PARVO_CORONA"]:
      return "parvovirus-coronavirus";
    case EXAM_ID_BY_NAME["URINARY_GGT"]:
      return "ggt-urinaria";
    case EXAM_ID_BY_NAME["CYTOLOGY"]:
      return "citologia";
    case EXAM_ID_BY_NAME["LIQUOR_ANALYSIS"]:
      return "analise-liquor";
    case EXAM_ID_BY_NAME["PROFILE_SMALL_ANIMALS"]:
      return "perfil-veterinario-animais-pequenos";
    case EXAM_ID_BY_NAME["PROFILE_LARGE_ANIMALS"]:
      return "perfil-veterinario-animais-grandes";
    case EXAM_ID_BY_NAME["PROFILE_SIMPLE"]:
      return "perfil-simples";
    case EXAM_ID_BY_NAME["PROFILE_PARASITOLOGICAL_MONITORING"]:
      return "perfil-acompanhamento-parasitologico";
    case EXAM_ID_BY_NAME["PROFILE_DOG_OBESITY_CHECK"]:
      return "perfil-cao-obeso";
    case EXAM_ID_BY_NAME["PROFILE_ELECTROLYTIC"]:
      return "perfil-eletrolitico";
    case EXAM_ID_BY_NAME["PROFILE_GERIATRIC"]:
      return "perfil-geriatrico";
    case EXAM_ID_BY_NAME["PROFILE_HEPATIC_SIMPLE"]:
      return "perfil-hepatico-simples";
    case EXAM_ID_BY_NAME["PROFILE_HEPATIC_COMPLETE"]:
      return "perfil-hepatico-completo";
    case EXAM_ID_BY_NAME["PROFILE_BONE_CHECK"]:
      return "perfil-osseo";
    case EXAM_ID_BY_NAME["PROFILE_PANCREATIC"]:
      return "perfil-pancreatico";
    case EXAM_ID_BY_NAME["PROFILE_ROUTINE"]:
      return "perfil-rotina";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_SIMPLE"]:
      return "perfil-pre-operatorio-simples";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_COMPLETE"]:
      return "perfil-pre-operatorio-completo";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_CANINE"]:
      return "perfil-pre-operatorio-canino";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_CANINE_2"]:
      return "perfil-pre-operatorio-canino-2";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_FELINE"]:
      return "perfil-pre-operatorio-felino";
    case EXAM_ID_BY_NAME["PROFILE_PREOPERATIVE_FELINE_2"]:
      return "perfil-pre-operatorio-felino-2";
    case EXAM_ID_BY_NAME["PROFILE_RENAL"]:
      return "perfil-renal";
    case EXAM_ID_BY_NAME["PROFILE_RENAL_2"]:
      return "perfil-renal-2";
    case EXAM_ID_BY_NAME["PROFILE_RENAL_3"]:
      return "perfil-renal-3";
    case EXAM_ID_BY_NAME["PROFILE_THYROID"]:
      return "perfil-tireoidiano";
    case EXAM_ID_BY_NAME["PROFILE_CARDIAC"]:
      return "perfil-cardiaco";
    case EXAM_ID_BY_NAME["PROFILE_SURGICAL"]:
      return "perfil-cirurgico";
    case EXAM_ID_BY_NAME["PROFILE_GASTROENTERITIS_BRONZE"]:
      return "perfil-gastroenterite-bronze";
    case EXAM_ID_BY_NAME["PROFILE_GASTROENTERITIS_SILVER"]:
      return "perfil-gastroenterite-prata";
    case EXAM_ID_BY_NAME["PROFILE_GASTROENTERITIS_GOLD"]:
      return "perfil-gastroenterite-ouro";
    case EXAM_ID_BY_NAME["PROFILE_HEMOPARASITES_BRONZE"]:
      return "perfil-hemoparasita-bronze";
    case EXAM_ID_BY_NAME["PROFILE_HEMOPARASITES_SILVER"]:
      return "perfil-hemoparasita-prata";
    case EXAM_ID_BY_NAME["PROFILE_HEMOPARASITES_GOLD"]:
      return "perfil-hemoparasita-ouro";
    case EXAM_ID_BY_NAME["PROFILE_CHECKUP"]:
      return "perfil-checkup";
    case EXAM_ID_BY_NAME["PROFILE_OBESITY"]:
      return "perfil-obeso";
    case EXAM_ID_BY_NAME["PROFILE_MORE_COMPLETE_HEPATIC"]:
      return "perfil-hepatico-mais-completo";
    case EXAM_ID_BY_NAME["PROFILE_COMPLETE_PARASITOLOGICAL_MONITORING"]:
      return "perfil-acompanhamento-parasitologico-completo";
    case EXAM_ID_BY_NAME["PROFILE_HYPERADRENAL_SUSPICIOUS"]:
      return "perfil-suspeito-hiperadreno";
    case EXAM_ID_BY_NAME["PROFILE_ANEMIC_1"]:
      return "perfil-anemico-1";
    case EXAM_ID_BY_NAME["PROFILE_RENAL_4"]:
      return "perfil-renal-4";
    case EXAM_ID_BY_NAME["PROFILE_ANEMIC_2"]:
      return "perfil-anemico-2";
    case EXAM_ID_BY_NAME["PROFILE_THYROID_MONITORING"]:
      return "perfil-acompanhamento-tireoidiano";
    case EXAM_ID_BY_NAME["PROFILE_CHECKUP2"]:
      return "perfil-checkup2";
    default:
      return "terceirizado";
  }
};

export const EXAM_TYPE_ID_BY_NAME = {
  BIOCHEMISTRY: 2,
  ENDOCRINOLOGY_CHEMILUMINESCENCE: 5,
  ENDOCRINOLOGY_RADIOIMMUNOASSAY: 6,
};

export const EXAM_TYPE_BY_ID = {
  2: "bioquimica",
  5: "endocrinologia-quimioluminescencia",
  6: "endocrinologia-radioimunoensaio",
};

/**
 * @var {Object}
 */
export const EXAM_ID_BY_NAME = {
  BABESIA: 1,
  DISTEMPER: 2,
  EHRLICHIA: 3,
  LEISHMANIOSE: 4,
  LEPTOSPIROSE: 5,
  URIC_ACID: 6,
  ALBUMIN: 7,
  ALT_TGP: 8,
  AMYLASE: 9,
  AST_TGO: 10,
  BILIRUBIN_AND_FRACTIONS: 11,
  CALCIUM: 12,
  IONIC_CALCIUM: 13,
  CHLORIDE: 14,
  TOTAL_CHOLESTEROL: 15,
  TOTAL_CHOLESTEROL_AND_FRACTIONS: 16,
  CREATININE: 17,
  CPK: 18,
  UREA: 19,
  IRON: 20,
  ALKALINE_PHOSPHATASE: 21,
  PHOSPHORUS: 22,
  FRUCTOSAMINE: 23,
  GGT: 24,
  GLUCOSE: 25,
  GLYCATED_HEMOGLOBIN: 26,
  LACTATE: 27,
  LIPASE: 28,
  IMMUNOREACTIVE_LIPASE: 29,
  MAGNESIUM: 30,
  PH: 31,
  POTASSIUM: 32,
  TOTAL_PROTEIN: 33,
  TOTAL_PROTEIN_AND_FRACTIONS: 34,
  SODIUM: 35,
  TRIGLYCERIDES: 36,
  FIBRINOGEN: 37,
  ASCITIC_FLUID: 38,
  THORACIC_FLUID: 39,
  CHEMILUMINESCENCE_HYPERSENSITIVE_ACTH: 40,
  CHEMILUMINESCENCE_CORTISOL: 41,
  CHEMILUMINESCENCE_CORTISOL_4H_AFTER_DEXAMETHASONE: 42,
  CHEMILUMINESCENCE_CORTISOL_8H_AFTER_DEXAMETHASONE: 43,
  CHEMILUMINESCENCE_CORTISOL_4H_AFTER_ACTH_STIMULI: 44,
  CHEMILUMINESCENCE_ERYTHROPOETIN: 45,
  CHEMILUMINESCENCE_ESTRADIOL: 46,
  CHEMILUMINESCENCE_PHENOBARBITAL: 47,
  CHEMILUMINESCENCE_HFS: 48,
  CHEMILUMINESCENCE_INSULIN: 49,
  CHEMILUMINESCENCE_LH: 50,
  CHEMILUMINESCENCE_PROGESTERONE: 51,
  CHEMILUMINESCENCE_PTH: 52,
  CHEMILUMINESCENCE_TOTAL_T3: 53,
  CHEMILUMINESCENCE_FREE_T4: 54,
  CHEMILUMINESCENCE_TOTAL_T4: 55,
  CHEMILUMINESCENCE_LEVOTHYROXIN_AFTER_TOTAL_T4: 56,
  CHEMILUMINESCENCE_TESTOSTERONE: 57,
  CHEMILUMINESCENCE_TSH: 58,
  RADIOIMMUNOASSAY_CORTISOL: 59,
  RADIOIMMUNOASSAY_CORTISOL_4H_AFTER_DEXAMETHASONE: 60,
  RADIOIMMUNOASSAY_CORTISOL_8H_AFTER_DEXAMETHASONE: 61,
  RADIOIMMUNOASSAY_CORTISOL_4H_AFTER_ACTH_STIMULI: 62,
  RADIOIMMUNOASSAY_ESTRADIOL: 63,
  RADIOIMMUNOASSAY_TOTAL_T3: 64,
  RADIOIMMUNOASSAY_FREE_T4: 65,
  RADIOIMMUNOASSAY_TOTAL_T4: 66,
  RADIOIMMUNOASSAY_LEVOTHYROXIN_AFTER_TOTAL_T4: 67,
  RADIOIMMUNOASSAY_FREE_T4_AFTER_DIALYSIS: 68,
  PLATELETS: 69,
  RETICULOCYTE_COUNT: 70,
  ERYTHROGRAM: 71,
  CORPUSCULAR_VOLUME: 72,
  HEMOGRAM: 73,
  HEMOGRAM_WITH_HEMOPARASITOLOGICAL_RESEARCH: 74,
  HEMOGRAM_WITH_HEMOCYTOZOA_RESEARCH: 75,
  HEMOGRAM_WITH_BUFFY_COAT_RESEARCH: 621,
  PROFILE_PREOPERATIVE_COMPLETE_2: 622,
  PROFILE_ENDOCRINOLOGICAL: 623,
  PROFILE_ROUTINE_3: 628,
  PROFILE_ONCOLOGICAL: 629,
  LEUKOGRAM: 76,
  LENTZ_CORPUSCLE_RESEARCH: 77,
  HEMOCYTOZOA_RESEARCH: 78,
  MICROFILARIAE_RESEARCH: 79,
  HEMOCYTOZOA_SERIAL_RESEARCH: 80,
  COAGULATION_TEST: 81,
  CROSS_MATCH: 82,
  IMMUNE_MEDIATED_HEMOLYTIC_ANEMIA: 83,
  FOUR_DX: 84,
  DISTEMPER_PARVOVIRUS_IGG: 85,
  DISTEMPER_PARVOVIRUS_IGM: 86,
  HEARTWORM_DISEASE: 87,
  EHRLICHIA_CANIS: 88,
  GIARDIA: 89,
  FIV_FELV: 90,
  IGA: 91,
  PIF: 92,
  COPROCULTURE: 93,
  FUNGAL_CULTURE: 94,
  CULTURE_ANTIBIOGRAM_AEROBIC: 95,
  CULTURE_ANTIBIOGRAM_ANAEROBIC: 96,
  HEMOCULTURE: 97,
  DIRECT_MYCOLOGICAL_RESEARCH: 98,
  MALASSEZIA_RESEARCH: 99,
  SPOROTHRIX_SCHENCKII: 100,
  UROCULTURE: 101,
  PARASITOLOGICAL_FECES_MIF: 102,
  PARASITOLOGICAL_FRESH_FECES: 103,
  PARASITOLOGICAL_FECES_OPG: 104,
  ECTOPARASITES_RESEARCH: 105,
  URINARY_BILIARY_CALCULATION: 106,
  EAS: 107,
  MICROALBUMINURIA: 108,
  CORTISOL_CREATININE_RATIO: 109,
  PROTEIN_CREATININE_RATIO: 110,
  LDH: 111,
  TLI: 112,
  HEMOGRAM_WITH_MICROFILARIAE_RESEARCH: 113,
  BLOOD_PROTEIN: 114,
  PARVO_CORONA: 115,
  URINARY_GGT: 116,
  CYTOLOGY: 117,
  LIQUOR_ANALYSIS: 118,
  PROFILE_SIMPLE: 119,
  PROFILE_PARASITOLOGICAL_MONITORING: 120,
  PROFILE_DOG_OBESITY_CHECK: 121,
  PROFILE_ELECTROLYTIC: 122,
  PROFILE_GERIATRIC: 123,
  PROFILE_HEPATIC_SIMPLE: 124,
  PROFILE_HEPATIC_COMPLETE: 125,
  PROFILE_BONE_CHECK: 126,
  PROFILE_PANCREATIC: 127,
  PROFILE_CARDIAC: 128,
  PROFILE_SURGICAL: 129,
  PROFILE_GASTROENTERITIS_BRONZE: 130,
  PROFILE_GASTROENTERITIS_SILVER: 131,
  PROFILE_GASTROENTERITIS_GOLD: 132,
  PROFILE_ROUTINE: 133,
  PROFILE_HEMOPARASITES_BRONZE: 134,
  PROFILE_HEMOPARASITES_SILVER: 135,
  PROFILE_HEMOPARASITES_GOLD: 136,
  PROFILE_SMALL_ANIMALS: 137,
  PROFILE_LARGE_ANIMALS: 138,
  PROFILE_CHECKUP: 139,
  PROFILE_PREOPERATIVE_SIMPLE: 140,
  PROFILE_PREOPERATIVE_COMPLETE: 141,
  PROFILE_PREOPERATIVE_CANINE: 142,
  PROFILE_PREOPERATIVE_CANINE_2: 143,
  PROFILE_PREOPERATIVE_FELINE: 144,
  PROFILE_PREOPERATIVE_FELINE_2: 145,
  PROFILE_RENAL: 146,
  PROFILE_RENAL_2: 147,
  PROFILE_RENAL_3: 148,
  PROFILE_THYROID: 149,
  QUALITATIVE_ANAPLASM_PCR_REAL_TIME: 500,
  QUALITATIVE_FIV_PCR_REAL_TIME: 501,
  QUALITATIVE_FELV_PCR_REAL_TIME: 502,
  QUALITATIVE_MYCOPLASM_PCR_REAL_TIME: 503,
  PROFILE_OBESITY: 485,
  PROFILE_MORE_COMPLETE_HEPATIC: 486,
  PROFILE_COMPLETE_PARASITOLOGICAL_MONITORING: 487,
  PROFILE_HYPERADRENAL_SUSPICIOUS: 488,
  PROFILE_ANEMIC_1: 489,
  PROFILE_RENAL_4: 490,
  PROFILE_ANEMIC_2: 491,
  PROFILE_THYROID_MONITORING: 492,
  RELATION_ALBUMIN_GLOBULIN: 600,
  PROFILE_CHECKUP2: 601
};

export const EXAM_NAME_BY_ID = {
  1: "babesia",
  2: "distemper",
  3: "ehrlichia",
  4: "leishmaniose",
  5: "leptospirose",
  6: "uricAcid",
  7: "albumin",
  8: "altTgp",
  9: "amylase",
  10: "astTgo",
  11: "bilirubinAndFractions",
  12: "calcium",
  13: "ionicCalcium",
  14: "chloride",
  15: "totalCholesterol",
  16: "totalCholesterolAndFractions",
  17: "creatinine",
  18: "cpk",
  19: "urea",
  20: "iron",
  21: "alkalinePhosphatase",
  22: "phosphorus",
  23: "fructosamine",
  24: "ggt",
  25: "glucose",
  26: "glycatedHemoglobin",
  27: "lactate",
  28: "lipase",
  29: "immunoreactive_lipase",
  30: "magnesium",
  31: "ph",
  32: "potassium",
  33: "total_protein",
  34: "total_protein_and_fractions",
  35: "sodium",
  36: "triglycerides",
  37: "fibrinogen",
  38: "ascitic_fluid",
  39: "thoracic_fluid",
  40: "chemiluminescence_hypersensitive_acth",
  41: "chemiluminescence_cortisol",
  42: "chemiluminescence_cortisol_4h_after_dexamethasone",
  43: "chemiluminescence_cortisol_8h_after_dexamethasone",
  44: "chemiluminescence_cortisol_4h_after_acth_stimuli",
  45: "chemiluminescence_erythropoetin",
  46: "chemiluminescence_estradiol",
  47: "chemiluminescence_phenobarbital",
  48: "chemiluminescence_hfs",
  49: "chemiluminescence_insulin",
  50: "chemiluminescence_lh",
  51: "chemiluminescence_progesterone",
  52: "chemiluminescence_pth",
  53: "chemiluminescence_total_t3",
  54: "chemiluminescence_free_t4",
  55: "chemiluminescence_total_t4",
  56: "chemiluminescence_levothyroxin_after_total_t4",
  57: "chemiluminescence_testosterone",
  58: "chemiluminescence_tsh",
  59: "radioimmunoassay_cortisol",
  60: "radioimmunoassay_cortisol_4h_after_dexamethasone",
  61: "radioimmunoassay_cortisol_8h_after_dexamethasone",
  62: "radioimmunoassay_cortisol_4h_after_acth_stimuli",
  63: "radioimmunoassay_estradiol",
  64: "radioimmunoassay_total_t3",
  65: "radioimmunoassay_free_t4",
  66: "radioimmunoassay_total_t4",
  67: "radioimmunoassay_levothyroxin_after_total_t4",
  68: "radioimmunoassay_free_t4_after_dialysis",
  69: "platelets",
  70: "reticulocyte_count",
  71: "erythrogram",
  72: "corpuscular_volume",
  73: "hemogram",
  74: "hemogram_with_hemoparasitological_research",
  75: "hemogram_with_hemocytozoa_research",
  621: "hemogram_with_buffy_coat_research",
  622: "profile_preoperative_complete_2",
  623: "profile_endocrinological",
  628: "profile_routine_3",
  629: "profile_oncological",
  76: "leukogram",
  77: "lentz_corpuscle_research",
  78: "hemocytozoa_research",
  79: "microfilariae_research",
  80: "hemocytozoa_serial_research",
  81: "coagulation_test",
  82: "cross_match",
  83: "immune_mediated_hemolytic_anemia",
  84: "four_dx",
  85: "distemper_parvovirus_igg",
  86: "distemper_parvovirus_igm",
  87: "heartworm_disease",
  88: "ehrlichia_canis",
  89: "giardia",
  90: "fiv_felv",
  91: "iga",
  92: "pif",
  93: "coproculture",
  94: "fungal_culture",
  95: "culture_antibiogram_aerobic",
  96: "culture_antibiogram_anaerobic",
  97: "hemoculture",
  98: "direct_mycological_research",
  99: "malassezia_research",
  100: "sporothrix_schenckii",
  101: "uroculture",
  102: "parasitological_feces_mif",
  103: "parasitological_fresh_feces",
  104: "parasitological_feces_opg",
  105: "ectoparasites_research",
  106: "urinary_biliary_calculation",
  107: "eas",
  108: "microalbuminuria",
  109: "cortisol_creatinine_ratio",
  110: "protein_creatinine_ratio",
  111: "ldh",
  112: "tli",
  113: "hemogram_with_microfilariae_research",
  114: "blood_protein",
  115: "parvo_corona",
  116: "urinary_ggt",
  117: "cytology",
  118: "liquor_analysis",
  119: "profile_simple",
  120: "profile_parasitological_monitoring",
  121: "profile_dog_obesity_check",
  122: "profile_electrolytic",
  123: "profile_geriatric",
  124: "profile_hepatic_simple",
  125: "profile_hepatic_complete",
  126: "profile_bone_check",
  127: "profile_pancreatic",
  128: "profile_cardiac",
  129: "profile_surgical",
  130: "profile_gastroenteritis_bronze",
  131: "profile_gastroenteritis_silver",
  132: "profile_gastroenteritis_gold",
  133: "profile_routine",
  134: "profile_hemoparasites_bronze",
  135: "profile_hemoparasites_silver",
  136: "profile_hemoparasites_gold",
  137: "profile_small_animals",
  138: "profile_large_animals",
  139: "profile_checkup",
  140: "profile_preoperative_simple",
  141: "profile_preoperative_complete",
  142: "profile_preoperative_canine",
  143: "profile_preoperative_canine_2",
  144: "profile_preoperative_feline",
  145: "profile_preoperative_feline_2",
  146: "profile_renal",
  147: "profile_renal_2",
  148: "profile_renal_3",
  149: "profile_thyroid",
  500: "qualitative_anaplasm_pcr_real_time",
  501: "qualitative_fiv_pcr_real_time",
  502: "qualitative_felv_pcr_real_time",
  503: "qualitative_mycoplasm_pcr_real_time",
  485: "profile_obesity",
  486: "profile_more_complete_hepatic",
  487: "profile_complete_parasitological_monitoring",
  488: "profile_hyperadrenal_suspicious",
  489: "profile_anemic_1",
  490: "profile_renal_4",
  491: "profile_anemic_2",
  492: "profile_thyroid_monitoring",
  600: "relation_albumin_globulin",
  601: "profile_checkup2"
};

export const EXAM_TITLE_BY_ID = {
  1: "babesia",
  2: "distemper",
  3: "ehrlichia",
  4: "leishmaniose",
  5: "leptospirose",
  6: "acido úrico",
  7: "Albumina",
  8: "alt tgp",
  9: "amilase",
  10: "ast tgo",
  11: "Bilirrubina e Frações",
  12: "Cálcio",
  13: "calcio iônico",
  14: "Cloretos",
  15: "Coloesterol Total",
  16: "Coloesterol Total e Frações",
  17: "Creatinina",
  18: "CPK",
  19: "urea",
  20: "ferro",
  21: "Fostatase Alcalina",
  22: "Fósforo",
  23: "fructosamina",
  24: "GGT",
  25: "glicose",
  26: "Hemoglobina Glicosilada",
  27: "lactato",
  28: "lipase",
  29: "Lipase immunoreactiva",
  30: "magnesio",
  31: "ph",
  32: "potassio",
  33: "Proteinas Totais",
  34: "Proteinas Totais e Frações",
  35: "sodio",
  36: "triglicerídeos",
  37: "fibrinogenio",
  38: "ascitic_fluid",
  39: "thoracic_fluid",
  40: "chemiluminescence_hypersensitive_acth",
  41: "chemiluminescence_cortisol",
  42: "chemiluminescence_cortisol_4h_after_dexamethasone",
  43: "chemiluminescence_cortisol_8h_after_dexamethasone",
  44: "chemiluminescence_cortisol_4h_after_acth_stimuli",
  45: "chemiluminescence_erythropoetin",
  46: "chemiluminescence_estradiol",
  47: "chemiluminescence_phenobarbital",
  48: "chemiluminescence_hfs",
  49: "chemiluminescence_insulin",
  50: "chemiluminescence_lh",
  51: "chemiluminescence_progesterone",
  52: "chemiluminescence_pth",
  53: "chemiluminescence_total_t3",
  54: "chemiluminescence_free_t4",
  55: "chemiluminescence_total_t4",
  56: "chemiluminescence_levothyroxin_after_total_t4",
  57: "chemiluminescence_testosterone",
  58: "chemiluminescence_tsh",
  59: "radioimmunoassay_cortisol",
  60: "radioimmunoassay_cortisol_4h_after_dexamethasone",
  61: "radioimmunoassay_cortisol_8h_after_dexamethasone",
  62: "radioimmunoassay_cortisol_4h_after_acth_stimuli",
  63: "radioimmunoassay_estradiol",
  64: "radioimmunoassay_total_t3",
  65: "radioimmunoassay_free_t4",
  66: "radioimmunoassay_total_t4",
  67: "radioimmunoassay_levothyroxin_after_total_t4",
  68: "radioimmunoassay_free_t4_after_dialysis",
  69: "platelets",
  70: "reticulocyte_count",
  71: "erythrogram",
  72: "corpuscular_volume",
  73: "hemogram",
  74: "hemogram_with_hemoparasitological_research",
  75: "hemogram_with_hemocytozoa_research",
  621: "hemogram_with_buffy_coat_research",
  622: "profile_preoperative_complete_2",
  623: "profile_endocrinological",
  628: "profile_routine_3",
  629: "profile_oncological",
  76: "leukogram",
  77: "lentz_corpuscle_research",
  78: "hemocytozoa_research",
  79: "microfilariae_research",
  80: "hemocytozoa_serial_research",
  81: "coagulation_test",
  82: "cross_match",
  83: "immune_mediated_hemolytic_anemia",
  84: "four_dx",
  85: "distemper_parvovirus_igg",
  86: "distemper_parvovirus_igm",
  87: "heartworm_disease",
  88: "ehrlichia_canis",
  89: "giardia",
  90: "fiv_felv",
  91: "iga",
  92: "pif",
  93: "coproculture",
  94: "fungal_culture",
  95: "culture_antibiogram_aerobic",
  96: "culture_antibiogram_anaerobic",
  97: "hemoculture",
  98: "direct_mycological_research",
  99: "malassezia_research",
  100: "sporothrix_schenckii",
  101: "uroculture",
  102: "parasitological_feces_mif",
  103: "parasitological_fresh_feces",
  104: "parasitological_feces_opg",
  105: "ectoparasites_research",
  106: "urinary_biliary_calculation",
  107: "eas",
  108: "microalbuminuria",
  109: "cortisol_creatinine_ratio",
  110: "protein_creatinine_ratio",
  111: "ldh",
  112: "tli",
  113: "hemogram_with_microfilariae_research",
  114: "blood_protein",
  115: "parvo_corona",
  116: "urinary_ggt",
  117: "cytology",
  118: "liquor_analysis",
  119: "profile_simple",
  120: "profile_parasitological_monitoring",
  121: "profile_dog_obesity_check",
  122: "profile_electrolytic",
  123: "profile_geriatric",
  124: "profile_hepatic_simple",
  125: "profile_hepatic_complete",
  126: "profile_bone_check",
  127: "profile_pancreatic",
  128: "profile_cardiac",
  129: "profile_surgical",
  130: "profile_gastroenteritis_bronze",
  131: "profile_gastroenteritis_silver",
  132: "profile_gastroenteritis_gold",
  133: "profile_routine",
  134: "profile_hemoparasites_bronze",
  135: "profile_hemoparasites_silver",
  136: "profile_hemoparasites_gold",
  137: "profile_small_animals",
  138: "profile_large_animals",
  139: "profile_checkup",
  140: "profile_preoperative_simple",
  141: "profile_preoperative_complete",
  142: "profile_preoperative_canine",
  143: "profile_preoperative_canine_2",
  144: "profile_preoperative_feline",
  145: "profile_preoperative_feline_2",
  146: "profile_renal",
  147: "profile_renal_2",
  148: "profile_renal_3",
  149: "profile_thyroid",
  500: "qualitative_anaplasm_pcr_real_time",
  501: "qualitative_fiv_pcr_real_time",
  502: "qualitative_felv_pcr_real_time",
  503: "qualitative_mycoplasm_pcr_real_time",
  485: "profile_obesity",
  486: "profile_more_complete_hepatic",
  487: "profile_complete_parasitological_monitoring",
  488: "profile_hyperadrenal_suspicious",
  489: "profile_anemic_1",
  490: "profile_renal_4",
  491: "profile_anemic_2",
  492: "profile_thyroid_monitoring",
  600: 'relation_albumin_globulin',
  601: 'profile_checkup2'
};

export const fieldNameToTitle = {
  result: "resultado",
  albumin: "albumina",
  iron: "ferro",
  ionic_calcium: "calcio iônico",
  calcium: "calcio",
};

export const multiResultHormenesIds = [
  EXAM_ID_BY_NAME.TOTAL_CHOLESTEROL_AND_FRACTIONS,
  EXAM_ID_BY_NAME.TOTAL_PROTEIN_AND_FRACTIONS,
  EXAM_ID_BY_NAME.IONIC_CALCIUM,
  EXAM_ID_BY_NAME.BILIRUBIN_AND_FRACTIONS,
];

export const isSimpleResultBiochemistry = (id) => {
  return !multiResultHormenesIds.includes(id);
};

export const CHEMILUMINESCENCE_EXAMS = [
  {
    chemiluminescenceHypersensitiveActh: "name",
    40: "id",
    title: "Quimioluminescência ACTH Hipersensível",
  },
  {
    name: "chemiluminescenceCortisol",
    id: 41,
    title: "Quimioluminescência Cortisol",
  },
  {
    name: "chemiluminescenceCortisol4HAfterDexamethasone",
    id: 42,
    title: "Quimioluminescência Cortisol 4h Após Dexametasona ",
  },
  {
    name: "chemiluminescenceCortisol8HAfterDexamethasone",
    id: 43,
    title: "Quimioluminescência Cortisol 8h Após Dexametasona ",
  },
  {
    name: "chemiluminescenceCortisol4HAfterActhStimuli",
    id: 44,
    title: "Quimioluminescência Cortisol Após Estímulo com ACTH ",
  },
  {
    name: "chemiluminescenceErythropoetin",
    id: 45,
    title: "Quimioluminescência Eritropoetina",
  },
  {
    name: "chemiluminescenceEstradiol",
    id: 46,
    title: "Quimioluminescência Estradiol",
  },
  {
    name: "chemiluminescencePhenobarbital",
    id: 47,
    title: "Quimioluminescência Fenobarbital",
  },
  {
    name: "chemiluminescenceHfs",
    id: 48,
    title: "Quimioluminescência FSH (Hormônio Folículo Estimulante)",
  },
  {
    name: "chemiluminescenceInsulin",
    id: 49,
    title: "Quimioluminescência Insulina",
  },
  {
    name: "chemiluminescenceLh",
    id: 50,
    title: "Quimioluminescência LH (Hormônio Luteinizante)",
  },
  {
    name: "chemiluminescenceProgesterone",
    id: 51,
    title: "Quimioluminescência Progesterona",
  },
  {
    name: "chemiluminescencePth",
    id: 52,
    title: "Quimioluminescência PTH (Paratormônio)",
  },
  {
    name: "chemiluminescenceTotalT3",
    id: 53,
    title: "Quimioluminescência T3 Total",
  },
  {
    name: "chemiluminescenceFreeT4",
    id: 54,
    title: "Quimioluminescência T4 Livre",
  },
  {
    name: "chemiluminescenceTotalT4",
    id: 55,
    title: "Quimioluminescência T4 Total",
  },

  {
    name: "chemiluminescenceLevothyroxinAfterTotalT4",
    id: 56,
    title: "Quimioluminescência T4 Total Pós Levotiroxina",
  },
  {
    name: "chemiluminescenceTestosterone",
    id: 57,
    title: "Quimioluminescência Testosterona",
  },
  { name: "chemiluminescenceTsh", id: 58, title: "Quimioluminescência TSH" },
];

export const RADIOIMMUNOASSAY_EXAMS = [
  {
    name: "radioimmunoassayCortisol",
    id: 59,
    title: "Radioimunoensaio Cortisol",
  },
  {
    name: "radioimmunoassayCortisol4HAfterDexamethasone",
    id: 60,
    title: "Radioimunoensaio Cortisol 4h Após Dexametasona",
  },
  {
    name: "radioimmunoassayCortisol8HAfterDexamethasone",
    id: 61,
    title: "Radioimunoensaio 8h Após Dexametasona",
  },
  {
    name: "radioimmunoassayCortisol4HAfterActhStimuli",
    id: 62,
    title: "Radioimunoensaio Cortisol após estímulo ACTH",
  },
  {
    name: "radioimmunoassayEstradiol",
    id: 63,
    title: "Radioimunoensaio Estradiol",
  },
  {
    name: "radioimmunoassayTotalT3",
    id: 64,
    title: "Radioimunoensaio T3 Total",
  },
  {
    name: "radioimmunoassayFreeT4",
    id: 65,
    title: "Radioimunoensaio T4 Livre",
  },
  {
    name: "radioimmunoassayTotalT4",
    id: 66,
    title: "Radioimunoensaio T4 Total",
  },
  {
    name: "radioimmunoassayLevothyroxinAfterTotalT4",
    id: 67,
    title: "Radioimunoensaio T4 Total Pós Levotiroxina",
  },
  {
    name: "radioimmunoassayFreeT4AfterDialysis",
    id: 68,
    title: " T4 Total pós Diálise",
  },
];

export const profileStructure = {
  [EXAM_ID_BY_NAME.PROFILE_OBESITY]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.ALT_TGP,
      EXAM_ID_BY_NAME.AST_TGO,
      EXAM_ID_BY_NAME.ALKALINE_PHOSPHATASE,
      EXAM_ID_BY_NAME.GLUCOSE,
      EXAM_ID_BY_NAME.TOTAL_CHOLESTEROL,
      EXAM_ID_BY_NAME.TRIGLYCERIDES,
    ],

    hasBiochemistry: true,
    hasChemiluminescence: false,
    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
      [EXAM_ID_BY_NAME.EAS]: true,
    },
  },
  [EXAM_ID_BY_NAME.PROFILE_MORE_COMPLETE_HEPATIC]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.ALT_TGP,
      EXAM_ID_BY_NAME.AST_TGO,
      EXAM_ID_BY_NAME.ALKALINE_PHOSPHATASE,
      EXAM_ID_BY_NAME.GGT,
      EXAM_ID_BY_NAME.UREA,
      EXAM_ID_BY_NAME.TOTAL_PROTEIN_AND_FRACTIONS,
      EXAM_ID_BY_NAME.BILIRUBIN_AND_FRACTIONS,
    ],

    hasChemiluminescence: false,
    hasBiochemistry: true,
    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
    },
  },
  [EXAM_ID_BY_NAME.PROFILE_COMPLETE_PARASITOLOGICAL_MONITORING]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.UREA,
      EXAM_ID_BY_NAME.CREATININE,
      EXAM_ID_BY_NAME.ALT_TGP,
      EXAM_ID_BY_NAME.ALKALINE_PHOSPHATASE,
      EXAM_ID_BY_NAME.ALBUMIN,
      EXAM_ID_BY_NAME.CHLORIDE,
      EXAM_ID_BY_NAME.SODIUM,
      EXAM_ID_BY_NAME.POTASSIUM,
      EXAM_ID_BY_NAME.CALCIUM,
      EXAM_ID_BY_NAME.PHOSPHORUS,
      EXAM_ID_BY_NAME.IONIC_CALCIUM,
      EXAM_ID_BY_NAME.TOTAL_PROTEIN_AND_FRACTIONS,
    ],

    hasChemiluminescence: false,
    hasBiochemistry: true,
    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
      [EXAM_ID_BY_NAME.MICROFILARIAE_RESEARCH]: true,
      [EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]: true,
    },
  },
  [EXAM_ID_BY_NAME.PROFILE_HYPERADRENAL_SUSPICIOUS]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.UREA,
      EXAM_ID_BY_NAME.CREATININE,
      EXAM_ID_BY_NAME.SODIUM,
      EXAM_ID_BY_NAME.POTASSIUM,
      EXAM_ID_BY_NAME.ALT_TGP,
      EXAM_ID_BY_NAME.AST_TGO,
      EXAM_ID_BY_NAME.ALKALINE_PHOSPHATASE,
      EXAM_ID_BY_NAME.IONIC_CALCIUM,
    ],

    chemiluminescenceIds: [EXAM_ID_BY_NAME.CHEMILUMINESCENCE_CORTISOL],

    hasBiochemistry: true,
    hasChemiluminescence: true,
    hasOutsourced: false,

    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
    },
  },

  [EXAM_ID_BY_NAME.PROFILE_ANEMIC_1]: {
    biochemistrysIds: [],

    chemiluminescenceIds: [],

    outsourcedNames: [],

    hasBiochemistry: false,
    hasChemiluminescence: false,
    hasOutsourced: false,

    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
      [EXAM_ID_BY_NAME.EHRLICHIA]: true,
      [EXAM_ID_BY_NAME.BABESIA]: true,
      [EXAM_ID_BY_NAME.QUALITATIVE_ANAPLASM_PCR_REAL_TIME]: true,
      [EXAM_ID_BY_NAME.RETICULOCYTE_COUNT]: true,
      [EXAM_ID_BY_NAME.BLOOD_PROTEIN]: true,
      [EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]: true,
      [EXAM_ID_BY_NAME.MICROFILARIAE_RESEARCH]: true,
    },
  },

  [EXAM_ID_BY_NAME.PROFILE_ANEMIC_2]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.UREA,
      EXAM_ID_BY_NAME.CREATININE,
      EXAM_ID_BY_NAME.ALT_TGP,
      EXAM_ID_BY_NAME.AST_TGO,
      EXAM_ID_BY_NAME.ALKALINE_PHOSPHATASE,
      EXAM_ID_BY_NAME.TOTAL_PROTEIN_AND_FRACTIONS,
      // EXAM_ID_BY_NAME.ALBUMIN,
    ],

    chemiluminescenceIds: [],

    outsourcedNames: [],

    hasBiochemistry: true,
    hasChemiluminescence: false,
    hasOutsourced: false,

    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
      [EXAM_ID_BY_NAME.EHRLICHIA]: true,
      [EXAM_ID_BY_NAME.BABESIA]: true,
      [EXAM_ID_BY_NAME.QUALITATIVE_ANAPLASM_PCR_REAL_TIME]: true,
      [EXAM_ID_BY_NAME.RETICULOCYTE_COUNT]: true,
      [EXAM_ID_BY_NAME.BLOOD_PROTEIN]: true,
      [EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]: true,
      [EXAM_ID_BY_NAME.MICROFILARIAE_RESEARCH]: true,
    },
  },

  [EXAM_ID_BY_NAME.PROFILE_RENAL_4]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.UREA,
      EXAM_ID_BY_NAME.CREATININE,
      EXAM_ID_BY_NAME.SODIUM,
      EXAM_ID_BY_NAME.POTASSIUM,
      EXAM_ID_BY_NAME.PHOSPHORUS,
      EXAM_ID_BY_NAME.IONIC_CALCIUM,
    ],

    chemiluminescenceIds: [],

    outsourcedNames: [],

    hasBiochemistry: true,
    hasChemiluminescence: false,
    hasOutsourced: false,

    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
      [EXAM_ID_BY_NAME.EAS]: true,
      [EXAM_ID_BY_NAME.PROTEIN_CREATININE_RATIO]: true,
    },
  },

  [EXAM_ID_BY_NAME.PROFILE_THYROID_MONITORING]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.ALT_TGP,
      EXAM_ID_BY_NAME.AST_TGO,
      EXAM_ID_BY_NAME.ALKALINE_PHOSPHATASE,
    ],

    chemiluminescenceIds: [
      EXAM_ID_BY_NAME.CHEMILUMINESCENCE_TOTAL_T4,
      EXAM_ID_BY_NAME.CHEMILUMINESCENCE_LEVOTHYROXIN_AFTER_TOTAL_T4,
    ],

    outsourcedNames: [],

    hasBiochemistry: true,
    hasChemiluminescence: true,
    hasOutsourced: false,

    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
    },
  },
  
  [EXAM_ID_BY_NAME.PROFILE_CHECKUP2]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.ALT_TGP,
      EXAM_ID_BY_NAME.AST_TGO,
      EXAM_ID_BY_NAME.ALKALINE_PHOSPHATASE,
      EXAM_ID_BY_NAME.GGT,
      EXAM_ID_BY_NAME.UREA,
      EXAM_ID_BY_NAME.CREATININE,
      EXAM_ID_BY_NAME.SODIUM,
      EXAM_ID_BY_NAME.POTASSIUM,
      EXAM_ID_BY_NAME.PHOSPHORUS,
      EXAM_ID_BY_NAME.GLUCOSE,
      EXAM_ID_BY_NAME.TOTAL_CHOLESTEROL,
      EXAM_ID_BY_NAME.IONIC_CALCIUM,
      EXAM_ID_BY_NAME.BILIRUBIN_AND_FRACTIONS,
      EXAM_ID_BY_NAME.TOTAL_PROTEIN_AND_FRACTIONS,
      EXAM_ID_BY_NAME.RELATION_ALBUMIN_GLOBULIN
    ],

    outsourcedNames: [],

    hasBiochemistry: true,
    hasChemiluminescence: false,
    hasOutsourced: false,

    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
      [EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]: true,
    },
  },

  [EXAM_ID_BY_NAME.PROFILE_PREOPERATIVE_COMPLETE_2]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.ALT_TGP,
      EXAM_ID_BY_NAME.ALKALINE_PHOSPHATASE,
      EXAM_ID_BY_NAME.UREA,
      EXAM_ID_BY_NAME.CREATININE,
      EXAM_ID_BY_NAME.GLUCOSE,

      EXAM_ID_BY_NAME.TOTAL_PROTEIN_AND_FRACTIONS,
      EXAM_ID_BY_NAME.RELATION_ALBUMIN_GLOBULIN
    ],
    outsourcedNames: [],

    hasBiochemistry: true,
    hasChemiluminescence: false,
    hasOutsourced: false,

    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
      // [EXAM_ID_BY_NAME.HEMOGRAM_WITH_HEMOPARASITOLOGICAL_RESEARCH]: true,
      [EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]: true,

    },
  },

  [EXAM_ID_BY_NAME.PROFILE_ENDOCRINOLOGICAL]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.ALT_TGP,
      EXAM_ID_BY_NAME.AST_TGO,
      EXAM_ID_BY_NAME.ALKALINE_PHOSPHATASE,
      EXAM_ID_BY_NAME.GGT,
      EXAM_ID_BY_NAME.UREA,
      EXAM_ID_BY_NAME.CREATININE,
      EXAM_ID_BY_NAME.GLUCOSE,
      EXAM_ID_BY_NAME.TRIGLYCERIDES,
      EXAM_ID_BY_NAME.TOTAL_CHOLESTEROL,
      EXAM_ID_BY_NAME.TOTAL_PROTEIN_AND_FRACTIONS,
      EXAM_ID_BY_NAME.RELATION_ALBUMIN_GLOBULIN,
    ],
    hasBiochemistry: true,
    hasChemiluminescence: false,
    hasOutsourced: false,

    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
      [EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]: true,
    }
  },
  
  [EXAM_ID_BY_NAME.PROFILE_ROUTINE_3]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.ALT_TGP,
      EXAM_ID_BY_NAME.AST_TGO,
      EXAM_ID_BY_NAME.ALKALINE_PHOSPHATASE,
      EXAM_ID_BY_NAME.GGT,
      EXAM_ID_BY_NAME.UREA,
      EXAM_ID_BY_NAME.CREATININE,
      EXAM_ID_BY_NAME.SODIUM,
      EXAM_ID_BY_NAME.POTASSIUM,
      EXAM_ID_BY_NAME.GLUCOSE,
      EXAM_ID_BY_NAME.TRIGLYCERIDES,
      EXAM_ID_BY_NAME.TOTAL_CHOLESTEROL,
      EXAM_ID_BY_NAME.TOTAL_PROTEIN_AND_FRACTIONS,
      EXAM_ID_BY_NAME.RELATION_ALBUMIN_GLOBULIN,
    ],
    hasBiochemistry: true,
    hasChemiluminescence: false,
    hasOutsourced: false,

    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
      [EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]: true,
    }
  },
  
  [EXAM_ID_BY_NAME.PROFILE_ONCOLOGICAL]: {
    biochemistrysIds: [
      EXAM_ID_BY_NAME.ALT_TGP,
      EXAM_ID_BY_NAME.AST_TGO,
      EXAM_ID_BY_NAME.ALKALINE_PHOSPHATASE,
      EXAM_ID_BY_NAME.GGT,
      EXAM_ID_BY_NAME.UREA,
      EXAM_ID_BY_NAME.CREATININE,
      EXAM_ID_BY_NAME.SODIUM,
      EXAM_ID_BY_NAME.POTASSIUM,
      EXAM_ID_BY_NAME.PHOSPHORUS,
      EXAM_ID_BY_NAME.GLUCOSE,
      EXAM_ID_BY_NAME.TRIGLYCERIDES,
      EXAM_ID_BY_NAME.TOTAL_CHOLESTEROL,
      EXAM_ID_BY_NAME.IONIC_CALCIUM,

      EXAM_ID_BY_NAME.TOTAL_PROTEIN_AND_FRACTIONS,
      EXAM_ID_BY_NAME.RELATION_ALBUMIN_GLOBULIN,
    ],
    hasBiochemistry: true,
    hasChemiluminescence: false,
    hasOutsourced: false,

    exams: {
      [EXAM_ID_BY_NAME.HEMOGRAM]: true,
      [EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH]: true,
    }
  }
};
