import React from "react";
import styled from "styled-components";

const StyledIconList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const IconList = props => (
  <StyledIconList {...props}>{props.children}</StyledIconList>
);

export default IconList;
