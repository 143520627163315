import React from "react";

/**
 * External
 */
import PropTypes from "prop-types";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import NumberInputMaterial from "./../NumberInputMaterial";
import PanelMaterialBody from "../PanelMaterialBody";
import PanelMaterialTop from "../PanelMaterialTop";
import NumberInputWithReference from "../NumberInputWithReference";

const propTypes = {};

/**
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const NumberInputWithReferencePanel = ({
  title,
  fieldTitle,
  value,
  fieldName,
  shouldDisable,
  hasValidation,
  validationReason,
  fieldChange,
  $field,
  valueMin,
  valueMax,
  decimalScale,
  unit,
  setRef,
  handleBlur
}) => (
  <React.Fragment>
    <PanelMaterial className="pb-20" flat={true}>
      <PanelMaterialTop title={title} />
      <PanelMaterialBody>
        <NumberInputWithReference
          value={value}
          unit={unit}
          fieldTitle={fieldTitle}
          fieldName={fieldName}
          shouldDisable={shouldDisable}
          decimalScale={decimalScale}
          fieldChange={fieldChange}
          $field={$field}
          hasValidation={hasValidation}
          validationReason={validationReason}
          valueMin={valueMin}
          valueMax={valueMax}
          unit={"mg/dL"}
          setRef={setRef}
          handleBlur={handleBlur}
        />
      </PanelMaterialBody>
    </PanelMaterial>
  </React.Fragment>
);

NumberInputWithReferencePanel.propTypes = propTypes;

export default NumberInputWithReferencePanel;
