import React from 'react';
import { Col } from './../Grid';

/**
 * @author Victor Heringer
 * 
 * Creates element for image preview
 * 
 * @param {Object|String} file
 * @param {String} key
 * @param {Object} styles
 * @param {Boolean} multiples
 * @param {Boolean} inline
 */
const preview = (image, key, styles, multiples, inline) => {

  const offset = multiples || inline ? 0 : 4;
  const colMd  = inline ? 12 : 4;

  return (
    <Col md={colMd} mdOffset={offset} key={key}>
      <img src={image} className={styles.imagePreview} />
    </Col>
  );
}

/**
 * @author Victor Heringer
 * 
 * Creates image preview from upload images or placeholders
 * 
 * @prop {Array} images
 * @prop {Array} placeholders
 * @prop {Boolean} display
 * @prop {Object} styles
 * @prop {Boolean} multiples
 * @prop {Boolean} inline
 */
const ImagePreview = ({ 
  images, 
  placeholders, 
  display, 
  styles, 
  multiples,
  inline
}) => {

  return display ? images.length > 0 
    ? images.map((image) => 
        preview(image.value, image.name, styles, multiples, inline)
      )
    : placeholders.map((image) => 
        preview(image.value, image.name, styles, multiples, inline)
      )
  : null;
}

export default ImagePreview;