/**
 * Internal
 */
import React from "react";
import Decimal from "./Decimal.js";
import { StringUtil } from "./../../useful";
import NumberDisplay from "./../../containers/NumberDisplay";
import { Col } from "./../../components/grid";
import NumberInputMaterial2 from "./../../containers/NumberInputMaterial2";
import CheckboxMaterial from "./../../containers/CheckboxMaterial";

import {
  isIntegerBiochemExam,
  KEY_TO_FIELD,
  COUNTER_LIMIT,
  EXAM_ID_BY_NAME,
  qttDecimalPlates,
} from "./Consts.js";

/**
 * External
 */
import update from "immutability-helper";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import caretRight from "@fortawesome/fontawesome-free-solid/faCaretRight";
import caretDown from "@fortawesome/fontawesome-free-solid/faCaretDown";
import InputValidationMsg from "../../containers/InputValidationMsg/index.js";
import InputExam from "../../components/input-exam/index.js";
import { INPUT_SPECIAL_VALUE } from "../../consts/index.js";

export const expandedRow = (
  <span style={{ cursor: "pointer", marginLeft: "10px" }}>
    <FontAwesomeIcon icon={caretDown} size="lg" />
  </span>
);

export const collapsedRow = (
  <span style={{ cursor: "pointer", marginLeft: "10px" }}>
    <FontAwesomeIcon icon={caretRight} size="lg" />
  </span>
);

const isEmpty = (value) => {
  return value === null || value === "" || value === undefined || !value;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Checks wether it is a case of anemia
 * It returns true if any of the values
 * for erythrocytes, corpuscular volume and hemoglobiometry is lower
 * than their relative minimum reference values for the given species
 *
 * @return {boolean}
 *
 */
export function isAnemia() {
  const { erythrocytes, corpuscularVolume, hemoglobiometry } =
    this.state.fields;

  const { erythrocytesMin, corpuscularVolumeMin, hemoglobiometryMin } =
    this.props.refsPromise.value;

  if (
    isEmptyField(erythrocytes) ||
    isEmptyField(corpuscularVolume) ||
    isEmptyField(hemoglobiometry)
  ) {
    return "";
  }

  const eryth = new Decimal(erythrocytes);
  const corpVol = new Decimal(corpuscularVolume);
  const hemoglob = new Decimal(hemoglobiometry);

  return (
    eryth.lessThan(erythrocytesMin) ||
    corpVol.lessThan(corpuscularVolumeMin) ||
    hemoglob.lessThan(hemoglobiometryMin)
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Checks wether it is a case of polcistemia
 * It returns true if any of the values
 * for erythrocytes, corpuscular volume and hemoglobiometry is higher
 * than their relative minimum reference value for the given species
 *
 * @return {boolean}
 *
 */
export function isPolicitemia() {
  const { erythrocytes, corpuscularVolume, hemoglobiometry } =
    this.state.fields;

  const { erythrocytesMax, corpuscularVolumeMax, hemoglobiometryMax } =
    this.props.refsPromise.value;

  if (
    isEmptyField(erythrocytes) ||
    isEmptyField(corpuscularVolume) ||
    isEmptyField(hemoglobiometry)
  ) {
    return "";
  }

  const eryth = new Decimal(erythrocytes);
  const corpVol = new Decimal(corpuscularVolume);
  const hemoglob = new Decimal(hemoglobiometry);

  return eryth.greaterThan(erythrocytesMax);
  // Correção do card de policitemia
  // ||
  // corpVol.greaterThan(corpuscularVolumeMax) ||
  // hemoglob.greaterThan(hemoglobiometryMax)
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Generates blood cells diagnosis
 *
 * @return {string}
 *
 */
export function bloodCellsDiagnosis() {
  if (this.isAnemia()) {
    return "anemia";
  }

  if (this.isPolicitemia()) {
    return "policitemia";
  }

  return "hemácia";
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Toggles intense leucopeny state
 *
 */
export function toggleIntenseLeucopeny() {
  this.setState((state) =>
    update(state, {
      fields: {
        isIntenseLeucopeny: {
          $set: !this.state.fields.isIntenseLeucopeny,
        },
      },
    })
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Opens counter
 *
 */
export function openCounter() {
  this.setState((state) =>
    update(state, {
      fields: {
        isCounterOpen: {
          $set: true,
        },
      },
    })
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Closes counter
 *
 */
export function closeCounter() {
  this.setState((state) =>
    update(state, {
      fields: {
        isCounterOpen: {
          $set: false,
        },
      },
    })
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Enables counter sound
 */
export function enableCounterSound() {
  this.setState((state) =>
    update(state, {
      fields: {
        shouldBlockCounterSound: {
          $set: false,
        },
      },
    })
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Handle state when the sound stop
 */
export function handleFinishedPlaying() {
  this.setState((state) =>
    update(state, {
      fields: {
        shouldBlockCounterSound: {
          $set: true,
        },
      },
    })
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Turns all specific leukogram fields into 0
 *
 */
export function resetLeukogram() {
  const emptyStr = "";

  this.setState((state) =>
    update(state, {
      fields: {
        metarrubrycites: { $set: emptyStr },
        relativeBasophils: { $set: emptyStr },
        relativeEosinophils: { $set: emptyStr },
        relativeMyelocytes: { $set: emptyStr },
        relativeMetamyelocytes: { $set: emptyStr },
        relativeBands: { $set: emptyStr },
        relativeSegs: { $set: emptyStr },
        relativeLymphocytes: { $set: emptyStr },
        relativeMonocytes: { $set: emptyStr },
        relativeHeterophils: { $set: emptyStr },
      },
    })
  );

  this.enableCounterSound();
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Turns all specific leukogram fields
 * that are empty strings into 0
 *
 */
export function zeroLeukogram() {
  const zero = 0;

  const zeroIfEmpty = (field) => {
    return this.state.fields[field] === ""
      ? zero.toString()
      : this.state.fields[field];
  };

  this.setState((state) =>
    update(state, {
      fields: {
        relativeBasophils: { $set: zeroIfEmpty("relativeBasophils") },
        relativeEosinophils: { $set: zeroIfEmpty("relativeEosinophils") },
        relativeMyelocytes: { $set: zeroIfEmpty("relativeMyelocytes") },
        relativeMetamyelocytes: { $set: zeroIfEmpty("relativeMetamyelocytes") },
        relativeBands: { $set: zeroIfEmpty("relativeBands") },
        relativeSegs: { $set: zeroIfEmpty("relativeSegs") },
        relativeLymphocytes: { $set: zeroIfEmpty("relativeLymphocytes") },
        relativeMonocytes: { $set: zeroIfEmpty("relativeMonocytes") },
        relativeHeterophils: { $set: zeroIfEmpty("relativeHeterophils") },
      },
    })
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Increments counter
 *
 * @param {string} field The name of the state that represents the field
 *
 */
export function incrementField(field) {
  const value = new Decimal(
    StringUtil.ifEmptyStrToZero(this.state.fields[field])
  );

  const newValue = value.plus(1).toPrecision();

  if (this.calcTotal() < COUNTER_LIMIT) {
    this.fieldChange(field, newValue);
  } else if (field === "metarrubrycites") {
    this.fieldChange(field, newValue);
  }
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Handles counter key press
 * Calls counter clogins method if key pressed is 'Enter'
 *
 * @param {object} event The input event object
 *
 */
export function handleKeyPress(event) {
  event.preventDefault();

  const key = event.key;

  const isCounterClosed = !this.state.fields.isCounterOpen;

  if (isCounterClosed) {
    return;
  }

  if ("Enter" === key) {
    this.closeCounter();
    return;
  }

  this.incrementField(KEY_TO_FIELD[key]);
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Calculates leukogram total
 *
 * @return {number} Total number of leukocytes
 *
 */

export function calcTotal() {
  const { fields } = this.state;

  if (
    StringUtil.isStrEmpty(fields.relativeMetamyelocytes) &
    StringUtil.isStrEmpty(fields.relativeMyelocytes) &
    StringUtil.isStrEmpty(fields.relativeEosinophils) &
    StringUtil.isStrEmpty(fields.relativeBands) &
    StringUtil.isStrEmpty(fields.relativeSegs) &
    StringUtil.isStrEmpty(fields.relativeLymphocytes) &
    StringUtil.isStrEmpty(fields.relativeMonocytes) &
    StringUtil.isStrEmpty(fields.relativeHeterophils) &
    StringUtil.isStrEmpty(fields.relativeBasophils)
  ) {
    return "";
  }

  const relativeMetamyelocytes = new Decimal(
    StringUtil.ifEmptyStrToZero(fields.relativeMetamyelocytes)
  );
  const relativeMyelocytes = new Decimal(
    StringUtil.ifEmptyStrToZero(fields.relativeMyelocytes)
  );
  const relativeEosinophils = new Decimal(
    StringUtil.ifEmptyStrToZero(fields.relativeEosinophils)
  );
  const relativeBands = new Decimal(
    StringUtil.ifEmptyStrToZero(fields.relativeBands)
  );
  const relativeSegs = new Decimal(
    StringUtil.ifEmptyStrToZero(fields.relativeSegs)
  );
  const relativeLymphocytes = new Decimal(
    StringUtil.ifEmptyStrToZero(fields.relativeLymphocytes)
  );
  const relativeMonocytes = new Decimal(
    StringUtil.ifEmptyStrToZero(fields.relativeMonocytes)
  );
  const relativeHeterophils = new Decimal(
    StringUtil.ifEmptyStrToZero(fields.relativeHeterophils)
  );
  const relativeBasophils = new Decimal(
    StringUtil.ifEmptyStrToZero(fields.relativeBasophils)
  );

  return relativeMetamyelocytes
    .plus(relativeMyelocytes)
    .plus(relativeEosinophils)
    .plus(relativeBands)
    .plus(relativeSegs)
    .plus(relativeLymphocytes)
    .plus(relativeMonocytes)
    .plus(relativeHeterophils)
    .plus(relativeBasophils)
    .toPrecision();
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Calculates absolute value for an informed leukocyte
 *
 * @param {Mix} value The leukocyte value
 *
 * @return {number} The absolute value for the leukocyte
 *
 */
export function calcAbsolute(value) {
  const globalValue = this.finalGlobalLeukogram();

  if (isEmpty(value) || isEmpty(globalValue)) {
    return "";
  }
  // console.log("value", value);
  // console.log("globalValue", globalValue);
  const global = new Decimal(globalValue);
  const relative = new Decimal(value);
  // console.log(
  //   "global",
  //   global.times(relative).dividedBy(100).round().toPrecision()
  // );

  return global.times(relative).dividedBy(100).round().toPrecision();
}

const isEmptyField = (value) => {
  return (
    value === "" ||
    value === "." ||
    value === "," ||
    value === undefined ||
    value === null
  );
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Calculates Mean Cospuscular Value
 * by multiplying globular volume to erythrocytes
 * and multiplying the result by 10
 *
 * @return {number} The mean corpuscular value, empty string otherwise
 *
 */
export function calcMCV() {
  const corpuscularVolume = this.state.fields.corpuscularVolume;
  const erythrocytes = this.state.fields.erythrocytes;
  if (isEmptyField(corpuscularVolume) || isEmptyField(erythrocytes)) {
    return "";
  }

  const globVol = new Decimal(corpuscularVolume);
  const eryth = new Decimal(erythrocytes);

  const erythStr = eryth.toFixed(2);

  if (erythStr === "0.0") {
    return erythStr;
  }

  return globVol.dividedBy(eryth).times(10).toFixed(2);
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Calculates Mean Cospuscular Hemoglobine Concentration
 * by multiplying hemoglobiometry to globular volume
 * and multiplying the result by 100
 *
 * @return {number} The mean corpuscular hemoglobine concentration,
 * empty string otherwise
 *
 */
export function calcMCHC() {
  const corpuscularVolume = this.state.fields.corpuscularVolume;
  const hemoglobiometry = this.state.fields.hemoglobiometry;
  if (isEmptyField(corpuscularVolume) || isEmptyField(hemoglobiometry)) {
    return "";
  }

  const globVol = new Decimal(corpuscularVolume);
  const hemoglob = new Decimal(hemoglobiometry);

  const strGlobVol = globVol.toFixed(2);

  if (strGlobVol === "0.0") {
    return strGlobVol;
  }

  return hemoglob.dividedBy(globVol).times(100).toFixed(2);
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Calculates the final value of global leukometry
 * considering the number of metarrubrists
 *
 * @return {number} The final global leukogram value
 *
 */
export function finalGlobalLeukogram() {
  const globalLeukometry = this.state.fields.globalLeukometry;
  const metarrubrycites = this.state.fields.metarrubrycites;

  if (StringUtil.isStrEmpty(globalLeukometry)) {
    return "";
  }

  if (StringUtil.isStrEmpty(metarrubrycites)) {
    const globalLeuk = new Decimal(globalLeukometry);
    return globalLeuk.toPrecision();
  }

  return Math.round((globalLeukometry * 100) / (100 + +metarrubrycites));
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Obtains the mcv diagnosis result
 *
 * @return {string}
 *
 */
export function mcvDiagnosis() {
  const { mcvMin, mcvMax } = this.props.refsPromise.value;
  if (
    isEmptyField(mcvMin) ||
    isEmptyField(mcvMax) ||
    isEmptyField(this.calcMCV())
  ) {
    return "";
  }
  const MCV = new Decimal(this.calcMCV());
  const MCVMin = new Decimal(mcvMin);
  const MCVMax = new Decimal(mcvMax);

  if (MCV.lessThan(MCVMin)) {
    return "microcítica";
  }

  if (MCV.greaterThan(MCVMax)) {
    return "macrocítica";
  }

  if (this.isPolicitemia()) {
    return "";
  }

  return "normocítica";
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Obtains the mchc diagnosis result
 *
 * @return {string}
 *
 */
export function mchcDiagnosis() {
  const { mchcMin, mchcMax } = this.props.refsPromise.value;

  if (
    isEmptyField(mchcMin) ||
    isEmptyField(mchcMax) ||
    isEmptyField(this.calcMCHC())
  ) {
    return "";
  }

  const MCHC = new Decimal(this.calcMCHC());
  const MCHCMin = new Decimal(mchcMin);
  const MCHCMax = new Decimal(mchcMax);

  if (MCHC.lessThan(MCHCMin)) {
    return "hipocrômica";
  }

  if (MCHC.greaterThan(MCHCMax)) {
    return "hipercrômica";
  }

  if (this.isPolicitemia()) {
    return "";
  }

  return "normocrômica";
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Resolves the result text for red blood cells
 * based on the exam results
 *
 * @return {string}
 *
 */
export function erythrogramDiagnosis() {
  const { erythrocytes, corpuscularVolume, hemoglobiometry } =
    this.state.fields;

  if (
    this.props.refsPromise.pending ||
    StringUtil.areAnyStrsEmpty(erythrocytes, corpuscularVolume, hemoglobiometry)
  ) {
    return "";
  }

  const erythrogramDiagnosis =
    this.bloodCellsDiagnosis() +
    " " +
    this.mcvDiagnosis() +
    " " +
    this.mchcDiagnosis();
  return StringUtil.capitalize(erythrogramDiagnosis).trim();
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Tells wether it is neutrophilic leukocytosis
 *
 * @return {boolean}
 *
 */
export function isNeutropLeukocytosis() {
  const {
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    relativeSegs,
  } = this.state.fields;

  if (
    StringUtil.areAnyStrsEmpty(
      relativeMyelocytes,
      relativeMetamyelocytes,
      relativeBands,
      relativeSegs
    )
  ) {
    return false;
  }

  const absoluteMyelocytes = this.calcAbsolute(relativeMyelocytes);
  const absoluteMetamyelocytes = this.calcAbsolute(relativeMetamyelocytes);
  const absoluteBands = this.calcAbsolute(relativeBands);
  const absoluteSegs = this.calcAbsolute(relativeSegs);

  const {
    relativeMyelocytesMax,
    absoluteMyelocytesMax,
    relativeMetamyelocytesMax,
    absoluteMetamyelocytesMax,
    relativeBandsMax,
    absoluteBandsMax,
    relativeSegsMax,
    absoluteSegsMax,
  } = this.props.refsPromise.value;

  return (
    isGreaterThan(relativeMyelocytes, relativeMyelocytesMax) ||
    isGreaterThan(absoluteMyelocytes, absoluteMyelocytesMax) ||
    isGreaterThan(relativeMetamyelocytes, relativeMetamyelocytesMax) ||
    isGreaterThan(absoluteMetamyelocytes, absoluteMetamyelocytesMax) ||
    isGreaterThan(relativeBands, relativeBandsMax) ||
    isGreaterThan(absoluteBands, absoluteBandsMax) ||
    isGreaterThan(relativeSegs, relativeSegsMax) ||
    isGreaterThan(absoluteSegs, absoluteSegsMax)
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Returns the diagnosis for global leukometry
 * based on global leukometry results
 *
 * @return {string}
 *
 */
export function globalLeukDiagnosis() {
  const globalLeukometry = this.finalGlobalLeukogram();

  if (
    this.props.refsPromise.pending ||
    StringUtil.isStrEmpty(globalLeukometry)
  ) {
    return "";
  }

  const { globalLeukometryMin, globalLeukometryMax } =
    this.props.refsPromise.value;

  const globalLeuk = new Decimal(globalLeukometry);

  const globalLeukMin = new Decimal(globalLeukometryMin);

  const globalLeukMax = new Decimal(globalLeukometryMax);

  if (globalLeuk.lessThan(globalLeukMin)) {
    return "leucopenia";
  }

  if (globalLeuk.greaterThan(globalLeukMax)) {
    if (this.isNeutropLeukocytosis()) {
      return "leucocitose neutrofílica";
    }

    return "leucocitose";
  }

  return "";
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Tells wethen value is less than valueMin
 *
 * @return {boolean}
 *
 */
function isLessThan(value, valueMin) {
  const val = new Decimal(value);
  const valMin = new Decimal(valueMin);
  return val.lessThan(valMin);
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Tells wethen value is greater than valueMax
 *
 * @return {boolean}
 *
 */
function isGreaterThan(value, valueMax) {
  if (isEmpty(value)) return "";
  const val = new Decimal(value);
  const valMax = new Decimal(valueMax);
  return val.greaterThan(valMax);
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Decides de diagnosis based on
 * relative and absolute values
 *
 * @return {boolean}
 *
 */
export function diagnosis(
  relative,
  relativeMin,
  relativeMax,
  absoluteMin,
  absoluteMax,
  lowName,
  highName,
  composed
) {
  const absolute = this.calcAbsolute(relative);

  if (StringUtil.isStrEmpty(relative) || StringUtil.isStrEmpty(absolute)) {
    return "";
  }

  return this.displayResults(
    relative,
    absolute,
    relativeMin,
    absoluteMin,
    relativeMax,
    absoluteMax,
    lowName,
    highName,
    composed
  );
}

function addConnectorStr(text, connector) {
  return text && StringUtil.isStrFilled(text) ? connector : "";
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces result
 *
 * @return {string}
 *
 */
export function displayResults(
  relative,
  absolute,
  relativeMin,
  absoluteMin,
  relativeMax,
  absoluteMax,
  lowName,
  highName,
  composed
) {
  if (
    relative &&
    relativeMin &&
    absolute &&
    absoluteMin &&
    lowName &&
    isLessThan(relative, relativeMin) &&
    isLessThan(absolute, absoluteMin)
  ) {
    return addConnectorStr(composed, ", ") + lowName + " relativa e absoluta";
  }

  if (
    relative &&
    relativeMax &&
    absolute &&
    absoluteMax &&
    highName &&
    isGreaterThan(relative, relativeMax) &&
    isGreaterThan(absolute, absoluteMax)
  ) {
    return addConnectorStr(composed, ", ") + highName + " relativa e absoluta";
  }

  let text = "";

  if (relative && relativeMin && lowName && isLessThan(relative, relativeMin)) {
    text += addConnectorStr(composed, ", ") + lowName + " relativa";
  } else if (
    absolute &&
    absoluteMin &&
    lowName &&
    isLessThan(absolute, absoluteMin)
  ) {
    text += addConnectorStr(composed, ", ") + lowName + " absoluta";
  }

  if (
    relative &&
    relativeMax &&
    highName &&
    isGreaterThan(relative, relativeMax)
  ) {
    text +=
      (StringUtil.isStrFilled(text) ? " e " : addConnectorStr(composed, ", ")) +
      highName +
      " relativa";
  } else if (
    absolute &&
    absoluteMax &&
    highName &&
    isGreaterThan(absolute, absoluteMax)
  ) {
    text +=
      (StringUtil.isStrFilled(text) ? " e " : addConnectorStr(composed, ", ")) +
      highName +
      " absoluta";
  }

  return text;
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Resolves the result text for red blood cells
 * based on the exam results
 *
 * @return {string}
 *
 */
export function DNNE(composed) {
  const { relativeMyelocytes, relativeMetamyelocytes, relativeBands } =
    this.state.fields;

  const absoluteMyelocytes = this.calcAbsolute(relativeMyelocytes);
  const absoluteMetamyelocytes = this.calcAbsolute(relativeMetamyelocytes);
  const absoluteBands = this.calcAbsolute(relativeBands);

  const {
    relativeMyelocytesMax,
    absoluteMyelocytesMax,
    relativeMetamyelocytesMax,
    absoluteMetamyelocytesMax,
    relativeBandsMax,
    absoluteBandsMax,
  } = this.props.refsPromise.value;

  if (StringUtil.isStrFilled(relativeMyelocytes)) {
    if (
      isGreaterThan(relativeMyelocytes, relativeMyelocytesMax) ||
      isGreaterThan(absoluteMyelocytes, absoluteMyelocytesMax)
    ) {
      return addConnectorStr(composed, " com") + " DNNE acentuado";
    }
  }

  if (StringUtil.isStrFilled(relativeMetamyelocytes)) {
    if (
      isGreaterThan(relativeMetamyelocytes, relativeMetamyelocytesMax) ||
      isGreaterThan(absoluteMetamyelocytes, absoluteMetamyelocytesMax)
    ) {
      return addConnectorStr(composed, " com") + " DNNE moderado";
    }
  }

  if (StringUtil.isStrFilled(relativeBands)) {
    if (
      isGreaterThan(relativeBands, relativeBandsMax) ||
      isGreaterThan(absoluteBands, absoluteBandsMax)
    ) {
      return addConnectorStr(composed, " com") + " DNNE leve";
    }
  }

  return "";
}

function ifRareThenZero(value) {
  return value === "Rare" ? 0 : value;
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Resolves the result text for red blood cells
 * based on the exam results
 *
 * @return {string}
 *
 */
export function leukogramDiagnosis() {
  if (this.state.fields.isIntenseLeucopeny) {
    return "Contagem de leucometria específica impossibilitada devido à leucopenia intensa";
  }

  const globalLeukometry = this.state.fields.globalLeukometry;

  if (
    this.props.refsPromise.pending ||
    StringUtil.isStrEmpty(globalLeukometry)
  ) {
    return "";
  }

  const {
    relativeBasophils,
    relativeEosinophils,
    relativeSegs,
    relativeLymphocytes,
    relativeMonocytes,
    relativeHeterophils,
  } = this.state.fields;

  const {
    relativeBasophilsMax,
    absoluteBasophilsMax,
    relativeEosinophilsMax,
    absoluteEosinophilsMax,
    relativeEosinophilsMin,
    absoluteEosinophilsMin,
    relativeSegsMax,
    absoluteSegsMax,
    relativeSegsMin,
    absoluteSegsMin,
    relativeLymphocytesMax,
    absoluteLymphocytesMax,
    relativeLymphocytesMin,
    absoluteLymphocytesMin,
    relativeMonocytesMax,
    absoluteMonocytesMax,
    relativeMonocytesMin,
    absoluteMonocytesMin,
    relativeHeterophilsMax,
    absoluteHeterophilsMax,
    relativeHeterophilsMin,
    absoluteHeterophilsMin,
  } = this.props.refsPromise.value;

  let composed = "";

  composed += this.globalLeukDiagnosis();

  composed += this.DNNE(composed);

  const relativeBasophMax = ifRareThenZero(relativeBasophilsMax);

  const absoluteBasophMax = ifRareThenZero(absoluteBasophilsMax);

  const basophils = this.diagnosis(
    relativeBasophils,
    null,
    relativeBasophMax || 1,
    null,
    absoluteBasophMax || 1,
    null,
    "basofilia",
    composed
  );

  composed += basophils;

  const eosinphils = this.diagnosis(
    relativeEosinophils,
    relativeEosinophilsMin,
    relativeEosinophilsMax,
    absoluteEosinophilsMin,
    absoluteEosinophilsMax,
    "eosinopenia",
    "eosinofilia",
    composed
  );

  composed += eosinphils;

  const segs = this.diagnosis(
    relativeSegs,
    relativeSegsMin,
    relativeSegsMax,
    absoluteSegsMin,
    absoluteSegsMax,
    "neutropenia",
    "neutrofilia",
    composed
  );

  composed += segs;

  const lymphocytes = this.diagnosis(
    relativeLymphocytes,
    relativeLymphocytesMin,
    relativeLymphocytesMax,
    absoluteLymphocytesMin,
    absoluteLymphocytesMax,
    "linfocitopenia",
    "linfocitose",
    composed
  );

  composed += lymphocytes;

  const monocytes = this.diagnosis(
    relativeMonocytes,
    relativeMonocytesMin,
    relativeMonocytesMax,
    absoluteMonocytesMin,
    absoluteMonocytesMax,
    "monocitopenia",
    "monocitose",
    composed
  );

  composed += monocytes;

  const heterophils = this.diagnosis(
    relativeHeterophils,
    relativeHeterophilsMin,
    relativeHeterophilsMax,
    absoluteHeterophilsMin,
    absoluteHeterophilsMax,
    "heteropenia",
    "heterofilia",
    composed
  );

  composed += heterophils;

  return StringUtil.capitalize(composed).trim();
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Resolves the result text for pletelets
 * based on the exam results
 *
 * @return {string}
 *
 */
export function plateletsDiagnosis() {
  const { platelets } = this.state.fields;

  if (this.props.refsPromise.pending || StringUtil.isStrEmpty(platelets)) {
    return "";
  }
  if (platelets === "" || platelets === null || platelets === undefined) {
    return "";
  }

  const { plateletsMin, plateletsMax } = this.props.refsPromise.value;

  if (isLessThan(platelets, plateletsMin)) {
    return "Trombocitopenia";
  }

  if (isGreaterThan(platelets, plateletsMax)) {
    return "Trombocitose";
  }

  return "-";
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Returns 0 if intense leucopeny
 *
 * @return {mixed}
 *
 */
export function zeroIfIntenseLeucopeny(value) {
  return this.state.fields.isIntenseLeucopeny ? 0 : value;
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Creates report object containing basic report data
 *
 * @return {Object}
 *
 */
export function createReportObj() {
  const { vetSignerId, requestExamState } = this.state.fields;

  const requestExamId = this.props.match.params.requestExamId;

  return {
    requestExamId: requestExamId,
    vetSignerId: vetSignerId,
    requestExamState: requestExamState,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Creates hemogram object
 *
 * @return {Object}
 *
 */
export function createErythrogramObj() {
  const {
    erythrocytes,
    corpuscularVolume,
    hemoglobiometry,
    erythrogramObs,
    erythrogramDiagnosis,
  } = this.state.fields;

  const mcv = this.calcMCV();
  const mchc = this.calcMCHC();

  return {
    erythrocytes: erythrocytes,
    corpuscularVolume: corpuscularVolume,
    hemoglobiometry: hemoglobiometry,
    mcv: mcv,
    mchc: mchc,
    erythrogramDiagnosis: erythrogramDiagnosis
      ? erythrogramDiagnosis
      : this.erythrogramDiagnosis(),
    erythrogramObs: erythrogramObs,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Creates leukogram object
 *
 * @return {Object}
 *
 */
export function createLeukogramObj() {
  const {
    globalLeukometry,
    isIntenseLeucopeny,
    metarrubrycites,
    relativeBasophils,
    relativeEosinophils,
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    relativeSegs,
    relativeLymphocytes,
    relativeMonocytes,
    relativeHeterophils,
    leukogramObs,
  } = this.state.fields;

  const relBasophils = this.zeroIfIntenseLeucopeny(relativeBasophils);
  const relEosinophils = this.zeroIfIntenseLeucopeny(relativeEosinophils);
  const relLymphocytes = this.zeroIfIntenseLeucopeny(relativeLymphocytes);
  const relMonocytes = this.zeroIfIntenseLeucopeny(relativeMonocytes);
  const relHeterophils = this.zeroIfIntenseLeucopeny(relativeHeterophils);
  const metarrub = this.zeroIfIntenseLeucopeny(metarrubrycites);
  const relMyelocytes = this.zeroIfIntenseLeucopeny(relativeMyelocytes);
  const relMetamyelocytes = this.zeroIfIntenseLeucopeny(relativeMetamyelocytes);
  const relBands = this.zeroIfIntenseLeucopeny(relativeBands);
  const relSegs = this.zeroIfIntenseLeucopeny(relativeSegs);

  return {
    globalLeukometry: globalLeukometry,
    isIntenseLeucopeny: isIntenseLeucopeny ? true : false,
    metarrubrycites: metarrub,
    relativeBasophils: relBasophils,
    relativeEosinophils: relEosinophils,
    relativeMyelocytes: relMyelocytes,
    relativeMetamyelocytes: relMetamyelocytes,
    relativeBands: relBands,
    relativeSegs: relSegs,
    relativeLymphocytes: relLymphocytes,
    relativeMonocytes: relMonocytes,
    relativeHeterophils: relHeterophils,
    leukogramObs: leukogramObs,
    absoluteBasophils: this.calcAbsolute(relBasophils),
    absoluteEosinophils: this.calcAbsolute(relEosinophils),
    absoluteMyelocytes: this.calcAbsolute(relMyelocytes),
    absoluteMetamyelocytes: this.calcAbsolute(relMetamyelocytes),
    absoluteBands: this.calcAbsolute(relBands),
    absoluteSegs: this.calcAbsolute(relSegs),
    absoluteLymphocytes: this.calcAbsolute(relLymphocytes),
    absoluteMonocytes: this.calcAbsolute(relMonocytes),
    absoluteHeterophils: this.calcAbsolute(relHeterophils),
    leukogramDiagnosis: this.leukogramDiagnosis(),
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Creates platelets object
 *
 * @return {Object}
 *
 */
export function createPlateletsObj() {
  const { platelets, plateletsObs } = this.state.fields;

  return {
    plateletsObs: plateletsObs,
    platelets: platelets,
    plateletsDiagnosis: this.plateletsDiagnosis(),
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Creates hemogram object
 *
 * @return {Object}
 *
 */
export function createHemogramObj() {
  const { hemogramObs } = this.state.fields;

  return {
    ...this.createPlateletsObj(),
    ...this.createErythrogramObj(),
    ...this.createLeukogramObj(),
    hemogramObs: hemogramObs,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Creates leukogram object
 *
 * @return {Object}
 *
 */
export function createLeukogramUpdateState(obj) {
  const isIntenseLeucopeny = obj.isIntenseLeucopeny ? true : false;
  const shouldBlockCounterSound = !isIntenseLeucopeny;

  const format = (value) => {
    const isZero = 0 === value;

    if (isZero) {
      return isIntenseLeucopeny ? "" : "0";
    }
    if (isEmpty(value)) return;

    return value.toString();
  };

  const globalLeukometry = format(obj.globalLeukometry);

  const metarrubrycites = format(obj.metarrubrycites);

  const relativeBasophils = format(obj.relativeBasophils);

  const relativeEosinophils = format(obj.relativeEosinophils);

  const relativeMyelocytes = format(obj.relativeMyelocytes);

  const relativeMetamyelocytes = format(obj.relativeMetamyelocytes);

  const relativeBands = format(obj.relativeBands);

  const relativeSegs = format(obj.relativeSegs);

  const relativeLymphocytes = format(obj.relativeLymphocytes);

  const relativeMonocytes = format(obj.relativeMonocytes);

  const relativeHeterophils = format(obj.relativeHeterophils);

  return {
    fields: {
      globalLeukometry: { $set: globalLeukometry },
      isIntenseLeucopeny: { $set: isIntenseLeucopeny },
      metarrubrycites: { $set: metarrubrycites },
      relativeBasophils: { $set: relativeBasophils },
      relativeEosinophils: { $set: relativeEosinophils },
      relativeMyelocytes: { $set: relativeMyelocytes },
      relativeMetamyelocytes: { $set: relativeMetamyelocytes },
      relativeBands: { $set: relativeBands },
      relativeSegs: { $set: relativeSegs },
      relativeLymphocytes: { $set: relativeLymphocytes },
      relativeMonocytes: { $set: relativeMonocytes },
      relativeHeterophils: { $set: relativeHeterophils },
      leukogramObs: { $set: obj.leukogramObs },
      vetSignerId: { $set: obj.vetSignerId },
      requestExamState: { $set: obj.requestExamState },
      shouldBlockCounterSound: { $set: shouldBlockCounterSound },
    },
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Creates hemogram object
 *
 * @return {Object}
 *
 */
export function createHemogramUpdateState(obj) {
  const isIntenseLeucopeny = obj.isIntenseLeucopeny ? true : false;
  const shouldBlockCounterSound = !isIntenseLeucopeny;

  const format = (value) => {
    const isZero = 0 === value;

    if (isZero) {
      return isIntenseLeucopeny ? "" : "0";
    }

    switch (typeof value) {
      case "string":
        return value.toString();
      case "number":
        return value;

      default:
        return "";
    }
  };

  const erythrocytes = format(obj.erythrocytes);

  const corpuscularVolume = format(obj.corpuscularVolume);

  const hemoglobiometry = format(obj.hemoglobiometry);

  const globalLeukometry = format(obj.globalLeukometry);

  const metarrubrycites = format(obj.metarrubrycites);

  const relativeBasophils = format(obj.relativeBasophils);

  const relativeEosinophils = format(obj.relativeEosinophils);

  const relativeMyelocytes = format(obj.relativeMyelocytes);

  const relativeMetamyelocytes = format(obj.relativeMetamyelocytes);

  const relativeBands = format(obj.relativeBands);

  const relativeSegs = format(obj.relativeSegs);

  const relativeLymphocytes = format(obj.relativeLymphocytes);

  const relativeMonocytes = format(obj.relativeMonocytes);

  const relativeHeterophils = format(obj.relativeHeterophils);

  const platelets = format(obj.platelets);

  return {
    fields: {
      erythrocytes: { $set: erythrocytes },
      corpuscularVolume: { $set: corpuscularVolume },
      hemoglobiometry: { $set: hemoglobiometry },
      erythrogramObs: { $set: obj.erythrogramObs },
      globalLeukometry: { $set: globalLeukometry },
      isIntenseLeucopeny: { $set: isIntenseLeucopeny },
      metarrubrycites: { $set: metarrubrycites },
      relativeBasophils: { $set: relativeBasophils },
      relativeEosinophils: { $set: relativeEosinophils },
      relativeMyelocytes: { $set: relativeMyelocytes },
      relativeMetamyelocytes: { $set: relativeMetamyelocytes },
      relativeBands: { $set: relativeBands },
      relativeSegs: { $set: relativeSegs },
      relativeLymphocytes: { $set: relativeLymphocytes },
      relativeMonocytes: { $set: relativeMonocytes },
      relativeHeterophils: { $set: relativeHeterophils },
      platelets: { $set: platelets },
      leukogramObs: { $set: obj.leukogramObs },
      plateletsObs: { $set: obj.plateletsObs },
      vetSignerId: { $set: obj.vetSignerId },
      requestExamState: { $set: obj.requestExamState },
      hemogramObs: { $set: obj.hemogramObs },
      shouldBlockCounterSound: { $set: shouldBlockCounterSound },
    },
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Corpuscular Volume reference object
 *
 * @return {Object}
 *
 */
export function createCorpuscularVolumeRefObj(data) {
  return {
    corpuscularVolumeMin: data.corpuscularVolumeMin,
    corpuscularVolumeMax: data.corpuscularVolumeMax,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Erythrogram reference object
 *
 * @return {Object}
 *
 */
export function createErythrogramRefObj(data) {
  return {
    ...createCorpuscularVolumeRefObj(data),
    erythrocytesMin: data.erythrocytesMin,
    erythrocytesMax: data.erythrocytesMax,
    hemoglobiometryMin: data.hemoglobiometryMin,
    hemoglobiometryMax: data.hemoglobiometryMax,
    mcvMin: data.mcvMin,
    mcvMax: data.mcvMax,
    mchcMin: data.mchcMin,
    mchcMax: data.mchcMax,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Leukogram reference object
 *
 * @return {Object}
 *
 */
export function createLeukogramRefObj(data) {
  return {
    globalLeukometryMin: data.globalLeukometryMin,
    globalLeukometryMax: data.globalLeukometryMax,
    relativeBasophilsMin: data.relativeBasophilsMin,
    relativeBasophilsMax: data.relativeBasophilsMax,
    absoluteBasophilsMin: data.absoluteBasophilsMin,
    absoluteBasophilsMax: data.absoluteBasophilsMax,
    relativeEosinophilsMin: data.relativeEosinophilsMin,
    relativeEosinophilsMax: data.relativeEosinophilsMax,
    absoluteEosinophilsMin: data.absoluteEosinophilsMin,
    absoluteEosinophilsMax: data.absoluteEosinophilsMax,
    relativeMyelocytesMin: data.relativeMyelocytesMin,
    relativeMyelocytesMax: data.relativeMyelocytesMax,
    absoluteMyelocytesMin: data.absoluteMyelocytesMin,
    absoluteMyelocytesMax: data.absoluteMyelocytesMax,
    relativeMetamyelocytesMin: data.relativeMetamyelocytesMin,
    relativeMetamyelocytesMax: data.relativeMetamyelocytesMax,
    absoluteMetamyelocytesMin: data.absoluteMetamyelocytesMin,
    absoluteMetamyelocytesMax: data.absoluteMetamyelocytesMax,
    relativeBandsMin: data.relativeBandsMin,
    relativeBandsMax: data.relativeBandsMax,
    absoluteBandsMin: data.absoluteBandsMin,
    absoluteBandsMax: data.absoluteBandsMax,
    relativeSegsMin: data.relativeSegsMin,
    relativeSegsMax: data.relativeSegsMax,
    absoluteSegsMin: data.absoluteSegsMin,
    absoluteSegsMax: data.absoluteSegsMax,
    relativeLymphocytesMin: data.relativeLymphocytesMin,
    relativeLymphocytesMax: data.relativeLymphocytesMax,
    absoluteLymphocytesMin: data.absoluteLymphocytesMin,
    absoluteLymphocytesMax: data.absoluteLymphocytesMax,
    relativeMonocytesMin: data.relativeMonocytesMin,
    relativeMonocytesMax: data.relativeMonocytesMax,
    absoluteMonocytesMin: data.absoluteMonocytesMin,
    absoluteMonocytesMax: data.absoluteMonocytesMax,
    relativeHeterophilsMin: data.relativeHeterophilsMin,
    relativeHeterophilsMax: data.relativeHeterophilsMax,
    absoluteHeterophilsMin: data.absoluteHeterophilsMin,
    absoluteHeterophilsMax: data.absoluteHeterophilsMax,
    hasHeterophils: data.hasHeterophils,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Platelets reference object
 *
 * @return {Object}
 *
 */
export function createPlateletsRefObj(data) {
  return {
    plateletsMin: data.plateletsMin,
    plateletsMax: data.plateletsMax,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Hemogram reference object
 *
 * @return {Object}
 *
 */
export function createHemogramRefObj(data) {
  return {
    ...createErythrogramRefObj(data),
    ...createLeukogramRefObj(data),
    ...createPlateletsRefObj(data),
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Corpuscular Volume reference object
 *
 * @return {Object}
 *
 */
export function createBloodProteinRefObj(data) {
  return {
    bloodProteinMin: data.bloodProteinMin,
    bloodProteinMax: data.bloodProteinMax,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Corpuscular Volume reference object
 *
 * @return {Object}
 *
 */
export function createFibrinogenRefObj(data) {
  return {
    fibrinogenMin: data.fibrinogenMin,
    fibrinogenMax: data.fibrinogenMax,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Cross match reference object
 *
 * @return {Object}
 *
 */
export function createCrossMatchRefObj(data) {
  return {
    ...createCorpuscularVolumeRefObj(data),
    ...createBloodProteinRefObj(data),
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Cross match reference object
 *
 * @return {Object}
 *
 */
export function createReticulocyteCountRefObj(data) {
  return {
    normalMin: data.reticCountFractionNormalMin,
    normalMax: data.reticCountFractionNormalMax,
    lowMin: data.reticCountFractionLowMin,
    lowMax: data.reticCountFractionLowMax,
    moderateMin: data.reticCountFractionModerateMin,
    moderateMax: data.reticCountFractionModerateMax,
    highMin: data.reticCountFractionHighMin,
    highMax: data.reticCountFractionHighMax,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Cross match reference object
 *
 * @return {Object}
 *
 */
export function createCoagulationTestRefObj(data) {
  return {
    tromboplastinTimeMin: data.tromboplastinTimeMin,
    tromboplastinTimeMax: data.tromboplastinTimeMax,
    prothrombinTimeMin: data.prothrombinTimeMin,
    prothrombinTimeMax: data.prothrombinTimeMax,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Cross match reference object
 *
 * @return {Object}
 *
 */
export function createUrinaryGgtRefObj(data) {
  return {
    min: data.urinaryGgtMin,
    max: data.urinaryGgtMax,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Biochemistry reference object
 *
 * @return {Object}
 *
 */
export function createBiochemistryRefObj(data) {
  return {
    ureaMin: (data && data.ureaMin) || "",
    ureaMax: (data && data.ureaMax) || "",
    ureaUnit: (data && data.ureaUnit) || "",
    altTgpMin: (data && data.altTgpMin) || "",
    altTgpMax: (data && data.altTgpMax) || "",
    altTgpUnit: (data && data.altTgpUnit) || "",
    astTgoMin: (data && data.astTgoMin) || "",
    astTgoMax: (data && data.astTgoMax) || "",
    astTgoUnit: (data && data.astTgoUnit) || "",
    ggtMin: (data && data.ggtMin) || "",
    ggtMax: (data && data.ggtMax) || "",
    ggtUnit: (data && data.ggtUnit) || "",
    phMin: (data && data.phMin) || "",
    phMax: (data && data.phMax) || "",
    phUnit: (data && data.phUnit) || "",
    chlorideMin: (data && data.chlorideMin) || "",
    chlorideMax: (data && data.chlorideMax) || "",
    chlorideUnit: (data && data.chlorideUnit) || "",
    creatinineMin: (data && data.creatinineMin) || "",
    creatinineMax: (data && data.creatinineMax) || "",
    creatinineUnit: (data && data.creatinineUnit) || "",
    alkalinePhosphataseMin: (data && data.alkalinePhosphataseMin) || "",
    alkalinePhosphataseMax: (data && data.alkalinePhosphataseMax) || "",
    alkalinePhosphataseUnit: (data && data.alkalinePhosphataseUnit) || "",
    totalProteinMin: (data && data.totalProteinMin) || "",
    totalProteinMax: (data && data.totalProteinMax) || "",
    totalProteinUnit: (data && data.totalProteinUnit) || "",
    totalCholesterolMin: (data && data.totalCholesterolMin) || "",
    totalCholesterolMax: (data && data.totalCholesterolMax) || "",
    totalCholesterolUnit: (data && data.totalCholesterolUnit) || "",
    albuminMin: (data && data.albuminMin) || "",
    albuminMax: (data && data.albuminMax) || "",
    albuminUnit: (data && data.albuminUnit) || "",
    glucoseMin: (data && data.glucoseMin) || "",
    glucoseMax: (data && data.glucoseMax) || "",
    glucoseUnit: (data && data.glucoseUnit) || "",
    lipaseMin: (data && data.lipaseMin) || "",
    lipaseMax: (data && data.lipaseMax) || "",
    lipaseUnit: (data && data.lipaseUnit) || "",
    amylaseMin: (data && data.amylaseMin) || "",
    amylaseMax: (data && data.amylaseMax) || "",
    amylaseUnit: (data && data.amylaseUnit) || "",
    calciumMin: (data && data.calciumMin) || "",
    calciumMax: (data && data.calciumMax) || "",
    calciumUnit: (data && data.calciumUnit) || "",
    sodiumMin: (data && data.sodiumMin) || "",
    sodiumMax: (data && data.sodiumMax) || "",
    sodiumUnit: (data && data.sodiumUnit) || "",
    potassiumMin: (data && data.potassiumMin) || "",
    potassiumMax: (data && data.potassiumMax) || "",
    potassiumUnit: (data && data.potassiumUnit) || "",
    magnesiumMin: (data && data.magnesiumMin) || "",
    magnesiumMax: (data && data.magnesiumMax) || "",
    magnesiumUnit: (data && data.magnesiumUnit) || "",
    phosphorusMin: (data && data.phosphorusMin) || "",
    phosphorusMax: (data && data.phosphorusMax) || "",
    phosphorusUnit: (data && data.phosphorusUnit) || "",
    triglyceridesMin: (data && data.triglyceridesMin) || "",
    triglyceridesMax: (data && data.triglyceridesMax) || "",
    triglyceridesUnit: (data && data.triglyceridesUnit) || "",
    ionicCalciumCalciumMin: data && data.ionicCalciumCalciumMin,
    ionicCalciumCalciumMax: data && data.ionicCalciumCalciumMax,
    ionicCalciumCalciumUnit: data && data.ionicCalciumCalciumUnit,
    ionicCalciumAlbuminMin: data && data.ionicCalciumAlbuminMin,
    ionicCalciumAlbuminMax: data && data.ionicCalciumAlbuminMax,
    ionicCalciumAlbuminUnit: data && data.ionicCalciumAlbuminUnit,
    ionicCalciumTotalProteinMin: data && data.ionicCalciumTotalProteinMin,
    ionicCalciumTotalProteinMax: data && data.ionicCalciumTotalProteinMax,
    ionicCalciumTotalProteinUnit: data && data.ionicCalciumTotalProteinUnit,
    ionicCalciumIonicCalciumMin: data && data.ionicCalciumIonicCalciumMin,
    ionicCalciumIonicCalciumMax: data && data.ionicCalciumIonicCalciumMax,
    ionicCalciumIonicCalciumUnit: data && data.ionicCalciumIonicCalciumUnit,
    totalProteinAndFractionsTotalProteinMin:
      data && data.totalProteinAndFractionsTotalProteinMin,
    totalProteinAndFractionsTotalProteinMax:
      data && data.totalProteinAndFractionsTotalProteinMax,
    totalProteinAndFractionsTotalProteinUnit:
      data && data.totalProteinAndFractionsTotalProteinUnit,
    totalProteinAndFractionsAlbuminMin:
      data && data.totalProteinAndFractionsAlbuminMin,
    totalProteinAndFractionsAlbuminMax:
      data && data.totalProteinAndFractionsAlbuminMax,
    totalProteinAndFractionsAlbuminUnit:
      data && data.totalProteinAndFractionsAlbuminUnit,
    totalProteinAndFractionsGlobulinMin:
      data && data.totalProteinAndFractionsGlobulinMin,
    totalProteinAndFractionsGlobulinMax:
      data && data.totalProteinAndFractionsGlobulinMax,
    totalProteinAndFractionsGlobulinUnit:
      data && data.totalProteinAndFractionsGlobulinUnit,
    
    relationAlbuminGlobulinResultMin:
      data && data.relationAlbuminGlobulinResultMin,
    relationAlbuminGlobulinResultMax:
      data && data.relationAlbuminGlobulinResultMax,
    relationAlbuminGlobulinResultUnit:
      data && data.relationAlbuminGlobulinResultUnit,
    relationAlbuminGlobulinAlbuminMin:
      data && data.relationAlbuminGlobulinAlbuminMin,
    relationAlbuminGlobulinAlbuminMax:
      data && data.relationAlbuminGlobulinAlbuminMax,
    relationAlbuminGlobulinAlbuminUnit:
      data && data.relationAlbuminGlobulinAlbuminUnit,
    relationAlbuminGlobulinGlobulinMin:
      data && data.relationAlbuminGlobulinGlobulinMin,
    relationAlbuminGlobulinGlobulinMax:
      data && data.relationAlbuminGlobulinGlobulinMax,
    relationAlbuminGlobulinGlobulinUnit:
      data && data.relationAlbuminGlobulinGlobulinUnit,
    
    totalCholesterolAndFractionsTotalCholesterolMin:
      (data && data.totalCholesterolAndFractionsTotalCholesterolMin) || "",
    totalCholesterolAndFractionsTotalCholesterolMax:
      (data && data.totalCholesterolAndFractionsTotalCholesterolMax) || "",
    totalCholesterolAndFractionsTotalCholesterolUnit:
      (data && data.totalCholesterolAndFractionsTotalCholesterolUnit) || "",
    totalCholesterolAndFractionsHdlMin:
      (data && data.totalCholesterolAndFractionsHdlMin) || "",
    totalCholesterolAndFractionsHdlMax:
      (data && data.totalCholesterolAndFractionsHdlMax) || "",
    totalCholesterolAndFractionsHdlUnit:
      (data && data.totalCholesterolAndFractionsHdlUnit) || "",
    totalCholesterolAndFractionsLdlMin:
      (data && data.totalCholesterolAndFractionsLdlMin) || "",
    totalCholesterolAndFractionsLdlMax:
      (data && data.totalCholesterolAndFractionsLdlMax) || "",
    totalCholesterolAndFractionsLdlUnit:
      (data && data.totalCholesterolAndFractionsLdlUnit) || "",
    totalCholesterolAndFractionsVldlMin:
      (data && data.totalCholesterolAndFractionsVldlMin) || "",
    totalCholesterolAndFractionsVldlMax:
      (data && data.totalCholesterolAndFractionsVldlMax) || "",
    totalCholesterolAndFractionsVldlUnit:
      (data && data.totalCholesterolAndFractionsVldlUnit) || "",
    totalCholesterolAndFractionsTriglyceridesMin:
      (data && data.totalCholesterolAndFractionsTriglyceridesMin) || "",
    totalCholesterolAndFractionsTriglyceridesMax:
      (data && data.totalCholesterolAndFractionsTriglyceridesMax) || "",
    totalCholesterolAndFractionsTriglyceridesUnit:
      (data && data.totalCholesterolAndFractionsTriglyceridesUnit) || "",
    bilirubinAndFractionsTotalMin:
      (data && data.bilirubinAndFractionsTotalMin) || "",
    bilirubinAndFractionsTotalMax:
      (data && data.bilirubinAndFractionsTotalMax) || "",
    bilirubinAndFractionsTotalUnit:
      (data && data.bilirubinAndFractionsTotalUnit) || "",
    bilirubinAndFractionsDirectMin:
      (data && data.bilirubinAndFractionsDirectMin) || "",
    bilirubinAndFractionsDirectMax:
      (data && data.bilirubinAndFractionsDirectMax) || "",
    bilirubinAndFractionsDirectUnit:
      (data && data.bilirubinAndFractionsDirectUnit) || "",
    bilirubinAndFractionsIndirectMin:
      (data && data.bilirubinAndFractionsIndirectMin) || "",
    bilirubinAndFractionsIndirectMax:
      (data && data.bilirubinAndFractionsIndirectMax) || "",
    bilirubinAndFractionsIndirectUnit:
      (data && data.bilirubinAndFractionsIndirectUnit) || "",
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * @return {Object}
 *
 */
export function createChemiluminescenceRefObj(data) {
  return {
    chemiluminescenceCortisolMin:
      (data && data.chemiluminescenceCortisolMin) || "",
    chemiluminescenceCortisolMax:
      (data && data.chemiluminescenceCortisolMax) || "",
    chemiluminescenceCortisolUnit:
      (data && data.chemiluminescenceCortisolUnit) || "",
    chemiluminescenceCortisol4HAfterActhStimuliMin:
      (data && data.chemiluminescenceCortisol4HAfterActhStimuliMin) || "",
    chemiluminescenceCortisol4HAfterActhStimuliMax:
      (data && data.chemiluminescenceCortisol4HAfterActhStimuliMax) || "",
    chemiluminescenceCortisol4HAfterActhStimuliUnit:
      (data && data.chemiluminescenceCortisol4HAfterActhStimuliUnit) || "",
    chemiluminescenceCortisol4HAfterDexamethasoneMin:
      (data && data.chemiluminescenceCortisol4HAfterDexamethasoneMin) || "",
    chemiluminescenceCortisol4HAfterDexamethasoneMax:
      (data && data.chemiluminescenceCortisol4HAfterDexamethasoneMax) || "",
    chemiluminescenceCortisol4HAfterDexamethasoneUnit:
      (data && data.chemiluminescenceCortisol4HAfterDexamethasoneUnit) || "",
    chemiluminescenceCortisol8HAfterDexamethasoneMin:
      (data && data.chemiluminescenceCortisol8HAfterDexamethasoneMin) || "",
    chemiluminescenceCortisol8HAfterDexamethasoneMax:
      (data && data.chemiluminescenceCortisol8HAfterDexamethasoneMax) || "",
    chemiluminescenceCortisol8HAfterDexamethasoneUnit:
      (data && data.chemiluminescenceCortisol8HAfterDexamethasoneUnit) || "",
    chemiluminescenceErythropoetinMin:
      (data && data.chemiluminescenceErythropoetinMin) || "",
    chemiluminescenceErythropoetinMax:
      (data && data.chemiluminescenceErythropoetinMax) || "",
    chemiluminescenceErythropoetinUnit:
      (data && data.chemiluminescenceErythropoetinUnit) || "",
    chemiluminescenceEstradiolMin:
      (data && data.chemiluminescenceEstradiolMin) || "",
    chemiluminescenceEstradiolMax:
      (data && data.chemiluminescenceEstradiolMax) || "",
    chemiluminescenceEstradiolUnit:
      (data && data.chemiluminescenceEstradiolUnit) || "",
    chemiluminescenceFreeT4Min: (data && data.chemiluminescenceFreeT4Min) || "",
    chemiluminescenceFreeT4Max: (data && data.chemiluminescenceFreeT4Max) || "",
    chemiluminescenceFreeT4Unit:
      (data && data.chemiluminescenceFreeT4Unit) || "",
    chemiluminescenceHfsMin: (data && data.chemiluminescenceHfsMin) || "",
    chemiluminescenceHfsMax: (data && data.chemiluminescenceHfsMax) || "",
    chemiluminescenceHfsUnit: (data && data.chemiluminescenceHfsUnit) || "",
    chemiluminescenceHypersensitiveActhMin:
      (data && data.chemiluminescenceHypersensitiveActhMin) || "",
    chemiluminescenceHypersensitiveActhMax:
      (data && data.chemiluminescenceHypersensitiveActhMax) || "",
    chemiluminescenceHypersensitiveActhUnit:
      (data && data.chemiluminescenceHypersensitiveActhUnit) || "",
    chemiluminescenceInsulinMin:
      (data && data.chemiluminescenceInsulinMin) || "",
    chemiluminescenceInsulinMax:
      (data && data.chemiluminescenceInsulinMax) || "",
    chemiluminescenceInsulinUnit:
      (data && data.chemiluminescenceInsulinUnit) || "",
    chemiluminescenceLevothyroxinAfterTotalT4Min:
      (data && data.chemiluminescenceLevothyroxinAfterTotalT4Min) || "",
    chemiluminescenceLevothyroxinAfterTotalT4Max:
      (data && data.chemiluminescenceLevothyroxinAfterTotalT4Max) || "",
    chemiluminescenceLevothyroxinAfterTotalT4Unit:
      (data && data.chemiluminescenceLevothyroxinAfterTotalT4Unit) || "",
    chemiluminescenceLhMin: (data && data.chemiluminescenceLhMin) || "",
    chemiluminescenceLhMax: (data && data.chemiluminescenceLhMax) || "",
    chemiluminescenceLhUnit: (data && data.chemiluminescenceLhUnit) || "",
    chemiluminescencePhenobarbitalMin:
      (data && data.chemiluminescencePhenobarbitalMin) || "",
    chemiluminescencePhenobarbitalMax:
      (data && data.chemiluminescencePhenobarbitalMax) || "",
    chemiluminescencePhenobarbitalUnit:
      (data && data.chemiluminescencePhenobarbitalUnit) || "",
    chemiluminescenceProgesteroneMin:
      (data && data.chemiluminescenceProgesteroneMin) || "",
    chemiluminescenceProgesteroneMax:
      (data && data.chemiluminescenceProgesteroneMax) || "",
    chemiluminescenceProgesteroneUnit:
      (data && data.chemiluminescenceProgesteroneUnit) || "",
    chemiluminescencePthMin: (data && data.chemiluminescencePthMin) || "",
    chemiluminescencePthMax: (data && data.chemiluminescencePthMax) || "",
    chemiluminescencePthUnit: (data && data.chemiluminescencePthUnit) || "",
    chemiluminescenceTestosteroneMin:
      (data && data.chemiluminescenceTestosteroneMin) || "",
    chemiluminescenceTestosteroneMax:
      (data && data.chemiluminescenceTestosteroneMax) || "",
    chemiluminescenceTestosteroneUnit:
      (data && data.chemiluminescenceTestosteroneUnit) || "",
    chemiluminescenceTotalT3Min:
      (data && data.chemiluminescenceTotalT3Min) || "",
    chemiluminescenceTotalT3Max:
      (data && data.chemiluminescenceTotalT3Max) || "",
    chemiluminescenceTotalT3Unit:
      (data && data.chemiluminescenceTotalT3Unit) || "",
    chemiluminescenceTotalT4Min:
      (data && data.chemiluminescenceTotalT4Min) || "",
    chemiluminescenceTotalT4Max:
      (data && data.chemiluminescenceTotalT4Max) || "",
    chemiluminescenceTotalT4Unit:
      (data && data.chemiluminescenceTotalT4Unit) || "",
    chemiluminescenceTshMin: (data && data.chemiluminescenceTshMin) || "",
    chemiluminescenceTshMax: (data && data.chemiluminescenceTshMax) || "",
    chemiluminescenceTshUnit: (data && data.chemiluminescenceTshUnit) || "",
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Biochemistry reference object
 *
 * @return {Object}
 *
 */
export function createRadioimmunoassayRefObj(data) {
  return {
    radioimmunoassayCortisolMin:
      (data && data.radioimmunoassayCortisolMin) || "",
    radioimmunoassayCortisolMax:
      (data && data.radioimmunoassayCortisolMax) || "",
    radioimmunoassayCortisolUnit:
      (data && data.radioimmunoassayCortisolUnit) || "",
    radioimmunoassayCortisol4HAfterActhStimuliMin:
      (data && data.radioimmunoassayCortisol4HAfterActhStimuliMin) || "",
    radioimmunoassayCortisol4HAfterActhStimuliMax:
      (data && data.radioimmunoassayCortisol4HAfterActhStimuliMax) || "",
    radioimmunoassayCortisol4HAfterActhStimuliUnit:
      (data && data.radioimmunoassayCortisol4HAfterActhStimuliUnit) || "",
    radioimmunoassayCortisol4HAfterDexamethasoneMin:
      (data && data.radioimmunoassayCortisol4HAfterDexamethasoneMin) || "",
    radioimmunoassayCortisol4HAfterDexamethasoneMax:
      (data && data.radioimmunoassayCortisol4HAfterDexamethasoneMax) || "",
    radioimmunoassayCortisol4HAfterDexamethasoneUnit:
      (data && data.radioimmunoassayCortisol4HAfterDexamethasoneUnit) || "",
    radioimmunoassayCortisol8HAfterDexamethasoneMin:
      (data && data.radioimmunoassayCortisol8HAfterDexamethasoneMin) || "",
    radioimmunoassayCortisol8HAfterDexamethasoneMax:
      (data && data.radioimmunoassayCortisol8HAfterDexamethasoneMax) || "",
    radioimmunoassayCortisol8HAfterDexamethasoneUnit:
      (data && data.radioimmunoassayCortisol8HAfterDexamethasoneUnit) || "",
    radioimmunoassayEstradiolMin:
      (data && data.radioimmunoassayEstradiolMin) || "",
    radioimmunoassayEstradiolMax:
      (data && data.radioimmunoassayEstradiolMax) || "",
    radioimmunoassayEstradiolUnit:
      (data && data.radioimmunoassayEstradiolUnit) || "",
    radioimmunoassayFreeT4Min: (data && data.radioimmunoassayFreeT4Min) || "",
    radioimmunoassayFreeT4Max: (data && data.radioimmunoassayFreeT4Max) || "",
    radioimmunoassayFreeT4Unit: (data && data.radioimmunoassayFreeT4Unit) || "",
    radioimmunoassayHypersensitiveActhMin:
      (data && data.radioimmunoassayHypersensitiveActhMin) || "",
    radioimmunoassayHypersensitiveActhMax:
      (data && data.radioimmunoassayHypersensitiveActhMax) || "",
    radioimmunoassayHypersensitiveActhUnit:
      (data && data.radioimmunoassayHypersensitiveActhUnit) || "",
    radioimmunoassayLevothyroxinAfterTotalT4Min:
      (data && data.radioimmunoassayLevothyroxinAfterTotalT4Min) || "",
    radioimmunoassayLevothyroxinAfterTotalT4Max:
      (data && data.radioimmunoassayLevothyroxinAfterTotalT4Max) || "",
    radioimmunoassayLevothyroxinAfterTotalT4Unit:
      (data && data.radioimmunoassayLevothyroxinAfterTotalT4Unit) || "",
    radioimmunoassayTotalT3Min: (data && data.radioimmunoassayTotalT3Min) || "",
    radioimmunoassayTotalT3Max: (data && data.radioimmunoassayTotalT3Max) || "",
    radioimmunoassayTotalT3Unit:
      (data && data.radioimmunoassayTotalT3Unit) || "",
    radioimmunoassayTotalT4Min: (data && data.radioimmunoassayTotalT4Min) || "",
    radioimmunoassayTotalT4Max: (data && data.radioimmunoassayTotalT4Max) || "",
    radioimmunoassayTotalT4Unit:
      (data && data.radioimmunoassayTotalT4Unit) || "",
    radioimmunoassayFreeT4AfterDialysisMin:
      (data && data.radioimmunoassayFreeT4AfterDialysisMin) || "",
    radioimmunoassayFreeT4AfterDialysisMax:
      (data && data.radioimmunoassayFreeT4AfterDialysisMax) || "",
    radioimmunoassayFreeT4AfterDialysisUnit:
      (data && data.radioimmunoassayFreeT4AfterDialysisUnit) || "",
  };
}

export function examTableHeaderFactory(obj) {
  let vets = {
    options: [],
    placeholder: "Carregando..",
  };

  let requestExamStates = {
    options: [],
    placeholder: "Carregando..",
  };

  let requestSampleNames = [];

  if (obj) {
    vets.options = obj.vets ? obj.vets : [];
    vets.placeholder = "Selecione";

    requestExamStates.options = obj.requestExamStates
      ? obj.requestExamStates
      : [];

    requestSampleNames = obj.requestSamples.map(
      (requestSample) => requestSample.name
    );

    requestExamStates.placeholder = "Selecione";

    return {
      animal: obj.animal || "",
      vets: vets,
      owner: obj.owner || "",
      species: obj.species || "",
      breed: obj.breed || "",
      age: obj.age || "",
      sex: obj.sex || "",
      requestSampleNames: requestSampleNames || "",
    };
  }

  return {
    animal: "",
    vets: vets,
    owner: "",
    species: "",
    breed: "",
    age: "",
    sex: "",
    requestSampleNames: requestSampleNames,
  };
}

export function biochemistryFactory(requestExam, refs) {
  let data = {};

  switch (requestExam.examId) {
    case EXAM_ID_BY_NAME["URIC_ACID"]:
      data = {
        result: "",
        refMin: refs.uricAcidMin,
        refMax: refs.uricAcidMax,
        refUnit: refs.uricAcidUnit,
      };
      break;
    case EXAM_ID_BY_NAME["ALBUMIN"]:
      data = {
        result: "",
        refMin: refs.albuminMin,
        refMax: refs.albuminMax,
        refUnit: refs.albuminUnit,
      };
      break;
    case EXAM_ID_BY_NAME["ALT_TGP"]:
      data = {
        result: "",
        refMin: refs.altTgpMin,
        refMax: refs.altTgpMax,
        refUnit: refs.altTgpUnit,
      };
      break;
    case EXAM_ID_BY_NAME["AMYLASE"]:
      data = {
        result: "",
        refMin: refs.amylaseMin,
        refMax: refs.amylaseMax,
        refUnit: refs.amylaseUnit,
      };
      break;
    case EXAM_ID_BY_NAME["AST_TGO"]:
      data = {
        result: "",
        refMin: refs.astTgoMin,
        refMax: refs.astTgoMax,
        refUnit: refs.astTgoUnit,
      };
      break;
    case EXAM_ID_BY_NAME["CALCIUM"]:
      data = {
        result: "",
        refMin: refs.calciumMin,
        refMax: refs.calciumMax,
        refUnit: refs.calciumUnit,
      };
      break;
    case EXAM_ID_BY_NAME["IONIC_CALCIUM"]:
      data = {
        result: "",
        calcium: "",
        albumin: "",
        totalProtein: "",
        ionicCalcium: "",
        isResultEditable: false,
        refMin: refs.ionicCalciumIonicCalciumMin,
        refMax: refs.ionicCalciumIonicCalciumMax,
        refUnit: refs.ionicCalciumIonicCalciumUnit,
        refCalciumMin: refs.calciumMin,
        refCalciumMax: refs.calciumMax,
        refCalciumUnit: refs.calciumUnit,
        refAlbuminMin: refs.albuminMin,
        refAlbuminMax: refs.albuminMax,
        refAlbuminUnit: refs.albuminUnit,
        refTotalProteinMin: refs.totalProteinMin,
        refTotalProteinMax: refs.totalProteinMax,
        refTotalProteinUnit: refs.totalProteinUnit,
      };
      break;
    case EXAM_ID_BY_NAME["CHLORIDE"]:
      data = {
        result: "",
        refMin: refs.chlorideMin,
        refMax: refs.chlorideMax,
        refUnit: refs.chlorideUnit,
      };
      break;
    case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL"]:
      data = {
        result: "",
        refMin: refs.totalCholesterolMin,
        refMax: refs.totalCholesterolMax,
        refUnit: refs.totalCholesterolUnit,
      };
      break;
    case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]:
      data = {
        totalCholesterol: "",
        hdl: "",
        triglycerides: "",
        totalCholesterolRefMin:
          refs.totalCholesterolAndFractionsTotalCholesterolMin,
        totalCholesterolRefMax:
          refs.totalCholesterolAndFractionsTotalCholesterolMax,
        totalCholesterolRefUnit:
          refs.totalCholesterolAndFractionsTotalCholesterolUnit,
        hdlRefMin: refs.totalCholesterolAndFractionsHdlMin,
        hdlRefMax: refs.totalCholesterolAndFractionsHdlMax,
        hdlRefUnit: refs.totalCholesterolAndFractionsHdlUnit,
        ldlRefMin: refs.totalCholesterolAndFractionsLdlMin,
        ldlRefMax: refs.totalCholesterolAndFractionsLdlMax,
        ldlRefUnit: refs.totalCholesterolAndFractionsLdlUnit,
        vldlRefMin: refs.totalCholesterolAndFractionsVldlMin,
        vldlRefMax: refs.totalCholesterolAndFractionsVldlMax,
        vldlRefUnit: refs.totalCholesterolAndFractionsVldlUnit,
        triglyceridesRefMin: refs.totalCholesterolAndFractionsTriglyceridesMin,
        triglyceridesRefMax: refs.totalCholesterolAndFractionsTriglyceridesMax,
        triglyceridesRefUnit:
          refs.totalCholesterolAndFractionsTriglyceridesUnit,
      };
      break;
    case EXAM_ID_BY_NAME["CREATININE"]:
      data = {
        result: "",
        refMin: refs.creatinineMin,
        refMax: refs.creatinineMax,
        refUnit: refs.creatinineUnit,
      };
      break;
    case EXAM_ID_BY_NAME["CPK"]:
      data = {
        result: "",
        refMin: refs.cpkMin,
        refMax: refs.cpkMax,
        refUnit: refs.cpkUnit,
      };
      break;
    case EXAM_ID_BY_NAME["UREA"]:
      data = {
        result: "",
        refMin: refs.ureaMin,
        refMax: refs.ureaMax,
        refUnit: refs.ureaUnit,
      };
      break;
    case EXAM_ID_BY_NAME["IRON"]:
      data = {
        result: "",
        refMin: refs.ironMin,
        refMax: refs.ironMax,
        refUnit: refs.ironUnit,
      };
      break;
    case EXAM_ID_BY_NAME["ALKALINE_PHOSPHATASE"]:
      data = {
        result: "",
        refMin: refs.alkalinePhosphataseMin,
        refMax: refs.alkalinePhosphataseMax,
        refUnit: refs.alkalinePhosphataseUnit,
      };
      break;
    case EXAM_ID_BY_NAME["PHOSPHORUS"]:
      data = {
        result: "",
        refMin: refs.phosphorusMin,
        refMax: refs.phosphorusMax,
        refUnit: refs.phosphorusUnit,
      };
      break;
    case EXAM_ID_BY_NAME["FRUCTOSAMINE"]:
      data = {
        result: "",
        refMin: refs.fructosamineMin,
        refMax: refs.fructosamineMax,
        refUnit: refs.fructosamineUnit,
      };
      break;
    case EXAM_ID_BY_NAME["GGT"]:
      data = {
        result: "",
        refMin: refs.ggtMin,
        refMax: refs.ggtMax,
        refUnit: refs.ggtUnit,
      };
      break;
    case EXAM_ID_BY_NAME["GLUCOSE"]:
      data = {
        result: "",
        refMin: refs.glucoseMin,
        refMax: refs.glucoseMax,
        refUnit: refs.glucoseUnit,
      };
      break;
    case EXAM_ID_BY_NAME["GLYCATED_HEMOGLOBIN"]:
      data = {
        result: "",
        refMin: refs.glycatedHemoglobinMin,
        refMax: refs.glycatedHemoglobinMax,
        refUnit: refs.glycatedHemoglobinUnit,
      };
      break;
    case EXAM_ID_BY_NAME["LACTATE"]:
      data = {
        result: "",
        refMin: refs.lactateMin,
        refMax: refs.lactateMax,
        refUnit: refs.lactateUnit,
      };
      break;
    case EXAM_ID_BY_NAME["LIPASE"]:
      data = {
        result: "",
        refMin: refs.lipaseMin,
        refMax: refs.lipaseMax,
        refUnit: refs.lipaseUnit,
      };
      break;
    case EXAM_ID_BY_NAME["IMMUNOREACTIVE_LIPASE"]:
      data = {
        result: "",
        refMin: refs.immunoreactiveLipaseMin,
        refMax: refs.immunoreactiveLipaseMax,
        refUnit: refs.immunoreactiveLipaseUnit,
      };
      break;
    case EXAM_ID_BY_NAME["MAGNESIUM"]:
      data = {
        result: "",
        refMin: refs.magnesiumMin,
        refMax: refs.magnesiumMax,
        refUnit: refs.magnesiumUnit,
      };
      break;
    case EXAM_ID_BY_NAME["PH"]:
      data = {
        result: "",
        refMin: refs.phMin,
        refMax: refs.phMax,
        refUnit: refs.phUnit,
      };
      break;
    case EXAM_ID_BY_NAME["POTASSIUM"]:
      data = {
        result: "",
        refMin: refs.potassiumMin,
        refMax: refs.potassiumMax,
        refUnit: refs.potassiumUnit,
      };
      break;
    case EXAM_ID_BY_NAME["TOTAL_PROTEIN"]:
      data = {
        result: "",
        refMin: refs.totalProteinMin,
        refMax: refs.totalProteinMax,
        refUnit: refs.totalProteinUnit,
      };
      break;
    case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]:
      data = {
        totalProtein: "",
        albumin: "",
        globulin: "",
        refTotalProteinMin: refs.totalProteinAndFractionsTotalProteinMin,
        refTotalProteinMax: refs.totalProteinAndFractionsTotalProteinMax,
        refTotalProteinUnit: refs.totalProteinAndFractionsTotalProteinUnit,
        refAlbuminMin: refs.totalProteinAndFractionsAlbuminMin,
        refAlbuminMax: refs.totalProteinAndFractionsAlbuminMax,
        refAlbuminUnit: refs.totalProteinAndFractionsAlbuminUnit,
        refGlobulinMin: refs.totalProteinAndFractionsGlobulinMin,
        refGlobulinMax: refs.totalProteinAndFractionsGlobulinMax,
        refGlobulinUnit: refs.totalProteinAndFractionsGlobulinUnit,
      };
      break;
    case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]:
      data = {
        total: "",
        direct: "",
        indirect: "",
        refTotalMin: refs.bilirubinAndFractionsTotalMin,
        refTotalMax: refs.bilirubinAndFractionsTotalMax,
        refTotalUnit: refs.bilirubinAndFractionsTotalUnit,
        refDirectMin: refs.bilirubinAndFractionsDirectMin,
        refDirectMax: refs.bilirubinAndFractionsDirectMax,
        refDirectUnit: refs.bilirubinAndFractionsDirectUnit,
        refIndirectMin: refs.bilirubinAndFractionsIndirectMin,
        refIndirectMax: refs.bilirubinAndFractionsIndirectMax,
        refIndirectUnit: refs.bilirubinAndFractionsIndirectUnit,
      };
      break;
    case EXAM_ID_BY_NAME["SODIUM"]:
      data = {
        result: "",
        refMin: refs.sodiumMin,
        refMax: refs.sodiumMax,
        refUnit: refs.sodiumUnit,
      };
      break;
    case EXAM_ID_BY_NAME["TRIGLYCERIDES"]:
      data = {
        result: "",
        refMin: refs.triglyceridesMin,
        refMax: refs.triglyceridesMax,
        refUnit: refs.triglyceridesUnit,
      };
      break;
    case EXAM_ID_BY_NAME["LDH"]:
      data = {
        result: "",
        refMin: refs.ldhMin,
        refMax: refs.ldhMax,
        refUnit: refs.ldhUnit,
      };
      break;
    case EXAM_ID_BY_NAME["TLI"]:
      data = {
        result: "",
        refMin: refs.tliMin,
        refMax: refs.tliMax,
        refUnit: refs.tliUnit,
      };
      break;
    case EXAM_ID_BY_NAME["ELECTROLYTES"]:
      data = {
        sodium: "",
        refSodiumMin: refs.sodiumMin,
        refSodiumMax: refs.sodiumMax,
        refSodiumUnit: refs.sodiumUnit,
        potassium: "",
        refPotassiumMin: refs.potassiumMin,
        refPotassiumMax: refs.potassiumMax,
        refPotassiumUnit: refs.potassiumUnit,
        phosphorus: "",
        refPhosphorusMin: refs.phosphorusMin,
        refPhosphorusMax: refs.phosphorusMax,
        refPhosphorusUnit: refs.phosphorusUnit,
        chloride: "",
        refChlorideMin: refs.chlorideMin,
        refChlorideMax: refs.chlorideMax,
        refChlorideUnit: refs.chlorideUnit,
        calcium: "",
        albumin: "",
        totalProtein: "",
        isResultEditable: false,
        ionicCalcium: "",
        refMin: refs.ionicCalciumIonicCalciumMin,
        refMax: refs.ionicCalciumIonicCalciumMax,
        refUnit: refs.ionicCalciumIonicCalciumUnit,
        refCalciumMin: refs.calciumMin,
        refCalciumMax: refs.calciumMax,
        refCalciumUnit: refs.calciumUnit,
        refAlbuminMin: refs.albuminMin,
        refAlbuminMax: refs.albuminMax,
        refAlbuminUnit: refs.albuminUnit,
        refTotalProteinMin: refs.totalProteinMin,
        refTotalProteinMax: refs.totalProteinMax,
        refTotalProteinUnit: refs.totalProteinUnit,
      };
  }

  return {
    id: 0,
    examId: requestExam.examId,
    name: requestExam.name,
    examTypeId: requestExam.examTypeId,
    requestExamId: requestExam.id,
    requestExamState: requestExam.requestExamState,
    endDate: requestExam.endDate,
    deadline: requestExam.deadline,
    ...data,
  };
}

export function endocrinologyFactory(requestExam, refs) {
  let data = {};

  switch (requestExam.examId) {
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_CORTISOL"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceCortisolMin,
        refMax: refs.chemiluminescenceCortisolMax,
        refUnit: refs.chemiluminescenceCortisolUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_CORTISOL_4H_AFTER_ACTH_STIMULI"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceCortisol4HAfterActhStimuliMin,
        refMax: refs.chemiluminescenceCortisol4HAfterActhStimuliMax,
        refUnit: refs.chemiluminescenceCortisol4HAfterActhStimuliUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_CORTISOL_4H_AFTER_DEXAMETHASONE"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceCortisol4HAfterDexamethasoneMin,
        refMax: refs.chemiluminescenceCortisol4HAfterDexamethasoneMax,
        refUnit: refs.chemiluminescenceCortisol4HAfterDexamethasoneUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_CORTISOL_8H_AFTER_DEXAMETHASONE"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceCortisol8HAfterDexamethasoneMin,
        refMax: refs.chemiluminescenceCortisol8HAfterDexamethasoneMax,
        refUnit: refs.chemiluminescenceCortisol8HAfterDexamethasoneUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_ERYTHROPOETIN"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceErythropoetinMin,
        refMax: refs.chemiluminescenceErythropoetinMax,
        refUnit: refs.chemiluminescenceErythropoetinUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_ESTRADIOL"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceEstradiolMin,
        refMax: refs.chemiluminescenceEstradiolMax,
        refUnit: refs.chemiluminescenceEstradiolUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_FREE_T4"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceFreeT4Min,
        refMax: refs.chemiluminescenceFreeT4Max,
        refUnit: refs.chemiluminescenceFreeT4Unit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_HFS"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceHfsMin,
        refMax: refs.chemiluminescenceHfsMax,
        refUnit: refs.chemiluminescenceHfsUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_HYPERSENSITIVE_ACTH"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceHypersensitiveActhMin,
        refMax: refs.chemiluminescenceHypersensitiveActhMax,
        refUnit: refs.chemiluminescenceHypersensitiveActhUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_INSULIN"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceInsulinMin,
        refMax: refs.chemiluminescenceInsulinMax,
        refUnit: refs.chemiluminescenceInsulinUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_LH"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceLhMin,
        refMax: refs.chemiluminescenceLhMax,
        refUnit: refs.chemiluminescenceLhUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_TESTOSTERONE"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceTestosteroneMin,
        refMax: refs.chemiluminescenceTestosteroneMax,
        refUnit: refs.chemiluminescenceTestosteroneUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_PHENOBARBITAL"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescencePhenobarbitalMin,
        refMax: refs.chemiluminescencePhenobarbitalMax,
        refUnit: refs.chemiluminescencePhenobarbitalUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_PROGESTERONE"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceProgesteroneMin,
        refMax: refs.chemiluminescenceProgesteroneMax,
        refUnit: refs.chemiluminescenceProgesteroneUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_PTH"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescencePthMin,
        refMax: refs.chemiluminescencePthMax,
        refUnit: refs.chemiluminescencePthUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_TESTOSTERONE"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceTestosteroneMin,
        refMax: refs.chemiluminescenceTestosteroneMax,
        refUnit: refs.chemiluminescenceTestosteroneUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_TOTAL_T3"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceTotalT3Min,
        refMax: refs.chemiluminescenceTotalT3Max,
        refUnit: refs.chemiluminescenceTotalT3Unit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_TOTAL_T4"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceTotalT4Min,
        refMax: refs.chemiluminescenceTotalT4Max,
        refUnit: refs.chemiluminescenceTotalT4Unit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_LEVOTHYROXIN_AFTER_TOTAL_T4"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceLevothyroxinAfterTotalT4Min,
        refMax: refs.chemiluminescenceLevothyroxinAfterTotalT4Max,
        refUnit: refs.chemiluminescenceLevothyroxinAfterTotalT4Unit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_TSH"]: {
      data = {
        result: "",
        refMin: refs.chemiluminescenceTshMin,
        refMax: refs.chemiluminescenceTshMax,
        refUnit: refs.chemiluminescenceTshUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["RADIOIMMUNOASSAY_CORTISOL"]: {
      data = {
        result: "",
        refMin: refs.radioimmunoassayCortisolMin,
        refMax: refs.radioimmunoassayCortisolMax,
        refUnit: refs.radioimmunoassayCortisolUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["RADIOIMMUNOASSAY_CORTISOL_4H_AFTER_ACTH_STIMULI"]: {
      data = {
        result: "",
        refMin: refs.radioimmunoassayCortisol4HAfterActhStimuliMin,
        refMax: refs.radioimmunoassayCortisol4HAfterActhStimuliMax,
        refUnit: refs.radioimmunoassayCortisol4HAfterActhStimuliUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["RADIOIMMUNOASSAY_CORTISOL_4H_AFTER_DEXAMETHASONE"]: {
      data = {
        result: "",
        refMin: refs.radioimmunoassayCortisol4HAfterDexamethasoneMin,
        refMax: refs.radioimmunoassayCortisol4HAfterDexamethasoneMax,
        refUnit: refs.radioimmunoassayCortisol4HAfterDexamethasoneUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["RADIOIMMUNOASSAY_CORTISOL_8H_AFTER_DEXAMETHASONE"]: {
      data = {
        result: "",
        refMin: refs.radioimmunoassayCortisol8HAfterDexamethasoneMin,
        refMax: refs.radioimmunoassayCortisol8HAfterDexamethasoneMax,
        refUnit: refs.radioimmunoassayCortisol8HAfterDexamethasoneUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["RADIOIMMUNOASSAY_ESTRADIOL"]: {
      data = {
        result: "",
        refMin: refs.radioimmunoassayEstradiolMin,
        refMax: refs.radioimmunoassayEstradiolMax,
        refUnit: refs.radioimmunoassayEstradiolUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["RADIOIMMUNOASSAY_FREE_T4"]: {
      data = {
        result: "",
        refMin: refs.radioimmunoassayFreeT4Min,
        refMax: refs.radioimmunoassayFreeT4Max,
        refUnit: refs.radioimmunoassayFreeT4Unit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["RADIOIMMUNOASSAY_HYPERSENSITIVE_ACTH"]: {
      data = {
        result: "",
        refMin: refs.radioimmunoassayHypersensitiveActhMin,
        refMax: refs.radioimmunoassayHypersensitiveActhMax,
        refUnit: refs.radioimmunoassayHypersensitiveActhUnit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["RADIOIMMUNOASSAY_LEVOTHYROXIN_AFTER_TOTAL_T4"]: {
      data = {
        result: "",
        refMin: refs.radioimmunoassayLevothyroxinAfterTotalT4Min,
        refMax: refs.radioimmunoassayLevothyroxinAfterTotalT4Max,
        refUnit: refs.radioimmunoassayLevothyroxinAfterTotalT4Unit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["RADIOIMMUNOASSAY_TOTAL_T3"]: {
      data = {
        result: "",
        refMin: refs.radioimmunoassayTotalT3Min,
        refMax: refs.radioimmunoassayTotalT3Max,
        refUnit: refs.radioimmunoassayTotalT3Unit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["RADIOIMMUNOASSAY_TOTAL_T4"]: {
      data = {
        result: "",
        refMin: refs.radioimmunoassayTotalT4Min,
        refMax: refs.radioimmunoassayTotalT4Max,
        refUnit: refs.radioimmunoassayTotalT4Unit,
      };
      break;
    }
    case EXAM_ID_BY_NAME["RADIOIMMUNOASSAY_FREE_T4_AFTER_DIALYSIS"]: {
      data = {
        result: "",
        refMin: refs.radioimmunoassayFreeT4AfterDialysisMin,
        refMax: refs.radioimmunoassayFreeT4AfterDialysisMax,
        refUnit: refs.radioimmunoassayFreeT4AfterDialysisUnit,
      };
      break;
    }
  }

  return {
    id: 0,
    examId: requestExam.examId,
    name: requestExam.name,
    examTypeId: requestExam.examTypeId,
    requestExamId: requestExam.id,
    requestExamState: requestExam.requestExamState,
    endDate: requestExam.endDate,
    deadline: requestExam.deadline,
    ...data,
  };
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Toggles field
 *
 */
export function fieldToggle(field) {
  this.setState((state) =>
    update(state, {
      fields: {
        [field]: {
          $set: !this.state.fields[field],
        },
      },
    })
  );
}

export function getBiochemistryTableObjs(values, refs) {
  return values.map((value) => {
    switch (value.id) {
      case EXAM_ID_BY_NAME["UREA"]: {
        return {
          name: "Uréia",
          fieldName: "urea",
          result: value.result,
          refMin: refs.ureaMin,
          refMax: refs.ureaMax,
          refUnit: refs.ureaUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["CREATININE"]: {
        return {
          name: "Creatinina",
          fieldName: "creatinine",
          result: value.result,
          refMin: refs.creatinineMin,
          refMax: refs.creatinineMax,
          refUnit: refs.creatinineUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["ALT_TGP"]: {
        return {
          name: "ALT (TGP)",
          fieldName: "altTgp",
          result: value.result,
          refMin: refs.altTgpMin,
          refMax: refs.altTgpMax,
          refUnit: refs.altTgpUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["AST_TGO"]: {
        return {
          name: "AST (TGO)",
          fieldName: "astTgo",
          result: value.result,
          refMin: refs.astTgoMin,
          refMax: refs.astTgoMax,
          refUnit: refs.astTgoUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["ALKALINE_PHOSPHATASE"]: {
        return {
          name: "Fosfatase Alcalina",
          fieldName: "alkalinePhosphatase",
          result: value.result,
          refMin: refs.alkalinePhosphataseMin,
          refMax: refs.alkalinePhosphataseMax,
          refUnit: refs.alkalinePhosphataseUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["TOTAL_PROTEIN"]: {
        return {
          name: "Proteínas Totais",
          fieldName: "totalProtein",
          result: value.result,
          refMin: refs.totalProteinMin,
          refMax: refs.totalProteinMax,
          refUnit: refs.totalProteinUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["ALBUMIN"]: {
        return {
          name: "Albumina",
          fieldName: "albumin",
          result: value.result,
          refMin: refs.albuminMin,
          refMax: refs.albuminMax,
          refUnit: refs.albuminUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["GLUCOSE"]: {
        return {
          name: "Glicose",
          fieldName: "glucose",
          result: value.result,
          refMin: refs.glucoseMin,
          refMax: refs.glucoseMax,
          refUnit: refs.glucoseUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["LIPASE"]: {
        return {
          name: "Lipase",
          fieldName: "lipase",
          result: value.result,
          refMin: refs.lipaseMin,
          refMax: refs.lipaseMax,
          refUnit: refs.lipaseUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["AMYLASE"]: {
        return {
          name: "Amilase",
          fieldName: "amylase",
          result: value.result,
          refMin: refs.amylaseMin,
          refMax: refs.amylaseMax,
          refUnit: refs.amylaseUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL"]: {
        return {
          name: "Colesterol Total",
          fieldName: "totalCholesterol",
          result: value.result,
          refMin: refs.totalCholesterolMin,
          refMax: refs.totalCholesterolMax,
          refUnit: refs.totalCholesterolUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["GGT"]: {
        return {
          name: "Gama GT (GGT)",
          fieldName: "ggt",
          result: value.result,
          refMin: refs.ggtMin,
          refMax: refs.ggtMax,
          refUnit: refs.ggtUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["PH"]: {
        return {
          name: "PH",
          fieldName: "ph",
          result: value.result,
          refMin: refs.ggtMin,
          refMax: refs.ggtMax,
          refUnit: refs.ggtUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["CALCIUM"]: {
        return {
          name: "Cálcio",
          fieldName: "calcium",
          result: value.result,
          refMin: refs.calciumMin,
          refMax: refs.calciumMax,
          refUnit: refs.calciumUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["SODIUM"]: {
        return {
          name: "Sódio",
          fieldName: "sodium",
          result: value.result,
          refMin: refs.sodiumMin,
          refMax: refs.sodiumMax,
          refUnit: refs.sodiumUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["POTASSIUM"]: {
        return {
          name: "Potássio",
          fieldName: "potassium",
          result: value.result,
          refMin: refs.potassiumMin,
          refMax: refs.potassiumMax,
          refUnit: refs.potassiumUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["MAGNESIUM"]: {
        return {
          name: "Magnésio",
          fieldName: "magnesium",
          result: value.result,
          refMin: refs.magnesiumMin,
          refMax: refs.magnesiumMax,
          refUnit: refs.magnesiumUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["PHOSPHORUS"]: {
        return {
          name: "Fósforo",
          fieldName: "phosphorus",
          result: value.result,
          refMin: refs.phosphorusMin,
          refMax: refs.phosphorusMax,
          refUnit: refs.phosphorusUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["TRIGLYCERIDES"]: {
        return {
          name: "Triglicerídeos",
          fieldName: "triglycerides",
          result: value.result,
          refMin: refs.triglyceridesMin,
          refMax: refs.triglyceridesMax,
          refUnit: refs.triglyceridesUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["CHLORIDE"]: {
        return {
          name: "Cloretos",
          fieldName: "chloride",
          result: value.result,
          refMin: refs.chlorideMin,
          refMax: refs.chlorideMax,
          refUnit: refs.chlorideUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["IONIC_CALCIUM"]: {
        return {
          name: "Cálcio Iônico",
          ionicCalciumCalciumFieldName: "ionicCalciumCalcium",
          ionicCalciumAlbuminFieldName: "ionicCalciumAlbumin",
          ionicCalciumTotalProteinFieldName: "ionicCalciumTotalProtein",
          ionicCalciumIonicCalciumFieldName: "ionicCalciumIonicCalcium",
          ionicCalciumCalcium: value.ionicCalciumCalcium,
          ionicCalciumAlbumin: value.ionicCalciumAlbumin,
          ionicCalciumTotalProtein: value.ionicCalciumTotalProtein,
          isIonicCalciumEditable: value.isIonicCalciumEditable,
          ionicCalciumIonicCalcium: value.ionicCalciumIonicCalcium,
          ionicCalciumCalciumRefMin: refs.ionicCalciumCalciumMin,
          ionicCalciumCalciumRefMax: refs.ionicCalciumCalciumMax,
          ionicCalciumCalciumRefUnit: refs.ionicCalciumCalciumUnit,
          ionicCalciumAlbuminRefMin: refs.ionicCalciumAlbuminMin,
          ionicCalciumAlbuminRefMax: refs.ionicCalciumAlbuminMax,
          ionicCalciumAlbuminRefUnit: refs.ionicCalciumAlbuminUnit,
          ionicCalciumTotalProteinRefMin: refs.ionicCalciumTotalProteinMin,
          ionicCalciumTotalProteinRefMax: refs.ionicCalciumTotalProteinMax,
          ionicCalciumTotalProteinRefUnit: refs.ionicCalciumTotalProteinUnit,
          ionicCalciumIonicCalciumRefMin: refs.ionicCalciumIonicCalciumMin,
          ionicCalciumIonicCalciumRefMax: refs.ionicCalciumIonicCalciumMax,
          ionicCalciumIonicCalciumRefUnit: refs.ionicCalciumIonicCalciumUnit,
          examId: value.id,
          examTypeId: 0,
          mustNotDisplayResultField: value.mustNotDisplayResultField,
        };
      }
      case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]: {
        return {
          name: "Proteínas Totais e Frações",
          totalProteinAndFractionsTotalProteinFieldName:
            "totalProteinAndFractionsTotalProtein",
          totalProteinAndFractionsAlbuminFieldName:
            "totalProteinAndFractionsAlbumin",
          totalProteinAndFractionsGlobulinFieldName:
            "totalProteinAndFractionsGlobulin",
          totalProteinAndFractionsTotalProtein:
            value.totalProteinAndFractionsTotalProtein,
          totalProteinAndFractionsAlbumin:
            value.totalProteinAndFractionsAlbumin,
          totalProteinAndFractionsGlobulin: calculateGlobulin(
            value.totalProteinAndFractionsTotalProtein,
            value.totalProteinAndFractionsAlbumin
          ),
          totalProteinAndFractionsTotalProteinRefMin:
            refs.totalProteinAndFractionsTotalProteinMin,
          totalProteinAndFractionsTotalProteinRefMax:
            refs.totalProteinAndFractionsTotalProteinMax,
          totalProteinAndFractionsTotalProteinRefUnit:
            refs.totalProteinAndFractionsTotalProteinUnit,
          totalProteinAndFractionsAlbuminRefMin:
            refs.totalProteinAndFractionsAlbuminMin,
          totalProteinAndFractionsAlbuminRefMax:
            refs.totalProteinAndFractionsAlbuminMax,
          totalProteinAndFractionsAlbuminRefUnit:
            refs.totalProteinAndFractionsAlbuminUnit,
          totalProteinAndFractionsGlobulinRefMin:
            refs.totalProteinAndFractionsGlobulinMin,
          totalProteinAndFractionsGlobulinRefMax:
            refs.totalProteinAndFractionsGlobulinMax,
          totalProteinAndFractionsGlobulinRefUnit:
            refs.totalProteinAndFractionsGlobulinUnit,
          examId: value.id,
          examTypeId: 0,
        };
      } case EXAM_ID_BY_NAME["RELATION_ALBUMIN_GLOBULIN"]: {
        return {
          name: "Relação Albumina:Globulina",
          relationAlbuminGlobulinResultFieldName:
            "relationAlbuminGlobulinResult",
          relationAlbuminGlobulinAlbuminFieldName:
            "relationAlbuminGlobulinAlbumin",
          relationAlbuminGlobulinGlobulinFieldName:
            "relationAlbuminGlobulinGlobulin",
          relationAlbuminGlobulinResult:
           (value.relationAlbuminGlobulinAlbumin / value.relationAlbuminGlobulinGlobulin) || 0,
          relationAlbuminGlobulinAlbumin:
            value.relationAlbuminGlobulinAlbumin,
          relationAlbuminGlobulinGlobulin: value.relationAlbuminGlobulinGlobulin,
          relationAlbuminGlobulinResultRefMin:
            refs.relationAlbuminGlobulinResultMin || 1,
          relationAlbuminGlobulinResultRefMax:
            refs.relationAlbuminGlobulinResultMax || 4,
          relationAlbuminGlobulinResultRefUnit:
            refs.relationAlbuminGlobulinResultUnit || 'g/dL',
          relationAlbuminGlobulinAlbuminRefMin:
            refs.relationAlbuminGlobulinAlbuminMin || refs.albuminMin,
          relationAlbuminGlobulinAlbuminRefMax: refs.relationAlbuminGlobulinAlbuminMax ||
            refs.albuminMax,
          relationAlbuminGlobulinAlbuminRefUnit: refs.relationAlbuminGlobulinAlbuminUnit ||
            refs.albuminUnit,
          relationAlbuminGlobulinGlobulinRefMin:
            refs.relationAlbuminGlobulinGlobulinMin,
          relationAlbuminGlobulinGlobulinRefMax:
            refs.relationAlbuminGlobulinGlobulinMax,
          relationAlbuminGlobulinGlobulinRefUnit:
            refs.relationAlbuminGlobulinGlobulinUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]: {
        return {
          name: "Colesterol Total e Frações",
          totalCholesterolAndFractionsTotalCholesterolFieldName:
            "totalCholesterolAndFractionsTotalCholesterol",
          totalCholesterolAndFractionsHdlFieldName:
            "totalCholesterolAndFractionsHdl",
          totalCholesterolAndFractionsLdlFieldName:
            "totalCholesterolAndFractionsLdl",
          totalCholesterolAndFractionsVldlFieldName:
            "totalCholesterolAndFractionsVldl",
          totalCholesterolAndFractionsTriglyceridesFieldName:
            "totalCholesterolAndFractionsTriglycerides",
          totalCholesterolAndFractionsTotalCholesterol:
            value.totalCholesterolAndFractionsTotalCholesterol,
          totalCholesterolAndFractionsHdl:
            value.totalCholesterolAndFractionsHdl,
          totalCholesterolAndFractionsLdl:
            value.totalCholesterolAndFractionsLdl,
          totalCholesterolAndFractionsVldl:
            value.totalCholesterolAndFractionsVldl,
          totalCholesterolAndFractionsTriglycerides:
            value.totalCholesterolAndFractionsTriglycerides,
          totalCholesterolAndFractionsTotalCholesterolRefMin:
            refs.totalCholesterolAndFractionsTotalCholesterolMin,
          totalCholesterolAndFractionsTotalCholesterolRefMax:
            refs.totalCholesterolAndFractionsTotalCholesterolMax,
          totalCholesterolAndFractionsTotalCholesterolRefUnit:
            refs.totalCholesterolAndFractionsTotalCholesterolUnit,
          totalCholesterolAndFractionsHdlRefMin:
            refs.totalCholesterolAndFractionsHdlMin,
          totalCholesterolAndFractionsHdlRefMax:
            refs.totalCholesterolAndFractionsHdlMax,
          totalCholesterolAndFractionsHdlRefUnit:
            refs.totalCholesterolAndFractionsHdlUnit,
          totalCholesterolAndFractionsLdlRefMin:
            refs.totalCholesterolAndFractionsLdlMin,
          totalCholesterolAndFractionsLdlRefMax:
            refs.totalCholesterolAndFractionsLdlMax,
          totalCholesterolAndFractionsLdlRefUnit:
            refs.totalCholesterolAndFractionsLdlUnit,
          totalCholesterolAndFractionsVldlRefMin:
            refs.totalCholesterolAndFractionsVldlMin,
          totalCholesterolAndFractionsVldlRefMax:
            refs.totalCholesterolAndFractionsVldlMax,
          totalCholesterolAndFractionsVldlRefUnit:
            refs.totalCholesterolAndFractionsVldlUnit,
          totalCholesterolAndFractionsTriglyceridesRefMin:
            refs.totalCholesterolAndFractionsTriglyceridesMin,
          totalCholesterolAndFractionsTriglyceridesRefMax:
            refs.totalCholesterolAndFractionsTriglyceridesMax,
          totalCholesterolAndFractionsTriglyceridesRefUnit:
            refs.totalCholesterolAndFractionsTriglyceridesUnit,
          mustNotDisplayResultField: value.mustNotDisplayResultField,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]: {
        return {
          name: "Bilirubina e Frações",
          bilirubinAndFractionsTotalFieldName: "bilirubinAndFractionsTotal",
          bilirubinAndFractionsDirectFieldName: "bilirubinAndFractionsDirect",
          bilirubinAndFractionsIndirectFieldName:
            "bilirubinAndFractionsIndirect",
          bilirubinAndFractionsTotal: value.bilirubinAndFractionsTotal,
          bilirubinAndFractionsDirect: value.bilirubinAndFractionsDirect,
          bilirubinAndFractionsIndirect: value.bilirubinAndFractionsIndirect,
          bilirubinAndFractionsTotalRefMin: refs.bilirubinAndFractionsTotalMin,
          bilirubinAndFractionsTotalRefMax: refs.bilirubinAndFractionsTotalMax,
          bilirubinAndFractionsTotalRefUnit:
            refs.bilirubinAndFractionsTotalUnit,
          bilirubinAndFractionsDirectRefMin:
            refs.bilirubinAndFractionsDirectMin,
          bilirubinAndFractionsDirectRefMax:
            refs.bilirubinAndFractionsDirectMax,
          bilirubinAndFractionsDirectRefUnit:
            refs.bilirubinAndFractionsDirectUnit,
          bilirubinAndFractionsIndirectRefMin:
            refs.bilirubinAndFractionsIndirectMin,
          bilirubinAndFractionsIndirectRefMax:
            refs.bilirubinAndFractionsIndirectMax,
          bilirubinAndFractionsIndirectRefUnit:
            refs.bilirubinAndFractionsIndirectUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
    }
  });
}

export function getChemiluminescenceTableObjs(values, refs) {
  return values.map((value) => {
    switch (value.id) {
      case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_CORTISOL"]: {
        return {
          name: "Quimio Cortisol",
          fieldName: "chemiluminescenceCortisol",
          result: value.result,
          refMin: refs.chemiluminescenceCortisolMin,
          refMax: refs.chemiluminescenceCortisolMax,
          refUnit: refs.chemiluminescenceCortisolUnit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_TOTAL_T3"]: {
        return {
          name: "Quimio T3 Total",
          fieldName: "chemiluminescenceTotalT3",
          result: value.result,
          refMin: refs.chemiluminescenceTotalT3Min,
          refMax: refs.chemiluminescenceTotalT3Max,
          refUnit: refs.chemiluminescenceTotalT3Unit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_TOTAL_T4"]: {
        return {
          name: "Quimio T4 Total",
          fieldName: "chemiluminescenceTotalT4",
          result: value.result,
          refMin: refs.chemiluminescenceTotalT4Min,
          refMax: refs.chemiluminescenceTotalT4Max,
          refUnit: refs.chemiluminescenceTotalT4Unit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_FREE_T4"]: {
        return {
          name: "Quimio T4 Livre",
          fieldName: "chemiluminescenceFreeT4",
          result: value.result,
          refMin: refs.chemiluminescenceFreeT4Min,
          refMax: refs.chemiluminescenceFreeT4Max,
          refUnit: refs.chemiluminescenceFreeT4Unit,
          examId: value.id,
          examTypeId: 0,
        };
      }
      case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_LEVOTHYROXIN_AFTER_TOTAL_T4"]: {
        return {
          name: "Quimio T4 Pós Levo",
          fieldName: "chemiluminescenceLevothyroxinAfterTotalT4",
          result: value.result,
          refMin: refs.chemiluminescenceLevothyroxinAfterTotalT4Min,
          refMax: refs.chemiluminescenceLevothyroxinAfterTotalT4Max,
          refUnit: refs.chemiluminescenceLevothyroxinAfterTotalT4Unit,
          examId: value.id,
          examTypeId: 0,
        };
      }
    }
  });
}

export function getRadioimmunoassayTableObjs(values, refs) {
  return values.map((value) => {
    switch (value.id) {
      case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_CORTISOL"]:
        return {
          name: "Quimio Cortisol",
          fieldName: "chemiluminescenceCortisol",
          result: value.result,
          refMin: refs.chemiluminescenceCortisolMin,
          refMax: refs.chemiluminescenceCortisolMax,
          refUnit: refs.chemiluminescenceCortisolUnit,
          examId: value.id,
          examTypeId: 0,
        };
      case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_TOTAL_T3"]:
        return {
          name: "Quimio T3 Total",
          fieldName: "chemiluminescenceTotalT3",
          result: value.result,
          refMin: refs.chemiluminescenceTotalT3Min,
          refMax: refs.chemiluminescenceTotalT3Max,
          refUnit: refs.chemiluminescenceTotalT3Unit,
          examId: value.id,
          examTypeId: 0,
        };
      case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_TOTAL_T4"]:
        return {
          name: "Quimio T4 Total",
          fieldName: "chemiluminescenceTotalT4",
          result: value.result,
          refMin: refs.chemiluminescenceTotalT4Min,
          refMax: refs.chemiluminescenceTotalT4Max,
          refUnit: refs.chemiluminescenceTotalT4Unit,
          examId: value.id,
          examTypeId: 0,
        };
      case EXAM_ID_BY_NAME["CHEMILUMINESCENCE_FREE_T4"]:
        return {
          name: "Quimio T4 Livre",
          fieldName: "chemiluminescenceFreeT4",
          result: value.result,
          refMin: refs.chemiluminescenceFreeT4Min,
          refMax: refs.chemiluminescenceFreeT4Max,
          refUnit: refs.chemiluminescenceFreeT4Unit,
          examId: value.id,
          examTypeId: 0,
        };
    }
  });
}

export function isBilirubinAndFractions(examId) {
  return examId === EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"];
}

export function isIonicCalcium(examId) {
  return examId === EXAM_ID_BY_NAME["IONIC_CALCIUM"];
}

export function isTotalProteinAndFractions(examId) {
  return examId === EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"];
}

export function isTotalCholesterolAndFractions(examId) {
  return examId === EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"];
}

export function isRelationAlbuminGlobulin(examId) {
  return examId === EXAM_ID_BY_NAME['RELATION_ALBUMIN_GLOBULIN']
}

export function isAnyOfSpecialBiochemistry(examId) {
  if (
    isBilirubinAndFractions(examId) ||
    isIonicCalcium(examId) ||
    isTotalCholesterolAndFractions(examId) ||
    isTotalProteinAndFractions(examId) || isRelationAlbuminGlobulin(examId)
  ) {
    return true;
  }
  return false;
}

export function renderExpander({ isExpanded, ...rest }) {
  const examId = rest.original.examId;

  if (isAnyOfSpecialBiochemistry(examId)) {
    return isExpanded ? expandedRow : collapsedRow;
  }

  return <span />;
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Renders the report result field
 *
 * @param {Object} data Number input material component
 *
 * @return {Object}
 *
 */
export function renderRefs(refMin, refMax, refUnit) {
  return (
    <Col md={12} className="mtINPUT_SPECIAL_VALUE0">
      <p className="text-center">
        <NumberDisplay value={refMin} decimalScale={1} suffix={" - "} />
        <NumberDisplay value={refMax} decimalScale={1} suffix={` ${refUnit}`} />
      </p>
    </Col>
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Renders the report result field
 *
 * @param {Object} data Number input material component
 *
 * @return {Object}
 *
 */
export function renderResultField(
  title,
  fieldName,
  value,
  hasValidation,
  validationReason,
  examId,
  handleChange,
  handleBlur,
  shouldDisable
) {
  const isInteger = isIntegerBiochemExam(examId);

  return (
    <div className="text-left">
      {title && <label>{title}</label>}
      <InputExam
        name={fieldName}
        hasValidationRecoil={true}
        decimalScale={isInteger ? 0 : qttDecimalPlates(examId)}
        value={value}
        disabled={shouldDisable}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <InputValidationMsg visible={hasValidation} message={validationReason} />
    </div>
  );
}

export function renderIonicCalcium(
  ionicCalciumCalciumFieldName,
  ionicCalciumAlbuminFieldName,
  ionicCalciumTotalProteinFieldName,
  ionicCalciumIonicCalciumFieldName,
  ionicCalciumCalcium,
  ionicCalciumAlbumin,
  ionicCalciumTotalProtein,
  ionicCalciumIonicCalcium,
  ionicCalciumCalciumHasValidation,
  ionicCalciumAlbuminHasValidation,
  ionicCalciumTotalProteinHasValidation,
  ionicCalciumIonicCalciumHasValidation,
  ionicCalciumCalciumValidationReason,
  ionicCalciumAlbuminValidationReason,
  ionicCalciumTotalProteinValidationReason,
  ionicCalciumIonicCalciumValidationReason,
  ionicCalciumCalciumRefMin,
  ionicCalciumCalciumRefMax,
  ionicCalciumCalciumRefUnit,
  ionicCalciumAlbuminRefMin,
  ionicCalciumAlbuminRefMax,
  ionicCalciumAlbuminRefUnit,
  ionicCalciumTotalProteinRefMin,
  ionicCalciumTotalProteinRefMax,
  ionicCalciumTotalProteinRefUnit,
  ionicCalciumIonicCalciumRefMin,
  ionicCalciumIonicCalciumRefMax,
  ionicCalciumIonicCalciumRefUnit,
  examId,
  handleChange,
  handleBlur,
  toggleIonicCalciumEditable,
  shouldDisable,
  isIonicCalciumEditable
) {
  return (
    <div className="text-left">
      <Col md={1}>
        <CheckboxMaterial
          id="isIonicCalciumEditable"
          name="isIonicCalciumEditable"
          titleAfter="Ativar resultado manual"
          checked={isIonicCalciumEditable}
          disabled={shouldDisable}
          onChange={toggleIonicCalciumEditable}
        />
      </Col>
      <Col md={1}>
        {renderResultField(
          "Cálcio",
          ionicCalciumCalciumFieldName,
          ionicCalciumCalcium,
          ionicCalciumCalciumHasValidation,
          ionicCalciumCalciumValidationReason,
          examId,
          handleChange,
          handleBlur,
          shouldDisable,
          qttDecimalPlates(examId)
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          ionicCalciumCalciumRefMin,
          ionicCalciumCalciumRefMax,
          ionicCalciumCalciumRefUnit
        )}
      </Col>
      <Col md={1}>
        {isIonicCalciumEditable
          ? renderResultField(
              "Albumina",
              ionicCalciumAlbuminFieldName,
              ionicCalciumAlbumin,
              false,
              undefined,
              examId,
              undefined,
              undefined,
              true
            )
          : renderResultField(
              "Albumina",
              ionicCalciumAlbuminFieldName,
              ionicCalciumAlbumin,
              ionicCalciumAlbuminHasValidation,
              ionicCalciumAlbuminValidationReason,
              examId,
              handleChange,
              handleBlur,
              shouldDisable
            )}
      </Col>
      <Col md={1}>
        {renderRefs(
          ionicCalciumAlbuminRefMin,
          ionicCalciumAlbuminRefMax,
          ionicCalciumAlbuminRefUnit
        )}
      </Col>
      <Col md={1}>
        {isIonicCalciumEditable
          ? renderResultField(
              "P.Totais",
              ionicCalciumTotalProteinFieldName,
              ionicCalciumTotalProtein,
              false,
              undefined,
              examId,
              undefined,
              undefined,
              true
            )
          : renderResultField(
              "P.Totais",
              ionicCalciumTotalProteinFieldName,
              ionicCalciumTotalProtein,
              ionicCalciumTotalProteinHasValidation,
              ionicCalciumTotalProteinValidationReason,
              examId,
              handleChange,
              handleBlur,
              shouldDisable
            )}
      </Col>
      <Col md={1}>
        {renderRefs(
          ionicCalciumTotalProteinRefMin,
          ionicCalciumTotalProteinRefMax,
          ionicCalciumTotalProteinRefUnit
        )}
      </Col>
      <Col md={1}>
        {isIonicCalciumEditable &&
          renderResultField(
            "C. Iônico",
            ionicCalciumIonicCalciumFieldName,
            ionicCalciumIonicCalcium,
            ionicCalciumIonicCalciumHasValidation,
            ionicCalciumIonicCalciumValidationReason,
            examId,
            handleChange,
            handleBlur,
            shouldDisable
          )}
        {!isIonicCalciumEditable &&
          renderResultField(
            "C. Iônico",
            ionicCalciumIonicCalciumFieldName,
            ionicCalciumIonicCalcium,
            undefined,
            undefined,
            examId,
            undefined,
            undefined,
            true
          )}
      </Col>
      <Col md={1}>
        {renderRefs(
          ionicCalciumIonicCalciumRefMin,
          ionicCalciumIonicCalciumRefMax,
          ionicCalciumIonicCalciumRefUnit
        )}
      </Col>
    </div>
  );
}

export function toggleIonicCalciumEditable() {
  const { isIonicCalciumEditable, ionicCalciumIonicCalcium } =
    this.state.fields;

  this.setState((state) =>
    update(state, {
      fields: {
        isIonicCalciumEditable: {
          $set: !isIonicCalciumEditable,
        },
        ionicCalciumIonicCalcium: {
          $set: "",
        },
      },
    })
  );
}

export function renderTotalCholesterolAndFractions(
  totalCholesterolAndFractionsTotalCholesterolFieldName,
  totalCholesterolAndFractionsHdlFieldName,
  totalCholesterolAndFractionsLdlFieldName,
  totalCholesterolAndFractionsVldlFieldName,
  totalCholesterolAndFractionsTriglyceridesFieldName,
  totalCholesterolAndFractionsTotalCholesterol,
  totalCholesterolAndFractionsHdl,
  totalCholesterolAndFractionsLdl,
  totalCholesterolAndFractionsVldl,
  totalCholesterolAndFractionsTriglycerides,
  totalCholesterolAndFractionsTotalCholesterolHasValidation,
  totalCholesterolAndFractionsHdlHasValidation,
  totalCholesterolAndFractionsLdlHasValidation,
  totalCholesterolAndFractionsVldlHasValidation,
  totalCholesterolAndFractionsTriglyceridesHasValidation,
  totalCholesterolAndFractionsTotalCholesterolValidationReason,
  totalCholesterolAndFractionsHdlValidationReason,
  totalCholesterolAndFractionsLdlValidationReason,
  totalCholesterolAndFractionsVldlValidationReason,
  totalCholesterolAndFractionsTriglyceridesValidationReason,
  totalCholesterolAndFractionsTotalCholesterolRefMin,
  totalCholesterolAndFractionsTotalCholesterolRefMax,
  totalCholesterolAndFractionsTotalCholesterolRefUnit,
  totalCholesterolAndFractionsHdlRefMin,
  totalCholesterolAndFractionsHdlRefMax,
  totalCholesterolAndFractionsHdlRefUnit,
  totalCholesterolAndFractionsLdlRefMin,
  totalCholesterolAndFractionsLdlRefMax,
  totalCholesterolAndFractionsLdlRefUnit,
  totalCholesterolAndFractionsVldlRefMin,
  totalCholesterolAndFractionsVldlRefMax,
  totalCholesterolAndFractionsVldlRefUnit,
  totalCholesterolAndFractionsTriglyceridesRefMin,
  totalCholesterolAndFractionsTriglyceridesRefMax,
  totalCholesterolAndFractionsTriglyceridesRefUnit,
  examId,
  handleChange,
  handleBlur,
  shouldDisable
) {
  return (
    <div className="text-left">
      <Col md={1}>
        {renderResultField(
          "C. Total",
          totalCholesterolAndFractionsTotalCholesterolFieldName,
          totalCholesterolAndFractionsTotalCholesterol,
          totalCholesterolAndFractionsTotalCholesterolHasValidation,
          totalCholesterolAndFractionsTotalCholesterolValidationReason,
          examId,
          handleChange,
          handleBlur,
          shouldDisable
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          totalCholesterolAndFractionsTotalCholesterolRefMin,
          totalCholesterolAndFractionsTotalCholesterolRefMax,
          totalCholesterolAndFractionsTotalCholesterolRefUnit
        )}
      </Col>
      <Col md={1}>
        {renderResultField(
          "HDL",
          totalCholesterolAndFractionsHdlFieldName,
          totalCholesterolAndFractionsHdl,
          totalCholesterolAndFractionsHdlHasValidation,
          totalCholesterolAndFractionsHdlValidationReason,
          examId,
          handleChange,
          handleBlur,
          shouldDisable
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          totalCholesterolAndFractionsHdlRefMin,
          totalCholesterolAndFractionsHdlRefMax,
          totalCholesterolAndFractionsHdlRefUnit
        )}
      </Col>
      <Col md={1}>
        {renderResultField(
          "Triglicerídeos",
          totalCholesterolAndFractionsTriglyceridesFieldName,
          totalCholesterolAndFractionsTriglycerides,
          totalCholesterolAndFractionsTriglyceridesHasValidation,
          totalCholesterolAndFractionsTriglyceridesValidationReason,
          examId,
          handleChange,
          handleBlur,
          shouldDisable
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          totalCholesterolAndFractionsTriglyceridesRefMin,
          totalCholesterolAndFractionsTriglyceridesRefMax,
          totalCholesterolAndFractionsTriglyceridesRefUnit
        )}
      </Col>
      <Col md={1}>
        {renderResultField(
          "LDL",
          totalCholesterolAndFractionsLdlFieldName,
          totalCholesterolAndFractionsLdl,
          totalCholesterolAndFractionsLdlHasValidation,
          totalCholesterolAndFractionsLdlValidationReason,
          examId,
          handleChange,
          handleBlur,
          true
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          totalCholesterolAndFractionsLdlRefMin,
          totalCholesterolAndFractionsLdlRefMax,
          totalCholesterolAndFractionsLdlRefUnit
        )}
      </Col>
      <Col md={1}>
        {renderResultField(
          "VLDL",
          totalCholesterolAndFractionsVldlFieldName,
          totalCholesterolAndFractionsVldl,
          totalCholesterolAndFractionsVldlHasValidation,
          totalCholesterolAndFractionsVldlValidationReason,
          examId,
          handleChange,
          handleBlur,
          true
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          totalCholesterolAndFractionsVldlRefMin,
          totalCholesterolAndFractionsVldlRefMax,
          totalCholesterolAndFractionsVldlRefUnit
        )}
      </Col>
    </div>
  );
}

export function renderTotalProteinAndFractions(
  totalProteinAndFractionsTotalProteinFieldName,
  totalProteinAndFractionsAlbuminFieldName,
  totalProteinAndFractionsGlobulinFieldName,
  totalProteinAndFractionsTotalProtein,
  totalProteinAndFractionsAlbumin,
  totalProteinAndFractionsGlobulin,
  totalProteinAndFractionsTotalProteinHasValidation,
  totalProteinAndFractionsAlbuminHasValidation,
  totalProteinAndFractionsTotalProteinValidationReason,
  totalProteinAndFractionsAlbuminValidationReason,
  totalProteinAndFractionsTotalProteinRefMin,
  totalProteinAndFractionsTotalProteinRefMax,
  totalProteinAndFractionsTotalProteinRefUnit,
  totalProteinAndFractionsAlbuminRefMin,
  totalProteinAndFractionsAlbuminRefMax,
  totalProteinAndFractionsAlbuminRefUnit,
  totalProteinAndFractionsGlobulinRefMin,
  totalProteinAndFractionsGlobulinRefMax,
  totalProteinAndFractionsGlobulinRefUnit,
  examId,
  handleChange,
  handleBlur,
  shouldDisable
) {
  return (
    <div className="text-left">
      <Col md={1}>
        {renderResultField(
          "P. Totais",
          totalProteinAndFractionsTotalProteinFieldName,
          totalProteinAndFractionsTotalProtein,
          totalProteinAndFractionsTotalProteinHasValidation,
          totalProteinAndFractionsTotalProteinValidationReason,
          examId,
          handleChange,
          handleBlur,
          shouldDisable
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          totalProteinAndFractionsTotalProteinRefMin,
          totalProteinAndFractionsTotalProteinRefMax,
          totalProteinAndFractionsTotalProteinRefUnit
        )}
      </Col>
      <Col md={1}>
        {renderResultField(
          "Albumina",
          totalProteinAndFractionsAlbuminFieldName,
          totalProteinAndFractionsAlbumin,
          totalProteinAndFractionsAlbuminHasValidation,
          totalProteinAndFractionsAlbuminValidationReason,
          examId,
          handleChange,
          handleBlur,
          shouldDisable
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          totalProteinAndFractionsAlbuminRefMin,
          totalProteinAndFractionsAlbuminRefMax,
          totalProteinAndFractionsAlbuminRefUnit
        )}
      </Col>
      <Col md={1}>
        {renderResultField(
          "Globulina",
          totalProteinAndFractionsGlobulinFieldName,
          totalProteinAndFractionsGlobulin,
          undefined,
          undefined,
          examId,
          handleChange,
          handleBlur,
          true
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          totalProteinAndFractionsGlobulinRefMin,
          totalProteinAndFractionsGlobulinRefMax,
          totalProteinAndFractionsGlobulinRefUnit
        )}
      </Col>
    </div>
  );
}

export function renderRelationAlbuminGlobulin(
  relationAlbuminGlobulinResultFieldName,
  relationAlbuminGlobulinAlbuminFieldName,
  relationAlbuminGlobulinGlobulinFieldName,
  relationAlbuminGlobulinResult,
  relationAlbuminGlobulinAlbumin,
  relationAlbuminGlobulinGlobulin,
  relationAlbuminGlobulinResultHasValidation,
  relationAlbuminGlobulinAlbuminHasValidation,
  relationAlbuminGlobulinResultValidationReason,
  relationAlbuminGlobulinAlbuminValidationReason,
  relationAlbuminGlobulinResultRefMin,
  relationAlbuminGlobulinResultRefMax,
  relationAlbuminGlobulinResultRefUnit,
  relationAlbuminGlobulinAlbuminRefMin,
  relationAlbuminGlobulinAlbuminRefMax,
  relationAlbuminGlobulinAlbuminRefUnit,
  relationAlbuminGlobulinGlobulinRefMin,
  relationAlbuminGlobulinGlobulinRefMax,
  relationAlbuminGlobulinGlobulinRefUnit,
  examId,
  handleChange,
  handleBlur,
  shouldDisable,
){
  return (
    <div className="text-left">
      <Col md={1}>
        {renderResultField(
          "Albumina",
          relationAlbuminGlobulinAlbuminFieldName,
          relationAlbuminGlobulinAlbumin,
          relationAlbuminGlobulinAlbuminHasValidation,
          relationAlbuminGlobulinAlbuminValidationReason,
          examId,
          handleChange,
          handleBlur,
          shouldDisable
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          relationAlbuminGlobulinAlbuminRefMin,
          relationAlbuminGlobulinAlbuminRefMax,
          relationAlbuminGlobulinAlbuminRefUnit
        )}
      </Col>
      <Col md={1}>
        {renderResultField(
          "Globulina",
          relationAlbuminGlobulinGlobulinFieldName,
          relationAlbuminGlobulinGlobulin,
          undefined,
          undefined,
          examId,
          handleChange,
          handleBlur,
          shouldDisable
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          relationAlbuminGlobulinGlobulinRefMin,
          relationAlbuminGlobulinGlobulinRefMax,
          relationAlbuminGlobulinGlobulinRefUnit
        )}
      </Col>
      <Col md={1}>
        {renderResultField(
          "Relação A:G",
          relationAlbuminGlobulinResultFieldName,
          Number(relationAlbuminGlobulinResult).toFixed(2),
          relationAlbuminGlobulinResultHasValidation,
          relationAlbuminGlobulinResultValidationReason,
          examId,
          handleChange,
          handleBlur,
          true,
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          relationAlbuminGlobulinResultRefMin,
          relationAlbuminGlobulinResultRefMax,
          relationAlbuminGlobulinResultRefUnit
        )}
      </Col>
    </div>
  );
}

export function renderBilirubinAndFractions(
  bilirubinAndFractionsTotalFieldName,
  bilirubinAndFractionsDirectFieldName,
  bilirubinAndFractionsIndirectFieldName,
  bilirubinAndFractionsTotal,
  bilirubinAndFractionsDirect,
  bilirubinAndFractionsIndirect,
  bilirubinAndFractionsTotalHasValidation,
  bilirubinAndFractionsDirectHasValidation,
  bilirubinAndFractionsTotalValidationReason,
  bilirubinAndFractionsDirectValidationReason,
  bilirubinAndFractionsTotalRefMin,
  bilirubinAndFractionsTotalRefMax,
  bilirubinAndFractionsTotalRefUnit,
  bilirubinAndFractionsDirectRefMin,
  bilirubinAndFractionsDirectRefMax,
  bilirubinAndFractionsDirectRefUnit,
  bilirubinAndFractionsIndirectRefMin,
  bilirubinAndFractionsIndirectRefMax,
  bilirubinAndFractionsIndirectRefUnit,
  examId,
  handleChange,
  handleBlur,
  shouldDisable
) {
  return (
    <div className="text-left">
      <Col md={1}>
        {renderResultField(
          "Total",
          bilirubinAndFractionsTotalFieldName,
          bilirubinAndFractionsTotal,
          bilirubinAndFractionsTotalHasValidation,
          bilirubinAndFractionsTotalValidationReason,
          examId,
          handleChange,
          handleBlur,
          shouldDisable
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          bilirubinAndFractionsTotalRefMin,
          bilirubinAndFractionsTotalRefMax,
          bilirubinAndFractionsTotalRefUnit
        )}
      </Col>
      <Col md={1}>
        {renderResultField(
          "Direta",
          bilirubinAndFractionsDirectFieldName,
          bilirubinAndFractionsDirect,
          bilirubinAndFractionsDirectHasValidation,
          bilirubinAndFractionsDirectValidationReason,
          examId,
          handleChange,
          handleBlur,
          shouldDisable
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          bilirubinAndFractionsDirectRefMin,
          bilirubinAndFractionsDirectRefMax,
          bilirubinAndFractionsDirectRefUnit
        )}
      </Col>
      <Col md={1}>
        {renderResultField(
          "Indireta",
          bilirubinAndFractionsIndirectFieldName,
          bilirubinAndFractionsIndirect,
          undefined,
          undefined,
          examId,
          handleChange,
          handleBlur,
          true
        )}
      </Col>
      <Col md={1}>
        {renderRefs(
          bilirubinAndFractionsIndirectRefMin,
          bilirubinAndFractionsIndirectRefMax,
          bilirubinAndFractionsIndirectRefUnit
        )}
      </Col>
    </div>
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Calculates LDL value
 *
 * @param {number} totalCholesterol
 * @param {number} hdl
 * @param {number} triglycerides
 *
 * @return {number}
 *
 */
export function calculateLdl(totalCholesterol, hdl, triglycerides) {
  const isTotalCholesterolEmpty =
    totalCholesterol === "" || totalCholesterol === null;
  const isHdlEmpty = hdl === "" || hdl === null;
  const isTriglyceridesEmpty = triglycerides === "" || triglycerides === null;

  if (isTotalCholesterolEmpty || isHdlEmpty || isTriglyceridesEmpty) {
    return "";
  }
  if (
    totalCholesterol == INPUT_SPECIAL_VALUE ||
    hdl == INPUT_SPECIAL_VALUE ||
    triglycerides == INPUT_SPECIAL_VALUE
  ) {
    return INPUT_SPECIAL_VALUE;
  }

  const totalCholesterolVal = new Decimal(totalCholesterol);
  const hdlVal = hdl ? new Decimal(hdl) : new Decimal(0);
  const vldl = calculateVldl(triglycerides);
  const vldlVal = vldl ? new Decimal(vldl) : new Decimal(0);

  return Math.round(totalCholesterolVal.minus(hdlVal).minus(vldlVal));
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Calculates VLDL value
 *
 * @param {number} triglycerides
 *
 * @return {number}
 *
 */
export function calculateVldl(triglycerides) {
  const isTriglyceridesEmpty = triglycerides === "" || triglycerides === null;

  if (triglycerides == INPUT_SPECIAL_VALUE) {
    return INPUT_SPECIAL_VALUE;
  }

  if (isTriglyceridesEmpty) {
    return "";
  }

  const triglyceridesVal = triglycerides
    ? new Decimal(triglycerides)
    : new Decimal(0);

  return Math.round(triglyceridesVal.dividedBy(5));
}

export function calculateGlobulin(totalProtein, albumin) {
  const isTotalProteinEmpty =
    totalProtein === "" ||
    totalProtein === null ||
    totalProtein === "." ||
    totalProtein === ",";
  const isAlbuminEmpty =
    albumin === "" ||
    albumin === null ||
    totalProtein === "." ||
    albumin === undefined;

  if (totalProtein == INPUT_SPECIAL_VALUE || albumin == INPUT_SPECIAL_VALUE) {
    return INPUT_SPECIAL_VALUE;
  }

  if (isTotalProteinEmpty || isAlbuminEmpty) {
    return "";
  }

  const albuminVal = albumin ? new Decimal(albumin) : new Decimal(0);
  const totalProteinVal = totalProtein
    ? new Decimal(totalProtein)
    : new Decimal(0);

  if (albuminVal.greaterThan(totalProteinVal)) {
    return 0.0;
  }

  return totalProteinVal.minus(albuminVal).toFixed(1);
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Calculates ionic calcium value
 *
 * @param {number} calcium
 * @param {number} albumin
 * @param {number} totalProtein
 *
 * @return {number}
 *
 */
export function calculateIonicCalcium(calcium, albumin, totalProtein) {
  const isCalciumEmpty = calcium === "" || calcium === null;
  const isAlbuminEmpty = albumin === "" || albumin === null;
  const isTotalProteinEmpty =
    totalProtein === "" ||
    totalProtein === null ||
    totalProtein === "," ||
    totalProtein === ".";

  if (
    calcium == INPUT_SPECIAL_VALUE ||
    albumin == INPUT_SPECIAL_VALUE ||
    totalProtein == INPUT_SPECIAL_VALUE
  ) {
    return INPUT_SPECIAL_VALUE;
  }

  if (isCalciumEmpty || isAlbuminEmpty || isTotalProteinEmpty) {
    return "";
  }

  const calciumVal = calcium ? new Decimal(calcium) : new Decimal(0);
  const albuminVal = albumin ? new Decimal(albumin) : new Decimal(0);
  const totalProteinVal = totalProtein
    ? new Decimal(totalProtein)
    : new Decimal(0);

  const proteinTimesConstant = totalProteinVal.times(0.19);

  const firstDenominator = proteinTimesConstant.plus(albuminVal).dividedBy(3);
  const numerator = calciumVal.times(6).minus(firstDenominator);
  const denominator = proteinTimesConstant.plus(albuminVal).plus(6);
  return numerator.dividedBy(denominator).toFixed(1);
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Calculates Bilirubin indirect value
 *
 * @param {mixed} total
 * @param {mixed} direct
 *
 * @return {number}
 *
 */
export function calculateBilirubinIndirect(total, direct) {
  const isTotalEmpty =
    total === "" || total === null || total === "," || total === ".";
  const isDirectEmpty =
    direct === "" || direct === null || direct === "," || direct === ".";

  if (total == INPUT_SPECIAL_VALUE || direct == INPUT_SPECIAL_VALUE) {
    return INPUT_SPECIAL_VALUE;
  }

  if (isTotalEmpty || isDirectEmpty) {
    return "";
  }

  const totalVal = total ? new Decimal(total) : new Decimal(0);
  const directVal = direct ? new Decimal(direct) : new Decimal(0);

  return totalVal.minus(directVal).toPrecision();
}

export function biochemWithValidationData(rows, $validation) {
  return rows.map((row) => {
    switch (row.examId) {
      case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]: {
        row.totalProteinAndFractionsTotalProteinHasValidation =
          $validation[
            row.totalCholesterolAndFractionsTotalCholesterolFieldName
          ].show;
        row.totalProteinAndFractionsAlbuminValidationReason =
          $validation[
            row.totalCholesterolAndFractionsTotalCholesterolFieldName
          ].error.reason;
        row.totalCholesterolAndFractionsHdlHasValidation =
          $validation[row.totalCholesterolAndFractionsHdlFieldName].show;
        row.totalCholesterolAndFractionsHdlValidationReason =
          $validation[
            row.totalCholesterolAndFractionsHdlFieldName
          ].error.reason;
        row.totalCholesterolAndFractionsTriglyceridesHasValidation =
          $validation[
            row.totalCholesterolAndFractionsTriglyceridesFieldName
          ].show;
        row.totalCholesterolAndFractionsTriglyceridesValidationReason =
          $validation[
            row.totalCholesterolAndFractionsTriglyceridesFieldName
          ].error.reason;
        break;
      }
      case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]: {
        row.totalProteinAndFractionsTotalProteinHasValidation =
          $validation[row.totalProteinAndFractionsTotalProteinFieldName].show;
        row.totalProteinAndFractionsTotalProteinValidationReason =
          $validation[
            row.totalProteinAndFractionsTotalProteinFieldName
          ].error.reason;
        row.totalProteinAndFractionsAlbuminHasValidation =
          $validation[row.totalProteinAndFractionsAlbuminFieldName].show;
        row.totalProteinAndFractionsAlbuminValidationReason =
          $validation[
            row.totalProteinAndFractionsAlbuminFieldName
          ].error.reason;
        break;
      }
      case EXAM_ID_BY_NAME["IONIC_CALCIUM"]: {
        if ($validation[row.ionicCalciumIonicCalciumFieldName] !== undefined) {
          row.ionicCalciumIonicCalciumHasValidation =
            $validation[row.ionicCalciumIonicCalciumFieldName].show;
          row.ionicCalciumIonicCalciumValidationReason =
            $validation[row.ionicCalciumIonicCalciumFieldName].error.reason;
        }

        row.ionicCalciumCalciumHasValidation =
          $validation[row.ionicCalciumCalciumFieldName].show;
        row.ionicCalciumCalciumValidationReason =
          $validation[row.ionicCalciumCalciumFieldName].error.reason;
        row.ionicCalciumAlbuminHasValidation =
          $validation[row.ionicCalciumAlbuminFieldName].show;
        row.ionicCalciumAlbuminValidationReason =
          $validation[row.ionicCalciumAlbuminFieldName].error.reason;
        row.ionicCalciumTotalProteinHasValidation =
          $validation[row.ionicCalciumTotalProteinFieldName].show;
        row.ionicCalciumTotalProteinValidationReason =
          $validation[row.ionicCalciumTotalProteinFieldName].error.reason;

        break;
      }
      case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]: {
        row.bilirubinAndFractionsTotalHasValidation =
          $validation[row.bilirubinAndFractionsTotalFieldName].show;
        row.bilirubinAndFractionsTotalValidationReason =
          $validation[row.bilirubinAndFractionsTotalFieldName].error.reason;
        row.bilirubinAndFractionsDirectHasValidation =
          $validation[row.bilirubinAndFractionsDirectFieldName].show;
        row.bilirubinAndFractionsDirectValidationReason =
          $validation[row.bilirubinAndFractionsDirectFieldName].error.reason;
        break;
      }
      default: {
        row.hasValidation = $validation[row.fieldName].show;
        row.validationReason = $validation[row.fieldName].error.reason;
      }
    }

    return row;
  });
}

export function chemiluminescenceWithValidationData(rows, $validation) {
  return rows.map((row) => {
    row.hasValidation = $validation[row.fieldName].show;
    row.validationReason = $validation[row.fieldName].error.reason;
    return row;
  });
}

export function calcMolecularBiologyResult(copyValues, precision, technique) {
  const isCopyValuesEmpty = copyValues === "";
  const isPrecisionEmpty = precision === "";
  const isTechniqueUnselected = technique === INPUT_SPECIAL_VALUE;

  if (isCopyValuesEmpty || isPrecisionEmpty || isTechniqueUnselected) {
    return "";
  }

  let techniqueValue = technique === 200 ? 30 : 60;

  return copyValues * Math.pow(10, precision) * (techniqueValue / 0.2);
}

export function suggestionsFilter(suggestions, tags) {
  const found = tags.find((name) =>
    name.includes(suggestions.name.replace(/\+/g, ""))
  );

  const shouldExclude = found === undefined;

  return shouldExclude;
}

export function prepareAntibioticTagsSuggestionFilter(
  sensitiveAntibioticTags,
  intermediateAntibioticTags,
  resistantAntibioticTags
) {
  return (suggestion, tags) => {
    const allTags = [
      ...sensitiveAntibioticTags,
      ...intermediateAntibioticTags,
      ...resistantAntibioticTags,
    ];
    const emptySuggestion = "-";
    const found = allTags.find(
      (name) => (name === suggestion.name) === emptySuggestion
    );
    const shouldExclude = found === undefined;
    return shouldExclude;
  };
}

export const getRequestExamStateNameById = (requestExamState) => {
  requestExamState = parseInt(requestExamState);
  switch (requestExamState) {
    case 1:
      return "Gravado e não enviado";

    case 2:
      return "Aguardando Chegada das Amostras";

    case 3:
      return "Em Andamento";

    case 4:
      return "Atendido e não liberado";

    case 5:
      return "Parado por problemas";

    case 6:
      return "Cancelado";

    case 7:
      return "Atendido e Liberado";

    case 8:
      return "Faturado";

    default:
      return `Ainda não definido`;
  }
};

export const calculateRpcuRatio = (protein, creatinine) => {
  if (
    protein === "" ||
    creatinine === "" ||
    protein === null ||
    creatinine === null ||
    !creatinine ||
    !protein
  ) {
    return "";
  }

  if (parseInt(creatinine, 10) === 0) {
    return 0;
  }

  const decProtein = new Decimal(protein);
  return decProtein.dividedBy(creatinine).toFixed(2);
};

// RETICULOCYTES CALCS

export const calcReticulocytePercentBy100 = (reticulocyteByField) => {
  if (
    reticulocyteByField === "" ||
    reticulocyteByField === "." ||
    reticulocyteByField === "," ||
    reticulocyteByField === undefined ||
    reticulocyteByField === null
  ) {
    return "";
  }

  const reticulocyteByFieldVal = new Decimal(reticulocyteByField);

  return reticulocyteByFieldVal.times(20).dividedBy(1000).toPrecision();
};

export const calcReticulocytePercent = (reticulocyteByField) => {
  if (
    reticulocyteByField === "" ||
    reticulocyteByField === "." ||
    reticulocyteByField === "," ||
    reticulocyteByField === undefined ||
    reticulocyteByField === null
  ) {
    return "";
  }

  const reticulocyteByFieldVal = new Decimal(reticulocyteByField);

  return reticulocyteByFieldVal.times(20).dividedBy(10).toPrecision();
};

export const calcAbsoluteErythrocyteCount = (erythrocyteCount) => {
  if (
    erythrocyteCount === "" ||
    erythrocyteCount === null ||
    erythrocyteCount === undefined ||
    erythrocyteCount === "," ||
    erythrocyteCount === "."
  ) {
    return "";
  }

  const erythrocyteCountVal = new Decimal(erythrocyteCount);

  const ten = new Decimal(10);
  const tenToSixth = ten.toPower(6);

  return erythrocyteCountVal.times(tenToSixth).toPrecision();
};

export const calcTotalReticulocyte = (
  erythrocyteCount,
  reticulocyteByField
) => {
  const absoluteErythrocyteCount =
    calcAbsoluteErythrocyteCount(erythrocyteCount);
  const reticulocytePercent = calcReticulocytePercentBy100(reticulocyteByField);
  if (
    absoluteErythrocyteCount === "" ||
    reticulocytePercent === "" ||
    !absoluteErythrocyteCount ||
    !reticulocytePercent
  ) {
    return "";
  }

  const absoluteErythrocyteCountVal = new Decimal(absoluteErythrocyteCount);

  return absoluteErythrocyteCountVal.times(reticulocytePercent).toPrecision();
};

export const calcReticulocyteCorrection = (
  corpuscularVolume,
  reticylocyteByField
) => {
  const reticulocytePercent = calcReticulocytePercent(reticylocyteByField);

  if (
    corpuscularVolume === "" ||
    corpuscularVolume === null ||
    corpuscularVolume === undefined ||
    corpuscularVolume === "," ||
    corpuscularVolume === "."
  ) {
    return "";
  }
  if (
    reticulocytePercent === "" ||
    reticulocytePercent === null ||
    reticulocytePercent === undefined ||
    reticulocytePercent === "," ||
    reticulocytePercent === "."
  ) {
    return "";
  }

  const reticulocytePercentVal = new Decimal(reticulocytePercent);
  const corpuscularVolumeVal = new Decimal(corpuscularVolume);

  return reticulocytePercentVal
    .times(corpuscularVolumeVal)
    .dividedBy(45 || 1)
    .toPrecision();
};
