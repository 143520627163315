import React from "react";
import SelectMaterial from "./../SelectMaterial";
import { Row } from "./../../components/grid";
import { SELECT_TEXT } from "../../assets/js/Consts.js";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";
import * as LifeVet from "./../../components";

const AnimalFieldsFirstRow = ({
  $field,
  $validation,
  updateField,
  onChangeSpecies,
  shouldRenderCustomer,
  customersPromise,
  speciesPromise,
  breedsPromise,
  customerId,
  name,
  speciesId,
  breedId,
  disableAll,
  shouldDisableCustomer,
  AnimalFieldsFirstRow,
  animalNameInputRef,
}) => (
  <Row>
    {shouldRenderCustomer && (
      <SelectMaterial
        id="customer"
        title="Cliente"
        required
        md={3}
        name="customerId"
        options={customersPromise.fulfilled ? customersPromise.value : []}
        value={customerId}
        searchable={true}
        disabled={
          customersPromise.pending || disableAll || shouldDisableCustomer
        }
        placeholder={
          customersPromise.pending ? SELECT_TEXT.LOADING : SELECT_TEXT.ACTIVE
        }
        hasValidationRecoil={true}
        {...$field("customerId", (value) => {
          updateField("customerId", value);
        })}
        hasValidation={$validation.customerId.show}
        validationReason={$validation.customerId.error.reason}
      />
    )}
    <LifeVet.Col sm={2}>
      <LifeVet.FormGroup
        errorMessage={$validation.name.show && $validation.name.error.reason}
      >
        <LifeVet.InputLabel>Nome do Animal</LifeVet.InputLabel>
        <LifeVet.Input
          disabled={disableAll}
          name="name"
          type="text"
          value={name}
          {...$field("name", (event) => {
            updateField("name", event.target.value);
          })}
          innerRef={animalNameInputRef}
          uppercase
        />
      </LifeVet.FormGroup>
    </LifeVet.Col>

    <SelectMaterial
      id="species"
      title="Espécie"
      required
      md={3}
      name="speciesId"
      options={speciesPromise.fulfilled ? speciesPromise.value : []}
      value={speciesId}
      searchable={true}
      disabled={speciesPromise.pending || disableAll}
      placeholder={
        speciesPromise.pending ? SELECT_TEXT.LOADING : SELECT_TEXT.ACTIVE
      }
      hasValidationRecoil={true}
      {...$field("speciesId", onChangeSpecies)}
      hasValidation={$validation.speciesId.show}
      validationReason={$validation.speciesId.error.reason}
    />
    <SelectMaterial
      id="breed"
      title="Raça"
      required
      md={3}
      name="breedId"
      options={
        breedsPromise && breedsPromise.fulfilled ? breedsPromise.value : []
      }
      value={breedId}
      searchable={true}
      disabled={
        speciesId === 0 ||
        (breedsPromise && breedsPromise.pending) ||
        disableAll
      }
      placeholder={
        !breedsPromise
          ? ""
          : breedsPromise.pending
          ? SELECT_TEXT.LOADING
          : SELECT_TEXT.ACTIVE
      }
      hasValidationRecoil={true}
      {...$field("breedId", (value) => {
        updateField("breedId", value);
      })}
      hasValidation={speciesId > 0 && $validation.breedId.show}
      validationReason={$validation.breedId.error.reason}
    />
  </Row>
);

export default AnimalFieldsFirstRow;
