import React, { useReducer } from "react";
import { Breed } from "./../../types/index";
import { reducer, ACTIONS } from "./reducer";

export const useBreed = () => {
  const defaultBreed = { id: 0, name: "", status: 1 };
  const [breed, dispatch] = useReducer(reducer, defaultBreed);

  const data = breed;
  const methods = {
    update: (breed: Breed) =>
      dispatch({ type: ACTIONS.UPDATE, payload: breed }),

    updateField: (field: string, value: string | number | boolean) =>
      dispatch({ type: ACTIONS.UPDATE_FIELD, payload: { field, value } }),

    reset: () => dispatch({ type: ACTIONS.UPDATE, payload: defaultBreed }),
  };
  return {
    data: data,
    defaultData: defaultBreed,
    methods: methods,
  };
};
