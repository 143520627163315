/**
 * @var {Array}
 */
export const values = [
  { id: 1, name: "DINHEIRO" },
  { id: 2, name: "CHEQUE" },
  { id: 3, name: "CARTÃO" },
  { id: 4, name: "DEPÓSITO BANCÁRIO" },
  { id: 5, name: "TRANSFERÊNCIA BANCÁRIA" },
  { id: 6, name: "BOLETO" },
];

export const toString = (id) => {
  switch (parseInt(id)) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return values[id - 1].name;
      break;
    default:
      return "";
  }
};
