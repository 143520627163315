import __update from "immutability-helper";
import { Specie, Action } from "./../../types/index";

export const ACTIONS = {
  UPDATE: "UPDATE",
  UPDATE_FIELD: "UPDATE_FIELD",
};

const update = (value: Specie, target: Specie): Specie => {
  const commands = {
    id: { $set: value.id },
    name: { $set: value.name },
    status: { $set: value.status },
  };
  return __update(target, commands);
};

const updateField = (field: string, value: any, target: Specie) => {
  const commands = {
    [field]: { $set: value },
  };
  return __update(target, commands);
};

export const reducer = (state: Specie, action: Action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return update(action.payload, state);
    case ACTIONS.UPDATE_FIELD:
      return updateField(action.payload.field, action.payload.value, state);
    default:
      return state;
  }
};
