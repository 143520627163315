import React, { Component } from "react";

/** Internal */
import NumberInputMaterial from "./../NumberInputMaterial";
import {
  isIntegerBiochemExam,
  EXAM_ID_BY_NAME,
  qttDecimalPlates
} from "./../../assets/js/Consts.js";
import CheckboxMaterial from "./../CheckboxMaterial";
import SelectMaterial from "../SelectMaterial";
import deadlineToString from "./../../assets/js/deadlineToString.js";
import moment from "../../assets/js/moment.js";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";

/** External */
import LVTable from "./../../old_components/LVTable";
import TableExamReportHeaderPanel from "./../TableExamReportHeaderPanel";
import NumberDisplay from "./../NumberDisplay";

/** Assets */
import styles from "./index.module.css";
import InputExam from "../../components/input-exam";
import InputValidationMsg from "../InputValidationMsg";

import TextAreaPanel2 from "../TextAreaPanel2";


const isBilirubinAndFractions = examId => {
  return examId === EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"];
};

const isIonicCalcium = examId => {
  return examId === EXAM_ID_BY_NAME["IONIC_CALCIUM"];
};

const isTotalProteinAndFractions = examId => {
  return examId === EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"];
};

const isTotalCholesterolAndFractions = examId => {
  return examId === EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"];
};
const isElectrolytes = examId => {
  return examId === EXAM_ID_BY_NAME["ELECTROLYTES"];
};

const isAnyOfSpecialBiochemistry = examId => {
  if (
    isBilirubinAndFractions(examId) ||
    isIonicCalcium(examId) ||
    isTotalCholesterolAndFractions(examId) ||
    isTotalProteinAndFractions(examId) || 
    isElectrolytes(examId)
  ) {
    return true;
  }
  return false;
};

const textStyle = {
  width: "82px"
};

const renderRef = (refMin, refMax, refUnit, md, hasNormalValue) => (
  <Col md={md}>
    {hasNormalValue && 
    <div 
      className="text-center"
        style={textStyle}
      > 
    Valor Normal </div>}
    <p 
      className="text-center"
      style={textStyle}
    >
      <NumberDisplay value={refMin} decimalScale={2} suffix={` - `} />
      <NumberDisplay value={refMax} decimalScale={2} suffix={` ${refUnit}`} />
    </p>
  </Col>
);

const renderInput = (
  title,
  name,
  md,
  $field,
  fieldChange,
  value,
  disabled,
  hasValidation,
  validationReason,
  isInteger,
  decimalPlates = 2
) => {
  let validationHandlers = {};

  if ($field !== undefined) {
    validationHandlers = $field(name);
  }

  return (
    <div className="text-left">
      <Col md={md}>
        {title && <label>{title}</label>}
        <InputExam
          title={title}
          name={name}
          md={md}
          block={true}
          decimalScale={isInteger ? 0 : decimalPlates}
          handleChange={fieldChange}
          value={value}
          disabled={disabled}
          {...validationHandlers}
        />
        <InputValidationMsg
          visible={hasValidation}
          message={validationReason}
        />
      </Col>
    </div>
  );
};

const renderReferenceValues = row => {
  const examId = row.original.examId;
  const refMin = row.original.refMin;
  const refMax = row.original.refMax;
  const refUnit = row.original.refUnit;

  if (isAnyOfSpecialBiochemistry(examId)) {
    return null;
  }

  return renderRef(refMin, refMax, refUnit, 12);
};

class BiochemistryReportFormView extends Component {
  constructor(props) {
    super(props);

    this.renderResultField = this.renderResultField.bind(this);
    this.renderTableSub = this.renderTableSub.bind(this);
    this.renderStatusField = this.renderStatusField.bind(this);
    this.renderIonicCalcium = this.renderIonicCalcium.bind(this);
    this.renderBilirubinAndFractions = this.renderBilirubinAndFractions.bind(
      this
    );
    this.renderTotalProteinAndFractions = this.renderTotalProteinAndFractions.bind(
      this
    );
    this.renderTotalCholesterolAndFractions = this.renderTotalCholesterolAndFractions.bind(
      this
    );
  }

  renderResultField(row) {
    const { resultChange, $field } = this.props.methods;
    const { disableAll, $validation } = this.props.data;

    const index = row.index.toString();
    const data = row.original;
    const name = `result-${index}`;
    const examId = row.original.examId;
    const md = 12;

    if (isAnyOfSpecialBiochemistry(examId)) {
      return null;
    }

    return renderInput(
      "",
      name,
      md,
      $field,
      resultChange,
      data.result,
      disableAll,
      $validation[name].show,
      $validation[name].error.reason,
      isIntegerBiochemExam(examId),
      qttDecimalPlates(examId)
    );
  }

  renderTableSub(row) {
    const examId = row.original.examId;
    switch (true) {
      case isBilirubinAndFractions(examId):
        return this.renderBilirubinAndFractions(row);

      case isIonicCalcium(examId):
        return this.renderIonicCalcium(row);

      case isTotalCholesterolAndFractions(examId):
        return this.renderTotalCholesterolAndFractions(row);

      case isTotalProteinAndFractions(examId):
        return this.renderTotalProteinAndFractions(row);

        case isElectrolytes(examId):
          return this.renderElectrolytes(row);  

      default:
        return <span />;
    }
  }

  renderElectrolytes(row) {
    const {
      sodium,
      refSodiumMin,
      refSodiumMax,
      refSodiumUnit,
      potassium,
      refPotassiumMin,
      refPotassiumMax,
      refPotassiumUnit,
      phosphorus,
      refPhosphorusMin,
      refPhosphorusMax,
      refPhosphorusUnit,
      chloride,
      refChlorideMin,
      refChlorideMax,
      refChlorideUnit,
      
      calcium,
      refCalciumMin,
      refCalciumMax,
      refCalciumUnit,
      albumin,
      refAlbuminMin,
      refAlbuminMax,
      refAlbuminUnit,
      totalProtein,
      refTotalProteinMin,
      refTotalProteinMax,
      refTotalProteinUnit,
      result,
      isResultEditable,
      refMin,
      refMax,
      refUnit,
    } = row.original;
    let {ionicCalcium} = row.original;

    const { disableAll, $validation } = this.props.data;

    const {
      $field,
      resultChange,
      calculateIonicCalcium,
      multiFieldResultChange,
      makeIonicCalciumToggable
    } = this.props.methods;

    const index = row.index.toString();
    const refMd = 1;
    const calciumName = `result-${index}-calcium`;
    const albuminName = `result-${index}-albumin`;
    const totalProteinName = `result-${index}-totalProtein`;
    const name = `result-${index}`;
    const sodiumName = `result-${index}-sodium`;
    const potassiumName = `result-${index}-potassium`;
    const phosphorusName = `result-${index}-phosphorus`;
    const chlorideName = `result-${index}-chloride`;
    const ionicCalciumName = `result-${index}-ionicCalcium`;
    

    const inputMd = 2;


    if ("" !== calcium && "" !== albumin && "" !== totalProtein && !isResultEditable) {
      ionicCalcium = calculateIonicCalcium(calcium, albumin, totalProtein);
    }

    return (
      <div className="mt-20">
        {renderInput(
          "Sódio",
          sodiumName,
          inputMd,
          $field,
          multiFieldResultChange,
          sodium,
          disableAll,
          $validation[sodiumName] && $validation[sodiumName].show,
          $validation[sodiumName] && $validation[sodiumName].error.reason,
          false,
          1
        )}
        {renderRef(refSodiumMin, refSodiumMax, refSodiumUnit, refMd, true)}
        {renderInput(
          "Potássio",
          potassiumName,
          inputMd,
          $field,
          multiFieldResultChange,
          potassium,
          disableAll,
          $validation[potassiumName] && $validation[potassiumName].show,
          $validation[potassiumName] && $validation[potassiumName].error.reason,
          false,
          1
        )}
        {renderRef(refPotassiumMin, refPotassiumMax, refPotassiumUnit, refMd, true)}
        {renderInput(
          "Fósforo",
          phosphorusName,
          inputMd,
          $field,
          multiFieldResultChange,
          phosphorus,
          disableAll,
          $validation[phosphorusName] && $validation[phosphorusName].show,
          $validation[phosphorusName] && $validation[phosphorusName].error.reason,
          false,
          1
        )}
        {renderRef(refPhosphorusMin, refPhosphorusMax, refPhosphorusUnit, refMd, true)}
        {renderInput(
          "Cloretos",
          chlorideName,
          inputMd,
          $field,
          multiFieldResultChange,
          chloride,
          disableAll,
          $validation[chlorideName] && $validation[chlorideName].show,
          $validation[chlorideName] && $validation[chlorideName].error.reason,
          false,
          1
        )}
        {renderRef(refChlorideMin, refChlorideMax, refChlorideUnit, refMd, true)}
        {renderInput(
          "Cálcio",
          calciumName,
          inputMd,
          $field,
          multiFieldResultChange,
          calcium,
          isResultEditable || disableAll,
          $validation[calciumName] && $validation[calciumName].show,
          $validation[calciumName] && $validation[calciumName].error.reason,
          false,
          1
        )}
        {renderRef(refCalciumMin, refCalciumMax, refCalciumUnit, refMd, true)}
        {renderInput(
          "Albumina",
          albuminName,
          inputMd,
          $field,
          multiFieldResultChange,
          isResultEditable ? "" : albumin,
          isResultEditable || disableAll,
          $validation[albuminName] && $validation[albuminName].show,
          $validation[albuminName] && $validation[albuminName].error.reason,
          false,
          1
        )}
        {renderRef(refAlbuminMin, refAlbuminMax, refAlbuminUnit, refMd, true)}
        {renderInput(
          "Proteínas Totais",
          totalProteinName,
          inputMd,
          $field,
          multiFieldResultChange,
          isResultEditable ? "" : totalProtein,
          isResultEditable || disableAll,
          $validation[totalProteinName] && $validation[totalProteinName].show,
          $validation[totalProteinName] &&
            $validation[totalProteinName].error.reason,
          false,
          1
        )}
        {renderRef(
          refTotalProteinMin,
          refTotalProteinMax,
          refTotalProteinUnit,
          refMd,
          true
        )}
        {isResultEditable &&
          renderInput(
            "Cálcio Iônico",
            ionicCalciumName,
            inputMd,
            $field,
            multiFieldResultChange,
            ionicCalcium,
            disableAll,
            $validation[name] && $validation[name].show,
            $validation[name] && $validation[name].error.reason
          )}
        {!isResultEditable &&
          renderInput(
            "Cálcio Iônico",
            ionicCalciumName,
            inputMd,
            $field,
            null,
            ionicCalcium,
            true
          )}
        {renderRef(refMin, refMax, refUnit, refMd, true)}
        <CheckboxMaterial
          id="isIonicCalciumEditable"
          name="isIonicCalciumEditable"
          titleAfter="Ativar resultado manual"
          checked={isResultEditable}
          disabled={disableAll}
          onChange={makeIonicCalciumToggable(index)}
        />
        
      </div>
    );
  }

  renderBilirubinAndFractions(row) {
    const {
      total,
      refTotalMin,
      refTotalMax,
      direct,
      refDirectMin,
      refDirectMax,
      refIndirectMin,
      refIndirectMax,
      refTotalUnit,
      refDirectUnit,
      refIndirectUnit
    } = row.original;

    const {
      $field,
      calculateBilirubinIndirect,
      multiFieldResultChange
    } = this.props.methods;

    const { disableAll, $validation } = this.props.data;

    const refMd = 2;

    const index = row.index.toString();
    const totalName = `result-${index}-total`;
    const directName = `result-${index}-direct`;
    const indirectName = `result-${index}-indirect`;
    const inputMd = 2;
    let indirect = "";

    const isTotalDirectNotEmptyStr = "" !== total && "" !== direct;

    if (isTotalDirectNotEmptyStr) {
      indirect = calculateBilirubinIndirect(total, direct);
    }

    return (
      <div className="mt-20">
        {renderInput(
          "Total",
          totalName,
          inputMd,
          $field,
          multiFieldResultChange,
          total,
          disableAll,
          $validation[totalName].show,
          $validation[totalName].error.reason
        )}
        {renderRef(refTotalMin, refTotalMax, refTotalUnit, refMd, true)}
        {renderInput(
          "Direta",
          directName,
          inputMd,
          $field,
          multiFieldResultChange,
          direct,
          disableAll,
          $validation[directName].show,
          $validation[directName].error.reason
        )}
        {renderRef(refDirectMin, refDirectMax, refDirectUnit, refMd, true)}
        {renderInput(
          "Indireta",
          indirectName,
          inputMd,
          $field,
          multiFieldResultChange,
          indirect,
          true
        )}
        {renderRef(
          refIndirectMin,
          refIndirectMax,
          refIndirectUnit,
          refMd,
          true
        )}
      </div>
    );
  }

  renderTotalProteinAndFractions(row) {
    const {
      totalProtein,
      refTotalProteinMin,
      refTotalProteinMax,
      refTotalProteinUnit,
      albumin,
      refAlbuminMin,
      refAlbuminMax,
      refAlbuminUnit,
      refGlobulinMin,
      refGlobulinMax,
      refGlobulinUnit,
      isResultEditable
    } = row.original;

    const { disableAll, $validation } = this.props.data;

    const {
      $field,
      multiFieldResultChange,
      calculateGlobulin
    } = this.props.methods;

    const index = row.index.toString();
    const refMd = 1;
    const globulinName = `result-${index}-globulin`;
    const albuminName = `result-${index}-albumin`;
    const totalProteinName = `result-${index}-totalProtein`;

    const inputMd = 2;

    return (
      <div className="mt-20">
        {renderInput(
          "Proteínas Totais",
          totalProteinName,
          inputMd,
          $field,
          multiFieldResultChange,
          isResultEditable ? "" : totalProtein,
          isResultEditable || disableAll,
          $validation[totalProteinName] && $validation[totalProteinName].show,
          $validation[totalProteinName] &&
            $validation[totalProteinName].error.reason,
          false,
          1
        )}
        {renderRef(
          refTotalProteinMin,
          refTotalProteinMax,
          refTotalProteinUnit,
          refMd,
          true
        )}
        {renderInput(
          "Albumina",
          albuminName,
          inputMd,
          $field,
          multiFieldResultChange,
          isResultEditable ? "" : albumin,
          isResultEditable || disableAll,
          $validation[albuminName] && $validation[albuminName].show,
          $validation[albuminName] && $validation[albuminName].error.reason,
          false,
          1
        )}
        {renderRef(refAlbuminMin, refAlbuminMax, refAlbuminUnit, refMd, true)}
        {renderInput(
          "Globulina",
          globulinName,
          inputMd,
          undefined,
          null,
          calculateGlobulin(totalProtein, albumin),
          true
        )}
        {renderRef(
          refGlobulinMin,
          refGlobulinMax,
          refGlobulinUnit,
          refMd,
          true
        )}
      </div>
    );
  }

  renderTotalCholesterolAndFractions(row) {
    const {
      totalCholesterol,
      hdl,
      triglycerides,
      totalCholesterolRefMin,
      totalCholesterolRefMax,
      totalCholesterolRefUnit,
      vldlRefMin,
      vldlRefMax,
      vldlRefUnit,
      hdlRefMin,
      hdlRefMax,
      hdlRefUnit,
      ldlRefMin,
      ldlRefMax,
      ldlRefUnit,
      triglyceridesRefMin,
      triglyceridesRefMax,
      triglyceridesRefUnit
    } = row.original;

    const {
      $field,
      calculateVldl,
      calculateLdl,
      multiFieldResultChange
    } = this.props.methods;

    const { disableAll, $validation } = this.props.data;

    const index = row.index.toString();
    const refMd = 1;
    const inputMd = 1;
    const totalCholesterolName = `result-${index}-totalCholesterol`;
    const hdlName = `result-${index}-hdl`;
    const triglyceridesName = `result-${index}-triglycerides`;
    const ldlName = `result-${index}-ldl`;
    const vldlName = `result-${index}-vldl`;

    let ldl = "";

    if ("" !== totalCholesterol && "" !== hdl && "" !== triglycerides) {
      ldl = calculateLdl(totalCholesterol, hdl, triglycerides);
    }

    let vldl = "";

    if ("" !== triglycerides) {
      vldl = calculateVldl(triglycerides);
    }

    return (
      <div className="mt-20">
        {renderInput(
          "Col. Total",
          totalCholesterolName,
          inputMd,
          $field,
          multiFieldResultChange,
          totalCholesterol,
          disableAll,
          $validation[totalCholesterolName].show,
          $validation[totalCholesterolName].error.reason
        )}
        {renderRef(
          totalCholesterolRefMin,
          totalCholesterolRefMax,
          totalCholesterolRefUnit,
          refMd,
          true
        )}
        {renderInput(
          "HDL",
          hdlName,
          inputMd,
          $field,
          multiFieldResultChange,
          hdl,
          disableAll,
          $validation[hdlName].show,
          $validation[hdlName].error.reason,
          true
        )}
        {renderRef(hdlRefMin, hdlRefMax, hdlRefUnit, refMd, true)}
        {renderInput(
          "Triglicerídeos",
          triglyceridesName,
          inputMd,
          $field,
          multiFieldResultChange,
          triglycerides,
          disableAll,
          $validation[triglyceridesName].show,
          $validation[triglyceridesName].error.reason,
          true
        )}
        {renderRef(
          triglyceridesRefMin,
          triglyceridesRefMax,
          triglyceridesRefUnit,
          refMd,
          true
        )}
        {renderInput(
          "LDL",
          ldlName,
          inputMd,
          $field,
          multiFieldResultChange,
          ldl,
          true
        )}
        {renderRef(ldlRefMin, ldlRefMax, ldlRefUnit, refMd, true)}
        {renderInput(
          "VLDL",
          vldlName,
          inputMd,
          $field,
          multiFieldResultChange,
          vldl,
          true
        )}
        {renderRef(vldlRefMin, vldlRefMax, vldlRefUnit, refMd, true)}
      </div>
    );
  }

  renderIonicCalcium(row) {
    const {
      calcium,
      refCalciumMin,
      refCalciumMax,
      refCalciumUnit,
      albumin,
      refAlbuminMin,
      refAlbuminMax,
      refAlbuminUnit,
      totalProtein,
      refTotalProteinMin,
      refTotalProteinMax,
      refTotalProteinUnit,
      result,
      isResultEditable,
      refMin,
      refMax,
      refUnit
    } = row.original;

    const { disableAll, $validation } = this.props.data;

    const {
      $field,
      resultChange,
      calculateIonicCalcium,
      multiFieldResultChange,
      makeIonicCalciumToggable
    } = this.props.methods;

    const index = row.index.toString();
    const refMd = 1;
    const calciumName = `result-${index}-calcium`;
    const albuminName = `result-${index}-albumin`;
    const totalProteinName = `result-${index}-totalProtein`;
    const name = `result-${index}`;

    const inputMd = 2;

    let ionicCalcium = "";

    if ("" !== calcium && "" !== albumin && "" !== totalProtein) {
      ionicCalcium = calculateIonicCalcium(calcium, albumin, totalProtein);
    }

    return (
      <div className="mt-20">
        {renderInput(
          "Cálcio",
          calciumName,
          inputMd,
          $field,
          multiFieldResultChange,
          isResultEditable ? "" : calcium,
          isResultEditable || disableAll,
          $validation[calciumName] && $validation[calciumName].show,
          $validation[calciumName] && $validation[calciumName].error.reason,
          false,
          1
        )}
        {renderRef(refCalciumMin, refCalciumMax, refCalciumUnit, refMd, true)}
        {renderInput(
          "Albumina",
          albuminName,
          inputMd,
          $field,
          multiFieldResultChange,
          isResultEditable ? "" : albumin,
          isResultEditable || disableAll,
          $validation[albuminName] && $validation[albuminName].show,
          $validation[albuminName] && $validation[albuminName].error.reason,
          false,
          1
        )}
        {renderRef(refAlbuminMin, refAlbuminMax, refAlbuminUnit, refMd, true)}
        {renderInput(
          "Proteínas Totais",
          totalProteinName,
          inputMd,
          $field,
          multiFieldResultChange,
          isResultEditable ? "" : totalProtein,
          isResultEditable || disableAll,
          $validation[totalProteinName] && $validation[totalProteinName].show,
          $validation[totalProteinName] &&
            $validation[totalProteinName].error.reason,
          false,
          1
        )}
        {renderRef(
          refTotalProteinMin,
          refTotalProteinMax,
          refTotalProteinUnit,
          refMd,
          true
        )}
        {isResultEditable &&
          renderInput(
            "Cálcio Iônico",
            name,
            inputMd,
            $field,
            resultChange,
            result,
            disableAll,
            $validation[name] && $validation[name].show,
            $validation[name] && $validation[name].error.reason
          )}
        {!isResultEditable &&
          renderInput(
            "Cálcio Iônico",
            name,
            inputMd,
            $field,
            null,
            ionicCalcium,
            true
          )}
        {renderRef(refMin, refMax, refUnit, refMd, true)}
        <CheckboxMaterial
          id="isIonicCalciumEditable"
          name="isIonicCalciumEditable"
          titleAfter="Ativar resultado manual"
          checked={isResultEditable}
          disabled={disableAll}
          onChange={makeIonicCalciumToggable(index)}
        />
      </div>
    );
  }

  renderStatusField(row) {
    const index = row.index.toString();
    const requestExamState = row.original.requestExamState;
    const name = `result-${index}`;

    const { requestExamStates, disableAll } = this.props.data;
    const { stateChange } = this.props.methods;

    return (
      <SelectMaterial
        name={name}
        md={12}
        options={requestExamStates}
        value={requestExamState}
        searchable={true}
        fit
        tabIndex={-1}
        disabled={disableAll}
        placeholder={"Selecione"}
        onChange={value => {
          stateChange(name, value);
        }}
      />
    );
  }

  render() {
    const defaultTable = {
      defaultPageSize: 5,
      defaultSorted: [{ id: "id", desc: true }],
      pageSize: 15,
      resizable: false,
      showPagination: true,
      noDataText: "Nenhum registro".toUpperCase(),
      loadingText: "Carregando",
      className: "-striped -highlight",
      previousText: "Anterior",
      nextText: "Próximo",
      pageText: "Página",
      ofText: "de",
      rowsText: "linhas",
      columns: [],
      showPageSizeOptions: false,
      pageSizeOptions: [5, 10, 15, 20, 25, 50, 100]
    };

    /**
     * Default configurations for biochemistry table
     * @type {Object}
     */
    const config = {
      defaultPageSize: 10,
      pageSize: this.props.data.fields.options.length,
      defaultSorted: [{ id: "name", desc: false }],
      data: this.props.data.fields.options,
      loading: false,
      resizable: defaultTable.resizable,
      showPagination: false,
      showPageSizeOptions: defaultTable.showPageSizeOptions,
      columns: [
        {
          Header: "Nome do Exame",
          accessor: "name",
          className: styles.centeredText,
          maxWidth: 320
        },
        {
          Header: props => <span className="float-left">Resultado</span>,
          className: styles.centeredText,
          sortable: false,
          width: 120,
          Cell: this.renderResultField
        },
        {
          Header: "Valores Normais",
          sortable: false,
          className: styles.centeredText,
          Cell: renderReferenceValues,
          width: 180
        },
        {
          Header: props => <span className="float-left">Status</span>,
          Cell: this.renderStatusField,
          sortable: false
        },
        {
          Header: props => <span className="float-left">Prazo</span>,
          className: styles.centeredText,
          Cell: props => deadlineToString(props.original.deadline),
          sortable: false,
          width: 80
        },
        {
          Header: props => (
            <span className="float-left">Data de Finalização</span>
          ),
          className: styles.centeredText,

          Cell: props => {
            const date = props.original.endDate;
            return date ? moment(date).format("DD/MM/YYYY") : "00-00-0000";
          },
          sortable: false,
          width: 120
        }
      ]
    };

    return (
      <ReportPanelMaterial
        title="Bioquímica"
        backLink={this.props.data.backLink}
        shouldDisable={this.props.data.disableAll}
        isUpdate={this.props.data.isUpdate}
        isRequesting={
          this.props.data.submitPromise && this.props.data.submitPromise.pending
        }
        handleSubmit={this.props.methods.onSubmit}
        handleSendReportEmail={this.props.methods.handleSendReportEmail}
        shouldSendEmail={this.props.data.shouldSendEmail}
        cancelReport={this.props.methods.cancelReport}
        stopReport={this.props.methods.stopReport}
      >
        <Row>
          <Col md={12}>
            <TableExamReportHeaderPanel
              data={this.props.data.examTableHeaderData}
              shouldDisable={this.props.data.disableAll}
              fields={this.props.data.fields}
              fieldChange={this.props.methods.fieldChange}
              $field={this.props.methods.$field}
              $validation={this.props.data.$validation}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr className="mt-20" />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="fit-table">
            <div className="text-center">
              <h5 className="table-title">Exames</h5>
            </div>
            <LVTable
              data={config.data}
              loading={config.loading}
              columns={config.columns}
              sortable={config.sortable}
              pageSize={config.pageSize}
              resizable={config.resizable}
              showPagination={config.showPagination}
              defaultPageSize={config.defaultPageSize}
              showPageSizeOptions={config.showPageSizeOptions}
              shouldDisableRowHighlight={true}
              defaultSorted={config.defaultSorted}
              collapseOnDataChange={false}
              shouldRowsStartExpanded={true}
              SubComponent={this.renderTableSub}
            />
            <TextAreaPanel2
              title="Obs"
              name={"biochemObs"}
              value={this.props.data.fields.biochemObs}
              disabled={this.props.shouldDisable}
              handleChange={this.props.methods.handleChange}
              handleBlur={this.props.handleBlur}
            />
          </Col>
        </Row>
      </ReportPanelMaterial>
    );
  }
}

export default BiochemistryReportFormView;
