import React from 'react';

/**
 * Internal
 */
import FlatPanelMaterial from './../FlatPanelMaterial';

/**
 * External
 */
import { Col, Row } from './../../components/grid';

const MethodPanel = ( { method, description } ) =>
  <FlatPanelMaterial>
    <Row>
      <Col md={ 12 }><h5><b>Método</b>: { method }</h5></Col>
      <Col md={ 12 }><h5><b>Descritivo</b>: { description }</h5></Col>
    </Row>
  </FlatPanelMaterial>

export default MethodPanel;