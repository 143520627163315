import React from "react";
import InputValidationMsg from "../../containers/InputValidationMsg";
import { Col, Row } from "../grid";
import InputExam from "../input-exam";
import styled from "styled-components";
import { ArrayUtil } from "../../useful";
import { INPUT_SPECIAL_VALUE } from "../../consts";
import NumberDisplay from "../../containers/NumberDisplay";
import { Checkbox } from "../../components";
import CheckboxMaterial from "../../containers/CheckboxMaterial";

const StyledInput = styled.input`
  height: 20px !important;
  padding: 3px 6px !important;
  border: 0px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const StyledInputContainer = styled.div`
  text-align: left;
`;

const StyledRefContainer = styled.div`
  margin-left: 180px;
  margin-top: 14px;
`;

const decimalSeparator = ",";
const specialChar = "*";

function renderRef(
  refMin = 2,
  refMax = 4,
  refUnit = "km/h",
  md = "2",
  hasNormalValue = true
) {
  return (
    <Col md={md}>
      {hasNormalValue && (
        <div style={{ width: "82px", textAlign: "center" }}>Valor Normal </div>
      )}
      <p style={{ width: "82px", textAlign: "center" }}>
        <NumberDisplay value={refMin} decimalScale={2} suffix={` - `} />
        <NumberDisplay value={refMax} decimalScale={2} suffix={` ${refUnit}`} />
      </p>
    </Col>
  );
}

const InputResult = ({
  title = "titulo",
  name = "nome",
  value,
  handleChange,
  refMin,
  refMax,
  refUnit,
  disabled,
}) => {
  const decimalScale = 2;
  const md = 2;

  function formatValue(value) {
    if (value.split("").length === 0) {
      return value;
    }
    if (
      !ArrayUtil.arrayContainsArray(supportedValues, value.split("")) ||
      value.split("").filter((x) => x == decimalSeparator).length > 1
    ) {
      return false;
    }
    const separeteValue = value.split(decimalSeparator);
    if (separeteValue[1] && separeteValue[1].split("").length > decimalScale) {
      return false;
    }

    if (value.split("").indexOf(specialChar) > -1) {
      return INPUT_SPECIAL_VALUE;
    }

    return value.replace(decimalSeparator, ".");
  }

  function onValueChange(e) {
    // const formatedValue = formatValue(e.target.value);
    e.target.value = formatValue(e.target.value);
    handleChange && e.target.value !== false && handleChange(e);
  }

  const supportedValues = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    decimalScale > 0 ? decimalSeparator : "",
    specialChar,
  ];

  value =
    typeof value === "string" ? value.replace(".", decimalSeparator) : value;
  value = parseInt(value) === INPUT_SPECIAL_VALUE ? specialChar : value;

  return (
    <>
      <StyledInputContainer>
        <Col md={md}>
          {title && <label>{title}</label>}
          <StyledInput
            title={title}
            name={name}
            md={md}
            block={true}
            value={value}
            disabled={disabled}
            // placeholder={placeholder}
            onChange={onValueChange}
          />
          

        </Col>
      </StyledInputContainer>
      <StyledRefContainer>
        {renderRef(refMin, refMax, refUnit)}
      </StyledRefContainer>
      <CheckboxMaterial
          titleAfter={"ativar resultado manual"}
          />
          {/* id="isIntenseLeucopeny"
              name="isIntenseLeucopeny"
              titleAfter="Leucopenia Intensa"
              checked={isIntenseLeucopeny}
              disabled={disableAll}
              onChange={(field) => fieldChange(field, !isIntenseLeucopeny)} */}
    </>
  );
};

export default InputResult;
