import { microfilariaeResearchRules } from "../../../assets/js/validationRules";

export const microfilariaeFieldNames = () => {
  return ["microfilariaeResearchResult", "microfilariaeResearchObs"];
};

export const microfilariaeDefaultState = () => {
  return {
    microfilariaeResearchResult: "",
    microfilariaeResearchObs: "",
  };
};

export const microfilariaeStateApiFactory = (obj) => {
  return {
    microfilariaeResearchResult: obj.microfilariaeResearchResult,
    microfilariaeResearchObs: obj.microfilariaeResearchObs,
  };
};

export const microfilariaeFieldsToApiObjParser = (fields) => {
  const { microfilariaeResearchResult, microfilariaeResearchObs } = fields;

  return {
    microfilariaeResearchResult: microfilariaeResearchResult,
    microfilariaeResearchObs: microfilariaeResearchObs,
  };
};

export const microfilariaeValidationRules = (fields, isCompleteValidation) => {
  const { microfilariaeResearchResult, microfilariaeResearchObs } = fields;

  return microfilariaeResearchRules(
    "microfilariaeResearchResult",
    microfilariaeResearchResult,
    "microfilariaeResearchObs",
    microfilariaeResearchObs,
    isCompleteValidation
  );
};
