import { withFormik } from "formik";
import { FETCH_MESSAGE, ERROR_MESSAGE } from "./../../../consts/messages";
import * as DateUtil from "./../../../useful/date-util";

import * as Yup from "yup";

export default withFormik({
  mapPropsToValues: () => ({
    month_and_year: null,
    start_date: DateUtil.getFirstDayOfTheMonthAsString(),
    end_date: DateUtil.getTodayDateAsString(),
    due_date: null,
    customer: null,
    regions: null,
    frequency: null,
    invoice_payment: {
      value: "",
      payment_method: null,
      payment_date: null,
      obs: ""
    }
  }),

  validationSchema: Yup.object().shape({
    month_and_year: Yup.string().nullable(),
    start_date: Yup.string().nullable(),
    end_date: Yup.string().nullable(),
    due_date: Yup.string().nullable(),
    customer: Yup.string().nullable(),
    regions: Yup.string().nullable(),
    frequency: Yup.string().nullable(),
    invoice_payment: Yup.object()
      .shape({
        value: Yup.string().nullable(),
        payment_method: Yup.string().nullable(),
        payment_date: Yup.string().nullable(),
        obs: Yup.string().nullable()
      })
      .nullable()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    const onSuccess = () => {
      setSubmitting(false);
      props.successNotification(FETCH_MESSAGE);
    };

    const onError = () => {
      setSubmitting(false);
      props.errorNotification(ERROR_MESSAGE);
    };

    let regions = null;
    if (values.regions) {
      regions = values.regions.map(region => {
        return region.id;
      });
      regions = regions.join();
    }
    const options = {
      customer_id: values.customer && values.customer.id,
      frequency: values.frequency && values.frequency.id,
      regions: regions,
      start_date: values.start_date,
      end_date: values.end_date,
      due_date: values.due_date
    };
    props.searchInvoices(options, onSuccess, onError);
  },

  displayName: "Faturamento"
});
