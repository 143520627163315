import React from "react";

/** Internal */
import { regionModalValidation } from "./../UnityForm/validation";
import DefaultTable from "./../DefaultTable";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import CheckboxMaterial from "./../CheckboxInputMaterial";
import TableButtonRemoveMaterial from "./../TableButtonRemoveMaterial";
import TableButtonEditMaterial from "./../TableButtonEditMaterial";
import TableButtonAddMaterial from "./../TableButtonAddMaterial";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";

/** Assets */
import styles from "./index.module.css";

/** External */
import { Row, Col, Modal } from "react-bootstrap";
import { validated } from "react-custom-validation";
import { SUBMIT_MODAL_KEY } from "../../assets/js/Consts";

/**
 * @author Victor Heringer
 *
 * Checks if a region is selected at table
 *
 * @param {String} name Reference value for region
 * @param {Array} selecteds List of selecteds region
 *
 * @return {Bool}
 */
const isSelectedAtTable = (name, selecteds) => {
  return selecteds.indexOf(name) > -1;
};

/**
 * @author Victor Heringer
 *
 * Render the checkbox to remove a row
 *
 * @param {Object} data
 * @param {Function} toggleSelectPhone
 * @param {Array} selectedPhones
 *
 * @return {Object}
 */
const renderInputButton = (
  data,
  toggleSelectRegion,
  selectedRegions,
  shouldDisableAll
) => {
  if (!shouldDisableAll) {
    return (
      <CheckboxMaterial
        checked={isSelectedAtTable(data.original.name, selectedRegions)}
        onChange={() => toggleSelectRegion(data.original.name)}
      />
    );
  }

  return <span />;
};

/**
 * @author Victor Heringer
 *
 * Render the edit button
 *
 * @param {Object} data Table data, including id to fetch user
 * @param {Function} handleLoadRegionToModal Calls modal and sets region
 *
 * @return {Object}
 */
const renderEditButton = (data, handleLoadRegionToModal) => {
  const region = { id: data.original.id, value: data.original.name };
  const onClick = () => handleLoadRegionToModal(region);

  if (region.id) {
    return <TableButtonEditMaterial onClick={onClick} />;
  }

  return <span />;
};

/**
 * @author Victor Heringer
 *
 * Returns react table configs
 *
 * @return {Array}
 */
const getTableConfigs = (
  regions,
  toggleSelectRegion,
  selectedRegions,
  handleLoadRegionToModal,
  shouldDisableAll
) => {
  let config = {
    defaultPageSize: 5,
    defaultSorted: [{ id: "id", desc: true }],
    pageSize: 15,
    resizable: false,
    showPagination: true,
    noDataText: "Nenhum registro".toUpperCase(),
    loadingText: "Carregando",
    className: "-striped -highlight",
    previousText: "Anterior",
    nextText: "Próximo",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    columns: [],
    showPageSizeOptions: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100]
  };

  config.data = regions;
  config.pageSize = 3;
  config.className = "no-page-info";
  config.columns = [
    {
      Header: "",
      sortable: false,
      filterable: false,
      width: 60,
      Cell: data =>
        renderInputButton(
          data,
          toggleSelectRegion,
          selectedRegions,
          shouldDisableAll
        )
    },
    {
      Header: "",
      sortable: false,
      width: 60,
      className: styles.centeredText,
      Cell: data => renderEditButton(data, handleLoadRegionToModal)
    },
    {
      Header: "Nome",
      accessor: "name",
      sortable: true,
      className: styles.centeredText
    }
  ];

  return config;
};

/**
 * @author Victor Heringer
 *
 * Regions form fields
 */
const RegionsFields = ({
  fields,
  $field,
  $fieldEvent,
  $validation,
  $submit,
  methods: {
    fieldChange,
    addRegionToTable,
    handleLoadRegionToModal,
    toggleSelectRegion,
    handleRemoveRegion,
    isUpdateing
  },
  data: { selectedRegions, displayRegionModal, defaultRegion, shouldDisableAll }
}) => {
  const modal = (
    <Modal
      show={displayRegionModal}
      onHide={() => handleLoadRegionToModal(defaultRegion)}
    >
      <Modal.Header closeButton>
        <Modal.Title> Adicionar Região </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <LVInputTextUpper
            id="modalRegion"
            autofocus={true}
            title="Região"
            required
            name="modalRegion"
            disabled={shouldDisableAll}
            md={12}
            value={fields.modalRegion}
            {...$field("modalRegion", event => {
              fieldChange("modalRegion", event.target.value);
            })}
            hasValidation={
              $validation &&
              $validation.modalRegion.show &&
              !$validation.modalRegion.isValid
            }
            validationReason={
              $validation && $validation.modalRegion.error.reason
            }
            isValid={$validation && $validation.modalRegion.isValid}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefaultMaterial
        keyCode={SUBMIT_MODAL_KEY}
        onKeyPress={() =>
          $submit(() =>
            addRegionToTable(
              { id: fields.modalRegionId, name: fields.modalRegion },
              $fieldEvent
            )
          )}
          type="default"
          title="Adicionar"
          disabled={shouldDisableAll}
          onClick={() =>
            $submit(() =>
              addRegionToTable(
                { id: fields.modalRegionId, name: fields.modalRegion },
                $fieldEvent
              )
            )
          }
        />
      </Modal.Footer>
    </Modal>
  );

  const openModalBtn = (
    <TableButtonAddMaterial
      title={"Nova Região"}
      className={"pull-right"}
      disabled={shouldDisableAll}
      onClick={() => handleLoadRegionToModal(defaultRegion)}
    />
  );

  const table = (
    <DefaultTable
      configurations={getTableConfigs(
        fields.regions,
        toggleSelectRegion,
        selectedRegions,
        handleLoadRegionToModal,
        shouldDisableAll
      )}
    />
  );

  const removeRegionsBtn = (
    <TableButtonRemoveMaterial
      text={{
        confirm: "Remover",
        message:
          "Os registros selecionados serão removidos permanentemente. Deseja continuar?",
        title: "Remover Regiões"
      }}
      className="pull-left"
      disabled={selectedRegions.length === 0}
      onClick={handleRemoveRegion}
    />
  );

  return (
    <React.Fragment>
      {modal}
      <Col md={6}>
        <Row>
          <Col xs={12} className="mb-15 mt-15">
            {openModalBtn}
            {removeRegionsBtn}
            <h5 className="table-title text-center"> Regiões </h5>
          </Col>
          <Col xs={12}>{table}</Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

const withValidation = validated(regionModalValidation);

export default withValidation(RegionsFields);
