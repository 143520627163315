import React, { useEffect, useState } from "react";
import __update from "immutability-helper";
import { useRequest } from "./index";
import {
  generateRequestValidationSchema,
  RequestValidationSchema,
} from "./../../schemas/index";
import { OnSuccess, OnError } from "./../../types/index";
import { useValidatedForm } from "../validated-form/index";
import { RequestConversor } from "../../services/request-conversor/new-to-old";
import { useFetch, ROUTES } from "../use-fetch";

export const useValidatedRequestForm = () => {
  const [schema, setSchema] = useState(RequestValidationSchema);
  const saveRequest = useFetch();
  const request = useRequest();
  const {
    errors,
    touched,
    isSubmitting,
    setError,
    unsetError,
    setTouched,
    unsetTouched,
    handleBlur,
    reset,
    resetValidation,
    validate,
    validateField,
    onValidationError,
    setIsSubmitting,
  } = useValidatedForm(request.data, request.methods.reset, schema);

  const onSubmit = (
    isPartialSave = false,
    onSuccess: OnSuccess,
    onError: OnError
  ) => {
    setIsSubmitting(true);
    const onValid = () => {
      let oldExamRequest = RequestConversor(request.data);

      saveRequest.fetch(
        ROUTES.saveRequest(oldExamRequest.id, isPartialSave),
        {
          method: oldExamRequest.id === 0 ? "POST" : "PUT",
          body: JSON.stringify(oldExamRequest),
        },
        onSuccess,
        onError
      );
    };

    validate(onValid, onValidationError);
  };

  const handleChange = (
    path: { name: string; index: number | undefined } | string,
    value: any
  ) => {
    let name = path as string;
    let index = null;
    if (typeof path !== "string") {
      name = path.name;
      index = path.index;
    }

    switch (name) {
      case "animal":
        request.methods.animal.update(value);
        break;
      case "customer":
        request.methods.customer.update(value);
        break;
      case "vet":
        request.methods.vet.update(value);
        break;
      case "exams":
        if (index !== null) {
          request.methods.exams.update(value);
        } else {
          request.methods.exams.reset();
          request.methods.exams.add(value);
        }
        break;
      case "samples":
        if (index !== null) {
          request.methods.samples.update(value);
        } else {
          request.methods.samples.reset();
          request.methods.samples.add(value);
        }
        break;
      default:
        request.methods.updateField(name, value);
    }

    unsetTouched(name);
  };

  useEffect(() => {
    request.data.id &&
      setSchema(generateRequestValidationSchema(request.data.id));
  }, [request.data.id]);

  useEffect(() => {
    if (Array.isArray(touched.samples)) {
      for (let i = 0; i < touched.samples.length; i++) {
        validateField(`samples[${i}].code`);
      }
    }
  }, [touched.samples]);

  return {
    data: request.data,
    methods: request.methods,
    errors,
    touched,
    isSubmitting,
    reset,
    resetValidation,
    setError,
    unsetError,
    setTouched,
    handleChange,
    handleBlur,
    validate,
    validateField,
    setIsSubmitting,
    submit: onSubmit,
  };
};
