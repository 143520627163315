import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import * as LifeVet from "./../../../components";
import LVTable from "./../../../old_components/LVTable";
import ButtonDefaultMaterial from "./../../../containers/ButtonDefaultMaterial";
import * as DateUtil from "./../../../useful/date-util";
import {ArrayUtil} from "./../../../useful"

const InvoiceDetailsModal = ({
  isOpen,
  toggle,
  data,
  start_date,
  end_date
}) => {
  const requestExamsQuantity = data ? data.requestExams.length : 0;
  const columns = [
    {
      id: "id",
      accessor: "id",
      Header: "Id",
      sortable: true,
      minWidth: 25,
      Cell: data => data.original.id
    },
    {
      id: "created_at",
      accessor: "created_at",
      Header: "Data Solic.",
      sortable: true,
      minWidth: 40,
      Cell: data => DateUtil.formatDate(data.original.created_at)
    },
    {
      id: "id",
      accessor: "id",
      Header: "Código Solic.",
      sortable: true,
      minWidth: 35,
      Cell: data => data.original.exam_request.id
    },
    {
      id: "animal",
      accessor: "animal.name",
      Header: "Animal",
      sortable: true,
      minWidth: 75,
      Cell: data =>
        data.original.exam_request && data.original.exam_request.animal.name
    },
    {
      id: "exam",
      accessor: "exam.name",
      Header: "Exame",
      sortable: true,
      minWidth: 70,
      Cell: data => data.original.lab_exam && data.original.lab_exam.exam.name
    },
    {
      id: "finalized_at",
      accessor: "finalized_at",
      Header: "Data Finaliz. Exame",
      sortable: true,
      minWidth: 50,
      Cell: data =>
        DateUtil.formatDate(data.original.end_date_time.substr(0, 10))
    },
    {
      id: "animal",
      accessor: "animal.owner",
      Header: "Proprietário",
      sortable: true,
      minWidth: 50,
      Cell: data =>
        data.original.exam_request && data.original.exam_request.animal.owner
    },
    {
      id: "price",
      accessor: "final_price",
      Header: "Preço",
      sortable: true,
      minWidth: 30,
      Cell: data => `R$ ${ArrayUtil.numberFormat(data.original.final_price)}`
    }
  ];

  return (
    <Modal show={isOpen} onHide={toggle} bsSize="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Fatura Processada Nº {data && data.invoice.id} - Detalhes</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={4}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Cliente</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.customer.name}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>

          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Quant. Solic.</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.examRequestsCount}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>

          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Quant. Exames</LifeVet.InputLabel>
              <LifeVet.Input value={requestExamsQuantity} isViewOnly={true} />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Data de Geração</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.invoice.created_at.substr(0, 10)}
                isViewOnly={true}
                type="date"
              />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Vencimento</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.invoice.due_date_time.substr(0, 10)}
                isViewOnly={true}
                type="date"
              />
            </LifeVet.FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Data Inicial</LifeVet.InputLabel>
              <LifeVet.Input value={start_date} type="date" isViewOnly={true} />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Data Final</LifeVet.InputLabel>
              <LifeVet.Input value={end_date} type="date" isViewOnly={true} />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Valor Bruto</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && `R$ ${ArrayUtil.numberFormat(data.invoice.gross_value)}`}
                isViewOnly={true}
              />
              
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Desconto (R$)</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && `R$ ${ArrayUtil.numberFormat(data.invoice.discount_amount)}`}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Desconto (%)</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.customer.preferences.discount}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Valor Líquido</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && `R$ ${ArrayUtil.numberFormat(data.invoice.net_value)}`}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <LVTable
              data={data ? data.requestExams : []}
              loading={false}
              columns={columns}
              sortable={true}
              pageSize={15}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefaultMaterial type="default" title="Voltar" onClick={toggle} />
      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceDetailsModal;
