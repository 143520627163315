import { validated } from "react-custom-validation";

import {
  required,
  minLength,
  lazyIsUniqueSpecie
} from "./../../assets/js/validationFunctions";

export const FormValidation = validated(props => {
  const { name } = props.fields;
  const fields = ["name"];

  const validations = props.isUpdate
    ? {
        name: {
          rules: [
            [required, name, "Informe o nome"],
            [minLength, name, "Mínimo de 2 caracteres"]
          ]
        }
      }
    : {
        name: {
          rules: [
            [required, name, "Informe o nome"],
            [minLength, name, "Mínimo de 2 caracteres"],
            [lazyIsUniqueSpecie, name, "Espécie já cadastrada"]
          ]
        }
      };

  return { fields, validations };
});
