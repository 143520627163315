import React, { Component } from "react";

/** Internal */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import { StringUtil } from "./../../useful";
import InputMaterial from "./../InputMaterial";
import LVTable from "./../../old_components/LVTable";
import { ACCESS_PROFILES } from "./../../assets/js/Consts";
import AccessManager from "./../../old_components/AccessManager";

/**
 * External
 */
import { Link } from "react-router-dom";

/**
 * Assets
 */
import styles from "./index.module.css";

class UnityView extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      pages: 1,
    };
  }

  renderSampleCodeField(
    data,
    styles,
    isUpdate,
    Util,
    $field,
    sampleCodeChange,
    disableAll
  ) {
    const index = data.index;
    const code = data.original.code;

    return (
      <InputMaterial
        className={styles.centered}
        name={`requestSample-${index}`}
        md={12}
        fit={true}
        block={true}
        disabled={disableAll}
        value={code}
        {...$field(`requestSample-${index}`, (event) => {
          const value = event.target.value;
          if (Util.isStrEmpty(value) || Util.isNumeric(value)) {
            sampleCodeChange(index, value);
          }
        })}
      />
    );
  }

  render() {
    const { objects, filter } = this.props;

    let config = {
      defaultPageSize: 5,
      defaultSorted: [{ id: "id", desc: true }],
      pageSize: 15,
      resizable: false,
      showPagination: true,
      noDataText: "Nenhum registro".toUpperCase(),
      loadingText: "Carregando",
      className: "-striped -highlight",
      previousText: "Anterior",
      nextText: "Próximo",
      pageText: "Página",
      ofText: "de",
      rowsText: "linhas",
      columns: [],
      showPageSizeOptions: false,
      pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
    };

    const formatCnpj = function (data) {
      const original = data.original;
      return StringUtil.formatCNPJ(original.cnpj);
    };

    config.data = objects.value && objects.value ? objects.value.data : [];
    config.loading = objects.pending;
    config.onDoubleClickRow = this.props.onTableDoubleClick;
    config.columns = [
      {
        id: "id",
        Header: "Código",
        sortable: true,
        accessor: "id",
        className: styles.centeredText,
        width: 80,
      },
      {
        id: "name",
        Header: "Nome",
        sortable: true,
        accessor: "name",
        className: styles.centeredText,
      },
      {
        id: "cnpj",
        Header: "CNPJ",
        sortable: true,
        Cell: formatCnpj,
        className: styles.centeredText,
      },
      {
        id: "city",
        Header: "Cidade",
        sortable: true,
        accessor: "city",
        className: styles.centeredText,
      },
    ];

    return (
      <React.Fragment>
        <PanelMaterial>
          <PanelMaterialTop title={"Unidades"}>
            <AccessManager allowed={[ACCESS_PROFILES.GENERAL_ADMINISTRATOR]}>
              <Link to="/unidades/nova">
                <ButtonSaveMaterial
                  title="Nova Unidade"
                  type="highlight"
                  position="top"
                  right={true}
                />
              </Link>
            </AccessManager>
          </PanelMaterialTop>
          <PanelMaterialBody>
            <LVTable
              filterable
              manual
              onTableDoubleClick={this.onTableDoubleClick}
              onFetchData={filter}
              pages={this.props.pages}
              data={config.data}
              ofText={config.ofText}
              columns={config.columns}
              loading={config.loading}
              pageSize={config.pageSize}
              rowsText={config.rowsText}
              pageText={config.pageText}
              sortable={config.sortable}
              nextText={config.nextText}
              resizable={config.resizable}
              className={config.className}
              noDataText={config.noDataText}
              loadingText={config.loadingText}
              previousText={config.previousText}
              defaultSorted={config.defaultSorted}
              showPagination={config.showPagination}
              defaultPageSize={config.defaultPageSize}
              showPageSizeOptions={config.showPageSizeOptions}
              onDoubleClickRow={config.onDoubleClickRow}
            />
          </PanelMaterialBody>
        </PanelMaterial>
      </React.Fragment>
    );
  }
}

export default UnityView;
