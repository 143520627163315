import React from 'react';

/**
 * @author Victor Heringer
 * 
 * Render a icon to represent an uploaded file
 * 
 * @prop {Boolean} display
 * @prop {Component} icon Any component that can reporesent an icon 
 */
const FilePreview = ({ display, icon }) => {
  return display && <div>{icon}</div>;
}

export default FilePreview;