import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import { SEX } from "../../assets/js/Consts";
import NewTripleRadioMaterial from "../NewTripleRadioMaterial";

const defaultProps = {};
const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for dual radio with material design styling
 */
const AnimalSexRadioMaterial = ({
  disabled,
  fieldName,
  selectedValue,
  updateField,
  $field,
  $validation,
  required,
}) => (
  <NewTripleRadioMaterial
    required={required}
    id={fieldName}
    title={"Sexo"}
    name={fieldName}
    disabled={disabled}
    selectedValue={selectedValue.toString()}
    firstLabel={"Macho"}
    firstValue={SEX.M.toString()}
    secondLabel={"Fêmea"}
    secondValue={SEX.F.toString()}
    thirdLabel={"Indefinido"}
    thirdValue={SEX.U.toString()}
    {...$field(fieldName, (value) => {
      updateField(fieldName, value);
    })}
    hasValidation={$validation[fieldName].show}
    validationReason={$validation[fieldName].error.reason}
  />
);

AnimalSexRadioMaterial.defaultProps = defaultProps;
AnimalSexRadioMaterial.propTypes = propTypes;

export default React.memo(AnimalSexRadioMaterial);
