import React, { useReducer } from "react";
import { reducer, ACTIONS } from "./reducer";
import { Vet } from "./../../types/index";

const defaultVet = {
  id: 0,
  name: "",
  crmv: "",
  signatureData: "",
  signatureFile: "",
};

export const useVet = () => {
  const [vet, dispatch] = useReducer(reducer, defaultVet);

  const data = vet;
  const methods = {
    update: (vet: Vet) => dispatch({ type: ACTIONS.UPDATE, payload: vet }),
    updateField: (field: string, value: string | number | boolean) =>
      dispatch({ type: ACTIONS.UPDATE_FIELD, payload: { field, value } }),
    reset: () => dispatch({ type: ACTIONS.UPDATE, payload: defaultVet }),
  };
  return {
    data,
    methods,
  };
};
