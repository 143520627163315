import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import CoagulationTestContent from "./../CoagulationTestContent";
import TextAreaPanel2 from "./../TextAreaPanel2";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Row, Col } from "./../../components/grid";

const CoagulationTestReportFormView = ({
  data: {
    refs,
    isUpdate,
    fields,
    backLink,
    disableAll,
    $validation,
    submitData,
    reportHeaderData,
    shouldSendEmail
  },
  methods: {
    fieldChange,
    handleChange,
    handleBlur,
    onSubmit,
    $field,
    handleSendReportEmail,
    cancelReport,
    stopReport
  }
}) => (
  <ReportPanelMaterial
    title="Teste de coagulação (PT e APTT)"
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitData.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <CoagulationTestContent
          panelTitle={"Resultado"}
          tromboplastinTimeName={"tromboplastinTime"}
          tromboplastinTimeValue={fields.tromboplastinTime}
          tromboplastinTimeRefMin={refs.tromboplastinTimeMin}
          tromboplastinTimeRefMax={refs.tromboplastinTimeMax}
          tromboplastinTimeRefUnit={"SEGUNDOS"}
          tromboplastinTimeValidationShow={$validation.tromboplastinTime.show}
          tromboplastinTimeValidationReason={
            $validation.tromboplastinTime.error.reason
          }
          prothrombinTimeName={"prothrombinTime"}
          prothrombinTimeValue={fields.prothrombinTime}
          prothrombinTimeRefMin={refs.prothrombinTimeMin}
          prothrombinTimeRefMax={refs.prothrombinTimeMax}
          prothrombinTimeRefUnit={"SEGUNDOS"}
          prothrombinTimeValidationShow={$validation.prothrombinTime.show}
          prothrombinTimeValidationReason={
            $validation.prothrombinTime.error.reason
          }
          disableAll={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <TextAreaPanel2
          title={"Obs"}
          fieldTitle={""}
          name={"obs"}
          value={fields.obs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default CoagulationTestReportFormView;
