import React, { Component } from 'react';

/** External */
import { Switch, Route } from 'react-router-dom';

/** Internal */
import ExamRequestList from './../ExamRequestList';
import ExamRequestForm from './../ExamRequestForm';
import ReportSection from './../ReportSection';
import ExamRequestSearchList from './../ExamRequestSearchList';
import ExamRequest2_0 from './../ExamRequest2.0';
import Home from '../Home';

class ExamRequestSection extends Component {

  constructor(props) {
    super(props);
    this.renderList = this.renderList.bind(this);
    this.renderHome = this.renderHome.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderSearch = this.renderSearch.bind(this);
    this.renderReportSection = this.renderReportSection.bind(this);
  }

  renderExamRequest2_0 = (routeProps) => {
    return <ExamRequest2_0
      {...routeProps}
      notificationSystem={this.props.notificationSystem}
    />;
  }

  /**
   *
   * @version 0.0.1
   *
   * @author Kayo Oliveira <contato@kayooliveira.com>
   *
  */

  renderHome(routeProps) {
    return (
      <Home
        {...routeProps}
        notificationSystem={this.props.notificationSystem} />
    )
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Renders the exam request list
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderList(routeProps) {
    return <ExamRequestList
      {...routeProps}
      notificationSystem={this.props.notificationSystem}
    />;
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Renders the exam request form
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderForm(routeProps) {
    return (
      <ExamRequestForm
        {...routeProps}
        key={this.props.location.key}
        notificationSystem={this.props.notificationSystem}
      />
    );
  }

  /**
   * @author Victor Heringer
   *
   * Renders the exam request search
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderSearch(routeProps) {
    return (
      <ExamRequestSearchList
        {...routeProps}
        notificationSystem={this.props.notificationSystem}
      />
    );
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Render the report request route
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderReportSection(routeProps) {
    return (
      <ReportSection
        {...routeProps}
        notificationSystem={this.props.notificationSystem}
      />
    );
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path={'/'}
          render={this.renderHome} />
        <Route
          exact
          path={'/solicitacoes-listagem'}
          render={this.renderList} />
        <Route
          exact
          path={'/solicitacoes-exame'}
          render={this.renderHome} />
        <Route
          exact
          path={'/solicitacoes-exame/buscar'}
          render={this.renderSearch} />
        <Route
          exact
          path={'/solicitacoes-exame/novo'}
          render={this.renderForm} />
        <Route
          exact
          path={'/solicitacoes-exame/:id'}
          render={this.renderForm} />
        <Route
          path={'/solicitacoes-exame/:id'}
          render={this.renderReportSection} />
      </Switch>
    );
  }
}

export default ExamRequestSection;