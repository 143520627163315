import { validated } from "react-custom-validation";

import {
  commonReportRules,
  globalLeukometryRules,
  leukogramRules
} from "../../assets/js/validationRules";

const validation = validated(props => {
  const {
    globalLeukometry,
    hasHeterophils,
    isIntenseLeucopeny,
    relativeBasophils,
    relativeEosinophils,
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    relativeSegs,
    relativeLymphocytes,
    relativeMonocytes,
    relativeHeterophils,
    leukogramObs,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  let fields = [
    "globalLeukometry",
    "relativeBasophils",
    "relativeEosinophils",
    "relativeMyelocytes",
    "relativeMetamyelocytes",
    "relativeBands",
    "relativeSegs",
    "relativeLymphocytes",
    "relativeMonocytes",
    "relativeHeterophils",
    "leukogramObs",
    "total",
    "requestExamState",
    "vetSignerId"
  ];

  const { isUpdate, total, refs } = props.data;

  const commonValidation = commonReportRules(vetSignerId, requestExamState);

  const globalLeukometryValidation = globalLeukometryRules(globalLeukometry);

  const fieldNames = {
    relativeBasophilsName: "relativeBasophilsName",
    relativeEosinophilsName: "relativeEosinophilsName",
    relativeLymphocytesName: "relativeLymphocytesName",
    relativeMonocytesName: "relativeMonocytesName",
    relativeHeterophilsName: "relativeHeterophilsName",
    relativeMyelocytesName: "relativeMyelocytesName",
    relativeMetamyelocytesName: "relativeMetamyelocytesName",
    relativeBandsName: "relativeBandsName",
    relativeSegsName: "relativeSegsName"
  };


  const leukogramValidation = leukogramRules({
    isIntenseLeucopeny,
    relativeBasophils,
    relativeEosinophils,
    relativeLymphocytes,
    relativeMonocytes,
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    relativeSegs,
    relativeHeterophils,
    leukogramObs,
    hasHeterophils: refs.hasHeterophils,
    isUpdate,
    total,
    fieldNames
  });

  const validations = {
    ...commonValidation,
    ...globalLeukometryValidation,
    ...leukogramValidation
  };

  return { fields, validations };
});

export default validation;
