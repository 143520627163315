import React, { useEffect, useState } from 'react'

import { ROUTES, useFetch } from "../../hooks/use-fetch"
import {Link, useHistory} from  'react-router-dom'

import PanelMaterial from "../PanelMaterial"
import PanelMaterialTop from "../PanelMaterialTop"
import PanelMaterialBody from "../PanelMaterialBody"

import LVTable from "../../old_components/LVTable"
import { ButtonDefaultMaterial } from '../../new_components/ButtonDefaultMaterial'

type AntibioticType = {
  id: string
  name: string
  initials: string
}

export function AntibioticList() {
  const [ pages, setPages ] = useState<number>(1)
  const antibiotics = useFetch<AntibioticType>()
  const history = useHistory()

  useEffect(() => {
    antibiotics.fetch(ROUTES.getAntibiotic())
  }, [])

  function editAntibiotic(_: any, __: any, rowInfo: any) {
    history.push(`/antibioticos/${rowInfo.original.id}`)
  }

  const config = {
    defaultPageSize: 5,
    defaultSorted: [{ id: "id", desc: true }],
    pageSize: 15,
    resizable: false,
    showPagination: true,
    noDataText: "Nenhum registro".toUpperCase(),
    loadingText: "Carregando",
    className: "-striped -highlight",
    previousText: "Anterior",
    nextText: "Próximo",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    showPageSizeOptions: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
    data:antibiotics.data || [],
    loading:antibiotics.pending,
    filterable: true,
    manual: true,
    pages: pages,
    columns: [
      {
        Header: "Código",
        sortable: true,
        minWidth: 1,
        accessor: "id",
      },

      {
        Header: "Nome",
        sortable: true,
        minWidth: 2,
        accessor: "name",
      },
      
      {
        Header: "Iniciais",
        sortable: false,
        minWidth: 2,
        accessor: "initials",
      },
    ],
    onDoubleClickRow: editAntibiotic
  }

  return (
      <PanelMaterial flat={false} >
        <PanelMaterialTop title={"Antibióticos"}>
          <Link to="/antibioticos/novo">
            <ButtonDefaultMaterial variant="highlight" position="right" >
              Novo Antibiótico
            </ButtonDefaultMaterial>
          </Link>
        </PanelMaterialTop>

        <PanelMaterialBody>
          <LVTable
            filterable={config.filterable}
            manual={config.manual}
            pages={config.pages}
            data={config.data}
            columns={config.columns}
            loading={config.loading}
            pageSize={config.pageSize}
            sortable={true}
            defaultSorted={config.defaultSorted}
            showPagination={config.showPagination}
            defaultPageSize={config.defaultPageSize}
            showPageSizeOptions={config.showPageSizeOptions}
            onDoubleClickRow={config.onDoubleClickRow}
          />
        </PanelMaterialBody>
      </PanelMaterial>
   )
}