import React from "react";
import InputResult from "../../components/hormone-content/input-result";

import { EXAM_ID_BY_NAME, EXAM_TITLE_BY_ID, EXAM_NAME_BY_ID, fieldNameToTitle, multiResultHormenesIds } from "../../consts/exams";
import { biochemistryDefaultValues } from "./biochemistryFactory";

function formatResult(value) {
  switch (typeof value) {
    case "string":
      return value.toString();
    case "number":
      return value;
    default:
      return "";
  }
};


export function addReportData(options, reports) {
  
  return options.map(option => {
    const found = reports.find(report => report.examId === option.examId);
    if (found) {
      option.id = found.id;
      option.requestExamState = found.requestExamState;
      option.obs = found.obs;
      switch (option.examId) {
        case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]: {
          option.total = formatResult(found.total);
          option.direct = formatResult(found.direct);
          break;
        }
        case EXAM_ID_BY_NAME["IONIC_CALCIUM"]: {
          const report = reports.find(report => report.isResultEditable != undefined);
          option.result = formatResult(found.result);
          option.calcium = formatResult(found.calcium);
          option.albumin = formatResult(found.albumin);
          option.totalProtein = formatResult(found.totalProtein);
          option.isResultEditable = report.isResultEditable === 1 ? true : false;
        }
        case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]: {
          option.totalProtein = formatResult(found.totalProtein);
          option.albumin = formatResult(found.albumin);
          option.globulin = formatResult(found.globulin);
          break;
        }
        case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]: {
          option.totalCholesterol = formatResult(found.totalCholesterol);
          option.hdl = formatResult(found.hdl);
          option.triglycerides = formatResult(found.triglycerides);
          break;
        }
        case EXAM_ID_BY_NAME["ELECTROLYTES"]: {
          const report = reports.find(report => report.isIonicCalciumEditable != undefined);
          option.sodium = formatResult(found.sodium);
          option.potassium = formatResult(found.potassium);
          option.phosphorus = formatResult(found.phosphorus);
          option.chloride = formatResult(found.chloride);
          option.calcium = formatResult(found.ionicCalciumCalcium);
          option.totalProtein = formatResult(found.ionicCalciumTotalProtein);
          option.albumin = formatResult(found.ionicCalciumAlbumin);
          option.ionicCalcium = formatResult(found.ionicCalciumIonicCalcium);
          option.isResultEditable = report.isIonicCalciumEditable;

          break;
        }
        default: {
          option.result = formatResult(found.result);
        }
      }
    }
    return option;
  });
}

export function fillObjectDataWithReport(options, report) {
  
  const reports = {};
  return options.map(option => {
    // const found = reports.find(report => report.examId === option.examId);

      option.id = report.id;
      option.requestExamState = report.requestExamState;
      option.obs = "TO DO";//report.obs;
      switch (option.id) {
        case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]: {
          option.total = formatResult(report.bilirubinAndFractionsTotal);
          option.direct = formatResult(report.bilirubinAndFractionsDirect);
          break;
        }
        case EXAM_ID_BY_NAME["IONIC_CALCIUM"]: {
          // const report = reports.find(report => report.isResultEditable != undefined);
          option.result = formatResult(report.ionicCalciumIonicCalcium);
          option.calcium = formatResult(report.ionicCalciumCalcium);
          option.albumin = formatResult(report.ionicCalciumAbumin);
          option.totalProtein = formatResult(report.ionicCalciumTotalProtein);
          option.isResultEditable = report.ionicCalciumIsResultEditable === 1 ? true : false;
        }
        case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]: {
          option.totalProtein = formatResult(report.totalProteinTotalProtain);
          option.albumin = formatResult(report.totalProteinAlbumin);
          option.globulin = formatResult(report.totalProteinGlobulin);
          break;
        }
        case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]: {
          option.totalCholesterol = formatResult(report.totalCholesterolAnFractionsTotalCholesterol);
          option.hdl = formatResult(report.totalCholesterolAnFractionsHdl);
          option.triglycerides = formatResult(report.totalCholesterolAnFractionsTriglycerides);
          break;
        }
        
        default: {
          const objectKey = EXAM_NAME_BY_ID[option.examId];
          const result = report[objectKey];
          result && (option.result = formatResult(result));
        }
      }
    
    return option;
  });
}




export function biochemistryFactory(examId, refs) {
  
  const data = biochemistryDefaultValues(examId, refs);
    
    return {
      id: 0,
      examId: examId,
      name: EXAM_TITLE_BY_ID[examId],
      // examTypeId: requestExam.examTypeId,
      // requestExamId: requestExam.id,
      // requestExamState: requestExam.requestExamState,
      // endDate: requestExam.endDate,
      // deadline: requestExam.deadline,
      ...data
    };
  }
