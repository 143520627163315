import { connect } from "react-refetch";
import GetUserTokenBasedOnEnv from "./services/get-user-token-based-on-env";
export default connect.defaults({
  buildRequest: function (mapping) {
    let options = {
      headers: mapping.headers,
      method: mapping.method,
      credentials: mapping.credentials,
      redirect: mapping.redirect,
      mode: mapping.mode,
      body: mapping.body,
    };
    options.headers["authorization"] = GetUserTokenBasedOnEnv();

    return new Request(process.env.REACT_APP_PROXY + mapping.url, options);
  },
  handleResponse: function (response) {
      if (response.status === 403) {
        window.location.href = '/login';
        localStorage.removeItem('auth_token');
        localStorage.setItem('session_expired', true) // Used on login page to show the toast. Please don't remove.
        throw new Error("Unauthorized");
    }
    return response.json()
  }
});
