import * as MESSAGES from "./messages";
import * as EXAMS from "./exams";
import * as MASKS from "./masks";
import * as NOTIFICATIONS from "./notifications";
import * as REPORT_DEFAULT_VALUES from "./report-default-values";
import * as PAYMENT_FREQUENCY from "./payment-frequency";
import * as INVOICE_STATES from "./invoice-states";
import * as PAYMENT_METHODS from "./payment-methods";
import * as TICKET_STATUS from "./ticket-status";
import * as ACCESS_PROFILES from "./access-profiles";
import * as EXAM_REQUEST_STATES from "./exam-request-states";
import * as REQUEST_EXAM_STATES from "./request-exam-states";
import * as ENV_TYPES from "./env-types";
import * as ENV from "./env";
import * as LOCAL_STORAGE_KEYS from "./local-storage-keys";
import * as TIME_LENGTHS from "./time-lengths";
import * as EXAM_TYPES from "./exam-types";
import { getDecimalPrecisionByExamId } from "./decimal-precision-by-exam";

const INPUT_SPECIAL_VALUE = -1;

export {
  MESSAGES,
  EXAMS,
  MASKS,
  REPORT_DEFAULT_VALUES,
  NOTIFICATIONS,
  PAYMENT_FREQUENCY,
  INVOICE_STATES,
  PAYMENT_METHODS,
  INPUT_SPECIAL_VALUE,
  TICKET_STATUS,
  ACCESS_PROFILES,
  EXAM_REQUEST_STATES,
  REQUEST_EXAM_STATES,
  ENV_TYPES,
  ENV,
  LOCAL_STORAGE_KEYS,
  TIME_LENGTHS,
  EXAM_TYPES,
  getDecimalPrecisionByExamId,
};
