import { getChemiluminescenceTableObjs } from "../../../assets/js/reportFunctions";
import { chemiluminescenceRules } from "../../../assets/js/validationRules";
import { EXAM_TYPES } from "../../../consts";
import {
  EXAM_ID_BY_NAME,
  EXAM_NAME_BY_ID,
  profileStructure,
} from "../../../consts/exams";

const camelCase = (str) => {
  const regExp = /[-_]\w/gi;
  return str.toLowerCase().replace(regExp, (match) => match[1].toUpperCase());
};

export const chemiluminescenceDefaultState = (examId) => {
  const chemiluminescenceIds = profileStructure[examId].chemiluminescenceIds;
  const fields = chemiluminescenceIds.reduce((acc, id) => {
    const fieldName = camelCase(EXAM_NAME_BY_ID[id]);
    return { acc, [fieldName]: "" };
  }, {});
  return {
    fields,
    chemiluminescenceObs: "",
    chemiluminescenceTotalT4: fields.chemiluminescenceTotalT4,
    chemiluminescenceCortisol: fields.chemiluminescenceCortisol,
    chemiluminescenceLevothyroxinAfterTotalT4:
      fields.chemiluminescenceLevothyroxinAfterTotalT4,
  };
};

export const chemiluminescenceStateApiFactory = (examId, obj) => {
  const chemiluminescenceIds = profileStructure[examId].chemiluminescenceIds;
  const state = chemiluminescenceIds.reduce((acc, id) => {
    const fieldName = camelCase(EXAM_NAME_BY_ID[id]);
    return { ...acc, [fieldName]: obj[fieldName] };
  }, {});
  return {
    ...state,
    chemiluminescenceTotalT4: obj.chemiluminescenceTotalT4,
    chemiluminescenceCortisol: obj.chemiluminescenceCortisol,
    chemiluminescenceLevothyroxinAfterTotalT4:
      obj.chemiluminescenceLevothyroxinAfterTotalT4,
  };
};

export const chemiluminescenceFieldsToApiParser = (examId, fields) => {
  const chemiluminescenceIds = profileStructure[examId].chemiluminescenceIds;
  const chemiluminescenceFields = chemiluminescenceIds.reduce((acc, id) => {
    const fieldName = camelCase(EXAM_NAME_BY_ID[id]);
    return { ...acc, [fieldName]: fields[fieldName] };
  }, {});
  return {
    ...chemiluminescenceFields,
  };
};

export const chemiluminescenceTableRowsState = (examId, fields, refs) => {
  if (!profileStructure[examId].hasChemiluminescence) {
    return getChemiluminescenceTableObjs([], refs);
  }
  const chemiluminescenceIds = profileStructure[examId].chemiluminescenceIds;
  const chemiluminescenceValues = chemiluminescenceIds.reduce(
    (acc, id) => [...acc, chemiluminescenceStateRowByExamId(id, fields)],
    []
  );
  return getChemiluminescenceTableObjs(chemiluminescenceValues, refs);
};

export const chemiluminescenceValidationRules = (
  fields,
  isCompleteValidation
) => {
  const {
    chemiluminescenceCortisol,
    chemiluminescenceTotalT4,
    chemiluminescenceLevothyroxinAfterTotalT4,
  } = fields;

  return chemiluminescenceRules(
    "chemiluminescenceCortisol",
    chemiluminescenceCortisol,
    "chemiluminescenceLevothyroxinAfterTotalT4",
    chemiluminescenceLevothyroxinAfterTotalT4,
    "chemiluminescenceTotalT4",
    chemiluminescenceTotalT4,
    isCompleteValidation
  );
};

export const chemiluminescenceFieldsNames = (examId) => {
  const chemiluminescenceIds = profileStructure[examId].chemiluminescenceIds;
  return chemiluminescenceIds.map((id) => camelCase(EXAM_NAME_BY_ID[id]));
};

const chemiluminescenceStateRowByExamId = (id, fields) => {
  switch (id) {
    default:
      const fieldName = camelCase(EXAM_NAME_BY_ID[id]);

      return {
        id,
        result: fields[fieldName],
      };
  }
};

export const chemiluminescenceRequests = (requestExamId) => {
  return {
    chemiluminescenceRefsPromise: `/reference-values/endocrinology-chemiluminescence/request-exams/${requestExamId}`,
  };
};
