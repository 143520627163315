import React, { Component } from "react";

/** Internal */
import LabFormFactory from "./factory";
import connectWithEndpoint from "./requests.js";
import { connect } from "react-redux";
import UnityFormView from "./../UnityFormView";
import { NOTIFICATIONS } from "./../../consts";

import { FIVE_SECONDS } from "./../../consts/time-lengths";

/*
  THE Consts.js FILE MUST BE ELIMINATED!
  ALL CONSTS SHALL BE PLACED IN consts/ DIRECTORY.
  */
import { ACCESS_PROFILES } from "../../assets/js/Consts";

/** External */
import update from "immutability-helper";
import { Redirect } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { FunctionUtil } from "./../../useful";

/**
 * Represent a new email data
 * @var {Object}
 */
const DEFAULT_EMAIL = LabFormFactory.createDefaultEmail();

/**
 * Represent a new phone data
 * @var {Object}
 */
const DEFAULT_PHONE = LabFormFactory.createDefaultPhone();

/**
 * Represent a new region data
 * @var {Object}
 */
const DEFAULT_REGION = LabFormFactory.createDefaultRegion();

/**
 * Represent a new user data
 * @var {Object}
 */
const DEFAULT_USER = LabFormFactory.createDefaultUser();

/**
 * Represent a new responsible data
 * @var {Object}
 */
const DEFAULT_RESPONSIBLE = LabFormFactory.createDefaultResponsible();

/**
 * @author Victor Heringer
 *
 * Component to held all state and functions for Unity Form
 */
class UnityForm extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      fields: {
        id: id ? id : 0,
        companyId: 0,
        name: "",
        cnpj: "",
        status: 1,
        stateId: 0,
        cityId: 0,
        neighborhood: "",
        cep: "",
        publicPlace: "",
        number: "",
        complement: "",

        /** Users */
        modalUserId: DEFAULT_USER.id,
        modalUserLogin: DEFAULT_USER.login,
        modalUserActive: DEFAULT_USER.active,
        modalUserPassword: DEFAULT_USER.password,
        modalUserName: DEFAULT_USER.username,
        modalUserEmail: DEFAULT_USER.email,
        modalUserPhone: DEFAULT_USER.phone,
        modalUserCPF: DEFAULT_USER.cpf,
        modalUserPhone: DEFAULT_USER.phone,
        modalUserIsVet: DEFAULT_USER.isVet,
        modalUserCRMV: DEFAULT_USER.crmv,
        modalUserInitIsVet: DEFAULT_USER.isVet,
        modalUserSignature: DEFAULT_USER.signature,
        modalUserAccessProfile: DEFAULT_USER.ACCESS_PROFILES,
        users: [],

        /** Responsible */
        modalResponsibleId: DEFAULT_RESPONSIBLE.id,
        modalResponsibleLogin: DEFAULT_RESPONSIBLE.login,
        modalResponsibleActive: DEFAULT_RESPONSIBLE.active,
        modalResponsiblePassword: DEFAULT_RESPONSIBLE.password,
        modalResponsibleName: DEFAULT_RESPONSIBLE.username,
        modalResponsibleEmail: DEFAULT_RESPONSIBLE.email,
        modalResponsiblePhone: DEFAULT_RESPONSIBLE.phone,
        modalResponsibleCPF: DEFAULT_RESPONSIBLE.cpf,
        modalResponsiblePhone: DEFAULT_RESPONSIBLE.phone,
        modalResponsibleIsVet: DEFAULT_RESPONSIBLE.isVet,
        modalResponsibleCRMV: DEFAULT_RESPONSIBLE.crmv,
        modalResponsibleInitIsVet: DEFAULT_RESPONSIBLE.isVet,
        modalResponsibleSignature: DEFAULT_RESPONSIBLE.signature,
        modalResponsibleAccessProfile: DEFAULT_RESPONSIBLE.ACCESS_PROFILES,
        responsible: [],

        /** Regions */
        modalRegionId: DEFAULT_REGION.id,
        modalRegion: DEFAULT_REGION.value,
        regions: [],

        /** Email */
        modalEmailId: DEFAULT_EMAIL.id,
        modalEmail: DEFAULT_EMAIL.value,
        emails: [],

        /** Phone */
        modalPhoneId: DEFAULT_PHONE.id,
        modalPhone: DEFAULT_PHONE.value,
        phones: [],
      },
      testFiles: [],

      displayRegionModal: false,
      selectedRegions: [],

      displayEmailModal: false,
      selectedEmails: [],

      displayPhoneModal: false,
      selectedPhones: [],

      displayUserModal: false,
      selectedUsers: [],

      displayResponsibleModal: false,
      selectedResponsible: [],
    };

    this.isUpdateing = this.isUpdateing.bind(this);
    this.loadCities = this.loadCities.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
    this.fieldChangeCNPJ = this.fieldChangeCNPJ.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleSelectAtTable = this.toggleSelectAtTable.bind(this);
    this.invalidNotification = this.invalidNotification.bind(this);
    this.includedNotification = this.includedNotification.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
    this.handleSignatureUpload = this.handleSignatureUpload.bind(this);
    this.handleResponsibleSignatureUpload =
      this.handleResponsibleSignatureUpload.bind(this);

    this.fieldChangeIsVet = this.fieldChangeIsVet.bind(this);

    /** User */
    this.handleRemoveUser = this.handleRemoveUser.bind(this);
    this.toggleSelectUser = this.toggleSelectUser.bind(this);
    this.addUserToTable = this.addUserToTable.bind(this);
    this.handleLoadUserToModal = this.handleLoadUserToModal.bind(this);
    this.definesNewUserAccessProfile =
      this.definesNewUserAccessProfile.bind(this);

    /** Responsible */
    this.handleRemoveResponsible = this.handleRemoveResponsible.bind(this);
    this.toggleSelectResponsible = this.toggleSelectResponsible.bind(this);
    this.addResponsibleToTable = this.addResponsibleToTable.bind(this);
    this.handleLoadResponsibleToModal =
      this.handleLoadResponsibleToModal.bind(this);
    this.definesNewResponsibleAccessProfile =
      this.definesNewResponsibleAccessProfile.bind(this);

    /** Regions */
    this.handleRemoveRegion = this.handleRemoveRegion.bind(this);
    this.toggleSelectRegion = this.toggleSelectRegion.bind(this);
    this.addRegionToTable = this.addRegionToTable.bind(this);
    this.handleLoadRegionToModal = this.handleLoadRegionToModal.bind(this);

    /** Emails */
    this.handleRemoveEmail = this.handleRemoveEmail.bind(this);
    this.toggleSelectEmail = this.toggleSelectEmail.bind(this);
    this.addEmailToTable = this.addEmailToTable.bind(this);
    this.handleLoadEmailToModal = this.handleLoadEmailToModal.bind(this);

    /** Phones */
    this.handleRemovePhone = this.handleRemovePhone.bind(this);
    this.toggleSelectPhone = this.toggleSelectPhone.bind(this);
    this.addPhoneToTable = this.addPhoneToTable.bind(this);
    this.handleLoadPhoneToModal = this.handleLoadPhoneToModal.bind(this);
  }

  /**
   * @author Victor Heringer
   *
   * Lifecycle method to set value at form if that is being update instead
   * of adding a new register
   */
  componentDidMount() {
    const onSuccess = (data, xhr) => {
      xhr.response.status === 200 && this.fillFields(data);
    };

    this.isUpdateing() && this.props.withId(this.state.fields.id, onSuccess);
  }

  /**
   * @author Victor Heringer
   *
   * Loads cities based on selected state
   */
  loadCities(stateId, then) {
    this.props.citiesForState(stateId, then);
  }

  /**
   * @author Victor Heringer
   *
   * Pops up a notifition for invalid submit
   */
  invalidNotification() {
    this.props.notificationSystem.add(NOTIFICATIONS.DEFAULT_INVALID_MESSAGE);
  }

  /**
   * @author Victor Heringer
   *
   * Pops up a notifition for already included values
   */
  includedNotification() {
    this.props.notificationSystem.add(
      NOTIFICATIONS.DEFAULT_ALREADY_INCLUDED_MESSAGE
    );
  }

  /**
   * @author Victor Heringer
   *
   * Removes pops up a notifition
   */
  removeNotification() {
    this.props.notificationSystem.add(
      NOTIFICATIONS.DEFAULT_DELETE_SUCCESS_MESSAGE
    );
  }

  /**
   * @author Victor Heringer
   *
   * Handles file upload
   *
   * @param {Object} file
   */
  handleSignatureUpload(file) {
    this.setState((state) =>
      update(state, {
        fields: {
          modalUserSignature: { $set: file },
        },
      })
    );
  }

  handleResponsibleSignatureUpload(file) {
    this.setState((state) =>
      update(state, {
        fields: {
          modalResponsibleSignature: { $set: file },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Decides if a create or edit screen
   *
   * @return {Bool}
   */
  isUpdateing() {
    return this.state.fields.id > 0;
  }

  /**
   * @author Victor Heringer
   *
   * Fill state related to all form fields if the values are give
   *
   * @param {string} obj All values that will be used to fill fields
   */
  fillFields(obj) {
    const lab = LabFormFactory.formatLabFromApi(obj);

    this.setState((state) =>
      update(state, {
        fields: {
          name: {
            $set: lab.name,
          },
          status: {
            $set: lab.status,
          },
          cnpj: {
            $set: lab.cnpj,
          },
          companyId: {
            $set: lab.companyId,
          },
          stateId: {
            $set: lab.stateId,
          },
          cep: {
            $set: lab.cep,
          },
          neighborhood: {
            $set: lab.neighborhood,
          },
          publicPlace: {
            $set: lab.publicPlace,
          },
          number: {
            $set: lab.number,
          },
          complement: {
            $set: lab.complement,
          },
          regions: {
            $set: lab.regions,
          },
          phones: {
            $set: lab.phones,
          },
          emails: {
            $set: lab.emails,
          },
          users: {
            $set: lab.users,
          },
          responsible: {
            $set: lab.responsible,
          },
        },
      })
    );

    const loadCity = () => {
      this.setState((state) =>
        update(state, {
          fields: {
            cityId: { $set: lab.cityId },
          },
        })
      );
    };

    this.loadCities(lab.stateId, loadCity);
  }

  /**
   * @author Victor Heringer
   *
   * Decides witch is the acesss profile that a new user will receive based
   * on current user access profile
   *
   * @param {Number} currentUserProfile
   */
  definesNewUserAccessProfile(currentUserProfile) {
    switch (currentUserProfile) {
      case ACCESS_PROFILES.GENERAL_ADMINISTRATOR: {
        return ACCESS_PROFILES.UNIT_ADMINISTRATOR;
      }
      case ACCESS_PROFILES.UNIT_ADMINISTRATOR: {
        return ACCESS_PROFILES.UNIT_USER;
      }
    }
  }

  definesNewResponsibleAccessProfile(currentResponsibleProfile) {
    switch (currentResponsibleProfile) {
      case ACCESS_PROFILES.GENERAL_ADMINISTRATOR: {
        return ACCESS_PROFILES.UNIT_ADMINISTRATOR;
      }
      case ACCESS_PROFILES.UNIT_ADMINISTRATOR: {
        return ACCESS_PROFILES.UNIT_USER;
      }
    }
  }

  /**
   * @author Victor Heringer
   *
   * Changes the given field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChange(field, value) {
    this.setState((state) =>
      update(state, {
        fields: {
          [field]: { $set: value },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Changes the isVet field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChangeIsVet(field, value) {
    this.setState((state) =>
      update(state, {
        fields: {
          [field]: {
            $set: value,
          },
          modalUserCRMV: {
            $set: "",
          },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Changes the given field state and call the api validate
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChangeCNPJ(field, value) {
    this.setState((state) =>
      update(state, {
        fields: {
          [field]: { $set: value },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Submits form data for api
   */
  handleSubmit() {
    const onSuccess = () => {
      this.props.notificationSystem.add(NOTIFICATIONS.DEFAULT_SAVE_MESSAGE);
    };

    const onError = () => {
      this.props.notificationSystem.add(NOTIFICATIONS.DEFAULT_ERROR_MESSAGE);
    };

    const fields = this.state.fields;

    if (!fields.id) {
      this.props.add(fields, onSuccess, onError);
    } else {
      this.props.update(fields.id, fields, onSuccess, onError);
    }
  }

  /**
   * @author Victor Heringer
   *
   * Load to modal a new user or a existing one
   *
   * @param user Object
   */
  handleLoadUserToModal(user) {
    this.setState((state) =>
      update(state, {
        fields: {
          modalUserId: {
            $set: user.id,
          },
          modalUserLogin: {
            $set: user.login,
          },
          modalUserActive: {
            $set: user.active,
          },
          modalUserPassword: {
            $set: user.password,
          },
          modalUserName: {
            $set: user.username,
          },
          modalUserEmail: {
            $set: user.email,
          },
          modalUserCPF: {
            $set: user.cpf,
          },
          modalUserAccessProfile: {
            $set: user.accessProfile,
          },
          modalUserPhone: {
            $set: user.phone,
          },
          modalUserIsVet: {
            $set: user.isVet,
          },
          modalUserInitIsVet: {
            $set: user.isVet,
          },
          modalUserCRMV: {
            $set: user.crmv,
          },
          modalUserSignature: {
            $set: user.signature,
          },
        },
        displayUserModal: {
          $set: !this.state.displayUserModal,
        },
      })
    );
  }

  handleLoadResponsibleToModal(responsible) {
    this.setState((state) =>
      update(state, {
        fields: {
          modalResponsibleId: {
            $set: responsible.id,
          },
          modalResponsibleLogin: {
            $set: responsible.login,
          },
          modalResponsibleActive: {
            $set: 1,
          },
          modalResponsiblePassword: {
            $set: responsible.password,
          },
          modalResponsibleName: {
            $set: responsible.username,
          },
          modalResponsibleEmail: {
            $set: responsible.email,
          },
          modalResponsibleCPF: {
            $set: responsible.cpf,
          },
          modalResponsibleAccessProfile: {
            $set: responsible.accessProfile,
          },
          modalResponsiblePhone: {
            $set: responsible.phone,
          },
          modalResponsibleIsVet: {
            $set: 1,
          },
          modalResponsibleInitIsVet: {
            $set: responsible.isVet,
          },
          modalResponsibleCRMV: {
            $set: responsible.crmv,
          },
          modalResponsibleSignature: {
            $set: responsible.signature,
          },
        },
        displayResponsibleModal: {
          $set: !this.state.displayResponsibleModal,
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Load to modal a new region or a existing one
   *
   * @param region Object
   */
  handleLoadRegionToModal(region) {
    this.setState((state) =>
      update(state, {
        fields: {
          modalRegionId: {
            $set: region.id,
          },
          modalRegion: {
            $set: region.value,
          },
        },
        displayRegionModal: {
          $set: !this.state.displayRegionModal,
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Load to modal a new email or a existing one
   *
   * @param email Object
   */
  handleLoadEmailToModal(email) {
    this.setState((state) =>
      update(state, {
        fields: {
          modalEmailId: {
            $set: email.id,
          },
          modalEmail: {
            $set: email.value,
          },
        },
        displayEmailModal: { $set: !this.state.displayEmailModal },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Check if should disable all fields
   *
   * @return {Boolean}
   */
  shouldDisableAll() {
    return this.props.accessProfile == ACCESS_PROFILES.UNIT_USER;
  }

  /**
   * @author Victor Heringer
   *
   * Load to modal a new phone or a existing one
   *
   * @param phone Object
   */
  handleLoadPhoneToModal(phone) {
    this.setState((state) =>
      update(state, {
        fields: {
          modalPhoneId: {
            $set: phone.id,
          },
          modalPhone: {
            $set: phone.value,
          },
        },
        displayPhoneModal: { $set: !this.state.displayPhoneModal },
      })
    );
    this.setState({ displayPhoneModal: !this.state.displayPhoneModal });
  }

  /**
   * @author Victor Heringer
   *
   * Checks and unchecks the ref checkbox at table
   *
   * @param {String} ref Value that will be add to table
   * @param {String} stateName The of the state that will be update
   */
  toggleSelectAtTable(ref, stateName) {
    let selecteds = [...this.state[stateName]];
    let newState = {};

    if (selecteds.indexOf(ref) === -1) {
      selecteds.push(ref);
    } else {
      selecteds.splice(selecteds.indexOf(ref), 1);
    }

    newState[stateName] = selecteds;
    this.setState(newState);
  }

  /**
   * @author Victor Heringer
   *
   * Checks and unchecks the user checkbox at table
   *
   * @param {String} region Value that will be add to table
   */
  toggleSelectUser(ref) {
    this.toggleSelectAtTable(ref, "selectedUsers");
  }
  toggleSelectResponsible(ref) {
    this.toggleSelectAtTable(ref, "selectedResponsible");
  }

  /**
   * @author Victor Heringer
   *
   * Checks and unchecks the region checkbox at table
   *
   * @param {String} region Value that will be add to table
   */
  toggleSelectRegion(ref) {
    this.toggleSelectAtTable(ref, "selectedRegions");
  }

  /**
   * @author Victor Heringer
   *
   * Checks and unchecks the emails checkbox at table
   *
   * @param {String} region Value that will be add to table
   */
  toggleSelectEmail(ref) {
    this.toggleSelectAtTable(ref, "selectedEmails");
  }

  /**
   * @author Victor Heringer
   *
   * Checks and unchecks the phoes checkbox at table
   *
   * @param {String} region Value that will be add to table
   */
  toggleSelectPhone(ref) {
    this.toggleSelectAtTable(ref, "selectedPhones");
  }

  /**
   * @author Victor Heringer
   *
   * Adds a line to table with new user and closes modal
   *
   * @param {Array} user Value that will be add to table
   * @param {Function} onClose Callback to be execute when close modal
   */
  addUserToTable(user, onClose) {
    const users = this.state.fields.users;

    if (user.id > 0) {
      for (let i = 0; i < users.length; i++) {
        if (users[i].id == user.id) {
          users[i].login = user.login;
          if (user.password) {
            users[i].password = user.password;
          }
          users[i].name = user.name;
          users[i].email = user.email;
          users[i].phone = user.phone;
          users[i].cpf = user.cpf;
          users[i].isVet = user.isVet;
          users[i].crmv = user.crmv;
          users[i].active = user.active;
          users[i].signature = user.signature;
          users[i].accessProfile = user.accessProfile;
          this.setState(
            (state) =>
              update(state, {
                fields: {
                  users: { $set: users },
                },
                displayUserModal: { $set: !this.state.displayUserModal },
              }),
            () => onClose("reset")
          );
        }
      }
    } else {
      this.setState(
        (state) =>
          update(state, {
            fields: {
              users: {
                $push: [
                  {
                    id: user.id,
                    login: user.login,
                    password: user.password,
                    name: user.name,
                    email: user.email,
                    phone: user.phone,
                    cpf: user.cpf,
                    isVet: user.isVet,
                    crmv: user.crmv,
                    active: user.active,
                    signature: user.signature,
                    accessProfile: user.accessProfile,
                  },
                ],
              },
            },
            displayUserModal: { $set: !this.state.displayUserModal },
          }),
        () => onClose("reset")
      );
    }
  }

  addResponsibleToTable(user, onClose) {
    const users = this.state.fields.responsible;

    if (user.id > 0) {
      for (let i = 0; i < users.length; i++) {
        if (users[i].id == user.id) {
          users[i].login = user.login;
          if (user.password) {
            users[i].password = user.password;
          }
          users[i].name = user.name;
          users[i].email = user.email;
          users[i].phone = user.phone;
          users[i].cpf = user.cpf;
          users[i].isVet = user.isVet;
          users[i].crmv = user.crmv;
          users[i].active = user.active;
          users[i].signature = user.signature;
          users[i].accessProfile = user.accessProfile;
          this.setState(
            (state) =>
              update(state, {
                fields: {
                  responsible: { $set: users },
                },
                displayResponsibleModal: {
                  $set: !this.state.displayResponsibleModal,
                },
              }),
            () => onClose("reset")
          );
        }
      }
    } else {
      this.setState(
        (state) =>
          update(state, {
            fields: {
              responsible: {
                $push: [
                  {
                    id: user.id,
                    login: user.login,
                    password: user.password,
                    name: user.name,
                    email: user.email,
                    phone: user.phone,
                    cpf: user.cpf,
                    isVet: user.isVet,
                    crmv: user.crmv,
                    active: user.active,
                    signature: user.signature,
                    accessProfile: user.accessProfile,
                  },
                ],
              },
            },
            displayResponsibleModal: {
              $set: !this.state.displayResponsibleModal,
            },
          }),
        () => onClose("reset")
      );
    }
  }

  /**
   * @author Victor Heringer
   *
   * Adds a line to table with new region and closes modal
   *
   * @param {Array} region Value that will be add to table
   * @param {Function} onClose Callback to be execute when close modal
   */
  addRegionToTable(region, onClose) {
    const regions = this.state.fields.regions;
    if (region.id > 0) {
      for (let i = 0; i < regions.length; i++) {
        if (regions[i] && regions[i].id == region.id) {
          regions[i].name = region.name;
          this.setState(
            (state) =>
              update(state, {
                fields: {
                  regions: { $set: regions },
                },
                displayRegionModal: { $set: !this.state.displayRegionModal },
              }),
            () => onClose("reset")
          );
        }
      }
    } else {
      for (let i = 0; i < regions.length; i++) {
        if (regions[i] && regions[i].name == region.name) {
          return this.includedNotification();
        }
      }
      this.setState(
        (state) =>
          update(state, {
            fields: {
              regions: { $push: [{ id: region.id, name: region.name }] },
              modalRegion: { $set: "" },
            },
            displayRegionModal: { $set: !this.state.displayRegionModal },
          }),
        () => onClose("reset")
      );
    }
  }

  /**
   * @author Victor Heringer
   *
   * Adds a line to table with new email and closes modal
   *
   * @param {Array} email Value that will be add to table
   * @param {Function} onClose Callback to be execute when close modal
   */
  addEmailToTable(email, onClose) {
    const emails = this.state.fields.emails;

    if (email.id > 0) {
      for (let i = 0; i < emails.length; i++) {
        if (emails[i].id == email.id) {
          emails[i].name = email.name;
          this.setState(
            (state) =>
              update(state, {
                fields: {
                  emails: { $set: emails },
                },
                displayEmailModal: { $set: !this.state.displayEmailModal },
              }),
            () => onClose("reset")
          );
        }
      }
    } else {
      for (let i = 0; i < emails.length; i++) {
        if (emails[i].name == email.name) {
          return this.includedNotification();
        }
      }
      this.setState(
        (state) =>
          update(state, {
            fields: {
              emails: { $push: [{ id: email.id, name: email.name }] },
              modalEmail: { $set: "" },
            },
            displayEmailModal: { $set: !this.state.displayEmailModal },
          }),
        () => onClose("reset")
      );
    }
  }

  /**
   * @author Victor Heringer
   *
   * Adds a line to table with new phone and closes modal
   *
   * @param {Array} phone Value that will be add to table
   * @param {Function} onClose Callback to be execute when close modal
   */
  addPhoneToTable(phone, onClose) {
    const phones = this.state.fields.phones;

    if (phone.id > 0) {
      for (let i = 0; i < phones.length; i++) {
        if (phones[i].id == phone.id) {
          phones[i].value = phone.value;
          this.setState(
            (state) =>
              update(state, {
                fields: {
                  phones: { $set: phones },
                },
                displayPhoneModal: { $set: !this.state.displayPhoneModal },
              }),
            () => onClose("reset")
          );
        }
      }
    } else {
      for (let i = 0; i < phones.length; i++) {
        if (phones[i].value == phone.value) {
          return this.includedNotification();
        }
      }
      this.setState(
        (state) =>
          update(state, {
            fields: {
              phones: { $push: [{ id: phone.id, value: phone.value }] },
              modalPhone: { $set: "" },
            },
            displayPhoneModal: { $set: !this.state.displayPhoneModal },
          }),
        () => onClose("reset")
      );
    }
  }

  /**
   * @author Victor Heringer
   *
   * Remove selected users from table
   */
  handleRemoveUser() {
    let users = [...this.state.fields.users];
    let selecteds = [...this.state.selectedUsers];

    for (let i = 0; i < users.length; i++) {
      if (selecteds.indexOf(users[i].name) > -1) {
        if (users[i].id > 0) {
          this.props.deleteUser(users[i].id, this.removeNotification);
        }
        delete users[i];
      }
    }

    users = users.filter(() => true);

    this.setState((state) =>
      update(state, {
        selectedUsers: { $set: [] },
        fields: {
          users: { $set: users },
        },
      })
    );
  }

  handleRemoveResponsible() {
    let responsibles = [...this.state.fields.responsible];
    let selecteds = [...this.state.selectedResponsible];

    for (let i = 0; i < responsibles.length; i++) {
      if (selecteds.indexOf(responsibles[i].name) > -1) {
        if (responsibles[i].id > 0) {
          this.props.deleteResponsible(
            responsibles[i].id,
            this.removeNotification
          );
        }
        delete responsibles[i];
      }
    }

    responsibles = responsibles.filter(() => true);

    this.setState((state) =>
      update(state, {
        selectedResponsible: { $set: [] },
        fields: {
          responsible: { $set: responsibles },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Remove selected regions from table
   */
  handleRemoveRegion() {
    let regions = [...this.state.fields.regions];
    let selecteds = [...this.state.selectedRegions];

    const onError = (response) => {
      const config = {
        title: "Error",
        message: response.toString(),
        level: "error",
        position: "tr",
        autoDismiss: FIVE_SECONDS,
      };
      this.props.notificationSystem.add(config);
    };

    for (let i = 0; i < regions.length; i++) {
      if (regions[i] && selecteds.indexOf(regions[i].name) > -1) {
        if (regions[i].id > 0) {
          this.props.deleteRegion(
            regions[i].id,
            () => {
              this.removeNotification();
              regions.splice(i, 1);
            },
            onError
          );
        } else {
          regions.splice(i, 1);
        }
      }
    }

    regions = regions.filter(() => true);

    this.setState((state) =>
      update(state, {
        selectedRegions: { $set: [] },
        fields: {
          regions: { $set: regions },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Remove selected emails from table
   */
  handleRemoveEmail() {
    let emails = [...this.state.fields.emails];
    let selecteds = [...this.state.selectedEmails];

    for (let i = 0; i < emails.length; i++) {
      if (selecteds.indexOf(emails[i].name) > -1) {
        if (emails[i].id > 0) {
          this.props.deleteEmail(emails[i].id, this.removeNotification);
        }
        delete emails[i];
      }
    }

    emails = emails.filter(() => true);

    this.setState((state) =>
      update(state, {
        selectedEmails: { $set: [] },
        fields: {
          emails: { $set: emails },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Remove selected phones from table
   */
  handleRemovePhone() {
    let phones = [...this.state.fields.phones];
    let selecteds = [...this.state.selectedPhones];

    for (let i = 0; i < phones.length; i++) {
      if (selecteds.indexOf(phones[i].value) > -1) {
        if (phones[i].id > 0) {
          this.props.deletePhone(phones[i].id, this.removeNotification);
        }
        delete phones[i];
      }
    }

    phones = phones.filter(() => true);

    this.setState((state) =>
      update(state, {
        selectedPhones: { $set: [] },
        fields: {
          phones: { $set: phones },
        },
      })
    );
  }

  render() {
    const { unityResponse, obj } = this.props;

    if (unityResponse && unityResponse.fulfilled) {
      return <Redirect to="/unidades" />;
    }

    if (obj && obj.pending) {
      return <BounceLoader color="#00B4AD" load />;
    }

    const submit = {
      pending: unityResponse && unityResponse.pending,
      placeholder: this.isUpdateing() ? "Atualizando.." : "Salvando..",
      btnTitle: this.isUpdateing() ? "Atualizar" : "Salvar",
      panelTitle: this.isUpdateing() ? "Unidade" : "Nova Unidade",
    };

    DEFAULT_USER.accessProfile = this.definesNewUserAccessProfile(
      this.props.accessProfile
    );

    DEFAULT_RESPONSIBLE.accessProfile = this.definesNewResponsibleAccessProfile(
      this.props.accessProfile
    );

    const methods = {
      isUpdateing: this.isUpdateing,
      loadCities: this.loadCities,
      handleSubmit: this.handleSubmit,
      fieldChange: this.fieldChange,
      fieldChangeCNPJ: this.fieldChangeCNPJ,
      fieldChangeIsVet: this.fieldChangeIsVet,
      invalidNotification: this.invalidNotification,
      handleSignatureUpload: this.handleSignatureUpload,
      handleResponsibleSignatureUpload: this.handleResponsibleSignatureUpload,

      /** Users */
      handleLoadUserToModal: this.handleLoadUserToModal,
      toggleSelectUser: this.toggleSelectUser,
      addUserToTable: this.addUserToTable,
      handleRemoveUser: this.handleRemoveUser,

      /** Responsible */
      handleLoadResponsibleToModal: this.handleLoadResponsibleToModal,
      toggleSelectResponsible: this.toggleSelectResponsible,
      addResponsibleToTable: this.addResponsibleToTable,
      handleRemoveResponsible: this.handleRemoveResponsible,

      /** Regions */
      handleLoadRegionToModal: this.handleLoadRegionToModal,
      toggleSelectRegion: this.toggleSelectRegion,
      addRegionToTable: this.addRegionToTable,
      handleRemoveRegion: this.handleRemoveRegion,

      /** Emails */
      handleLoadEmailToModal: this.handleLoadEmailToModal,
      toggleSelectEmail: this.toggleSelectEmail,
      addEmailToTable: this.addEmailToTable,
      handleRemoveEmail: this.handleRemoveEmail,

      /** Phones */
      handleLoadPhoneToModal: this.handleLoadPhoneToModal,
      toggleSelectPhone: this.toggleSelectPhone,
      addPhoneToTable: this.addPhoneToTable,
      handleRemovePhone: this.handleRemovePhone,
    };

    const data = {
      states: this.props.states,
      cities: this.props.cities,
      companyCNPJ: this.props.companyCNPJ,
      allDisabled: false,
      submit: submit,
      testFiles: this.testFiles,
      shouldDisableAll: this.shouldDisableAll(),

      /** User */
      defaultUser: DEFAULT_USER,
      selectedUsers: this.state.selectedUsers,
      displayUserModal: this.state.displayUserModal,

      /** Responsible */
      defaultResponsible: DEFAULT_RESPONSIBLE,
      selectedResponsible: this.state.selectedResponsible,
      displayResponsibleModal: this.state.displayResponsibleModal,

      /** Email */
      defaultEmail: DEFAULT_EMAIL,
      selectedEmails: this.state.selectedEmails,
      displayEmailModal: this.state.displayEmailModal,

      /** Region */
      defaultRegion: DEFAULT_REGION,
      selectedRegions: this.state.selectedRegions,
      displayRegionModal: this.state.displayRegionModal,

      /** Phone */
      defaultPhone: DEFAULT_PHONE,
      selectedPhones: this.state.selectedPhones,
      displayPhoneModal: this.state.displayPhoneModal,
    };

    return (
      //{console.log(JSON.stringify(this.state.fields))} Esta linha estava uma linha abaixo antes de ser comentada!
      <React.Fragment>
        <UnityFormView
          data={data}
          methods={methods}
          fields={this.state.fields}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    accessProfile: state.userReducer.access_profile,
  };
};

export default FunctionUtil.compose(
  connectWithEndpoint,
  connect(mapStateToProps)
)(UnityForm);
