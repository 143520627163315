import React from "react";
import PropTypes from "prop-types";

/**
 * Assets
 */
import "./index.css";

/**
 * @author Victor Heringer
 *
 * Return the class corresponding to button type
 *
 * @param  {string} type Type could be [default] or [highlight]
 */
const SWITCH_TYPE = (type) => {
  switch (type) {
    case "default":
      return "btn btn-material btn-material-default";
    case "highlight":
      return "btn btn-material btn-material-highlight";
    case "danger":
      return "btn btn-material btn-material-danger";
    case "warning":
      return "btn btn-material btn-material-warning";
  }
};

const BLOCK = (block) => {
  return block ? " btn-block " : "";
};

const RESPONSIVE = (responsive) => {
  return responsive ? " responsive-btn " : "";
};

let callbackKeyPressFunction = "";
let keyPressCode = "";

const setKeyPress = (callback, code) => {
  callbackKeyPressFunction = callback;
  keyPressCode = code;
};

const handleKeyPress = (event) => {
  if (event.keyCode === keyPressCode) {
    callbackKeyPressFunction();
  }
};

/**
 * @author Victor Heringer
 *
 * Pure links for sidebar
 */
const ButtonDefaultMaterial = ({
  id,
  title,
  type,
  style,
  onClick,
  keyCode,
  onKeyPress,
  className,
  block,
  icon,
  disabled,
  responsive,
}) => {
  return (
    <>
      {keyCode && setKeyPress(onKeyPress, keyCode)}
      <button
        id={id}
        style={style}
        onKeyDown={handleKeyPress}
        className={
          SWITCH_TYPE(type) +
          " material-btn " +
          className +
          BLOCK(block) +
          RESPONSIVE(responsive)
        }
        onClick={onClick}
        disabled={disabled}
      >
        <span className={responsive ? "visible-xs" : ""}>{icon}</span>
        <span className={responsive ? "hidden-xs" : ""}>{title}</span>
      </button>
    </>
  );
};

ButtonDefaultMaterial.propTypes = {
  type: PropTypes.oneOf(["default", "highlight", "danger"]),
  block: PropTypes.bool,
};

export default ButtonDefaultMaterial;
