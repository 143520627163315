import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { EXAM_TYPES } from "../../consts";
import { ROUTES, useFetch } from "../use-fetch";
import { EXAM_ID_BY_NAME } from "../../consts/exams";

const useReport = ({ requestExamId, examsIds, reportId }) => {
  const fetchedReports = useFetch();

  useEffect(() => {
    reportId > 0 &&
      fetchedReports.fetch(
        ROUTES.getReport(reportId),
        { method: "GET" },
        () => {}
      );
  }, []);

  return {};
};

export default useReport;
