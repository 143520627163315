import { isEmptyField } from "./util";

import { StringUtil } from "../../../useful";
import { Decimal } from "decimal.js";

//     this.enableCounterSound();

// export function incrementField(field) {
//     const value = new Decimal(
//       StringUtil.ifEmptyStrToZero(this.state.fields[field])
//     );

//     const newValue = value.plus(1).toPrecision();

//     if (this.calcTotal() < COUNTER_LIMIT) {
//       this.fieldChange(field, newValue);
//     } else if (field === "metarrubrycites") {
//       this.fieldChange(field, newValue);
//     }
//   }

const isEmpty = (value) => {
  return value === null || value === "" || value === undefined || !value;
};

function isGreaterThan(value, valueMax) {
  if (isEmpty(value)) return "";
  const val = new Decimal(value);
  const valMax = new Decimal(valueMax);
  return val.greaterThan(valMax);
}

function isLessThan(value, valueMin) {
  const val = new Decimal(value);
  const valMin = new Decimal(valueMin);
  return val.lessThan(valMin);
}

export function calculateFinalGlobalLeukogram({
  globalLeukometry,
  metarrubrycites,
}) {
  if (StringUtil.isStrEmpty(globalLeukometry)) {
    return "";
  }

  if (StringUtil.isStrEmpty(metarrubrycites)) {
    const globalLeuk = new Decimal(globalLeukometry);
    return globalLeuk.toPrecision();
  }

  return Math.round((globalLeukometry * 100) / (100 + +metarrubrycites));
  // return Math.round(globalLeukometry / (1 + metarrubrycites / 100));
}

function calcAbsolute(value, { globalLeukometry, metarrubrycites }) {
  const globalValue = calculateFinalGlobalLeukogram({
    globalLeukometry,
    metarrubrycites,
  });

  if (isEmptyField(value) || isEmptyField(globalValue)) {
    return "";
  }
  const global = new Decimal(globalValue);
  const relative = new Decimal(value);

  return global.times(relative).dividedBy(100).round().toPrecision();
}

function isNeutropLeukocytosis(
  {
    globalLeukometry,
    metarrubrycites,
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    relativeSegs,
  },
  {
    relativeMyelocytesMax,
    absoluteMyelocytesMax,
    relativeMetamyelocytesMax,
    absoluteMetamyelocytesMax,
    relativeBandsMax,
    absoluteBandsMax,
    relativeSegsMax,
    absoluteSegsMax,
  }
) {
  if (
    StringUtil.areAnyStrsEmpty(
      relativeMyelocytes,
      relativeMetamyelocytes,
      relativeBands,
      relativeSegs
    )
  ) {
    return false;
  }

  const absoluteMyelocytes = calcAbsolute(relativeMyelocytes, {
    globalLeukometry,
    metarrubrycites,
  });
  const absoluteMetamyelocytes = calcAbsolute(relativeMetamyelocytes, {
    globalLeukometry,
    metarrubrycites,
  });
  const absoluteBands = calcAbsolute(relativeBands, {
    globalLeukometry,
    metarrubrycites,
  });
  const absoluteSegs = calcAbsolute(relativeSegs, {
    globalLeukometry,
    metarrubrycites,
  });

  return (
    isGreaterThan(relativeMyelocytes, relativeMyelocytesMax) ||
    isGreaterThan(absoluteMyelocytes, absoluteMyelocytesMax) ||
    isGreaterThan(relativeMetamyelocytes, relativeMetamyelocytesMax) ||
    isGreaterThan(absoluteMetamyelocytes, absoluteMetamyelocytesMax) ||
    isGreaterThan(relativeBands, relativeBandsMax) ||
    isGreaterThan(absoluteBands, absoluteBandsMax) ||
    isGreaterThan(relativeSegs, relativeSegsMax) ||
    isGreaterThan(absoluteSegs, absoluteSegsMax)
  );
}

function globalLeukDiagnosis(leukogramValues, refs) {
  const globalLeukometry = calculateFinalGlobalLeukogram(leukogramValues);

  if (
    //this.props.refsPromise.pending ||
    StringUtil.isStrEmpty(globalLeukometry)
  ) {
    return "";
  }

  const globalLeuk = new Decimal(globalLeukometry);

  const globalLeukMin = new Decimal(refs.globalLeukometryMin);

  const globalLeukMax = new Decimal(refs.globalLeukometryMax);

  if (globalLeuk.lessThan(globalLeukMin)) {
    return "leucopenia";
  }

  if (globalLeuk.greaterThan(globalLeukMax)) {
    if (isNeutropLeukocytosis(leukogramValues, refs)) {
      return "leucocitose neutrofílica";
    }

    return "leucocitose";
  }

  return "";
}

function diagnosis(
  relative,
  relativeMin,
  relativeMax,
  absoluteMin,
  absoluteMax,
  lowName,
  highName,
  composed,
  { globalLeukometry, metarrubrycites }
) {
  const absolute = calcAbsolute(relative, {
    globalLeukometry,
    metarrubrycites,
  });

  if (StringUtil.isStrEmpty(relative) || StringUtil.isStrEmpty(absolute)) {
    return "";
  }

  return displayResults(
    relative,
    absolute,
    relativeMin,
    absoluteMin,
    relativeMax,
    absoluteMax,
    lowName,
    highName,
    composed
  );
}

function addConnectorStr(text, connector) {
  return text && StringUtil.isStrFilled(text) ? connector : "";
}

function displayResults(
  relative,
  absolute,
  relativeMin,
  absoluteMin,
  relativeMax,
  absoluteMax,
  lowName,
  highName,
  composed
) {
  if (
    relative &&
    relativeMin &&
    absolute &&
    absoluteMin &&
    lowName &&
    isLessThan(relative, relativeMin) &&
    isLessThan(absolute, absoluteMin)
  ) {
    return addConnectorStr(composed, ", ") + lowName + " relativa e absoluta";
  }

  if (
    relative &&
    relativeMax &&
    absolute &&
    absoluteMax &&
    highName &&
    relative > relativeMax &&
    absolute > absoluteMax
  ) {
    return addConnectorStr(composed, ", ") + highName + " relativa e absoluta";
  }

  let text = "";

  if (relative && relativeMin && lowName && relative < relativeMin) {
    text += addConnectorStr(composed, ", ") + lowName + " relativa";
  } else if (absolute && absoluteMin && lowName && absolute < absoluteMin) {
    text += addConnectorStr(composed, ", ") + lowName + " absoluta";
  }

  if (relative && relativeMax && highName && relative > relativeMax) {
    text +=
      (StringUtil.isStrFilled(text) ? " e " : addConnectorStr(composed, ", ")) +
      highName +
      " relativa";
  } else if (absolute && absoluteMax && highName && absolute > absoluteMax) {
    text +=
      (StringUtil.isStrFilled(text) ? " e " : addConnectorStr(composed, ", ")) +
      highName +
      " absoluta";
  }

  return text;
}

function DNNE(
  composed,
  {
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    globalLeukometry,
    metarrubrycites,
  },
  {
    relativeMyelocytesMax,
    absoluteMyelocytesMax,
    relativeMetamyelocytesMax,
    absoluteMetamyelocytesMax,
    relativeBandsMax,
    absoluteBandsMax,
  }
) {
  const absoluteMyelocytes = calcAbsolute(relativeMyelocytes, {
    globalLeukometry,
    metarrubrycites,
  });
  const absoluteMetamyelocytes = calcAbsolute(relativeMetamyelocytes, {
    globalLeukometry,
    metarrubrycites,
  });
  const absoluteBands = calcAbsolute(relativeBands, {
    globalLeukometry,
    metarrubrycites,
  });

  if (StringUtil.isStrFilled(relativeMyelocytes)) {
    if (
      isGreaterThan(relativeMyelocytes, relativeMyelocytesMax) ||
      isGreaterThan(absoluteMyelocytes, absoluteMyelocytesMax)
    ) {
      return addConnectorStr(composed, " com") + " DNNE acentuado";
    }
  }

  if (StringUtil.isStrFilled(relativeMetamyelocytes)) {
    if (
      isGreaterThan(relativeMetamyelocytes, relativeMetamyelocytesMax) ||
      isGreaterThan(absoluteMetamyelocytes, absoluteMetamyelocytesMax)
    ) {
      return addConnectorStr(composed, " com") + " DNNE moderado";
    }
  }

  if (StringUtil.isStrFilled(relativeBands)) {
    if (
      isGreaterThan(relativeBands, relativeBandsMax) ||
      isGreaterThan(absoluteBands, absoluteBandsMax)
    ) {
      return addConnectorStr(composed, " com") + " DNNE leve";
    }
  }

  return "";
}

export function leukogramDiagnosis(leukogramValues, refs) {
  if (leukogramValues.isIntenseLeucopeny) {
    return "Contagem de leucometria específica impossibilitada devido à leucopenia intensa";
  }
  // if (
  //   this.props.refsPromise.pending ||
  //   StringUtil.isStrEmpty(globalLeukometry)
  // ) {
  //   return "";
  // }

  const {
    relativeBasophilsMax,
    absoluteBasophilsMax,
    relativeEosinophilsMax,
    absoluteEosinophilsMax,
    relativeEosinophilsMin,
    absoluteEosinophilsMin,
    relativeSegsMax,
    absoluteSegsMax,
    relativeSegsMin,
    absoluteSegsMin,
    relativeLymphocytesMax,
    absoluteLymphocytesMax,
    relativeLymphocytesMin,
    absoluteLymphocytesMin,
    relativeMonocytesMax,
    absoluteMonocytesMax,
    relativeMonocytesMin,
    absoluteMonocytesMin,
    relativeHeterophilsMax,
    absoluteHeterophilsMax,
    relativeHeterophilsMin,
    absoluteHeterophilsMin,
  } = refs;

  const {
    relativeHeterophils,
    relativeMonocytes,
    relativeLymphocytes,
    relativeSegs,
    relativeEosinophils,
    relativeBasophils,
  } = leukogramValues;

  let composed = "";
  composed += globalLeukDiagnosis(leukogramValues, refs);

  composed += DNNE(composed, leukogramValues, refs);

  const relativeBasophMax = ifRareThenZero(relativeBasophilsMax);

  const absoluteBasophMax = ifRareThenZero(absoluteBasophilsMax);

  const basophils = diagnosis(
    relativeBasophils,
    null,
    relativeBasophMax,
    null,
    absoluteBasophMax,
    null,
    "basofilia",
    composed,
    leukogramValues
  );
  composed += basophils;

  const eosinphils = diagnosis(
    relativeEosinophils,
    relativeEosinophilsMin,
    relativeEosinophilsMax,
    absoluteEosinophilsMin,
    absoluteEosinophilsMax,
    "eosinopenia",
    "eosinofilia",
    composed,
    leukogramValues
  );

  composed += eosinphils;

  const segs = diagnosis(
    relativeSegs,
    relativeSegsMin,
    relativeSegsMax,
    absoluteSegsMin,
    absoluteSegsMax,
    "neutropenia",
    "neutrofilia",
    composed,
    leukogramValues
  );

  composed += segs;

  const lymphocytes = diagnosis(
    relativeLymphocytes,
    relativeLymphocytesMin,
    relativeLymphocytesMax,
    absoluteLymphocytesMin,
    absoluteLymphocytesMax,
    "linfocitopenia",
    "linfocitose",
    composed,
    leukogramValues
  );

  composed += lymphocytes;

  const monocytes = diagnosis(
    relativeMonocytes,
    relativeMonocytesMin,
    relativeMonocytesMax,
    absoluteMonocytesMin,
    absoluteMonocytesMax,
    "monocitopenia",
    "monocitose",
    composed,
    leukogramValues
  );

  composed += monocytes;

  const heterophils = diagnosis(
    relativeHeterophils,
    relativeHeterophilsMin,
    relativeHeterophilsMax,
    absoluteHeterophilsMin,
    absoluteHeterophilsMax,
    "heteropenia",
    "heterofilia",
    composed,
    leukogramValues
  );

  composed += heterophils;

  return StringUtil.capitalize(composed).trim();
}

function ifRareThenZero(value) {
  return value === "Rare" ? 0 : value;
}

export { calcAbsolute };
