import React from "react";

class FocusManagerTarget extends React.Component {
  render() {
    return (
      <div
        onKeyDown={event => {
          this.props.handler && this.props.handler(event.target.name, event);
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default FocusManagerTarget;
