import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { EXAM_ID_BY_NAME } from "../../consts/exams";

// ${(props) => props.display && "display:" + props.display};
const StyledSpan = styled.span`
  text-transform: uppercase;
  font-family: QuickSand;
  font-weight: bold;
  color: #757575;
  margin-left: 5px;
  margin-right: 5px;
  bottom: 6px;
  position: relative;
  left: -4px;
`;

const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;  
`;

const NEGATIVE_ID = 99;
const NEGATIVE_NAME = "Negativo";
export const parasitologialFreshFecesCheckboxes = [
  {
    title:
      "Não foram encontrados ovos e/ou oocistos de parasitos na amostra analisada.",
    name: NEGATIVE_NAME,
    id: NEGATIVE_ID,
  },
  { name: "Enterobuis", title: "ovos de Enterobuis sp", prefixId: 1, id: 1 },
  { name: "Giardia", title: "cistos de Giardia sp", prefixId: 1, id: 2 },
  { name: "Trichomonas", title: "Trichomonas sp", prefixId: 1, id: 3 },
  { name: "Ancylostoma",title: "ovos de Ancylostoma sp", prefixId: 1, id: 4 },
  { name: "Isosporasp", title: "oocistos de Isospora sp", prefixId: 1, id: 5 },
  { name: "Dyphylidium", title: "ovos de Dipylidium sp", prefixId: 1, id: 6 },
  { name: "Ophidascaris", title: "ovos de Ophidascaris sp", prefixId: 1, id: 7 },
  { name: "Toxocara", title: "ovos de Toxocara sp", prefixId: 1, id: 8 },
  { name: "Trichuris", title: "ovos de Trichuris sp", prefixId: 1, id: 9 },
  { name: "Trichostrongylus", title: "ovos de Trichostrongylus sp", prefixId: 1, id: 10 },
  { name: "Oxyuris", title: "ovos de Oxyuris sp", prefixId: 1, id: 11 },
  { name: "Eimeria", title: "oocistos de Eimeria sp", prefixId: 1, id: 12 },
  { name: "Ascaridia", title: "ovos de Ascaridia sp", prefixId: 1, id: 13 },
  { name: "Stronglyloides", title: "ovos de Strongyloides sp", prefixId: 1, id: 14 },
  { name: "Capillaria", title: "ovos de Capillaria sp", prefixId: 1, id: 15 },
];
const parasitologialFreshFecesPrefixes = [{ name: "Presença de", id: 1 }];


export const hemocitozoaResearchCheckboxes = [
  {
    title:
      "No esfregaço sanguíneo confeccionado não foram encontrados hemocitozoários.",
    name: NEGATIVE_NAME,
    id: NEGATIVE_ID,
  },
  { title: "Babesia sp.", prefixId: 2, id: 1, name: "Babesia" },
  {
    title:
      "Rickettsiaceae (Ehrlichia sp. ou Anaplasma sp.)",
    prefixId: 1,
    id: 2,
    name: "Rickettsiaceae",
  },
  {
    title: "Mycoplasma sp. (Haemobartonella sp.)",
    prefixId: 1,
    id: 3,
    name: "Mycoplasma",
  },
  { title: "Hepatozoon sp.", prefixId: 2, id: 4, name: "Hepatozoon" },
];

export const buffyCoatResearchCheckboxes = [
  {
    title:
      "No esfregaço sanguíneo confeccionado em capa leucocitária não foram encontrados hemocitozoários.",
    name: NEGATIVE_NAME,
    id: NEGATIVE_ID,
  },
  { title: "Babesia sp.", prefixId: 2, id: 1, name: "Babesia" },
  {
    title:
      "Rickettsiaceae (Ehrlichia sp. ou Anaplasma sp.)",
    prefixId: 1,
    id: 2,
    name: "Rickettsiaceae",
  },
  {
    title: "Mycoplasma sp. (Haemobartonella sp.)",
    prefixId: 1,
    id: 3,
    name: "Mycoplasma",
  },
  { title: "Hepatozoon sp.", prefixId: 2, id: 4, name: "Hepatozoon" },
]

const hemocitozoaResearchCheckPrefixes = [
  { name: "Presença de estruturas sugestivas", id: 1 },
  { name: "Positivo para", id: 2 },
];

const buffyCoatResearchCheckPrefixes = [
  { name: "Presença de estruturas sugestivas", id: 1 },
  { name: "Positivo para", id: 2 },
];

const hemocitozoaResearchCheckSufix = " ao exame microscópico no esfregaço sanguíneo.";

const buffyCoatResearchCheckSufix = " ao exame microscópico no esfregaço sanguíneo confeccionado em capa leucocitária.";

export const lentzCorpuscleResearchCheckboxes = [
  {
    title:"No esfregaço sanguíneo confeccionado não foram encontrados corpúsculo de Sinegaglia Lentz.",
    name: NEGATIVE_NAME,
    id: NEGATIVE_ID
  },
  { name:"Corpúsculo de Sinegaglia Lentz.", title: "Inclusões de corpúsculo de Sinegaglia Lentz", id:1, prefixId: 1 }
];
const lentzCorpuscleResearchPrefixes = [{ name: "Presença de", id: 1 }];

export const microfilariaeResearchCheckboxes = [
  {
    title:"Na amostra enviada não foram encontradas Microfilárias.",
    name: NEGATIVE_NAME,
    id: NEGATIVE_ID
  },
  { name:"Dirofilaria immitis", title: "microfilárias da espécie Dirofilaria immitis", id:1, prefixId: 1 },
  { name:"Dipetalonema", title: "microfilárias do gênero Dipetalonema", id:2, prefixId: 1 }
];
const microfilariaeResearchPrefixes = [{ name: "Presença de", id: 1 }];


export const ectoparasitesResearchCheckboxes = [
  {
    name:NEGATIVE_NAME,
    title:
      "Na amostra enviada não foram encontrados ectoparasitas ao exame de microscopia óptica (aumento de 100x).",
      id: NEGATIVE_ID
  },
  { name: "Demodex", title: "Demodex sp.", prefixId: 1, id:1 },
  { name: "Sarcoptes scabiei", title: "Sarcoptes scabiei", prefixId: 1, id:2 },
  { name: "Otodectes cynotis", title: "Otodectes cynotis", prefixId: 1, id:3 },
  { name: "Lynxacarus radovskyi", title: "Lynxacarus radovskyi", prefixId: 1, id:4 },
  { name: "Notoedres cati", title: "Notoedres cati", prefixId: 1, id:5 },
];
const ectoparasitesResearchPrefixes = [{ name: "Positivo para", id: 1 }];
const ectoparasitesResearchSufix = " ao exame de microscopia óptica(aumento de 100x).";


export const directMycologicalCheckboxes = [
  {
    title:"Na amostra analisada, não foram encontrados hifas nem esporos condizentes com crescimento fúngico.",
    name: NEGATIVE_NAME,
    id: NEGATIVE_ID
  },
  { name:"Ectotrix", title: "estruturas de ectotrix", id:1, prefixId: 1 },
  { name:"Hifas fúngicas", title: "hifas fúngicas", id:2, prefixId: 1 },
  { name:"Endotrix", title: "estruturas de endotrix", id:3, prefixId: 1 },
  { name:"Conídios", title: "estruturas de conídios", id:4, prefixId: 1 },
];
const directMycologicalPrefixes = [{ name: "Presença de", id: 1 }];


export const malasseziaResearchCheckboxes = [
  {
    title:"Na amostra enviada não foram encontradas presenças de leveduras de Malassezia sp.",
    name: NEGATIVE_NAME,
    id: NEGATIVE_ID
  },
  { name:"Malassezia", title: "leveduras de Malassezia sp", id:1, prefixId: 1 },
  
];
const malasseziaResearchPrefixes = [{ name: "Presença de", id: 1 }];





const getCheckedResult = (examId, itens) => {
  switch (examId) {
    case EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH:
      return formatCheckboxResult(
        hemocitozoaResearchCheckPrefixes,
        itens,
        hemocitozoaResearchCheckboxes,
        hemocitozoaResearchCheckSufix
      );
    case EXAM_ID_BY_NAME.HEMOGRAM_WITH_BUFFY_COAT_RESEARCH:
      return formatCheckboxResult(
        buffyCoatResearchCheckPrefixes,
        itens,
        buffyCoatResearchCheckboxes,
        buffyCoatResearchCheckSufix
      );
    case EXAM_ID_BY_NAME.PARASITOLOGICAL_FRESH_FECES:
      return formatCheckboxResult(
        parasitologialFreshFecesPrefixes,
        itens,
        parasitologialFreshFecesCheckboxes
      );

    case EXAM_ID_BY_NAME.LENTZ_CORPUSCLE_RESEARCH:
      return formatCheckboxResult(
        lentzCorpuscleResearchPrefixes,
        itens,
        lentzCorpuscleResearchCheckboxes
      );
    case EXAM_ID_BY_NAME.MICROFILARIAE_RESEARCH:
      return formatCheckboxResult(
        microfilariaeResearchPrefixes,
        itens,
        microfilariaeResearchCheckboxes
      );
    case EXAM_ID_BY_NAME.ECTOPARASITES_RESEARCH:
      return formatCheckboxResult(
        ectoparasitesResearchPrefixes,
        itens,
        ectoparasitesResearchCheckboxes,
        ectoparasitesResearchSufix
      );
      case EXAM_ID_BY_NAME.DIRECT_MYCOLOGICAL_RESEARCH:
        return formatCheckboxResult(
          directMycologicalPrefixes,
          itens,
          directMycologicalCheckboxes
        );  
      case EXAM_ID_BY_NAME.MALASSEZIA_RESEARCH:
        return formatCheckboxResult(
          malasseziaResearchPrefixes,
          itens,
          malasseziaResearchCheckboxes
        );    
    default:
      break;
  }
};

const formatCheckboxResult = (prefixes, itens, checkboxesObject, sufix = ".") => {
  const checkboxes = Object.entries(itens).map((key, index) => {
    const id = key[1].id;
    const checkbox = checkboxesObject
      .filter((i) => {
        return i.id == id;
      })
      .shift();
    return checkbox;
  });

  const negativeValue = checkboxes
    .filter((checkbox) => checkbox && checkbox.id == NEGATIVE_ID)
    .shift();
  if (negativeValue) {
    return negativeValue.title;
  }

  let result = prefixes.reduce((acc, prefix) => {
    let phrase = prefix.name;
    checkboxes.map((checkbox, index) => {
      if (checkbox && checkbox.prefixId == prefix.id) {
          phrase += phrase  === prefix.name ? " " + checkbox.title : ", " + checkbox.title;
      }
    });
    if (phrase != prefix.name) {
      acc += acc === "" ? phrase : ". " + phrase;
    }
    return acc;
  }, "");

  result += sufix;
  return result;
};

const useCheckbox = (examId) => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedResult, setcheckedResult] = useState("");

  const handleChange = (e) => {
    if (e.target.id == NEGATIVE_ID) {
      setCheckedItems({
        [e.target.name]: e.target.checked ? { id: e.target.id } : false,
      });
    } else {
      checkedItems[NEGATIVE_NAME] = false;
      setCheckedItems({
        ...checkedItems,
        [e.target.name]: e.target.checked ? { id: e.target.id } : false,
      });
    }
  };

  useEffect(() => {
    Object.keys(checkedItems).length !== 0 &&
      setcheckedResult(getCheckedResult(examId, checkedItems));
  }, [checkedItems]);

  return {
    handleChange,
    checkedItems,
    checkedResult,
    handleCheckedResultChange: setcheckedResult,
  };
};

export const withCheckbox = (Component, examId) => {
  return (props) => {
    const {
      handleChange,
      checkedItems,
      checkedResult,
      handleCheckedResultChange,
    } = useCheckbox(examId);
    return (
      <Component
        multiBoxChange={handleChange}
        multiCheckboxState={checkedItems}
        checkedResult={checkedResult}
        handleCheckedResultChange={handleCheckedResultChange}
        {...props}
      />
    );
  };
};

export const withCheckbox2 = (Component, examId) => {
  return (props) => {
    const {
      handleChange,
      checkedItems,
      checkedResult,
      handleCheckedResultChange,
    } = useCheckbox(examId);
    return (
      <Component
        multiBoxChange2={handleChange}
        multiCheckboxState2={checkedItems}
        checkedResult2={checkedResult}
        handleCheckedResultChange2={handleCheckedResultChange}
        {...props}
      />
    );
  };
};

const CheckboxItem = ({ name, checked = false, onChange, id, title }) => {
  return (
    <>
      <StyledCheckbox
        type={"checkbox"}
        name={name}
        checked={checked}
        onChange={onChange}
        id={id}
        title={title}
      />
      <StyledSpan>
        {name}
      </StyledSpan>
    </>
  );
};

export const MultiCheckbox = ({ itens, handleChange, state }) => {
  return (
    <>
      {itens.map((item, index) => (
        <>
          {index == 0 ? (
            <>
            <div>
              <CheckboxItem
                name={item.name}
                checked={state[item.name]}
                onChange={handleChange}
                id={item.id}
              />
            </div>
            <hr style={{borderTop: "1px solid #d9d9d9"}}/>
            </>
          ) : (
            <CheckboxItem
              name={item.name}
              checked={state[item.name]}
              onChange={handleChange}
              id={item.id}
            />
          )}
        </>
      ))}
    </>
  );
};
