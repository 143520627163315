import React from 'react';
import PropTypes from 'prop-types';

/**
 * External
 */
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';

/**
 * Internal
 */
import InputValidationMsg from './../InputValidationMsg';
import styles from './index.module.css';

const propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  shouldDisable: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  tabIndex: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number
   ] ),
  value: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number
   ] ),
  validationType: PropTypes.oneOf( [
    'danger',
    'warning',
    'info'
    ] ),
  hasValidationRecoil: PropTypes.bool,
  labelInline: PropTypes.bool,
  inputGroup: PropTypes.bool,
  inputGroupAfter: PropTypes.string,
  titleAfter: PropTypes.string,
  hasValidation: PropTypes.bool,
  validationReason: PropTypes.string,
  block: PropTypes.bool,
  fit: PropTypes.bool,
  className: PropTypes.string,
  inputGroupClassName: PropTypes.string
};

const defaultProps = {
  title: '',
  shouldDisable: false,
  validationReason: '',
  hasValidation: false,
  validationType: 'danger',
  hasValidationRecoil: true
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for input text with material design styling
 */
const InputTextMaterial = ( {
  fit,
  type,
  name,
  title,
  block,
  value,
  handleBlur,
  handleChange,
  shouldDisable,
  className,
  titleAfter, 
  inputGroup,
  hasValidation,
  validationType,
  inputGroupAfter,
  hasValidationRecoil,
  validationReason,
  inputGroupClassName,
  mask,
  autofocus,
  tabIndex
} ) => {
  /**
   * Input group class
   * @type {Object}
   */
  const inputGroupClass = classNames(
    { inputGroupClassName },
    { 'input-group': inputGroup },
    { 'form-group': !inputGroup },
    { 'd-block': block },
    { 'fit': fit }
  );

  /**
   * Input class
   * @type {Object}
   */
  const inputClass = classNames(
    'form-control',
    styles.inputMaterial,
    className
  );

  let onChange = undefined;

  if( handleChange ) {
    onChange = ( event ) => {
      if( handleChange ) {
        handleChange( name, event.target.value );
      }
    }
  }

  const input = <input
    autoFocus={ autofocus }
    type={ type }
    name={ name }
    value={ value }
    onBlur={ handleBlur }
    disabled={ shouldDisable }
    onChange={ onChange }
    className={ inputClass } 
    tabIndex={ tabIndex } />

  const maskedInput = <MaskedInput
    type={ type }
    name={ name }
    mask={ mask }
    autoFocus={ autofocus }
    value={ value }
    onBlur={ handleBlur }
    disabled={ shouldDisable }
    onChange={ onChange }
    className={ inputClass } 
    tabIndex={ tabIndex } />

  return (
    <React.Fragment>
      <div className={ inputGroupClass }>
        { title && <label>{ title }</label> }
        { mask ? maskedInput : input }
        { titleAfter &&
          <label className={ styles.labelAfeterMaterial } >
            { titleAfter }
          </label>
        }
        { inputGroup && inputGroupAfter &&
          <span className="input-group-addon">
            { inputGroupAfter }
          </span>
        }
      </div>
      <InputValidationMsg
        type={ validationType }
        visible={ hasValidation }
        message={ validationReason }
        top={ hasValidationRecoil ? '-18px' : '' } />
    </React.Fragment>
  );
}

InputTextMaterial.propTypes = propTypes;
InputTextMaterial.defaultProps = defaultProps;

export default InputTextMaterial;