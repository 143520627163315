import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import HemogramPanel from "./../HemogramPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import ParvoCoronaPanel from "./../ParvoCoronaPanel";
import ParasitologicalFreshFecesPanel from "./../ParasitologicalFreshFecesPanel";
import FecesCoprocytologicalPanel from "./../FecesCoprocytologicalPanel";
import UroculturePanel from "./../UroculturePanel";
import { Col, Row } from "./../../components/grid";
import DefaultMicrobiologyContent from "../DefaultMicrobiologyContent";

const ProfileGastroenteritisSilverReportFormView = ({
  title,
  data: {
    refs,
    total,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    fecesColorOptionsPromise,
    fecesSmellOptionsPromise,
    fecesAspectOptionsPromise,
    fecesConsistencyOptionsPromise,
    reportHeaderData,
    urocultureNegativeMessage,
    antibioticOptions,
    checkboxes,
    parasitologicalFreshFecesResult,
    checkdState,
    reportData,
  },
  methods: {
    $field,
    fieldChange,
    onSubmit,
    calcMCHC,
    calcMCV,
    erythrogramDiagnosis,
    plateletsDiagnosis,
    calcAbsolute,
    resetLeukogram,
    onClickCounter,
    leukogramDiagnosis,
    toggleIntenseLeucopeny,
    finalGlobalLeukogram,
    handleKeyPress,
    handleFinishedPlaying,
    closeCounter,
    handleChange,
    handleBlur,
    toggleIsNegative,
    prepareAntibioticTagsSuggestionFilter,
    getQuantitativeResult,
    cancelReport,
    stopReport,
    handleSendReportEmail,
    handleFocus,
    setRef,
    multiBoxChange,
    handleCheckedResultChange,
  },
}) => (
  <ReportPanelMaterial
    title={title}
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    cancelReport={cancelReport}
    stopReport={stopReport}
    handleSendReportEmail={handleSendReportEmail}
    handleFocus={handleFocus}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <HemogramPanel
          fields={fields}
          refs={refs}
          disableAll={disableAll}
          $validation={$validation}
          total={total}
          $field={$field}
          fieldChange={fieldChange}
          calcMCV={calcMCV}
          calcMCHC={calcMCHC}
          calcAbsolute={calcAbsolute}
          resetLeukogram={resetLeukogram}
          onClickCounter={onClickCounter}
          toggleIntenseLeucopeny={toggleIntenseLeucopeny}
          finalGlobalLeukogram={finalGlobalLeukogram}
          erythrogramDiagnosis={erythrogramDiagnosis}
          leukogramDiagnosis={leukogramDiagnosis}
          plateletsDiagnosis={plateletsDiagnosis}
          handleKeyPress={handleKeyPress}
          handleFinishedPlaying={handleFinishedPlaying}
          closeCounter={closeCounter}
          setRef={setRef}
          reportData={reportData}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ParvoCoronaPanel
          title="Resultado"
          materialId={fields.parvoCoronaMaterialId}
          isParvovirus={fields.parvoCoronaIsParvovirus}
          isCoronavirus={fields.parvoCoronaIsCoronavirus}
          materialIdFieldName="parvoCoronaMaterialId"
          isParvovirusFieldName="parvoCoronaIsParvovirus"
          isCoronavirusFieldName="parvoCoronaIsCoronavirus"
          materialIdHasValidation={$validation.parvoCoronaMaterialId.show}
          isParvovirusHasValidation={$validation.parvoCoronaIsParvovirus.show}
          isCoronavirusHasValidation={$validation.parvoCoronaIsCoronavirus.show}
          materialIdValidationReason={
            $validation.parvoCoronaMaterialId.error.reason
          }
          isParvovirusValidationReason={
            $validation.parvoCoronaIsParvovirus.error.reason
          }
          isCoronavirusValidationReason={
            $validation.parvoCoronaIsCoronavirus.error.reason
          }
          obsFieldName="parvoCoronaObs"
          obs={fields.parvoCoronaObs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ParasitologicalFreshFecesPanel
          title="Exame Macroscópico"
          fecesColorOptionId={
            fields.parasitologicalFreshFecesFecesColorOptionId
          }
          fecesColorOptionIdFieldName="parasitologicalFreshFecesFecesColorOptionId"
          fecesColorOptions={
            fecesColorOptionsPromise.fulfilled
              ? fecesColorOptionsPromise.value
              : []
          }
          isFecesColorOptionsLoading={true === fecesColorOptionsPromise.pending}
          fecesColorOptionIdHasValidation={
            $validation &&
            $validation.parasitologicalFreshFecesFecesColorOptionId.show
          }
          fecesColorOptionIdValidationReason={
            $validation &&
            $validation.parasitologicalFreshFecesFecesColorOptionId.error.reason
          }
          fecesSmellOptionId={
            fields.parasitologicalFreshFecesFecesSmellOptionId
          }
          fecesSmellOptionIdFieldName="parasitologicalFreshFecesFecesSmellOptionId"
          fecesSmellOptions={
            fecesSmellOptionsPromise.fulfilled
              ? fecesSmellOptionsPromise.value
              : []
          }
          isFecesSmellOptionsLoading={true === fecesSmellOptionsPromise.pending}
          fecesSmellOptionIdHasValidation={
            $validation.parasitologicalFreshFecesFecesSmellOptionId.show
          }
          fecesSmellOptionIdValidationReason={
            $validation.parasitologicalFreshFecesFecesSmellOptionId.error.reason
          }
          fecesAspectOptionId={
            fields.parasitologicalFreshFecesFecesAspectOptionId
          }
          fecesAspectOptionIdFieldName="parasitologicalFreshFecesFecesAspectOptionId"
          fecesAspectOptions={
            fecesAspectOptionsPromise.fulfilled
              ? fecesAspectOptionsPromise.value
              : []
          }
          isFecesAspectOptionsLoading={
            true === fecesAspectOptionsPromise.pending
          }
          fecesAspectOptionIdHasValidation={
            $validation.parasitologicalFreshFecesFecesAspectOptionId.show
          }
          fecesAspectOptionIdValidationReason={
            $validation.parasitologicalFreshFecesFecesAspectOptionId.error
              .reason
          }
          fecesConsistencyOptionId={
            fields.parasitologicalFreshFecesFecesConsistencyOptionId
          }
          fecesConsistencyOptionIdFieldName="parasitologicalFreshFecesFecesConsistencyOptionId"
          fecesConsistencyOptions={
            fecesConsistencyOptionsPromise.fulfilled
              ? fecesConsistencyOptionsPromise.value
              : []
          }
          isFecesConsistencyOptionsLoading={
            true === fecesConsistencyOptionsPromise.pending
          }
          fecesConsistencyOptionIdHasValidation={
            $validation.parasitologicalFreshFecesFecesConsistencyOptionId.show
          }
          fecesConsistencyOptionIdValidationReason={
            $validation.parasitologicalFreshFecesFecesConsistencyOptionId.error
              .reason
          }
          macroscopicParasitesFieldName={
            "parasitologicalFreshFecesMacroscopicParasites"
          }
          macroscopicParasites={
            fields.parasitologicalFreshFecesMacroscopicParasites
          }
          macroscopicParasitesHasValidation={
            $validation.parasitologicalFreshFecesMacroscopicParasites.show
          }
          macroscopicParasitesValidationReason={
            $validation.parasitologicalFreshFecesMacroscopicParasites.error
              .reason
          }
          resultFieldName="parasitologicalFreshFecesResult"
          result={fields.parasitologicalFreshFecesResult}
          resultHasValidation={$validation.parasitologicalFreshFecesResult.show}
          resultValidationReason={
            $validation.parasitologicalFreshFecesResult.error.reason
          }
          obsFieldName="parasitologicalFreshFecesObs"
          obs={fields.parasitologicalFreshFecesObs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
          parasitologicalFreshFecesResult={parasitologicalFreshFecesResult}
          checkdState={checkdState}
          checkboxes={checkboxes}
          multiBoxChange={multiBoxChange}
          handleCheckedResultChange={handleCheckedResultChange}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <DefaultMicrobiologyContent
          title="Coprocultura"
          materialFieldName="urocultureMaterial"
          material={fields.urocultureMaterial}
          materialHasValidation={[
            fields.urocultureIsNegative
              ? []
              : $validation.urocultureMaterial.show,
          ]}
          materialValidationReason={[
            fields.urocultureIsNegative
              ? []
              : $validation.urocultureMaterial.error.reason,
          ]}
          isNegativeFieldName="urocultureIsNegative"
          isNegative={fields.urocultureIsNegative}
          resultFieldName="urocultureResult"
          result={
            fields.urocultureIsNegative
              ? urocultureNegativeMessage
              : fields.urocultureResult
          }
          resultHasValidation={
            $validation.urocultureResult && $validation.urocultureResult.show
          }
          resultValidationReason={
            $validation.urocultureResult &&
            $validation.urocultureResult.error.reason
          }
          antibioticOptions={antibioticOptions}
          sensitiveTagsFieldName={"urocultureSensitiveAntibioticTags"}
          sensitiveTags={fields.urocultureSensitiveAntibioticTags}
          sensitiveHasValidation={
            $validation.urocultureSensitiveAntibioticTags &&
            $validation.urocultureSensitiveAntibioticTags.show
          }
          sensitiveValidationReason={
            $validation.urocultureSensitiveAntibioticTags &&
            $validation.urocultureSensitiveAntibioticTags.error.reason
          }
          intermediateTagsFieldName={"urocultureIntermediateAntibioticTags"}
          intermediateTags={fields.urocultureIntermediateAntibioticTags}
          intermediateHasValidation={
            $validation.urocultureIntermediateAntibioticTags &&
            $validation.urocultureIntermediateAntibioticTags.show
          }
          intermediateValidationReason={
            $validation.urocultureIntermediateAntibioticTags &&
            $validation.urocultureIntermediateAntibioticTags.error.reason
          }
          resistantTagsFieldName={"urocultureResistantAntibioticTags"}
          resistantTags={fields.urocultureResistantAntibioticTags}
          resistantHasValidation={
            $validation.urocultureResistantAntibioticTags &&
            $validation.urocultureResistantAntibioticTags.show
          }
          resistantValidationReason={
            $validation.urocultureResistantAntibioticTags &&
            $validation.urocultureResistantAntibioticTags.error.reason
          }
          obsFieldName="urocultureObs"
          obs={fields.urocultureIsNegative ? "" : fields.urocultureObs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
          toggleIsNegative={toggleIsNegative}
          getQuantitativeResult={getQuantitativeResult}
          suggestionsFilter={prepareAntibioticTagsSuggestionFilter(
            fields.urocultureSensitiveAntibioticTags,
            fields.urocultureIntermediateAntibioticTags,
            fields.urocultureResistantAntibioticTags
          )}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FecesCoprocytologicalPanel
          fieldName="fecesCoprocytologicalResult"
          result={fields.fecesCoprocytologicalResult}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasValidation={$validation.fecesCoprocytologicalResult.show}
          validationReason={
            $validation.fecesCoprocytologicalResult.error.reason
          }
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default ProfileGastroenteritisSilverReportFormView;
