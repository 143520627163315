import React from 'react';

import { Row, Col } from './../../components/grid';
import FlatPanelMaterial from './../FlatPanelMaterial';
import FibrinogenContent from './../FibrinogenContent';

const FibrinogenPanel = ( {
  resultFieldName,
  result,
  obsFieldName,
  obs,
  resultHasValidation,
  resultValidationReason,
  obsHasValidation,
  obsValidationReason,
  refMin,
  refMax,
  shouldDisable,
  handleChange,
  handleBlur
} ) =>
  <FlatPanelMaterial title="Fibrinogênio">
    <Row>
      <Col md={ 12 }>
        <FibrinogenContent
          resultFieldName={ resultFieldName }
          result={ result }
          obsFieldName={ obsFieldName }
          obs={ obs }
          resultHasValidation={ resultHasValidation }
          resultValidationReason={ resultValidationReason }
          obsHasValidation={ obsHasValidation }
          obsValidationReason={ obsValidationReason }
          refMin={ refMin }
          refMax={ refMax }
          shouldDisable={ shouldDisable }
          handleChange={ handleChange }
          handleBlur={ handleBlur } />
      </Col>
    </Row>
  </FlatPanelMaterial>

export default FibrinogenPanel;