import React, { useMemo, useRef } from "react";
import * as LifeVet from "../../../../../components/index";
import { useTable } from "react-table-v7";
import { Table } from "react-bootstrap";
import { RequestSample } from "../../../../../types/index";
import __update from "immutability-helper";

const TOTAL_PER_PAGE = 7;

export const RequestSamplesTable = ({
  data,
  methods,
  touched,
  errors,
  setTouched,
  handleChange,
  handleBlur,
  loggedUserIsFromClinic,
  disableAll,
}: {
  data: Array<RequestSample>;
  methods: any;
  touched: any;
  errors: any;
  setTouched: any;
  handleChange: any;
  handleBlur: any;
  loggedUserIsFromClinic: boolean;
  disableAll: boolean;
}) => {
  const sampleRefs = useRef(
    Array(16)
      .fill(null)
      .map(() => React.createRef<HTMLElement>())
  );

  const onChangeCode = (data: any, handleChange: any) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = e.target.value;
    let containsOtherThanNumbers =
      (e.target.value.match(/\D/g) || []).length > 0;

    if (containsOtherThanNumbers) return;

    if (value.length <= 10) {
      const newSample = __update(data.sample, {
        code: { $set: value },
      });
      handleChange({ name: "samples", index: data.index }, newSample);
    }

    if (value.length >= 10) {
      const ref = sampleRefs.current[data.index + 1];
      if (ref && ref.current) ref.current.focus();
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Material de Amostra",
        accessor: "name",
        id: "name",
        disableSortBy: true,
      },
      {
        Header: "Identificação",
        accessor: "code",
        id: "code",
        Cell: (props: {
          data: Array<RequestSample>;
          row: { index: number; original: RequestSample };
        }) => {
          const disable = loggedUserIsFromClinic || disableAll;
          const sampleData = {
            // samples: props.data,
            sample: props.row.original,
            ref: sampleRefs.current[props.row.index],
            disable: disable,
            index: props.row.index,
            error:
              errors && errors[props.row.index] && errors[props.row.index].code,
          };

          return (
            <LifeVet.Input
              value={sampleData.sample.code}
              onChange={onChangeCode(sampleData, handleChange)}
              onBlur={() =>
                sampleData.error &&
                setTouched(`samples[${sampleData.index}].code`)
              }
              innerRef={sampleData.ref}
              type={"text"}
              key={sampleData.index}
              disabled={sampleData.disable}
              errorMessage={sampleData.error}
              max={9999999999}
            />
          );
        },
        disableSortBy: true,
      },
    ];
  }, [errors, data.length, disableAll]);

  const samples = useMemo(() => data, [data]);

  const table = useTable({
    initialState: {
      pageSize: TOTAL_PER_PAGE,
      pageIndex: 0,
    },
    columns: columns,
    data: samples,
    autoResetFilters: false, // don't reset filters when passed new data to the table
    autoResetSortBy: false, // don't reset sorting when passed new data to the table
    autoResetPage: false, // don't reset pagination when passed new data to the table
    disableMultiSort: true,
    pageCount: 1,
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = table;

  return (
    <>
      <Table
        {...getTableProps()}
        condensed
        bordered
        hover
        striped
        className={"text-center"}
      >
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps()}
                  style={{ textAlign: "center" }}
                >
                  {column.render("Header")}{" "}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td style={{ width: "50%" }} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {rows.length === 0 && (
        <div
          style={{ marginTop: "-15px", fontWeight: "bold" }}
          className="text-center"
        >
          Nenhum registro.
        </div>
      )}
    </>
  );
};
