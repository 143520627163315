import connect from "../../api-connector";
import { EXAM_TYPE_ID_BY_NAME } from "../../assets/js/Consts.js";
import { EXAM_TYPES } from "../../consts";

const connectWithEndpoint = connect(props => {
  const requestExamId = props.match.params.requestExamId;
  const biochemistryExamTypeId = EXAM_TYPE_ID_BY_NAME["BIOCHEMISTRY"];

  const examRequestId = props.match.params.examRequestId;

  const biochemIds = [1, 2, 3];

  const serializedBiochemIds = JSON.stringify(biochemIds);

  return {
    examsPromise: `/exams?outsourced=false&type=${biochemistryExamTypeId}`,
    requestExamStatesPromise: `/request-exam-states`,
    reportHeaderPromise: `/report/request-exams/${requestExamId}/report-header`,
    refsPromise: `/reference-values/?requestExamId=${requestExamId}&examTypeId=${EXAM_TYPES.HEMATOLOGY}`,
    fecesColorOptionsPromise: "/feces-color-options",
    fecesSmellOptionsPromise: "/feces-smell-options",
    fecesAspectOptionsPromise: "/feces-aspect-options",
    fecesConsistencyOptionsPromise: "/feces-consistency-options",
    withId: (id, then) => ({
      objPromise: {
        url: `/reports/${id}`,
        then: then
      }
    }),
    getBiochemistryExams: then => ({
      biochemistryExams: {
        url: `/biochemistry/exam-requests/${examRequestId}?biochemIds=${serializedBiochemIds}`,
        then: then
      }
    }),
    postReport: (report, release, then) => ({
      reportSubmitPromise: {
        url: `/reports?release=${release}`,
        method: "POST",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    sendEmailReport: (id, then) => ({
      sendEmailReportPromisse: {
        url: `/mail/print-report/${id}`,
        method: "POST",
        then: then
      }
    }),
    updateReport: (report, release, then) => ({
      reportSubmitPromise: {
        url: `/reports/${report.id}?release=${release}`,
        method: "PUT",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    cancelReport: (report, then) => ({
      reportSubmitPromise: {
        url: `/reports/${report.requestExamId}/cancel`,
        method: "PUT",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    stopReport: (report, then) => ({
      reportSubmitPromise: {
        url: `/reports/${report.id}/stop`,
        method: "PUT",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    })
  };
});

export default connectWithEndpoint;
