import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import { required } from '../../assets/js/validationFunctions';

import { commonReportRules, urocultureRules } from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    material,
    result,
    coloniesCount,
    sensitiveAntibioticTags,
    intermediateAntibioticTags,
    resistantAntibioticTags,
    isNegative,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const {completeValidation} = props.data;

  let fields = [
    'material',
    'result',
    'sensitiveAntibioticTags',
    'intermediateAntibioticTags',
    'resistantAntibioticTags',
    'coloniesCount',
    'vetSignerId',
    'requestExamState'
  ];

  const commonValidation = commonReportRules(
    vetSignerId, requestExamState );

  const validation = urocultureRules(
    'material',
    material,
    'result',
    result,
    'sensitiveAntibioticTags',
    sensitiveAntibioticTags,
    'intermediateAntibioticTags',
    intermediateAntibioticTags,
    'resistantAntibioticTags',
    resistantAntibioticTags,
    'coloniesCount',
    coloniesCount,
    isNegative,
    completeValidation
  );

  let validations = {
    ...commonValidation,
    ...validation
  };

  return { fields, validations };
} );

export default validation;