import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
* Internal
*/
import FlatPanelMaterial from './../FlatPanelMaterial';
import TagsInputMaterial2 from './../TagsInputMaterial2';

/**
* External
*/
import { Row, Col } from './../../components/grid';

/**
 * Assets
 */

 const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a ThreeTagsInputPanel form
 * 
 */
const ThreeTagsInputPanel = ( {
  panelTitle,
  firstTitle,
  firstTags,
  firstName,
  firstSuggestions,
  firstSuggestionsFilter,
  firstHasValidation,
  firstValidationReason,
  secondTitle,
  secondTags,
  secondName,
  secondSuggestions,
  secondSuggestionsFilter,
  secondHasValidation,
  secondValidationReason,
  thirdTitle,
  thirdTags,
  thirdName,
  thirdSuggestions,
  thirdSuggestionsFilter,
  thirdHasValidation,
  thirdValidationReason,
  shouldDisable,
  handleChange,
  handleBlur,
  } ) =>
  <FlatPanelMaterial title={ panelTitle }>
    <Row>
      <Col md={ 4 }>
        <TagsInputMaterial2
          title={ firstTitle }
          name={ firstName }
          tags={ firstTags }
          disabled={ shouldDisable }
          onChange={ handleChange }
          onBlur={ handleBlur }
          suggestions={ firstSuggestions }
          suggestionsFilter={firstSuggestionsFilter }
          hasValidation={ firstHasValidation }
          validationReason={ firstValidationReason }
          validationRecoil={ false } />
      </Col>
      <Col md={ 4 }>
        <TagsInputMaterial2
          title={ secondTitle }
          name={ secondName }
          tags={ secondTags }
          disabled={ shouldDisable }
          onChange={ handleChange }
          onBlur={ handleBlur }
          suggestions={ secondSuggestions }
          suggestionsFilter={secondSuggestionsFilter }
          hasValidation={ secondHasValidation }
          validationReason={ secondValidationReason }
          validationRecoil={ false } />
      </Col>
      <Col md={ 4 }>
        <TagsInputMaterial2
          title={ thirdTitle }
          name={ thirdName }
          tags={ thirdTags }
          disabled={ shouldDisable }
          onChange={ handleChange }
          onBlur={ handleBlur }
          suggestions={ thirdSuggestions }
          suggestionsFilter={thirdSuggestionsFilter }
          onBlur={ handleBlur }
          hasValidation={ thirdHasValidation }
          validationReason={ thirdValidationReason }
          validationRecoil={ false } />
      </Col>
    </Row>
  </FlatPanelMaterial>

ThreeTagsInputPanel.propTypes = propTypes;

export default ThreeTagsInputPanel;