import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import ParvoCoronaContent from "./../ParvoCoronaContent";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";
import MethodPanel from "./../MethodPanel";
import { ManufacturerInformationFields } from "../ManufacturerInformationFields";

const ParvoCoronaReportFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Parvovirus + Coronavirus"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
      
    <ParvoCoronaContent
      title="Resultado"
      materialId={data.fields.materialId}
      isParvovirus={data.fields.isParvovirus}
      isCoronavirus={data.fields.isCoronavirus}
      materialIdFieldName="materialId"
      isParvovirusFieldName="isParvovirus"
      isCoronavirusFieldName="isCoronavirus"
      materialIdHasValidation={data.$validation.materialId.show}
      isParvovirusHasValidation={data.$validation.isParvovirus.show}
      isCoronavirusHasValidation={data.$validation.isCoronavirus.show}
      materialIdValidationReason={data.$validation.materialId.error.reason}
      isParvovirusValidationReason={data.$validation.isParvovirus.error.reason}
      isCoronavirusValidationReason={
        data.$validation.isCoronavirus.error.reason
      }
      shouldDisable={data.disableAll}
      handleChange={methods.handleChange}
      handleBlur={methods.handleBlur}
      obsFieldName="obs"
      obs={data.fields.obs}
    />
    
    <ManufacturerInformationFields
      manufacturer={data.fields.manufacturer}
      batch={data.fields.batch}
      validity={data.fields.validity}
      obs={data.fields.manufacturerInformationObs}
      obsFieldName="manufacturerInformationObs"
      onChange={(name, value) => methods.fieldChange(name, value)}
    />
  </ReportPanelMaterial>
);

export default ParvoCoronaReportFormView;
