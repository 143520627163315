import React from "react";
import { Badge } from "./../../components/badge";
import {
  blueberry,
  leaf400,
  pumpkin,
  grape,
  text000
} from "./../../components/colors";

const InProgress = () => {
  return (
    <Badge textColor={text000} backgroundColor={blueberry} text="Em Aberto" />
  );
};

const InAnalysis = () => {
  return (
    <Badge textColor={text000} backgroundColor={grape} text="Em Análise" />
  );
};

const InDevelopment = () => {
  return (
    <Badge
      textColor={text000}
      backgroundColor={pumpkin}
      text="Em Desenvolvimento"
    />
  );
};

const CompletedBadge = () => {
  return (
    <Badge textColor={text000} backgroundColor={leaf400} text="Concluído" />
  );
};

export { InProgress, InAnalysis, InDevelopment, CompletedBadge };
