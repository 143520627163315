import React, { LabelHTMLAttributes, ReactNode } from "react"

import { FieldError } from "react-hook-form"
import { StyledLabel } from "./styles"

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  error?: FieldError
  children: ReactNode
}

export function Label({ error, children, ...props }: LabelProps) {
   return (
      <StyledLabel {...props} error={!!error}>
        {children}
      </StyledLabel>
   )
}