import React from 'react';

/**
 * External
 */
import PropTypes from 'prop-types';
import { Col } from './../../components/grid';

/**
 * Internal
 */
import DoubleNumberInputWithReferencePanel2 from './../DoubleNumberInputWithReferencePanel2';

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const CoagulationTestContent = ( {
  panelTitle,
  tromboplastinTimeName,
  tromboplastinTimeValue,
  tromboplastinTimeRefMin,
  tromboplastinTimeRefMax,
  tromboplastinTimeRefUnit,
  tromboplastinTimeValidationShow,
  tromboplastinTimeValidationReason,
  prothrombinTimeName,
  prothrombinTimeValue,
  prothrombinTimeRefMin,
  prothrombinTimeRefMax,
  prothrombinTimeRefUnit,
  prothrombinTimeValidationShow,
  prothrombinTimeValidationReason,
  disableAll,
  handleChange,
  handleBlur
} ) =>
  <DoubleNumberInputWithReferencePanel2
    panelTitle={ panelTitle }
    firstTitle={ 'Tempo de Prototrombina' }
    firstValue={ prothrombinTimeValue }
    firstRefMin={ prothrombinTimeRefMin }
    firstRefMax={ prothrombinTimeRefMax }
    firstRefUnit={ prothrombinTimeRefUnit }
    firstName={ prothrombinTimeName }
    firstDecimalScale={ 2 }
    firstValidationShow={ prothrombinTimeValidationShow }
    firstValidationReason={ prothrombinTimeValidationReason }
    secondTitle={ 'Tempo de Tromboplastina' }
    secondName={ tromboplastinTimeName }
    secondValue={ tromboplastinTimeValue }
    secondRefMin={ tromboplastinTimeRefMin }
    secondRefMax={ tromboplastinTimeRefMax }
    secondRefUnit={ tromboplastinTimeRefUnit }
    secondDecimalScale={ 1 }
    secondValidationShow={ tromboplastinTimeValidationShow }
    secondValidationReason={ tromboplastinTimeValidationReason }
    disableAll={ disableAll }
    handleChange={ handleChange }
    handleBlur={ handleBlur } 
    firstSuffix={" "+ prothrombinTimeRefUnit}
    secondSuffix={" " +tromboplastinTimeRefUnit}
    />

CoagulationTestContent.propTypes = propTypes;

export default CoagulationTestContent;