import { isEmptyField } from "./util";

import { StringUtil } from "../../../useful";
import Decimal from "decimal.js";

function isAnemia(
  { erythrocytes, corpuscularVolume, hemoglobiometry },
  { erythrocytesMin, corpuscularVolumeMin, hemoglobiometryMin }
) {
  if (
    isEmptyField(erythrocytes) ||
    isEmptyField(corpuscularVolume) ||
    isEmptyField(hemoglobiometry) ||
    isEmptyField(erythrocytesMin)
  ) {
    return "";
  }

  const eryth = new Decimal(erythrocytes);
  const corpVol = new Decimal(corpuscularVolume);
  const hemoglob = new Decimal(hemoglobiometry);

  return (
    eryth.lessThan(erythrocytesMin) ||
    corpVol.lessThan(corpuscularVolumeMin) ||
    hemoglob.lessThan(hemoglobiometryMin)
  );
}

function isPolicitemia(
  { erythrocytes, corpuscularVolume, hemoglobiometry },
  { erythrocytesMax, corpuscularVolumeMax, hemoglobiometryMax }
) {
  if (
    isEmptyField(erythrocytes) ||
    isEmptyField(corpuscularVolume) ||
    isEmptyField(hemoglobiometry) ||
    isEmptyField(erythrocytesMax)
  ) {
    return "";
  }

  const eryth = new Decimal(erythrocytes);
  return eryth.greaterThan(erythrocytesMax);
}



 function bloodCellsDiagnosis(erythogramValues, erythogramRefs) {
  if (isAnemia(erythogramValues, erythogramRefs)) { 
    return "anemia";
  }

  if (isPolicitemia(erythogramValues, erythogramRefs)) {
    return "policitemia";
  }

  return "hemácia";
}

 function calcMCV({corpuscularVolume, erythrocytes}) {
  if (isEmptyField(corpuscularVolume) || isEmptyField(erythrocytes)) {
    return "";
  }
  const globVol = new Decimal(corpuscularVolume);
  const eryth = new Decimal(erythrocytes);
  const erythStr = eryth.toFixed(2);
  if (erythStr === "0.0") {
    return erythStr;
  }
  return globVol.dividedBy(eryth).times(10).toFixed(2);
}

 function calcMCHC({corpuscularVolume, hemoglobiometry}) {
  if (isEmptyField(corpuscularVolume) || isEmptyField(hemoglobiometry)) {
    return "";
  }

  const globVol = new Decimal(corpuscularVolume);
  const hemoglob = new Decimal(hemoglobiometry);

  const strGlobVol = globVol.toFixed(2);

  if (strGlobVol === "0.0") {
    return strGlobVol;
  }

  return hemoglob.dividedBy(globVol).times(100).toFixed(2);
}

 function mcvDiagnosis(erythogramValues,refs) {
  if (isEmptyField(refs.mcvMin) || isEmptyField(refs.mcvMax) || isEmptyField(calcMCV(erythogramValues))) { 
    return "";
  }
  const MCV = new Decimal(calcMCV(erythogramValues));
  const MCVMin = new Decimal(refs.mcvMin);
  const MCVMax = new Decimal(refs.mcvMax);

  if (MCV.lessThan(MCVMin)) {
    return "microcítica";
  }

  if (MCV.greaterThan(MCVMax)) {
    return "macrocítica";
  }

  if (isPolicitemia(erythogramValues,refs)) { 
    return "";
  }
  return "normocítica";
}

 function mchcDiagnosis(erythogramValues,refs) {
  if (
    isEmptyField(refs.mchcMin) ||
    isEmptyField(refs.mchcMax) ||
    isEmptyField(calcMCHC(erythogramValues, refs)) 
  ) {
    return "";
  }

  const MCHC = new Decimal(calcMCHC(erythogramValues, refs)); 
  const MCHCMin = new Decimal(refs.mchcMin);
  const MCHCMax = new Decimal(refs.mchcMax);

  if (MCHC.lessThan(MCHCMin)) {
    return "hipocrômica";
  }

  if (MCHC.greaterThan(MCHCMax)) {
    return "hipercrômica";
  }

  if (isPolicitemia(erythogramValues, refs)) {   
    return "";
  }

  return "normocrômica";
}

 function erythrogramDiagnosis(
  erythogramValues, refs
) {
  if(StringUtil.isStrEmpty(erythogramValues.erythrocytes, erythogramValues.corpuscularVolume, erythogramValues.hemoglobiometry)) 
      return ""

  const erythrogramDiagnosis =
    bloodCellsDiagnosis(erythogramValues, refs) + " " + mcvDiagnosis(erythogramValues, refs) + " " + mchcDiagnosis(erythogramValues, refs);  
  return StringUtil.capitalize(erythrogramDiagnosis).trim();
}

export  {
  erythrogramDiagnosis,
  calcMCV,
  calcMCHC
}
