import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
* Internal
*/
import TextAreaMaterial2 from './../TextAreaMaterial2';
import FlatPanelMaterial from './../FlatPanelMaterial';

/**
* External
*/
import { Row, Col } from './../../components/grid';

/**
 * Assets
 */
const propTypes = {
  title: PropTypes.string,
  fieldTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  hasValidation: PropTypes.bool,
  validationReason: PropTypes.string,
  shouldDisable: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func
  
};
const defaultProps = {
  title: '',
  fieldTitle: '',
  hasValidation: false,
  shouldDisable: false
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a TextAreaPanel2 form
 * 
 */
const TextAreaPanel2 = ( {
  title,
  fieldTitle,
  name,
  value,
  hasValidation,
  validationReason,
  shouldDisable,
  handleChange,
  handleBlur
} ) =>
  <FlatPanelMaterial title={ title } >
    <Row>
      <Col md={ 12 }>
        <TextAreaMaterial2
          title={ fieldTitle }
          name={ name }
          value={ value }
          disabled={ shouldDisable }
          handleChange={ handleChange }
          handleBlur={ handleBlur }
          hasValidation={ hasValidation }
          validationReason={ validationReason } />
      </Col>
    </Row>
  </FlatPanelMaterial>

TextAreaPanel2.propTypes = propTypes;
TextAreaPanel2.defaultProps = defaultProps;

export default TextAreaPanel2;