import React from 'react';

/**
* Internal
*/
import FlatPanelMaterial from './../FlatPanelMaterial';
import HemocytozoaResearchContent from './../HemocytozoaResearchContent';

/**
 * Assets
 */

 const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a HemocytozoaResearchPanel form
 * 
 */
const HemocytozoaResearchPanel = ( {
  title,
  resultFieldName,
  result,
  resultHasValidation,
  resultValidationReason,
  obsFieldName,
  obs,
  obsHasValidation,
  obsValidationReason,
  handleChange,
  handleBlur,
  shouldDisable,
  handleResultChange,
  checkboxes,
  multiCheckboxChange,
  checkdState
  } ) =>
  <FlatPanelMaterial title={ title ? title : "Pesquisa de Hemocitozoários" } >
    <HemocytozoaResearchContent
      resultFieldName={ resultFieldName }
      result={ result }
      handleResultChange={handleResultChange}
      resultHasValidation={ resultHasValidation }
      resultValidationReason={ resultValidationReason }
      obsFieldName={ obsFieldName }
      obs={ obs }
      obsHasValidation={ obsHasValidation }
      obsValidationReason={ obsValidationReason }
      handleChange={ handleChange }
      handleBlur={ handleBlur }
      shouldDisable={ shouldDisable } 
      checkboxes={checkboxes}
      multiCheckboxChange={multiCheckboxChange}
      checkdState={checkdState}
      />
      
  </FlatPanelMaterial>

HemocytozoaResearchPanel.propTypes = propTypes;

export default HemocytozoaResearchPanel;