import __update from "immutability-helper";
import { UseAnimalState, Action } from "./../../types/index";

export const ACTIONS = {
  UPDATE: "UPDATE",
  UPDATE_FIELD: "UPDATE_FIELD",
};

const update = (
  value: UseAnimalState,
  target: UseAnimalState
): UseAnimalState => {
  const commands = {
    id: { $set: value.id },
    name: { $set: value.name },
    birthDate: { $set: value.birthDate },
    owner: { $set: value.owner },
    phone: { $set: value.phone },
    cpf: { $set: value.cpf },
    externalCode: { $set: value.externalCode },
    microchipCode: { $set: value.microchipCode },
    sexId: { $set: value.sexId },
    sexName: { $set: value.sexName },
  };
  return __update(target, commands);
};

const updateField = (
  field: string,
  value: any,
  target: UseAnimalState
): UseAnimalState => {
  const commands = {
    [field]: { $set: value },
  };
  return __update(target, commands);
};

export const reducer = (state: UseAnimalState, action: Action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return update(action.payload, state);
    case ACTIONS.UPDATE_FIELD:
      return updateField(action.payload.field, action.payload.value, state);
    default:
      return state;
  }
};
