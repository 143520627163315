import React from "react";
import { Switch, Route } from "react-router-dom";

import SpecieList from "./list";
import SpecieForm from "./form";
import NotFound from "./../NotFound";

const renderList = routeProps => {
  return <SpecieList {...routeProps} />;
};

const renderForm = routeProps => {
  return <SpecieForm {...routeProps} />;
};

const Species = () => (
  <Switch>
    <Route exact path={"/especies"} render={renderList} />
    <Route exact path={"/especies/nova"} render={renderForm} />
    <Route path={"/especies/:id"} render={renderForm} />
    <Route component={NotFound} />
  </Switch>
);

export default Species;
