import { withFormik } from "formik";
import { CREATED_MESSAGE, ERROR_MESSAGE } from "./../../consts/messages";
import { TICKET_STATUS } from "./../../consts";
import * as Yup from "yup";

export default withFormik({
  mapPropsToValues: () => {
    return {
      subject: "",
      message: "",
      status: TICKET_STATUS.IN_PROGRESS,
    };
  },

  validationSchema: Yup.object().shape({
    subject: Yup.string().required("Campo requerido"),
    message: Yup.string().required("Campo requerido"),
    status: Yup.number().nullable(),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    values.lab = props.lab;
    const onSuccess = () => {
      setSubmitting(false);
      props.successNotification(CREATED_MESSAGE);
      props.history.push("/tickets");
    };

    const onError = () => {
      setSubmitting(false);
      props.errorNotification(ERROR_MESSAGE);
    };

    props.match.params.id > 0
      ? props.updateTicket(values, onSuccess, onError)
      : props.createTicket(values, onSuccess, onError);
  },

  displayName: "TicketForm",
});
