
import {
  FETCH_LOGIN_BEGIN,
  FETCH_LOGIN_FAIL,
  FETCH_LOGIN_SUCCESS,
  USER_LOGGED_IN,
  USER_NOT_LOGGED_IN,
  USER_LOGOUT,
} from "../actions/";

/**
 * Default user state
 * @type {Object}
 */
const defaultState = {
  user: {},
  token: "",
  is_authenticated: false,
  access_profile: false,
  lab_id: 0,
  customer_id: 0,
  customer_person_id: 0,
  error: null,
  fetching: false,
  fetched: false,
};

/**
 * @author Victor Heringer
 *
 * Manage the user state across aplication
 *
 * @param  {object} state  State of aplication
 * @param  {object} action Dispatched action
 *
 * @return {Mixed}
 */
export default function userReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_LOGIN_BEGIN: {
      return { ...state, fetching: true, error: null };
    }
    case FETCH_LOGIN_FAIL: {
      return { ...state, fetching: false, error: action.payload.error };
    }
    case USER_NOT_LOGGED_IN: {
      return { ...state, user: null, is_authenticated: false };
    }
    case USER_LOGOUT: {
      return { ...state, user: null, is_authenticated: false };
    }
    case FETCH_LOGIN_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        user: action.payload.user,
        token: action.payload.user.token,
        access_profile: action.payload.user.access_profile,
        lab_id: action.payload.user.lab_id,
        customer_id: action.payload.user.customer_id,
        customer_person_id: action.payload.user.customer_person_id,
        is_authenticated: true,
      };
    }
    case USER_LOGGED_IN: {
      return {
        ...state,
        user: action.payload.user,
        access_profile: action.payload.user.access_profile,
        lab_id: action.payload.user.lab_id,
        customer_id: action.payload.user.customer_id,
        customer_person_id: action.payload.user.customer_person_id,
        is_authenticated: true,
      };
    }
    default:
      return state;
  }
}
