import React from "react";
import PropTypes from "prop-types";

import AccessManager from "./../AccessManager";

/**
 * External
 */
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

/**
 * @author Victor Heringer
 *
 * Pure links for sidebar
 */
const LVSidebarSimpleLink = ({
  id,
  to,
  title,
  className,
  onClick,
  allowed,
}) => {
  return (
    <AccessManager allowed={allowed} name={title}>
      <li style={{ fontSize: "11px" }} className={className} onClick={onClick}>
        <Link to={to}>{title}</Link>
      </li>
    </AccessManager>
  );
};

LVSidebarSimpleLink.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withRouter(LVSidebarSimpleLink);
