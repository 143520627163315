import React, { Component } from "react";
import cloneDeep from "lodash/cloneDeep";

/** Internal */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import PanelMaterialBottom from "./../PanelMaterialBottom";
import InputMaterial from "./../InputMaterial";
import SelectMaterial from "./../SelectMaterial";
import SampleModal from "./../ExamSampleListView/SampleModal";
import connectWithEndpoint from "./requests.js";
import { NOTIFICATIONS } from "./../../consts";
import {
  BTN_SAVE_TITLE,
  BTN_UPDATE_TITLE,
  BTN_SAVING_TITLE,
  BTN_UPDATING_TITLE
} from "../../assets/js/Consts.js";

/** External */
import { Link } from "react-router-dom";
import ReactTable from "react-table-v6";
import update from "immutability-helper";
import { Row, Col } from "./../../components/grid";

class ExamSampleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      fields: {
        modalSampleName: "",
        selectedExamType: 2,
        sampleExams: {}
      },
      sampleModalTable: { pages: 1, sorted: null }
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.renderEditLink = this.renderEditLink.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
    this.handleSubmitSample = this.handleSubmitSample.bind(this);
    this.sampleModalFilters = this.sampleModalFilters.bind(this);
    this.renderSelectSamples = this.renderSelectSamples.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitModalData = this.submitModalData.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  componentDidMount() {
    const then = (data, xhr) => {
      xhr.response.status === 200 && this.fillFields(data);
    };

    this.props.getExams(this.state.fields.selectedExamType, then);
  }

  fillFields(data) {
    data.map(value => {
      const samples = value.samples.map(sample => {
        return {
          pivot: sample.pivot.id,
          sample: sample.id,
          exam: sample.pivot.exam_id
        };
      });

      for (let i = samples.length; i < 3; i++) {
        samples.push({ pivot: 0, sample: 0, exam: 0 });
      }

      this.setState(state =>
        update(state, {
          fields: {
            sampleExams: {
              $merge: {
                [value.id]: samples
              }
            }
          }
        })
      );
    });
  }

  handleClose() {
    this.setState({ show: false });
    this.props._getSamples();
  }

  handleShow() {
    this.setState({ show: true });
  }

  /**
   * @author Victor Heringer
   *
   * Renders exam request edit link column
   *
   * @param {Object} data Represents an exam request in the table
   *
   * @return {Object}
   *
   */
  renderEditLink(data) {}

  handleSubmitSample(successCallback) {
    const sample = { name: this.state.fields.modalSampleName };

    const onSuccess = () => {
      this.setState(
        update(this.state, {
          fields: { modalSampleName: { $set: "" } }
        })
      );
      successCallback("reset");
      this.props.notificationSystem.add(NOTIFICATIONS.DEFAULT_SAVE_MESSAGE);
    };

    this.props.addSample(sample, onSuccess);
  }

  changeTableSelectField(idx, subfield, pivot, value) {
    if (this.examAndSampleCombinationAlreadyExists(idx, value)) return;

    this.setState(state =>
      update(state, {
        fields: {
          sampleExams: {
            [idx]: {
              [subfield]: {
                sample: { $set: value },
                pivot: { $set: pivot },
                exam: { $set: idx }
              }
            }
          }
        }
      })
    );
  }

  examAndSampleCombinationAlreadyExists = (examId, sampleId) => {
    const { sampleExams } = this.state.fields;
    const NoSampleId = 0;
    const found = sampleExams[examId].filter(function(current) {      
      return (current.sample === sampleId && sampleId != NoSampleId );
    });
    return found.length > 0;
  };

  /**
   * @author Victor Heringer
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   */
  submitModalData() {
    const { _samples } = this.props;

    const isSet = _samples !== undefined;
    const isPending = isSet && _samples.pending;
    const isFulfilled = isSet && _samples.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: BTN_SAVING_TITLE,
      btnTitle: BTN_SAVE_TITLE
    };
  }

  /**
   * @author Victor Heringer
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   */
  submitData() {
    const { examsSamplesResponse } = this.props;

    const isSet = examsSamplesResponse !== undefined;
    const isPending = isSet && examsSamplesResponse.pending;
    const isFulfilled = isSet && examsSamplesResponse.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: BTN_SAVING_TITLE,
      btnTitle: BTN_SAVE_TITLE
    };
  }

  renderSelectSamples(data, pos) {
    const { _samples } = this.props;
    let options = _samples && _samples.value ? _samples.value : [];
    options = cloneDeep(options);

    const key = `${data.row.id}`;
    const value = this.state.fields.sampleExams[key][pos]
      ? this.state.fields.sampleExams[key][pos]["sample"]
      : 0;
    const pivot = this.state.fields.sampleExams[key][pos]
      ? this.state.fields.sampleExams[key][pos]["pivot"]
      : 0;

    options.unshift({ id: 0, name: "NENHUM" });

    return (
      <SelectMaterial
        fit
        title=""
        md={12}
        name="examType"
        options={options}
        value={value}
        searchable={true}
        onChange={event => this.changeTableSelectField(key, pos, pivot, event)}
      />
    );
  }

  /**
   * @author Victor Heringer
   *
   * Filter unity table
   *
   * @param {Object} state
   * @param {Object} instance
   */
  sampleModalFilters(state, instance) {
    const filters = { id: "", name: "" };

    for (let data in state.filtered) {
      filters[state.filtered[data].id] = state.filtered[data].value;
    }

    const then = (data, xhr) => {
      this.setState(
        update(this.state, {
          sampleModalTable: { pages: { $set: data.last_page } }
        })
      );
    };

    this.props.sampleSearch(filters, state.page, state.sorted, then);
  }

  /**
   * @author Victor Heringer
   *
   * Changes the given field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChange(field, value) {
    this.setState(state =>
      update(state, {
        fields: {
          [field]: { $set: value }
        }
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Changes the given field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChangeExamType(field, value) {
    this.setState(state =>
      update(state, {
        fields: {
          [field]: { $set: value },
          sampleExams: { $set: {} }
        }
      })
    );

    const then = data => this.fillFields(data);
    this.props.getExams(value, then);
  }

  handleSubmit(data) {
    const then = data => {
      this.props.notificationSystem.add(NOTIFICATIONS.DEFAULT_SAVE_MESSAGE);

      const then = (data, xhr) => {
        xhr.response.status === 200 && this.fillFields(data);
      };

      this.props.getExams(this.state.fields.selectedExamType, then);
    };

    this.props.examsSamples(data, then);
  }

  render() {
    const { exams, examTypes, examsSamplesResponse } = this.props;

    let config = {
      defaultPageSize: 5,
      defaultSorted: [{ id: "id", desc: true }],
      pageSize: 15,
      resizable: false,
      showPagination: true,
      noDataText: "Nenhum registro".toUpperCase(),
      loadingText: "Carregando",
      className: "-striped -highlight",
      previousText: "Anterior",
      nextText: "Próximo",
      pageText: "Página",
      ofText: "de",
      rowsText: "linhas",
      columns: [],
      showPageSizeOptions: false,
      pageSizeOptions: [5, 10, 15, 20, 25, 50, 100]
    };

    config = update(config, {
      data: {
        $set: exams && exams.value ? exams.value : []
      },
      loading: {
        $set: exams && exams.pending
      },
      showPagination: {
        $set: false
      },
      pageSize: {
        $set: exams && exams.value ? exams.value.length : 15
      }
    });

    config.columns = [
      {
        Header: "Código",
        sortable: false,
        maxWidth: 100,
        className: "centeredText",
        filterable: false,
        accessor: "id"
      },
      {
        Header: "Nome",
        sortable: false,
        className: "centeredText",
        filterable: false,
        accessor: "name"
      },
      {
        Header: "Material de Amostra 1",
        sortable: false,
        className: "centeredText",
        filterable: false,
        Cell: data => this.renderSelectSamples(data, 0)
      },
      {
        Header: "Material de Amostra 2",
        sortable: false,
        className: "centeredText",
        filterable: false,
        Cell: data => this.renderSelectSamples(data, 1)
      },
      {
        Header: "Material de Amostra 3",
        sortable: false,
        className: "centeredText",
        filterable: false,
        Cell: data => this.renderSelectSamples(data, 2)
      }
    ];

    const data = {
      show: this.state.show,
      fields: this.state.fields,
      request: this.props.sampleResponse,
      sampleModalTable: this.state.sampleModalTable,
      samples: this.props.samples
    };

    const methods = {
      handleClose: this.handleClose,
      fieldChange: this.fieldChange,
      handleSubmitSample: this.handleSubmitSample,
      sampleModalFilters: this.sampleModalFilters,
      submitModalData: this.submitModalData
    };

    const EXAMS_TYPES = examTypes && examTypes.value ? examTypes.value : [];
    const submitData = this.submitData();

    return (
      <React.Fragment>
        <SampleModal data={data} methods={methods} />
        <PanelMaterial>
          <PanelMaterialTop title={"Exame x Material de Amostra"}>
            <ButtonDefaultMaterial
              title="Novo Material de Amostra"
              type="highlight"
              position="top"
              right={true}
              onClick={this.handleShow}
            />
          </PanelMaterialTop>
          <PanelMaterialBody>
            <Row>
              <SelectMaterial
                title="Tipo de Exame"
                md={5}
                name="examType"
                options={EXAMS_TYPES}
                value={this.state.fields.selectedExamType}
                searchable={true}
                onChange={event =>
                  this.fieldChangeExamType("selectedExamType", event)
                }
              />
            </Row>
            <Row className="pb-20">
              <Col md={12}>
                <ReactTable
                  filterable={config.filterable}
                  manual={config.manual}
                  pages={config.pages}
                  data={config.data}
                  ofText={config.ofText}
                  columns={config.columns}
                  loading={config.loading}
                  pageSize={config.pageSize}
                  rowsText={config.rowsText}
                  pageText={config.pageText}
                  sortable={config.sortable}
                  nextText={config.nextText}
                  resizable={config.resizable}
                  className={config.className}
                  noDataText={config.noDataText}
                  loadingText={config.loadingText}
                  previousText={config.previousText}
                  defaultSorted={config.defaultSorted}
                  showPagination={config.showPagination}
                  defaultPageSize={config.defaultPageSize}
                  showPageSizeOptions={config.showPageSizeOptions}
                />
              </Col>
            </Row>
          </PanelMaterialBody>
          <PanelMaterialBottom>
            <ButtonSaveMaterial
              type="highlight"
              title={submitData.btnTitle}
              right={true}
              onClick={() => this.handleSubmit(this.state.fields)}
              requesting={submitData.pending}
              disabled={submitData.pending}
              requestingTitle={submitData.placeholder}
            />
          </PanelMaterialBottom>
        </PanelMaterial>
      </React.Fragment>
    );
  }
}

export default connectWithEndpoint(ExamSampleList);
