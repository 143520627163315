import React from 'react';

/**
 * External
 */
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

/**
 * Internal
 */
import PanelMaterial from './../PanelMaterial';
import PanelMaterialBody from '../PanelMaterialBody';
import PanelMaterialTop from '../PanelMaterialTop';
import NumberInputWithReference2 from './../NumberInputWithReference2';

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const DoubleNumberInputWithReferencePanel2 = ( {
  panelTitle,
  firstTitle,
  firstName,
  firstValue,
  firstRefMin,
  firstRefMax,
  firstRefUnit,
  firstDecimalScale,
  firstValidationShow,
  firstValidationReason,
  secondTitle,
  secondName,
  secondValue,
  secondRefMin,
  secondRefMax,
  secondRefUnit,
  secondDecimalScale,
  secondValidationShow,
  secondValidationReason,
  disableAll,
  handleChange,
  handleBlur,
  firstSuffix,
  secondSuffix
} ) =>
  <React.Fragment>
    <PanelMaterial className='pb-20' flat={ true }>
      <PanelMaterialTop title={ panelTitle } />
      <PanelMaterialBody>
        <Col md={ 12 }>
          <NumberInputWithReference2
            fieldTitle={ firstTitle }
            value={ firstValue }
            fieldName={ firstName }
            shouldDisable={ disableAll }
            hasValidation={ firstValidationShow }
            validationReason={ firstValidationReason }
            handleChange={ handleChange }
            handleBlur={ handleBlur }
            valueMin={ firstRefMin }
            valueMax={ firstRefMax }
            decimalScale={ firstDecimalScale }
            unit={ firstRefUnit } 
            suffix={firstSuffix}
            />
        </Col>
        <Col md={ 12 }>
          <NumberInputWithReference2
            fieldTitle={ secondTitle }
            value={ secondValue }
            fieldName={ secondName }
            shouldDisable={ disableAll }
            hasValidation={ secondValidationShow }
            validationReason={ secondValidationReason }
            handleChange={ handleChange }
            handleBlur={ handleBlur }
            valueMin={ secondRefMin }
            valueMax={ secondRefMax }
            decimalScale={ secondDecimalScale }
            omitRefTitle={ true }
            unit={ secondRefUnit } 
            suffix={secondSuffix}
            />
        </Col>
      </PanelMaterialBody>
    </PanelMaterial>
  </React.Fragment>

DoubleNumberInputWithReferencePanel2.propTypes = propTypes;

export default DoubleNumberInputWithReferencePanel2;