import { generateUrl } from "./../../useful/url-util";
import {
  AnimalFilters,
  SpecieFilters,
  BreedFilters,
  Sortings,
  Pagination,
} from "./../../types/index";
import { EXAM_TYPES } from "../../consts";

const API_URL = process.env.REACT_APP_PROXY;

export const ROUTES = {
  getSpecies: (
    filters: SpecieFilters = {},
    sortings: Sortings = {},
    pagination: Pagination = { totalPerPage: 10, page: 0 }
  ) => generateUrl("species", filters, sortings, pagination),
  getBreeds: (
    filters: BreedFilters = {},
    sortings: Sortings = {},
    pagination: Pagination = { totalPerPage: 10, page: 0 }
  ) => generateUrl("breeds", filters, sortings, pagination),
  getAnimal: (animalId: number) => `${API_URL}/animals/${animalId}`,
  getAnimals: (
    filters: AnimalFilters = {},
    sortings: Sortings = {},
    pagination: Pagination = { totalPerPage: 7, page: 0 }
  ) => generateUrl("animals", filters, sortings, pagination),
  getRequest: (requestId: number) => `${API_URL}/exam-requests/${requestId}`,
  saveRequest: (requestId: number, isPartialSave = false) => {
    return requestId === 0
      ? `${API_URL}/exam-requests?isPartialSave=${isPartialSave}`
      : `${API_URL}/exam-requests/${requestId}?isPartialSave=${isPartialSave}`;
  },
  saveAnimal: (animalId: number) => {
    return animalId === 0
      ? `${API_URL}/animals`
      : `${API_URL}/animals/${animalId}`;
  },
  getAntibiotic: () => `${API_URL}/antibiotic-options`,
  saveAntibiotic: (antibioticId: string) => {
    return antibioticId
    ? `${API_URL}/antibiotic-options/${antibioticId}`
    : `${API_URL}/antibiotic-options`
  },
  createAntibiotic: () => `${API_URL}/antibiotic-options`,
  getExamTypes: () => `${API_URL}/exam-types`,
  getVetsByCustomer: (customerId: number) =>
    `${API_URL}/vets/customers/${customerId}`,
  getCustomersByLab: (labId: number) => `${API_URL}/customers/labs/${labId}`,
  getCustomer: (customerId: number) => `${API_URL}/customers/${customerId}`,
  getExamsByTypeId: (typeId: number) => `${API_URL}/exams?type=${typeId}`,
  getLabExamsByCustomerAndExamType: (customerId: number, examTypeId: number) =>
    `${API_URL}/lab-exams/customer/${customerId}/exam-type/${examTypeId}`,
  getExams: () => `${API_URL}/exams`,
  getExamPrintedReport: (reportId: number) =>
    `${API_URL}/print/report/${reportId}`,
  getRequestPrintedReport: (requestId: number) =>
    `${API_URL}/exam-requests/${requestId}/print`,
  mailRequest: (requestId: number) =>
    `${API_URL}/mail/print-reports/${requestId}`,
  cancelRequestExam: (reportId: number) =>
    `${API_URL}/reports/${reportId}/cancel`,
  receiveAndAssistSample: (sampleCode: string) =>
    `${API_URL}/exam-request/request-sample/code/${sampleCode}`,

  getReportHeader: (id: number) =>
    `${API_URL}/report/request-exams/${id}/report-header`,
  getReportById: (id: number) => `${API_URL}/reports/${id}`,
  getRefs: (requestExamId: number, examTypeId: number) =>
    `${API_URL}/reference-values/?requestExamId=${requestExamId}&examTypeId=${examTypeId}`,
  getBiochemistryExams: (examRequestId: string) =>
    `${API_URL}/biochemistry/request-exams/${examRequestId}`,
  getBiochemistryReports: (examRequestId: string) =>
    `${API_URL}/biochemistry/reports/${examRequestId}`,
  getBiochemistryReferences: (examRequestId: string) =>
    `${API_URL}/reference-values/?examRequestId=${examRequestId}&examTypeId=${EXAM_TYPES.BIOCHEMISTRY}`,
  getReport: (requestExamId: number) => `${API_URL}/reports/${requestExamId}`,

  getRegions: () => `${API_URL}/customers/lab/regions`,
  getCustomers: () => `${API_URL}/customers/all`,
  emailRecords: (queryParams: string | undefined) =>
    `${API_URL}/emails/records${queryParams}`,
  getExamRequestStates: () => `${API_URL}/exam-request-states`,
};
