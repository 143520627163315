import connect from "../../api-connector";

const connectWithEndpoint = connect(props => {
  const examRequestId = props.match.params.examRequestId;
  const examTypeId = props.examTypeId;

  return {
    requestExamStates: `/request-exam-states`,
    examTableReportHeaderPromise: `/report/request-exams/${examRequestId}/exam-table-report-header/${examTypeId}`,
    getRequestExamsWithExamRequestId: then => ({
      requestExamsWithExamRequestId: {
        url: `/biochemistry/request-exams/${examRequestId}`,
        then: then
      }
    }),
    post: (options, release, then) => ({
      submitPromise: {
        url: `/reports/biochemistry/${examRequestId}?release=${release}`,
        method: "POST",
        force: true,
        body: JSON.stringify(options),
        then: then
      }
    }),
    cancelReport: (options, then) => ({
      submitPromise: {
        url: `/reports/biochemistry/${examRequestId}/cancel`,
        method: "PUT",
        force: true,
        body: JSON.stringify(options),
        then: then
      }
    }),
    stopReport: (options, then) => ({
      submitPromise: {
        url: `/reports/biochemistry/${examRequestId}/stop`,
        method: "PUT",
        force: true,
        body: JSON.stringify(options),
        then: then
      }
    }),
    withExamRequestId: (id, then) => ({
      obj: {
        url: `/reports/exam-requests/${id}`,
        then: then
      }
    })
  };
});

export default connectWithEndpoint;
