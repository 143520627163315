import React from "react";

/** Internal */
import { Col, Row } from "./../../components/grid";
import ReportHeader from "./../ReportHeader";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import DefaultMicrobiologyContent from "./../DefaultMicrobiologyContent";
import TextAreaPanel2 from "./../TextAreaPanel2";

const DefaultMicrobiologyReportFormView = ({
  data: {
    title,
    isUpdate,
    fields,
    backLink,
    disableAll,
    $validation,
    antibioticOptions,
    submitPromise,
    reportHeaderData,
    shouldSendEmail,
    negativeMessage
  },
  methods: {
    fieldChange,
    prepareAntibioticTagsSuggestionFilter,
    handleChange,
    handleBlur,
    onSubmit,
    $field,
    handleSendReportEmail,
    toggleIsNegative,
    cancelReport,
    stopReport
  }
}) => (
  <ReportPanelMaterial
    title={title}
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <DefaultMicrobiologyContent
          materialFieldName="material"
          material={fields.material}
          materialHasValidation={$validation.material.show}
          materialValidationReason={$validation.material.error.reason}
          isNegativeFieldName="isNegative"
          isNegative={fields.isNegative}
          resultFieldName="result"
          result={fields.result}
          resultHasValidation={$validation.result && $validation.result.show}
          resultValidationReason={
            $validation.result && $validation.result.error.reason
          }
          antibioticOptions={antibioticOptions}
          sensitiveTagsFieldName={"sensitiveAntibioticTags"}
          sensitiveTags={fields.sensitiveAntibioticTags}
          sensitiveHasValidation={
            $validation.sensitiveAntibioticTags &&
            $validation.sensitiveAntibioticTags.show
          }
          sensitiveValidationReason={
            $validation.sensitiveAntibioticTags &&
            $validation.sensitiveAntibioticTags.error.reason
          }
          intermediateTagsFieldName={"intermediateAntibioticTags"}
          intermediateTags={fields.intermediateAntibioticTags}
          intermediateHasValidation={
            $validation.intermediateAntibioticTags &&
            $validation.intermediateAntibioticTags.show
          }
          intermediateValidationReason={
            $validation.intermediateAntibioticTags &&
            $validation.intermediateAntibioticTags.error.reason
          }
          resistantTagsFieldName={"resistantAntibioticTags"}
          resistantTags={fields.resistantAntibioticTags}
          resistantHasValidation={
            $validation.resistantAntibioticTags &&
            $validation.resistantAntibioticTags.show
          }
          resistantValidationReason={
            $validation.resistantAntibioticTags &&
            $validation.resistantAntibioticTags.error.reason
          }
          obsFieldName="obs"
          obs={fields.isNegative ? "" : fields.obs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
          toggleIsNegative={toggleIsNegative}
          suggestionsFilter={prepareAntibioticTagsSuggestionFilter(
            fields.sensitiveAntibioticTags,
            fields.intermediateAntibioticTags,
            fields.resistantAntibioticTags
          )}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <TextAreaPanel2
          title={"Obs"}
          fieldTitle={""}
          name={"obs"}
          value={fields.obs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default DefaultMicrobiologyReportFormView;
