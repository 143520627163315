import React from 'react';

/**
 * Internal
 */
import PhysicalExamFields from './../PhysicalExamFields';
import ChemicalExamFields from './../ChemicalExamFields';
import SedimentoscopyFields from './../SedimentoscopyFields';
import TextAreaPanel2 from './../TextAreaPanel2';
import { Col, Row } from './../../components/grid';

const EasContent = ( {
  volumeFieldName,
  volume,
  volumeHasValidation,
  volumeValidationReason,
  densityFieldName,
  density,
  densityHasValidation,
  densityValidationReason,
  densityRefs,
  colorOptionIdFieldName,
  colorOptionId,
  isColorOptionIdLoading,
  colorOptionIdHasValidation,
  colorOptionIdValidationReason,
  colorOptions,
  smellOptionIdFieldName,
  smellOptionId,
  isSmellOptionIdLoading,
  smellOptionIdHasValidation,
  smellOptionIdValidationReason,
  smellOptions,
  aspectOptionIdFieldName,
  aspectOptionId,
  isAspectOptionIdLoading,
  aspectOptionIdHasValidation,
  aspectOptionIdValidationReason,
  aspectOptions,
  proteinsOptionIdFieldName,
  proteinsOptionId,
  proteinsOptionIdHasValidation,
  proteinsOptionIdValidationReason,
  proteinsOptions,
  proteinsRefs,
  glucoseOptionIdFieldName,
  glucoseOptionId,
  glucoseOptionIdHasValidation,
  glucoseOptionIdValidationReason,
  glucoseOptions,
  glucoseRefs,
  acetoneOptionIdFieldName,
  acetoneOptionId,
  acetoneOptionIdHasValidation,
  acetoneOptionIdValidationReason,
  acetoneOptions,
  acetoneRefs,
  phFieldName,
  ph,
  phHasValidation,
  phValidationReason,
  phRefs,
  bilirubinOptionIdFieldName,
  bilirubinOptionId,
  bilirubinOptionIdHasValidation,
  bilirubinOptionIdValidationReason,
  bilirubinOptions,
  bilirubinRefs,
  urobilinogenOptionIdFieldName,
  urobilinogenOptionId,
  urobilinogenOptionIdHasValidation,
  urobilinogenOptionIdValidationReason,
  urobilinogenOptions,
  urobilinogenRefs,
  hemoglobinOptionIdFieldName,
  hemoglobinOptionId,
  hemoglobinOptionIdHasValidation,
  hemoglobinOptionIdValidationReason,
  hemoglobinOptions,
  hemoglobinRefs,
  leukocyteOptionIdFieldName,
  leukocyteOptionId,
  leukocyteOptionIdHasValidation,
  leukocyteOptionIdValidationReason,
  leukocyteOptions,
  leukocyteRefs,
  nitriteOptionIdFieldName,
  nitriteOptionId,
  nitriteOptionIdHasValidation,
  nitriteOptionIdValidationReason,
  nitriteOptions,
  nitriteRefs,
  epithelialCellsOptionTagsFieldName,
  epithelialCellsOptionTags,
  epithelialCellsOptionTagsSuggestions,
  epithelialCellsOptionTagsHasValidation,
  epithelialCellsOptionTagsValidationReason,
  epithelialCellsRefs,
  erythrocytesMinFieldName,
  erythrocytesMin,
  erythrocytesMaxFieldName,
  erythrocytesMax,
  erythrocytesHasValidation,
  erythrocytesValidationReason,
  isErythrocytesAbsentFieldName,
  isErythrocytesAbsent,
  isErythrocytesUncountableFieldName,
  isErythrocytesUncountable,
  erythrocytesRefs,
  pusCellsMinFieldName,
  pusCellsMin,
  pusCellsMaxFieldName,
  pusCellsMax,
  pusCellsHasValidation,
  pusCellsValidationReason,
  isPusCellsAbsentFieldName,
  isPusCellsAbsent,
  isPusCellsUncountableFieldName,
  isPusCellsUncountable,
  pusCellsRefs,
  ridgesOptionTagsFieldName,
  ridgesOptionTags,
  ridgesOptionTagsSuggestions,
  ridgesOptionTagsHasValidation,
  ridgesOptionTagsValidationReason,
  ridgesOptionRefs,
  castsOptionTagsFieldName,
  castsOptionTags,
  castsOptionTagsSuggestions,
  castsOptionTagsHasValidation,
  castsOptionTagsValidationReason,
  castsOptionRefs,
  bacterialFloraOptionIdFieldName,
  bacterialFloraOptionId,
  bacterialFloraOptionIdHasValidation,
  bacterialFloraOptionIdValidationReason,
  bacterialFloraOptions,
  bacterialFloraOptionRefs,
  spermatozoaOptionIdFieldName,
  spermatozoaOptionId,
  spermatozoaOptionIdHasValidation,
  spermatozoaOptionIdValidationReason,
  spermatozoaOptions,
  spermatozoaOptionRefs,
  obsFieldName,
  obs,
  suggestionsFilter,
  handleChange,
  handleBlur,
  handleToggle,
  shouldDisable
} ) =>
  <React.Fragment>
    <Row>
      <Col md={ 12 }>
        <PhysicalExamFields
          volumeFieldName={ volumeFieldName }
          volume={ volume }
          volumeHasValidation={ volumeHasValidation }
          volumeValidationReason={ volumeValidationReason }
          densityFieldName={ densityFieldName }
          density={ density }
          densityHasValidation={ densityHasValidation }
          densityValidationReason={ densityValidationReason }
          densityRefs={ densityRefs }
          colorOptionIdFieldName={ colorOptionIdFieldName }
          colorOptionId={ colorOptionId }
          isColorOptionIdLoading={ isColorOptionIdLoading }
          colorOptionIdHasValidation={ colorOptionIdHasValidation }
          colorOptionIdValidationReason={ colorOptionIdValidationReason }
          colorOptions={ colorOptions }
          smellOptionIdFieldName={ smellOptionIdFieldName }
          smellOptionId={ smellOptionId }
          isSmellOptionIdLoading={ isSmellOptionIdLoading }
          smellOptionIdHasValidation={ smellOptionIdHasValidation }
          smellOptionIdValidationReason={ smellOptionIdValidationReason }
          smellOptions={ smellOptions }
          aspectOptionIdFieldName={ aspectOptionIdFieldName }
          aspectOptionId={ aspectOptionId }
          isAspectOptionIdLoading={ isAspectOptionIdLoading }
          aspectOptionIdHasValidation={ aspectOptionIdHasValidation }
          aspectOptionIdValidationReason={ aspectOptionIdValidationReason }
          aspectOptions={ aspectOptions }
          handleChange={ handleChange }
          handleBlur={ handleBlur }
          shouldDisable={ shouldDisable } />
      </Col>
    </Row>
    <Row>
      <Col md={ 5 }>
        <ChemicalExamFields
          proteinsOptionIdFieldName={ proteinsOptionIdFieldName }
          proteinsOptionId={ proteinsOptionId }
          proteinsOptionIdHasValidation={ proteinsOptionIdHasValidation }
          proteinsOptionIdValidationReason={ proteinsOptionIdValidationReason }
          proteinsOptions={ proteinsOptions }
          proteinsRefs={ proteinsRefs }
          glucoseOptionIdFieldName={ glucoseOptionIdFieldName }
          glucoseOptionId={ glucoseOptionId }
          glucoseOptionIdHasValidation={ glucoseOptionIdHasValidation }
          glucoseOptionIdValidationReason={ glucoseOptionIdValidationReason }
          glucoseOptions={ glucoseOptions }
          glucoseRefs={ glucoseRefs }
          acetoneOptionIdFieldName={ acetoneOptionIdFieldName }
          acetoneOptionId={ acetoneOptionId }
          acetoneOptionIdHasValidation={ acetoneOptionIdHasValidation }
          acetoneOptionIdValidationReason={ acetoneOptionIdValidationReason }
          acetoneOptions={ acetoneOptions }
          acetoneRefs={ acetoneRefs }
          phFieldName={ phFieldName }
          ph={ ph }
          phHasValidation={ phHasValidation }
          phValidationReason={ phValidationReason }
          phRefs={ phRefs }
          bilirubinOptionIdFieldName={ bilirubinOptionIdFieldName }
          bilirubinOptionId={ bilirubinOptionId }
          bilirubinOptionIdHasValidation={ bilirubinOptionIdHasValidation }
          bilirubinOptionIdValidationReason={ bilirubinOptionIdValidationReason }
          bilirubinOptions={ bilirubinOptions }
          bilirubinRefs={ bilirubinRefs }
          urobilinogenOptionIdFieldName={ urobilinogenOptionIdFieldName }
          urobilinogenOptionId={ urobilinogenOptionId }
          urobilinogenOptionIdHasValidation={ urobilinogenOptionIdHasValidation }
          urobilinogenOptionIdValidationReason={ urobilinogenOptionIdValidationReason }
          urobilinogenOptions={ urobilinogenOptions }
          urobilinogenRefs={ urobilinogenRefs }
          hemoglobinOptionIdFieldName={ hemoglobinOptionIdFieldName }
          hemoglobinOptionId={ hemoglobinOptionId }
          hemoglobinOptionIdHasValidation={ hemoglobinOptionIdHasValidation }
          hemoglobinOptionIdValidationReason={ hemoglobinOptionIdValidationReason }
          hemoglobinOptions={ hemoglobinOptions }
          hemoglobinRefs={ hemoglobinRefs }
          leukocyteOptionIdFieldName={ leukocyteOptionIdFieldName }
          leukocyteOptionId={ leukocyteOptionId }
          leukocyteOptionIdHasValidation={ leukocyteOptionIdHasValidation }
          leukocyteOptionIdValidationReason={ leukocyteOptionIdValidationReason }
          leukocyteOptions={ leukocyteOptions }
          leukocyteRefs={ leukocyteRefs }
          nitriteOptionIdFieldName={ nitriteOptionIdFieldName }
          nitriteOptionId={ nitriteOptionId }
          nitriteOptionIdHasValidation={ nitriteOptionIdHasValidation }
          nitriteOptionIdValidationReason={ nitriteOptionIdValidationReason }
          nitriteOptions={ nitriteOptions }
          nitriteRefs={ nitriteRefs }
          handleChange={ handleChange }
          handleBlur={ handleBlur }
          shouldDisable={ shouldDisable } />
      </Col>
      <Col md={ 7 }>
        <SedimentoscopyFields
          epithelialCellsOptionTagsFieldName={ epithelialCellsOptionTagsFieldName }
          epithelialCellsOptionTags={ epithelialCellsOptionTags }
          epithelialCellsOptionTagsSuggestions={ epithelialCellsOptionTagsSuggestions }
          epithelialCellsOptionTagsHasValidation={ epithelialCellsOptionTagsHasValidation }
          epithelialCellsOptionTagsValidationReason={ epithelialCellsOptionTagsValidationReason }
          epithelialCellsRefs={ epithelialCellsRefs }
          erythrocytesMinFieldName={ erythrocytesMinFieldName }
          erythrocytesMin={ erythrocytesMin }
          erythrocytesMaxFieldName={ erythrocytesMaxFieldName }
          erythrocytesMax={ erythrocytesMax }
          erythrocytesHasValidation={ erythrocytesHasValidation }
          erythrocytesValidationReason={ erythrocytesValidationReason }
          isErythrocytesAbsentFieldName={ isErythrocytesAbsentFieldName }
          isErythrocytesAbsent={ isErythrocytesAbsent }
          isErythrocytesUncountableFieldName={ isErythrocytesUncountableFieldName }
          isErythrocytesUncountable={ isErythrocytesUncountable }
          erythrocytesRefs={ erythrocytesRefs }
          pusCellsMinFieldName={ pusCellsMinFieldName }
          pusCellsMin={ pusCellsMin }
          pusCellsMaxFieldName={ pusCellsMaxFieldName }
          pusCellsMax={ pusCellsMax }
          pusCellsHasValidation={ pusCellsHasValidation }
          pusCellsValidationReason={ pusCellsValidationReason }
          isPusCellsAbsentFieldName={ isPusCellsAbsentFieldName }
          isPusCellsAbsent={ isPusCellsAbsent }
          isPusCellsUncountableFieldName={ isPusCellsUncountableFieldName }
          isPusCellsUncountable={ isPusCellsUncountable }
          pusCellsRefs={ pusCellsRefs }
          ridgesOptionTagsFieldName={ ridgesOptionTagsFieldName }
          ridgesOptionTags={ ridgesOptionTags }
          ridgesOptionTagsSuggestions={ ridgesOptionTagsSuggestions }
          ridgesOptionTagsHasValidation={ ridgesOptionTagsHasValidation }
          ridgesOptionTagsValidationReason={ ridgesOptionTagsValidationReason }
          ridgesOptionRefs={ ridgesOptionRefs }
          castsOptionTagsFieldName={ castsOptionTagsFieldName }
          castsOptionTags={ castsOptionTags }
          castsOptionTagsSuggestions={ castsOptionTagsSuggestions }
          castsOptionTagsHasValidation={ castsOptionTagsHasValidation }
          castsOptionTagsValidationReason={ castsOptionTagsValidationReason }
          castsOptionRefs={ castsOptionRefs }
          bacterialFloraOptionIdFieldName={ bacterialFloraOptionIdFieldName }
          bacterialFloraOptionId={ bacterialFloraOptionId }
          bacterialFloraOptionIdHasValidation={ bacterialFloraOptionIdHasValidation }
          bacterialFloraOptionIdValidationReason={ bacterialFloraOptionIdValidationReason }
          bacterialFloraOptions={ bacterialFloraOptions }
          bacterialFloraOptionRefs={ bacterialFloraOptionRefs }
          spermatozoaOptionIdFieldName={ spermatozoaOptionIdFieldName }
          spermatozoaOptionId={ spermatozoaOptionId }
          spermatozoaOptionIdHasValidation={ spermatozoaOptionIdHasValidation }
          spermatozoaOptionIdValidationReason={ spermatozoaOptionIdValidationReason }
          spermatozoaOptions={ spermatozoaOptions }
          spermatozoaOptionRefs={ spermatozoaOptionRefs }
          suggestionsFilter={ suggestionsFilter }
          handleToggle={ handleToggle }
          handleChange={ handleChange }
          handleBlur={ handleBlur }
          shouldDisable={ shouldDisable } />
      </Col>
    </Row>
    <Row>
      <Col md={ 12 }>
        <TextAreaPanel2
          title={ 'Obs' }
          fieldTitle={ '' }
          name={ obsFieldName }
          value={ obs }
          shouldDisable={ shouldDisable }
          handleChange={ handleChange }
          handleBlur={ handleBlur } />
      </Col>
    </Row>
  </React.Fragment>

export default EasContent;