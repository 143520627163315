import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import BloodProteinPanel from "./../BloodProteinPanel";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col } from "./../../components/grid";

const BloodProteinReportFormView = ({
  data: {
    refs,
    isUpdate,
    fields,
    backLink,
    disableAll,
    $validation,
    submitData,
    reportHeaderData,
    shouldSendEmail
  },
  methods: {
    fieldChange,
    onSubmit,
    handleSendReportEmail,
    $field,
    cancelReport,
    stopReport
  }
}) => (
  <ReportPanelMaterial
    title="Proteínas Plasmáticas"
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitData.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Col md={12}>
      <ReportHeader
        data={reportHeaderData}
        fields={fields}
        fieldChange={fieldChange}
        $field={$field}
        $validation={$validation}
      />
    </Col>
    <Col md={12}>
      <BloodProteinPanel
        panelTitle="Resultado"
        result={fields.result}
        refMin={refs.bloodProteinMin}
        refMax={refs.bloodProteinMax}
        disableAll={disableAll}
        fieldChange={fieldChange}
        $field={$field}
        hasValidation={$validation.result.show}
        validationReason={$validation.result.error.reason}
      />
    </Col>
  </ReportPanelMaterial>
);

export default BloodProteinReportFormView;
