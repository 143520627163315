import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import {
  required,
  greaterThan
  } from '../../assets/js/validationFunctions';

import {
  commonReportRules,
  plateletsRules
  } from '../../assets/js/validationRules';

const validation = validated( props => {

  const {
    platelets,
    plateletsObs,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const {completeValidation} = props.data;

  let fields = [
    'platelets',
    'plateletsObs',
    'requestExamState',
    'vetSignerId'
  ];

  const commonValidation = commonReportRules(
    vetSignerId, requestExamState );

  const plateletsValidation =
    plateletsRules(
      platelets,
      plateletsObs,
      completeValidation
      );

  const validations = {
    ...commonValidation,
    ...plateletsValidation
    };

  return { fields, validations };

} );

export default validation;