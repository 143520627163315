/* eslint-disable no-unused-vars */
import React from "react";
import { Switch, Route } from "react-router-dom";

import TicketsList from "./list";
import TicketsForm from "./form";
import NotFound from "./../../containers/NotFound";

const Tickets = (routeProps) => (
  <Switch>
    <Route
      exact
      path={"/tickets"}
      component={() => <TicketsList {...routeProps} />}
    />
    <Route
      exact
      path={"/tickets/novo"}
      component={() => <TicketsForm {...routeProps} />}
    />
    <Route
      exact
      path={"/tickets/:id"}
      component={() => <TicketsForm {...routeProps} />}
    />
    <Route component={NotFound} />
  </Switch>
);

export default Tickets;
