const isEmptyField = (value) => {
    return (
      value === "" ||
      value === "." ||
      value === "," ||
      value === undefined ||
      value === null
    );
  };

  export {
      isEmptyField
  };