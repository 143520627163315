export const initialData = {
  titles: {
    chemiluminescenceCortisol: "Quimioluminescência Cortisol",
    chemiluminescenceCortisol4HAfterActhStimuli:
      "Quimioluminescência Cortisol Após Estímulo com ACTH ",
    chemiluminescenceCortisol4HAfterDexamethasone:
      "Quimioluminescência Cortisol 4h Após Dexametasona ",
    chemiluminescenceCortisol8HAfterDexamethasone:
      "Quimioluminescência Cortisol 8h Após Dexametasona ",
    chemiluminescenceErythropoetin: "Quimioluminescência Eritropoetina",
    chemiluminescenceEstradiol: "Quimioluminescência Estradiol",
    chemiluminescenceFreeT4: "Quimioluminescência T4 Livre",
    chemiluminescenceHfs:
      "Quimioluminescência FSH (Hormônio Folículo Estimulante)",
    chemiluminescenceHypersensitiveActh:
      "Quimioluminescência ACTH Hipersensível",
    chemiluminescenceInsulin: "Quimioluminescência Insulina",
    chemiluminescenceLevothyroxinAfterTotalT4:
      "Quimioluminescência T4 Total Pós Levotiroxina",
    chemiluminescenceLh: "Quimioluminescência LH (Hormônio Luteinizante)",
    chemiluminescencePhenobarbital: "Quimioluminescência Fenobarbital",
    chemiluminescenceProgesterone: "Quimioluminescência Progesterona",
    chemiluminescencePth: "Quimioluminescência PTH (Paratormônio)",
    chemiluminescenceTestosterone: "Quimioluminescência Testosterona",
    chemiluminescenceTotalT3: "Quimioluminescência T3 Total",
    chemiluminescenceTotalT4: "Quimioluminescência T4 Total",
    chemiluminescenceTsh: "Quimioluminescência TSH",
  },
  fields: {
    chemiluminescenceCortisolMin: "",
    chemiluminescenceCortisolMax: "",
    chemiluminescenceCortisolUnit: "",
    chemiluminescenceCortisol4HAfterActhStimuliMin: "",
    chemiluminescenceCortisol4HAfterActhStimuliMax: "",
    chemiluminescenceCortisol4HAfterActhStimuliUnit: "",
    chemiluminescenceCortisol4HAfterDexamethasoneMin: "",
    chemiluminescenceCortisol4HAfterDexamethasoneMax: "",
    chemiluminescenceCortisol4HAfterDexamethasoneUnit: "",
    chemiluminescenceCortisol8HAfterDexamethasoneMin: "",
    chemiluminescenceCortisol8HAfterDexamethasoneMax: "",
    chemiluminescenceCortisol8HAfterDexamethasoneUnit: "",
    chemiluminescenceErythropoetinMin: "",
    chemiluminescenceErythropoetinMax: "",
    chemiluminescenceErythropoetinUnit: "",
    chemiluminescenceEstradiolMin: "",
    chemiluminescenceEstradiolMax: "",
    chemiluminescenceEstradiolUnit: "",
    chemiluminescenceFreeT4Min: "",
    chemiluminescenceFreeT4Max: "",
    chemiluminescenceFreeT4Unit: "",
    chemiluminescenceHfsMin: "",
    chemiluminescenceHfsMax: "",
    chemiluminescenceHfsUnit: "",
    chemiluminescenceHypersensitiveActhMin: "",
    chemiluminescenceHypersensitiveActhMax: "",
    chemiluminescenceHypersensitiveActhUnit: "",
    chemiluminescenceInsulinMin: "",
    chemiluminescenceInsulinMax: "",
    chemiluminescenceInsulinUnit: "",
    chemiluminescenceLevothyroxinAfterTotalT4Min: "",
    chemiluminescenceLevothyroxinAfterTotalT4Max: "",
    chemiluminescenceLevothyroxinAfterTotalT4Unit: "",
    chemiluminescenceLhMin: "",
    chemiluminescenceLhMax: "",
    chemiluminescenceLhUnit: "",
    chemiluminescencePhenobarbitalMin: "",
    chemiluminescencePhenobarbitalMax: "",
    chemiluminescencePhenobarbitalUnit: "",
    chemiluminescenceProgesteroneMin: "",
    chemiluminescenceProgesteroneMax: "",
    chemiluminescenceProgesteroneUnit: "",
    chemiluminescencePthMin: "",
    chemiluminescencePthMax: "",
    chemiluminescencePthUnit: "",
    chemiluminescenceTestosteroneMin: "",
    chemiluminescenceTestosteroneMax: "",
    chemiluminescenceTestosteroneUnit: "",
    chemiluminescenceTotalT3Min: "",
    chemiluminescenceTotalT3Max: "",
    chemiluminescenceTotalT3Unit: "",
    chemiluminescenceTotalT4Min: "",
    chemiluminescenceTotalT4Max: "",
    chemiluminescenceTotalT4Unit: "",
    chemiluminescenceTshMin: "",
    chemiluminescenceTshMax: "",
    chemiluminescenceTshUnit: "",
  },
};
