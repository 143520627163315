import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import PlateletsDiagnosisPanel from "./../PlateletsDiagnosisPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";
import PlateletsPanelWithCounter from "../PlateletsPanelWithCounter";

const PlateletsReportFormReportFormView = ({
  data: {
    refs,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    shouldSendEmail
  },
  methods: {
    $field,
    fieldChange,
    plateletsDiagnosis,
    onSubmit,
    handleSendReportEmail,
    cancelReport,
    stopReport
  }
}) => (
  <ReportPanelMaterial
    title="Contagem de Plaquetas"
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <PlateletsPanelWithCounter
          panelTitle={"Resultado"}
          fieldTitle={"Contagem de Plaquetas"}
          fieldName={"platelets"}
          value={fields.platelets}
          disableAll={disableAll}
          $validation={$validation}
          fieldChange={fieldChange}
          $field={$field}
          valueMin={refs.plateletsMin}
          valueMax={refs.plateletsMax}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <PlateletsDiagnosisPanel
          title={"Diagnóstico"}
          plateletsObs={fields.plateletsObs}
          shouldDisable={disableAll}
          $validation={$validation}
          fieldChange={fieldChange}
          diagnosisFn={plateletsDiagnosis}
          $field={$field}
          plateletsMin={refs.plateletsMin}
          plateletsMax={refs.plateletsMax}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default PlateletsReportFormReportFormView;
