import React, { Component } from 'react';

/**
 * External
 */
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

/**
 * Internal
 */
import BreedList from './../BreedList';
import BreedForm from './../BreedForm';
import NotFound from './../NotFound';

class BreedSection extends Component {

  constructor( props ) {
    super( props );
    this.renderList = this.renderList.bind( this );
    this.renderForm = this.renderForm.bind( this );
  }

  /**
   * @author Victor Heringer
   *
   * Renders the breed list
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderList( routeProps ) {
    return (
      <BreedList 
        { ...routeProps } 
      />
    );
  }

  /**
     * @author Victor Heringer
     *
     * Renders the breed form
     *
     * @param  {Object} routeProps Props for the route
     *
     * @return {Object}
     */
  renderForm( routeProps ) {

    return (
      <BreedForm
        { ...routeProps }
        notificationSystem={ this.props.notificationSystem }
      />
    );

  }

  render() {

    return (
      <Switch>
        <Route
          exact
          path={ '/racas' }
          render={ this.renderList } />
        <Route
          path={ '/racas/nova' }
          render={ this.renderForm } />
        <Route
          path={ '/racas/:id' }
          render={ this.renderForm } />
        <Route component={ NotFound } />
      </Switch>
    );

  }
}

const mapStateToProps = function ( state ) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user,
  }
}

export default connect( mapStateToProps )( BreedSection );