export const values = [
  { id: "local", name: "Local" },
  { id: "staging", name: "Staging" },
  { id: "production", name: "Production" },
];

export const LOCAL = "local";
export const STAGING = "staging";
export const PRODUCTION = "production";

export const toString = (id) => {
  switch (id) {
    case "local":
      return "Local";
    case "staging":
      return "Staging";
    case "production":
      return "Production";
    default:
      return "Tipo de ambiente de desenvolvimento inválido.";
  }
};
