export const initialData = {
  titles: {
    thoracicDensity: "Densidade",
    asciticDensity: "Densidade",
    liquorDensity: "Densidade",
    liquorPh: "PH",
    liquorProteins: "Proteínas",
    liquorGlucose: "Glicose",
    liquorLeukocytes: "Leucócitos",
    
  },
  fields: {
    thoracicDensityMin: "",
    thoracicDensityMax: "",
    asciticDensityMin: "",
    asciticDensityMax: "",
    liquorDensityMin: "",
    liquorDensityMax: "",
    liquorPhMin: "",
    liquorPhMax: "",
    liquorProteinsMin: "",
    liquorProteinsMax: "",
    liquorGlucoseMin: "",
    liquorGlucoseMax: "",
    liquorLeukocytesMin: "",
    liquorLeukocytesMax: "",
    
  },
};
