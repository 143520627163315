/**
 * Internal
 */
import { REQUEST_EXAM_STATE_ID_BY_NAME } from "../../consts/request-exam-states.js";

export const containerState = () => {
  return {
    redirectToList: false,
  };
};

export const reportState = () => ({
  reportDataId: 0,
  vetSignerId: 0,
  requestExamState: REQUEST_EXAM_STATE_ID_BY_NAME["RECEBIDO_EM_ANDAMENTO"],
});

export const erythrogramState = () => ({
  erythrocytes: "",
  corpuscularVolume: "",
  hemoglobiometry: "",
  erythrogramObs: "",
});

export const leukogramState = () => ({
  globalLeukometry: "",
  isIntenseLeucopeny: false,
  metarrubrycites: "",
  relativeBasophils: "",
  relativeEosinophils: "",
  relativeMyelocytes: "",
  relativeMetamyelocytes: "",
  relativeBands: "",
  relativeSegs: "",
  relativeLymphocytes: "",
  relativeMonocytes: "",
  relativeHeterophils: "",
  leukogramObs: "",
  isCounterOpen: false,
  shouldBlockCounterSound: false,
});

export const plateletsState = () => ({
  platelets: "",
  plateletsObs: "",
});

export const hemogramState = () => ({
  ...erythrogramState(),
  ...leukogramState(),
  ...plateletsState(),
  hemogramObs: "",
});

export const microfilariaeResearchState = () => ({
  microfilariaeResearchResult: "",
  microfilariaeResearchObs: "",
});

export const hemocytozoaResearchState = () => ({
  hemocytozoaResearchResult: "",
  hemocytozoaResearchObs: "",
});
