import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import NumberInputMaterial2 from "./../../containers/NumberInputMaterial2";
import SelectMaterial from "../SelectMaterial";
import NumberDisplay from "./../NumberDisplay";
import deadlineToString from "./../../assets/js/deadlineToString.js";
import moment from "../../assets/js/moment.js";
import LVTable from "./../../old_components/LVTable";
import { getDecimalPrecisionByExamId } from "./../../consts";
import { Col } from "./../../components/grid";
import InputExam from "../../components/input-exam/index.js";
import InputValidationMsg from "../InputValidationMsg";
/**
 * External
 */
import styles from "./index.module.css";

const renderInput = (
  title,
  name,
  md,
  $field,
  fieldChange,
  value,
  disabled,
  hasValidation,
  validationReason,
  decimalPrecision
) => (
  <div className="text-left">
    
    {/* <NumberInputMaterial2
      title={title}
      name={name}
      allowNegative={false}
      isNumericString={true}
      decimalScale={decimalPrecision}
      handleChange={fieldChange}
      value={value}
      disabled={disabled}
      {...$field(name)}
      hasValidation={hasValidation}
      validationReason={validationReason}
    /> */}

<Col md={md}>
        {title && <label>{title}</label>}
        <InputExam
          title={title}
          name={name}
          md={md}
          block={true}
          decimalScale={decimalPrecision}//decimalScale={isInteger ? 0 : decimalPlates}
          handleChange={fieldChange}
          value={value}
          disabled={disabled}
          //{...validationHandlers}
        />
        <InputValidationMsg
          visible={hasValidation}
          message={validationReason}
        />
      </Col>




  </div>
);
const renderRef = (row) => {
  const { refMin, refMax, refUnit } = row.original;

  return (
    <Col md={12} className="mt-10">
      <p className="text-center">
        <NumberDisplay value={refMin} decimalScale={1} suffix={` - `} />
        <NumberDisplay value={refMax} decimalScale={1} suffix={` ${refUnit}`} />
      </p>
    </Col>
  );
};

const propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  requestExamStates: PropTypes.array.isRequired,
  handleStateChange: PropTypes.func.isRequired,
  resultChange: PropTypes.func.isRequired,
  shouldDisable: PropTypes.bool.isRequired,
  $field: PropTypes.func.isRequired,
  $validation: PropTypes.object.isRequired,
  shouldUseReducedVersion: PropTypes.bool,
};

const defaultProps = {
  title: "",
  shouldUseReducedVersion: false,
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Default exam table
 */
class DefaultExamTable extends Component {
  constructor(props) {
    super(props);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Renders the report result field
   *
   * @param {Object} data Number input material component
   *
   * @return {Object}
   *
   */
  renderResultField = (row) => {
    const index = row.index.toString();
    const name = `result-${index}`;
    const examId = row.original.examId;
    const md = 12;
    const decimalPrecision = getDecimalPrecisionByExamId(examId);

    return renderInput(
      "",
      name,
      md,
      this.props.$field,
      this.props.resultChange,
      row.original.result,
      this.props.shouldDisable,
      this.props.$validation[name].show,
      this.props.$validation[name].error.reason,
      decimalPrecision
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * Renders the report result field
   *
   * @param {Object} data Number input material component
   *
   * @return {Object}
   *
   */
  renderStatusField = (row) => {
    const index = row.index.toString();
    const requestExamState = row.original.requestExamState;
    const name = `result-${index}`;

    return (
      <SelectMaterial
        name={name}
        md={12}
        options={this.props.requestExamStates}
        value={requestExamState}
        searchable={true}
        fit
        tabIndex={-1}
        disabled={this.props.shouldDisable}
        placeholder={"Selecione"}
        onChange={(value) => {
          this.props.handleStateChange(name, value);
        }}
      />
    );
  };

  render() {
    const config = {
      data: this.props.options,
      loading: false,
      defaultPageSize: 10,
      pageSize: this.props.options.length,
      defaultSorted: [{ id: "name", desc: false }],
      shouldDisableRowHighlight: true,
      showPagination: false,
      columns: [
        {
          Header: "Nome do Exame",
          accessor: "name",
          className: styles.leftText,
          maxWidth: 320,
        },
        {
          Header: (props) => <span className="float-left">Resultado</span>,
          className: styles.centeredText,
          sortable: false,
          width: 120,
          Cell: this.renderResultField,
        },
        {
          Header: "Valores Normais",
          sortable: false,
          className: styles.centeredText,
          Cell: renderRef,
          width: 180,
        },
        {
          Header: (props) => <span className="float-left">Status</span>,
          Cell: this.renderStatusField,
          sortable: false,
        },
        {
          Header: (props) => <span className="float-left">Prazo</span>,
          className: styles.centeredText,
          Cell: (props) => deadlineToString(props.original.deadline),
          show: this.props.shouldUseReducedVersion ? false : true,
          sortable: false,
          width: 80,
        },
        {
          Header: (props) => (
            <span className="float-left">Data de Finalização</span>
          ),
          className: styles.centeredText,
          Cell: (props) => {
            const date = props.original.endDate;
            return date ? moment(date).format("DD/MM/YYYY") : "00-00-0000";
          },
          show: this.props.shouldUseReducedVersion ? false : true,
          sortable: false,
          width: 120,
        },
      ],
    };

    return (
      <React.Fragment>
        <div className="text-center">
          <h5 className="table-title">{this.props.title}</h5>
        </div>
        <LVTable {...config} />
      </React.Fragment>
    );
  }
}

DefaultExamTable.propTypes = propTypes;
DefaultExamTable.defaultProps = defaultProps;

export default DefaultExamTable;
