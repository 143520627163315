import React from "react";
import PropTypes from "prop-types";

/**
 * External
 */
import { Row, Col } from "./../../components/grid";
import NumberDisplay from "./../NumberDisplay";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import NumberInputMaterial from "./../NumberInputMaterial";
import CheckboxMaterial from "./../CheckboxMaterial";
import PanelMaterialBody from "../PanelMaterialBody";
import PanelMaterialTop from "../PanelMaterialTop";

const isAllowed = (values, e) => {
  if (values.floatValue > 999) {
    return false;
  }
  return true;
};

const propTypes = {};

/**
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 *
 */
const GlobalLeukogramPanel = ({
  globalLeukometry,
  metarrubrycites,
  isIntenseLeucopeny,
  disableAll,
  $validation,
  refs,
  fieldChange,
  $field,
  toggleIntenseLeucopeny,
  finalGlobalLeukogram,
  setRef,
}) => (
  <React.Fragment>
    <PanelMaterial className="pb-15 pt-5 leukogram-panel" flat={true}>
      <PanelMaterialTop title="Leucometria Global" />
      <PanelMaterialBody>
        <Row>
          <Col xs={12}>
            <Col xs={7}>
              <p className="cleanItem">Leucometria Global</p>
            </Col>
            <Col xs={5} className="text-center">
              <p className="cleanItem">Valor Normal</p>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col xs={7}>
            <NumberInputMaterial
              name="globalLeukometry"
              md={12}
              allowNegative={false}
              inputGroupAfter="/μl"
              inputGroup={true}
              decimalScale={0}
              value={globalLeukometry}
              disabled={disableAll}
              fieldChange={fieldChange}
              {...$field("globalLeukometry")}
              hasValidation={$validation.globalLeukometry.show}
              validationReason={$validation.globalLeukometry.error.reason}
              hasValidationRecoil={false}
              setFieldRef={setRef}
            />
          </Col>
          <Col xs={5}>
            <Col xs={6} className="text-right cleanItem">
              <p className="mr-20">
                <NumberDisplay
                  value={refs.globalLeukometryMin}
                  decimalScale={0}
                />
              </p>
            </Col>
            <Col xs={6} className="text-left cleanItem">
              <p className="ml-5">
                <NumberDisplay
                  value={refs.globalLeukometryMax}
                  decimalScale={0}
                />
              </p>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={"mb-10"}>
            <CheckboxMaterial
              id="intenseLeucopeny"
              name="intenseLeucopeny"
              titleAfter="Leucopenia Intensa"
              checked={isIntenseLeucopeny}
              disabled={disableAll}
              onChange={toggleIntenseLeucopeny}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Col xs={7}>
              <p className="cleanItem">Metarrubrícitos</p>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col xs={7}>
            <NumberInputMaterial
              name="metarrubrycites"
              md={12}
              allowNegative={false}
              inputGroupAfter="%"
              inputGroup={true}
              decimalScale={0}
              isAllowed={isAllowed}
              value={isIntenseLeucopeny ? "" : metarrubrycites}
              disabled={isIntenseLeucopeny || disableAll}
              fieldChange={fieldChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Col xs={7}>
              <p className="cleanItem">Resultado</p>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col xs={7}>
            <NumberInputMaterial
              name="finalGlobalLeukometry"
              md={12}
              allowNegative={false}
              inputGroupAfter="/μl"
              inputGroup={true}
              disabled={true}
              value={isIntenseLeucopeny ? "" : finalGlobalLeukogram()}
              decimalScale={0}
              fixedDecimalScale={true}
            />
          </Col>
        </Row>
      </PanelMaterialBody>
    </PanelMaterial>
  </React.Fragment>
);

GlobalLeukogramPanel.propTypes = propTypes;

export default GlobalLeukogramPanel;
