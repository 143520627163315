import connect from "../../api-connector";

const connectWithEndpoint = connect(props => ({
  addPricingTable: (obj, then, error) => ({
    PricingTableResponse: {
      url: `/pricing-table`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  }),
  allPricingTables: `/pricing-tables`,
  pricingTables: `/pricing-table/filter`,
  pricingTableSearch: (filters, page, sorted, then) => ({
    pricingTables: {
      url: `/pricing-table/filter?id=${filters.id}&name=${filters.name}&page=${page}&sort=${sorted[0].id}&desc=${sorted[0].desc}&customerId=${filters.customerId}`,
      method: "GET",
      then: then,
      force: true
    }
  }),
  pricingTableWithCustomerId: (customerId, then) => ({
    pricingTableResponseWithId: {
      url: `/pricing-table/filter?customerId=${customerId}`,
      method: "GET",
      then: then,
      force: true
    }
  }),
  pricingTableWithId: (id, then) => ({
    pricingTableResponseWithId: {
      url: `/pricing-table/${id}`,
      method: "GET",
      then: then,
      force: true
    }
  }),
  addExamPlace: (obj, then, error) => ({
    examPlaceResponse: {
      url: `/exam-place`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  }),
  allExamPlaces: `/exam-places`,
  examPlaces: `/exam-place/filter`,
  examPlaceSearch: (filters, page, sorted, then) => ({
    examPlaces: {
      url: `/exam-place/filter?id=${filters.id}&name=${filters.name}&page=${page}&sort=${sorted[0].id}&desc=${sorted[0].desc}`,
      method: "GET",
      then: then,
      force: true
    }
  }),
  pricingTableExams: (obj, then, error) => ({
    pricingTableExamsResponse: {
      url: `/pricing-table-exam`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  })
}));

export default connectWithEndpoint;
