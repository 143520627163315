import React from 'react';

/**
 * External
 */
import { Row, Col } from './../../components/grid';

/**
 * Internal
 */
import InputMaterial from './../InputMaterial';

/**
 * @author Alessandro Bastos Grandini
 *
 * Platelets diagnosis fields
 * 
 */
const PlateletsDiagnosisContent = ( {
  plateletsObs,
  $validation,
  fieldChange,
  $field,
  diagnosisFn,
  shouldDisable
} ) =>
  <React.Fragment>
    <Row>
      <Col xs={ 1 } className='text-right'>
        <p className='mr-10 cleanItem'>Plaq</p>
      </Col>
      <Col xs={ 11 }>
        <InputMaterial
          name='diagnosis'
          md={ 12 }
          fit={ true }
          value={ diagnosisFn() }
          disabled={ true }
        />
      </Col>
    </Row>
    <Row>
      <Col xs={ 1 } className='text-right'>
        <p className='mr-10 cleanItem'>OBS</p>
      </Col>
      <Col xs={ 11 }>
        <InputMaterial
          name='plateletsObs'
          md={ 12 }
          fit={ true }
          value={ plateletsObs }
          disabled={ shouldDisable }

          { ...$field( 'plateletsObs', ( event ) => {
            fieldChange( 'plateletsObs', event.target.value );
          } ) }

          hasValidation={ $validation.plateletsObs.show }
          validationReason={ $validation.plateletsObs.error.reason }
        />
      </Col>
    </Row>
  </React.Fragment>

PlateletsDiagnosisContent.propTypes = {};

export default PlateletsDiagnosisContent;