import React from 'react';

/**
 * Internal
 */
import FlatPanelMaterial from './../FlatPanelMaterial';
import { IMMUNOLOGY_EXAM_MATERIALS, IMMUNOLOGY_INFO } from './../../assets/js/Consts';
import DualRadioMaterial2 from './../DualRadioMaterial2';
import SelectMaterial2 from './../SelectMaterial2';
import TextAreaPanel2 from './../TextAreaPanel2';
import MethodPanel from './../MethodPanel';

/**
 * External
 */
import { Col, Row } from './../../components/grid';

const ParvoCoronaContent = ( {
    title,
    materialId,
    isParvovirus,
    isCoronavirus,
    materialIdFieldName,
    isParvovirusFieldName,
    isCoronavirusFieldName,
    materialIdHasValidation,
    isParvovirusHasValidation,
    isCoronavirusHasValidation,
    materialIdValidationReason,
    isParvovirusValidationReason,
    isCoronavirusValidationReason,
    obsFieldName,
    obs,
    shouldDisable,
    handleChange,
    handleBlur
} ) =>
  <React.Fragment>
   <MethodPanel
      method={ IMMUNOLOGY_INFO.PARVO_CORONA.METHOD }
      description={ IMMUNOLOGY_INFO.PARVO_CORONA.DESCRIPTION } />
    <FlatPanelMaterial title={ title }>
      <Row className="mb-10">
        <Col md={ 4 }>
          <SelectMaterial2
            title="Material Enviado"
            name={ materialIdFieldName }
            options={ IMMUNOLOGY_EXAM_MATERIALS }
            value= { materialId }
            isSearchable={ true }
            hasValidationRecoil
            shouldDisable={ shouldDisable }
            onChange={ handleChange }
            onBlur={ handleBlur }
            hasValidation={ materialIdHasValidation }
            validationReason={ materialIdValidationReason } />
        </Col>
        <Col md={ 4 }>
          <DualRadioMaterial2
            title='Resultado Parvovirus Canino'
            name={ isParvovirusFieldName }
            selectedValue={ isParvovirus }
            disabled={ shouldDisable }
            firstLabel='Negativo'
            firstValue={ 0 }
            secondLabel='Postivo'
            secondValue={ 1 }
            onChange={ handleChange }
            hasValidation={ isParvovirusHasValidation }
            validationReason={ isParvovirusValidationReason } />
        </Col>
        <Col md={ 4 }>
          <DualRadioMaterial2
            title='Resultado Coronavirus Canino'
            name={ isCoronavirusFieldName }
            selectedValue={ isCoronavirus }
            disabled={ shouldDisable }
            firstLabel='Negativo'
            firstValue={ 0 }
            secondLabel='Postivo'
            secondValue={ 1 }
            onChange={ handleChange }
            onBlur={ handleBlur }
            hasValidation={ isCoronavirusHasValidation }
            validationReason={ isCoronavirusValidationReason } />
        </Col>
      </Row>
    </FlatPanelMaterial>
    <TextAreaPanel2
      title={ 'Obs' }
      fieldTitle={ '' }
      name={ obsFieldName }
      value={ obs }
      shouldDisable={ shouldDisable }
      handleChange={ handleChange }
      handleBlur={ handleBlur } />
  </React.Fragment>

export default ParvoCoronaContent;