import React, { useState, useEffect } from "react";
import { connect as reduxConnect } from "react-redux";

import apiConnect from "./../../../api-connector";
import promiseUtil from "./../../../useful/promise-util";
import * as DateUtil from "./../../../useful/date-util";
import { mapStateToProps, mapDispatchToProps } from "./../../../redux/maps";
import requests from "./../requests";
import FormState from "./form-state";
import { FETCH_MESSAGE, ERROR_MESSAGE, SELECT_INVOICE_MESSAGE } from "./../../../consts/messages";
import InvoiceSearchView from "./view.js";
import { ACCESS_PROFILES } from "../../../assets/js/Consts.js";
import { ArrayUtil, StringUtil } from "../../../useful";
import { INVOICE_STATES } from "../../../consts";


const InvoiceSearch = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  successNotification,
  errorNotification,
  infoNotification,
  notifications,
  getInvoice,
  printInvoice,
  mailInvoice,
  deleteInvoice,
  registerPaymentToInvoice,
  deletePaymentFromInvoice,
  deletedInvoice,
  registeredPayment,
  deletedPayment,
  fetched,
  searched,
  regions,
  customers,
  setFieldValue,
  fetchInvoicesCsv,
  warningNotification,
  user
}) => {
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
  const [printableInvoiceUrl, setPrintableInvoiceUrl] = useState(false);

  const invoices = promiseUtil.extractValue(searched, []);

  const fetchedInvoice = promiseUtil.extractValue(fetched, null);

  regions = promiseUtil.extractValue(regions, []);

  customers = promiseUtil.extractValue(customers, null);

  const shoundDisable = (customers && regions);


  useEffect(() => {
    if (isCLinicUser(user)) {
      if (customers) {
        const selectedCustumer = customers.filter(customer => customer.id === user.customer_id);
        customers && setFieldValue("customer", selectedCustumer[0]);
      }
    }
  }, [customers]);

  const isCLinicUser = ({ access_profile }) => {
    const allowed = [
      ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
      ACCESS_PROFILES.CLINIC_USER
    ];
    return allowed.includes(access_profile);
  };

  let disableAll = !(regions && customers) || isSubmitting;

  const togglePaymentModal = (e) => {
   e && e.preventDefault();

    // paymentModalIsOpen && handleSubmit();

    setPaymentModalIsOpen(!paymentModalIsOpen);
  };

  const handleClosePrintableInvoiceModal = () => {
    setPrintableInvoiceUrl(false);
  };

  const viewInvoice = (invoiceId, getInvoice, setPreviewModalIsOpen) => {
    const onSuccess = () => {
      setPreviewModalIsOpen(true);
      successNotification(FETCH_MESSAGE);
    };

    const onError = () => {
      errorNotification(ERROR_MESSAGE);
    };
    getInvoice(invoiceId, onSuccess, onError);
  };

  const onInvoicesTableDoubleClick = (
    invoiceId,
    getInvoice,
    setPreviewModalIsOpen,
    isClinicUser
  ) => {
    if (isClinicUser) {
      handleInvoicePrinting(invoiceId);
    }
    else {

      viewInvoice(invoiceId, getInvoice, setPreviewModalIsOpen);
    }
  };

  const handleMonthAndYearChange = (event, setFieldValue, handleChange) => {
    const month = event.target.value.substr(5, 2);
    const year = event.target.value.substr(0, 4);
    setFieldValue(
      "start_date",
      DateUtil.getFirstDayOfTheMonthAsString(month, year)
    );
    setFieldValue(
      "end_date",
      DateUtil.getLastDayOfTheMonthAsString(month, year)
    );
    handleChange(event);
  };

  const handleRegisterPayment = (invoiceId, invoicePayment) => {
    const onSuccess = () => {
      const onError = () => {
        errorNotification(ERROR_MESSAGE);
      };

      successNotification(FETCH_MESSAGE);

      getInvoice(invoiceId, () => { }, onError);
    };

    const onError = () => {
      errorNotification(ERROR_MESSAGE);
    };

    invoicePayment.payment_method_id = invoicePayment.payment_method.id;
    invoicePayment.payment_date =
      invoicePayment.payment_date || DateUtil.getTodayDateAsString();

    registerPaymentToInvoice(invoiceId, invoicePayment, onSuccess, onError);
  };

  const handleDeletePayment = (invoiceId, invoicePaymentId) => {
    const onSuccess = () => {
      const onError = () => {
        errorNotification(ERROR_MESSAGE);
      };

      successNotification(FETCH_MESSAGE);

      getInvoice(invoiceId, () => { }, onError);
    };

    const onError = () => {
      errorNotification(ERROR_MESSAGE);
    };

    deletePaymentFromInvoice(invoicePaymentId, onSuccess, onError);
  };

  const handleTableDoubleClick = (
    rowInfo,
    getInvoice,
    setPaymentModalIsOpen,
    isClinicUser
  ) => {
    if (!rowInfo) return;
    return onInvoicesTableDoubleClick(
      rowInfo.original.id,
      getInvoice,
      setPaymentModalIsOpen,
      isClinicUser
    );
  };

  const handleInvoicePrinting = (invoiceId) => {
    const onSuccess = (values) => {
      const API_URL = process.env.REACT_APP_PROXY;
      const S3_URL = process.env.REACT_APP_S3_URL;

      let root = S3_URL
        ? S3_URL + "/"
        : API_URL + "/storage/printed/invoices/";

      setPrintableInvoiceUrl(root + values.file);
      handleSubmit();
    };

    const onError = () => {
      errorNotification(ERROR_MESSAGE);
    };

    infoNotification({
      title: "Solicitação em processo.",
      message: "Estamos gerando sua fatura, aguarde um momento!",
      level: "info",
      position: "tr",
      autoDismiss: 5,
    });

    printInvoice(invoiceId, onSuccess, onError);
  };

  const handleInvoiceMailing = (invoiceId) => {
    const onSuccess = (values) => { };

    const onError = () => {
      errorNotification(ERROR_MESSAGE);
    };

    infoNotification({
      title: "Solicitação em andamento.",
      message: "Em até 3 minutos o cliente receberá a fatura.",
      level: "info",
      position: "tr",
      autoDismiss: 5,
    });

    mailInvoice(invoiceId, onSuccess, onError);
  };

  const handleInvoiceDeletion = (invoiceId) => {
    const onSuccess = () => {
      successNotification(FETCH_MESSAGE);
      handleSubmit();
    };

    const onError = () => {
      errorNotification(ERROR_MESSAGE);
    };

    deleteInvoice(invoiceId, onSuccess, onError);
  };
 
  const header = ["Número da Fatura", "Cliente", "Valor Bruto", "Valor líquido", "Valor pago", "Vencimento", "Situação" ];
  const generateInvoiceData = () => {

    const data = invoices.map(invoice => {
     return  [ invoice.id,
        invoice.customer.name,
        String(invoice.gross_value).replace(".", ','),
        String(invoice.net_value).replace(".", ','),
        String(invoice.total_paid).replace(".", ','),
        StringUtil.formatDateUi(invoice.due_date_time),
        INVOICE_STATES.toString(invoice.status)
      ]
    });
    return data;
  }

  const exportInvoiceExcel = () => {
    const data = generateInvoiceData();

    ArrayUtil.exportCSVFile(header, data, "fatura");
    // const sucess = (data) => {
    //   ArrayUtil.exportCSVFile(data.header, data.itens, "fatura");

    // };
    // const onError = () => {
    //   errorNotification(ERROR_MESSAGE);
    // };
    // const ids = invoices.map(invoice => invoice.id);
    // invoices.length?
    //   fetchInvoicesCsv(ids, sucess, onError) :
    //   warningNotification(SELECT_INVOICE_MESSAGE);

  }

  return (
      <InvoiceSearchView
        values={values}
        touched={touched}
        errors={errors}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleSubmit={handleSubmit}
        handleMonthAndYearChange={handleMonthAndYearChange}
        handleRegisterPayment={handleRegisterPayment}
        handleDeletePayment={handleDeletePayment}
        handleTableDoubleClick={handleTableDoubleClick}
        handleInvoicePrinting={handleInvoicePrinting}
        handleInvoiceMailing={handleInvoiceMailing}
        handleInvoiceDeletion={handleInvoiceDeletion}
        getInvoice={getInvoice}
        invoices={invoices}
        regions={regions}
        customers={customers}
        fetchedInvoice={fetchedInvoice}
        notifications={notifications}
        togglePaymentModal={togglePaymentModal}
        paymentModalIsOpen={paymentModalIsOpen}
        setPaymentModalIsOpen={setPaymentModalIsOpen}
        printableInvoiceUrl={printableInvoiceUrl}
        handleClosePrintableInvoiceModal={handleClosePrintableInvoiceModal}
        disableAll={disableAll}
        isClinicUser={isCLinicUser(user)}
        exportInvoiceExcel={exportInvoiceExcel}
      />
  );
};
const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);
const connectWithApi = apiConnect(requests);

export default connectWithRedux(connectWithApi(FormState(InvoiceSearch)));

