import { validated } from "react-custom-validation";
import {
  required,
  minLength,
  maxLength,
  greaterThan,
  greaterThanAndGreaterThan,
  greaterThanOrGreaterThan,
  equalToOrGreaterThanOrGreaterThan,
  lazyIsUniqueSample,
} from "../../assets/js/validationFunctions";

import { animalRules } from "../../assets/js/validationRules";

const validation = validated((props) => {
  const {
    customerId,
    animalId,
    sexId,
    speciesId,
    breedId,
    name,
    years,
    months,
    owner,
    vetId,
    examTypeId,
    labExams,
    clinicalReport,
    requestExams,
    requestSamples,
  } = props.data.fields;

  let fields = [
    "customerId",
    "examTypeId",
    "labExams",
    "clinicalReport",
    "vetId" /* ,
    'requestExamsLength' */,
  ];

  let validations = {
    customerId: {
      rules: [[greaterThan, customerId, 0, "Selecione o cliente"]],
    },
    clinicalReport: {
      rules: [
        [required, clinicalReport, "Informe o relato clínico"],
        [maxLength, clinicalReport, 5000, "Máximo de 5000 caracteres"],
      ],
    },
    vetId: {
      rules: [
        [greaterThanAndGreaterThan, vetId, 0, customerId, 0, "Selecione"],
      ],
    },
    examTypeId: {
      rules: [
        [
          greaterThanOrGreaterThan,
          examTypeId,
          0,
          requestExams.length,
          0,
          "Selecione o tipo de exame",
        ],
      ],
    },
    labExams: {
      rules: [
        [
          equalToOrGreaterThanOrGreaterThan,
          examTypeId,
          0,
          requestExams.length,
          0,
          labExams.length,
          0,
          "Selecione o exame",
        ],
      ],
    },
    requestExamsLength: {
      rules: [[greaterThan, requestExams.length, 0, ""]],
    },
  };
  // Adds request sample code validation
  requestSamples.forEach((requestSample, index) => {
    const { code } = requestSample;

    const fieldName = `requestSample-${index}`;

    validations[fieldName] = {
      rules: [
        [required, code, "Informe o código da amostra"],
        [lazyIsUniqueSample, code, props.data.id],
      ],
    };

    fields.push(fieldName);
  });

  if (props.data.isNewAnimal) {
    fields.push(
      "name",
      "sexId",
      "speciesId",
      "breedId",
      /* 'birth', */
      "years",
      "months",
      "owner"
    );

    validations.name = {
      rules: [
        [required, name, "Informe o nome do animal"],
        [maxLength, name, 30, "Máximo de 30 caracteres"],
      ],
    };

    validations.sexId = {
      rules: [[greaterThan, sexId, 0, "Selecione o sexo"]],
    };

    validations.speciesId = {
      rules: [[greaterThan, speciesId, 0, "Selecione a espécie"]],
    };

    validations.breedId = {
      rules: [
        [
          greaterThanAndGreaterThan,
          breedId,
          0,
          speciesId,
          0,
          "Selecione a raça",
        ],
      ],
    };

    /* validations.birth = {
      rules: [
        [ required, birth, 'Informe a data de nascimento' ]
      ]
    }
    */

    validations.years = {
      rules: [
        [required, years, "Informe os anos"],
        [minLength, years, 1, "Mínimo de 1 dígito"],
        [maxLength, years, 2, "Máximo de 2 dígitos"],
      ],
    };

    validations.months = {
      rules: [
        [required, months, "Informe os meses"],
        [minLength, months, 1, "Mínimo de 1 dígito"],
        [maxLength, months, 2, "Máximo de 2 dígitos"],
      ],
    };

    validations.owner = {
      rules: [
        [required, owner, "Informe o nome do proprietário"],
        [maxLength, owner, 64, "Máximo de 64 caracteres"],
      ],
    };

    return { fields, validations };
  }

  fields.push("animalId");

  validations.animalId = {
    rules: [
      [
        greaterThanAndGreaterThan,
        animalId,
        0,
        customerId,
        0,
        "Selecione o animal",
      ],
    ],
  };

  return { fields, validations };
});

export default validation;

/**
 * @author Alessandro Bastos Grandini
 *
 * Validation function for email
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const animalModalValidation = (props) => {
  const {
    name,
    years,
    months,
    owner,
    sexId,
    birthDate,
    cpf,
    breedId,
    speciesId,
    customerId,
    ageOption,
  } = props.fields;

  const fields = [
    "name",
    "years",
    "owner",
    "cpf",
    "sexId",
    "breedId",
    "speciesId",
    "customerId",
    "ageOption",
    "birthDate",
  ];

  const fieldNames = {
    nameName: "name",
    yearsName: "years",
    monthsName: "months",
    ownerName: "owner",
    sexIdName: "sexId",
    cpfName: "cpf",
    speciesIdName: "speciesId",
    breedIdName: "breedId",
    customerIdName: "customerId",
    ageOptionName: "ageOption",
    birthDateName: "birthDate",
  };

  const validations = animalRules({
    name: name,
    years: years,
    months: months,
    owner: owner,
    sexId: sexId,
    birthDate: birthDate,
    cpf: cpf,
    breedId: breedId,
    speciesId: speciesId,
    customerId: customerId,
    fieldNames: fieldNames,
    ageOption: ageOption,
  });

  return { fields, validations };
};
