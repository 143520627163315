import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import * as LifeVet from "./../../../components";
import ButtonDefaultMaterial from "./../../../containers/ButtonDefaultMaterial";
import InvoiceExamsTable from "./invoice-exams-table";
import InvoicePaymentsTable from "./invoice-payments-table";
import { PAYMENT_METHODS } from "./../../../consts";
import * as NumberUtil from "./../../../useful/number-util";
import {ArrayUtil} from "./../../../useful"

const InvoicePaymentModal = ({
  isOpen,
  data,
  values,
  toggle,
  setFieldValue,
  handleChange,
  handleBlur,
  handleRegisterPayment,
  handleDeletePayment,
  disableAll
}) => {
  const requestExamsQuantity = data ? data.requestExams.length : 0;

  return (
    <Modal show={isOpen} onHide={toggle} bsSize="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Fatura Nº {data && data.invoice.id} - Detalhes</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={4}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Cliente</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.customer.name}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>

          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Quant. Solic.</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.examRequestsCount}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>

          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Quant. Exames</LifeVet.InputLabel>
              <LifeVet.Input value={requestExamsQuantity} isViewOnly={true} />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Data Inicial</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.invoice.start_date.substr(0,10)}
                type="date"
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Data Final</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.invoice.end_date.substr(0,10)}
                type="date"
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Vencimento</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.invoice.due_date_time.substr(0,10)}
                type="date"
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>

          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Data de Geração</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.invoice.created_at.substr(0, 10)}
                type="date"
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Valor Bruto</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && `R$ ${ArrayUtil.numberFormat(data.invoice.gross_value)}`}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Desconto (R$)</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && `R$ ${ArrayUtil.numberFormat(data.invoice.discount_amount)}`}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Desconto (%)</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.customer.preferences.discount}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>

          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Valor Líquido</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && `R$ ${ArrayUtil.numberFormat(data.invoice.net_value)}`}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>
        </Row>

        <Row className="mt-20">
          <Col>
            <InvoiceExamsTable data={data ? data.requestExams : []} />
          </Col>
        </Row>
        <br />
        <h3 className="mt-20">Pagamentos da Fatura</h3>
        <Row className="mt-20">
          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Valor (R$)</LifeVet.InputLabel>
              <LifeVet.Input
                name="invoice_payment.value"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.invoice_payment.value}
                type="number"
                disabled={disableAll}
              />
            </LifeVet.FormGroup>
          </Col>

          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Forma de Pagamento</LifeVet.InputLabel>

              <LifeVet.Select
                name="invoice_payment.payment_method"
                value={values.invoice_payment.payment_method}
                options={PAYMENT_METHODS.values}
                searchable={true}
                multi={false}
                closeOnSelect={true}
                onChange={value => {
                  setFieldValue("invoice_payment.payment_method", value);
                }}
                disabled={disableAll}
              />
            </LifeVet.FormGroup>
          </Col>

          <Col sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Data</LifeVet.InputLabel>
              <LifeVet.Input
                name="invoice_payment.payment_date"
                value={values.invoice_payment.payment_date}
                onChange={handleChange}
                onBlur={handleBlur}
                type="date"
                disabled={disableAll}
              />
            </LifeVet.FormGroup>
          </Col>

          <Col sm={4}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Obs</LifeVet.InputLabel>
              <LifeVet.Input
                name="invoice_payment.obs"
                value={values.invoice_payment.obs}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disableAll}
              />
            </LifeVet.FormGroup>
          </Col>

          <Col sm={2}>
            <LifeVet.Button
              float="right"
              type="submit"
              depth="2"
              variant="primary"
              className="mt-20 pull-right"
              onClick={() =>
                handleRegisterPayment(data.invoice.id, values.invoice_payment)
              }
              disabled={disableAll}
            >
              Adicionar
            </LifeVet.Button>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col>
            <InvoicePaymentsTable
              handleDeletePayment={handleDeletePayment}
              data={data ? data.invoice.invoice_payments : []}
            />
          </Col>
        </Row>
        <Row className="mt-20">
          <Col smOffset={7} sm={2}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Total Pago</LifeVet.InputLabel>
              <LifeVet.Input
                value={data && data.invoice.total_paid}
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>
          <Col sm={3}>
            <LifeVet.FormGroup>
              <LifeVet.InputLabel>Saldo em Aberto</LifeVet.InputLabel>
              <LifeVet.Input
                value={
                  data &&
                  NumberUtil.round(
                    data.invoice.net_value - data.invoice.total_paid
                  )
                }
                isViewOnly={true}
              />
            </LifeVet.FormGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefaultMaterial type="default" title="Voltar" onClick={toggle} />
      </Modal.Footer>
    </Modal>
  );
};

export default InvoicePaymentModal;
