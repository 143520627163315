import React, { Component } from "react";
import update from "immutability-helper";
import Notifications from "react-notification-system-redux";
import { connect as connectWithRedux } from "react-redux";
import FadeIn from "react-fade-in";
import { ACCESS_PROFILES } from "./../../assets/js/Consts";
import AccessManager from "./../../old_components/AccessManager";
import * as LifeVet from "./../../components";

/** External */
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faPlusSquare from "@fortawesome/fontawesome-free-solid/faPlusSquare";
import faCheckSquare from "@fortawesome/fontawesome-free-solid/faCheckSquare";
import faSearch from "@fortawesome/fontawesome-free-solid/faSearch";
import { Link } from "react-router-dom";
import { Row, Col } from "./../../components/grid";
import connectWithEndpoint from './requests'

import { MESSAGES } from "./../../consts";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
import { FunctionUtil } from "./../../useful";
import { HomeContainer } from "./components/Container";
import ReceiveAndAssistModal from "../ExamRequestListView/ReceiveAndAssistModal";

/**
 * @author Kayo Oliveira
 *
 * New Homepage
 */
class ExamRequestList extends Component {
  state = {
    fields: {
      modalSampleCode: "",
    },
    showReceiveAndAssistModal: false,
    shouldRedirect: false,
    redirectToId: 0,
  };

  isClinicUserType = (profile) => {
    const clinicUserTypes = [
      ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
      ACCESS_PROFILES.CLINIC_USER,
    ];

    return clinicUserTypes.includes(profile);
  };

  /**
   * @author Victor Heringer
   *
   * Opens modal
   *
   * @return {Void}
   */
  handleShowReceiveAndAssistModal = () => {
    console.log('teste')
    this.setState((state) =>
      update(state, {
        showReceiveAndAssistModal: {
          $set: !this.state.showReceiveAndAssistModal,
        },
      })
    );
    this.updateField("modalSampleCode", "");
  };

  /**
   * @author Victor Heringer
   *
   * Closes modal
   *
   * @return {Void}
   */
  handleCloseReceiveAndAssistModal = () => {
    this.setState((state) =>
      update(state, {
        showReceiveAndAssistModal: {
          $set: !this.state.showReceiveAndAssistModal,
        },
      })
    );
  };

  updateField = (field, value) => {
    if (field === "modalSampleCode" && value.length > 10) return;

    this.setState((state) =>
      update(state, {
        fields: { [field]: { $set: value } },
      })
    );

    if (field === "modalSampleCode" && value.length == 10)
      this.receiveAndAssistSampleCode(value);
  };
  receiveAndAssistSampleCode = (code) => {
    const objIsEmpty = (obj) => {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    };

    const onSuccess = (data) => {
      if (objIsEmpty(data)) {
        this.props.errorNotification(MESSAGES.REQUEST_SAMPLE_CODE_NOT_FOUND);
      }
    };
    this.props.receiveAndAssistRequestExam(code, onSuccess);
  };

  render() {
    const { receiveAndAssistPromise } = this.props;

    if (
      (receiveAndAssistPromise &&
        receiveAndAssistPromise.value &&
        receiveAndAssistPromise.value.id) ||
      this.state.shouldRedirect
    ) {
      const id = this.state.redirectToId || receiveAndAssistPromise.value.id;
      this.props.history.push(`/solicitacoes-exame/${id}`);
    }

    const methods = {
      handleShowReceiveAndAssistModal: this.handleShowReceiveAndAssistModal,
      handleCloseReceiveAndAssistModal: this.handleCloseReceiveAndAssistModal,
      receiveAndAssistSampleCode: this.receiveAndAssistSampleCode,
      updateField: this.updateField,
    };

    const data = {
      fields: this.state.fields,
      showReceiveAndAssistModal: this.state.showReceiveAndAssistModal,
      isClinicUserType: this.isClinicUserType(this.props.access_profile),
      receiveAndAssistPromise: receiveAndAssistPromise,
    };

    return (
      <FadeIn>
        <LifeVet.PanelHeader title="Página inicial">
          <Row className="text-center">
            <Col className="mt-5" xsHidden>
              {/* <ButtonTimer
                goal={120}
                loop
                callback={methods.refreshExamRequestTables}
                title={"Clique para atualizar a página"}
              /> */}
              <AccessManager
                allowed={[
                  ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                  ACCESS_PROFILES.UNIT_USER,
                  ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
                  ACCESS_PROFILES.CLINIC_USER,
                ]}
              >
                {/* <Link to="/solicitacoes-exame/2.0/novo">
                  <LifeVet.Button depth="2" variant="danger">
                    Solicitação (2.0)
                  </LifeVet.Button>
                </Link> */}
                <Link to="/solicitacoes-exame/novo">
                  <LifeVet.Button depth="2" variant="primary">
                    Nova Solicitação
                  </LifeVet.Button>
                </Link>
              </AccessManager>
              <Link to="/solicitacoes-exame/buscar">
                <LifeVet.Button depth="2" variant="secondary">
                  Buscar Solicitação
                </LifeVet.Button>
              </Link>
              <AccessManager
                allowed={[
                  ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                  ACCESS_PROFILES.UNIT_USER,
                ]}
              >
                <LifeVet.Button
                  onClick={methods.handleShowReceiveAndAssistModal}
                  depth="2"
                  variant="secondary"
                >
                  Receber e Laudar
                </LifeVet.Button>
              </AccessManager>
            </Col>

            <Col className="pl-0 pr-0" smHidden mdHidden lgHidden>
              <Link to="/solicitacoes-exame/novo">
                <LifeVet.Button depth="2" variant="primary">
                  <FontAwesomeIcon size="lg" icon={faPlusSquare} />
                </LifeVet.Button>
              </Link>

              <Link to="/solicitacoes-exame/buscar">
                <LifeVet.Button depth="2" variant="secondary">
                  <FontAwesomeIcon size="lg" icon={faSearch} />
                </LifeVet.Button>
              </Link>
              <AccessManager
                allowed={[
                  ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                  ACCESS_PROFILES.UNIT_USER,
                ]}
              >
                <LifeVet.Button
                  onClick={methods.handleShowReceiveAndAssistModal}
                  depth="2"
                  variant="secondary"
                >
                  <FontAwesomeIcon size="lg" icon={faCheckSquare} />
                </LifeVet.Button>
              </AccessManager>
            </Col>
          </Row>
        </LifeVet.PanelHeader>
        <ReceiveAndAssistModal data={data} methods={methods} />

        <HomeContainer />
        <Notifications notifications={this.props.notifications} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(
  connectWithRedux(mapStateToProps, mapDispatchToProps),
  connectWithEndpoint
)(ExamRequestList);
