import { StringUtil } from "./../../useful";

/**
 * @author Victor Heringer
 *
 * Handles data format manipulaion
 */
class CustomerFormFactory {
  /**
   * @author Victor Heringer
   *
   * Convert customer data format from api to client expected data
   *
   * @param {Object} data
   *
   * @return {Object}
   */
  static formatCustomerFromApi(data) {
    const phones = data.phones ? data.phones : [];
    const emails = data.emails ? data.emails : [];
    const users = data.users ? data.users : [];

    let customer = {
      id: data.id,

      /** Person */
      name: data.name,
      status: data.active,
      regionIds: data.regionIds,
      pricingTableId: data.tablePriceId,
      personType: data.personType,
      phones: CustomerFormFactory.formatPhonesFromApi(phones),
      emails: CustomerFormFactory.formatEmailsFromApi(emails),
      users: CustomerFormFactory.formatUsersFromApi(users),
      logo: [],

      /** Address */
      cep: StringUtil.formatCEP(data.addressCEP),
      stateId: data.addressStateId,
      cityId: data.addressCityId,
      neighborhood: data.addressNeighborhood,
      publicPlace: data.addressPublicPlace,
      number: data.addressNumber,
      complement: data.addressComplement,

      /** Preferences */
      preferencesPaymentFrequency: data.preferencesPaymentFrequency,
      preferencesTerm: data.preferencesTerm,
      preferencesDiscount: data.preferencesDiscount,
      preferencesReceivesPrintReport: data.preferencesReceivesPrintReport,
      preferencesDefaulter: data.preferencesDefaulter,
      preferencesUsesPaymentSlip: data.preferencesUsesPaymentSlip,

      /** Fiscal */
      fiscalPersonType: data.fiscalPersonType,
      fiscalCPF: StringUtil.formatCPF(data.fiscalCPF),
      fiscalCNPJ: StringUtil.formatCNPJ(data.fiscalCNPJ),
      fiscalName: data.fiscalName,
      fiscalEmail: data.fiscalEmail,
      fiscalPhone: data.fiscalPhone,
      fiscalCellphone: data.fiscalCellphone,
      fiscalResponsibleName: data.fiscalResponsibleName,
      fiscalResponsibleRG: data.fiscalResponsibleRG,

      /* Company */

      cnpj: StringUtil.formatCNPJ(data.cnpj),

      /* Individual */
      cpf: StringUtil.formatCPF(data.cpf),
    };

    if (data.logoFile && data.logoData) {
      customer.logo = [{ name: data.logoFile, value: data.logoData }];
    }

    return customer;
  }

  /**
   * @author Victor Heringer
   *
   * Convert email data format from api to client expected data
   *
   * @param {Array} emails
   *
   * @return {Object}
   */
  static formatEmailsFromApi(emails) {
    return emails.map((data) => {
      return {
        id: data.id,
        name: data.value,
        sendReport: data.send_report ? 1 : 0,
        sendInvoice: data.send_invoice ? 1 : 0,
      };
    });
  }

  /**
   * @author Victor Heringer
   *
   * Convert phone data format from api to client expected data
   *
   * @param {Array} phones
   *
   * @return {Object}
   */
  static formatPhonesFromApi(phones) {
    return phones.map((data) => {
      return { id: data.id, name: data.value };
    });
  }

  /**
   * @author Victor Heringer
   *
   * Convert user data format from api to client expected data
   *
   * @param {Array} users
   *
   * @return {Object}
   */
  static formatUsersFromApi(users) {
    return users.map((data) => {
      let user = {
        /** User */
        id: data.user.id,
        login: data.user.name,
        access: data.user.active,
        password: "",
        type: data.type,
        accessProfile: data.user.access_profile,

        /** Person */
        email: "",
        phone: "",
        optPhone: "",

        /** Individual */
        name: data.user.individual.person.name,
        cpf: data.user.individual.cpf,

        /** Vet */
        crmv: "",
        isVet: data.user.individual.is_vet,
      };

      if (data.user.individual.person.phones.length > 0) {
        user.phone = data.user.individual.person.phones[0].value;
      }

      if (data.user.individual.person.phones.length > 1) {
        user.optPhone = data.user.individual.person.phones[1].value;
      }

      if (data.user.individual.is_vet) {
        user.crmv = data.user.individual.vet.crmv;
      }

      if (data.user.individual.person.emails.length > 0) {
        user.email = data.user.individual.person.emails[0].value;
      }

      return user;
    });
  }
}

export default CustomerFormFactory;
