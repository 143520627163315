import React, { useReducer } from "react";

import { Animal, UseAnimalState } from "./../../types/index";
import { useBreed } from "./../breed/index";
import { useSpecie } from "./../specie/index";
import { useCustomer } from "./../customer/index";

import { reducer, ACTIONS } from "./reducer";

const defaultAnimal: UseAnimalState = {
  id: 0,
  name: "",
  birthDate: "",
  owner: "",
  phone: "",
  cpf: "",
  externalCode: "",
  microchipCode: "",
  sexId: 1,
  sexName: "",
};

export const useAnimal = () => {
  const [animal, dispatch] = useReducer(reducer, defaultAnimal);
  const breed = useBreed();
  const specie = useSpecie();
  const customer = useCustomer();

  const data:Animal = {
    id: animal.id,
    name: animal.name,
    birthDate: animal.birthDate,
    owner: animal.owner,
    phone: animal.phone,
    cpf: animal.cpf,
    externalCode: animal.externalCode,
    microchipCode: animal.microchipCode,
    sexId: animal.sexId,
    sexName: animal.sexName,
    specie: specie.data,
    breed: breed.data,
    customer: customer.data,
  };

  const methods = {
    update: (animal: Animal) => {
      dispatch({ type: ACTIONS.UPDATE, payload: animal });
      breed.methods.update(animal.breed);
      specie.methods.update(animal.specie);
      customer.methods.update(animal.customer);
    },
    updateField: (field: string, value: number | string) =>
      dispatch({ type: ACTIONS.UPDATE_FIELD, payload: { field, value } }),
    reset: () => {
      dispatch({ type: ACTIONS.UPDATE, payload: defaultAnimal });
      breed.methods.reset();
      specie.methods.reset();
      customer.methods.reset();
    },
    breed: breed.methods,
    specie: specie.methods,
    customer: customer.methods,
  };

  return {
    data: data,
    defaultData: defaultAnimal,
    methods: methods,
  };
};
