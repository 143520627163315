import React from "react";
import FadeIn from "react-fade-in";
import { Modal, Row, Col } from "react-bootstrap";
import Notifications from "react-notification-system-redux";
import * as LifeVet from "../../../components";
import InvoicePaymentModal from "./invoice-payment-modal";
import InvoicesTable from "./invoices-table";
import { PAYMENT_FREQUENCY } from "./../../../consts";

const InvoiceSearchView = ({
  values,
  touched,
  errors,
  isSubmitting,
  setFieldValue,
  handleChange,
  handleBlur,
  handleSubmit,
  handleMonthAndYearChange,
  handleRegisterPayment,
  handleDeletePayment,
  handleTableDoubleClick,
  handleInvoicePrinting,
  handleInvoiceMailing,
  handleInvoiceDeletion,
  getInvoice,
  invoices,
  regions,
  customers,
  fetchedInvoice,
  notifications,
  togglePaymentModal,
  paymentModalIsOpen,
  setPaymentModalIsOpen,
  printableInvoiceUrl,
  handleClosePrintableInvoiceModal,
  disableAll,
  isClinicUser,
  exportInvoiceExcel
}) => {

  const submitButtonComponent = () => {

    return (
      <Col sm={2} className="mt-15">
        <LifeVet.Button
          float="right"
          type="submit"
          depth="2"
          variant="primary"
          onClick={handleSubmit}
          disabled={disableAll}
        >
          Consultar
              </LifeVet.Button>
      </Col>
    );

  };

  return (
    <FadeIn>
      <Modal
        bsSize="large"
        show={printableInvoiceUrl}
        onHide={handleClosePrintableInvoiceModal}
      >
        <Modal.Body>
          <embed src={printableInvoiceUrl} width="100%" height="600" />
        </Modal.Body>
        <Modal.Footer>
          <LifeVet.Button
            float="right"
            type="submit"
            depth="2"
            variant="primary"
            disabled={disableAll}
            onClick={handleClosePrintableInvoiceModal}
          >
            Fechar
          </LifeVet.Button>
        </Modal.Footer>
      </Modal>
      <InvoicePaymentModal
        isOpen={paymentModalIsOpen}
        data={fetchedInvoice}
        values={values}
        toggle={(e) => togglePaymentModal(e)}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleRegisterPayment={handleRegisterPayment}
        handleDeletePayment={handleDeletePayment}
        disableAll={disableAll}
      />
      <LifeVet.Panel>
        <LifeVet.PanelHeader title="Consulta de Fatura">
          <div>.</div>
        </LifeVet.PanelHeader>
        <LifeVet.PanelBody>
          <Row>
            <Col sm={3}>
              <LifeVet.FormGroup
                errorMessage={touched.month_and_year && errors.month_and_year}
              >
                <LifeVet.InputLabel>Mês / Ano</LifeVet.InputLabel>
                <LifeVet.Input
                  onChange={(event) => {
                    event.persist();
                    handleMonthAndYearChange(
                      event,
                      setFieldValue,
                      handleChange
                    );
                  }}
                  onBlur={handleBlur}
                  value={values.month_and_year}
                  disabled={isSubmitting || disableAll}
                  name="month_and_year"
                  type="month"
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={2}>
              <LifeVet.FormGroup
                errorMessage={touched.start_date && errors.start_date}
              >
                <LifeVet.InputLabel>Data Inicial</LifeVet.InputLabel>
                <LifeVet.Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.start_date}
                  disabled={isSubmitting || disableAll}
                  name="start_date"
                  type="date"
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={2}>
              <LifeVet.FormGroup
                errorMessage={touched.end_date && errors.end_date}
              >
                <LifeVet.InputLabel>Data Final</LifeVet.InputLabel>
                <LifeVet.Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.end_date}
                  disabled={isSubmitting || disableAll}
                  name="end_date"
                  type="date"
                />
              </LifeVet.FormGroup>
            </Col>

            <Col sm={2}>
              <LifeVet.FormGroup
                errorMessage={touched.due_date && errors.due_date}
              >
                <LifeVet.InputLabel>Vencimento</LifeVet.InputLabel>
                <LifeVet.Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.due_date}
                  disabled={isSubmitting || disableAll}
                  name="due_date"
                  type="date"
                />
              </LifeVet.FormGroup>
            </Col>
            {isClinicUser && submitButtonComponent()}
            {!isClinicUser &&
              <Col sm={3}>
                <LifeVet.FormGroup
                  errorMessage={touched.frequency && errors.frequency}
                >
                  <LifeVet.InputLabel>
                    Frequência de Faturamento
                </LifeVet.InputLabel>
                  <LifeVet.Select
                    name="frequency"
                    value={values.frequency}
                    onChange={(value) => {
                      setFieldValue("frequency", value);
                    }}
                    value={values.frequency}
                    disabled={isSubmitting || disableAll}
                    options={PAYMENT_FREQUENCY.values}
                    multi={false}
                    closeOnSelect={true}
                  />
                </LifeVet.FormGroup>
              </Col>
            }
          </Row>
          <Row>
            {!isClinicUser &&
              <Col sm={4}>
                <LifeVet.FormGroup>
                  <LifeVet.InputLabel>Regiões</LifeVet.InputLabel>

                  <LifeVet.Select
                    id="exam_select"
                    name="regions"
                    value={values.regions}
                    options={regions}
                    searchable={true}
                    disabled={isSubmitting || disableAll || !regions}
                    multi={true}
                    closeOnSelect={false}
                    onChange={(value) => {
                      setFieldValue("regions", value);
                    }}
                  />
                </LifeVet.FormGroup>
              </Col>
            }
            {!isClinicUser &&
              <Col sm={3}>
                <LifeVet.FormGroup>
                  <LifeVet.InputLabel>Clientes</LifeVet.InputLabel>
                  <LifeVet.Select
                    id="exam_select"
                    name="customer"
                    value={values.customer}
                    options={customers}
                    searchable={true}
                    disabled={isSubmitting || disableAll || !customers}
                    placeholder={""}
                    multi={false}
                    closeOnSelect={true}
                    onChange={(value) => {
                      setFieldValue("customer", value);
                    }}
                    onBlur={handleBlur}
                  />
                </LifeVet.FormGroup>
              </Col>
            }
            {!isClinicUser && submitButtonComponent()}
          </Row>
          <Row></Row>
          <Row>
            <Col>
              <InvoicesTable
                data={invoices}
                handleInvoicePrinting={handleInvoicePrinting}
                handleInvoiceMailing={handleInvoiceMailing}
                handleInvoiceDeletion={handleInvoiceDeletion}
                onTableDoubleClick={(currentId, state, rowInfo, column) =>
                  handleTableDoubleClick(
                    rowInfo,
                    getInvoice,
                    setPaymentModalIsOpen,
                    isClinicUser
                  )
                }
                isClinicUser={isClinicUser}
              />
            </Col>
          </Row>
        </LifeVet.PanelBody>
        <LifeVet.PanelFooter>
          <LifeVet.Button
            float="right"
            type="submit"
            depth="2"
            variant="primary"
            disabled={isSubmitting || disableAll }
            onClick={exportInvoiceExcel}
            title="Esta funcionalidade estará disponível em breve."
          >
            Exportar Excel
          </LifeVet.Button>
        </LifeVet.PanelFooter>
      </LifeVet.Panel>
      <Notifications notifications={notifications} />
    </FadeIn>
  );
};

export default InvoiceSearchView;
