import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import NumberInputMaterial2 from "../../NumberInputMaterial2";
import InputMaterial from "../../InputMaterial";
import { initialData } from "./initial-data";

export default function EndocrinologyReference({
  data,
  setField,
  extract,
  loading,
  loadInitialData,
}) {
  useEffect(() => {
    loadInitialData(initialData);
  }, []);

  function renderMinAndMaxField(key) {
    const { titles } = initialData;

    let title = titles[key];
    return (
      <Row>
        <Col md={3}>
          <p>{title}</p>
        </Col>
        <Col md={2}>
          <NumberInputMaterial2
            name={`${key}Min`}
            title={"Min"}
            inputGroupAfter=""
            inputGroup={true}
            hasValidationRecoil={true}
            decimalScale={1}
            fit={true}
            block={true}
            inputGroup={true}
            allowNegative={false}
            value={extract(data, `${key}Min`, "")}
            disabled={loading}
            shouldDisableThousandSeparator={false}
            handleChange={(field, value) => {
              setField(`${key}Min`, value);
            }}
          />
        </Col>
        <Col md={2}>
          <NumberInputMaterial2
            name={`${key}Max`}
            title={"Max"}
            inputGroupAfter=""
            inputGroup={true}
            hasValidationRecoil={true}
            decimalScale={1}
            fit={true}
            block={true}
            inputGroup={true}
            allowNegative={false}
            value={extract(data, `${key}Max`, "")}
            disabled={loading}
            shouldDisableThousandSeparator={false}
            handleChange={(field, value) => setField(`${key}Max`, value)}
          />
        </Col>
        <Col md={2}>
          <div style={{ marginTop: "-15px" }}>
            <InputMaterial
              disabled={loading}
              title="Unidade"
              name={`${key}Unit`}
              md={12}
              value={extract(data, `${key}Unit`, "")}
              handleChange={(field, value) => setField(`${key}Unit`, value)}
            />
          </div>
        </Col>
      </Row>
    );
  }

  function renderSingleField(key) {
    const { titles } = initialData;
    let title = titles[key];

    return (
      <Row className="mt-20">
        <Col md={2}>
          <p>{title}</p>
        </Col>
        <Col md={4}>
          <InputMaterial
            disabled={loading}
            title=""
            name={key}
            md={12}
            value={extract(data, key, "")}
            onChange={(event) => setField(key, event.target.value)}
          />
        </Col>
      </Row>
    );
  }
  return (
    <div>
      {renderSingleField("density")}
      {renderSingleField("proteins")}
      {renderSingleField("glucose")}
      {renderSingleField("acetone")}
      {renderSingleField("ph")}
      {renderSingleField("bilirubin")}
      {renderSingleField("urobilinogen")}
      {renderSingleField("hemoglobin")}
      {renderSingleField("leukocyte")}
      {renderSingleField("nitrite")}
      {renderSingleField("epithelialCells")}
      {renderSingleField("pusCells")}
      {renderSingleField("erythrocytes")}
      {renderSingleField("ridges")}
      {renderSingleField("casts")}
      {renderSingleField("bacterialFlora")}
      {renderSingleField("spermatozoa")}
      {renderMinAndMaxField("cortisolCreatinineRatio")}
      {renderMinAndMaxField("proteinCreatinineRatio")}
      {renderMinAndMaxField("microalbuminuria")}
      {renderMinAndMaxField("urinaryGgt")}
    </div>
  );
}
