import { required } from "../../assets/js/validationFunctions.js";

/**
 * @author Victor Heringer
 *
 * Validation function for receive and assist modal
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const ReceiveAndAssistModalValidation = props => {
  const { modalSampleCode } = props.data.fields;

  const fields = ["modalSampleCode"];

  const validations = {
    modalSampleCode: {
      rules: [[required, modalSampleCode, ""]]
    }
  };

  return { fields, validations };
};
