import React, { Component } from "react";

/**
 * Internal
 */
import ErythrogramReportFormView from "./../ErythrogramReportFormView";
import connectWithEndpoint from "./requests.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import validation from "./validation.js";
import {
  BTN_SAVE_TITLE,
  BTN_UPDATE_TITLE,
  BTN_SAVING_TITLE,
  BTN_UPDATING_TITLE,
  REQUEST_EXAM_STATE_ID_BY_NAME,
} from "../../assets/js/Consts.js";
import {
  containerState,
  reportState,
} from "../../modules/factories/default-states.js";
import { PromiseUtil } from "../../useful";
import { REPORT_DEFAULT_VALUES } from "./../../consts";
import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";
import {
  mcvDiagnosis,
  mchcDiagnosis,
  bloodCellsDiagnosis,
  erythrogramDiagnosis,
  calcMCV,
  calcMCHC,
  isAnemia,
  isPolicitemia,
  createErythrogramRefObj,
} from "../../assets/js/reportFunctions.js";
import { erythrogramState } from "../../modules/factories/default-states.js";

/**
 * External
 */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

const View = ({ data, methods, $field, $validation, $submit }) => {
  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;
    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    $field: $field,
    ...methods,
  };

  return <ErythrogramReportFormView data={newData} methods={newMethods} />;
};

const ValidatedView = validation(View);

class ErythrogramReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        ...reportState(),
        ...erythrogramState(),
      },
      ...containerState(),
      shouldSendEmail: false,
    };

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.createObj = this.createObj.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.shouldDisplayLoader = this.shouldDisplayLoader.bind(this);
    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);
    this.submitData = this.submitData.bind(this);
    this.mcvDiagnosis = mcvDiagnosis.bind(this);
    this.mchcDiagnosis = mchcDiagnosis.bind(this);
    this.calcMCV = calcMCV.bind(this);
    this.calcMCHC = calcMCHC.bind(this);
    this.isAnemia = isAnemia.bind(this);
    this.isPolicitemia = isPolicitemia.bind(this);
    this.bloodCellsDiagnosis = bloodCellsDiagnosis.bind(this);
    this.erythrogramDiagnosis = erythrogramDiagnosis.bind(this);
    this.calcMCHC = calcMCHC.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        xhr.response.status === 200 && this.fillFields(data);
      };

      this.props.getReportById(id, then);
    }
    this.props.getReportHeader(this.props.match.params.requestExamId);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    this.setState((state) =>
      update(state, {
        fields: {
          erythrocytes: { $set: obj.erythrocytes },
          corpuscularVolume: { $set: obj.corpuscularVolume },
          hemoglobiometry: { $set: obj.hemoglobiometry },
          erythrogramObs: { $set: obj.erythrogramObs },
          reportDataId: { $set: obj.reportDataId },
          vetSignerId: { $set: obj.vetSignerId },
          requestExamState: { $set: obj.requestExamState },
        },
        shouldSendEmail: {
          $set: obj.requestExamState == REQUEST_EXAM_STATE_ID_BY_NAME,
        },
      })
    );
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Creates hemogram object
   *
   * @return {Object}
   *
   */
  createObj() {
    const {
      erythrocytes,
      corpuscularVolume,
      hemoglobiometry,
      erythrogramObs,
      reportDataId,
      vetSignerId,
      requestExamState,
    } = this.state.fields;

    const requestExamId = this.props.match.params.requestExamId;

    const erythrogramDiagnosis = this.erythrogramDiagnosis();

    return {
      id: this.getId(),
      requestExamId: requestExamId,
      erythrocytes: erythrocytes,
      corpuscularVolume: corpuscularVolume,
      hemoglobiometry: hemoglobiometry,
      mcv: this.calcMCV(),
      mchc: this.calcMCHC(),
      erythrogramDiagnosis,
      erythrogramObs: erythrogramObs,
      reportDataId: reportDataId,
      vetSignerId: vetSignerId,
      requestExamState: requestExamState,
    };
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Decides wether is should
   * display the screen loader
   *
   */
  shouldDisplayLoader() {
    const { reportPromise } = this.props;
    const isUpdate = this.isUpdate();

    let editFetches = null;

    const shouldCheckEditFetches = isUpdate && reportPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([reportPromise]);
    }

    return shouldCheckEditFetches && editFetches && editFetches.pending;
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Sorts report header promise into
   * a data object
   *
   * @return {Object}
   *
   */
  reportHeaderData() {
    const { reportHeader } = this.props;

    return reportHeader.fulfilled ? reportHeader.value : null;
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   *
   */
  submitData() {
    const { submitPromise } = this.props;

    const isUpdate = this.isUpdate();

    const btnTitle = isUpdate ? BTN_UPDATE_TITLE : BTN_SAVE_TITLE;
    const isSet = submitPromise !== undefined;
    const isPending = isSet && submitPromise.pending;
    const isFulfilled = isSet && submitPromise.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: isUpdate ? BTN_UPDATING_TITLE : BTN_SAVING_TITLE,
      btnTitle: btnTitle,
    };
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (!this.state.completeValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" || obj[i] == 0 ? null : obj[i];
      }
    }

    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  render() {
    const { examRequestId, requestExamId, reportPromise } =
      this.props.match.params;
    const backLink = "/solicitacoes-exame/" + examRequestId;

    const { reportSubmitPromise, refsPromise, reportHeaderPromise } =
      this.props;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    // Loader
    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );

    const refsData = PromiseUtil.extractValue(
      refsPromise,
      REPORT_DEFAULT_VALUES.ERYTHROGRAM_REF_DEFAULT,
      createErythrogramRefObj
    );

    const reportPromiseData = PromiseUtil.extractValue(reportPromise, {});
    const data = {
      isUpdate: this.isUpdate(),
      completeValidation: this.state.completeValidation,
      fields: this.state.fields,
      backLink: backLink,
      submitPromise: reportSubmitPromise,
      examRequestId: examRequestId,
      disableAll: reportSubmitPromise && reportSubmitPromise.pending,
      requestExamId: requestExamId,
      reportHeaderData: reportHeaderFactory(reportHeaderData),
      refs: refsData,
      shouldSendEmail: this.state.shouldSendEmail,
      reportData: reportPromiseData,
    };

    // Methods
    const methods = {
      fieldChange: this.fieldChange,
      erythrogramDiagnosis: this.erythrogramDiagnosis,
      handleSubmit: this.handleSubmit,
      invalidNotification: this.invalidNotification,
      calcMCV: this.calcMCV,
      calcMCHC: this.calcMCHC,
      handleSendReportEmail: this.handleSendReportEmail,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      cancelReport: this.cancelReport,
      stopReport: this.stopReport,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(connectWithEndpoint)(ErythrogramReportForm);
