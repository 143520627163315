import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import HemogramPanel from "./../HemogramPanel";
import HemocytozoaResearchPanel from "./../HemocytozoaResearchPanel";
import MicrofilariaeResearchPanel from "./../MicrofilariaeResearchPanel";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Row, Col } from "./../../components/grid";

const HemogramWithHemoparasiticalResearchFormView = ({
  data: {
    refs,
    total,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    shouldSendEmail,
    hemocitozoaResearchCheckboxes,
    hemocytozoaResearchResult,
    checkdState,
    checkdStateMicrofilariaeResearch,
    microfilariaeResearchCheckboxes,
    microfilariaeResearchResult,
    reportData,
  },
  methods: {
    $field,
    fieldChange,
    onSubmit,
    calcMCHC,
    calcMCV,
    erythrogramDiagnosis,
    plateletsDiagnosis,
    calcAbsolute,
    resetLeukogram,
    onClickCounter,
    leukogramDiagnosis,
    toggleIntenseLeucopeny,
    finalGlobalLeukogram,
    handleKeyPress,
    handleFinishedPlaying,
    closeCounter,
    handleSendReportEmail,
    handleChange,
    handleBlur,
    cancelReport,
    stopReport,
    handleFocus,
    setRef,
    multiBoxChange,
    handleCheckedResultChange,
    microfilariaeResearchMultiBoxChange,
    microfilariaeResearchMandleCheckedResultChange,
  },
}) => (
  <ReportPanelMaterial
    title="Hemograma com Pesquisa Hemoparasitológica"
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
    handleFocus={handleFocus}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <HemogramPanel
        fields={fields}
        refs={refs}
        disableAll={disableAll}
        $validation={$validation}
        total={total}
        $field={$field}
        fieldChange={fieldChange}
        calcMCV={calcMCV}
        calcMCHC={calcMCHC}
        calcAbsolute={calcAbsolute}
        resetLeukogram={resetLeukogram}
        onClickCounter={onClickCounter}
        toggleIntenseLeucopeny={toggleIntenseLeucopeny}
        finalGlobalLeukogram={finalGlobalLeukogram}
        erythrogramDiagnosis={erythrogramDiagnosis}
        leukogramDiagnosis={leukogramDiagnosis}
        plateletsDiagnosis={plateletsDiagnosis}
        handleKeyPress={handleKeyPress}
        handleFinishedPlaying={handleFinishedPlaying}
        closeCounter={closeCounter}
        setRef={setRef}
        reportData={reportData}
      />
    </Row>
    <Row>
      <Col md={12}>
        <HemocytozoaResearchPanel
          resultFieldName={"hemocytozoaResearchResult"}
          result={hemocytozoaResearchResult}
          resultHasValidation={$validation.hemocytozoaResearchResult.show}
          resultValidationReason={
            $validation.hemocytozoaResearchResult.error.reason
          }
          obsFieldName={"hemocytozoaResearchObs"}
          obs={fields.hemocytozoaResearchObs}
          obsHasValidation={$validation.hemocytozoaResearchObs.show}
          obsValidationReason={$validation.hemocytozoaResearchObs.error.reason}
          handleChange={handleChange}
          handleBlur={handleBlur}
          shouldDisable={disableAll}
          checkboxes={hemocitozoaResearchCheckboxes}
          multiCheckboxChange={multiBoxChange}
          checkdState={checkdState}
          handleResultChange={handleCheckedResultChange}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <MicrofilariaeResearchPanel
          resultFieldName={"microfilariaeResearchResult"}
          result={microfilariaeResearchResult}
          resultHasValidation={$validation.microfilariaeResearchResult.show}
          resultValidationReason={
            $validation.microfilariaeResearchResult.error.reason
          }
          obsFieldName={"microfilariaeResearchObs"}
          obs={fields.microfilariaeResearchObs}
          obsHasValidation={$validation.microfilariaeResearchObs.show}
          obsValidationReason={
            $validation.microfilariaeResearchObs.error.reason
          }
          handleChange={handleChange}
          handleBlur={handleBlur}
          shouldDisable={disableAll}
          handleResultChange={microfilariaeResearchMandleCheckedResultChange}
          checkboxes={microfilariaeResearchCheckboxes}
          multiCheckboxChange={microfilariaeResearchMultiBoxChange}
          checkdState={checkdStateMicrofilariaeResearch}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default HemogramWithHemoparasiticalResearchFormView;
