/**
 * @var {Array}
 */
export const values = [
  { id: 0, name: "SEMANAL" },
  { id: 1, name: "QUINZENAL" },
  { id: 2, name: "MENSAL" },
  { id: 3, name: "À VISTA" },
];

export const toString = (id) => {
  switch (parseInt(id)) {
    case 1:
    case 2:
    case 3:
    case 4:
      return values[id].name;
      break;
    default:
      return "Frequência de pagamento inválida.";
  }
};
