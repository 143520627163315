import React from "react";
import FadeIn from "react-fade-in";
import { Modal, Row, Col } from "react-bootstrap";
import Notifications from "react-notification-system-redux";
import * as LifeVet from "../../components";
import {Link} from "react-router-dom";

const LabRecordsView = ({
  values,
  touched,
  errors,
  isSubmitting,
  setFieldValue,
  inputChange,
  fieldChange,
  handleBlur,
  handleSubmit,
  regions,
  customers,
  disableAll,
  shouldEnableLabs,
}) => {
  return (
    <FadeIn>
      <LifeVet.Panel>
        <LifeVet.PanelHeader title="Relatório de Unidades">
          <div>.</div>
        </LifeVet.PanelHeader>
        <LifeVet.PanelBody>
          <Row>
            <Col sm={2}>
              <LifeVet.FormGroup
              // errorMessage={touched.start_date && errors.start_date}
              >
                <LifeVet.InputLabel>Data Inicial</LifeVet.InputLabel>
                <LifeVet.Input
                  onChange={inputChange}
                  onBlur={handleBlur}
                  //   value={values.start_date}
                  disabled={isSubmitting || disableAll}
                  name="startDate"
                  type="date"
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={2}>
              <LifeVet.FormGroup
              
              >
                <LifeVet.InputLabel>Data Final</LifeVet.InputLabel>
                <LifeVet.Input
                  onChange={inputChange}
                  onBlur={handleBlur}
                  // //   value={values.end_date}
                  disabled={isSubmitting || disableAll}
                  name="endDate"
                  type="date"
                />
              </LifeVet.FormGroup>
            </Col>

          </Row>
              <LifeVet.Button
                float="right"
                type="submit"
                depth="2"
                variant="primary"
                onClick={handleSubmit}
                disabled={disableAll}
              >
                Exportar CSV
              </LifeVet.Button>
        </LifeVet.PanelBody>
        <LifeVet.PanelFooter>
        </LifeVet.PanelFooter>
      </LifeVet.Panel>
    </FadeIn>
  );
};

export default LabRecordsView;
