import React from "react";
import styled from "styled-components";
import { granadilla200 } from "./../colors";
import { getDepth } from "./../depth";

const StyledIconListItem = styled.li`
  ${props => getDepth(props.depth)}
  background-color: ${granadilla200};
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 7px;
`;

const StyledWrapper = styled.div`
  display: flex;
`;

const StyledIconWrapper = styled.div`
  margin-right: 15px;
  max-height: 30px;
`;

const StyledTextWrapper = styled.div`
  flex: 1;
`;

const IconListItem = props => (
  <StyledIconListItem {...props}>
    <StyledWrapper>
      <StyledIconWrapper>{props.icon}</StyledIconWrapper>
      <StyledTextWrapper>{props.children}</StyledTextWrapper>
    </StyledWrapper>
  </StyledIconListItem>
);

export default IconListItem;
