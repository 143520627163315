import __update from "immutability-helper";
import { Address, Action } from "./../../types/index";

export const ACTIONS = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  RESET: "RESET",
};

const add = (value: Array<Address>, target: Array<Address>): Array<Address> => {
  const commands = {
    $push: value,
  };
  return __update(target, commands);
};

const update = (value: Address, target: Array<Address>): Array<Address> => {
  const newValue: Array<Address> = target.map((curr) =>
    curr.id === value.id ? value : curr
  );
  const commands = {
    $set: newValue,
  };
  return __update(target, commands);
};

const remove = (value: Address, target: Array<Address>): Array<Address> => {
  const newValue: Array<Address> = target.filter(
    (curr) => curr.id !== value.id
  );
  const commands = {
    $set: newValue,
  };
  return __update(target, commands);
};

const reset = (target: Array<Address>): Array<Address> => {
  const commands = {
    $set: [],
  };
  return __update(target, commands);
};

export const reducer = (state: Array<Address>, action: Action) => {
  switch (action.type) {
    case ACTIONS.ADD:
      return add(action.payload, state);
    case ACTIONS.UPDATE:
      return update(action.payload, state);
    case ACTIONS.REMOVE:
      return remove(action.payload, state);
    case ACTIONS.RESET:
      return reset(state);
    default:
      return state;
  }
};
