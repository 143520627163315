import * as Yup from "yup";
import { YupLocale } from "./index";

Yup.setLocale(YupLocale);

export const CustomerValidationSchema = Yup.object().shape({
  id: Yup.number().required(),
  type: Yup.string(),
  name: Yup.string().required(),
  isActive: Yup.boolean(),
  receivesPrintedReport: Yup.boolean(),
  phones: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      value: Yup.string(),
    })
  ),
  emails: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      value: Yup.string(),
      sendReport: Yup.boolean(),
      sendInvoice: Yup.boolean(),
    })
  ),
  addresses: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      publicPlace: Yup.string(),
      cep: Yup.string(),
      number: Yup.string(),
      complement: Yup.string(),
      neighborhood: Yup.string(),
      cityId: Yup.number(),
      stateId: Yup.number(),
    })
  ),
});
