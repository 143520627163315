import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * External
 */
import { Col } from "react-bootstrap";
import { RadioGroup, Radio } from "react-radio-group";

/**
 * Internal
 */
import InputValidationMsg from "./../InputValidationMsg";

const defaultProps = {};
const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for dual radio with material design styling
 */
const NewTripleRadioMaterial = ({
  title,
  name,
  selectedValue,
  onChange: newOnChange,
  disabled,
  firstLabel,
  firstValue,
  secondLabel,
  secondValue,
  thirdLabel,
  thirdValue,
  hasValidation,
  validationType,
  hasValidationRecoil,
  validationReason,
  required,
}) => {
  /**
   * Validation Component
   * @type {Object}
   */
  const validation = (
    <InputValidationMsg
      type={validationType}
      visible={hasValidation}
      message={validationReason}
      top={hasValidationRecoil ? "-18px" : ""}
    />
  );
  const onChange = (e) => {
    return newOnChange(parseInt(e.target.value));
  };

  return (
    <div>
      <div className="form-group mb-0">
        <label className="mb-0">{title}</label>
        {required && <label> * </label>}
        <RadioGroup>
          <Col xs={4} className="pd-smaller-left">
            <input
              type="radio"
              name={name}
              disabled={disabled}
              value={firstValue}
              onChange={onChange}
              checked={parseInt(firstValue) === parseInt(selectedValue)}
            />
            <span className="radio-label">{firstLabel}</span>
          </Col>
          <Col xs={4} className="pd-smaller-left">
            <input
              type="radio"
              name={name}
              disabled={disabled}
              value={secondValue}
              onChange={onChange}
              checked={parseInt(secondValue) === parseInt(selectedValue)}
            />
            <span className="radio-label">{secondLabel}</span>
          </Col>
          <Col xs={4} className="pd-smaller-left">
            <input
              type="radio"
              name={name}
              disabled={disabled}
              value={thirdValue}
              onChange={onChange}
              defaultChecked={parseInt(thirdValue) === parseInt(selectedValue)}
            />
            <span className="radio-label">{thirdLabel}</span>
          </Col>
        </RadioGroup>
      </div>
      {validation && validation}
    </div>
  );
};

NewTripleRadioMaterial.defaultProps = defaultProps;
NewTripleRadioMaterial.propTypes = propTypes;

export default React.memo(NewTripleRadioMaterial);
