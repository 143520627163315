import React from 'react';

/**
 * Internal
 */
import PanelMaterial from './../PanelMaterial';
import PanelMaterialTop from './../PanelMaterialTop';
import PanelMaterialBody from '../PanelMaterialBody';
import LeukogramDiagnosisContent from '../LeukogramDiagnosisContent';

/**
 * @author Alessandro Bastos Grandini
 *
 * Leukogram diagnosis panel
 * 
 */
const LeukogramDiagnosisPanel = ( {
  obs,
  diagnosisFn,
  shouldDisable,
  $validation,
  fieldChange,
  $field
} ) =>
  <PanelMaterial className="pt-5" title='Diagnóstico' flat={ true }>
    <PanelMaterialTop title='Diagnóstico' />
    <PanelMaterialBody>
      <LeukogramDiagnosisContent
        obs={ obs }
        diagnosisFn={ diagnosisFn }
        shouldDisable={ shouldDisable }
        $validation={ $validation }
        fieldChange={ fieldChange }
        $field={ $field }
        />
    </PanelMaterialBody>
  </PanelMaterial>

LeukogramDiagnosisPanel.propTypes = {};

export default LeukogramDiagnosisPanel;