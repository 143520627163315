import React from "react";
import * as LifeVet from "./../../../../../components";
import AccessManager from "../../../../../old_components/AccessManager";
import { ACCESS_PROFILES } from "../../../../../consts";

//Icons
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faAngleLeft from "@fortawesome/fontawesome-free-solid/faAngleLeft";
import faCheckSquare from "@fortawesome/fontawesome-free-solid/faCheckSquare";

export const Header = ({
  id,
  formIsInUpdateMode,
  disableAll,
  history,
  openReceiveAndAssistModal,
}: {
  id: number;
  formIsInUpdateMode: boolean;
  disableAll: boolean;
  history: any;
  openReceiveAndAssistModal: () => void;
}) => {
  //
  return (
    <>
      <LifeVet.PanelHeader
        title={formIsInUpdateMode ? `Solicitação nº ${id}` : `Nova Solicitação`}
      >
        <LifeVet.Row className="text-center">
          <LifeVet.Col>
            <LifeVet.Button
              variant="secondary"
              depth="2"
              disabled={disableAll}
              icon={<FontAwesomeIcon size="lg" icon={faAngleLeft} />}
              responsive
              onClick={() => {
                history.goBack();
              }}
            >
              Voltar
            </LifeVet.Button>
            <AccessManager
              allowed={[
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_USER,
              ]}
            >
              <LifeVet.Button
                depth="2"
                onClick={openReceiveAndAssistModal}
                disabled={disableAll}
                variant="secondary"
                icon={<FontAwesomeIcon size="lg" icon={faCheckSquare} />}
                responsive
              >
                Receber e Laudar
              </LifeVet.Button>
            </AccessManager>
          </LifeVet.Col>
        </LifeVet.Row>
      </LifeVet.PanelHeader>
    </>
  );
};
