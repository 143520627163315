import React from "react";

/**
 * Exernal
 */
import classNames from "classnames";

/**
 * Internal
 */

/**
 * Assets
 */
import "./index.css";

/**
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Panel material bottom part. Takes Material Buttons as children
 */
const PanelMaterialTop = ({ children, title }) => {
  const panelClass = title
    ? classNames("panel-title-wrapper")
    : classNames("panel-title-wrapper", "hide");
  return (
    <div className="col-xs-12">
      <div className={"col-xs-12 col-sm-6 no-padding"}>
        <div className={panelClass}>
          <h1 className="panel-title">{title}</h1>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 no-padding">
        <div className="panel-top-wrapper">{children}</div>
      </div>
    </div>
  );
};

export default PanelMaterialTop;
