import React from 'react';

/**
 * External
 */
import { Row, Col } from './../../components/grid';

/**
 * Internal
 */
import InputMaterial from './../InputMaterial';

/**
 * @author Alessandro Bastos Grandini
 *
 *  Leukogram diagnosis fields
 * 
 */
const LeukogramDiagnosisContent = ( {
  obs,
  diagnosisFn,
  shouldDisable,
  $validation,
  fieldChange,
  $field
} ) =>
  <React.Fragment>
    <Row>
      <Col xs={ 1 } className='text-right'>
        <p className='mr-10 cleanItem'>S.B</p>
      </Col>
      <Col xs={ 11 }>
        <InputMaterial
          rows={ 2 }
          name='leukogramDiagnosis'
          md={ 12 }
          fit={ true }
          value={ diagnosisFn() }
          disabled={ true }
        />
      </Col>
    </Row>
    <Row>
      <Col xs={ 1 } className='text-right'>
        <p className='mr-10 cleanItem'>OBS</p>
      </Col>
      <Col xs={ 11 }>
        <InputMaterial
          name='leukogramObs'
          md={ 12 }
          fit={ true }
          value={ obs }
          disabled={ shouldDisable }

          { ...$field( 'leukogramObs', ( event ) => {
            fieldChange( 'leukogramObs', event.target.value );
          } ) }

          hasValidation={ $validation.leukogramObs.show }
          validationReason={ $validation.leukogramObs.error.reason }
        />
      </Col>
    </Row>
  </React.Fragment>

LeukogramDiagnosisContent.propTypes = {};

export default LeukogramDiagnosisContent;