import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import FlatPanelMaterial from "./../FlatPanelMaterial";
import NumberInputMaterial2 from "../NumberInputMaterial2";
import InputMaterial from "../InputMaterial";
import SelectMaterial2 from "../SelectMaterial2";

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a PhysicalExamFields form
 */
const PhysicalExamFields = ({
  volumeFieldName,
  volume,
  volumeHasValidation,
  volumeValidationReason,
  densityFieldName,
  density,
  densityHasValidation,
  densityValidationReason,
  densityRefs,
  colorOptionIdFieldName,
  colorOptionId,
  colorOptionIdHasValidation,
  colorOptionIdValidationReason,
  colorOptions,
  smellOptionIdFieldName,
  smellOptionId,
  smellOptionIdHasValidation,
  smellOptionIdValidationReason,
  smellOptions,
  aspectOptionIdFieldName,
  aspectOptionId,
  aspectOptionIdHasValidation,
  aspectOptionIdValidationReason,
  aspectOptions,
  handleChange,
  handleBlur,
  shouldDisable
}) => (
  <div className="erythrogramForm tight-fields">
    <FlatPanelMaterial title="Exame Físico">
      <div className="no-padding-cols distante-rows">
        <div className="row">
          <div className="col-xs-4">
            <div className="col-xs-5 text-right">
              <p className="mr-20 cleanItem">
                Volume ( <span className="transform-lower">m</span>L )
              </p>
            </div>
            <div className="col-xs-7">
              <NumberInputMaterial2
                title=""
                name={volumeFieldName}
                hasValidationRecoil={false}
                decimalScale={1}
                value={volume}
                disabled={shouldDisable}
                handleChange={handleChange}
                handleBlur={handleBlur}
                hasValidation={volumeHasValidation}
                validationReason={volumeValidationReason}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <div className="col-xs-5 text-right">
              <p className="mr-20 cleanItem">Densidade</p>
            </div>
            <div className="col-xs-7">
              <InputMaterial
                title=""
                name={densityFieldName}
                hasValidationRecoil={false}
                decimalScale={0}
                value={density}
                disabled={shouldDisable}
                handleChange={handleChange}
                handleBlur={handleBlur}
                hasValidation={densityHasValidation}
                validationReason={densityValidationReason}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <div className="col-xs-12">
              <div className="col-xs-11 col-xs-offset-1">
                <p className="cleanItem">
                  Valor normal densidade: {densityRefs}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-4">
            <div className="col-xs-5 text-right">
              <p className="mr-20 cleanItem">Cor</p>
            </div>
            <div className="col-xs-7">
              <SelectMaterial2
                title=""
                name={colorOptionIdFieldName}
                options={colorOptions}
                value={colorOptionId}
                isSearchable={true}
                hasValidationRecoil={false}
                shouldDisable={shouldDisable}
                onChange={handleChange}
                onBlur={handleBlur}
                hasValidation={colorOptionIdHasValidation}
                validationReason={colorOptionIdValidationReason}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <div className="col-xs-5 text-right">
              <p className="mr-20 cleanItem">Odor</p>
            </div>
            <div className="col-xs-7">
              <SelectMaterial2
                title=""
                name={smellOptionIdFieldName}
                options={smellOptions}
                value={smellOptionId}
                isSearchable={true}
                hasValidationRecoil={false}
                shouldDisable={shouldDisable}
                onChange={handleChange}
                onBlur={handleBlur}
                hasValidation={smellOptionIdHasValidation}
                validationReason={smellOptionIdValidationReason}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <div className="col-xs-3 text-right">
              <p className="mr-20 cleanItem">Aspecto</p>
            </div>
            <div className="col-xs-7">
              <SelectMaterial2
                title=""
                name={aspectOptionIdFieldName}
                options={aspectOptions}
                value={aspectOptionId}
                isSearchable={true}
                hasValidationRecoil={false}
                shouldDisable={shouldDisable}
                onChange={handleChange}
                onBlur={handleBlur}
                hasValidation={aspectOptionIdHasValidation}
                validationReason={aspectOptionIdValidationReason}
              />
            </div>
          </div>
        </div>
      </div>
    </FlatPanelMaterial>
  </div>
);

export default PhysicalExamFields;
