import React, { useReducer } from "react";
import { reducer, ACTIONS } from "./reducer";
import { Email } from "./../../types/index";

const defaultEmails: Array<Email> = [];

export const useEmails = () => {
  const [emails, dispatch] = useReducer(reducer, defaultEmails);
  return {
    data: emails,
    methods: {
      add: (email: Array<Email>) =>
        dispatch({ type: ACTIONS.ADD, payload: email }),

      update: (email: Email) =>
        dispatch({ type: ACTIONS.UPDATE, payload: email }),

      remove: (id: number) => dispatch({ type: ACTIONS.REMOVE, payload: id }),

      get: (id: number) => emails.filter((curr) => curr.id === id).pop(),

      reset: () => dispatch({ type: ACTIONS.RESET }),
    },
  };
};
