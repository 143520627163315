import React, { Component } from 'react';

/** Internal */
import OutsourcedExamsListView from './../OutsourcedExamsListView';
import connectWithEndpoint from './requests.js';

/** External */
import { connect as reduxConnect } from 'react-redux';
import { FunctionUtil } from "./../../useful";
import FadeIn from 'react-fade-in';
import debounce from 'lodash/debounce';
import { Redirect } from 'react-router';
import { ACCESS_PROFILES } from '../../assets/js/Consts';

class OutsourcedExamsList extends Component {

  /**
   * Constructor
   */
  constructor( props ) {
    super( props );
    this.filter = this.filter.bind( this );
    this.state = { pages: 1, sorted: null };
    this.onTableDoubleClick = this.onTableDoubleClick.bind( this );
  }

  onTableDoubleClick(currentId, state, rowInfo, column) {
    this.setState({ shouldRedirect: true, redirectToId: rowInfo.original.id });
  }

  /**
   * @author Victor Heringer
   * 
   * Filter breeds table
   * 
   * @param {Object} state 
   * @param {Object} instance 
   */
  filter( state, instance ) {
    
    const filters = { id: '', name: '', examType: '' };

    for (let data in state.filtered) {
      filters[state.filtered[data].id] = state.filtered[data].value;
    }

    const then = ( data, xhr ) => {
      this.setState({
        pages: data.last_page
      });
    };

    this.props.outsourcedExamsSearch( filters, state.page, state.sorted, then );
  }

  /**
   * @author Sergio Pallet
   *
   * Check if should disable all fields
   *
   * @return {Boolean}
   */
  shouldDisableAll() {          
    const haveNoAccess =
      this.props.access_profile == ACCESS_PROFILES.CLINIC_ADMINISTRATOR ||
      this.props.access_profile == ACCESS_PROFILES.CLINIC_USER ||
      this.props.access_profile == ACCESS_PROFILES.UNIT_USER ||      
      this.props.access_profile == ACCESS_PROFILES.UNIT_ADMINISTRATOR;
    const pending =
      this.props.outsourcedExams && this.props.outsourcedExams.pending;
    return haveNoAccess || pending;
  }

  render() {

    const { user, outsourcedExamseeds, access_profile, history } = this.props;

    if (this.state.shouldRedirect) {
      const id = this.state.redirectToId;
      return <Redirect to={`/exame-terceirizado/${id}`} />
    }

    return (
      <FadeIn>
        <OutsourcedExamsListView
          user={ user }
          objects={ outsourcedExamseeds }
          filter={ debounce( this.filter.bind( this ), 500 ) }
          accessProfile={ access_profile }
          pages={ this.state.pages }
          onTableDoubleClick={ this.onTableDoubleClick }
          shoudDisable={this.shouldDisableAll()}
          history={history}
        />
      </FadeIn>
    );
  }
}

const mapStateToProps = function ( state ) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user,
  }
}

const connectWithRedux = reduxConnect( mapStateToProps );

export default FunctionUtil.compose(
  connectWithRedux,
  connectWithEndpoint
)( OutsourcedExamsList );