import isMobile from "../../services/is-mobile";

/**
 * Default user state
 * @type {Object}
 */
const defaultState = {
  is_mobile: isMobile,
};

export default function mobileReducer(state = defaultState) {
  return {
    ...state,
  };
}
