import React from 'react'

import { Route, Switch } from "react-router-dom"
import { AntibioticForm, NotificationSystemType } from '../AntibioticForm'
import { AntibioticList } from '../AntibioticList'

interface AntibioticSectionProps {
  notificationSystem: NotificationSystemType
}

export function AntibioticSection(props: AntibioticSectionProps) {
   return (
      <Switch>
       <Route exact path='/antibioticos/novo'>
         <AntibioticForm {...props} />
       </Route>

       <Route exact path='/antibioticos/:id'>
         <AntibioticForm {...props} />
       </Route>

       <Route exact path='/antibioticos'>
         <AntibioticList />
       </Route>
      </Switch>
   )
}