import React from "react";

/** Internal */
import ButtonSaveMaterial from "../ButtonSaveMaterial";
import { pricingTableModalValidation } from "../PricingTableList/validation";
import { validated } from "react-custom-validation";
import update from "immutability-helper";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";

/** External */
import { Row, Col, Modal } from "react-bootstrap";
import ReactTable from "react-table-v6";
import debounce from "lodash/debounce";
import ButtonDefaultMaterial from "../ButtonDefaultMaterial";
import { SUBMIT_MODAL_KEY } from "../../assets/js/Consts";

const PricingTableModal = ({
  data,
  methods,
  $field,
  $validation,
  $submit,
  $fieldEvent,
}) => {
  const { pricingTables, pricingTableModalShow, fields } = data;

  const submitData = methods.submitPricingTableModalData();

  const config = update(
    {
      defaultPageSize: 5,
      defaultSorted: [{ id: "id", desc: true }],
      pageSize: 15,
      resizable: false,
      showPagination: true,
      noDataText: "Nenhum registro".toUpperCase(),
      loadingText: "Carregando",
      className: "-striped -highlight",
      previousText: "Anterior",
      nextText: "Próximo",
      pageText: "Página",
      ofText: "de",
      rowsText: "linhas",
      columns: [],
      showPageSizeOptions: false,
      pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
    },
    {
      data: {
        $set:
          pricingTables && pricingTables.value ? pricingTables.value.data : [],
      },
      loading: {
        $set: pricingTables && pricingTables.pending,
      },
      filterable: {
        $set: true,
      },
      manual: {
        $set: true,
      },
      onFetchData: {
        $set: debounce(methods.pricingTableModalFilters, 500),
      },
      pages: {
        $set: data.pricingTableModal.pages,
      },
      pageSize: {
        $set: 5,
      },
      defaultSorted: {
        $set: [{ id: "id", desc: false }],
      },
      columns: {
        $set: [
          {
            Header: "Código",
            maxWidth: 100,
            accessor: "id",
            className: "centeredText",
          },
          {
            Header: "Nome da tabela",
            accessor: "name",
            className: "centeredText",
          },
        ],
      },
    }
  );

  return (
    <Modal
      show={pricingTableModalShow}
      onHide={methods.handleClosePricingTableModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Tabela de Preços</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <ReactTable {...config} />
          </Col>
        </Row>
        <Row className="mt-20">
          <LVInputTextUpper
            md={8}
            id="modalPricingTableName"
            name="modalPricingTableName"
            title="Nova Tabela"
            value={fields.modalPricingTableName}
            {...$field("modalPricingTableName", (event) => {
              methods.fieldChange("modalPricingTableName", event.target.value);
            })}
            hasValidation={
              $validation &&
              $validation.modalPricingTableName.show &&
              !$validation.modalPricingTableName.isValid
            }
            validationReason={
              $validation && $validation.modalPricingTableName.error.reason
            }
            isValid={$validation && $validation.modalPricingTableName.isValid}
          />
          <Col md={4}>
            <ButtonDefaultMaterial
              onKeyPress={() =>
                $submit(() => methods.handleSubmitPricingTable($fieldEvent))
              }
              keyCode={SUBMIT_MODAL_KEY}
              title={submitData.btnTitle}
              type="highlight"
              requestingTitle={submitData.placeholder}
              className="mt-15"
              disabled={submitData.pending}
              requesting={submitData.pending}
              onClick={() =>
                $submit(() => methods.handleSubmitPricingTable($fieldEvent))
              }
              block
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const withValidation = validated(pricingTableModalValidation);

export default withValidation(PricingTableModal);
