import React, { Component } from "react";

import * as LifeVet from "../../../../../components";
import { Row, Col } from "../../../../../components/grid";
import ButtonDefaultMaterial from "../../../../ButtonDefaultMaterial";
import NumberInputMaterial from "../../../../NumberInputMaterial";
import InputMaterial from "../../../../InputMaterial";
import Checkbox from "../../../../../components/checkbox";
import NumberDisplay from "../../../../NumberDisplay";
import LVTable from "../../../../../old_components/LVTable";
import { PAYMENT_METHODS, REQUEST_EXAM_STATES } from "../../../../../consts";
import { StringUtil } from "../../../../../useful";

import { PromiseUtil } from "./../../../../../useful";

import styles from "./index.module.css";

/**
 * @author Alessandro Bastos Grandini
 *
 * Renders report link icon in request exams table
 *
 * @param {Object} data Represents an exam request in the table
 *
 * @return {Object}
 */
const renderPrintButton = (data, printSingleReport, isClinicUserType) => {
  const releasedExamsTypes = [
    REQUEST_EXAM_STATES.REQUEST_EXAM_STATE_ID_BY_NAME.ATENDIDO_E_LIBERADO,
    REQUEST_EXAM_STATES.REQUEST_EXAM_STATE_ID_BY_NAME.FATURADO,
  ];

  const clinicUserTypeIsAccessingNonAttendedExam = isClinicUserType
    ? !releasedExamsTypes.includes(data.original.request_exam_state)
    : false;

  const isEnabled =
    data.original &&
    !clinicUserTypeIsAccessingNonAttendedExam &&
    data.original.report
      ? true
      : false;
  const disabledClass = !isEnabled ? styles.disableTableBtn : "";

  return (
    <button
      disabled={!isEnabled}
      className={`btn-table-action btn-ex-selecionados ${disabledClass}`}
      onClick={() => printSingleReport(data.original.report.id)}
    >
      <i className="fa fa-print" />
    </button>
  );
};

const renderCheckbox = (data, onChangeRequestExamIsPaidField, disableAll) => {
  return (
    <Checkbox
      checked={data.original.is_paid}
      disabled={disableAll}
      name="is_paid"
      onChange={(event) =>
        onChangeRequestExamIsPaidField(data.index, !data.original.is_paid)
      }
    />
  );
};

const renderNumberInput = (name, value, fieldChange, isInteger, disabled) => (
  <Row>
    <Col>
      <NumberInputMaterial
        title={null}
        name={name.toString()}
        md={12}
        allowNegative={false}
        isNumericString={isInteger ? false : true}
        block={true}
        thousandSeparator={"."}
        decimalSeparator={","}
        decimalScale={isInteger ? 0 : 2}
        fieldChange={fieldChange}
        value={value}
        disabled={disabled}
        className="text-center"
      />
    </Col>
  </Row>
);

const renderTextInput = (name, value, fieldChange, disabled) => (
  <Row>
    <Col>
      <InputMaterial
        title={null}
        name={name.toString()}
        md={12}
        block={true}
        onChange={(event) => fieldChange(event.target.name, event.target.value)}
        value={value}
        disabled={disabled}
        className="text-center"
      />
    </Col>
  </Row>
);
/**
 * @author Alessandro Bastos Grandini
 *
 * Renders report link icon in request exams table
 *
 * @param {Object} data Represents an exam request in the table
 *
 * @return {Object}
 */
const renderDeleteButton = (
  data,
  removeReport,
  cancelReport,
  shouldDisableExamsTableFields
) => {
  const isSaved = data.original && data.original.id > 0 ? true : false;

  if (isSaved) {
    return shouldDisableExamsTableFields() ? (
      ""
    ) : (
      <button
        className={"btn-table-action btn-ex-selecionados"}
        onClick={() => cancelReport(data.original.id)}
      >
        <i className="fa fa-times-circle" style={{ color: "red" }} />
      </button>
    );
  }

  return (
    <button
      className={"btn-table-action btn-ex-selecionados"}
      onClick={() => removeReport(data.original)}
    >
      <i className="fa fa-trash" />
    </button>
  );
};

const renderReceiveDate = (data) => {
  const date = StringUtil.formatDateUi(data.original.start_date_time);
  return date === "Invalid date" ? "" : date;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Renders the sample code field in the request samples table
 *
 * @return {Object}
 *
 */
const renderMainPrintButton = (data) => {
  return (
    <button className="btn-ex-selecionados">
      <i className="fa fa-print" />
    </button>
  );
};

const renderDeadLine = (data, onChangeField, disableAll) => {
  const onChange = (id, value) => {
    onChangeField(id, "final_dead_line", value);
  };

  if (data.original.id > 0) {
    return renderNumberInput(
      data.original.id,
      data.original.final_dead_line,
      onChange,
      true,
      disableAll
    );
  }

  const days = data.original.final_dead_line || data.original.deadline_in_days;
  const withS = days > 1 ? "s" : "";
  return `${days} dia${withS}`;
};

/**
 * @author Alessandro Bastos Grandini
 * @author Anderson Souza
 *
 * Renders the exam price info
 *
 * @param {Object} data Represents a request sample in the table
 * @param {Object} fieldChange The callback function used to change the state
 * @param {Object} disableAll Determines if the input will be disabled
 *
 * @return {Object}
 *
 */
const renderPriceInfo = (data, fieldChange, disableAll) => {
  const onChange = (id, value) => {
    fieldChange(id, "final_price", value);
  };

  if (data.original.id > 0) {
    return renderNumberInput(
      data.original.id,
      data.original.final_price,
      onChange,
      false,
      disableAll
    );
  }

  return (
    <NumberDisplay
      prefix={"R$ "}
      value={data.original.final_price || data.original.price}
      decimalScale={2}
      isNumericString={true}
    />
  );
};

/**
 * @author Anderson Souza
 *
 * Renders the exam observation
 *
 * @param {Object} data Represents a request sample in the table
 * @param {Object} fieldChange The callback function used to change the state
 * @param {Object} disableAll Determines if the input will be disabled
 *
 * @return {Object}
 *
 */
const renderObservation = (data, fieldChange, disableAll) => {
  const onChange = (id, value) => {
    fieldChange(id, "obs", value);
  };
  if (data.original.id > 0) {
    return renderTextInput(
      data.original.id,
      data.original.obs,
      onChange,
      disableAll
    );
  }
  return data.original.obs;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Request exams and samples tables
 *
 */
class ExamsSection extends Component {
  /**
   * @author Alessandro Bastos Grandini
   *
   * Renders the sample code field in the request samples table
   *
   * @param {Object} data Represents a request sample in the table
   *
   * @return {Object}
   *
   */
  renderSampleCodeField = (props) => {
    const index = props.index.toString();
    const code = props.original.code;
    const fieldName = `requestSample-${index}`;
    return (
      <NumberInputMaterial
        key={index}
        name={fieldName}
        className={styles.centered}
        md={12}
        hasValidationRecoil={false}
        fit={true}
        disabled={this.props.isReadOnly}
        block={false}
        inputGroup={true}
        allowNegative={false}
        fieldChange={this.props.onChangeSampleCode}
        value={code}
        shouldDisableThousandSeparator={true}
        {...this.props.$field(fieldName)}
        hasValidation={this.props.$validation[fieldName].show}
        validationReason={this.props.$validation[fieldName].error.reason}
        setFieldRef={this.props.setRequestExamSampleFieldRef}
      />
    );
  };

  render() {
    const examsConfig = {
      pageSize:
        this.props.fields.requestExams.length > 5
          ? this.props.fields.requestExams.length
          : 5,
      loading: false,
      onDoubleClickRow: this.props.onTableDoubleClick,
      onSingleClickRow: this.props.onTableSingleClick,
      showPagination: false,
      data: this.props.fields.requestExams,
      style: { width: "100%", overflowX: "scroll" },
      columns: [
        {
          Header: "",
          sortable: false,
          show: this.props.fields.id === 0 ? false : true,
          minWidth: 40,
          Cell: (data) =>
            renderDeleteButton(
              data,
              this.props.removeReportFromTable,
              this.props.cancelReport,
              this.props.shouldDisableExamsTableFields
            ),
        },
        {
          Header: renderMainPrintButton,
          sortable: false,
          show: this.props.fields.id === 0 ? false : true,
          minWidth: 40,
          Cell: (data) =>
            renderPrintButton(
              data,
              this.props.printSingleReport,
              this.props.loggedUserIsFromClinic
            ),
        },
        {
          Header: "Nome do Exame",
          minWidth: 200,
          accessor: "name",
          Cell: (data) => (
            <div style={
              {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                margin: '0 auto'
              }
            }>
              {
                Number(data.original.lab_exam.exam.is_outsourced) === 1 && (
                  <strong>
                    ANEXO - {''}
                  </strong>
                 )
              }
              
              {data.value}
            </div>
          )
        },
        {
          Header: "Status",
          accessor: "status",
          minWidth: 200,
          Cell: (data) => {
            return REQUEST_EXAM_STATES.getRequestExamStateNameById(
              data.original.request_exam_state,
              this.props.isClinicUserType
            );
          },
        },
        {
          Header: "Prazo (dias)",
          minWidth: 80,
          Cell: (data) =>
            renderDeadLine(
              data,
              this.props.updateRequestExamField,
              this.props.shouldDisableExamsTableFields()
            ),
        },
        {
          Header: "Preço (RS)",
          minWidth: 80,
          Cell: (data) =>
            renderPriceInfo(
              data,
              this.props.updateRequestExamField,
              this.props.shouldDisableExamsTableFields()
            ),
        },
        {
          Header: "Pago",
          accessor: "paid",
          minWidth: 100,
          Cell: (data) => {
            return renderCheckbox(
              data,
              this.props.onChangeRequestExamIsPaidField,
              this.props.shouldDisableExamsTableFields()
            );
          },
        },
        {
          Header: "Observação",
          minWidth: 300,
          Cell: (data) =>
            renderObservation(
              data,
              this.props.updateRequestExamField,
              this.props.shouldDisableExamsTableFields()
            ),
        },

        {
          Header: "Forma de Pagamento",
          accessor: "payment",
          minWidth: 200,
          Cell: (data) =>
            renderPaymentMethodSelect(
              data,
              this.props.updateRequestExamField,
              this.props.shouldDisableExamsTableFields()
            ),
        },
        {
          Header: "Recebimento",
          accessor: "receivement",
          minWidth: 200,
          Cell: (data) => renderReceiveDate(data),
        },
      ],
    };

    const renderPaymentMethodSelect = (data, fieldChange, disable) => {
      const onChange = (event) => {
        fieldChange(data.original.id, "payment_method", event.id);
      };
      return (
        <LifeVet.Select
          id="exam_select"
          name="labExams"
          options={PAYMENT_METHODS.values}
          value={data.original.payment_method}
          disabled={disable}
          placeholder={"Escolha a forma de pagamento"}
          theValueIsAnId={true}
          multi={false}
          onChange={onChange}
          clearable={false}
          closeOnSelect={true}
        />
      );
    };

    /**
     * Default configurations for samples table
     * @type {Object}
     */
    const samplesConfig = {
      pageSize:
        this.props.fields.requestSamples.length > 5
          ? this.props.fields.requestSamples.length
          : 5,
      data: this.props.fields.requestSamples,
      showPagination: false,
      columns: [
        {
          Header: "Material de Amostra",
          accessor: "name",
          minWidth: 50,
        },
        {
          Header: (props) => (
            <span className="float-left">Identificação *</span>
          ),
          minWidth: 40,
          className: "float-left",
          Cell: this.renderSampleCodeField,
        },
      ],
    };

    const {
      examTypesPromise,
      labExamsPromise,
      isExamTypesSelected,
      disableAll,
    } = this.props;
    const examTypes = PromiseUtil.extractValue(examTypesPromise, []);
    const labExamsDefault = isExamTypesSelected
      ? PromiseUtil.extractValue(labExamsPromise, [])
      : [];
    
    const labExams = labExamsDefault.map(exam => {
      if (exam.exam.is_outsourced) {
        exam.name = exam.exam.name + " (ANEXO)";
      }
      return exam
    });
    
    const disableLabExams =
      disableAll ||
      (labExamsPromise && labExamsPromise.pending) ||
      !isExamTypesSelected;

    let labExamPlaceholder =
      labExamsPromise && labExamsPromise.pending ? "Carregando.." : "Selecione";

    labExamPlaceholder = isExamTypesSelected ? labExamPlaceholder : "";

    return (
      <React.Fragment>
        <Row>
          {" "}
          <hr />{" "}
        </Row>
        <Row>
          <Col md={12} className="no-padding">
            <LifeVet.Col sm={4}>
              <LifeVet.FormGroup
                errorMessage={
                  this.props.$validation.examTypeId.show &&
                  this.props.$validation.examTypeId.error.reason
                }
              >
                <LifeVet.InputLabel>Tipo do Exame</LifeVet.InputLabel>
                <LifeVet.Select
                  id="exam_type_select"
                  name="examTypeId"
                  options={examTypes}
                  value={this.props.fields.examTypeId}
                  searchable={true}
                  theValueIsAnId={true}
                  disabled={
                    disableAll ||
                    (examTypesPromise && examTypesPromise.pending) ||
                    this.props.isReadOnly ||
                    !this.props.isCustomerSelected(this.props.fields.customerId)
                  }
                  placeholder={
                    examTypesPromise.pending ? "Carregando.." : "Selecione"
                  }
                  {...this.props.$field("examTypeId", (value) => {
                    this.props.onSelectExamType(value.id);
                  })}
                  setRef={this.props.setRef}
                />
              </LifeVet.FormGroup>
            </LifeVet.Col>

            <LifeVet.Col sm={5}>
              <LifeVet.FormGroup
                errorMessage={
                  this.props.$validation.labExams.show &&
                  this.props.$validation.labExams.error.reason
                }
              >
                <LifeVet.InputLabel>Exames</LifeVet.InputLabel>
                <LifeVet.Select
                  id="exam_select"
                  name="labExams"
                  options={labExams}
                  value={this.props.fields.labExams}
                  searchable={true}
                  disabled={disableLabExams}
                  placeholder={labExamPlaceholder}
                  {...this.props.$field("labExams", (value) => {
                    value = value ? value : [];
                    this.props.updateField("labExams", value);
                  })}
                  multi={true}
                  clearable={true}
                  closeOnSelect={false}
                  setRef={this.props.setRef}
                />
              </LifeVet.FormGroup>
            </LifeVet.Col>

            <Col md={3}>
              <ButtonDefaultMaterial
                id="include_exam_btn"
                onClick={this.props.addLabExams}
                type="highlight"
                title="Incluir Exame"
                className="btn-form mt-20"
                block={true}
                disabled={disableAll || this.props.isReadOnly}
              />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="text-center">
              <h5 className="table-title">Exames Selecionados</h5>
            </div>
            <LVTable
              className={"ReactTable-small"}
              data={examsConfig.data}
              style={examsConfig.style}
              columns={examsConfig.columns}
              loading={examsConfig.loading}
              pageSize={examsConfig.pageSize}
              sortable={examsConfig.sortable}
              showPagination={examsConfig.showPagination}
              onDoubleClickRow={examsConfig.onDoubleClickRow}
              onClickRow={examsConfig.onSingleClickRow}
              shouldDisableRowHighlight={true}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="text-center">
              <h5 className="table-title">Materiais de Amostra</h5>
            </div>
            <LVTable
              className={"ReactTable-small"}
              data={samplesConfig.data}
              style={samplesConfig.style}
              columns={samplesConfig.columns}
              loading={samplesConfig.loading}
              pageSize={samplesConfig.pageSize}
              sortable={samplesConfig.sortable}
              resizable={samplesConfig.resizable}
              showPagination={samplesConfig.showPagination}
              shouldPaintRowOnClick={false}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ExamsSection;
