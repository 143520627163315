import React from "react";

/** Internal */
import { sampleModalValidation } from "./../ExamSampleList/validation";
import { validated } from "react-custom-validation";
import TableButtonEditMaterial from "./../TableButtonEditMaterial";
import update from "immutability-helper";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";

/** External */
import { Row, Col, Modal } from "react-bootstrap";
import ReactTable from "react-table-v6";
import debounce from "lodash/debounce";
import ButtonDefaultMaterial from "../ButtonDefaultMaterial";
import { SUBMIT_MODAL_KEY } from "../../assets/js/Consts";

const SampleModal = ({
  data,
  methods,
  $field,
  $validation,
  $submit,
  $fieldEvent
}) => {
  const { samples, show, fields } = data;

  let config = {
    defaultPageSize: 5,
    defaultSorted: [{ id: "id", desc: true }],
    pageSize: 15,
    resizable: false,
    showPagination: true,
    noDataText: "Nenhum registro".toUpperCase(),
    loadingText: "Carregando",
    className: "-striped -highlight",
    previousText: "Anterior",
    nextText: "Próximo",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    columns: [],
    showPageSizeOptions: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100]
  };

  config = update(config, {
    data: {
      $set: samples && samples.value ? samples.value.data : []
    },
    loading: {
      $set: samples && samples.pending
    },
    filterable: {
      $set: true
    },
    manual: {
      $set: true
    },
    onFetchData: {
      $set: debounce(methods.sampleModalFilters, 500)
    },
    pages: {
      $set: data.sampleModalTable.pages
    },
    pageSize: {
      $set: 5
    }
  });

  /**
   * @author Victor Heringer
   *
   * Render the edit button
   *
   * @param {Object} data Table data, including id to fetch user
   *
   * @return {Object}
   */
  const renderEditButton = data => {
    return <TableButtonEditMaterial onClick={() => {}} disabled={false} />;
  };

  config.columns = [
    /*{
    Header: '',
    sortable: false,
    maxWidth: 60,
    className: "centeredText",
    Cell: data => renderEditButton( data ),
    filterable: false
  },*/ {
      Header: "Código",
      sortable: true,
      maxWidth: 100,
      accessor: "id",
      className: "centeredText",
      filterable: true
    },
    {
      Header: "Nome do material de amostra",
      sortable: true,
      accessor: "name",
      className: "centeredText",
      filterable: true
    }
  ];

  const defaultSorted = [{ id: "id", desc: false }];

  const handleSave = function(handleSubmit, invalidNotification) {
    return e => {
      $submit(() => handleSubmit($fieldEvent));
    };
  };

  const submitData = methods.submitModalData();

  return (
    <Modal show={show} onHide={methods.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Materiais de Amostra</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <ReactTable
              filterable={config.filterable}
              manual={config.manual}
              onFetchData={config.onFetchData}
              pages={config.pages}
              data={config.data}
              ofText={config.ofText}
              columns={config.columns}
              loading={config.loading}
              pageSize={config.pageSize}
              rowsText={config.rowsText}
              pageText={config.pageText}
              sortable={config.sortable}
              nextText={config.nextText}
              resizable={config.resizable}
              className={config.className}
              noDataText={config.noDataText}
              loadingText={config.loadingText}
              previousText={config.previousText}
              defaultSorted={config.defaultSorted}
              showPagination={config.showPagination}
              defaultPageSize={config.pageSize}
              showPageSizeOptions={config.showPageSizeOptions}
              defaultSorted={defaultSorted}
            />
          </Col>
        </Row>
        <Row className="mt-20">
          <LVInputTextUpper
            md={8}
            required
            id="modalSampleName"
            name="modalSampleName"
            title="Novo Material de amostra"
            value={fields.modalSampleName}
            {...$field("modalSampleName", event => {
              methods.fieldChange("modalSampleName", event.target.value);
            })}
            hasValidation={
              $validation &&
              $validation.modalSampleName.show &&
              !$validation.modalSampleName.isValid
            }
            validationReason={
              $validation && $validation.modalSampleName.error.reason
            }
            isValid={$validation && $validation.modalSampleName.isValid}
          />
          <Col md={4}>
          <ButtonDefaultMaterial
              title={submitData.btnTitle}
              onKeyPress={handleSave(methods.handleSubmitSample)}
              keyCode={SUBMIT_MODAL_KEY}
              type="highlight"
              requestingTitle={submitData.placeholder}
              className="mt-15"
              disabled={submitData.pending}
              requesting={submitData.pending}
              onClick={handleSave(methods.handleSubmitSample)}
              block
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const withValidation = validated(sampleModalValidation);

export default withValidation(SampleModal);
