import React from "react";
import PropTypes from "prop-types";

/** External */
import { connect } from "react-redux";

/**
 * @author Victor Heringer
 *
 * Check if an user can access a component
 *
 * @param  {number} profile Perfil de acesso do usuário
 * @param  {array}  allowed Array de quais perfis tem acesso
 *
 * @return {bool}           True se tiver acesso
 */
const hasAccess = (profile, allowed) => {
  return allowed.indexOf(profile) > -1;
};

/**
 * @author Victor Heringer
 *
 * Wrapper for input text with material design styling
 */
const AccessManager = ({ profile, allowed, notAllowedComponent, children }) => {
  return hasAccess(profile, allowed) ? children : notAllowedComponent;
};

AccessManager.defaultProps = {
  allowed: [],
  notAllowedComponent: null,
};

const mapStateToProps = function (state) {
  return {
    profile: state.userReducer.access_profile,
  };
};

export default connect(mapStateToProps)(AccessManager);
