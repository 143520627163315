import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal
 */
import SelectMaterial2 from './../SelectMaterial2';
import TextAreaMaterial2 from './../TextAreaMaterial2';
import { Row, Col } from './../../components/grid';

/**
 * External
 */

const propTypes = {
  shouldDisable: PropTypes.bool
};

const defaultProps = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Erythrogram diagnosis panel
 * 
 */
const FecesMacroscopicFields = ( {
  fecesColorOptionId,
  fecesColorOptionIdFieldName,
  fecesColorOptions,
  isFecesColorOptionsLoading,
  fecesColorOptionIdHasValidation,
  fecesColorOptionIdValidationReason,
  fecesSmellOptionId,
  fecesSmellOptionIdFieldName,
  fecesSmellOptions,
  isFecesSmellOptionsLoading,
  fecesSmellOptionIdHasValidation,
  fecesSmellOptionIdValidationReason,
  fecesAspectOptionId,
  fecesAspectOptionIdFieldName,
  fecesAspectOptions,
  isFecesAspectOptionsLoading,
  fecesAspectOptionIdHasValidation,
  fecesAspectOptionIdValidationReason,
  fecesConsistencyOptionId,
  fecesConsistencyOptionIdFieldName,
  fecesConsistencyOptions,
  isFecesConsistencyOptionsLoading,
  fecesConsistencyOptionIdHasValidation,
  fecesConsistencyOptionIdValidationReason,
  macroscopicParasitesFieldName,
  macroscopicParasites,
  macroscopicParasitesHasValidation,
  macroscopicParasitesValidationReason,
  shouldDisable,
  handleChange,
  handleBlur
} ) =>
  <React.Fragment>
    <Row className="mb-10">
      <Col md={ 3 }>
        <SelectMaterial2
          title="Cor"
          hasValidationRecoil
          name={ fecesColorOptionIdFieldName }
          options={ fecesColorOptions }
          value={ fecesColorOptionId }
          isSearchable={ true }
          shouldDisable={ shouldDisable }
          isLoading={ isFecesColorOptionsLoading }
          onChange={ handleChange }
          onBlur={ handleBlur }
          hasValidation={ fecesColorOptionIdHasValidation }
          validationReason={ fecesColorOptionIdValidationReason } />
      </Col>
      <Col md={ 3 }>
        <SelectMaterial2
          title="Odor"
          md={ 3 }
          hasValidationRecoil
          name={ fecesSmellOptionIdFieldName }
          options={ fecesSmellOptions }
          value={ fecesSmellOptionId }
          isSearchable={ true }
          shouldDisable={ shouldDisable }
          isLoading={ isFecesSmellOptionsLoading }
          onChange={ handleChange }
          onBlur={ handleBlur }
          hasValidation={ fecesSmellOptionIdHasValidation }
          validationReason={ fecesSmellOptionIdValidationReason } />
      </Col>
      <Col md={ 3 }>
        <SelectMaterial2
          title="Aspecto"
          hasValidationRecoil
          name={ fecesAspectOptionIdFieldName }
          options={ fecesAspectOptions }
          value={ fecesAspectOptionId }
          isSearchable={ true }
          shouldDisable={ shouldDisable }
          isLoading={ isFecesAspectOptionsLoading }
          onChange={ handleChange }
          onBlur={ handleBlur }
          hasValidation={ fecesAspectOptionIdHasValidation }
          validationReason={ fecesAspectOptionIdValidationReason } />
      </Col>
      <Col md={ 3 }>
        <SelectMaterial2
          title="Consistência"
          hasValidationRecoil
          name={ fecesConsistencyOptionIdFieldName }
          options={ fecesConsistencyOptions }
          value={ fecesConsistencyOptionId }
          isSearchable={ true }
          shouldDisable={ shouldDisable }
          isLoading={ isFecesConsistencyOptionsLoading }
          onChange={ handleChange }
          onBlur={ handleBlur }
          hasValidation={ fecesConsistencyOptionIdHasValidation }
          validationReason={ fecesConsistencyOptionIdValidationReason } />
      </Col>
    </Row>
    <Row className="mb-10">
      <Col md={ 12 }>
        <TextAreaMaterial2
          title={ 'Parasitas Macroscópicos' }
          name={ macroscopicParasitesFieldName }
          value={ macroscopicParasites }
          shouldDisable={ shouldDisable }
          handleChange={ handleChange }
          handleBlur={ handleBlur }
          hasValidation={ macroscopicParasitesHasValidation }
          validationReason={ macroscopicParasitesValidationReason } />
      </Col>
    </Row>
  </React.Fragment>

FecesMacroscopicFields.defaultProps = defaultProps;
FecesMacroscopicFields.propTypes = propTypes;

export default FecesMacroscopicFields;