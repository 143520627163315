import React, { useEffect, useMemo, useState } from "react";
import { SELECT_TEXT } from "../../../../../assets/js/Consts.js";
import * as LifeVet from "../../../../../components";
import { Customer } from "./../../../../../types/index";
import { CustomerConversor } from "./../../../../../services/request-conversor/old-to-new/index";

export const CustomerSelect = ({
  data,
  errors,
  touched,
  handleChange,
  handleBlur,
  disable,
  customersFetching,
}: {
  data: Customer;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  disable: boolean;
  customersFetching: any;
}) => {
  const defaultRef: any = null;
  const [ref, setRef] = useState(defaultRef);
  let options: any = customersFetching.fulfilled ? customersFetching.data : [];

  errors = errors && (errors.id || errors.name);

  useEffect(() => {
    if (customersFetching.fulfilled) ref && ref.focus();
  }, [customersFetching.fulfilled]);

  useEffect(() => {
    if (customersFetching.fulfilled && data.id === 0) 
      ref && ref.focus();
    
  }, [data]);

  return (
    <LifeVet.FormGroup noError>
      <LifeVet.InputLabel hasError={touched && errors}>
        Cliente *
      </LifeVet.InputLabel>
      <LifeVet.Select
        options={options}
        value={data.id}
        searchable={true}
        clearable={false}
        disabled={disable}
        theValueIsAnId={true}
        placeholder={
          customersFetching.pending ? SELECT_TEXT.LOADING : SELECT_TEXT.ACTIVE
        }
        onChange={(option: any) =>
          handleChange("customer", CustomerConversor(option))
        }
        onBlur={() => handleBlur("customer")}
        setRef={(ref) => setRef(ref)}
        onKeyDown
        errorMessage={touched && errors}
      />
    </LifeVet.FormGroup>
  );
};
