import React, { useEffect } from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import FlatPanelMaterial from "./../FlatPanelMaterial";
import FecesMacroscopicFields from "./../FecesMacroscopicFields";
import TextAreaPanel2 from "./../TextAreaPanel2";
import ReportPanelMaterial from "./../ReportPanelMaterial";

/** External */
import { Col, Row } from "./../../components/grid";
import { MultiCheckbox } from "../../components/result-group";
import { Checkbox } from "../../components";
import { EXAM_ID_BY_NAME } from "../../consts/exams";
import CheckboxInputMaterial from "../CheckboxInputMaterial";
import CheckboxMaterial from "../CheckboxMaterial";
import InputMaterial from "../InputMaterial";

const ParasitologicalFecesEpgReportFormView = ({
  data: {
    isUpdate,
    fields,
    fecesColorOptionsPromise,
    fecesSmellOptionsPromise,
    fecesAspectOptionsPromise,
    fecesConsistencyOptionsPromise,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    shouldSendEmail,
    checkState,
    checkboxes,
    result,
  },
  methods: {
    fieldChange,
    handleChange,
    handleBlur,
    onSubmit,
    $field,
    handleSendReportEmail,
    cancelReport,
    stopReport,
    multiBoxChange,
    handleCheckedResultChange,
  },
}) => {
  useEffect(() => {
    if (
      fields.macroscopicParasites &&
      fields.macroscopicParasites.toLowerCase() === "ausentes"
    ) {
      fieldChange("absent", true);
    } else {
      fieldChange("absent", false);
    }
  }, [fields.macroscopicParasites]);
  return (
    <ReportPanelMaterial
      title="Exame Parasitológico de Fezes Frescas"
      backLink={backLink}
      shouldDisable={disableAll}
      isUpdate={isUpdate}
      isRequesting={submitPromise && submitPromise.pending}
      handleSubmit={onSubmit}
      handleSendReportEmail={handleSendReportEmail}
      shouldSendEmail={shouldSendEmail}
      cancelReport={cancelReport}
      stopReport={stopReport}
    >
      <Row>
        <Col md={12}>
          <ReportHeader
            data={reportHeaderData}
            fields={fields}
            fieldChange={fieldChange}
            $field={$field}
            $validation={$validation}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FlatPanelMaterial title="Exame Macroscópico">
            <FecesMacroscopicFields
              fecesColorOptionId={fields.fecesColorOptionId}
              fecesColorOptionIdFieldName="fecesColorOptionId"
              fecesColorOptions={
                fecesColorOptionsPromise.fulfilled
                  ? fecesColorOptionsPromise.value
                  : []
              }
              isFecesColorOptionsLoading={
                true === fecesColorOptionsPromise.pending
              }
              fecesColorOptionIdHasValidation={
                $validation && $validation.fecesColorOptionId.show
              }
              fecesColorOptionIdValidationReason={
                $validation && $validation.fecesColorOptionId.error.reason
              }
              fecesSmellOptionId={fields.fecesSmellOptionId}
              fecesSmellOptionIdFieldName="fecesSmellOptionId"
              fecesSmellOptions={
                fecesSmellOptionsPromise.fulfilled
                  ? fecesSmellOptionsPromise.value
                  : []
              }
              isFecesSmellOptionsLoading={
                true === fecesSmellOptionsPromise.pending
              }
              fecesSmellOptionIdHasValidation={
                $validation.fecesSmellOptionId.show
              }
              fecesSmellOptionIdValidationReason={
                $validation.fecesSmellOptionId.error.reason
              }
              fecesAspectOptionId={fields.fecesAspectOptionId}
              fecesAspectOptionIdFieldName="fecesAspectOptionId"
              fecesAspectOptions={
                fecesAspectOptionsPromise.fulfilled
                  ? fecesAspectOptionsPromise.value
                  : []
              }
              isFecesAspectOptionsLoading={
                true === fecesAspectOptionsPromise.pending
              }
              fecesAspectOptionIdHasValidation={
                $validation.fecesAspectOptionId.show
              }
              fecesAspectOptionIdValidationReason={
                $validation.fecesAspectOptionId.error.reason
              }
              fecesConsistencyOptionId={fields.fecesConsistencyOptionId}
              fecesConsistencyOptionIdFieldName="fecesConsistencyOptionId"
              fecesConsistencyOptions={
                fecesConsistencyOptionsPromise.fulfilled
                  ? fecesConsistencyOptionsPromise.value
                  : []
              }
              isFecesConsistencyOptionsLoading={
                true === fecesConsistencyOptionsPromise.pending
              }
              fecesConsistencyOptionIdHasValidation={
                $validation.fecesConsistencyOptionId.show
              }
              fecesConsistencyOptionIdValidationReason={
                $validation.fecesConsistencyOptionId.error.reason
              }
              macroscopicParasitesFieldName={"macroscopicParasites"}
              macroscopicParasites={fields.macroscopicParasites}
              macroscopicParasitesHasValidation={
                $validation.macroscopicParasites.show
              }
              macroscopicParasitesValidationReason={
                $validation.macroscopicParasites.error.reason
              }
              result={fields.result}
              resultHasValidation={$validation.result.show}
              resultValidationReason={$validation.result.error.reason}
              shouldDisable={disableAll}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <CheckboxMaterial
              titleAfter={"Ausentes"}
              disabled={disableAll}
              name={fields.absent}
              id="absent"
              checked={fields.absent}
              {...$field("absent", (value) => {
                fieldChange("absent", value ? false : true);
                fieldChange("macroscopicParasites", "Ausentes");
              })}
            />
          </FlatPanelMaterial>
        </Col>
      </Row>
      <Row className="mb-10">
        <Col md={12}>
          <TextAreaPanel2
            title="Resultado"
            fieldTitle=""
            name="result"
            value={result}
            shouldDisable={disableAll}
            handleChange={(name, value) => handleCheckedResultChange(value)}
            handleBlur={handleBlur}
            hasValidation={$validation.result.show}
            validationReason={$validation.result.error.reason}
          />
          <MultiCheckbox
            itens={checkboxes}
            handleChange={multiBoxChange}
            state={checkState}
          />
        </Col>
      </Row>
      <Row className="mb-10">
        <Col md={12}>
          <TextAreaPanel2
            title={"Obs"}
            fieldTitle=""
            name="obs"
            value={fields.obs}
            shouldDisable={disableAll}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Col>
      </Row>
    </ReportPanelMaterial>
  );
};

export default ParasitologicalFecesEpgReportFormView;
