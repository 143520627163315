import React, { Component } from "react";

/** Internal */
import BreedFormView from "./../BreedFormView";

import { FIVE_SECONDS } from "./../../consts/time-lengths";

/*
  THE Consts.js FILE MUST BE ELIMINATED!
  ALL CONSTS SHALL BE PLACED IN consts/ DIRECTORY.
  */
import { ACCESS_PROFILES } from "../../assets/js/Consts";

/** External */
import connect from "../../api-connector";
import update from "immutability-helper";
import { Redirect } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { connect as reduxConnect } from "react-redux";
import { FunctionUtil } from "./../../useful";

class BreedForm extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        id: props.match.params.id ? props.match.params.id : 0,
        name: "",
        speciesId: 0,
        status: 1,
      },
    };

    this.isUpdate = this.isUpdate.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.invalidNotification = this.invalidNotification.bind(this);
    this.shouldDisableAll = this.shouldDisableAll.bind(this);
  }

  componentDidMount() {
    const then = (data, xhr) => {
      if (xhr.response.status === 200) this.fillFields(data);
    };

    if (this.state.fields.id) {
      this.props.withId(this.state.fields.id, then);
    }
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChange(field, value) {
    this.setState((state) =>
      update(state, { fields: { [field]: { $set: value } } })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Fill state related to all form fields
   *
   * @param {string} obj All values that will be used to fill fields
   */
  fillFields(obj) {
    var newState = {
      fields: {
        name: { $set: obj.name },
        speciesId: { $set: obj.specie.id },
        status: { $set: obj.status },
      },
    };
    this.setState((state) => update(state, newState));
  }

  /**
   * @author Victor Heringer
   *
   * Submits breed form data
   *
   * @param {Object} event The submit event
   *
   * @return {boolean}
   */
  handleSubmit() {
    const { id, name, speciesId, status } = this.state.fields;

    const obj = { id: id, name: name, speciesId: speciesId, status: status };

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        this.props.notificationSystem.add({
          title: "Sucesso",
          message: this.isUpdate() ? "Raça atualizada!" : "Raça salva!",
          level: "success",
          position: "tr",
          autoDismiss: FIVE_SECONDS,
        });
      }
    };

    const error = (data, xhr) => {
      if (xhr.response.status === 409) {
        this.props.notificationSystem.add({
          title: "Nome Inválido",
          message: "Nome já cadastrado para espécie selecionada.",
          level: "error",
          position: "tr",
          autoDismiss: FIVE_SECONDS,
        });
      }
    };

    if (id > 0) {
      this.props.update(id, obj, then, error);
    } else {
      this.props.add(obj, then, error);
    }
  }

  /**
   * @author Victor Heringer
   *
   * Checks if is at editing state
   *
   * @return {boolean}
   */
  isUpdate() {
    return this.state.fields.id > 0;
  }

  /**
   * @author Victor Heringer
   *
   * Pops up a notifition for invalid submit
   */
  invalidNotification() {
    this.props.notificationSystem.add({
      title: "Atenção, dados incompletos!",
      message: "Verifique os destaques em vermelho.",
      level: "error",
      position: "tr",
      autoDismiss: FIVE_SECONDS,
    });
  }

  /**
   * @author Sergio Pallet
   *
   * Check if should disable all fields
   *
   * @return {Boolean}
   */
  shouldDisableAll() {
    const haveNoAccess =
      this.props.access_profile == ACCESS_PROFILES.CLINIC_ADMINISTRATOR ||
      this.props.access_profile == ACCESS_PROFILES.CLINIC_USER ||
      this.props.access_profile == ACCESS_PROFILES.UNIT_USER ||
      this.props.access_profile == ACCESS_PROFILES.UNIT_ADMINISTRATOR;
    const pending = this.props.objects && this.props.objects.pending;
    return haveNoAccess || pending;
  }

  render() {
    const { obj, breedResponse } = this.props;

    if (breedResponse && breedResponse.fulfilled) {
      return <Redirect to="/racas" />;
    }

    if (obj && obj.pending) {
      return <BounceLoader color="#00B4AD" load />;
    }

    return (
      <div>
        <BreedFormView
          isUpdate={this.isUpdate()}
          fields={this.state.fields}
          species={this.props.species}
          fieldChange={this.fieldChange}
          handleSubmit={this.handleSubmit}
          invalidNotification={this.invalidNotification}
          disableAll={this.shouldDisableAll()}
        />
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user,
  };
};

const connectWithEndpoint = connect((props) => ({
  species: "/species?status=true",
  add: (obj, then, error) => ({
    breedResponse: {
      url: `/breeds`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true,
    },
  }),
  update: (id, obj, then, error) => ({
    breedResponse: {
      url: `/breeds/${id}`,
      method: "PUT",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true,
    },
  }),
  withId: (id, then) => ({
    obj: {
      url: `/breeds/${id}`,
      then: then,
    },
  }),
}));

const connectWithRedux = reduxConnect(mapStateToProps);

export default FunctionUtil.compose(
  connectWithRedux,
  connectWithEndpoint
)(BreedForm);
