const values = [
  // Chemiluminescence
  { examId: 40, decimalPrecision: 2 },
  { examId: 41, decimalPrecision: 2 },
  { examId: 42, decimalPrecision: 2 },
  { examId: 43, decimalPrecision: 2 },
  { examId: 44, decimalPrecision: 2 },
  { examId: 45, decimalPrecision: 2 },
  { examId: 47, decimalPrecision: 2 },
  { examId: 48, decimalPrecision: 2 },
  { examId: 49, decimalPrecision: 2 },
  { examId: 50, decimalPrecision: 2 },
  { examId: 51, decimalPrecision: 2 },
  { examId: 52, decimalPrecision: 2 },
  { examId: 53, decimalPrecision: 2 },
  { examId: 54, decimalPrecision: 2 },
  { examId: 55, decimalPrecision: 2 },
  { examId: 56, decimalPrecision: 2 },
  { examId: 57, decimalPrecision: 2 },
  { examId: 58, decimalPrecision: 2 },

  // Radioimmunoassay
  { examId: 59, decimalPrecision: 2 },
  { examId: 60, decimalPrecision: 2 },
  { examId: 61, decimalPrecision: 2 },
  { examId: 62, decimalPrecision: 2 },
  { examId: 63, decimalPrecision: 2 },
  { examId: 64, decimalPrecision: 2 },
  { examId: 65, decimalPrecision: 2 },
  { examId: 66, decimalPrecision: 2 },
  { examId: 67, decimalPrecision: 2 },
  { examId: 68, decimalPrecision: 2 },
];

const defaultPrecision = 2;

export const getDecimalPrecisionByExamId = (id) => {
  id = parseInt(id);

  let exam = values
    .filter((element) => {
      return element.examId === id;
    })
    .pop();

  return exam ? exam.decimalPrecision : defaultPrecision;
};
