import React, { Component } from "react";

/** External */
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

/** Internal */
import InvoiceProcessing from "./processing";
import InvoiceSearch from "./search";
import NotFound from "../../containers/NotFound";

class Invoices extends Component {
  /**
   * @author Anderson Souza
   *
   * Renders the Invoice Processing Search form.
   *
   * @memberof Invoices
   */
  renderInvoiceProcessing = routeProps => {
    return (
      <InvoiceProcessing
        {...routeProps}
        notificationSystem={this.props.notificationSystem}
      />
    );
  };

  /**
   * @author Anderson Souza
   *
   * Renders the Invoice Search form.
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderInvoiceSearch = routeProps => {
    return (
      <InvoiceSearch
        {...routeProps}
        notificationSystem={this.props.notificationSystem}
      />
    );
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path={"/financeiro/faturamento"}
          render={this.renderInvoiceProcessing}
        />
        <Route
          exact
          path={"/financeiro/consulta-fatura"}
          render={this.renderInvoiceSearch}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user
  };
};

export default connect(mapStateToProps)(Invoices);
