import emailMask from "text-mask-addons/dist/emailMask";

/**
 * Mask format (__)____-____
 * @param {Array}
 */
const eighDigitsMask = [
  "(",
  /\d/,
  /\d/,
  ")",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

/**
 * Mask format (__)_____-____
 * @param {Array}
 */
const nineDigitsMask = [
  "(",
  /\d/,
  /\d/,
  ")",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

/**
 * @author Victor Heringer
 *
 * Gets the phone mask based at phone's length
 *
 * @param {String} rawValue
 * @param {Object} config
 *
 * @return {Array}
 */
const phoneMask = (rawValue, config) => {
  let value = rawValue.replace(/_/g, "");
  return value.length >= 14 ? nineDigitsMask : eighDigitsMask;
};

export const PHONE = phoneMask;

/**
 * @var {Array}
 */
export const CEP = [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];

/**
 * @var {Array}
 */
export const CNPJ = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

/**
 * @var {Array}
 */
export const CPF = [
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

/**
 * @var {Function}
 */
export const EMAIL = emailMask;




export const eightDigitsPhoneMask = (input) => {
  input = removeAllNonDigits(input);

  return input.replace(/(\d{0,2})(\d{0,4})(\d{0,4})/, (match, $1, $2, $3) => {
    $1 = $1.padEnd(2, "_");
    $2 = $2.padEnd(4, "_");
    $3 = $3.padEnd(4, "_");
    return `(${$1}) ${$2}-${$3}`;
  });
};

export const nineDigitsPhoneMask = (input) => {
  input = removeAllNonDigits(input);

  return input.replace(/(\d{0,2})(\d{0,5})(\d{0,4})/, (match, $1, $2, $3) => {
    $1 = $1.padEnd(2, "_");
    $2 = $2.padEnd(5, "_");
    $3 = $3.padEnd(4, "_");
    return `(${$1}) ${$2}-${$3}`;
  });
};

export const phoneMaskFunction = (input) => {
  if (!input) return "(__) ____-____";
  input = removeAllNonDigits(`${input}`).slice(0,11);
  return input.length > 8
    ? eightDigitsPhoneMask(input)
    : nineDigitsPhoneMask(input);
};

const removeAllNonDigits = (input) => {
  return input.replace(/[^\d]+/g, "");
};

export const PHONE_MASK = {
  mask: phoneMaskFunction,
  unmask: removeAllNonDigits
};
