import React from "react";
import PropTypes from "prop-types";

/**
 * External
 */
import { Col } from "react-bootstrap";
import Select from "react-select-legacy";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";

/**
 * Internal
 */
import InputValidationMsg from "./../InputValidationMsg";

/**
 * Assets
 */
import styles from "./index.module.css";
import faAngleDown from "@fortawesome/fontawesome-free-solid/faAngleDown";
import faAngleUp from "@fortawesome/fontawesome-free-solid/faAngleUp";

const arrowRenderer = params => {
  return params.isOpen ? (
    <FontAwesomeIcon
      className="select-custom-arrow"
      size="lg"
      icon={faAngleUp}
    />
  ) : (
    <FontAwesomeIcon
      className="select-custom-arrow"
      size="lg"
      icon={faAngleDown}
    />
  );
};

const defaultProps = {
  title: "",
  name: "",
  value: [],
  className: "col-md-3",
  searchable: false,
  disabled: false,
  placeholder: "",
  hasValidation: false,
  validationReason: "",
  multi: false,
  closeOnSelect: true,
  simpleValue: true,
  md: 12
};

const propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  options: PropTypes.array.isRequired,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  multi: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  hasValidation: PropTypes.bool,
  validationReason: PropTypes.string
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for the react-select component with material design styling
 */
const SelectMaterial = ({
  title,
  md,
  name,
  value,
  options,
  searchable,
  disabled,
  placeholder,
  valueKey,
  labelKey,
  multi,
  closeOnSelect,
  simpleValue,
  onChange,
  onBlur,
  hasValidation,
  validationReason,
  hasValidationRecoil,
  id,
  wrapperClassName,
  fit,
  tabIndex,
  required,
  autoFocus
}) => {
  /**
   * Validation Component
   * @type {Object}
   */
  const validation = (
    <InputValidationMsg
      id={name + "-validation-msg"}
      type={"danger"}
      visible={hasValidation}
      message={validationReason}
      top={hasValidationRecoil ? "-18px" : ""}
    />
  );

  const numberMd = parseInt(md, 10);
  const fitClass = fit ? styles.fit : "form-group";

  return (
    <Col md={numberMd} className={wrapperClassName}>
      <div className={fitClass}>
        {title && <label>{title}</label>}
        {required && <label> * </label>}
        <Select
          instanceId={id}
          className="select-material"
          name={name}
          options={options}
          value={value}
          searchable={true === searchable}
          disabled={true === disabled}
          placeholder={placeholder}
          valueKey={valueKey ? valueKey : "id"}
          labelKey={labelKey ? labelKey : "name"}
          multi={multi === true}
          arrowRenderer={arrowRenderer}
          reseHTMLOptGroupElement
          closeOnSelect={true === closeOnSelect}
          clearable={false}
          simpleValue={true === simpleValue}
          onChange={onChange}
          onBlur={onBlur}
          resetValue={0}
          noResultsText="Nenhum resultado"
          tabIndex={tabIndex}
          autoFocus={autoFocus}
        />
      </div>
      {validation}
    </Col>
  );
};

SelectMaterial.defaultProps = defaultProps;
SelectMaterial.propTypes = propTypes;

export default SelectMaterial;
