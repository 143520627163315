import React from "react";
import PropTypes from "prop-types";

/**
 * External
 */
import Select from "react-select-legacy";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";

/**
 * Internal
 */
import InputValidationMsg from "./../InputValidationMsg";
import { SELECT_TEXT } from "../../assets/js/Consts.js";

/**
 * Assets
 */
import styles from "./index.module.css";
import faAngleDown from "@fortawesome/fontawesome-free-solid/faAngleDown";
import faAngleUp from "@fortawesome/fontawesome-free-solid/faAngleUp";

const arrowRenderer = params => {
  return params.isOpen ? (
    <FontAwesomeIcon
      className="select-custom-arrow"
      size="lg"
      icon={faAngleUp}
    />
  ) : (
    <FontAwesomeIcon
      className="select-custom-arrow"
      size="lg"
      icon={faAngleDown}
    />
  );
};

const defaultProps = {
  title: "",
  name: "",
  value: [],
  className: "col-md-3",
  isSearchable: false,
  disabled: false,
  placeholder: "",
  isLoading: false,
  hasValidation: false,
  validationReason: "",
  multi: false,
  shouldCloseOnSelect: true,
  simpleValue: true,
  md: 12
};

const propTypes = {
  title: PropTypes.string,
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  options: PropTypes.array.isRequired,
  isSearchable: PropTypes.bool,
  shouldDisable: PropTypes.bool,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  multi: PropTypes.bool,
  shouldCloseOnSelect: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasValidation: PropTypes.bool,
  validationReason: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for the react-select component with material design styling
 */
const SelectMaterial2 = ({
  title,
  name,
  value,
  options,
  isSearchable,
  shouldDisable,
  valueKey,
  labelKey,
  multi,
  shouldCloseOnSelect,
  simpleValue,
  onChange,
  onBlur,
  isLoading,
  hasValidation,
  validationReason,
  hasValidationRecoil,
  id,
  fit,
  tabIndex
}) => {
  /**
   * Validation Component
   * @type {Object}
   */
  const validation = (
    <InputValidationMsg
      id={name + "-validation-msg"}
      type={"danger"}
      visible={hasValidation}
      message={validationReason}
      top={hasValidationRecoil ? "-18px" : ""}
    />
  );

  const fitClass = fit ? styles.fit : "form-group";

  let handleChange = undefined;

  if (undefined !== onChange) {
    handleChange = value => {
      onChange(name, value);
    };
  }

  let handleBlur = undefined;

  if (undefined !== onBlur) {
    handleBlur = value => {
      onBlur({ target: { name: name } });
    };
  }

  return (
    <React.Fragment>
      <div className={fitClass}>
        {title && <label>{title}</label>}
        <Select
          instanceId={id}
          className="select-material"
          name={name}
          options={options}
          value={value}
          searchable={true === isSearchable}
          disabled={true === isLoading || true === shouldDisable}
          placeholder={
            true === isLoading ? SELECT_TEXT.LOADING : SELECT_TEXT.ACTIVE
          }
          valueKey={valueKey ? valueKey : "id"}
          labelKey={labelKey ? labelKey : "name"}
          multi={multi === true}
          arrowRenderer={arrowRenderer}
          reseHTMLOptGroupElement
          closeOnSelect={true === shouldCloseOnSelect}
          clearable={false}
          simpleValue={true === simpleValue}
          onChange={handleChange}
          onBlur={handleBlur}
          resetValue={0}
          noResultsText="Nenhum resultado"
          tabIndex={tabIndex}
        />
      </div>
      {validation}
    </React.Fragment>
  );
};

SelectMaterial2.defaultProps = defaultProps;
SelectMaterial2.propTypes = propTypes;

export default SelectMaterial2;
