import React from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
} from "react-table-v7";
import styled from "styled-components";

// import styles from "./index.module.css";
import { Col, Row } from "./../../components/grid";

/** Assets */
import InputExam from "../../components/input-exam";

import InputValidationMsg from "../../containers/InputValidationMsg/index.js";
import NumberDisplay from "../../containers/NumberDisplay";
import InputResult from "./input-result";
import { mockFactory } from "../../hooks/hormone/factory";




const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const HormoneView = ({ exams, handleChange, tableData }) => {
  const columns = React.useMemo(
    // () => [
    //   {
    //     Header: "Nome Do Exame",
    //     accessor: "col1",
    //   },
    //   {
    //     Header: "Resultado",
    //     accessor: "col2",
    //   },
    //   {
    //     Header: "status",
    //     accessor: "col3",
    //   },
    //   {
    //     Header: "Prazo",
    //     accessor: "col4",
    //   },
    //   {
    //     Header: "Data de Finalização a",
    //     accessor: "col5",
    //   },
    // ],
    // []

    () => [
      {
        Header: "Nome Do Exame",
        accessor: "col1",
      },
      {
        Header: "Resultado",
        accessor: "col2",
      },
      {
        Header: "Resultado",
        accessor: "col3",
      },
      {
        Header: "Resultado",
        accessor: "col4",
      },
      {
        Header: "status",
        accessor: "col5",
      },
      {
        Header: "Prazo",
        accessor: "col6",
      },
      {
        Header: "Data de Finalização",
        accessor: "col7",
      },
    ],
    []


  );
    

  const data = 
      Object.entries(exams).reduce((acc, [k, v], index) => {
        acc.push({
          col1: v.title,
          col2: InputResult({
            title: v.fields[0].title,
            name: v.fields[0].name,
            value: v.fields[0].value,
            refMin:v.fields[0].ref.min,
            refMax:v.fields[0].ref.minMax,
            refUnit:v.fields[0].ref.unit,
            handleChange: handleChange,
          }),
          col3: v.status,
          col4: v.deadLine,
          col5: v.endDate,
        });
        return acc;
      }, []);
    

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns,   data:tableData    });

  // return (
  //   <InputResult/>
  // );

  return (
    <Styles>
      <table
        condensed
        bordered
        hover
        striped
        {...getTableProps()}
        className={"text-center"}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Styles>
  );
};

export default React.memo(HormoneView);
