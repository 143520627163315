class ExamRequestFormFactory {
  DEFAULT_TABLE_SIZE = 5;

  static createInitialState(isClinicUserType, customerId) {
    return {
      fields: {
        customerId: isClinicUserType ? customerId : null,
        animalId: 0,
        clinicalReport: "",
        vetId: null,
        examTypeId: 0,
        labExams: [],
        species: "",
        speciesId: 0,
        breeds: "",
        breedId: 0,
        sexId: 0,
        name: "",
        birthDate: null,
        years: "",
        ageOption: null,
        months: "",
        owner: "",
        phone: "",
        cpf: "",
        externalCode: "",
        microchipCode: "",
        requestExamsPageSize: this.DEFAULT_TABLE_SIZE,
        requestExams: [],
        requestSamplesPageSize: this.DEFAULT_TABLE_SIZE,
        requestSamples: [],
        animalFilterName: "",
        animalFilterOwner: "",
        animalFilterSexId: 0,
        modalSampleCode: "",
      },
      examRequestState: null,
      shouldRedirect: false,
      redirectToId: 0,
      postSent: false,
      redirectToList: false,
      showPrintReportModal: false,
      printedModalUrl: "",
      showAnimalModal: false,
      animalPages: 1,
      animalSorted: null,
      shouldShowAnimalForm: false,
      selectedRowId: 0,
      shouldDisableAnimalFields: false,
      showReceiveAndAssistModal: false,
    };
  }

  static createDefaultAnimal() {
    return {
      speciesId: { $set: 0 },
      breedId: { $set: 0 },
      sexId: { $set: 0 },
      name: { $set: "" },
      birthDate: { $set: null },
      years: { $set: "" },
      months: { $set: "" },
      owner: { $set: "" },
      phone: { $set: "" },
      cpf: { $set: "" },
      externalCode: { $set: "" },
      microchipCode: { $set: "" },
      animalFilterName: { $set: "" },
      animalFilterOwner: { $set: "" },
      animalFilterSexId: { $set: 0 },
      ageOption: { $set: null },
    };
  }

  static createDefaultForm(iniState) {
    return {
      fields: {
        id: { $set: iniState.fields.id },
        customerId: { $set: iniState.fields.customerId },
        animalId: { $set: iniState.fields.animalId },
        speciesId: { $set: iniState.fields.speciesId },
        breedId: { $set: iniState.fields.breedId },
        sexId: { $set: iniState.fields.sexId },
        name: { $set: iniState.fields.name },
        birth: { $set: iniState.fields.birth },
        ageOption: { $set: iniState.fields.ageOption },
        years: { $set: iniState.fields.years },
        months: { $set: iniState.fields.months },
        owner: { $set: iniState.fields.owner },
        clinicalReport: { $set: iniState.fields.clinicalReport },
        vetId: { $set: iniState.fields.vetId },
        examTypeId: { $set: iniState.fields.examTypeId },
        labExams: { $set: iniState.fields.labExams },
        requestExamsPageSize: { $set: iniState.fields.requestExamsPageSize },
        requestExams: { $set: iniState.fields.requestExams },
        requestSamplesPageSize: {
          $set: iniState.fields.requestSamplesPageSize,
        },
        requestSamples: { $set: iniState.fields.requestSamples },
      },
      postSent: { $set: iniState.postSent },
      redirectToList: { $set: iniState.redirectToList },
    };
  }
}

export default ExamRequestFormFactory;
