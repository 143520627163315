import { StringUtil } from "./../../useful";
import {
  required,
  minLength,
  greaterThan,
  differentThan,
  requiredIfHasAnother,
  validateCPF,
  validEmail,
  requiredIfExistAnother,
  lazyIsUniqueLogin
} from "../../assets/js/validationFunctions.js";

/**
 * @author Victor Heringer
 *
 * Validation function for unity
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const customerValidation = props => {
  const {
    name,
    cnpj,
    cep,
    cpf,
    number,
    stateId,
    pricingTableId,
    regionIds,
    cityId,
    neighborhood
  } = props.fields;

  const { companyCNPJ } = props.data;

  const cnpjPromisse =
    companyCNPJ && companyCNPJ.fulfilled ? companyCNPJ.value.valid : {};
  const cnpjFulfield = companyCNPJ && companyCNPJ.fulfilled;

  const fields = [
    "name",
    "cnpj",
    "cpf",
    "status",
    "neighborhood",
    "cep",
    "publicPlace",
    "number",
    "complement",
    "email",
    "phone",
    "regionIds",
    "pricingTableId",
    "cityId",
    "stateId"
  ];

  const validations = {
    name: {
      rules: [
        [required, name, "Informe o nome"],
        [minLength, name, 2, "Mínimo de 2 caracteres"]
      ]
    },
    cnpj: {
      rules: []
    },
    cpf: {
      rules: []
    },
    neighborhood: {
      rules: [[required, neighborhood, "Informe o bairro"]]
    },
    cep: {
      rules: [
        [required, StringUtil.onlyNumbers(cep), "Informe o cep"],
        [minLength, StringUtil.onlyNumbers(cep), 8, "Informe o cep completo"]
      ]
    },
    number: {
      rules: [[required, number, "Informe o número"]]
    },
    complement: {
      rules: []
    },
    regionIds: {
      rules: [[required, regionIds, "Selecione uma região"]]      
    },
    pricingTableId: {
      rules: [[greaterThan, pricingTableId, 0, "Selecione uma tabela"]]
    },
    stateId: {
      rules: [[greaterThan, stateId, 0, "Selecione um estado"]]
    },
    cityId: {
      rules: [[greaterThan, cityId, 0, "Selecione uma cidade"]]
    }
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for user
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const userModalValidation = props => {
  const {
    modalUserId,
    modalUserName,
    modalUserCPF,
    modalUserEmail,
    modalUserPhone,
    modalUserOptionalPhone,
    modalUserLogin,
    modalUserPassword,
    modalUserCRMV,
    modalUserIsVet
  } = props.fields;

  const fields = [
    "modalUserName",
    "modalUserCPF",
    "modalUserEmail",
    "modalUserPhone",
    "modalUserLogin",
    "modalUserPassword",
    "modalUserCRMV",
    "modalUserIsVet"
  ];

  const validations = {
    modalUserName: {
      rules: [
        [required, modalUserName, "Informe o nome"],
        [minLength, modalUserName, 2, "Mínimo de 2 caracteres"]
      ]
    },
    modalUserCPF: {
      rules: [
        [
          validateCPF,
          StringUtil.onlyNumbers(modalUserCPF),
          true,
          "Informe um CPF válido"
        ]
      ]
    },
    modalUserCRMV: {
      rules: [
        [requiredIfHasAnother, modalUserCRMV, modalUserIsVet, "Informe o CRMV"]
      ]
    },
    modalUserEmail: {
      rules:  modalUserEmail !== "" ? [[validEmail, modalUserEmail, "Informe um email válido"]] : []
    },
    modalUserPhone: {
      rules: []
    },
    modalUserOptionalPhone: {
      rules: []
    },
    modalUserLogin: {
      rules: [
        [required, modalUserLogin, "Informe o login"],
        [
          lazyIsUniqueLogin,
          { login: modalUserLogin, id: modalUserId },
          "Login já cadastrado."
        ]
      ]
    },
    modalUserPassword: {
      rules: [
        [
          requiredIfExistAnother,
          modalUserPassword,
          modalUserId,
          "Informe a senha"
        ]
      ]
    }
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for email
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const emailModalValidation = props => {
  const { modalEmail } = props.fields;

  const fields = ["modalEmail"];

  const validations = {
    modalEmail: {
      rules: [
        [required, modalEmail, "Informe o email"],
        [validEmail, modalEmail, "Informe um email válido"]
      ]
    }
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for phone
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const phoneModalValidation = props => {
  const { modalPhone } = props.fields;

  const fields = ["modalPhone"];

  const validations = {
    modalPhone: {
      rules: [
        [required, modalPhone, "Informe o telefone"],
        [
          minLength,
          StringUtil.onlyNumbers(modalPhone),
          10,
          "Informe o telefone completo"
        ]
      ]
    }
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for region
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const regionModalValidation = props => {
  const { modalRegion } = props.fields;

  const fields = ["modalRegion"];

  const validations = {
    modalRegion: {
      rules: [[required, modalRegion, "Informe a região"]]
    }
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for fiscal document
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const fiscalModalValidation = props => {
  const { fiscalDocumentType } = props.fields;

  const fields = ["fiscalDocumentType"];

  const validations = {
    fiscalPersonType: {
      rules: []
    },
    fiscalCPF: {
      rules: []
    },
    fiscalCNPJ: {
      rules: []
    },
    fiscalName: {
      rules: []
    },
    fiscalEmail: {
      rules: []
    },
    fiscalPhone: {
      rules: []
    },
    fiscalCellphone: {
      rules: []
    },
    fiscalResponsibleName: {
      rules: []
    },
    fiscalResponsibleRG: {
      rules: []
    }
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for preferences
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const preferencesModalValidation = props => {
  const {
    preferencePaymentFrequence,
    preferenceTerm,
    preferenceDiscount,
    preferenceReceivesPrintReport,
    preferenceDefaulter,
    preferenceUsesPaymentSlip
  } = props.fields;

  const fields = [
    "preferencePaymentFrequence",
    "preferenceTerm",
    "preferenceDiscount",
    "preferenceReceivesPrintReport",
    "preferenceDefaulter",
    "preferenceUsesPaymentSlip"
  ];

  const validations = {
    preferencePaymentFrequence: {
      rules: []
    },
    preferenceTerm: {
      rules: []
    },
    preferenceDiscount: {
      rules: []
    },
    preferenceReceivesPrintReport: {
      rules: []
    },
    preferenceDefaulter: {
      rules: []
    },
    preferenceUsesPaymentSlip: {
      rules: []
    }
  };

  return { fields, validations };
};
