import React, { useEffect } from "react";

/** Internal */
import ReportPanelMaterial from "./../ReportPanelMaterial";
import ReportHeader from "./../ReportHeader";
import SelectMaterial2 from "./../SelectMaterial2";
import FlatPanelMaterial from "./../FlatPanelMaterial";
import TextAreaMaterial2 from "./../TextAreaMaterial2";
import TextAreaPanel2 from "./../TextAreaPanel2";
import { Col, Row } from "./../../components/grid";
import { MultiCheckbox } from "../../components/result-group";
import CheckboxMaterial from "../CheckboxMaterial";

const ParasitologicalFecesMifReportFormView = ({
  data: {
    isUpdate,
    fields,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    fecesColorOptionsPromise,
    reportHeaderData,
    shouldSendEmail,
    checkState,
    checkboxes,
    result,
  },
  methods: {
    fieldChange,
    handleChange,
    handleBlur,
    onSubmit,
    $field,
    handleSendReportEmail,
    cancelReport,
    stopReport,
    multiBoxChange,
    handleCheckedResultChange,
  },
}) => {
  useEffect(() => {
    if (fields.macroscopicParasites.toLowerCase() === "ausentes") {
      fieldChange("absent", true);
    } else {
      fieldChange("absent", false);
    }
  }, [fields.macroscopicParasites]);
  return (
    <ReportPanelMaterial
      title="Exame Parasitológico de Fezes (MIF)"
      backLink={backLink}
      shouldDisable={disableAll}
      isUpdate={isUpdate}
      isRequesting={submitPromise && submitPromise.pending}
      handleSubmit={onSubmit}
      handleSendReportEmail={handleSendReportEmail}
      shouldSendEmail={shouldSendEmail}
      cancelReport={cancelReport}
      stopReport={stopReport}
    >
      <Row>
        <Col md={12}>
          <ReportHeader
            data={reportHeaderData}
            fields={fields}
            fieldChange={fieldChange}
            $field={$field}
            $validation={$validation}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FlatPanelMaterial title="Exame Macroscópico">
            <Row>
              <Col md={3}>
                <SelectMaterial2
                  title="Cor"
                  hasValidationRecoil
                  name="fecesColorOptionId"
                  options={
                    fecesColorOptionsPromise.fulfilled
                      ? fecesColorOptionsPromise.value
                      : []
                  }
                  value={fields.fecesColorOptionId}
                  isSearchable={true}
                  shouldDisable={disableAll}
                  isLoading={true === fecesColorOptionsPromise.pending}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasValidation={$validation.fecesColorOptionId.show}
                  validationReason={$validation.fecesColorOptionId.error.reason}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <TextAreaMaterial2
                  title={"Parasitas Macroscópicos"}
                  name={"macroscopicParasites"}
                  value={fields.macroscopicParasites}
                  disabled={disableAll}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  hasValidation={$validation.macroscopicParasites.show}
                  validationReason={
                    $validation.macroscopicParasites.error.reason
                  }
                />
                <CheckboxMaterial
                  titleAfter={"Ausentes"}
                  disabled={disableAll}
                  name={fields.absent}
                  id="absent"
                  checked={fields.absent}
                  {...$field("absent", (value) => {
                    fieldChange("absent", value ? false : true);
                    fieldChange("macroscopicParasites", "Ausentes");
                  })}
                />
              </Col>
            </Row>
          </FlatPanelMaterial>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TextAreaPanel2
            title={"Exame Microscópico"}
            fieldTitle=""
            name={"result"}
            value={result}
            shouldDisable={disableAll}
            handleChange={(name, value) => handleCheckedResultChange(value)}
            handleBlur={handleBlur}
            hasValidation={$validation.result.show}
            validationReason={$validation.result.error.reason}
          />
          <MultiCheckbox
            itens={checkboxes}
            handleChange={multiBoxChange}
            state={checkState}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TextAreaPanel2
            title={"Obs"}
            fieldTitle=""
            name={"obs"}
            value={fields.obs}
            shouldDisable={disableAll}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Col>
      </Row>
    </ReportPanelMaterial>
  );
};

export default ParasitologicalFecesMifReportFormView;
