import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import NumberInputWithReference2 from "./../NumberInputWithReference2";
import TextAreaPanel2 from "./../TextAreaPanel2";
import NumberInputMaterial2 from "./../NumberInputMaterial2";
import ReportPanelMaterial from "../ReportPanelMaterial";
import FlatPanelMaterial from "../FlatPanelMaterial";
import { Row, Col } from "./../../components/grid";

const ProteinCreatinineRatioReportFormView = ({ data, methods }) => {
  return (
    <ReportPanelMaterial
      title="Relação Proteína/Creatinina"
      backLink={data.backLink}
      shouldDisable={data.disableAll}
      isUpdate={data.isUpdate}
      isRequesting={data.submitData.pending}
      handleSubmit={methods.onSubmit}
      handleSendReportEmail={methods.handleSendReportEmail}
      shouldSendEmail={data.shouldSendEmail}
      cancelReport={methods.cancelReport}
      stopReport={methods.stopReport}
    >
      <Row>
        <Col md={12}>
          <ReportHeader
            data={data.reportHeaderData}
            fields={data.fields}
            fieldChange={methods.fieldChange}
            $field={methods.$field}
            $validation={data.$validation}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FlatPanelMaterial title="Resultado">
            <Row>
              <Col md={3}>
                <NumberInputMaterial2
                  title="Protein"
                  name="protein"
                  hasValidationRecoil={true}
                  decimalScale={1}
                  value={data.fields.protein}
                  disabled={data.disableAll}
                  inputGroupAfter="mg/dL"
                  inputGroup={true}
                  handleChange={methods.handleChange}
                  handleBlur={methods.handleBlur}
                  hasValidation={data.$validation.protein.show}
                  validationReason={data.$validation.protein.error.reason}
                />
              </Col>
              <Col md={3}>
                <NumberInputMaterial2
                  title="Creatinine"
                  name="creatinine"
                  hasValidationRecoil={true}
                  decimalScale={1}
                  value={data.fields.creatinine}
                  disabled={data.disableAll}
                  inputGroupAfter="mg/dL"
                  inputGroup={true}
                  handleChange={methods.handleChange}
                  handleBlur={methods.handleBlur}
                  hasValidation={data.$validation.creatinine.show}
                  validationReason={data.$validation.creatinine.error.reason}
                />
              </Col>
              <Col md={6}>
                <NumberInputWithReference2
                  fieldTitle="Resultado"
                  value={methods.calculateRatio()}
                  fieldName="result"
                  shouldDisable={true}
                  allowNegative={false}
                  handleChange={methods.handleChange}
                  handleBlur={methods.handleBlur}
                  valueMin={data.refData.min}
                  valueMax={data.refData.max}
                  decimalScale={2}
                  unit="mg/dL"
                />
              </Col>
            </Row>
          </FlatPanelMaterial>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TextAreaPanel2
            title={"Obs"}
            fieldTitle={""}
            name={"obs"}
            value={data.fields.obs}
            shouldDisable={data.disableAll}
            handleChange={methods.handleChange}
            handleBlur={methods.handleBlur}
          />
        </Col>
      </Row>
    </ReportPanelMaterial>
  );
};

export default ProteinCreatinineRatioReportFormView;
