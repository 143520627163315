import React, { Component } from "react";

/** Internal */

import {
  isIntegerBiochemExam,
  EXAM_ID_BY_NAME,
  qttDecimalPlates,
} from "./../../assets/js/Consts.js";

import deadlineToString from "./../../assets/js/deadlineToString.js";
import moment from "../../assets/js/moment.js";

import { Col, Row } from "./../../components/grid";
/** External */
import LVTable from "./../../old_components/LVTable";

/** Assets */
import styles from "./index.module.css";
import InputExam from "../../components/input-exam";

import TextAreaPanel2 from "../../containers/TextAreaPanel2";
import InputValidationMsg from "../../containers/InputValidationMsg";
import SelectMaterial from "../../containers/SelectMaterial";
import ButtonDefaultMaterial from "../../containers/ButtonDefaultMaterial";
import CheckboxMaterial from "../../containers/CheckboxMaterial";
import NumberDisplay from "../../containers/NumberDisplay";
import NumberInputMaterial from "../../containers/NumberInputMaterial/index.js";
import ReportPanelMaterial from "../../containers/ReportPanelMaterial/index.js";
import TableExamReportHeaderPanel from "../../containers/TableExamReportHeaderPanel/index.js";

const isBilirubinAndFractions = (examId) => {
  return examId === EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"];
};

const isIonicCalcium = (examId) => {
  return examId === EXAM_ID_BY_NAME["IONIC_CALCIUM"];
};

const isTotalProteinAndFractions = (examId) => {
  return examId === EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"];
};

const isTotalCholesterolAndFractions = (examId) => {
  return examId === EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"];
};
const isElectrolytes = (examId) => {
  return examId === EXAM_ID_BY_NAME["ELECTROLYTES"];
};

const isAnyOfSpecialBiochemistry = (examId) => {
  if (
    isBilirubinAndFractions(examId) ||
    isIonicCalcium(examId) ||
    isTotalCholesterolAndFractions(examId) ||
    isTotalProteinAndFractions(examId) ||
    isElectrolytes(examId)
  ) {
    return true;
  }
  return false;
};

const textStyle = {
  width: "82px",
};

const renderRef = (refMin, refMax, refUnit, md, hasNormalValue) => (
  <Col md={md}>
    {hasNormalValue && (
      <div className="text-center" style={textStyle}>
        Valor Normal{" "}
      </div>
    )}
    <p className="text-center" style={textStyle}>
      <NumberDisplay value={refMin} decimalScale={2} suffix={` - `} />
      <NumberDisplay value={refMax} decimalScale={2} suffix={` ${refUnit}`} />
    </p>
  </Col>
);

const renderInput = (
  title,
  name,
  md,
  fieldChange,
  value,
  disabled,
  hasValidation,
  isInteger,
  decimalPlates = 2,
  errors = []
) => {
  return (
    <div className="text-left">
      <Col md={md}>
        {title && <label>{title}</label>}
        {<p>{name}</p>}
        <InputExam
          title={title}
          name={name}
          md={md}
          block={true}
          decimalScale={isInteger ? 0 : decimalPlates}
          handleChange={fieldChange}
          value={value}
          disabled={disabled}
          handleBlur={() => {}}
        />
        <InputValidationMsg visible={true} message={"requerido"} />
      </Col>
    </div>
  );
};

const renderReferenceValues = (row) => {
  const examId = row.original.examId;
  const refMin = row.original.refMin;
  const refMax = row.original.refMax;
  const refUnit = row.original.refUnit;

  if (isAnyOfSpecialBiochemistry(examId)) {
    return null;
  }

  return renderRef(refMin, refMax, refUnit, 12);
};

const hormoneview2 = ({ data, methods, errors }) => {
  const {
    resultChange,
    $field,
    calculateIonicCalcium,
    multiFieldResultChange,
    makeIonicCalciumToggable,
    calculateBilirubinIndirect,
    calculateVldl,
    calculateLdl,
    calculateGlobulin,
    stateChange,
  } = methods;

  const { disableAll, requestExamStates, fields } = data;

  function renderResultField(row) {
    const index = row.index.toString();
    const data = row.original;
    const name = `result-${index}`;
    const examId = row.original.examId;
    const md = 12;

    if (isAnyOfSpecialBiochemistry(examId)) {
      return null;
    }

    return renderInput(
      "",
      name,
      md,
      resultChange,
      data.result,
      disableAll,
      false,
      isIntegerBiochemExam(examId),
      qttDecimalPlates(examId)
    );
  }

  function renderTableSub(row) {
    const examId = row.original.examId;
    switch (true) {
      case isBilirubinAndFractions(examId):
        return renderBilirubinAndFractions(row);

      case isIonicCalcium(examId):
        return renderIonicCalcium(row);

      case isTotalCholesterolAndFractions(examId):
        return renderTotalCholesterolAndFractions(row);

      case isTotalProteinAndFractions(examId):
        return renderTotalProteinAndFractions(row);

      case isElectrolytes(examId):
        return renderElectrolytes(row);

      default:
        return <span />;
    }
  }

  function renderElectrolytes(row) {
    const {
      sodium,
      refSodiumMin,
      refSodiumMax,
      refSodiumUnit,
      potassium,
      refPotassiumMin,
      refPotassiumMax,
      refPotassiumUnit,
      phosphorus,
      refPhosphorusMin,
      refPhosphorusMax,
      refPhosphorusUnit,
      chloride,
      refChlorideMin,
      refChlorideMax,
      refChlorideUnit,

      calcium,
      refCalciumMin,
      refCalciumMax,
      refCalciumUnit,
      albumin,
      refAlbuminMin,
      refAlbuminMax,
      refAlbuminUnit,
      totalProtein,
      refTotalProteinMin,
      refTotalProteinMax,
      refTotalProteinUnit,
      result,
      isResultEditable,
      refMin,
      refMax,
      refUnit,
    } = row.original;
    let { ionicCalcium } = row.original;

    const index = row.index.toString();
    const refMd = 1;
    const calciumName = `result-${index}-calcium`;
    const albuminName = `result-${index}-albumin`;
    const totalProteinName = `result-${index}-totalProtein`;
    const name = `result-${index}`;
    const sodiumName = `result-${index}-sodium`;
    const potassiumName = `result-${index}-potassium`;
    const phosphorusName = `result-${index}-phosphorus`;
    const chlorideName = `result-${index}-chloride`;
    const ionicCalciumName = `result-${index}-ionicCalcium`;

    const inputMd = 2;

    if (
      "" !== calcium &&
      "" !== albumin &&
      "" !== totalProtein &&
      !isResultEditable
    ) {
      ionicCalcium = calculateIonicCalcium(calcium, albumin, totalProtein);
    }

    return (
      <div className="mt-20">
        {renderInput(
          "Sódio",
          sodiumName,
          inputMd,
          multiFieldResultChange,
          sodium,
          disableAll,
          false,
          false,
          1
        )}
        {renderRef(refSodiumMin, refSodiumMax, refSodiumUnit, refMd, true)}
        {renderInput(
          "Potássio",
          potassiumName,
          inputMd,
          $field,
          multiFieldResultChange,
          potassium,
          disableAll,
          false,
          false,
          1
        )}
        {renderRef(
          refPotassiumMin,
          refPotassiumMax,
          refPotassiumUnit,
          refMd,
          true
        )}
        {renderInput(
          "Fósforo",
          phosphorusName,
          inputMd,
          multiFieldResultChange,
          phosphorus,
          disableAll,
          false,
          false,
          1
        )}
        {renderRef(
          refPhosphorusMin,
          refPhosphorusMax,
          refPhosphorusUnit,
          refMd,
          true
        )}
        {renderInput(
          "Cloretos",
          chlorideName,
          inputMd,
          multiFieldResultChange,
          chloride,
          disableAll,
          false,
          false,
          1
        )}
        {renderRef(
          refChlorideMin,
          refChlorideMax,
          refChlorideUnit,
          refMd,
          true
        )}
        {renderInput(
          "Cálcio",
          calciumName,
          inputMd,
          multiFieldResultChange,
          calcium,
          isResultEditable || disableAll,
          false,
          false,
          1
        )}
        {renderRef(refCalciumMin, refCalciumMax, refCalciumUnit, refMd, true)}
        {renderInput(
          "Albumina",
          albuminName,
          inputMd,
          multiFieldResultChange,
          isResultEditable ? "" : albumin,
          isResultEditable || disableAll,
          false,
          false,
          1
        )}
        {renderRef(refAlbuminMin, refAlbuminMax, refAlbuminUnit, refMd, true)}
        {renderInput(
          "Proteínas Totais",
          totalProteinName,
          inputMd,
          $field,
          multiFieldResultChange,
          isResultEditable ? "" : totalProtein,
          isResultEditable || disableAll,
          false,
          false,
          1
        )}
        {renderRef(
          refTotalProteinMin,
          refTotalProteinMax,
          refTotalProteinUnit,
          refMd,
          true
        )}
        {isResultEditable &&
          renderInput(
            "Cálcio Iônico",
            ionicCalciumName,
            inputMd,
            multiFieldResultChange,
            ionicCalcium,
            disableAll,
            false
          )}
        {!isResultEditable &&
          renderInput(
            "Cálcio Iônico",
            ionicCalciumName,
            inputMd,
            $field,
            null,
            ionicCalcium,
            true
          )}
        {renderRef(refMin, refMax, refUnit, refMd, true)}
        <CheckboxMaterial
          id="isIonicCalciumEditable"
          name="isIonicCalciumEditable"
          titleAfter="Ativar resultado manual"
          checked={isResultEditable}
          disabled={disableAll}
          onChange={makeIonicCalciumToggable(index)}
        />
      </div>
    );
  }

  function renderBilirubinAndFractions(row) {
    const {
      total,
      refTotalMin,
      refTotalMax,
      direct,
      refDirectMin,
      refDirectMax,
      refIndirectMin,
      refIndirectMax,
      refTotalUnit,
      refDirectUnit,
      refIndirectUnit,
    } = row.original;

    const refMd = 2;

    const index = row.index.toString();
    const totalName = `result-${index}-total`;
    const directName = `result-${index}-direct`;
    const indirectName = `result-${index}-indirect`;
    const inputMd = 2;
    let indirect = "";

    const isTotalDirectNotEmptyStr = "" !== total && "" !== direct;

    if (isTotalDirectNotEmptyStr) {
      indirect = calculateBilirubinIndirect(total, direct);
    }

    return (
      <div className="mt-20">
        {renderInput(
          "Total",
          totalName,
          inputMd,
          multiFieldResultChange,
          total,
          disableAll,
          false
        )}
        {renderRef(refTotalMin, refTotalMax, refTotalUnit, refMd, true)}
        {renderInput(
          "Direta",
          directName,
          inputMd,
          multiFieldResultChange,
          direct,
          disableAll,
          false,
          ""
        )}
        {renderRef(refDirectMin, refDirectMax, refDirectUnit, refMd, true)}
        {renderInput(
          "Indireta",
          indirectName,
          inputMd,
          multiFieldResultChange,
          indirect,
          true
        )}
        {renderRef(
          refIndirectMin,
          refIndirectMax,
          refIndirectUnit,
          refMd,
          true
        )}
      </div>
    );
  }

  function renderTotalProteinAndFractions(row) {
    const {
      totalProtein,
      refTotalProteinMin,
      refTotalProteinMax,
      refTotalProteinUnit,
      albumin,
      refAlbuminMin,
      refAlbuminMax,
      refAlbuminUnit,
      refGlobulinMin,
      refGlobulinMax,
      refGlobulinUnit,
      isResultEditable,
    } = row.original;

    const index = row.index.toString();
    const refMd = 1;
    const globulinName = `result-${index}-globulin`;
    const albuminName = `result-${index}-albumin`;
    const totalProteinName = `result-${index}-totalProtein`;

    const inputMd = 2;

    return (
      <div className="mt-20">
        {renderInput(
          "Proteínas Totais",
          totalProteinName,
          inputMd,
          multiFieldResultChange,
          isResultEditable ? "" : totalProtein,
          isResultEditable || disableAll,
          false,
          false,
          1
        )}
        {renderRef(
          refTotalProteinMin,
          refTotalProteinMax,
          refTotalProteinUnit,
          refMd,
          true
        )}
        {renderInput(
          "Albumina",
          albuminName,
          inputMd,
          multiFieldResultChange,
          isResultEditable ? "" : albumin,
          isResultEditable || disableAll,
          false,
          false,
          1
        )}
        {renderRef(refAlbuminMin, refAlbuminMax, refAlbuminUnit, refMd, true)}
        {renderInput(
          "Globulina",
          globulinName,
          inputMd,
          undefined,
          null,
          calculateGlobulin(totalProtein, albumin),
          true
        )}
        {renderRef(
          refGlobulinMin,
          refGlobulinMax,
          refGlobulinUnit,
          refMd,
          true
        )}
      </div>
    );
  }

  function renderTotalCholesterolAndFractions(row) {
    const {
      totalCholesterol,
      hdl,
      triglycerides,
      totalCholesterolRefMin,
      totalCholesterolRefMax,
      totalCholesterolRefUnit,
      vldlRefMin,
      vldlRefMax,
      vldlRefUnit,
      hdlRefMin,
      hdlRefMax,
      hdlRefUnit,
      ldlRefMin,
      ldlRefMax,
      ldlRefUnit,
      triglyceridesRefMin,
      triglyceridesRefMax,
      triglyceridesRefUnit,
    } = row.original;

    const index = row.index.toString();
    const refMd = 1;
    const inputMd = 1;
    const totalCholesterolName = `result-${index}-totalCholesterol`;
    const hdlName = `result-${index}-hdl`;
    const triglyceridesName = `result-${index}-triglycerides`;
    const ldlName = `result-${index}-ldl`;
    const vldlName = `result-${index}-vldl`;

    let ldl = "";

    if ("" !== totalCholesterol && "" !== hdl && "" !== triglycerides) {
      ldl = calculateLdl(totalCholesterol, hdl, triglycerides);
    }

    let vldl = "";

    if ("" !== triglycerides) {
      vldl = calculateVldl(triglycerides);
    }

    return (
      <div className="mt-20">
        {renderInput(
          "Col. Total",
          totalCholesterolName,
          inputMd,
          multiFieldResultChange,
          totalCholesterol,
          disableAll,
          false
        )}
        {renderRef(
          totalCholesterolRefMin,
          totalCholesterolRefMax,
          totalCholesterolRefUnit,
          refMd,
          true
        )}
        {renderInput(
          "HDL",
          hdlName,
          inputMd,
          multiFieldResultChange,
          hdl,
          disableAll,
          false,
          true
        )}
        {renderRef(hdlRefMin, hdlRefMax, hdlRefUnit, refMd, true)}
        {renderInput(
          "Triglicerídeos",
          triglyceridesName,
          inputMd,
          multiFieldResultChange,
          triglycerides,
          disableAll,
          false,
          true
        )}
        {renderRef(
          triglyceridesRefMin,
          triglyceridesRefMax,
          triglyceridesRefUnit,
          refMd,
          true
        )}
        {renderInput(
          "LDL",
          ldlName,
          inputMd,
          multiFieldResultChange,
          ldl,
          true
        )}
        {renderRef(ldlRefMin, ldlRefMax, ldlRefUnit, refMd, true)}
        {renderInput(
          "VLDL",
          vldlName,
          inputMd,
          multiFieldResultChange,
          vldl,
          true
        )}
        {renderRef(vldlRefMin, vldlRefMax, vldlRefUnit, refMd, true)}
      </div>
    );
  }

  function renderIonicCalcium(row) {
    const {
      calcium,
      refCalciumMin,
      refCalciumMax,
      refCalciumUnit,
      albumin,
      refAlbuminMin,
      refAlbuminMax,
      refAlbuminUnit,
      totalProtein,
      refTotalProteinMin,
      refTotalProteinMax,
      refTotalProteinUnit,
      result,
      isResultEditable,
      refMin,
      refMax,
      refUnit,
    } = row.original;

    const index = row.index.toString();
    const refMd = 1;
    const calciumName = `result-${index}-calcium`;
    const albuminName = `result-${index}-albumin`;
    const totalProteinName = `result-${index}-totalProtein`;
    const name = `result-${index}`;

    const inputMd = 2;

    let ionicCalcium = "";

    if ("" !== calcium && "" !== albumin && "" !== totalProtein) {
      ionicCalcium = calculateIonicCalcium(calcium, albumin, totalProtein);
    }

    return (
      <div className="mt-20">
        {renderInput(
          "Cálcio",
          calciumName,
          inputMd,
          multiFieldResultChange,
          isResultEditable ? "" : calcium,
          isResultEditable || disableAll,
          false,
          false,
          1
        )}
        {renderRef(refCalciumMin, refCalciumMax, refCalciumUnit, refMd, true)}
        {renderInput(
          "Albumina",
          albuminName,
          inputMd,
          multiFieldResultChange,
          isResultEditable ? "" : albumin,
          isResultEditable || disableAll,
          false,
          false,
          1
        )}
        {renderRef(refAlbuminMin, refAlbuminMax, refAlbuminUnit, refMd, true)}
        {renderInput(
          "Proteínas Totais",
          totalProteinName,
          inputMd,
          multiFieldResultChange,
          isResultEditable ? "" : totalProtein,
          isResultEditable || disableAll,
          false,
          false,
          1
        )}
        {renderRef(
          refTotalProteinMin,
          refTotalProteinMax,
          refTotalProteinUnit,
          refMd,
          true
        )}
        {isResultEditable &&
          renderInput(
            "Cálcio Iônico",
            name,
            inputMd,
            resultChange,
            result,
            disableAll,
            false
          )}
        {!isResultEditable &&
          renderInput("Cálcio Iônico", name, inputMd, null, ionicCalcium, true)}
        {renderRef(refMin, refMax, refUnit, refMd, true)}
        <CheckboxMaterial
          id="isIonicCalciumEditable"
          name="isIonicCalciumEditable"
          titleAfter="Ativar resultado manual"
          checked={isResultEditable}
          disabled={disableAll}
          onChange={makeIonicCalciumToggable(index)}
        />
      </div>
    );
  }

  function renderStatusField(row) {
    const index = row.index.toString();
    const requestExamState = row.original.requestExamState;
    const name = `result-${index}`;

    return (
      <SelectMaterial
        name={name}
        md={12}
        options={requestExamStates}
        value={requestExamState}
        searchable={true}
        fit
        tabIndex={-1}
        disabled={disableAll}
        placeholder={"Selecione"}
        onChange={(value) => {
          stateChange(name, value);
        }}
      />
    );
  }

  const defaultTable = {
    defaultPageSize: 5,
    defaultSorted: [{ id: "id", desc: true }],
    pageSize: 15,
    resizable: false,
    showPagination: true,
    noDataText: "Nenhum registro".toUpperCase(),
    loadingText: "Carregando",
    className: "-striped -highlight",
    previousText: "Anterior",
    nextText: "Próximo",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    columns: [],
    showPageSizeOptions: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
  };

  /**
   * Default configurations for biochemistry table
   * @type {Object}
   */
  const config = {
    defaultPageSize: 10,
    pageSize: fields.options.length,
    defaultSorted: [{ id: "name", desc: false }],
    data: fields.options,
    loading: false,
    resizable: defaultTable.resizable,
    showPagination: false,
    showPageSizeOptions: defaultTable.showPageSizeOptions,
    columns: [
      {
        Header: "Nome do Exame",
        accessor: "name",
        className: styles.centeredText,
        maxWidth: 320,
      },
      {
        Header: (props) => <span className="float-left">Resultado</span>,
        className: styles.centeredText,
        sortable: false,
        width: 120,
        Cell: renderResultField,
      },
      {
        Header: "Valores Normais",
        sortable: false,
        className: styles.centeredText,
        Cell: renderReferenceValues,
        width: 180,
      },
      // {
      //   Header: props => <span className="float-left">Status</span>,
      //   Cell: renderStatusField,
      //   sortable: false
      // },
      // {
      //   Header: props => <span className="float-left">Prazo</span>,
      //   className: styles.centeredText,
      //   Cell: props => deadlineToString(props.original.deadline),
      //   sortable: false,
      //   width: 80
      // },
      // {
      //   Header: props => (
      //     <span className="float-left">Data de Finalização</span>
      //   ),
      //   className: styles.centeredText,

      //   Cell: props => {
      //     const date = props.original.endDate;
      //     return date ? moment(date).format("DD/MM/YYYY") : "00-00-0000";
      //   },
      //   sortable: false,
      //   width: 120
      // }
    ],
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <hr className="mt-20" />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="fit-table">
          <div className="text-center">
            <h5 className="table-title">Exames</h5>
          </div>
          <LVTable
            data={config.data}
            loading={config.loading}
            columns={config.columns}
            sortable={config.sortable}
            pageSize={config.pageSize}
            resizable={config.resizable}
            showPagination={config.showPagination}
            defaultPageSize={config.defaultPageSize}
            showPageSizeOptions={config.showPageSizeOptions}
            shouldDisableRowHighlight={true}
            defaultSorted={config.defaultSorted}
            collapseOnDataChange={false}
            shouldRowsStartExpanded={true}
            SubComponent={renderTableSub}
          />
          {/* <TextAreaPanel2
              title="Obs"
              name={"biochemObs"}
              value={props.data.fields.biochemObs}
              disabled={props.shouldDisable}
              handleChange={props.methods.handleChange}
              handleBlur={props.handleBlur}
            /> */}
        </Col>
      </Row>
    </>
    // </ReportPanelMaterial>
  );
};

export default React.memo(hormoneview2);
