import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImagePreview from './ImagePreview';
import FilePreview from './FilePreview';
import DisplayErrors from './DisplayErrors';
import ClearButton from './ClearButton';
import Description from './Description';
import Title from './Title';
import FilesNameList from './FilesNameList';
import styles from './index.module.css';
import { Row, Col } from './../Grid';
import PdfPreview from './PdfPreview';

/**
 * @author Victor Heringer
 * 
 * Preview images if it is one, or upload files
 * 
 * @prop {String} id
 * @prop {String} name
 * @prop {String} description
 * @prop {String} title
 * @prop {Boolean} allowMultiples
 * @prop {Array} allowedExtensions Example: [ '.png', '.jpg', '.jpeg' ]
 * @prop {Boolean} hasImgPreview
 * @prop {Boolean} displayFileIconPreview
 * @prop {Boolean} displayClearBtn
 * @prop {String} clearBtnTitle
 * @prop {Function} setContainerFiles
 * @prop {Int} maxFileSize
 * @prop {Boolean} asBase64
 * @prop {Array} placeholders
 * @prop {Boolean} hasFilesNames
 * @prop {Component} fileIcon
 * @prop {Boolean} inline
 * @prop {Boolean} hasPdfPreview
 * @prop {Boolean} disabled
 */
class LVFileInputMaterial extends Component {

  constructor(props) {
    super(props);
    this.state = {files: [], errors: []};
    this.fileInput = React.createRef();
  }

  /**
   * @author Victor Heringer
   * 
   * Push a file to state.
   * 
   * @param {Object} file Can be a File object or a base64 file
   */
  pushFileToState = (file) => {
    this.setState((state, props) => {
      return { files: state.files.concat(file) };
    }, () => this.props.setContainerFiles(this.state.files));
  }

  /**
   * @author Victor Heringer
   * 
   * Pushs a error message to state.
   * 
   * @param {String} message
   */
  pushErrorToState = (message) => {
    this.setState((state, props) => {
      return { errors: state.errors.concat(message) };
    } );
  }

  /**
   * @author Victor Heringer
   * 
   * Resets to the initial state
   */
  resetState = ( ) => {
    this.setState((state, props) => {
      return { files: [], errors: [] };
    }, () => this.props.setContainerFiles(this.state.files));
  }

  /**
   * @author Victor Heringer
   * 
   * Resets the input file value
   */
  resetInput = () => {
    this.fileInput.current.value = '';
  }

  /**
   * @author Victor Heringer
   * 
   * Handles the click at clear button
   * 
   * @param {Event} event 
   */
  handleClearButton = (event) => {
    this.resetState();
    this.resetInput();
  }

  /**
   * @author Victor Heringer
   * 
   * Converts a file to base 64 and push to state
   * 
   * @param {File} file 
   */
  pushFileToStateAsBase64 = (file) => {
    
    const reader = new FileReader();
    reader.readAsDataURL(file);
    
    reader.onload = () => {
      this.pushFileToState( { 
        name: file.name, 
        extension: file.name.split('.').pop(), 
        value: reader.result
      } );
    };
  }

  /**
   * @author Victor Heringer
   * 
   * Change input file handler
   * 
   * @param {Event} event 
   */
  onChange = (event) => {
    
    const files = event.target.files;
    this.resetState();
    
    for (let i = 0; i < files.length; i++) {
      if( files[i].size > this.props.maxFileSize ) {
        let filename = files[i].name;
        this.resetInput(event);
        this.pushErrorToState(`O arquivo ${filename} é muito grande`);
      } else if (this.props.asBase64) {
        this.pushFileToStateAsBase64(files[i]);
      }
      else {
        this.pushFileToState(files[i]);
      }
    }
  }

  render( ) {

    const { 
      id,
      name,
      placeholders,
      description,
      title,
      allowMultiples,
      allowedExtensions,
      hasImgPreview,
      hasFilePreview,
      hasPdfPreview,
      hasFilesNames,
      clearBtnTitle,
      fileIcon,
      inline,
      disabled
    } = this.props;

    const grid = {
      input: { md: 6 },
      clearBtn: { md: 6 },
      filesNameList: { md: 12 },
      inlineImgPreview: { md: 0 }
    };

    const disabledClearBtn = this.state.files.length == 0 && placeholders.length == 0;

    if ( this.state.files.length > 0 ) {
      grid.input.md = 6;
    }

    const imgPrev = this.state.files.length > 0 || placeholders.length > 0;

    if (inline && !imgPrev && hasImgPreview) {
      grid.input.md = 3;
      grid.clearBtn.md = 3;
      grid.filesNameList.md = 6;
    }
    else if (inline && imgPrev && hasImgPreview) {
      grid.input.md = 3;
      grid.clearBtn.md = 3;
      grid.filesNameList.md = 4;
      grid.inlineImgPreview.md = 2;
    }

    return (
      <Row className={styles.FileInputMaterialWrapper}>
        <Col md={12} className={styles.pd5}>
          <Description text={description} styles={styles} />
        </Col>
        <Col md={grid.inlineImgPreview.md}>
          <ImagePreview
            images={this.state.files}
            placeholders={placeholders}
            display={(hasImgPreview && inline)}
            styles={styles}
            multiples={allowMultiples}
            inline={inline}
          />
        </Col>
        <Col md={grid.input.md} className={styles.pd5}>
          <div className={styles.inputWrapper}>
            <Title disabled={disabled} text={title} styles={styles.inputLabel} />
            <input
              disabled={disabled}
              id={id}
              name={name}
              type="file"
              className={styles.inputFile}
              onChange={this.onChange}
              accept={allowedExtensions.join(', ')}
              multiple={allowMultiples}
              ref={this.fileInput}
            />
          </div>
        </Col>
        <Col md={grid.clearBtn.md} className={styles.pd5}>
          <ClearButton
            onClick={this.handleClearButton}
            title={clearBtnTitle}
            display={true}
            style={styles}
            disabled={disabledClearBtn || disabled}
          />
        </Col>
        <Col md={grid.filesNameList.md} className={styles.pd5}>
          <FilesNameList 
            display={hasFilesNames} 
            files={this.state.files}
            styles={styles}
          />
        </Col>
        <Col md={12} className={styles.pd5}>
          <DisplayErrors errors={this.state.errors} styles={styles} />
        </Col>
        <Col md={12} className={styles.pd5}>
          <PdfPreview 
            pdfs={this.state.files} 
            display={hasPdfPreview}
            width={'100%'}
            height={'400px'}
            placeholders={placeholders}
        />
        </Col>
        <Col md={12}>
          <ImagePreview
            images={this.state.files}
            placeholders={placeholders}
            display={ ( hasImgPreview && !inline ) }
            styles={styles}
            multiples={allowMultiples}
            inline={inline}
          />
          <FilePreview display={hasFilePreview} icon={fileIcon} />
        </Col>
      </Row>
    );
  }
}

LVFileInputMaterial.defaultProps = {
  id: '',
  name: '',
  description: '',
  title: '',
  allowMultiples: false,
  allowedExtensions: [],
  hasImgPreview: true,
  displayFileIconPreview: false,
  displayClearBtn: true,
  clearBtnTitle: 'Clear',
  maxFileSize: 500000,
  asBase64: true,
  placeholders: [],
  hasFilesNames: true,
  fileIcon: {},
  inline: false,
  hasPdfPreview:false,
  hasFilePreview: false,
  disabled: false
};

LVFileInputMaterial.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  allowMultiples: PropTypes.bool, 
  allowedExtensions: PropTypes.array, 
  hasImgPreview: PropTypes.bool,
  displayFileIconPreview: PropTypes.bool,
  displayClearBtn: PropTypes.bool,
  clearBtnTitle: PropTypes.string,
  maxFileSize: PropTypes.number,
  asBase64: PropTypes.bool,
  placeholders: PropTypes.array, 
  hasFilesNames: PropTypes.bool,
  fileIcon: PropTypes.object,
  inline: PropTypes.bool,
  hasPdfPreview: PropTypes.bool
};

export default LVFileInputMaterial;