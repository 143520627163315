import {
  EXAM_ID_BY_NAME,
  EXAM_TITLE_BY_ID,
  fieldNameToTitle,
  multiResultHormenesIds,
} from "../../consts/exams";

export function biochemistryDefaultValues(examId, refs) {
  let data = {};
  switch (examId) {
    case EXAM_ID_BY_NAME["URIC_ACID"]:
      data = {
        result: "",
        refMin: refs.uricAcidMin,
        refMax: refs.uricAcidMax,
        refUnit: refs.uricAcidUnit
      };
      break;
    case EXAM_ID_BY_NAME["ALBUMIN"]:
      data = {
        result: "",
        refMin: refs.albuminMin,
        refMax: refs.albuminMax,
        refUnit: refs.albuminUnit,
      };
      break;
    case EXAM_ID_BY_NAME["ALT_TGP"]:
      data = {
        result: "",
        refMin: refs.altTgpMin,
        refMax: refs.altTgpMax,
        refUnit: refs.altTgpUnit
      };
      break;
    case EXAM_ID_BY_NAME["AMYLASE"]:
      data = {
        result: "",
        refMin: refs.amylaseMin,
        refMax: refs.amylaseMax,
        refUnit: refs.amylaseUnit
      };
      break;
    case EXAM_ID_BY_NAME["AST_TGO"]:
      data = {
        result: "",
        refMin: refs.astTgoMin,
        refMax: refs.astTgoMax,
        refUnit: refs.astTgoUnit
      };
      break;
    case EXAM_ID_BY_NAME["CALCIUM"]:
      data = {
        result: "",
        refMin: refs.calciumMin,
        refMax: refs.calciumMax,
        refUnit: refs.calciumUnit
      };
      break;
    case EXAM_ID_BY_NAME["IONIC_CALCIUM"]:
      data = {
        result: "",
        calcium: "",
        albumin: "",
        totalProtein: "",
        ionicCalcium: "",
        isResultEditable: false,
        refMin: refs.ionicCalciumIonicCalciumMin,
        refMax: refs.ionicCalciumIonicCalciumMax,
        refUnit: refs.ionicCalciumIonicCalciumUnit,
        refCalciumMin: refs.calciumMin,
        refCalciumMax: refs.calciumMax,
        refCalciumUnit: refs.calciumUnit,
        refAlbuminMin: refs.albuminMin,
        refAlbuminMax: refs.albuminMax,
        refAlbuminUnit: refs.albuminUnit,
        refTotalProteinMin: refs.totalProteinMin,
        refTotalProteinMax: refs.totalProteinMax,
        refTotalProteinUnit: refs.totalProteinUnit
      };
      break;
    case EXAM_ID_BY_NAME["CHLORIDE"]:
      data = {
        result: "",
        refMin: refs.chlorideMin,
        refMax: refs.chlorideMax,
        refUnit: refs.chlorideUnit
      };
      break;
    case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL"]:
      data = {
        result: "",
        refMin: refs.totalCholesterolMin,
        refMax: refs.totalCholesterolMax,
        refUnit: refs.totalCholesterolUnit
      };
      break;
    case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]:
      data = {
        totalCholesterol: "",
        hdl: "",
        triglycerides: "",
        totalCholesterolRefMin:
          refs.totalCholesterolAndFractionsTotalCholesterolMin,
        totalCholesterolRefMax:
          refs.totalCholesterolAndFractionsTotalCholesterolMax,
        totalCholesterolRefUnit:
          refs.totalCholesterolAndFractionsTotalCholesterolUnit,
        hdlRefMin: refs.totalCholesterolAndFractionsHdlMin,
        hdlRefMax: refs.totalCholesterolAndFractionsHdlMax,
        hdlRefUnit: refs.totalCholesterolAndFractionsHdlUnit,
        ldlRefMin: refs.totalCholesterolAndFractionsLdlMin,
        ldlRefMax: refs.totalCholesterolAndFractionsLdlMax,
        ldlRefUnit: refs.totalCholesterolAndFractionsLdlUnit,
        vldlRefMin: refs.totalCholesterolAndFractionsVldlMin,
        vldlRefMax: refs.totalCholesterolAndFractionsVldlMax,
        vldlRefUnit: refs.totalCholesterolAndFractionsVldlUnit,
        triglyceridesRefMin: refs.totalCholesterolAndFractionsTriglyceridesMin,
        triglyceridesRefMax: refs.totalCholesterolAndFractionsTriglyceridesMax,
        triglyceridesRefUnit: refs.totalCholesterolAndFractionsTriglyceridesUnit
      };
      break;
    case EXAM_ID_BY_NAME["CREATININE"]:
      data = {
        result: "",
        refMin: refs.creatinineMin,
        refMax: refs.creatinineMax,
        refUnit: refs.creatinineUnit
      };
      break;
    case EXAM_ID_BY_NAME["CPK"]:
      data = {
        result: "",
        refMin: refs.cpkMin,
        refMax: refs.cpkMax,
        refUnit: refs.cpkUnit
      };
      break;
    case EXAM_ID_BY_NAME["UREA"]:
      data = {
        result: "",
        refMin: refs.ureaMin,
        refMax: refs.ureaMax,
        refUnit: refs.ureaUnit
      };
      break;
    case EXAM_ID_BY_NAME["IRON"]:
      data = {
        result: "",
        refMin: refs.ironMin,
        refMax: refs.ironMax,
        refUnit: refs.ironUnit
      };
      break;
    case EXAM_ID_BY_NAME["ALKALINE_PHOSPHATASE"]:
      data = {
        result: "",
        refMin: refs.alkalinePhosphataseMin,
        refMax: refs.alkalinePhosphataseMax,
        refUnit: refs.alkalinePhosphataseUnit
      };
      break;
    case EXAM_ID_BY_NAME["PHOSPHORUS"]:
      data = {
        result: "",
        refMin: refs.phosphorusMin,
        refMax: refs.phosphorusMax,
        refUnit: refs.phosphorusUnit
      };
      break;
    case EXAM_ID_BY_NAME["FRUCTOSAMINE"]:
      data = {
        result: "",
        refMin: refs.fructosamineMin,
        refMax: refs.fructosamineMax,
        refUnit: refs.fructosamineUnit
      };
      break;
    case EXAM_ID_BY_NAME["GGT"]:
      data = {
        result: "",
        refMin: refs.ggtMin,
        refMax: refs.ggtMax,
        refUnit: refs.ggtUnit
      };
      break;
    case EXAM_ID_BY_NAME["GLUCOSE"]:
      data = {
        result: "",
        refMin: refs.glucoseMin,
        refMax: refs.glucoseMax,
        refUnit: refs.glucoseUnit
      };
      break;
    case EXAM_ID_BY_NAME["GLYCATED_HEMOGLOBIN"]:
      data = {
        result: "",
        refMin: refs.glycatedHemoglobinMin,
        refMax: refs.glycatedHemoglobinMax,
        refUnit: refs.glycatedHemoglobinUnit
      };
      break;
    case EXAM_ID_BY_NAME["LACTATE"]:
      data = {
        result: "",
        refMin: refs.lactateMin,
        refMax: refs.lactateMax,
        refUnit: refs.lactateUnit
      };
      break;
    case EXAM_ID_BY_NAME["LIPASE"]:
      data = {
        result: "",
        refMin: refs.lipaseMin,
        refMax: refs.lipaseMax,
        refUnit: refs.lipaseUnit
      };
      break;
    case EXAM_ID_BY_NAME["IMMUNOREACTIVE_LIPASE"]:
      data = {
        result: "",
        refMin: refs.immunoreactiveLipaseMin,
        refMax: refs.immunoreactiveLipaseMax,
        refUnit: refs.immunoreactiveLipaseUnit
      };
      break;
    case EXAM_ID_BY_NAME["MAGNESIUM"]:
      data = {
        result: "",
        refMin: refs.magnesiumMin,
        refMax: refs.magnesiumMax,
        refUnit: refs.magnesiumUnit
      };
      break;
    case EXAM_ID_BY_NAME["PH"]:
      data = {
        result: "",
        refMin: refs.phMin,
        refMax: refs.phMax,
        refUnit: refs.phUnit
      };
      break;
    case EXAM_ID_BY_NAME["POTASSIUM"]:
      data = {
        result: "",
        refMin: refs.potassiumMin,
        refMax: refs.potassiumMax,
        refUnit: refs.potassiumUnit
      };
      break;
    case EXAM_ID_BY_NAME["TOTAL_PROTEIN"]:
      data = {
        result: "",
        refMin: refs.totalProteinMin,
        refMax: refs.totalProteinMax,
        refUnit: refs.totalProteinUnit
      };
      break;
    case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]:
      data = {
        totalProtein: "",
        albumin: "",
        globulin: "",
        refTotalProteinMin: refs.totalProteinAndFractionsTotalProteinMin,
        refTotalProteinMax: refs.totalProteinAndFractionsTotalProteinMax,
        refTotalProteinUnit: refs.totalProteinAndFractionsTotalProteinUnit,
        refAlbuminMin: refs.totalProteinAndFractionsAlbuminMin,
        refAlbuminMax: refs.totalProteinAndFractionsAlbuminMax,
        refAlbuminUnit: refs.totalProteinAndFractionsAlbuminUnit,
        refGlobulinMin: refs.totalProteinAndFractionsGlobulinMin,
        refGlobulinMax: refs.totalProteinAndFractionsGlobulinMax,
        refGlobulinUnit: refs.totalProteinAndFractionsGlobulinUnit
      };
      break;
    case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]:
      data = {
        total: "",
        direct: "",
        indirect: "",
        refTotalMin: refs.bilirubinAndFractionsTotalMin,
        refTotalMax: refs.bilirubinAndFractionsTotalMax,
        refTotalUnit: refs.bilirubinAndFractionsTotalUnit,
        refDirectMin: refs.bilirubinAndFractionsDirectMin,
        refDirectMax: refs.bilirubinAndFractionsDirectMax,
        refDirectUnit: refs.bilirubinAndFractionsDirectUnit,
        refIndirectMin: refs.bilirubinAndFractionsIndirectMin,
        refIndirectMax: refs.bilirubinAndFractionsIndirectMax,
        refIndirectUnit: refs.bilirubinAndFractionsIndirectUnit
      };
      break;
    case EXAM_ID_BY_NAME["SODIUM"]:
      data = {
        result: "",
        refMin: refs.sodiumMin,
        refMax: refs.sodiumMax,
        refUnit: refs.sodiumUnit
      };
      break;
    case EXAM_ID_BY_NAME["TRIGLYCERIDES"]:
      data = {
        result: "",
        refMin: refs.triglyceridesMin,
        refMax: refs.triglyceridesMax,
        refUnit: refs.triglyceridesUnit
      };
      break;
    case EXAM_ID_BY_NAME["LDH"]:
      data = {
        result: "",
        refMin: refs.ldhMin,
        refMax: refs.ldhMax,
        refUnit: refs.ldhUnit
      };
      break;
    case EXAM_ID_BY_NAME["TLI"]:
      data = {
        result: "",
        refMin: refs.tliMin,
        refMax: refs.tliMax,
        refUnit: refs.tliUnit
      };
      break;
      case EXAM_ID_BY_NAME["ELECTROLYTES"]:
      data = {
        sodium: "",
        refSodiumMin: refs.sodiumMin,
        refSodiumMax: refs.sodiumMax,
        refSodiumUnit: refs.sodiumUnit,
        potassium: "",
        refPotassiumMin: refs.potassiumMin,
        refPotassiumMax: refs.potassiumMax,
        refPotassiumUnit: refs.potassiumUnit,
        phosphorus: "",
        refPhosphorusMin: refs.phosphorusMin,
        refPhosphorusMax: refs.phosphorusMax,
        refPhosphorusUnit: refs.phosphorusUnit,
        chloride: "",
        refChlorideMin: refs.chlorideMin,
        refChlorideMax: refs.chlorideMax,
        refChlorideUnit: refs.chlorideUnit,
        calcium: "",
        albumin: "",
        totalProtein: "",
        isResultEditable: false,
        ionicCalcium: "",
        refMin: refs.ionicCalciumIonicCalciumMin,
        refMax: refs.ionicCalciumIonicCalciumMax,
        refUnit: refs.ionicCalciumIonicCalciumUnit,
        refCalciumMin: refs.calciumMin,
        refCalciumMax: refs.calciumMax,
        refCalciumUnit: refs.calciumUnit,
        refAlbuminMin: refs.albuminMin,
        refAlbuminMax: refs.albuminMax,
        refAlbuminUnit: refs.albuminUnit,
        refTotalProteinMin: refs.totalProteinMin,
        refTotalProteinMax: refs.totalProteinMax,
        refTotalProteinUnit: refs.totalProteinUnit
      };
      
  }
  return data;
}
