import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import FlatPanelMaterial from "./../FlatPanelMaterial";
import InputTextMaterial from "./../InputTextMaterial";
import TextAreaMaterial2 from "./../TextAreaMaterial2";
import TextAreaPanel2 from "./../TextAreaPanel2";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Row, Col } from "./../../components/grid";

const FungalCultureFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Cultura de Fungos"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FlatPanelMaterial title="Resultado">
          <Row>
            <Col md={4}>
              <InputTextMaterial
                title="Material Enviado"
                name="material"
                shouldDisable={data.disableAll}
                value={data.fields.material}
                handleBlur={methods.handleBlur}
                handleChange={methods.handleChange}
                hasValidation={data.$validation.material.show}
                validationReason={data.$validation.material.error.reason}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-10">
              <TextAreaMaterial2
                title={"Resultado"}
                name="result"
                value={data.fields.result}
                disabled={data.disableAll}
                handleChange={methods.handleChange}
                handleBlur={methods.handleBlur}
                hasValidation={data.$validation.result.show}
                validationReason={data.$validation.result.error.reason}
              />
            </Col>
          </Row>
        </FlatPanelMaterial>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <TextAreaPanel2
          title={"Obs"}
          fieldTitle={""}
          name={"obs"}
          value={data.fields.obs}
          shouldDisable={data.disableAll}
          handleChange={methods.handleChange}
          handleBlur={methods.handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default FungalCultureFormView;
