import React from "react";

/**
 * External
 */
import classNames from "classnames";

/**
 * Internal
 */
import styles from "./index.module.css";
import { ArrayUtil } from "../../useful";
import { INPUT_SPECIAL_VALUE } from "../../consts";

const InputExam = ({
  decimalScale,
  placeholder,
  name,
  disabled,
  handleBlur,
  handleChange,
  value
}) => {
  const decimalSeparator = ",";
  const specialChar = "*";
  
  
const formatValue = value => {
    if (value.split("").length === 0) {
      return value;
    }
    if (
      !ArrayUtil.arrayContainsArray(supportedValues, value.split("")) ||
      value.split("").filter(x => x == decimalSeparator).length > 1
    ) {
      return false;
    }
    const separeteValue = value.split(decimalSeparator);
    if (separeteValue[1] && separeteValue[1].split("").length > decimalScale) {
      return false;
    }

    if (value.split("").indexOf(specialChar) > -1) {
      return INPUT_SPECIAL_VALUE;
    }

    return value.replace(decimalSeparator, ".");
  };

  const onValueChange = e => {
    const formatedValue = formatValue(e.target.value);
    handleChange &&
      formatedValue !== false &&
      handleChange(e.target.name, formatedValue);
  };

  const supportedValues = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    decimalScale > 0 ? decimalSeparator : "",
    specialChar
  ];
  value = typeof value === 'string' ?  value.replace(".", decimalSeparator) :  value;
  value = parseInt(value) === INPUT_SPECIAL_VALUE ? specialChar : value;
  return (
    <input
      name={name}
      type={"text"}
      value={value}
      className={classNames("form-control", styles.inputMaterial)}
      onBlur={handleBlur}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onValueChange}
    />
  );
};

export default InputExam;
