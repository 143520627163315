import React from 'react';
import PropTypes from 'prop-types';

/**
* Internal
*/
import TextAreaPanel2 from './../TextAreaPanel2';

/**
 * Assets
 */
const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  shouldDisable: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  hasValidation: PropTypes.bool,
  validationReason: PropTypes.string
};

const defaultProps = {
  shouldDisable: false,
  hasValidation: false,
  validationReason: ''
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a ObsPanel form
 * 
 */
const ObsPanel = ( {
  name,
  value,
  shouldDisable,
  handleChange,
  handleBlur,
  hasValidation,
  validationReason
} ) =>
  <TextAreaPanel2
    title="Obs"
    name={ name }
    value={ value }
    disabled={ shouldDisable }
    handleChange={ handleChange }
    handleBlur={ handleBlur }
    hasValidation={ hasValidation }
    validationReason={ validationReason } />

ObsPanel.propTypes = propTypes;
ObsPanel.defaultProps = defaultProps;

export default ObsPanel;