export enum Sex {
  M = 1,
  F = 2,
  U = 3,
}

export const sexOptions = [
  { id: Sex.M, name: "Macho" },
  { id: Sex.F, name: "Fêmea" },
  { id: Sex.U, name: "Indefinido" },
];

export const getSexNameFromId = {
  [Sex.M]: "Macho",
  [Sex.F]: "Fêmea",
  [Sex.U]: "Indefinido",
};
