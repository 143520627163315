import { StringUtil } from "./../../useful";
import {
  required,
  minLength,
  greaterThan,
  validateCNPJ,
  validateCPF,
  validEmail,
  requiredIfExistAnother,
  requiredIfHasAnother,
} from "../../assets/js/validationFunctions.js";

/**
 * @author Victor Heringer
 *
 * Validation function for unity
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const unityValidation = (props) => {
  const { name, cnpj, cep, number, stateId, cityId, neighborhood } =
    props.fields;

  const { companyCNPJ } = props.data;

  const cnpjPromisse =
    companyCNPJ && companyCNPJ.fulfilled ? companyCNPJ.value.valid : {};
  const cnpjFulfield = companyCNPJ && companyCNPJ.fulfilled;

  const fields = [
    "name",
    "cnpj",
    "status",
    "neighborhood",
    "cep",
    "publicPlace",
    "number",
    "complement",
    "email",
    "phone",
    "cityId",
    "stateId",
  ];

  const validations = {
    name: {
      rules: [
        [required, name, "Informe o nome"],
        [minLength, name, 2, "Mínimo de 2 caracteres"],
      ],
    },
    cnpj: {
      rules: [
        [required, cnpj, "Informe o cnpj"],
        [validateCNPJ, StringUtil.onlyNumbers(cnpj), "CNPJ incorreto"],
      ],
    },
    neighborhood: {
      rules: [[required, neighborhood, "Informe o bairro"]],
    },
    cep: {
      rules: [
        [required, StringUtil.onlyNumbers(cep), "Informe o cep"],
        [minLength, StringUtil.onlyNumbers(cep), 8, "Informe o cep completo"],
      ],
    },
    number: {
      rules: [[required, number, "Informe o número"]],
    },
    complement: {
      rules: [],
    },
    stateId: {
      rules: [[greaterThan, stateId, 0, "Selecione um estado"]],
    },
    cityId: {
      rules: [[greaterThan, cityId, 0, "Selecione uma cidade"]],
    },
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for user
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const userModalValidation = (props) => {
  const {
    modalUserId,
    modalUserName,
    modalUserCPF,
    modalUserEmail,
    modalUserPhone,
    modalUserLogin,
    modalUserPassword,
    modalUserCRMV,
    modalUserIsVet,
  } = props.fields;

  const fields = [
    "modalUserName",
    "modalUserCPF",
    "modalUserEmail",
    "modalUserPhone",
    "modalUserLogin",
    "modalUserPassword",
    "modalUserCRMV",
    "modalUserIsVet",
  ];

  const validations = {
    modalUserName: {
      rules: [
        [required, modalUserName, "Informe o nome"],
        [minLength, modalUserName, 2, "Mínimo de 2 caracteres"],
      ],
    },
    modalUserCPF: {
      rules: [
        [
          validateCPF,
          StringUtil.onlyNumbers(modalUserCPF),
          true,
          "Informe um CPF válido",
        ],
      ],
    },
    modalUserCRMV: {
      rules: [
        [requiredIfHasAnother, modalUserCRMV, modalUserIsVet, "Informe o CRMV"],
      ],
    },
    modalUserEmail: {
      rules: [[validEmail, modalUserEmail, "Informe um email válido"]],
    },
    modalUserPhone: {
      rules: [],
    },
    modalUserLogin: {
      rules: [[required, modalUserLogin, "Informe o login"]],
    },
    modalUserPassword: {
      rules: [
        [
          requiredIfExistAnother,
          modalUserPassword,
          modalUserId,
          "Informe a senha",
        ],
      ],
    },
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for user
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const responsibleModalValidation = (props) => {
  const {
    modalResponsibleId,
    modalResponsibleName,
    modalResponsibleCPF,
    modalResponsibleEmail,
    modalResponsiblePhone,
    modalResponsibleLogin,
    modalResponsiblePassword,
    modalResponsibleCRMV,
    modalResponsibleIsVet,
  } = props.fields;

  const fields = [
    "modalResponsibleName",
    "modalResponsibleCPF",
    "modalResponsibleEmail",
    "modalResponsiblePhone",
    "modalResponsibleLogin",
    "modalResponsiblePassword",
    "modalResponsibleCRMV",
    "modalResponsibleIsVet",
  ];

  const validations = {
    modalResponsibleName: {
      rules: [
        [required, modalResponsibleName, "Informe o nome"],
        [minLength, modalResponsibleName, 2, "Mínimo de 2 caracteres"],
      ],
    },
    modalResponsibleCPF: {
      rules: [],
    },
    modalResponsibleCRMV: {
      rules: [],
    },
    modalResponsibleEmail: {
      rules: [],
    },
    modalResponsiblePhone: {
      rules: [],
    },
    modalResponsibleLogin: {
      rules: [],
    },
    modalResponsiblePassword: {
      rules: [],
    },
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for email
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const emailModalValidation = (props) => {
  const { modalEmail } = props.fields;

  const fields = ["modalEmail"];

  const validations = {
    modalEmail: {
      rules: [
        [required, modalEmail, "Informe o email"],
        [validEmail, modalEmail, "Informe um email válido"],
      ],
    },
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for phone
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const phoneModalValidation = (props) => {
  const { modalPhone } = props.fields;

  const fields = ["modalPhone"];

  const validations = {
    modalPhone: {
      rules: [
        [required, modalPhone, "Informe o telefone"],
        [
          minLength,
          StringUtil.onlyNumbers(modalPhone),
          10,
          "Informe o telefone completo",
        ],
      ],
    },
  };

  return { fields, validations };
};

/**
 * @author Victor Heringer
 *
 * Validation function for region
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const regionModalValidation = (props) => {
  const { modalRegion } = props.fields;

  const fields = ["modalRegion"];

  const validations = {
    modalRegion: {
      rules: [[required, modalRegion, "Informe a região"]],
    },
  };

  return { fields, validations };
};
