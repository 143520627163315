import React from "react";

/**
 * External
 */
import PropTypes from "prop-types";
import { Row, Col } from "./../../components/grid";
import NumberDisplay from "./../NumberDisplay";

/**
 * Internal
 */
import NumberInputMaterial from "./../NumberInputMaterial";
import { FocusManagerTarget } from "./../../services";

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const NumberInputWithReference = ({
  fieldTitle,
  value,
  fieldName,
  shouldDisable,
  hasValidation,
  validationReason,
  fieldChange,
  $field = () => {},
  valueMin,
  valueMax,
  decimalScale,
  omitRefTitle,
  unit,
  setRef,
  handleBlur
}) => (
  <div className="no-padding-cols distante-rows">
    <Row>
      <div className="col-xs-12">
        <div className="col-xs-5 col-xs-offset-3 text-center">
          <p className="cleanItem"></p>
        </div>
        <div className="col-xs-4 text-center">
          <p className="cleanItem">{omitRefTitle ? "" : "Valor Normal"}</p>
        </div>
      </div>
    </Row>
    <Row>
      <div className="col-xs-4 text-right">
        <p className="mr-20 cleanItem">{fieldTitle}</p>
      </div>
      <Col xs={4}>
        <NumberInputMaterial
          name={fieldName}
          md={12}
          inputGroup={true}
          inputGroupAfter={unit}
          value={value}
          disabled={shouldDisable}
          allowNegative={false}
          decimalScale={decimalScale}
          fieldChange={fieldChange}
          {...$field(fieldName)}
          hasValidation={hasValidation}
          validationReason={validationReason}
          hasValidationRecoil={false}
          setFieldRef={setRef}
          onBlur={handleBlur}
        />
      </Col>
      <Col xs={4}>
        <Col xs={6} className="text-right cleanItem">
          <p className="mr-20">
            <NumberDisplay value={valueMin} decimalScale={decimalScale} />
          </p>
        </Col>
        <Col xs={6} className="text-left cleanItem">
          <p className="ml-5">
            <NumberDisplay value={valueMax} decimalScale={decimalScale} />
          </p>
        </Col>
      </Col>
    </Row>
  </div>
);

NumberInputWithReference.propTypes = propTypes;

export default NumberInputWithReference;
