import { useFormik } from "formik";
import { element } from "prop-types";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { EXAM_TYPES } from "../../consts";
import { ROUTES, useFetch } from "../use-fetch";

import { teste } from "./examFunctions/index.js";
import useErytogram from "./use-erythogram";
import {
  erythogramState,
  globalLeukogramState,
  leukogramState,
  plateletsState,
} from "./hematologyDefaultState";
import useLeukogram from "./use-leukogram";
import usePlatelets from "./use-platelets";

const defaultHemogramState = {
  hemogramObs: "hemograma obs",
};

const erythogramKeys = Object.keys(erythogramState);
const leukogramKeys = Object.keys(leukogramState);
const plateletsKeys = Object.keys(plateletsState);

const useHemogram = ({ requestExamId }) => {
  const examTypeId = EXAM_TYPES.HEMATOLOGY;
  const [refs, setRefs] = useState({});
  const refsPromise = useFetch();
  const [erythogramCounterIsOpen, setErythogramCounterIsOpen] = useState(false);


  const {
    values,
    handleChange,
    errors: hemogramErrors,
    handleBlur: hemogramHandleBlur,
    touched: hemogramTouched,
    setFieldValue:hemogramSetFieldValue,
  } = useFormik({
    initialValues: defaultHemogramState,
  });

  const {
    errors: erythogramErrors,
    handleBlur: erythogramHandleBlur,
    touched: erythogramTouched,
    values: erythogramValues,
    handleChange: erythogramHandleChange,
    setFieldValue: erythogramSetFieldValue,
  } = useErytogram({ requestExamId });

  const {
    errors: leukogramErrors,
    handleBlur: leukogramHandleBlur,
    touched: leukogramTouched,
    values: leukogramValues,
    handleChange: leukogramHandleChange,
    setFieldValue: leukogramSetFieldValue,
  } = useLeukogram({ requestExamId });


  const {
    errors: plateletsErrors,
    handleBlur: plateletsHandleBlur,
    touched: plateletsTouched,
    values: plateletsValues,
    handleChange: plateletsHandleChange,
    setFieldValue: plateletsSetFieldValue,

  } = usePlatelets({requestExamId});

  const touched = {
    ...erythogramTouched,
    ...leukogramTouched,
    ...plateletsTouched
  };

  const fields = {
    ...values,
    ...erythogramValues,
    ...leukogramValues,
    ...plateletsValues
  };

  const errors = {
    ...hemogramErrors,
    ...erythogramErrors,
    ...leukogramErrors,
    ...plateletsErrors
  };

  useEffect(() => {
    refsPromise.fetch(
      ROUTES.getRefs(requestExamId, examTypeId),
      { method: "GET" },
      (data) => setRefs(data)
    );
  }, []);

  const handleBlur = (event) => {
    const field = event.target.name;
    const isErythogramField = erythogramKeys.some((key) => field === key);
    if (isErythogramField) {
      erythogramHandleBlur(event);
      return;
    }

    const isLeukogramField = leukogramKeys.some((key) => field === key);
    if (isLeukogramField) {
      leukogramHandleBlur(event);
      return;
    }

    const isPlateletsField = plateletsKeys.some((key) => field === key);
    if (isPlateletsField) {
      plateletsHandleBlur(event);
      return;
    }
  };

  const fieldChange = (field, value) => {
    const isErythogramField = erythogramKeys.some((key) => field === key);
    if (isErythogramField) {
      erythogramSetFieldValue(field, value);
      return;
    }

    const isLeukogramField = leukogramKeys.some((key) => field === key);
    if (isLeukogramField) {
      leukogramSetFieldValue(field, value);
      return;
    }    
    const isPlateletsField = plateletsKeys.some((key) => field === key);
    if (isPlateletsField) {
      plateletsSetFieldValue(field, value);
      
      return;
    }
    hemogramSetFieldValue(field, value);
  };

  function addCounterErythogram(field) {
    const addedValue = Number(fields[field]) + 1;
    if (field === "totalCounter") {
      setErythogramCounterIsOpen(false);
      return;
    }
    fields.leukogramTotal < 100 && leukogramSetFieldValue(field, addedValue);
  }

  return {
    hemogramRefs: refs,
    fieldChange,
    fields,
    errors,
    handleBlur,
    touched,
    erythogramCounterIsOpen,
    toggleErythogramCounter: () =>
      setErythogramCounterIsOpen(!erythogramCounterIsOpen),
    closeErythogramCounter: () =>
      setErythogramCounterIsOpen(!erythogramCounterIsOpen),
    addCounterErythogram,
  };
};

export default useHemogram;
