import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * External
 */
import NumberDisplay from "./../NumberDisplay";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import NumberInputMaterial from "./../NumberInputMaterial";
import PanelMaterialBody from "../PanelMaterialBody";
import PanelMaterialTop from "../PanelMaterialTop";

/**
 * Assets
 */

const propTypes = {};

/**
 * @author Victor Heringer
 *
 * Component that represents a ErythrogramPanel form
 *
 */
const ErythrogramPanel = ({
  title,
  erythrocytes,
  corpuscularVolume,
  hemoglobiometry,
  erythrocytesMin,
  erythrocytesMax,
  corpuscularVolumeMin,
  corpuscularVolumeMax,
  hemoglobiometryMin,
  hemoglobiometryMax,
  mcvMin,
  mcvMax,
  mchcMin,
  mchcMax,
  disableAll,
  $validation,
  fieldChange,
  $field,
  calcMCV,
  calcMCHC,
  flat,
  setRef,
}) => (
  <PanelMaterial className="pb-15 pt-5" flat={flat}>
    <PanelMaterialTop title={title} />
    <PanelMaterialBody>
      <div className="no-padding-cols distante-rows">
        <div className="row">
          <div className="col-xs-12">
            <div className="col-xs-5 col-xs-offset-3 text-center">
              <p className="cleanItem"></p>
            </div>
            <div className="col-xs-4 text-center">
              <p className="cleanItem">Valor Normal</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-4 text-right">
            <p className="mr-20 cleanItem">Hemácias</p>
          </div>
          <div className="col-xs-4">
            <NumberInputMaterial
              name="erythrocytes"
              md={12}
              inputGroupAfter="μl"
              inputGroup={true}
              allowNegative={false}
              decimalScale={2}
              fieldChange={fieldChange}
              value={erythrocytes}
              disabled={disableAll}
              {...$field("erythrocytes")}
              hasValidation={$validation.erythrocytes.show}
              validationReason={$validation.erythrocytes.error.reason}
              hasValidationRecoil={false}
              setFieldRef={setRef}
            />
          </div>
          <div className="col-xs-4">
            <div className="col-xs-6 text-center cleanItem">
              <p>
                <NumberDisplay value={erythrocytesMin} decimalScale={1} />
              </p>
            </div>
            <div className="col-xs-6 text-center cleanItem">
              <p>
                <NumberDisplay
                  value={erythrocytesMax}
                  decimalScale={1}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-4 text-right">
            <p className="mr-20 cleanItem">Volume Globular</p>
          </div>
          <div className="col-xs-4">
            <NumberInputMaterial
              name="corpuscularVolume"
              md={12}
              inputGroupAfter="%"
              inputGroup={true}
              allowNegative={false}
              decimalScale={2}
              fieldChange={fieldChange}
              value={corpuscularVolume}
              disabled={disableAll}
              {...$field("corpuscularVolume")}
              hasValidation={$validation.corpuscularVolume.show}
              validationReason={$validation.corpuscularVolume.error.reason}
              hasValidationRecoil={false}
              setFieldRef={setRef}
            />
          </div>
          <div className="col-xs-4">
            <div className="col-xs-6 text-center cleanItem">
              <p>
                <NumberDisplay
                  value={corpuscularVolumeMin}
                  decimalScale={1}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
            <div className="col-xs-6 text-center cleanItem">
              <p>
                <NumberDisplay
                  value={corpuscularVolumeMax}
                  decimalScale={1}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-4 text-right">
            <p className="mr-20 cleanItem">Hemoglobiometria</p>
          </div>
          <div className="col-xs-4">
            <NumberInputMaterial
              name="hemoglobiometry"
              md={12}
              inputGroupAfter="q/dl"
              inputGroup={true}
              allowNegative={false}
              decimalScale={2}
              fieldChange={fieldChange}
              value={hemoglobiometry}
              disabled={disableAll}
              {...$field("hemoglobiometry")}
              hasValidation={$validation.hemoglobiometry.show}
              validationReason={$validation.hemoglobiometry.error.reason}
              hasValidationRecoil={false}
              setFieldRef={setRef}
            />
          </div>
          <div className="col-xs-4">
            <div className="col-xs-6 text-center cleanItem">
              <p>
                <NumberDisplay
                  value={hemoglobiometryMin}
                  decimalScale={1}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
            <div className="col-xs-6 text-center cleanItem">
              <p>
                <NumberDisplay
                  value={hemoglobiometryMax}
                  decimalScale={1}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-4 text-right">
            <p className="mr-20 cleanItem">VCM</p>
          </div>
          <div className="col-xs-4">
            <NumberInputMaterial
              name="mcv"
              md={12}
              disabled={true}
              inputGroupAfter="fL"
              inputGroup={true}
              allowNegative={false}
              value={calcMCV()}
              disabled={true}
              decimalScale={2}
            />
          </div>
          <div className="col-xs-4">
            <div className="col-xs-6 text-center cleanItem">
              <p>
                <NumberDisplay
                  value={mcvMin}
                  decimalScale={1}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
            <div className="col-xs-6 text-center cleanItem">
              <p>
                <NumberDisplay
                  value={mcvMax}
                  decimalScale={1}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-4 text-right">
            <p className="mr-20 cleanItem">CHCM</p>
          </div>
          <div className="col-xs-4">
            <NumberInputMaterial
              name="mchc"
              md={12}
              disabled={true}
              inputGroupAfter="fL"
              inputGroup={true}
              allowNegative={false}
              value={calcMCHC()}
              disabled={true}
              decimalScale={2}
              {...$field("hemoglobiometry")}
              hasValidation={$validation.mchc.show}
              validationReason={$validation.mchc.error.reason}
              hasValidationRecoil={false}
            />
          </div>
          <div className="col-xs-4">
            <div className="col-xs-6 text-center cleanItem">
              <p>
                <NumberDisplay
                  value={mchcMin}
                  decimalScale={1}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
            <div className="col-xs-6 text-center cleanItem">
              <p>
                <NumberDisplay
                  value={mchcMax}
                  decimalScale={1}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </PanelMaterialBody>
  </PanelMaterial>
);

ErythrogramPanel.propTypes = propTypes;

export default ErythrogramPanel;
