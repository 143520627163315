import { molecularBiologyRules } from "../../../../assets/js/validationRules";

export const leishmaniasisFieldNames = () => {
  return [
    "leishmaniasisMaterials",
    "leishmaniasisTechnique",
    "leishmaniasisQualitativeResult",
    "leishmaniasisCopyValues",
    "leishmaniasisPrecision",
    "leishmaniasisResult",
    "leishmaniasisObs",
  ];
};

export const leishmaniasisDefaultState = () => {
  return {
    leishmaniasisMaterials: [],
    leishmaniasisTechnique: -1,
    leishmaniasisQualitativeResult: -1,
    leishmaniasisCopyValues: "",
    leishmaniasisPrecision: "",
    leishmaniasisResult: "",
    leishmaniasisObs: "",
  };
};

export const leishmaniasisStateApiFactory = (obj) => {
  const {
    leishmaniasisMaterials,
    leishmaniasisTechnique,
    leishmaniasisQualitativeResult,
    leishmaniasisCopyValues,
    leishmaniasisPrecision,
    leishmaniasisResult,
    leishmaniasisObs,
  } = obj;
  return {
    leishmaniasisMaterials: leishmaniasisMaterials
      ? leishmaniasisMaterials
      : [],
    leishmaniasisTechnique: leishmaniasisTechnique
      ? leishmaniasisTechnique
      : -1,
    leishmaniasisQualitativeResult: !(leishmaniasisQualitativeResult === null)
      ? leishmaniasisQualitativeResult
      : -1,
    leishmaniasisCopyValues: leishmaniasisCopyValues
      ? leishmaniasisCopyValues
      : "",
    leishmaniasisPrecision: leishmaniasisPrecision
      ? leishmaniasisPrecision
      : "",
    leishmaniasisResult: leishmaniasisResult ? leishmaniasisResult : "",
    leishmaniasisObs: leishmaniasisObs ? leishmaniasisObs : "",
  };
};

export const leishmaniasisFieldsToApiObjParser = (fields) => {
  const {
    leishmaniasisMaterials,
    leishmaniasisTechnique,
    leishmaniasisQualitativeResult,
    leishmaniasisCopyValues,
    leishmaniasisPrecision,
    leishmaniasisResult,
    leishmaniasisObs,
  } = fields;

  return {
    leishmaniasisMaterials: leishmaniasisMaterials,
    leishmaniasisTechnique: leishmaniasisTechnique,
    leishmaniasisQualitativeResult: leishmaniasisQualitativeResult,
    leishmaniasisCopyValues: leishmaniasisCopyValues,
    leishmaniasisPrecision: leishmaniasisPrecision,
    leishmaniasisResult: leishmaniasisResult,
    leishmaniasisObs: leishmaniasisObs,
  };
};

export const leishmaniasisValidationRules = (fields, isCompleteValidation) => {
  const {
    leishmaniasisMaterials,
    leishmaniasisTechnique,
    leishmaniasisQualitativeResult,
    leishmaniasisCopyValues,
    leishmaniasisPrecision,
  } = fields;
  return molecularBiologyRules(
    "leishmaniasisTechnique",
    leishmaniasisTechnique,
    "leishmaniasisQualitativeResult",
    leishmaniasisQualitativeResult,
    "leishmaniasisCopyValues",
    leishmaniasisCopyValues,
    "leishmaniasisPrecision",
    leishmaniasisPrecision,
    "leishmaniasisMaterials",
    leishmaniasisMaterials,
    isCompleteValidation
  );
};
