import React, { useCallback, useEffect } from "react";

import FormState from "./form-state";
import { connectFormWithEndpoint } from "./requests";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
import apiConnect from "./../../api-connector";
import { connect as reduxConnect } from "react-redux";
import { TICKET_STATUS, ACCESS_PROFILES } from "./../../consts";
import NotFound from "./../../containers/NotFound";
import FadeIn from "react-fade-in";
import * as LifeVet from "./../../components";
import Notifications from "react-notification-system-redux";

const TicketsForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  notifications,
  history,
  ticket,
  match,
  access_profile,
}) => {
  const navigate = useCallback(() => {
    history.push("/tickets");
  });

  useEffect(() => {
    if (ticket.fulfilled) {
      setFieldValue("subject", ticket.value.subject);
      setFieldValue("message", ticket.value.message);
      setFieldValue("status", ticket.value.status);
    }
  }, ticket.value);

  const isUserGeneralAdmin = (access_profile) => {
    return access_profile === ACCESS_PROFILES.GENERAL_ADMINISTRATOR;
  };

  return (
    <FadeIn>
      {!isUserGeneralAdmin(access_profile) && match.params.id > 0 ? (
        <NotFound />
      ) : (
        <LifeVet.Panel>
          <LifeVet.Form onSubmit={handleSubmit}>
            <LifeVet.PanelHeader title="Novo Ticket" />
            <LifeVet.PanelBody>
              <LifeVet.Row>
                <LifeVet.Col sm={6}>
                  <LifeVet.FormGroup
                    errorMessage={touched.subject && errors.subject}
                  >
                    <LifeVet.InputLabel>Assunto</LifeVet.InputLabel>
                    <LifeVet.Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                      disabled={isSubmitting || ticket.pending}
                      name="subject"
                    />
                  </LifeVet.FormGroup>
                </LifeVet.Col>
                <LifeVet.Col sm={6}>
                  <LifeVet.FormGroup
                    errorMessage={touched.status && errors.status}
                  >
                    <LifeVet.InputLabel>Status</LifeVet.InputLabel>

                    <LifeVet.Select
                      name="status"
                      value={values.status}
                      theValueIsAnId={true}
                      onChange={(value) => {
                        setFieldValue("status", value.id);
                      }}
                      value={values.status}
                      disabled={
                        isSubmitting ||
                        ticket.pending ||
                        !isUserGeneralAdmin(access_profile)
                      }
                      options={TICKET_STATUS.values}
                      multi={false}
                      closeOnSelect={true}
                    />
                  </LifeVet.FormGroup>
                </LifeVet.Col>
                <LifeVet.Col sm={12}>
                  <LifeVet.FormGroup
                    errorMessage={touched.message && errors.message}
                  >
                    <LifeVet.InputLabel>Mensagem</LifeVet.InputLabel>
                    <LifeVet.TextArea
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      disabled={isSubmitting || ticket.pending}
                      name="message"
                      rows="10"
                    />
                  </LifeVet.FormGroup>
                </LifeVet.Col>
              </LifeVet.Row>
            </LifeVet.PanelBody>
            <LifeVet.PanelFooter>
              <LifeVet.Button
                type="submit"
                depth="2"
                variant="secondary"
                onClick={navigate}
                disabled={isSubmitting || ticket.pending}
              >
                Voltar
              </LifeVet.Button>
              <LifeVet.Button
                disabled={isSubmitting || ticket.pending}
                loading={isSubmitting || ticket.pending}
                type="submit"
                depth="2"
                variant="primary"
              >
                {isSubmitting || ticket.pending ? "Aguarde..." : "Salvar"}
              </LifeVet.Button>
            </LifeVet.PanelFooter>
          </LifeVet.Form>
          <Notifications notifications={notifications} />
        </LifeVet.Panel>
      )}
    </FadeIn>
  );
};

const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);
const connectWithApi = apiConnect(connectFormWithEndpoint);

export default connectWithRedux(connectWithApi(FormState(TicketsForm)));
