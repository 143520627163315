import React from "react";
import PropTypes from "prop-types";
import InputMaterial from "./../../containers/InputMaterial";

/**
 * @author Victor Heringer
 *
 * Transforms the event value to uppercase
 *
 * @param {Object} event
 * @param {Function} callback
 */
const toUpperCase = (event, callback) => {
  event.target.value = event.target.value.toUpperCase();
  callback(event);
};

/**
 * @author Victor Heringer
 *
 * Wrapper to change value to uppercase
 */
const LVInputTextUpper = ({
  id,
  md,
  fit,
  type,
  name,
  title,
  block,
  value,
  onBlur,
  onChange,
  disabled,
  className,
  titleAfter,
  inputGroup,
  hasValidation,
  validationType,
  inputGroupAfter,
  hasValidationRecoil,
  validationReason,
  containerClassName,
  inputGroupClassName,
  mask,
  maskValue,
  componentUnder,
  autofocus,
  required
}) => {
  return (
    <InputMaterial
      required={required}
      id={id}
      md={md}
      fit={fit}
      type={type}
      name={name}
      title={title}
      block={block}
      value={value}
      onBlur={event => toUpperCase(event, onBlur)}
      onChange={event => toUpperCase(event, onChange)}
      disabled={disabled}
      className={className}
      titleAfter={titleAfter}
      inputGroup={inputGroup}
      hasValidation={hasValidation}
      validationType={validationType}
      inputGroupAfter={inputGroupAfter}
      hasValidationRecoil={hasValidationRecoil}
      validationReason={validationReason}
      containerClassName={containerClassName}
      inputGroupClassName={inputGroupClassName}
      mask={mask}
      maskValue={maskValue}
      componentUnder={componentUnder}
      autofocus={autofocus}
    />
  );
};

LVInputTextUpper.defaultProps = {
  md: 12,
  type: "text",
  title: "",
  disabled: false,
  validationReason: "",
  hasValidation: false,
  validationType: "danger",
  hasValidationRecoil: true
};

LVInputTextUpper.propTypes = {
  /** Required */
  name: PropTypes.string.isRequired,

  /** Default */
  title: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validationType: PropTypes.oneOf(["danger", "warning", "info"]),
  hasValidationRecoil: PropTypes.bool,

  /** Custom */
  md: PropTypes.number,
  labelInline: PropTypes.bool,
  inputGroup: PropTypes.bool,
  inputGroupAfter: PropTypes.string,
  titleAfter: PropTypes.string,
  hasValidation: PropTypes.bool,
  validationReason: PropTypes.string,
  block: PropTypes.bool,
  fit: PropTypes.bool,

  /** Classes */
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string
};

export default LVInputTextUpper;
