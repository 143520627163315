import { success, error, warning, info } from "react-notification-system-redux";
import { ACCESS_PROFILES } from "./../consts";

const loggedUserIsAClinicUser = (profile) => {
  return ACCESS_PROFILES.CLINIC_USER == profile;
};

const loggedUserIsFromClinic = (profile) => {
  const clinicUsersTypes = [
    ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
    ACCESS_PROFILES.CLINIC_USER,
  ];

  return clinicUsersTypes.includes(profile);
};

export const mapStateToProps = function (state) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user,
    notifications: state.notifications,
    loggedUserIsFromClinic: loggedUserIsFromClinic(
      state.userReducer.access_profile
    ),
    loggedUserIsAClinicUser: loggedUserIsAClinicUser(
      state.userReducer.access_profile
    ),
    userLabId: state.userReducer.lab_id,
    customerId: state.userReducer.user.customer_id,
    userPersonCustomerId: state.userReducer.user.customer_person_id,
    isMobile: state.mobileReducer.is_mobile,
    requestId: state.requestReducer.requestId
  };
};

export const mapDispatchToProps = function (dispatch) {
  return {
    successNotification: (config) => dispatch(success(config)),
    errorNotification: (config) => dispatch(error(config)),
    warningNotification: (config) => dispatch(warning(config)),
    infoNotification: (config) => dispatch(info(config)),
  };
};
