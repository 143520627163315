import React, { useEffect, useState } from "react";
import { SELECT_TEXT } from "../../../../../assets/js/Consts.js";
import * as LifeVet from "../../../../../components";
import { ExamType } from "../../../../../types/index";

export const ExamTypeSelect = ({
  data,
  setExamType,
  disable,
  fetchedExamTypes,
}: {
  data: ExamType;
  setExamType: any;
  disable: boolean;
  fetchedExamTypes: any;
}) => {
  const defaultRef: any = null;
  const [ref, setRef] = useState(defaultRef);
  let options: any = fetchedExamTypes.fulfilled ? fetchedExamTypes.data : [];

  useEffect(() => {
    ref && setTimeout(() => ref.focus(), 400);
    ref && setTimeout(() => ref.focus(), 800);
  }, [disable]);  

  return (
    <LifeVet.FormGroup>
      <LifeVet.InputLabel>Tipo de Exame</LifeVet.InputLabel>
      <LifeVet.Select
        options={options}
        value={data.id}
        searchable={true}
        disabled={fetchedExamTypes.pending || disable}
        theValueIsAnId={true}
        placeholder={
          fetchedExamTypes.pending ? SELECT_TEXT.LOADING : SELECT_TEXT.ACTIVE
        }
        onChange={(option: any) => setExamType(option)}
        setRef={(ref) => setRef(ref)}
        onKeyDown
      />
    </LifeVet.FormGroup>
  );
};
