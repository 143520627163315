import React, { useEffect, useState } from "react";

/** Internal */
import ButtonSaveMaterial from "../../../../ButtonSaveMaterial";
import ButtonDefaultMaterial from "../../../../ButtonDefaultMaterial";
import AnimalFields from "../../../../AnimalFields";
import { KeyEventListener } from "../../../../../components/key-event-listener";
import { animalModalValidation } from "../../../validation";

/** External */
import { Row, Col, Modal } from "react-bootstrap";
import ReactTable from "react-table-v6";
import update from "immutability-helper";
import debounce from "lodash/debounce";
import { validated } from "react-custom-validation";

/**
 * Assets
 */
import styles from "./index.module.css";

const AnimalModal = ({
  $field,
  $submit,
  $fieldEvent,
  $validation,
  updateField,
  onChangeSpecies,
  onChangeBirthDate,
  customersPromise,
  speciesPromise,
  breedsPromise,
  fields,
  pages,
  searchedAnimalsPromise: oldSearchedAnimalsPromise,
  showModal,
  handleClose,
  filter,
  handleShowForm,
  handleCloseForm,
  shouldShowForm,
  handleTableClicks,
  handlePressSelectBtn,
  handleAnimalSubmit,
  invalidNotification,
  disableAll,
  handleExited,
  handleKeepFilterState,
  onChangeYearsOrMonths,
  animalNameInputRef,
  animalNameSearchInputRef,
}) => {
  const SUBMIT_MODAL_KEY = "Enter";
  let config = {
    defaultPageSize: 5,
    defaultSorted: [{ id: "id", desc: true }],
    pageSize: 15,
    resizable: false,
    showPagination: true,
    noDataText: "Nenhum registro".toUpperCase(),
    loadingText: "Carregando",
    className: "-striped -highlight",
    previousText: "Anterior",
    nextText: "Próximo",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    columns: [],
    showPageSizeOptions: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
  };

  const onSubmit = () => {
    const callback = () => {
      $fieldEvent("reset");
    };
    handleAnimalSubmit(callback);
    setSearchedAnimalsPromise(undefined);
  };

  const onExited = () => {
    $fieldEvent("reset");
    handleExited();
  };
  const [isLoading, setisLoading] = useState(true);
  const [searchedAnimalsPromise, setSearchedAnimalsPromise] =
    useState(undefined);
  useEffect(() => {
    setisLoading(true);
    if (
      oldSearchedAnimalsPromise &&
      oldSearchedAnimalsPromise !== [] &&
      !oldSearchedAnimalsPromise.pending &&
      oldSearchedAnimalsPromise.fulfilled &&
      oldSearchedAnimalsPromise.value !== []
    ) {
      setSearchedAnimalsPromise(oldSearchedAnimalsPromise);
      return setisLoading(false);
    }
    return setSearchedAnimalsPromise(undefined);
  }, [oldSearchedAnimalsPromise]);

  config = update(config, {
    data: {
      $set:
        searchedAnimalsPromise && searchedAnimalsPromise.fulfilled
          ? searchedAnimalsPromise.value
          : [],
    },
    loading: {
      $set: isLoading,
    },
    filterable: {
      $set: true,
    },
    pageSize: {
      $set: 10,
    },
    defaultPageSize: {
      $set: 10,
    },
    manual: {
      $set: true,
    },
    onFetchData: {
      $set: debounce(filter, 500),
    },
    pages: {
      $set: pages,
    },
    getTrProps: { $set: handleTableClicks },
  });

  config.columns = [
    {
      Header: "Código",
      sortable: true,
      minWidth: 1,
      accessor: "id",
      className: styles.centeredText,
    },
    {
      Header: "Nome",
      sortable: true,
      minWidth: 2,
      accessor: "name",
      className: styles.centeredText,

      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          style={{ width: "100%" }}
          onChange={(event) => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          ref={animalNameSearchInputRef}
        />
      ),
    },
    {
      Header: "Cliente",
      sortable: true,
      minWidth: 2,
      show: false,
      accessor: "customer",
      className: styles.centeredText,
    },
    {
      Header: "Proprietário",
      sortable: true,
      minWidth: 2,
      accessor: "owner",
      className: styles.centeredText,
    },
    {
      Header: "Espécie",
      sortable: true,
      minWidth: 2,
      accessor: "species",
      className: styles.centeredText,
    },
    {
      Header: "Raça",
      sortable: true,
      minWidth: 2,
      accessor: "breed",
      className: styles.centeredText,
    },
    {
      Header: "Sexo",
      sortable: false,
      minWidth: 2,
      accessor: "sexId",
      className: styles.centeredText,
      Cell: (data) => {
        const sexId = data.row.sexId;
        switch (sexId) {
          case 1:
            return "Macho";
            break;
          case 2:
            return "Fêmea";
            break;
          case 3:
            return "Indefinido";
            break;
          default:
            return "Todos";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Todos</option>
          <option value={1}>Macho</option>
          <option value={2}>Fêmea</option>
          <option value={3}>Indefinido</option>
        </select>
      ),
    },
  ];

  return (
    <Modal
      show={showModal}
      bsSize="lg"
      onHide={() => {
        setisLoading(true);
        handleClose();
      }}
      onExited={onExited}
    >
      <Modal.Header closeButton>
        <Modal.Title>{shouldShowForm ? "Novo Animal" : "Animais"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-20">
          <Col md={12}>
            <div className={shouldShowForm ? "hidden" : ""}>
              {showModal && (
                <ReactTable
                  onFilteredChange={handleKeepFilterState}
                  {...config}
                />
              )}
            </div>
          </Col>
        </Row>
        <KeyEventListener
          key={SUBMIT_MODAL_KEY}
          onKeyDown={() => $submit(onSubmit, invalidNotification)}
        >
          <AnimalFields
            fields={fields}
            $field={$field}
            $validation={$validation}
            updateField={updateField}
            className={shouldShowForm ? "" : "hidden"}
            onChangeSpecies={onChangeSpecies}
            onChangeBirthDate={onChangeBirthDate}
            shouldRenderCustomer={false}
            customersPromise={customersPromise}
            speciesPromise={speciesPromise}
            breedsPromise={breedsPromise}
            disableAll={disableAll}
            onChangeYearsOrMonths={onChangeYearsOrMonths}
            animalNameInputRef={animalNameInputRef}
          />
        </KeyEventListener>
        {shouldShowForm && (
          <span className="pull-right">Pressione ENTER para salvar.</span>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!shouldShowForm && (
          <ButtonSaveMaterial
            title="Selecionar"
            type="highlight"
            className="pull-right"
            onClick={handlePressSelectBtn}
            disabled={disableAll}
            right={true}
          />
        )}
        {!shouldShowForm && (
          <ButtonSaveMaterial
            title="Novo"
            type="highlight"
            className="pull-right"
            disabled={disableAll}
            onClick={handleShowForm}
            right={true}
          />
        )}
        {shouldShowForm && (
          <ButtonDefaultMaterial
            type="default"
            title="Voltar à Listagem"
            className="pull-left"
            disabled={disableAll}
            onClick={handleCloseForm}
            right={true}
          />
        )}
        {shouldShowForm && (
          <ButtonSaveMaterial
            title="Salvar e Selecionar"
            type="highlight"
            className="pull-right"
            disabled={disableAll}
            requesting={disableAll}
            requestingTitle={"Salvando.."}
            onClick={() => $submit(onSubmit, invalidNotification)}
            right={true}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

const withValidation = validated(animalModalValidation);

export default withValidation(AnimalModal);
