import React from "react";
import styled from "styled-components";
import { text700 } from "./../colors";
import { mainFont } from "./../fonts";

const StyledListTitle = styled.h3`
  ${mainFont}
  margin: 0px 0 0px;
  font-size: 12px;
  font-weight: bold;
  color: ${text700};
`;

const ListTitle = props => (
  <StyledListTitle {...props}>{props.children}</StyledListTitle>
);

export default ListTitle;
