import React from "react";

/**
 * Internal
 */
import FlatPanelMaterial from "./../FlatPanelMaterial";
import HemogramContent from "./../HemogramContent";

const HemogramPanel = ({
  fields,
  refs,
  disableAll,
  $validation,
  total,
  $field,
  fieldChange,
  calcMCV,
  calcMCHC,
  calcAbsolute,
  resetLeukogram,
  onClickCounter,
  toggleIntenseLeucopeny,
  finalGlobalLeukogram,
  erythrogramDiagnosis,
  leukogramDiagnosis,
  plateletsDiagnosis,
  handleKeyPress,
  closeCounter,
  setRef,
  reportData,
}) => (
  <FlatPanelMaterial title="Hemograma">
    <HemogramContent
      fields={fields}
      refs={refs}
      disableAll={disableAll}
      $validation={$validation}
      total={total}
      $field={$field}
      fieldChange={fieldChange}
      calcMCV={calcMCV}
      calcMCHC={calcMCHC}
      calcAbsolute={calcAbsolute}
      resetLeukogram={resetLeukogram}
      onClickCounter={onClickCounter}
      toggleIntenseLeucopeny={toggleIntenseLeucopeny}
      finalGlobalLeukogram={finalGlobalLeukogram}
      erythrogramDiagnosis={erythrogramDiagnosis}
      leukogramDiagnosis={leukogramDiagnosis}
      plateletsDiagnosis={plateletsDiagnosis}
      handleKeyPress={handleKeyPress}
      closeCounter={closeCounter}
      setRef={setRef}
      reportData={reportData}
    />
  </FlatPanelMaterial>
);

export default HemogramPanel;
