import React from 'react';

/**
 * Internal
 */
import TextAreaPanel2 from './../TextAreaPanel2';

const FecesCoprocytologicalPanel = ( {
  fieldName,
  result,
  shouldDisable,
  handleChange,
  handleBlur,
  hasValidation,
  validationReason
} ) =>
  <TextAreaPanel2
    title={ 'Coprocitológico de Fezes' }
    fieldTitle={ '' }
    name={ fieldName }
    value={ result }
    shouldDisable={ shouldDisable }
    handleChange={ handleChange }
    handleBlur={ handleBlur }
    hasValidation={ hasValidation }
    validationReason={ validationReason } />

export default FecesCoprocytologicalPanel;