import React, { Component, useEffect, useState } from "react";
import update from "immutability-helper";
import FadeIn from "react-fade-in";
import _ from "lodash";
import { Redirect } from "react-router";
import cloneDeep from "lodash/cloneDeep";
import { connect } from "react-redux";
import Notifications from "react-notification-system-redux";
import { BounceLoader } from "react-spinners";
import ExamRequestFormView from "./view";
import validation from "./validation.js";
import connectWithEndpoint from "./requests.js";
import moment from "../../assets/js/moment.js";
import ExamRequestFormFactory from "./factory.js";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
import {
  FunctionUtil,
  ArrayUtil,
  StringUtil,
  PromiseUtil,
} from "./../../useful";
import {
  MESSAGES,
  EXAMS,
  TIME_LENGTHS,
  ACCESS_PROFILES,
  EXAM_REQUEST_STATES,
  REQUEST_EXAM_STATES,
  ENV,
} from "./../../consts";

const MINUMUM_TABLE_SIZE = 5;
const MAX_LENGTH_NOTIFICATION = 80;

const View = ({ data, methods, $field, $validation, $submit, $fieldEvent }) => {
  const [animalData, setAnimalData] = useState(data.searchedAnimalsPromise);

  useEffect(() => {
    data.searchedAnimalsPromise && data.searchedAnimalsPromise.refreshing
      ? setAnimalData([])
      : setAnimalData(data.searchedAnimalsPromise);
  }, [data.searchedAnimalsPromise]);

  const submitAndRestart = () => {
    const resetForm = () => {
      methods.restartForm();
      $fieldEvent("reset");
    };

    !data.alreadySubmitted && methods.handleSubmitAndRestart(resetForm);
  };

  const onChangeFormMode = () => {
    $fieldEvent("reset");
  };

  const onSubmit = (isPartialSave = false) => {
    if (!data.alreadySubmitted) {
      isPartialSave === true
        ? $submit(methods.handleSubmitPartial, methods.invalidNotification)
        : $submit(methods.handleSubmitNormal, methods.invalidNotification);
    }
  };

  const onSubmitAndRestart = (e) => {
    e.preventDefault();
    setAnimalData([]);
    $submit(submitAndRestart, methods.invalidNotification);
  };

  const newData = {
    ...data,
    $validation: $validation,
    searchedAnimalsPromise: animalData,
  };

  const newMethods = {
    onSubmit: onSubmit,
    onSubmitAndRestart: onSubmitAndRestart,
    $field: $field,
    onChangeFormMode: onChangeFormMode,
    ...methods,
  };

  return <ExamRequestFormView data={newData} methods={newMethods} />;
};

const ValidatedView = validation(View);

class ExamRequestForm extends Component {
  constructor(props) {
    super(props);

    this.state = ExamRequestFormFactory.createInitialState(
      props.loggedUserIsFromClinic,
      props.userPersonCustomerId
    );

    // Refs to manage focus
    this.animalNameSearchInputRef = React.createRef();
    this.animalNameInputRef = React.createRef();
    this.customerIdRef = React.createRef();
    this.vetIdRef = React.createRef();
    this.examTypeIdRef = React.createRef();
    this.labExamsRef = React.createRef();
    this.clinicalReportRef = React.createRef();

    if (props.loggedUserIsFromClinic) {
      props.getVetsWithCustomerId(
        props.userPersonCustomerId,
        this.onSuccessFocusOnVetField
      );
    }
  }

  componentDidMount() {
    const {
      loggedUserIsFromClinic,
      getExamRequestWithId,
      getAnimalWithId,
      getVetsWithCustomerId,
      getCustomers,
    } = this.props;

    const examRequestId = this.getExamRequestIdFromRoute();

    const onSuccessFillExamRequestForm = (obj) => {
      const customerId = obj.customer.id;

      getVetsWithCustomerId(customerId, this.onSuccessFocusOnVetField);

      const animalId = obj.animal_id;
      const onSuccessUpdateStateWithNewAnimal = this.updateStateWithNewAnimal;

      getAnimalWithId(animalId, onSuccessUpdateStateWithNewAnimal);

      this.fillFormFields(obj);
    };

    examRequestId &&
      getExamRequestWithId(examRequestId, onSuccessFillExamRequestForm);

    let onSuccessDoNothing = () => {};

    getCustomers(
      loggedUserIsFromClinic
        ? onSuccessDoNothing
        : this.onSuccessFocusOnCustomerField
    );
  }

  onSuccessFocusOnCustomerField = () => {
    this.focusOnRef(this.customerIdRef);
  };

  onSuccessFocusOnVetField = () => this.focusOnRef(this.vetIdRef);

  getExamRequestIdFromRoute = () => {
    return this.props.match.params.id ? this.props.match.params.id : 0;
  };

  formIsInUpdateMode = () => {
    return this.getExamRequestIdFromRoute() > 0;
  };

  resetBreedField = () => {
    this.updateField("breedId", 0);
  };

  resetModalSampleCodeField = () => {
    this.updateField("modalSampleCode", "");
  };

  resetPrintedModalUrl = () => {
    this.setState({ printedModalUrl: "" });
  };

  onChangeSpecies = (newId) => {
    this.updateField("speciesId", newId);
    if (newId > 0) {
      this.props.getBreedsWithSpeciesId(newId);
    }
    this.resetBreedField();
  };

  fillYearsAndMonthsFields = (birthDate) => {
    const years = StringUtil.dateToYears(birthDate);
    const monthsLeft = StringUtil.dateToMonths(birthDate);
    this.setState((state) =>
      update(state, {
        fields: {
          ["years"]: { $set: years ? years.toString() : 0 },
          ["months"]: { $set: monthsLeft ? monthsLeft.toString() : 0 },
        },
      })
    );
  };

  fillBirthDate = () => {
    const { months, years } = this.state.fields;
    const now = moment();
    let date = now.add(-months, "M");
    date = date.add(-years, "y");

    this.setState((state) =>
      update(state, { fields: { ["birthDate"]: { $set: date } } })
    );
  };

  onChangeBirthDate = (value) => {
    const fillYearsAndMonthsFieldsFromBirthDate = () =>
      this.fillYearsAndMonthsFields(this.state.birthDate);

    const updateBirthDate = (state) =>
      update(state, { fields: { ["birthDate"]: { $set: value } } });

    this.setState(updateBirthDate, fillYearsAndMonthsFieldsFromBirthDate);
  };

  onChangeYearsOrMonths = (field, value) => {
    const updateField = (state) =>
      update(state, {
        fields: {
          [field]: { $set: value },
        },
      });

    this.setState(updateField, this.fillBirthDate);
  };

  cancelReport = (reportId) => {
    const onSuccess = () => {
      const { requestExams } = this.state.fields;

      const changeRequestExamStateToCancelled = (requestExam) => {
        if (requestExam.id === reportId) {
          requestExam.request_exam_state = REQUEST_EXAM_STATES.CANCELLED;
        }
        return requestExam;
      };

      const mappedRequestExams = requestExams.map(
        changeRequestExamStateToCancelled
      );

      const showSuccessNotification = () =>
        this.props.infoNotification(
          MESSAGES.REQUEST_EXAM_CANCELLED_SUCCESSFULLY
        );

      const updateRequestExams = (state) =>
        update(state, {
          fields: {
            requestExams: { $set: mappedRequestExams },
          },
        });

      this.setState(updateRequestExams, showSuccessNotification);
    };

    const onError = () => {
      this.props.errorNotification(MESSAGES.REQUEST_EXAM_CANCELLING_ERROR);
    };

    this.props.cancelExamRequest(reportId, onSuccess, onError);
  };

  invalidNotification = (title, message) => {
    this.props.errorNotification({
      title: title ? title : "Atenção, dados incompletos!",
      message: message ? message : "Verifique os destaques em vermelho.",
      level: "error",
      position: "tr",
      autoDismiss: TIME_LENGTHS.FIVE_SECONDS,
    });
  };

  updateStateWithNewAnimal = (animal) => {
    this.setState((state) =>
      update(state, {
        fields: {
          id: { $set: animal.id },
          name: { $set: animal.name },
          birthDate: { $set: animal.birthDate },
          owner: { $set: animal.owner },
          phone: { $set: animal.phone },
          cpf: { $set: animal.cpf },
          externalCode: { $set: animal.externalCode },
          microchipCode: { $set: animal.microchipCode },
          sexId: { $set: animal.sexId },
          species: { $set: animal.species },
          speciesId: { $set: animal.speciesId },
          breed: { $set: animal.breed },
          breedId: { $set: animal.breedId },
          customerId: { $set: animal.customerId },
          ageOption: { $set: animal.ageOption },
        },
      })
    );
  };

  updateField = (field, value, callback) => {
    if (field === "modalSampleCode" && value.length > 10) return;

    if (field === "modalSampleCode" && value.length == 10)
      this.receiveAndAssistSampleCode(value);

    if (field === "vetId" && value > 0)
      this.focusOnRef(this.clinicalReportRef.current, 500);

    this.setState((state) =>
      update(state, { fields: { [field]: { $set: value } } })
    );
  };

  handlePrintReportModalClose = () => {
    this.setState({ showPrintReportModal: false });
  };

  disableAnimalFields = (boolean) => {
    this.setState({ shouldDisableAnimalFields: boolean });
  };

  setRef = (ref, field) => {
    field += "Ref";
    this[field] = ref;
  };

  focusOnRef = (ref, timeout = 250) => {
    setTimeout(() => {
      if (!ref || ref.focus === undefined || ref.focus === null) return;
      ref.focus();
    }, timeout);
  };

  handleShowAnimalModal = () => {
    const focusOnAnimalNameSearchInput = () =>
      this.focusOnRef(this.animalNameSearchInputRef.current);

    if (this.state.fields.speciesId) {
      this.props.getBreedsWithSpeciesId(this.state.fields.speciesId);
    }
    const callback = () => {
      setTimeout(focusOnAnimalNameSearchInput, 1500);
    };

    this.setState({ showAnimalModal: true }, callback);
  };

  setAnimalId(animalId) {
    this.setState((state) =>
      update(state, {
        animalId: { $set: animalId },
      })
    );
  }

  hideAnimalModal = () => {
    this.setState({ showAnimalModal: false });
  };

  getLengthOrMinimumTableSize(length) {
    return length > MINUMUM_TABLE_SIZE ? length : MINUMUM_TABLE_SIZE;
  }

  isCustomerSelected = (customerId) => {
    return customerId !== null && customerId > 0;
  };

  handleToggleReceiveAndAssistModal = () => {
    this.setState((state) =>
      update(state, {
        showReceiveAndAssistModal: { $set: !state.showReceiveAndAssistModal },
      })
    );
  };

  handleShowReceiveAndAssistModal = () => {
    this.handleToggleReceiveAndAssistModal();
    this.resetModalSampleCodeField();
  };

  removeReportFromTable = (row) => {
    const { requestExams, requestSamples } = this.state.fields;
    const filterRequestExams = (requestExam) =>
      row.lab_exam.id != requestExam.lab_exam.id;

    const remainingRequestExams = requestExams.filter(filterRequestExams);

    const labExams = remainingRequestExams.map(
      (requestExam) => requestExam.lab_exam
    );

    const filterRequestSamples = (requestSample) => {
      if (requestSample.id > 0) return true;

      for (let i in labExams) {
        for (let j in labExams[i].exam.samples) {
          const sample = labExams[i].exam.samples[j];
          if (requestSample.sample.id == sample.id) {
            return true;
          }
        }
      }

      return false;
    };

    const updateRequestSamples = () => {
      const remainingRequestSamples =
        requestSamples.filter(filterRequestSamples);

      this.setState((state) =>
        update(state, {
          fields: {
            requestSamples: { $set: remainingRequestSamples },
          },
        })
      );
    };

    this.setState(
      (state) =>
        update(state, {
          fields: {
            requestExams: { $set: remainingRequestExams },
          },
        }),
      updateRequestSamples
    );
  };

  receiveAndAssistSampleCode = (code) => {
    const objIsEmpty = (obj) => {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    };

    const onSuccess = (data) => {
      if (objIsEmpty(data)) {
        this.props.errorNotification(MESSAGES.REQUEST_SAMPLE_CODE_NOT_FOUND);
      }
    };
    this.props.receiveAndAssistRequestExam(code, onSuccess);
  };

  clearAnimalFields = () => {
    const defaultAnimal = ExamRequestFormFactory.createDefaultAnimal();
    this.setState((state) => update(state, { fields: defaultAnimal }));
  };

  shouldSendPrintedReport = () => {
    const { customersPromise, loggedUserIsFromClinic } = this.props;
    const { customerId } = this.state.fields;

    const filterCustomers = (customer) => customer.id === customerId;
    const customers = PromiseUtil.extractValue(customersPromise, null);
    if (customers && !loggedUserIsFromClinic) {
      const customer = customers.filter(filterCustomers).shift();
      return customer.shouldSendPrintedReport;
    }

    return true;
  };

  printSingleReport = (reportId) => {
    const API_URL = ENV.REACT_APP_PROXY;
    const S3_URL = ENV.REACT_APP_S3_URL;

    const ROOT = S3_URL ? S3_URL + "/" : API_URL + "/storage/printed-reports/";

    this.resetPrintedModalUrl();

    const onSuccess = (value) => {
      if (!this.shouldSendPrintedReport())
        this.props.warningNotification(MESSAGES.DONT_SEND_REPORT_MESSAGE);

      const fileUrl = ROOT + value.fileName + "#view=FitH";

      this.setState({
        printedModalUrl: fileUrl,
        printedModalFilename: value.fileName,
        showPrintReportModal: true,
      });
    };

    this.props.infoNotification(MESSAGES.WAITING_REPORT_MESSAGE);

    this.props.printedSingleReport(reportId, onSuccess);
  };

  printExamRequest = (examRequestId) => {
    const API_URL = ENV.REACT_APP_PROXY;
    const S3_URL = ENV.REACT_APP_S3_URL;

    const ROOT = S3_URL ? S3_URL + "/" : API_URL + "/storage/printed-reports/";

    this.resetPrintedModalUrl();

    const onSuccess = (value) => {
      if (!this.shouldSendPrintedReport())
        this.props.warningNotification(MESSAGES.DONT_SEND_REPORT_MESSAGE);

      const fileUrl = ROOT + value.fileName + "#view=FitH";

      this.setState({
        printedModalUrl: fileUrl,
        showPrintReportModal: true,
      });
    };

    this.props.infoNotification(MESSAGES.WAITING_REPORT_MESSAGE);

    this.props.getExamRequestPrintedReport(examRequestId, onSuccess);
  };

  handleShowAnimalForm = () => {
    const { animalFilterName, animalFilterOwner, animalFilterSexId } =
      this.state.fields;

    const focusOnAnimalNameInput = () =>
      this.focusOnRef(this.animalNameInputRef.current);

    this.setState(
      (state) =>
        update(state, {
          fields: {
            name: { $set: animalFilterName.toUpperCase() },
            owner: { $set: animalFilterOwner.toUpperCase() },
            sexId: { $set: animalFilterSexId },
          },
        }),
      focusOnAnimalNameInput
    );

    this.setState({ shouldShowAnimalForm: true });
  };

  handleCloseAnimalForm = () => {
    this.setState((state) =>
      update(state, {
        shouldShowAnimalForm: { $set: false },
      })
    );
  };

  onExitedAnimalModal = () => {
    this.selectRowOfId(0);
    this.disableAnimalFields(false);
    this.handleCloseAnimalForm();
  };

  emailExamRequestReports = () => {
    const onSuccess = () =>
      this.props.successNotification(MESSAGES.EMAIL_SENT_SUCCESSFULLY);

    const onError = () =>
      this.props.errorNotification(MESSAGES.EMAIL_SENDING_ERROR);

    if (this.shouldSendEmail()) {
      this.props.emailExamRequest(
        this.getExamRequestIdFromRoute(),
        onSuccess,
        onError
      );
    } else {
      this.props.warningNotification(
        MESSAGES.NO_PERMISSION_TO_SEND_EMAIL_WARNING
      );
    }
  };

  shouldSendEmail = () => {
    const { requestExams } = this.state.fields;
    const acceptSendStatus = [
      REQUEST_EXAM_STATES.ATTENDED_AND_RELEASED,
      REQUEST_EXAM_STATES.BILLED,
    ];

    const requestExamStates = requestExams.map(
      (requestExam) => requestExam.request_exam_state
    );

    let hasAttendedAndReleasedRequestExams = false;
    requestExamStates.forEach((state) => {
      if (acceptSendStatus.includes(state))
        hasAttendedAndReleasedRequestExams = true;
    });
    return hasAttendedAndReleasedRequestExams;
  };

  redirectToExamRequestOfId = (examRequestId) => {
    this.props.history.push(`/solicitacoes-exame/${examRequestId}`);
  };

  redirectToList = () => {
    this.setState({ redirectToList: true });
  };

  fillFormFields = (obj) => {
    this.setState((state) =>
      update(state, {
        fields: {
          customerId: { $set: obj.customer_id },
          animalId: { $set: obj.animal_id },
          clinicalReport: { $set: obj.clinical_report },
          vetId: { $set: obj.vet_id },
          requestExams: { $set: obj.request_exams },
          requestSamples: { $set: obj.request_samples },
        },
        examRequestState: { $set: obj.exam_request_state },
      })
    );
  };

  restartForm = () => {
    const { loggedUserIsFromClinic, userPersonCustomerId } = this.props;
    const callbackSetVet = () => {
      if (loggedUserIsFromClinic) {
        this.getVetsWithCustomerId(userPersonCustomerId, () =>
          this.focusOnRef(this.vetIdRef)
        );
      } else {
        this.focusOnRef(this.customerIdRef);
      }
    };

    const initialState = ExamRequestFormFactory.createInitialState(
      loggedUserIsFromClinic,
      userPersonCustomerId
    );

    this.setState(
      (state) =>
        update(state, {
          $set: initialState,
        }),
      callbackSetVet
    );
  };

  getRequestSampleIndexFromFieldName = (fieldName) => {
    const substrToRemove = "requestSample-";
    return fieldName.replace(substrToRemove, "");
  };

  onChangeSampleCode = (field, value) => {
    const index = this.getRequestSampleIndexFromFieldName(field);

    if (value && value.length > 10)
      value = this.state.fields.requestSamples[index]["code"];

    const updateSampleCode = (index, value) => {
      this.setState(
        (state) =>
          update(state, {
            fields: {
              ["requestSamples"]: {
                [index]: {
                  ["code"]: { $set: value },
                },
              },
            },
          }),
        () => this.focusOnExamSampleField(index, value.length)
      );
    };

    updateSampleCode(index, value);
  };

  onChangeRequestExamIsPaidField = (requestExamIndex, value) => {
    this.setState((state) =>
      update(state, {
        fields: {
          ["requestExams"]: {
            [requestExamIndex]: {
              ["is_paid"]: { $set: value },
            },
          },
        },
      })
    );
  };

  setRequestExamSampleFieldRef = (element) => {
    if (!element) return;

    const index = this.getRequestSampleIndexFromFieldName(element.name);

    this.setState((state) =>
      update(state, {
        fields: {
          ["requestSamples"]: {
            [index]: {
              ["ref"]: { $set: element },
            },
          },
        },
      })
    );
  };

  focusOnExamSampleField = (index, length) => {
    const { requestSamples } = this.state.fields;
    const focusOnRequestSample = (index) => {
      requestSamples[index].ref.focus();
    };
    index = parseInt(index) + 1;

    if (length === 10 && requestSamples[index] !== undefined) {
      setTimeout(() => focusOnRequestSample(index), 100);
    }
  };

  onSelectCustomer = (customerId) => {
    if (customerId > 0) {
      this.props.getVetsWithCustomerId(customerId, () =>
        this.focusOnRef(this.vetIdRef)
      );
    }
    this.updateField("customerId", customerId);
    this.updateField("vetId", 0);
  };

  onSelectExamType = (examTypeId) => {
    const { customerId } = this.state.fields;
    const onSuccess = () => this.focusOnRef(this.labExamsRef);

    if (examTypeId > 0) {
      this.props.getLabExamsWithCustomerIdAndExamTypeId(
        customerId,
        examTypeId,
        onSuccess
      );
    }
    this.updateField("examTypeId", examTypeId);
    this.updateField("labExams", []);
  };

  getAlreadyIncludedElementsInTheArray(newElements, previousElements) {
    const filterAlreadyIncludedElements = (prev) => {
      for (const element of newElements) {
        if (element.name === prev.name) return true;
      }
      return false;
    };

    const alreadyIncluded = previousElements.filter(
      filterAlreadyIncludedElements
    );

    return alreadyIncluded;
  }

  composeExamWarningMessage(exams) {
    const reducer = (message, exam, index, array) => {
      if (index === 0) return message + exam.name;
      let isPenultimate = array.length === index + 1;
      let connector = isPenultimate ? " e " : ", ";
      return message + connector + exam.name;
    };

    const singleExamMessage = (exams) => {
      return "Exame " + exams[0].name + " já incluído!";
    };

    const multipleExamMessage = (exams, reducer) => {
      return "Exames " + exams.reduce(reducer, "") + " já incluídos!";
    };

    let message =
      exams.length > 1
        ? multipleExamMessage(exams, reducer)
        : singleExamMessage(exams);

    if (message.length > MAX_LENGTH_NOTIFICATION) {
      message = "Um ou mais exames selecionados já foram incluídos!";
    }

    return message;
  }

  elementIsInTheArray = (element, array) => {
    const included = this.getAlreadyIncludedElementsInTheArray(
      [element],
      array
    );
    return included.length > 0;
  };

  getSamplesYetNotAddedToTheExamRequest = (labExams) => {
    const { requestSamples } = this.state.fields;
    let yetNotAddedSamples = [];

    const getYetNotAddedSamples = (sample) => {
      if (
        !this.elementIsInTheArray(sample, yetNotAddedSamples) &&
        !this.elementIsInTheArray(sample, requestSamples)
      ) {
        yetNotAddedSamples.push({
          id: 0,
          code: "",
          name: sample.name,
          sample: sample,
        });
      }
    };

    for (let labExam of labExams) {
      labExam.samples.forEach(getYetNotAddedSamples);
    }

    return yetNotAddedSamples;
  };

  composeRequestExamsFrom(labExams) {
    return labExams.map((labExam) => ({
      id: 0,
      name: labExam.name,
      price: labExam.price,
      deadline_in_days: labExam.deadline_in_days,
      obs: "",
      payment_method: "",
      payment_type_id: 0,
      lab_exam: labExam,
      is_paid: 0,
      request_exam_state: this.getRequestExamInitialStateFromUserProfile(),
    }));
  }

  showSelectAtLeastOneExamNotification = () =>
    this.props.warningNotification(MESSAGES.PENDING_EXAM_SELECTION);

  showExamsAlreadyIncludedNotification = (exams) => {
    const message = this.composeExamWarningMessage(exams);
    this.props.warningNotification(
      MESSAGES.generateWarningForExamsAlreadyIncluded(message)
    );
  };

  getRequestExamInitialStateFromUserProfile = () => {
    return this.props.loggedUserIsFromClinic
      ? -1
      : REQUEST_EXAM_STATES.IN_PROGRESS;
  };

  addLabExams = () => {
    const { labExams, requestExams, examTypeId } = this.state.fields;

    const isBiochemistry =
      examTypeId === EXAMS.EXAM_TYPE_ID_BY_NAME["BIOCHEMISTRY"];

    const noExamWasSelected = labExams.length === 0;

    if (noExamWasSelected) {
      this.showSelectAtLeastOneExamNotification();
      return;
    }

    const newRequestExams = this.composeRequestExamsFrom(labExams);

    const alreadyIncluded = this.getAlreadyIncludedElementsInTheArray(
      newRequestExams,
      requestExams
    );

    const hasAlreadyIncludedExams = alreadyIncluded.length > 0;

    if (hasAlreadyIncludedExams) {
      this.showExamsAlreadyIncludedNotification(alreadyIncluded);
      return;
    }

    this.addRequestExams(newRequestExams);

    const requestSamples = this.getSamplesYetNotAddedToTheExamRequest(labExams);

    this.addRequestSamples(requestSamples);
  };

  addRequestExams = (newRequestExams) => {
    const { requestExams } = this.state.fields;

    const updateRequestExams = (state) => {
      const alreadyIncluded = this.getAlreadyIncludedElementsInTheArray(
        newRequestExams,
        requestExams
      );

      if (alreadyIncluded.length > 0) {
        return;
      }

      return update(state, {
        fields: { ["requestExams"]: { $push: newRequestExams } },
      });
    };

    const resetLabExamsAndRequestExamsPageSize = () => {
      this.setState((state) =>
        update(state, {
          fields: {
            ["labExams"]: { $set: [] },
            ["requestExamsPageSize"]: {
              $set: this.getLengthOrMinimumTableSize(requestExams.length),
            },
          },
        })
      );
    };

    this.setState(updateRequestExams, resetLabExamsAndRequestExamsPageSize);
  };

  updateRequestExamField = (requestExamId, field, value) => {
    const { requestExams } = this.state.fields;

    const findRequestExamById = (requestExamId, requestExams) => {
      let foundIndex;
      requestExams.forEach((requestExam, index) => {
        if (requestExam.id == requestExamId) {
          foundIndex = index;
        }
      });
      return foundIndex;
    };

    const updateField = (requestExamIndex, field, value) => {
      return (state) =>
        update(state, {
          fields: {
            ["requestExams"]: {
              [requestExamIndex]: {
                [field]: { $set: value },
              },
            },
          },
        });
    };

    const requestExamIndex = findRequestExamById(requestExamId, requestExams);

    this.setState(updateField(requestExamIndex, field, value));
  };

  addRequestSamples = (newRequestSamples) => {
    const addNewRequestSamplesToState = (state) =>
      update(state, {
        fields: {
          ["requestSamples"]: { $push: newRequestSamples },
        },
      });

    const updateSamplePageSize = () => {
      this.setState((state) =>
        update(state, {
          fields: {
            ["requestSamplesPageSize"]: {
              $set: this.getLengthOrMinimumTableSize(
                state.fields.requestSamples.length
              ),
            },
          },
        })
      );
    };

    this.setState(addNewRequestSamplesToState, updateSamplePageSize);
  };

  createExamRequestFromState = () => {
    const {
      customerId,
      animalId,
      speciesId,
      breedId,
      sexId,
      name,
      years,
      months,
      owner,
      clinicalReport,
      vetId,
      examTypeId,
      requestExams,
      requestSamples,
    } = this.state.fields;

    const deleteRefFromRequestSample = (requestSample) => {
      delete requestSample.ref;
      return requestSample;
    };

    const requestSamplesWithNoRefs = requestSamples.map(
      deleteRefFromRequestSample
    );

    return {
      id: this.getExamRequestIdFromRoute(),
      customerId: customerId,
      animalId: animalId,
      speciesId: speciesId,
      breedId: breedId,
      sexId: sexId,
      name: name,
      birth: "0000-00-00",
      years: years,
      months: months,
      owner: owner,
      clinicalReport: clinicalReport,
      vetId: vetId,
      examTypeId: examTypeId,
      requestExams: requestExams,
      requestSamples: requestSamplesWithNoRefs,
    };
  };

  handleSubmitAndRestart = (resetForm) => {
    const obj = this.createExamRequestFromState();

    const onSuccess = (data, xhr) => {
      if (xhr.response.status >= 200 && xhr.response.status < 300) {
        this.props.successNotification(MESSAGES.CREATED_EXAM_REQUEST);
      }

      resetForm && resetForm();
    };

    this.props.addExamRequest(obj, onSuccess);
  };

  handleSubmitNormal = () => {
    this.handleSubmit(false);
  };

  handleSubmitPartial = () => {
    this.handleSubmit(true);
  };

  handleSubmit = (isPartialSave = false) => {
    const formIsInUpdateMode = this.formIsInUpdateMode();
    const examRequest = this.createExamRequestFromState();

    const onSuccess = (data, xhr) => {
      if (xhr.response.status >= 200 && xhr.response.status < 300) {
        const message = formIsInUpdateMode
          ? MESSAGES.UPDATED_EXAM_REQUEST
          : MESSAGES.CREATED_EXAM_REQUEST;

        this.props.successNotification(message);

        if (!formIsInUpdateMode) this.redirectToExamRequestOfId(data.id);

        this.fillFormFields(data);
      }

      this.redirectToList();
    };

    if (formIsInUpdateMode) {
      this.props.updateExamRequest(examRequest, onSuccess, isPartialSave);
    } else {
      this.props.addExamRequest(examRequest, onSuccess, isPartialSave);
    }
  };

  labExamsWithElectrolites(labExamsData) {
    let data = cloneDeep(labExamsData);

    data.options = _.orderBy(data.options, ["name"], ["asc"]);

    return data;
  }

  panelTitle(id, formIsInUpdateMode) {
    return formIsInUpdateMode
      ? "Solicitação de Exame Nº " + id
      : "Nova Solicitação de Exame";
  }

  shouldDisplayLoader() {
    const { fetchedExamRequestPromise } = this.props;

    const examRequestIsReady =
      fetchedExamRequestPromise && fetchedExamRequestPromise.fulfilled;

    return this.formIsInUpdateMode() && !examRequestIsReady;
  }

  createAnimalObjFromState = () => {
    const {
      id,
      name,
      birthDate,
      owner,
      phone,
      cpf,
      externalCode,
      microchipCode,
      sexId,
      species,
      speciesId,
      breed,
      breedId,
      customerId,
      createdAt,
      ageOption,
    } = this.state.fields;
    const fetchedExamRequestData = PromiseUtil.extractValue(
      this.props.fetchedExamRequestPromise
    );
    return {
      id: id,
      name: name,
      birthDate: birthDate,
      ageOption: ageOption,
      years: fetchedExamRequestData?.created_at
        ? StringUtil.newDateToYears(
            birthDate,
            fetchedExamRequestData.created_at
          )
        : StringUtil.dateToYears(birthDate),
      months: fetchedExamRequestData?.created_at
        ? StringUtil.newDateToMonths(
            birthDate,
            fetchedExamRequestData.created_at
          )
        : StringUtil.dateToMonths(birthDate),
      owner: owner,
      phone: phone,
      cpf: cpf,
      externalCode: externalCode,
      microchipCode: microchipCode,
      sexId: sexId,
      species: species,
      speciesId: speciesId,
      breed: breed,
      breedId: breedId,
      customerId: customerId,
    };
  };

  handleAnimalSubmit = (callback) => {
    const obj = this.createAnimalObjFromState();
    this.disableAnimalFields(true);
    const onSuccess = (animal, xhr) => {
      if (xhr.response.status >= 200 && xhr.response.status < 300) {
        const onSuccess = this.updateStateWithNewAnimal;
        this.props.getAnimalWithId(animal.id, onSuccess);
        this.setSelectedAnimal(animal.id);
        this.hideAnimalModal();
        callback();
        this.focusOnRef(this.examTypeIdRef, 500);
        if (obj.id && obj.id != animal.id) {
          this.handleSubmit(true);
        }
      }
    };
    if (this.state.fields.id > 0) {
      this.props.updateAnimal(obj, onSuccess);
    } else {
      this.props.addAnimal(obj, onSuccess);
    }
  };

  filterAnimals = (reactTableState) => {
    const { customerId } = this.state.fields;

    const filters = {
      id: "",
      name: "",
      customer: "",
      owner: "",
      species: "",
      breed: "",
      externalCode: "",
      microchipCode: "",
      cpf: "",
      phone: "",
      sexId: "",
      customerId: customerId,
    };

    reactTableState.filtered.forEach((filter) => {
      filters[filter.id] = filter.value;
    });

    const onSuccess = (data) => {
      this.setState({
        animalPages: data.last_page,
      });
    };

    this.props.searchAnimals(
      filters,
      reactTableState.page,
      reactTableState.pageSize,
      reactTableState.sorted,
      onSuccess
    );
  };

  setSelectedAnimal = (id) => {
    this.setState((state) =>
      update(state, { fields: { animalId: { $set: id } } })
    );
    // this.props.getExamsReferenceValuesByAnimalId(id);
  };

  selectRowOfId = (value) => {
    this.setState({ selectedRowId: value });
  };

  handleSelectAnimal = () => {
    const selectedRowId = this.state.selectedRowId;
    if (selectedRowId === 0) {
      this.props.warningNotification(MESSAGES.PENDING_ANIMAL_SELECTION);
      return;
    }
    this.setSelectedAnimal(selectedRowId);
    this.props.getAnimalWithId(selectedRowId, this.updateStateWithNewAnimal);
    this.hideAnimalModal();
  };

  handleKeepAnimalFilterState = (filtered) => {
    const nameObj = ArrayUtil.findByKeyVal(filtered, "id", "name");
    const ownerObj = ArrayUtil.findByKeyVal(filtered, "id", "owner");
    const sexIdObj = ArrayUtil.findByKeyVal(filtered, "id", "sexId");

    const name = nameObj ? nameObj.value : "";
    const owner = ownerObj ? ownerObj.value : "";
    const sexId = sexIdObj ? parseInt(sexIdObj.value, 10) : 0;

    this.setState((state) =>
      update(state, {
        fields: {
          animalFilterName: { $set: name },
          animalFilterOwner: { $set: owner },
          animalFilterSexId: { $set: sexId },
        },
      })
    );
  };

  handleAnimalTableClicks = (state, rowInfo, column) => {
    const { selectedRowId } = this.state;
    const currentId = rowInfo ? rowInfo.original.id : null;
    const shouldPaint = currentId === selectedRowId;
    const backgroundColor = "#4dc3bf";

    return {
      onClick: (e, handleOriginal) => {
        this.selectRowOfId(currentId);
        handleOriginal && handleOriginal();
      },
      onDoubleClick: (e, handleOriginal) => {
        this.setSelectedAnimal(currentId);
        this.props.getAnimalWithId(currentId, this.updateStateWithNewAnimal);
        this.hideAnimalModal();
        handleOriginal && handleOriginal();
        this.focusOnRef(this.examTypeIdRef, 500);
      },
      style: {
        background: shouldPaint ? backgroundColor : "",
      },
    };
  };

  animalObj() {
    const { fetchedAnimalPromise } = this.props;
    const isPromiseFulfilled =
      fetchedAnimalPromise !== undefined && fetchedAnimalPromise.fulfilled;

    if (isPromiseFulfilled) {
      return {
        name: fetchedAnimalPromise.value.name,
        sexId: fetchedAnimalPromise.value.sexId,
        species: fetchedAnimalPromise.value.species,
        breed: fetchedAnimalPromise.value.breed,
        years: StringUtil.dateToYears(fetchedAnimalPromise.value.birthDate),
        months: StringUtil.dateToMonths(fetchedAnimalPromise.value.birthDate),
        owner: fetchedAnimalPromise.value.owner,
        ageOption: fetchedAnimalPromise.value.ageOption,
      };
    }
    return {
      name: "",
      sexId: 0,
      species: "",
      breed: "",
      years: "",
      months: "",
      ageOption: null,
      owner: "",
    };
  }

  shouldDisableCustomer = (accessProfile) => {
    const shouldDisable = [
      ACCESS_PROFILES.CLINIC_USER,
      ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
    ];

    return shouldDisable.includes(accessProfile);
  };

  shouldDisableExamsTableFields = () => {
    const shouldDisable = [
      ACCESS_PROFILES.CLINIC_USER,
      ACCESS_PROFILES.CLINIC_ADMINISTRATOR,
    ];
    return shouldDisable.includes(this.props.access_profile);
  };

  onTableDoubleClick = (currentId, state, rowInfo, column, singleClick) => {
    if (!rowInfo) {
      return;
    }
    if (
      !column.id &&
      rowInfo.original.id > 0 &&
      rowInfo.original.final_dead_line != null &&
      rowInfo.original.final_price != null
    )
      return;

    if (
      this.props.loggedUserIsFromClinic &&
      rowInfo.original.request_exam_state >=
        REQUEST_EXAM_STATES.ATTENDED_AND_RELEASED
    ) {
      this.printSingleReport(rowInfo.original.report.id);
      return;
    }

    if (rowInfo.original.request_exam_state === REQUEST_EXAM_STATES.PENDING) {
      return;
    }

    if (singleClick) return;

    const original = rowInfo.original;
    const report = rowInfo.original.report;
    const reportInfo = report ? report.id : "novo";

    this.setState({
      shouldRedirect: true,
      showPrintReportModal: true,
      redirectTo: {
        examRequestId: this.getExamRequestIdFromRoute(),
        requestExamId: original.id,
        examName: EXAMS.EXAM_URL_BY_ID(original.lab_exam.exam.id),
        examId: reportInfo,
        examType: original.lab_exam.exam.exam_type.id,
        isOutsourced: original.lab_exam.exam.is_outsourced,
      },
    });
  };

  redirectToRequestExam = () => {
    const {
      examRequestId,
      requestExamId,
      examName,
      examId,
      examType,
      isOutsourced,
    } = this.state.redirectTo;

    let isBiochemistry = false,
      isChemiluminescence = false,
      isRadioimmunoassay = false;
    switch (examType) {
      case EXAMS.EXAM_TYPE_ID_BY_NAME.BIOCHEMISTRY:
        isBiochemistry = true;
        break;
      case EXAMS.EXAM_TYPE_ID_BY_NAME.ENDOCRINOLOGY_CHEMILUMINESCENCE:
        isBiochemistry = true;
        break;
      case EXAMS.EXAM_TYPE_ID_BY_NAME.ENDOCRINOLOGY_RADIOIMMUNOASSAY:
        isBiochemistry = true;
        break;

      default:
        break;
    }
    const examIsOfTableReport =
      isBiochemistry || isChemiluminescence || isRadioimmunoassay;

    if (!isOutsourced && examIsOfTableReport) {
      const examTypeName = EXAMS.EXAM_TYPE_BY_ID[examType];
      let url = `/solicitacoes-exame/${examRequestId}/${examTypeName}`;
      return <Redirect to={url} />;
    } else {
      let url = `/solicitacoes-exame/${examRequestId}/exame/${requestExamId}/${examName}/${examId}`;
      return <Redirect to={url} />;
    }
  };

  render() {
    const {
      shouldRedirect,
      redirectTo,
      fields,
      showAnimalModal,
      animalPages,
      shouldShowAnimalForm,
      shouldDisableAnimalFields,
      examRequestState,
    } = this.state;

    const {
      vetsPromise,
      breedsPromise,
      fetchedAnimalPromise,
      searchedAnimalsPromise,
      createdAnimalPromise,
      speciesPromise,
      labExamsPromise,
      customersPromise,
      examTypesPromise,
      submittedExamRequestPromise,
      receiveAndAssistPromise,
      loggedUserIsFromClinic,
    } = this.props;

    const receivedAndAssistedExamRequest = PromiseUtil.extractValue(
      receiveAndAssistPromise,
      null
    );

    if (shouldRedirect && redirectTo.requestExamId) {
      return this.redirectToRequestExam();
    }

    if (receivedAndAssistedExamRequest && receivedAndAssistedExamRequest.id) {
      this.redirectToExamRequestOfId(receivedAndAssistedExamRequest.id);
    }

    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" load />;
    }

    const formIsInUpdateMode = this.formIsInUpdateMode();
    const isExamTypesSelected = fields.examTypeId > 0;
    const disableAll =
      submittedExamRequestPromise && submittedExamRequestPromise.pending;
    const alreadySubmitted =
      submittedExamRequestPromise && submittedExamRequestPromise.pending;
    const panelTitle = this.panelTitle(
      this.getExamRequestIdFromRoute(),
      formIsInUpdateMode
    );
    const disableCustomerSelect =
      this.shouldDisableCustomer(this.props.access_profile) ||
      this.getExamRequestIdFromRoute() > 0;
    const isReadOnly =
      examRequestState > EXAM_REQUEST_STATES.PENDING && loggedUserIsFromClinic;

    // Data
    const data = {
      alreadySubmitted: alreadySubmitted,
      id: this.getExamRequestIdFromRoute(),
      panelTitle: panelTitle,
      fields: fields,
      isReadOnly: isReadOnly,
      submittedExamRequestPromise: submittedExamRequestPromise,
      formIsInUpdateMode: formIsInUpdateMode,
      disableAll: disableAll,
      animal: this.createAnimalObjFromState(),
      fetchedAnimalPromise: fetchedAnimalPromise,
      createdAnimalPromise: createdAnimalPromise,
      searchedAnimalsPromise: searchedAnimalsPromise,
      labExamsPromise: labExamsPromise,
      isExamTypesSelected: isExamTypesSelected,
      examTypesPromise: examTypesPromise,
      showAnimalModal: showAnimalModal,
      animalPages: animalPages,
      shouldShowAnimalForm: shouldShowAnimalForm,
      speciesPromise: speciesPromise,
      breedsPromise: breedsPromise,
      customersPromise: customersPromise,
      vetsPromise: vetsPromise,
      shouldDisableAnimalFields: shouldDisableAnimalFields,
      showReceiveAndAssistModal: this.state.showReceiveAndAssistModal,
      disableCustomerSelect: disableCustomerSelect,
      loggedUserIsFromClinic: this.props.loggedUserIsFromClinic,
      showPrintReportModal: this.state.showPrintReportModal,
      printedModalUrl: this.state.printedModalUrl,
      printedModalFilename: this.state.printedModalFilename,
      animalNameSearchInputRef: this.animalNameSearchInputRef,
      animalNameInputRef: this.animalNameInputRef,
      clinicalReportRef: this.clinicalReportRef,
      setRef: this.setRef,
      isMobile: this.props.isMobile,
    };

    // Methods
    const methods = {
      handlePrintReportModalClose: this.handlePrintReportModalClose,
      updateField: this.updateField,
      invalidNotification: this.invalidNotification,
      onChangeSampleCode: this.onChangeSampleCode,
      printSingleReport: this.printSingleReport,
      printExamRequest: this.printExamRequest,
      restartForm: this.restartForm,
      onSelectCustomer: this.onSelectCustomer,
      onSelectExamType: this.onSelectExamType,
      addLabExams: this.addLabExams,
      emailExamRequestReports: this.emailExamRequestReports,
      isCustomerSelected: this.isCustomerSelected,
      handleShowAnimalModal: this.handleShowAnimalModal,
      filterAnimals: this.filterAnimals,
      handleShowAnimalForm: this.handleShowAnimalForm,
      handleCloseAnimalForm: this.handleCloseAnimalForm,
      handleAnimalTableClicks: this.handleAnimalTableClicks,
      handleSelectAnimal: this.handleSelectAnimal,
      onChangeSpecies: this.onChangeSpecies,
      onChangeYearsOrMonths: this.onChangeYearsOrMonths,
      onChangeBirthDate: this.onChangeBirthDate,
      handleAnimalSubmit: this.handleAnimalSubmit,
      onExitedAnimalModal: this.onExitedAnimalModal,
      handleAnimalModalClose: this.hideAnimalModal,
      handleKeepAnimalFilterState: this.handleKeepAnimalFilterState,
      handleSubmitAndRestart: this.handleSubmitAndRestart,
      handleCloseReceiveAndAssistModal: this.handleToggleReceiveAndAssistModal,
      handleShowReceiveAndAssistModal: this.handleShowReceiveAndAssistModal,
      onTableDoubleClick: this.onTableDoubleClick,
      onTableSingleClick: this.onTableDoubleClick,
      removeReportFromTable: this.removeReportFromTable,
      isUniqSample: this.props.isUniqSample,
      receiveAndAssistSampleCode: this.receiveAndAssistSampleCode,
      setRequestExamSampleFieldRef: this.setRequestExamSampleFieldRef,
      updateRequestExamPrice: this.updateRequestExamPrice,
      updateRequestExamDeadline: this.updateRequestExamDeadline,
      updateRequestExamField: this.updateRequestExamField,
      shouldDisableExamsTableFields: this.shouldDisableExamsTableFields,
      handleSubmitPartial: this.handleSubmitPartial,
      handleSubmitNormal: this.handleSubmitNormal,
      onChangeRequestExamIsPaidField: this.onChangeRequestExamIsPaidField,
      cancelReport: this.cancelReport,
      infoNotification: this.props.infoNotification,
      history: this.props.history,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
        <Notifications notifications={this.props.notifications} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(
  connect(mapStateToProps, mapDispatchToProps),
  connectWithEndpoint
)(ExamRequestForm);
