import React from "react";

/** Internal */
import FlatPanelMaterial from "../FlatPanelMaterial";
import ReportHeader from "./../ReportHeader";
import DualRadioMaterial2 from "./../DualRadioMaterial2";
import ResultPanel from "./../ResultPanel";
import ObsPanel from "./../ObsPanel";
import InputTextMaterial from "./../InputTextMaterial";
import NumberInputMaterial2 from "./../NumberInputMaterial2";
import SelectMaterial2 from "./../SelectMaterial2";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";

const MATERIALS = [
  { id: 1, name: "Cálculo Renal" },
  { id: 2, name: "Cálculo Urinário" },
  { id: 3, name: "Cálculo Biliar" },
  { id: 4, name: "Cálculo Vesical" },
];

const CHEMISTRY_EXAM = [
  { id: 1, name: "Negativo" },
  { id: 2, name: "Presença Discreta" },
  { id: 3, name: "Presença Moderada" },
  { id: 4, name: "Presença Acentuada" },
];

const UrinaryAndBiliaryCalculusReportFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Análise de Cálculo Urinário / Biliar"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <FlatPanelMaterial title="Material">
      <Row>
        <Col md={4}>
          <SelectMaterial2
            title="Material Enviado"
            name="materialId"
            options={MATERIALS}
            value={data.fields.materialId}
            isSearchable={true}
            hasValidationRecoil={true}
            shouldDisable={data.disableAll}
            onChange={methods.handleChange}
            onBlur={methods.handleBlur}
            hasValidation={data.$validation.materialId.show}
            validationReason={data.$validation.materialId.error.reason}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}></Col>
      </Row>
    </FlatPanelMaterial>
    <FlatPanelMaterial title="Exame Físico">
      <Row className="mb-10">
        <Col md={3}>
          <NumberInputMaterial2
            title="Dimensão X"
            name="dimensionX"
            hasValidationRecoil={true}
            decimalScale={2}
            block={true}
            inputGroup={true}
            allowNegative={false}
            value={data.fields.dimensionX}
            disabled={data.disableAll}
            handleChange={methods.handleChange}
            handleBlur={methods.handleBlur}
            hasValidation={data.$validation.dimensionX.show}
            validationReason={data.$validation.dimensionX.error.reason}
          />
        </Col>
        <Col md={3}>
          <NumberInputMaterial2
            title="Dimensão Y"
            name="dimensionY"
            hasValidationRecoil={true}
            decimalScale={2}
            block={true}
            inputGroup={true}
            allowNegative={false}
            value={data.fields.dimensionY}
            disabled={data.disableAll}
            handleChange={methods.handleChange}
            handleBlur={methods.handleBlur}
            hasValidation={data.$validation.dimensionY.show}
            validationReason={data.$validation.dimensionY.error.reason}
          />
        </Col>
        <Col md={3}>
          <NumberInputMaterial2
            title="Dimensão Z"
            name="dimensionZ"
            hasValidationRecoil={true}
            decimalScale={2}
            block={true}
            inputGroup={true}
            allowNegative={false}
            value={data.fields.dimensionZ}
            disabled={data.disableAll}
            handleChange={methods.handleChange}
            handleBlur={methods.handleBlur}
            hasValidation={data.$validation.dimensionZ.show}
            validationReason={data.$validation.dimensionZ.error.reason}
          />
        </Col>
        <Col md={3}>
          <NumberInputMaterial2
            title="Peso (g)"
            name="weight"
            hasValidationRecoil={true}
            decimalScale={3}
            block={true}
            inputGroup={true}
            allowNegative={false}
            value={data.fields.weight}
            disabled={data.disableAll}
            handleChange={methods.handleChange}
            handleBlur={methods.handleBlur}
            hasValidation={data.$validation.weight.show}
            validationReason={data.$validation.weight.error.reason}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <InputTextMaterial
            title="Cor"
            name="color"
            shouldDisable={data.disableAll}
            value={data.fields.color}
            handleBlur={methods.handleBlur}
            handleChange={methods.handleChange}
            hasValidation={data.$validation.color.show}
            validationReason={data.$validation.color.error.reason}
          />
        </Col>
        <Col md={3}>
          <InputTextMaterial
            title="Consistência"
            name="consistency"
            shouldDisable={data.disableAll}
            value={data.fields.consistency}
            handleBlur={methods.handleBlur}
            handleChange={methods.handleChange}
            hasValidation={data.$validation.consistency.show}
            validationReason={data.$validation.consistency.error.reason}
          />
        </Col>
        <Col md={3}>
          <InputTextMaterial
            title="Forma"
            name="shape"
            shouldDisable={data.disableAll}
            value={data.fields.shape}
            handleBlur={methods.handleBlur}
            handleChange={methods.handleChange}
            hasValidation={data.$validation.shape.show}
            validationReason={data.$validation.shape.error.reason}
          />
        </Col>
        <Col md={3}>
          <InputTextMaterial
            title="Superfície"
            name="surface"
            shouldDisable={data.disableAll}
            value={data.fields.surface}
            handleBlur={methods.handleBlur}
            handleChange={methods.handleChange}
            hasValidation={data.$validation.surface.show}
            validationReason={data.$validation.surface.error.reason}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
    <FlatPanelMaterial title="Exame Químico">
      <Row>
        <Col md={4}>
          <SelectMaterial2
            title="Carbonato"
            name="carbonate"
            options={CHEMISTRY_EXAM}
            value={data.fields.carbonate}
            isSearchable={true}
            hasValidationRecoil={true}
            shouldDisable={data.disableAll}
            onChange={methods.handleChange}
            onBlur={methods.handleBlur}
            hasValidation={data.$validation.carbonate.show}
            validationReason={data.$validation.carbonate.error.reason}
          />
        </Col>
        <Col md={4}>
          <SelectMaterial2
            title="Fosfato"
            name="phosphate"
            options={CHEMISTRY_EXAM}
            value={data.fields.phosphate}
            isSearchable={true}
            hasValidationRecoil={true}
            shouldDisable={data.disableAll}
            onChange={methods.handleChange}
            onBlur={methods.handleBlur}
            hasValidation={data.$validation.phosphate.show}
            validationReason={data.$validation.phosphate.error.reason}
          />
        </Col>
        <Col md={4}>
          <SelectMaterial2
            title="Urato"
            name="urato"
            options={CHEMISTRY_EXAM}
            value={data.fields.urato}
            isSearchable={true}
            hasValidationRecoil={true}
            shouldDisable={data.disableAll}
            onChange={methods.handleChange}
            onBlur={methods.handleBlur}
            hasValidation={data.$validation.urato.show}
            validationReason={data.$validation.urato.error.reason}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <SelectMaterial2
            title="Oxalato"
            name="oxalate"
            options={CHEMISTRY_EXAM}
            value={data.fields.oxalate}
            isSearchable={true}
            hasValidationRecoil={true}
            shouldDisable={data.disableAll}
            onChange={methods.handleChange}
            onBlur={methods.handleBlur}
            hasValidation={data.$validation.oxalate.show}
            validationReason={data.$validation.oxalate.error.reason}
          />
        </Col>
        <Col md={4}>
          <SelectMaterial2
            title="Magnésio"
            name="magnesium"
            options={CHEMISTRY_EXAM}
            value={data.fields.magnesium}
            isSearchable={true}
            hasValidationRecoil={true}
            shouldDisable={data.disableAll}
            onChange={methods.handleChange}
            onBlur={methods.handleBlur}
            hasValidation={data.$validation.magnesium.show}
            validationReason={data.$validation.magnesium.error.reason}
          />
        </Col>
        <Col md={4}>
          <SelectMaterial2
            title="Cistina"
            name="cistina"
            options={CHEMISTRY_EXAM}
            value={data.fields.cistina}
            isSearchable={true}
            hasValidationRecoil={true}
            shouldDisable={data.disableAll}
            onChange={methods.handleChange}
            onBlur={methods.handleBlur}
            hasValidation={data.$validation.cistina.show}
            validationReason={data.$validation.cistina.error.reason}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <SelectMaterial2
            title="Cálcio"
            name="calcium"
            options={CHEMISTRY_EXAM}
            value={data.fields.calcium}
            isSearchable={true}
            hasValidationRecoil={true}
            shouldDisable={data.disableAll}
            onChange={methods.handleChange}
            onBlur={methods.handleBlur}
            hasValidation={data.$validation.calcium.show}
            validationReason={data.$validation.calcium.error.reason}
          />
        </Col>
        <Col md={4}>
          <SelectMaterial2
            title="Amônio"
            name="ammonium"
            options={CHEMISTRY_EXAM}
            value={data.fields.ammonium}
            isSearchable={true}
            hasValidationRecoil={true}
            shouldDisable={data.disableAll}
            onChange={methods.handleChange}
            onBlur={methods.handleBlur}
            hasValidation={data.$validation.ammonium.show}
            validationReason={data.$validation.ammonium.error.reason}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
    <Row>
      <Col md={12}>
        <ResultPanel
          name="result"
          value={data.fields.result}
          shouldDisable={data.disableAll}
          handleChange={methods.handleChange}
          handleBlur={methods.handleBlur}
          hasValidation={data.$validation.result.show}
          validationReason={data.$validation.result.error.reason}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ObsPanel
          name="obs"
          value={data.fields.obs}
          shouldDisable={data.disableAll}
          handleChange={methods.handleChange}
          handleBlur={methods.handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default UrinaryAndBiliaryCalculusReportFormView;
