import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import HemogramPanel from "./../HemogramPanel";
import MicrofilariaeResearchPanel from "./../MicrofilariaeResearchPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import { Col } from "./../../components/grid";

const HemogramWithMicrofilariaeResearchFormView = ({
  data: {
    refs,
    total,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    shouldSendEmail,
    reportData,
  },
  methods: {
    $field,
    fieldChange,
    onSubmit,
    calcMCHC,
    calcMCV,
    erythrogramDiagnosis,
    plateletsDiagnosis,
    calcAbsolute,
    resetLeukogram,
    onClickCounter,
    leukogramDiagnosis,
    toggleIntenseLeucopeny,
    finalGlobalLeukogram,
    handleKeyPress,
    handleFinishedPlaying,
    closeCounter,
    handleSendReportEmail,
    handleChange,
    handleBlur,
    cancelReport,
    stopReport,
    handleFocus,
    setRef,
  },
}) => (
  <ReportPanelMaterial
    title="Hemograma com Pesquisa de Microfilária"
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
    handleFocus={handleFocus}
  >
    <Col md={12}>
      <ReportHeader
        data={reportHeaderData}
        fields={fields}
        fieldChange={fieldChange}
        $field={$field}
        $validation={$validation}
      />
    </Col>
    <HemogramPanel
      fields={fields}
      refs={refs}
      disableAll={disableAll}
      $validation={$validation}
      total={total}
      $field={$field}
      fieldChange={fieldChange}
      calcMCV={calcMCV}
      calcMCHC={calcMCHC}
      calcAbsolute={calcAbsolute}
      resetLeukogram={resetLeukogram}
      onClickCounter={onClickCounter}
      toggleIntenseLeucopeny={toggleIntenseLeucopeny}
      finalGlobalLeukogram={finalGlobalLeukogram}
      erythrogramDiagnosis={erythrogramDiagnosis}
      leukogramDiagnosis={leukogramDiagnosis}
      plateletsDiagnosis={plateletsDiagnosis}
      handleKeyPress={handleKeyPress}
      handleFinishedPlaying={handleFinishedPlaying}
      closeCounter={closeCounter}
      setRef={setRef}
      reportData={reportData}
    />
    <Col md={12}>
      <MicrofilariaeResearchPanel
        resultFieldName={"microfilariaeResearchResult"}
        result={fields.microfilariaeResearchResult}
        resultHasValidation={$validation.microfilariaeResearchResult.show}
        resultValidationReason={
          $validation.microfilariaeResearchResult.error.reason
        }
        obsFieldName={"microfilariaeResearchObs"}
        obs={fields.microfilariaeResearchObs}
        obsHasValidation={$validation.microfilariaeResearchObs.show}
        obsValidationReason={$validation.microfilariaeResearchObs.error.reason}
        handleChange={handleChange}
        handleBlur={handleBlur}
        shouldDisable={disableAll}
      />
    </Col>
  </ReportPanelMaterial>
);

export default HemogramWithMicrofilariaeResearchFormView;
