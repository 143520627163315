export const initialData = {
  titles: {
    erythrocytes: "Eritrócitos",
    corpuscularVolume: "Volume Corpuscular",
    hemoglobiometry: "Hemoglobimetria",
    mcv: "VCM",
    mchc: "CHCM",
    globalLeukometry: "Leucometria Global",
    relativeBasophils: "Basófilos Relativos",
    absoluteBasophils: "Basófilos Absoluto",
    relativeEosinophils: "Eosinófilos Relativos",
    absoluteEosinophils: "Eosinófilos Absolutos",
    relativeMyelocytes: "Mielócitos Relativo",
    absoluteMyelocytes: "Mielócitos Absoluto",
    relativeMetamyelocytes: "Metamielócitos Relativo",
    absoluteMetamyelocytes: "Metamielócitos Absoluto",
    relativeBands: "Bastão Relativos",
    absoluteBands: "Bastão Absolutos",
    relativeSegs: "Segmentados Relativos",
    absoluteSegs: "Segmentados Absolutos",
    relativeLymphocytes: "Linfócitos Relativos",
    absoluteLymphocytes: "Linfócitos Absolutos",
    relativeMonocytes: "Monócitos Relativos",
    absoluteMonocytes: "Monócitos Absolutos",
    relativeHeterophils: "Heterófilos Relativos",
    absoluteHeterophils: "Heterófilos Absolutos",
    platelets: "Plaquetas",
    bloodProtein: "Proteínas Plasmáticas",
    reticCountFractionNormal: "Contagem de Reticulócitos Normal",
    reticCountFractionLow: "Contagem de Reticulócitos Leve",
    reticCountFractionModerate: "Contagem de Reticulócitos Moderada",
    reticCountFractionHigh: "Contagem de Reticulócitos Intensa",
    tromboplastinTime: "Tempo de Tromboplastina",
    prothrombinTime: "Tempo de Protrombina",
   
  },
  fields: {
    erythrocytesMin: "",
    erythrocytesMax: "",
    corpuscularVolumeMin: "",
    corpuscularVolumeMax: "",
    hemoglobiometryMin: "",
    hemoglobiometryMax: "",
    mcvMin: "",
    mcvMax: "",
    mchcMin: "",
    mchcMax: "",
    globalLeukometryMin: "",
    globalLeukometryMax: "",
    relativeBasophilsMin: "",
    relativeBasophilsMax: "",
    absoluteBasophilsMin: "",
    absoluteBasophilsMax: "",
    relativeEosinophilsMin: "",
    relativeEosinophilsMax: "",
    absoluteEosinophilsMin: "",
    absoluteEosinophilsMax: "",
    relativeMyelocytesMin: "",
    relativeMyelocytesMax: "",
    absoluteMyelocytesMin: "",
    absoluteMyelocytesMax: "",
    relativeMetamyelocytesMin: "",
    relativeMetamyelocytesMax: "",
    absoluteMetamyelocytesMin: "",
    absoluteMetamyelocytesMax: "",
    relativeBandsMin: "",
    relativeBandsMax: "",
    absoluteBandsMin: "",
    absoluteBandsMax: "",
    relativeSegsMin: "",
    relativeSegsMax: "",
    absoluteSegsMin: "",
    absoluteSegsMax: "",
    relativeLymphocytesMin: "",
    relativeLymphocytesMax: "",
    absoluteLymphocytesMin: "",
    absoluteLymphocytesMax: "",
    relativeMonocytesMin: "",
    relativeMonocytesMax: "",
    absoluteMonocytesMin: "",
    absoluteMonocytesMax: "",
    hasHeterophils: "",
    relativeHeterophilsMin: "",
    relativeHeterophilsMax: "",
    absoluteHeterophilsMin: "",
    absoluteHeterophilsMax: "",
    plateletsMin: "",
    plateletsMax: "",
    bloodProteinMin: "",
    bloodProteinMax: "",
    bloodProteinUnit: "",
    reticCountFractionNormalMin: "",
    reticCountFractionNormalMax: "",
    reticCountFractionLowMin: "",
    reticCountFractionLowMax: "",
    reticCountFractionModerateMin: "",
    reticCountFractionModerateMax: "",
    reticCountFractionHighMin: "",
    reticCountFractionHighMax: "",
    reticResultNormalVal: "",
    reticAbsoluteNormalVal: "",
    tromboplastinTimeMin: "",
    tromboplastinTimeMax: "",
    prothrombinTimeMin: "",
    prothrombinTimeMax: "",
    reticResultNormalVal: "",
    reticAbsoluteNormalVal: "",
    reticCountCorrectionFactor: "",
    hasHeterophils: "",
  },
};
