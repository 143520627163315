import React, { Component } from "react";

/** Internal */
import CustomerFormView from "./../CustomerFormView";
import connectWithEndpoint from "./requests.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { NOTIFICATIONS } from "./../../consts";
import { PERSON_TYPES, ACCESS_PROFILES } from "../../assets/js/Consts.js";
import CustomerFormFactory from "./factory";

/** External */
import update from "immutability-helper";
import { Redirect } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { FunctionUtil } from "./../../useful";

/**
 * Represent a new fiscal data
 * @var {Object}
 */
const DEFAULT_FISCAL = {
  personType: 0,
  cpf: "",
  cnpj: "",
  name: "",
  email: "",
  phone: "",
  cellphone: "",
  responsibleName: "",
  responsibleRG: "",
};

/**
 * Represent a new email data
 * @var {Object}
 */
const DEFAULT_EMAIL = {
  id: 0,
  value: "",
  sendInvoice: 0,
  sendReport: 0,
};

/**
 * Represent a new preferences data
 * @var {Object}
 */
const DEFAULT_PREFERENCES = {
  paymentFrequence: 2,
  term: 0,
  discount: 0,
  receivesPrintReport: 0,
  defaulter: 0,
  usesPaymentSlip: 0,
};

/**
 * Represent a new phone data
 * @var {Object}
 */
const DEFAULT_PHONE = {
  id: 0,
  value: "",
};

/**
 * Represent a new user data
 * @var {Object}
 */
const DEFAULT_USER = {
  id: 0,
  login: "",
  active: 1,
  password: "",
  username: "",
  type: 0,
  email: "",
  cpf: "",
  phone: "",
  optionalPhone: "",
  isVet: 0,
  crmv: "",
  accessProfile: 1,
};

const DEFAULT_CUSTOMER = {
  id: 0,
  companyId: 0,
  name: "",
  cnpj: "",
  cpf: "",
  status: 1,
  regionIds: [],
  pricingTableId: 0,
  stateId: 0,
  cityId: 0,
  neighborhood: "",
  personType: 0,
  cep: "",
  publicPlace: "",
  number: "",
  complement: "",
  logo: [],
};

/**
 * @author Victor Heringer
 *
 * Component to held all state and functions for Customer Form
 */
class CustomerForm extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      fields: {
        id: id ? id : DEFAULT_CUSTOMER.id,
        companyId: DEFAULT_CUSTOMER.companyId,
        name: DEFAULT_CUSTOMER.name,
        cnpj: DEFAULT_CUSTOMER.cnpj,
        cpf: DEFAULT_CUSTOMER.cpf,
        status: DEFAULT_CUSTOMER.status,
        regionIds: DEFAULT_CUSTOMER.regionIds,
        pricingTableId: DEFAULT_CUSTOMER.pricingTableId,
        stateId: DEFAULT_CUSTOMER.stateId,
        cityId: DEFAULT_CUSTOMER.cityId,
        neighborhood: DEFAULT_CUSTOMER.neighborhood,
        personType: DEFAULT_CUSTOMER.personType,
        cep: DEFAULT_CUSTOMER.cep,
        publicPlace: DEFAULT_CUSTOMER.publicPlace,
        number: DEFAULT_CUSTOMER.number,
        complement: DEFAULT_CUSTOMER.complement,
        logo: DEFAULT_CUSTOMER.logo,

        /** Preferences */
        preferencesPaymentFrequency: DEFAULT_PREFERENCES.paymentFrequence,
        preferencesTerm: DEFAULT_PREFERENCES.term,
        preferencesDiscount: DEFAULT_PREFERENCES.discount,
        preferencesReceivesPrintReport: DEFAULT_PREFERENCES.receivesPrintReport,
        preferencesDefaulter: DEFAULT_PREFERENCES.defaulter,
        preferencesUsesPaymentSlip: DEFAULT_PREFERENCES.usesPaymentSlip,

        /** Fiscal */
        fiscalPersonType: DEFAULT_FISCAL.personType,
        fiscalCPF: DEFAULT_FISCAL.cpf,
        fiscalCNPJ: DEFAULT_FISCAL.cnpj,
        fiscalName: DEFAULT_FISCAL.name,
        fiscalEmail: DEFAULT_FISCAL.email,
        fiscalPhone: DEFAULT_FISCAL.phone,
        fiscalCellphone: DEFAULT_FISCAL.cellphone,
        fiscalResponsibleName: DEFAULT_FISCAL.responsibleName,
        fiscalResponsibleRG: DEFAULT_FISCAL.responsibleRG,

        /** Users */
        modalUserId: DEFAULT_USER.id,
        modalUserLogin: DEFAULT_USER.login,
        modalUserActive: DEFAULT_USER.active,
        modalUserPassword: DEFAULT_USER.password,
        modalUserName: DEFAULT_USER.username,
        modalUserType: DEFAULT_USER.type,
        modalUserEmail: DEFAULT_USER.email,
        modalUserPhone: DEFAULT_USER.phone,
        modalUserOptionalPhone: DEFAULT_USER.optionalPhone,
        modalUserCPF: DEFAULT_USER.cpf,
        modalUserPhone: DEFAULT_USER.phone,
        modalUserIsVet: DEFAULT_USER.isVet,
        modalUserInitIsVet: DEFAULT_USER.isVet,
        modalUserCRMV: DEFAULT_USER.crmv,
        modalUserAccessProfile: DEFAULT_USER.accessProfile,
        users: [],

        /** Email */
        modalEmailId: DEFAULT_EMAIL.id,
        modalEmail: DEFAULT_EMAIL.value,
        modalEmailSendReport: DEFAULT_EMAIL.sendReport,
        modalEmailSendInvoice: DEFAULT_EMAIL.sendInvoice,
        emails: [],

        /** Phone */
        modalPhoneId: DEFAULT_PHONE.id,
        modalPhone: DEFAULT_PHONE.value,
        phones: [],
      },

      displayEmailModal: false,
      selectedEmails: [],

      displayPhoneModal: false,
      selectedPhones: [],

      displayUserModal: false,
      selectedUsers: [],

      displayPreferencesModal: false,
      displayFiscalModal: false,
      userPasswordInputType: "password",
    };

    this.isUpdating = this.isUpdating.bind(this);
    this.loadCities = this.loadCities.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogoUpload = this.handleLogoUpload.bind(this);
    this.toggleSelectAtTable = this.toggleSelectAtTable.bind(this);
    this.invalidNotification = this.invalidNotification.bind(this);
    this.includedNotification = this.includedNotification.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
    this.shouldDisableAll = this.shouldDisableAll.bind(this);

    /** User */
    this.handleRemoveUser = this.handleRemoveUser.bind(this);
    this.toggleSelectUser = this.toggleSelectUser.bind(this);
    this.addUserToTable = this.addUserToTable.bind(this);
    this.handleLoadUserToModal = this.handleLoadUserToModal.bind(this);
    this.fieldChangeIsVet = this.fieldChangeIsVet.bind(this);
    this.definesNewUserAccessProfile = this.definesNewUserAccessProfile.bind(
      this
    );

    /** Emails */
    this.handleRemoveEmail = this.handleRemoveEmail.bind(this);
    this.toggleSelectEmail = this.toggleSelectEmail.bind(this);
    this.addEmailToTable = this.addEmailToTable.bind(this);
    this.handleLoadEmailToModal = this.handleLoadEmailToModal.bind(this);

    /** Phones */
    this.handleRemovePhone = this.handleRemovePhone.bind(this);
    this.toggleSelectPhone = this.toggleSelectPhone.bind(this);
    this.addPhoneToTable = this.addPhoneToTable.bind(this);
    this.handleLoadPhoneToModal = this.handleLoadPhoneToModal.bind(this);

    /** Preferences */
    this.handleLoadPreferencesToModal = this.handleLoadPreferencesToModal.bind(
      this
    );

    /** Fiscal */
    this.handleLoadFiscalToModal = this.handleLoadFiscalToModal.bind(this);
  }

  /**
   * @author Victor Heringer
   *
   * Lifecycle method to set value at form if that is being update instead
   * of adding a new register
   */
  componentDidMount() {
    const onSuccess = (data, xhr) => {
      xhr.response.status === 200 && this.fillFields(data);
    };

    this.isUpdating() && this.props.withId(this.state.fields.id, onSuccess);
  }

  /**
   * @author Victor Heringer
   *
   * Decides witch is the access profile that a new user will receive based
   * on current user access profile
   *
   * @param {Number} currentUserProfile
   */
  definesNewUserAccessProfile(currentUserProfile) {
    switch (currentUserProfile) {
      case ACCESS_PROFILES.UNIT_ADMINISTRATOR: {
        return ACCESS_PROFILES.CLINIC_ADMINISTRATOR;
      }
      case ACCESS_PROFILES.CLINIC_ADMINISTRATOR: {
        return ACCESS_PROFILES.CLINIC_USER;
      }
    }
  }

  /**
   * @author Victor Heringer
   *
   * Fill state related to all form fields if the values are give
   *
   * @param {String} obj All values that will be used to fill fields
   */
  fillFields(obj) {
    const customer = CustomerFormFactory.formatCustomerFromApi(obj);

    this.setState((state) =>
      update(state, {
        fields: {
          name: {
            $set: customer.name,
          },
          status: {
            $set: customer.status,
          },
          cep: {
            $set: customer.cep,
          },
          neighborhood: {
            $set: customer.neighborhood,
          },
          publicPlace: {
            $set: customer.publicPlace,
          },
          number: {
            $set: customer.number,
          },
          complement: {
            $set: customer.complement,
          },
          stateId: {
            $set: customer.stateId,
          },
          regionIds: {
            $set: customer.regionIds,
          },
          pricingTableId: {
            $set: customer.pricingTableId,
          },
          phones: {
            $set: customer.phones,
          },
          emails: {
            $set: customer.emails,
          },
          users: {
            $set: customer.users,
          },
          personType: {
            $set: customer.personType,
          },
          logo: {
            $set: customer.logo,
          },
          cpf: {
            $set: customer.cpf,
          },
          cnpj: {
            $set: customer.cnpj,
          },
          preferencesPaymentFrequency: {
            $set: customer.preferencesPaymentFrequency,
          },
          preferencesTerm: {
            $set: customer.preferencesTerm,
          },
          preferencesDiscount: {
            $set: customer.preferencesDiscount,
          },
          preferencesReceivesPrintReport: {
            $set: customer.preferencesReceivesPrintReport,
          },
          preferencesDefaulter: {
            $set: customer.preferencesDefaulter,
          },
          preferencesUsesPaymentSlip: {
            $set: customer.preferencesUsesPaymentSlip,
          },
          fiscalPersonType: {
            $set: customer.fiscalPersonType,
          },
          fiscalCPF: {
            $set: customer.fiscalCPF,
          },
          fiscalCNPJ: {
            $set: customer.fiscalCNPJ,
          },
          fiscalName: {
            $set: customer.fiscalName,
          },
          fiscalEmail: {
            $set: customer.fiscalEmail,
          },
          fiscalPhone: {
            $set: customer.fiscalPhone,
          },
          fiscalCellphone: {
            $set: customer.fiscalCellphone,
          },
          fiscalResponsibleName: {
            $set: customer.fiscalResponsibleName,
          },
          fiscalResponsibleRG: {
            $set: customer.fiscalResponsibleRG,
          },
        },
      })
    );

    this.loadCities(customer.stateId, () => {
      this.setState((state) =>
        update(state, {
          fields: { cityId: { $set: customer.cityId } },
        })
      );
    });
  }

  handleLogoUpload(file) {
    this.setState((state) =>
      update(state, {
        fields: { logo: { $set: file } },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Decides if a create or edit screen
   *
   * @return {Bool}
   */
  isUpdating() {
    return this.state.fields.id > 0;
  }

  /**
   * @author Victor Heringer
   *
   * Loads cities based on selected state
   */
  loadCities(stateId, then) {
    this.props.citiesForState(stateId, then);
  }

  /**
   * @author Victor Heringer
   *
   * Pops up a notification for invalid submit
   */
  invalidNotification() {
    this.props.notificationSystem.add(NOTIFICATIONS.DEFAULT_INVALID_MESSAGE);
  }

  /**
   * @author Victor Heringer
   *
   * Pops up a notification for already included values
   */
  includedNotification() {
    this.props.notificationSystem.add(
      NOTIFICATIONS.DEFAULT_ALREADY_INCLUDED_MESSAGE
    );
  }

  /**
   * @author Victor Heringer
   *
   * Pops up a notification when delete a resource
   */
  removeNotification() {
    this.props.notificationSystem.add(
      NOTIFICATIONS.DEFAULT_DELETE_SUCCESS_MESSAGE
    );
  }

  /**
   * @author Victor Heringer
   *
   * Changes the given field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChange(field, value) {
    this.setState((state) =>
      update(state, { fields: { [field]: { $set: value } } })
    );
  }

  fieldChangeIsVet(field, value) {
    this.setState((state) =>
      update(state, {
        fields: {
          [field]: { $set: value },
          modalUserCRMV: { $set: "" },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Submits form data for api
   */
  handleSubmit() {
    const fields = this.state.fields;

    const onError = () => {
      this.props.notificationSystem.add(NOTIFICATIONS.DEFAULT_ERROR_MESSAGE);
    };

    const onSuccess = () => {
      this.props.notificationSystem.add(NOTIFICATIONS.DEFAULT_SAVE_MESSAGE);
    };

    if (!fields.id) {
      this.props.add(fields, onSuccess, onError);
    } else {
      this.props.update(fields.id, fields, onSuccess, onError);
    }
  }

  /**
   * @author Victor Heringer
   *
   * Load to modal a new user or a existing one
   *
   * @param user Object
   */
  handleLoadUserToModal(user) {
    this.setState((state) =>
      update(state, {
        fields: {
          modalUserId: {
            $set: user.id,
          },
          modalUserLogin: {
            $set: user.login,
          },
          modalUserActive: {
            $set: user.active,
          },
          modalUserAccessProfile: {
            $set: user.accessProfile,
          },
          modalUserPassword: {
            $set: user.password,
          },
          modalUserName: {
            $set: user.username,
          },
          modalUserEmail: {
            $set: user.email,
          },
          modalUserCPF: {
            $set: user.cpf,
          },
          modalUserPhone: {
            $set: user.phone,
          },
          modalUserOptionalPhone: {
            $set: user.optPhone,
          },
          modalUserIsVet: {
            $set: user.isVet,
          },
          modalUserInitIsVet: {
            $set: user.isVet,
          },
          modalUserCRMV: {
            $set: user.crmv,
          },
        },
        displayUserModal: { $set: !this.state.displayUserModal },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Load to modal a new email or a existing one
   *
   * @param email Object
   */
  handleLoadEmailToModal(email) {
    this.setState((state) =>
      update(state, {
        fields: {
          modalEmailId: { $set: email.id },
          modalEmail: { $set: email.value },
          modalEmailSendReport: { $set: email.sendReport },
          modalEmailSendInvoice: { $set: email.sendInvoice },
        },
        displayEmailModal: { $set: !this.state.displayEmailModal },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Load to modal a new phone or a existing one
   *
   * @param phone Object
   */
  handleLoadPhoneToModal(phone) {
    this.setState((state) =>
      update(state, {
        fields: {
          modalPhoneId: { $set: phone.id },
          modalPhone: { $set: phone.value },
        },
        displayPhoneModal: { $set: !this.state.displayPhoneModal },
      })
    );
    this.setState({ displayPhoneModal: !this.state.displayPhoneModal });
  }

  handleLoadPreferencesToModal() {
    this.setState((state) =>
      update(state, {
        displayPreferencesModal: { $set: !this.state.displayPreferencesModal },
      })
    );
  }

  handleLoadFiscalToModal() {
    this.setState((state) =>
      update(state, {
        displayFiscalModal: { $set: !this.state.displayFiscalModal },
      })
    );
  }

  toggleShowHidePassword = (inputType) => {
    this.setState({
      userPasswordInputType: inputType === "password" ? "text" : "password",
    });
  };

  /**
   * @author Victor Heringer
   *
   * Checks and unchecks the ref checkbox at table
   *
   * @param {String} ref Value that will be add to table
   * @param {String} stateName The of the state that will be update
   */
  toggleSelectAtTable(ref, stateName) {
    let selecteds = [...this.state[stateName]];
    let newState = {};

    if (selecteds.indexOf(ref) === -1) {
      selecteds.push(ref);
    } else {
      selecteds.splice(selecteds.indexOf(ref), 1);
    }

    newState[stateName] = selecteds;
    this.setState(newState);
  }

  /**
   * @author Victor Heringer
   *
   * Checks and unchecks the user checkbox at table
   *
   * @param {String} region Value that will be add to table
   */
  toggleSelectUser(ref) {
    this.toggleSelectAtTable(ref, "selectedUsers");
  }

  /**
   * @author Victor Heringer
   *
   * Checks and unchecks the emails checkbox at table
   *
   * @param {String} ref Value that will be add to table
   */
  toggleSelectEmail(ref) {
    this.toggleSelectAtTable(ref, "selectedEmails");
  }

  /**
   * @author Victor Heringer
   *
   * Checks and unchecks the phoes checkbox at table
   *
   * @param {String} ref Value that will be add to table
   */
  toggleSelectPhone(ref) {
    this.toggleSelectAtTable(ref, "selectedPhones");
  }

  /**
   * @author Victor Heringer
   *
   * Adds a line to table with new user and closes modal
   *
   * @param {Array} user Value that will be add to table
   * @param {Function} onClose Callback to be execute when close modal
   */
  addUserToTable(user, onClose) {
    const users = this.state.fields.users;

    if (user.id > 0) {
      for (let i = 0; i < users.length; i++) {
        if (users[i].id == user.id) {
          users[i].login = user.login;
          users[i].access = user.active;
          if (user.password) {
            users[i].password = user.password;
          }
          users[i].name = user.name;
          users[i].email = user.email;
          users[i].phone = user.phone;
          users[i].cpf = user.cpf;
          users[i].isVet = user.isVet;
          users[i].crmv = user.crmv;
          users[i].type = user.type;
          users[i].accessProfile = user.accessProfile;
          users[i].optionalPhone = user.optionalPhone;
          this.setState(
            (state) =>
              update(state, {
                fields: {
                  users: { $set: users },
                },
                displayUserModal: { $set: !this.state.displayUserModal },
              }),
            () => onClose("reset")
          );
        }
      }
    } else {
      this.setState(
        (state) =>
          update(state, {
            fields: {
              users: {
                $push: [
                  {
                    id: user.id,
                    login: user.login,
                    access: user.active,
                    password: user.password,
                    name: user.name,
                    email: user.email,
                    phone: user.phone,
                    cpf: user.cpf,
                    isVet: user.isVet,
                    crmv: user.crmv,
                    type: user.type,
                    accessProfile: user.accessProfile,
                    optionalPhone: user.optionalPhone,
                  },
                ],
              },
            },
            displayUserModal: { $set: !this.state.displayUserModal },
          }),
        () => onClose("reset")
      );
    }
  }

  /**
   * @author Victor Heringer
   *
   * Adds a line to table with new email and closes modal
   *
   * @param {Array} email Value that will be add to table
   * @param {Function} onClose Callback to be execute when close modal
   */
  addEmailToTable(email, onClose) {
    const emails = this.state.fields.emails;

    if (email.id > 0) {
      for (let i = 0; i < emails.length; i++) {
        if (emails[i].id == email.id) {
          emails[i].name = email.name;
          emails[i].sendInvoice = email.sendInvoice;
          emails[i].sendReport = email.sendReport;
          this.setState(
            (state) =>
              update(state, {
                fields: {
                  emails: { $set: emails },
                },
                displayEmailModal: { $set: !this.state.displayEmailModal },
              }),
            () => onClose("reset")
          );
        }
      }
    } else {
      for (let i = 0; i < emails.length; i++) {
        if (emails[i].name == email.name) {
          return this.includedNotification();
        }
      }
      this.setState(
        (state) =>
          update(state, {
            fields: {
              emails: {
                $push: [
                  {
                    id: email.id,
                    name: email.name,
                    sendReport: email.sendReport,
                    sendInvoice: email.sendInvoice,
                  },
                ],
              },
              modalEmail: { $set: DEFAULT_EMAIL.value },
              modalEmailId: { $set: DEFAULT_EMAIL.id },
              modalEmailSendReport: { $set: DEFAULT_EMAIL.sendReport },
              modalEmailSendInvoice: { $set: DEFAULT_EMAIL.sendInvoice },
            },
            displayEmailModal: { $set: !this.state.displayEmailModal },
          }),
        () => onClose("reset")
      );
    }
  }

  /**
   * @author Victor Heringer
   *
   * Adds a line to table with new phone and closes modal
   *
   * @param {Array} phone Value that will be add to table
   * @param {Function} onClose Callback to be execute when close modal
   */
  addPhoneToTable(phone, onClose) {
    const phones = this.state.fields.phones;

    if (phone.id > 0) {
      for (let i = 0; i < phones.length; i++) {
        if (phones[i].id == phone.id) {
          phones[i].name = phone.name;
          this.setState(
            (state) =>
              update(state, {
                fields: {
                  phones: { $set: phones },
                },
                displayPhoneModal: { $set: !this.state.displayPhoneModal },
              }),
            () => onClose("reset")
          );
        }
      }
    } else {
      for (let i = 0; i < phones.length; i++) {
        if (phones[i].name == phone.name) {
          return this.includedNotification();
        }
      }
      this.setState(
        (state) =>
          update(state, {
            fields: {
              phones: { $push: [{ id: phone.id, name: phone.name }] },
              modalPhone: { $set: "" },
            },
            displayPhoneModal: { $set: !this.state.displayPhoneModal },
          }),
        () => onClose("reset")
      );
    }
  }

  /**
   * @author Victor Heringer
   *
   * Remove selected users from table
   */
  handleRemoveUser() {
    let users = [...this.state.fields.users];
    let selecteds = [...this.state.selectedUsers];

    for (let i = 0; i < users.length; i++) {
      if (selecteds.indexOf(users[i].name) > -1) {
        if (users[i].id > 0) {
          this.props.deleteUser(users[i].id, this.removeNotification);
        }
        delete users[i];
      }
    }

    users = users.filter(() => true);

    this.setState((state) =>
      update(state, {
        selectedUsers: { $set: [] },
        fields: {
          users: { $set: users },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Remove selected emails from table
   */
  handleRemoveEmail() {
    let emails = [...this.state.fields.emails];
    let selecteds = [...this.state.selectedEmails];

    for (let i = 0; i < emails.length; i++) {
      if (selecteds.indexOf(emails[i].name) > -1) {
        if (emails[i].id > 0) {
          this.props.deleteEmail(emails[i].id, this.removeNotification);
        }
        delete emails[i];
      }
    }

    emails = emails.filter(() => true);

    this.setState((state) =>
      update(state, {
        selectedEmails: { $set: [] },
        fields: {
          emails: { $set: emails },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Check if should disable all fields
   *
   * @return {Boolean}
   */
  shouldDisableAll() {
    const haveNoAccess =
      this.props.accessProfile == ACCESS_PROFILES.CLINIC_USER ||
      this.props.accessProfile == ACCESS_PROFILES.UNIT_USER ||
      this.props.accessProfile == ACCESS_PROFILES.CLINIC_ADMINISTRATOR;
    const pending =
      this.props.customerResponse && this.props.customerResponse.pending;
    return haveNoAccess || pending;
  }

  /**
   * @author Victor Heringer
   *
   * Remove selected phones from table
   */
  handleRemovePhone() {
    let phones = [...this.state.fields.phones];
    let selecteds = [...this.state.selectedPhones];

    for (let i = 0; i < phones.length; i++) {
      if (selecteds.indexOf(phones[i].name) > -1) {
        if (phones[i].id > 0) {
          this.props.deletePhone(phones[i].id, this.removeNotification);
        }
        delete phones[i];
      }
    }

    phones = phones.filter(() => true);

    this.setState((state) =>
      update(state, {
        selectedPhones: { $set: [] },
        fields: {
          phones: { $set: phones },
        },
      })
    );
  }

  render() {
    const { customerResponse, obj } = this.props;

    if (customerResponse && customerResponse.fulfilled) {
      return <Redirect to="/clientes" />;
    }

    if (obj && obj.pending) {
      return <BounceLoader color="#00B4AD" load />;
    }

    const submit = {
      pending: customerResponse && customerResponse.pending,
      placeholder: this.isUpdating() ? "Atualizando.." : "Salvando..",
      btnTitle: this.isUpdating() ? "Atualizar" : "Salvar",
      panelTitle: this.isUpdating() ? "Cliente" : "Novo Cliente",
    };

    const methods = {
      isUpdating: this.isUpdating,
      loadCities: this.loadCities,
      handleSubmit: this.handleSubmit,
      fieldChange: this.fieldChange,
      invalidNotification: this.invalidNotification,
      handleLogoUpload: this.handleLogoUpload,

      /** Users */
      handleLoadUserToModal: this.handleLoadUserToModal,
      toggleSelectUser: this.toggleSelectUser,
      addUserToTable: this.addUserToTable,
      handleRemoveUser: this.handleRemoveUser,
      fieldChangeIsVet: this.fieldChangeIsVet,

      /** Emails */
      handleLoadEmailToModal: this.handleLoadEmailToModal,
      toggleSelectEmail: this.toggleSelectEmail,
      addEmailToTable: this.addEmailToTable,
      handleRemoveEmail: this.handleRemoveEmail,

      /** Phones */
      handleLoadPhoneToModal: this.handleLoadPhoneToModal,
      toggleSelectPhone: this.toggleSelectPhone,
      addPhoneToTable: this.addPhoneToTable,
      handleRemovePhone: this.handleRemovePhone,

      /** Preferences */
      handleLoadPreferencesToModal: this.handleLoadPreferencesToModal,

      /** Fiscal */
      handleLoadFiscalToModal: this.handleLoadFiscalToModal,
      toggleShowHidePassword: this.toggleShowHidePassword,
    };

    DEFAULT_USER.accessProfile = this.definesNewUserAccessProfile(
      this.props.accessProfile
    );

    const data = {
      states: this.props.states,
      cities: this.props.cities,
      regions: this.props.regions,
      pricingTables: this.props.pricingTables,
      companyCNPJ: this.props.companyCNPJ,
      allDisabled: false,
      submit: submit,
      personTypes: PERSON_TYPES,
      shouldDisableAll: this.shouldDisableAll(),

      /** User */
      defaultUser: DEFAULT_USER,
      selectedUsers: this.state.selectedUsers,
      displayUserModal: this.state.displayUserModal,

      /** Email */
      defaultEmail: DEFAULT_EMAIL,
      selectedEmails: this.state.selectedEmails,
      displayEmailModal: this.state.displayEmailModal,

      /** Phone */
      defaultPhone: DEFAULT_PHONE,
      selectedPhones: this.state.selectedPhones,
      displayPhoneModal: this.state.displayPhoneModal,

      /** Preferences */
      defaultPreferences: DEFAULT_PREFERENCES,
      displayPreferencesModal: this.state.displayPreferencesModal,

      /** Fiscal */
      defaultFiscal: DEFAULT_FISCAL,
      displayFiscalModal: this.state.displayFiscalModal,
      userPasswordInputType: this.state.userPasswordInputType,
    };

    return (
      <React.Fragment>
        <CustomerFormView
          data={data}
          methods={methods}
          fields={this.state.fields}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    accessProfile: state.userReducer.access_profile,
  };
};

export default FunctionUtil.compose(
  connect(mapStateToProps),
  withRouter,
  connectWithEndpoint
)(CustomerForm);
