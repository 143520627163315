import connect from "../../api-connector";

const connectWithEndpoint = connect(props => ({
  updatePassword: (obj, then, error) => ({
    customerResponse: {
      url: `/users/password`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  })
}));

export default connectWithEndpoint;
