// import {calcMCHC} from "./hemogramMethods";
import * as ErythogramMethods from "./erythogramMethods";
import * as LeukogramMethods from "./leukogramMethods";


const { erythrogramDiagnosis, calcMCV, calcMCHC } = ErythogramMethods;
const {calculateFinalGlobalLeukogram, leukogramDiagnosis} = LeukogramMethods;

function isEmptyField(value){
  return (
    value === "" ||
    value === "." ||
    value === "," ||
    value === undefined ||
    value === null
  );
};

function plateletsDiagnosis(platelets, { plateletsMin, plateletsMax }) {  
  if (platelets === "" || platelets === null || platelets === undefined) {
    return "";
  }
    
  if (platelets < plateletsMin) {
    return "Trombocitopenia";
  }

  if (platelets > plateletsMax) {
    return "Trombocitose";
  }

  return "-";
}


export { erythrogramDiagnosis, calcMCV, calcMCHC, isEmptyField, plateletsDiagnosis, calculateFinalGlobalLeukogram, leukogramDiagnosis };
