import React from "react";
import styled from "styled-components";
import { granadilla100 } from "./../colors";

const StyledPanelBody = styled.div`
  background-color: ${granadilla100};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: ${(props) => getPaddingSize(props)};
`;

const getPaddingSize = ({ paddingSize }) => {
  switch (paddingSize) {
    case "small":
      return "25px 20px";
    case "medium":
      return "40px 20px";
    case "large":
      return "60px 25px";

    default:
      return "60px 25px";
  }
};

const PanelBody = (props) => (
  <StyledPanelBody {...props}>{props.children}</StyledPanelBody>
);

export default PanelBody;
