import { validated } from 'react-custom-validation';

import {
  commonReportRules,
  hemogramRules,
  parvoCoronaRules,
  parasitologicalFreshFecesRules,
  molecularBiologyRules,
  urocultureRules,
  fecesCoprocytologicalRules,
  defaultMicrobiologyRules
} from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    erythrocytes,
    corpuscularVolume,
    hemoglobiometry,
    globalLeukometry,
    relativeBasophils,
    relativeEosinophils,
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    relativeSegs,
    relativeLymphocytes,
    relativeMonocytes,
    relativeHeterophils,
    platelets,
    erythrogramObs,
    leukogramObs,
    plateletsObs,
    hemogramObs,
    isIntenseLeucopeny,
    parvoCoronaMaterialId,
    parvoCoronaIsParvovirus,
    parvoCoronaIsCoronavirus,
    parasitologicalFreshFecesFecesColorOptionId,
    parasitologicalFreshFecesFecesSmellOptionId,
    parasitologicalFreshFecesFecesAspectOptionId,
    parasitologicalFreshFecesFecesConsistencyOptionId,
    parasitologicalFreshFecesMacroscopicParasites,
    // urocultureMaterial,
    // urocultureResult,
    // urocultureSensitiveAntibioticTags,
    // urocultureIntermediateAntibioticTags,
    // urocultureResistantAntibioticTags,
    // urocultureColoniesCount,
    // urocultureIsNegative,
    coprocultureMaterial,
    coprocultureResult,
    coprocultureSensitiveAntibioticTags,
    coprocultureIntermediateAntibioticTags,
    coprocultureResistantAntibioticTags,
    coprocultureIsNegative,
    distemperTechnique,
    distemperQualitativeResult,
    distemperCopyValues,
    distemperPrecision,
    distemperMolecularBiologyMaterials,
    fecesCoprocytologicalResult,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const { refs, isUpdate, completeValidation } = props.data;
  const parasitologicalFreshFecesResult = props.data.parasitologicalFreshFecesResult;
  

  let fields = [
    'erythrocytes',
    'corpuscularVolume',
    'hemoglobiometry',
    'globalLeukometry',
    'relativeBasophils',
    'relativeEosinophils',
    'relativeMyelocytes',
    'relativeMetamyelocytes',
    'relativeBands',
    'relativeSegs',
    'relativeLymphocytes',
    'relativeMonocytes',
    'relativeHeterophils',
    'platelets',
    'erythrogramObs',
    'leukogramObs',
    'plateletsObs',
    'hemocytozoaResearch',
    'microfilariaeResearch',
    'hemogramObs',
    'total',
    'mchc',
    'parvoCoronaMaterialId',
    'parvoCoronaIsParvovirus',
    'parvoCoronaIsCoronavirus',
    'parasitologicalFreshFecesFecesColorOptionId',
    'parasitologicalFreshFecesFecesSmellOptionId',
    'parasitologicalFreshFecesFecesAspectOptionId',
    'parasitologicalFreshFecesFecesConsistencyOptionId',
    'parasitologicalFreshFecesResult',
    'parasitologicalFreshFecesMacroscopicParasites',
    'urocultureMaterial',
    'urocultureResult',
    'urocultureSensitiveAntibioticTags',
    'urocultureIntermediateAntibioticTags',
    'urocultureResistantAntibioticTags',
    'urocultureColoniesCount',
    'urocultureIsNegative',
    'distemperTechnique',
    'distemperQualitativeResult',
    'distemperCopyValues',
    'distemperPrecision',
    'distemperResult',
    'distemperMolecularBiologyMaterials',
    'fecesCoprocytologicalResult',
    'requestExamState',
    'vetSignerId'
  ];

  const fieldNames = {
    erythrocytesName:           'erythrocytes',
    corpuscularVolumeName:      'corpuscularVolume',
    hemoglobiometryName:        'hemoglobiometry',
    relativeBasophilsName:      'relativeBasophils',
    relativeEosinophilsName:    'relativeEosinophils',
    relativeMyelocytesName:     'relativeMyelocytes',
    relativeMetamyelocytesName: 'relativeMetamyelocytes',
    relativeBandsName:          'relativeBands',
    relativeSegsName:           'relativeSegs',
    relativeLymphocytesName:    'relativeLymphocytes',
    relativeMonocytesName:      'relativeMonocytes',
    relativeHeterophilsName:    'relativeHeterophils',
    plateletsName:              'platelets',
    plateletsObsName:           'plateletsObs'
  };

  const commonValidation = commonReportRules( vetSignerId, requestExamState );

  const hemogramValidation = hemogramRules( {
    globalLeukometry: globalLeukometry,
    plateletsName: 'platelets',
    platelets: platelets,
    plateletsObsName: 'plateletsObs',
    plateletsObs: plateletsObs,
    erythrocytes: erythrocytes,
    corpuscularVolume: corpuscularVolume,
    hemoglobiometry: hemoglobiometry,
    erythrogramObs: erythrogramObs,
    mchc: props.data.mchc,
    mchcMax: refs.mchcMax,
    isIntenseLeucopeny: isIntenseLeucopeny,
    relativeBasophils: relativeBasophils,
    relativeEosinophils: relativeEosinophils,
    relativeLymphocytes: relativeLymphocytes,
    relativeMonocytes: relativeMonocytes,
    relativeMyelocytes: relativeMyelocytes,
    relativeMetamyelocytes: relativeMetamyelocytes,
    relativeBands: relativeBands,
    relativeSegs: relativeSegs,
    relativeHeterophils: relativeHeterophils,
    leukogramObs: leukogramObs,
    hasHeterophils: refs.hasHeterophils,
    isUpdate: isUpdate,
    total: props.data.total,
    fieldNames: fieldNames,
    hemogramObs,
    completeValidation
    } );

  const parvoCoronaValidation = parvoCoronaRules(
    'parvoCoronaMaterialId',
    parvoCoronaMaterialId,
    'parvoCoronaIsParvovirus',
    parvoCoronaIsParvovirus,
    'parvoCoronaIsCoronavirus',
    parvoCoronaIsCoronavirus,
    completeValidation
  );

  const parasitologicalFreshFecesValidation = parasitologicalFreshFecesRules(
    'parasitologicalFreshFecesFecesColorOptionId',
    parasitologicalFreshFecesFecesColorOptionId,
    'parasitologicalFreshFecesFecesSmellOptionId',
    parasitologicalFreshFecesFecesSmellOptionId,
    'parasitologicalFreshFecesFecesAspectOptionId',
    parasitologicalFreshFecesFecesAspectOptionId,
    'parasitologicalFreshFecesFecesConsistencyOptionId',
    parasitologicalFreshFecesFecesConsistencyOptionId,
    'parasitologicalFreshFecesMacroscopicParasites',
    parasitologicalFreshFecesMacroscopicParasites,
    'parasitologicalFreshFecesResult',
    parasitologicalFreshFecesResult,
    completeValidation
  );

  const molecularBiologyValidation = molecularBiologyRules(
    'distemperTechnique',
    distemperTechnique,
    'distemperQualitativeResult',
    distemperQualitativeResult,
    'distemperCopyValues',
    distemperCopyValues,
    'distemperPrecision',
    distemperPrecision,
    'distemperMolecularBiologyMaterials',
    distemperMolecularBiologyMaterials,
    completeValidation
  );

  // const urocultureValidation = urocultureRules(
  //   'urocultureMaterial',
  //   urocultureMaterial,
  //   'urocultureResult',
  //   urocultureResult,
  //   'urocultureSensitiveAntibioticTags',
  //   urocultureSensitiveAntibioticTags,
  //   'urocultureIntermediateAntibioticTags',
  //   urocultureIntermediateAntibioticTags,
  //   'urocultureResistantAntibioticTags',
  //   urocultureResistantAntibioticTags,
  //   'urocultureColoniesCount',
  //   urocultureColoniesCount,
  //   urocultureIsNegative,
  //   completeValidation
  // );


  const coprocultureValidation = defaultMicrobiologyRules(
    'coprocultureMaterial',
    coprocultureMaterial,
    'coprocultureResult',
    coprocultureResult,
    'coprocultureSensitiveAntibioticTags',
    coprocultureSensitiveAntibioticTags,
    'coprocultureIntermediateAntibioticTags',
    coprocultureIntermediateAntibioticTags,
    'coprocultureResistantAntibioticTags',
    coprocultureResistantAntibioticTags,
    coprocultureIsNegative,
    completeValidation
  );

  const fecesCoprocytologicalValidation = fecesCoprocytologicalRules(
    'fecesCoprocytologicalResult',
    fecesCoprocytologicalResult,
    completeValidation
  );

  const validations = {
    ...commonValidation,
    ...hemogramValidation,
    ...parvoCoronaValidation,
    ...coprocultureValidation,
    ...parasitologicalFreshFecesValidation,
    ...molecularBiologyValidation,
    ...fecesCoprocytologicalValidation
  };

  return { fields, validations };
} );

export default validation;