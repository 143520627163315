import React from 'react';

/**
 * @author Victor Heringer
 * 
 * Display some informative text
 * 
 * @prop {String} text
 * @prop {Object} styles
 */
const Description = ({ text, styles }) => {
  return <div className={styles.description}>{text}</div>;
}

export default Description;

