import React from "react";

/** External */
import { Switch, Route } from "react-router-dom";

/** Internal */
import {
  EXAM_TYPE_ID_BY_NAME,
  COPROCULTURE_NEGATIVE_MESSAGE,
  HEMOCULTURE_NEGATIVE_MESSAGE,
  UROCULTURE_NEGATIVE_MESSAGE,
  CULTURE_ANTIBIOGRAM_ANAEROBIC_NEGATIVE_MESSAGE,
  CULTURE_ANTIBIOGRAM_AEROBIC_NEGATIVE_MESSAGE,
  ACCESS_PROFILES,
} from "../../assets/js/Consts.js";
import { MESSAGES } from "./../../consts";

import AccessManager from "./../../old_components/AccessManager";
import NotFound from "./../NotFound";
import BiochemistryReportForm from "./../BiochemistryReportForm";
import DefaultExamTableReportForm from "./../DefaultExamTableReportForm";
import HemogramReportForm from "./../HemogramReportForm";
import EasReportForm from "./../EasReportForm";
import HemocytozoaResearchReportForm from "./../HemocytozoaResearchReportForm";
import MicrofilariaeResearchReportForm from "./../MicrofilariaeResearchReportForm";
import HemogramWithHemoparasiticalResearchForm from "./../HemogramWithHemoparasiticalResearchForm";
import HemogramWithHemocytozoaResearchForm from "./../HemogramWithHemocytozoaResearchForm";
import HemogramWithMicrofilariaeResearchForm from "./../HemogramWithMicrofilariaeResearchForm";
import PlateletsReportForm from "./../PlateletsReportForm";
import CortisolCreatinineRatioReportForm from "./../CortisolCreatinineRatioReportForm";
import ProteinCreatinineRatioReportForm from "./../ProteinCreatinineRatioReportForm";
import OutsourcedReportForm from "./../OutsourcedReportForm";
import ErythrogramReportForm from "./../ErythrogramReportForm";
import LeukogramReportForm from "./../LeukogramReportForm";
import CorpuscularVolumeReportForm from "./../CorpuscularVolumeReportForm";
import HemocytozoaSerialResearchReportForm from "./../HemocytozoaSerialResearchReportForm";
import LentzCorpuscleResearchReportForm from "./../LentzCorpuscleResearchReportForm";
import DirectMycologicalResearchReportForm from "./../DirectMycologicalResearchReportForm";
import MalasseziaResearchReportForm from "./../MalasseziaResearchReportForm";
import EctoparasitesResearchReportForm from "./../EctoparasitesResearchReportForm";
import FibrinogenReportForm from "./../FibrinogenReportForm";
import MicroalbuminuriaReportForm from "./../MicroalbuminuriaReportForm";
import CrossMatchReportForm from "./../CrossMatchReportForm";
import ReticulocyteCountReportForm from "./../ReticulocyteCountReportForm";
import BloodProteinReportForm from "./../BloodProteinReportForm";
import FourDXReportForm from "./../FourDXReportForm";
import FivFelvReportForm from "./../FivFelvReportForm";
import DistemperAndParvovirusIgGReportForm from "./../DistemperAndParvovirusIgGReportForm";
import DistemperAndParvovirusIgMReportForm from "./../DistemperAndParvovirusIgMReportForm";
import PifReportForm from "./../PifReportForm";
import EhrlichiaCanisReportForm from "./../EhrlichiaCanisReportForm";
import CanineBrucellosisReportForm from "./../CanineBrucellosisReportForm";
import GiardiaReportForm from "./../GiardiaReportForm";
import HeartwormDiseaseReportForm from "./../HeartwormDiseaseReportForm";
import CoagulationTestReportForm from "./../CoagulationTestReportForm";
import ParvoCoronaReportForm from "./../ParvoCoronaReportForm";
import IgaReportForm from "./../IgaReportForm";
import UrinaryGgtReportForm from "./../UrinaryGgtReportForm";
import ParasitologicalFecesMifReportForm from "./../ParasitologicalFecesMifReportForm";
import ParasitologicalFreshFecesReportForm from "./../ParasitologicalFreshFecesReportForm";
import ParasitologicalFecesEpgReportForm from "./../ParasitologicalFecesEpgReportForm";
import SporothrixSchenckiiReportForm from "./../SporothrixSchenckiiReportForm";
import FungalCultureReportForm from "./../FungalCultureReportForm";
import CytologyReportForm from "./../CytologyReportForm";
import DefaultMolecularBiologyForm from "./../DefaultMolecularBiologyForm";
import UrinaryAndBiliaryCalculusReportForm from "./../UrinaryAndBiliaryCalculusReportForm";
import LiquorAnalisisReportForm from "./../LiquorAnalisisReportForm";
import AsciticThoracicFluidReportForm from "./../AsciticThoracicFluidReportForm";
import DefaultMicrobiologyReportForm from "./../DefaultMicrobiologyReportForm";
import UrocultureReportForm from "./../UrocultureReportForm";
import ProfileSimpleReportForm from "./../ProfileSimpleReportForm";
import ProfileParasitologicalMonitoringReportForm from "./../ProfileParasitologicalMonitoringReportForm";
import ProfileDogObesityCheckReportForm from "./../ProfileDogObesityCheckReportForm";
import ProfileGeriatricReportForm from "./../ProfileGeriatricReportForm";
import ProfileHepaticSimpleReportForm from "./../ProfileHepaticSimpleReportForm";
import ProfileHepaticCompleteReportForm from "./../ProfileHepaticCompleteReportForm";
import ProfilePancreaticReportForm from "./../ProfilePancreaticReportForm";
import ProfileRoutine from "./../../modules/exams/profiles/routine";
import ProfileBoneCheckReportForm from "./../ProfileBoneCheckReportForm";
import ProfilePreoperativeSimpleReportForm from "./../ProfilePreoperativeSimpleReportForm";
import ProfilePreoperativeComplete from "./../../modules/exams/profiles/pre-operative-complete";
import ProfilePreoperativeCanine from "./../../modules/exams/profiles/pre-operative-canine";
import ProfilePreoperativeCanine2 from "./../../modules/exams/profiles/pre-operative-canine-2";
import ProfilePreoperativeFeline from "./../../modules/exams/profiles/pre-operative-feline";
import ProfilePreoperativeFeline2 from "./../../modules/exams/profiles/pre-operative-feline-2";
import ProfileRenalReportForm from "./../ProfileRenalReportForm";
import ProfileRenal2ReportForm from "./../ProfileRenal2ReportForm";
import ProfileRenal3ReportForm from "./../ProfileRenal3ReportForm";
import ProfileSmallAnimals from "./../../modules/exams/profiles/small-animals";
import ProfileLargeAnimals from "./../../modules/exams/profiles/large-animals";
import ProfileThyroidReportForm from "./../ProfileThyroidReportForm";
import ProfileElectrolyticReportForm from "./../ProfileElectrolyticReportForm";
import ProfileCardiacReportForm from "./../ProfileCardiacReportForm";
import ProfileSurgical from "./../../modules/exams/profiles/surgical";
import ProfileCheckup from "./../../modules/exams/profiles/checkup";
import ProfileGastroenteritisBronzeReportForm from "./../ProfileGastroenteritisBronzeReportForm";
import ProfileGastroenteritisSilverReportForm from "./../ProfileGastroenteritisSilverReportForm";
import ProfileGastroenteritisGoldReportForm from "./../ProfileGastroenteritisGoldReportForm";
import ProfileHemoparasitesBronzeReportForm from "./../ProfileHemoparasitesBronzeReportForm";
import ProfileHemoparasitesSilverReportForm from "./../ProfileHemoparasitesSilverReportForm";
import ProfileHemoparasitesGoldReportForm from "./../ProfileHemoparasitesGoldReportForm";
import ImmuneMediatedHemolyticAnemiaReportForm from "./../ImmuneMediatedHemolyticAnemiaReportForm";
import ProfileObesity from "../ProfileObesity/index.js";
import ProfileForm from "../ProfileForm/index.js";
import { EXAM_ID_BY_NAME } from "../../consts/exams.js";
import HemogramWithBuffyCoatResearchForm from "../HemogramWithBuffyCoatResearchForm/index.js";

const ReportSection = ({ notificationSystem }) => {
  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderBabesia = (routeProps) => {
    return (
      <DefaultMolecularBiologyForm
        {...routeProps}
        panelTitle="Babesia"
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Kayo Oliveira
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */

  const renderQualitativeAnaplasm = (routeProps) => {
    return (
      <DefaultMolecularBiologyForm
        {...routeProps}
        panelTitle="Anaplasma Qualitativo"
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Kayo Oliveira
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */

  const renderFiv = (routeProps) => {
    return (
      <DefaultMolecularBiologyForm
        {...routeProps}
        panelTitle="Fiv Qualitativo"
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Kayo Oliveira
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */

  const renderFelv = (routeProps) => {
    return (
      <DefaultMolecularBiologyForm
        {...routeProps}
        panelTitle="Felv Qualitativo"
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Kayo Oliveira
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */

  const renderMycoplasm = (routeProps) => {
    return (
      <DefaultMolecularBiologyForm
        {...routeProps}
        panelTitle="Mycoplasma Qualitativo"
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderDistemper = (routeProps) => {
    return (
      <DefaultMolecularBiologyForm
        {...routeProps}
        panelTitle="Cinomose"
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderEhrlichia = (routeProps) => {
    return (
      <DefaultMolecularBiologyForm
        {...routeProps}
        panelTitle="Ehrlichia"
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderLeishmaniasis = (routeProps) => {
    return (
      <DefaultMolecularBiologyForm
        {...routeProps}
        panelTitle="Leishmaniose"
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderLeptospirosis = (routeProps) => {
    return (
      <DefaultMolecularBiologyForm
        {...routeProps}
        panelTitle="Leptospirose"
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderHemogram = (routeProps) => {
    return (
      <HemogramReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderEas = (routeProps) => {
    return (
      <EasReportForm {...routeProps} notificationSystem={notificationSystem} />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderBiochemistry = (routeProps) => {
    return (
      <BiochemistryReportForm
        {...routeProps}
        examTypeId={EXAM_TYPE_ID_BY_NAME.BIOCHEMISTRY}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderEndocrinologyChemiluminescence = (routeProps) => {
    return (
      <DefaultExamTableReportForm
        {...routeProps}
        title="Endocrinologia ( Quimioluminescência )"
        examTypeId={EXAM_TYPE_ID_BY_NAME.ENDOCRINOLOGY_CHEMILUMINESCENCE}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderEndocrinologyRadioimmunoassay = (routeProps) => {
    return (
      <DefaultExamTableReportForm
        {...routeProps}
        title="Endocrinologia ( Radioimunoensaio )"
        examTypeId={EXAM_TYPE_ID_BY_NAME.ENDOCRINOLOGY_RADIOIMMUNOASSAY}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderHemocytozoaResearch = (routeProps) => {
    return (
      <HemocytozoaResearchReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderMicrofilariaeResearch = (routeProps) => {
    return (
      <MicrofilariaeResearchReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderHemogramWithHemoparasiticalResearch = (routeProps) => {
    return (
      <HemogramWithHemoparasiticalResearchForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderHemogramWithHemocytozoaResearch = (routeProps) => {
    return (
      <HemogramWithHemocytozoaResearchForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  const renderHemogramWithBuffyCoatResearch = (routeProps) => {
    return (
      <HemogramWithBuffyCoatResearchForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderHemogramWithMicrofilariaeResearch = (routeProps) => {
    return (
      <HemogramWithMicrofilariaeResearchForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderPlatelets = (routeProps) => {
    return (
      <PlateletsReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderCortisolCreatinineRatio = (routeProps) => {
    return (
      <CortisolCreatinineRatioReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */

  const renderProteinCreatinineRatio = (routeProps) => {
    return (
      <ProteinCreatinineRatioReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */

  const renderOutsourced = (routeProps) => {
    return (
      <OutsourcedReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */

  const renderErythrogram = (routeProps) => {
    return (
      <ErythrogramReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderLeukogram = (routeProps) => {
    return (
      <LeukogramReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderCorpuscularVolume = (routeProps) => {
    return (
      <CorpuscularVolumeReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderHemocytozoaSerialResearch = (routeProps) => {
    return (
      <HemocytozoaSerialResearchReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderLentzCorpuscle = (routeProps) => {
    return (
      <LentzCorpuscleResearchReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderDirectMycologicalResearch = (routeProps) => {
    return (
      <DirectMycologicalResearchReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderMalasseziaResearch = (routeProps) => {
    return (
      <MalasseziaResearchReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderEctoparasitesResearch = (routeProps) => {
    return (
      <EctoparasitesResearchReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderFibrinogen = (routeProps) => {
    return (
      <FibrinogenReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderMicroalbuminuria = (routeProps) => {
    return (
      <MicroalbuminuriaReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderCrossMatch = (routeProps) => {
    return (
      <CrossMatchReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderReticulocyteCount = (routeProps) => {
    return (
      <ReticulocyteCountReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderGiardia = (routeProps) => {
    return (
      <GiardiaReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderFourDX = (routeProps) => {
    return (
      <FourDXReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderAsciticFluidReportForm = (routeProps) => {
    return (
      <AsciticThoracicFluidReportForm
        {...routeProps}
        panelTitle="Análise de Líquido Ascítico"
        examReference={"ascitic"}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderThoracicFluidReportForm = (routeProps) => {
    return (
      <AsciticThoracicFluidReportForm
        {...routeProps}
        panelTitle="Análise de Líquido Torácico"
        examReference={"thoracic"}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderLiquorAnalisis = (routeProps) => {
    return (
      <LiquorAnalisisReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderUrinaryAndBiliaryCalculusReportForm = (routeProps) => {
    return (
      <UrinaryAndBiliaryCalculusReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderCytology = (routeProps) => {
    return (
      <CytologyReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderFungalCulture = (routeProps) => {
    return (
      <FungalCultureReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderSporothrixSchenckii = (routeProps) => {
    return (
      <SporothrixSchenckiiReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderIga = (routeProps) => {
    return (
      <IgaReportForm {...routeProps} notificationSystem={notificationSystem} />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderParvoCorona = (routeProps) => {
    return (
      <ParvoCoronaReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderHeartwormDisease = (routeProps) => {
    return (
      <HeartwormDiseaseReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderCanineBrucellosis = (routeProps) => {
    return (
      <CanineBrucellosisReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderEhrlichiaCanis = (routeProps) => {
    return (
      <EhrlichiaCanisReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderPif = (routeProps) => {
    return (
      <PifReportForm {...routeProps} notificationSystem={notificationSystem} />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderFivFelv = (routeProps) => {
    return (
      <FivFelvReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderBloodProtein = (routeProps) => {
    return (
      <BloodProteinReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderDistemperParvovirusIgG = (routeProps) => {
    return (
      <DistemperAndParvovirusIgGReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Victor Heringer
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderDistemperParvovirusIgM = (routeProps) => {
    return (
      <DistemperAndParvovirusIgMReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderCoagulationTest = (routeProps) => {
    return (
      <CoagulationTestReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderUrinaryGgt = (routeProps) => {
    return (
      <UrinaryGgtReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderParasitologicalFecesMifReportForm = (routeProps) => {
    return (
      <ParasitologicalFecesMifReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderParasitologicalFreshFecesReportForm = (routeProps) => {
    return (
      <ParasitologicalFreshFecesReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderParasitologicalFecesEpgReportForm = (routeProps) => {
    return (
      <ParasitologicalFecesEpgReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderCoprocultureReportForm = (routeProps) => {
    return (
      <DefaultMicrobiologyReportForm
        {...routeProps}
        title="Cultura de Fezes"
        negativeMessage={MESSAGES.COPROCULTURE_NEGATIVE_MESSAGE}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderHemocultureReportForm = (routeProps) => {
    return (
      <DefaultMicrobiologyReportForm
        {...routeProps}
        title="Hemocultura"
        negativeMessage={MESSAGES.HEMOCULTURE_NEGATIVE_MESSAGE}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderCultAntibioAnaerobicReportForm = (routeProps) => {
    return (
      <DefaultMicrobiologyReportForm
        {...routeProps}
        title="Cultura e Antibiograma - Anaeróbios"
        negativeMessage={
          MESSAGES.CULTURE_ANTIBIOGRAM_ANAEROBIC_NEGATIVE_MESSAGE
        }
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderCultAntibioAerobicReportForm = (routeProps) => {
    return (
      <DefaultMicrobiologyReportForm
        {...routeProps}
        title="Cultura e Antibiograma - Aeróbios"
        negativeMessage={MESSAGES.CULTURE_ANTIBIOGRAM_AEROBIC_NEGATIVE_MESSAGE}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderUrocultureReportForm = (routeProps) => {
    return (
      <UrocultureReportForm
        {...routeProps}
        negativeMessage={MESSAGES.UROCULTURE_NEGATIVE_MESSAGE}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileSimpleReportForm = (routeProps) => {
    return (
      <ProfileSimpleReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileParasitologicalMonitoringReportForm = (routeProps) => {
    return (
      <ProfileParasitologicalMonitoringReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileDogObesityCheckReportForm = (routeProps) => {
    return (
      <ProfileDogObesityCheckReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileGeriatricReportForm = (routeProps) => {
    return (
      <ProfileGeriatricReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileHepaticSimpleReportForm = (routeProps) => {
    return (
      <ProfileHepaticSimpleReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileHepaticCompleteReportForm = (routeProps) => {
    return (
      <ProfileHepaticCompleteReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfilePancreaticReportForm = (routeProps) => {
    return (
      <ProfilePancreaticReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileRoutine = (routeProps) => {
    return (
      <ProfileRoutine {...routeProps} notificationSystem={notificationSystem} />
    );
  };

  /**
   * @author Alessandro Bastos GrandinipPreoperativeSIm
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileBoneCheckReportForm = (routeProps) => {
    return (
      <ProfileBoneCheckReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfilePreoperativeSimpleReportForm = (routeProps) => {
    return (
      <ProfilePreoperativeSimpleReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfilePreoperativeComplete = (routeProps) => {
    return (
      <ProfilePreoperativeComplete
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Anderson Souza
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfilePreoperativeCanine = (routeProps) => {
    return (
      <ProfilePreoperativeCanine
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfilePreoperativeCanine2 = (routeProps) => {
    return (
      <ProfilePreoperativeCanine2
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfilePreoperativeFeline = (routeProps) => {
    return (
      <ProfilePreoperativeFeline
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfilePreoperativeFeline2 = (routeProps) => {
    return (
      <ProfilePreoperativeFeline2
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileRenalReportForm = (routeProps) => {
    return (
      <ProfileRenalReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileRenal2ReportForm = (routeProps) => {
    return (
      <ProfileRenal2ReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileRenal3ReportForm = (routeProps) => {
    return (
      <ProfileRenal3ReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileSmallAnimals = (routeProps) => {
    return (
      <ProfileSmallAnimals
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileLargeAnimals = (routeProps) => {
    return (
      <ProfileLargeAnimals
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileThyroidReportForm = (routeProps) => {
    return (
      <ProfileThyroidReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileElectrolyticReportForm = (routeProps) => {
    return (
      <ProfileElectrolyticReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileCardiacReportForm = (routeProps) => {
    return (
      <ProfileCardiacReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileSurgical = (routeProps) => {
    return (
      <ProfileSurgical
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileCheckup = (routeProps) => {
    return (
      <ProfileCheckup {...routeProps} notificationSystem={notificationSystem} />
    );
  };

  

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileGastroenteritisBronzeReportForm = (routeProps) => {
    return (
      <ProfileGastroenteritisBronzeReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileGastroenteritisSilverReportForm = (routeProps) => {
    return (
      <ProfileGastroenteritisSilverReportForm
        {...routeProps}
        urocultureNegativeMessage={COPROCULTURE_NEGATIVE_MESSAGE}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileGastroenteritisGoldReportForm = (routeProps) => {
    return (
      <ProfileGastroenteritisGoldReportForm
        {...routeProps}
        urocultureNegativeMessage={COPROCULTURE_NEGATIVE_MESSAGE}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileHemoparasitesBronzeReportForm = (routeProps) => {
    return (
      <ProfileHemoparasitesBronzeReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileHemoparasitesSilverReportForm = (routeProps) => {
    return (
      <ProfileHemoparasitesSilverReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderProfileHemoparasitesGoldReportForm = (routeProps) => {
    return (
      <ProfileHemoparasitesGoldReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * @param {Object} routeProps
   *
   * @return {Object} Report component
   *
   */
  const renderImmuneMediatedHemolyticAnemiaReportForm = (routeProps) => {
    return (
      <ImmuneMediatedHemolyticAnemiaReportForm
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  const renderProfileObesityReportForm = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_OBESITY}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  const renderProfileMoreCompleteHepaticForm = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_MORE_COMPLETE_HEPATIC}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  const renderProfileCheckup2Form = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_CHECKUP2}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  
  const renderProfilePreoperativeComplete2Form = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_PREOPERATIVE_COMPLETE_2}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  const renderProfileEndocrinologicalForm = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_ENDOCRINOLOGICAL}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    )
  }
  const renderProfileRoutine3Form = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_ROUTINE_3}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    )
  }
  const renderProfileOncologicalForm = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_ONCOLOGICAL}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    )
  }

  const renderProfileCompleteParasitologicalMonitoringForm = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_COMPLETE_PARASITOLOGICAL_MONITORING}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  const renderProfileHyperadrenalSuspicious = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_HYPERADRENAL_SUSPICIOUS}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  const renderProfileAnemic1 = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_ANEMIC_1}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };
  const renderProfileAnemic2 = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_ANEMIC_2}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  const renderProfileRenal4 = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_RENAL_4}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };
  const renderProfileThyroidMonitoring = (routeProps) => {
    return (
      <ProfileForm
        examId={EXAM_ID_BY_NAME.PROFILE_THYROID_MONITORING}
        {...routeProps}
        notificationSystem={notificationSystem}
      />
    );
  };

  return (
    <React.Fragment>
      <AccessManager
        allowed={[
          ACCESS_PROFILES.UNIT_ADMINISTRATOR,
          ACCESS_PROFILES.UNIT_USER,
        ]}
        notAllowedComponent={<NotFound />}
      >
        <Switch>
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/analise-liquor/:id"
            }
            render={renderLiquorAnalisis}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/analise-liquido-ascitico/:id"
            }
            render={renderAsciticFluidReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/analise-liquido-toracico/:id"
            }
            render={renderThoracicFluidReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/babesia/:id"
            }
            render={renderBabesia}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/anaplasma-qualitativo-pcr-real-time/:id"
            }
            render={renderQualitativeAnaplasm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/fiv-qualitativo-pcr-real-time/:id"
            }
            render={renderFiv}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/felv-qualitativo-pcr-real-time/:id"
            }
            render={renderFelv}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/mycoplasma-qualitativo-pcr-real-time/:id"
            }
            render={renderMycoplasm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/cinomose/:id"
            }
            render={renderDistemper}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/ehrlichia/:id"
            }
            render={renderEhrlichia}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/leishmaniose/:id"
            }
            render={renderLeishmaniasis}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/leptospirose/:id"
            }
            render={renderLeptospirosis}
          />
          <Route
            path={"/solicitacoes-exame/:examRequestId/bioquimica"}
            render={renderBiochemistry}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/endocrinologia-quimioluminescencia"
            }
            render={renderEndocrinologyChemiluminescence}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/endocrinologia-radioimunoensaio"
            }
            render={renderEndocrinologyRadioimmunoassay}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/hemograma/:id"
            }
            render={renderHemogram}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/eas/:id"
            }
            render={renderEas}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/pesquisa-hemocitozoarios/:id"
            }
            render={renderHemocytozoaResearch}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/pesquisa-microfilaria/:id"
            }
            render={renderMicrofilariaeResearch}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/hemograma-pesquisa-hemoparasitologica/:id"
            }
            render={renderHemogramWithHemoparasiticalResearch}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/hemograma-pesquisa-hemocitozoarios/:id"
            }
            render={renderHemogramWithHemocytozoaResearch}
          />
          <Route
            path={"/solicitacoes-exame/:examRequestId/exame/:requestExamId/hemograma-pesquisa-em-capa-leucocitaria/:id"}
            render={renderHemogramWithBuffyCoatResearch}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/hemograma-pesquisa-microfilaria/:id"
            }
            render={renderHemogramWithMicrofilariaeResearch}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/contagem-plaquetas/:id"
            }
            render={renderPlatelets}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/relacao-cortisol-creatinina/:id"
            }
            render={renderCortisolCreatinineRatio}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/relacao-proteina-creatinina/:id"
            }
            render={renderProteinCreatinineRatio}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/eritrograma/:id"
            }
            render={renderErythrogram}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/leukograma/:id"
            }
            render={renderLeukogram}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/hematocrito/:id"
            }
            render={renderCorpuscularVolume}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/pesquisa-seriada-hemocitozoarios/:id"
            }
            render={renderHemocytozoaSerialResearch}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/pesquisa-corpusculo-lentz/:id"
            }
            render={renderLentzCorpuscle}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/micologico-direto/:id"
            }
            render={renderDirectMycologicalResearch}
          />

          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/pesquisa-malassezia-sp/:id"
            }
            render={renderMalasseziaResearch}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/pesquisa-ectoparasitas/:id"
            }
            render={renderEctoparasitesResearch}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/fibrinogenio/:id"
            }
            render={renderFibrinogen}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/microalbuminuria/:id"
            }
            render={renderMicroalbuminuria}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/teste-compatibilidade-sanguinea/:id"
            }
            render={renderCrossMatch}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/contagem-reticulocitos/:id"
            }
            render={renderReticulocyteCount}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/proteinas-plasmaticas/:id"
            }
            render={renderBloodProtein}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/4dx/:id"
            }
            render={renderFourDX}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/analise-calculo-urinario-biliar/:id"
            }
            render={renderUrinaryAndBiliaryCalculusReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/citologia/:id"
            }
            render={renderCytology}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/cultura-fungos/:id"
            }
            render={renderFungalCulture}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/pesquisa-sporothrix-schenckii/:id"
            }
            render={renderSporothrixSchenckii}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/parvovirus-coronavirus/:id"
            }
            render={renderParvoCorona}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/dirofilariose/:id"
            }
            render={renderHeartwormDisease}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/giardia/:id"
            }
            render={renderGiardia}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/ehrlichia-canis/:id"
            }
            render={renderEhrlichiaCanis}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/pif/:id"
            }
            render={renderPif}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/imunoglobulina-a/:id"
            }
            render={renderIga}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/cinomose-parvovirose-igg/:id"
            }
            render={renderDistemperParvovirusIgG}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/cinomose-parvovirose-igm/:id"
            }
            render={renderDistemperParvovirusIgM}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/fiv-felv/:id"
            }
            render={renderFivFelv}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/brucelose-canina/:id"
            }
            render={renderCanineBrucellosis}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/teste-coagulacao/:id"
            }
            render={renderCoagulationTest}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/ggt-urinaria/:id"
            }
            render={renderUrinaryGgt}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/parasitologico-fezes-mif/:id"
            }
            render={renderParasitologicalFecesMifReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/parasitologico-fezes-frescas/:id"
            }
            render={renderParasitologicalFreshFecesReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/parasitologico-fezes-opg/:id"
            }
            render={renderParasitologicalFecesEpgReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/cultura-de-fezes/:id"
            }
            render={renderCoprocultureReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/hemocultura/:id"
            }
            render={renderHemocultureReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/cultura-antibiograma-anaerobios/:id"
            }
            render={renderCultAntibioAnaerobicReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/cultura-antibiograma-aerobios/:id"
            }
            render={renderCultAntibioAerobicReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/urocultura/:id"
            }
            render={renderUrocultureReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-simples/:id"
            }
            render={renderProfileSimpleReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-acompanhamento-parasitologico/:id"
            }
            render={renderProfileParasitologicalMonitoringReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-cao-obeso/:id"
            }
            render={renderProfileDogObesityCheckReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-geriatrico/:id"
            }
            render={renderProfileGeriatricReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-hepatico-simples/:id"
            }
            render={renderProfileHepaticSimpleReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-hepatico-completo/:id"
            }
            render={renderProfileHepaticCompleteReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-pancreatico/:id"
            }
            render={renderProfilePancreaticReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-rotina/:id"
            }
            render={renderProfileRoutine}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-osseo/:id"
            }
            render={renderProfileBoneCheckReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-pre-operatorio-simples/:id"
            }
            render={renderProfilePreoperativeSimpleReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-pre-operatorio-completo/:id"
            }
            render={renderProfilePreoperativeComplete}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-pre-operatorio-canino/:id"
            }
            render={renderProfilePreoperativeCanine}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-pre-operatorio-canino-2/:id"
            }
            render={renderProfilePreoperativeCanine2}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-pre-operatorio-felino/:id"
            }
            render={renderProfilePreoperativeFeline}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-pre-operatorio-felino-2/:id"
            }
            render={renderProfilePreoperativeFeline2}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-renal/:id"
            }
            render={renderProfileRenalReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-renal-2/:id"
            }
            render={renderProfileRenal2ReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-renal-3/:id"
            }
            render={renderProfileRenal3ReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-veterinario-animais-pequenos/:id"
            }
            render={renderProfileSmallAnimals}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-veterinario-animais-grandes/:id"
            }
            render={renderProfileLargeAnimals}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-tireoidiano/:id"
            }
            render={renderProfileThyroidReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-eletrolitico/:id"
            }
            render={renderProfileElectrolyticReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-cardiaco/:id"
            }
            render={renderProfileCardiacReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-cirurgico/:id"
            }
            render={renderProfileSurgical}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-checkup/:id"
            }
            render={renderProfileCheckup}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-gastroenterite-bronze/:id"
            }
            render={renderProfileGastroenteritisBronzeReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-gastroenterite-prata/:id"
            }
            render={renderProfileGastroenteritisSilverReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-gastroenterite-ouro/:id"
            }
            render={renderProfileGastroenteritisGoldReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-hemoparasita-bronze/:id"
            }
            render={renderProfileHemoparasitesBronzeReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-hemoparasita-prata/:id"
            }
            render={renderProfileHemoparasitesSilverReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-hemoparasita-ouro/:id"
            }
            render={renderProfileHemoparasitesGoldReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/anemia-hemolitica-imunomediada/:id"
            }
            render={renderImmuneMediatedHemolyticAnemiaReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-obeso/:id"
            }
            render={renderProfileObesityReportForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-hepatico-mais-completo/:id"
            }
            render={renderProfileMoreCompleteHepaticForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-checkup2/:id"
            }
            render={renderProfileCheckup2Form}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-preoperatorio-completo-2/:id"
            }
            render={renderProfilePreoperativeComplete2Form}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-endocrinologico/:id"
            }
            render={renderProfileEndocrinologicalForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-rotina-3/:id"
            }
            render={renderProfileRoutine3Form}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-oncologico/:id"
            }
            render={renderProfileOncologicalForm}
          />

          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-acompanhamento-parasitologico-completo/:id"
            }
            render={renderProfileCompleteParasitologicalMonitoringForm}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-suspeito-hiperadreno/:id"
            }
            render={renderProfileHyperadrenalSuspicious}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-anemico-1/:id"
            }
            render={renderProfileAnemic1}
          />
          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-anemico-2/:id"
            }
            render={renderProfileAnemic2}
          />

          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-renal-4/:id"
            }
            render={renderProfileRenal4}
          />

          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/perfil-acompanhamento-tireoidiano/:id"
            }
            render={renderProfileThyroidMonitoring}
          />

          <Route
            path={
              "/solicitacoes-exame/:examRequestId/exame/:requestExamId/terceirizado/:id"
            }
            render={renderOutsourced}
          />
          <Route component={NotFound} />
        </Switch>
      </AccessManager>
    </React.Fragment>
  );
};

export default ReportSection;
