import React from "react";

/**
 * Internal
 */
import NumberInputWithReferencePanel2 from "./../NumberInputWithReferencePanel2";
import ObsPanel from "./../ObsPanel";

/**
 * External
 */
import { Row, Col } from "./../../components/grid";

const FibrinogenContent = ({
  resultFieldName,
  result,
  obsFieldName,
  obs,
  resultHasValidation,
  resultValidationReason,
  obsHasValidation,
  obsValidationReason,
  refMin,
  refMax,
  shouldDisable,
  handleChange,
  handleBlur
}) => (
  <React.Fragment>
    <Row>
      <Col md={12}>
        <NumberInputWithReferencePanel2
          title="Resultado"
          value={result}
          fieldTitle="Fibrinogênio"
          fieldName={resultFieldName}
          shouldDisable={shouldDisable}
          hasValidation={resultHasValidation}
          validationReason={resultValidationReason}
          handleChange={handleChange}
          handleBlur={handleBlur}
          valueMin={refMin}
          valueMax={refMax}
          decimalScale={1}
          unit="mg/dL"
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ObsPanel
          name={obsFieldName}
          value={obs}
          shouldDisable={shouldDisable}
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasValidation={obsHasValidation}
          validationReason={obsValidationReason}
        />
      </Col>
    </Row>
  </React.Fragment>
);

export default FibrinogenContent;
