import React from "react";

/**
 * External
 */
import PropTypes from "prop-types";
import { Row, Col } from "./../../components/grid";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import InputMaterial from "./../InputMaterial";
import NumberInputMaterial from "./../NumberInputMaterial";
import PanelMaterialBody from "../PanelMaterialBody";
import PanelMaterialTop from "../PanelMaterialTop";
import BirthDatePickerMaterial from "./../BirthDatePickerMaterial";
import AnimalSexRadioMaterial from "./../AnimalSexRadioMaterial";

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const DonorPanel = ({
  panelTitle,
  name,
  nameField,
  breed,
  breedField,
  birthDate,
  birthDateField,
  years,
  yearsField,
  months,
  monthsField,
  sexId,
  sexIdField,
  owner,
  ownerField,
  disableAll,
  fieldChange,
  $field,
  $validation,
  birthDateChange,
  yearsMonthsChange,
}) => (
  <React.Fragment>
    <PanelMaterial className="pb-20" flat={true}>
      <PanelMaterialTop title={panelTitle} />
      <PanelMaterialBody>
        <Col md={12}>
          <InputMaterial
            id={nameField}
            title="Nome"
            name={nameField}
            md={4}
            value={name}
            disabled={disableAll}
            {...$field(nameField, (event) => {
              fieldChange(nameField, event.target.value);
            })}
            hasValidation={$validation[nameField].show}
            validationReason={$validation[nameField].error.reason}
          />
          <InputMaterial
            id={breedField}
            title="Raça"
            name={breedField}
            md={4}
            value={breed}
            disabled={disableAll}
            {...$field(breedField, (event) => {
              fieldChange(breedField, event.target.value);
            })}
            hasValidation={$validation[breedField].show}
            validationReason={$validation[breedField].error.reason}
          />
          <Col md={2}>
            <BirthDatePickerMaterial
              id={birthDateField}
              name={birthDateField}
              value={birthDate}
              $field={$field}
              disabled={disableAll}
              onChange={birthDateChange}
            />
          </Col>
          <NumberInputMaterial
            id={yearsField}
            title="Anos"
            name={yearsField}
            md={1}
            value={years}
            allowNegative={false}
            isNumericString={true}
            format="###"
            fieldChange={yearsMonthsChange}
            {...$field(yearsField)}
            hasValidation={
              $validation[yearsField] && $validation[yearsField].show
            }
            validationReason={
              $validation[yearsField] && $validation[yearsField].error.reason
            }
          />
          <NumberInputMaterial
            id={monthsField}
            title="Meses"
            name={monthsField}
            md={1}
            value={months}
            allowNegative={false}
            isNumericString={true}
            format="##"
            fieldChange={yearsMonthsChange}
            {...$field(monthsField)}
            hasValidation={
              $validation[monthsField] && $validation[monthsField].show
            }
            validationReason={
              $validation[monthsField] && $validation[monthsField].error.reason
            }
          />
        </Col>
        <Col md={12}>
          <Col md={6}>
            <AnimalSexRadioMaterial
              disabled={disableAll}
              fieldName={sexIdField}
              selectedValue={sexId}
              updateField={fieldChange}
              $field={$field}
              $validation={$validation}
            />
          </Col>
          <InputMaterial
            id={ownerField}
            title="Proprietário"
            name={ownerField}
            md={6}
            value={owner}
            disabled={disableAll}
            {...$field(ownerField, (event) => {
              fieldChange(ownerField, event.target.value);
            })}
            hasValidation={$validation[ownerField].show}
            validationReason={$validation[ownerField].error.reason}
          />
        </Col>
      </PanelMaterialBody>
    </PanelMaterial>
  </React.Fragment>
);

DonorPanel.propTypes = propTypes;

export default DonorPanel;
