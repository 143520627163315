import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import { maxLength } from '../../assets/js/validationFunctions';

import { OBS_VALIDATION_MAX_LENGTH } from '../../assets/js/Consts.js';

import {
  commonReportRules,
  hemocytozoaResearchRules
} from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    researchs,
    obs,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const {completeValidation} = props.data;
  let fields = [
    'result',
    'obs',
    'requestExamState',
    'vetSignerId'
  ];

  const commonValidation = commonReportRules( vetSignerId, requestExamState );

  let validations = { ...commonValidation };

  researchs.forEach( ( research, index ) => {
    const resultFieldName = `result-${ index }`;
    const obsFieldName = `obs-${ index }`;

    const rules = hemocytozoaResearchRules(
      resultFieldName,
      research.result,
      obsFieldName,
      research.obs,
      completeValidation
    );

    validations = {
      ...validations,
      ...rules
    };

    fields.push( resultFieldName );
    fields.push( obsFieldName );
  } );

  validations.obs = {
    rules: [
      [ maxLength, obs, OBS_VALIDATION_MAX_LENGTH ]
    ]
  }

  return { fields, validations };
} );

export default validation;