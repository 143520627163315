import connect from "../../api-connector";

const connectWithEndpoint = connect((props) => ({
  customersPromise: "/customers/labs/" + props.userLabId,
  speciesPromise: "/species/all",
  add: (obj, then) => ({
    submitPromise: {
      url: `/animals`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      force: true,
    },
  }),
  update: (obj, then) => ({
    submitPromise: {
      url: `/animals/` + obj.id,
      method: "PUT",
      body: JSON.stringify(obj),
      then: then,
      force: true,
    },
  }),
  withId: (id, then) => ({
    objPromise: {
      url: `/animals/${id}`,
      then: then,
    },
  }),
  breedsWithSpeciesId: (id) => ({
    breedsPromise: `/breeds/species/${id}`,
  }),
}));

export default connectWithEndpoint;
