import React from "react";
import styled, { keyframes } from "styled-components";
import { mainFont } from "./../fonts";
import { FaSpinner } from "react-icons/fa";
import { getDepth } from "./../depth";
import {
  leaf,
  blueberry,
  petunia,
  rose,
  blackberry,
  pumpkin,
  granadilla,
  red,
  lime900,
} from "./../colors";

const StyledButton = styled.button`
  ${mainFont}
  ${(props) => getDepth(props.depth)}
  background-color: ${(props) => getVariant(props)};
  padding: ${(props) => (props.thin ? "4px" : "10px")};
  border-radius: 8px;
  border: 0px;
  color: ${(props) => getColor(props)};
  margin: 5px;
  cursor: ${(props) => getCursor(props)};
  min-width: 45px;
  border: 1px solid ${(props) => getOutline(props)};
  width: ${(props) => getWidth(props)};
  float: ${(props) => props.float};
  &:hover {
    filter: ${(props) => getHover(props)};
  }

  &:active {
    box-shadow: none;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSpin = styled.div`
  display: inline-block;
  animation: ${rotate} 1.5s linear infinite;
  margin-right: 5px;
`;

const getVariant = ({ variant, outline, disabled }) => {
  variant = disabled ? "disabled" : variant;
  return outline ? "transparent" : translateVariantColor(variant);
};

const getOutline = ({ variant, disabled }) => {
  variant = disabled ? "disabled" : variant;
  return translateVariantColor(variant);
};

const getColor = ({ outline, variant, disabled }) => {
  if (disabled && outline) {
    return granadilla;
  }
  return outline ? translateVariantColor(variant) : petunia;
};

const getHover = ({ disabled, outline }) => {
  if (disabled) {
    return "none";
  }
  if (outline) {
    return "brightness(140%)";
  }
  return "brightness(90%)";
};

const getCursor = ({ disabled }) => {
  return disabled ? "not-allowed" : "pointer";
};

const getWidth = ({ block }) => {
  return block ? "100%" : "auto";
};

const translateVariantColor = (variant) => {
  switch (variant) {
    case "primary":
      return leaf;
    case "secondary":
      return blueberry;
    case "danger":
      return red;
    case "disabled":
      return granadilla;
    case "warning": {
      return pumpkin;
    }
    case "success": {
      return "lawngreen";
    }
    default:
      return leaf;
  }
};

const Button = (props) => (
  <StyledButton {...props}>
    {props.loading ? (
      <StyledSpin>
        <FaSpinner />
      </StyledSpin>
    ) : null}
    {props.responsive ? (
      <>
        <span className="hidden-above-xs">{props.icon}</span>
        <span className="hidden-in-xs">{props.children}</span>
      </>
    ) : (
      props.children
    )}
  </StyledButton>
);

export default Button;
