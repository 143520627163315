import Decimal from "../../assets/js/Decimal.js";
import axios from "axios";

const isNumeric = value => {
  const re = /(0|[1-9][0-9]*)$/;
  return re.test(value);
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value is not empty
 *
 * @param  {String} value   Target
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const isTrue = (value, message) => (value ? true : message);

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value is not empty
 *
 * @param  {String} value   Target
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const required = (value, message) =>
  (value && value.length > 0) || typeof value === "number" ? true : message;

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value is equal
 *
 * @param  {String} value   Target
 * @param  {String} compare Value compare to target
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const isEqual = (value, compare, message) =>
  value === compare ? true : message;

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value is not empty
 *
 * @param  {String} value   Target
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const requiredObj = (value, message) => (value ? true : message);

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value achieves the required min length
 *
 * @param  {String} value   Target
 * @param  {Number} length  Minimum length
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const minLength = (value, length, message) =>
  value.length >= length ? true : message;

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value achieves the required max length
 *
 * @param  {String} value   Target
 * @param  {Number} length  Maximum length
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const maxLength = (value, length, message) =>
  typeof value === "string" ? (value.length <= length ? true : message) : true;

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value length is less than a number
 *
 * @param  {String} value   Target
 * @param  {Number} length  Maximum length
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const lessThan = (value, comparison, message) => {
  if (value === null || value === "") {
    return message;
  }
  const decValue = new Decimal(value);
  const decComparison = new Decimal(comparison);

  return decValue.lessThan(decComparison) ? true : message;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value length is less
 * than or equal to a number
 *
 * @param  {String} value   Target
 * @param  {Number} length  Maximum length
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */

export const lessThanOrEqualTo = (value, number, message) => {
  const decValue = new Decimal(value);
  const decNumber = new Decimal(number);

  return decValue.lessThanOrEqualTo(decNumber) ? true : message;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value is greater than a comparison value
 *
 * @param  {String} value   Target
 * @param  {Number} comparison  Minumum comparison
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const greaterThan = (value, comparison, message) => {
  if (value === null || value === "" || value === undefined) {
    return message;
  }
  const decValue = new Decimal(value);
  const decComparison = new Decimal(comparison);
  return decValue.greaterThan(decComparison) || decValue < decComparison? true : message;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value is not equal than another
 *
 * @param  {String} value   Target
 * @param  {Number} length  The other value
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const differentThan = (value, number, message) =>
  value !== number ? true : message;

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value is composed by only numbers
 *
 * @param  {String} value   Target
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const onlyNumbers = (value, message) =>
  isNumeric(value) ? true : message;

/**
 * @author Victor Heringer
 *
 * Validate if the value is a valid email
 *
 * @param  {String} value   Target
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const validEmail = (value, message) => {
  var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(value).toLowerCase()) ? true : message;
};

/**
 * @author Victor Heringer
 *
 * Validate if a cpf is valid
 *
 * @param  {String} cpf   Target
 *
 * @return {Boolean}
 */
export const validateCPF = (cpf, allowEmpty, message) => {
  let sum;
  let leftover;

  if (allowEmpty && !cpf) {
    return true;
  }

  sum = 0;
  if (cpf == "00000000000") return message;

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  leftover = (sum * 10) % 11;

  if (leftover == 10 || leftover == 11) leftover = 0;
  if (leftover != parseInt(cpf.substring(9, 10))) return message;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  leftover = (sum * 10) % 11;

  if (leftover == 10 || leftover == 11) leftover = 0;
  if (leftover != parseInt(cpf.substring(10, 11))) return message;
  return true;
};

/**
 * @author Victor Heringer
 *
 * Validate if a cnpj is valid
 *
 * @param  {String} cnpj   Target
 * @param  {String} message Validation message if fails to assert
 *
 * @return {Boolean}
 */
export const validateCNPJ = (cnpj, message) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  let invalidCNPJS = [
    "00000000000000",
    "11111111111111",
    "22222222222222",
    "33333333333333",
    "44444444444444",
    "55555555555555",
    "66666666666666",
    "77777777777777",
    "88888888888888",
    "99999999999999"
  ];

  if (cnpj == "" || cnpj.length != 14 || invalidCNPJS.includes(cnpj)) {
    return message;
  }

  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  let digits = cnpj.substring(size);
  let sum = 0;
  let pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;

    if (pos < 2) {
      pos = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result != digits.charAt(0)) {
    return message;
  }

  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result != digits.charAt(1)) {
    return message;
  }

  return true;
};

/**
 * @author Victor Heringer
 *
 * Makes the value required only if another value is set
 *
 * @param  {String} value   Target
 * @param  {String} anotherValue Valute related to target
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}
 */
export const requiredIfExistAnother = (value, anotherValue, message) => {
  if (anotherValue) {
    return true;
  } else {
    return value ? true : message;
  }
};

export const requiredIfHasAnother = (value, anotherValue, message) => {
  return (anotherValue && value) || (!anotherValue && !value) ? true : message;
};

/**
 * @author Victor Heringer
 *
 * Handles api that is realized at validation
 *
 * @param  {String} data   Api result request data
 * @param  {String} fulfilled If data has already been injected
 *
 * @return {Mix}
 */
export const handleApiValidate = (data, fulfilled, message) => {
  return !fulfilled ? message : data;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if the value length is greater than a number and
 * the value other value is also greater than second number
 *
 * @param  {String} value
 * @param  {String} number
 * @param  {String} secondValue
 * @param  {String} secondNumber
 * @param  {String} message
 *
 * @return {String} Warning Message
 *
 */
export const greaterThanAndGreaterThan = (
  value,
  comparison,
  secondValue,
  secondComparison,
  message
) => {
  if (value === null || value === "" || value === undefined) {
    return message;
  }
  if (secondValue === null || secondValue === "" || secondValue === undefined) {
    return message;
  }
  const decValue = new Decimal(value);
  const decComparison = new Decimal(comparison);
  const decSecondValue = new Decimal(secondValue);
  const decSecondComparison = new Decimal(secondComparison);

  return decValue.greaterThan(decComparison) &&
    decSecondValue.greaterThan(decSecondComparison)
    ? true
    : message;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Validate if a value equals some other value
 *
 * @param  {String} value   Target
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const equalTo = (value, comparison, message) => {
  return value === comparison ? true : message;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Validates is a vales equals some other value
 * or a value is greater than another value
 * or a value is greater than some other value
 *
 * @param  {Mixed} firstValue
 * @param  {Mixed} firstComparison
 * @param  {Number} secondValue
 * @param  {Number} secondComparison
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const greaterThanOrGreaterThan = (
  value,
  comparison,
  secondValue,
  secondComparison,
  message
) => {
  const decValue = new Decimal(value);
  const decComparison = new Decimal(comparison);
  const decSecondValue = new Decimal(secondValue);
  const decSecondComparison = new Decimal(secondComparison);

  return decValue.greaterThan(decComparison) ||
    decSecondValue.greaterThan(decSecondComparison)
    ? true
    : message;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Validates is a vales equals some other value
 * or a value is greater than another value
 * or a value is greater than some other value
 *
 * @param  {Mixed} firstValue
 * @param  {Mixed} firstComparison
 * @param  {Number} secondValue
 * @param  {Number} secondComparison
 * @param  {Number} thirdValue
 * @param  {Number} thirdComparison
 * @param  {String} message Validation message if fails to assert
 *
 * @return {String}         Warning Message
 */
export const equalToOrGreaterThanOrGreaterThan = (
  value,
  comparison,
  secondValue,
  secondComparison,
  thirdValue,
  thirdComparison,
  message
) => {
  const decSecondValue = new Decimal(secondValue);
  const decSecondComparison = new Decimal(secondComparison);
  const decThirdValue = new Decimal(thirdValue);
  const decThirdComparison = new Decimal(thirdComparison);

  return value === comparison ||
    decSecondValue.greaterThan(decSecondComparison) ||
    decThirdValue.greaterThan(decThirdComparison)
    ? true
    : message;
};

const API_URL = process.env.REACT_APP_PROXY;

export const lazyIsUniqueSample = (code, examRequestId) => {
  const URL_TO_FETCH = `${API_URL}/is-unique-sample/${examRequestId}/${code}`;

  return axios.get(URL_TO_FETCH).then(response => {
    return response.data.isUnique ? true : "Código já cadastrado";
  });
};

export const lazyIsUniqueLogin = ({ login, id }) => {
  const URL_TO_FETCH = `${API_URL}/users/login/is-available/${login}?id=${id}`;

  return axios.get(URL_TO_FETCH).then(response => {
    return response.data.isAvailable ? true : "Login já cadastrado.";
  });
};

export const lazyIsUniqueSpecie = specieName => {
  const URL_TO_FETCH = `${API_URL}/species/is-unique-specie/${specieName}`;
  return axios.get(URL_TO_FETCH).then(response => {
    return response.data.isUnique ? true : "Espécie já cadastrada";
  });
};

export const lazyIsUniqueOutsourcedExam = (examName, examTypeId) => {
  const URL_TO_FETCH = `${API_URL}/outsourced-exams/is-unique-exam/${examName}/${examTypeId}`;
  return axios.get(URL_TO_FETCH).then(response => {
    return response.data.isUnique ? true : "Exame já cadastrado";
  });
};

export const lazyIsUniqueBreed = (breedName, speciesId) => {
  const URL_TO_FETCH = `${API_URL}/breeds/is-unique-breed/${breedName}/${speciesId}`;
  return axios.get(URL_TO_FETCH).then(response => {
    return response.data.isUnique ? true : "Espécie já cadastrada";
  });
};
