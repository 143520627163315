import { easRules } from "../../../assets/js/validationRules";
import { EXAM_TYPES } from "../../../consts";

export const easFiedsNames = () => {
  return [
    "easVolume",
    "easColorOptionId",
    "easSmellOptionId",
    "easAspectOptionId",
    "easDensity",
    "easProteinsOptionId",
    "easGlucoseOptionId",
    "easAcetoneOptionId",
    "easPh",
    "easBilirubinOptionId",
    "easUrobilinogenOptionId",
    "easHemoglobinOptionId",
    "easLeukocyteOptionId",
    "easNitriteOptionId",
    "easEpithelialCellsOptionTags",
    "easRidgesOptionTags",
    "easCastsOptionTags",
    "easErythrocytesMin",
    "easErythrocytesMax",
    "easIsErythrocytesAbsent",
    "easIsErythrocytesUncountable",
    "easPusCellsMin",
    "easPusCellsMax",
    "easIsPusCellsAbsent",
    "easIsPusCellsUncountable",
    "easBacterialFloraOptionId",
    "easSpermatozoaOptionId",
    "easObs",
  ];
};

export const easDefaultState = () => {
  return {
    easVolume: "",
    easColorOptionId: 0,
    easSmellOptionId: 0,
    easAspectOptionId: 0,
    easDensity: "",
    easProteinsOptionId: 0,
    easGlucoseOptionId: 0,
    easAcetoneOptionId: 0,
    easPh: "",
    easBilirubinOptionId: 0,
    easUrobilinogenOptionId: 0,
    easHemoglobinOptionId: 0,
    easLeukocyteOptionId: 0,
    easNitriteOptionId: 0,
    easEpithelialCellsOptionTags: [],
    easRidgesOptionTags: [],
    easCastsOptionTags: [],
    easErythrocytesMin: "",
    easErythrocytesMax: "",
    easIsErythrocytesAbsent: false,
    easIsErythrocytesUncountable: false,
    easPusCellsMin: "",
    easPusCellsMax: "",
    easIsPusCellsAbsent: false,
    easIsPusCellsUncountable: false,
    easBacterialFloraOptionId: 0,
    easSpermatozoaOptionId: 0,
    easObs: "",
  };
};

export const easStateApiFactory = (obj) => {
  return {
    easVolume: obj.easVolume,
    easColorOptionId: obj.easColorOptionId,
    easSmellOptionId: obj.easSmellOptionId,
    easAspectOptionId: obj.easAspectOptionId,
    easDensity: obj.easDensity,
    easProteinsOptionId: obj.easProteinsOptionId,
    easGlucoseOptionId: obj.easGlucoseOptionId,
    easAcetoneOptionId: obj.easAcetoneOptionId,
    easPh: obj.easPh,
    easBilirubinOptionId: obj.easBilirubinOptionId,
    easUrobilinogenOptionId: obj.easUrobilinogenOptionId,
    easHemoglobinOptionId: obj.easHemoglobinOptionId,
    easLeukocyteOptionId: obj.easLeukocyteOptionId,
    easNitriteOptionId: obj.easNitriteOptionId,
    easEpithelialCellsOptionTags: obj.easEpithelialCellsOptionTags,
    easRidgesOptionTags: obj.easRidgesOptionTags,
    easCastsOptionTags: obj.easCastsOptionTags,
    easErythrocytesMin: obj.easErythrocytesMin,
    easErythrocytesMax: obj.easErythrocytesMax,
    easIsErythrocytesAbsent: obj.easIsErythrocytesAbsent,
    easIsErythrocytesUncountable: obj.easIsErythrocytesUncountable,
    easPusCellsMin: obj.easPusCellsMin,
    easPusCellsMax: obj.easPusCellsMax,
    easIsPusCellsAbsent: obj.easIsPusCellsAbsent,
    easIsPusCellsUncountable: obj.easIsPusCellsUncountable,
    easBacterialFloraOptionId: obj.easBacterialFloraOptionId,
    easSpermatozoaOptionId: obj.easSpermatozoaOptionId,
    easObs: obj.easObs,
  };
};

export const easFieldsToApiObjParser = (fields) => {
  const {
    easVolume,
    easColorOptionId,
    easSmellOptionId,
    easAspectOptionId,
    easDensity,
    easProteinsOptionId,
    easGlucoseOptionId,
    easAcetoneOptionId,
    easPh,
    easBilirubinOptionId,
    easUrobilinogenOptionId,
    easHemoglobinOptionId,
    easLeukocyteOptionId,
    easNitriteOptionId,
    easEpithelialCellsOptionTags,
    easRidgesOptionTags,
    easCastsOptionTags,
    easErythrocytesMin,
    easErythrocytesMax,
    easIsErythrocytesAbsent,
    easIsErythrocytesUncountable,
    easPusCellsMin,
    easPusCellsMax,
    easIsPusCellsAbsent,
    easIsPusCellsUncountable,
    easBacterialFloraOptionId,
    easSpermatozoaOptionId,
    easObs,
  } = fields;

  const easErythMin =
    easIsErythrocytesAbsent || easIsErythrocytesUncountable
      ? ""
      : easErythrocytesMin;
  const easErythMax =
    easIsErythrocytesAbsent || easIsErythrocytesUncountable
      ? ""
      : easErythrocytesMax;
  const easPCellsMin =
    easIsPusCellsAbsent || easIsPusCellsUncountable ? "" : easPusCellsMin;
  const easPCellsMax =
    easIsPusCellsAbsent || easIsPusCellsUncountable ? "" : easPusCellsMax;
  return {
    easVolume,
    easColorOptionId,
    easSmellOptionId,
    easAspectOptionId,
    easDensity,
    easProteinsOptionId,
    easGlucoseOptionId,
    easAcetoneOptionId,
    easPh,
    easBilirubinOptionId,
    easUrobilinogenOptionId,
    easHemoglobinOptionId,
    easLeukocyteOptionId,
    easNitriteOptionId,
    easEpithelialCellsOptionTags,
    easRidgesOptionTags,
    easCastsOptionTags,
    easErythrocytesMin: easErythMin,
    easErythrocytesMax: easErythMax,
    easIsErythrocytesAbsent,
    easIsErythrocytesUncountable,
    easPusCellsMin: easPCellsMin,
    easPusCellsMax: easPCellsMax,
    easIsPusCellsAbsent,
    easIsPusCellsUncountable,
    easBacterialFloraOptionId,
    easSpermatozoaOptionId,
    easObs,
  };
};

export const easValidationRules = (fields, isCompleteValidation) => {
  const {
    easVolume,
    easColorOptionId,
    easSmellOptionId,
    easAspectOptionId,
    easDensity,
    easProteinsOptionId,
    easGlucoseOptionId,
    easAcetoneOptionId,
    easPh,
    easBilirubinOptionId,
    easUrobilinogenOptionId,
    easHemoglobinOptionId,
    easLeukocyteOptionId,
    easNitriteOptionId,
    easEpithelialCellsOptionTags,
    easRidgesOptionTags,
    easCastsOptionTags,
    easBacterialFloraOptionId,
    easSpermatozoaOptionId,
    easObs,
    easIsErythrocytesAbsent,
    easIsErythrocytesUncountable,
    easErythrocytesMin,
    easErythrocytesMax,
    easIsPusCellsAbsent,
    easIsPusCellsUncountable,
    easPusCellsMin,
    easPusCellsMax,
  } = fields;

  return easRules(
    "easVolume",
    easVolume,
    "easColorOptionId",
    easColorOptionId,
    "easSmellOptionId",
    easSmellOptionId,
    "easAspectOptionId",
    easAspectOptionId,
    "easDensity",
    easDensity,
    "easProteinsOptionId",
    easProteinsOptionId,
    "easGlucoseOptionId",
    easGlucoseOptionId,
    "easAcetoneOptionId",
    easAcetoneOptionId,
    "easPh",
    easPh,
    "easBilirubinOptionId",
    easBilirubinOptionId,
    "easUrobilinogenOptionId",
    easUrobilinogenOptionId,
    "easHemoglobinOptionId",
    easHemoglobinOptionId,
    "easLeukocyteOptionId",
    easLeukocyteOptionId,
    "easNitriteOptionId",
    easNitriteOptionId,
    "easEpithelialCellsOptionTags",
    easEpithelialCellsOptionTags,
    "easRidgesOptionTags",
    easRidgesOptionTags,
    "easCastsOptionTags",
    easCastsOptionTags,
    "easBacterialFloraOptionId",
    easBacterialFloraOptionId,
    "easSpermatozoaOptionId",
    easSpermatozoaOptionId,
    "easObs",
    easObs,
    easIsErythrocytesAbsent,
    easIsErythrocytesUncountable,
    easErythrocytesMin,
    easErythrocytesMax,
    easIsPusCellsAbsent,
    easIsPusCellsUncountable,
    easPusCellsMin,
    easPusCellsMax,
    isCompleteValidation
  );
};

export const easRequests = (requestExamId) => {
  return {
    easRefsPromise: `/reference-values/?requestExamId=${requestExamId}&examTypeId=${EXAM_TYPES.URINALYSIS}`,
    colorOptions: "/color-options",
    smellOptions: "/smell-options",
    aspectOptions: "/aspect-options",
    glucoseOptions: "/glucose-options",
    acetoneOptions: "/acetone-options",
    bilirubinOptions: "/bilirubin-options",
    urobilinogenOptions: "/urobilinogen-options",
    hemoglobinOptions: "/hemoglobin-options",
    leukocyteOptions: "/leukocyte-options",
    nitriteOptions: "/nitrite-options",
    bacterialFloraOptions: "/bacterial-flora-options",
    spermatozoaOptions: "/spermatozoa-options",
    proteinsOptions: "/proteins-options",
    epithelialCellsOptions: "/epithelial-cells-options",
    ridgesOptions: "/ridges-options",
    castsOptions: "/casts-options",
  };
}

export const extractEasOptions = (props) => {
  const {
    colorOptions,
    smellOptions,
    aspectOptions,
    glucoseOptions,
    acetoneOptions,
    bilirubinOptions,
    urobilinogenOptions,
    hemoglobinOptions,
    leukocyteOptions,
    nitriteOptions,
    bacterialFloraOptions,
    spermatozoaOptions,
    proteinsOptions,
    epithelialCellsOptions,
    ridgesOptions,
    castsOptions,
  } = props;

  return  {
    colorOptions: colorOptions.fulfilled ? colorOptions.value : [],
    smellsOptions: smellOptions.fulfilled ? smellOptions.value : [],
    aspectsOptions: aspectOptions.fulfilled ? aspectOptions.value : [],
    glucoseOptions: glucoseOptions.fulfilled ? glucoseOptions.value : [],
    acetoneOptions: acetoneOptions.fulfilled ? acetoneOptions.value : [],
    bilirubinOptions: bilirubinOptions.fulfilled
      ? bilirubinOptions.value
      : [],
    urobilinogenOptions: urobilinogenOptions.fulfilled
      ? urobilinogenOptions.value
      : [],
    hemoglobinOptions: hemoglobinOptions.fulfilled
      ? hemoglobinOptions.value
      : [],
    leukocyteOptions: leukocyteOptions.fulfilled
      ? leukocyteOptions.value
      : [],
    nitriteOptions: nitriteOptions.fulfilled ? nitriteOptions.value : [],
    bacterialFloraOptions: bacterialFloraOptions.fulfilled
      ? bacterialFloraOptions.value
      : [],
    spermatozoaOptions: spermatozoaOptions.fulfilled
      ? spermatozoaOptions.value
      : [],
    proteinsOptions: proteinsOptions.fulfilled ? proteinsOptions.value : [],
    epithelialCellsOptions: epithelialCellsOptions.fulfilled
      ? epithelialCellsOptions.value
      : [],
    ridgesOptions: ridgesOptions.fulfilled ? ridgesOptions.value : [],
    castsOptions: castsOptions.fulfilled ? castsOptions.value : [],
  };

}

