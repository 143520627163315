import React, { Component } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import InputValidationMsg from './../InputValidationMsg';

import {
  EditorState
} from 'draft-js';

class WysiwygEditor extends Component {

  constructor( props ) {
    super( props );

    this.state = {
      editorState: EditorState.createEmpty()
    }

    this.onEditorStateChange = this.onEditorStateChange.bind( this );
  }

  onEditorStateChange( editorState ) {
    this.setState( { editorState } );
  }

  render() {
    const editorStateFromOutside = this.props.editorState;

    const {
      name,
      shouldDisable,
      shouldDisplayValidation,
      validationMessage,
      onBlur,
      onChange
    } = this.props;

    const handleChange = ( newEditorState ) => {
      onChange( name, newEditorState ); 
    }

    return (
      <React.Fragment>
        <Editor
          editorState={ editorStateFromOutside }
          toolbarClassName="draf-toolbar"
          wrapperClassName="wrapperClassName"
          editorClassName="draft-editor"
          readOnly={ shouldDisable }
          onEditorStateChange={ handleChange }
          onBlur={ onBlur }
          toolbar={ {
            options: [ 'inline', 'list', 'history' ],
            inline: { options: [ 'bold', 'italic', 'underline' ] },
            list: { options: [ 'unordered', 'ordered' ] }
          } } />
        { !shouldDisable && <InputValidationMsg
          type="danger"
          visible={ shouldDisplayValidation }
          message={ validationMessage }
          top="" /> }
      </React.Fragment>
    );
  }
}

export default WysiwygEditor;