import React, { Component, useState } from "react";

import { Tab, Tabs, Col, Row } from "react-bootstrap";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
import { ArrayUtil, FunctionUtil } from "./../../useful";

// import { connect as reduxConnect } from "react-redux";

import refetchConnect from "./../../api-connector";
import connectWithEndpoint from "./requests";
import ExamRecordsView from "./view";
import { useForm } from "../../hooks/useForm";
import promiseUtil from "./../../useful/promise-util";

const header = [
  "Id do Cliente",
  "Nome do Cliente",
  "Qtd de Exames Atendidos e liberados",
  "Valor dos exames Atendidos e Liberados",
];

function enableLabs(userLabId) {
  return true;
}

const ExamRecords = (props) => {
  const [values, loading, inputChange, fieldChange, handleSubmit] = useForm();
  const regions = promiseUtil.extractValue(props.regions, []);
  const customers = promiseUtil.extractValue(props.customers, []);

  const submitActions = () => {
    const success = (data) => {
      data.file = data.file.replace(/,/g, ";");
      data.file = data.file.replace(/decimal_separetor/g, ",");
      var data = new Blob(["\ufeff", data.file], {
        type: "text/csv;charset=utf-8",
      });
      var csvURL = window.URL.createObjectURL(data);
      var tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "relatório.csv");
      tempLink.click();
      // ArrayUtil.exportCSVFile(header, data.file, "relatório");
    };
    const error = () => {
      alert("Erro interno, tente novamente ou contacte o suporte");
    };
    props.examRecords(values, success, error);
  };

  const shouldEnableLabs = enableLabs(props.userLabId);

  return (
    <>
      <ExamRecordsView
        values={values}
        handleSubmit={handleSubmit(submitActions)}
        inputChange={inputChange}
        fieldChange={fieldChange}
        loading={loading}
        regions={regions}
        customers={customers}
        shouldEnableLabs={shouldEnableLabs}
      />
    </>
  );
};

export default refetchConnect(connectWithEndpoint)(ExamRecords);
