import React from 'react';

/**
 * Assets
 */
import './index.css';

/**
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Panel material bottom part. Takes Material Buttons as children
 */
const PanelMaterialBody = ( { children } ) =>
  <div className="col-xs-12">
    <div className="panel-content-wrapper">
      { children }
    </div>
  </div>

export default PanelMaterialBody;
