import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import LVTable from "./../../old_components/LVTable";
import {
  renderRefs,
  renderExpander,
  isAnyOfSpecialBiochemistry,
  isIonicCalcium,
  isTotalCholesterolAndFractions,
  isTotalProteinAndFractions,
  isBilirubinAndFractions,
  renderIonicCalcium,
  renderTotalCholesterolAndFractions,
  renderTotalProteinAndFractions,
  renderBilirubinAndFractions,
  isRelationAlbuminGlobulin,
  renderRelationAlbuminGlobulin,
} from "./../../assets/js/reportFunctions.js";

import NumberInputMaterial2 from "./../../containers/NumberInputMaterial2";

import FlatPanelMaterial from "./../../containers/FlatPanelMaterial";
import TextAreaPanel2 from "./../../containers/TextAreaPanel2";

import {
  isIntegerBiochemExam,
  EXAM_ID_BY_NAME,
  qttDecimalPlates,
} from "./../../assets/js/Consts.js";

/**
 * Assets
 */
import styles from "./index.module.css";
import InputExam from "../../components/input-exam";
import { Col } from "react-bootstrap";
import InputValidationMsg from "../../containers/InputValidationMsg";

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for react table
 */
class ProfileBiochemistryTable extends Component {
  constructor(props) {
    super(props);

    this.renderResultField = this.renderResultField.bind(this);
  }

  renderResultField(props) {
    const {
      fieldName,
      result,
      hasValidation,
      validationReason,
      examId,
      mustNotDisplayResultField,
    } = props.original;

    const isInteger = isIntegerBiochemExam(examId);

    return mustNotDisplayResultField ? (
      <span></span>
    ) : (
      <div className="text-left">
        <Col>
          <InputExam
            name={fieldName}
            decimalScale={isInteger ? 0 : qttDecimalPlates(examId)}
            value={result}
            disabled={this.props.shouldDisable}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
          />
          <InputValidationMsg
            visible={hasValidation}
            message={validationReason}
          />
        </Col>
      </div>
    );
  }

  isBioWithNoResultInput = (examId) => {
    const noResultExams = [
      EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"],
      EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"],
      EXAM_ID_BY_NAME["RELATION_ALBUMIN_GLOBULIN"]
    ];
    return noResultExams.indexOf(examId) === -1 ? false : true;
  };

  render() {
    /**
     * @type {array}
     */
    const columns = [
      {
        Header: "Nome do Exame",
        accessor: "name",
        className: styles.leftText,
        maxWidth: 480,
      },
      {
        Header: (props) => <span className="float-left">Resultado</span>,
        className: styles.centeredText,
        sortable: false,
        width: 120,
        Cell: (props) => {
          return this.isBioWithNoResultInput(props.original.examId)
            ? null
            : this.renderResultField(props);
        },
      },
      {
        Header: "Valores Normais",
        sortable: false,
        className: styles.centeredText,
        Cell: (props) => {
          if (isAnyOfSpecialBiochemistry(props.original.examId)) {
            return null;
          }

          return renderRefs(
            props.original.refMin,
            props.original.refMax,
            props.original.refUnit
          );
        },
        width: 320,
      },
    ];

    const renderTableSub = (row) => {
      switch (true) {
        case isBilirubinAndFractions(row.original.examId):
          return renderBilirubinAndFractions(
            row.original.bilirubinAndFractionsTotalFieldName,
            row.original.bilirubinAndFractionsDirectFieldName,
            row.original.bilirubinAndFractionsIndirectFieldName,
            row.original.bilirubinAndFractionsTotal,
            row.original.bilirubinAndFractionsDirect,
            row.original.bilirubinAndFractionsIndirect,
            row.original.bilirubinAndFractionsTotalHasValidation,
            row.original.bilirubinAndFractionsDirectHasValidation,
            row.original.bilirubinAndFractionsTotalValidationReason,
            row.original.bilirubinAndFractionsDirectValidationReason,
            row.original.bilirubinAndFractionsTotalRefMin,
            row.original.bilirubinAndFractionsTotalRefMax,
            row.original.bilirubinAndFractionsTotalRefUnit,
            row.original.bilirubinAndFractionsDirectRefMin,
            row.original.bilirubinAndFractionsDirectRefMax,
            row.original.bilirubinAndFractionsDirectRefUnit,
            row.original.bilirubinAndFractionsIndirectRefMin,
            row.original.bilirubinAndFractionsIndirectRefMax,
            row.original.bilirubinAndFractionsIndirectRefUnit,
            row.original.examId,
            this.props.handleChange,
            this.props.handleBlur,
            this.props.shouldDisable
          );
        case isIonicCalcium(row.original.examId):
          return renderIonicCalcium(
            row.original.ionicCalciumCalciumFieldName,
            row.original.ionicCalciumAlbuminFieldName,
            row.original.ionicCalciumTotalProteinFieldName,
            row.original.ionicCalciumIonicCalciumFieldName,
            row.original.ionicCalciumCalcium,
            row.original.ionicCalciumAlbumin,
            row.original.ionicCalciumTotalProtein,
            row.original.ionicCalciumIonicCalcium,
            row.original.ionicCalciumCalciumHasValidation,
            row.original.ionicCalciumAlbuminHasValidation,
            row.original.ionicCalciumTotalProteinHasValidation,
            row.original.ionicCalciumIonicCalciumHasValidation,
            row.original.ionicCalciumCalciumValidationReason,
            row.original.ionicCalciumAlbuminValidationReason,
            row.original.ionicCalciumTotalProteinValidationReason,
            row.original.ionicCalciumIonicCalciumValidationReason,
            row.original.ionicCalciumCalciumRefMin,
            row.original.ionicCalciumCalciumRefMax,
            row.original.ionicCalciumCalciumRefUnit,
            row.original.ionicCalciumAlbuminRefMin,
            row.original.ionicCalciumAlbuminRefMax,
            row.original.ionicCalciumAlbuminRefUnit,
            row.original.ionicCalciumTotalProteinRefMin,
            row.original.ionicCalciumTotalProteinRefMax,
            row.original.ionicCalciumTotalProteinRefUnit,
            row.original.ionicCalciumIonicCalciumRefMin,
            row.original.ionicCalciumIonicCalciumRefMax,
            row.original.ionicCalciumIonicCalciumRefUnit,
            row.original.examId,
            this.props.handleChange,
            this.props.handleBlur,
            this.props.toggleIonicCalciumEditable,
            this.props.shouldDisable,
            row.original.isIonicCalciumEditable
          );
        case isTotalCholesterolAndFractions(row.original.examId):
          return renderTotalCholesterolAndFractions(
            row.original.totalCholesterolAndFractionsTotalCholesterolFieldName,
            row.original.totalCholesterolAndFractionsHdlFieldName,
            row.original.totalCholesterolAndFractionsLdlFieldName,
            row.original.totalCholesterolAndFractionsVldlFieldName,
            row.original.totalCholesterolAndFractionsTriglyceridesFieldName,
            row.original.totalCholesterolAndFractionsTotalCholesterol,
            row.original.totalCholesterolAndFractionsHdl,
            row.original.totalCholesterolAndFractionsLdl,
            row.original.totalCholesterolAndFractionsVldl,
            row.original.totalCholesterolAndFractionsTriglycerides,
            row.original
              .totalCholesterolAndFractionsTotalCholesterolHasValidation,
            row.original.totalCholesterolAndFractionsHdlHasValidation,
            row.original.totalCholesterolAndFractionsLdlHasValidation,
            row.original.totalCholesterolAndFractionsVldlHasValidation,
            row.original.totalCholesterolAndFractionsTriglyceridesHasValidation,
            row.original
              .totalCholesterolAndFractionsTotalCholesterolValidationReason,
            row.original.totalCholesterolAndFractionsHdlValidationReason,
            row.original.totalCholesterolAndFractionsLdlValidationReason,
            row.original.totalCholesterolAndFractionsVldlValidationReason,
            row.original
              .totalCholesterolAndFractionsTriglyceridesValidationReason,
            row.original.totalCholesterolAndFractionsTotalCholesterolRefMin,
            row.original.totalCholesterolAndFractionsTotalCholesterolRefMax,
            row.original.totalCholesterolAndFractionsTotalCholesterolRefUnit,
            row.original.totalCholesterolAndFractionsHdlRefMin,
            row.original.totalCholesterolAndFractionsHdlRefMax,
            row.original.totalCholesterolAndFractionsHdlRefUnit,
            row.original.totalCholesterolAndFractionsLdlRefMin,
            row.original.totalCholesterolAndFractionsLdlRefMax,
            row.original.totalCholesterolAndFractionsLdlRefUnit,
            row.original.totalCholesterolAndFractionsVldlRefMin,
            row.original.totalCholesterolAndFractionsVldlRefMax,
            row.original.totalCholesterolAndFractionsVldlRefUnit,
            row.original.totalCholesterolAndFractionsTriglyceridesRefMin,
            row.original.totalCholesterolAndFractionsTriglyceridesRefMax,
            row.original.totalCholesterolAndFractionsTriglyceridesRefUnit,
            row.original.examId,
            this.props.handleChange,
            this.props.handleBlur,
            this.props.shouldDisable
          );
        case isTotalProteinAndFractions(row.original.examId):
          return renderTotalProteinAndFractions(
            row.original.totalProteinAndFractionsTotalProteinFieldName,
            row.original.totalProteinAndFractionsAlbuminFieldName,
            row.original.totalProteinAndFractionsGlobulinFieldName,
            row.original.totalProteinAndFractionsTotalProtein,
            row.original.totalProteinAndFractionsAlbumin,
            row.original.totalProteinAndFractionsGlobulin,
            false,
            false,
            row.original.totalProteinAndFractionsTotalProteinValidationReason,
            row.original.totalProteinAndFractionsAlbuminValidationReason,
            row.original.totalProteinAndFractionsTotalProteinRefMin,
            row.original.totalProteinAndFractionsTotalProteinRefMax,
            row.original.totalProteinAndFractionsTotalProteinRefUnit,
            row.original.totalProteinAndFractionsAlbuminRefMin,
            row.original.totalProteinAndFractionsAlbuminRefMax,
            row.original.totalProteinAndFractionsAlbuminRefUnit,
            row.original.totalProteinAndFractionsGlobulinRefMin,
            row.original.totalProteinAndFractionsGlobulinRefMax,
            row.original.totalProteinAndFractionsGlobulinRefUnit,
            row.original.examId,
            this.props.handleChange,
            this.props.handleBlur,
            this.props.shouldDisable
          );
         case isRelationAlbuminGlobulin(row.original.examId):
          return renderRelationAlbuminGlobulin(
            row.original.relationAlbuminGlobulinResultFieldName,
            row.original.relationAlbuminGlobulinAlbuminFieldName,
            row.original.relationAlbuminGlobulinGlobulinFieldName,
            row.original.relationAlbuminGlobulinResult,
            row.original.relationAlbuminGlobulinAlbumin,
            row.original.relationAlbuminGlobulinGlobulin,
            row.original.relationAlbuminGlobulinResultHasValidation,
            row.original.relationAlbuminGlobulinAlbuminHasValidation,
            row.original.relationAlbuminGlobulinResultValidationReason,
            row.original.relationAlbuminGlobulinAlbuminValidationReason,
            row.original.relationAlbuminGlobulinResultRefMin,
            row.original.relationAlbuminGlobulinResultRefMax,
            row.original.relationAlbuminGlobulinResultRefUnit,
            row.original.relationAlbuminGlobulinAlbuminRefMin,
            row.original.relationAlbuminGlobulinAlbuminRefMax,
            row.original.relationAlbuminGlobulinAlbuminRefUnit,
            row.original.relationAlbuminGlobulinGlobulinRefMin,
            row.original.relationAlbuminGlobulinGlobulinRefMax,
            row.original.relationAlbuminGlobulinGlobulinRefUnit,
            row.original.examId,
            this.props.handleChange,
            this.props.handleBlur,
            this.props.shouldDisable
          );
        default:
          return <span></span>;
      }
    };

    return (
      <React.Fragment>
        <div className="text-center">
          <h5 className="table-title">{this.props.title.toUpperCase()}</h5>
        </div>
        <div className="fit-table">
          <LVTable
            data={this.props.data}
            columns={columns}
            // defaultSorted={[{ id: "name", desc: false }]}
            pageSize={this.props.data.length}
            showPagination={false}
            shouldPaintRowOnClick={false}
            SubComponent={renderTableSub}
            ExpanderComponent={renderExpander}
          />
          {this.props.biochemObs !== undefined ? (
            <TextAreaPanel2
              title="Obs"
              name={"biochemObs"}
              value={this.props.biochemObs}
              disabled={this.props.shouldDisable}
              handleChange={this.props.handleChange}
              handleBlur={this.props.handleBlur}
            />
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

ProfileBiochemistryTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};

ProfileBiochemistryTable.defaultProps = {
  title: "",
  data: [],
};

export default ProfileBiochemistryTable;
