import { molecularBiologyRules } from "../../../../assets/js/validationRules";

export const babesiaFieldNames = () => {
  return [
    "babesiaMaterials",
    "babesiaTechnique",
    "babesiaQualitativeResult",
    "babesiaCopyValues",
    "babesiaPrecision",
    "babesiaResult",
    "babesiaObs",
  ];
};

export const babesiaDefaultState = () => {
  return {
    babesiaMaterials: [],
    babesiaTechnique: -1,
    babesiaQualitativeResult: -1,
    babesiaCopyValues: "",
    babesiaPrecision: "",
    babesiaResult: "",
    babesiaObs: "",
  };
};

export const babesiaStateApiFactory = (obj) => {
  const {
    babesiaMaterials,
    babesiaTechnique,
    babesiaQualitativeResult,
    babesiaCopyValues,
    babesiaPrecision,
    babesiaResult,
    babesiaObs,
  } = obj;
  return {
    babesiaMaterials: babesiaMaterials ? babesiaMaterials : [],
    babesiaTechnique: babesiaTechnique ? babesiaTechnique : -1,
    babesiaQualitativeResult: !(babesiaQualitativeResult == null)
      ? babesiaQualitativeResult
      : -1,
    babesiaCopyValues: babesiaCopyValues ? babesiaCopyValues : "",
    babesiaPrecision: babesiaPrecision ? babesiaPrecision : "",
    babesiaResult: babesiaResult ? babesiaResult : "",
    babesiaObs: babesiaObs ? babesiaObs : "",
  };
};

export const babesiaFieldsToApiObjParser = (fields) => {
  const {
    babesiaMaterials,
    babesiaTechnique,
    babesiaQualitativeResult,
    babesiaCopyValues,
    babesiaPrecision,
    babesiaResult,
    babesiaObs,
  } = fields;

  return {
    babesiaMaterials: babesiaMaterials,
    babesiaTechnique: babesiaTechnique,
    babesiaQualitativeResult: babesiaQualitativeResult,
    babesiaCopyValues: babesiaCopyValues,
    babesiaPrecision: babesiaPrecision,
    babesiaResult: babesiaResult,
    babesiaObs: babesiaObs,
  };
};

export const babesiaValidationRules = (fields, isCompleteValidation) => {
  const {
    babesiaMaterials,
    babesiaTechnique,
    babesiaQualitativeResult,
    babesiaCopyValues,
    babesiaPrecision,
  } = fields;
  return molecularBiologyRules(
    "babesiaTechnique",
    babesiaTechnique,
    "babesiaQualitativeResult",
    babesiaQualitativeResult,
    "babesiaCopyValues",
    babesiaCopyValues,
    "babesiaPrecision",
    babesiaPrecision,
    "babesiaMaterials",
    babesiaMaterials,
    isCompleteValidation
  );
};
