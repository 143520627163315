import React from 'react';
import PropTypes from 'prop-types';
import ButtonDefaultMaterial from './../ButtonDefaultMaterial';

/** External */
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import plus from '@fortawesome/fontawesome-free-solid/faPlus';

/**
 * @author Victor Heringer
 * 
 * @param {Mix} title Text, icon or component to represene de title
 * @param {Function} onClick Handles the click at button
 * @param {String} className Style for component 
 * @param {Boolean} disabled Disables the button
 */
const TableButtonAddMaterial = ( { title, onClick, className, disabled } ) => {
  return (
    <ButtonDefaultMaterial
      title={ title }
      type='highlight'
      className={ className }
      responsive
      disabled={ disabled }
      icon={ <FontAwesomeIcon icon={ plus } /> }
      onClick={ onClick }
    />
  )
}

TableButtonAddMaterial.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default TableButtonAddMaterial;