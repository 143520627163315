import React from "react";

import SpecieFormView from "./form-view";

import { FIVE_SECONDS } from "./../../consts/time-lengths";

/*
  THE Consts.js FILE MUST BE ELIMINATED!
  ALL CONSTS SHALL BE PLACED IN consts/ DIRECTORY.
  */
import { ACCESS_PROFILES } from "./../../assets/js/Consts";
import Notifications from "react-notification-system-redux";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";

import apiConnect from "../../api-connector";
import update from "immutability-helper";
import { Redirect } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { connectFormWithEndpoint } from "./requests";
import { FunctionUtil } from "./../../useful";
import { connect as reduxConnect } from "react-redux";

class SpecieForm extends React.Component {
  state = {
    fields: {
      id: this.props.match.params.id ? this.props.match.params.id : 0,
      name: "",
      status: 1,
    },
  };

  componentDidMount() {
    const then = (data, xhr) => {
      if (xhr.response.status === 200) this.fillFields(data);
    };

    if (this.state.fields.id) {
      this.props.withId(this.state.fields.id, then);
    }
  }

  /**
   * @author Anderson Souza <andersonsouza@protonmail.com>
   *
   * Changes field state
   *
   * @param {string} field Name of the state that represents the field
   * @param {mixed}  value New value
   *
   */
  fieldChange = (field, value) => {
    this.setState((state) =>
      update(state, { fields: { [field]: { $set: value } } })
    );
  };

  /**
   *
   * @author Anderson Souza <andersonsouza@protonmail.com>
   *
   * Fill the state related to all the form fields
   *
   * @param {string} obj All values that will be used to fill fields
   *
   */
  fillFields = (obj) => {
    let newState = {
      fields: {
        name: { $set: obj.name },
        status: { $set: obj.status },
      },
    };

    this.setState((state) => update(state, newState));
  };

  /**
   * @author Anderson Souza <andersonsouza@protonmail.com>
   *
   * Submits Specie form data
   *
   * @param {Object} event The submit event
   *
   * @return {boolean}
   *
   */
  handleSubmit = () => {
    const { id, name, status } = this.state.fields;

    const obj = { id: id, name: name, status: status };

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        this.props.successNotification({
          title: "Sucesso",
          message: this.isUpdate() ? "Espécie atualizada!" : "Espécie salva!",
          position: "tr",
          autoDismiss: FIVE_SECONDS,
        });
      }
    };

    const error = (data, xhr) => {
      if (xhr.response.status === 409) {
        this.props.errorNotification({
          title: "Nome Inválido",
          message: "",
        });
      }
    };

    if (id > 0) {
      this.props.update(id, obj, then, error);
    } else {
      this.props.add(obj, then, error);
    }
  };

  /**
   *
   * @author Anderson Souza <andersonsouza@protonmail.com>
   *
   * Check if is at editing state
   *
   * @return {boolean}
   */
  isUpdate() {
    return this.state.fields.id > 0;
  }

  /**
   * @author Anderson Souza <andersonsouza@protonmail.com>
   *
   * Pops up a notification for invalid submit
   *
   */
  invalidNotification = () => {
    this.props.errorNotification({
      title: "Atenção, dados incompletos!",
      message: "Verifique os destaques em vermelho.",
      position: "tr",
      autoDismiss: FIVE_SECONDS,
    });
  };

  /**
   * @author Sergio Pallet
   *
   * Check if should disable all fields
   *
   * @return {Boolean}
   */
  shouldDisableAll() {
    const haveNoAccess =
      this.props.access_profile == ACCESS_PROFILES.CLINIC_ADMINISTRATOR ||
      this.props.access_profile == ACCESS_PROFILES.CLINIC_USER ||
      this.props.access_profile == ACCESS_PROFILES.UNIT_USER ||
      this.props.access_profile == ACCESS_PROFILES.UNIT_ADMINISTRATOR;
    const pending = this.props.species && this.props.species.pending;
    return haveNoAccess || pending;
  }

  render() {
    const { obj, specieResponse } = this.props;
    if (specieResponse && specieResponse.fulfilled) {
      return <Redirect to="/especies" />;
    }

    if (obj && obj.pending) {
      return <BounceLoader color="#00B4AD" load />;
    }

    return (
      <>
        <SpecieFormView
          isUpdate={this.isUpdate()}
          fields={this.state.fields}
          fieldChange={this.fieldChange}
          handleSubmit={this.handleSubmit}
          invalidNotification={this.invalidNotification}
          disableAll={this.shouldDisableAll()}
        />
        <Notifications notifications={this.props.notifications} />
      </>
    );
  }
}

const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);
const connectWithApi = apiConnect(connectFormWithEndpoint);

export default FunctionUtil.compose(
  connectWithRedux,
  connectWithApi
)(SpecieForm);
