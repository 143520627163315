import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import HemogramPanel from "./../HemogramPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import ProfileBiochemistryTable from "./../../old_components/ProfileBiochemistryTable";
import ProfileEndocrinologyTable from "./../../old_components/ProfileEndocrinologyTable";
import FlatPanelMaterial from "./../FlatPanelMaterial";

/**
 * External
 */
import { Row, Col } from "./../../components/grid";
import LVFileInputMaterial from "../../old_components/LVFileInputMaterial";
import Checkbox from "../../components/checkbox";
import CheckboxMaterial from "../CheckboxMaterial";

const HemogramWithBiochemistryChemiluminescenceView = ({
  title,
  data: {
    refs,
    total,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    requestExamStates,
    biochemistryTableData,
    chemiluminescenceTableData,
    reportData,
  },
  methods: {
    $field,
    fieldChange,
    onSubmit,
    calcMCHC,
    calcMCV,
    erythrogramDiagnosis,
    plateletsDiagnosis,
    calcAbsolute,
    resetLeukogram,
    onClickCounter,
    leukogramDiagnosis,
    toggleIntenseLeucopeny,
    finalGlobalLeukogram,
    handleKeyPress,
    handleFinishedPlaying,
    closeCounter,
    handleChange,
    handleBlur,
    toggleIonicCalciumEditable,
    cancelReport,
    stopReport,
    handleSendReportEmail,
    handleFocus,
    setRef,
    uploadAttachment,
    handleDeactivateChemilExams,
  },
}) => (
  <ReportPanelMaterial
    title={title}
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    cancelReport={cancelReport}
    stopReport={stopReport}
    handleSendReportEmail={handleSendReportEmail}
    handleFocus={handleFocus}
    uploadAttachment={uploadAttachment}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <HemogramPanel
      fields={fields}
      refs={refs}
      disableAll={disableAll}
      $validation={$validation}
      total={total}
      $field={$field}
      fieldChange={fieldChange}
      calcMCV={calcMCV}
      calcMCHC={calcMCHC}
      calcAbsolute={calcAbsolute}
      resetLeukogram={resetLeukogram}
      onClickCounter={onClickCounter}
      toggleIntenseLeucopeny={toggleIntenseLeucopeny}
      finalGlobalLeukogram={finalGlobalLeukogram}
      erythrogramDiagnosis={erythrogramDiagnosis}
      leukogramDiagnosis={leukogramDiagnosis}
      plateletsDiagnosis={plateletsDiagnosis}
      handleKeyPress={handleKeyPress}
      handleFinishedPlaying={handleFinishedPlaying}
      closeCounter={closeCounter}
      setRef={setRef}
      reportData={reportData}
    />
    <Row>
      <Col md={12}>
        <FlatPanelMaterial title="Bioquímica">
          <ProfileBiochemistryTable
            data={biochemistryTableData}
            isUpdate={isUpdate}
            handleChange={handleChange}
            handleBlur={handleBlur}
            toggleIonicCalciumEditable={toggleIonicCalciumEditable}
            shouldDisable={disableAll}
            requestExamStates={requestExamStates}
            biochemObs={fields.biochemObs}
          />
        </FlatPanelMaterial>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FlatPanelMaterial title="Quimioluminescência">
          <CheckboxMaterial
            name={"deactivateChemilExam"}
            titleAfter="Resultado Anexo"
            disabled={disableAll}
            onChange={handleDeactivateChemilExams}
            checked={fields.deactivateChemilExam}
          />
          <ProfileEndocrinologyTable
            data={chemiluminescenceTableData}
            isUpdate={isUpdate}
            handleChange={handleChange}
            handleBlur={handleBlur}
            shouldDisable={disableAll || fields.deactivateChemilExam}
            requestExamStates={requestExamStates}
            chemilObs={fields.chemilObs}
            handleInative={true}
          />
        </FlatPanelMaterial>
      </Col>
    </Row>
    <LVFileInputMaterial
      id={"signaturesUpload"}
      name={"signaturesUpload"}
      allowedExtensions={[".pdf"]}
      setContainerFiles={(value) => handleChange("attachment", value)}
      asBase64={true}
      maxFileSize={2000000}
      allowMultiples={false}
      hasImgPreview={false}
      hasPdfPreview={true}
      clearBtnTitle={"Remover Anexo"}
      title={"Selecionar Anexo"}
      description={
        "Tamanho máximo aceito para o arquivo: 2MB - Utilize apenas arquivos em PDF"
      }
      hasFilesNames={true}
      placeholders={fields.attachment}
      disabled={disableAll}
    />
  </ReportPanelMaterial>
);

export default HemogramWithBiochemistryChemiluminescenceView;
