import React from "react";

/** Internal */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialBody from "./../PanelMaterialBody";
import PanelMaterialTop from "./../PanelMaterialTop";
import InputMaterial from "./../InputMaterial";
import SelectMaterial from "./../SelectMaterial";
import DatePickerMaterial from "./../DatePickerMaterial";
import LVTable from "./../../old_components/LVTable";
import { StringUtil } from "./../../useful";
import { PromiseUtil } from "../../useful";
import { examRequestSearchValidation } from "./../ExamRequestSearchList/validation";
import * as LifeVet from "./../../components";
import { EXAM_REQUEST_STATES } from "./../../consts";

/** External */
import { Row, Col } from "./../../components/grid";
import { validated } from "react-custom-validation";
import { Link } from "react-router-dom";
import moment from "moment";

const today = moment().endOf("day");
const paintRow = (state, rowInfo, column) => {
  const stateName =
    rowInfo && rowInfo.original && rowInfo.original.stateName
      ? rowInfo.original.stateName
      : "";

  switch (stateName) {
    case "AGUARDANDO_RECEBIMENTO": {
      return "";
    }
    case "RECEBIDO_EM_ANDAMENTO": {
      return "";
    }
    case "ATENDIDO_E_NAO_LIBERADO": {
      return "";
    }
    case "PARADO_POR_PROBLEMAS": {
      return "";
    }
    case "CANCELADO": {
      return "";
    }
    case "ATENDIDO_E_LIBERADO": {
      return "";
    }
    case "FATURADO": {
      return "";
    }
    default:
      return "";
  }
};

const ExamRequestSearchListView = ({ data, methods }) => {
  const {
    examsRequest,
    fields,
    requestExamStates,
    exams,
    sexs,
    species,
    customers,
    shouldDisableCustomer,
    page,
  } = data;

  const {
    handleSubmitSearch,
    fieldChange,
    handleCleanFilters,
    onTableDoubleClick,
    yearsMonthsChange,
    onPageChange,
  } = methods;

  const config = {
    pageSize: 12,
    onDoubleClickRow: onTableDoubleClick,
    loading: PromiseUtil.isPending(examsRequest),
    data: PromiseUtil.extractValue(examsRequest, []),
    columns: [
      {
        id: "code",
        Header: "Código",
        sortable: true,
        maxWidth: 70,
        accessor: "code",
      },
      {
        id: "status",
        Header: "Status",
        sortable: true,
        accessor: "exam_request_state",
        Cell: (data) => (
          <div className="cut-text">
            {EXAM_REQUEST_STATES.toString(data.original.exam_request_state)}
          </div>
        ),
      },
      {
        id: "date",
        Header: "Data",
        sortable: true,
        minWidth: 50,
        Cell: (data) => (
          <div className="cut-text">
            {StringUtil.formatDateUi(data.original.createdAt)}
          </div>
        ),
      },
      {
        id: "animal",
        Header: "Animal",
        sortable: true,
        Cell: (data) => (
          <div className="cut-text">{data.original.animalName}</div>
        ),
      },
      {
        id: "specie",
        Header: "Espécie",
        sortable: true,
        accessor: "specieName",
      },
      {
        id: "age",
        Header: "Idade",
        sortable: false,
        Cell: (data) => (
          <div className="cut-text">
            {data.original.animalAgeOption
              ? data.original.animalAgeOption === 1
                ? "NÃO INFORMADO"
                : data.original.animalAgeOption === 2
                  ? "ADULTO"
                  : StringUtil.newDateToYears(
                    data.original.birthDate,
                    data.original.createdAt
                  ) +
                  " A e " +
                  StringUtil.newDateToMonths(
                    data.original.birthDate,
                    data.original.createdAt
                  ) +
                  " M"
              : StringUtil.newDateToYears(
                data.original.birthDate,
                data.original.createdAt
              ) +
              " A e " +
              StringUtil.newDateToMonths(
                data.original.birthDate,
                data.original.createdAt
              ) +
              " M"}
          </div>
        ),
      },
      // {
      //   id: "years",
      //   Header: "Anos",
      //   sortable: true,
      //   maxWidth: 70,
      //   Cell: (data) => (
      //     <div className="cut-text">
      //       {data.original.ageOption
      //         ? 0
      //         : StringUtil.newDateToYears(
      //             data.original.birthDate,
      //             data.original.createdAt
      //           )}
      //     </div>
      //   ),
      // },
      // {
      //   id: "months",
      //   Header: "Meses",
      //   maxWidth: 70,
      //   sortable: true,
      //   Cell: (data) => (
      //     <div className="cut-text">
      //       {data.original.ageOption
      //         ? 0
      //         : StringUtil.newDateToMonths(
      //             data.original.birthDate,
      //             data.original.createdAt
      //           )}
      //     </div>
      //   ),
      // },
      {
        id: "sex",
        Header: "Sexo",
        sortable: true,
        maxWidth: 40,
        accessor: "sexName",
      },
      {
        id: "owner",
        Header: "Dono",
        sortable: true,
        Cell: (data) => (
          <div className="cut-text">{data.original.ownerName}</div>
        ),
      },
      {
        id: "customer",
        Header: "Cliente",
        sortable: true,
        Cell: (data) => (
          <div className="cut-text">{data.original.customerName}</div>
        ),
      },
    ],
  };

  const EXAM_REQUEST_STATUS = [
    { id: 0, name: "Nenhum" },
    ...PromiseUtil.extractValue(requestExamStates, []),
  ];

  const EXAMS = [
    { id: 0, name: "Nenhum" },
    ...PromiseUtil.extractValue(exams, []),
  ];

  const SEXS = [
    { id: 0, name: "Nenhum" },
    ...PromiseUtil.extractValue(sexs, []),
  ];

  const SPECIES = [
    { id: 0, name: "Nenhum" },
    ...PromiseUtil.extractValue(species, [], (response) => response.data),
  ];

  const CUSTOMERS = [
    { id: 0, name: "Nenhum" },
    ...PromiseUtil.extractValue(customers, []),
  ];

  return (
    <React.Fragment>
      <LifeVet.Panel>
        <LifeVet.PanelHeader title={"Buscar Solicitação"}>
          <Link to="/">
            <LifeVet.Button float="right" depth="2" variant="secondary">
              Voltar
            </LifeVet.Button>
          </Link>
        </LifeVet.PanelHeader>
        <LifeVet.PanelBody>
          <Row>
            <InputMaterial
              md={2}
              xs={12}
              id="code"
              name="code"
              title="Código"
              value={fields.code}
              onChange={(event) => fieldChange("code", event.target.value)}
              autofocus={true}
            />
            <SelectMaterial
              id="customerId"
              title="Cliente"
              md="3"
              xs={12}
              name="customerId"
              options={CUSTOMERS}
              value={fields.customerId}
              onChange={(event) => fieldChange("customerId", event)}
              disabled={shouldDisableCustomer}
              searchable
            />
            <SelectMaterial
              id="requestExamState"
              name="requestExamState"
              title="Status da Solicitação"
              options={EXAM_REQUEST_STATUS}
              value={fields.requestExamState}
              onChange={(event) => fieldChange("requestExamState", event)}
              md="3"
              xs={12}
              searchable
            />
            <SelectMaterial
              id="examId"
              name="examId"
              title="Exame"
              options={EXAMS}
              value={fields.examId}
              onChange={(event) => fieldChange("examId", event)}
              md="4"
              xs={12}
              searchable
            />
            <InputMaterial
              md={2}
              xs={12}
              id="animalName"
              name="animalName"
              title="Animal"
              value={fields.animalName}
              onChange={(event) =>
                fieldChange("animalName", event.target.value)
              }
            />
            <InputMaterial
              md={2}
              xs={12}
              id="ownerName"
              name="ownerName"
              title="Proprietário"
              value={fields.ownerName}
              onChange={(event) => fieldChange("ownerName", event.target.value)}
            />
            <SelectMaterial
              id="species"
              title="Espécie"
              md="2"
              xs={12}
              name="customerId"
              options={SPECIES}
              value={fields.specieId}
              searchable
              onChange={(event) => fieldChange("specieId", event)}
            />
            <SelectMaterial
              id="sexId"
              title="Sexo"
              md="2"
              xs={12}
              name="sexid"
              options={SEXS}
              value={fields.sexId}
              onChange={(event) => fieldChange("sexId", event)}
            />
            <InputMaterial
              md={1}
              xs={12}
              id="years"
              value={fields.years}
              name="years"
              title="Anos"
              onChange={(event) =>
                yearsMonthsChange("years", event.target.value)
              }
            />
            <InputMaterial
              md={1}
              xs={12}
              id="months"
              name="months"
              value={fields.months}
              title="Meses"
              onChange={(event) =>
                yearsMonthsChange("months", event.target.value)
              }
            />
            <Col xs={12} md={2}>
              <DatePickerMaterial
                id={"birthDate"}
                name={"birthDate"}
                title="Nascimento"
                startDate={fields.birthDate}
                maxDate={today}
              />
            </Col>
            <Col xs={12} md={2}>
              <DatePickerMaterial
                id="initialDate"
                name="initialDate"
                title="Data Inicial"
                startDate={fields.initialDate}
                onChange={(event) => fieldChange("initialDate", event)}
              />
            </Col>
            <Col xs={12} md={2}>
              <DatePickerMaterial
                id="endDate"
                name="endDate"
                title="Data Final"
                startDate={fields.endDate}
                onChange={(event) => fieldChange("endDate", event)}
              />
            </Col>
            <Col xs={12} md={8}>
              <LifeVet.Button
                float="right"
                depth="2"
                variant="danger"
                onClick={handleCleanFilters}
              >
                Limpar
              </LifeVet.Button>
              <LifeVet.Button
                float="right"
                depth="2"
                variant="primary"
                onClick={handleSubmitSearch}
              >
                Buscar
              </LifeVet.Button>
            </Col>
            <Col xs={12} className="pt-10">
              <LVTable
                data={config.data}
                loading={config.loading}
                columns={config.columns}
                pageSize={config.pageSize}
                onDoubleClickRow={config.onDoubleClickRow}
                onClickRow={config.onDoubleClickRow}
                onPageChange={onPageChange}
                page={page}
              />
            </Col>
          </Row>
        </LifeVet.PanelBody>
      </LifeVet.Panel>
    </React.Fragment>
  );
};

const withValidation = validated(examRequestSearchValidation);

export default withValidation(ExamRequestSearchListView);
