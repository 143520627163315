import React, { Component } from "react";
import Bugsnag from "@bugsnag/js";
/**
 * External
 */
import { connect } from "react-redux";

/**
 * Internal
 */
import LoginForm from "./../LoginForm";
import { loginUser } from "./../../redux/actions/";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: { username: "", password: "" },
      isSubmitDisabled: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.shouldEnableSubmit = this.shouldEnableSubmit.bind(this);
  }

  shouldEnableSubmit() {
    const hasUsername = this.state.fields.username.length > 0;
    const hasPassword = this.state.fields.password.length > 0;

    if (hasUsername && hasPassword) {
      this.setState((prevState, props) => {
        prevState.isSubmitDisabled = false;
        return prevState;
      });
    }
  }

  handleChange(event) {
    const field = event.target.name;
    const value = event.target.value;

    this.setState((prevState, props) => {
      prevState.fields[field] = value;
      return prevState;
    }, this.shouldEnableSubmit);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { username, password } = this.state.fields;
    const addUserInfoToBugsnag = (user) => {
      // console.log("user", user);
      Bugsnag.setUser(user.id, "no-email-available", user.username);
    };
    this.props.dispatch(loginUser(username, password, addUserInfoToBugsnag));
  }

  render() {
    return (
      <LoginForm
        fields={this.state.fields}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        isSubmitEnabled={this.state.IsSubmitEnabled}
        requesting={this.props.fetching}
        invalidLogin={this.props.error}
        notificationSystem={this.props.notificationSystem}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    is_authenticated: state.userReducer.is_authenticated,
    fetching: state.userReducer.fetching,
    error: state.userReducer.error,
  };
};

export default connect(mapStateToProps)(Login);
