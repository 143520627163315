import {
  Sortings,
  Pagination,
  SpecieFilters,
  BreedFilters,
} from "./../types/index";

type Filters = SpecieFilters | BreedFilters;

export const generateUrl = (
  apiRoute: string,
  filters: Filters,
  sortings: Sortings,
  pagination: Pagination
) => {
  const filtersQuery = convertObjectToQueryString(filters);
  const sortingQuery = convertObjectToQueryString(sortings);
  const paginationQuery = convertObjectToQueryString(pagination);
  return `${process.env.REACT_APP_PROXY}/${apiRoute}?${filtersQuery}${sortingQuery}${paginationQuery}`;
};

export const convertObjectToQueryString = (object: object) =>
  Object.entries(object).reduce(
    (acc, [key, value]) => (value ? acc + `${key}=${value}&` : acc),
    ``
  );
