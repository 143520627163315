import React from "react";

/** Internal */
import { preferencesModalValidation } from "./../CustomerForm/validation";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import NumberInputMaterial from "./../NumberInputMaterial";
import SelectMaterial from "./../SelectMaterial";
import { YES_OR_NO } from "../../assets/js/Consts.js";

import * as PAYMENT_FREQUENCE from "../../consts/payment-frequency.js";

/** External */
import { Row, Col, Modal } from "react-bootstrap";
import { validated } from "react-custom-validation";

const PreferenceFields = ({
  fields,
  $field,
  $fieldEvent,
  $validation,
  $submit,
  methods: {
    isUpdateing,
    fieldChange,
    addPhoneToTable,
    handleLoadPreferencesToModal,
    toggleSelectPhone,
    handleRemovePhone,
  },
  data: { displayPreferencesModal, defaultPreferences, shouldDisableAll },
}) => {
  const discountInput = (
    <NumberInputMaterial
      id="preferencesDiscount"
      name="preferencesDiscount"
      title="Desconto (%)"
      md={4}
      autofocus={true}
      value={fields.preferencesDiscount}
      fieldChange={fieldChange}
      {...$field("preferencesDiscount")}
      disabled={shouldDisableAll}
    />
  );

  const paymentFrequence = (
    <SelectMaterial
      title="Frequência do pagamento"
      md={4}
      name="preferencesPaymentFrequency"
      options={PAYMENT_FREQUENCE.values}
      value={fields.preferencesPaymentFrequency}
      searchable={true}
      {...$field("preferencesPaymentFrequency", (event) => {
        fieldChange("preferencesPaymentFrequency", event);
      })}
      disabled={shouldDisableAll}
    />
  );

  const termInput = (
    <NumberInputMaterial
      id="preferencesTerm"
      name="preferencesTerm"
      title="Prazo (Dias)"
      md={4}
      value={fields.preferencesTerm}
      fieldChange={fieldChange}
      {...$field("preferencesTerm")}
      disabled={shouldDisableAll}
    />
  );

  const defaulter = (
    <SelectMaterial
      title="Inadimplente"
      md={6}
      name="preferencesDefaulter"
      options={YES_OR_NO}
      value={fields.preferencesDefaulter}
      searchable={true}
      {...$field("preferencesDefaulter", (event) => {
        fieldChange("preferencesDefaulter", event);
      })}
      disabled={shouldDisableAll}
    />
  );

  const usesPaymentSlip = (
    <SelectMaterial
      title="Utiliza Boleto"
      md={6}
      name="preferencesUsesPaymentSlip"
      options={YES_OR_NO}
      value={fields.preferencesUsesPaymentSlip}
      searchable={true}
      {...$field("preferencesUsesPaymentSlip", (event) => {
        fieldChange("preferencesUsesPaymentSlip", event);
      })}
      disabled={shouldDisableAll}
    />
  );

  const receivesPrintReport = (
    <SelectMaterial
      title="Receber Laudo Impresso"
      md={6}
      name="preferencesReceivesPrintReport"
      options={YES_OR_NO}
      value={fields.preferencesReceivesPrintReport}
      searchable={true}
      {...$field("preferencesReceivesPrintReport", (event) => {
        fieldChange("preferencesReceivesPrintReport", event);
      })}
      disabled={shouldDisableAll}
    />
  );

  const modal = (
    <Modal
      show={displayPreferencesModal}
      onHide={() => handleLoadPreferencesToModal(defaultPreferences)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Preferências</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <h5>
              <b>Financeiro</b>
            </h5>
            <br />
          </Col>
        </Row>
        <Row>
          {discountInput}
          {termInput}
          {paymentFrequence}
          {defaulter}
          {usesPaymentSlip}
        </Row>
        <Row>
          <Col xs={12}>
            <h5>
              <b>Outros</b>
            </h5>
            <br />
          </Col>
        </Row>
        <Row>{receivesPrintReport}</Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefaultMaterial
          type="default"
          onClick={handleLoadPreferencesToModal}
          title="Confirmar"
          disabled={shouldDisableAll}
        />
      </Modal.Footer>
    </Modal>
  );

  return <React.Fragment>{modal}</React.Fragment>;
};

const withValidation = validated(preferencesModalValidation);

export default withValidation(PreferenceFields);
