import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal
 */
import ButtonDefaultMaterial from './../ButtonDefaultMaterial';

/**
 * Assets
 */
import './index.css';

/**
 * @author Victor Heringer
 *
 * Button material default stylized for back functionality
 */

/**
 * @author Victor Heringer
 *
 * Button that goes to the previous route
 */
const ButtonBackMaterial = ( {
    children,
    title,
    type,
    position,
    onClick,
    disabled
    } ) => {

    return (
      <ButtonDefaultMaterial
        onClick={ onClick }
        type={ type }
        title={ title }
        className={ position === 'top' ? 'back-top' : 'back-bottom-left' }
        disabled={ disabled }
      >
      { children }
      </ButtonDefaultMaterial>
    );

}

ButtonBackMaterial.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf( [ 'default', 'highlight' ] ),
    position: PropTypes.oneOf( [ 'top', 'bottom' ] )
}

export default ButtonBackMaterial;
