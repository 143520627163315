import React from "react";

/**
 * Internal
 */
import { customerValidation } from "./../CustomerForm/validation";
import CustomerFields from "./CustomerFields";
import UsersFields from "./UsersFields";
import EmailsFields from "./EmailsFields";
import PreferencesFields from "./PreferencesFields";
import FiscalFields from "./FiscalFields";
import PhonesFields from "./PhonesFields";
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import PanelMaterialBottom from "./../PanelMaterialBottom";
import ButtonBackMaterial from "./../ButtonBackMaterial";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";

/**
 * External
 */
import { validated } from "react-custom-validation";
import { Link } from "react-router-dom";
import { Row } from "./../../components/grid";

const CustomerFormView = ({
  methods,
  $field,
  fields,
  data,
  $submit,
  $validation
}) => {
  const onClickSave = function(handleSubmit, invalidNotification) {
    return e => {
      e.preventDefault();
      $submit(handleSubmit, invalidNotification);
    };
  };

  const saveBtn = (
    <ButtonSaveMaterial
      type="highlight"
      title={data.submit.btnTitle}
      right={true}
      onClick={onClickSave(methods.handleSubmit, methods.invalidNotification)}
      disabled={data.allDisabled}
      requesting={data.submit.pending}
      requestingTitle={data.submit.placeholder}
      disabled={data.shouldDisableAll}
    />
  );

  const backBtnTop = (
    <ButtonBackMaterial
      type="default"
      title="Voltar"
      position="top"
      disabled={data.allDisabled}
    />
  );

  const backBtnBottom = (
    <ButtonBackMaterial
      type="default"
      title="Voltar"
      position="bottom"
      disabled={data.allDisabled}
    />
  );

  const preferencesBtnTop = (
    <ButtonDefaultMaterial
      type="highlight"
      title="Preferências"
      position="top"
      onClick={methods.handleLoadPreferencesToModal}
    />
  );

  const fiscalBtnTop = (
    <ButtonDefaultMaterial
      type="highlight"
      title="Dados Fiscais"
      position="top"
      onClick={methods.handleLoadFiscalToModal}
    />
  );

  return (
    <React.Fragment>
      <PreferencesFields methods={methods} fields={fields} data={data} />
      <FiscalFields methods={methods} fields={fields} data={data} />
      <PanelMaterial>
        <PanelMaterialTop title={data.submit.panelTitle}>
          <Link to="/clientes">{backBtnTop}</Link>
          {preferencesBtnTop}
          {fiscalBtnTop}
        </PanelMaterialTop>
        <PanelMaterialBody>
          <CustomerFields
            methods={methods}
            fields={fields}
            data={data}
            $field={$field}
            $validation={$validation}
          />
          <Row>
            <hr className="mt-20 mb-20" />
          </Row>
          <Row>
            <EmailsFields methods={methods} fields={fields} data={data} />
            <PhonesFields methods={methods} fields={fields} data={data} />
          </Row>
          <Row>
            <hr className="mt-20 mb-20" />
          </Row>
          <Row>
            <UsersFields methods={methods} fields={fields} data={data} />
          </Row>
        </PanelMaterialBody>
        <PanelMaterialBottom>
          <Link to="/clientes">{backBtnBottom}</Link>
          {saveBtn}
        </PanelMaterialBottom>
      </PanelMaterial>
    </React.Fragment>
  );
};

const withValidation = validated(customerValidation);

export default withValidation(CustomerFormView);
