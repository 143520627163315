import React from "react";

import FocusTrap from "focus-trap-react";
import PropTypes from "prop-types";
import KeyHandler, { KEYPRESS } from "react-key-handler";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import close from "@fortawesome/fontawesome-free-solid/faTimes";

import { COUNTER_LIMIT, KEY_TO_FIELD } from "../../assets/js/Consts.js";
import SlideUp from "./../SlideUp";

import "./index.css";

import { SoundService } from "./../../services";
/**
 * @author Alessandro Bastos Grandini
 *
 * Converts a empty string into 0
 *
 * @param  {Mix}  value The value that will be tested
 *
 * @return {Mix}        0 if is a empty string, otherwise return the value
 */
const ifEmptyStrToZero = value => {
  return value === "" ? 0 : value;
};

const propTypes = {
  open: PropTypes.bool,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fields: PropTypes.object.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  handleFinishedPlaying: PropTypes.func.isRequired
};

const defaultProps = {
  total: 0,
  open: false,
  hasHeterophils: true
};

/**
 * @author Alessandro Bastos Grandini
 */
const HemogramCounter = ({
  open,
  total,
  fields,  
  handleKeyPress,
  handleKeyPress2,
  handleClose,
  hasHeterophils
}) => {
  const onKeyPress = event => {
    event.preventDefault();
    handleKeyPress && handleKeyPress(event);
    handleKeyPress2 && handleKeyPress2(KEY_TO_FIELD[event.key]);
  };

    /**
   * @author Sergio Alex Pallet
   * 
   * Shortcut key to counter
   *
   * @type {Array}
   */
  // const KEYS = [
  //   'a', 'A', 's', 'S', 'd', 'D', 'f', 'F',
  //   'g', 'G', 'h', 'H', 'j', 'J', 'k', 'K',
  //   'l', 'L', 'm', 'M', 'Enter'
  // ];

  /**
   * @author Alessandro Bastos Grandini
   *
   * Map the keys to handle
   */

  // const keyHandlers = KEYS.map(key => (
  //   <KeyHandler
  //     key={key}
  //     keyEventName={KEYPRESS}
  //     keyValue={key}
  //     onKeyHandle={onKeyPress}
  //   />
  // ));

  const keys = {
    basophils: {
      title: "Basófilo",
      field: fields.relativeBasophils,
      keyName: "L"
    },
    eosinophils: {
      title: "Eosinófilo",
      field: fields.relativeEosinophils,
      keyName: "D"
    },
    myelocytes: {
      title: "Mielócito",
      field: fields.relativeMyelocytes,
      keyName: "S"
    },
    bands: {
      title: "Bastão",
      field: fields.relativeBands,
      keyName: "F"
    },
    segs: {
      title: "Segmentado",
      field: fields.relativeSegs,
      keyName: "G"
    },
    lymphocytes: {
      title: "Linfócito",
      field: fields.relativeLymphocytes,
      keyName: "H"
    },
    monocytes: {
      title: "Monócito",
      field: fields.relativeMonocytes,
      keyName: "J"
    },
    heterophils: {
      title: "Heterófilo",
      field: fields.relativeHeterophils,
      keyName: "K"
    },
    metamyelocytes: {
      title: "Metamielócito",
      field: fields.relativeMetamyelocytes,
      keyName: "A"
    },
    metarrubrycites: {
      title: "Metarrubrícitos",
      field: fields.metarrubrycites,
      keyName: "M"
    }
  };

  const keysWithHeterophils = [
    keys.basophils,
    keys.eosinophils,
    keys.lymphocytes,
    keys.monocytes,
    keys.heterophils,
    keys.metarrubrycites
  ];

  const keysWithoutHeterophils = [
    keys.basophils,
    keys.eosinophils,
    keys.myelocytes,
    keys.metamyelocytes,
    keys.bands,
    keys.segs,
    keys.lymphocytes,
    keys.monocytes,
    keys.metarrubrycites
  ];

  const definedKeys = hasHeterophils
    ? keysWithHeterophils
    : keysWithoutHeterophils;
    
/**
 * 
 * @author Kayo Oliveira
 * 
 * Fixed the bug of "K" key counting data without being previously defined
 * 
 */

  const keyHandlers = definedKeys.map(key => (
    <>
    <KeyHandler
      key={key.keyName}
      keyEventName={KEYPRESS}
      keyValue={key.keyName}
      onKeyHandle={onKeyPress}
    />
    <KeyHandler
      key={key.keyName.toLowerCase()}
      keyEventName={KEYPRESS}
      keyValue={key.keyName.toLowerCase()}
      onKeyHandle={onKeyPress}
    />
    </>
  ));


  const buttons = definedKeys.map(item => (
    <div key={item.keyName} className="item">
      <button disabled>
        <p>({item.keyName})</p>
        <span>{item.title}</span>
        <p className="contagem">( {ifEmptyStrToZero(item.field)} )</p>
      </button>
    </div>
  ));
  
  const totalDisplay = ifEmptyStrToZero(total);

  const shouldAllowSound = open && totalDisplay >= COUNTER_LIMIT;

  return (
    <>
    {shouldAllowSound && (        
        SoundService.playWarningSound()
    )}    
    <SlideUp open={open}>
      {keyHandlers}
      <FocusTrap active={open}>
        <span className="close-message">Pressione Enter para Fechar</span>
        <div className="close-counter-btn">
          <button onClick={handleClose}>
            <FontAwesomeIcon icon={close} />
          </button>
        </div>
        <div className="pane">
          {buttons}
          <div className="item total">
            <button disabled>
              <p>{totalDisplay}</p>
            </button>
          </div>
        </div>
      </FocusTrap>
    </SlideUp>
    </>
  );
};

HemogramCounter.propTypes = propTypes;
HemogramCounter.defaultProps = defaultProps;

export default HemogramCounter;
