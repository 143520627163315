import React from 'react';

/**
 * @author Victor Heringer
 * 
 * Display a list with all uploaded file names
 * 
 * @prop {Array} files
 * @prop {Boolean} display
 * @prop {Object} styles
 */
const FilesNameList = ({ files, display, styles }) => {
  return (display ? 
    <div className={ styles.fileNameListWrapper }>
      {files.length == 0 && <span>Nenhum arquivo selecionado</span>}
      <ul>
        {files.map(file => <li key={file.name}><small>{file.name}</small></li>)}
      </ul>
    </div>
    : null
  );
}

export default FilesNameList;