/**
 * @author Alessandro Bastos Grandini
 *
 * Corpuscular Volume reference default object
 *
 * @return {Object}
 *
 */
export const CORPUSCULAR_VOLUME_REF_DEFAULT = {
  corpuscularVolumeMin: '',
  corpuscularVolumeMax: ''
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Erythrogram reference default object
 *
 * @return {Object}
 *
 */
export const ERYTHROGRAM_REF_DEFAULT = {
  ...CORPUSCULAR_VOLUME_REF_DEFAULT,
  erythrocytesMin:      '',
  erythrocytesMax:      '',
  hemoglobiometryMin:   '',
  hemoglobiometryMax:   '',
  mcvMin:               '',
  mcvMax:               '',
  mchcMin:              '',
  mchcMax:              ''
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Leukogram reference default object
 *
 * @return {Object}
 *
 */
export const LEUKOGRAM_REF_DEFAULT = {
  globalLeukometryMin:       '',
  globalLeukometryMax:       '',
  relativeBasophilsMin:      '',
  relativeEosinophilsMin:    '',
  relativeLymphocytesMin:    '',
  relativeMonocytesMin:      '',
  relativeMyelocytesMin:     '',
  relativeMetamyelocytesMin: '',
  relativeBandsMin:          '',
  relativeSegsMin:           '',
  relativeHeterophilsMin:    '',
  relativeBasophilsMax:      '',
  relativeEosinophilsMax:    '',
  relativeLymphocytesMax:    '',
  relativeMonocytesMax:      '',
  relativeMyelocytesMax:     '',
  relativeMetamyelocytesMax: '',
  relativeBandsMax:          '',
  relativeSegsMax:           '',
  relativeHeterophilsMax:    '',
  absoluteBasophilsMin:      '',
  absoluteEosinophilsMin:    '',
  absoluteLymphocytesMin:    '',
  absoluteMonocytesMin:      '',
  absoluteMyelocytesMin:     '',
  absoluteMetamyelocytesMin: '',
  absoluteBandsMin:          '',
  absoluteSegsMin:           '',
  absoluteHeterophilsMin:    '',
  absoluteBasophilsMax:      '',
  absoluteEosinophilsMax:    '',
  absoluteLymphocytesMax:    '',
  absoluteMonocytesMax:      '',
  absoluteMyelocytesMax:     '',
  absoluteMetamyelocytesMax: '',
  absoluteBandsMax:          '',
  absoluteSegsMax:           '',
  absoluteHeterophilsMax:    ''
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Platelets reference default object
 *
 * @return {Object}
 *
 */
export const PLATELETS_REF_DEFAULT = {
  plateletsMin: '',
  plateletsMax: '',
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Hemogram reference default object
 *
 * @return {Object}
 *
 */
export const HEMOGRAM_REF_DEFAULT = {
  ...ERYTHROGRAM_REF_DEFAULT,
  ...LEUKOGRAM_REF_DEFAULT,
  ...PLATELETS_REF_DEFAULT
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Hemogram reference default object
 *
 * @return {Object}
 *
 */
export const BLOOD_PROTEIN_REF_DEFAULT = {
  bloodProteinMin: '',
  bloodProteinMax: ''
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Hemogram reference default object
 *
 * @return {Object}
 *
 */
export const FIBRINOGEN_REF_DEFAULT = {
  fibrinogenMin: '',
  fibrinogenMax: ''
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Hemogram reference default object
 *
 * @return {Object}
 *
 */
export const CROSS_MATCH_REF_DEFAULT = {
  ...CORPUSCULAR_VOLUME_REF_DEFAULT,
  ...BLOOD_PROTEIN_REF_DEFAULT
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Coagulation test reference default object
 *
 * @return {Object}
 *
 */
export const COAGULATION_TEST_REF_DEFAULT = {
  tromboplastinTimeMin: '',
  tromboplastinTimeMax: '',
  prothrombinTimeMin: '',
  prothrombinTimeMax: ''
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Urinary GGT reference default object
 *
 * @return {Object}
 *
 */
export const URINARY_GGT_REF_DEFAULT = {
  min: '',
  max: ''
}

export const RETICULOCYTE_COUNT_REF_DEFAULT = {
  normalMin: '',
  normalMax: '',
  lowMin: '',
  lowMax: '',
  moderateMin: '',
  moderateMax: '',
  highMin: '',
  highMax: ''
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Biochemistry reference default object
 *
 * @return {Object}
 *
 */
export const BIOCHEMISTRY_REF_DEFAULT = {
  ureaMin: '',
  ureaMax: '',
  ureaUnit: '',
  altTgpMin: '',
  altTgpMax: '',
  altTgpUnit: '',
  astTgoMin: '',
  astTgoMax: '',
  astTgoUnit: '',
  ggtMin: '',
  ggtMax: '',
  ggtUnit: '',
  phMin: '',
  phMax: '',
  phUnit: '',
  chlorideMin: '',
  chlorideMax: '',
  chlorideUnit: '',
  creatinineMin: '',
  creatinineMax: '',
  creatinineUnit: '',
  alkalinePhosphataseMin: '',
  alkalinePhosphataseMax: '',
  alkalinePhosphataseUnit: '',
  totalProteinMin: '',
  totalProteinMax: '',
  totalProteinUnit: '',
  totalCholesterolMin: '',
  totalCholesterolMax: '',
  totalCholesterolUnit: '',
  albuminMin: '',
  albuminMax: '',
  albuminUnit: '',
  calciumMin: '',
  calciumMax: '',
  calciumUnit: '',
  lipaseMin: '',
  lipaseMax: '',
  lipaseUnit: '',
  amylaseMin: '',
  amylaseMax: '',
  amylaseUnit: '',
  glucoseMin: '',
  glucoseMax: '',
  glucoseUnit: '',
  sodiumMin: '',
  sodiumMax: '',
  sodiumUnit: '',
  potassiumMin: '',
  potassiumMax: '',
  potassiumUnit: '',
  magnesiumMin: '',
  magnesiumMax: '',
  magnesiumUnit: '',
  phosphorusMin: '',
  phosphorusMax: '',
  phosphorusUnit: '',
  triglyceridesMin: '',
  triglyceridesMax: '',
  triglyceridesUnit: '',
  ionicCalciumCalcium: '',
  ionicCalciumAlbumin: '',
  ionicCalciumTotalProtein: '',
  ionicCalciumIonicCalcium: '',
  ionicCalciumCalciumMin: '',
  ionicCalciumCalciumMax: '',
  ionicCalciumCalciumUnit: '',
  ionicCalciumAlbuminMin: '',
  ionicCalciumAlbuminMax: '',
  ionicCalciumAlbuminUnit: '',
  ionicCalciumTotalProteinMin: '',
  ionicCalciumTotalProteinMax: '',
  ionicCalciumTotalProteinUnit: '',
  ionicCalciumIonicCalciumMin: '',
  ionicCalciumIonicCalciumMax: '',
  ionicCalciumIonicCalciumUnit: '',
  totalProteinAndFractionsTotalProtein: '',
  totalProteinAndFractionsAlbumin: '',
  totalProteinAndFractionsGlobulin: '',
  totalProteinAndFractionsTotalProteinMin: '',
  totalProteinAndFractionsTotalProteinMax: '',
  totalProteinAndFractionsTotalProteinUnit: '',
  totalProteinAndFractionsAlbuminMin: '',
  totalProteinAndFractionsAlbuminMax: '',
  totalProteinAndFractionsAlbuminUnit: '',
  totalProteinAndFractionsGlobulinMin: '',
  totalProteinAndFractionsGlobulinMax: '',
  totalProteinAndFractionsGlobulinUnit: '',
  totalCholesterolAndFractionsTotalCholesterolMin: '',
  totalCholesterolAndFractionsTotalCholesterolMax: '',
  totalCholesterolAndFractionsTotalCholesterolUnit: '',
  totalCholesterolAndFractionsHdlMin: '',
  totalCholesterolAndFractionsHdlMax: '',
  totalCholesterolAndFractionsHdlUnit: '',
  totalCholesterolAndFractionsLdlMin: '',
  totalCholesterolAndFractionsLdlMax: '',
  totalCholesterolAndFractionsLdlUnit: '',
  totalCholesterolAndFractionsVldlMin: '',
  totalCholesterolAndFractionsVldlMax: '',
  totalCholesterolAndFractionsVldlUnit: '',
  totalCholesterolAndFractionsTriglyceridesMin: '',
  totalCholesterolAndFractionsTriglyceridesMax: '',
  totalCholesterolAndFractionsTriglyceridesUnit: '',
  bilirubinAndFractionsTotalMin: '',
  bilirubinAndFractionsTotalMax: '',
  bilirubinAndFractionsTotalUnit: '',
  bilirubinAndFractionsDirectMin: '',
  bilirubinAndFractionsDirectMax: '',
  bilirubinAndFractionsDirectUnit: '',
  bilirubinAndFractionsIndirectMin: '',
  bilirubinAndFractionsIndirectMax: '',
  bilirubinAndFractionsIndirectUnit: '',
  relationAlbuminGlobulinMin: '',
  relationAlbuminGlobulinMax: '',
  relationAlbuminGlobulinGlobulinMin: '',
  relationAlbuminGlobulinGlobulinMax: '',
  relationAlbuminGlobulinAlbuminMin: '',
  relationAlbuminGlobulinAlbuminMax: '',
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Biochemistry reference default object
 *
 * @return {Object}
 *
 */
export const CHEMILUMINESCENCE_REF_DEFAULT = {
  chemiluminescenceCortisolMin: '',
  chemiluminescenceCortisolMax: '',
  chemiluminescenceCortisolUnit: '',
  chemiluminescenceCortisol4HAfterActhStimuliMin: '',
  chemiluminescenceCortisol4HAfterActhStimuliMax: '',
  chemiluminescenceCortisol4HAfterActhStimuliUnit: '',
  chemiluminescenceCortisol4HAfterDexamethasoneMin: '',
  chemiluminescenceCortisol4HAfterDexamethasoneMax: '',
  chemiluminescenceCortisol4HAfterDexamethasoneUnit: '',
  chemiluminescenceCortisol8HAfterDexamethasoneMin: '',
  chemiluminescenceCortisol8HAfterDexamethasoneMax: '',
  chemiluminescenceCortisol8HAfterDexamethasoneUnit: '',
  chemiluminescenceErythropoetinMin: '',
  chemiluminescenceErythropoetinMax: '',
  chemiluminescenceErythropoetinUnit: '',
  chemiluminescenceEstradiolMin: '',
  chemiluminescenceEstradiolMax: '',
  chemiluminescenceEstradiolUnit: '',
  chemiluminescenceFreeT4Min: '',
  chemiluminescenceFreeT4Max: '',
  chemiluminescenceFreeT4Unit: '',
  chemiluminescenceHfsMin: '',
  chemiluminescenceHfsMax: '',
  chemiluminescenceHfsUnit: '',
  chemiluminescenceHypersensitiveActhMin: '',
  chemiluminescenceHypersensitiveActhMax: '',
  chemiluminescenceHypersensitiveActhUnit: '',
  chemiluminescenceInsulinMin: '',
  chemiluminescenceInsulinMax: '',
  chemiluminescenceInsulinUnit: '',
  chemiluminescenceLevothyroxinAfterTotalT4Min: '',
  chemiluminescenceLevothyroxinAfterTotalT4Max: '',
  chemiluminescenceLevothyroxinAfterTotalT4Unit: '',
  chemiluminescenceLhMin: '',
  chemiluminescenceLhMax: '',
  chemiluminescenceLhUnit: '',
  chemiluminescencePhenobarbitalMin: '',
  chemiluminescencePhenobarbitalMax: '',
  chemiluminescencePhenobarbitalUnit: '',
  chemiluminescenceProgesteroneMin: '',
  chemiluminescenceProgesteroneMax: '',
  chemiluminescenceProgesteroneUnit: '',
  chemiluminescencePthMin: '',
  chemiluminescencePthMax: '',
  chemiluminescencePthUnit: '',
  chemiluminescenceTestosteroneMin: '',
  chemiluminescenceTestosteroneMax: '',
  chemiluminescenceTestosteroneUnit: '',
  chemiluminescenceTotalT3Min: '',
  chemiluminescenceTotalT3Max: '',
  chemiluminescenceTotalT3Unit: '',
  chemiluminescenceTotalT4Min: '',
  chemiluminescenceTotalT4Max: '',
  chemiluminescenceTotalT4Unit: '',
  chemiluminescenceTshMin: '',
  chemiluminescenceTshMax: '',
  chemiluminescenceTshUnit: ''
}