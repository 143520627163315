export const GENERAL_ADMINISTRATOR = 1;
export const UNIT_ADMINISTRATOR = 2;
export const UNIT_USER = 3;
export const CLINIC_ADMINISTRATOR = 4;
export const CLINIC_USER = 5;

export const values = [
  { id: 1, name: "Administrador Geral" },
  { id: 2, name: "Administrador da Unidade" },
  { id: 3, name: "Usuário da Unidade" },
  { id: 4, name: "Administrador da Clínica" },
  { id: 5, name: "Usuário da Clínica" }
];

export const CLINIC_VALUES = [
  { id: 4, name: "Administrador da Clínica" },
  { id: 5, name: "Usuário da Clínica" }
];

export const toString = id => {
  switch (parseInt(id)) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return values[id - 1].name;
      break;
    default:
      return "Perfil de acesso inválido.";
  }
};
