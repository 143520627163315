import React from "react";
import FadeIn from "react-fade-in";
import { Modal, Row, Col } from "react-bootstrap";
import * as LifeVet from "../../components";
import { Table } from "react-bootstrap";
import { BounceLoader } from "react-spinners";

function renderTable({ getTableProps, getTableBodyProps, headerGroups, rows, prepareRow }) {
  return (
    <Table
        {...getTableProps()}
        condensed
        bordered
        hover
        striped
        className={"text-center"}
      >
        <thead>
          {
            headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {
                        column.render("Header")
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {
                    row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            cell.render("Cell")
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </Table>
  );
}

const EmailRecordsView = ({
  values,
  isSubmitting,
  handleSubmit,
  customers,
  tableInstance,
  handleChange,
  setFieldValue,
  cleanValues,
  requestExamStates,
  dateRef,
  tableIsLoading,
  tableIsFullfield,
  errors,
  touched,
  handleBlur
}) => {
  

  return (
    <FadeIn>
      <LifeVet.Panel>
        <LifeVet.PanelHeader title="Verificação e-mail solicitação">
          <div>.</div>
        </LifeVet.PanelHeader>
        <LifeVet.PanelBody>
          <Row>
            <Col sm={2}>
              <LifeVet.FormGroup
               errorMessage={touched.date && errors.date}
               >
                <LifeVet.InputLabel>Data</LifeVet.InputLabel>
                <LifeVet.Input
                  name="date"
                  type="date"
                  id="date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.date}
                  disabled={isSubmitting}
                  innerRef={dateRef}
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={4}>
              <LifeVet.FormGroup
              >
                <LifeVet.InputLabel>Status</LifeVet.InputLabel>
                <LifeVet.Select
                  name="examRequestState"
                  onChange={(values, field) => {
                    setFieldValue(field.name, values.id);
                  }}
                  value={values.examRequestState}
                  options={requestExamStates}
                  disabled={isSubmitting}
                  closeOnSelect={true}
                  theValueIsAnId
                />
              </LifeVet.FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <LifeVet.FormGroup>
                <LifeVet.InputLabel>Cliente</LifeVet.InputLabel>
                <LifeVet.Select
                  name="customer"
                  value={values.customer}
                  options={customers}
                  searchable={true}
                  disabled={isSubmitting}
                  multi={false}
                  closeOnSelect={true}
                  onChange={(values, field) => {
                    setFieldValue(field.name, values.id);
                  }}
                  theValueIsAnId
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={1} className="mt-15">
              <LifeVet.Button
                float="right"
                type="submit"
                depth="2"
                variant="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Buscar
              </LifeVet.Button>
            </Col>
            <Col sm={1} className="mt-15">
              <LifeVet.Button
                float="right"
                type="submit"
                depth="2"
                variant="secondary"
                onClick={cleanValues}
                disabled={isSubmitting}
              >
                Limpar
              </LifeVet.Button>
            </Col>
          </Row>
          <Row>
            {tableIsLoading &&
              <BounceLoader color="#00B4AD" />
            } 
            {tableIsFullfield && renderTable(tableInstance) }
          </Row>
        </LifeVet.PanelBody>
        <LifeVet.PanelFooter>
          <LifeVet.Button
            float="right"
            type="submit"
            depth="2"
            variant="primary"
            disabled={true}
            title="Esta funcionalidade estará disponível em breve."
            onClick={handleSubmit}
          >
            Imprimir
          </LifeVet.Button>
        </LifeVet.PanelFooter>
      </LifeVet.Panel>
    </FadeIn>
  );
};

export default EmailRecordsView;
