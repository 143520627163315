export const values = [
  { id: 1, name: "Em Aberto" },
  { id: 2, name: "Em Análise" },
  { id: 3, name: "Em Desenvolvimento" },
  { id: 4, name: "Concluído" }
];

export const IN_PROGRESS = 1;
export const IN_ANALYSIS = 2;
export const IN_DEVELOPMENT = 3;
export const COMPLETED = 4;

export const toString = id => {
  switch (parseInt(id)) {
    case 1:
    case 2:
    case 3:
    case 4:
      return values[id - 1].name;
      break;
    default:
      return "Status de ticket inválido.";
  }
};
