/**
 * @var {Array}
 */
export const values = [
  { id: 0, name: "PAGO" },
  { id: 1, name: "VENCIDO" },
  { id: 2, name: "EM ABERTO" }
];

export const toString = id => {
  switch (parseInt(id)) {
    case 0:
    case 1:
    case 2:
      return values[id].name;
      break;
    default:
      return "Status da fatura inválido.";
  }
};
