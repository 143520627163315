import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import {
  required,
  maxLength
} from '../../assets/js/validationFunctions';

import {
  commonReportRules
} from '../../assets/js/validationRules';

import { OBS_VALIDATION_MAX_LENGTH, REQUIRED_VALIDATION_MESSAGE } from '../../assets/js/Consts.js';

const validation = validated( props => {
  const {
    protein,
    creatinine,
    obs,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const {completeValidation} = props.data;

  let fields = [
    'protein',
    'creatinine',
    'obs',
    'requestExamState',
    'vetSignerId'
  ];

  const commonValidation = commonReportRules( vetSignerId, requestExamState );

  const validation = {
    protein: {
      rules: completeValidation ? [
        [ required, protein, REQUIRED_VALIDATION_MESSAGE ]
      ] : []
    },
    creatinine: {
      rules: completeValidation ? [
        [ required, creatinine, REQUIRED_VALIDATION_MESSAGE ]
      ] : []
    },
    obs: {
      rules: completeValidation ? [
        [ maxLength, obs, OBS_VALIDATION_MAX_LENGTH ]
      ] : []
    }
  }

  const validations = { ...commonValidation, ...validation };

  return { fields, validations };
} );

export default validation;