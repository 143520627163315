import React from "react";
import styled from "styled-components";
import { rose, blackberry } from "./../colors";
import { mainFont } from "./../fonts";

const StyledLabel = styled.label`
  ${mainFont}
  color: ${props => hasErrorColor(props)};
`;

const hasErrorColor = ({ hasError }) => {
  return hasError ? rose : blackberry;
};

const InputLabel = props => (
  <StyledLabel {...props}>{props.children}</StyledLabel>
);

InputLabel.displayName = "input-label";

export default InputLabel;
