import React from "react";
import styled from "styled-components";

const StyledPanelFooter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  margin-top: -25px;
  justify-content: flex-end;
`;

const PanelFooter = props => (
  <StyledPanelFooter {...props}>{props.children}</StyledPanelFooter>
);

export default PanelFooter;
