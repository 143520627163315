import React from "react";

/**
 * External
 */
import PropTypes from "prop-types";
import { Row, Col } from "./../../components/grid";
import NumberDisplay from "./../NumberDisplay";

/**
 * Internal
 */
import NumberInputMaterial2 from "./../NumberInputMaterial2";

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const NumberInputWithReference2 = ({
  fieldTitle,
  value,
  fieldName,
  shouldDisable,
  hasValidation,
  validationReason,
  handleChange,
  handleBlur,
  valueMin,
  valueMax,
  decimalScale,
  shouldOmitRefTitle,
  unit,
  suffix
}) => (
  <div className="no-padding-cols distante-rows">
    <Row>
      <div className="col-xs-12">
        <div className="col-xs-5 col-xs-offset-3 text-center">
          <p className="cleanItem"></p>
        </div>
        <div className="col-xs-4 text-center">
          <p className="cleanItem">
            {shouldOmitRefTitle ? "" : "Valor Normal"}
          </p>
        </div>
      </div>
    </Row>
    <Row>
      <div className="col-xs-4 text-right">
        <p className="mr-20 cleanItem">{fieldTitle}</p>
      </div>
      <Col xs={4}>
        <NumberInputMaterial2
          name={ fieldName }
          inputGroup={ true }
          inputGroupAfter={ unit } 
          hasValidationRecoil={ true }
          decimalScale={ decimalScale }
          fit={ true }
          block={ false }
          inputGroup={ true }
          allowNegative={ false }
          value={ value }
          disabled={ shouldDisable }
          handleChange={ handleChange }
          handleBlur={ handleBlur }
          hasValidation={ hasValidation }
          validationReason={ validationReason }
          hasValidationRecoil={ false } />
      </Col>
      <Col xs={4}>
        <Col xs={6} className="text-right cleanItem">
          <p className="mr-20">
            <NumberDisplay value={valueMin} decimalScale={decimalScale} />
          </p>
        </Col>
        <Col xs={6} className="text-left cleanItem">
          <p className="ml-5">
            <NumberDisplay value={valueMax} decimalScale={decimalScale} suffix={suffix} />
          </p>
        </Col>
      </Col>
    </Row>
  </div>
);

NumberInputWithReference2.propTypes = propTypes;

export default NumberInputWithReference2;