import React from "react";

/**
 * Internal
 */
import TextAreaPanel2 from "./../TextAreaPanel2";
import ReportHeader from "./../ReportHeader";
import ReticulocyteCountPanel from "./../ReticulocyteCountPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import ReticulocyteNormalFractionPanel from "./../ReticulocyteNormalFractionPanel";
import { Col, Row } from "./../../components/grid";

const ReticulocyteCountReportFormView = ({
  data: {
    refs,
    isUpdate,
    fields,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    reportHeaderData,
    shouldSendEmail,
  },
  methods: {
    handleChange,
    handleBlur,
    fieldChange,
    onSubmit,
    handleSendReportEmail,
    $field,
    calcReticulocytePercent,
    calcAbsoluteErythrocyteCount,
    calcTotalReticulocyte,
    calcReticulocyteCorrection,
    cancelReport,
    stopReport,
  },
}) => (
  <ReportPanelMaterial
    title="Contagem de Reticulócitos"
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    handleSendReportEmail={handleSendReportEmail}
    shouldSendEmail={shouldSendEmail}
    cancelReport={cancelReport}
    stopReport={stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <ReticulocyteCountPanel
          panelTitle={"Resultado"}
          reticulocyteByField={fields.reticulocyteByField}
          reticulocyteByFieldName={"reticulocyteByField"}
          reticulocyteByFieldTitle={"Ret. por Campo"}
          reticulocyteByFieldHasValidation={
            $validation.reticulocyteByField.show
          }
          reticulocyteByFieldValidationReason={
            $validation.reticulocyteByField.error.reason
          }
          erythrocyteCount={fields.erythrocyteCount}
          erythrocyteCountName={"erythrocyteCount"}
          erythrocyteCountTitle={"Nº de Hemácias"}
          erythrocyteCountHasValidation={$validation.erythrocyteCount.show}
          erythrocyteCountValidationReason={
            $validation.erythrocyteCount.error.reason
          }
          corpuscularVolume={fields.corpuscularVolume}
          corpuscularVolumeName={"corpuscularVolume"}
          corpuscularVolumeTitle={"Vol. Globular"}
          corpuscularVolumeHasValidation={$validation.corpuscularVolume.show}
          corpuscularVolumeValidationReason={
            $validation.corpuscularVolume.error.reason
          }
          reticulocytePercent={calcReticulocytePercent()}
          reticulocytePercentName={"reticulocytePercent"}
          reticulocytePercentTitle={"% de Ret."}
          absoluteErythrocyteCount={calcAbsoluteErythrocyteCount()}
          absoluteErythrocyteCountName={"absoluteErythrocyteCount"}
          absoluteErythrocyteCountTitle={"Nº de Hemácias Abs"}
          totalReticulocyte={calcTotalReticulocyte()}
          totalReticulocyteName={"totalReticulocyte"}
          totalReticulocyteTitle={"Total de Retic."}
          totalReticulocyte={calcTotalReticulocyte()}
          totalReticulocyteName={"totalReticulocyte"}
          totalReticulocyteTitle={"Total de Ret."}
          reticulocyteCorrection={calcReticulocyteCorrection()}
          reticulocyteCorrectionName={"reticulocyteCorrection"}
          reticulocyteCorrectionTitle={"Correção de Ret."}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
      <Col md={6}>
        <ReticulocyteNormalFractionPanel
          normalMin={refs.normalMin}
          normalMax={refs.normalMax}
          lowMin={refs.lowMin}
          lowMax={refs.lowMax}
          moderateMin={refs.moderateMin}
          moderateMax={refs.moderateMax}
          highMin={refs.highMin}
          highMax={refs.highMax}
          unit={"mg/dL"}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <TextAreaPanel2
          title={"Obs"}
          fieldTitle={""}
          name={"obs"}
          value={fields.obs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default ReticulocyteCountReportFormView;
