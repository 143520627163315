export const initialData = {
  titles: {
    Albumina: "albumin",
    Amilase: "amylase",
    "ALT (TGP)": "altTgp",
    "AST (TGO)": "astTgo",
    Cálcio: "calcium",
    "Cálcio Iônico - Cálcio": "ionicCalciumCalcium",
    "Cálcio Iônico - Albumina": "ionicCalciumAlbumin",
    "Cálcio Iônico - Proteínas Totais": "ionicCalciumTotalProtein",
    "Cálcio Iônico": "ionicCalciumIonicCalcium",
    CPK: "cpk",
    Cloretos: "chloride",
    "Colesterol Total": "totalCholesterol",

    "Colesterol Total e Frações - Colesterol Total":
      "totalCholesterolAndFractionsTotalCholesterol",
    "Colesterol Total e Frações - HDL": "totalCholesterolAndFractionsHdl",

    "Colesterol Total e Frações - LDL": "totalCholesterolAndFractionsLdl",
    "Colesterol Total e Frações - VLDL": "totalCholesterolAndFractionsVldl",
    "Colesterol Total e Frações - Triglicerides":
      "totalCholesterolAndFractionsTriglycerides",
    Creatinina: "creatinine",
    Ferro: "iron",
    "Fosfatáse Alcalina": "alkalinePhosphatase",
    Fósforo: "phosphorus",
    Frutosamina: "fructosamine",
    "Gama GT": "ggt",
    Glicose: "glucose",
    Lactato: "lactate",
    Lipase: "lipase",
    Magnésio: "magnesium",
    Potássio: "potassium",
    "Proteínas Totais": "totalProtein",
    "Proteinas Totais e Frações - Proteinas Totais":
      "totalProteinAndFractionsTotalProtein",
    "Proteinas Totais e Frações - Albumina": "totalProteinAndFractionsAlbumin",
    "Proteinas Totais e Frações - Globulina":
      "totalProteinAndFractionsGlobulin",
    Sódio: "sodium",
    Triglicerideos: "triglycerides",
    "Hemoglobina Glicosilada": "glycatedHemoglobin",
    Uréia: "urea",
    "Ácido Úrico": "uricAcid",
    "Bilirrubina e Frações - Total": "bilirubinAndFractionsTotal",
    "Bilirrubina e Frações - Direto": "bilirubinAndFractionsDirect",
    "Bilirrubina e Frações - Indireto": "bilirubinAndFractionsIndirect",
    LDH: "ldh",
    "Lipase Imunoreativa": "immunoreactiveLipase",
    PH: "ph",
    TLI: "tli",
  },
  fields: {
    albuminMin: "",
    albuminMax: "",
    albuminUnit: "",
    amylaseMin: "",
    amylaseMax: "",
    amylaseUnit: "",
    altTgpMin: "",
    altTgpMax: "",
    altTgpUnit: "",
    astTgoMin: "",
    astTgoMax: "",
    astTgoUnit: "",
    calciumMin: "",
    calciumMax: "",
    calciumUnit: "",
    ionicCalciumCalciumMin: "",
    ionicCalciumCalciumMax: "",
    ionicCalciumCalciumUnit: "",
    ionicCalciumAlbuminMin: "",
    ionicCalciumAlbuminMax: "",
    ionicCalciumAlbuminUnit: "",
    ionicCalciumTotalProteinMin: "",
    ionicCalciumTotalProteinMax: "",
    ionicCalciumTotalProteinUnit: "",
    ionicCalciumIonicCalciumMin: "",
    ionicCalciumIonicCalciumMax: "",
    ionicCalciumIonicCalciumUnit: "",
    cpkMin: "",
    cpkMax: "",
    cpkUnit: "",
    chlorideMin: "",
    chlorideMax: "",
    chlorideUnit: "",
    totalCholesterolMin: "",
    totalCholesterolMax: "",
    totalCholesterolUnit: "",
    totalCholesterolAndFractionsTotalCholesterolMin: "",
    totalCholesterolAndFractionsTotalCholesterolMax: "",
    totalCholesterolAndFractionsTotalCholesterolUnit: "",
    totalCholesterolAndFractionsHdlMin: "",
    totalCholesterolAndFractionsHdlMax: "",
    totalCholesterolAndFractionsHdlUnit: "",
    totalCholesterolAndFractionsLdlMin: "",
    totalCholesterolAndFractionsLdlMax: "",
    totalCholesterolAndFractionsLdlUnit: "",
    totalCholesterolAndFractionsVldlMin: "",
    totalCholesterolAndFractionsVldlMax: "",
    totalCholesterolAndFractionsVldlUnit: "",
    totalCholesterolAndFractionsTriglyceridesMin: "",
    totalCholesterolAndFractionsTriglyceridesMax: "",
    totalCholesterolAndFractionsTriglyceridesUnit: "",
    creatinineMin: "",
    creatinineMax: "",
    creatinineUnit: "",
    ironMin: "",
    ironMax: "",
    ironUnit: "",
    alkalinePhosphataseMin: "",
    alkalinePhosphataseMax: "",
    alkalinePhosphataseUnit: "",
    phosphorusMin: "",
    phosphorusMax: "",
    phosphorusUnit: "",
    fructosamineMin: "",
    fructosamineMax: "",
    fructosamineUnit: "",
    ggtMin: "",
    ggtMax: "",
    ggtUnit: "",
    glucoseMin: "",
    glucoseMax: "",
    glucoseUnit: "",
    lactateMin: "",
    lactateMax: "",
    lactateUnit: "",
    lipaseMin: "",
    lipaseMax: "",
    lipaseUnit: "",
    magnesiumMin: "",
    magnesiumMax: "",
    magnesiumUnit: "",
    potassiumMin: "",
    potassiumMax: "",
    potassiumUnit: "",
    totalProteinMin: "",
    totalProteinMax: "",
    totalProteinUnit: "",
    totalProteinAndFractionsTotalProteinMin: "",
    totalProteinAndFractionsTotalProteinMax: "",
    totalProteinAndFractionsTotalProteinUnit: "",
    totalProteinAndFractionsAlbuminMin: "",
    totalProteinAndFractionsAlbuminMax: "",
    totalProteinAndFractionsAlbuminUnit: "",
    totalProteinAndFractionsGlobulinMin: "",
    totalProteinAndFractionsGlobulinMax: "",
    totalProteinAndFractionsGlobulinUnit: "",
    sodiumMin: "",
    sodiumMax: "",
    sodiumUnit: "",
    triglyceridesMin: "",
    triglyceridesMax: "",
    triglyceridesUnit: "",
    glycatedHemoglobinMin: "",
    glycatedHemoglobinMax: "",
    glycatedHemoglobinUnit: "",
    ureaMin: "",
    ureaMax: "",
    ureaUnit: "",
    uricAcidMin: "",
    uricAcidMax: "",
    uricAcidUnit: "",
    bilirubinAndFractionsTotalMin: "",
    bilirubinAndFractionsTotalMax: "",
    bilirubinAndFractionsTotalUnit: "",
    bilirubinAndFractionsDirectMin: "",
    bilirubinAndFractionsDirectMax: "",
    bilirubinAndFractionsDirectUnit: "",
    bilirubinAndFractionsIndirectMin: "",
    bilirubinAndFractionsIndirectMax: "",
    bilirubinAndFractionsIndirectUnit: "",
    ldhMin: "",
    ldhMax: "",
    ldhUnit: "",
    immunoreactiveLipaseMin: "",
    immunoreactiveLipaseMax: "",
    immunoreactiveLipaseUnit: "",
    phMin: "",
    phMax: "",
    phUnit: "",
    tliMin: "",
    tliMax: "",
    tliUnit: "",
  },
};
