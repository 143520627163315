import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import HemogramPanel from "./../HemogramPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import ParvoCoronaPanel from "./../ParvoCoronaPanel";
import ParasitologicalFreshFecesPanel from "./../ParasitologicalFreshFecesPanel";
import DefaultMolecularBiologyPanel from "./../DefaultMolecularBiologyPanel";
import FecesCoprocytologicalPanel from "./../FecesCoprocytologicalPanel";
import UroculturePanel from "./../UroculturePanel";

/**
 * External
 */
import { Col, Row } from "./../../components/grid";
import DefaultMicrobiologyContent from "../DefaultMicrobiologyContent";

const ProfileGastroenteritisGoldReportFormView = ({
  title,
  data: {
    refs,
    total,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    fecesColorOptionsPromise,
    fecesSmellOptionsPromise,
    fecesAspectOptionsPromise,
    fecesConsistencyOptionsPromise,
    reportHeaderData,
    antibioticOptions,
    materialsSuggestions,
    checkboxes,
    parasitologicalFreshFecesResult,
    checkdState,
    reportData,
  },
  methods: {
    $field,
    fieldChange,
    onSubmit,
    calcMCHC,
    calcMCV,
    erythrogramDiagnosis,
    plateletsDiagnosis,
    calcAbsolute,
    resetLeukogram,
    onClickCounter,
    leukogramDiagnosis,
    toggleIntenseLeucopeny,
    finalGlobalLeukogram,
    handleKeyPress,
    handleFinishedPlaying,
    calcMolecularBiologyResult,
    closeCounter,
    handleChange,
    handleBlur,
    toggleIsNegative,
    prepareAntibioticTagsSuggestionFilter,
    getQuantitativeResult,
    materialSuggestionsFilter,
    cancelReport,
    stopReport,
    handleSendReportEmail,
    handleFocus,
    setRef,
    multiBoxChange,
    handleCheckedResultChange,
  },
}) => (
  <ReportPanelMaterial
    title={title}
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    cancelReport={cancelReport}
    stopReport={stopReport}
    handleSendReportEmail={handleSendReportEmail}
    handleFocus={handleFocus}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <HemogramPanel
          fields={fields}
          refs={refs}
          disableAll={disableAll}
          $validation={$validation}
          total={total}
          $field={$field}
          fieldChange={fieldChange}
          calcMCV={calcMCV}
          calcMCHC={calcMCHC}
          calcAbsolute={calcAbsolute}
          resetLeukogram={resetLeukogram}
          onClickCounter={onClickCounter}
          toggleIntenseLeucopeny={toggleIntenseLeucopeny}
          finalGlobalLeukogram={finalGlobalLeukogram}
          erythrogramDiagnosis={erythrogramDiagnosis}
          leukogramDiagnosis={leukogramDiagnosis}
          plateletsDiagnosis={plateletsDiagnosis}
          handleKeyPress={handleKeyPress}
          handleFinishedPlaying={handleFinishedPlaying}
          closeCounter={closeCounter}
          setRef={setRef}
          reportData={reportData}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ParvoCoronaPanel
          title="Resultado"
          materialId={fields.parvoCoronaMaterialId}
          isParvovirus={fields.parvoCoronaIsParvovirus}
          isCoronavirus={fields.parvoCoronaIsCoronavirus}
          materialIdFieldName="parvoCoronaMaterialId"
          isParvovirusFieldName="parvoCoronaIsParvovirus"
          isCoronavirusFieldName="parvoCoronaIsCoronavirus"
          materialIdHasValidation={$validation.parvoCoronaMaterialId.show}
          isParvovirusHasValidation={$validation.parvoCoronaIsParvovirus.show}
          isCoronavirusHasValidation={$validation.parvoCoronaIsCoronavirus.show}
          materialIdValidationReason={
            $validation.parvoCoronaMaterialId.error.reason
          }
          isParvovirusValidationReason={
            $validation.parvoCoronaIsParvovirus.error.reason
          }
          isCoronavirusValidationReason={
            $validation.parvoCoronaIsCoronavirus.error.reason
          }
          obsFieldName="parvoCoronaObs"
          obs={fields.parvoCoronaObs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ParasitologicalFreshFecesPanel
          title="Exame Macroscópico"
          fecesColorOptionId={
            fields.parasitologicalFreshFecesFecesColorOptionId
          }
          fecesColorOptionIdFieldName="parasitologicalFreshFecesFecesColorOptionId"
          fecesColorOptions={
            fecesColorOptionsPromise.fulfilled
              ? fecesColorOptionsPromise.value
              : []
          }
          isFecesColorOptionsLoading={true === fecesColorOptionsPromise.pending}
          fecesColorOptionIdHasValidation={
            $validation &&
            $validation.parasitologicalFreshFecesFecesColorOptionId.show
          }
          fecesColorOptionIdValidationReason={
            $validation &&
            $validation.parasitologicalFreshFecesFecesColorOptionId.error.reason
          }
          fecesSmellOptionId={
            fields.parasitologicalFreshFecesFecesSmellOptionId
          }
          fecesSmellOptionIdFieldName="parasitologicalFreshFecesFecesSmellOptionId"
          fecesSmellOptions={
            fecesSmellOptionsPromise.fulfilled
              ? fecesSmellOptionsPromise.value
              : []
          }
          isFecesSmellOptionsLoading={true === fecesSmellOptionsPromise.pending}
          fecesSmellOptionIdHasValidation={
            $validation.parasitologicalFreshFecesFecesSmellOptionId.show
          }
          fecesSmellOptionIdValidationReason={
            $validation.parasitologicalFreshFecesFecesSmellOptionId.error.reason
          }
          fecesAspectOptionId={
            fields.parasitologicalFreshFecesFecesAspectOptionId
          }
          fecesAspectOptionIdFieldName="parasitologicalFreshFecesFecesAspectOptionId"
          fecesAspectOptions={
            fecesAspectOptionsPromise.fulfilled
              ? fecesAspectOptionsPromise.value
              : []
          }
          isFecesAspectOptionsLoading={
            true === fecesAspectOptionsPromise.pending
          }
          fecesAspectOptionIdHasValidation={
            $validation.parasitologicalFreshFecesFecesAspectOptionId.show
          }
          fecesAspectOptionIdValidationReason={
            $validation.parasitologicalFreshFecesFecesAspectOptionId.error
              .reason
          }
          fecesConsistencyOptionId={
            fields.parasitologicalFreshFecesFecesConsistencyOptionId
          }
          fecesConsistencyOptionIdFieldName="parasitologicalFreshFecesFecesConsistencyOptionId"
          fecesConsistencyOptions={
            fecesConsistencyOptionsPromise.fulfilled
              ? fecesConsistencyOptionsPromise.value
              : []
          }
          isFecesConsistencyOptionsLoading={
            true === fecesConsistencyOptionsPromise.pending
          }
          fecesConsistencyOptionIdHasValidation={
            $validation.parasitologicalFreshFecesFecesConsistencyOptionId.show
          }
          fecesConsistencyOptionIdValidationReason={
            $validation.parasitologicalFreshFecesFecesConsistencyOptionId.error
              .reason
          }
          macroscopicParasitesFieldName={
            "parasitologicalFreshFecesMacroscopicParasites"
          }
          macroscopicParasites={
            fields.parasitologicalFreshFecesMacroscopicParasites
          }
          macroscopicParasitesHasValidation={
            $validation.parasitologicalFreshFecesMacroscopicParasites.show
          }
          macroscopicParasitesValidationReason={
            $validation.parasitologicalFreshFecesMacroscopicParasites.error
              .reason
          }
          resultFieldName="parasitologicalFreshFecesResult"
          result={fields.parasitologicalFreshFecesResult}
          resultHasValidation={$validation.parasitologicalFreshFecesResult.show}
          resultValidationReason={
            $validation.parasitologicalFreshFecesResult.error.reason
          }
          obsFieldName="parasitologicalFreshFecesObs"
          obs={fields.parasitologicalFreshFecesObs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
          parasitologicalFreshFecesResult={parasitologicalFreshFecesResult}
          checkdState={checkdState}
          checkboxes={checkboxes}
          multiBoxChange={multiBoxChange}
          handleCheckedResultChange={handleCheckedResultChange}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <DefaultMicrobiologyContent
          title="Coprocultura"
          materialFieldName="coprocultureMaterial"
          material={fields.coprocultureMaterial}
          materialHasValidation={$validation.coprocultureMaterial.show}
          materialValidationReason={
            $validation.coprocultureMaterial.error.reason
          }
          isNegativeFieldName="coprocultureIsNegative"
          isNegative={fields.coprocultureIsNegative}
          resultFieldName="coprocultureResult"
          result={fields.coprocultureResult}
          resultHasValidation={
            $validation.coprocultureResult &&
            $validation.coprocultureResult.show
          }
          resultValidationReason={
            $validation.coprocultureResult &&
            $validation.coprocultureResult.error.reason
          }
          antibioticOptions={antibioticOptions}
          sensitiveTagsFieldName={"coprocultureSensitiveAntibioticTags"}
          sensitiveTags={fields.coprocultureSensitiveAntibioticTags}
          sensitiveHasValidation={
            $validation.coprocultureSensitiveAntibioticTags &&
            $validation.coprocultureSensitiveAntibioticTags.show
          }
          sensitiveValidationReason={
            $validation.coprocultureSensitiveAntibioticTags &&
            $validation.coprocultureSensitiveAntibioticTags.error.reason
          }
          intermediateTagsFieldName={"coprocultureIntermediateAntibioticTags"}
          intermediateTags={fields.coprocultureIntermediateAntibioticTags}
          intermediateHasValidation={
            $validation.coprocultureIntermediateAntibioticTags &&
            $validation.coprocultureIntermediateAntibioticTags.show
          }
          intermediateValidationReason={
            $validation.coprocultureIntermediateAntibioticTags &&
            $validation.coprocultureIntermediateAntibioticTags.error.reason
          }
          resistantTagsFieldName={"coprocultureResistantAntibioticTags"}
          resistantTags={fields.coprocultureResistantAntibioticTags}
          resistantHasValidation={
            $validation.coprocultureResistantAntibioticTags &&
            $validation.coprocultureResistantAntibioticTags.show
          }
          resistantValidationReason={
            $validation.coprocultureResistantAntibioticTags &&
            $validation.coprocultureResistantAntibioticTags.error.reason
          }
          obsFieldName="coprocultureObs"
          obs={fields.coprocultureIsNegative ? "" : fields.coprocultureObs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
          toggleIsNegative={toggleIsNegative}
          getQuantitativeResult={getQuantitativeResult}
          suggestionsFilter={prepareAntibioticTagsSuggestionFilter(
            fields.coprocultureSensitiveAntibioticTags,
            fields.coprocultureIntermediateAntibioticTags,
            fields.coprocultureResistantAntibioticTags
          )}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <DefaultMolecularBiologyPanel
          title={"PCR Cinomose"}
          materialsFieldName={"distemperMolecularBiologyMaterials"}
          materials={fields.distemperMolecularBiologyMaterials}
          materialsSuggestions={materialsSuggestions}
          materialsSuggestionsFilter={materialSuggestionsFilter}
          materialsHasValidation={
            $validation.distemperMolecularBiologyMaterials.show
          }
          materialsValidationReason={
            $validation.distemperMolecularBiologyMaterials.error.reason
          }
          techniqueFieldName="distemperTechnique"
          technique={fields.distemperTechnique}
          techniqueHasValidation={$validation.distemperTechnique.show}
          techniqueValidationReason={
            $validation.distemperTechnique.error.reason
          }
          qualitativeResultFieldName="distemperQualitativeResult"
          qualitativeResult={fields.distemperQualitativeResult}
          qualitativeResultHasValidation={
            $validation.distemperQualitativeResult.show
          }
          qualitativeResultValidationReason={
            $validation.distemperQualitativeResult.error.reason
          }
          copyValuesFieldName="distemperCopyValues"
          copyValues={fields.distemperCopyValues}
          copyValuesHasValidation={$validation.distemperCopyValues.show}
          copyValuesValidationReason={
            $validation.distemperCopyValues.error.reason
          }
          precisionFieldName="distemperPrecision"
          precision={fields.distemperPrecision}
          precisionHasValidation={$validation.distemperPrecision.show}
          precisionValidationReason={
            $validation.distemperPrecision.error.reason
          }
          resultFieldName="result"
          result={calcMolecularBiologyResult(
            fields.distemperCopyValues,
            fields.distemperPrecision,
            fields.distemperTechnique
          )}
          obsFieldName="distemperObs"
          obs={fields.distemperObs}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FecesCoprocytologicalPanel
          fieldName="fecesCoprocytologicalResult"
          result={fields.fecesCoprocytologicalResult}
          shouldDisable={disableAll}
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasValidation={$validation.fecesCoprocytologicalResult.show}
          validationReason={
            $validation.fecesCoprocytologicalResult.error.reason
          }
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default ProfileGastroenteritisGoldReportFormView;
