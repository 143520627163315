import __update from "immutability-helper";
import { RequestSample, Action } from "./../../types/index";

export const ACTIONS = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  RESET: "RESET",
};

const add = (
  value: Array<RequestSample>,
  target: Array<RequestSample>
): Array<RequestSample> => {
  const commands = {
    $push: value,
  };
  return __update(target, commands);
};

const update = (
  value: RequestSample,
  target: Array<RequestSample>
): Array<RequestSample> => {
  const newValue: Array<RequestSample> = target.map((curr) =>
    curr.sampleId === value.sampleId ? value : curr
  );
  const commands = {
    $set: newValue,
  };
  return __update(target, commands);
};

const remove = (
  value: RequestSample,
  target: Array<RequestSample>
): Array<RequestSample> => {
  const newValue: Array<RequestSample> = target.filter(
    (curr) => curr.sampleId !== value.sampleId
  );
  const commands = {
    $set: newValue,
  };
  return __update(target, commands);
};

const reset = (target: Array<RequestSample>): Array<RequestSample> => {
  const commands = {
    $set: [],
  };
  return __update(target, commands);
};

export const reducer = (state: Array<RequestSample>, action: Action) => {
  switch (action.type) {
    case ACTIONS.ADD:
      return add(action.payload, state);
    case ACTIONS.UPDATE:
      return update(action.payload, state);
    case ACTIONS.REMOVE:
      return remove(action.payload, state);
    case ACTIONS.RESET:
      return reset(state);
    default:
      return state;
  }
};
