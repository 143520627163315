import React from "react";
import Sidebar from "./sidebar";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  padding: 15px;
`;

const Skeleton = ({ children }) => (
  <Wrapper>
    <Sidebar />
    <Content>{children}</Content>
  </Wrapper>
);
export default Skeleton;
