import { styled, keyframes } from "@stitches/react";

const slideUp = keyframes({
  from: {
    transform: "translateY(100%)",
  },
  to: {
    transform: "translateY(0)",
  },
});
const slideDown = keyframes({
  from: {
    transform: "translateY(0)",
  },
  to: {
    transform: "translateY(100%)",
  },
});

export const Modal = styled("div", {
  position: "fixed",
  width: "95vw",
  margin: "0 auto",
  left: "2.5vw",
  minHeight: "10rem",
  bottom: "0",
  zIndex: "9999999",
  animation: `${slideUp} 0.3s ease-in-out`,
  background: "#fff",
  border: "2px solid #00678F",
  borderTopRightRadius: "0.5rem",
  display: "flex",
  flexDirection: "column",
});
export const CloseModal = styled("button", {
  border: "none",
  position: "absolute",
  top: 0,
  right: 0,
  color: "#fff",
  borderBottomLeftRadius: "0.5rem",
  padding: "0.5rem 0.8rem",
  outline: "none",
  background: "#00678F",
  transition: "0.2s",
  "&:hover": {
    background: "#02978F",
  },
});
export const ModalHeader = styled("span", {
  position: "absolute",
  top: "-2.8rem",
  left: "-0.2rem",
  backgroundColor: "#00678F",
  color: "#FFF",
  padding: "0.5rem",
  borderTopRightRadius: "0.5rem",
  borderTopLeftRadius: "0.5rem",
  borderTop: "2px solid #00678F",
  borderRight: "2px solid #00678F",
  borderLeft: "2px solid #00678F",
  textTransform: "uppercase",
});

export const ModalContent = styled("div", {
  flex: 1,
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  width: "100%",
});

export const Input = styled("input", {
  padding: "0.5rem",
  display: "block",
  width: "80px",
  textAlign: "center",

  height: "80px",
  outline: "none",
  background: "#fff",
  border: "2px solid #00678F",
  borderRadius: "0.5rem",
  fontSize: "2.5rem",
  color: "#00678F",
  "&:focus": {
    border: "2px solid #02978F",
  },
});

export const ModalContentLeft = styled("div", {
  flex: 1,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "1rem",
  alignItems: "center",
  justifyContent: "flex-start",
});
export const ModalContentRight = styled("div", {
  width: "20%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& span": {
    textAlign: "center",
    fontSize: "2rem",
  },
});

export const ModalFooter = styled("div", {
  width: "100%",
  background: "rgba(0,0,0,0.1)",
  padding: "0.4rem",
  textAlign: "center",
});
