import React, { Component } from "react";
import { EXAM_TYPES } from "../../consts";

/** Internal */
import {
  EXAM_ID_BY_NAME,
  REQUEST_EXAM_STATE_ID_BY_NAME,
} from "../../assets/js/Consts.js";
import BiochemistryReportFormView from "./../BiochemistryReportFormView";
import validation from "./validation.js";
import connectWithEndpoint from "./requests.js";
import { BounceLoader } from "react-spinners";

import {
  examTableHeaderFactory,
  biochemistryFactory,
  calculateLdl,
  calculateVldl,
  calculateGlobulin,
  calculateIonicCalcium,
  calculateBilirubinIndirect,
} from "../../assets/js/reportFunctions.js";

import reportCommonConnectWithEndpoint from "./../../reportCommonRequests";

import {
  handleSendReportEmail,
  successNotification,
  invalidNotification,
} from "../../assets/js/containerFunctions.js";

/** External */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";

const API_URL = process.env.REACT_APP_PROXY;

const View = ({ data, methods, $field, $validation, $submit, $fieldEvent }) => {
  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;

    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeValidationControls(false, release, then)
      : methods.changeValidationControls(true, release, then);
  };

  const handleBlur = (event) => {
    $fieldEvent("blur", event.target.name);
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    handleBlur: handleBlur,
    $field: $field,
    ...methods,
  };

  return <BiochemistryReportFormView data={newData} methods={newMethods} />;
};

const ValidatedView = validation(View);

class BiochemistryReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      submitActionIsRelease: false,
      fields: {
        options: [],
        vetSignerId: 0,
      },
      shouldSendEmail: false,
    };
    this.state.fields.biochemObs = "";
    this.isUpdate = this.isUpdate.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
    this.redirectToList = this.redirectToList.bind(this);
    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);
    this.resultChange = this.resultChange.bind(this);
    this.stateChange = this.stateChange.bind(this);
    this.multiFieldResultChange = this.multiFieldResultChange.bind(this);
    this.toggleIonicCalciumEditable =
      this.toggleIonicCalciumEditable.bind(this);
    this.makeIonicCalciumToggable = this.makeIonicCalciumToggable.bind(this);
    this.examTableHeaderData = this.examTableHeaderData.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
    this.shouldDisplayLoader = this.shouldDisplayLoader.bind(this);
  }

  componentDidMount() {
    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const examRequestId = this.props.match.params.examRequestId;

        const urls = [
          `${API_URL}/biochemistry/reports/${examRequestId}`,
          `${API_URL}/reference-values/?examRequestId=${examRequestId}&examTypeId=${EXAM_TYPES.BIOCHEMISTRY}`,
        ];

        const allRequests = urls.map((url) =>
          fetch(url).then((response) => response.json())
        );

        Promise.all(allRequests).then(([reports, refs]) => {
          this.fillFields(reports, refs);
        });
      }
    };
    
    this.props.getRequestExamsWithExamRequestId(then);
  }

  handleChange = (field, value) => {
    this.setState((state) =>
      update(state, {
        fields: {
          [field]: { $set: value },
        },
      })
    );
  };

  changeField(idx, subfield, value) {
    this.setState((state) =>
      update(state, {
        fields: {
          ["options"]: {
            [idx]: {
              [subfield]: { $set: value },
            },
          },
        },
      })
    );
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes sample code state
   *
   * @param {integer} idx The index of the request sample
   * in the request sample state array
   * @param {subfield} subfield
   * @param {mixed} value The new value
   *
   */
  multiFieldResultChange(field, value) {
    const infoArray = field.split("-");
    const idx = infoArray[1];
    const subfield = infoArray[2];
    this.changeField(idx, subfield, value);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes sample code state
   *
   */
  resultChange(field, value) {
    const idx = field.replace("result-", "");
    const subfield = "result";
    this.changeField(idx, subfield, value);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes request status id
   *
   */
  stateChange(field, value) {
    const idx = field.replace("result-", "");
    const subfield = "requestExamState";
    this.changeField(idx, subfield, value);
  }

  makeIonicCalciumToggable(idx) {
    return () => {
      this.toggleIonicCalciumEditable(idx);
    };
  }

  toggleIonicCalciumEditable(idx) {
    const subfield = "isResultEditable";
    const option = this.state.fields.options[idx];
    const isResultEditable = option.isResultEditable;
    this.changeField(idx, subfield, !isResultEditable);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Activates state that redirects to list
   *
   */
  redirectToList() {
    this.setState((state) => update(state, { redirectToList: { $set: true } }));
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChange(field, value) {
    this.setState((state) =>
      update(state, { fields: { [field]: { $set: value } } })
    );
  }

  getVetSignerId(reports) {
    return reports.length > 0 ? reports[0].vetSignerId : 0;
  }

  addReportData(options, reports) {
    return options.map((option) => {
      const found = reports.find((report) => report.examId === option.examId);

      if (found) {
        option.id = found.id;
        option.requestExamState = found.requestExamState;
        option.obs = found.obs;
        switch (option.examId) {
          case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]: {
            option.total = this.formatResult(found.total);
            option.direct = this.formatResult(found.direct);
            break;
          }
          case EXAM_ID_BY_NAME["IONIC_CALCIUM"]: {
            const report = reports.find(
              (report) => report.isResultEditable != undefined
            );
            option.result = this.formatResult(found.result);
            option.calcium = this.formatResult(found.calcium);
            option.albumin = this.formatResult(found.albumin);
            option.totalProtein = this.formatResult(found.totalProtein);
            option.isResultEditable =
              report.isResultEditable === 1 ? true : false;
          }
          case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]: {
            option.totalProtein = this.formatResult(found.totalProtein);
            option.albumin = this.formatResult(found.albumin);
            option.globulin = this.formatResult(found.globulin);
            break;
          }
          case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]: {
            option.totalCholesterol = this.formatResult(found.totalCholesterol);
            option.hdl = this.formatResult(found.hdl);
            option.triglycerides = this.formatResult(found.triglycerides);
            break;
          }
          case EXAM_ID_BY_NAME["ELECTROLYTES"]: {
            const report = reports.find(
              (report) => report.isIonicCalciumEditable != undefined
            );
            option.sodium = this.formatResult(found.sodium);
            option.potassium = this.formatResult(found.potassium);
            option.phosphorus = this.formatResult(found.phosphorus);
            option.chloride = this.formatResult(found.chloride);
            option.calcium = this.formatResult(found.ionicCalciumCalcium);
            option.totalProtein = this.formatResult(
              found.ionicCalciumTotalProtein
            );
            option.albumin = this.formatResult(found.ionicCalciumAlbumin);
            option.ionicCalcium = this.formatResult(
              found.ionicCalciumIonicCalcium
            );
            option.isResultEditable = report.isIonicCalciumEditable;

            break;
          }
          default: {
            option.result = this.formatResult(found.result);
          }
        }
      }

      return option;
    });
  }

  formatResult = (value) => {
    switch (typeof value) {
      case "string":
        return value.toString();
      case "number":
        return value;
      default:
        return "";
    }
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fillFields(reports, refs) {
    const vetSignerId = this.getVetSignerId(reports);
    const requestExams = [...this.props.requestExamsWithExamRequestId.value];
    let options = requestExams.map((requestExam) => {
      return biochemistryFactory(requestExam, refs);
    });

    if (reports.length > 0) {
      options = this.addReportData(options, reports);
    }

    this.setState((state) =>
      update(state, {
        fields: {
          options: { $set: options },
          vetSignerId: { $set: vetSignerId },
          biochemObs: { $set: options[0].obs },
        },
        shouldSendEmail: {
          $set: false,
        },
      })
    );
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];

    this.handleSubmit(false, false, false, requestExamState);
  };

  changeValidationControls = (
    completeValidation,
    submitActionIsRelease,
    then
  ) => {
    const toUpdate = {
      completeValidation: { $set: completeValidation },
      submitActionIsRelease: { $set: submitActionIsRelease },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  /**
   *
   * @author Alessandro Bastos Grandini
   *
   * Submits hemogram report form data
   *
   * @param {Object} event The submit event
   *
   * @return {boolean}
   *
   */
  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const { options, vetSignerId, biochemObs } = this.state.fields;
    const parcialSave = !this.state.completeValidation;
    let completeOptions = options.map((option) => {
      const isBilirubinAndFractions =
        option.examId === EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"];
      const isIonicCalcium = option.examId === EXAM_ID_BY_NAME["IONIC_CALCIUM"];
      const isTotalCholesterolAndFractions =
        option.examId === EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"];

      const isElectrolytes = option.examId === EXAM_ID_BY_NAME["ELECTROLYTES"];

      if (isBilirubinAndFractions) {
        option.indirect = calculateBilirubinIndirect(
          option.total,
          option.direct
        );
      }
      option.obs = biochemObs;
      if (isElectrolytes) {
        if (!option.isResultEditable) {
          option.result = calculateIonicCalcium(
            option.calcium,
            option.albumin,
            option.totalProtein
          );
          option.ionicCalcium = option.result;
        } else {
          option.result = option.ionicCalcium;
        }
      }

      if (isIonicCalcium) {
        if (!option.isResultEditable) {
          option.result = calculateIonicCalcium(
            option.calcium,
            option.albumin,
            option.totalProtein
          );
        }
      }

      if (isTotalCholesterolAndFractions) {
        option.ldl = calculateLdl(
          option.totalCholesterol,
          option.hdl,
          option.triglycerides
        );

        option.vldl = calculateVldl(option.triglycerides);
      }
      option.vetSignerId = vetSignerId;
      option.parcialSave = parcialSave;
      return option;
    });

    if (!this.state.completeValidation) {
      completeOptions = this.changeDefaultExamValues(completeOptions);
    }
    if (requestExamState > 0) {
      for (let i in completeOptions) {
        completeOptions[i].requestExamState = requestExamState;
      }
    }
    const isUpdate = this.isUpdate();
    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };
    if (cancel) {
      this.props.cancelReport(completeOptions, then);
    } else if (stop) {
      this.props.stopReport(completeOptions, then);
    } else {
      this.props.post(completeOptions, release, then);
    }
  };

  changeDefaultExamValues = (obj) => {
    for (let i in obj) {
      switch (obj[i].examId) {
        case EXAM_ID_BY_NAME["IONIC_CALCIUM"]:
          obj[i].albumin = this.formatEmptyValue(obj[i].albumin);
          obj[i].globulin = this.formatEmptyValue(obj[i].globulin);
          obj[i].totalProtein = this.formatEmptyValue(obj[i].totalProtein);
          obj[i].calcium = this.formatEmptyValue(obj[i].calcium);

        case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]:
          obj[i].direct = this.formatEmptyValue(obj[i].direct);
          obj[i].total = this.formatEmptyValue(obj[i].total);

        case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]:
          obj[i].albumin = this.formatEmptyValue(obj[i].albumin);
          obj[i].totalProtein = this.formatEmptyValue(obj[i].totalProtein);
        case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]:
          obj[i].albumin = this.formatEmptyValue(obj[i].albumin);
          obj[i].globulin = this.formatEmptyValue(obj[i].globulin);
          obj[i].totalProtein = this.formatEmptyValue(obj[i].totalProtein);
          obj[i].calcium = this.formatEmptyValue(obj[i].calcium);

        case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]:
          obj[i].hdl = this.formatEmptyValue(obj[i].hdl);
          obj[i].ldl = this.formatEmptyValue(obj[i].ldl);
          obj[i].totalCholesterol = this.formatEmptyValue(
            obj[i].totalCholesterol
          );
          obj[i].triglycerides = this.formatEmptyValue(obj[i].triglycerides);
          obj[i].vldl = this.formatEmptyValue(obj[i].vldl);

        case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]:
          obj[i].albumin = this.formatEmptyValue(obj[i].albumin);
          obj[i].globulin = this.formatEmptyValue(obj[i].globulin);
          obj[i].totalProtein = this.formatEmptyValue(obj[i].totalProtein);
          obj[i].calcium = this.formatEmptyValue(obj[i].calcium);

        default:
          obj[i].result = this.formatEmptyValue(obj[i].result);
          break;
      }
    }
    return obj;
  };

  formatEmptyValue = (value) => {
    return value === "" || value === 0 ? null : value;
  };

  emptyValues = (...args) => {
    let isEmpty = false;
    for (let i = 0; i < args.length; i++) {
      if (args[i] === 0 || args[i] === null || args[i] === "") {
        isEmpty = true;
        break;
      }
    }
    return isEmpty;
  };
  isUpdate() {
    const options = this.state.fields.options;

    for (const option of options) {
      if (option.id > 0) {
        return true;
      }
    }

    return false;
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Sorts report header promise into
   * a data object
   *
   * @return {Object}
   *
   */
  examTableHeaderData() {
    const { examTableReportHeaderPromise } = this.props;

    return examTableReportHeaderPromise.fulfilled
      ? examTableReportHeaderPromise.value
      : null;
  }

  requestExamStatesData(requestExamStates) {
    return requestExamStates ? requestExamStates.value : [];
  }

  shouldDisplayLoader() {
    const { requestExamsWithExamRequestId } = this.props;

    const areReqExamsLoaded =
      requestExamsWithExamRequestId !== undefined &&
      requestExamsWithExamRequestId.fulfilled;
    return !areReqExamsLoaded;
  }

  render() {
    const { examRequestId, requestExamId } = this.props.match.params;    
    const backLink = "/solicitacoes-exame/" + examRequestId;
    // console.log("this.props.requestExamsWithExamRequestId:", this.props.requestExamsWithExamRequestId);

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    // Loader
    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    const { submitPromise, requestExamStates } = this.props;

    const methods = {
      fieldChange: this.fieldChange,
      handleChange: this.handleChange,
      handleSubmit: this.handleSubmit,
      successNotificatio: this.successNotification,
      invalidNotification: this.invalidNotification,
      resultChange: this.resultChange,
      stateChange: this.stateChange,
      calculateBilirubinIndirect: calculateBilirubinIndirect,
      calculateIonicCalcium: calculateIonicCalcium,
      multiFieldResultChange: this.multiFieldResultChange,
      makeIonicCalciumToggable: this.makeIonicCalciumToggable,
      calculateLdl: calculateLdl,
      calculateVldl: calculateVldl,
      calculateGlobulin: calculateGlobulin,
      handleSendReportEmail: this.handleSendReportEmail,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      attendAndNotRelease: this.attendAndNotRelease,
      cancelReport: this.cancelReport,
      stopReport: this.stopReport,
      changeValidationControls: this.changeValidationControls,
    };

    const submit = {
      isSet: submitPromise !== undefined,
      isPending: submitPromise && submitPromise.pending,
    };

    const examTableHeaderData = this.examTableHeaderData();

    const data = {
      shouldSendEmail: this.state.shouldSendEmail,
      requestExamId: requestExamId,
      backLink: backLink,
      text: this.state.text,
      fields: this.state.fields,
      submit: submit,
      examRequestId: this.props.match.params.examRequestId,
      disableAll: submit.isSet,
      submitPromise: submitPromise,
      isUpdate: this.isUpdate(),
      examTableHeaderData: examTableHeaderFactory(examTableHeaderData),
      completeValidation: this.state.completeValidation,
      submitActionIsRelease: this.state.submitActionIsRelease,
      requestExamStates: this.requestExamStatesData(requestExamStates),
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(
  reportCommonConnectWithEndpoint,
  connectWithEndpoint
)(BiochemistryReportForm);
