import React, { useReducer } from "react";
import { RequestSample } from "./../../types/index";
import { reducer, ACTIONS } from "./reducer";

const defaultRequestSamples: Array<RequestSample> = [];

export const useRequestSamples = () => {
  const [requestSamples, dispatch] = useReducer(reducer, defaultRequestSamples);
  const data = requestSamples;
  const methods = {
    add: (requestSample: Array<RequestSample>) =>
      dispatch({ type: ACTIONS.ADD, payload: requestSample }),
    update: (requestSample: RequestSample) =>
      dispatch({ type: ACTIONS.UPDATE, payload: requestSample }),
    remove: (id: number) => dispatch({ type: ACTIONS.REMOVE, payload: id }),
    get: (id: number) => requestSamples.filter((curr) => curr.id === id).pop(),
    getWithSampleId: (sampleId: number) =>
      requestSamples.filter((curr) => curr.sampleId === sampleId).pop(),
    reset: () => dispatch({ type: ACTIONS.RESET }),
  };

  return {
    data,
    methods,
  };
};
