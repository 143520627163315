import React, { useEffect, useState } from "react";
import * as LifeVet from "../../../../../components/index";
import { Animal } from "../../../../../types/index";
import { SEX } from "../../../../../assets/js/Consts.js";
import { StringUtil } from "./../../../../../useful";

export const AnimalSection = ({
  animal,
  errors,
  touched,
  disabled,
  openAnimalModal,
}: {
  animal: Animal;
  errors: any;
  touched: any;
  disabled: boolean;
  openAnimalModal: () => void;
}) => {
  const [years, setYears] = useState("0");
  const [months, setMonths] = useState("0");

  useEffect(() => {
    setYears(StringUtil.dateToYears(animal.birthDate) || "");
    setMonths(StringUtil.dateToMonths(animal.birthDate) || "");
  }, [animal.birthDate]);

  return (
    <>
      <LifeVet.Row>
        <LifeVet.Col md={3}>
          <LifeVet.Button
            variant="secondary"
            disabled={disabled}
            onClick={openAnimalModal}
          >
            Buscar / Adicionar Animal
          </LifeVet.Button>
          {touched && errors && (
            <LifeVet.ErrorMessage>
              É obrigatório selecionar um animal.
            </LifeVet.ErrorMessage>
          )}
        </LifeVet.Col>
        <LifeVet.Col md={3}>
          <LifeVet.InputLabel>Nome do Animal</LifeVet.InputLabel>
          <LifeVet.Input
            value={animal.name}
            disabled={true}
            type="text"
            onChange={null}
            onBlur={null}
            innerRef={null}
            name={""}
            isViewOnly={false}
            errorMessage={""}
          />
        </LifeVet.Col>

        <LifeVet.Col md={3}>
          <LifeVet.InputLabel>Espécie</LifeVet.InputLabel>
          <LifeVet.Input
            disabled={true}
            type="text"
            value={animal.specie.name}
            onChange={null}
            onBlur={null}
            innerRef={null}
            name={""}
            isViewOnly={false}
            errorMessage={""}
          />
        </LifeVet.Col>

        <LifeVet.Col md={3}>
          <LifeVet.InputLabel>Raça</LifeVet.InputLabel>
          <LifeVet.Input
            disabled={true}
            type="text"
            value={animal.breed.name}
            onChange={null}
            onBlur={null}
            innerRef={null}
            name={""}
            isViewOnly={false}
            errorMessage={""}
          />
        </LifeVet.Col>
      </LifeVet.Row>
      <br />
      <LifeVet.Row>
        <LifeVet.Col md={3}>
          <LifeVet.InputLabel>Sexo</LifeVet.InputLabel>
          <br />
          <LifeVet.Radio
            name={"sexId"}
            checked={SEX.M === animal.sexId}
            disabled={true}
            value="M"
          />
          <LifeVet.InputLabel className={"ml-5 mr-5"}>M</LifeVet.InputLabel>
          <LifeVet.Radio
            name={"sexId"}
            checked={SEX.F === animal.sexId}
            disabled={true}
            value="F"
          />
          <LifeVet.InputLabel className={"ml-5 mr-5"}>F</LifeVet.InputLabel>
          <LifeVet.Radio
            name={"sexId"}
            checked={SEX.U === animal.sexId}
            disabled={true}
            value="U"
          />
          <LifeVet.InputLabel className={"ml-5 mr-5"}>U</LifeVet.InputLabel>
        </LifeVet.Col>

        <LifeVet.Col md={3}>
          <LifeVet.InputLabel>Anos</LifeVet.InputLabel>
          <LifeVet.Input
            disabled={true}
            type="text"
            value={years}
            onChange={null}
            onBlur={null}
            innerRef={null}
            name={""}
            isViewOnly={false}
            errorMessage={""}
          />
        </LifeVet.Col>

        <LifeVet.Col md={3}>
          <LifeVet.InputLabel>Meses</LifeVet.InputLabel>
          <LifeVet.Input
            disabled={true}
            type="text"
            value={months}
            onChange={null}
            onBlur={null}
            innerRef={null}
            name={""}
            isViewOnly={false}
            errorMessage={""}
          />
        </LifeVet.Col>

        <LifeVet.Col md={3}>
          <LifeVet.InputLabel>Proprietário</LifeVet.InputLabel>
          <LifeVet.Input
            disabled={true}
            type="text"
            value={animal.owner}
            onChange={null}
            onBlur={null}
            innerRef={null}
            name={""}
            isViewOnly={false}
            errorMessage={""}
          />
        </LifeVet.Col>
      </LifeVet.Row>
    </>
  );
};
