import {
  Animal,
  Request,
  RequestExam,
  RequestSample,
} from "../../../types/index";

export const RequestConversor = (request: Request): any => {
  return {
    id: request.id,
    customerId: request.customer.id,
    animalId: request.animal.id,
    speciesId: request.animal.specie.id,
    breedId: request.animal.breed.id,
    sexId: request.animal.sexId,
    name: request.animal.name,
    birth: request.animal.birthDate,
    years: -1,
    months: -1,
    owner: request.animal.owner,
    clinicalReport: request.clinicalReport,
    vetId: request.vet.id,
    examTypeId: -1,
    requestExams: request.exams.map((requestExam) =>
      RequestExamConversor(requestExam)
    ),
    requestSamples: request.samples.map((requestSample) =>
      RequestSampleConversor(requestSample)
    ),
  };
};

export const RequestExamConversor = (newRequestExam: RequestExam): object => {
  return {
    id: newRequestExam.id,
    name: newRequestExam.name,
    final_price: newRequestExam.price,
    price: newRequestExam.price,
    final_dead_line: newRequestExam.deadlineInDays,
    deadline_in_days: newRequestExam.deadlineInDays,
    obs: newRequestExam.obs,
    payment_method: newRequestExam.paymentMethod ? newRequestExam.paymentMethod.id : 0,
    is_paid: newRequestExam.isPaid,
    request_exam_state: newRequestExam.status,
    start_date_time: newRequestExam.startDate,
    attachmentId: newRequestExam.attachmentId,
    lab_exam: { id: newRequestExam.exam.id, exam: { id: -1 } },
  };
};

export const RequestSampleConversor = (
  newRequestSample: RequestSample
): object => {
  return {
    id: newRequestSample.id,
    code: newRequestSample.code,
    sample: { id: newRequestSample.sampleId },
  };
};

export const AnimalConversor = (newAnimal: Animal): any => {
  return {
    id: newAnimal.id,
    name: newAnimal.name,
    phone: newAnimal.phone,
    owner: newAnimal.owner,
    cpf: newAnimal.cpf,
    externalCode: newAnimal.externalCode,
    microchipCode: newAnimal.microchipCode,
    customerId: newAnimal.customer.id,
    sexId: newAnimal.sexId,
    birthDate: newAnimal.birthDate,
    speciesId: newAnimal.specie.id,
    breedId: newAnimal.breed.id,
  };
};
