import React, { Component } from "react";

/**
 * Internal
 */
import EasReportFormView from "./../EasReportFormView";
import connectWithEndpoint from "./requests.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import validation from "./validation.js";

import { TIME_LENGTHS, REQUEST_EXAM_STATES } from "./../../consts";

/*
  THE Consts.js FILE MUST BE ELIMINATED!
  ALL CONSTS SHALL BE PLACED IN consts/ DIRECTORY.
  */
import {
  EXAM_ID_BY_NAME,
  REQUEST_EXAM_STATE_ID_BY_NAME,
} from "../../assets/js/Consts.js";

import {
  fieldToggle,
  suggestionsFilter,
} from "../../assets/js/reportFunctions.js";
import Notifications from "react-notification-system-redux";
import { connect as reduxConnect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";

import {
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";

/**
 * External
 */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";

const View = ({ data, methods, $field, $validation, $submit, $fieldEvent }) => {
  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;
    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };
  const handleChange = (field, value) => {
    $fieldEvent("change", field);
    methods.fieldChange(field, value);
  };

  const handleBlur = (event) => {
    $fieldEvent("blur", event.target.name);
  };

  const handleToggle = (field) => {
    methods.fieldToggle(field);
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    handleChange: handleChange,
    handleBlur: handleBlur,
    handleToggle: handleToggle,
    onSubmit: onSubmit,
    $field: $field,
    ...methods,
  };

  return (
    <>
      <EasReportFormView data={newData} methods={newMethods} />;
      <Notifications notifications={methods.notifications} />
    </>
  );
};

const ValidatedView = validation(View);

class EasReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        id: props.match.params.id ? props.match.params.id : 0,
        volume: "",
        colorOptionId: 0,
        smellOptionId: 0,
        aspectOptionId: 0,
        density: "",
        proteinsOptionId: 0,
        glucoseOptionId: 0,
        acetoneOptionId: 0,
        ph: "",
        bilirubinOptionId: 0,
        urobilinogenOptionId: 0,
        hemoglobinOptionId: 0,
        leukocyteOptionId: 0,
        nitriteOptionId: 0,
        epithelialCellsOptionTags: [],
        ridgesOptionTags: [],
        castsOptionTags: [],
        erythrocytesMin: "",
        erythrocytesMax: "",
        isErythrocytesAbsent: false,
        isErythrocytesUncountable: false,
        pusCellsMin: "",
        pusCellsMax: "",
        isPusCellsAbsent: false,
        isPusCellsUncountable: false,
        bacterialFloraOptionId: 0,
        spermatozoaOptionId: 0,
        obs: "",
        reportDataId: 0,
        vetSignerId: 0,
        requestExamState: REQUEST_EXAM_STATES.IN_PROGRESS,
      },
      redirectToList: false,
    };

    this.isUpdate = this.isUpdate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fieldToggle = fieldToggle.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
    this.createObj = this.createObj.bind(this);
    this.redirectToList = this.redirectToList.bind(this);
    this.invalidNotification = this.invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const { fields } = this.state;

    if (fields.id > 0) {
      const then = (data, xhr) => {
        if (xhr.response.status === 200) {
          const obj = data;
          this.fillFields(obj);
        }
      };

      this.props.withId(fields.id, then);
    }
  }

  redirectToList() {
    this.setState({ redirectToList: true });
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChange(field, value) {
    this.setState((state) =>
      update(state, { fields: { [field]: { $set: value } } })
    );
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fillFields(obj) {
    this.setState((state) =>
      update(state, {
        fields: {
          volume: { $set: obj.volume },
          colorOptionId: { $set: obj.colorOptionId },
          smellOptionId: { $set: obj.smellOptionId },
          aspectOptionId: { $set: obj.aspectOptionId },
          density: { $set: obj.density },
          proteinsOptionId: { $set: obj.proteinsOptionId },
          glucoseOptionId: { $set: obj.glucoseOptionId },
          acetoneOptionId: { $set: obj.acetoneOptionId },
          ph: { $set: obj.ph },
          bilirubinOptionId: { $set: obj.bilirubinOptionId },
          urobilinogenOptionId: { $set: obj.urobilinogenOptionId },
          hemoglobinOptionId: { $set: obj.hemoglobinOptionId },
          leukocyteOptionId: { $set: obj.leukocyteOptionId },
          nitriteOptionId: { $set: obj.nitriteOptionId },
          epithelialCellsOptionTags: { $set: obj.epithelialCellsOptionTags },
          ridgesOptionTags: { $set: obj.ridgesOptionTags },
          castsOptionTags: { $set: obj.castsOptionTags },
          erythrocytesMin: { $set: obj.erythrocytesMin },
          erythrocytesMax: { $set: obj.erythrocytesMax },
          isErythrocytesAbsent: { $set: obj.isErythrocytesAbsent },
          isErythrocytesUncountable: { $set: obj.isErythrocytesUncountable },
          pusCellsMin: { $set: obj.pusCellsMin },
          pusCellsMax: { $set: obj.pusCellsMax },
          isPusCellsAbsent: { $set: obj.isPusCellsAbsent },
          isPusCellsUncountable: { $set: obj.isPusCellsUncountable },
          bacterialFloraOptionId: { $set: obj.bacterialFloraOptionId },
          spermatozoaOptionId: { $set: obj.spermatozoaOptionId },
          obs: { $set: obj.obs },
          reportDataId: { $set: obj.reportDataId },
          vetSignerId: { $set: obj.vetSignerId },
          requestExamState: { $set: obj.requestExamState },
        },
      })
    );
  }

  invalidNotification() {
    this.props.errorNotification({
      title: "Atenção, dados incompletos!",
      message: "Verifique os destaques em vermelho.",
      position: "tr",
      autoDismiss: TIME_LENGTHS.FIVE_SECONDS,
    });
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes field state based on change event
   * and input name equal to the name of
   * the state variable
   *
   * @param {object} field The change event object
   */
  onChange(event) {
    const field = event.target.name;
    const value = event.target.value;
    this.fieldChange(field, value);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Creates exam object
   *
   * @param {object} field The exam object
   */
  createObj() {
    const { fields } = this.state;
    const requestExamId = this.props.match.params.requestExamId;

    const erythrocytesMin =
      fields.isErythrocytesAbsent || fields.isErythrocytesUncountable
        ? ""
        : fields.erythrocytesMin;
    const erythrocytesMax =
      fields.isErythrocytesAbsent || fields.isErythrocytesUncountable
        ? ""
        : fields.erythrocytesMax;
    const pusCellsMin =
      fields.isPusCellsAbsent || fields.isPusCellsUncountable
        ? ""
        : fields.pusCellsMin;
    const pusCellsMax =
      fields.isPusCellsAbsent || fields.isPusCellsUncountable
        ? ""
        : fields.pusCellsMax;
    return {
      id: fields.id,
      examId: EXAM_ID_BY_NAME["EAS"],
      requestExamId: requestExamId,
      volume: fields.volume,
      colorOptionId: fields.colorOptionId,
      smellOptionId: fields.smellOptionId,
      aspectOptionId: fields.aspectOptionId,
      density: fields.density,
      proteinsOptionId: fields.proteinsOptionId,
      glucoseOptionId: fields.glucoseOptionId,
      acetoneOptionId: fields.acetoneOptionId,
      ph: fields.ph,
      bilirubinOptionId: fields.bilirubinOptionId,
      urobilinogenOptionId: fields.urobilinogenOptionId,
      hemoglobinOptionId: fields.hemoglobinOptionId,
      leukocyteOptionId: fields.leukocyteOptionId,
      nitriteOptionId: fields.nitriteOptionId,
      epithelialCellsOptionTags: fields.epithelialCellsOptionTags,
      erythrocytesMin: erythrocytesMin,
      erythrocytesMax: erythrocytesMax,
      isErythrocytesAbsent: fields.isErythrocytesAbsent,
      isErythrocytesUncountable: fields.isErythrocytesUncountable,
      pusCellsMin: pusCellsMin,
      pusCellsMax: pusCellsMax,
      isPusCellsAbsent: fields.isPusCellsAbsent,
      isPusCellsUncountable: fields.isPusCellsUncountable,
      pusCells: fields.pusCells,
      ridgesOptionTags: fields.ridgesOptionTags,
      castsOptionTags: fields.castsOptionTags,
      bacterialFloraOptionId: fields.bacterialFloraOptionId,
      spermatozoaOptionId: fields.spermatozoaOptionId,
      obs: fields.obs,
      reportDataId: fields.reportDataId,
      vetSignerId: fields.vetSignerId,
      requestExamState: fields.requestExamState,
    };
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (!this.state.completeValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" || obj[i] == 0 ? null : obj[i];
      }
    }

    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  isUpdate() {
    return this.state.fields.id > 0;
  }

  render() {
    const { redirectToList } = this.state;
    const {
      refs,
      reportHeader,
      colorOptions,
      smellOptions,
      aspectOptions,
      glucoseOptions,
      acetoneOptions,
      bilirubinOptions,
      urobilinogenOptions,
      hemoglobinOptions,
      leukocyteOptions,
      nitriteOptions,
      bacterialFloraOptions,
      spermatozoaOptions,
      proteinsOptions,
      epithelialCellsOptions,
      ridgesOptions,
      castsOptions,
      reportSubmitPromise,
    } = this.props;

    const { examRequestId } = this.props.match.params;

    if (redirectToList) {
      return <Redirect to={"/solicitacoes-exame/" + examRequestId} />;
    }

    if (refs.pending) {
      return <BounceLoader color="#00B4AD" />;
    }

    const isUpdate = this.isUpdate();
    const disableAll = reportSubmitPromise !== undefined;
    const reportHeaderData = reportHeaderFactory(
      reportHeader.fulfilled ? reportHeader.value : null
    );

    const options = {
      colorOptions: colorOptions.fulfilled ? colorOptions.value : [],
      smellsOptions: smellOptions.fulfilled ? smellOptions.value : [],
      aspectsOptions: aspectOptions.fulfilled ? aspectOptions.value : [],
      glucoseOptions: glucoseOptions.fulfilled ? glucoseOptions.value : [],
      acetoneOptions: acetoneOptions.fulfilled ? acetoneOptions.value : [],
      bilirubinOptions: bilirubinOptions.fulfilled
        ? bilirubinOptions.value
        : [],
      urobilinogenOptions: urobilinogenOptions.fulfilled
        ? urobilinogenOptions.value
        : [],
      hemoglobinOptions: hemoglobinOptions.fulfilled
        ? hemoglobinOptions.value
        : [],
      leukocyteOptions: leukocyteOptions.fulfilled
        ? leukocyteOptions.value
        : [],
      nitriteOptions: nitriteOptions.fulfilled ? nitriteOptions.value : [],
      bacterialFloraOptions: bacterialFloraOptions.fulfilled
        ? bacterialFloraOptions.value
        : [],
      spermatozoaOptions: spermatozoaOptions.fulfilled
        ? spermatozoaOptions.value
        : [],
      proteinsOptions: proteinsOptions.fulfilled ? proteinsOptions.value : [],
      epithelialCellsOptions: epithelialCellsOptions.fulfilled
        ? epithelialCellsOptions.value
        : [],
      ridgesOptions: ridgesOptions.fulfilled ? ridgesOptions.value : [],
      castsOptions: castsOptions.fulfilled ? castsOptions.value : [],
    };

    const backLink = "/solicitacoes-exame/" + examRequestId;
    const saveBtnText = isUpdate ? "Atualizar" : "Salvar";
    const savingText = isUpdate ? "Atualizando" : "Salvando";

    const data = {
      isUpdate: isUpdate,
      completeValidation: this.state.completeValidation,
      refs: refs.fulfilled ? refs.value : [],
      fields: this.state.fields,
      options: options,
      disableAll: disableAll,
      reportHeaderData: reportHeaderData,
      examRequestId: examRequestId,
      submitPromise: reportSubmitPromise,
      backLink: backLink,
      saveBtnText: saveBtnText,
      completeValidation: this.state.completeValidation,
      savingText: savingText,
    };

    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      suggestionsFilter: suggestionsFilter,
      invalidNotification: this.invalidNotification,
      fieldToggle: this.fieldToggle,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      cancelReport: this.cancelReport,
      notifications: this.props.notifications,
      changeCompleteValidation: this.changeCompleteValidation,
      handleSendReportEmail: this.handleSendReportEmail,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      stopReport: this.stopReport,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}
const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);

export default FunctionUtil.compose(
  connectWithEndpoint,
  connectWithRedux
)(EasReportForm);
