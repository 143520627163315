import { validated } from 'react-custom-validation';
import {
  required,
  minLength,
  greaterThan,
  validateCNPJ,
  validateCPF,
  validEmail,
  requiredIfExistAnother,
  handleApiValidate
} from '../../assets/js/validationFunctions.js';

import { commonReportRules } from '../../assets/js/validationRules';

const validation = validated(props => {

  const {
    vetSignerId,
    requestExamState,
    distemper,
    parvovirus,
    materialId,
    manufacturer,
    batch,
    validity,
    manufacturerInformationObs
  } = props.data.fields;

  const {completeValidation} = props.data;

  const commonValidation = commonReportRules(vetSignerId, requestExamState);
  const validations = {
    ...commonValidation,
    materialId: {
      rules: completeValidation ? [
        [greaterThan, materialId, 0, 'Informe o material enviado']
      ] : []
    },
    distemper: {
      rules: completeValidation ? [
        [greaterThan, distemper, -1, 'Informe o campo']
      ] : []
    },
    parvovirus: {
      rules: completeValidation ? [
        [greaterThan, parvovirus, -1, 'Informe o campo']
      ] : []
    },
    // manufacturer: {
    //   rules: completeValidation ? [
    //     [required,'Informe o fabricante' ]
    //   ]:[]
    // },
    // batch: {
    //   rules: completeValidation ? [
    //     [required,'Informe o lote' ]
    //   ]:[]
    // },
    // validity: {
    //   rules: completeValidation ? [
    //     [required,'Informe a validade' ]
    //   ]:[]
    // },
    manufacturer: [],
    batch: [],
    validity: [],
    manufacturerInformationObs: []
  };

  let fields = [
    'requestExamState',
    'vetSignerId',
    'materialId',
    'distemper',
    'parvovirus',
    'manufacturer',
    'batch',
    'validity',
    'manufacturerInformationObs'
  ];

  return { fields, validations };
});

export default validation;