import React from "react";

/** Internal */
import { Row } from "./../../components/grid";
import NumberInputMaterial from "./../NumberInputMaterial";
import { PHONE_MASK, CPF_MASK } from "../../assets/js/Consts";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";

const AnimalFieldsThirdRow = ({
  $field,
  $validation,
  updateField,
  phone,
  cpf,
  externalCode,
  microchipCode,
  disableAll,
}) => (
  <Row>
    <LVInputTextUpper
      mask
      maskValue={PHONE_MASK}
      id="phone"
      title="Telefone"
      name="phone"
      md={2}
      value={phone}
      {...$field("phone", (event) => {
        updateField("phone", event.target.value);
      })}
    />
    <LVInputTextUpper
      id="cpf"
      name="cpf"
      title="CPF"
      md={2}
      value={cpf}
      disabled={disableAll}
      mask
      maskValue={CPF_MASK}
      {...$field("cpf", (event) => {
        updateField("cpf", event.target.value);
      })}
      hasValidation={$validation.cpf.show}
      validationReason={$validation.cpf.error.reason}
    />
    <NumberInputMaterial
      id="externalCode"
      title="Cód. Externo"
      name="externalCode"
      md={2}
      value={externalCode}
      disabled={disableAll}
      allowNegative={false}
      isNumericString={true}
      fieldChange={updateField}
      {...$field("externalCode")}
    />
    <LVInputTextUpper
      id="microchipCode"
      name="microchipCode"
      title="Cód. Microchip"
      md={2}
      value={microchipCode}
      disabled={disableAll}
      {...$field("microchipCode", (event) => {
        updateField("microchipCode", event.target.value);
      })}
    />
  </Row>
);

export default AnimalFieldsThirdRow;
