import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal
 */
import ReferenceValueWithTitle from './../ReferenceValueWithTitle';
import FlatPanelMaterial from './../FlatPanelMaterial';
import { Col, Row } from './../../components/grid';

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const ReticulocyteNormalFractionPanel = ( {
  normalMin,
  normalMax,
  lowMin,
  lowMax,
  moderateMin,
  moderateMax,
  highMin,
  highMax,
  unit
} ) =>
  <FlatPanelMaterial title="Grau de Resposta (%)" >
    <Row>
      <ReferenceValueWithTitle
        title={ 'Normal' }
        valueMin={ normalMin }
        valueMax={ normalMax }
        decimalScale={ 0 }
        unit={ unit } />
    </Row>
    <Row>
      <ReferenceValueWithTitle
        title={ 'Leve' }
        valueMin={ lowMin }
        valueMax={ lowMax }
        decimalScale={ 0 }
        unit={ unit } />
    </Row>
    <Row>
      <ReferenceValueWithTitle
        title={ 'Moderada' }
        valueMin={ moderateMin }
        valueMax={ moderateMax }
        decimalScale={ 0 }
        unit={ unit } />
    </Row>
    <Row>
      <ReferenceValueWithTitle
        title={ 'Intensa' }
        valueMin={ highMin }
        valueMax={ highMax }
        decimalScale={ 0 }
        unit={ unit } />
    </Row>
  </FlatPanelMaterial>

ReticulocyteNormalFractionPanel.propTypes = propTypes;

export default ReticulocyteNormalFractionPanel;