import { useFormik } from "formik";
import { element } from "prop-types";
import React, { useEffect, useState } from "react";
import { EXAM_TYPES } from "../../consts";
import { ROUTES, useFetch } from "../use-fetch";
import { REQUEST_EXAM_STATE_ID_BY_NAME } from "../../consts/request-exam-states";

const defaultHeaderState = {
  animalFields: { animal: "", breed: "", species: "", sex: "", age: "" },
  requestExamStates: { placeholder: "", options: "" },
  vets: { options: [] },
  requestSamplesNames: [],
  deadline: "",
  endDate: "",
  requestExamStateId: REQUEST_EXAM_STATE_ID_BY_NAME["RECEBIDO_EM_ANDAMENTO"],
  vetSignerId: 0,
};

const validate = (values) => {
  const errors = {};
  if (!values.vetSignerId) errors.vetSignerId = "requerido";
  return errors;
};

const headerStateFactory = (data) => {
  return {
    animalFields: {
      animal: data.animal,
      breed: data.breed,
      species: data.species,
      sex: data.sex,
      age: data.age,
    },
    requestExamStates: {
      placeholder: "placeholder",
      options: data.requestExamStates,
    },
    vets: { options: data.vets },
    requestSamplesNames: data.requestSamples.map((sample) => sample.name),
    deadline: data.deadline,
    endDate: data.endDate,
  };
};

const UseHeader = ({ requestExamId }) => {
  const {
    values,
    errors: headerErrors,
    handleChange: headerChange,
    setValues,
  } = useFormik({
    initialValues: defaultHeaderState,
    validate,
  });

  
  // useEffect(() => {
  //   setValues(defaultHeaderState)
  // }, []);
  
  
  const fetchedHeader =  useFetch();
  
  useEffect(() => {
    fetchedHeader.fetch(
      ROUTES.getReportHeader(requestExamId),
      { method: "GET" },
      (data) => setValues(headerStateFactory(data))
    );
  }, [requestExamId]);


  return {
    animalFields: values.animalFields,
    vets: values.vets,
    deadline: values.deadline,
    endDate: values.endDate,
    requestSampleNames: values.requestSamplesNames,
    requestExamStates: values.requestExamStates,
    Headerobs: values.obs,
    requestExamStateId: values.requestExamStateId,
    vetSignerId: values.vetSignerId,
    headerChange,
    headerErrors,
  };
};
export default UseHeader;
