import React, { Component, useEffect, useState } from "react";

const maxPeriodValue = 9999;

const periodUiFactory = (periods, specieId) => {
  const emptyPeriod = {
    name: "nenhum",
    id: 0,
    min: 0,
    max: 0,
    specie: {},
  };

  if(!periods || !specieId)
    return [emptyPeriod];

  const periodsUi = periods.reduce((acc, period) => {
    if(period.specie.id != specieId ) 
      return acc;
    const month = period.max > 1 ? "meses" : "mes";
    let name = `de ${period.min} a ${period.max} ` + month;
    period.max == maxPeriodValue && (name = `mais de ${period.min} meses`);
    return [...acc, { ...period, name }];
  }, []);
  return [emptyPeriod, ...periodsUi];
};

const specieHasPeriod = (specieId, periods) => {
  return periods && periods.some((period) => period.specie.id === specieId);
};

export const useSpeciesAndReference = (props) => {
  const [specieId, setSpecieId] = useState(0);
  const [periodId, setPeriodId] = useState(0);
  const [examTypeId, setExamTypeId] = useState(0);
  const [references, setReferences] = useState({});
  const [periods, setPeriods] = useState([]);

  useEffect(() => {
    !props.periods.pending &&
      setPeriods(periodUiFactory(props.periods.value, specieId));
  }, [props.periods.pending]);

  useEffect(() => {
    examTypeId && fieldChangeLoadReferenceValues(examTypeId);
  }, [examTypeId, specieId, periodId]);

  useEffect(() => {
    setPeriodId(0);
    !specieHasPeriod(specieId, periods) &&
      setPeriods(periodUiFactory(props.periods.value, specieId));
  }, [specieId]);

  const referenceChange = (referenceName, value) => {
    setReferences({ ...references, [referenceName]: value });
  };

  const handleSubmit = () => {
    const then = () => {
      props.notificationSystem.add({
        title: "Sucesso!",
        message: "Salvo com sucesso",
        level: "success",
        position: "tr",
        autoDismiss: 5,
      });
    };
    const fields = {
      selectedSpecieId: specieId,
      examTypeId,
      references,
      periodId,
    };

    props.submit(fields, then);
  };

  const fieldChangeLoadReferenceValues = (examTypeId) => {
    const then = (response) => {
      setReferences(response);
    };
    props.filter({ specieId, periodId, examTypeId }, then);
  };

  return {
    specieId,
    setSpecieId,
    references,
    setReferences,
    examTypeId,
    setExamTypeId,
    periods,
    setPeriodId,
    periodId,
    referenceChange,
    handleSubmit,
    specieHasPeriod,
  };
};
