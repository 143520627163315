import React, { Component, useEffect, useState, useMemo, useRef } from "react";

import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
// import { connect as reduxConnect } from "react-redux";
import refetchConnect from "./../../api-connector";
import * as LifeVet from "../../components";
import { Link } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import promiseUtil from "./../../useful/promise-util";
import EmailRecordsView from "./view";
import { ROUTES, useFetch } from "../../hooks/use-fetch";
import { useFormik } from "formik";
import { useTable } from "react-table-v7";
import { StringUtil } from "../../useful";

function validate(state) {
  const erros = {};
  state.date || (erros.date = "Preencha a data");
  return erros;
}
const initialValues = {
  customer: "",
  regions: [],
  date: "",
  examRequestState: "",
};

function tableDataFactory(data) {
  return data.map((line) => ({
    col1: line.exam_request.id,
    col2: line.exam_request.customer.name,
    col3: line.qtt,
  }));
}

const EmailRecords = (props) => {
  const fetchedRegions = useFetch();
  const fetchedCustomers = useFetch();
  const fetchedExamRequestStates = useFetch();
  const {
    values,
    handleChange,
    setFieldValue,
    setValues,
    setTouched,
    errors,
    touched,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => searchRecords(values),
  });
  const fetcheEmailRecords = useFetch();
  const [tableData, setTableData] = useState([]);
  const dateRef = useRef("");

  useEffect(() => {
    fetchedRegions.fetch(ROUTES.getRegions());
    fetchedCustomers.fetch(ROUTES.getCustomers());
    fetchedExamRequestStates.fetch(ROUTES.getExamRequestStates());
  }, []);

  function errorMessage() {
    alert(
      "Ocorreu um erro interno, tente novamente ou entre em contato com o suporte"
    );
  }

  function searchRecords(values) {
    const { customer, examRequestState, date } = values;
    const searchData = {};
    customer && (searchData.customerId = customer);
    examRequestState && (searchData.examRequestStateId = examRequestState);
    date && (searchData.date = date);
    const query = StringUtil.buildQueryString(searchData);

    fetcheEmailRecords.fetch(
      ROUTES.emailRecords(query),
      {
        method: "GET",
      },
      (data) => setTableData(tableDataFactory(data)),
      errorMessage
    );
  }

  const columns = useMemo(
    () => [
      {
        Header: "Número da solicitação",
        accessor: "col1",
      },
      {
        Header: "Cliente",
        accessor: "col2",
      },
      {
        Header: "quantidade de envios",
        accessor: "col3",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: tableData });

  function cleanValues() {
    setValues([]);
    setTableData([]);
    // console.log(dateRef.current);
    dateRef.current.value = "";
  }

  return (
    <EmailRecordsView
      regions={fetchedRegions.fulfilled ? fetchedRegions.data : []}
      customers={fetchedCustomers.fulfilled ? fetchedCustomers.data : []}
      requestExamStates={
        fetchedExamRequestStates.fulfilled ? fetchedExamRequestStates.data : []
      }
      handleChange={handleChange}
      values={values}
      handleSubmit={handleSubmit}
      tableInstance={tableInstance}
      setFieldValue={setFieldValue}
      cleanValues={cleanValues}
      dateRef={dateRef}
      tableIsLoading={fetcheEmailRecords.pending}
      tableIsFullfield={fetcheEmailRecords.fulfilled}
      errors={errors}
      touched={touched}
      handleBlur={handleBlur}
    />
  );
};

export default EmailRecords;
