import { molecularBiologyRules } from "../../../../assets/js/validationRules";

export const qualitativeAnaplasmFieldNames = () => {
  return [
    "qualitativeAnaplasmMaterials",
    "qualitativeAnaplasmTechnique",
    "qualitativeAnaplasmQualitativeResult",
    "qualitativeAnaplasmCopyValues",
    "qualitativeAnaplasmPrecision",
    "qualitativeAnaplasmResult",
    "qualitativeAnaplasmObs",
  ];
};

export const qualitativeAnaplasmDefaultState = () => {
  return {
    qualitativeAnaplasmMaterials: [],
    qualitativeAnaplasmTechnique: -1,
    qualitativeAnaplasmQualitativeResult: -1,
    qualitativeAnaplasmCopyValues: "",
    qualitativeAnaplasmPrecision: "",
    qualitativeAnaplasmResult: "",
    qualitativeAnaplasmObs: "",
  };
};

// export const qualitativeFivDefaultState = () => {
//   return {
//     qualitativeFivMaterials: [],
//     qualitativeFivTechnique: -1,
//     qualitativeFivQualitativeResult: -1,
//     qualitativeFivCopyValues: "",
//     qualitativeFivPrecision: "",
//     qualitativeFivResult: "",
//     qualitativeFivObs: "",
//   };
// };

// export const qualitativeFivFieldNames = () => {
//   return [
//     "qualitativeFivMaterials",
//     "qualitativeFivTechnique",
//     "qualitativeFivQualitativeResult",
//     "qualitativeFivCopyValues",
//     "qualitativeFivPrecision",
//     "qualitativeFivResult",
//     "qualitativeFivObs",
//   ];
// };
// export const qualitativeFelvDefaultState = () => {
//   return {
//     qualitativeFelvMaterials: [],
//     qualitativeFelvTechnique: -1,
//     qualitativeFelvQualitativeResult: -1,
//     qualitativeFelvCopyValues: "",
//     qualitativeFelvPrecision: "",
//     qualitativeFelvResult: "",
//     qualitativeFelvObs: "",
//   };
// };
// export const qualitativeFelvFieldNames = () => {
//   return [
//     "qualitativeFelvMaterials",
//     "qualitativeFelvTechnique",
//     "qualitativeFelvQualitativeResult",
//     "qualitativeFelvCopyValues",
//     "qualitativeFelvPrecision",
//     "qualitativeFelvResult",
//     "qualitativeFelvObs",
//   ];
// };
// export const qualitativeMycoplasmDefaultState = () => {
//   return {
//     qualitativeMycoplasmMaterials: [],
//     qualitativeMycoplasmTechnique: -1,
//     qualitativeMycoplasmQualitativeResult: -1,
//     qualitativeMycoplasmCopyValues: "",
//     qualitativeMycoplasmPrecision: "",
//     qualitativeMycoplasmResult: "",
//     qualitativeMycoplasmObs: "",
//   };
// };

// export const qualitativeMycoplasmFieldNames = () => {
//   return [
//     "qualitativeMycoplasmMaterials",
//     "qualitativeMycoplasmTechnique",
//     "qualitativeMycoplasmQualitativeResult",
//     "qualitativeMycoplasmCopyValues",
//     "qualitativeMycoplasmPrecision",
//     "qualitativeMycoplasmResult",
//     "qualitativeMycoplasmObs",
//   ];
// };

export const qualitativeAnaplasmStateApiFactory = (obj) => {
  const {
    qualitativeAnaplasmMaterials,
    qualitativeAnaplasmTechnique,
    qualitativeAnaplasmQualitativeResult,
    qualitativeAnaplasmCopyValues,
    qualitativeAnaplasmPrecision,
    qualitativeAnaplasmResult,
    qualitativeAnaplasmObs,
  } = obj;
  return {
    qualitativeAnaplasmMaterials: qualitativeAnaplasmMaterials
      ? qualitativeAnaplasmMaterials
      : [],
    qualitativeAnaplasmTechnique: qualitativeAnaplasmTechnique
      ? qualitativeAnaplasmTechnique
      : -1,
    qualitativeAnaplasmQualitativeResult: !(
      qualitativeAnaplasmQualitativeResult === null
    )
      ? qualitativeAnaplasmQualitativeResult
      : -1,
    qualitativeAnaplasmCopyValues: qualitativeAnaplasmCopyValues
      ? qualitativeAnaplasmCopyValues
      : "",
    qualitativeAnaplasmPrecision: qualitativeAnaplasmPrecision
      ? qualitativeAnaplasmPrecision
      : "",
    qualitativeAnaplasmResult: qualitativeAnaplasmResult
      ? qualitativeAnaplasmResult
      : "",
    qualitativeAnaplasmObs: qualitativeAnaplasmObs
      ? qualitativeAnaplasmObs
      : "",
  };
};

export const qualitativeAnaplasmFieldsToApiObjParser = (fields) => {
  const {
    qualitativeAnaplasmMaterials,
    qualitativeAnaplasmTechnique,
    qualitativeAnaplasmQualitativeResult,
    qualitativeAnaplasmCopyValues,
    qualitativeAnaplasmPrecision,
    qualitativeAnaplasmResult,
    qualitativeAnaplasmObs,
  } = fields;

  return {
    qualitativeAnaplasmMaterials: qualitativeAnaplasmMaterials,
    qualitativeAnaplasmTechnique: qualitativeAnaplasmTechnique,
    qualitativeAnaplasmQualitativeResult: qualitativeAnaplasmQualitativeResult,
    qualitativeAnaplasmCopyValues: qualitativeAnaplasmCopyValues,
    qualitativeAnaplasmPrecision: qualitativeAnaplasmPrecision,
    qualitativeAnaplasmResult: qualitativeAnaplasmResult,
    qualitativeAnaplasmObs: qualitativeAnaplasmObs,
  };
};

export const qualitativeAnaplasmValidationRules = (
  fields,
  isCompleteValidation
) => {
  const {
    qualitativeAnaplasmMaterials,
    qualitativeAnaplasmTechnique,
    qualitativeAnaplasmQualitativeResult,
    qualitativeAnaplasmCopyValues,
    qualitativeAnaplasmPrecision,
  } = fields;
  return molecularBiologyRules(
    "qualitativeAnaplasmTechnique",
    qualitativeAnaplasmTechnique,
    "qualitativeAnaplasmQualitativeResult",
    qualitativeAnaplasmQualitativeResult,
    "qualitativeAnaplasmCopyValues",
    qualitativeAnaplasmCopyValues,
    "qualitativeAnaplasmPrecision",
    qualitativeAnaplasmPrecision,
    "qualitativeAnaplasmMaterials",
    qualitativeAnaplasmMaterials,
    isCompleteValidation
  );
};
