import React, { Component } from "react";

/** Internal */
import CanineBrucellosisReportFormView from "./../CanineBrucellosisReportFormView";
import reportCommonConnectWithEndpoint from "./../../reportCommonRequests";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import validation from "./validation.js";
import { PromiseUtil } from "../../useful";
import {
  BTN_SAVE_TITLE,
  BTN_UPDATE_TITLE,
  BTN_SAVING_TITLE,
  BTN_UPDATING_TITLE,
  REQUEST_EXAM_STATE_ID_BY_NAME
} from "../../assets/js/Consts.js";
import { containerState, reportState } from "../../modules/factories/default-states.js";
import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSubmit,
  handleSendReportEmail
} from "../../assets/js/containerFunctions.js";

/** External */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

/**
 * @author Victor Heringer
 *
 * Adds validation to the view
 *
 * @prop {Object} data
 * @prop {Object} methods
 * @prop {Object} $field
 * @prop {Object} $validation
 * @prop {Function} $submit
 */
const View = ({ data, methods, $field, $validation, $submit }) => {
  const dataWithValidation = { $validation: $validation, ...data };

  const methodsWithValidation = {
    onSubmit: e => {
      e.preventDefault();
      $submit(methods.handleSubmit, methods.invalidNotification);
    },
    $field: $field,
    ...methods
  };

  return (
    <CanineBrucellosisReportFormView
      data={dataWithValidation}
      methods={methodsWithValidation}
    />
  );
};

const ValidatedView = validation(View);

/**
 * @author Victor Heringer
 *
 * Container for ousourced exams report form
 */
class CanineBrucellosisReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        ...reportState(),
        result: -1,
        materialId: 0,
        obs: ""
      },
      shouldSendEmail: false
    };

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.handleSubmit = handleSubmit.bind(this);
    this.redirectToList = redirectToList.bind(this);

    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);

    this.createObj = this.createObj.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.submitData = this.submitData.bind(this);
    this.shouldDisplayLoader = this.shouldDisplayLoader.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        xhr.response.status === 200 && this.fillFields(data);
      };
      this.props.getReportById(id, then);
    }
    this.props.getReportHeader(this.props.match.params.requestExamId);
  }

  /**
   * @author Victor Heringer
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    this.setState(state =>
      update(state, {
        shouldSendEmail: {
          $set: obj.requestExamState == REQUEST_EXAM_STATE_ID_BY_NAME
        },
        fields: {
          result: {
            $set: obj.result
          },
          materialId: {
            $set: obj.materialId
          },
          obs: {
            $set: obj.obs
          },
          reportDataId: {
            $set: obj.reportDataId
          },
          vetSignerId: {
            $set: obj.vetSignerId
          },
          requestExamState: {
            $set: obj.requestExamState
          }
        }
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Creates 4DX object
   *
   * @return {Object}
   *
   */
  createObj() {
    const {
      reportDataId,
      vetSignerId,
      requestExamState,
      result,
      materialId,
      obs
    } = this.state.fields;

    return {
      id: this.getId(),
      examId: this.state.examId,
      report: this.state.fields.files,
      requestExamId: this.props.match.params.requestExamId,
      reportDataId: reportDataId,
      vetSignerId: vetSignerId,
      requestExamState: requestExamState,
      result: result,
      materialId: materialId,
      obs: obs
    };
  }

  /**
   * @author Victor Heringer
   *
   * Decides wether is should display the screen loader
   */
  shouldDisplayLoader() {
    const { reportPromise } = this.props;

    let editFetches = null;
    const isUpdate = this.isUpdate();
    const shouldCheckEditFetches = isUpdate && reportPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([reportPromise]);
    }

    return shouldCheckEditFetches && editFetches && editFetches.pending;
  }

  /**
   * @author Victor Heringer
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   */
  submitData() {
    const { reportSubmitPromise } = this.props;

    const isUpdate = this.isUpdate();
    const isSet = reportSubmitPromise !== undefined;
    const isPending = isSet && reportSubmitPromise.pending;
    const isFulfilled = isSet && reportSubmitPromise.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: isUpdate ? BTN_UPDATING_TITLE : BTN_SAVING_TITLE,
      btnTitle: isUpdate ? BTN_UPDATE_TITLE : BTN_SAVE_TITLE
    };
  }

  render() {
    const { examRequestId, requestExamId } = this.props.match.params;
    const { reportHeaderPromise } = this.props;
    const backLink = "/solicitacoes-exame/" + examRequestId;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    // Loader
    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const submitData = this.submitData();
    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );

    const data = {
      isUpdate: this.isUpdate(),
      fields: this.state.fields,
      backLink: backLink,
      shouldSendEmail: this.state.shouldSendEmail,
      requestExamId: requestExamId,
      submitData: submitData,
      examRequestId: examRequestId,
      disableAll: submitData.pending,
      reportHeaderData: reportHeaderFactory(reportHeaderData)
    };

    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      invalidNotification: this.invalidNotification,
      handleSendReportEmail: this.handleSendReportEmail
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(reportCommonConnectWithEndpoint)(
  CanineBrucellosisReportForm
);
