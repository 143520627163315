import React, { ReactNode } from "react"

import { ComponentProps } from '@stitches/react'

import { StyledButtonDefault } from "./styles"

interface ButtonDefaultMaterialProps extends ComponentProps<typeof StyledButtonDefault> {
  children?: ReactNode
}

export function ButtonDefaultMaterial({ children, ...props }: ButtonDefaultMaterialProps) {
   return (
     <StyledButtonDefault {...props}>
      { children }
     </StyledButtonDefault>
   )
}