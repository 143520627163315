import React, { useEffect, useState } from "react";

/**
 * Internal
 */
import PanelMaterial from "../PanelMaterial";
import NumberInputMaterial from "../NumberInputMaterial";
import PanelMaterialBody from "../PanelMaterialBody";
import PanelMaterialTop from "../PanelMaterialTop";
import NumberInputWithReference from "../NumberInputWithReference";
import ButtonDefaultMaterial from "../ButtonDefaultMaterial";
import PlateletsCounter from "./modal";

const propTypes = {};

/**
 *
 * @version 0.0.1
 *
 * @author Kayo Oliveira
 *
 * * Description: Platelets Counter Panel
 *
 * @param {panelTitle,fieldTitle,fieldName,value,shouldDisable,hasValidation,validationReason,fieldChange,$field,valueMin,valueMax,setRef}
 *
 * @return JSX.Element
 *
 */
const PlateletsPanelWithCounter = ({
  panelTitle,
  fieldTitle,
  fieldName,
  value,
  shouldDisable,
  hasValidation,
  validationReason,
  fieldChange,
  $field,
  valueMin,
  valueMax,
  setRef,
}) => {
  const [isOpenCounter, setIsOpenCounter] = useState(false);
  const [result, setResult] = React.useState(value);
  useEffect(() => {
    fieldChange(fieldName, result || value);
  }, [result]);
  return (
    <>
      <React.Fragment>
        <PanelMaterial className="pb-20" flat={true}>
          <PanelMaterialTop title={panelTitle} />
          <PanelMaterialBody>
            <NumberInputWithReference
              value={value}
              fieldTitle={fieldTitle}
              fieldName={fieldName}
              shouldDisable={shouldDisable}
              decimalScale={0}
              fieldChange={fieldChange}
              $field={$field}
              hasValidation={hasValidation}
              validationReason={validationReason}
              valueMin={valueMin}
              valueMax={valueMax}
              unit={"mg/dL"}
              setRef={setRef}
            />
            <ButtonDefaultMaterial
              title="Contador"
              style={{
                width: "50%",
                margin: "auto",
                marginTop: "2rem",
              }}
              type="highlight"
              block={true}
              onClick={() => setIsOpenCounter(!isOpenCounter)}
              disabled={shouldDisable}
            />
          </PanelMaterialBody>
        </PanelMaterial>
      </React.Fragment>
      {isOpenCounter && (
        <PlateletsCounter
          handleClose={() => setIsOpenCounter(!isOpenCounter)}
          result={result}
          setResult={setResult}
        />
      )}
    </>
  );
};

export default PlateletsPanelWithCounter;
