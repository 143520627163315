import { FIVE_SECONDS, THREE_SECONDS } from "./time-lengths";

export const UROCULTURE_NEGATIVE_MESSAGE =
  "Ausência de crescimento bacteriano em Agar Sangue, Agar Cled e Agar MacConkey, em 48 horas, a 37 ºC.";

export const COPROCULTURE_NEGATIVE_MESSAGE =
  "Ausência de crescimento bacteriano em Agar Sangue, Agar SS e Agar MacConkey, em 48 horas, a 37 ºC.";

export const HEMOCULTURE_NEGATIVE_MESSAGE =
  "Ausência de crescimento bacteriano em Caldo TSB, Agar Sangue e Agar MacConkey, em 5 dias, a 37 ºC.";

export const CULTURE_ANTIBIOGRAM_ANAEROBIC_NEGATIVE_MESSAGE =
  "Ausência de crescimento bacteriano em Agar Sangue e Agar MacConkey, em 48 horas, a 37 ºC, em atmosfera de anaerobiose.";

export const CULTURE_ANTIBIOGRAM_AEROBIC_NEGATIVE_MESSAGE =
  "Ausência de crescimento bacteriano em Agar Sangue e Agar MacConkey, em 48 horas, a 37 ºC.";

export const SUCCESS_EMAIL_MESSAGE =
  "Em até 3 minutos o cliente deve ter recebido o email!";
export const WARNING_EMAIL_MESSAGE =
  "Apenas exames com status de Atendido e Liberado podem ser enviados";

export const CREATED_EXAM_REQUEST = {
  title: "Solicitação realizada com sucesso",
  message: "Os dados foram salvos!",
  level: "success",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const UPDATED_EXAM_REQUEST = {
  title: "Solicitação atualizada com sucesso",
  message: "Os dados foram salvos!",
  level: "success",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const REQUEST_SAMPLE_CODE_NOT_FOUND = {
  title: "Registro não econtrado",
  message: "Nenhuma solicitação cadastrada com o código informado",
  level: "error",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const REQUEST_EXAM_CANCELLED_SUCCESSFULLY = {
  title: "Cancelado",
  message: "Exame foi Cancelado com Sucesso",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const REQUEST_EXAM_CANCELLING_ERROR = {
  title: "Erro ao cancelar",
  message: "Não foi possível cancelar o Exame",
  level: "error",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const EMAIL_SENT_SUCCESSFULLY = {
  title: "Email enviado",
  message: "Em até 3 minutos o cliente deve ter recebido o email!",
  level: "success",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const EMAIL_SENDING_ERROR = {
  title: "Erro",
  message: "Ocorreu um erro ao tentar realizar o envio do email",
  level: "error",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const NO_PERMISSION_TO_SEND_EMAIL_WARNING = {
  title: "Não Enviado.",
  message: WARNING_EMAIL_MESSAGE,
  level: "warning",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const PENDING_EXAM_SELECTION = {
  title: "Atenção!",
  message: "Selecione pelo menos um exame",
  level: "warning",
  position: "tr",
  autoDismiss: THREE_SECONDS,
};

export const PENDING_ANIMAL_SELECTION = {
  title: "Atenção!",
  message: "Selecione um animal",
  level: "warning",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const ANIMAL_SAVED_SUCCESSFULLY = {
  title: "Salvo",
  message: "Animal salvo com sucesso.",
  level: "success",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const ANIMAL_SAVING_ERROR = {
  title: "Erro",
  message: "Erro ao salvar o animal.",
  level: "error",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const generateWarningForExamsAlreadyIncluded = (message) => {
  let obj = EXAM_ALREADY_INCLUDED_WARNING;
  obj.message = message;
  return obj;
};

export const EXAM_ALREADY_INCLUDED_WARNING = {
  title: "Atenção!",
  message: "Um ou mais exames selecionados já foram incluídos.",
  level: "warning",
  position: "tr",
  autoDismiss: THREE_SECONDS,
};

export const WAITING_REPORT_MESSAGE = {
  title: "Solicitação em processo.",
  message: "Estamos gerando seu laudo, aguarde um momento!",
  level: "info",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const DONT_SEND_REPORT_MESSAGE = {
  title: "Detalhes.",
  message: "Cliente não deseja receber laudo impresso",
  level: "warning",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const CREATED_MESSAGE = {
  title: "Solicitação realizada com sucesso",
  message: "Os dados foram salvos!",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const PROCESSING_MESSAGE = {
  title: "Aguarde um momento",
  message: "Sua ação está sendo processada",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const ERROR_MESSAGE = {
  title: "Houve um problema na solicitação",
  message: "Entre em contato com o suporte ou crie um ticket",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const FETCH_MESSAGE = {
  title: "Consulta realizada com sucesso!",
  message: "A tela foi atualizada com os dados requeridos.",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const PROCESS_INVOICES_MESSAGE = {
  title: "O processamento foi realizado com sucesso!",
  message: "As faturas selecionadas foram geradas.",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};

export const SELECT_INVOICE_MESSAGE = {
  title: "Busque as fatura antes de exportar",
  message: "Faturas Não Selecionadas",
  position: "tr",
  autoDismiss: FIVE_SECONDS,
};
