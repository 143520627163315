import { validated } from 'react-custom-validation';
import {
  required,
  minLength,
  greaterThan,
  validateCNPJ,
  validateCPF,
  validEmail,
  requiredIfExistAnother,
  handleApiValidate
} from '../../assets/js/validationFunctions.js';

import { commonReportRules } from '../../assets/js/validationRules';

const validation = validated(props => {

  const {
    vetSignerId,
    requestExamState,
    volume,
    density,
    colorId,
    ph,
    proteins,
    aspectId,
    glucose,
    leukocytes,
    microscopy,
    coagulation
  } = props.data.fields;

  const {completeValidation}  = props.data;

  const commonValidation = commonReportRules(vetSignerId, requestExamState);
  const validations = {
    ...commonValidation,
    volume: {
      rules: completeValidation ? [
        [required, volume, 'Informe o campo' ]
      ] : []
    },
    density: {
      rules: completeValidation ? [
        [required, density, 'Informe o campo']
      ] : []
    },
    colorId: {
      rules: completeValidation ? [
        [greaterThan, colorId, 0, 'Informe o campo']
      ] : []
    },
    aspectId: {
      rules: completeValidation ? [
        [greaterThan, aspectId, 0, 'Informe o campo']
      ] : []
    },
    ph: {
      rules: completeValidation ? [
        [required, ph, 'Informe o campo']
      ] : []
    },
    proteins: {
      rules: completeValidation ? [
        [required, proteins, 'Informe o campo']
      ] : []
    },
    glucose: {
      rules: completeValidation ? [
        [required, glucose, 'Informe o campo']
      ] : []
    },
    leukocytes: {
      rules: completeValidation ? [
        [required, leukocytes, 'Informe o campo']
      ] : []
    },
    coagulation: {
      rules: completeValidation ? [
        [greaterThan, coagulation, -1, 'Informe o campo']
      ] : []
    },
    microscopy: {
      rules: completeValidation ? [
        [required, microscopy, 'Informe o campo']
      ] : []
    }
  };

  let fields = [
    'requestExamState',
    'vetSignerId',
    'volume',
    'density',
    'colorId',
    'ph',
    'proteins',
    'aspectId',
    'glucose',
    'leukocytes',
    'microscopy',
    'coagulation'
  ];

  return { fields, validations };
});

export default validation;