import __update from "immutability-helper";
import { RequestExam, Action } from "../../types/index";

export const ACTIONS = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  RESET: "RESET",
};

const add = (
  value: Array<RequestExam>,
  target: Array<RequestExam>
): Array<RequestExam> => {
  const commands = {
    $push: value,
  };
  return __update(target, commands);
};

const update = (
  value: RequestExam,
  target: Array<RequestExam>
): Array<RequestExam> => {
  const newValue: Array<RequestExam> = target.map((curr) =>
    curr.exam.id === value.exam.id ? value : curr
  );
  const commands = {
    $set: newValue,
  };
  return __update(target, commands);
};

const remove = (
  value: RequestExam,
  target: Array<RequestExam>
): Array<RequestExam> => {
  const newValue: Array<RequestExam> = target.filter(
    (curr) => curr.exam.id !== value.exam.id
  );
  const commands = {
    $set: newValue,
  };

  return __update(target, commands);
};

const reset = (target: Array<RequestExam>): Array<RequestExam> => {
  const commands = {
    $set: [],
  };
  return __update(target, commands);
};

export const reducer = (state: Array<RequestExam>, action: Action) => {
  switch (action.type) {
    case ACTIONS.ADD:
      return add(action.payload, state);
    case ACTIONS.UPDATE:
      return update(action.payload, state);
    case ACTIONS.REMOVE:
      return remove(action.payload, state);
    case ACTIONS.RESET:
      return reset(state);
    default:
      return state;
  }
};
