import React,{ useEffect } from "react"

import { ROUTES, useFetch } from "../../hooks/use-fetch"
import { NewAntibioticFormDataType } from "./components/AntibioticFormFields/@types"

import { Notification } from 'react-notification-system'
import { Link, useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"

import PanelMaterial from "../PanelMaterial"
import PanelMaterialTop from "../PanelMaterialTop"
import PanelMaterialBody from "../PanelMaterialBody"
import PanelMaterialBottom from "../PanelMaterialBottom"

import { AntibioticFormFields } from "./components/AntibioticFormFields"
import { ButtonDefaultMaterial } from "../../new_components/ButtonDefaultMaterial"

export type NotificationSystemType = {
  add: (options: Notification) => void
  clear: () => void
}

interface AntibioticFormProps {
  notificationSystem: NotificationSystemType
}

export function AntibioticForm({ notificationSystem }: AntibioticFormProps) {
  const antibioticQuery = useFetch()
  const editAntibioticQuery = useFetch()
  const history = useHistory()
  const { id } = useParams<{ id?: string }>()

  useEffect(() => {
    if (id) {
      editAntibioticQuery.fetch(ROUTES.saveAntibiotic(id))
    }
  }, [id])

  const fields = id ? editAntibioticQuery.data : undefined

  useEffect(() => {
    if (antibioticQuery.pending) {
      notificationSystem.clear()
      notificationSystem.add({
        title: "Carregando!",
        message: "Cadastrando antibiótico",
        level: "warning",
        position: "tr",
        autoDismiss: 5,
      })

      return
    }

    if (antibioticQuery.data) {
      notificationSystem.clear()
      notificationSystem.add({
        title: "Sucesso!",
        message:  "Antibiótico cadastrado com Sucesso!",
        level: "success",
        position: "tr",
        autoDismiss: 5,
      })

      history.push('/antibioticos')
      return
    }

    if (antibioticQuery.error) {
      notificationSystem.clear()
      notificationSystem.add({
        title: "Erro!",
        message: "Falha ao cadastrar o antibiótico!",
        level: "error",
        position: "tr",
        autoDismiss: 5,
      })

      return
    }

  }, [antibioticQuery.data, antibioticQuery.error, antibioticQuery.pending])

  function handleSubmit(values: NewAntibioticFormDataType) {
    const { name, initials } = values 
    const method = id ? 'PUT' : 'POST'
    const endpoint = id ? ROUTES.saveAntibiotic(id) : ROUTES.createAntibiotic()

    antibioticQuery.fetch(
      endpoint, { method, body: JSON.stringify({
        id,
        name,
        initials,
      })}
    )
  }

  return (
    <PanelMaterial flat={false}>
      <PanelMaterialTop title={"Antibióticos"}><></></PanelMaterialTop>

      <PanelMaterialBody>
        <AntibioticFormFields fields={fields} onSubmit={handleSubmit} />
      </PanelMaterialBody>

      <PanelMaterialBottom>
        <Link to="/antibioticos">
          <ButtonDefaultMaterial position="left" variant="default" form="antibiotic-form" type="button">
            Voltar
          </ButtonDefaultMaterial>
        </Link>

        <ButtonDefaultMaterial className="btn-right" position="right" variant="highlight" form="antibiotic-form" type="submit">
          Salvar
        </ButtonDefaultMaterial>
      </PanelMaterialBottom>
    </PanelMaterial>
  )
}