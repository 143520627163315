import { validated } from "react-custom-validation";

import {
  commonReportRules,
  hemogramRules,
  biochemistryRules2
} from "../../assets/js/validationRules";

const validation = validated(props => {
  const {
    erythrocytes,
    corpuscularVolume,
    hemoglobiometry,
    globalLeukometry,
    relativeBasophils,
    relativeEosinophils,
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    relativeSegs,
    relativeLymphocytes,
    relativeMonocytes,
    relativeHeterophils,
    platelets,
    erythrogramObs,
    leukogramObs,
    plateletsObs,
    hemogramObs,
    hemocytozoaResearchResult,
    hemocytozoaResearchObs,
    microfilariaeResearchResult,
    microfilariaeResearchObs,
    isIntenseLeucopeny,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const { refs, isUpdate, rows, completeValidation } = props.data;

  let fields = [
    "erythrocytes",
    "corpuscularVolume",
    "hemoglobiometry",
    "globalLeukometry",
    "relativeBasophils",
    "relativeEosinophils",
    "relativeMyelocytes",
    "relativeMetamyelocytes",
    "relativeBands",
    "relativeSegs",
    "relativeLymphocytes",
    "relativeMonocytes",
    "relativeHeterophils",
    "platelets",
    "erythrogramObs",
    "leukogramObs",
    "plateletsObs",
    "hemocytozoaResearchResult",
    "hemocytozoaResearchObs",
    "microfilariaeResearchResult",
    "microfilariaeResearchObs",
    "hemogramObs",
    "total",
    "mchc",
    "urea",
    "altTgp",
    "requestExamState",
    "vetSignerId"
  ];

  const fieldNames = {
    erythrocytesName: "erythrocytes",
    corpuscularVolumeName: "corpuscularVolume",
    hemoglobiometryName: "hemoglobiometry",
    relativeBasophilsName: "relativeBasophils",
    relativeEosinophilsName: "relativeEosinophils",
    relativeMyelocytesName: "relativeMyelocytes",
    relativeMetamyelocytesName: "relativeMetamyelocytes",
    relativeBandsName: "relativeBands",
    relativeSegsName: "relativeSegs",
    relativeLymphocytesName: "relativeLymphocytes",
    relativeMonocytesName: "relativeMonocytes",
    relativeHeterophilsName: "relativeHeterophils",
    plateletsName: "platelets",
    plateletsObsName: "plateletsObs"
  };

  const commonValidation = commonReportRules(vetSignerId, requestExamState);

  const hemogramValidation = hemogramRules({
    globalLeukometry: globalLeukometry,
    plateletsName: "platelets",
    platelets: platelets,
    plateletsObsName: "plateletsObs",
    plateletsObs: plateletsObs,
    erythrocytes: erythrocytes,
    corpuscularVolume: corpuscularVolume,
    hemoglobiometry: hemoglobiometry,
    erythrogramObs: erythrogramObs,
    mchc: props.data.mchc,
    mchcMax: refs.mchcMax,
    isIntenseLeucopeny: isIntenseLeucopeny,
    relativeBasophils: relativeBasophils,
    relativeEosinophils: relativeEosinophils,
    relativeLymphocytes: relativeLymphocytes,
    relativeMonocytes: relativeMonocytes,
    relativeMyelocytes: relativeMyelocytes,
    relativeMetamyelocytes: relativeMetamyelocytes,
    relativeBands: relativeBands,
    relativeSegs: relativeSegs,
    relativeHeterophils: relativeHeterophils,
    leukogramObs: leukogramObs,
    hasHeterophils: refs.hasHeterophils,
    isUpdate: isUpdate,
    total: props.data.total,
    fieldNames: fieldNames,
    hemogramObs,
    completeValidation
  });

  const biochemistryValidation = biochemistryRules2(rows, completeValidation);

  const validations = {
    ...commonValidation,
    ...hemogramValidation,
    ...biochemistryValidation
  };

  return { fields, validations };
});

export default validation;
