import React from "react";

/** Internal */
import { phoneModalValidation } from "./../UnityForm/validation";
import DefaultTable from "./../DefaultTable";
import InputMaterial from "./../InputMaterial";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import CheckboxMaterial from "./../CheckboxInputMaterial";
import { PHONE_MASK, SUBMIT_MODAL_KEY } from "../../assets/js/Consts";
import TableButtonRemoveMaterial from "./../TableButtonRemoveMaterial";
import TableButtonEditMaterial from "./../TableButtonEditMaterial";
import TableButtonAddMaterial from "./../TableButtonAddMaterial";
import PhoneMask from "./../../old_components/Masks/PhoneMask";
import { StringUtil } from "./../../useful";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";

/** Assets */
import styles from "./index.module.css";

/** External */
import { validated } from "react-custom-validation";
import { Row, Col, Modal } from "react-bootstrap";

/**
 * @author Victor Heringer
 *
 * Checks if a phone is selected at table
 *
 * @param {String} value Reference value for phone
 * @param {Array} selecteds List of selecteds phone
 *
 * @return {Bool}
 */
const isSelectedAtTable = (value, selecteds) => {
  return selecteds.indexOf(value) > -1;
};

/**
 * @author Victor Heringer
 *
 * Render the checkbox to remove a row
 *
 * @param {Object} data
 * @param {Function} toggleSelectPhone
 * @param {Array} selectedPhones
 *
 * @return {Object}
 */
const renderInputButton = (
  data,
  toggleSelectPhone,
  selectedPhones,
  shouldDisableAll
) => {
  if (!shouldDisableAll) {
    return (
      <CheckboxMaterial
        checked={isSelectedAtTable(data.original.value, selectedPhones)}
        onChange={() => toggleSelectPhone(data.original.value)}
      />
    );
  }

  return <span />;
};

/**
 * @author Victor Heringer
 *
 * Render the edit button
 *
 * @param {Object} data Table data, including id to fetch user
 * @param {Function} handleLoadPhoneToModal Calls modal and sets region
 *
 * @return {Object}
 */
const renderEditButton = (data, handleLoadPhoneToModal) => {
  const phone = { id: data.original.id, value: data.original.value };
  const onClick = () => handleLoadPhoneToModal(phone);

  if (phone.id > 0) {
    return <TableButtonEditMaterial onClick={onClick} />;
  }

  return <span />;
};

/**
 * @author Victor Heringer
 *
 * Returns react table configs
 *
 * @return {Array}
 */
const getTableConfigs = (
  phones,
  toggleSelectPhone,
  selectedPhones,
  handleLoadPhoneToModal,
  shouldDisableAll
) => {
  let config = {
    defaultPageSize: 5,
    defaultSorted: [{ id: "id", desc: true }],
    pageSize: 15,
    resizable: false,
    showPagination: true,
    noDataText: "Nenhum registro".toUpperCase(),
    loadingText: "Carregando",
    className: "-striped -highlight",
    previousText: "Anterior",
    nextText: "Próximo",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    columns: [],
    showPageSizeOptions: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
  };

  config.data = phones;
  config.pageSize = 3;
  config.className = "no-page-info";
  config.columns = [
    {
      Header: "",
      sortable: false,
      filterable: false,
      width: 60,
      Cell: (data) =>
        renderInputButton(
          data,
          toggleSelectPhone,
          selectedPhones,
          shouldDisableAll
        ),
    },
    {
      Header: "",
      sortable: false,
      width: 60,
      className: styles.centeredText,
      Cell: (data) => renderEditButton(data, handleLoadPhoneToModal),
    },
    {
      Header: "Telefone",
      sortable: true,
      className: styles.centeredText,
      Cell: (data) => {
        return (
          <PhoneMask value={StringUtil.onlyNumbers(data.original.value)} />
        );
      },
    },
  ];

  return config;
};

/**
 * @author Victor Heringer
 *
 * Phones form fields
 */
const PhonesFields = ({
  fields,
  $field,
  $fieldEvent,
  $validation,
  $submit,
  methods: {
    isUpdateing,
    fieldChange,
    addPhoneToTable,
    handleLoadPhoneToModal,
    toggleSelectPhone,
    handleRemovePhone,
  },
  data: { selectedPhones, displayPhoneModal, defaultPhone, shouldDisableAll },
}) => {
  const modal = (
    <Modal
      show={displayPhoneModal}
      onHide={() => handleLoadPhoneToModal(defaultPhone)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Telefone</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <LVInputTextUpper
            mask
            autofocus={true}
            maskValue={PHONE_MASK}
            disabled={shouldDisableAll}
            id="modalPhone"
            title="Telefone"
            required
            name="modalPhone"
            md={12}
            value={fields.modalPhone}
            {...$field("modalPhone", (event) => {
              fieldChange("modalPhone", event.target.value);
            })}
            hasValidation={
              $validation &&
              $validation.modalPhone.show &&
              !$validation.modalPhone.isValid
            }
            validationReason={
              $validation && $validation.modalPhone.error.reason
            }
            isValid={$validation && $validation.modalPhone.isValid}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefaultMaterial
          keyCode={SUBMIT_MODAL_KEY}
          onKeyPress={() =>
            $submit(() =>
              addPhoneToTable(
                { id: fields.modalPhoneId, value: fields.modalPhone },
                $fieldEvent
              )
            )
          }
          type="default"
          title="Adicionar"
          disabled={shouldDisableAll}
          onClick={() =>
            $submit(() =>
              addPhoneToTable(
                { id: fields.modalPhoneId, value: fields.modalPhone },
                $fieldEvent
              )
            )
          }
        />
      </Modal.Footer>
    </Modal>
  );

  const openModalBtn = (
    <TableButtonAddMaterial
      title={"Novo Telefone"}
      className={"pull-right"}
      disabled={shouldDisableAll}
      onClick={() => handleLoadPhoneToModal(defaultPhone)}
    />
  );

  const table = (
    <DefaultTable
      configurations={getTableConfigs(
        fields.phones,
        toggleSelectPhone,
        selectedPhones,
        handleLoadPhoneToModal,
        shouldDisableAll
      )}
    />
  );

  const removePhonesBtn = (
    <TableButtonRemoveMaterial
      text={{
        confirm: "Remover",
        message:
          "Os registros selecionados serão removidos permanentemente. Deseja continuar?",
        title: "Remover Telefones",
      }}
      className="pull-left"
      disabled={selectedPhones.length === 0}
      onClick={handleRemovePhone}
    />
  );

  return (
    <React.Fragment>
      {modal}
      <Col md={6}>
        <Row>
          <Col xs={12} className="mb-15 mt-15">
            {openModalBtn}
            {removePhonesBtn}
            <h5 className="table-title text-center"> Telefones </h5>
          </Col>
          <Col xs={12}>{table}</Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

const withValidation = validated(phoneModalValidation);

export default withValidation(PhonesFields);
