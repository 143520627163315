import React, { Component } from "react";

/**
 * Internal
 */
import ReticulocyteCountReportFormView from "./../ReticulocyteCountReportFormView";
import connectWithEndpoint from "./requests.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import validation from "./validation.js";
import { PromiseUtil } from "../../useful";
import { REQUEST_EXAM_STATE_ID_BY_NAME } from "../../assets/js/Consts.js";
import { Decimal } from "decimal.js";
import {
  containerState,
  reportState,
} from "../../modules/factories/default-states.js";
import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";
import { createReticulocyteCountRefObj } from "../../assets/js/reportFunctions.js";
import { REPORT_DEFAULT_VALUES } from "./../../consts";

/**
 * External
 */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

const View = ({ data, methods, $field, $validation, $submit, $fieldEvent }) => {
  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;
    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };

  const handleChange = (field, value) => {
    $fieldEvent("change", field);
    methods.fieldChange(field, value);
  };

  const handleBlur = (event) => {
    $fieldEvent("blur", event.target.name);
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    handleChange: handleChange,
    handleBlur: handleBlur,
    $field: $field,
    ...methods,
  };

  return (
    <ReticulocyteCountReportFormView data={newData} methods={newMethods} />
  );
};

const ValidatedView = validation(View);

class ReticulocyteCountReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        ...reportState(),
        reticulocyteByField: "",
        erythrocyteCount: "",
        corpuscularVolume: "",
        obs: "",
      },
      reticCountCorrectionFactor: 45,
      ...containerState(),
      shouldSendEmail: false,
    };

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.fieldChange = fieldChange.bind(this);

    this.redirectToList = redirectToList.bind(this);
    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);
    this.createObj = this.createObj.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.shouldDisplayLoader = this.shouldDisplayLoader.bind(this);
    this.calcReticulocytePercent = this.calcReticulocytePercent.bind(this);
    this.calcReticulocytePercentBy100 =
      this.calcReticulocytePercentBy100.bind(this);
    this.calcAbsoluteErythrocyteCount =
      this.calcAbsoluteErythrocyteCount.bind(this);
    this.calcTotalReticulocyte = this.calcTotalReticulocyte.bind(this);
    this.calcReticulocyteCorrection =
      this.calcReticulocyteCorrection.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        if (xhr.response.status === 200) {
          const obj = data;
          this.fillFields(obj);
        }
      };

      this.props.withId(id, then);
    }

    const then = (response) => {
      const then = (response) => {
        this.setState((state) =>
          update(state, {
            reticCountCorrectionFactor: {
              $set: response.reticCountCorrectionFactor,
            },
          })
        );
      };

      this.props.getHematologyReferenceValues(response.specieId, then);
    };

    this.props.fetchReportHeader(then);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    this.setState((state) =>
      update(state, {
        fields: {
          reticulocyteByField: { $set: obj.reticulocyteByField },
          erythrocyteCount: { $set: obj.erythrocyteCount },
          corpuscularVolume: { $set: obj.corpuscularVolume },
          obs: { $set: obj.obs },
          reportDataId: { $set: obj.reportDataId },
          vetSignerId: { $set: obj.vetSignerId },
          requestExamState: { $set: obj.requestExamState },
        },
        shouldSendEmail: {
          $set:
            obj.requestExamState ==
            REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_LIBERADO"],
        },
      })
    );
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Creates hemogram object
   *
   * @return {Object}
   *
   */
  createObj() {
    const {
      reticulocyteByField,
      erythrocyteCount,
      corpuscularVolume,
      obs,
      reportDataId,
      vetSignerId,
      requestExamState,
    } = this.state.fields;

    const requestExamId = this.props.match.params.requestExamId;

    const reticulocytePercent =
      this.calcReticulocytePercent(reticulocyteByField);

    return {
      id: this.getId(),
      requestExamId: requestExamId,
      reticulocyteByField: reticulocyteByField,
      erythrocyteCount: erythrocyteCount,
      corpuscularVolume: corpuscularVolume,
      reticulocytePercent: reticulocytePercent,
      absoluteErythrocyteCount:
        this.calcAbsoluteErythrocyteCount(erythrocyteCount),
      totalReticulocyte: this.calcTotalReticulocyte(
        erythrocyteCount,
        reticulocytePercent
      ),
      reticulocyteCorrection: this.calcReticulocyteCorrection(
        reticulocytePercent,
        corpuscularVolume
      ),
      obs: obs,
      reportDataId: reportDataId,
      vetSignerId: vetSignerId,
      requestExamState: requestExamState,
    };
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Decides wether it should
   * display the screen loader
   *
   */
  shouldDisplayLoader() {
    const { objPromise } = this.props;
    const isUpdate = this.isUpdate();

    let editFetches = null;

    const shouldCheckEditFetches = isUpdate && objPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([objPromise]);
    }

    return isUpdate && editFetches && editFetches.pending;
  }

  calcReticulocytePercentBy100() {
    const { reticulocyteByField } = this.state.fields;

    if (
      reticulocyteByField === "" ||
      reticulocyteByField === "." ||
      reticulocyteByField === "," ||
      reticulocyteByField === undefined ||
      reticulocyteByField === null
    ) {
      return "";
    }

    const reticulocyteByFieldVal = new Decimal(reticulocyteByField);

    return reticulocyteByFieldVal.times(20).dividedBy(1000).toPrecision();
  }

  calcReticulocytePercent() {
    const { reticulocyteByField } = this.state.fields;

    if (
      reticulocyteByField === "" ||
      reticulocyteByField === "." ||
      reticulocyteByField === "," ||
      reticulocyteByField === undefined ||
      reticulocyteByField === null
    ) {
      return "";
    }

    const reticulocyteByFieldVal = new Decimal(reticulocyteByField);

    return reticulocyteByFieldVal.times(20).dividedBy(10).toPrecision();
  }

  calcAbsoluteErythrocyteCount() {
    const { erythrocyteCount } = this.state.fields;

    if (
      erythrocyteCount === "" ||
      erythrocyteCount === null ||
      erythrocyteCount === undefined ||
      erythrocyteCount === "," ||
      erythrocyteCount === "."
    ) {
      return "";
    }

    const erythrocyteCountVal = new Decimal(erythrocyteCount);

    const ten = new Decimal(10);
    const tenToSixth = ten.toPower(6);

    return erythrocyteCountVal.times(tenToSixth).toPrecision();
  }

  calcTotalReticulocyte() {
    const absoluteErythrocyteCount = this.calcAbsoluteErythrocyteCount();
    const reticulocytePercent = this.calcReticulocytePercentBy100();

    if (absoluteErythrocyteCount === "" || reticulocytePercent === "") {
      return "";
    }

    const absoluteErythrocyteCountVal = new Decimal(absoluteErythrocyteCount);

    return absoluteErythrocyteCountVal.times(reticulocytePercent).toPrecision();
  }

  calcReticulocyteCorrection() {
    const { corpuscularVolume } = this.state.fields;

    const reticulocytePercent = this.calcReticulocytePercent();

    if (
      corpuscularVolume === "" ||
      corpuscularVolume === null ||
      corpuscularVolume === undefined ||
      corpuscularVolume === "," ||
      corpuscularVolume === "."
    ) {
      return "";
    }
    if (
      reticulocytePercent === "" ||
      reticulocytePercent === null ||
      reticulocytePercent === undefined ||
      reticulocytePercent === "," ||
      reticulocytePercent === "."
    ) {
      return "";
    }

    const reticulocytePercentVal = new Decimal(reticulocytePercent);
    const corpuscularVolumeVal = new Decimal(corpuscularVolume);

    return reticulocytePercentVal
      .times(corpuscularVolumeVal)
      .dividedBy(this.state.reticCountCorrectionFactor || 1)
      .toPrecision();
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (!this.state.completeValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" || obj[i] == 0 ? null : obj[i];
      }
    }

    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  render() {
    const { examRequestId } = this.props.match.params;
    const { reportHeaderPromise, refsPromise, reportSubmitPromise } =
      this.props;

    const backLink = "/solicitacoes-exame/" + examRequestId;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    // Loader
    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data

    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );

    const refs = PromiseUtil.extractValue(
      refsPromise,
      REPORT_DEFAULT_VALUES.RETICULOCYTE_COUNT_REF_DEFAULT,
      createReticulocyteCountRefObj
    );

    const data = {
      refs: refs,
      isUpdate: this.isUpdate(),
      completeValidation: this.state.completeValidation,
      fields: this.state.fields,
      backLink: backLink,
      submitPromise: reportSubmitPromise,
      examRequestId: examRequestId,
      disableAll: reportSubmitPromise && reportSubmitPromise.pending,
      reportHeaderData: reportHeaderFactory(reportHeaderData),
      shouldSendEmail: this.state.shouldSendEmail,
    };

    // Methods
    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      birthDateChange: this.birthDateChange,
      invalidNotification: this.invalidNotification,
      calcReticulocytePercent: this.calcReticulocytePercent,
      calcAbsoluteErythrocyteCount: this.calcAbsoluteErythrocyteCount,
      calcTotalReticulocyte: this.calcTotalReticulocyte,
      calcReticulocyteCorrection: this.calcReticulocyteCorrection,
      handleSendReportEmail: this.handleSendReportEmail,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      cancelReport: this.cancelReport,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      stopReport: this.stopReport,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(connectWithEndpoint)(
  ReticulocyteCountReportForm
);
