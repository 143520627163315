import React, { Component } from "react";

/** Internal */
import DefaultMolecularBiologyFormView from "./../DefaultMolecularBiologyFormView";
import { PromiseUtil } from "../../useful";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import validation from "./validation.js";
import { MOLECULAR_BIOLOGY_MATERIALS } from "../../assets/js/Consts.js";

import {
  BTN_SAVE_TITLE,
  BTN_UPDATE_TITLE,
  BTN_SAVING_TITLE,
  BTN_UPDATING_TITLE,
  REQUEST_EXAM_STATE_ID_BY_NAME,
} from "../../assets/js/Consts.js";

import { reportState } from "../../modules/factories/default-states.js";

import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";

import {
  calcMolecularBiologyResult,
  suggestionsFilter,
} from "../../assets/js/reportFunctions.js";

import connectWithEndpoint from "./requests";

/** External */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

/**
 * @author Victor Heringer
 *
 * Adds validation to the view
 *
 * @prop {Object} data
 * @prop {Object} methods
 * @prop {Object} $field
 * @prop {Object} $validation
 * @prop {Function} $submit
 */
const View = ({ data, methods, $field, $validation, $submit, $fieldEvent }) => {
  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;

    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };

  const handleChange = (field, value) => {
    $fieldEvent("change", field);
    methods.fieldChange(field, value);
  };

  const handleBlur = (event) => {
    const field = event.target.name;
    $fieldEvent("blur", field);
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    handleChange: handleChange,
    handleBlur: handleBlur,
    $field: $field,
    ...methods,
  };

  return (
    <DefaultMolecularBiologyFormView data={newData} methods={newMethods} />
  );
};

const ValidatedView = validation(View);

/**
 * @author Alessandro Bastos Grandini
 * @author Victor Heringer
 *
 * Container for ousourced exams report form
 */
class DefaultMolecularBiologyForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        ...reportState(),
        qualitativeResult: -1,
        technique: -1,
        copyValues: "",
        precision: "",
        materials: [],
        obs: "",
      },
      shouldSendEmail: false,
    };

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);
    this.createObj = this.createObj.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.submitData = this.submitData.bind(this);
    this.shouldDisplayLoader = this.shouldDisplayLoader.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        xhr.response.status === 200 && this.fillFields(data);
      };

      this.props.getReportById(id, then);
    }

    this.props.getReportHeader(this.props.match.params.requestExamId);
  }

  /**
   * @author Victor Heringer
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    this.setState((state) =>
      update(state, {
        shouldSendEmail: {
          $set: obj.requestExamState == REQUEST_EXAM_STATE_ID_BY_NAME,
        },
        fields: {
          qualitativeResult: {
            $set: obj.qualitativeResult,
          },
          technique: {
            $set: obj.technique,
          },
          copyValues: {
            $set: obj.copyValues,
          },
          precision: {
            $set: obj.precision,
          },
          result: {
            $set: obj.result,
          },
          materials: {
            $set: obj.materials,
          },
          obs: {
            $set: obj.obs,
          },
          vetSignerId: {
            $set: obj.vetSignerId,
          },
          requestExamState: {
            $set: obj.requestExamState,
          },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Creates 4DX object
   *
   * @return {Object}
   *
   */
  createObj() {
    const {
      vetSignerId,
      requestExamState,
      qualitativeResult,
      technique,
      copyValues,
      precision,
      materials,
      obs,
    } = this.state.fields;

    return {
      id: this.getId(),
      report: this.state.fields.files,
      requestExamId: this.props.match.params.requestExamId,
      vetSignerId: vetSignerId,
      requestExamState: requestExamState,
      qualitativeResult: qualitativeResult,
      technique: technique,
      copyValues: copyValues,
      precision: precision,
      result: calcMolecularBiologyResult(copyValues, precision, technique),
      materials: materials,
      obs: obs,
    };
  }

  /**
   * @author Victor Heringer
   *
   * Decides wether is should display the screen loader
   */
  shouldDisplayLoader() {
    const { reportPromise } = this.props;

    let editFetches = null;
    const isUpdate = this.isUpdate();
    const shouldCheckEditFetches = isUpdate && reportPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([reportPromise]);
    }

    return shouldCheckEditFetches && editFetches && editFetches.pending;
  }

  /**
   * @author Victor Heringer
   *
   * Sorts report header promise into a data object
   *
   * @return {Object}
   */
  reportHeaderData() {
    const { reportHeader } = this.props;
    return reportHeader.fulfilled ? reportHeader.value : null;
  }

  /**
   * @author Victor Heringer
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   */
  submitData() {
    const { reportSubmitPromise } = this.props;

    const isUpdate = this.isUpdate();
    const isSet = reportSubmitPromise !== undefined;
    const isPending = isSet && reportSubmitPromise.pending;
    const isFulfilled = isSet && reportSubmitPromise.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: isUpdate ? BTN_UPDATING_TITLE : BTN_SAVING_TITLE,
      btnTitle: isUpdate ? BTN_UPDATE_TITLE : BTN_SAVE_TITLE,
    };
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (!this.state.completeValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" || obj[i] == 0 ? null : obj[i];
      }
    }
    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  render() {
    const { examRequestId, requestExamId } = this.props.match.params;
    const { reportHeaderPromise } = this.props;
    const backLink = "/solicitacoes-exame/" + examRequestId;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    // Loader
    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const submitData = this.submitData();
    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );

    const data = {
      isUpdate: this.isUpdate(),
      completeValidation: this.state.completeValidation,
      fields: this.state.fields,
      backLink: backLink,
      submitData: submitData,
      materialsSuggestions: MOLECULAR_BIOLOGY_MATERIALS,
      examRequestId: examRequestId,
      requestExamId: requestExamId,
      disableAll: submitData.pending,
      shouldSendEmail: this.state.shouldSendEmail,
      reportHeaderData: reportHeaderFactory(reportHeaderData),
      panelTitle: this.props.panelTitle,
    };

    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      calcMolecularBiologyResult: calcMolecularBiologyResult,
      invalidNotification: this.invalidNotification,
      suggestionsFilter: suggestionsFilter,
      fieldChangeCalcResult: this.fieldChangeCalcResult,
      handleSendReportEmail: this.handleSendReportEmail,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      cancelReport: this.cancelReport,
      stopReport: this.stopReport,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(connectWithEndpoint)(
  DefaultMolecularBiologyForm
);
