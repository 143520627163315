import React, { useReducer } from "react";
import { reducer, ACTIONS } from "./reducer";
import { Specie, Status } from "./../../types/index";

export const useSpecie = () => {
  const defaultSpecie = { id: 0, name: "", status: Status.ACTIVE };

  const [specie, dispatch] = useReducer(reducer, defaultSpecie);
  const data = specie;
  const methods = {
    update: (specie: Specie) =>
      dispatch({ type: ACTIONS.UPDATE, payload: specie }),
    updateField: (field: string, value: string | number | boolean) =>
      dispatch({ type: ACTIONS.UPDATE_FIELD, payload: { field, value } }),
    reset: () => dispatch({ type: ACTIONS.UPDATE, payload: defaultSpecie }),
  };
  return {
    data: data,
    defaultData: defaultSpecie,
    methods: methods,
  };
};
