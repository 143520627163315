import React from "react";
import styled from "styled-components";
import { Input } from "./../form/";
import { granadilla100, granadilla200 } from "./../colors";

const Wrapper = styled.div`
  width: 240px;
  background-color: ${granadilla100};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const InputWrapper = styled.div`
  padding: 30px 15px;
`;

const List = styled.ul`
  padding: 0px;
  margin: 0px;
  list-style: none;
`;

const ListItem = styled.li`
  padding: 15px 0 15px 20px;
  font-family: arial;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 11px;

  :hover {
    background-color: ${granadilla200};
  }
`;

const ItemLink = styled.a``;

const Sidebar = () => (
  <Wrapper>
    <InputWrapper>
      <Input />
    </InputWrapper>
    <List>
      <ListItem>
        <ItemLink>Main Section</ItemLink>
      </ListItem>
      <ListItem>
        <ItemLink>Finacial</ItemLink>
      </ListItem>
      <ListItem>
        <ItemLink>Reports</ItemLink>
      </ListItem>
      <ListItem>
        <ItemLink>Logout</ItemLink>
      </ListItem>
    </List>
  </Wrapper>
);

export default Sidebar;
