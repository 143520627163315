import { validated } from 'react-custom-validation';

import { commonReportRules, parvoCoronaRules } from '../../assets/js/validationRules';

const validation = validated(props => {

  const { 
    vetSignerId, 
    requestExamState,
    isParvovirus,
    isCoronavirus,
    materialId
  } = props.data.fields;

  const {completeValidation} = props.data;

  const commonValidation = commonReportRules(vetSignerId, requestExamState);

  const parvoAndCoronaValidation = parvoCoronaRules(
    'materialId',
    materialId,
    'isParvovirus',
    isParvovirus,
    'isCoronavirus',
    isCoronavirus,
    completeValidation
  );
  

  const validations = { 
    ...commonValidation,
    ...parvoAndCoronaValidation
  };

  let fields = [
    'requestExamState', 
    'vetSignerId', 
    'materialId',
    'isParvovirus',
    'isCoronavirus'
  ];

  return { fields, validations };
});

export default validation;