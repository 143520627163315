import connect from "../../api-connector";

const connectWithEndpoint = connect(props => ({
  addSample: (obj, then, error) => ({
    sampleResponse: {
      url: `/sample`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  }),
  examsSamples: (obj, then, error) => ({
    examsSamplesResponse: {
      url: `/samples-exams`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  }),
  examTypes: "/exam-types",
  _samples: `/_samples`,
  _getSamples: then => ({
    _samples: {
      url: `/_samples`,
      method: "GET",
      then: then,
      force: true
    }
  }),
  getExams: (id, then) => ({
    exams: {
      url: `/exams?type=${id}`,
      method: "GET",
      then: then,
      force: true
    }
  }),
  sampleSearch: (filters, page, sorted, then) => ({
    samples: {
      url: `/samples?id=${filters.id}&name=${filters.name}&page=${page}&sort=${sorted[0].id}&desc=${sorted[0].desc}`,
      method: "GET",
      then: then,
      force: true
    }
  })
}));

export default connectWithEndpoint;
