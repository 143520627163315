import React, { Component } from "react";
import ReactTable from "react-table-v6";
import PropTypes from "prop-types";

/**
 * @author Alessandro Bastos Grandini
 * @author Victor Heringer
 *
 * Wrapper for react table
 */
class LVTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: -1,
      expandedRows: [],
    };

    this.createHandleClickTable = this.createHandleClickTable.bind(this);
    this.getExtended = this.getExtended.bind(this);
  }

  getExtended() {
    return this.props.data.map((x) => true);
  }

  /**
   * @author Victor Heringer
   *
   * Defines click and double click action to table
   *
   * @param {Object} state React table's state
   * @param {Object} rowInfo Row info contains the original data
   * @param {Object} column
   *
   * @return {Array}
   */
  createHandleClickTable(state, rowInfo, column) {
    const index = this.state.index;
    const currIndex = rowInfo ? rowInfo.index : null;
    const { shouldPaintRowOnClick, shouldDisableRowHighlight } = this.props;

    const shouldPaint =
      shouldPaintRowOnClick &&
      currIndex === index &&
      !shouldDisableRowHighlight;
    const { onClickRow, onDoubleClickRow } = this.props;
    const isOnClickRowSet = onClickRow !== undefined;
    const isDoubleClickRowSet = onDoubleClickRow !== undefined;

    return {
      onClick: (e, handleOriginal) => {
        if (isOnClickRowSet) {
          onClickRow(currIndex, state, rowInfo, column, true);
        }
        this.setState({ index: currIndex });
        handleOriginal && handleOriginal();
      },
      onDoubleClick: (e, handleOriginal) => {
        if (isDoubleClickRowSet) {
          onDoubleClickRow(currIndex, state, rowInfo, column, false);
        }
        handleOriginal && handleOriginal();
      },
      style: {
        background: shouldPaint
          ? "#4dc3bf6b"
          : this.props.getDefaultColorCallback(state, rowInfo, column),
      },
    };
  }

  /**
   * @author Victor Heringer
   *
   * Sets a align class to all rows
   *
   * @param {Array} columns
   * @param {String} align
   *
   * @return {Array}
   */
  alignColumns(columns) {
    return columns.map((value) => ({
      ...value,
      className: value.className + " centeredText",
    }));
  }

  renderExpander() {
    return <span />;
  }

  render() {
    const {
      defaultPageSize,
      data,
      defaultSorted,
      pageSize,
      loading,
      resizable,
      style,
      showPagination,
      columns,
      showPageSizeOptions,
      pageSizeOptions,
      className,
      collapseOnDataChange,
      SubComponent,
      ofText,
      pageText,
      rowsText,
      nextText,
      previousText,
      loadingText,
      noDataText,
      sortable,
      manual,
      getTrProps,
      getTdProps,
      alignClass,
      onFetchData,
      filterable,
      pages,
      page,
      onPageChange,
    } = this.props;

    return (
      <ReactTable
        // Text
        ofText={ofText}
        pageText={pageText}
        rowsText={rowsText}
        nextText={nextText}
        previousText={previousText}
        loadingText={loadingText}
        noDataText={noDataText}
        // Pagination
        showPagination={showPagination}
        onFetchData={onFetchData}
        pages={pages}
        pageSize={pageSize}
        defaultPageSize={defaultPageSize}
        manual={manual}
        pageSizeOptions={pageSizeOptions}
        // Sorting
        defaultSorted={defaultSorted}
        sortable={sortable}
        // Data and Structure
        data={data}
        columns={this.alignColumns(columns)}
        showPageSizeOptions={showPageSizeOptions}
        loading={loading}
        className={[className, "-striped -highlight"].join(" ")}
        collapseOnDataChange={collapseOnDataChange}
        ExpanderComponent={this.renderExpander}
        SubComponent={SubComponent}
        // Actions
        filterable={filterable}
        getTrProps={getTrProps}
        getTdProps={getTdProps ? getTdProps : this.createHandleClickTable}
        resizable={resizable}
        expanded={this.getExtended()}
        style={style}
        page={page}
        onPageChange={onPageChange}
      />
    );
  }
}

LVTable.propTypes = {
  defaultPageSize: PropTypes.number,
  sortable: PropTypes.bool,
  data: PropTypes.array,
  defaultSorted: PropTypes.array,
  pageSize: PropTypes.number,
  loading: PropTypes.bool,
  resizable: PropTypes.bool,
  showPagination: PropTypes.bool,
  columns: PropTypes.array,
  showPageSizeOptions: PropTypes.bool,
  pageSizeOptions: PropTypes.array,
  className: PropTypes.string,
  collapseOnDataChange: PropTypes.bool,
  ExpanderComponent: PropTypes.func,
  SubComponent: PropTypes.func,
  ofText: PropTypes.string,
  pageText: PropTypes.string,
  rowsText: PropTypes.string,
  nextText: PropTypes.string,
  previousText: PropTypes.string,
  loadingText: PropTypes.string,
  noDataText: PropTypes.string,
  style: PropTypes.object,
  manual: PropTypes.bool,
  shouldDisableRowHighlight: PropTypes.bool,
  getTrProps: PropTypes.func,
  getTdProps: PropTypes.func,
  alignClass: PropTypes.string,
  onClickRow: PropTypes.func,
  onDoubleClickRow: PropTypes.func,
  onFetchData: PropTypes.func,
  shouldPaintRowOnClick: PropTypes.bool,
  defaultRowColor: PropTypes.string,
  shouldRowsStartExpanded: PropTypes.bool,
  page: PropTypes.number,
};

LVTable.defaultProps = {
  defaultPageSize: 5,
  sortable: true,
  data: [],
  defaultSorted: [],
  pageSize: 15,
  loading: false,
  resizable: false,
  showPagination: true,
  columns: [],
  showPageSizeOptions: false,
  pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
  className: "",
  collapseOnDataChange: false,
  ExpanderComponent: undefined,
  SubComponent: undefined,
  ofText: "de".toUpperCase(),
  pageText: "Página".toUpperCase(),
  rowsText: "linhas".toUpperCase(),
  nextText: "Próximo".toUpperCase(),
  previousText: "Anterior".toUpperCase(),
  loadingText: "Carregando".toUpperCase(),
  noDataText: "Nenhum registro".toUpperCase(),
  style: {},
  manual: false,
  shouldDisableRowHighlight: false,
  getTrProps: undefined,
  getTdProps: undefined,
  onClickRow: undefined,
  onDoubleClickRow: undefined,
  alignClass: "centeredText",
  onFetchData: undefined,
  filterable: undefined,
  pages: undefined,
  shouldPaintRowOnClick: true,
  defaultRowColor: "",
  shouldRowsStartExpanded: false,
  getDefaultColorCallback: () => "",
};

export default LVTable;
