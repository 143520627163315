import React from "react";
import PropTypes from "prop-types";

/**
 * External
 */
import { Col } from "./../../components/grid";
import classNames from "classnames";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

/**
 * Internal
 */
import InputValidationMsg from "./../InputValidationMsg";
//import styles from './index.module.css';

const renderCheckbox = (
  name,
  checked,
  onChange,
  disabled
  //inputClass
) => {
  let handleChange = undefined;

  if (onChange) {
    handleChange = e => {
      onChange(e.target.name);
    };
  }

  return (
    <Checkbox
      name={name}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

const defaultProps = {
  md: 12,
  block: false,
  disabled: false,
  onChange: null,
  inputGroup: true,
  titleBefore: "",
  titleAfter: "",
  className: "",
  containerClassName: "",
  inputGroupClassName: "",
  validationReason: "",
  hasValidation: false,
  validationType: "danger",
  hasValidationRecoil: true
};

const propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.PropTypes.bool.isRequired,
  md: PropTypes.number,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inputGroup: PropTypes.bool,
  titleBefore: PropTypes.string,
  titleAfter: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  validationReason: PropTypes.string,
  hasValidation: PropTypes.bool,
  validationType: PropTypes.oneOf(["danger", "warning", "info"]),
  hasValidationRecoil: PropTypes.bool
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for checkbox with material design styling
 */
const CheckboxMaterial = ({
  name,
  checked,
  md,
  block,
  disabled,
  onChange,
  inputGroup,
  titleBefore,
  titleAfter,
  className,
  containerClassName,
  inputGroupClassName,
  validationReason,
  hasValidation,
  validationType,
  hasValidationRecoil
}) => {
  /**
   * Input class
   * @type {Object}
   */
  const inputClass = classNames("form-control", className);

  /**
   * Validation Component
   * @type {Object}
   */
  const validation = (
    <InputValidationMsg
      type={validationType}
      visible={hasValidation}
      message={validationReason}
      top={hasValidationRecoil ? "-18px" : ""}
    />
  );

  const input = renderCheckbox(name, checked, onChange, disabled, inputClass);

  return (
    <Col md={md} className={containerClassName}>
      <div className={inputGroupClassName}>
        <label>
          {titleBefore && <span>{titleBefore}</span>}
          {input}
          <span className="cleanItem">
            {!titleBefore && titleAfter && <span>{" " + titleAfter}</span>}
          </span>
        </label>
      </div>
      {validation}
    </Col>
  );
};

CheckboxMaterial.defaultProps = defaultProps;
CheckboxMaterial.propTypes = propTypes;

export default CheckboxMaterial;
