import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import InputMaterial from "./../InputMaterial";
import SelectMaterial from "./../SelectMaterial";
import {
  IMMUNOLOGY_EXAM_MATERIALS,
  IMMUNOLOGY_INFO
} from "./../../assets/js/Consts";
import NumberInputWithReference from "./../NumberInputWithReference";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";
import FlatPanelMaterial from "./../FlatPanelMaterial";
import MethodPanel from "./../MethodPanel";
import ObsPanel from "./../ObsPanel";

const IgaFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="IgA"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <MethodPanel
      method={IMMUNOLOGY_INFO.IGA.METHOD}
      description={IMMUNOLOGY_INFO.IGA.DESCRIPTION}
    />
    <FlatPanelMaterial title="Resultado">
      <Row>
        <Col md={4}></Col>
        <SelectMaterial
          id="materialId"
          title="Material Enviado"
          md={4}
          hasValidationRecoil
          name="materialId"
          options={IMMUNOLOGY_EXAM_MATERIALS}
          value={data.fields.materialId}
          searchable={true}
          disabled={data.disableAll}
          {...methods.$field("materialId", event => {
            methods.fieldChange("materialId", event);
          })}
          hasValidation={
            data.$validation &&
            data.$validation.materialId.show &&
            !data.$validation.materialId.isValid
          }
          validationReason={
            data.$validation && data.$validation.materialId.error.reason
          }
          isValid={data.$validation && data.$validation.materialId.isValid}
        />
      </Row>
      <Row>
        <Col md={12}>
          <NumberInputWithReference
            panelTitle={"Resultado"}
            fieldTitle={"Resultado"}
            fieldName={"result"}
            value={data.fields.result}
            disableAll={data.disableAll}
            hasValidation={data.$validation.result.show}
            validationReason={data.$validation.result.error.reason}
            fieldChange={methods.fieldChange}
            $field={methods.$field}
            valueMin={data.refs.igaMin}
            valueMax={data.refs.igaMax}
            decimalScale={1}
            shouldDisable={data.disableAll}
            unit="mg/dL"
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
    <Row>
      <Col md={12}>
        <ObsPanel
          name="obs"
          value={data.fields.obs}
          shouldDisable={data.disableAll}
          handleChange={methods.handleChange}
          handleBlur={methods.handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default IgaFormView;
