import React, { useReducer } from "react";
import { reducer, ACTIONS } from "./reducer";
import { RequestExam } from "../../types/index";

const defaultRequestExams: Array<RequestExam> = [];

export const useRequestExams = () => {
  const [requestExams, dispatch] = useReducer(reducer, defaultRequestExams);
  const data = requestExams;
  const methods = {
    add: (requestExam: Array<RequestExam>) =>
      dispatch({ type: ACTIONS.ADD, payload: requestExam }),
    update: (requestExam: RequestExam) =>
      dispatch({ type: ACTIONS.UPDATE, payload: requestExam }),
    remove: (requestExam: RequestExam) =>
      dispatch({ type: ACTIONS.REMOVE, payload: requestExam }),
    get: (id: number) =>
      requestExams.filter((requestExam) => requestExam.id === id).pop(),
    reset: () => dispatch({ type: ACTIONS.RESET }),
  };
  return {
    data,
    methods,
  };
};
