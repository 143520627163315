import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal
 */
import InputValidationMsg from './../InputValidationMsg';

/**
 * External
 */
import DatePicker from "react-datepicker";

/**
 * Assets
 */
import styles from './index.module.css';

const defaultProps = {};
const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for input text with material design styling
 */
const DatePickerMaterial = ( {
  id,
  name,
  title,
  onBlur,
  onChange,
  disabled,
  startDate,
  maxDate,
  readOnly,
  hasValidation,
  validationType,
  validationReason,
  hasValidationRecoil
} ) => {

  /**
   * Validation Component
   * @type {Object}
   */
  const validation = <InputValidationMsg
    id={ name + '-validation-msg' }
    type={ validationType }
    visible={ hasValidation }
    message={ validationReason }
    top={ hasValidationRecoil ? '-18px' : '' }
  />;

  return (
    <React.Fragment>
      <div className="form-group datepicker-material">
        <label>{ title }</label>
        <DatePicker
          id={ id }
          name={ name }
          selected={ startDate }
          maxDate={ maxDate }
          readOnly={ readOnly }
          onBlur={ onBlur }
          onChange={ onChange }
          showYearDropdown
          dateFormatCalendar="MMMM"
          scrollableYearDropdown
          yearDropdownItemNumber={ 100 }
          disabled={ disabled }
          autoComplete="off"
          />
      </div>
      { validation }
    </React.Fragment>
  );

}

DatePickerMaterial.defaultProps = defaultProps;
DatePickerMaterial.propTypes = propTypes;

export default DatePickerMaterial;