import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import { commonReportRules, easRules } from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    volume,
    colorOptionId,
    smellOptionId,
    aspectOptionId,
    density,
    proteinsOptionId,
    glucoseOptionId,
    acetoneOptionId,
    ph,
    bilirubinOptionId,
    urobilinogenOptionId,
    hemoglobinOptionId,
    leukocyteOptionId,
    nitriteOptionId,
    epithelialCellsOptionTags,
    ridgesOptionTags,
    castsOptionTags,
    bacterialFloraOptionId,
    spermatozoaOptionId,
    obs,
    isErythrocytesAbsent,
    isErythrocytesUncountable,
    erythrocytesMin,
    erythrocytesMax,
    isPusCellsAbsent,
    isPusCellsUncountable,
    pusCellsMin,
    pusCellsMax,
    vetSignerId    
  } = props.data.fields;

  const {completeValidation} = props.data;

  const fields = [
    'volume',
    'colorOptionId',
    'smellOptionId',
    'aspectOptionId',
    'density',
    'proteinsOptionId',
    'glucoseOptionId',
    'acetoneOptionId',
    'ph',
    'bilirubinOptionId',
    'urobilinogenOptionId',
    'hemoglobinOptionId',
    'leukocyteOptionId',
    'nitriteOptionId',
    'epithelialCellsOptionTags',
    'erythrocytes',
    'pusCells',
    'ridgesOptionTags',
    'castsOptionTags',
    'bacterialFloraOptionId',
    'spermatozoaOptionId',
    'obs',
    'vetSignerId'
  ];

  const commonValidation = commonReportRules( vetSignerId  );

  const easValidation = easRules(
    'volume',
    volume,
    'colorOptionId',
    colorOptionId,
    'smellOptionId',
    smellOptionId,
    'aspectOptionId',
    aspectOptionId,
    'density',
    density,
    'proteinsOptionId',
    proteinsOptionId,
    'glucoseOptionId',
    glucoseOptionId,
    'acetoneOptionId',
    acetoneOptionId,
    'ph',
    ph,
    'bilirubinOptionId',
    bilirubinOptionId,
    'urobilinogenOptionId',
    urobilinogenOptionId,
    'hemoglobinOptionId',
    hemoglobinOptionId,
    'leukocyteOptionId',
    leukocyteOptionId,
    'nitriteOptionId',
    nitriteOptionId,
    'epithelialCellsOptionTags',
    epithelialCellsOptionTags,
    'ridgesOptionTags',
    ridgesOptionTags,
    'castsOptionTags',
    castsOptionTags,
    'bacterialFloraOptionId',
    bacterialFloraOptionId,
    'spermatozoaOptionId',
    spermatozoaOptionId,
    'obs',
    obs,
    isErythrocytesAbsent,
    isErythrocytesUncountable,
    erythrocytesMin,
    erythrocytesMax,
    isPusCellsAbsent,
    isPusCellsUncountable,
    pusCellsMin,
    pusCellsMax,
    props.data.completeValidation,
    completeValidation 
  );

  const validations = {
    ...commonValidation,
    ...easValidation
  };

  return { fields, validations };
} );

export default validation;