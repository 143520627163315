import connect from "../../api-connector";
import { StringUtil } from "./../../useful";

const connectWithEndpoint = () => ({
  
    examRecords: (options, success, error) => {
        let queryString = StringUtil.buildQueryString(options);    
      return {
        searched: {
          url: `/exams/lab-records${queryString}`,
          method: "GET",
          then: success,
          catch: error
        }
      };
    },
    regions: "/customers/lab/regions",
    customers: "/customers/all"
  });  

export default connectWithEndpoint;
