import React from 'react';
import PropTypes from 'prop-types';

/**
 * External
 */
import FlatPanelMaterial from './../FlatPanelMaterial';
import NumberInputMaterial2 from '../NumberInputMaterial2';
import SelectMaterial2 from '../SelectMaterial2';

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a ChemicalExamFields form
 */
const ChemicalExamFields = ( {
  proteinsOptionIdFieldName,
  proteinsOptionId,
  proteinsOptionIdHasValidation,
  proteinsOptionIdValidationReason,
  proteinsOptions,
  proteinsRefs,
  glucoseOptionIdFieldName,
  glucoseOptionId,
  glucoseOptionIdHasValidation,
  glucoseOptionIdValidationReason,
  glucoseOptions,
  glucoseRefs,
  acetoneOptionIdFieldName,
  acetoneOptionId,
  acetoneOptionIdHasValidation,
  acetoneOptionIdValidationReason,
  acetoneOptions,
  acetoneRefs,
  phFieldName,
  ph,
  phHasValidation,
  phValidationReason,
  phRefs,
  bilirubinOptionIdFieldName,
  bilirubinOptionId,
  bilirubinOptionIdHasValidation,
  bilirubinOptionIdValidationReason,
  bilirubinOptions,
  bilirubinRefs,
  urobilinogenOptionIdFieldName,
  urobilinogenOptionId,
  urobilinogenOptionIdHasValidation,
  urobilinogenOptionIdValidationReason,
  urobilinogenOptions,
  urobilinogenRefs,
  hemoglobinOptionIdFieldName,
  hemoglobinOptionId,
  hemoglobinOptionIdHasValidation,
  hemoglobinOptionIdValidationReason,
  hemoglobinOptions,
  hemoglobinRefs,
  leukocyteOptionIdFieldName,
  leukocyteOptionId,
  leukocyteOptionIdHasValidation,
  leukocyteOptionIdValidationReason,
  leukocyteOptions,
  leukocyteRefs,
  nitriteOptionIdFieldName,
  nitriteOptionId,
  nitriteOptionIdHasValidation,
  nitriteOptionIdValidationReason,
  nitriteOptions,
  nitriteRefs,
  handleChange,
  handleBlur,
  shouldDisable
} ) =>
  <div className="chemicalExamForm tight-fields">
    <FlatPanelMaterial title="Exame Químico">
      <div className='no-padding-cols distante-rows'>
        <div className='row'>
          <div className='col-xs-12'>
            <div className='col-xs-5 col-xs-offset-3 text-center'>
              <p className='cleanItem'>Resultado</p>
            </div>
            <div className='col-xs-4 text-center'>
              <p className='cleanItem'>Valor Normal</p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-3 text-right'>
            <p className='mr-20 cleanItem'>Proteínas</p>
          </div>
          <div className='col-xs-5'>
              <SelectMaterial2
                title=""
                name={ proteinsOptionIdFieldName }
                options={ proteinsOptions }
                value= { proteinsOptionId }
                isSearchable={ true }
                hasValidationRecoil={ false }
                shouldDisable={ shouldDisable }
                onChange={ handleChange }
                onBlur={ handleBlur }
                hasValidation={ proteinsOptionIdHasValidation }
                validationReason={ proteinsOptionIdValidationReason } />
          </div>
          <div className='col-xs-4'>
            <div className='text-center cleanItem'>
              <p>
                { proteinsRefs }
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-3 text-right'>
            <p className='mr-20 cleanItem'>Glicose</p>
          </div>
          <div className='col-xs-5'>
            <SelectMaterial2
              title=""
              name={ glucoseOptionIdFieldName }
              options={ glucoseOptions }
              value= { glucoseOptionId }
              isSearchable={ true }
              hasValidationRecoil={ false }
              shouldDisable={ shouldDisable }
              onChange={ handleChange }
              onBlur={ handleBlur }
              hasValidation={ glucoseOptionIdHasValidation }
              validationReason={ glucoseOptionIdValidationReason } />
          </div>
          <div className='col-xs-4'>
            <div className='text-center cleanItem'>
              <p>
                { glucoseRefs }
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-3 text-right'>
            <p className='mr-20 cleanItem'>Acetona</p>
          </div>
          <div className='col-xs-5'>
            <SelectMaterial2
              title=""
              name={ acetoneOptionIdFieldName }
              options={ acetoneOptions }
              value= { acetoneOptionId }
              isSearchable={ true }
              hasValidationRecoil={ false }
              shouldDisable={ shouldDisable }
              onChange={ handleChange }
              onBlur={ handleBlur }
              hasValidation={ acetoneOptionIdHasValidation }
              validationReason={ acetoneOptionIdValidationReason } />
          </div>
          <div className='col-xs-4'>
            <div className='text-center cleanItem'>
              <p>
                { acetoneRefs }
              </p>
            </div>
          </div>
        </div>

        <div className='row mt-5'>
          <div className='col-xs-3 text-right'>
            <p className='mr-20 cleanItem'>PH</p>
          </div>
          <div className='col-xs-5'>
            <NumberInputMaterial2
              title=""
              name={ phFieldName }
              hasValidationRecoil={ false }
              decimalScale={ 1 }
              allowNegative={ false }
              value={ ph }
              disabled={ shouldDisable }
              handleChange={ handleChange }
              handleBlur={ handleBlur }
              hasValidation={ phHasValidation }
              validationReason={ phValidationReason } />
          </div>
          <div className='col-xs-4'>
            <div className='text-center cleanItem'>
              <p>
                { phRefs }
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-3 text-right'>
            <p className='mr-20 cleanItem'>Bilirrubina</p>
          </div>
          <div className='col-xs-5'>
            <SelectMaterial2
              title=""
              name={ bilirubinOptionIdFieldName }
              options={ bilirubinOptions }
              value= { bilirubinOptionId }
              isSearchable={ true }
              hasValidationRecoil={ false }
              shouldDisable={ shouldDisable }
              onChange={ handleChange }
              onBlur={ handleBlur }
              hasValidation={ bilirubinOptionIdHasValidation }
              validationReason={ bilirubinOptionIdValidationReason } />
          </div>
          <div className='col-xs-4'>
            <div className='text-center cleanItem'>
              <p>
                { bilirubinRefs }
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-3 text-right'>
            <p className='mr-20 cleanItem'>Urobilinogênio</p>
          </div>
          <div className='col-xs-5'>
            <SelectMaterial2
              title=""
              name={ urobilinogenOptionIdFieldName }
              options={ urobilinogenOptions }
              value= { urobilinogenOptionId }
              isSearchable={ true }
              hasValidationRecoil={ false }
              shouldDisable={ shouldDisable }
              onChange={ handleChange }
              onBlur={ handleBlur }
              hasValidation={ urobilinogenOptionIdHasValidation }
              validationReason={ urobilinogenOptionIdValidationReason } />
          </div>
          <div className='col-xs-4'>
            <div className='text-center cleanItem'>
              <p>
                { urobilinogenRefs }
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-3 text-right'>
            <p className='mr-20 cleanItem'>Hemoglobina</p>
          </div>
          <div className='col-xs-5'>
            <SelectMaterial2
              title=""
              name={ hemoglobinOptionIdFieldName }
              options={ hemoglobinOptions }
              value= { hemoglobinOptionId }
              isSearchable={ true }
              hasValidationRecoil={ false }
              shouldDisable={ shouldDisable }
              onChange={ handleChange }
              onBlur={ handleBlur }
              hasValidation={ hemoglobinOptionIdHasValidation }
              validationReason={ hemoglobinOptionIdValidationReason } />
          </div>
          <div className='col-xs-4'>
            <div className='text-center cleanItem'>
              <p>
                { hemoglobinRefs }
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-3 text-right'>
            <p className='mr-20 cleanItem'>Leucócito</p>
          </div>
          <div className='col-xs-5'>
            <SelectMaterial2
              title=""
              name={ leukocyteOptionIdFieldName }
              options={ leukocyteOptions }
              value= { leukocyteOptionId }
              isSearchable={ true }
              hasValidationRecoil={ false }
              shouldDisable={ shouldDisable }
              onChange={ handleChange }
              onBlur={ handleBlur }
              hasValidation={ leukocyteOptionIdHasValidation }
              validationReason={ leukocyteOptionIdValidationReason } />
          </div>
          <div className='col-xs-4'>
            <div className='text-center cleanItem'>
              <p>
                { leukocyteRefs }
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-3 text-right'>
            <p className='mr-20 cleanItem'>Nitrito</p>
          </div>
          <div className='col-xs-5'>
            <SelectMaterial2
              title=""
              name={ nitriteOptionIdFieldName }
              options={ nitriteOptions }
              value= { nitriteOptionId }
              isSearchable={ true }
              hasValidationRecoil={ false }
              shouldDisable={ shouldDisable }
              onChange={ handleChange }
              onBlur={ handleBlur }
              hasValidation={ nitriteOptionIdHasValidation }
              validationReason={ nitriteOptionIdValidationReason } />
          </div>
          <div className='col-xs-4'>
            <div className='text-center cleanItem'>
              <p>
                { nitriteRefs }
              </p>
            </div>
          </div>
        </div>
      </div>
    </FlatPanelMaterial>
  </div>

export default ChemicalExamFields;