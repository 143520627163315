import React from "react";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "../PanelMaterialBody";
import ErythrogramDiagnosisContent from "../ErythrogramDiagnosisContent";

/**
 * @author Alessandro Bastos Grandini
 *
 * Erythrogram diagnosis panel
 *
 */
const ErythrogramDiagnosisPanel = ({
  obs,
  diagnosisFn,
  shouldDisable,
  $validation,
  fieldChange,
  $field,
  diagnosisInput,
}) => (
  <PanelMaterial className="pt-5" title="Diagnóstico" flat={true}>
    <PanelMaterialTop title="Diagnóstico" />
    <PanelMaterialBody>
      <ErythrogramDiagnosisContent
        obs={obs}
        diagnosisFn={diagnosisFn}
        shouldDisable={shouldDisable}
        $validation={$validation}
        fieldChange={fieldChange}
        $field={$field}
        diagnosisInput={diagnosisInput}
      />
    </PanelMaterialBody>
  </PanelMaterial>
);

ErythrogramDiagnosisPanel.propTypes = {};

export default ErythrogramDiagnosisPanel;
