import { validated } from "react-custom-validation";
import { required, greaterThan } from "../../assets/js/validationFunctions.js";

import { REQUIRED_VALIDATION_MESSAGE } from "../../assets/js/Consts.js";
import { commonReportRules } from "../../assets/js/validationRules";

const validation = validated((props) => {
  const {
    vetSignerId,
    requestExamState,
    result,
    materialId,
    carbonate,
    phosphate,
    urato,
    oxalate,
    magnesium,
    cistina,
    calcium,
    ammonium,
    consistency,
    surface,
    shape,
    dimensionX,
    dimensionY,
    dimensionZ,
    weight,
    color,
  } = props.data.fields;

  const commonValidation = commonReportRules(vetSignerId, requestExamState);

  const { completeValidation } = props.data;

  const validations = {
    ...commonValidation,
    materialId: {
      rules: completeValidation
        ? [[greaterThan, materialId, 0, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    consistency: {
      rules: completeValidation
        ? [[required, consistency, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    surface: {
      rules: completeValidation
        ? [[required, surface, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    shape: {
      rules: completeValidation
        ? [[required, shape, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    carbonate: {
      rules: completeValidation
        ? [[greaterThan, carbonate, 0, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    phosphate: {
      rules: completeValidation
        ? [[greaterThan, phosphate, 0, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    urato: {
      rules: completeValidation
        ? [[greaterThan, urato, 0, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    oxalate: {
      rules: completeValidation
        ? [[greaterThan, oxalate, 0, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    magnesium: {
      rules: completeValidation
        ? [[greaterThan, magnesium, 0, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    cistina: {
      rules: completeValidation
        ? [[greaterThan, cistina, 0, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    calcium: {
      rules: completeValidation
        ? [[greaterThan, calcium, 0, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    ammonium: {
      rules: completeValidation
        ? [[greaterThan, ammonium, 0, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    dimensionX: {
      rules: completeValidation
        ? [[required, dimensionX, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    dimensionY: {
      rules: completeValidation
        ? [[required, dimensionY, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    dimensionZ: {
      rules: completeValidation
        ? [[required, dimensionZ, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    weight: {
      rules: completeValidation
        ? [[required, weight, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    color: {
      rules: completeValidation
        ? [[required, color, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    result: {
      rules: completeValidation
        ? [[required, result, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
  };

  let fields = [
    "requestExamState",
    "vetSignerId",
    "materialId",
    "result",
    "carbonate",
    "phosphate",
    "urato",
    "oxalate",
    "magnesium",
    "cistina",
    "calcium",
    "ammonium",
    "consistency",
    "surface",
    "shape",
    "dimensionX",
    "dimensionY",
    "dimensionZ",
    "weight",
    "color",
  ];

  return { fields, validations };
});

export default validation;
