import React, { useEffect, useState } from "react";
import GetUserTokenBasedOnEnv from "./../../services/get-user-token-based-on-env/index";
import { FetchOptions, OnSuccess, OnError } from "./../../types/index";

export const useFetch = <T = any>() => {
  const defaultResponse: T | null = null;
  const [response, setResponse] = useState<T | null>(defaultResponse);
  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);

  const runFetch = (
    url: string,
    options: FetchOptions = {
      method: "GET",
    },
    onSuccess: OnSuccess = () => {},
    onError: OnError = () => {}
  ) => {
    options.headers = {
      "Content-Type": "application/json",
      authorization: GetUserTokenBasedOnEnv()
    };
    fetch(url, options)
      .then((response: any) => {
        response
          .json()
          .then((data: any) => {
            setResponse(data);
            setPending(false);
            onSuccess(data);
          })
          .catch((error: any) => {
            console.error(error);
            setResponse(null);
            setPending(false);
            onSuccess(null);
          });
      })
      .catch((error: string) => {
        console.error(error);
        setError(error);
        setPending(false);
        onError(error);
      });
    setPending(true);
  };

  const reset = () => {
    setError("");
    setResponse(null);
  };

  return {
    data: response,
    error: error,
    pending: pending,
    fulfilled: (response !== null || error !== "") && !pending,
    fetch: runFetch,
    reset: reset,
  };
};
