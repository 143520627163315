import React from "react";
import styled from "styled-components";

const StyledFormGroup = styled.div`
  margin-bottom: 20px;
`;

function handleState(children, key, { errorMessage }) {
  switch (children.type.name) {
    case "Input":
    case "Select":
    case "TextArea": {
      return React.cloneElement(children, {
        key: key,
        errorMessage: errorMessage,
      });
    }
    case "InputLabel": {
      const props = { hasError: errorMessage ? true : false, key: key };
      return React.cloneElement(children, props);
    }
    default:
      return children;
  }
}

const getChildren = (props) => {
  return props.children.length
    ? props.children.map((children, key) => handleState(children, key, props))
    : handleState(props.children, props);
};

const FormGroup = (props) => (
  <StyledFormGroup {...props}>
    {props.noError ? props.children : getChildren(props)}
  </StyledFormGroup>
);

export default FormGroup;
