import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import NumberInputMaterial from "./../NumberInputMaterial";
import TextAreaPanel2 from "./../TextAreaPanel2";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Row, Col } from "./../../components/grid";
import NumberDisplay from "./../NumberDisplay";
import FlatPanelMaterial from "./../FlatPanelMaterial";

const CortisolCreatinineRatioReportFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Relação Cortisol/Creatinina"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FlatPanelMaterial title="Resultado">
          <NumberInputMaterial
            id="cortisol"
            title="Cortisol"
            name="cortisol"
            md={3}
            value={data.fields.cortisol}
            disabled={data.disableAll}
            inputGroupAfter="mg/dL"
            inputGroup={true}
            allowNegative={false}
            decimalScale={2}
            fieldChange={methods.fieldChange}
            {...methods.$field("cortisol")}
            hasValidation={
              data.$validation.cortisol && data.$validation.cortisol.show
            }
            validationReason={
              data.$validation.cortisol &&
              data.$validation.cortisol.error.reason
            }
            validationType="danger"
            hasValidationRecoil={false}
          />
          <NumberInputMaterial
            id="creatinine"
            title="Creatinine"
            name="creatinine"
            md={3}
            value={data.fields.creatinine}
            disabled={data.disableAll}
            inputGroupAfter="mg/dL"
            inputGroup={true}
            allowNegative={false}
            decimalScale={2}
            fieldChange={methods.fieldChange}
            {...methods.$field("creatinine")}
            hasValidation={
              data.$validation.creatinine && data.$validation.creatinine.show
            }
            validationReason={
              data.$validation.creatinine &&
              data.$validation.creatinine.error.reason
            }
            validationType="danger"
            hasValidationRecoil={false}
          />
          <NumberInputMaterial
            id="result"
            title="Resultado"
            name="result"
            md={3}
            value={methods.calculateRatio()}
            disabled={true}
            allowNegative={false}
            decimalScale={2}
          />
          <Col md={3} className="mt-10">
            <div className="text-center"> Valor Normal </div>
            <p className="text-center">
              <NumberDisplay
                value={data.refData.min}
                decimalScale={1}
                suffix={` - `}
              />
              <NumberDisplay
                value={data.refData.max}
                decimalScale={1}
                suffix={` mg/dL`}
              />
            </p>
          </Col>
        </FlatPanelMaterial>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <TextAreaPanel2
          title="Obs"
          fieldTitle={""}
          name={"obs"}
          value={data.fields.obs}
          disabled={data.disableAll}
          handleChange={methods.handleChange}
          handleBlur={methods.handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default CortisolCreatinineRatioReportFormView;
