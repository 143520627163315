import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import NumberInputMaterial2 from "./../../NumberInputMaterial2";

const initialData = { igaMin: "", igaMax: "" };

export default function ImmunologyReference({
  data,
  setField,
  extract,
  loading,
  loadInitialData,
}) {
  useEffect(() => {
    loadInitialData(initialData);
  }, []);

  return (
    <Row className="mt-20">
      <Col md={2}>
        <p>Iga</p>
      </Col>
      <Col md={2}>
        <NumberInputMaterial2
          name={"igaMin"}
          title={"Min"}
          inputGroupAfter=""
          inputGroup={true}
          hasValidationRecoil={true}
          decimalScale={1}
          fit={true}
          block={true}
          inputGroup={true}
          allowNegative={false}
          value={extract(data, "igaMin", "")}
          disabled={loading}
          shouldDisableThousandSeparator={false}
          handleChange={(field, value) => setField("igaMin", value)}
        />
      </Col>
      <Col md={2}>
        <NumberInputMaterial2
          name={"igaMax"}
          title={"Max"}
          inputGroupAfter=""
          inputGroup={true}
          hasValidationRecoil={true}
          decimalScale={1}
          fit={true}
          block={true}
          inputGroup={true}
          allowNegative={false}
          value={extract(data, "igaMax", "")}
          disabled={loading}
          shouldDisableThousandSeparator={false}
          handleChange={(field, value) => setField("igaMax", value)}
        />
      </Col>
    </Row>
  );
}
