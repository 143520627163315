import React from "react";

/**
 * External
 */
import PropTypes from "prop-types";
import { Row, Col } from "./../../components/grid";
import NumberFormat from "react-number-format";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import NumberInputMaterial from "./../NumberInputMaterial";
import PanelMaterialBody from "../PanelMaterialBody";
import ButtonDefaultMaterial from "../ButtonDefaultMaterial";
import PanelMaterialTop from "../PanelMaterialTop";

const basophilComponent = value => {
  if ("Rare" === value) {
    return value;
  }

  return (
    <NumberFormat
      displayType={"text"}
      value={value}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={0}
    />
  );
};

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 * @author Victor Heringer
 *
 * Component that represents a LeukogramPanel form
 *
 */
const LeukogramPanel = ({
  title,
  isIntenseLeucopeny,
  relativeBasophils,
  relativeEosinophils,
  relativeMyelocytes,
  relativeMetamyelocytes,
  relativeBands,
  relativeSegs,
  relativeLymphocytes,
  relativeMonocytes,
  relativeHeterophils,
  disableAll,
  $validation,
  refs,
  total,
  fieldChange,
  $field,
  calcAbsolute,
  resetLeukogram,
  onClickCounter
}) => (
  <React.Fragment>
    <PanelMaterial className="pb-15" flat={true}>
      <PanelMaterialTop title={title} />
      <PanelMaterialBody>
        <div className="no-padding-cols distante-rows">
          <Row className="mb-5">
            <Col xs={12} className="text-center">
              <Col xs={4} mdOffset={3}>
                <p className="cleanItem">Leucometria Específica</p>
              </Col>
              <Col xs={5}>
                <p className="cleanItem">Valor Normal</p>
              </Col>
            </Col>
            <Col xs={12} className="text-center">
              <Col xs={4} mdOffset={3}>
                <div className="cleanItem">
                  <Col xs={6}>V. Relat. (%)</Col>
                  <Col xs={6}>
                    V. Absol. <span className="transform-lower">(μl)</span>
                  </Col>
                </div>
              </Col>
              <Col xs={5}>
                <div className="cleanItem">
                  <Col xs={6}>V. Relat. (%)</Col>
                  <Col xs={6}>
                    V. Absol. <span className="transform-lower">(μl)</span>
                  </Col>
                </div>
              </Col>
            </Col>
          </Row>

          <Row>
            <Col xs={3} className="text-right">
              <p className="mr-20 cleanItem">Basófilio</p>
            </Col>
            <Col xs={4}>
              <NumberInputMaterial
                name="relativeBasophils"
                md={6}
                inputGroup={true}
                allowNegative={false}
                containerClassName="pr-10"
                decimalScale={0}
                value={isIntenseLeucopeny ? "" : relativeBasophils}
                disabled={isIntenseLeucopeny || disableAll}
                fieldChange={fieldChange}
                {...$field("relativeBasophils")}
                hasValidation={
                  $validation.relativeBasophils &&
                  $validation.relativeBasophils.show
                }
                validationReason={
                  $validation.relativeBasophils &&
                  $validation.relativeBasophils.error.reason
                }
                hasValidationRecoil={false}
              />

              <NumberInputMaterial
                name="absoluteBasophils"
                md={6}
                allowNegative={false}
                inputGroup={true}
                value={
                  isIntenseLeucopeny ? "" : calcAbsolute(relativeBasophils)
                }
                disabled={true}
                decimalScale={0}
              />
            </Col>
            <Col xs={5}>
              <Col xs={3} className="text-center cleanItem">
                <p>{basophilComponent(refs.relativeBasophilsMin)}</p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>{basophilComponent(refs.relativeBasophilsMax)}</p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.absoluteBasophilsMin}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.absoluteBasophilsMax}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
            </Col>
          </Row>

          <Row>
            <Col xs={3} className="text-right">
              <p className="mr-20 cleanItem">Eosinófilo</p>
            </Col>
            <Col xs={4}>
              <NumberInputMaterial
                name="relativeEosinophils"
                md={6}
                allowNegative={false}
                inputGroup={true}
                containerClassName="pr-10"
                decimalScale={0}
                value={isIntenseLeucopeny ? "" : relativeEosinophils}
                disabled={isIntenseLeucopeny || disableAll}
                fieldChange={fieldChange}
                {...$field("relativeEosinophils")}
                hasValidation={
                  $validation.relativeEosinophils &&
                  $validation.relativeEosinophils.show
                }
                validationReason={
                  $validation.relativeEosinophils &&
                  $validation.relativeEosinophils.error.reason
                }
                hasValidationRecoil={false}
              />
              <NumberInputMaterial
                name="absoluteEosinophils"
                md={6}
                allowNegative={false}
                inputGroup={true}
                value={
                  isIntenseLeucopeny ? "" : calcAbsolute(relativeEosinophils)
                }
                disabled={true}
                decimalScale={0}
              />
            </Col>
            <Col xs={5}>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.relativeEosinophilsMin}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.relativeEosinophilsMax}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.absoluteEosinophilsMin}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.absoluteEosinophilsMax}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
            </Col>
          </Row>

          {!refs.hasHeterophils && (
            <Row>
              <Col xs={3} className="text-right">
                <p className="mr-20 cleanItem">Mielócito</p>
              </Col>
              <Col xs={4}>
                <NumberInputMaterial
                  name="relativeMyelocytes"
                  md={6}
                  allowNegative={false}
                  inputGroup={true}
                  containerClassName="pr-10"
                  decimalScale={0}
                  value={isIntenseLeucopeny ? "" : relativeMyelocytes}
                  disabled={isIntenseLeucopeny || disableAll}
                  fieldChange={fieldChange}
                  {...$field("relativeMyelocytes")}
                  hasValidation={
                    $validation.relativeMyelocytes &&
                    $validation.relativeMyelocytes.show
                  }
                  validationReason={
                    $validation.relativeMyelocytes &&
                    $validation.relativeMyelocytes.error.reason
                  }
                  hasValidationRecoil={false}
                />
                <NumberInputMaterial
                  name="absoluteMyelocytes"
                  md={6}
                  allowNegative={false}
                  inputGroup={true}
                  value={
                    isIntenseLeucopeny ? "" : calcAbsolute(relativeMyelocytes)
                  }
                  disabled={true}
                  decimalScale={0}
                />
              </Col>
              <Col xs={5}>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.relativeMyelocytesMin}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.relativeMyelocytesMax}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.absoluteMyelocytesMin}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.absoluteMyelocytesMax}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
              </Col>
            </Row>
          )}

          {!refs.hasHeterophils && (
            <Row>
              <Col xs={3} className="text-right">
                <p className="mr-20 cleanItem">Metamielócito</p>
              </Col>
              <Col xs={4}>
                <NumberInputMaterial
                  name="relativeMetamyelocytes"
                  md={6}
                  allowNegative={false}
                  inputGroup={true}
                  containerClassName="pr-10"
                  decimalScale={0}
                  value={isIntenseLeucopeny ? "" : relativeMetamyelocytes}
                  disabled={isIntenseLeucopeny || disableAll}
                  fieldChange={fieldChange}
                  {...$field("relativeMetamyelocytes")}
                  hasValidation={
                    $validation.relativeMetamyelocytes &&
                    $validation.relativeMetamyelocytes.show
                  }
                  validationReason={
                    $validation.relativeMetamyelocytes &&
                    $validation.relativeMetamyelocytes.error.reason
                  }
                  hasValidationRecoil={false}
                />
                <NumberInputMaterial
                  name="absoluteMetamyelocytes"
                  md={6}
                  allowNegative={false}
                  inputGroup={true}
                  value={
                    isIntenseLeucopeny
                      ? ""
                      : calcAbsolute(relativeMetamyelocytes)
                  }
                  disabled={true}
                  decimalScale={0}
                />
              </Col>
              <Col xs={5}>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.relativeMetamyelocytesMin}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.relativeMetamyelocytesMax}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.absoluteMetamyelocytesMin}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.absoluteMetamyelocytesMax}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
              </Col>
            </Row>
          )}

          {!refs.hasHeterophils && (
            <Row>
              <Col xs={3} className="text-right">
                <p className="mr-20 cleanItem">Bastão</p>
              </Col>
              <Col xs={4}>
                <NumberInputMaterial
                  name="relativeBands"
                  md={6}
                  allowNegative={false}
                  inputGroup={true}
                  containerClassName="pr-10"
                  decimalScale={0}
                  value={isIntenseLeucopeny ? "" : relativeBands}
                  disabled={isIntenseLeucopeny || disableAll}
                  fieldChange={fieldChange}
                  {...$field("relativeBands")}
                  hasValidation={
                    $validation.relativeBands && $validation.relativeBands.show
                  }
                  validationReason={
                    $validation.relativeBands &&
                    $validation.relativeBands.error.reason
                  }
                  hasValidationRecoil={false}
                />
                <NumberInputMaterial
                  name="absoluteBands"
                  md={6}
                  allowNegative={false}
                  inputGroup={true}
                  value={isIntenseLeucopeny ? "" : calcAbsolute(relativeBands)}
                  disabled={true}
                  decimalScale={0}
                />
              </Col>
              <Col xs={5}>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.relativeBandsMin}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.relativeBandsMax}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.absoluteBandsMin}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.absoluteBandsMax}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
              </Col>
            </Row>
          )}

          {!refs.hasHeterophils && (
            <Row>
              <Col xs={3} className="text-right">
                <p className="mr-20 cleanItem">Segmentado</p>
              </Col>
              <Col xs={4}>
                <NumberInputMaterial
                  name="relativeSegs"
                  md={6}
                  allowNegative={false}
                  inputGroup={true}
                  containerClassName="pr-10"
                  decimalScale={0}
                  value={isIntenseLeucopeny ? "" : relativeSegs}
                  disabled={isIntenseLeucopeny || disableAll}
                  fieldChange={fieldChange}
                  {...$field("relativeSegs")}
                  hasValidation={
                    $validation.relativeSegs && $validation.relativeSegs.show
                  }
                  validationReason={
                    $validation.relativeSegs &&
                    $validation.relativeSegs.error.reason
                  }
                  hasValidationRecoil={false}
                />
                <NumberInputMaterial
                  name="absoluteSegs"
                  md={6}
                  allowNegative={false}
                  inputGroup={true}
                  value={isIntenseLeucopeny ? "" : calcAbsolute(relativeSegs)}
                  disabled={true}
                  decimalScale={0}
                />
              </Col>
              <Col xs={5}>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.relativeSegsMin}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.relativeSegsMax}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.absoluteSegsMin}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.absoluteSegsMax}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={3} className="text-right">
              <p className="mr-20 cleanItem">Linfócito</p>
            </Col>
            <Col xs={4}>
              <NumberInputMaterial
                name="relativeLymphocytes"
                md={6}
                allowNegative={false}
                inputGroup={true}
                containerClassName="pr-10"
                decimalScale={0}
                value={isIntenseLeucopeny ? "" : relativeLymphocytes}
                disabled={isIntenseLeucopeny || disableAll}
                fieldChange={fieldChange}
                {...$field("relativeLymphocytes")}
                hasValidation={
                  $validation.relativeLymphocytes &&
                  $validation.relativeLymphocytes.show
                }
                validationReason={
                  $validation.relativeLymphocytes &&
                  $validation.relativeLymphocytes.error.reason
                }
                hasValidationRecoil={false}
              />
              <NumberInputMaterial
                name="absoluteLymphocytes"
                md={6}
                allowNegative={false}
                inputGroup={true}
                value={
                  isIntenseLeucopeny ? "" : calcAbsolute(relativeLymphocytes)
                }
                disabled={true}
                decimalScale={0}
              />
            </Col>
            <Col xs={5}>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.relativeLymphocytesMin}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.relativeLymphocytesMax}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.absoluteLymphocytesMin}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.absoluteLymphocytesMax}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
            </Col>
          </Row>

          <Row>
            <Col xs={3} className="text-right">
              <p className="mr-20 cleanItem">Monócito</p>
            </Col>
            <Col xs={4}>
              <NumberInputMaterial
                name="relativeMonocytes"
                md={6}
                allowNegative={false}
                inputGroup={true}
                containerClassName="pr-10"
                decimalScale={0}
                value={isIntenseLeucopeny ? "" : relativeMonocytes}
                disabled={isIntenseLeucopeny || disableAll}
                fieldChange={fieldChange}
                {...$field("relativeMonocytes")}
                hasValidation={
                  $validation.relativeMonocytes &&
                  $validation.relativeMonocytes.show
                }
                validationReason={
                  $validation.relativeMonocytes &&
                  $validation.relativeMonocytes.error.reason
                }
                hasValidationRecoil={false}
              />
              <NumberInputMaterial
                name="absoluteMonocytes"
                md={6}
                allowNegative={false}
                inputGroup={true}
                value={
                  isIntenseLeucopeny ? "" : calcAbsolute(relativeMonocytes)
                }
                disabled={true}
                decimalScale={0}
              />
            </Col>
            <Col xs={5}>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.relativeMonocytesMin}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.relativeMonocytesMax}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.absoluteMonocytesMin}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
              <Col xs={3} className="text-center cleanItem">
                <p>
                  <NumberFormat
                    displayType={"text"}
                    value={refs.absoluteMonocytesMax}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                  />
                </p>
              </Col>
            </Col>
          </Row>

          {refs.hasHeterophils && (
            <Row>
              <Col xs={3} className="text-right">
                <p className="mr-20 cleanItem">Heterófilo</p>
              </Col>
              <Col xs={4}>
                <NumberInputMaterial
                  name="relativeHeterophils"
                  md={6}
                  allowNegative={false}
                  inputGroup={true}
                  containerClassName="pr-10"
                  decimalScale={0}
                  value={isIntenseLeucopeny ? "" : relativeHeterophils}
                  disabled={isIntenseLeucopeny || disableAll}
                  fieldChange={fieldChange}
                  {...$field("relativeHeterophils")}
                  hasValidation={
                    $validation.relativeHeterophils &&
                    $validation.relativeHeterophils.show
                  }
                  validationReason={
                    $validation.relativeHeterophils &&
                    $validation.relativeHeterophils.error.reason
                  }
                  hasValidationRecoil={false}
                />
                <NumberInputMaterial
                  name="absoluteHeterophils"
                  md={6}
                  allowNegative={false}
                  inputGroup={true}
                  value={
                    isIntenseLeucopeny ? "" : calcAbsolute(relativeHeterophils)
                  }
                  disabled={true}
                  decimalScale={0}
                />
              </Col>
              <Col xs={5}>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.relativeHeterophilsMin}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.relativeHeterophilsMax}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.absoluteHeterophilsMin}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
                <Col xs={3} className="text-center cleanItem">
                  <p>
                    <NumberFormat
                      displayType={"text"}
                      value={refs.absoluteHeterophilsMax}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                    />
                  </p>
                </Col>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={12}>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col md={3} className="text-right">
              <p className="mr-20 mt-5 cleanItem">Total</p>
            </Col>
            <Col md={3}>
              <NumberInputMaterial
                name="total"
                md={12}
                allowNegative={false}
                inputGroup={true}
                block={true}
                className={"mt-5"}
                value={isIntenseLeucopeny ? "" : total}
                disabled={true}
                decimalScale={0}
                {...$field("total")}
                hasValidation={$validation.total && $validation.total.show}
                validationReason={
                  $validation.total && $validation.total.error.reason
                }
                hasValidationRecoil={false}
              />
            </Col>
            <React.Fragment>
              <Col md={3} className="pl-20 pr-5">
                <ButtonDefaultMaterial
                  title="Contador"
                  type="highlight"
                  block={true}
                  onClick={onClickCounter}
                  disabled={isIntenseLeucopeny || disableAll}
                />
              </Col>
              <Col md={1} className="pl-5 pr-10">
                <ButtonDefaultMaterial
                  title="Reiniciar"
                  type="default"
                  block={true}
                  onClick={resetLeukogram}
                  disabled={isIntenseLeucopeny || disableAll}
                />
              </Col>
            </React.Fragment>
          </Row>
        </div>
      </PanelMaterialBody>
    </PanelMaterial>
  </React.Fragment>
);

LeukogramPanel.propTypes = propTypes;

export default LeukogramPanel;
