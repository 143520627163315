export const connectFormWithEndpoint = props => ({
  createTicket: (obj, then, error) => ({
    ticket: {
      url: `/tickets`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  }),
  updateTicket: (obj, then, error) => ({
    ticket: {
      url: `/tickets/${props.match.params.id}`,
      method: "PUT",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  }),
  ticket: `/tickets/${props.match.params.id}`
});

export const connectListWithEndpoint = () => ({
  tickets: "/tickets"
});
