import {
  required,
  maxLength,
  equalTo,
  lessThanOrEqualTo,
  greaterThan,
  minLength,
  validateCPF,
  requiredObj,
} from "./validationFunctions";

import { StringUtil } from "./../../useful";

import {
  ZERO,
  TEN,
  A_HUNDRED,
  A_HUNDRED_AND_EIGHTY,
  EXAM_ID_BY_NAME,
  OBS_VALIDATION_MAX_LENGTH,
  REQUIRED_VALIDATION_MESSAGE,
  MAX_LENGTH_VALIDATION_MESSAGE,
} from "../../assets/js/Consts.js";
import moment from "moment";

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces report common validation rules
 *
 * @param  {mixed} vetSignerId
 * @param  {mixed} requestExamState
 *
 * @return {Object} Object with rules
 *
 */
export const commonReportRules = (vetSignerId, requestExamState) => ({
  vetSignerId: {
    rules: [[greaterThan, vetSignerId, ZERO, REQUIRED_VALIDATION_MESSAGE]],
  },
});

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces corpuscular volume validation rules
 *
 * @param  {String} corpuscularVolume
 * @param  {boolean} isUpdate
 *
 * @return {Object} Object with rules
 *
 */
export const corpuscularVolumeRules = (fieldName, result) => {
  return {
    [fieldName]: {
      rules: [[required, result, REQUIRED_VALIDATION_MESSAGE]],
    },
  };
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces coagulation test validation rules
 *
 * @param  {string} tromboplastinTimeName
 * @param  {String} tromboplastinTime
 * @param  {String} prothrombinTimeName
 * @param  {String} prothrombinTime
 *
 * @return {Object} Object with rules
 *
 */
export const coagulationTestRules = (
  tromboplastinTimeName,
  tromboplastinTime,
  prothrombinTimeName,
  prothrombinTime,
  completeValidation
) => {
  return {
    [tromboplastinTimeName]: {
      rules: completeValidation
        ? [[required, tromboplastinTime, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [prothrombinTimeName]: {
      rules: completeValidation
        ? [[required, prothrombinTime, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
  };
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces erythrogram validation rules
 *
 * @param  {string} erythrocytes
 * @param  {String} corpuscularVolume
 * @param  {String} hemoglobiometry
 * @param  {String} mchc
 * @param  {String} mchcRef
 * @param  {boolean} isUpdate
 *
 * @return {Object} Object with rules
 *
 */
export const erythrogramRules = ({
  erythrocytes,
  corpuscularVolume,
  hemoglobiometry,
  erythrogramObs,
  mchc,
  mchcMax,
  isUpdate,
  fieldNames: { erythrocytesName, corpuscularVolumeName, hemoglobiometryName },
  completeValidation: completeValidation,
}) => {
  const mchcParsed = mchc === "" ? 0 : parseFloat(mchc);
  const mchcRef = parseFloat(mchcMax);

  const fieldNames = [
    erythrocytesName,
    corpuscularVolumeName,
    hemoglobiometryName,
  ];

  const mchcDependsOn = fieldNames;
  const mchcNeedsTouch = isUpdate ? [] : fieldNames;

  const validation = completeValidation
    ? {
        ...corpuscularVolumeRules(corpuscularVolumeName, corpuscularVolume),
        erythrocytes: {
          rules: [[required, erythrocytes, REQUIRED_VALIDATION_MESSAGE]],
        },
        hemoglobiometry: {
          rules: [[required, hemoglobiometry, REQUIRED_VALIDATION_MESSAGE]],
        },
      }
    : {
        [corpuscularVolumeName]: {
          rules: [],
        },
        erythrocytes: {
          rules: [],
        },
        hemoglobiometry: {
          rules: [],
        },
      };

  return {
    ...validation,
    mchc: {
      rules: completeValidation
        ? [[lessThanOrEqualTo, mchcParsed, mchcRef, "O valor excedeu o máximo"]]
        : [],
      fields: [mchcDependsOn, mchcNeedsTouch],
    },
    erythrogramObs: {
      rules: completeValidation
        ? [
            [
              maxLength,
              erythrogramObs,
              A_HUNDRED_AND_EIGHTY,
              "Máximo de 180 caracteres",
            ],
          ]
        : [],
    },
  };
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces global leukometry validation rules
 *
 * @param  {string} globalLeukometry
 *
 * @return {Object} Object with rules
 *
 */
export const globalLeukometryRules = (globalLeukometry, completeValidation) => {
  const rules = completeValidation
    ? [[required, globalLeukometry, "obrigatório"]]
    : [];
  return {
    globalLeukometry: {
      rules: rules,
    },
  };
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces platelets validation rules
 *
 * @param  {string} platelets'
 *
 * @return {Object} Object with rules
 */
export const plateletsRules = (result, obs, completeValidation) => {
  const plateletsRules = completeValidation
    ? [[required, result, REQUIRED_VALIDATION_MESSAGE]]
    : [];
  return {
    platelets: {
      rules: plateletsRules,
    },
    plateletsObs: {
      rules: [
        [maxLength, obs, A_HUNDRED_AND_EIGHTY, "Máximo de 180 caracteres"],
      ],
    },
  };
};

const heterophilRelatedRules = (
  hasHeterophils,
  relativeMyelocytes,
  relativeMetamyelocytes,
  relativeBands,
  relativeSegs,
  relativeHeterophils,
  completeValidation
) => {
  if (hasHeterophils) {
    const rules = completeValidation
      ? [[required, relativeHeterophils, REQUIRED_VALIDATION_MESSAGE]]
      : [];
    const validationWithHeterophils = {
      relativeHeterophils: {
        rules: rules,
      },
    };

    return validationWithHeterophils;
  }

  const relativeMyelocytesRequiredRules = completeValidation
    ? [[required, relativeMyelocytes, REQUIRED_VALIDATION_MESSAGE]]
    : [];
  const relativeMetamyelocytesRequiredRules = completeValidation
    ? [[required, relativeMetamyelocytes, REQUIRED_VALIDATION_MESSAGE]]
    : [];
  const relativeBandsRequiredRules = completeValidation
    ? [[required, relativeBands, REQUIRED_VALIDATION_MESSAGE]]
    : [];
  const relativeSegsRequiredRules = completeValidation
    ? [[required, relativeSegs, REQUIRED_VALIDATION_MESSAGE]]
    : [];
  const validationWithoutHeterophils = {
    relativeMyelocytes: {
      rules: relativeMyelocytesRequiredRules,
    },
    relativeMetamyelocytes: {
      rules: relativeMetamyelocytesRequiredRules,
    },
    relativeBands: {
      rules: relativeBandsRequiredRules,
    },
    relativeSegs: {
      rules: relativeSegsRequiredRules,
    },
  };

  return validationWithoutHeterophils;
};

const heterophilRelatedFields = (
  hasHeterophils,
  relativeHeterophilsName,
  relativeMyelocytesName,
  relativeMetamyelocytesName,
  relativeBandsName,
  relativeSegsName
) => {
  if (hasHeterophils) {
    return [relativeHeterophilsName];
  }
  return [
    relativeMyelocytesName,
    relativeMetamyelocytesName,
    relativeBandsName,
    relativeSegsName,
  ];
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces erythrogram validation rules
 *
 * @param  {string} erythrocytes
 * @param  {String} corpuscularVolume
 * @param  {String} hemoglobiometry
 * @param  {String} mchc
 * @param  {String} mchcRef\
 *
 * @return {Object} Object with rules
 */
export const leukogramRules = ({
  isIntenseLeucopeny,
  relativeBasophils,
  relativeEosinophils,
  relativeLymphocytes,
  relativeMonocytes,
  relativeMyelocytes,
  relativeMetamyelocytes,
  relativeBands,
  relativeSegs,
  relativeHeterophils,
  leukogramObs,
  hasHeterophils,
  isUpdate,
  total,
  fieldNames: {
    relativeBasophilsName,
    relativeEosinophilsName,
    relativeLymphocytesName,
    relativeMonocytesName,
    relativeHeterophilsName,
    relativeMyelocytesName,
    relativeMetamyelocytesName,
    relativeBandsName,
    relativeSegsName,
  },
  completeValidation,
}) => {
  const rules = completeValidation
    ? [
        [
          maxLength,
          leukogramObs,
          A_HUNDRED_AND_EIGHTY,
          "Máximo de 180 caracteres",
        ],
      ]
    : [];
  const validation = {
    leukogramObs: {
      rules: rules,
    },
  };

  if (isIntenseLeucopeny) {
    return validation;
  }

  let fields = [
    relativeBasophilsName,
    relativeEosinophilsName,
    relativeLymphocytesName,
    relativeMonocytesName,
  ];

  fields = [
    ...fields,
    ...heterophilRelatedFields(
      hasHeterophils,
      relativeHeterophilsName,
      relativeMyelocytesName,
      relativeMetamyelocytesName,
      relativeBandsName,
      relativeSegsName,
      completeValidation
    ),
  ];

  const totalDependsOn = fields;
  const totalNeedsTouch = isUpdate ? [] : fields;
  const incomingTotal = total ? total : ZERO;
  const totalInt = parseInt(incomingTotal, TEN);

  const relativeBasophilsRequiredRule = completeValidation
    ? [[required, relativeBasophils, REQUIRED_VALIDATION_MESSAGE]]
    : [];
  const relativeEosinophilsRequiredRule = completeValidation
    ? [[required, relativeEosinophils, REQUIRED_VALIDATION_MESSAGE]]
    : [];
  const relativeLymphocytesRequiredRule = completeValidation
    ? [[required, relativeLymphocytes, REQUIRED_VALIDATION_MESSAGE]]
    : [];
  const relativeMonocytesRequiredRule = completeValidation
    ? [[required, relativeMonocytes, REQUIRED_VALIDATION_MESSAGE]]
    : [];
  const totalRule = completeValidation
    ? [[equalTo, totalInt, A_HUNDRED, "O valor deve ser igual a 100"]]
    : [];
  return {
    ...validation,
    relativeBasophils: {
      rules: relativeBasophilsRequiredRule,
    },
    relativeEosinophils: {
      rules: relativeEosinophilsRequiredRule,
    },
    relativeLymphocytes: {
      rules: relativeLymphocytesRequiredRule,
    },
    relativeMonocytes: {
      rules: relativeMonocytesRequiredRule,
    },
    total: {
      rules: totalRule,
      fields: [totalDependsOn, totalNeedsTouch],
    },
    ...heterophilRelatedRules(
      hasHeterophils,
      relativeMyelocytes,
      relativeMetamyelocytes,
      relativeBands,
      relativeSegs,
      relativeHeterophils,
      completeValidation
    ),
  };
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces hemogram validation rules
 *
 * @return {Object} Object with rules
 */
export const hemogramRules = ({
  globalLeukometry,
  platelets,
  plateletsObs,
  erythrocytes,
  corpuscularVolume,
  hemoglobiometry,
  erythrogramObs,
  mchc,
  mchcMax,
  isIntenseLeucopeny,
  relativeBasophils,
  relativeEosinophils,
  relativeLymphocytes,
  relativeMonocytes,
  relativeMyelocytes,
  relativeMetamyelocytes,
  relativeBands,
  relativeSegs,
  relativeHeterophils,
  leukogramObs,
  hasHeterophils,
  isUpdate,
  total,
  fieldNames,
  hemogramObs,
  completeValidation,
}) => {
  const erythrogramValidation = erythrogramRules({
    erythrocytes: erythrocytes,
    corpuscularVolume: corpuscularVolume,
    hemoglobiometry: hemoglobiometry,
    erythrogramObs: erythrogramObs,
    mchc: mchc,
    mchcMax: mchcMax,
    isUpdate: isUpdate,
    fieldNames: fieldNames,
    completeValidation: completeValidation,
  });
  const globalLeukometryValidation = globalLeukometryRules(
    globalLeukometry,
    completeValidation
  );
  const plateletsValidation = plateletsRules(
    platelets,
    plateletsObs,
    completeValidation
  );

  let validations = {
    ...erythrogramValidation,
    ...globalLeukometryValidation,
    ...plateletsValidation,
    hemogramObs: {
      rules: [
        [
          maxLength,
          hemogramObs,
          A_HUNDRED_AND_EIGHTY,
          "Máximo de 180 caracteres",
        ],
      ],
    },
  };

  const leukogramValidations = leukogramRules({
    isIntenseLeucopeny,
    relativeBasophils,
    relativeEosinophils,
    relativeLymphocytes,
    relativeMonocytes,
    relativeMyelocytes,
    relativeMetamyelocytes,
    relativeBands,
    relativeSegs,
    relativeHeterophils,
    leukogramObs,
    hasHeterophils,
    isUpdate,
    total,
    fieldNames,
    completeValidation,
  });

  return { ...validations, ...leukogramValidations };
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces hemocytozoa research validation rules
 *
 * @param  {string} result
 *
 * @return {Object} Object with rules
 */
export const hemocytozoaResearchRules = (
  resultName,
  result,
  obsName,
  obs,
  completeValidation
) => ({
  [resultName]: {
    rules: completeValidation
      ? [[required, result, REQUIRED_VALIDATION_MESSAGE]]
      : [],
  },
  [obsName]: {
    rules: [[maxLength, obs, OBS_VALIDATION_MAX_LENGTH]],
  },
});

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces microfilariae research validation rules
 *
 * @param  {string} result
 *
 * @return {Object} Object with rules
 */
export const microfilariaeResearchRules = (
  resultName,
  result,
  obsName,
  obs,
  completeValidation
) => ({
  [resultName]: {
    rules: completeValidation
      ? [[required, result, REQUIRED_VALIDATION_MESSAGE]]
      : [],
  },
  [obsName]: {
    rules: [
      [
        maxLength,
        obs,
        OBS_VALIDATION_MAX_LENGTH,
        MAX_LENGTH_VALIDATION_MESSAGE,
      ],
    ],
  },
});

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces fibrinogen validation rules
 *
 * @param  {string} result
 *
 * @return {Object} Object with rules
 */
export const fibrinogenRules = (
  resultName,
  result,
  obsName,
  obs,
  completeValidation
) => ({
  [resultName]: {
    rules: completeValidation
      ? [[required, result, REQUIRED_VALIDATION_MESSAGE]]
      : [],
  },
  [obsName]: {
    rules: [[maxLength, obs, OBS_VALIDATION_MAX_LENGTH]],
  },
});

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces animal validation rules
 *
 * @param  {string} name
 * @param  {number} years
 * @param  {number} months
 * @param  {number} sexId
 * @param  {Object} birthDate
 * @param  {string} cpf
 * @param  {number} sexId
 *
 * @return {Object} Object with rules
 *
 */

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces biochemistry validation rules
 *
 * @return {Object} Object with rules
 */
export const biochemistryRules = (rows, completeValidation) => {
  const validations = {};
  const fields = [];
  const getRule = (rule, params, message) => {
    return completeValidation
      ? [rule, ...params, message]
      : [required, "defaultValue", message];
  };

  rows.forEach((row, index) => {
    const examId = row.examId;

    const fieldName = row.fieldName;

    switch (examId) {
      case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]: {
        const totalName = `result-${index}-total`;
        const directName = `result-${index}-direct`;

        fields.push(totalName);
        fields.push(directName);

        const { total, direct } = row;

        const totalValue = total === "" ? 0 : total;
        const directValue = direct === "" ? 0 : direct;

        validations[totalName] = {
          rules: getRule(required, [totalValue], REQUIRED_VALIDATION_MESSAGE),
        };

        validations[directName] = {
          rules: [
            getRule(required, [direct], REQUIRED_VALIDATION_MESSAGE),
            [
              lessThanOrEqualTo,
              directValue,
              totalValue,
              "Deve ser menor que o total",
            ],
          ],
        };

        break;
      }
      case EXAM_ID_BY_NAME["IONIC_CALCIUM"]: {
        const isCalculatedResult = !row.isResultEditable;

        if (isCalculatedResult) {
          const { calcium, albumin, totalProtein } = row;

          const calciumName = `result-${index}-calcium`;
          const albuminName = `result-${index}-albumin`;
          const totalProteinName = `result-${index}-totalProtein`;

          fields.push(calciumName);
          fields.push(albuminName);
          fields.push(totalProteinName);

          validations[calciumName] = {
            rules: [
              /*[required, calcium, REQUIRED_VALIDATION_MESSAGE]*/
            ],
          };

          validations[albuminName] = {
            rules: [
              /*[required, albumin, REQUIRED_VALIDATION_MESSAGE]*/
            ],
          };

          validations[totalProteinName] = {
            rules: [
              /*[required, totalProtein, REQUIRED_VALIDATION_MESSAGE]*/
            ],
          };
        } else {
          const { result } = row;

          fields.push(fieldName);

          validations[fieldName] = {
            rules: [getRule(required, [result], REQUIRED_VALIDATION_MESSAGE)],
          };
        }

        break;
      }
      case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]: {
        const { totalProtein, albumin, globulin } = row;

        const totalProteinName = `result-${index}-totalProtein`;
        const albuminName = `result-${index}-albumin`;
        const globulinName = `result-${index}-globulin`;

        fields.push(totalProteinName);
        fields.push(albuminName);
        fields.push(globulinName);

        validations[totalProteinName] = {
          rules: [
            getRule(required, [totalProtein], REQUIRED_VALIDATION_MESSAGE),
            [greaterThan, totalProtein, ZERO, REQUIRED_VALIDATION_MESSAGE],
          ],
        };

        validations[albuminName] = {
          rules: [getRule(required, [albumin], REQUIRED_VALIDATION_MESSAGE)],
        };

        validations[globulinName] = {
          rules: [getRule(required, [globulin], REQUIRED_VALIDATION_MESSAGE)],
        };

        break;
      }
      case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]: {
        const { totalCholesterol, hdl, triglycerides } = row;

        const totalCholesterolName = `result-${index}-totalCholesterol`;
        const hdlName = `result-${index}-hdl`;
        const triglyceridesName = `result-${index}-triglycerides`;

        fields.push(totalCholesterolName);
        fields.push(hdlName);
        fields.push(triglyceridesName);

        validations[totalCholesterolName] = {
          rules: [
            getRule(required, [totalCholesterol], REQUIRED_VALIDATION_MESSAGE),
            [greaterThan, totalCholesterol, ZERO, REQUIRED_VALIDATION_MESSAGE],
          ],
        };

        validations[hdlName] = {
          rules: [getRule(required, [hdl], REQUIRED_VALIDATION_MESSAGE)],
        };

        validations[triglyceridesName] = {
          rules: [
            getRule(required, [triglycerides], REQUIRED_VALIDATION_MESSAGE),
          ],
        };

        break;
      }
      default: {
        const { result } = row;

        validations[fieldName] = {
          rules: [getRule(required, [result], REQUIRED_VALIDATION_MESSAGE)],
        };

        fields.push(fieldName);
      }
    }
  });

  return validations;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces biochemistry validation rules
 *
 * @return {Object} Object with rules
 */
export const biochemistryRules2 = (rows, completeValidation) => {
  const validations = {};
  const fields = [];

  rows.forEach((row, index) => {
    const examId = row.examId;

    const fieldName = row.fieldName;

    switch (examId) {
      case EXAM_ID_BY_NAME["BILIRUBIN_AND_FRACTIONS"]: {
        const {
          bilirubinAndFractionsTotalFieldName,
          bilirubinAndFractionsDirectFieldName,
          bilirubinAndFractionsTotal,
          bilirubinAndFractionsDirect,
        } = row;

        fields.push(bilirubinAndFractionsTotalFieldName);
        fields.push(bilirubinAndFractionsDirectFieldName);

        validations[bilirubinAndFractionsTotalFieldName] = {
          rules: completeValidation
            ? [
                [
                  required,
                  bilirubinAndFractionsTotal,
                  REQUIRED_VALIDATION_MESSAGE,
                ],
              ]
            : [],
        };

        const fieldNameRule = completeValidation
          ? [
              [
                required,
                bilirubinAndFractionsDirect,
                REQUIRED_VALIDATION_MESSAGE,
              ],

              [
                lessThanOrEqualTo,
                bilirubinAndFractionsDirect,
                bilirubinAndFractionsTotal,
                "Deve ser menor que o total",
              ],
            ]
          : [];

        validations[bilirubinAndFractionsDirectFieldName] = {
          rules: fieldNameRule,
        };

        break;
      }
      case EXAM_ID_BY_NAME["IONIC_CALCIUM"]: {
        const {
          isIonicCalciumEditable,
          ionicCalciumCalciumFieldName,
          ionicCalciumAlbuminFieldName,
          ionicCalciumTotalProteinFieldName,
          ionicCalciumIonicCalciumFieldName,
          ionicCalciumCalcium,
          ionicCalciumAlbumin,
          ionicCalciumTotalProtein,
          ionicCalciumIonicCalcium,
        } = row;

        fields.push(ionicCalciumCalciumFieldName);
        fields.push(ionicCalciumAlbuminFieldName);
        fields.push(ionicCalciumTotalProteinFieldName);

        validations[ionicCalciumCalciumFieldName] = {
          rules: completeValidation
            ? [[required, ionicCalciumCalcium, REQUIRED_VALIDATION_MESSAGE]]
            : [],
        };

        validations[ionicCalciumAlbuminFieldName] = {
          rules: completeValidation
            ? [[required, ionicCalciumAlbumin, REQUIRED_VALIDATION_MESSAGE]]
            : [],
        };

        validations[ionicCalciumTotalProteinFieldName] = {
          rules: completeValidation
            ? [
                [
                  required,
                  ionicCalciumTotalProtein,
                  REQUIRED_VALIDATION_MESSAGE,
                ],
              ]
            : [],
        };

        if (isIonicCalciumEditable) {
          validations[ionicCalciumCalciumFieldName] = {
            rules: [],
          };

          validations[ionicCalciumAlbuminFieldName] = {
            rules: [],
          };

          validations[ionicCalciumTotalProteinFieldName] = {
            rules: [],
          };

          validations[ionicCalciumIonicCalciumFieldName] = {
            rules: completeValidation
              ? [
                  [
                    required,
                    ionicCalciumIonicCalcium,
                    REQUIRED_VALIDATION_MESSAGE,
                  ],
                ]
              : [],
          };
        }

        break;
      }
      case EXAM_ID_BY_NAME["TOTAL_PROTEIN_AND_FRACTIONS"]: {
        const {
          totalProteinAndFractionsTotalProteinFieldName,
          totalProteinAndFractionsAlbuminFieldName,
          totalProteinAndFractionsTotalProtein,
          totalProteinAndFractionsAlbumin,
        } = row;

        fields.push(totalProteinAndFractionsTotalProteinFieldName);
        fields.push(totalProteinAndFractionsAlbuminFieldName);

        const totalProteinFieldNameRule = completeValidation
          ? [
              [
                required,
                totalProteinAndFractionsTotalProtein,
                REQUIRED_VALIDATION_MESSAGE,
              ],
              [
                greaterThan,
                totalProteinAndFractionsTotalProtein,
                ZERO,
                REQUIRED_VALIDATION_MESSAGE,
              ],
            ]
          : [];

        validations[totalProteinAndFractionsTotalProteinFieldName] = {
          rules: totalProteinFieldNameRule,
        };

        const albuminFiledNameValidation = completeValidation
          ? [
              [
                required,
                totalProteinAndFractionsAlbumin,
                REQUIRED_VALIDATION_MESSAGE,
              ],
            ]
          : [];

        validations[totalProteinAndFractionsAlbuminFieldName] = {
          rules: albuminFiledNameValidation,
        };

        break;
      }
      case EXAM_ID_BY_NAME["TOTAL_CHOLESTEROL_AND_FRACTIONS"]: {
        const {
          totalCholesterolAndFractionsTotalCholesterolFieldName,
          totalCholesterolAndFractionsHdlFieldName,
          totalCholesterolAndFractionsTriglyceridesFieldName,
          totalCholesterolAndFractionsTotalCholesterol,
          totalCholesterolAndFractionsHdl,
          totalCholesterolAndFractionsTriglycerides,
        } = row;

        fields.push(totalCholesterolAndFractionsTotalCholesterolFieldName);
        fields.push(totalCholesterolAndFractionsHdlFieldName);
        fields.push(totalCholesterolAndFractionsTriglyceridesFieldName);

        validations[totalCholesterolAndFractionsTotalCholesterolFieldName] = {
          rules: completeValidation
            ? [
                [
                  required,
                  totalCholesterolAndFractionsTotalCholesterol,
                  REQUIRED_VALIDATION_MESSAGE,
                ],
                [
                  greaterThan,
                  totalCholesterolAndFractionsTotalCholesterol,
                  ZERO,
                  REQUIRED_VALIDATION_MESSAGE,
                ],
              ]
            : [],
        };

        validations[totalCholesterolAndFractionsHdlFieldName] = {
          rules: completeValidation
            ? [
                [
                  required,
                  totalCholesterolAndFractionsHdl,
                  REQUIRED_VALIDATION_MESSAGE,
                ],
              ]
            : [],
        };

        validations[totalCholesterolAndFractionsTriglyceridesFieldName] = {
          rules: completeValidation
            ? [
                [
                  required,
                  totalCholesterolAndFractionsTriglycerides,
                  REQUIRED_VALIDATION_MESSAGE,
                ],
              ]
            : [],
        };

        break;
      }
      default: {
        const { result } = row;

          validations[fieldName] = {
            rules: completeValidation && fieldName
            ? [[required, result, REQUIRED_VALIDATION_MESSAGE]]
            : [],
          };
            
          fields.push(fieldName);
      }
    }
  });

  return validations;
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Produces biochemistry validation rules
 *
 * @return {Object} Object with rules
 */
export const chemiluminescenceRules = (rows, completeValidation) => {
  const validations = {};
  const fields = [];
  rows.forEach((row, index) => {
    const fieldName = row.fieldName;

    const { result } = row;

    validations[fieldName] = {
      rules: completeValidation
        ? [[required, result, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    };

    fields.push(fieldName);
  });

  return validations;
};

export const animalRules = ({
  name,
  years,
  months,
  owner,
  sexId,
  birthDate,
  cpf,
  breedId,
  speciesId,
  customerId,
  ageOption,
  fieldNames: {
    nameName,
    yearsName,
    monthsName,
    ownerName,
    sexIdName,
    cpfName,
    speciesIdName,
    breedIdName,
    customerIdName,
    ageOptionName,
    birthDateName,
  },
}) => {
  const validations = {
    [nameName]: {
      rules: [
        [required, name, REQUIRED_VALIDATION_MESSAGE],
        [minLength, name, 2, "Mínimo de 2 caracteres"],
        [maxLength, name, 30, "Máximo de 30 caracteres"],
      ],
    },
    [ownerName]: {
      rules: [
        [required, owner, REQUIRED_VALIDATION_MESSAGE],
        [minLength, owner, 2, "Mínimo de 2 caracteres"],
        [maxLength, owner, 64, "Máximo de 64 caracteres"],
      ],
    },
    [sexIdName]: {
      rules: [[greaterThan, sexId, 0, "Selecione"]],
    },
    [cpfName]: {
      rules: [
        [validateCPF, StringUtil.onlyNumbers(cpf), true, "CPF incorreto"],
      ],
    },
    [speciesIdName]: {
      rules: [[greaterThan, speciesId, 0, "Selecione"]],
    },
    [breedIdName]: {
      rules: [[greaterThan, breedId, 0, "Selecione"]],
    },
    [customerIdName]: {
      rules: [[greaterThan, customerId, 0, "Selecione"]],
    },
    [birthDateName]: {
      rules: [[isFutureDate, birthDate, "Data inválida"]],
    },
  };

  if (!birthDate & !ageOption) {
    validations[yearsName] = {
      rules: [[required, years, REQUIRED_VALIDATION_MESSAGE]],
    };

    validations[monthsName] = {
      rules: [[required, months, REQUIRED_VALIDATION_MESSAGE]],
    };
  }
  if (ageOption) {
    validations[yearsName] = {
      rules: [],
    };

    validations[monthsName] = {
      rules: [],
    };
  }

  return validations;
};

function isFutureDate(idate, message) {
  var today = moment();
  var idate = moment(idate);
  const teste = idate.isAfter(today);
  if (teste) {
    return message;
  }
  return true;
}

export const parvoCoronaRules = (
  materialIdFieldName,
  materialId,
  isParvovirusFieldName,
  isParvovirus,
  isCoronavirusFieldName,
  isCoronavirus,
  completeValidation
) => {
  return {
    [materialIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, materialId, 0, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [isParvovirusFieldName]: {
      rules: completeValidation
        ? [[greaterThan, isParvovirus, -1, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [isCoronavirusFieldName]: {
      rules: completeValidation
        ? [[greaterThan, isCoronavirus, -1, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
  };
};

export const parasitologicalFreshFecesRules = (
  fecesColorOptionIdFieldName,
  fecesColorOptionId,
  fecesSmellOptionIdFieldName,
  fecesSmellOptionId,
  fecesAspectOptionIdFieldName,
  fecesAspectOptionId,
  fecesConsistencyOptionIdFieldName,
  fecesConsistencyOptionId,
  macroscopicParasitesFieldName,
  macroscopicParasites,
  resultFieldName,
  result,
  completeValidation
) => {
  return {
    [fecesColorOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, fecesColorOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [fecesSmellOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, fecesSmellOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [fecesAspectOptionIdFieldName]: {
      rules: completeValidation
        ? [
            [
              greaterThan,
              fecesAspectOptionId,
              ZERO,
              REQUIRED_VALIDATION_MESSAGE,
            ],
          ]
        : [],
    },
    [fecesConsistencyOptionIdFieldName]: {
      rules: completeValidation
        ? [
            [
              greaterThan,
              fecesConsistencyOptionId,
              ZERO,
              REQUIRED_VALIDATION_MESSAGE,
            ],
          ]
        : [],
    },
    [macroscopicParasitesFieldName]: {
      rules: completeValidation
        ? [[required, macroscopicParasites, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [resultFieldName]: {
      rules: completeValidation
        ? [[required, result, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
  };
};

export const molecularBiologyRules = (
  molecularBiologyTechniqueFieldName,
  molecularBiologyTechnique,
  molecularBiologyQualitativeResultFieldName,
  molecularBiologyQualitativeResult,
  molecularBiologyCopyValuesFieldName,
  molecularBiologyCopyValues,
  molecularBiologyPrecisionFieldName,
  molecularBiologyPrecision,
  molecularBiologyMaterialsFieldName,
  molecularBiologyMaterials,
  completeValidation
) => {
  return {
    [molecularBiologyTechniqueFieldName]: {
      rules: completeValidation
        ? [
            [
              greaterThan,
              molecularBiologyTechnique,
              -1,
              REQUIRED_VALIDATION_MESSAGE,
            ],
          ]
        : [],
    },
    [molecularBiologyQualitativeResultFieldName]: {
      rules: completeValidation
        ? [
            [
              greaterThan,
              molecularBiologyQualitativeResult,
              -1,
              REQUIRED_VALIDATION_MESSAGE,
            ],
          ]
        : [],
    },
    [molecularBiologyCopyValuesFieldName]: {
      rules:
        completeValidation && molecularBiologyQualitativeResult
          ? [
              [
                required,
                molecularBiologyCopyValues,
                REQUIRED_VALIDATION_MESSAGE,
              ],
            ]
          : [],
    },
    [molecularBiologyPrecisionFieldName]: {
      rules:
        completeValidation && molecularBiologyQualitativeResult
          ? [[required, molecularBiologyPrecision, REQUIRED_VALIDATION_MESSAGE]]
          : [],
    },
    [molecularBiologyMaterialsFieldName]: {
      rules: completeValidation
        ? [[required, molecularBiologyMaterials, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
  };
};

export const fecesCoprocytologicalRules = (
  fecesCoprocytologicalResultFieldName,
  fecesCoprocytologicalResult,
  completeValidation
) => {
  return {
    [fecesCoprocytologicalResultFieldName]: {
      rules: completeValidation
        ? [[required, fecesCoprocytologicalResult, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
  };
};

export const defaultMicrobiologyRules = (
  materialFieldName,
  material,
  resultFieldName,
  result,
  sensitiveAntibioticTagsFieldName,
  sensitiveAntibioticTags,
  intermediateAntibioticTagsFieldName,
  intermediateAntibioticTags,
  resistantAntibioticTagsFieldName,
  resistantAntibioticTags,
  isNegative,
  completeValidation
) => {
  const rules = {
    [materialFieldName]: {
      rules: completeValidation
        ? [[required, material, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
  };

  const isPositive = !isNegative;

  if (isPositive) {
    rules[resultFieldName] = {
      rules: completeValidation
        ? [[required, result, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    };

    rules[sensitiveAntibioticTagsFieldName] = {
      rules: completeValidation
        ? [[required, sensitiveAntibioticTags, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    };

    rules[intermediateAntibioticTagsFieldName] = {
      rules: completeValidation
        ? [[required, intermediateAntibioticTags, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    };

    rules[resistantAntibioticTagsFieldName] = {
      rules: completeValidation
        ? [[required, resistantAntibioticTags, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    };
  } else {
    rules[resultFieldName] = {
      rules: completeValidation
        ? [[required, result, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    };
  }

  return rules;
};

export const urocultureRules = (
  materialFieldName,
  material,
  resultFieldName,
  result,
  sensitiveAntibioticTagsFieldName,
  sensitiveAntibioticTags,
  intermediateAntibioticTagsFieldName,
  intermediateAntibioticTags,
  resistantAntibioticTagsFieldName,
  resistantAntibioticTags,
  coloniesCountFieldName,
  coloniesCount,
  isNegative,
  completeValidation
) => {
  const rules = {
    ...defaultMicrobiologyRules(
      materialFieldName,
      material,
      resultFieldName,
      result,
      sensitiveAntibioticTagsFieldName,
      sensitiveAntibioticTags,
      intermediateAntibioticTagsFieldName,
      intermediateAntibioticTags,
      resistantAntibioticTagsFieldName,
      resistantAntibioticTags,
      completeValidation
    ),
  };

  const isPositive = !isNegative;

  if (isPositive) {
    rules[coloniesCountFieldName] = {
      rules: completeValidation
        ? [[required, coloniesCount, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    };
  }

  return rules;
};

export const heartwormDiseaseRules = (
  materialIdFieldName,
  materialId,
  isPositiveFieldName,
  isPositive,
  completeValidation
) => {
  return {
    [materialIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, materialId, 0, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [isPositiveFieldName]: {
      rules: completeValidation
        ? [[greaterThan, isPositive, -1, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
  };
};

export const easRules = (
  volumeFieldName,
  volume,
  colorOptionIdFieldName,
  colorOptionId,
  smellOptionIdFieldName,
  smellOptionId,
  aspectOptionIdFieldName,
  aspectOptionId,
  densityFieldName,
  density,
  proteinsOptionIdFieldName,
  proteinsOptionId,
  glucoseOptionIdFieldName,
  glucoseOptionId,
  acetoneOptionIdFieldName,
  acetoneOptionId,
  phFieldName,
  ph,
  bilirubinOptionIdFieldName,
  bilirubinOptionId,
  urobilinogenOptionIdFieldName,
  urobilinogenOptionId,
  hemoglobinOptionIdFieldName,
  hemoglobinOptionId,
  leukocyteOptionIdFieldName,
  leukocyteOptionId,
  nitriteOptionIdFieldName,
  nitriteOptionId,
  epithelialCellsOptionTagsFieldName,
  epithelialCellsOptionTags,
  ridgesOptionTagsFieldName,
  ridgesOptionTags,
  castsOptionTagsFieldName,
  castsOptionTags,
  bacterialFloraOptionIdFieldName,
  bacterialFloraOptionId,
  spermatozoaOptionIdFieldName,
  spermatozoaOptionId,
  obsFieldName,
  obs,
  isErythrocytesAbsent,
  isErythrocytesUncountable,
  erythrocytesMin,
  erythrocytesMax,
  isPusCellsAbsent,
  isPusCellsUncountable,
  pusCellsMin,
  pusCellsMax,
  completeValidation
) => {
  const isErythrocytesValid =
    isErythrocytesAbsent ||
    isErythrocytesUncountable ||
    (erythrocytesMin !== "" && erythrocytesMax !== "");
  const isPusCellsValid =
    isPusCellsAbsent ||
    isPusCellsUncountable ||
    (pusCellsMin !== "" && pusCellsMax !== "");
  return {
    [volumeFieldName]: {
      rules: completeValidation
        ? [[required, volume, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [colorOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, colorOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [smellOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, smellOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [aspectOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, aspectOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [aspectOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, aspectOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [densityFieldName]: {
      rules: completeValidation
        ? [[required, density, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [proteinsOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, proteinsOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [glucoseOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, glucoseOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [acetoneOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, acetoneOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [phFieldName]: {
      rules: completeValidation
        ? [[required, ph, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [bilirubinOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, bilirubinOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [urobilinogenOptionIdFieldName]: {
      rules: completeValidation
        ? [
            [
              greaterThan,
              urobilinogenOptionId,
              ZERO,
              REQUIRED_VALIDATION_MESSAGE,
            ],
          ]
        : [],
    },
    [hemoglobinOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, hemoglobinOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [leukocyteOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, leukocyteOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [nitriteOptionIdFieldName]: {
      rules: completeValidation
        ? [[greaterThan, nitriteOptionId, ZERO, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [epithelialCellsOptionTagsFieldName]: {
      rules: completeValidation
        ? [[required, epithelialCellsOptionTags, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    isErythrocytesValid: {
      rules: completeValidation
        ? [[requiredObj, isErythrocytesValid, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    isPusCellsValid: {
      rules: completeValidation
        ? [[requiredObj, isPusCellsValid, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [ridgesOptionTagsFieldName]: {
      rules: completeValidation
        ? [[required, ridgesOptionTags, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [castsOptionTagsFieldName]: {
      rules: completeValidation
        ? [[required, castsOptionTags, REQUIRED_VALIDATION_MESSAGE]]
        : [],
    },
    [bacterialFloraOptionIdFieldName]: {
      rules: completeValidation
        ? [
            [
              greaterThan,
              bacterialFloraOptionId,
              ZERO,
              REQUIRED_VALIDATION_MESSAGE,
            ],
          ]
        : [],
    },
    [spermatozoaOptionIdFieldName]: {
      rules: completeValidation
        ? [
            [
              greaterThan,
              spermatozoaOptionId,
              ZERO,
              REQUIRED_VALIDATION_MESSAGE,
            ],
          ]
        : [],
    },
    [obsFieldName]: {
      rules: [[maxLength, obs, 600, "Máximo de 600 caracteres"]],
    },
  };
};

export const ehrlichiaRules = (
  materialIdName,
  materialId,
  resultName,
  result,
  completeValidation
) => {
  return {
    [materialIdName]: {
      rules: completeValidation
        ? [[greaterThan, materialId, 0, "Informe o material enviado"]]
        : [],
    },
    [resultName]: {
      rules: completeValidation
        ? [[greaterThan, result, -1, "Informe o campo"]]
        : [],
    },
  };
};
