import { validated } from 'react-custom-validation';
import { required, greaterThan } from '../../assets/js/validationFunctions.js';
import { REQUIRED_VALIDATION_MESSAGE } from '../../assets/js/Consts.js';
import { commonReportRules } from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    vetSignerId,
    requestExamState,
    result,
    materialId,
  } = props.data.fields;

  const {completeValidation} = props.data;
  const commonValidation = commonReportRules( vetSignerId, requestExamState );
  const validations = {
    ...commonValidation,
    materialId: {
      rules: completeValidation ? [
        [ greaterThan, materialId, 0, REQUIRED_VALIDATION_MESSAGE ]
      ] : []
    },
    result: {
      rules: completeValidation ? [
        [ required, result, REQUIRED_VALIDATION_MESSAGE ]
      ] : []
    }
  };

  let fields = [
    'requestExamState',
    'vetSignerId',
    'materialId',
    'result'
  ];

  return { fields, validations };
} );

export default validation;