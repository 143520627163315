import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import ButtonSaveMaterial from "../ButtonSaveMaterial";
import { SUBMIT_BTN_TEXT } from "../../assets/js/Consts.js";

const propTypes = {
  isRequesting: PropTypes.bool,
  shouldDisable: PropTypes.bool,
  isUpdate: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const defaultProps = {
  shouldDisable: false,
  isRequesting: false
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a submit button
 */
const SubmitButtonMaterial = ({
  isRequesting,
  shouldDisable,
  isUpdate,
  handleSubmit,
  title,
  type
}) => (
  <ButtonSaveMaterial
    type={type || "highlight"}
    title={
      title ? title : isUpdate ? SUBMIT_BTN_TEXT.UPDATE : SUBMIT_BTN_TEXT.SAVE
    }
    right={true}
    onClick={handleSubmit}
    requesting={isRequesting}
    requestingTitle={
      isUpdate ? SUBMIT_BTN_TEXT.UPDATING : SUBMIT_BTN_TEXT.SAVING
    }
    disabled={shouldDisable}
  />
);

SubmitButtonMaterial.propTypes = propTypes;
SubmitButtonMaterial.defaultProps = defaultProps;

export default SubmitButtonMaterial;
