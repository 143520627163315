import React from 'react';

/**
 * Internal
 */
import FlatPanelMaterial from './../FlatPanelMaterial';
import NumberInputMaterial2 from './../NumberInputMaterial2';
import { Col, Row } from './../../components/grid';

const ColoniesCountContent = ( {
  coloniesCountFieldName,
  coloniesCount,
  coloniesCountHasValidation,
  coloniesCountValidationReason,
  isNegative,
  shouldDisable,
  handleChange,
  handleBlur,
  getQuantitativeResult
} ) =>
  <FlatPanelMaterial title="Resultado Quantitativo">
    <Row>
      <Col md={ 2 }>
        <NumberInputMaterial2
          title="Contagem de Colônias"
          name={ coloniesCountFieldName }
          hasValidationRecoil={ true }
          decimalScale={ 0 }
          value={ isNegative ? '' : coloniesCount }
          disabled={ isNegative || shouldDisable }
          handleChange={ handleChange }
          handleBlur={ handleBlur }
          hasValidation={ coloniesCountHasValidation }
          validationReason={ coloniesCountValidationReason } />
      </Col>
      <Col md={ 2 }>
        <NumberInputMaterial2
          title="Resultado Quantitativo"
          name="getQuantitativeResult"
          hasValidationRecoil={ true }
          decimalScale={ 0 }
          allowNegative={ false }
          value={ getQuantitativeResult( coloniesCount, isNegative ) }
          disabled={ true } />
      </Col>
    </Row>
  </FlatPanelMaterial>

export default ColoniesCountContent;