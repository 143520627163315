import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * External
 */
import { Panel } from "react-bootstrap";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faAngleUp from "@fortawesome/fontawesome-free-solid/faAngleUp";
import update from "immutability-helper";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialBody from "../PanelMaterialBody";
import PanelMaterialBottom from "../PanelMaterialBottom";
import ButtonSaveMaterial from "../ButtonSaveMaterial";
import InputMaterial from "./../InputMaterial";
import SelectMaterial from "./../SelectMaterial";
import TagsInputMaterial from "./../TagsInputMaterial";
import { Row } from "react-bootstrap";
import { StringUtil } from "../../useful";

/**
 * Assets
 */
import "./index.css";

const propTypes = {
  data: PropTypes.shape({
    animal: PropTypes.string.isRequired,
    vets: PropTypes.shape({
      options: PropTypes.array.isRequired,
      placeholder: PropTypes.string.isRequired,
    }),
    requestExamStates: PropTypes.shape({
      options: PropTypes.array.isRequired,
      placeholder: PropTypes.string.isRequired,
    }),
    owner: PropTypes.string.isRequired,
    species: PropTypes.string.isRequired,
    breed: PropTypes.string.isRequired,
    age: PropTypes.string.isRequired,
    sex: PropTypes.string.isRequired,
    requestSampleNames: PropTypes.array.isRequired,
    deadline: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    endDate: PropTypes.string.isRequired,
    obs: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a ReportHeader form
 *
 */
class ReportHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Toggles header open/closed state
   *
   */
  toggleOpen() {
    this.setState((state) =>
      update(state, { isOpen: { $set: !this.state.isOpen } })
    );
  }

  onToggle() {}

  render() {
    const iconClass = this.state.isOpen
      ? "sub-menu-caret-up"
      : "sub-menu-caret-down";
    const {
      animal,
      vets,
      animalAgeOption,
      owner,
      species,
      breed,
      age,
      sex,
      requestSampleNames,
      deadline,
      endDate,
      obs,
      requestExamStates,
      disableAll,
    } = this.props.data;

    const { fieldChange, $field, fields, $validation } = this.props;
    return (
      <div className="reportHeader">
        <PanelMaterial className="pb-10 mt-0 mb-15" flat={true}>
          <PanelMaterialBody>
            <Row>
              <InputMaterial
                title="Animal"
                name="animal"
                id="animal"
                md={4}
                value={animal}
                disabled
              />
              <SelectMaterial
                title="Status"
                md="4"
                id="requestExamState"
                name="requestExamState"
                value={fields.requestExamState}
                searchable={true}
                placeholder={requestExamStates.placeholder}
                options={requestExamStates.options}
                disabled={true}
                {...$field("requestExamState", (value) => {
                  fieldChange("requestExamState", value);
                })}
              />
              <SelectMaterial
                title="Assinatura"
                md="4"
                id="vetSignerId"
                name="vetSignerId"
                placeholder={"Selecione"}
                value={fields.vetSignerId}
                searchable={true}
                options={vets.options}
                {...$field("vetSignerId", (value) => {
                  fieldChange("vetSignerId", value);
                })}
                isValid={$validation.vetSignerId.isValid}
                hasValidation={$validation.vetSignerId.show}
                validationReason={$validation.vetSignerId.error.reason}
                hasValidationRecoil={true}
              />
              <TagsInputMaterial
                title={"Amostras"}
                tags={requestSampleNames}
                md={12}
                disabled={true}
              />
            </Row>
            <Row className="mt-20">
              <Panel
                id="report-header-panel"
                expanded={this.state.isOpen}
                onToggle={this.onToggle}
              >
                <Panel.Collapse>
                  <Panel.Body>
                    <InputMaterial
                      title="Proprietário"
                      name="owner"
                      id="owner"
                      md={3}
                      value={owner}
                      disabled
                    />
                    <InputMaterial
                      title="Espécie"
                      name="specie"
                      id="specie"
                      md={3}
                      value={species}
                      disabled
                    />
                    <InputMaterial
                      title="Raça"
                      name="breed"
                      id="breed"
                      md={3}
                      value={breed}
                      disabled
                    />
                    <InputMaterial
                      title="Idade"
                      name="age"
                      id="age"
                      md={2}
                      value={
                        animalAgeOption
                          ? animalAgeOption === 0
                            ? "Não Informado"
                            : animalAgeOption === 1
                            ? "Adulto"
                            : age
                          : age
                      }
                      disabled
                    />
                    <InputMaterial
                      title="Sexo"
                      name="sex"
                      id="sex"
                      md={1}
                      value={sex}
                      disabled
                    />
                    <InputMaterial
                      title="Prazo"
                      name="deadline"
                      id="deadline"
                      md={2}
                      value={deadline}
                      disabled
                    />
                    <InputMaterial
                      title="Data da finalização"
                      name="endDate"
                      id="endDate"
                      md={3}
                      value={StringUtil.formatDateUi(endDate)}
                      disabled
                    />
                    <InputMaterial
                      title="Obs"
                      name="obs"
                      id="obs"
                      md={7}
                      value={obs}
                      disabled
                    />
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </Row>
          </PanelMaterialBody>
          <PanelMaterialBottom>
            <ButtonSaveMaterial
              onClick={this.toggleOpen}
              title={
                <FontAwesomeIcon
                  size="lg"
                  icon={faAngleUp}
                  className={iconClass}
                />
              }
              type="highlight"
              position="top"
              right={true}
              id="btn_go_exam_request"
              className="circular-btn btn-center"
            />
          </PanelMaterialBottom>
        </PanelMaterial>
      </div>
    );
  }
}

ReportHeader.propTypes = propTypes;

export default ReportHeader;
