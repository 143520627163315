import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "../../../../../components/grid";
import { SEX } from "../../../../../assets/js/Consts.js";
import NumberInputMaterial from "../../../../NumberInputMaterial";
import InputMaterial from "../../../../InputMaterial";
import TripleRadioMaterial from "../../../../TripleRadioMaterial";
import ButtonDefaultMaterial from "../../../../ButtonDefaultMaterial";
import DualRadioMaterial from "../../../../DualRadioMaterial";

const AnimalSection = ({
  name,
  species,
  breed,
  sexId,
  years,
  ageOption,
  months,
  owner,
  disabled,
  hasValidation,
  validationMsg,
  handleShowAnimalModal,
}) => (
  <React.Fragment>
    <Row>
      <Col md={3}>
        <ButtonDefaultMaterial
          id="searchAddAnimalBtn"
          type={name ? "warning" : "highlight"}
          title={!name ? `Buscar/Adicionar Animal` : `Editar Animal`}
          className="btn-form mt-10"
          block={true}
          onClick={handleShowAnimalModal}
          disabled={disabled}
        />
        {hasValidation ? validationMsg : ""}
      </Col>
      <InputMaterial
        title="Nome do Animal"
        required
        name="name"
        md={3}
        value={name}
        disabled={true}
      />
      <InputMaterial
        title="Espécie"
        name="speciesDisplay"
        md={3}
        disabled={true}
        value={species}
      />
      <InputMaterial
        title="Raça"
        name="breedDisplay"
        md={3}
        disabled={true}
        value={breed}
      />
    </Row>
    <Row>
      <Col md={5}>
        <TripleRadioMaterial
          title={"Sexo"}
          name={"sexId"}
          selectedValue={sexId}
          firstLabel={"Macho"}
          firstValue={SEX.M}
          secondLabel={"Fêmea"}
          secondValue={SEX.F}
          thirdLabel={"Indefinido"}
          thirdValue={SEX.U}
          disabled={true}
        />
      </Col>
      {ageOption ? (
        <DualRadioMaterial
          id="ageOption"
          title="Idade"
          disabled={true}
          name="ageOption"
          firstLabel="Não informado"
          firstValue={1}
          secondLabel="Adulto"
          secondValue={2}
          md={2}
          selectedValue={ageOption}
        />
      ) : (
        <>
          <NumberInputMaterial
            title="Anos"
            name="years"
            md={1}
            value={years}
            disabled={true}
            allowNegative={false}
            format="##"
          />
          <NumberInputMaterial
            title="Meses"
            name="months"
            md={1}
            value={months}
            disabled={true}
            allowNegative={false}
            format="##"
          />
        </>
      )}

      <InputMaterial
        title="Proprietário"
        name="owner"
        md={5}
        value={owner}
        disabled={true}
      />
    </Row>
  </React.Fragment>
);

const defaultProps = {
  name: "",
  species: "",
  breed: "",
  sexId: 0,
  years: "",
  months: "",
  owner: "",
  disabled: true,
  ageOption: 0,
};

const propTypes = {
  name: PropTypes.string.isRequired,
  species: PropTypes.string.isRequired,
  breed: PropTypes.string.isRequired,
  sexId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  years: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  months: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  owner: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  ageOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AnimalSection.defaultProps = defaultProps;
AnimalSection.propTypes = propTypes;

export default AnimalSection;
