import React, { Component } from "react";
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import PanelMaterialBottom from "./../PanelMaterialBottom";
import SelectMaterial from "./../SelectMaterial";
import connectWithEndpoint from "./requests.js";
import { FunctionUtil } from "./../../useful";
import {
  BTN_SAVE_TITLE,
  BTN_SAVING_TITLE,
  EXAM_REFERENCE_TYPES,
  EXAM_REFERENCE_TYPES_LIST,
} from "../../assets/js/Consts.js";
import ImmunologyReferenceForm from "./immunologyReferenceForm";
import BiochemistryReferenceForm from "./biochemistryReferenceForm";
import EndocrinologyChemiluminescenceForm from "./endocrinologyChemiluminescenceForm";
import EndocrinologyRadioimmunoassayForm from "./endocrinologyRadioimmunoassayForm";
import UrinalysisForm from "./urinalysisReferenceForm";
import HematologyForm from "./hematologyReferenceForm";
import ManifoldReferenceForm from "./manifoldReferenceForm";

/** External */
import update from "immutability-helper";
import { Row } from "react-bootstrap";
import SpeciesAndReferenceView from "./view";

class SpeciesAndReferencesForm extends Component {
  
  render() {
    return (
      <SpeciesAndReferenceView {...this.props}/>
    );
  
  }
}

export default FunctionUtil.compose(connectWithEndpoint)(
  SpeciesAndReferencesForm
);
