import React, { Component } from "react";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import PanelMaterialTop from "./../PanelMaterialTop";
import PanelMaterialBody from "./../PanelMaterialBody";
import ButtonSaveMaterial from "./../ButtonSaveMaterial";
import InputMaterial from "./../InputMaterial";
import TableButtonEditMaterial from "./../TableButtonEditMaterial";
import LVTable from "./../../old_components/LVTable";
import { ACCESS_PROFILES } from "./../../assets/js/Consts";
import AccessManager from "./../../old_components/AccessManager";

/** External */
import { Link } from "react-router-dom";

class CustomerListView extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = { pages: 1 };
    this.companyOrIndividual = this.companyOrIndividual.bind(this);
  }

  renderSampleCodeField(
    data,
    styles,
    isUpdate,
    Util,
    $field,
    sampleCodeChange,
    disableAll
  ) {
    const index = data.index;
    const code = data.original.code;

    return (
      <InputMaterial
        className={styles.centered}
        name={`requestSample-${index}`}
        md={12}
        fit={true}
        block={true}
        disabled={disableAll}
        value={code}
        {...$field(`requestSample-${index}`, event => {
          const value = event.target.value;
          if (Util.isStrEmpty(value) || Util.isNumeric(value)) {
            sampleCodeChange(index, value);
          }
        })}
      />
    );
  }

  companyOrIndividual(type) {
    return type == "C" ? "Pessoa Jurídica" : "Pessoa Física";
  }

  render() {
    const { objects, filter } = this.props;

    let config = {
      defaultPageSize: 5,
      defaultSorted: [{ id: "id", desc: true }],
      pageSize: 15,
      resizable: false,
      showPagination: true,
      noDataText: "Nenhum registro".toUpperCase(),
      loadingText: "Carregando",
      className: "-striped -highlight",
      previousText: "Anterior",
      nextText: "Próximo",
      pageText: "Página",
      ofText: "de",
      rowsText: "linhas",
      columns: [],
      showPageSizeOptions: false,
      pageSizeOptions: [5, 10, 15, 20, 25, 50, 100]
    };

    config.data = objects.value && objects.value.data ? objects.value.data : [];
    config.onDoubleClickRow = this.props.onTableDoubleClick;
    config.loading = objects.pending;
    config.columns = [
      {
        id: "id",
        Header: "Código",
        sortable: true,
        accessor: "id",
        className: "centeredText",
        width: 80
      },
      {
        id: "name",
        Header: "Nome",
        sortable: true,
        accessor: "personName",
        className: "centeredText"
      },
      {
        id: "type",
        Header: "Tipo",
        accessor: "type",
        filterable: false,
        sortable: true,
        Cell: data => this.companyOrIndividual(data.original.type),
        className: "centeredText"
      }
    ];

    return (
      <React.Fragment>
        <PanelMaterial>
          <PanelMaterialTop title={"Clientes"}>
            <AccessManager
              allowed={[
                ACCESS_PROFILES.UNIT_ADMINISTRATOR,
                ACCESS_PROFILES.UNIT_USER
              ]}
            >
              <Link to="/clientes/novo">
                <ButtonSaveMaterial
                  title="Novo Cliente"
                  type="highlight"
                  position="top"
                  right={true}
                  disabled={this.props.shouldDisableAll}
                />
              </Link>
            </AccessManager>
          </PanelMaterialTop>
          <PanelMaterialBody>
            <LVTable
              filterable
              manual
              onFetchData={filter}
              pages={this.props.pages}
              data={config.data}
              ofText={config.ofText}
              columns={config.columns}
              loading={config.loading}
              pageSize={config.pageSize}
              rowsText={config.rowsText}
              pageText={config.pageText}
              sortable={config.sortable}
              nextText={config.nextText}
              resizable={config.resizable}
              className={config.className}
              noDataText={config.noDataText}
              loadingText={config.loadingText}
              previousText={config.previousText}
              defaultSorted={config.defaultSorted}
              showPagination={config.showPagination}
              defaultPageSize={config.defaultPageSize}
              showPageSizeOptions={config.showPageSizeOptions}
              onDoubleClickRow={config.onDoubleClickRow}
            />
          </PanelMaterialBody>
        </PanelMaterial>
      </React.Fragment>
    );
  }
}

export default CustomerListView;
