import React, { Component } from "react";

/** Internal */
import AnimalListView from "./../AnimalListView";
import connectWithEndpoint from "./requests.js";

/** External */
import { connect as reduxConnect } from "react-redux";
import { FunctionUtil } from "./../../useful";
import FadeIn from "react-fade-in";
import debounce from "lodash/debounce";
import { Redirect } from "react-router";

class AnimalList extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      pages: 1,
      sorted: null,
      shouldRedirect: false,
      redirectToId: 0,
    };
    this.filter = this.filter.bind(this);
    this.createObjs = this.createObjs.bind(this);
    this.onTableDoubleClick = this.onTableDoubleClick.bind(this);
  }

  onTableDoubleClick(currentId, state, rowInfo, column) {
    this.setState({ shouldRedirect: true, redirectToId: rowInfo.original.id });
  }

  /**
   * @author Victor Heringer
   *
   * Filter breeds table
   *
   * @param {Object} state
   * @param {Object} instance
   *
   */
  filter(state) {
    const filters = {
      id: "",
      name: "",
      customer: "",
      owner: "",
      species: "",
      breed: "",
      externalCode: "",
      microchipCode: "",
      cpf: "",
      phone: "",
      sexId: "",
      customerId: "",
    };

    for (let data in state.filtered) {
      filters[state.filtered[data].id] = state.filtered[data].value;
    }

    const then = (data, xhr) => {
      this.setState({
        pages: data.lastPage,
      });
    };

    this.props.search(filters, state.page, state.pageSize, state.sorted, then);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Maps objects out of request data
   *
   * @param {Object} state
   * @returns {array}
   *
   */
  createObj(vm) {
    return {
      id: vm.id,
      name: vm.name,
      customer: vm.customer,
      owner: vm.owner,
      species: vm.species,
      breed: vm.breed,
      externalCode: vm.externalCode,
      microchipCode: vm.microchipCode,
      cpf: vm.cpf,
      phone: vm.phone,
      sexId: vm.sexId,
    };
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Maps objects out of request data
   *
   * @returns {array}
   *
   */
  createObjs() {
    const { objects } = this.props;
    if (objects && objects.value) {
      return objects.value.map((item) => {
        return this.createObj(item);
      });
    }
    return [];
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Informs wether table is loading
   *
   * @returns {boolean}
   *
   */
  isTableLoading() {
    const { objects } = this.props;

    if (objects === undefined) {
      return true;
    }

    return objects.pending;
  }

  render() {
    if (this.state.shouldRedirect) {
      const id = this.state.redirectToId;
      return <Redirect to={`/animais/${id}`} />;
    }

    const { user, access_profile } = this.props;
    const objects = this.createObjs();
    // console.log(objects);

    return (
      <FadeIn>
        <AnimalListView
          user={user}
          objects={objects}
          isTableLoading={this.isTableLoading()}
          filter={debounce(this.filter.bind(this), 500)}
          accessProfile={access_profile}
          pages={this.state.pages}
          onTableDoubleClick={this.onTableDoubleClick}
        />
      </FadeIn>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user,
  };
};

const connectWithRedux = reduxConnect(mapStateToProps);

export default FunctionUtil.compose(
  connectWithRedux,
  connectWithEndpoint
)(AnimalList);
