import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

/**
 * @author Victor Heringer
 * 
 * Component for material design checkbox
 */
const CheckboxInputMaterial = ({
  id,
  name,
  checked, 
  onChange, 
  className,
  wrapperClassName
}) => (
  <label className={ wrapperClassName  + ' checkbox-input-material-wrapper' }>
    <input
      id={ id }
      checked={ checked }
      name={ name }
      type="checkbox"
      onChange={ onChange }
    />
      <span className={ className +  ' checkbox-input-material' }></span>
  </label>
)

CheckboxInputMaterial.propTypes = {
  checked: PropTypes.bool
};

CheckboxInputMaterial.defaultProps = {
  checked: false
};

export default CheckboxInputMaterial;