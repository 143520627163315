import React from 'react';
import PropTypes from 'prop-types';

/**
* Internal
*/
import PanelMaterial from './../PanelMaterial';
import PanelMaterialBody from '../PanelMaterialBody';
import PanelMaterialTop from '../PanelMaterialTop';
import PanelMaterialBottom from '../PanelMaterialBottom';


const propTypes = {
  title: PropTypes.string
};

const defaultProps = {
  title: ''
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a flat panel material
 * 
 */
const FlatPanelMaterial = ( { title, children } ) =>
  <PanelMaterial className='pb-15 pt-5' flat={ true }>
    <PanelMaterialTop title={ title } />
    <PanelMaterialBody>
      { children }
    </PanelMaterialBody>
    <PanelMaterialBottom>
    </PanelMaterialBottom>
  </PanelMaterial>

FlatPanelMaterial.propTypes = propTypes;
FlatPanelMaterial.defaultProps = defaultProps;

export default FlatPanelMaterial;