import React from 'react';
import Mask from '.';
import PropTypes from 'prop-types';

/**
 * @author Victor Heringer
 * 
 * Adds mask to a phone
 * 
 * @param {String} value 
 */
const PhoneMask = ({ value }) => {
  return value.length > 9  ? <Mask pattern={'(##) ####&-####'} value={value} /> : "";
}

PhoneMask.propTypes = {
  value: PropTypes.string
};

export default PhoneMask;