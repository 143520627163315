import { validated } from 'react-custom-validation';
import {
  required,
  minLength,
  greaterThan,
  validateCNPJ,
  validateCPF,
  validEmail,
  requiredIfExistAnother,
  handleApiValidate
} from '../../assets/js/validationFunctions.js';

import { commonReportRules } from '../../assets/js/validationRules';

const validation = validated(props => {

  const {
    vetSignerId,
    requestExamState,
    isEhrlichiaCannis,
    isBorreliaBurgdorferi,
    isDirofilariaImmitis,
    isAnasplasmaPhagocytophilum,
    materialId,
  } = props.data.fields;

  const {completeValidation} = props.data;

  const commonValidation = commonReportRules(vetSignerId, requestExamState);
  const validations = {
    ...commonValidation,
    materialId: {
      rules: completeValidation ?  [
        [greaterThan, materialId, 0, 'Informe o material enviado']
      ] : []
    },
    isEhrlichiaCannis: {
      rules: completeValidation ?  [
        [greaterThan, isEhrlichiaCannis, -1, 'Informe o campo']
      ] : []
    },
    isBorreliaBurgdorferi: {
      rules: completeValidation ?  [
        [greaterThan, isBorreliaBurgdorferi, -1, 'Informe o campo']
      ] : []
    },
    isDirofilariaImmitis: {
      rules: completeValidation ?  [
        [greaterThan, isDirofilariaImmitis, -1, 'Informe o campo']
      ] : []
    },
    isAnasplasmaPhagocytophilum: {
      rules: completeValidation ?  [
        [greaterThan, isAnasplasmaPhagocytophilum, -1, 'Informe o campo']
      ] : []
    }
  };

  let fields = [
    'requestExamState',
    'vetSignerId',
    'materialId',
    'isEhrlichiaCannis',
    'isBorreliaBurgdorferi',
    'isDirofilariaImmitis',
    'isAnasplasmaPhagocytophilum'
  ];

  return { fields, validations };
});

export default validation;