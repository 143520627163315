import { validated } from 'react-custom-validation';

import { commonReportRules, molecularBiologyRules } from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    vetSignerId,
    requestExamState,
    technique,
    qualitativeResult,
    copyValues,
    precision,
    materials
  } = props.data.fields;

  const {completeValidation} = props.data;

  const commonValidation = commonReportRules( vetSignerId, requestExamState );

  const molecularBiologyValidation = molecularBiologyRules(
    'technique',
    technique,
    'qualitativeResult',
    qualitativeResult,
    'copyValues',
    copyValues,
    'precision',
    precision,
    'materials',
    materials,
    completeValidation
  );

  const validations = {
    ...commonValidation,
    ...molecularBiologyValidation
  };

  let fields = [
    'requestExamState',
    'vetSignerId',
    'technique',
    'qualitativeResult',
    'copyValues',
    'precision',
    'materials'
  ];

  return { fields, validations };
} );

export default validation;