import React from 'react';
import { Row as BsRow } from 'react-bootstrap';
import PropTypes from 'prop-types'; 

/**
 * @author Victor Heringer
 *
 * Rows for grid
 * 
 * @prop {String} componentClass
 * @prop {String} mdClass
 * @prop {String} className
 * @prop {Element} children
 */
const Row = ({ componentClass, mdClass, className, children }) => {
  return (<React.Fragment>
    <BsRow
      componentClass={componentClass}
      bsClass={ mdClass }
      className={ className }
    >
      {children}
    </BsRow>
  </React.Fragment>);
}

Row.defaultProps = {
  mdClass: 'row',
  componentClass: 'div'
};

Row.propTypes = {
  componentClass: PropTypes.string,
  mdClass: PropTypes.string,
  className: PropTypes.string
}

export default Row;