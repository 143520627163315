/**
 * @author Victor Heringer
 *
 * Validation function for email
 *
 * @param {Objcet} props
 *
 * @return {Object}
 */
export const examRequestSearchValidation = props => {
  //const { } = props.data.fields;

  const fields = [""];

  const validations = {};

  return { fields, validations };
};
