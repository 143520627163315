import React from "react";
import * as LifeVet from "./../../../../../components";
import { SamplesSection } from "../samples-section";
import {
  CustomerSelect,
  VetSelect,
  ClinicalReportField,
  AnimalSection,
  AnimalModal,
  ExamsSection,
} from "./../index";
import { Request, RequestExam } from "../../../../../types/index";

export const Body = ({
  data,
  methods,
  errors,
  touched,
  setError,
  setTouched,
  handleChange,
  handleBlur,
  history,
  customersFetching,
  vetsFetching,
  disableAll,
  isMobile,
  loggedUserIsFromClinic,
  loggedUserIsAClinicUser,
  formIsInUpdateMode,
  isReadOnly,
  labId,
  customerId,
  animalModalIsOpen,
  openAnimalModal,
  closeAnimalModal,
  printExam,
  redirectToExam,
  successNotification,
  errorNotification,
  warningNotification,
  infoNotification,
}: {
  data: Request;
  methods: any;
  errors: any;
  touched: any;
  setError: any;
  setTouched: any;
  handleChange: any;
  handleBlur: any;
  customersFetching: any;
  vetsFetching: any;
  history: any;
  isMobile: boolean;
  loggedUserIsFromClinic: boolean;
  loggedUserIsAClinicUser: boolean;
  labId: number;
  customerId: number;
  animalModalIsOpen: boolean;
  openAnimalModal: () => void;
  closeAnimalModal: () => void;
  disableAll: boolean;
  isReadOnly: boolean;
  formIsInUpdateMode: boolean;
  printExam: any;
  redirectToExam: (exam: RequestExam) => void;
  successNotification: any;
  errorNotification: any;
  warningNotification: any;
  infoNotification: any;
}) => {
  const customerIsSelected = data.customer.id > 0;
  const animalIsSelected = data.animal.id > 0;
  return (
    <>
      <LifeVet.PanelBody paddingSize={"small"}>
        <AnimalModal
          customer={data.customer}
          methods={methods.animal}
          handleChange={handleChange}
          labId={labId}
          customerId={data.customer.id}
          loggedUserIsFromClinic={loggedUserIsFromClinic}
          isOpen={animalModalIsOpen}
          closeAnimalModal={closeAnimalModal}
          successNotification={successNotification}
          errorNotification={errorNotification}
        />
        <LifeVet.Row>
          <LifeVet.Col sm={3}>
            <CustomerSelect
              data={data.customer}
              disable={
                disableAll || loggedUserIsFromClinic || formIsInUpdateMode
              }
              customersFetching={customersFetching}
              errors={errors.customer}
              touched={touched.customer}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </LifeVet.Col>

          <LifeVet.Col sm={3}>
            <VetSelect
              data={data.vet}
              methods={methods.vet}
              errors={errors.vet}
              touched={touched.vet}
              handleChange={handleChange}
              handleBlur={handleBlur}
              disable={disableAll || data.customer.id === 0}
              isReadOnly={isReadOnly}
              vetsFetching={vetsFetching}
            />
          </LifeVet.Col>
          <LifeVet.Col sm={6}>
            <ClinicalReportField
              data={data.clinicalReport}
              errors={errors.clinicalReport}
              touched={touched.clinicalReport}
              handleChange={handleChange}
              handleBlur={handleBlur}
              disable={disableAll}
              isReadOnly={isReadOnly}
              vet={data.vet}
            />
          </LifeVet.Col>
        </LifeVet.Row>

        <AnimalSection
          animal={data.animal}
          errors={errors.animal}
          touched={touched.animal}
          disabled={disableAll || formIsInUpdateMode || !customerIsSelected}
          openAnimalModal={openAnimalModal}
        />
        <br />
        <hr style={{ backgroundColor: "#CCCCCC", height: "2px" }} />
        <br />
        <ExamsSection
          exams={data.exams}
          samples={data.samples}
          methods={methods}
          errors={errors.exams}
          touched={errors.exams}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setError={setError}
          loggedUserIsFromClinic={loggedUserIsFromClinic}
          customerId={data.customer.id}
          printExam={printExam}
          redirectToExam={redirectToExam}
          disableAll={disableAll || !customerIsSelected || !animalIsSelected}
          warningNotification={warningNotification}
          errorNotification={errorNotification}
          infoNotification={infoNotification}
        />
        <br />
        <SamplesSection
          data={data.samples}
          methods={methods.samples}
          errors={errors.samples}
          touched={touched.samples}
          setTouched={setTouched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          loggedUserIsFromClinic={loggedUserIsFromClinic}
          disableAll={disableAll}
        />
      </LifeVet.PanelBody>
      
    </>
  );
};
