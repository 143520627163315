import React, { useReducer } from "react";
import { Request, UseRequestState, RequestStatus } from "./../../types/index";
import { useAnimal } from "../animal/index";
import { useCustomer } from "../customer/index";
import { useVet } from "../vet/index";
import { useRequestExams } from "../request-exams/index";
import { useRequestSamples } from "../request-samples/index";
import { reducer, ACTIONS } from "./reducer";

const defaultRequest: UseRequestState = {
  id: 0,
  status: RequestStatus.PENDING,
  createdAt: "2000-01-01 10:00:00",
  updatedAt: "2000-01-01 12:00:00",
  clinicalReport: "",
};

export const useRequest = () => {
  const [request, dispatch] = useReducer(reducer, defaultRequest);
  const animal = useAnimal();
  const customer = useCustomer();
  const vet = useVet();
  const exams = useRequestExams();
  const samples = useRequestSamples();

  const data:Request = {
    id: request.id,
    clinicalReport: request.clinicalReport,
    status: request.status,
    createdAt: request.createdAt,
    updatedAt: request.updatedAt,
    animal: animal.data,
    customer: customer.data,
    vet: vet.data,
    exams: exams.data,
    samples: samples.data,
  };

  const methods = {
    update: (request: Request) => {
      dispatch({ type: ACTIONS.UPDATE, payload: request });
      animal.methods.update(request.animal);
      customer.methods.update(request.customer);
      vet.methods.update(request.vet);
      exams.methods.reset();
      exams.methods.add(request.exams);
      samples.methods.reset();
      samples.methods.add(request.samples);
    },
    updateField: (field: string, value: string | number | boolean) =>
      dispatch({ type: ACTIONS.UPDATE_FIELD, payload: { field, value } }),
    reset: () => {
      dispatch({ type: ACTIONS.UPDATE, payload: defaultRequest });
      animal.methods.reset();
      customer.methods.reset();
      vet.methods.reset();
      exams.methods.reset();
      samples.methods.reset();
    },
    animal: animal.methods,
    customer: customer.methods,
    vet: vet.methods,
    exams: exams.methods,
    samples: samples.methods,
  };

  return {
    data: data,
    defaultData: defaultRequest,
    methods: methods,
  };
};
