import { Yup } from "./yup";
import { AnimalValidationSchema } from "./animal";

const API_URL = process.env.REACT_APP_PROXY;

export const generateRequestValidationSchema = (requestId: number) => {
  Yup.addMethod<Yup.StringSchema>(Yup.string, "isSampleUnique", function () {
    return this.test(
      "is-unique",
      `Código já utilizado.`,
      // @ts-ignore
      function (value: string | undefined | null, context: object) {
        if (value?.length === 0) return Promise.resolve(false);
        return fetch(`${API_URL}/is-unique-sample/${requestId}/${value}`)
          .then((response) => response.json())
          .then((body) => (body.isUnique === true ? true : false));
      }
    );
  });

  return Yup.object().shape({
    id: Yup.number(),
    clinicalReport: Yup.string().required(),
    status: Yup.number(),
    createdAt: Yup.string(),
    updatedAt: Yup.string(),
    customer: Yup.object().shape({
      id: Yup.number().min(1, "Este campo é obrigatório").required(),
      name: Yup.string().required(),
      type: Yup.string(),
      isActive: Yup.boolean(),
      receivesPrintedReport: Yup.boolean(),
      phones: Yup.array().of(
        Yup.object().shape({
          id: Yup.number(),
          value: Yup.string(),
        })
      ),
      emails: Yup.array().of(
        Yup.object().shape({
          id: Yup.number(),
          value: Yup.string(),
          sendReport: Yup.boolean(),
          sendInvoice: Yup.boolean(),
        })
      ),
      addresses: Yup.array().of(
        Yup.object().shape({
          id: Yup.number(),
          publicPlace: Yup.string(),
          cep: Yup.string(),
          number: Yup.string(),
          complement: Yup.string(),
          neighborhood: Yup.string(),
          cityId: Yup.number(),
          stateId: Yup.number(),
        })
      ),
    }),
    vet: Yup.object().shape({
      id: Yup.number().min(1, "Este campo é obrigatório").required(),
      name: Yup.string(),
      crmv: Yup.string(),
      signatureData: Yup.string(),
      signatureFile: Yup.string().nullable(),
    }),
    animal: AnimalValidationSchema,
    exams: Yup.array()
      .min(1, "Selecione ao menos um exame.")
      .of(
        Yup.object().shape({
          id: Yup.number(),
          name: Yup.string(),
          price: Yup.number(),
          deadlineInDays: Yup.number(),
          obs: Yup.string(),
          paymentMethod: Yup.object()
          .shape({
            id: Yup.number(),
            name: Yup.string(),
          })
          .nullable(),
          isPaid: Yup.boolean(),
          status: Yup.number(),
          startDate: Yup.string(),
          attachmentId: Yup.number().nullable(),
          examId: Yup.number(),
        })
      ),
    samples: Yup.array()
      .min(1, "Deve haver ao menos uma amostra.")
      .of(
        Yup.object().shape({
          id: Yup.number(),
          code: Yup.string()
            .min(1, "A amostra deve conter ao menos 1 caracteres.")
            .required("A amostra deve ser preenchida.")
            //@ts-ignore
            .isSampleUnique(),
          name: Yup.string(),
          sampleId: Yup.number(),
        })
      ),
  });
};

export const RequestValidationSchema = Yup.object().shape({
  id: Yup.number(),
  clinicalReport: Yup.string().required(),
  status: Yup.number(),
  createdAt: Yup.string(),
  updatedAt: Yup.string(),
  customer: Yup.object().shape({
    id: Yup.number().min(1, "Este campo é obrigatório").required(),
    name: Yup.string().required(),
    type: Yup.string(),
    isActive: Yup.boolean(),
    receivesPrintedReport: Yup.boolean(),
    phones: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        value: Yup.string(),
      })
    ),
    emails: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        value: Yup.string(),
        sendReport: Yup.boolean(),
        sendInvoice: Yup.boolean(),
      })
    ),
    addresses: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        publicPlace: Yup.string(),
        cep: Yup.string(),
        number: Yup.string(),
        complement: Yup.string(),
        neighborhood: Yup.string(),
        cityId: Yup.number(),
        stateId: Yup.number(),
      })
    ),
  }),
  vet: Yup.object().shape({
    id: Yup.number().min(1, "Este campo é obrigatório").required(),
    name: Yup.string(),
    crmv: Yup.string(),
    signatureData: Yup.string(),
    signatureFile: Yup.string().nullable(),
  }),
  animal: AnimalValidationSchema,
  exams: Yup.array()
    .min(1, "Selecione ao menos um exame.")
    .of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
        price: Yup.number(),
        deadlineInDays: Yup.number(),
        obs: Yup.string(),
        paymentMethod: Yup.object()
          .shape({
            id: Yup.number(),
            name: Yup.string(),
          })
          .nullable(),
        isPaid: Yup.boolean(),
        status: Yup.number(),
        startDate: Yup.string(),
        attachmentId: Yup.number().nullable(),
        examId: Yup.number(),
      })
    ),
  samples: Yup.array()
    .min(1, "Deve haver ao menos uma amostra.")
    .of(
      Yup.object().shape({
        id: Yup.number(),
        code: Yup.string()
          .min(1, "A amostra deve conter ao menos 1 dígito.")
          .required("A amostra deve ser preenchida.")
          //@ts-ignore
          .isSampleUnique(),
        name: Yup.string(),
        sampleId: Yup.number(),
      })
    ),
});
