import React from 'react';

/**
 * @author Victor Heringer
 * 
 * Circle to represente the status of a booleans at tables, lists and etc.
 * Green means true, otherwise the circle will be red
 * 
 * @param {Boolean} status The value witch the color will follow 
 */
const StatusCircle = ({ status }) => { 

  const fill = status ? 'green' : 'red';

  return ( 
    <svg height="9" width="9">
      <circle cx="4" cy="4" r="3" fill={ fill } />
    </svg> 
  )
}

export default StatusCircle;