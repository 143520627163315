import React, { Component } from 'react';

/** External */
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

/** Internal */
import CustomerList from './../CustomerList';
import CustomerForm from './../CustomerForm';
import NotFound from './../NotFound';

/**
 * @author Victor Heringer
 * 
 * Component to handle customer routes
 */
class CustomerSection extends Component {
  
  constructor(  props) {
    super( props );
    this.renderList = this.renderList.bind( this );
    this.renderForm = this.renderForm.bind( this );
  }

  /**
   * @author Victor Heringer
   *
   * Renders the customer list
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderList(routeProps) {
    return ( <CustomerList { ...routeProps } /> );
  }

  /**
   * @author Victor Heringer
   *
   * Renders the breed form
   *
   * @param  {Object} routeProps Props for the route
   *
   * @return {Object}
   */
  renderForm( routeProps ) { 

    return (
      <CustomerForm
        { ...routeProps }
        notificationSystem={ this.props.notificationSystem }
      />
    );
  }

  render() {

    return (
      <Switch>
        <Route
          exact
          path={ '/clientes' }
          render={ this.renderList } />
        <Route
          path={ '/clientes/novo' }
          render={ this.renderForm } />
        <Route
          path={ '/clientes/:id' }
          render={ this.renderForm } />
        <Route component={ NotFound } />
      </Switch>
    );
  }
}

const mapStateToProps = function ( state ) {
  return {
    access_profile: state.userReducer.access_profile,
    user: state.userReducer.user,
  }
}

export default connect( mapStateToProps )( CustomerSection );