export const initialData = {
  titles: {
    density: "Densidade",
    proteins: "Proteínas",
    glucose: "Glicose",
    acetone: "Acetona",
    ph: "Ph",
    bilirubin: "Bilirrubina",
    urobilinogen: "Urobilinogênio",
    hemoglobin: "Hemoglobina",
    leukocyte: "Leucócitos",
    nitrite: "Nitrito",
    epithelialCells: "Células Epiteliais",
    pusCells: "Piócitos",
    erythrocytes: "Hemácias",
    ridges: "Cristais",
    casts: "Cilindros",
    bacterialFlora: "Flora Bacteriana",
    spermatozoa: "Espermatozóides",
    cortisolCreatinineRatio: "Relação Cortisol / Creatinina",
    proteinCreatinineRatio: "Relação Proteína / Creatinina",
    microalbuminuria: "Microalbuminúria",
    urinaryGgt: "Ggt Urinário",
  },
  fields: {
    density: "",
    proteins: "",
    glucose: "",
    acetone: "",
    ph: "",
    bilirubin: "",
    urobilinogen: "",
    hemoglobin: "",
    leukocyte: "",
    nitrite: "",
    epithelialCells: "",
    pusCells: "",
    erythrocytes: "",
    ridges: "",
    casts: "",
    bacterialFlora: "",
    spermatozoa: "",
    cortisolCreatinineRatioMin: "",
    cortisolCreatinineRatioMax: "",
    proteinCreatinineRatioMin: "",
    proteinCreatinineRatioMax: "",
    microalbuminuriaMin: "",
    microalbuminuriaMax: "",
    urinaryGgtMin: "",
    urinaryGgtMax: "",
  },
};
