import React, { Component } from "react";

/** Internal */
import Access from "./access";

/** Assets */
import "./index.css";
import LogoVerticalWhite from "./../../assets/img/logo-vertical-white.png";
import ButtonSaveMaterial from "../ButtonSaveMaterial/index.js";

class LoginForm extends Component {
  componentDidMount(props) {
    const session_expired = localStorage.getItem('session_expired')
    if (session_expired) {
      this.props.notificationSystem.add({
      title: "Atenção!",
      message: "Sua sessão expirou, por favor, faça login novamente!",
      level: "warning",
      position: "tr",
      autoDismiss: 5,
      });
      localStorage.removeItem('session_expired')
    }
  }
  render() {
    const { fields, isSubmitEnabled, handleChange, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit} className="login-wrapper">
        <img className="logo-lifevet" src={LogoVerticalWhite} alt="Logo LifeVet" />
        <div className="container">
          <div className="row">
            <div className="col-md-offset-4 col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  placeholder="USUÁRIO"
                  value={fields.username}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-offset-4 col-md-4">
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="SENHA"
                  value={fields.password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-offset-4 col-md-4">
              <div className="form-group">
                <ButtonSaveMaterial
                  title={"Login"}
                  type={"default"}
                  className="loginBtn"
                  disable={isSubmitEnabled}
                  requesting={this.props.requesting}
                />
              </div>
            </div>
          </div>
        </div>
        {this.props.invalidLogin && (
          <div
            style={{ textAlign: "center" }}
            className={[
              "alert alert-danger",
              "col-md-offset-4",
              "col-md-4"
            ].join(" ")}
          >
            Login ou senha inválida
          </div>
        )}
      </form>
    );
  }
}

export default LoginForm;