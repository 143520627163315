import React from 'react';

/**
 * @author Victor Heringer
 * 
 * @prop {Array} errors
 * @prop {Object} styles
 */
const DisplayErrors = ({ errors, styles }) => { 
  return errors.map((error) => 
    <p className={styles.errors} key={error}>{error}</p> 
  );
}

export default DisplayErrors;