import React from "react";

/**
 * External
 */
import PropTypes from "prop-types";
import { Col, Row } from "./../../components/grid";

/**
 * Internal
 */
import PanelMaterial from "./../PanelMaterial";
import NumberInputWithReference from "./../NumberInputWithReference";
import PanelMaterialBody from "../PanelMaterialBody";
import PanelMaterialTop from "../PanelMaterialTop";

const propTypes = {};

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a Platelets
 */
const BloodProteinPanel = ({
  panelTitle,
  result,
  resultName,
  refMin,
  refMax,
  disableAll,
  fieldChange,
  $field,
  validationReason,
  hasValidation,
}) => {
  return (
    <PanelMaterial className="pb-20" flat={true}>
      <PanelMaterialTop title={panelTitle} />
      <PanelMaterialBody>
        <Col md={12}>
          <NumberInputWithReference
            fieldTitle={"Resultado"}
            value={result}
            fieldName={resultName || "result"}
            shouldDisable={disableAll}
            hasValidation={hasValidation}
            validationReason={validationReason}
            fieldChange={fieldChange}
            $field={$field}
            valueMin={refMin}
            valueMax={refMax}
            decimalScale={1}
            unit={"%"}
          />
        </Col>
      </PanelMaterialBody>
    </PanelMaterial>
  );
};

BloodProteinPanel.propTypes = propTypes;

export default BloodProteinPanel;
