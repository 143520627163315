import React, { Component } from "react";

/** Internal */
import AsciticThoracicFluidReportFormView from "./../AsciticThoracicFluidReportFormView";
import connectWithEndpoint from "./requests.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import validation from "./validation.js";
import { PromiseUtil } from "../../useful";
import {
  BTN_SAVE_TITLE,
  BTN_UPDATE_TITLE,
  BTN_SAVING_TITLE,
  BTN_UPDATING_TITLE,
  REQUEST_EXAM_STATE_ID_BY_NAME,
} from "../../assets/js/Consts.js";
import {
  containerState,
  reportState,
} from "../../modules/factories/default-states.js";
import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";

/** External */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

/**
 * @author Victor Heringer
 *
 * Adds validation to the view
 *
 * @prop {Object} data
 * @prop {Object} methods
 * @prop {Object} $field
 * @prop {Object} $validation
 * @prop {Function} $submit
 */
const View = ({ data, methods, $field, $validation, $submit, $fieldEvent }) => {
  const dataWithValidation = { $validation: $validation, ...data };

  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;

    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };

  const handleChange = (field, value) => {
    $fieldEvent("change", field);
    methods.fieldChange(field, value);
  };

  const handleBlur = (event) => {
    $fieldEvent("blur", event.target.name);
  };

  const methodsWithValidation = {
    onSubmit: onSubmit,
    handleChange: handleChange,
    handleBlur: handleBlur,
    $field: $field,
    ...methods,
  };

  return (
    <AsciticThoracicFluidReportFormView
      data={dataWithValidation}
      methods={methodsWithValidation}
    />
  );
};

const ValidatedView = validation(View);

/**
 * @author Victor Heringer
 *
 * Container for ousourced exams report form
 */
class AsciticThoracicFluidReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        ...reportState(),
        // Physical
        volume: "",
        density: "",
        colorId: 0,
        smellId: 0,
        aspectId: 0,
        sedimentId: 0,
        supernatantId: 0,
        // Chemical
        proteins: "",
        glucose: "",
        hiddenBloodId: 0,
        ph: "",
        coagulation: "",
        // Score
        hemocyte: "",
        hemocyteResult: "",
        leukocytes: "",
        // Others
        result: "",
        obs: "",
      },
      shouldSendEmail: false,
    };

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.redirectToList = redirectToList.bind(this);

    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);

    this.createObj = this.createObj.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.submitData = this.submitData.bind(this);
    this.shouldDisplayLoader = this.shouldDisplayLoader.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        xhr.response.status === 200 && this.fillFields(data);
      };

      this.props.getReportById(id, then);
    }
    this.props.getReportHeader(this.props.match.params.requestExamId);
  }

  /**
   * @author Victor Heringer
   *
   * Handle change on hemocyte and calculate result
   *
   * @param {Number} value
   *
   */
  handleHemocyteChange = (field, value) => {
    this.setState((state) =>
      update(state, {
        fields: {
          hemocyte: { $set: value },
          hemocyteResult: { $set: value * 1000000 },
        },
      })
    );
  };

  /**
   * @author Victor Heringer
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    this.setState((state) =>
      update(state, {
        shouldSendEmail: {
          $set:
            obj.requestExamState ==
            REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_LIBERADO"],
        },
        fields: {
          // Physical
          volume: {
            $set: obj.volume,
          },
          density: {
            $set: obj.density,
          },
          colorId: {
            $set: obj.colorId,
          },
          smellId: {
            $set: obj.smellId,
          },
          aspectId: {
            $set: obj.aspectId,
          },
          sedimentId: {
            $set: obj.sedimentId,
          },
          supernatantId: {
            $set: obj.supernatantId,
          },
          // Chemical
          proteins: {
            $set: obj.proteins,
          },
          glucose: {
            $set: obj.glucose,
          },
          hiddenBloodId: {
            $set: obj.hiddenBloodId,
          },
          ph: {
            $set: obj.ph,
          },
          pandy: {
            $set: obj.pandy,
          },
          coagulation: {
            $set: obj.coagulation,
          },
          // Score
          hemocyte: {
            $set: obj.hemocyte,
          },
          hemocyteResult: {
            $set: obj.hemocyteResult,
          },
          leukocytes: {
            $set: obj.leukocytes,
          },
          // Others
          result: {
            $set: obj.result,
          },
          obs: {
            $set: obj.obs,
          },
          reportDataId: {
            $set: obj.reportDataId,
          },
          vetSignerId: {
            $set: obj.vetSignerId,
          },
          requestExamState: {
            $set: obj.requestExamState,
          },
        },
      })
    );
  }

  /**
   * @author Victor Heringer
   *
   * Creates 4DX object
   *
   * @return {Object}
   *
   */
  createObj() {
    const {
      reportDataId,
      vetSignerId,
      requestExamState,
      volume,
      density,
      colorId,
      smellId,
      aspectId,
      sedimentId,
      supernatantId,
      proteins,
      glucose,
      hiddenBloodId,
      ph,
      pandy,
      coagulation,
      hemocyte,
      hemocyteResult,
      leukocytes,
      result,
      obs,
    } = this.state.fields;

    return {
      id: this.getId(),
      examId: this.state.examId,
      report: this.state.fields.files,
      requestExamId: this.props.match.params.requestExamId,
      reportDataId: reportDataId,
      vetSignerId: vetSignerId,
      requestExamState: requestExamState,
      volume: volume,
      density: density,
      colorId: colorId,
      smellId: smellId,
      aspectId: aspectId,
      sedimentId: sedimentId,
      supernatantId: supernatantId,
      proteins: proteins,
      glucose: glucose,
      hiddenBloodId: hiddenBloodId,
      ph: ph,
      pandy: pandy,
      coagulation: coagulation,
      hemocyte: hemocyte,
      hemocyteResult: hemocyteResult,
      leukocytes: leukocytes,
      result: result,
      obs: obs,
    };
  }

  /**
   * @author Victor Heringer
   *
   * Decides wether is should display the screen loader
   */
  shouldDisplayLoader() {
    const { reportPromise } = this.props;

    let editFetches = null;
    const isUpdate = this.isUpdate();
    const shouldCheckEditFetches = isUpdate && reportPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([reportPromise]);
    }

    return shouldCheckEditFetches && editFetches && editFetches.pending;
  }

  /**
   * @author Victor Heringer
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   */
  submitData() {
    const { reportSubmitPromise } = this.props;

    const isUpdate = this.isUpdate();
    const isSet = reportSubmitPromise !== undefined;
    const isPending = isSet && reportSubmitPromise.pending;
    const isFulfilled = isSet && reportSubmitPromise.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: isUpdate ? BTN_UPDATING_TITLE : BTN_SAVING_TITLE,
      btnTitle: isUpdate ? BTN_UPDATE_TITLE : BTN_SAVE_TITLE,
    };
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    if (!this.state.completeValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" || obj[i] == 0 ? null : obj[i];
      }
    }
    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  render() {
    const { examRequestId, requestExamId } = this.props.match.params;
    const { reportHeaderPromise } = this.props;
    const backLink = "/solicitacoes-exame/" + examRequestId;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    // Loader
    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const submitData = this.submitData();
    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );
    const defaultReferenceValues = { densityMin: 0, densityMax: 0 };

    let refs = {};

    if (this.props.examReference == "ascitic") {
      refs = PromiseUtil.extractValue(
        this.props.asciticRefs,
        defaultReferenceValues,
        (data) => {
          return {
            densityMin: data.asciticDensityMin,
            densityMax: data.asciticDensityMax,
          };
        }
      );
    }
    if (this.props.examReference == "thoracic") {
      refs = PromiseUtil.extractValue(
        this.props.thoracicRefs,
        defaultReferenceValues,
        (data) => {
          return {
            densityMin: data.thoracicDensityMin,
            densityMax: data.thoracicDensityMax,
          };
        }
      );
    }

    const data = {
      isUpdate: this.isUpdate(),
      fields: this.state.fields,
      completeValidation: this.state.completeValidation,
      backLink: backLink,
      shouldSendEmail: this.state.shouldSendEmail,
      requestExamId: requestExamId,
      submitData: submitData,
      examRequestId: examRequestId,
      disableAll: submitData.pending,
      panelTitle: this.props.panelTitle,
      reportHeaderData: reportHeaderFactory(reportHeaderData),
      refs: refs,
      aspectOptions:
        this.props.aspectOptions && this.props.aspectOptions.value
          ? this.props.aspectOptions.value
          : [],
      colorOptions:
        this.props.colorOptions && this.props.colorOptions.value
          ? this.props.colorOptions.value
          : [],
      hiddenBloodOptions:
        this.props.hiddenBloodOptions && this.props.hiddenBloodOptions.value
          ? this.props.hiddenBloodOptions.value
          : [],
      sedimentOptions:
        this.props.sedimentOptions && this.props.sedimentOptions.value
          ? this.props.sedimentOptions.value
          : [],
      smellOptions:
        this.props.smellOptions && this.props.smellOptions.value
          ? this.props.smellOptions.value
          : [],
      supernatantOptions:
        this.props.supernatantOptions && this.props.supernatantOptions.value
          ? this.props.supernatantOptions.value
          : [],
      proteinsOptions:
        this.props.proteinsOptions && this.props.proteinsOptions.value
          ? this.props.proteinsOptions.value
          : [],
      glucoseOptions:
        this.props.glucoseOptions && this.props.glucoseOptions.value
          ? this.props.glucoseOptions.value
          : [],
    };

    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      invalidNotification: this.invalidNotification,
      handleHemocyteChange: this.handleHemocyteChange,
      handleSendReportEmail: this.handleSendReportEmail,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      cancelReport: this.cancelReport,
      stopReport: this.stopReport,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

export default connectWithEndpoint(AsciticThoracicFluidReportForm);
