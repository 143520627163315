import connect from "./../../api-connector";
import { EXAM_TYPES } from "../../consts";

export default connect(props => {
  const requestExamId = props.match.params.requestExamId;
  return {
    refsPromise: `/reference-values/?requestExamId=${requestExamId}&examTypeId=${EXAM_TYPES.HEMATOLOGY}`,
    postReport: (report, release, then) => ({
      reportSubmitPromise: {
        url: `/reports?release=${release}`,
        method: "POST",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    updateReport: (report, release, then) => ({
      reportSubmitPromise: {
        url: `/reports/${report.id}?release=${release}`,
        method: "PUT",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    cancelReport: (report, then) => ({
      reportSubmitPromise: {
        url: `/reports/${report.requestExamId}/cancel`,
        method: "PUT",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    stopReport: (report, then) => ({
      reportSubmitPromise: {
        url: `/reports/${report.id}/stop`,
        method: "PUT",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    sendEmailReport: (id, then) => ({
      sendEmailReportPromisse: {
        url: `/mail/print-report/${id}`,
        method: "POST",
        then: then
      }
    }),
    getReportHeader: id => ({
      reportHeaderPromise: {
        url: `/report/request-exams/${id}/report-header`,
        method: "GET"
      }
    }),

    getReportById: (id, then) => ({
      reportPromise: {
        url: `/reports/${id}`,
        then: then
      }
    })
  };
});
