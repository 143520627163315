import connect from "../../api-connector";

const connectWithEndpoint = connect((props) => ({
  receiveAndAssistRequestExam: (barcode, then, error = () => {}) => ({
    receiveAndAssistPromise: {
      url: `/exam-request/request-sample/code/${barcode}`,
      method: "PUT",
      then: then,
      catch: error,
      force: true,
    },
  }),
  getLabExamRequestsByStatus: () => ({
    pendingExams: `/exam-requests/lab/${props.userLabId}/pending`,
    stuckExams: `/exam-requests/lab/${props.userLabId}/stuck`,
    awaitingExams: `/exam-requests/lab/${props.userLabId}/awaiting`,
    inProgressExams: `/exam-requests/lab/${props.userLabId}/in-progress`,
    lateExams: `/exam-requests/lab/${props.userLabId}/late`,
    attendedPartiallyExams: `/exam-requests/lab/${props.userLabId}/attended-partially`,
    cancelledExams: `/exam-requests/lab/${props.userLabId}/cancelled`,
    attendedExams: `/exam-requests/lab/${props.userLabId}/attended`,
    billedExams: `/exam-requests/lab/${props.userLabId}/billed`,
  }),
  getCustomerExamRequestsByStatus: () => ({
    pendingExams: `/exam-requests/customer/${props.customerId}/pending`,
    awaitingExams: `/exam-requests/customer/${props.customerId}/awaiting`,
    inProgressExams: `/exam-requests/customer/${props.customerId}/in-progress`,
    attendedExams: `/exam-requests/customer/${props.customerId}/attended`,
  }),
}));

export default connectWithEndpoint;
