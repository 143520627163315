import React from "react";
import PropTypes from "prop-types";

/**
 * Internal
 */
import FlatPanelMaterial from "./../FlatPanelMaterial";
import SelectMaterial2 from "./../SelectMaterial2";
import TagsInputMaterial2 from "./../TagsInputMaterial2";
import MinMaxInputMaterial from "./../MinMaxInputMaterial";
import { Col, Row } from "./../../components/grid";

/**
 * @author Victor Heringer
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a SedimentoscopyFields form
 */
const SedimentoscopyFields = ({
  epithelialCellsOptionTagsFieldName,
  epithelialCellsOptionTags,
  epithelialCellsOptionTagsSuggestions,
  epithelialCellsOptionTagsHasValidation,
  epithelialCellsOptionTagsValidationReason,
  epithelialCellsRefs,
  erythrocytesMinFieldName,
  erythrocytesMin,
  erythrocytesMaxFieldName,
  erythrocytesMax,
  erythrocytesHasValidation,
  erythrocytesValidationReason,
  isErythrocytesAbsentFieldName,
  isErythrocytesAbsent,
  isErythrocytesUncountableFieldName,
  isErythrocytesUncountable,
  erythrocytesRefs,
  pusCellsMinFieldName,
  pusCellsMin,
  pusCellsMaxFieldName,
  pusCellsMax,
  pusCellsHasValidation,
  pusCellsValidationReason,
  isPusCellsAbsentFieldName,
  isPusCellsAbsent,
  isPusCellsUncountableFieldName,
  isPusCellsUncountable,
  pusCellsRefs,
  ridgesOptionTagsFieldName,
  ridgesOptionTags,
  ridgesOptionTagsSuggestions,
  ridgesOptionTagsHasValidation,
  ridgesOptionTagsValidationReason,
  ridgesOptionRefs,
  castsOptionTagsFieldName,
  castsOptionTags,
  castsOptionTagsSuggestions,
  castsOptionTagsHasValidation,
  castsOptionTagsValidationReason,
  castsOptionRefs,
  bacterialFloraOptionIdFieldName,
  bacterialFloraOptionId,
  bacterialFloraOptionIdHasValidation,
  bacterialFloraOptionIdValidationReason,
  bacterialFloraOptions,
  bacterialFloraOptionRefs,
  spermatozoaOptionIdFieldName,
  spermatozoaOptionId,
  spermatozoaOptionIdHasValidation,
  spermatozoaOptionIdValidationReason,
  spermatozoaOptions,
  spermatozoaOptionRefs,
  suggestionsFilter,
  handleChange,
  handleBlur,
  handleToggle,
  shouldDisable
}) => (
  <div className="easReportForm tight-fields">
    <FlatPanelMaterial title="Sedimentoscopia">
      <div className="no-padding-cols distante-rows">
        <div className="row">
          <div className="col-xs-12">
            <div className="col-xs-4 col-xs-offset-3 text-center">
              <p className="cleanItem">Resultado</p>
            </div>
            <div className="col-xs-5 text-center">
              <p className="cleanItem">Valor Normal</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 mb-5">
            <div className="col-xs-3 text-right">
              <p className="mr-20 cleanItem">Células Epiteliais</p>
            </div>
            <div className="col-xs-4">
              <TagsInputMaterial2
                title=""
                name={epithelialCellsOptionTagsFieldName}
                tags={epithelialCellsOptionTags}
                disabled={shouldDisable}
                onChange={handleChange}
                onBlur={handleBlur}
                suggestions={epithelialCellsOptionTagsSuggestions}
                suggestionsFilter={suggestionsFilter}
                hasValidation={epithelialCellsOptionTagsHasValidation}
                validationReason={epithelialCellsOptionTagsValidationReason}
                validationRecoil={false}
              />
            </div>
            <div className="col-xs-5">
              <div className=" text-center cleanItem">
                <p>{epithelialCellsRefs}</p>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <MinMaxInputMaterial
            md={12}
            title="Hemácias"
            firstCheckboxTitle="Ausente"
            secondCheckboxTitle="Incontáveis"
            minName={erythrocytesMinFieldName}
            maxName={erythrocytesMaxFieldName}
            minValue={erythrocytesMin}
            maxValue={erythrocytesMax}
            isFirstCheckboxChecked={isErythrocytesAbsent}
            isSecondCheckboxChecked={isErythrocytesUncountable}
            firstCheckboxName={isErythrocytesAbsentFieldName}
            secondCheckboxName={isErythrocytesUncountableFieldName}
            refs={erythrocytesRefs}
            onChange={handleChange}
            onBlur={handleBlur}
            onToggle={handleToggle}
            hasValidation={erythrocytesHasValidation}
            validationReason={erythrocytesValidationReason}
            shouldDisable={shouldDisable}
          />
        </Row>

        <Row>
          <MinMaxInputMaterial
            md={12}
            title="Piócitos"
            firstCheckboxTitle="Ausente"
            secondCheckboxTitle="Incontáveis"
            minName={pusCellsMinFieldName}
            maxName={pusCellsMaxFieldName}
            minValue={pusCellsMin}
            maxValue={pusCellsMax}
            isFirstCheckboxChecked={isPusCellsAbsent}
            isSecondCheckboxChecked={isPusCellsUncountable}
            firstCheckboxName={isPusCellsAbsentFieldName}
            secondCheckboxName={isPusCellsUncountableFieldName}
            refs={pusCellsRefs}
            onChange={handleChange}
            onBlur={handleBlur}
            onToggle={handleToggle}
            hasValidation={pusCellsHasValidation}
            validationReason={pusCellsValidationReason}
            shouldDisable={shouldDisable}
          />
        </Row>

        <div className="row mb-5">
          <div className="col-xs-12">
            <div className="col-xs-3 text-right">
              <p className="mr-20 cleanItem">Cristais</p>
            </div>
            <div className="col-xs-4">
              <TagsInputMaterial2
                title=""
                name={ridgesOptionTagsFieldName}
                tags={ridgesOptionTags}
                disabled={shouldDisable}
                onChange={handleChange}
                onBlur={handleBlur}
                suggestions={ridgesOptionTagsSuggestions}
                suggestionsFilter={suggestionsFilter}
                hasValidation={ridgesOptionTagsHasValidation}
                validationReason={ridgesOptionTagsValidationReason}
                validationRecoil={false}
              />
            </div>
            <div className="col-xs-5">
              <div className="text-center cleanItem">
                <p>{ridgesOptionRefs}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div className="col-xs-3 text-right">
              <p className="mr-20 cleanItem">Cilindros</p>
            </div>

            <div className="col-xs-4 mb-5">
              <TagsInputMaterial2
                title=""
                name={castsOptionTagsFieldName}
                tags={castsOptionTags}
                disabled={shouldDisable}
                onChange={handleChange}
                onBlur={handleBlur}
                suggestions={castsOptionTagsSuggestions}
                suggestionsFilter={suggestionsFilter}
                hasValidation={castsOptionTagsHasValidation}
                validationReason={castsOptionTagsValidationReason}
                validationRecoil={false}
              />
            </div>
            <div className="col-xs-5">
              <div className="text-center cleanItem">
                <p>{castsOptionRefs}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div className="col-xs-3 text-right">
              <p className="mr-20 cleanItem">Flora Bacteriana</p>
            </div>
            <div className="col-xs-4">
              <SelectMaterial2
                title=""
                name={bacterialFloraOptionIdFieldName}
                options={bacterialFloraOptions}
                value={bacterialFloraOptionId}
                isSearchable={true}
                hasValidationRecoil={false}
                shouldDisable={shouldDisable}
                onChange={handleChange}
                onBlur={handleBlur}
                hasValidation={bacterialFloraOptionIdHasValidation}
                validationReason={bacterialFloraOptionIdValidationReason}
              />
            </div>
            <div className="col-xs-5">
              <div className="text-center cleanItem">
                <p>{bacterialFloraOptionRefs}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div className="col-xs-3 text-right">
              <p className="mr-20 cleanItem">Espermatozóides</p>
            </div>
            <div className="col-xs-4">
              <SelectMaterial2
                title=""
                name={spermatozoaOptionIdFieldName}
                options={spermatozoaOptions}
                value={spermatozoaOptionId}
                isSearchable={true}
                hasValidationRecoil={false}
                shouldDisable={shouldDisable}
                onChange={handleChange}
                onBlur={handleBlur}
                hasValidation={spermatozoaOptionIdHasValidation}
                validationReason={spermatozoaOptionIdValidationReason}
              />
            </div>
            <div className="col-xs-5">
              <div className="text-center cleanItem">
                <p>{spermatozoaOptionRefs}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FlatPanelMaterial>
  </div>
);

export default SedimentoscopyFields;
