import React from "react";

/**
 * Internal
 */
import FlatPanelMaterial from "./../FlatPanelMaterial";
import InputTextMaterial from "./../InputTextMaterial";
import CheckboxMaterial from "./../CheckboxMaterial";
import TextAreaMaterial2 from "./../TextAreaMaterial2";
import AntibiogramPanel from "./../AntibiogramPanel";
import { Col, Row } from "./../../components/grid";
import TextAreaPanel2 from "../TextAreaPanel2";

const DefaultMicrobiologyContent = ({
  materialFieldName,
  material,
  materialHasValidation,
  materialValidationReason,
  isNegativeFieldName,
  isNegative,
  resultFieldName,
  result,
  resultHasValidation,
  resultValidationReason,
  antibioticOptions,
  sensitiveTagsFieldName,
  sensitiveTags,
  sensitiveHasValidation,
  sensitiveValidationReason,
  intermediateTagsFieldName,
  intermediateTags,
  intermediateHasValidation,
  intermediateValidationReason,
  resistantTagsFieldName,
  resistantTags,
  resistantHasValidation,
  resistantValidationReason,
  shouldDisable,
  handleChange,
  handleBlur,
  toggleIsNegative,
  suggestionsFilter,
  obsFieldName,
  obs,
  title = 'Cultura'
}) => (
  <React.Fragment>
    <FlatPanelMaterial title={title}>
      <Row>
        <Col md={4}>
          <InputTextMaterial
            title="Material Enviado"
            name={materialFieldName}
            shouldDisable={shouldDisable}
            value={material}
            handleBlur={handleBlur}
            handleChange={handleChange}
            hasValidation={materialHasValidation}
            validationReason={materialValidationReason}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={"mb-10"}>
          <CheckboxMaterial
            name={isNegativeFieldName}
            titleAfter="Negativo"
            checked={isNegative}
            disabled={shouldDisable}
            onChange={toggleIsNegative}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TextAreaMaterial2
            title={"Resultado"}
            name={resultFieldName}
            value={result}
            disabled={shouldDisable}
            handleChange={handleChange}
            handleBlur={handleBlur}
            hasValidation={resultHasValidation}
            validationReason={resultValidationReason}
          />
        </Col>
      </Row>
    </FlatPanelMaterial>
    <Row>
      <Col md={12}>
        <AntibiogramPanel
          antibioticOptions={antibioticOptions}
          suggestionsFilter={suggestionsFilter}
          sensitiveTagsFieldName={sensitiveTagsFieldName}
          sensitiveTags={isNegative ? [] : sensitiveTags}
          sensitiveHasValidation={sensitiveHasValidation}
          sensitiveValidationReason={sensitiveValidationReason}
          intermediateTagsFieldName={intermediateTagsFieldName}
          intermediateTags={isNegative ? [] : intermediateTags}
          intermediateHasValidation={intermediateHasValidation}
          intermediateValidationReason={intermediateValidationReason}
          resistantTagsFieldName={resistantTagsFieldName}
          resistantTags={isNegative ? [] : resistantTags}
          resistantHasValidation={resistantHasValidation}
          resistantValidationReason={resistantValidationReason}
          handleChange={handleChange}
          handleBlur={handleBlur}
          shouldDisable={isNegative || shouldDisable}
        />
      </Col>
      {obs && (
        <Col md={12}>
          <TextAreaPanel2
            title={'Obs'}
            fieldTitle={''}
            name={obsFieldName}
            value={obs}
            shouldDisable={isNegative || shouldDisable}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Col>
      )}
    </Row>
  </React.Fragment>
);

export default DefaultMicrobiologyContent;
