import React from "react";

/** Internal */
import { emailModalValidation } from "./../UnityForm/validation";
import DefaultTable from "./../DefaultTable";
import InputMaterial from "./../InputMaterial";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import CheckboxMaterial from "./../CheckboxInputMaterial";
import { EMAIL_MASK, SUBMIT_MODAL_KEY } from "../../assets/js/Consts";
import TableButtonRemoveMaterial from "./../TableButtonRemoveMaterial";
import TableButtonEditMaterial from "./../TableButtonEditMaterial";
import TableButtonAddMaterial from "./../TableButtonAddMaterial";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";

/** Assets */
import styles from "./index.module.css";

/** External */
import { validated } from "react-custom-validation";
import { Row, Col, Modal } from "react-bootstrap";

/**
 * @author Victor Heringer
 *
 * Checks if a email is selected at table
 *
 * @param {String} name Reference value for email
 * @param {Array} selecteds List of selecteds email
 *
 * @return {Bool}
 */
const isSelectedAtTable = (name, selecteds) => {
  return selecteds.indexOf(name) > -1;
};

/**
 * @author Victor Heringer
 *
 * Render the checkbox to remove the a row
 *
 * @param {Object} data
 * @param {Function} toggleSelectEmail
 * @param {Array} selectedEmails
 */
const renderInputButton = (
  data,
  toggleSelectEmail,
  selectedEmails,
  shouldDisableAll
) => {
  if (!shouldDisableAll) {
    return (
      <CheckboxMaterial
        checked={isSelectedAtTable(data.original.name, selectedEmails)}
        onChange={() => toggleSelectEmail(data.original.name)}
      />
    );
  }

  return <span />;
};

/**
 * @author Victor Heringer
 *
 * Render the edit button
 *
 * @param {Object} data Table data, including id to fetch user
 * @param {Function} handleLoadEmailToModal Calls modal and sets region
 *
 * @return {Object}
 */
const renderEditButton = (data, handleLoadEmailToModal) => {
  const email = { id: data.original.id, value: data.original.name };
  const onClick = () => handleLoadEmailToModal(email);

  if (email.id) {
    return <TableButtonEditMaterial onClick={onClick} />;
  }

  return <span />;
};

/**
 * @author Victor Heringer
 *
 * Returns react table configs
 *
 * @return {Array}
 */
const getTableConfigs = (
  emails,
  toggleSelectEmail,
  selectedEmails,
  handleLoadEmailToModal,
  shouldDisableAll
) => {
  let config = {
    defaultPageSize: 5,
    defaultSorted: [{ id: "id", desc: true }],
    pageSize: 15,
    resizable: false,
    showPagination: true,
    noDataText: "Nenhum registro".toUpperCase(),
    loadingText: "Carregando",
    className: "-striped -highlight",
    previousText: "Anterior",
    nextText: "Próximo",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    columns: [],
    showPageSizeOptions: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100]
  };

  config.data = emails;
  config.pageSize = 3;
  config.className = "no-page-info";
  config.columns = [
    {
      Header: "",
      sortable: false,
      filterable: false,
      width: 60,
      Cell: data =>
        renderInputButton(
          data,
          toggleSelectEmail,
          selectedEmails,
          shouldDisableAll
        )
    },
    {
      Header: "",
      sortable: false,
      width: 60,
      className: styles.centeredText,
      Cell: data => renderEditButton(data, handleLoadEmailToModal)
    },
    {
      Header: "Email",
      accessor: "name",
      className: styles.centeredText,
      sortable: true
    }
  ];

  return config;
};

/**
 * @author Victor Heringer
 *
 * Emails form fields
 */
const EmailsFields = ({
  fields,
  $field,
  $fieldEvent,
  $validation,
  $submit,
  methods: {
    fieldChange,
    addEmailToTable,
    handleLoadEmailToModal,
    toggleSelectEmail,
    handleRemoveEmail,
    fillEmailModal,
    isUpdateing
  },
  data: { selectedEmails, displayEmailModal, defaultEmail, shouldDisableAll }
}) => {
  const modal = (
    <Modal
      show={displayEmailModal}
      onHide={() => handleLoadEmailToModal(defaultEmail)}
    >
      <Modal.Header closeButton>
        <Modal.Title> Adicionar Email </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <LVInputTextUpper
            autofocus={true}
            id="modalEmail"
            title="Email"
            required
            name="modalEmail"
            disabled={shouldDisableAll}
            md={12}
            value={fields.modalEmail}
            {...$field("modalEmail", event => {
              fieldChange("modalEmail", event.target.value);
            })}
            hasValidation={
              $validation &&
              $validation.modalEmail.show &&
              !$validation.modalEmail.isValid
            }
            validationReason={
              $validation && $validation.modalEmail.error.reason
            }
            isValid={$validation && $validation.modalEmail.isValid}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefaultMaterial
        keyCode={SUBMIT_MODAL_KEY}
        onKeyPress={() =>
          $submit(() =>
            addEmailToTable(
              { id: fields.modalEmailId, name: fields.modalEmail },
              $fieldEvent
            )
          )}
          type="default"
          title="Adicionar"
          disabled={shouldDisableAll}
          onClick={() =>
            $submit(() =>
              addEmailToTable(
                { id: fields.modalEmailId, name: fields.modalEmail },
                $fieldEvent
              )
            )
          }
        />
      </Modal.Footer>
    </Modal>
  );

  const openModalBtn = (
    <TableButtonAddMaterial
      title={"Novo Email"}
      className={"pull-right"}
      disabled={shouldDisableAll}
      onClick={() => handleLoadEmailToModal(defaultEmail)}
    />
  );

  const table = (
    <DefaultTable
      configurations={getTableConfigs(
        fields.emails,
        toggleSelectEmail,
        selectedEmails,
        handleLoadEmailToModal,
        shouldDisableAll
      )}
    />
  );

  const removeEmailsBtn = (
    <TableButtonRemoveMaterial
      text={{
        confirm: "Remover",
        message:
          "Os registros selecionados serão removidos permanentemente. Deseja continuar?",
        title: "Remover Emails"
      }}
      className="pull-left"
      disabled={selectedEmails.length === 0}
      onClick={handleRemoveEmail}
    />
  );

  return (
    <React.Fragment>
      {modal}
      <Col md={6}>
        <Row>
          <Col xs={12} className="mb-15 mt-15">
            {openModalBtn}
            {removeEmailsBtn}
            <h5 className="table-title text-center"> Emails </h5>
          </Col>
          <Col xs={12}>{table}</Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

const withValidation = validated(emailModalValidation);

export default withValidation(EmailsFields);
