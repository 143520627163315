import { validated } from 'react-custom-validation';

/**
 * Internal
 */
import {
  required,
  minLength,
  maxLength,
  greaterThan
  } from '../../assets/js/validationFunctions';

import {
  commonReportRules
  } from '../../assets/js/validationRules';

const validation = validated( props => {

  const {
    donorName,
    donorBreed,
    birthDate,
    years,
    months,
    donorSexId,
    donorOwner,
    receptorCorpuscularVolume,
    receptorBloodProtein,
    donorCorpuscularVolume,
    donorBloodProtein,
    majorCrossMatchOptionId,
    minorCrossMatchOptionId,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const {completeValidation} = props.data;

  let fields = [
    'donorName',
    'donorBreed',
    'birthDate',
    'years',
    'months',
    'donorSexId',
    'donorOwner',
    'receptorCorpuscularVolume',
    'receptorBloodProtein',
    'donorCorpuscularVolume',
    'donorBloodProtein',
    'majorCrossMatchOptionId',
    'minorCrossMatchOptionId',
    'vetSignerId',
    'requestExamState'
  ];

  const commonValidation = commonReportRules(
    vetSignerId, requestExamState );

  const validations = {
    ...commonValidation,
    donorName: {
      rules: completeValidation ?  [
        [ required, donorName, 'Obrigatório' ],
        [ minLength, donorName, 2, 'Mínimo de 2 caracteres' ],
        [ maxLength, donorName, 30, 'Máximo de 30 caracteres' ]
      ] : []
    },
    donorBreed: {
      rules: completeValidation ? [
        [ required, donorBreed, 'Obrigatório' ],
        [ minLength, donorBreed, 2, 'Mínimo de 2 caracteres' ],
        [ maxLength, donorBreed, 30, 'Máximo de 30 caracteres' ]
      ] : []
    },
    donorSexId: {
      rules: completeValidation ?  [
        [ greaterThan, donorSexId, 0, 'Obrigatório' ]
      ] : []
    },
    donorOwner: {
      rules: completeValidation ? [
        [ required, donorOwner, 'Obrigatório' ],
        [ minLength, donorOwner, 2, 'Mínimo de 2 caracteres' ],
        [ maxLength, donorOwner, 64, 'Máximo de 64 caracteres' ]
      ] : []
    },
    receptorCorpuscularVolume: {
      rules: completeValidation ?  [
        [ required, receptorCorpuscularVolume, 'Obrigatório' ]
      ] : []
    },
    receptorBloodProtein: {
      rules: completeValidation ? [
        [ required, receptorBloodProtein, 'Obrigatório' ]
      ] : []
    },
    donorCorpuscularVolume: {
      rules: completeValidation ? [
        [ required, donorCorpuscularVolume, 'Obrigatório' ]
      ] : []
    },
    donorBloodProtein: {
      rules: completeValidation ? [
        [ required, donorBloodProtein, 'Obrigatório' ]
      ] : []
    },
    majorCrossMatchOptionId: {
      rules: completeValidation ? [
        [ greaterThan, majorCrossMatchOptionId, 0, 'Obrigatório' ]
      ] : []
    },
    minorCrossMatchOptionId: {
      rules: completeValidation ? [
        [ greaterThan, minorCrossMatchOptionId, 0, 'Obrigatório' ]
      ] : []
    },
  };

 
  return { fields, validations };

} );

export default validation;