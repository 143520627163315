import React from 'react';
import ReactTable from 'react-table-v6';

const DefaultTable = ({ configurations }) => (
 <ReactTable
    data={ configurations.data }
    ofText={ configurations.ofText }
    columns={ configurations.columns }
    loading={ configurations.loading }
    pageSize={ configurations.pageSize }
    rowsText={ configurations.rowsText }
    pageText={ configurations.pageText }
    sortable={ configurations.sortable }
    nextText={ configurations.nextText }
    resizable={ configurations.resizable }
    className={ configurations.className }
    noDataText={ configurations.noDataText }
    loadingText={ configurations.loadingText }
    previousText={ configurations.previousText }
    defaultSorted={ configurations.defaultSorted }
    showPagination={ configurations.showPagination }
    defaultPageSize={ configurations.defaultPageSize }
    showPageSizeOptions={ configurations.showPageSizeOptions }
  />
);

export default DefaultTable;