import React, { Component } from "react";

/** Internal */
import CortisolCreatinineRatioReportFormView from "./../CortisolCreatinineRatioReportFormView";
import connectWithEndpoint from "./requests.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import validation from "./validation.js";
import Decimal from "../../assets/js/Decimal.js";
import { PromiseUtil } from "../../useful";
import {
  containerState,
  reportState,
} from "../../modules/factories/default-states.js";

import {
  getId,
  isUpdate,
  fieldChange,
  redirectToList,
  invalidNotification,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";

import {
  BTN_SAVE_TITLE,
  BTN_UPDATE_TITLE,
  BTN_SAVING_TITLE,
  BTN_UPDATING_TITLE,
  REQUEST_EXAM_STATE_ID_BY_NAME,
} from "../../assets/js/Consts.js";

/** External */
import update from "immutability-helper";
import { FunctionUtil } from "./../../useful";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

const View = ({ data, methods, $field, $validation, $submit, $fieldEvent }) => {
  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;
    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };

  const handleChange = (field, value) => {
    $fieldEvent("change", field);
    methods.fieldChange(field, value);
  };

  const handleBlur = (event) => {
    $fieldEvent("blur", event.target.name);
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    handleChange: handleChange,
    handleBlur: handleBlur,
    $field: $field,
    ...methods,
  };

  return (
    <CortisolCreatinineRatioReportFormView
      data={newData}
      methods={newMethods}
    />
  );
};

const ValidatedView = validation(View);

class CortisolCreatinineRatioReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completeValidation: false,
      fields: {
        ...reportState(),
        cortisol: "",
        creatinine: "",
        obs: "",
      },
      shouldSendEmail: false,
      ...containerState("CORTISOL-CREATININE-RATIO"),
    };

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.createObj = this.createObj.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.fieldChange = fieldChange.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.shouldDisplayLoader = this.shouldDisplayLoader.bind(this);
    this.invalidNotification = invalidNotification.bind(this);
    this.successNotification = successNotification.bind(this);
    this.submitData = this.submitData.bind(this);
    this.calculateRatio = this.calculateRatio.bind(this);
    this.refData = this.refData.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        xhr.response.status === 200 && this.fillFields(data);
      };
      this.props.getReportById(id, then);
    }
    this.props.getReportHeader(this.props.match.params.requestExamId);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    this.setState((state) =>
      update(state, {
        fields: {
          cortisol: { $set: obj.cortisol },
          creatinine: { $set: obj.creatinine },
          obs: { $set: obj.obs },
          reportDataId: { $set: obj.reportDataId },
          vetSignerId: { $set: obj.vetSignerId },
          requestExamState: { $set: obj.requestExamState },
        },
        shouldSendEmail: {
          $set: obj.requestExamState == REQUEST_EXAM_STATE_ID_BY_NAME,
        },
      })
    );
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Creates hemogram object
   *
   * @return {Object}
   *
   */
  createObj() {
    const { examId } = this.state;

    const {
      cortisol,
      creatinine,
      obs,
      reportDataId,
      vetSignerId,
      requestExamState,
    } = this.state.fields;

    const requestExamId = this.props.match.params.requestExamId;

    const result = this.calculateRatio();

    return {
      id: this.getId(),
      examId: examId,
      requestExamId: requestExamId,
      cortisol: cortisol,
      creatinine: creatinine,
      result: result,
      obs: obs,
      reportDataId: reportDataId,
      vetSignerId: vetSignerId,
      requestExamState: requestExamState,
    };
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Decides wether is should
   *  display the screen loader
   *
   */
  shouldDisplayLoader() {
    const { reportPromise } = this.props;
    const isUpdate = this.isUpdate();

    let editFetches = null;

    const shouldCheckEditFetches = isUpdate && reportPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([reportPromise]);
    }

    return isUpdate && editFetches && editFetches.pending;
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Sorts submit promise into a data object
   *
   * @return {Object}
   *
   */
  submitData() {
    const { reportSubmitPromise } = this.props;

    const isUpdate = this.isUpdate();

    const btnTitle = isUpdate ? BTN_UPDATE_TITLE : BTN_SAVE_TITLE;
    const isSet = reportSubmitPromise !== undefined;
    const isPending = isSet && reportSubmitPromise.pending;
    const isFulfilled = isSet && reportSubmitPromise.fulfilled;

    return {
      pending: isPending,
      fulfilled: isFulfilled,
      placeholder: isUpdate ? BTN_UPDATING_TITLE : BTN_SAVING_TITLE,
      btnTitle: btnTitle,
    };
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Calculates cortisol/creatinine ratio
   *
   * @return {number}
   *
   */
  calculateRatio() {
    const { cortisol, creatinine } = this.state.fields;
    const creatinineMultiplyer = 0.0884;
    const cortisolMultiplyer = 27.59;

    if (
      cortisol === "" ||
      creatinine === "" ||
      cortisol === null ||
      creatinine === null
    ) {
      return "";
    }

    const decCortisol = new Decimal(cortisol);
    const decCreatinine = new Decimal(creatinine);
    const multiplyedCortisol = decCortisol.times(cortisolMultiplyer);
    const multiplyedCreatinine = decCreatinine.times(creatinineMultiplyer);
    return multiplyedCortisol.dividedBy(multiplyedCreatinine).toNumber();
  }

  refData() {
    const { refsPromise } = this.props;

    if (refsPromise.pending) {
      return {
        min: "",
        max: "",
      };
    }

    const { cortisolCreatinineRatioMin, cortisolCreatinineRatioMax } =
      refsPromise.value;

    return {
      min: cortisolCreatinineRatioMin,
      max: cortisolCreatinineRatioMax,
    };
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      completeValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };

    if (!this.state.completeValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" || obj[i] == 0 ? null : obj[i];
      }
    }

    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  render() {
    const { examRequestId, requestExamId } = this.props.match.params;
    const { reportHeaderPromise } = this.props;
    const backLink = "/solicitacoes-exame/" + examRequestId;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    // Loader
    if (this.shouldDisplayLoader()) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const submitData = this.submitData();
    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );

    const data = {
      isUpdate: this.isUpdate(),
      completeValidation: this.state.completeValidation,
      fields: this.state.fields,
      backLink: backLink,
      submitData: submitData,
      examRequestId: examRequestId,
      disableAll: submitData.pending,
      reportHeaderData: reportHeaderFactory(reportHeaderData),
      shouldSendEmail: this.state.shouldSendEmail,
      requestExamId: requestExamId,
      refData: this.refData(),
    };

    // Methods
    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      invalidNotification: this.invalidNotification,
      calculateRatio: this.calculateRatio,
      handleSendReportEmail: this.handleSendReportEmail,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      cancelReport: this.cancelReport,
      changeCompleteValidation: this.changeCompleteValidation,
      attendAndNotRelease: this.attendAndNotRelease,
      stopReport: this.stopReport,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}

export default FunctionUtil.compose(connectWithEndpoint)(
  CortisolCreatinineRatioReportForm
);
