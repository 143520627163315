import connect from "../../api-connector";
import { EXAM_TYPES } from "../../consts";

const connectWithEndpoint = connect(props => {
  const requestExamId = props.match.params.requestExamId;

  return {
    reportHeader: `/report/request-exams/${requestExamId}/report-header`,
    refs: `/reference-values/?requestExamId=${requestExamId}&examTypeId=${EXAM_TYPES.URINALYSIS}`,
    colorOptions: "/color-options",
    smellOptions: "/smell-options",
    aspectOptions: "/aspect-options",
    glucoseOptions: "/glucose-options",
    acetoneOptions: "/acetone-options",
    bilirubinOptions: "/bilirubin-options",
    urobilinogenOptions: "/urobilinogen-options",
    hemoglobinOptions: "/hemoglobin-options",
    leukocyteOptions: "/leukocyte-options",
    nitriteOptions: "/nitrite-options",
    bacterialFloraOptions: "/bacterial-flora-options",
    spermatozoaOptions: "/spermatozoa-options",
    proteinsOptions: "/proteins-options",
    epithelialCellsOptions: "/epithelial-cells-options",
    ridgesOptions: "/ridges-options",
    castsOptions: "/casts-options",
    examRequestWithId: (id, then) => ({
      examRequest: {
        url: `/exam-requests/${id}`,
        then: then
      }
    }),
    withId: (id, then) => ({
      obj: {
        url: `/reports/${id}`,
        then: then
      }
    }),
    postReport: (report, release, then) => ({
      reportSubmitPromise: {
        url: `/reports?release=${release}`,
        method: "POST",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    updateReport: (report, release, then) => ({
      reportSubmitPromise: {
        url: `/reports/${report.id}?release=${release}`,
        method: "PUT",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    cancelReport: (report, then) => ({
      reportSubmitPromise: {
        url: `/reports/${report.requestExamId}/cancel`,
        method: "PUT",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    stopReport: (report, then) => ({
      reportSubmitPromise: {
        url: `/reports/${report.id}/stop`,
        method: "PUT",
        force: true,
        body: JSON.stringify(report),
        then: then
      }
    }),
    sendEmailReport: (id, then) => ({
      sendEmailReportPromisse: {
        url: `/mail/print-report/${id}`,
        method: "POST",
        then: then
      }
    }),
    getReportHeader: id => ({
      reportHeaderPromise: {
        url: `/report/request-exams/${id}/report-header`,
        method: "GET"
      }
    }),

    getReportById: (id, then) => ({
      reportPromise: {
        url: `/reports/${id}`,
        then: then
      }
    })
  };
});

export default connectWithEndpoint;
