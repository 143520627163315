export const PENDING = 1;
export const AWAITING = 2;
export const IN_PROGRESS = 3;
export const ATTENDED_AND_NOT_RELEASED = 4;
export const STUCK_BY_PROBLEMS = 5;
export const CANCELLED = 6;
export const ATTENDED_AND_RELEASED = 7;
export const BILLED = 8;

export const values = [
  { id: 1, name: "Pendente de Envio" },
  { id: 2, name: "Aguardando Recebimento" },
  { id: 3, name: "Em Andamento" },
  { id: 4, name: "Atendido e Não Liberado" },
  { id: 5, name: "Parado Por Problemas" },
  { id: 6, name: "Cancelado" },
  { id: 7, name: "Atendido e Liberado" },
  { id: 8, name: "Faturado" },
];

export const getRequestExamStateNameById = (requestExamState) => {
  requestExamState = parseInt(requestExamState);
  switch (requestExamState) {
    case 1:
      return "Gravado e não enviado";
    case 2:
      return "Aguardando Chegada das Amostras";
    case 3:
      return "Em Andamento";
    case 4:
      return "Atendido e não liberado";
    case 5:
      return "Parado por problemas";
    case 6:
      return "Cancelado";
    case 7:
      return "Atendido e Liberado";
    case 8:
      return "Faturado";
    default:
      return `Ainda não definido`;
  }
};

export const toString = (id) => {
  switch (parseInt(id)) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
      return values[id - 1].name;
      break;
    default:
      return "Status de solicitação inválido.";
  }
};

export const REQUEST_EXAM_STATE_ID_BY_NAME = {
  AGUARDANDO_RECEBIMENTO: 2,
  RECEBIDO_EM_ANDAMENTO: 3,
  ATENDIDO_E_NAO_LIBERADO: 4,
  PARADO_POR_PROBLEMAS: 5,
  CANCELADO: 6,
  ATENDIDO_E_LIBERADO: 7,
  FATURADO: 8,
};
