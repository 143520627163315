import { styled } from "../../../../components/stitches";

export const StyledForm = styled('form', {
  padding: "0 15px",
  display: "flex",
  alignItems: 'stretch',
  gap: '10px',

  '& div': {
    width: "100%",
  },

  '@md': {
    flexDirection: 'column'
  }
})