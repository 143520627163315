import {
  required,
  isEqual
} from '../../assets/js/validationFunctions.js';

import { validated } from 'react-custom-validation';

/**
 * @author Victor Heringer
 * 
 * Validation function for change password
 * 
 * @param {Objcet} props 
 * 
 * @return {Object}
 */
const changePasswordValidation = validated( (props) => {

  const {
    currentPassword,
    newPassword,
    reNewPassword
  } = props.data.fields;

  const fields = [
    'currentPassword',
    'newPassword',
    'reNewPassword'
  ];
  
  const validations = {
    currentPassword: {
      rules: [
        [ required, currentPassword, 'Informe a senha' ]
      ]
    },
    newPassword: {
      rules: [
        [ required, newPassword, 'Informe a nova senha' ]
      ]
    },
    reNewPassword: {
      rules: [
        [ required, reNewPassword, 'Confirme a nova senha' ],
        [ isEqual, newPassword, reNewPassword, 'As senhas são diferentes' ]
      ]
    }
  };

  return { fields, validations };
} );

export default changePasswordValidation;