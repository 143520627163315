import React, {
  Component,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { REQUEST_EXAM_STATE_ID_BY_NAME } from "../../consts/request-exam-states";
import useHemogram from "../../hooks/hemogram/use-hemogram";
import { ROUTES, useFetch } from "../../hooks/use-fetch";
import ProfileObesityView from "./view";
import { useFormik } from "formik";
import { UseHeader } from "../../hooks/header";
import HormoneContent from "../../components/hormone-content";
import useHormone from "../../hooks/hormone";
import { useReport } from "../../hooks/report";
import { EXAM_ID_BY_NAME, EXAM_TITLE_BY_ID } from "../../consts/exams";

//submit
//mail
//focus

const ProfileObesity = (props) => {
  const { requestExamId, id: reportId } = props.match.params;

  const biochemistyExamsIds = [
    EXAM_ID_BY_NAME.ALBUMIN,
    EXAM_ID_BY_NAME.IRON,
    EXAM_ID_BY_NAME.IONIC_CALCIUM,
    EXAM_ID_BY_NAME.BILIRUBIN_AND_FRACTIONS,
    EXAM_ID_BY_NAME.ALT_TGP,
  ];
  const profileSimpleBioIds = [
    EXAM_ID_BY_NAME.ALT_TGP,
    EXAM_ID_BY_NAME.CREATININE,
  ];

  // const {
  //   hemogramRefs,
  //   fieldChange: hemogramFieldChage,
  //   fields: hemogramFields,
  //   errors: hemogramErrors,
  //   handleBlur: HandleHemogramBlur,
  //   touched: hemogramTouchedFields,
  //   erythogramHandleChange,
  //   erythogramCounterIsOpen,
  //   closeErythogramCounter,
  //   toggleErythogramCounter,
  //   addCounterErythogram,
  // } = useHemogram({ requestExamId });

  // const {
  //   animalFields,
  //   vets,
  //   deadline,
  //   endDate,
  //   requestSampleNames,
  //   requestExamStates,
  //   HeaderObs,
  //   requestExamStateId,
  //   vetSignerId,
  //   headerChange,
  //   headerErrors,
  // } = UseHeader({ requestExamId });
  // const examsIds = [35, 36];

  useReport({ reportId });
  const { data: biochemistyData, methods: biochemistryMethods } = useHormone({
    examsIds: biochemistyExamsIds,
  });

  // useReport

  return (
    <>
      {/* <ProfileObesityView
        requestExamId={requestExamId}
        hemogramRefs={hemogramRefs}
        hemogramFieldChage={hemogramFieldChage}
        hemogramFields={hemogramFields}
        hemogramErrors={hemogramErrors}
        hemogramTouchedFields={hemogramTouchedFields}
        HandleHemogramBlur={HandleHemogramBlur}
        erythogramHandleChange={erythogramHandleChange}
        erythogramCounterIsOpen={erythogramCounterIsOpen}
        closeErythogramCounter={closeErythogramCounter}
        toggleErythogramCounter={toggleErythogramCounter}
        addCounterErythogram={addCounterErythogram}
        headerChange={headerChange}
        headerErrors={headerErrors}
        animalFields={animalFields}
        vets={vets}
        deadline={deadline}
        endDate={endDate}
        requestSampleNames={requestSampleNames}
        requestExamStates={requestExamStates}
        HeaderObs={HeaderObs}
        requestExamStateId={requestExamStateId}
        vetSignerId={vetSignerId}
        headerErrors={headerErrors}
      /> */}
      <HormoneContent
        exams={"biochemistryState"}
        handleChange={"handleChange"}
        tableData={"tableData"}
        data={biochemistyData}
        methods={biochemistryMethods}
      />
    </>
  );
};

export default React.memo(ProfileObesity);
