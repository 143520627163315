import React from 'react';
import PropTypes from 'prop-types';

/**
 * External
 */
import { Col } from 'react-bootstrap';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';

/**
 * Internal
 */
import InputValidationMsg from './../InputValidationMsg';
import styles from './index.module.css';

const renderInput = (
  id,
  name,
  type,
  value,
  onBlur,
  onChange,
  disabled,
  inputClass,
  autofocus,
  handleChange
) => {
  
  
    const onValueChange = (values, e) => {      
      if(handleChange){
        const name = values.target.name;
        const value = values.target.value;
        return handleChange(name, value);
      }
      return onChange(values);
    };
  return (
    <input
      id={ id }
      autoFocus={ autofocus }
      type={ type }
      name={ name }
      value={ value }
      onBlur={ onBlur }
      disabled={ disabled }
      onChange={ onValueChange}
      className={ inputClass }
    />
  );
}

const renderMaskInput = (
  id,
  name,
  type,
  value,
  onBlur,
  onChange,
  disabled,
  inputClass,
  mask,
  autofocus
) => {

  return (
    <MaskedInput
      id={ id }
      type={ type }
      name={ name }
      mask={ mask }
      autoFocus={ autofocus }
      value={ value }
      onBlur={ onBlur }
      disabled={ disabled }
      onChange={ onChange }
      className={ inputClass }
    />
  );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for input text with material design styling
 */
const InputMaterial = ( {
  id,
  md,
  fit,
  type,
  name,
  title,
  block,
  value,
  onBlur,
  onChange,
  disabled,
  className,
  titleAfter, 
  inputGroup,
  hasValidation,
  validationType,
  inputGroupAfter,
  hasValidationRecoil,
  validationReason,
  containerClassName,
  inputGroupClassName,
  mask,
  maskValue,
  componentUnder,
  autofocus,
  required,
  handleChange
} ) => {

  /**
   * Container input class
   * @type {Object}
   */
  const inputContainerClass = classNames( containerClassName );

  /**
   * Input group class
   * @type {Object}
   */
  const inputGroupClass = classNames(
    { inputGroupClassName },
    { 'input-group': inputGroup },
    { 'form-group': !inputGroup },
    { 'd-block': block },
    { 'fit': fit }
  );

  /**
   * Input class
   * @type {Object}
   */
  const inputClass = classNames(
    'form-control',
    styles.inputMaterial,
    className
  );

  /**
   * Validation Component
   * @type {Object}
   */
  const validation = <InputValidationMsg
    id={ name + '-validation-msg' }
    type={ validationType }
    visible={ hasValidation }
    message={ validationReason }
    top={ hasValidationRecoil ? '-18px' : '' } />;

  const input = renderInput(
    id,
    name,
    type,
    value,
    onBlur,
    onChange,
    disabled,
    inputClass,
    autofocus,
    handleChange
  );

  const maskInput = renderMaskInput(
    id,
    name,
    type,
    value,
    onBlur,
    onChange,
    disabled,
    inputClass,
    maskValue,
    autofocus,
    handleChange
  );

  return (
    <Col md={ md } className={ inputContainerClass }>
      <div className={ inputGroupClass }>
        { title && <label>{ title }</label> }
        { required && <label> * </label> }
        { mask ? maskInput : input }
        { titleAfter &&
          <label className={ styles.labelAfeterMaterial }>
            { titleAfter }
          </label>
        }
        { inputGroup && inputGroupAfter &&
          <span className="input-group-addon">
            { inputGroupAfter }
          </span>
        }
      </div>
      { validation }
      { componentUnder }
    </Col>
  );

}

InputMaterial.defaultProps = {
  md: 12,
  type: 'text',
  title: '',
  disabled: false,
  validationReason: '',
  hasValidation: false,
  validationType: 'danger',
  hasValidationRecoil: true,
  mask:undefined,
  id:undefined,
  maskValue:undefined,
  componentUnder:undefined,
  autofocus:undefined,
  required:undefined,
  handleChange:undefined,
};

InputMaterial.propTypes = {

  /**
   * Required
   */
  name: PropTypes.string.isRequired,

  /**
   * Default
   */
  title: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number
  ] ),
  validationType: PropTypes.oneOf( [ 'danger', 'warning', 'info' ] ),
  hasValidationRecoil: PropTypes.bool,

  /**
   * Custom
   */
  md: PropTypes.number,
  labelInline: PropTypes.bool,
  inputGroup: PropTypes.bool,
  inputGroupAfter: PropTypes.string,
  titleAfter: PropTypes.string,
  hasValidation: PropTypes.bool,
  validationReason: PropTypes.string,
  block: PropTypes.bool,
  fit: PropTypes.bool,

  /**
   * Classes
   */
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string
}

export default InputMaterial;