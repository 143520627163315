import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal
 */
import InputValidationMsg from './../InputValidationMsg';

/**
 * External
 */
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import Autosuggest from 'react-autosuggest';
import { Col } from 'react-bootstrap';
import classNames from 'classnames';

/**
 * Assets
 */
import "./index.css";

/**
 * @author Alessandro Bastos Grandini
 *
 * Decides wether suggestion should be rendered
 * 
 * @param  {String}  name The suggestion name
 *
 * @return {boolean} True or false
 * 
 */
const shouldRenderSuggestions = ( name ) => name && name.trim().length > 0;

/**
 * @author Alessandro Bastos Grandini
 *
 * Extracts suggestion value
 * 
 * @param  {Object}  suggestion The suggestion object
 *
 * @return {String} The name of the suggestion
 * 
 */
const getSuggestionValue = ( suggestion ) => {
  return suggestion.name;
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Filters suggestions 
 * 
 * @param  {Array}    suggestions Array of suggestion objects
 * @param  {function} suggestionsFilter Filter for suggestions
 * @param  {Array}    tags Array of tags
 * 
 * @return {Array} The resulting suggestions
 * 
 */
const filteredSuggestions = ( suggestions, suggestionsFilter, tags ) => {

  if ( suggestionsFilter && suggestions ) {

    const filter = ( suggestions ) => {
      return suggestionsFilter( suggestions, tags );
    }

    return suggestions.filter( filter );
  }

  return suggestions;

}

/**
 * @author Alessandro Bastos Grandini
 *
 * Default props object
 * 
 */
const defaultProps = {
  md: 12,
  type: 'text',
  title: '',
  disabled: false,
  validationReason: '',
  hasValidation: false,
  validationType: 'danger',
  hasValidationRecoil: true,
  suggestionsFilter: undefined,
  inputGroup: false
};

/**
 * @author Alessandro Bastos Grandini
 *
 * Prop types object
 * 
 */
const propTypes = {
  md: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number,
  ] ),
  type: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  validationReason: PropTypes.string,
  hasValidation: PropTypes.bool,
  validationType: PropTypes.string,
  hasValidationRecoil: PropTypes.bool,
  suggestionsFilter: PropTypes.func,
  inputGroup: PropTypes.bool,
  tags: PropTypes.arrayOf( PropTypes.string ).isRequired,
  suggestions: PropTypes.arrayOf( PropTypes.shape( {
    name: PropTypes.string.isRequired
  } ) ),
  updateTags: PropTypes.func
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Wrapper for tags input
 * 
 */
const TagsInputMaterial = ( {
  md,
  fit,
  tags,
  name,
  block,
  onBlur,
  disabled,
  inputGroup,
  updateTags,
  suggestions,
  hasValidation,
  validationType,
  hasValidationRecoil,
  validationReason,
  suggestionsFilter,
  containerClassName,
  inputGroupClassName,
  placeholder,
  title
} ) => {

  /**
   * @author Alessandro Bastos Grandini
   *
   * Updates tag state
   * 
   * @param  {Array}  newTags Array of tag names
   * 
   */
  const handleChange = ( newTags ) => {

    if( updateTags ) {

      const filteredTags = newTags.filter( ( tag ) => {

        for( const suggestion of suggestions ) {
          if ( suggestion.name === tag ) {
            return true;
          }
        }

        return false;

      } );

      updateTags( filteredTags );

    }

  }

  /**
   * Input group class
   * @type {Object}
   */
  const inputGroupClass = classNames(
    { inputGroupClassName },
    { 'input-group': inputGroup },
    { 'form-group': !inputGroup },
    { 'd-block': block },
    { 'fit': fit }
  );

  /**
   * Container input class
   * @type {Object}
   */
  const inputContainerClass = classNames( containerClassName );

  /**
   * @author Alessandro Bastos Grandini
   *
   * Renders autosuggest input
   * 
   * @param  {Object}  obj Object with params
   * 
   * @return {Obj} A configured autosuggest component
   * 
   */
  const autosuggestRenderInput = ( { addTag, ...props } ) => {

    const { disabled } = props;

    const handleOnChange = ( e, { newValue, method } ) => {
      if ( method === 'enter' ) {
        e.preventDefault()
      } else {
        props.onChange( e )
      }
    }

    const inputValue =
      ( props.value && props.value.trim().toLowerCase() ) || '';
    const inputLength = inputValue.length;

    let resultingSuggestions = [];

    if(
         !disabled
      && suggestions
      && suggestionsFilter
      && tags
      ) {
      resultingSuggestions =
        filteredSuggestions( suggestions, suggestionsFilter, tags )
        .filter( ( suggestion ) => {
          const lowercaseValue =
            suggestion.name.toLowerCase().slice( 0, inputLength );
          return ( lowercaseValue === inputValue );
        } );
    }

    const inputProps = {
      ...props,
      placeholder: placeholder ? placeholder : '',
      onChange: handleOnChange,
      onBlur: onBlur
    }

    return (
      <Autosuggest
        ref={ props.ref }
        suggestions={ resultingSuggestions }
        shouldRenderSuggestions={ shouldRenderSuggestions }
        getSuggestionValue={ getSuggestionValue }
        renderSuggestion={ ( suggestion ) => <span>{ suggestion.name }</span> }
        inputProps={ inputProps }
        onSuggestionSelected={ ( e, { suggestion } ) => {
          addTag( suggestion.name )
        } }
        onSuggestionsClearRequested={ () => { } }
        onSuggestionsFetchRequested={ () => { } }
      />
    );

  }

  /**
   * Validation Component
   * @type {Object}
   */
  const validation = <InputValidationMsg
    type={ validationType }
    visible={ hasValidation }
    message={ validationReason }
    top={ hasValidationRecoil ? '-18px' : '' }
  />;

  const disabledClass = disabled ? ' disabled-tag ' : '';

  return(
    <Col md={ md } className={ inputContainerClass + disabledClass }>
      { title && <label className="input-label">{ title }</label> }
      <TagsInput
        value={ tags }
        disabled={ disabled }
        onChange={ handleChange }
        renderInput={ autosuggestRenderInput }
      />
      { validation }
    </Col>
  );

}

TagsInputMaterial.defaultProps = defaultProps;

TagsInputMaterial.propTypes = propTypes;

export default TagsInputMaterial;