import * as biochemistryMethods from "./biochemistryMethods";

const {
  calculateBilirubinIndirect,
  calculateIonicCalcium,
  calculateGlobulin,
  calculateLdl,
  calculateVldl,
} = biochemistryMethods;

export {
  calculateBilirubinIndirect,
  calculateIonicCalcium,
  calculateGlobulin,
  calculateLdl,
  calculateVldl,
};
