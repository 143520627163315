import React from 'react';

/**
 * External
 */
import { Row, Col } from './../../components/grid';

/**
 * Internal
 */
import PanelMaterial from './../PanelMaterial';
import PanelMaterialTop from './../PanelMaterialTop';
import PanelMaterialBody from '../PanelMaterialBody';
import PlateletsDiagnosisContent from '../PlateletsDiagnosisContent';

/**
 * @author Alessandro Bastos Grandini
 *
 * Component that represents a PlateletsDiagnosisPanel form
 * 
 */
const PlateletsDiagnosisPanel = ( {
  title,
  result,
  plateletsObs,
  disableAll,
  $validation,
  fieldChange,
  $field,
  diagnosisFn
} ) =>
  <PanelMaterial className="pt-5" title='Diagnóstico' flat={ true }>
    <PanelMaterialTop title={ title } />
    <PanelMaterialBody>
      <PlateletsDiagnosisContent
        result={ result }
        plateletsObs={ plateletsObs }
        disableAll={ disableAll }
        $validation={ $validation }
        fieldChange={ fieldChange }
        $field={ $field }
        diagnosisFn={ diagnosisFn }
        />
    </PanelMaterialBody>
  </PanelMaterial>

PlateletsDiagnosisPanel.propTypes = {}

export default PlateletsDiagnosisPanel;