import React from 'react';
import { Transition } from 'react-transition-group';

const duration = 0.5;

const defaultStyle = {
  transition: `${ duration }s ease`,
  bottom: '-100%',
  position: 'fixed',
  zIndex: '9999',
  width: '90%',
  left: 'calc( 5% )',
  boxShadow: ' 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
}

const transitionStyles = {
  entering: { bottom: '-100%' },
  entered: { bottom: '0' },
  exiting: {  },
  exited: {  }
};

const SlideUp = ( { children, open } ) => (

  <Transition in={ open } timeout={ duration } >
    { ( state ) => (
      <div style={ {
        ...defaultStyle,
        ...transitionStyles[state]
      } } >
        { children }
      </div>
    ) }
  </Transition>

);

export default SlideUp;