import React from "react";
import { Col, Row } from "../../../components";

/**
 * Internal
 */

// import HemogramPanel from "./../HemogramPanel";
// import ProfileBiochemistryTable from "./../../old_components/ProfileBiochemistryTable";
// import FlatPanelMaterial from "./../FlatPanelMaterial";

/**
 * External
 */
import { EXAM_ID_BY_NAME, profileStructure } from "../../../consts/exams";
import ReportHeader from "../../ReportHeader";
import ReportPanelMaterial from "../../ReportPanelMaterial";
import {
  renderBiochemistryPanel,
  renderEasPanel,
  renderHemogramPanel,
  renderMicrofilariaePanel,
  renderHemocytozoaPanel,
  renderChemiluminescencePanel,
  renderEhrlichiaPanel,
  renderQualitativeAnaplasmPanel,
  renderBabesiaPanel,
  renderRpcuPanel,
  renderReticulocytePanel,
  renderBloodProteinPanel,
  renderLeishmaniasisPanel,
} from "./methods";

const ProfileView = ({ data, methods }) => (
  <ReportPanelMaterial
    title={data.title}
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitPromise && data.submitPromise.pending}
    handleSubmit={methods.onSubmit}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
    handleSendReportEmail={methods.handleSendReportEmail}
    handleFocus={methods.handleFocus}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>

    {/* EXAM ORDER: 
        • Hemogram
        • Hemocytozoa
        • Microfilariae
        • BloodProtein
        • Reticulocytes
        • Biochemistry
        • Chemiluminescence
        • EAS
        • RPCU
        • Leishmaniasis
        • Babesia
        • Ehrlichia
        • Qualitative Anaplasm

        @author Kayo Oliveira
    */}

    {profileStructure[data.fields.examId].exams[EXAM_ID_BY_NAME.HEMOGRAM] &&
      renderHemogramPanel(data, methods)}

    {profileStructure[data.fields.examId].exams[
      EXAM_ID_BY_NAME.HEMOCYTOZOA_RESEARCH
    ] && renderHemocytozoaPanel(data, methods)}

    {profileStructure[data.fields.examId].exams[
      EXAM_ID_BY_NAME.MICROFILARIAE_RESEARCH
    ] && renderMicrofilariaePanel(data, methods)}

    {profileStructure[data.fields.examId].exams[
      EXAM_ID_BY_NAME.BLOOD_PROTEIN
    ] && renderBloodProteinPanel(data, methods)}

    {profileStructure[data.fields.examId].exams[
      EXAM_ID_BY_NAME.RETICULOCYTE_COUNT
    ] && renderReticulocytePanel(data, methods)}

    {profileStructure[data.fields.examId].hasBiochemistry &&
      renderBiochemistryPanel(data, methods)}

    {profileStructure[data.fields.examId].hasChemiluminescence &&
      renderChemiluminescencePanel(data, methods)}

    {profileStructure[data.fields.examId].exams[EXAM_ID_BY_NAME.EAS] &&
      renderEasPanel(data, methods)}

    {profileStructure[data.fields.examId].exams[
      EXAM_ID_BY_NAME.PROTEIN_CREATININE_RATIO
    ] && renderRpcuPanel(data, methods)}

    {profileStructure[data.fields.examId].exams[EXAM_ID_BY_NAME.LEISHMANIOSE] &&
      renderLeishmaniasisPanel(data, methods)}

    {profileStructure[data.fields.examId].exams[EXAM_ID_BY_NAME.BABESIA] &&
      renderBabesiaPanel(data, methods)}

    {profileStructure[data.fields.examId].exams[EXAM_ID_BY_NAME.EHRLICHIA] &&
      renderEhrlichiaPanel(data, methods)}

    {profileStructure[data.fields.examId].exams[
      EXAM_ID_BY_NAME.QUALITATIVE_ANAPLASM_PCR_REAL_TIME
    ] && renderQualitativeAnaplasmPanel(data, methods)}
  </ReportPanelMaterial>
);

export default ProfileView;
