import React from "react";

/** Internal */
import { fiscalModalValidation } from "./../CustomerForm/validation";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";
import SelectMaterial from "./../SelectMaterial";
import {
  PHONE_MASK,
  CPF_MASK,
  CNPJ_MASK,
  EMAIL_MASK
} from "../../assets/js/Consts.js";

/** External */
import { Row, Modal } from "react-bootstrap";
import { validated } from "react-custom-validation";

const FiscalFields = ({
  fields,
  $field,
  $fieldEvent,
  $validation,
  $submit,
  methods: {
    isUpdateing,
    fieldChange,
    addPhoneToTable,
    handleLoadFiscalToModal,
    toggleSelectPhone,
    handleRemovePhone
  },
  data: { displayFiscalModal, defaultFiscal, personTypes, shouldDisableAll }
}) => {
  const nameInput = (
    <LVInputTextUpper
      id="fiscalName"
      name="fiscalName"
      autofocus={true}
      title="Nome"
      md={6}
      value={fields.fiscalName}
      {...$field("fiscalName", event => {
        fieldChange("fiscalName", event.target.value);
      })}
      disabled={shouldDisableAll}
    />
  );

  const documentTypeSelect = (
    <SelectMaterial
      title="Tipo de Pessoa"
      md={6}
      name="fiscalPersonType"
      options={personTypes}
      value={fields.fiscalPersonType}
      searchable={true}
      disabled={shouldDisableAll}
      {...$field("fiscalPersonType", event => {
        fieldChange("fiscalPersonType", event);
      })}
      hasValidation={
        $validation &&
        $validation.fiscalPersonType.show &&
        !$validation.fiscalPersonType.isValid
      }
      validationReason={
        $validation && $validation.fiscalPersonType.error.reason
      }
    />
  );

  const cpfInput = (
    <LVInputTextUpper
      mask
      maskValue={CPF_MASK}
      id="fiscalCPF"
      name="fiscalCPF"
      title="CPF"
      md={6}
      disabled={shouldDisableAll}
      value={fields.fiscalCPF}
      {...$field("fiscalCPF", event => {
        fieldChange("fiscalCPF", event.target.value);
      })}
    />
  );

  const cnpjInput = (
    <LVInputTextUpper
      mask
      maskValue={CNPJ_MASK}
      id="fiscalCNPJ"
      name="fiscalCNPJ"
      title="CNPJ"
      md={6}
      disabled={shouldDisableAll}
      value={fields.fiscalCNPJ}
      {...$field("fiscalCNPJ", event => {
        fieldChange("fiscalCNPJ", event.target.value);
      })}
    />
  );

  const emailInput = (
    <LVInputTextUpper
      id="fiscalEmail"
      name="fiscalEmail"
      title="Email"
      md={6}
      disabled={shouldDisableAll}
      value={fields.fiscalEmail}
      {...$field("fiscalEmail", event => {
        fieldChange("fiscalEmail", event.target.value);
      })}
    />
  );

  const phoneInput = (
    <LVInputTextUpper
      mask
      maskValue={PHONE_MASK}
      id="fiscalPhone"
      name="fiscalPhone"
      title="Telefone"
      md={6}
      disabled={shouldDisableAll}
      value={fields.fiscalPhone}
      {...$field("fiscalPhone", event => {
        fieldChange("fiscalPhone", event.target.value);
      })}
    />
  );

  const cellphoneInput = (
    <LVInputTextUpper
      mask
      maskValue={PHONE_MASK}
      id="fiscalCellphone"
      name="fiscalCellphone"
      title="Celular"
      disabled={shouldDisableAll}
      md={6}
      value={fields.fiscalCellphone}
      {...$field("fiscalCellphone", event => {
        fieldChange("fiscalCellphone", event.target.value);
      })}
    />
  );

  const responsibleNameInput = (
    <LVInputTextUpper
      id="fiscalResponsibleName"
      name="fiscalResponsibleName"
      title="Responsavél"
      md={6}
      disabled={shouldDisableAll}
      value={fields.fiscalResponsibleName}
      {...$field("fiscalResponsibleName", event => {
        fieldChange("fiscalResponsibleName", event.target.value);
      })}
    />
  );

  const rgResponsibleInput = (
    <LVInputTextUpper
      id="fiscalResponsibleRG"
      name="fiscalResponsibleRG"
      title="RG do Responsavél"
      md={6}
      disabled={shouldDisableAll}
      value={fields.fiscalResponsibleRG}
      {...$field("fiscalResponsibleRG", event => {
        fieldChange("fiscalResponsibleRG", event.target.value);
      })}
    />
  );

  const modal = (
    <Modal
      show={displayFiscalModal}
      onHide={() => handleLoadFiscalToModal(defaultFiscal)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Dados Fiscais</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {nameInput}
          {emailInput}
          {documentTypeSelect}
          {fields.fiscalPersonType == 0 && cnpjInput}
          {fields.fiscalPersonType == 1 && cpfInput}
          {phoneInput}
          {cellphoneInput}
          {responsibleNameInput}
          {rgResponsibleInput}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefaultMaterial
          onClick={handleLoadFiscalToModal}
          type="default"
          title="Confirmar"
          disabled={shouldDisableAll}
        />
      </Modal.Footer>
    </Modal>
  );

  return <React.Fragment>{modal}</React.Fragment>;
};

const withValidation = validated(fiscalModalValidation);

export default withValidation(FiscalFields);
