export const connectListWithEndpoint = props => ({
  objects: {
    url: `/species${props.location.search}`,
    comparison: `${props.location.search}`
  }
});

export const connectFormWithEndpoint = props => ({
  species: "/species?status=true",
  add: (obj, then, error) => ({
    specieResponse: {
      url: `/species`,
      method: "POST",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  }),
  update: (id, obj, then, error) => ({
    specieResponse: {
      url: `/species/${id}`,
      method: "PUT",
      body: JSON.stringify(obj),
      then: then,
      catch: error,
      force: true
    }
  }),
  withId: (id, then) => ({
    obj: {
      url: `/species/${id}`,
      then: then
    }
  })
});
