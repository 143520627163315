import { styled } from "../../components/stitches"

export const StyledErrorMessage = styled('div', {
  color: '$rose',
  marginTop: '5px',
  fontFamily: 'Arial',
  fontSize: '12px',

  '@md': {
    textAlign: "right"
  }
})

export const StyledInput = styled('input', {
  borderRadius: "3px",
  border: "none",
  padding: "5px",
  fontSize: "12px",
  width: "100%",
  color: "$blackberry",
  boxSizing: "border-box",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
  fontFamily: "Arial",

  "&::-webkit-input-placeholder": {
    color: "$granadilla"
  },


  variants: {
    error: {
      true: {
        border: "1px solid $rose"
      }
    }
  }
})