class PromiseUtil {
  static extractValue(
    promise,
    placeholderValue,
    valueConversionFunction = (value) => value
  ) {
    if (!promise || promise.pending) {
      return placeholderValue;
    }
    return valueConversionFunction(promise.value);
  }

  static isPending(promisse) {
    return promisse && promisse.pending;
  }
}

export default PromiseUtil;
