import Decimal from "decimal.js";
import { INPUT_SPECIAL_VALUE } from "../../../consts/index.js";


function isEmpty(value) {
  const emptyValues = [
    null,
    "",
    ",",
    ".",
    undefined
  ];
  return emptyValues.includes(value);
}


export function calculateBilirubinIndirect(total, direct) {
    if (total == INPUT_SPECIAL_VALUE || direct == INPUT_SPECIAL_VALUE) {
      return INPUT_SPECIAL_VALUE;
    }
  
    if (isEmpty(total) || isEmpty(direct)) {
      return "";
    }
  
    const totalVal = new Decimal(total);
    const directVal = new Decimal(direct);
  
    return totalVal.minus(directVal).toPrecision();
  }

  export function calculateIonicCalcium(calcium, albumin, totalProtein) {
    
      if(calcium == INPUT_SPECIAL_VALUE || albumin == INPUT_SPECIAL_VALUE || totalProtein == INPUT_SPECIAL_VALUE) {
        return INPUT_SPECIAL_VALUE;
      }
  
    if (isEmpty(calcium) || isEmpty(albumin) || isEmpty(totalProtein)) {
      return "";
    }
  
    const calciumVal = new Decimal(calcium);
    const albuminVal = new Decimal(albumin);
    const totalProteinVal = new Decimal(totalProtein);
  
    const proteinTimesConstant = totalProteinVal.times(0.19);
  
    const firstDenominator = proteinTimesConstant.plus(albuminVal).dividedBy(3);
    const numerator = calciumVal.times(6).minus(firstDenominator);
    const denominator = proteinTimesConstant.plus(albuminVal).plus(6);
    return numerator.dividedBy(denominator).toFixed(1);
  }

  export function calculateVldl(triglycerides) {
    if (isEmpty(triglycerides)) {
      return "";
    }
  
    if (triglycerides == INPUT_SPECIAL_VALUE) {
      return INPUT_SPECIAL_VALUE;
    }
  
    
  
    const triglyceridesVal = new Decimal(triglycerides);
  
    return Math.round(triglyceridesVal.dividedBy(5));
  }

  export function calculateLdl(totalCholesterol, hdl, triglycerides) {
    
    if (isEmpty(totalCholesterol) || isEmpty(hdl) || isEmpty(triglycerides)) {
      return "";
    }
    if (
      totalCholesterol == INPUT_SPECIAL_VALUE ||
      hdl == INPUT_SPECIAL_VALUE ||
      triglycerides == INPUT_SPECIAL_VALUE
    ) {
      return INPUT_SPECIAL_VALUE;
    }
  
    const totalCholesterolVal = new Decimal(totalCholesterol);
    const hdlVal = new Decimal(hdl);
    const vldl = calculateVldl(triglycerides);
    const vldlVal = new Decimal(vldl);
  
    return Math.round(totalCholesterolVal.minus(hdlVal).minus(vldlVal));
  }

  
export function calculateGlobulin(totalProtein, albumin) {
    const isTotalProteinEmpty =
      totalProtein === "" ||
      totalProtein === null ||
      totalProtein === "." ||
      totalProtein === ",";
    const isAlbuminEmpty =
      albumin === "" ||
      albumin === null ||
      totalProtein === "." ||
      albumin === undefined;
  
    if (totalProtein == INPUT_SPECIAL_VALUE || albumin == INPUT_SPECIAL_VALUE) {
      return INPUT_SPECIAL_VALUE;
    }
  
    if (isTotalProteinEmpty || isAlbuminEmpty) {
      return "";
    }
  
    const albuminVal = new Decimal(albumin);
    const totalProteinVal = new Decimal(totalProtein);
  
    if (albuminVal.greaterThan(totalProteinVal)) {
      return 0.0;
    }
  
    return totalProteinVal.minus(albuminVal).toFixed(1);
  }