const pad = number => {
  if (number < 10) {
    return "0" + number;
  }
  return number;
};

export const formatDate = date => {
  const year = date.substr(0, 4);
  const month = date.substr(5, 2);
  const day = date.substr(8, 2);

  return `${day}/${month}/${year}`;
};

export const getTodayDateAsString = () => {
  let now = new Date(Date.now());
  now =
    now.getFullYear() +
    "-" +
    pad(now.getMonth() + 1) +
    "-" +
    pad(now.getDate());

  return now;
};

export const getFirstDayOfTheMonthAsString = (month, year) => {
  if (month && year) {
    return `${year}-${month}-01`;
  }
  let now = new Date(Date.now());
  if (month) {
    return `${now.getFullYear()}-${month}-01`;
  }

  now = now.getFullYear() + "-" + pad(now.getUTCMonth() + 1) + "-01";
  return now;
};

export const getLastDayOfTheMonthAsString = (month, year) => {
  let day = "31";
  let now = new Date(Date.now());
  switch (month) {
    case "02":
      day = "28";
      break;
    case "04":
    case "06":
    case "09":
    case "11":
      day = "30";
      break;
  }
  if (month && year) {
    return `${year}-${month}-${day}`;
  }
  if (month) {
    return `${now.getFullYear()}-${month}-${day}`;
  } else {
    now = now.getFullYear() + "-" + pad(now.getUTCMonth() + 1) + `-${day}`;
    return now;
  }
};
