import React from "react";
import InputLabel from "./input-label";
import Input from "./input";
import { MaskedInput } from "./masked-input";
import TextArea from "./textarea";
import FormGroup from "./form-group";
import ErrorMessage from "./error-message";

export { InputLabel, Input, MaskedInput, TextArea, FormGroup, ErrorMessage };

export const Form = (props) => <form {...props}></form>;
