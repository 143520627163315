import React, { Component } from "react";

/** Internal */
import HemogramReportFormView from "./../HemogramReportFormView";
import connectWithEndpoint from "./requests.js";
import validation from "./validation.js";
import reportHeaderFactory from "../ReportHeader/reportHeaderFactory.js";
import Notifications from "react-notification-system-redux";
import { connect as reduxConnect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "./../../redux/maps";
import { FocusManagerConfig, hemogramConfigOption } from "./../../services";

import {
  containerState,
  reportState,
  hemogramState,
} from "../../modules/factories/default-states.js";

import { PromiseUtil } from "../../useful";
import { REPORT_DEFAULT_VALUES } from "./../../consts";

import { FIVE_SECONDS } from "./../../consts/time-lengths";

/*
  THE Consts.js FILE MUST BE ELIMINATED!
  ALL CONSTS SHALL BE PLACED IN consts/ DIRECTORY.
  */
import { REQUEST_EXAM_STATE_ID_BY_NAME } from "../../assets/js/Consts.js";

import {
  getId,
  isUpdate,
  redirectToList,
  successNotification,
  handleSendReportEmail,
} from "../../assets/js/containerFunctions.js";

import {
  isAnemia,
  isPolicitemia,
  bloodCellsDiagnosis,
  toggleIntenseLeucopeny,
  openCounter,
  closeCounter,
  handleKeyPress,
  enableCounterSound,
  handleFinishedPlaying,
  calcMCV,
  calcMCHC,
  calcTotal,
  calcAbsolute,
  erythrogramDiagnosis,
  mcvDiagnosis,
  mchcDiagnosis,
  leukogramDiagnosis,
  zeroLeukogram,
  incrementField,
  globalLeukDiagnosis,
  resetLeukogram,
  finalGlobalLeukogram,
  isNeutropLeukocytosis,
  diagnosis,
  displayResults,
  DNNE,
  plateletsDiagnosis,
  zeroIfIntenseLeucopeny,
  createHemogramObj,
  createHemogramUpdateState,
  createHemogramRefObj,
  createReportObj,
  createPlateletsObj,
  createErythrogramObj,
  createLeukogramObj,
} from "../../assets/js/reportFunctions.js";

/** External */
import { FunctionUtil } from "./../../useful";
import update from "immutability-helper";
import { BounceLoader } from "react-spinners";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { PromiseState } from "react-refetch";

const View = ({ data, methods, $field, $validation, $submit }) => {
  const onSubmit = (
    release = false,
    parcialSave = false,
    attendAndNotRelease = false
  ) => {
    const submitMethod = release
      ? methods.handleSubmitWithRelease
      : attendAndNotRelease
      ? methods.attendAndNotRelease
      : methods.handleSubmitWithoutRelease;

    const then = () => {
      $submit(submitMethod, methods.invalidNotification);
    };
    parcialSave
      ? methods.changeCompleteValidation(false, then)
      : methods.changeCompleteValidation(true, then);
  };
  const onClickCounter = () => {
    methods.openCounter();
    methods.zeroLeukogram();
  };

  const newData = {
    $validation: $validation,
    ...data,
  };

  const newMethods = {
    onSubmit: onSubmit,
    $field: $field,
    onClickCounter: onClickCounter,
    ...methods,
  };

  return (
    <>
      <HemogramReportFormView data={newData} methods={newMethods} />
      <Notifications notifications={methods.notifications} />
    </>
  );
};

const ValidatedView = validation(View);

class HemogramReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCompleteValidation: true,
      fields: {
        ...reportState(),
        ...hemogramState(),
      },
      ...containerState(),
      shouldSendEmail: false,
    };

    this.getId = getId.bind(this);
    this.isUpdate = isUpdate.bind(this);
    this.redirectToList = redirectToList.bind(this);
    this.successNotification = successNotification.bind(this);
    this.openCounter = openCounter.bind(this);
    this.closeCounter = closeCounter.bind(this);
    this.handleKeyPress = handleKeyPress.bind(this);
    this.enableCounterSound = enableCounterSound.bind(this);
    this.handleFinishedPlaying = handleFinishedPlaying.bind(this);
    this.isAnemia = isAnemia.bind(this);
    this.isPolicitemia = isPolicitemia.bind(this);
    this.bloodCellsDiagnosis = bloodCellsDiagnosis.bind(this);
    this.calcMCV = calcMCV.bind(this);
    this.calcMCHC = calcMCHC.bind(this);
    this.calcTotal = calcTotal.bind(this);
    this.calcAbsolute = calcAbsolute.bind(this);
    this.erythrogramDiagnosis = erythrogramDiagnosis.bind(this);
    this.mcvDiagnosis = mcvDiagnosis.bind(this);
    this.mchcDiagnosis = mchcDiagnosis.bind(this);
    this.leukogramDiagnosis = leukogramDiagnosis.bind(this);
    this.zeroLeukogram = zeroLeukogram.bind(this);
    this.incrementField = incrementField.bind(this);
    this.globalLeukDiagnosis = globalLeukDiagnosis.bind(this);
    this.resetLeukogram = resetLeukogram.bind(this);
    this.finalGlobalLeukogram = finalGlobalLeukogram.bind(this);
    this.isNeutropLeukocytosis = isNeutropLeukocytosis.bind(this);
    this.toggleIntenseLeucopeny = toggleIntenseLeucopeny.bind(this);
    this.zeroIfIntenseLeucopeny = zeroIfIntenseLeucopeny.bind(this);
    this.calcMCV = calcMCV.bind(this);
    this.calcMCHC = calcMCHC.bind(this);
    this.calcTotal = calcTotal.bind(this);
    this.calcAbsolute = calcAbsolute.bind(this);
    this.erythrogramDiagnosis = erythrogramDiagnosis.bind(this);
    this.mcvDiagnosis = mcvDiagnosis.bind(this);
    this.mchcDiagnosis = mchcDiagnosis.bind(this);
    this.leukogramDiagnosis = leukogramDiagnosis.bind(this);
    this.zeroLeukogram = zeroLeukogram.bind(this);
    this.incrementField = incrementField.bind(this);
    this.globalLeukDiagnosis = globalLeukDiagnosis.bind(this);
    this.resetLeukogram = resetLeukogram.bind(this);
    this.finalGlobalLeukogram = finalGlobalLeukogram.bind(this);
    this.isNeutropLeukocytosis = isNeutropLeukocytosis.bind(this);
    this.plateletsDiagnosis = plateletsDiagnosis.bind(this);
    this.toggleIntenseLeucopeny = toggleIntenseLeucopeny.bind(this);
    this.zeroIfIntenseLeucopeny = zeroIfIntenseLeucopeny.bind(this);
    this.diagnosis = diagnosis.bind(this);
    this.displayResults = displayResults.bind(this);
    this.DNNE = DNNE.bind(this);
    this.createPlateletsObj = createPlateletsObj.bind(this);
    this.createErythrogramObj = createErythrogramObj.bind(this);
    this.createLeukogramObj = createLeukogramObj.bind(this);
    this.createHemogramObj = createHemogramObj.bind(this);
    this.createReportObj = createReportObj.bind(this);
    this.createObj = this.createObj.bind(this);
    this.handleSendReportEmail = handleSendReportEmail.bind(this);
  }

  componentDidMount() {
    const id = this.getId();

    if (id > 0) {
      const then = (data, xhr) => {
        xhr.response.status === 200 && this.fillFields(data);
      };

      this.props.getReportById(id, then);
    }

    this.props.getReportHeader(this.props.match.params.requestExamId);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Changes field state
   *
   * @param {string} field The name of the state that represents the field
   * @param {mixed} value The new value
   */
  fieldChange = (field, value) => {
    this.setState((state) =>
      update(state, { fields: { [field]: { $set: value } } })
    );
  };

  /**
   * @author Alessandro Bastos Grandini
   *
   * Fill state related to all form fields
   *
   * @param {string} obj Object with properties to fill state
   *
   */
  fillFields(obj) {
    const editState = createHemogramUpdateState(obj);
    editState.shouldSendEmail = {
      $set:
        obj.requestExamState ==
        REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_LIBERADO"],
    };
    const fillObject = (state) => update(state, editState);
    this.setState(fillObject);
  }

  /**
   * @author Alessandro Bastos Grandini
   *
   * Obtains the submit object
   *
   * @return {Object}
   *
   */
  createObj() {
    return {
      id: this.getId(),
      ...this.createReportObj(),
      ...this.createHemogramObj(),
    };
  }

  cancelReport = () => {
    const release = false;
    const cancel = true;
    const stop = false;
    this.handleSubmit(release, cancel, stop);
  };

  stopReport = () => {
    const release = false;
    const cancel = false;
    const stop = true;
    this.handleSubmit(release, cancel, stop);
  };

  handleSubmitWithRelease = () => {
    this.handleSubmit(true);
  };

  handleSubmitWithoutRelease = () => {
    this.handleSubmit();
  };

  attendAndNotRelease = () => {
    const requestExamState =
      REQUEST_EXAM_STATE_ID_BY_NAME["ATENDIDO_E_NAO_LIBERADO"];
    this.handleSubmit(false, false, false, requestExamState);
  };

  changeCompleteValidation = (value, then) => {
    const toUpdate = {
      showCompleteValidation: { $set: value },
    };
    this.setState((state) => update(state, toUpdate), then);
  };

  /**
   * @author Anderson Souza <andersonsouza@protonmail.com>
   *
   * Pops up a notification for invalid submit
   *
   */
  invalidNotification = () => {
    this.props.errorNotification({
      title: "Atenção, dados incompletos!",
      message: "Verifique os destaques em vermelho.",
      position: "tr",
      autoDismiss: FIVE_SECONDS,
    });
  };

  handleSubmit = (
    release = false,
    cancel = false,
    stop = false,
    requestExamState = 0
  ) => {
    const isUpdate = this.isUpdate();
    const obj = this.createObj();

    const then = (data, xhr) => {
      if (xhr.response.status === 200) {
        const message = isUpdate ? "Exame Atualizado." : "Exame Salvo.";
        this.successNotification(message);
        this.redirectToList();
      } else {
        this.props.notificationSystem.add({
          title: "Erro!",
          level: "error",
          message:
            "Não é possível Salvar Parcial um exame que está Atendido e Liberado",
          position: "tr",
          autoDismiss: 5,
        });
      }
    };
    if (!this.state.showCompleteValidation) {
      for (let i in obj) {
        obj[i] = obj[i] === "" ? null : obj[i];
      }
    }

    if (requestExamState > 0) {
      obj.requestExamState = requestExamState;
    }

    if (cancel) {
      this.props.cancelReport(obj, then);
    } else if (isUpdate) {
      if (stop) {
        this.props.stopReport(obj, then);
      } else {
        this.props.updateReport(obj, release, then);
      }
    } else {
      this.props.postReport(obj, release, then);
    }
  };

  render() {
    const { examRequestId, requestExamId } = this.props.match.params;
    const backLink = "/solicitacoes-exame/" + examRequestId;

    // Redirect
    if (this.state.redirectToList) {
      return <Redirect to={backLink} />;
    }

    const {
      refsPromise,
      reportSubmitPromise,
      reportPromise,
      reportHeaderPromise,
    } = this.props;

    const {
      fields,
      showCompleteValidation,
      isCounterOpen,
      shouldBlockCounterSound,
      showTotalValidation,
    } = this.state;

    const isUpdate = this.isUpdate();

    // Loader
    let editFetches = null;
    const shouldCheckEditFetches = isUpdate && reportPromise !== undefined;

    if (shouldCheckEditFetches) {
      editFetches = PromiseState.all([refsPromise, reportPromise]);
    }

    const shouldDisplayLoader = isUpdate
      ? editFetches && editFetches.pending
      : refsPromise.pending;
    if (shouldDisplayLoader) {
      return <BounceLoader color="#00B4AD" />;
    }

    // Data
    const reportHeaderData = PromiseUtil.extractValue(
      reportHeaderPromise,
      null
    );

    const refs = PromiseUtil.extractValue(
      refsPromise,
      REPORT_DEFAULT_VALUES.HEMOGRAM_REF_DEFAULT,
      createHemogramRefObj
    );

    const total = this.calcTotal();

    const reportPromiseData = PromiseUtil.extractValue(reportPromise, {});

    const data = {
      isUpdate: isUpdate,
      fields: fields,
      showCompleteValidation: showCompleteValidation,
      backLink: backLink,
      refs: refs,
      submitPromise: reportSubmitPromise,
      examRequestId: examRequestId,
      requestExamId: requestExamId,
      isCounterOpen: isCounterOpen,
      shouldBlockCounterSound: shouldBlockCounterSound,
      showTotalValidation: showTotalValidation,
      disableAll: reportSubmitPromise && reportSubmitPromise.pending,
      total: fields.isIntenseLeucopeny ? "" : total,
      mchc: this.calcMCHC(),
      reportHeaderData: reportHeaderFactory(reportHeaderData),
      shouldSendEmail: this.state.shouldSendEmail,
      reportData: reportPromiseData,
    };

    // Methods
    const methods = {
      fieldChange: this.fieldChange,
      handleSubmit: this.handleSubmit,
      openCounter: this.openCounter,
      resetLeukogram: this.resetLeukogram,
      handleKeyPress: this.handleKeyPress,
      calcAbsolute: this.calcAbsolute,
      toggleIntenseLeucopeny: this.toggleIntenseLeucopeny,
      invalidNotification: this.invalidNotification,
      notifications: this.props.notifications,
      finalGlobalLeukogram: this.finalGlobalLeukogram,
      zeroLeukogram: this.zeroLeukogram,
      showvalidationFunctions: this.showvalidationFunctions,
      erythrogramDiagnosis: this.erythrogramDiagnosis,
      leukogramDiagnosis: this.leukogramDiagnosis,
      calcMCV: this.calcMCV,
      calcMCHC: this.calcMCHC,
      calcTotal: this.calcTotal,
      plateletsDiagnosis: this.plateletsDiagnosis,
      handleFinishedPlaying: this.handleFinishedPlaying,
      closeCounter: this.closeCounter,
      handleSendReportEmail: this.handleSendReportEmail,
      handleSubmitWithRelease: this.handleSubmitWithRelease,
      handleSubmitWithoutRelease: this.handleSubmitWithoutRelease,
      attendAndNotRelease: this.attendAndNotRelease,
      changeCompleteValidation: this.changeCompleteValidation,
      cancelReport: this.cancelReport,
      stopReport: this.stopReport,
      handleFocus: this.props.handleFocus,
      setRef: this.props.setRef,
    };

    return (
      <FadeIn>
        <ValidatedView data={data} methods={methods} />
      </FadeIn>
    );
  }
}
const connectWithRedux = reduxConnect(mapStateToProps, mapDispatchToProps);

const focusManager = FocusManagerConfig(
  hemogramConfigOption.config,
  hemogramConfigOption.initialFocus
);

export default FunctionUtil.compose(
  focusManager,
  connectWithEndpoint,
  connectWithRedux
)(HemogramReportForm);
