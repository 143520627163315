/**
 * Internal
 */
import moment from '../../assets/js/moment.js';
import { StringUtil } from '../../useful';

/**
 * External
 */
import update from 'immutability-helper';

export function onChangeSpecies( value ) {  
  this.fieldChange( 'speciesId', value );
  if( value > 0 ) {
    this.props.breedsWithSpeciesId( value );
  }
  this.fieldChange( 'breedId', 0 );
}

/**
 * @author Alessandro Bastos Grandini
 *
 * Changes birthDate field state
 *
 * @param {mixed} value The new value
 * 
 */
export function birthDateChange( value ) {

 const fillYearsAndMonths = () => {

   const { birthDate } = this.state.fields;
   const years = StringUtil.dateToYears(birthDate);
   const monthsLeft = StringUtil.dateToMonths(birthDate);
   this.setState( ( state ) => update(
     state, { fields: {
       [ 'years' ]: { $set: years ? years.toString() : 0 },
       [ 'months' ]: { $set: monthsLeft ? monthsLeft.toString() : 0 }
     } }
   ) );

 };

 this.setState( ( state ) => update(
   state, { fields: { [ 'birthDate' ]: { $set: value } } }
 ), fillYearsAndMonths );
 
}

export function yearsMonthsChange(field, value) {   

  const fillBirthDate = () => {
    const {months,years} = this.state.fields;
    const now = moment(); 
    let date = now.add(-months, 'M');
    date = date.add(-years, 'y');

    this.setState( ( state ) => update(
      state, { fields: { [ 'birthDate' ]: { $set: date } } }
    ) );
  }

  this.setState( ( state ) => update(
    state, { fields: {
      [ field ]: { $set: value }
    } }
  ),  fillBirthDate);

}