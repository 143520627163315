import React,{useEffect} from "react"

import { useForm } from "react-hook-form"

import { validationSchema } from "./validations/schema"
import { yupResolver } from '@hookform/resolvers/yup'

import { AntibioticFormDefaultValues } from "./consts"
import { NewAntibioticFormDataType } from "./@types"

import { Row } from "../../../../old_components/Grid"
import { Input } from "../../../../new_components/Input"
import { Label } from "../../../../new_components/Label"

import { StyledForm } from "./styles"

interface AntibioticFormFieldsProps {
  onSubmit: (data: NewAntibioticFormDataType) => void
  fields?: NewAntibioticFormDataType
}

export function AntibioticFormFields({ onSubmit, fields }: AntibioticFormFieldsProps) {
  const resolver = yupResolver(validationSchema)

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue
  } = useForm<NewAntibioticFormDataType>({
    resolver,
    defaultValues: AntibioticFormDefaultValues
  })

  useEffect(() => { 
    if (fields) {
      setValue('name', fields.name)
      setValue('initials', fields.initials)
    }
  }, [fields])

  return (
    <Row>
      <StyledForm id={'antibiotic-form'} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Label htmlFor="name" error={errors.name}>Nome do Antibiótico</Label>
          <Input id="name" {...register("name")} error={errors.name} />
        </div>

        <div>
          <Label htmlFor="initials" error={errors.name}>Iniciais do Antibiótico</Label>
          <Input id="initials" {...register("initials")} error={errors.initials} />
        </div>
      </StyledForm>
    </Row>
  )
}