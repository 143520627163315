import React, { useRef } from "react";

/** Internal */
import { userModalValidation } from "./../CustomerForm/validation";
import LVTable from "./../../old_components/LVTable";
import InputMaterial from "./../InputMaterial";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import SelectMaterial from "./../SelectMaterial";
import CheckboxMaterial from "./../CheckboxInputMaterial";
import StatusCircle from "./../StatusCircle";
import TableButtonRemoveMaterial from "./../TableButtonRemoveMaterial";
import TableButtonEditMaterial from "./../TableButtonEditMaterial";
import TableButtonAddMaterial from "./../TableButtonAddMaterial";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import eye from "@fortawesome/fontawesome-free-solid/faEye";
import {
  STATUS,
  YES_OR_NO,
  CPF_MASK,
  PHONE_MASK,
  USER_TYPE,
  ACCESS_PROFILES_LIST,
  SUBMIT_MODAL_KEY,
} from "../../assets/js/Consts.js";
import { CLINIC_VALUES } from "../../consts/access-profiles.js";

/** External */
import { validated } from "react-custom-validation";
import { Row, Col, Modal } from "react-bootstrap";
import DefaultTable from "../DefaultTable";

/**
 * @author Victor Heringer
 *
 * Render the checkbox thats allow to delete user
 *
 * @param {Object} data
 * @param {Function} toggleSelectUser
 * @param {Array} selectedUsers
 */
const renderCheckbox = (data, toggleSelectUser, selectedUsers) => {
  if (!data.original.id) {
    return (
      <CheckboxMaterial
        checked={selectedUsers.indexOf(data.original.name) > -1}
        onChange={() => toggleSelectUser(data.original.name)}
      />
    );
  }

  return <span />;
};

/**
 * @author Victor Heringer
 *
 * Render a circle to display boolean status
 *
 * @param {Bool} status
 *
 * @return {Object}
 */
const renderStatusCircle = (status) => {
  return <StatusCircle status={status} />;
};

const renderUserType = (type) => {
  return USER_TYPE[type].name;
};

/**
 * @author Victor Heringer
 *
 * Render the edit button
 *
 * @param {Object} data Table data, including id to fetch user
 * @param {Function} handleLoadUserToModal Calls modal and sets the user
 *
 * @return {Object}
 */
const renderEditButton = (data, handleLoadUserToModal) => {
  const user = {
    id: data.original.id,
    login: data.original.login,
    active: data.original.access,
    password: "",
    username: data.original.name,
    email: data.original.email,
    phone: data.original.phone,
    optPhone: data.original.optPhone,
    cpf: data.original.cpf,
    isVet: data.original.isVet,
    crmv: data.original.crmv,
    accessProfile: data.original.accessProfile,
  };

  const onClick = () => handleLoadUserToModal(user);

  return <TableButtonEditMaterial onClick={onClick} disabled={!user.id} />;
};

/**
 * @author Victor Heringer
 *
 * Returns react table configs
 *
 * @return {Array}
 */
const getTableConfigs = (
  users,
  toggleSelectUser,
  selectedUsers,
  handleLoadUserToModal
) => {
  let config = {
    defaultPageSize: 5,
    defaultSorted: [{ id: "id", desc: true }],
    pageSize: 15,
    resizable: false,
    showPagination: true,
    noDataText: "Nenhum registro".toUpperCase(),
    loadingText: "Carregando",
    className: "-striped -highlight",
    previousText: "Anterior",
    nextText: "Próximo",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    columns: [],
    showPageSizeOptions: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
  };

  config.data = users;
  config.pageSize = 3;
  config.className = "no-page-info";
  config.columns = [
    {
      Header: "",
      sortable: false,
      filterable: false,
      width: 60,
      Cell: (data) => renderCheckbox(data, toggleSelectUser, selectedUsers),
    },
    {
      Header: "",
      sortable: false,
      width: 60,
      className: "centeredText",
      Cell: (data) => renderEditButton(data, handleLoadUserToModal),
    },
    {
      Header: "Nome",
      accessor: "name",
      sortable: true,
      className: "centeredText",
    },
    {
      Header: "Veterinário",
      accessor: "isVet",
      sortable: true,
      Cell: (data) => renderStatusCircle(data.original.isVet),
      className: "centeredText",
    },
    {
      Header: "Tipo",
      accessor: "type",
      sortable: true,
      Cell: (data) => renderUserType(data.original.type),
      className: "centeredText",
    },
    {
      Header: "Acesso",
      accessor: "access",
      Cell: (data) => renderStatusCircle(data.original.access),
      sortable: true,
      className: "centeredText",
    },
  ];
  return config;
};

/**
 * @author Victor Heringer
 *
 * Regions form fields
 */
const UsersFields = ({
  fields,
  $field,
  $fieldEvent,
  $validation,
  $submit,
  methods: {
    fieldChange,
    fieldChangeIsVet,
    addUserToTable,
    handleRemoveUser,
    handleLoadUserToModal,
    toggleSelectUser,
    toggleShowHidePassword,
  },
  data: {
    selectedUsers,
    displayUserModal,
    defaultUser,
    shouldDisableAll,
    userPasswordInputType,
  },
}) => {
  const userfields = {
    id: fields.modalUserId,
    login: fields.modalUserLogin,
    active: fields.modalUserActive,
    password: fields.modalUserPassword,
    name: fields.modalUserName,
    email: fields.modalUserEmail,
    phone: fields.modalUserPhone,
    cpf: fields.modalUserCPF,
    optionalPhone: fields.modalUserOptionalPhone,
    isVet: fields.modalUserIsVet,
    crmv: fields.modalUserCRMV,
    type: fields.modalUserType,
    accessProfile: fields.modalUserAccessProfile,
  };
  const nameInput = (
    <LVInputTextUpper
      id="modalUserName"
      name="modalUserName"
      title="Nome"
      required
      autofocus={true}
      md={6}
      disabled={shouldDisableAll}
      value={fields.modalUserName}
      {...$field("modalUserName", (event) => {
        fieldChange("modalUserName", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserName.show &&
        !$validation.modalUserName.isValid
      }
      validationReason={$validation && $validation.modalUserName.error.reason}
      isValid={$validation && $validation.modalUserName.isValid}
    />
  );

  const cpfInput = (
    <LVInputTextUpper
      id="modalUserCPF"
      name="modalUserCPF"
      title="CPF"
      md={6}
      mask
      maskValue={CPF_MASK}
      disabled={shouldDisableAll}
      value={fields.modalUserCPF}
      {...$field("modalUserCPF", (event) => {
        fieldChange("modalUserCPF", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserCPF.show &&
        !$validation.modalUserCPF.isValid
      }
      validationReason={$validation && $validation.modalUserCPF.error.reason}
      isValid={$validation && $validation.modalUserCPF.isValid}
    />
  );

  const emailInput = (
    <LVInputTextUpper
      id="modalUserEmail"
      name="modalUserEmail"
      title="Email"
      md={6}
      disabled={shouldDisableAll}
      value={fields.modalUserEmail}
      {...$field("modalUserEmail", (event) => {
        fieldChange("modalUserEmail", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserEmail.show &&
        !$validation.modalUserEmail.isValid
      }
      validationReason={$validation && $validation.modalUserEmail.error.reason}
      isValid={$validation && $validation.modalUserEmail.isValid}
    />
  );

  const typeInput = (
    <SelectMaterial
      title="Tipo"
      md={6}
      disabled={shouldDisableAll}
      name="modalUserType"
      options={USER_TYPE}
      value={fields.modalUserType}
      searchable={true}
      {...$field("modalUserType", (event) => {
        fieldChange("modalUserType", event);
      })}
    />
  );

  const phoneInput = (
    <LVInputTextUpper
      id="modalUserPhone"
      name="modalUserPhone"
      title="Telefone"
      md={6}
      mask
      maskValue={PHONE_MASK}
      disabled={shouldDisableAll}
      value={fields.modalUserPhone}
      {...$field("modalUserPhone", (event) => {
        fieldChange("modalUserPhone", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserPhone.show &&
        !$validation.modalUserPhone.isValid
      }
      validationReason={$validation && $validation.modalUserPhone.error.reason}
      isValid={$validation && $validation.modalUserPhone.isValid}
    />
  );

  const optionalPhoneInput = (
    <LVInputTextUpper
      id="modalUserOptionalPhone"
      name="modalUserOptionalPhone"
      title="Telefone Adicional"
      md={6}
      mask
      maskValue={PHONE_MASK}
      disabled={shouldDisableAll}
      value={fields.modalUserOptionalPhone}
      {...$field("modalUserOptionalPhone", (event) => {
        fieldChange("modalUserOptionalPhone", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserOptionalPhone.show &&
        !$validation.modalUserOptionalPhone.isValid
      }
      validationReason={
        $validation && $validation.modalUserOptionalPhone.error.reason
      }
      isValid={$validation && $validation.modalUserOptionalPhone.isValid}
    />
  );

  const loginInput = (
    <LVInputTextUpper
      id="modalUserLogin"
      name="modalUserLogin"
      title="Login"
      required
      md={6}
      disabled={shouldDisableAll}
      value={fields.modalUserLogin}
      {...$field("modalUserLogin", (event) => {
        fieldChange("modalUserLogin", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserLogin.show &&
        !$validation.modalUserLogin.isValid
      }
      validationReason={$validation && $validation.modalUserLogin.error.reason}
      isValid={$validation && $validation.modalUserLogin.isValid}
    />
  );

  const passwordInput = (
    <>
      <InputMaterial
        id="modalUserPassword"
        name="modalUserPassword"
        title="Senha"
        required
        type={userPasswordInputType}
        md={4}
        disabled={shouldDisableAll}
        value={fields.modalUserPassword}
        {...$field("modalUserPassword", (event) => {
          fieldChange("modalUserPassword", event.target.value);
        })}
        hasValidation={
          $validation &&
          $validation.modalUserPassword.show &&
          !$validation.modalUserPassword.isValid
        }
        validationReason={
          $validation && $validation.modalUserPassword.error.reason
        }
        isValid={$validation && $validation.modalUserPassword.isValid}
      />
      <Col md={2}>
        <label>&nbsp;</label>
        <br />
        <button
          onClick={() => toggleShowHidePassword(userPasswordInputType)}
          title="Mostrar/Esconder Senha"
        >
          <FontAwesomeIcon icon={eye} size="sm" />
        </button>
      </Col>
    </>
  );

  const active = (
    <SelectMaterial
      title="Ativo"
      md={6}
      name="modalUserActive"
      options={STATUS}
      disabled={shouldDisableAll}
      value={fields.modalUserActive}
      searchable={true}
      {...$field("modalUserActive", (event) => {
        fieldChange("modalUserActive", event);
      })}
    />
  );

  const accessProfile = (
    <SelectMaterial
      title="Perfil de acesso"
      md={6}
      name="modalUserAccessProfile"
      options={CLINIC_VALUES}
      value={fields.modalUserAccessProfile}
      searchable={true}
      {...$field("modalUserAccessProfile", (event) => {
        fieldChange("modalUserAccessProfile", event);
      })}
    />
  );

  const shouldDisableVet =
    fields.modalUserId > 0 && fields.modalUserInitIsVet ? true : false;

  const isVetSelect = (
    <SelectMaterial
      title="Veterinário"
      md={6}
      name="modalUserIsVet"
      options={YES_OR_NO}
      value={fields.modalUserIsVet}
      disabled={shouldDisableAll || shouldDisableVet}
      searchable={true}
      {...$field("modalUserIsVet", (event) => {
        fieldChangeIsVet("modalUserIsVet", event);
      })}
    />
  );

  const crmv = (
    <LVInputTextUpper
      id="modalUserCRMV"
      name="modalUserCRMV"
      title="CRMV"
      md={6}
      disabled={!fields.modalUserIsVet || shouldDisableAll}
      value={fields.modalUserCRMV}
      {...$field("modalUserCRMV", (event) => {
        fieldChange("modalUserCRMV", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserCRMV.show &&
        !$validation.modalUserCRMV.isValid
      }
      validationReason={$validation && $validation.modalUserCRMV.error.reason}
      isValid={$validation && $validation.modalUserCRMV.isValid}
    />
  );

  const modal = (
    <Modal
      show={displayUserModal}
      onHide={() => handleLoadUserToModal(defaultUser)}
    >
      <Modal.Header closeButton>
        <Modal.Title> Adicionar Usuário </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {nameInput}
          {cpfInput}
          {emailInput}
          {typeInput}
          {phoneInput}
          {optionalPhoneInput}
          {loginInput}
          {passwordInput}
          {accessProfile}
          {active}
          {isVetSelect}
          {crmv}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefaultMaterial
          type="default"
          title="Adicionar"
          disabled={shouldDisableAll}
          keyCode={SUBMIT_MODAL_KEY}
          onKeyPress={() =>
            $submit(
              () => addUserToTable(userfields, $fieldEvent),
              () => ({})
            )
          }
          onClick={() =>
            $submit(
              () => addUserToTable(userfields, $fieldEvent),
              () => ({})
            )
          }
        />
      </Modal.Footer>
    </Modal>
  );

  const openModalBtn = (
    <TableButtonAddMaterial
      title={"Novo Usuário"}
      className={"pull-right"}
      onClick={() => handleLoadUserToModal(defaultUser)}
      disabled={shouldDisableAll}
    />
  );

  const table = (
    <DefaultTable
      configurations={getTableConfigs(
        fields.users,
        toggleSelectUser,
        selectedUsers,
        handleLoadUserToModal
      )}
    />
  );

  const removeUsersBtn = (
    <TableButtonRemoveMaterial
      text={{
        confirm: "Remover",
        message:
          "Os registros selecionados serão removidos permanentemente. Deseja continuar?",
        title: "Remover Usuários",
      }}
      className="pull-left"
      disabled={selectedUsers.length === 0 || shouldDisableAll}
      onClick={handleRemoveUser}
    />
  );

  return (
    <React.Fragment>
      {modal}
      <Col md={12}>
        <Row>
          <Col xs={12} className="mb-15 mt-15">
            {openModalBtn}
            {removeUsersBtn}
            <h5 className="table-title text-center"> Usuários </h5>
          </Col>
          <Col xs={12}>{table}</Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

const withValidation = validated(userModalValidation);

export default withValidation(UsersFields);
