import React from "react";

/** Internal */
import AnimalFieldsFirstRow from "./../AnimalFieldsFirstRow";
import AnimalFieldsSecondRow from "./../AnimalFieldsSecondRow";
import AnimalFieldsThirdRow from "./../AnimalFieldsThirdRow";

const AnimalFields = ({
  className,
  $field,
  $validation,
  updateField,
  onChangeSpecies,
  onChangeBirthDate,
  onChangeYearsOrMonths,
  shouldRenderCustomer,
  customersPromise,
  speciesPromise,
  breedsPromise,
  disableAll,
  shouldDisableCustomer,
  animalNameInputRef,
  fields: {
    customerId,
    name,
    speciesId,
    breedId,
    sexId,
    ageOption,
    birthDate,
    years,
    months,
    owner,
    phone,
    cpf,
    externalCode,
    microchipCode,
  },
}) => (
  <div className={className}>
    <AnimalFieldsFirstRow
      $field={$field}
      $validation={$validation}
      updateField={updateField}
      onChangeSpecies={onChangeSpecies}
      shouldRenderCustomer={shouldRenderCustomer}
      customersPromise={customersPromise}
      speciesPromise={speciesPromise}
      breedsPromise={breedsPromise}
      customerId={customerId}
      name={name}
      speciesId={speciesId}
      breedId={breedId}
      disableAll={disableAll}
      shouldDisableCustomer={shouldDisableCustomer}
      animalNameInputRef={animalNameInputRef}
    />
    <AnimalFieldsSecondRow
      $field={$field}
      $validation={$validation}
      updateField={updateField}
      onChangeBirthDate={onChangeBirthDate}
      onChangeYearsOrMonths={onChangeYearsOrMonths}
      sexId={sexId}
      ageOption={ageOption}
      birthDate={birthDate}
      years={years}
      months={months}
      owner={owner}
      disableAll={disableAll}
    />
    <AnimalFieldsThirdRow
      $field={$field}
      $validation={$validation}
      updateField={updateField}
      phone={phone}
      cpf={cpf}
      externalCode={externalCode}
      microchipCode={microchipCode}
      disableAll={disableAll}
    />
  </div>
);

export default AnimalFields;
