import React from "react";

/**
 * Internal
 */
import ReportHeader from "./../ReportHeader";
import HemogramPanel from "./../HemogramPanel";
import ReportPanelMaterial from "./../ReportPanelMaterial";
import ParvoCoronaPanel from "./../ParvoCoronaPanel";
import ParasitologicalFreshFecesPanel from "./../ParasitologicalFreshFecesPanel";

/**
 * External
 */
import { Col, Row } from "./../../components/grid";

const ProfileGastroenteritisBronzeReportFormView = ({
  title,
  data: {
    refs,
    total,
    fields,
    isUpdate,
    backLink,
    disableAll,
    $validation,
    submitPromise,
    fecesColorOptionsPromise,
    fecesSmellOptionsPromise,
    fecesAspectOptionsPromise,
    fecesConsistencyOptionsPromise,
    reportHeaderData,
    checkboxes,
    parasitologicalFreshFecesResult,
    checkdState,
    reportData,
  },
  methods: {
    $field,
    fieldChange,
    onSubmit,
    calcMCHC,
    calcMCV,
    erythrogramDiagnosis,
    plateletsDiagnosis,
    calcAbsolute,
    resetLeukogram,
    onClickCounter,
    leukogramDiagnosis,
    toggleIntenseLeucopeny,
    finalGlobalLeukogram,
    handleKeyPress,
    handleFinishedPlaying,
    closeCounter,
    handleChange,
    handleBlur,
    cancelReport,
    stopReport,
    handleSendReportEmail,
    handleFocus,
    setRef,
    multiBoxChange,
    handleCheckedResultChange,
  },
}) => (
  <ReportPanelMaterial
    title={title}
    backLink={backLink}
    shouldDisable={disableAll}
    isUpdate={isUpdate}
    isRequesting={submitPromise && submitPromise.pending}
    handleSubmit={onSubmit}
    cancelReport={cancelReport}
    stopReport={stopReport}
    handleSendReportEmail={handleSendReportEmail}
    handleFocus={handleFocus}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={reportHeaderData}
          fields={fields}
          fieldChange={fieldChange}
          $field={$field}
          $validation={$validation}
        />
      </Col>
    </Row>
    <HemogramPanel
      fields={fields}
      refs={refs}
      disableAll={disableAll}
      $validation={$validation}
      total={total}
      $field={$field}
      fieldChange={fieldChange}
      calcMCV={calcMCV}
      calcMCHC={calcMCHC}
      calcAbsolute={calcAbsolute}
      resetLeukogram={resetLeukogram}
      onClickCounter={onClickCounter}
      toggleIntenseLeucopeny={toggleIntenseLeucopeny}
      finalGlobalLeukogram={finalGlobalLeukogram}
      erythrogramDiagnosis={erythrogramDiagnosis}
      leukogramDiagnosis={leukogramDiagnosis}
      plateletsDiagnosis={plateletsDiagnosis}
      handleKeyPress={handleKeyPress}
      handleFinishedPlaying={handleFinishedPlaying}
      closeCounter={closeCounter}
      setRef={setRef}
      reportData={reportData}
    />
    <ParvoCoronaPanel
      title="Resultado"
      materialId={fields.materialId}
      isParvovirus={fields.isParvovirus}
      isCoronavirus={fields.isCoronavirus}
      materialIdFieldName="materialId"
      isParvovirusFieldName="isParvovirus"
      isCoronavirusFieldName="isCoronavirus"
      materialIdHasValidation={$validation.materialId.show}
      isParvovirusHasValidation={$validation.isParvovirus.show}
      isCoronavirusHasValidation={$validation.isCoronavirus.show}
      materialIdValidationReason={$validation.materialId.error.reason}
      isParvovirusValidationReason={$validation.isParvovirus.error.reason}
      isCoronavirusValidationReason={$validation.isCoronavirus.error.reason}
      obsFieldName="parvoCoronaObs"
      obs={fields.parvoCoronaObs}
      shouldDisable={disableAll}
      handleChange={handleChange}
      handleBlur={handleBlur}
    />
    <ParasitologicalFreshFecesPanel
      title="Exame Macroscópico"
      fecesColorOptionId={fields.fecesColorOptionId}
      fecesColorOptionIdFieldName="fecesColorOptionId"
      fecesColorOptions={
        fecesColorOptionsPromise.fulfilled ? fecesColorOptionsPromise.value : []
      }
      isFecesColorOptionsLoading={true === fecesColorOptionsPromise.pending}
      fecesColorOptionIdHasValidation={
        $validation && $validation.fecesColorOptionId.show
      }
      fecesColorOptionIdValidationReason={
        $validation && $validation.fecesColorOptionId.error.reason
      }
      fecesSmellOptionId={fields.fecesSmellOptionId}
      fecesSmellOptionIdFieldName="fecesSmellOptionId"
      fecesSmellOptions={
        fecesSmellOptionsPromise.fulfilled ? fecesSmellOptionsPromise.value : []
      }
      isFecesSmellOptionsLoading={true === fecesSmellOptionsPromise.pending}
      fecesSmellOptionIdHasValidation={$validation.fecesSmellOptionId.show}
      fecesSmellOptionIdValidationReason={
        $validation.fecesSmellOptionId.error.reason
      }
      fecesAspectOptionId={fields.fecesAspectOptionId}
      fecesAspectOptionIdFieldName="fecesAspectOptionId"
      fecesAspectOptions={
        fecesAspectOptionsPromise.fulfilled
          ? fecesAspectOptionsPromise.value
          : []
      }
      isFecesAspectOptionsLoading={true === fecesAspectOptionsPromise.pending}
      fecesAspectOptionIdHasValidation={$validation.fecesAspectOptionId.show}
      fecesAspectOptionIdValidationReason={
        $validation.fecesAspectOptionId.error.reason
      }
      fecesConsistencyOptionId={fields.fecesConsistencyOptionId}
      fecesConsistencyOptionIdFieldName="fecesConsistencyOptionId"
      fecesConsistencyOptions={
        fecesConsistencyOptionsPromise.fulfilled
          ? fecesConsistencyOptionsPromise.value
          : []
      }
      isFecesConsistencyOptionsLoading={
        true === fecesConsistencyOptionsPromise.pending
      }
      fecesConsistencyOptionIdHasValidation={
        $validation.fecesConsistencyOptionId.show
      }
      fecesConsistencyOptionIdValidationReason={
        $validation.fecesConsistencyOptionId.error.reason
      }
      macroscopicParasitesFieldName={"macroscopicParasites"}
      macroscopicParasites={fields.macroscopicParasites}
      macroscopicParasitesHasValidation={$validation.macroscopicParasites.show}
      macroscopicParasitesValidationReason={
        $validation.macroscopicParasites.error.reason
      }
      resultFieldName="parasitologicalFreshFecesResult"
      result={fields.parasitologicalFreshFecesResult}
      resultHasValidation={$validation.parasitologicalFreshFecesResult.show}
      resultValidationReason={
        $validation.parasitologicalFreshFecesResult.error.reason
      }
      obsFieldName="parasitologicalFreshFecesObs"
      obs={fields.parasitologicalFreshFecesObs}
      shouldDisable={disableAll}
      handleChange={handleChange}
      handleBlur={handleBlur}
      parasitologicalFreshFecesResult={parasitologicalFreshFecesResult}
      checkdState={checkdState}
      checkboxes={checkboxes}
      multiBoxChange={multiBoxChange}
      handleCheckedResultChange={handleCheckedResultChange}
    />
  </ReportPanelMaterial>
);

export default ProfileGastroenteritisBronzeReportFormView;
