export const YupLocale = {
  mixed: {
    required: "Este campo é obrigatório",
  },
  number: {
    min: "Este campo é obrigatório",
    max: "Deve ser menor que ${max}",
  },
  string: {
    min: "Este campo é obrigatório",
    max: "Deve possuir menos de ${max} caracter(es)",
  },
  array: {
    min: "Deve ter ao menos ${min}",
    max: "Deve ter no máximo ${max}",
  },
};
