import React, { ChangeEvent, useEffect } from "react";
import { FocusManagerConfig } from "../../../services";
import * as C from "./styles";

interface IPlateletsCounter {
  handleClose: () => void;
  result: string | number;
  setResult: (result: string | number) => void;
}
/**
 *
 * @version 0.0.1
 *
 * @author Kayo Oliveira
 *
 * * Description: Platelets Counter Modal
 *
 * @param {handleClose, result, setResult} props
 *
 * @return {JSX.Element}
 *
 */
const PlateletsCounter: React.FC<IPlateletsCounter> = ({
  handleClose,
  result,
  setResult,
}) => {
  const [inputs, setInputs] = React.useState<number[]>([0, 0, 0, 0, 0]);
  const contentRef = React.useRef<any>(null);
  useEffect(() => {
    const notZeroInputs = inputs.filter((input) => input !== 0);
    if (notZeroInputs.length < 5) {
      setResult(
        Math.round((inputs.reduce((acc, curr) => acc + curr, 0) / 5) * 20)
      );
    }
    setResult(
      Math.round(
        (inputs.reduce((acc, curr) => acc + curr, 0) / notZeroInputs.length) *
          20
      )
    );
  }, [inputs]);
  return (
    <C.Modal>
      <C.CloseModal onClick={handleClose}>x</C.CloseModal>
      <C.ModalHeader>Contador De Plaquetas</C.ModalHeader>
      <C.ModalContent>
        <C.ModalContentLeft ref={contentRef}>
          {inputs.map((input, index) => (
            <C.Input
              key={index}
              value={input}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChange(e, index);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                handleKeyDown(e, index);
              }}
              autoFocus={index === 0}
              type="text"
            />
          ))}
        </C.ModalContentLeft>
        <C.ModalContentRight>
          <span>
            resultado:
            <br /> {result}
          </span>
        </C.ModalContentRight>
      </C.ModalContent>
    </C.Modal>
  );
  function handleChange(e: ChangeEvent<HTMLInputElement>, index: number): void {
    const newInputs = [...inputs];
    newInputs[index] = Number(e.target.value);
    setInputs(newInputs);
  }

  function addInput(): void {
    const newInputs = [...inputs];
    newInputs.push(0);
    const newInput = contentRef.current.lastChild;
    newInput.focus();
    setInputs(newInputs);
  }

  function handleKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void {
    e.preventDefault();
    if (e.key === "Enter") {
      if (contentRef.current) {
        let nextInput = contentRef.current.children[index + 1];
        if (
          nextInput &&
          contentRef.current.children[index].value.toString() !== "0"
        ) {
          nextInput.focus();
        } else if (
          !nextInput &&
          contentRef.current.children[index].value.toString() !== "0" &&
          contentRef.current.children[index - 1].value.toString() !== "0"
        ) {
          addInput();
          setTimeout(() => {
            contentRef.current.children[index + 1].focus();
          }, 100);
        } else if (
          !nextInput &&
          contentRef.current.children[index].value.toString() === "0"
        ) {
          const newInputs = [...inputs];
          newInputs.splice(index, 1);
          setInputs(newInputs);
          setTimeout(() => {
            handleClose();
          }, 100);
        }
      }
    } else if (e.key === "Backspace") {
      if (inputs[index] === 0 && inputs.length > 1) {
        const beforeInput = contentRef.current.children[index - 1];
        if (beforeInput) {
          beforeInput.focus();
        }
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
      } else {
        const newInputs = [...inputs];
        newInputs[index] = newInputs[index] - 1;
        setInputs(newInputs);
      }
    } else if (e.key === " ") {
      const newInputs = [...inputs];
      newInputs[index]++;
      setInputs(newInputs);
    }
  }
};

export default PlateletsCounter;
