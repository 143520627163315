import { validated } from 'react-custom-validation';

import {
  commonReportRules,
  microfilariaeResearchRules
  } from '../../assets/js/validationRules';

const validation = validated( props => {
  const {
    obs,
    vetSignerId,
    requestExamState
  } = props.data.fields;

  const {completeValidation} = props.data;
  const result = props.data.result;

  let fields = [
    'result',
    'obs',
    'requestExamState',
    'vetSignerId'
  ];

  const commonValidation = commonReportRules( vetSignerId, requestExamState );

  const microfilariaeResearchValidation = microfilariaeResearchRules(
    'result',
    result,
    'obs',
    obs,
    completeValidation
  );

  const validations = {
    ...commonValidation,
    ...microfilariaeResearchValidation
  };

  return { fields, validations };
} );

export default validation;