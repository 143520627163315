import React from "react";

/** Internal */
import ReportHeader from "./../ReportHeader";
import TextAreaPanel2 from "./../TextAreaPanel2";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Row, Col } from "./../../components/grid";
import { MultiCheckbox } from "../../components/result-group";
import { EXAM_ID_BY_NAME } from "../../consts/exams";

const ResultObsView = ({ title, data, methods }) => (
  <ReportPanelMaterial
    title={title}
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        {data.checkboxes ? (
          <>
            <TextAreaPanel2
              title={"Resultado"}
              fieldTitle={""}
              name={"result"}
              value={data.result}
              shouldDisable={data.disableAll}
              handleChange={(name, value) =>
                methods.handleCheckedResultChange(value)
              }
              handleBlur={methods.handleBlur}
              hasValidation={data.$validation.result.show}
              validationReason={data.$validation.result.error.reason}
            />
            <MultiCheckbox
              itens={data.checkboxes}
              handleChange={methods.multiBoxChange}
              state={data.checkState}
            />
          </>
        ) : (
          <TextAreaPanel2
            title={"Resultado"}
            fieldTitle={""}
            name={"result"}
            value={data.result}
            shouldDisable={data.disableAll}
            handleChange={methods.handleChange}
            handleBlur={methods.handleBlur}
            hasValidation={data.$validation.result.show}
            validationReason={data.$validation.result.error.reason}
          />
        )}
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <TextAreaPanel2
          title={"Obs"}
          fieldTitle={""}
          name={"obs"}
          value={data.fields.obs}
          shouldDisable={data.disableAll}
          handleChange={methods.handleChange}
          handleBlur={methods.handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default ResultObsView;
