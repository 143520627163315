import React from "react";

/** Internal */
import FlatPanelMaterial from "./../FlatPanelMaterial";
import ReportHeader from "./../ReportHeader";
import DualRadioMaterial from "./../DualRadioMaterial";
import SelectMaterial from "./../SelectMaterial";
import NumberInputMaterial from "./../NumberInputMaterial";
import NumberInputWithReference from "./../NumberInputWithReference";
import TextAreaMaterial from "./../TextAreaMaterial";
import ObsPanel from "./../ObsPanel";
import ReportPanelMaterial from "../ReportPanelMaterial";
import { Col, Row } from "./../../components/grid";

const LiquorAnalisisFormView = ({ data, methods }) => (
  <ReportPanelMaterial
    title="Análise de Líquor"
    backLink={data.backLink}
    shouldDisable={data.disableAll}
    isUpdate={data.isUpdate}
    isRequesting={data.submitData.pending}
    handleSubmit={methods.onSubmit}
    handleSendReportEmail={methods.handleSendReportEmail}
    shouldSendEmail={data.shouldSendEmail}
    cancelReport={methods.cancelReport}
    stopReport={methods.stopReport}
  >
    <Row>
      <Col md={12}>
        <ReportHeader
          data={data.reportHeaderData}
          fields={data.fields}
          fieldChange={methods.fieldChange}
          $field={methods.$field}
          $validation={data.$validation}
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FlatPanelMaterial title="Físico">
          <Row>
            <Col md={12}>
              <NumberInputWithReference
                fieldTitle={"Densidade"}
                fieldName={"density"}
                value={data.fields.density}
                shouldDisable={data.disableAll}
                hasValidation={data.$validation.density.show}
                validationReason={data.$validation.density.error.reason}
                fieldChange={methods.fieldChange}
                $field={methods.$field}
                valueMin={data.refs.densityMin}
                valueMax={data.refs.densityMax}
                decimalScale={1}
                unit="kg/m3"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <hr />
            </Col>
          </Row>
          <Row>
            <NumberInputMaterial
              name="volume"
              md={4}
              title="Volume"
              allowNegative={false}
              decimalScale={1}
              fieldChange={methods.fieldChange}
              value={data.fields.volume}
              disabled={data.disableAll}
              {...methods.$field("volume")}
              hasValidation={data.$validation.volume.show}
              validationReason={data.$validation.volume.error.reason}
              hasValidationRecoil
            />
            <SelectMaterial
              id="colorId"
              title="Cor"
              md={4}
              name="colorId"
              options={data.colors}
              value={data.fields.colorId}
              searchable={true}
              disabled={data.disableAll}
              hasValidationRecoil
              {...methods.$field("colorId", event => {
                methods.fieldChange("colorId", event);
              })}
              hasValidation={
                data.$validation &&
                data.$validation.colorId.show &&
                !data.$validation.colorId.isValid
              }
              validationReason={
                data.$validation && data.$validation.colorId.error.reason
              }
              isValid={data.$validation && data.$validation.colorId.isValid}
            />
            <SelectMaterial
              id="aspectId"
              title="AspectIdo"
              md={4}
              name="aspectId"
              options={data.aspects}
              value={data.fields.aspectId}
              searchable={true}
              disabled={data.disableAll}
              hasValidationRecoil
              {...methods.$field("aspectId", event => {
                methods.fieldChange("aspectId", event);
              })}
              hasValidation={
                data.$validation &&
                data.$validation.aspectId.show &&
                !data.$validation.aspectId.isValid
              }
              validationReason={
                data.$validation && data.$validation.aspectId.error.reason
              }
              isValid={data.$validation && data.$validation.aspectId.isValid}
            />
          </Row>
        </FlatPanelMaterial>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <FlatPanelMaterial title="Químico">
          <NumberInputWithReference
            fieldTitle={"Ph"}
            fieldName={"ph"}
            value={data.fields.ph}
            shouldDisable={data.disableAll}
            hasValidation={data.$validation.ph.show}
            validationReason={data.$validation.ph.error.reason}
            fieldChange={methods.fieldChange}
            $field={methods.$field}
            valueMin={data.refs.phMin}
            valueMax={data.refs.phMax}
            decimalScale={1}
            unit=" "
          />
          <NumberInputWithReference
            fieldTitle={"Proteínas"}
            fieldName={"proteins"}
            value={data.fields.proteins}
            shouldDisable={data.disableAll}
            hasValidation={data.$validation.proteins.show}
            validationReason={data.$validation.proteins.error.reason}
            fieldChange={methods.fieldChange}
            $field={methods.$field}
            valueMin={data.refs.proteinsMin}
            valueMax={data.refs.proteinsMax}
            decimalScale={1}
            unit="mg/dL"
            omitRefTitle
          />
          <NumberInputWithReference
            fieldTitle={"Glicose"}
            fieldName={"glucose"}
            value={data.fields.glucose}
            shouldDisable={data.disableAll}
            hasValidation={data.$validation.glucose.show}
            validationReason={data.$validation.glucose.error.reason}
            fieldChange={methods.fieldChange}
            $field={methods.$field}
            valueMin={data.refs.glucoseMin}
            valueMax={data.refs.glucoseMax}
            decimalScale={1}
            unit="mg/dL"
            omitRefTitle
          />
        </FlatPanelMaterial>
      </Col>
      <Col md={6}>
        <FlatPanelMaterial title="Contagem">
          <Row>
            <NumberInputWithReference
              fieldTitle={"Leucócitos"}
              fieldName={"leukocytes"}
              value={data.fields.leukocytes}
              shouldDisable={data.disableAll}
              hasValidation={data.$validation.leukocytes.show}
              validationReason={data.$validation.leukocytes.error.reason}
              fieldChange={methods.fieldChange}
              $field={methods.$field}
              valueMin={data.refs.leukocytesMin}
              valueMax={data.refs.leukocytesMax}
              decimalScale={1}
              unit="kg/m3"
            />
          </Row>
          <Row className="mt-20">
            <DualRadioMaterial
              md={12}
              title="Presença de Coagulação"
              name="coagulation"
              selectedValue={data.fields.coagulation}
              disabled={data.disableAll}
              firstLabel="Negativo"
              firstValue={0}
              secondLabel="Postivo"
              secondValue={1}
              {...methods.$field("coagulation", event => {
                methods.fieldChange("coagulation", event);
              })}
              hasValidation={
                data.$validation &&
                data.$validation.coagulation.show &&
                !data.$validation.coagulation.isValid
              }
              validationReason={
                data.$validation && data.$validation.coagulation.error.reason
              }
              isValid={data.$validation && data.$validation.coagulation.isValid}
            />
          </Row>
        </FlatPanelMaterial>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FlatPanelMaterial title="Microscopia">
          <TextAreaMaterial
            id="microscopy"
            title="Microscopia"
            name="microscopy"
            disabled={data.disableAll}
            value={data.fields.microscopy}
            {...methods.$field("microscopy", event => {
              methods.fieldChange("microscopy", event.target.value);
            })}
            hasValidation={
              data.$validation &&
              data.$validation.microscopy.show &&
              !data.$validation.microscopy.isValid
            }
            validationReason={
              data.$validation && data.$validation.microscopy.error.reason
            }
            isValid={data.$validation && data.$validation.microscopy.isValid}
          />
        </FlatPanelMaterial>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ObsPanel
          name="obs"
          value={data.fields.obs}
          shouldDisable={data.disableAll}
          handleChange={methods.handleChange}
          handleBlur={methods.handleBlur}
        />
      </Col>
    </Row>
  </ReportPanelMaterial>
);

export default LiquorAnalisisFormView;
