import React from "react";
import FadeIn from "react-fade-in";
import { Modal, Row, Col } from "react-bootstrap";
import Notifications from "react-notification-system-redux";
import * as LifeVet from "../../../components";
import InvoicePreviewModal from "./invoice-preview-modal";
import InvoiceDetailsModal from "./invoice-details-modal";
import ProcessedInvoicesTable from "./processed-invoices-table";
import PotentialInvoicesTable from "./potential-invoices-table";
import { PAYMENT_FREQUENCY } from "./../../../consts";

const InvoiceProcessingView = ({
  previewModalIsOpen,
  detailsModalIsOpen,
  invoicesWereProcessed,
  togglePreviewModal,
  toggleDetailsModal,
  setDetailsModalIsOpen,
  setPreviewModalIsOpen,
  setInvoicesWereProcessed,
  setInvoicesWereSearched,
  previewedInvoice,
  fetchedInvoice,
  processedInvoices,
  potentialInvoices,
  previewInvoice,
  processInvoices,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  handleMonthAndYearChange,
  handleInvoiceGeneration,
  handleInvoicePrinting,
  printableInvoiceUrl,
  handleClosePrintableInvoiceModal,
  handleInvoiceMailing,
  handleReturnToSearch,
  isSubmitting,
  disableAll,
  disableSelectionOfAllCustomers,
  customers,
  regions,
  selectedCustomersIds,
  setSelectedCustomersIds,
  selectAllCustomers,
  toggleCustomerSelection,
  removeCustomerFromSelection,
  addCustomerToSelection,
  isCustomerSelected,
  onPreviewTableDoubleClick,
  onDetailsTableDoubleClick,
  setFieldValue,
  notifications,
}) => {
  return (
    <FadeIn>
      <Modal
        bsSize="large"
        show={printableInvoiceUrl}
        onHide={handleClosePrintableInvoiceModal}
      >
        <Modal.Body>
          <embed src={printableInvoiceUrl} width="100%" height="600" />
        </Modal.Body>
        <Modal.Footer>
          <LifeVet.Button
            float="right"
            type="submit"
            depth="2"
            variant="primary"
            onClick={handleClosePrintableInvoiceModal}
          >
            Fechar
          </LifeVet.Button>
        </Modal.Footer>
      </Modal>
      <InvoicePreviewModal
        isOpen={previewModalIsOpen}
        toggle={togglePreviewModal}
        data={previewedInvoice}
      />
      <InvoiceDetailsModal
        isOpen={detailsModalIsOpen}
        toggle={toggleDetailsModal}
        data={fetchedInvoice}
        start_date={values.start_date}
        end_date={values.end_date}
      />
      <LifeVet.Panel>
        <LifeVet.PanelHeader title="Faturamento"></LifeVet.PanelHeader>
        <LifeVet.PanelBody>
          <Row>
            <Col sm={3}>
              <LifeVet.FormGroup
                errorMessage={touched.month_and_year && errors.month_and_year}
              >
                <LifeVet.InputLabel>Mês / Ano</LifeVet.InputLabel>
                <LifeVet.Input
                  onBlur={handleBlur}
                  value={values.month_and_year}
                  disabled={isSubmitting || disableAll}
                  name="month_and_year"
                  type="month"
                  onChange={(event) => {
                    event.persist();
                    handleMonthAndYearChange(
                      event,
                      setFieldValue,
                      handleChange
                    );
                  }}
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={2}>
              <LifeVet.FormGroup
                errorMessage={touched.start_date && errors.start_date}
              >
                <LifeVet.InputLabel>Data Inicial</LifeVet.InputLabel>
                <LifeVet.Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.start_date}
                  disabled={isSubmitting || disableAll}
                  name="start_date"
                  type="date"
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={2}>
              <LifeVet.FormGroup
                errorMessage={touched.end_date && errors.end_date}
              >
                <LifeVet.InputLabel>Data Final</LifeVet.InputLabel>
                <LifeVet.Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.end_date}
                  disabled={isSubmitting || disableAll}
                  name="end_date"
                  type="date"
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={3}>
              <LifeVet.FormGroup
                errorMessage={touched.frequency && errors.frequency}
              >
                <LifeVet.InputLabel>
                  Frequência de Faturamento
                </LifeVet.InputLabel>
                <LifeVet.Select
                  name="frequency"
                  value={values.frequency}
                  onChange={(value) => setFieldValue("frequency", value)}
                  value={values.frequency}
                  disabled={isSubmitting || disableAll}
                  options={PAYMENT_FREQUENCY.values}
                  multi={false}
                  closeOnSelect={true}
                />
              </LifeVet.FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={5}>
              <LifeVet.FormGroup>
                <LifeVet.InputLabel>Regiões</LifeVet.InputLabel>

                <LifeVet.Select
                  id="exam_select"
                  name="regions"
                  value={values.regions}
                  options={regions}
                  searchable={true}
                  disabled={isSubmitting || disableAll || !regions}
                  multi={true}
                  closeOnSelect={false}
                  onChange={(value) => setFieldValue("regions", value)}
                />
              </LifeVet.FormGroup>
            </Col>
            <Col sm={5}>
              <LifeVet.FormGroup>
                <LifeVet.InputLabel>Clientes</LifeVet.InputLabel>

                <LifeVet.Select
                  id="exam_select"
                  name="customer"
                  value={values.customer}
                  options={customers}
                  searchable={true}
                  disabled={isSubmitting || disableAll || !customers}
                  placeholder={""}
                  multi={false}
                  closeOnSelect={true}
                  onChange={(value) => setFieldValue("customer", value)}
                  onBlur={handleBlur}
                />
              </LifeVet.FormGroup>
            </Col>
            {invoicesWereProcessed || (
              <Col sm={2} className="mt-15">
                <LifeVet.Button
                  float="left"
                  type="submit"
                  depth="2"
                  variant="primary"
                  onClick={(e) => {
                    handleSubmit(e);
                    setInvoicesWereSearched(true);
                  }}
                  disabled={disableAll}
                >
                  Consultar
                </LifeVet.Button>
              </Col>
            )}
          </Row>

          {invoicesWereProcessed ? (
            <>
              <h3>Faturamentos Processados</h3>
              <Row>
                <Col className="mt-20">
                  <ProcessedInvoicesTable
                    data={processedInvoices}
                    handleInvoicePrinting={handleInvoicePrinting}
                    handleInvoiceMailing={handleInvoiceMailing}
                    onTableDoubleClick={(currentId, state, rowInfo) =>
                      onDetailsTableDoubleClick(
                        rowInfo.original.id,
                        setDetailsModalIsOpen
                      )
                    }
                  />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <PotentialInvoicesTable
                    potentialInvoices={potentialInvoices}
                    selectedCustomersIds={selectedCustomersIds}
                    setSelectedCustomersIds={setSelectedCustomersIds}
                    toggleCustomerSelection={toggleCustomerSelection}
                    isCustomerSelected={isCustomerSelected}
                    onTableDoubleClick={(currentId, state, rowInfo) =>
                      onPreviewTableDoubleClick(
                        rowInfo.original.customer_id,
                        potentialInvoices,
                        previewInvoice,
                        values,
                        setPreviewModalIsOpen
                      )
                    }
                  />
                </Col>
              </Row>
            </>
          )}
        </LifeVet.PanelBody>
        <LifeVet.PanelFooter>
          {invoicesWereProcessed ? (
            <LifeVet.Button
              float="right"
              variant="primary"
              onClick={() => handleReturnToSearch()}
            >
              Voltar
            </LifeVet.Button>
          ) : (
            <>
              <Col>
                <LifeVet.Button
                  float="right"
                  type="submit"
                  depth="2"
                  variant="primary"
                  disabled={selectedCustomersIds.length == 0}
                  onClick={() =>
                    handleInvoiceGeneration(
                      selectedCustomersIds,
                      potentialInvoices,
                      processInvoices,
                      values,
                      setInvoicesWereProcessed
                    )
                  }
                >
                  Gerar Fatura
                </LifeVet.Button>
              </Col>
              <Col>
                <LifeVet.Button
                  float="right"
                  type="submit"
                  depth="2"
                  variant="primary"
                  onClick={selectAllCustomers}
                  disabled={disableSelectionOfAllCustomers}
                >
                  Marcar Todos
                </LifeVet.Button>
              </Col>
            </>
          )}
        </LifeVet.PanelFooter>
      </LifeVet.Panel>
      <Notifications notifications={notifications} />
    </FadeIn>
  );
};

export default InvoiceProcessingView;
