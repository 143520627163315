import React from 'react';

/**
 * Internal
 */
import FlatPanelMaterial from './../FlatPanelMaterial';
import DefaultMolecularBiologyContent from './../DefaultMolecularBiologyContent';

const DefaultMolecularBiologyPanel = ( {
  title,
  materialsFieldName,
  materials,
  materialsSuggestions,
  materialsSuggestionsFilter,
  materialsHasValidation,
  materialsValidationReason,
  techniqueFieldName,
  technique,
  techniqueHasValidation,
  techniqueValidationReason,
  qualitativeResultFieldName,
  qualitativeResult,
  qualitativeResultHasValidation,
  qualitativeResultValidationReason,
  copyValuesFieldName,
  copyValues,
  copyValuesHasValidation,
  copyValuesValidationReason,
  precisionFieldName,
  precision,
  precisionHasValidation,
  precisionValidationReason,
  resultFieldName,
  result,
  obsFieldName,
  obs,
  shouldDisable,
  handleChange,
  handleBlur
} ) =>
  <FlatPanelMaterial title={ title } >
    <DefaultMolecularBiologyContent
      materialsFieldName={ materialsFieldName }
      materials={ materials }
      materialsSuggestions={ materialsSuggestions }
      materialsSuggestionsFilter={ materialsSuggestionsFilter }
      materialsHasValidation={ materialsHasValidation }
      materialsValidationReason={ materialsValidationReason }
      techniqueFieldName={ techniqueFieldName }
      technique={ technique }
      techniqueHasValidation={ techniqueHasValidation }
      techniqueValidationReason={ techniqueValidationReason }
      qualitativeResultFieldName={ qualitativeResultFieldName }
      qualitativeResult={ qualitativeResult }
      qualitativeResultHasValidation={ qualitativeResultHasValidation }
      qualitativeResultValidationReason={ qualitativeResultValidationReason }
      copyValuesFieldName={ copyValuesFieldName }
      copyValues={ copyValues }
      copyValuesHasValidation={ copyValuesHasValidation }
      copyValuesValidationReason={ copyValuesValidationReason }
      precisionFieldName={ precisionFieldName }
      precision={ precision }
      precisionHasValidation={ precisionHasValidation }
      precisionValidationReason={ precisionValidationReason }
      resultFieldName={ resultFieldName }
      result={ result }
      obsFieldName={ obsFieldName }
      obs={ obs }
      shouldDisable={ shouldDisable }
      handleChange={ handleChange }
      handleBlur={ handleBlur } />
  </FlatPanelMaterial>

export default DefaultMolecularBiologyPanel;