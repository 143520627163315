import { required } from '../../assets/js/validationFunctions.js';

/**
 * @author Victor Heringer
 * 
 * Validation function for receive and assist modal
 * 
 * @param {Objcet} props 
 * 
 * @return {Object}
 */
export const PaymentMethodModalValidation = (props) => {

  const { name } = props.data.fields;

  const fields = ['name'];

  const validations = {
    name: {
      rules: [
        [required, name, '']
      ]
    }
  }

  return { fields, validations };
}