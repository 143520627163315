import React from "react";
import * as LifeVet from "./../../../../../components";
import styles from "./index.module.css";

export const Footer = ({
  requestId,
  disableAll,
  isReadOnly,
  formIsInUpdateMode,
  loggedUserIsFromClinic,
  goBack,
  requestIsSubmitting,
  submit,
  submitWithPartialSave,
  submitAndRestart,
  sendEmail,
  printRequest,
}: {
  requestId: number;
  disableAll: boolean;
  isReadOnly: boolean;
  formIsInUpdateMode: boolean;
  loggedUserIsFromClinic: boolean;
  goBack: any;
  requestIsSubmitting: boolean;
  submit: any;
  submitWithPartialSave: any;
  submitAndRestart: any;
  sendEmail: any;
  printRequest: any;
}) => {
  const labUserTitle = formIsInUpdateMode ? "Atualizar" : "Salvar";
  const submitBtnTitle = loggedUserIsFromClinic ? "Enviar " : labUserTitle;
  const submitBtnPlaceholder = formIsInUpdateMode
    ? "Atualizando.."
    : "Salvando..";

  return (
    <LifeVet.PanelFooter>
      <LifeVet.Button
        disabled={disableAll}
        onClick={goBack}
        depth="2"
        variant="secondary"
        float="left"
      >
        Voltar
      </LifeVet.Button>
      <LifeVet.Button
        onClick={() =>
          submit({ isPartialSave: false, redirect: false, restart: false })
        }
        disabled={disableAll || isReadOnly}
        depth="2"
        variant="primary"
      >
        {requestIsSubmitting ? submitBtnPlaceholder : submitBtnTitle}
      </LifeVet.Button>
      {loggedUserIsFromClinic && (
        <LifeVet.Button
          onClick={submitWithPartialSave}
          disabled={disableAll || isReadOnly}
          depth="2"
          variant="primary"
        >
          {requestIsSubmitting ? submitBtnPlaceholder : "Salvar "}
        </LifeVet.Button>
      )}
      {!formIsInUpdateMode && !loggedUserIsFromClinic && (
        <LifeVet.Button
          onClick={submitAndRestart}
          disabled={disableAll || isReadOnly}
          depth="2"
          variant="primary"
        >
          {requestIsSubmitting ? submitBtnPlaceholder : "Salvar e Criar Nova"}
        </LifeVet.Button>
      )}
      {formIsInUpdateMode && !loggedUserIsFromClinic && (
        <LifeVet.Button
          className={styles.emailBtn}
          disabled={disableAll || isReadOnly}
          onClick={() => sendEmail(requestId)}
          depth="2"
          variant="primary"
        >
          Email
        </LifeVet.Button>
      )}
      {formIsInUpdateMode && (
        <LifeVet.Button
          className={styles.emailBtn}
          disabled={disableAll || isReadOnly}
          onClick={() => {
            printRequest(requestId);
          }}
          depth="2"
          variant="primary"
        >
          Imprimir
        </LifeVet.Button>
      )}
    </LifeVet.PanelFooter>
  );
};
