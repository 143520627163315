import React from "react";

/** Internal */
import { userModalValidation } from "./../UnityForm/validation";
import DefaultTable from "./../DefaultTable";
import InputMaterial from "./../InputMaterial";
import LVInputTextUpper from "./../../old_components/LVInputTextUpper";
import ButtonDefaultMaterial from "./../ButtonDefaultMaterial";
import SelectMaterial from "./../SelectMaterial";
import CheckboxMaterial from "./../CheckboxInputMaterial";
import StatusCircle from "./../StatusCircle";
import TableButtonRemoveMaterial from "./../TableButtonRemoveMaterial";
import TableButtonEditMaterial from "./../TableButtonEditMaterial";
import TableButtonAddMaterial from "./../TableButtonAddMaterial";
import {
  STATUS,
  YES_OR_NO,
  CPF_MASK,
  PHONE_MASK,
  ACCESS_PROFILES_LIST,
  SUBMIT_MODAL_KEY,
} from "../../assets/js/Consts.js";
import LVFileInputMaterial from "./../../old_components/LVFileInputMaterial";

/** Assets */
import styles from "./index.module.css";

/** External */
import { validated } from "react-custom-validation";
import { Row, Col, Modal } from "react-bootstrap";

/**
 * @author Victor Heringer
 *
 * Render the checkbox thats allow to delete user
 *
 * @param {Object} data
 * @param {Function} toggleSelectUser
 * @param {Array} selectedUsers
 */
const renderCheckbox = (data, toggleSelectUser, selectedUsers) => {
  if (!data.original.id) {
    return (
      <CheckboxMaterial
        checked={selectedUsers.indexOf(data.original.name) > -1}
        onChange={() => toggleSelectUser(data.original.name)}
      />
    );
  }
  return <span />;
};

/**
 * @author Victor Heringer
 *
 * Render a circle to display boolean status
 *
 * @param {Bool} status
 *
 * @return {Object}
 */
const renderStatusCircle = (status) => {
  return <StatusCircle status={status} />;
};

/**
 * @author Victor Heringer
 *
 * Render the edit button
 *
 * @param {Object} data Table data, including id to fetch user
 * @param {Function} handleLoadUserToModal Calls modal and sets the user
 *
 * @return {Object}
 */
const renderEditButton = (data, handleLoadUserToModal) => {
  const user = {
    id: data.original.id,
    login: data.original.login,
    active: data.original.active,
    password: "",
    username: data.original.name,
    email: data.original.email,
    phone: data.original.phone,
    cpf: data.original.cpf,
    isVet: data.original.isVet,
    isResponsible: data.original.isResponsible,
    crmv: data.original.crmv,
    signature: data.original.signature,
    accessProfile: data.original.accessProfile,
  };

  const onClick = () => handleLoadUserToModal(user);

  return <TableButtonEditMaterial onClick={onClick} disabled={!user.id} />;
};

/**
 * @author Victor Heringer
 *
 * Returns react table configs
 *
 * @return {Array}
 */
const getTableConfigs = (
  users,
  toggleSelectUser,
  selectedUsers,
  handleLoadUserToModal
) => {
  let config = {
    defaultPageSize: 5,
    defaultSorted: [{ id: "id", desc: true }],
    pageSize: 25,
    resizable: false,
    showPagination: true,
    noDataText: "Nenhum registro".toUpperCase(),
    loadingText: "Carregando",
    className: "-striped -highlight",
    previousText: "Anterior",
    nextText: "Próximo",
    pageText: "Página",
    ofText: "de",
    rowsText: "linhas",
    columns: [],
    showPageSizeOptions: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
  };
  config.data = users;
  config.pageSize = 5;
  config.className = "no-page-info";
  config.columns = [
    {
      Header: "",
      sortable: false,
      filterable: false,
      width: 60,
      Cell: (data) => renderCheckbox(data, toggleSelectUser, selectedUsers),
    },
    {
      Header: "",
      sortable: false,
      width: 60,
      className: styles.centeredText,
      Cell: (data) => renderEditButton(data, handleLoadUserToModal),
    },
    {
      Header: "Nome",
      accessor: "name",
      sortable: true,
      className: styles.centeredText,
    },
    {
      Header: "Veterinário",
      accessor: "isVet",
      sortable: true,
      Cell: (data) => renderStatusCircle(data.original.isVet),
      className: styles.centeredText,
    },
    {
      Header: "Acesso",
      accessor: "access",
      Cell: (data) => renderStatusCircle(data.original.access),
      sortable: true,
      className: styles.centeredText,
    },
  ];

  return config;
};

/**
 * @author Victor Heringer
 *
 * Regions form fields
 */
const UsersFields = ({
  fields,
  $field,
  $fieldEvent,
  $validation,
  $submit,
  methods: {
    fieldChange,
    fieldChangeIsVet,
    addUserToTable,
    handleRemoveUser,
    handleLoadUserToModal,
    toggleSelectUser,
    handleSignatureUpload,
  },
  data: { selectedUsers, displayUserModal, defaultUser, shouldDisableAll },
}) => {
  const nameInput = (
    <LVInputTextUpper
      id="modalUserName"
      name="modalUserName"
      title="Nome"
      required
      autofocus={true}
      md={6}
      disabled={shouldDisableAll}
      value={fields.modalUserName}
      {...$field("modalUserName", (event) => {
        fieldChange("modalUserName", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserName.show &&
        !$validation.modalUserName.isValid
      }
      validationReason={$validation && $validation.modalUserName.error.reason}
      isValid={$validation && $validation.modalUserName.isValid}
    />
  );

  const cpfInput = (
    <LVInputTextUpper
      id="modalUserCPF"
      name="modalUserCPF"
      title="CPF"
      disabled={shouldDisableAll}
      md={6}
      mask
      maskValue={CPF_MASK}
      value={fields.modalUserCPF}
      {...$field("modalUserCPF", (event) => {
        fieldChange("modalUserCPF", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserCPF.show &&
        !$validation.modalUserCPF.isValid
      }
      validationReason={$validation && $validation.modalUserCPF.error.reason}
      isValid={$validation && $validation.modalUserCPF.isValid}
    />
  );

  const emailInput = (
    <LVInputTextUpper
      id="modalUserEmail"
      name="modalUserEmail"
      title="Email"
      required
      disabled={shouldDisableAll}
      md={6}
      value={fields.modalUserEmail}
      {...$field("modalUserEmail", (event) => {
        fieldChange("modalUserEmail", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserEmail.show &&
        !$validation.modalUserEmail.isValid
      }
      validationReason={$validation && $validation.modalUserEmail.error.reason}
      isValid={$validation && $validation.modalUserEmail.isValid}
    />
  );

  const phoneInput = (
    <LVInputTextUpper
      id="modalUserPhone"
      name="modalUserPhone"
      title="Telefone"
      disabled={shouldDisableAll}
      md={6}
      mask
      maskValue={PHONE_MASK}
      value={fields.modalUserPhone}
      {...$field("modalUserPhone", (event) => {
        fieldChange("modalUserPhone", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserPhone.show &&
        !$validation.modalUserPhone.isValid
      }
      validationReason={$validation && $validation.modalUserPhone.error.reason}
      isValid={$validation && $validation.modalUserPhone.isValid}
    />
  );

  const loginInput = (
    <InputMaterial
      id="modalUserLogin"
      name="modalUserLogin"
      title="Login"
      required
      disabled={shouldDisableAll}
      md={6}
      value={fields.modalUserLogin}
      {...$field("modalUserLogin", (event) => {
        fieldChange("modalUserLogin", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserLogin.show &&
        !$validation.modalUserLogin.isValid
      }
      validationReason={$validation && $validation.modalUserLogin.error.reason}
      isValid={$validation && $validation.modalUserLogin.isValid}
    />
  );

  const passwordInput = (
    <InputMaterial
      id="modalUserPassword"
      name="modalUserPassword"
      disabled={shouldDisableAll}
      title="Senha"
      required
      type="password"
      md={6}
      value={fields.modalUserPassword}
      {...$field("modalUserPassword", (event) => {
        fieldChange("modalUserPassword", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserPassword.show &&
        !$validation.modalUserPassword.isValid
      }
      validationReason={
        $validation && $validation.modalUserPassword.error.reason
      }
      isValid={$validation && $validation.modalUserPassword.isValid}
    />
  );

  const accessProfile = (
    <SelectMaterial
      title="Perfil de Acesso"
      md={6}
      name="modalUserAccessProfile"
      options={ACCESS_PROFILES_LIST}
      value={fields.modalUserAccessProfile}
      searchable={true}
      disabled
      {...$field("modalUserAccessProfile", (event) => {
        fieldChange("modalUserAccessProfile", event);
      })}
    />
  );

  const active = (
    <SelectMaterial
      title="Ativo"
      md={6}
      name="modalUserActive"
      options={STATUS}
      value={fields.modalUserActive}
      searchable={true}
      disabled={shouldDisableAll}
      {...$field("modalUserActive", (event) => {
        fieldChange("modalUserActive", event);
      })}
    />
  );

  const shouldDisableVet =
    fields.modalUserId > 0 && fields.modalUserInitIsVet ? true : false;

  const shouldDisableResponsible = false;

  const isVetSelect = (
    <SelectMaterial
      title="Veterinário"
      md={6}
      name="modalUserIsVet"
      options={YES_OR_NO}
      value={fields.modalUserIsVet}
      disabled={shouldDisableVet || shouldDisableAll}
      searchable={true}
      {...$field("modalUserIsVet", (event) => {
        fieldChangeIsVet("modalUserIsVet", event);
      })}
    />
  );

  const crmv = (
    <LVInputTextUpper
      id="modalUserCRMV"
      name="modalUserCRMV"
      title="CRMV"
      md={6}
      disabled={!fields.modalUserIsVet || shouldDisableAll}
      value={fields.modalUserCRMV}
      {...$field("modalUserCRMV", (event) => {
        fieldChange("modalUserCRMV", event.target.value);
      })}
      hasValidation={
        $validation &&
        $validation.modalUserCRMV.show &&
        !$validation.modalUserCRMV.isValid
      }
      validationReason={$validation && $validation.modalUserCRMV.error.reason}
      isValid={$validation && $validation.modalUserCRMV.isValid}
    />
  );

  /**
   *
   * @author Kayo Oliveira
   *
   * select if user is responsible 4 the unit
   */

  const submitUser = () =>
    $submit(
      () =>
        addUserToTable(
          {
            id: fields.modalUserId,
            login: fields.modalUserLogin,
            password: fields.modalUserPassword,
            name: fields.modalUserName,
            email: fields.modalUserEmail,
            phone: fields.modalUserPhone,
            cpf: fields.modalUserCPF,
            phone: fields.modalUserPhone,
            isVet: fields.modalUserIsVet,
            crmv: fields.modalUserCRMV,
            active: fields.modalUserActive,
            signature: fields.modalUserSignature,
            accessProfile: fields.modalUserAccessProfile,
          },
          $fieldEvent
        ),
      () => ({})
    );

  const modal = (
    <Modal
      show={displayUserModal}
      onHide={() => handleLoadUserToModal(defaultUser)}
    >
      <Modal.Header closeButton>
        <Modal.Title> Adicionar Usuário </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {nameInput}
          {cpfInput}
          {emailInput}
          {phoneInput}
          {loginInput}
          {passwordInput}
          {accessProfile}
          {active}
          {isVetSelect}
          {crmv}
        </Row>
        <Row className="p-15">
          <Col xs={12}>
            <LVFileInputMaterial
              id={"signaturesUpload"}
              name={"signaturesUpload"}
              allowedExtensions={[".png", ".jpg", ".jpeg"]}
              setContainerFiles={handleSignatureUpload}
              asBase64={true}
              maxFileSize={500000}
              allowMultiples={false}
              hasImgPreview={true}
              clearBtnTitle={"Remover Assinatura"}
              title={"Selecionar Asssinatura"}
              description={
                "Tamanho máximo aceito para o arquivo: 500kb - Utilize a resolução 200x85 px"
              }
              hasFilesNames={true}
              placeholders={fields.modalUserSignature}
              disabled={shouldDisableAll}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefaultMaterial
          keyCode={SUBMIT_MODAL_KEY}
          onKeyPress={submitUser}
          type="default"
          title="Adicionar"
          disabled={shouldDisableAll}
          onClick={submitUser}
        />
      </Modal.Footer>
    </Modal>
  );

  const openModalBtn = (
    <TableButtonAddMaterial
      title={"Novo Usuário"}
      className={"pull-right"}
      disabled={shouldDisableAll}
      onClick={() => handleLoadUserToModal(defaultUser)}
    />
  );

  const table = (
    <DefaultTable
      configurations={getTableConfigs(
        fields.users,
        toggleSelectUser,
        selectedUsers,
        handleLoadUserToModal
      )}
    />
  );

  const removeUsersBtn = (
    <TableButtonRemoveMaterial
      text={{
        confirm: "Remover",
        message:
          "Os registros selecionados serão removidos permanentemente. Deseja continuar?",
        title: "Remover Usuários",
      }}
      className="pull-left"
      disabled={selectedUsers.length === 0}
      onClick={handleRemoveUser}
    />
  );

  return (
    <React.Fragment>
      {modal}
      <Col md={6}>
        <Row>
          <Col xs={12} className="mb-15 mt-15">
            {openModalBtn}
            {removeUsersBtn}
            <h5 className="table-title text-center"> Usuários </h5>
          </Col>
          <Col xs={12}>{table}</Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

const withValidation = validated(userModalValidation);

export default withValidation(UsersFields);
